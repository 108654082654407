import { Task, ZIO } from '@mxt/zio'
import { pipe } from 'fp-ts/pipeable'
import * as t from 'io-ts'
import { Maybe } from '@mxt/zio/codec'
import { POApi } from '../POApi'
import { CashOutType, MaturityPayment, RequestAuthFormDataSubmit } from './model'
import { SubmissionService } from './SubmissionService'
export namespace MaturityPaymentService {
  const getSuspendAmount = (policyNum: string) =>
    pipe(
      POApi.post(`wf-api/policy/account-balance`)(
        t.type({
          body: Maybe(
            t.type({
              accountGroups: Maybe(
                t.array(
                  t.type({
                    amount: Maybe(t.number),
                    sacsCode: Maybe(t.string),
                    sacsType: Maybe(t.string)
                  })
                )
              )
            })
          )
        })
      )({
        body: {
          policyNum,
          function: 'TOTAL',
          accountGroups: [
            {
              sacsCode: 'LP',
              sacsType: 'S'
            },
            {
              sacsCode: 'LF',
              sacsType: 'WF'
            },
            {
              sacsCode: 'LX',
              sacsType: 'TF'
            },
            {
              sacsCode: 'LP',
              sacsType: 'SB'
            },
            {
              sacsCode: 'LP',
              sacsType: 'PS'
            },
            {
              sacsCode: 'LX',
              sacsType: 'EX'
            },
            {
              sacsCode: 'LP',
              sacsType: 'MI'
            },
            {
              sacsCode: 'LI',
              sacsType: 'MO'
            },
            {
              sacsCode: 'LP',
              sacsType: 'PX'
            },
            {
              sacsCode: 'LP',
              sacsType: 'U'
            }
          ]
        }
      }),
      ZIO.map((responseData) => {
        return responseData.body?.accountGroups?.map((item) => {
          const amount = getAmountByFilterSaccode(item.amount ?? 0, item.sacsCode ?? '', item.sacsType ?? '')
          return {
            amount: amount,
            sacsCode: item.sacsCode,
            sacsType: item.sacsType
          }
        })
      })
    )

  const getAmountByFilterSaccode = (amount: number, sacsCode: string, sacsType: string) => {
    if (
      (sacsCode === 'LP' && sacsType === 'MI') ||
      (sacsCode === 'LI' && sacsType === 'MO') ||
      (sacsCode === 'LP' && sacsType === 'PX')
    ) {
      return amount < 0 ? amount * -1 : amount
    }
    return amount
  }

  const paidMaturity = (policyNum: string) =>
    pipe(
      POApi.post(`wf-api/policy/account-balance`)(
        t.type({
          body: Maybe(
            t.type({
              accountGroups: Maybe(
                t.array(
                  t.type({
                    amount: Maybe(t.number),
                    sacsCode: Maybe(t.string),
                    sacsType: Maybe(t.string)
                  })
                )
              )
            })
          )
        })
      )({
        body: {
          policyNum,
          function: 'T542',
          accountGroups: [
            {
              sacsCode: 'GL',
              sacsType: 'MD'
            }
          ]
        }
      })
    )

  const notPaidPremium = (policyNum: string) =>
    pipe(
      POApi.post(`wf-api/policy/account-balance`)(
        t.type({
          body: Maybe(
            t.type({
              accountGroups: Maybe(
                t.array(
                  t.type({
                    amount: Maybe(t.number),
                    sacsCode: Maybe(t.string),
                    sacsType: Maybe(t.string)
                  })
                )
              )
            })
          )
        })
      )({
        body: {
          policyNum,
          function: 'T542',
          accountGroups: [
            {
              sacsCode: 'LN',
              sacsType: 'VP'
            }
          ]
        }
      })
    )

  const deductOfClaims = (policyNum: string) =>
    pipe(
      POApi.post(`wf-api/policy/account-balance`)(
        t.type({
          body: Maybe(
            t.type({
              accountGroups: Maybe(
                t.array(
                  t.type({
                    amount: Maybe(t.number),
                    sacsCode: Maybe(t.string),
                    sacsType: Maybe(t.string)
                  })
                )
              )
            })
          )
        })
      )({
        body: {
          policyNum,
          function: 'T542',
          accountGroups: [
            {
              sacsCode: 'LP',
              sacsType: 'LT'
            },
            {
              sacsCode: 'LP',
              sacsType: 'LZ'
            },
            {
              sacsCode: 'LP',
              sacsType: 'LU'
            },
            {
              sacsCode: 'LP',
              sacsType: 'LV'
            }
          ]
        }
      })
    )

  export const getMaturityBenefit = (policyNum: string) =>
    pipe(
      ZIO.zipPar(
        getSuspendAmount(policyNum),
        paidMaturity(policyNum),
        notPaidPremium(policyNum),
        deductOfClaims(policyNum)
      ),
      ZIO.map(([suspendData, padMaturityData, notPaidData, deductData]): MaturityPayment.MaturityBenefit => {
        // const suspendAmount =
        //   (suspendData.body && suspendData.body?.accountGroups?.reduce((total, e) => total + (e.amount ?? 0), 0)) || 0
        const suspendAmount = (suspendData && suspendData.reduce((total, e) => total + (e.amount ?? 0), 0)) || 0
        const paidMaturity =
          (padMaturityData.body &&
            padMaturityData.body?.accountGroups?.reduce((total, e) => total + (e.amount ?? 0), 0)) ||
          0
        const notPaidPremium =
          (notPaidData.body && notPaidData.body.accountGroups?.reduce((total, e) => total + (e.amount ?? 0), 0)) || 0
        const deductOfClaims =
          (deductData.body && deductData.body.accountGroups?.reduce((total, e) => total + (e.amount ?? 0), 0)) || 0
        return {
          suspendAmount,
          paidMaturity,
          notPaidPremium,
          deductOfClaims
        }
      })
    )

  export const getCashoutMaturity = (policyNum: string) =>
    POApi.get(`wf-api/policy/${policyNum}/cashout?type=maturity`)(
      t.type({
        body: Maybe(
          t.type({
            cashOutOptions: Maybe(
              t.array(
                t.type({
                  paymentOption: Maybe(
                    t.type({
                      amount: t.number,
                      currency: t.string
                    })
                  ),
                  type: t.union([
                    t.literal(CashOutType.MATURITYBENEFIT),
                    t.literal(CashOutType.PRUCASHBENEFIT),
                    t.literal(CashOutType.SUSPENDAMOUNT)
                  ])
                })
              )
            ),
            totalCashBenefit: Maybe(t.number),
            attributesExt: Maybe(
              t.type({
                LOAN_EXCEED_80_SURRENDER_VALUE: Maybe(t.number),
                NOT_PAID_PREMIUM: Maybe(t.number),
                TOTAL_CASH_BENEFIT: Maybe(t.number),
                NET_TOTAL_CASH_BENEFIT: Maybe(t.number),
                TOTAL_MATURITY_AMOUNT: Maybe(t.number)
              })
            )
          })
        )
      })
    )

  export const getDetail = (policyNum: string): Task<MaturityPayment.Detail> =>
    pipe(
      ZIO.zipPar(
        SubmissionService.getPolicy(policyNum),
        getCashoutMaturity(policyNum),
        getMaturityBenefit(policyNum),
        SubmissionService.getPolicyLoanInfoMaturity(policyNum)
      ),
      ZIO.map(([policy, cashout, maturityBenefitInfo, loanInfo]): MaturityPayment.Detail => {
        const suspendAmount = maturityBenefitInfo.suspendAmount
        const paidMaturity = maturityBenefitInfo.paidMaturity
        const notPaidPremium = maturityBenefitInfo.notPaidPremium
        const deductOfClaims = maturityBenefitInfo.deductOfClaims
        const maturityBenefit =
          cashout.body?.cashOutOptions?.find((e) => e.type === CashOutType.MATURITYBENEFIT)?.paymentOption?.amount ?? 0
        const prucashBenefit =
          cashout.body?.cashOutOptions?.find((e) => e.type === CashOutType.PRUCASHBENEFIT)?.paymentOption?.amount ?? 0
        const totalCashBenefit = cashout.body?.attributesExt?.TOTAL_MATURITY_AMOUNT ?? 0
        const principalAplOpl = loanInfo.balanceAPL + loanInfo.balanceOPL
        const interestAplOpl = loanInfo.interestAPL + loanInfo.interestOPL

        return {
          policyNum,
          clientId: policy.body.owners.id ?? '',
          maturityBenefit,
          prucashBenefit,
          suspendAmount,
          totalCashBenefit,
          paidMaturity,
          principalAplOpl,
          interestAplOpl,
          notPaidPremium,
          deductOfClaims
        }
      })
    )
  export const submit =
    (policyNum: string, data: MaturityPayment.SubmitData) => (requestauth: RequestAuthFormDataSubmit) =>
      SubmissionService.submit(t.unknown)(
        `wf-api/policy/${policyNum}/maturity`,
        { body: data },
        policyNum,
        requestauth,
        []
      )
  // pipe(
  //   PulseOpsApi.postAuthError()(`wf-api/policy/${policyNum}/maturity`)(t.unknown)({
  //     body: data,
  //     otpTransaction: requestauth,
  //     source: SourceType.PULSE4OPS
  //   }),
  //   ZIO.asVoid
  // )

  export const getAccessData = (policyNum: string): Task<MaturityPayment.AccessDataCheck> =>
    pipe(
      ZIO.zipPar(
        SubmissionService.getPolicy(policyNum),
        POApi.post(`wf-api/policy/account-balance`)(
          t.type({
            body: t.type({
              accountGroups: t.array(
                t.type({
                  amount: t.number,
                  sacsCode: t.string,
                  sacsType: t.string
                })
              )
            })
          })
        )({
          body: {
            policyNum,
            function: 'TOTAL',
            accountGroups: [
              {
                sacsCode: 'LP',
                sacsType: 'MP'
              },
              {
                sacsCode: 'LP',
                sacsType: 'EO'
              },
              {
                sacsCode: 'LN',
                sacsType: 'EI'
              }
            ]
          }
        })
      ),
      ZIO.map(([policy, banlance]): MaturityPayment.AccessDataCheck => {
        return {
          contractStatus: policy.body.status ?? '',
          balanceAmount: banlance.body.accountGroups.reduce((total, e) => total + e.amount, 0)
        }
      })
    )
}
