import React, { useState, useEffect, useRef, useCallback } from 'react'
import { View } from 'react-native'
import { assets } from '@pulseops/common'
import { useVoiceRecording } from '../BussinessLogic'

interface Props {
  audioRef: any
  progressBarRef: any
  handleNext?: any
  setTimeProgress: (e: number) => void
}
const Controls: React.FC<Props> = ({ audioRef, progressBarRef, setTimeProgress }) => {
  const { state, controller } = useVoiceRecording()

  useEffect(() => {
    const audio = audioRef.current
    const onPlay = () => audio.play()

    if (audio && state.isPlaying) {
      audio.addEventListener('canplaythrough', onPlay)
    }

    if (!state.isPlaying) {
      audio.addEventListener('canplaythrough', audio.pause())
    }

    return () => {
      if (audio) {
        audio.pause() // to enable garbage collection
        audio.removeEventListener('canplaythrough', onPlay)
      }
    }
  }, [state.isPlaying, audioRef])

  const togglePlayPause = () => {
    controller.setIsPlaying(!state.isPlaying)
  }
  const playAnimationRef = useRef<number>()

  const repeat = useCallback(() => {
    const currentTime = audioRef?.current?.currentTime
    setTimeProgress(currentTime)
    if(progressBarRef.current !== null) {
      progressBarRef.current.value = currentTime
      progressBarRef?.current.style.setProperty(
        '--range-progress',
        `${(progressBarRef?.current.value / audioRef.current.duration) * 100}%`
      )
    }

    playAnimationRef.current = requestAnimationFrame(repeat)
    return
  }, [audioRef, audioRef, progressBarRef, setTimeProgress])

  useEffect(() => {
    if (state.isPlaying) {
      audioRef.current.play()
    } else {
      audioRef.current.pause()
    }
    playAnimationRef.current = requestAnimationFrame(repeat)
  }, [state.isPlaying, audioRef])

  const skipForward = () => {
    audioRef.current.currentTime += 15
  }

  const skipBackward = () => {
    audioRef.current.currentTime -= 15
  }

  return (
    <div className="controls-wrapper">
      <div className="controls">
        <button onClick={skipBackward} disabled={state.loadingSrc}>
          <assets.BtnPrevIcon />
        </button>
        <button onClick={togglePlayPause} disabled={state.loadingSrc}>
          {state.isPlaying ? <assets.BtnPlayIcon /> : <assets.BtnPauseIcon />}
        </button>
        <button onClick={skipForward} disabled={state.loadingSrc}>
          <assets.BtnNextIcon />
        </button>
      </div>
    </div>
  )
}

export default Controls
