import { ZIO } from '@mxt/zio'
import {
  AddLackOfPayeeService,
  AppContext,
  GeneralService,
  PaymentType,
  StorageBlob,
  TransactionType,
  useMobile
} from '@pulseops/common'
import { ClaimStackParamList } from '@pulseops/submission'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import { pipe } from 'fp-ts/lib/function'
import moment from 'moment'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ActivityIndicator, SafeAreaView, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { DocumentOption } from '../common'
import { ClaimInfoHeader } from '../common/claim-header'
import * as AddLackOfPayeeForm from './add-lack-of-payee-form'
import { ClaimInfo } from './claim-info'
import { PaymentMethod } from './payee/payment-method'
import { RequestType } from './request-type'

type Props = StackScreenProps<ClaimStackParamList, 'AddLackOfPayeeScreen'>

export const AddLackOfPayeeScreen = (props: Props) => {
  const defaultFormValues: AddLackOfPayeeForm.Form = {
    caseId: null,
    suspendType: null,
    claimNumber: '',
    paymentMode: null,
    payeeIsNotPolicyOwner: false,
    bankIdNumber: '',
    bankAccountName: '',
    bankAccountNumber: '',
    address: '',
    bank: null,
    bankBranch: null,
    dob: 'null',
    foreignAddress: '',
    gaOffice: null,
    gender: null,
    issuedBy: '',
    issuedDate: null,
    nationality: null,
    nationality2: null,
    occupation: null,
    payeeId: '',
    payeeName: '',
    relationshipWithPO: null,
    residenceCountry: null,
    documentType: null
  }
  const form = useForm<AddLackOfPayeeForm.Form>({ mode: 'all', defaultValues: defaultFormValues })
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const { isWide } = useMobile()
  const { t } = useTranslation()

  const [selectedDocumentType, setSelectedDocumentType] = React.useState<DocumentOption[]>([])

  const [defaultPayeeIsNotPO, setDefaultPayeeIsNotPO] = React.useState<{
    payeeNotPO: boolean
    bankAccountName: string
    bankIdNumber: string
    dob: Date | null
  }>({
    payeeNotPO: false,
    bankAccountName: '',
    bankIdNumber: '',
    dob: null
  })

  const isFocused = useIsFocused()
  const navigation = useNavigation()
  const { changeBreadcrumb, showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigation.navigate('HomeScreen')
          }
        },
        {
          title: t('Submission:EForm'),
          navigate: () => navigation.navigate('StaffSubmissionStack', { screen: 'StaffSubmissionScreen' })
        },
        {
          title: t('common:ClaimServices'),
          navigate: () =>
            props.navigation.navigate('ClaimListScreen', {
              policyNum: props.route.params?.policyNum,
              policyOwner: props.route.params?.policyOwner,
              officeCode: props.route.params?.officeCode,
              officeName: props.route.params?.officeName,
              clientNumber: props.route.params.clientNumber
            })
        },
        { title: t('claim:AddLackOfPayee'), navigate: null }
      ])
    }
  }, [isFocused])

  const renderFooter = () => {
    return (
      <View style={[styles.footer, isWide ? { marginRight: 19 } : {}]}>
        <TouchableOpacity style={styles.button} onPress={() => form.reset(defaultFormValues)}>
          <Text style={styles.textButton}>{t('claim:ClearAll')}</Text>
        </TouchableOpacity>

        <TouchableOpacity style={styles.button} onPress={onSubmit}>
          <Text style={styles.textButton}>
            {t('claim:SendRequest')} {isLoading && <ActivityIndicator color="red" />}
          </Text>
        </TouchableOpacity>
      </View>
    )
  }
  const onSubmit = form.handleSubmit((values: AddLackOfPayeeForm.Form) => {
    const author = !!localStorage.getItem('AuthState')
      ? JSON.parse(localStorage?.getItem('AuthState') || '')?.authData?.username
      : ''
    const policyNumber = props.route.params.policyNum
    showGlobalLoading(true)
    setIsLoading(true)
    pipe(
      ZIO.collectAll(
        selectedDocumentType.map((d) =>
          GeneralService.getMetaData(TransactionType.ADD_LACK_OF_PAYEE_INFO, d.docTypeCode)
        )
      ),
      ZIO.flatMap((metaDataResArray) => {
        const officeCode = props.route.params?.officeCode
        const files: StorageBlob.FileContent[] = selectedDocumentType.reduce(
          (acc: StorageBlob.FileContent[], curr, i) => {
            const metaDataRes = metaDataResArray[i]
            const metaDataRaw: StorageBlob.MetaDataUpload = {
              type: metaDataRes.data.transactionType,
              doctype: metaDataRes.data.doctypeEn,
              class: metaDataRes.data.classFilenet,
              docid: metaDataRes.data.docID,
              maindoc: metaDataRes.data.mainDoc,
              subdoc: metaDataRes.data.subDoc,
              polnum: props.route.params.policyNum,
              batchno: !!officeCode ? metaDataRes.data.batchNo + '-' + officeCode : metaDataRes.data.batchNo
            }
            return acc.concat(
              curr.files.map((f) => ({
                file: f.file,
                metaData: metaDataRaw
              }))
            )
          },
          []
        )
        return pipe(StorageBlob.uploadToSubmit('CL', policyNumber)(files))
      }),
      ZIO.tap((file) => {
        const {
          caseId,
          suspendType,
          paymentMode,
          dob,
          gender,
          occupation,
          relationshipWithPO,
          nationality,
          nationality2,
          address,
          bank,
          bankAccountName,
          bankAccountNumber,
          bankBranch,
          bankIdNumber,
          foreignAddress,
          gaOffice,
          issuedBy,
          issuedDate,
          payeeId,
          payeeIsNotPolicyOwner,
          payeeName,
          residenceCountry
        } = values

        let paymentInfo: AddLackOfPayeeService.Payment = {
          paymentMethod: paymentMode?.value || '',
          dob: moment(dob).format('YYYY/MM/DD') || '',
          gender: gender?.value || '',
          occupation: occupation?.value || '',
          relationshipWithPO: relationshipWithPO?.value || '',
          nationality: nationality?.value || '',
          nationality2: nationality2?.value || '',
          residenceCountry: residenceCountry?.value || '',
          address,
          foreignAddress,
          isPayeeNotPO: payeeIsNotPolicyOwner
        }
        switch (paymentMode?.value) {
          case PaymentType.BANKTRANSFER:
            paymentInfo.bankTransfer = {
              accountName: bankAccountName,
              idNumber: bankIdNumber,
              bank: bank?.value || '',
              bankName: bank?.label || '',
              branch: bankBranch?.value || '',
              branchName: bankBranch?.label || '',
              accountNumber: bankAccountNumber
            }
            break
          case PaymentType.PAID_AT_BANK:
            paymentInfo.payAtBank = {
              payeeName,
              idNumber: payeeId,
              issuedDate: moment(issuedDate).format('DD/MM/YYYY'),
              issuedBy,
              bank: bank?.value || '',
              bankName: bank?.label || '',
              branch: bankBranch?.value || '',
              branchName: bankBranch?.label || ''
            }
            break
          case PaymentType.CASH_AT_COUNTER:
            paymentInfo.payAtCounter = {
              gaOffice: gaOffice?.value || '',
              payeeName: payeeName,
              idNumber: payeeId
            }
            break

          default:
            break
        }

        const data = {
          lackOfPayeeDTOS: [
            {
              transactionType: TransactionType.ADD_LACK_OF_PAYEE_INFO,
              suspendCode: suspendType?.value || '',
              caseId: caseId?.value || '',
              author: author,
              payment: paymentInfo,
              attachments: file
            }
          ]
        }
        return pipe(
          AddLackOfPayeeService.submit(policyNumber, data, true),
          ZIO.tap((response) => {
            showToast(t('message:RequestSendSuccessfully'), 'success')
            setIsLoading(false)
            showGlobalLoading(false)
            props.navigation.navigate('ClaimListScreen', {
              policyNum: props.route.params.policyNum,
              policyOwner: props.route.params.policyOwner,
              officeCode: props.route.params.officeCode,
              officeName: props.route.params.officeName,
              clientNumber: props.route.params.clientNumber
            })
            return ZIO.succeed(response)
          })
        )
      }),
      ZIO.catchAll((e) =>
        ZIO.effectTotal(() => {
          setIsLoading(false)
          showGlobalLoading(false)
          showToast(t('message:MS050001'), 'error')
          alert(JSON.stringify(e))
        })
      ),
      ZIO.unsafeRun({})
    )
  })

  return (
    <SafeAreaView style={{ flex: 1, width: '100%' }}>
      <ScrollView>
        <View style={{ flex: 1, width: '100%', marginBottom: 150 }}>
          <ClaimInfoHeader
            menus={{
              policyNum: props.route.params?.policyNum,
              policyOwner: props.route.params?.policyOwner,
              officeName: props.route.params?.officeName,
              clientNumber: props.route.params?.clientNumber ?? ''
            }}
          />
          <View style={[{ marginHorizontal: 'auto', width: '100%', paddingHorizontal: 15 }]}>
            <RequestType />
          </View>
          <View style={[{ marginHorizontal: 'auto', width: '100%', paddingHorizontal: 15 }]}>
            <ClaimInfo
              form={form}
              policyNum={props.route.params?.policyNum}
              setDefaultPayeeIsNotPO={setDefaultPayeeIsNotPO}
            />
          </View>
          <View style={[{ marginHorizontal: 'auto', width: '100%', paddingHorizontal: 15 }]}>
            <PaymentMethod
              policyNum={props.route.params?.policyNum}
              officeCode={props.route.params?.officeCode}
              form={form}
              defaultPayeeIsNotPO={defaultPayeeIsNotPO}
              setSelectedDocumentType={setSelectedDocumentType}
              selectedDocumentType={selectedDocumentType}
            />
          </View>
        </View>
      </ScrollView>
      {renderFooter()}
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 68,
    marginTop: 15,
    marginLeft: 15,
    marginRight: 15,
    borderRadius: 8,
    backgroundColor: '#FFFFFF'
  },
  icon: {
    height: 22,
    width: 17
  },
  // Tab ++
  tabLabel: {
    paddingTop: 8,
    paddingBottom: 8
  },
  tabLabelActive: {
    color: 'red',
    fontWeight: 'bold'
  },
  tab: {
    minWidth: 260,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#E7E7E7',
    borderTopLeftRadius: 14,
    borderTopRightRadius: 14
  },
  tabActive: {
    minWidth: 260,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    borderTopLeftRadius: 14,
    borderTopRightRadius: 14
  },
  // Tab --
  policyInfoContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row'
  },
  policyInfoSectionMobile: {
    flexDirection: 'row',
    width: '100%',
    paddingLeft: 10
  },
  policyInfoSectionWide: {
    flexDirection: 'row',
    flex: 1 / 3,
    width: 'auto',
    paddingLeft: 33
  },
  bold: {
    fontWeight: 'bold'
  },
  sectionTitle: {
    fontSize: 15,
    fontWeight: '800'
  },

  footer: {
    flex: 1,
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    flexDirection: 'row',
    backgroundColor: '#f9f9f9',
    height: 70,
    shadowOffset: {
      width: 0,
      height: -5
    },
    shadowColor: '#e6e6e6',
    shadowOpacity: 15,
    shadowRadius: 24,
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  textButton: { textAlign: 'center', color: '#ED1B2E', fontWeight: 'bold' },
  textButtonRed: { textAlign: 'center', color: '#FFF', fontWeight: 'bold' },
  btnTextDisabled: { textAlign: 'center', color: '#B0B0B0', fontWeight: 'bold' },
  button: {
    marginLeft: 20,
    marginVertical: 20,
    justifyContent: 'center',
    backgroundColor: '#fff',
    borderRadius: 100,
    borderWidth: 1,
    borderColor: '#ED1B2E',
    minWidth: 121,
    height: 35,
    paddingHorizontal: 30
  },
  btnRed: {
    marginLeft: 20,
    marginVertical: 20,
    justifyContent: 'center',
    alignContent: 'center',
    backgroundColor: '#ED1B2E',
    borderRadius: 100,
    borderWidth: 1,
    borderColor: '#ED1B2E',
    minWidth: 121,
    height: 35,
    paddingHorizontal: 30
  },
  btnDisabled: {
    marginLeft: 20,
    marginVertical: 20,
    justifyContent: 'center',
    backgroundColor: '#FFF',
    borderRadius: 100,
    borderWidth: 1,
    borderColor: '#B0B0B0',
    minWidth: 121,
    height: 35,
    paddingHorizontal: 30
  }
})
