import { SubmissionService } from '../SubmissionService'

export namespace Inheritance {
  export interface ClientPolicy {
    policyNum: string
    name?: string
  }

  export interface ClientExtra extends SubmissionService.ClientQuery {
    role: 'LA' | 'BEN'
    relationshipCode: string
  }

  export interface CustomerData {
    customerId: string
    idType: string
    idNum: string
    issuedAt: string
    name: string
    surName: string
    fatca: boolean
    dob: string
    dod: string
    gender: string
    nationality: string
    nationality2: string
    mobilePhoneCode: string
    mobilePhoneNumber: string
    email: string
    role: string
    occupation: string
    address: string
    countryCode: string
    city: string
    district: string
    ward: string
    foreignAddress: string
    foreignCountry: string
    relationShipWithMainLifeAssured: string
    relationShipWithMainLifeAssuredLA2: string
    relationShipWithMainLifeAssuredLA3: string
    relationShipWithMainLifeAssuredLA4: string
  }

  export interface Detail {
    customerId: string
    policyHasBen: boolean
    laList: {
      clientNumberOfLA: string
      life: string
      name: string
    }[]
    customerData: CustomerData[]
    sumAssured: number
    clientPolicyList: Array<ClientPolicy>
  }

  export interface Submit {
    clientId: string
    policyNo: string
    clientNo: string
    idNumber: string
    relationship1: string
    surname: string
    givenName: string
    birthDate: string
    sex: string
    nationality: string
    telCode: string
    mobilePhone: string
    email: string
    isFatca: 'true' | 'false'
    street: string
    country: string
    city: string
    district: string
    ward: string
    occupation: string
    smsIndicator: string
    emailIndicator: string
    foreignAdd: string
    foreignCountry: string
    nationality2: string
    foreignCountry: string
    clientNo2?: string
    relationship2?: string
    clientNo3?: string
    relationship3?: string
    clientNo4?: string
    relationship4?: string

    attributesExt?: ExtraInfo

    relativeDocuments: InheritanceDocumentFile[]
    inheritanceDocuments: InheritanceDocumentFile[]

    deathDate: string
    appliedPolicys: { appliedPolicy: string }[]
  }

  export interface ExtraInfo {
    EMAILS_DUP: Array<{
      clientId: string
      relationshipType: string
    }>
    PHONES_DUP: Array<{
      clientId: string
      relationshipType: string
    }>
    LOCATION_CODE: string
    taxResidencyCountry?: string
  }

  export interface InheritanceDocumentFile {
    name: string
    url: string
  }
}
