import React, { useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import Select from './Select'
import { TextField } from '@material-ui/core'

const transOptions = [
  {
    label: 'The Shawshank Redemption',
    value: '1'
  },
  {
    label: 'The Godfather',
    value: '2'
  }
]

const userGroupOptions = [
  {
    label: 'Group 1',
    value: '1'
  },
  {
    label: 'Group 2',
    value: '2'
  }
]

const userLevelOptions = [
  {
    label: 'Level 1',
    value: '1'
  },
  {
    label: 'Level 2',
    value: '2'
  }
]

interface IFilterBox {
  defaultValues?: any
  onChange?: (data: any) => void
}

const FilterBox: React.FC<IFilterBox> = (props) => {
  const { defaultValues, onChange } = props
  const { t } = useTranslation()
  const [filters, setFilters] = useState<any>(
    defaultValues ?? {
      transactionType: [],
      assignee: '',
      userGroup: [],
      userLevel: []
    }
  )

  useEffect(() => {
    onChange?.(filters)
  }, [filters])

  return (
    <View style={styles.container}>
      <View style={styles.item}>
        <Select
          options={transOptions}
          placeholder="Find Transaction"
          inputLabel="Transaction Type"
          onChange={(data) => setFilters({ ...filters, transactionType: data })}
          getOptionLabel={(option) => option.label}
        />
      </View>
      <View style={styles.item}>
        <TextField
          style={{ backgroundColor: '#fff' }}
          variant="outlined"
          label={'Assignee'}
          placeholder={'Find assignee'}
          onChange={(e) => setFilters({ ...filters, assignee: e.target.value })}
        />
      </View>
      <View style={styles.item}>
        <Select
          options={userGroupOptions}
          placeholder="Find Group"
          inputLabel="User group"
          onChange={(data) => setFilters({ ...filters, userGroup: data })}
          getOptionLabel={(option) => option.label}
        />
      </View>
      <View style={styles.item}>
        <Select
          options={userLevelOptions}
          placeholder="Find Level"
          inputLabel="User level"
          onChange={(data) => setFilters({ ...filters, userLevel: data })}
          getOptionLabel={(option) => option.label}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row'
  },
  item: {
    marginLeft: 10,
    marginRight: 10,
    minWidth: 200
  }
})

export default FilterBox
