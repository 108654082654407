import {
  AppContext,
  CancelRider,
  CancelRiderService,
  Checkbox,
  ErrorHandling,
  formatNumberWithComma,
  Panel,
  RoundedButton,
  Title
} from '@pulseops/common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, SafeAreaView, Text, StyleSheet } from 'react-native'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { Controller, FieldArrayWithId, useFieldArray, useForm, UseFormReturn } from 'react-hook-form'
import { CancelRiderFormData } from '../../cancel-rider'
import { useLoading } from '@mxt/zio-react'
// import { useIsFocused } from '@react-navigation/native'
import { Error } from '@pulseops/submission/common'
import moment from 'moment'
import { MajorCombinedForm } from '../major-combined-form'
import { useIsFocused } from '@react-navigation/native'
interface Props {
  policyNum: string
  isConfirmed: boolean
  validateBeforeContinue: ({ validateCancelRider }: { validateCancelRider: () => Promise<boolean> }) => void
  form: UseFormReturn<MajorCombinedForm.MajorCombined, object>
}

export const CancelRiderComponent: React.FC<Props> = ({ policyNum, isConfirmed, form, validateBeforeContinue }) => {
  const { t, i18n } = useTranslation()

  // const isFocused = useIsFocused()
  const [isLoading, bindLoader] = useLoading(false)
  const { showGlobalLoading, showToast } = React.useContext(AppContext.AppContextInstance)
  const [detailData, setDetailData] = React.useState<CancelRider.DetailCancelRiderInfo>()
  const [estimatedPremiumFlag, setEstimatedPremiumFlag] = React.useState<boolean>(false)
  const [errorMsg, setErrorMsg] = React.useState<string>('')
  const isFocused = useIsFocused()

  const cancelRiderFormArray = useFieldArray({
    control: form.control,
    name: 'cancelRider.LARiderForm'
  })

  React.useEffect(() => {
    if (isFocused) {
      form.setValue('cancelRider', {
        cancelAllRider: false,
        LARiderForm: [],
        reEstimatedInstallPremium: 0,
        totalPremium: 0
      })
    }
  }, [isFocused])

  React.useEffect(() => {
    cancelRiderFormArray.remove()
    pipe(
      CancelRiderService.getDetail(policyNum),
      ZIO.map((responseData) => {
        setDetailData(responseData)
        form.setValue('cancelRider.totalPremium', responseData.totalPremium)
        let LARiderFormArray: Array<CancelRiderFormData.RiderForm> = []
        const LARiderItemList = responseData.laRiderInfo
        LARiderItemList.map((LAItem) => {
          LAItem.riders.map((rider) => {
            const riderInfoItem = responseData.riderNameList.find((x) => x.code === rider.productCode)
            rider.productName = getRiderName(riderInfoItem, i18n.language)
            LARiderFormArray.push({
              riderCode: rider.productCode,
              riderName: rider.productName,
              SelectRowForRider: false,
              riskCommDate: rider.riskCommDate,
              riskCessDate: rider.riskCessDate,
              sumAssured: rider.sumAssured,
              installmentPrem: rider.installmentPrem,
              newInstallmentPremium: 0,
              lifeNo: rider.lifeNo,
              coverageNo: rider.coverageNo,
              riderNo: rider.riderNo,
              riderStatus: rider.riderStatus
            })
          })

          cancelRiderFormArray.append({
            selectAllForLA: false,
            LAName: LAItem.clientName,
            lifeAssured: LAItem.lifeAssured,
            cancelRiderForm: LARiderFormArray
          })
          // LARiderFormArray.remove()
          LARiderFormArray = []
        })
      }),
      bindLoader,
      ErrorHandling.run()
    )
  }, [i18n.language])

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  validateBeforeContinue({
    validateCancelRider: async () => {
      if (validateSubmitData()) return true
      else return false
    }
  })

  const getRiderName = (item: any, isEN = 'en') => {
    return !!item ? (isEN === 'en' ? item.name : item.nameVi) : ''
  }

  const convertDateStringForSubmit = (strDate: string) => {
    return moment(strDate).format('YYYY-MM-DD')
  }

  const convertNumberToCurrencyFormat = (value: number) => {
    return formatNumberWithComma(value)
  }

  const convertStringToDateString = (strDate: string) => {
    return moment(strDate).format('DD/MM/YYYY')
  }

  const getTotalSelectedPremium = () => {
    let totalPremium: number = 0
    cancelRiderFormArray.fields.forEach((fieldItem) => {
      const riderTotal = fieldItem.cancelRiderForm.reduce((total, riderItem) => {
        return riderItem.SelectRowForRider ? total + riderItem.installmentPrem : total
      }, 0)
      totalPremium += riderTotal
    })
    console.log('totalSelectedInstallPremium: ' + totalPremium)
    return totalPremium
  }

  // only calculate one time when user click first time
  const calculateEstimatedInstallPremium = () => {
    let totalSelectedInstallPremium: number = getTotalSelectedPremium()
    setEstimatedPremiumFlag(true)
    const estimatedPremium = (detailData?.totalPremium ?? 0) - totalSelectedInstallPremium
    form.setValue('cancelRider.reEstimatedInstallPremium', estimatedPremium)
    if (estimatedPremium < (detailData?.minPremium ?? 0)) {
      // showToast(t(`message:MS050014`), 'error')
      showToast(t(`message:MS050054`, { min: formatNumberWithComma(detailData?.minPremium ?? 0) }), 'error')
      form.setValue('cancelRider.reEstimatedInstallPremium', 0)
    } else if (estimatedPremium <= 0) {
      showToast(t(`message:MS050034`), 'error')
      form.setValue('cancelRider.reEstimatedInstallPremium', 0)
    }
  }

  //calculate again when user checked or unchecked rider
  const reCalculateEstimatedInstallPremium = (riderPremium: number, selectedRow: boolean) => {
    let reEstimatedInstallPremiumVal: number = 0
    let totalSelectedInstallPremium: number = 0
    if (estimatedPremiumFlag) {
      const ReEstimatePremium = form.watch('cancelRider.reEstimatedInstallPremium')
      totalSelectedInstallPremium = Number(detailData?.totalPremium ?? 0) - ReEstimatePremium
      if (selectedRow) {
        reEstimatedInstallPremiumVal =
          Number(detailData?.totalPremium ?? 0) - (totalSelectedInstallPremium + riderPremium)
        form.setValue('cancelRider.reEstimatedInstallPremium', reEstimatedInstallPremiumVal)
      } else {
        reEstimatedInstallPremiumVal =
          Number(detailData?.totalPremium ?? 0) - (totalSelectedInstallPremium - riderPremium)
        form.setValue('cancelRider.reEstimatedInstallPremium', reEstimatedInstallPremiumVal)
      }
      if (reEstimatedInstallPremiumVal < Number(detailData?.minPremium)) {
        // showToast(t(`message:MS050014`), 'error')
        showToast(t(`message:MS050054`, { min: formatNumberWithComma(detailData?.minPremium ?? 0) }), 'error')
        form.setValue('cancelRider.reEstimatedInstallPremium', 0)
      } else if (reEstimatedInstallPremiumVal <= 0) {
        showToast(t(`message:MS050034`), 'error')
        form.setValue('cancelRider.reEstimatedInstallPremium', 0)
      }
    }
  }

  const changeEventForLA = (
    checkedAllLA: boolean,
    LAIndex: number,
    fieldItem: FieldArrayWithId<CancelRiderFormData.CancelAllRiderForm, 'LARiderForm', 'id'>
  ) => {
    if (checkedAllLA) {
      fieldItem.cancelRiderForm.forEach((subField, subIndex) => {
        const selectedElement = subField.SelectRowForRider
        if (!selectedElement) {
          form.setValue(`cancelRider.LARiderForm.${LAIndex}.cancelRiderForm.${subIndex}.SelectRowForRider`, true)
          // reCalculateEstimatedInstallPremium(subField.installmentPrem, true)
          changeEventForRider(subField, true)
        }
      })
    } else {
      fieldItem.cancelRiderForm.forEach((subField, subIndex) => {
        const selectedElement = subField.SelectRowForRider
        if (selectedElement) {
          form.setValue(`cancelRider.LARiderForm.${LAIndex}.cancelRiderForm.${subIndex}.SelectRowForRider`, false)
          // reCalculateEstimatedInstallPremium(subField.installmentPrem, false)
          changeEventForRider(subField, false)
        }
      })
    }
  }

  const changeEventForRider = (riderInfo: CancelRiderFormData.RiderForm, isChecked: boolean) => {
    reCalculateEstimatedInstallPremium(riderInfo.installmentPrem, isChecked)
  }

  const onCancelAllRiderCheckedEvent = (value: boolean) => {
    cancelRiderFormArray.fields.map((fieldItem, index) => {
      form.setValue(`cancelRider.LARiderForm.${index}.selectAllForLA`, value)
      changeEventForLA(value, index, fieldItem)
    })
  }

  const isCheckedValidRider = () => {
    let invalidFlag: boolean = false
    cancelRiderFormArray.fields.forEach((fieldItem) => {
      fieldItem.cancelRiderForm.forEach((riderItem) => {
        if (riderItem.SelectRowForRider) {
          invalidFlag = true
        }
      })
    })
    return invalidFlag
  }

  const validateSubmitData = () => {
    const ReEstimatePremium = form.watch('cancelRider.reEstimatedInstallPremium')
    if (!isCheckedValidRider()) {
      setErrorMsg(t('message:MS050234'))
      return false
    } else if (ReEstimatePremium < Number(detailData?.minPremium ?? 0)) {
      // showToast(t(`message:MS050014`), 'error')
      showToast(t(`message:MS050054`, { min: formatNumberWithComma(detailData?.minPremium ?? 0) }), 'error')
      return false
    } else if (ReEstimatePremium <= 0) {
      showToast(t(`message:MS050034`), 'error')
      return false
    } else {
      setErrorMsg('')
    }
    return true
  }

  const mappingData = (): CancelRider.SubmitCancelRiderData => {
    let mappingCancelRiderData: CancelRider.SubmitLARider[] = cancelRiderFormArray.fields.map((fieldItem) => {
      const riderListForEachLA: CancelRider.SubmitedRiderInfo[] = fieldItem.cancelRiderForm
        .filter((x) => x.SelectRowForRider)
        .map((subItem) => {
          const riskCommDate = convertDateStringForSubmit(subItem.riskCommDate)
          const riskCessDate = convertDateStringForSubmit(subItem.riskCessDate)
          const newInstallmentPremium = Number(detailData?.totalPremium ?? '') - subItem.installmentPrem
          return {
            productCode: subItem.riderCode,
            riskCommDate: riskCommDate,
            riskCessDate: riskCessDate,
            sumAssured: subItem.sumAssured,
            installmentPremium: subItem.installmentPrem,
            newInstallmentPremium: newInstallmentPremium,
            lifeNo: subItem.lifeNo,
            coverageNo: subItem.coverageNo,
            riderNo: subItem.riderNo,
            status: subItem.riderStatus
          }
        })
      return {
        lifeAssured: fieldItem.lifeAssured,
        riders: riderListForEachLA
      }
    })
    return {
      lifeAssureds: mappingCancelRiderData.filter((x) => !!x && x.riders.length > 0)
    }
  }

  return (
    <SafeAreaView style={{ flex: 1, marginTop: 15 }}>
      <View style={styles.sectionContent}>
        <Title title={t('CancelRider:RIDERLIST')}></Title>
        <Controller
          control={form.control}
          name="cancelRider.cancelAllRider"
          render={({ field: { onChange, onBlur, value } }) => (
            <Checkbox
              title={t('CancelRider:CancelAllRider')}
              value={value}
              onChange={(val) => {
                onChange(val)
                onCancelAllRiderCheckedEvent(val)
              }}
              onBlur={onBlur}
              // checkBoxStyle={{marginBottom:20}}
              disabled={isConfirmed}
            />
          )}
        />

        {cancelRiderFormArray.fields.map((fieldItem, index) => (
          <Panel title={fieldItem.LAName} key={fieldItem.id} isNoPadding={true} isTransactionToggleExpand={true}>
            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', paddingRight: 20 }}>
              <Text style={{ fontStyle: 'italic' }}>{t('requestInfo:Currency')}: VND</Text>
            </View>
            {/* rider table */}
            <View style={{ flex: 1 }}>
              {/* table header */}
              <View>
                {/* table row */}
                <View style={{ flexDirection: 'row' }}>
                  <View style={{ flex: 5, padding: 10 }}>
                    <Controller
                      control={form.control}
                      name={`cancelRider.LARiderForm.${index}.selectAllForLA`}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Checkbox
                          onChange={(checked) => {
                            onChange(checked)
                            changeEventForLA(checked, index, fieldItem)
                          }}
                          onBlur={onBlur}
                          value={value}
                          disabled={isConfirmed}
                          checkBoxStyle={styles.tableCheckBox}
                        />
                      )}
                    />
                  </View>
                  <View style={{ flex: 25, padding: 10 }}>
                    <Text style={styles.tableHeaderText}>{t('requestInfo:RiderName')}</Text>
                  </View>
                  <View style={{ flex: 20, padding: 10 }}>
                    <Text style={styles.tableHeaderText}>{t('CancelRider:RiskCommencementDate')}</Text>
                  </View>
                  <View style={{ flex: 15, padding: 10 }}>
                    <Text style={styles.tableHeaderText}>{t('requestInfo:RiskCessationDate')}</Text>
                  </View>
                  <View style={{ flex: 15, padding: 10 }}>
                    <Text style={styles.tableHeaderText}>{t('requestInfo:SumAssured')}</Text>
                  </View>
                  <View style={{ flex: 20, padding: 10 }}>
                    <Text style={styles.tableHeaderText}>{t('CancelRider:InstallmentPremium')}</Text>
                  </View>
                </View>
              </View>
              {/* table body */}
              <View style={{ backgroundColor: 'white' }}>
                {fieldItem.cancelRiderForm.map((riderInfo, subIndex) => {
                  return (
                    <View
                      key={subIndex}
                      style={{ flexDirection: 'row', borderTopWidth: 1, borderTopColor: 'rgb(238, 238, 238)' }}
                    >
                      <View style={{ flex: 5, padding: 10 }}>
                        <Controller
                          control={form.control}
                          name={`cancelRider.LARiderForm.${index}.cancelRiderForm.${subIndex}.SelectRowForRider`}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <Checkbox
                              onChange={(riderCheck) => {
                                onChange(riderCheck)
                                changeEventForRider(riderInfo, riderCheck)
                              }}
                              onBlur={onBlur}
                              value={value}
                              disabled={isConfirmed}
                              checkBoxStyle={styles.tableCheckBox}
                            />
                          )}
                        />
                      </View>
                      <View style={{ flex: 25, padding: 10 }}>
                        <Text>{riderInfo.riderName}</Text>
                      </View>
                      <View style={{ flex: 20, padding: 10 }}>
                        <Text>{convertStringToDateString(riderInfo.riskCommDate)}</Text>
                      </View>
                      <View style={{ flex: 15, padding: 10 }}>
                        <Text>{convertStringToDateString(riderInfo.riskCessDate)}</Text>
                      </View>
                      <View style={{ flex: 15, padding: 10 }}>
                        <Text>{convertNumberToCurrencyFormat(riderInfo.sumAssured)}</Text>
                      </View>
                      <View style={{ flex: 20, padding: 10 }}>
                        <Text>{convertNumberToCurrencyFormat(riderInfo.installmentPrem)}</Text>
                      </View>
                    </View>
                  )
                })}
              </View>
            </View>
          </Panel>
        ))}
        <View>
          {!isConfirmed && (
            <RoundedButton
              showBorder={false}
              filled={true}
              activeOpacity={0.7}
              text={t('CancelRider:CalculatePremium')}
              textStyle={styles.btnText}
              style={{ height: 39, paddingHorizontal: 20 }}
              textColorDisable="#FFFFFF"
              textColorEnable="#FFFFFF"
              bgColorDisable={'#B0B0B0'}
              bgColorEnable={'#ED1B2E'}
              borderColorDisable={'#B0B0B0'}
              borderColorEnable={'#ED1B2E'}
              onPress={calculateEstimatedInstallPremium}
            />
          )}

          <View style={styles.estimatedPremiumBtn}>
            <Text>{t('CancelRider:EstimatedNewInstallmentPremium')}</Text>
            {form.watch('cancelRider.reEstimatedInstallPremium') > 0 && (
              <Text style={styles.red_line}>
                {convertNumberToCurrencyFormat(form.watch('cancelRider.reEstimatedInstallPremium'))} VND
              </Text>
            )}
          </View>
          <View style={styles.estimatedPremiumBtn}>{!!errorMsg && <Error message={errorMsg} />}</View>
        </View>
      </View>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  btnText: {
    fontSize: 14
  },
  red_line: {
    color: '#ed1c2e',
    fontSize: 15,
    fontWeight: '700'
  },
  estimatedPremiumBtn: {
    marginTop: 15
  },
  tableHeaderText: {
    fontSize: 15,
    fontWeight: '700'
  },
  TableColText: {
    fontSize: 15
  },
  tableCheckBox: {
    padding: 0,
    marginTop: 0,
    paddingLeft: 10
  },
  sectionContent: {
    padding: 20,
    backgroundColor: '#fafafa',
    border: '1px solid #d3dce6',
    boxSizing: 'border-box',
    borderRadius: 8
  }
})
