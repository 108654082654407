import { TransactionType } from '../TransactionType'
import * as api from '../task-detail-api'
import * as D from 'date-fns'
import { CashLessService } from '@pulseops/common'
import moment from 'moment'
import _ from 'lodash'
export interface Receip {
  action: string
  newDescription?: string | null
  transactionCode?: string | null
  receiptNo?: string | null
  lastUpdatedBy?: string | null
  receiptDate?: string | null
  paymentType?: string | null
  totalAmount?: number | null
  policyNumber?: string | null
  proposalNumber?: string | null
  transactions: {
    amount: number
    newDescription?: string | null
    subAccountCode: string
    subAccountType: string
  }[]
}

export interface VeriCashLess {
  tag:
    | TransactionType.CASHLESS_O1
    | TransactionType.CASHLESS_O2
    | TransactionType.CASHLESS_O3
    | TransactionType.CASHLESS_DK
    | TransactionType.CASHLESS_C9
    | TransactionType.CASHLESS_P2
    | TransactionType.CASHLESS_V2
    | TransactionType.CASHLESS_M2
    | TransactionType.CASHLESS_A9
    | TransactionType.CASHLESS_S6
    | TransactionType.CASHLESS_L3
    | TransactionType.CASHLESS_D7
    | TransactionType.CASHLESS_M3
    | TransactionType.CASHLESS_V4
    | TransactionType.CASHLESS_A0
    | TransactionType.CASHLESS_I4
    | TransactionType.CASHLESS_P9
    | TransactionType.CASHLESS_I2
    | TransactionType.CASHLESS_A7
    | TransactionType.CASHLESS_S1
    | TransactionType.CASHLESS_P3
    | TransactionType.CASHLESS_S2
    | TransactionType.CASHLESS_B3
    | TransactionType.CASHLESS_S3
    | TransactionType.CASHLESS_C3
    | TransactionType.CASHLESS_U3
    | TransactionType.CASHLESS_V3
    | TransactionType.CASHLESS_I3
    | TransactionType.CASHLESS_C7
    | TransactionType.CASHLESS_X1
    | TransactionType.CASHLESS_T3
    | TransactionType.CASHLESS_M9
    | TransactionType.CASHLESS_D6
    | TransactionType.CASHLESS_D8
    | TransactionType.CASHLESS_D9
    | TransactionType.CASHLESS_V5
  taskId: string
  businessKey: string
  processsInstanceId: string
  email: string
  channel: string
  bankCode: string
  id: string
  payerName: string
  paymentMethod: string
  phoneNumber: string
  policyNumber?: string
  policyNumberOld?: string
  policyOwner: string
  transactionDate: Date
  receiptDate?: Date
  bankTransferNo?: string

  /** Receipt Amount */
  amount: number
  paymentReason: string
  proposalNumber?: string
  referenceNumber?: string
  proposalNumberOld?: string
  priorProposal?: boolean // ưu tiên show field proposal nếu field này là true

  /** Phí định kì - renAmount */
  basicPremium: number

  /** Phí dư */
  amountLP: number

  productCode: string
  productType: string
  productName?: {
    en: string
    vi: string
  }
  remark?: string
  ao: number
  ai: number
  lo: number
  li: number

  /** Phí giảm */
  discount: number

  /** Raw data for update data perpose */
  rawData: api.CashLess

  //start date - premium date
  loanStarDate: string
  lastPremiumDate: string
  lastTransactionDate: string
  receipts: Receip[] | null | undefined
  journals: Receip[] | null | undefined
  noncash: Receip | null | undefined
  discountReceipts: Receip[] | null | undefined
  coreRemainingAmount: number
  coreRecipts: Receip[] | null | undefined
  coreJournals: Receip[] | null | undefined
  coreNonCash: Receip | null | undefined
  coreDiscounts: Receip[] | null | undefined
  cardType: string | undefined

  /** OPL date */
  minOplDate: Date | undefined

  /** APL date */
  minAplDate: Date | undefined

  //LPS, LPU, LNVP, LPE1
  LPSAmount: number
  LPUAmount: number
  LPE1Amount: number
  LNVPAmount: number

  // interestRate to calucualte Noncash amount L action
  interestRate: number

  policyStatus: string
  transactionStatus: string
  warningCode: string
  source: string
  stpFunction: string
  writeOffAction: string
  InsPremium: number
  rawCoreData: CashLessService.CashLessDetail
  suspendReason: string
  hasVoucher: boolean
  vouchers: {
    code: string
    amount: string
  }[]
}

export const CashLess = (
  detail: api.CashLess & api.TaskDetailBase, // get from detailMapper data function (libs/common/src/service/PulseOpsService.ts)
  cashlessDetail: CashLessService.CashLessDetail // get form CashlessService get detail function (libs/common/src/service/CashLessService.ts)
): VeriCashLess => {
  const { payload } = detail
  const { body } = payload
  const wrapCoreReceipt = cashlessDetail.coreReceips.map<Receip>((c) => {
    const totalAmount = c.transactions?.reduce<number>((pre, cur) => pre + cur.amount ?? 0, 0) ?? 0
    return {
      ...c,
      transactions: c.transactions ?? [],
      totalAmount
    }
  })

  const wrapCoreDiscounts = cashlessDetail.coreDiscounts.map<Receip>((c) => {
    const totalAmount = c.transactions?.reduce<number>((pre, cur) => pre + cur.amount ?? 0, 0) ?? 0
    return {
      ...c,
      transactions: c.transactions ?? [],
      totalAmount
    }
  })

  const wrapCoreJournal = cashlessDetail.coreJournals.map<Receip>((c) => {
    const totalAmount = c.transactions?.reduce<number>((pre, cur) => pre + cur.amount ?? 0, 0) ?? 0
    return {
      ...c,
      transactions: c.transactions ?? [],
      totalAmount
    }
  })

  const getPolicyNumOfProposalInfo = cashlessDetail.proposalInfo && cashlessDetail.proposalInfo.policyNum

  const wrapCoreNoncashByPolicy = cashlessDetail.coreNonCash.filter(
    (item) =>
      (body.policyNumber && item.policyNumber === body.policyNumber) ||
      (body.proposalNumber && getPolicyNumOfProposalInfo && item.policyNumber === getPolicyNumOfProposalInfo)
  )

  const wrapCoreNoncash =
    cashlessDetail.coreNonCash &&
    cashlessDetail.coreNonCash.length > 0 &&
    _.last(wrapCoreNoncashByPolicy) &&
    (_.last(wrapCoreNoncashByPolicy)?.action !== 'MSP_R_RPL' || _.isNull(_.last(wrapCoreNoncashByPolicy)?.receiptNo))
      ? {
          ..._.last(wrapCoreNoncashByPolicy),
          transactions: _.last(wrapCoreNoncashByPolicy)?.transactions ?? [],
          totalAmount:
            _.last(wrapCoreNoncashByPolicy)?.transactions?.reduce<number>((pre, cur) => pre + cur.amount ?? 0, 0) ?? 0
        }
      : undefined

  // const wrapCoreNoncash =
  //   cashlessDetail.coreNonCash && cashlessDetail.coreNonCash.length > 0
  //     ? {
  //         ...cashlessDetail.coreNonCash[0],
  //         transactions: cashlessDetail.coreNonCash[0].transactions ?? [],
  //         totalAmount:
  //           cashlessDetail.coreNonCash[0].transactions?.reduce<number>((pre, cur) => pre + cur.amount ?? 0, 0) ?? 0
  //       }
  //     : undefined

  const wrapNoncashPayloadByPolicy =
    _.last(wrapCoreNoncashByPolicy) &&
    body.nonCashReceipts &&
    (_.last(wrapCoreNoncashByPolicy)?.action !== 'MSP_R_RPL' || _.isNull(_.last(wrapCoreNoncashByPolicy)?.receiptNo))
      ? body.nonCashReceipts.find((item) => item.transactionCode === _.last(wrapCoreNoncashByPolicy)?.transactionCode)
      : body.nonCashReceipts && body.nonCashReceipts?.length > cashlessDetail.coreNonCash.length
      ? _.isEmpty(cashlessDetail.coreNonCash) && body.nonCashReceipts
        ? body.nonCashReceipts[0]
        : _.xorBy(cashlessDetail.coreNonCash, body.nonCashReceipts, 'transactionCode')[0]
      : undefined

  const wrapNonCashPayload = wrapNoncashPayloadByPolicy ? wrapNoncashPayloadByPolicy : undefined

  // interest rate date with default 0.0
  const interestRate = cashlessDetail.interestRates.reduce<number>((pre, cur) => {
    const isBetween = moment(moment(body.transactionDate).format('MM/DD/yyyy')).isBetween(cur.fromDate, cur.toDate,undefined,"[]")
    return isBetween ? cur.rate : pre
  }, 0.0)
  return {
    tag: detail.transactionType,
    taskId: detail.id,
    businessKey: detail.businessKey ?? '',
    processsInstanceId: detail.processInstanceId ?? '',
    payerName: body.payerName ?? '-',
    email: body.email,
    channel: body.channel,
    id: body.id || '',
    paymentMethod: body.paymentMethod,
    phoneNumber: body.phoneNumber,
    policyNumber: body.policyNumber ?? cashlessDetail.policyInfo?.policyNo ?? undefined,
    policyNumberOld: body.policyNumberOld ?? undefined,
    policyOwner: body.policyOwner,
    transactionDate: D.parseISO(body.transactionDate),
    receiptDate: body.receiptDate != null ? D.parseISO(body.receiptDate) : undefined,
    bankCode: body.bankCode,
    amount: body.amount ?? 0, // tổng phí
    proposalNumber: body.proposalNumber ?? detail.proposalNumber ?? undefined,
    paymentReason: body.paymentReason,
    referenceNumber: body.referenceNumber ?? '-',
    bankTransferNo: detail.pendingNum ?? undefined,
    //amount LPS-LPU
    amountLP: cashlessDetail.totalAmount, // Phí dư
    basicPremium: cashlessDetail.basicPremium, // phí định kì

    // policyInfo
    productCode: cashlessDetail.policyInfo?.productCode ?? cashlessDetail.proposalInfo?.contractTypeCode ?? '',
    productType: cashlessDetail.policyInfo?.productType ?? '',
    productName: cashlessDetail.contractNameRaw,

    ao: cashlessDetail.balanceAPL,
    ai: cashlessDetail.interestAPL,
    lo: cashlessDetail.balanceOPL,
    li: cashlessDetail.interestOPL,
    policyStatus: cashlessDetail.policyInfo?.status ?? '',
    discount: cashlessDetail.discountAmount, // phí giảm

    rawData: detail,
    rawCoreData: cashlessDetail,

    loanStarDate: cashlessDetail.loanStarDate ?? '-',
    lastPremiumDate: cashlessDetail.lastPremiumDate ?? '-',
    lastTransactionDate: cashlessDetail.lastTransactionDate ?? '-',
    receipts: detail.payload.body.receipts ?? undefined,
    discountReceipts: detail.payload.body.discountReceipts ?? undefined,
    journals: detail.payload.body.journalReceipts ?? undefined,
    noncash: wrapNonCashPayload ?? undefined,
    coreRecipts: wrapCoreReceipt,
    coreJournals: wrapCoreJournal,
    coreNonCash: wrapCoreNoncash,
    coreDiscounts: wrapCoreDiscounts,
    coreRemainingAmount: cashlessDetail.remainingCore,
    cardType: body.cardType ?? '-',

    minAplDate: cashlessDetail.minAplDate,
    minOplDate: cashlessDetail.minOplDate,

    LPSAmount: cashlessDetail.LPSAmount,
    LPUAmount: cashlessDetail.LPUAmount,
    LPE1Amount: cashlessDetail.LPE1Amount,
    LNVPAmount: cashlessDetail.LNVPAmount,
    interestRate,
    priorProposal: detail.payload.body.priorProposal ?? undefined,
    proposalNumberOld: detail.payload.body.proposalNumberOld ?? undefined,
    remark: payload.body.remark ?? undefined,
    transactionStatus: cashlessDetail.transactionStatus ?? '',
    warningCode: cashlessDetail.warningCode ?? '',
    source: detail.source,
    stpFunction: detail.stpFunction || '',
    writeOffAction: detail.writeOffAction || '',
    InsPremium: ['UR5', 'UR6', 'VL2', 'VL3', 'VL5'].includes(cashlessDetail.policyInfo?.productCode as string)
      ? cashlessDetail.insPremium || 0
      : cashlessDetail.basicPremium || 0,
    suspendReason: detail.suspendReason || '',
    writeOffAction: detail.writeOffAction || '',
    hasVoucher: body.hasVoucher || false,
    vouchers: body.vouchers || []
  }
}
