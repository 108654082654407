import React, { useEffect, useRef } from 'react'
import { View } from 'react-native'
import { useVoiceRecording } from '../BussinessLogic'

interface Props {
  audioRef: any
  progressBarRef: any
}

export interface AudioRef {
  onLoad: () => void
}

export const DisplayTrack = React.forwardRef(({ audioRef, progressBarRef }: Props, ref: React.Ref<AudioRef>) => {
  const { state, controller } = useVoiceRecording()
  const peerConnectionRef = useRef<RTCPeerConnection>()
  const onLoadedMetadata = () => {
    const seconds = audioRef.current.duration
    controller.setDurationVoice(seconds)
    progressBarRef.current.max = seconds
  }

  useEffect(() => {
    const setupWebRTC = async () => {
      if (!peerConnectionRef.current) {
        peerConnectionRef.current = new RTCPeerConnection()

        peerConnectionRef.current.ontrack = (event) => {
          const remoteAudioTrack = event.streams[0].getTracks()[0]
          audioRef.current.srcObject = new MediaStream([remoteAudioTrack])
        }

        const offer = await peerConnectionRef.current.createOffer()
        await peerConnectionRef.current.setLocalDescription(offer)
      }
    }

    setupWebRTC()

    return () => {
      if (peerConnectionRef.current) {
        peerConnectionRef.current.close()
        peerConnectionRef.current = undefined
      }
    }
  }, [])

  React.useImperativeHandle(ref, () => ({
    onLoad: () => {
      onLoadedMetadata()
    }
  }))

  return (
    <View style={{ display: 'none' }}>
      <audio controls controlsList="nodownload" ref={audioRef} id="audio_track" onLoadedMetadata={onLoadedMetadata}>
        <source src={state.currentTrack?.src} type="audio/x-wav"></source>
      </audio>
    </View>
  )
})