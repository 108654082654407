import React from 'react'
import { makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { ActivityIndicator, useWindowDimensions, View } from 'react-native'
import { ErrorActivity, mapTransactionType, mapTransactionTypeToServiceType } from '@pulseops/common'

interface Props {
  dataSource: ErrorActivity.TaskOp[]
  isLoading: boolean
  topSpace?: number
}

export const ErrorHandlerTable = (props: Props) => {
  const { isLoading = false, dataSource = [], topSpace } = props

  const { height } = useWindowDimensions()
  const { t } = useTranslation('TaskManagement')
  const useStyles = makeStyles({
    container: {
      border: `1px solid #D3DCE6`,
      borderWidth: 1,
      borderRadius: 8,
      maxHeight: topSpace ? height - topSpace : height - 340
    },
    table: {},
    tableHeader: {
      backgroundColor: '#E7E7E7'
    },
    tableBody: {
      backgroundColor: '#FFFFFF'
    },
    tableCellHeader: {
      backgroundColor: '#F4F4F4',
      whiteSpace: 'nowrap',
      fontWeight: 'bold',
      color: '#70777E'
    },
    tableCell: {
      whiteSpace: 'nowrap'
    }
  })

  const classes = useStyles()

  const tableHeaderArray = [
    t('ErrorHandlerTable.ServiceType'),
    t('ErrorHandlerTable.PolicyNumber'),
    t('ErrorHandlerTable.Assignee'),
    t('ErrorHandlerTable.TransactionType'),
    t('ErrorHandlerTable.CaseID'),
    t('ErrorHandlerTable.Source'),
    t('ErrorHandlerTable.CreatedDate'),
    t('ErrorHandlerTable.ClientNumber'),
    t('ErrorHandlerTable.StuckTime'),
    t('ErrorHandlerTable.CurrentActivity')
    // t('ErrorHandlerTable.CategoryError'),
    // t('ErrorHandlerTable.NameError')
  ]

  const loadingDataRow = () => (
    <TableRow>
      <TableCell style={{ textAlign: 'center' }} colSpan={12}>
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <ActivityIndicator size="large" color="red" />
        </View>
      </TableCell>
    </TableRow>
  )

  const noDataRow = () => (
    <TableRow>
      <TableCell style={{ textAlign: 'center' }} colSpan={12}>
        {t('common:Nodata')}
      </TableCell>
    </TableRow>
  )

  const existedDataRow = (data: ErrorActivity.TaskOp, rowIndex: number) => {
    let tableCellArray = [
      data.transactionType ? t(mapTransactionTypeToServiceType.get(data.transactionType) ?? '-') : '-',
      data?.policyNumber,
      data?.assignee,
      data.transactionType ? t(mapTransactionType.get(data.transactionType) ?? '-') : '-',
      data?.caseId,
      data?.source,
      data.createdDate ? format(data.createdDate, 'dd/MM/yyyy - HH:mm:ss') : '-',
      data?.clientNumber,
      data.stuckTime ? format(data.stuckTime, 'dd/MM/yyyy - HH:mm:ss') : '-',
      data?.currentActivity
      // data?.categoryError,
      // data?.nameError
    ]

    return (
      <TableRow key={data.id ?? rowIndex} style={{ backgroundColor: '#FFFFFF' }}>
        {tableCellArray.map((item, index) => (
          <TableCell className={classes.tableCell} key={`rowIndex_${rowIndex}_${index}`}>
            {item}
          </TableCell>
        ))}
      </TableRow>
    )
  }

  return (
    <TableContainer component={Paper} className={classes.container} elevation={1}>
      <Table className={classes.table} stickyHeader>
        <TableHead className={classes.tableHeader}>
          <TableRow>
            {tableHeaderArray.map((item, index) => (
              <TableCell className={classes.tableCellHeader} key={index}>
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {isLoading ? (
            loadingDataRow()
          ) : (
            <>
              {dataSource.length > 0 ? (
                <>{dataSource.map((item: ErrorActivity.TaskOp, index) => existedDataRow(item, index))}</>
              ) : (
                noDataRow()
              )}
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
