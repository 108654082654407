/* eslint-disable no-extra-boolean-cast */
import { View, Text, StyleSheet, SafeAreaView, TouchableOpacity, ScrollView } from 'react-native'
import React from 'react'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'
import { AppConfig, AppContext, GroupPolicyPermission, Input, MenuPermissions, RBAC, assets } from '@pulseops/common'
import { Controller, useForm } from 'react-hook-form'
import { AuthService, ErrorHandling, StorageBlobApi } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import _ from 'lodash'
import { useLoading } from '@mxt/zio-react'

export interface RequestHistoryFormData {
  policyNumber: string
}

export const SEABillingChangeRequestScreen = () => {
  const isFocused = useIsFocused()
  const { changeBreadcrumb, showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const navigation = useNavigation()
  const { t, i18n } = useTranslation('BillingChange')
  const [loading, bindLoader] = useLoading(false)
  const [checkIpnput, setcheckIpnput] = React.useState<boolean>(false)
  const permissions: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([]))
  const submissionReportForm = useForm<RequestHistoryFormData>({
    defaultValues: {
      policyNumber: ''
    }
  })

  React.useEffect(() => {
    showGlobalLoading(loading)
  }, [loading])
  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigation.navigate('HomeScreen')
          }
        },
        {
          title: t('ImportFileForBillingChange'),
          navigate: null
        }
      ])
    }
  }, [isFocused])
  const configAruze = pipe(
    AppConfig.get,
    ZIO.map((_) => _),
    ErrorHandling.runDidMount()
  )

  const validExtensions = ['png', 'jpg', 'jpeg', 'pdf', 'tif', 'tiff']
  const fileNameSearch = submissionReportForm.watch('policyNumber').trim()
  const fileNameCheck = fileNameSearch.split('.')
  const nameCheck = fileNameCheck[1]
  const currVersion = configAruze?.version.split('.').join('-') || '1-0-0'
  const baseUrl = `https://pru-api-nprd.prudential.com.vn`
  const folder = () => {
    if (configAruze?.env === 'pva-prd-az1') {
      return 'banca_sea'
    } else if (configAruze?.env === 'pva-stag') {
      return 'banca_seastag'
    } else {
      return 'banca_seauat'
    }
  }

  const urlName = `${baseUrl}/azurestorage/${currVersion}/?container=pulseforops&blob=${folder()}/${fileNameSearch}`
  const downloadURI = (uri: string, name: string) => {
    const link = document.createElement('a')
    link.download = name
    link.target = '_blank'
    link.href = uri
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
  const getLink = (url: string) => {
    pipe(
      ZIO.zipPar(StorageBlobApi.getRemoteConfig('banca_seauat', ''), AuthService.token, AuthService.getLoginType),
      ZIO.flatMap(([storageConfig, token, loginType]) => {
        return pipe(
          ZIO.fromPromise(() => {
            const apiUrl = url.split('?')
            const storageUrl = apiUrl[0]
            const paramInfo = apiUrl[1]
            const paramList = paramInfo.split('&')
            const containerName = !!paramList[0] ? paramList[0].split('=')[1] : ''
            const blobName = !!paramList[1] ? paramList[1].split('=')[1] : ''
            return fetch(storageUrl, {
              method: 'GET',
              headers: {
                'Cache-Control': 'no-cache',
                Pragma: 'no-cache',
                Container: containerName,
                Token: storageConfig.sas,
                Blob: blobName,
                Authorization: `Bearer ${token}`,
                'X-Authen-Vendor': loginType
              }
            })
          })
        )
      }),
      ZIO.flatMap((res) =>
        ZIO.zipPar(
          ZIO.succeed(res),
          ZIO.fromPromise(() => res.blob())
        )
      ),
      ZIO.tap(([res, blob]) => {
        if (res.ok === false || res.status === 404) {
          showToast(t('AuthenticationDocuments'), 'error')
          setcheckIpnput(true)
        } else {
          downloadURI(window.URL.createObjectURL(blob), `${fileNameSearch}`)
        }
        return ZIO.unit
      }),
      ZIO.catchAll((e) => {
        showToast(t('AuthenticationDocuments'), 'error')
        return ZIO.unit
      }),
      bindLoader,
      ErrorHandling.run()
    )
  }

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView>
        <View style={styles.searchContainer}>
          <View style={[styles.row, { marginTop: 5, padding: 15 }]}>
            <Controller
              control={submissionReportForm.control}
              name="policyNumber"
              render={({ field, fieldState: { error } }) => (
                <View style={[styles.inputStyle, { marginTop: 6 }]}>
                  <Input
                    {...field}
                    value={field.value ?? ''}
                    title={t('RequestForm')}
                    maxLength={30}
                    onFocus={() => {
                      if (field.value) {
                        setcheckIpnput(false)
                      }
                    }}
                    placeholder={i18n.language === 'en' ? 'Input' : 'Nhập'}
                    errorMessage={error?.message}
                  />
                </View>
              )}
            />
          </View>
          {permissions && permissions.includes(GroupPolicyPermission.ExportDataHistoryImportBillingChange) && (
            <View style={[styles.row, { alignItems: 'center', paddingBottom: 10 }]}>
              <TouchableOpacity
                style={{ width: 118, height: 40, marginLeft: 30 }}
                disabled={checkIpnput === true ? true : false}
                onPress={() => {
                  if (!validExtensions.includes(nameCheck)) {
                    return showToast(t('AuthenticationDocuments'), 'error')
                  } else if (!!submissionReportForm.watch('policyNumber')) {
                    return getLink(urlName)
                  } else {
                    return showToast(t('message:MS030031'), 'error')
                  }
                }}
              >
                <View
                  style={[
                    checkIpnput === true ? styles.primaryBtn1 : styles.primaryBtn,
                    { width: i18n.language === 'vi' ? 150 : 120, height: 40 }
                  ]}
                >
                  <View
                    style={{
                      width: 20,
                      height: 22,
                      justifyContent: 'center',
                      alignItems: 'center',
                      alignSelf: 'center'
                    }}
                  >
                    <assets.ExportCurve />
                  </View>
                  <View style={{ width: 8 }} />
                  <Text style={{ textAlign: 'center', color: '#fff', fontWeight: '600' }}>{t('Export')}</Text>
                </View>
              </TouchableOpacity>
            </View>
          )}
        </View>
      </ScrollView>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  primaryBtn: {
    backgroundColor: '#ed1b2e',
    borderRadius: 100,
    padding: 10,
    boxShadow: 'none',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  primaryBtn1: {
    backgroundColor: '#ccc',
    borderRadius: 100,
    padding: 10,
    boxShadow: 'none',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  searchContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    margin: 15
  },
  row: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  inputStyle: {
    marginHorizontal: 15,
    marginBottom: 15,
    width: '30%'
  },
  sessonText: {
    textTransform: 'uppercase',
    fontWeight: '800',
    fontSize: 15
  },
  btn: {
    height: 40,
    backgroundColor: '#ed1b2e',
    borderRadius: 100,
    paddingHorizontal: 30,
    paddingVertical: 10,
    boxShadow: 'none',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    alignSelf: 'center'
  },
  btnUpload: {
    height: 40,
    borderRadius: 100,
    paddingHorizontal: 15,
    paddingVertical: 10,
    boxShadow: 'none'
  },
  rowBtn: {
    width: '100%',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 15
  },
  btnText: {
    textAlign: 'center',
    color: '#fff',
    fontFamily: 'NotoSans_700Bold',
    alignSelf: 'center',
    justifyContent: 'center'
  },
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'baseline',
    borderRadius: 16
    // maxHeight: 24
  },

  lalel: {
    fontSize: 13,
    fontWeight: '500',
    marginHorizontal: 16,
    marginVertical: 2,
    lineHeight: 24,
    whiteSpace: 'nowrap'
  }
})
