import { BuildConfig } from '@pulseops/common'
import { LinkingOptions } from '@react-navigation/native'
import * as Linking from 'expo-linking'

const prefix = Linking.createURL('/')

// console.log('prefixxx', Linking.createURL(''))

export const linking: LinkingOptions = {
  prefixes: [prefix, 'http://localhost:19006'],
  config: {
    screens: {
      MainStack: {
        path: BuildConfig.contextPath,
        screens: {
          LoginScreen: '',
          UserStack: {
            path: 'user',
            screens: {
              HomeScreen: 'home',
              UserDrawer: {
                path: 'drawer',
                screens: {
                  BOStack: {
                    path: 'back-office',
                    screens: {
                      BODashboardScreen: 'bo-dashboard',
                      BODetailScreen: 'bo-detail'
                    }
                  },
                  BusinessStack: {
                    path: 'business',
                    screens: {
                      BusinessScreen: 'business-list',
                      ClaimListScreen: 'claim-list',
                      ClaimDetailScreen: {
                        path: 'claim-detail'
                      },
                      ContractListScreen: 'contract-list',
                      ContractDetailScreen: {
                        path: 'contract-detail'
                      }
                    }
                  },
                  DIStack: {
                    path: 'digital',
                    screens: {
                      DIDashboardScreen: 'digital-dashboard',
                      PolicyInquiryScreen: 'policy-inquiry',
                      PolicyInquiryDetailScreen: 'policy-inquiry-detail',
                      VoucherInquiryScreen: 'voucher-inquiry'
                    }
                  },
                  TaskDashboardScreen: 'task-dashboard',
                  TaskDetailStack: {
                    path: 'task',
                    screens: {
                      TaskDetailScreen: 'detail',
                      TrackingStatusClaimScreen: 'tracking-status-claim'
                    }
                  },
                  NotificationScreen: 'notification',
                  ServiceInquiryScreen: 'service-inquiry',
                  // DsAdminScreen: 'ds-admin',
                  PCDataImportScreen: 'PCDataImportScreen',
                  PCRequestListStackScreen: {
                    path: 'pc-request-list',
                    screens: {
                      PCRequestListScreen: 'search-pc-request-list',
                      PCRequestListDetailScreen: 'detail-pc-request-lists'
                    }
                  },
                  PolicyGroupListStack: {
                    path: 'group-policy',
                    screens: {
                      SEAPolicyGroupScreen: 'group-policy',
                      SEABillingChangeScreen: 'billing-change',
                      SEAWithdrawScreen: 'with-draw',
                      SEABillingChangeUploadScreen: 'billing-change-upload',
                      SEABillingChangeImportHistoryScreen: 'billing-change-inport-history',
                      SEABillingChanceImportHistoryDetailScreen: 'billing-change-inport-history-detail',
                      SEABillingChangeRequestScreen: 'billing-change-request'
                    }
                  },

                  ReportsStack: {
                    path: 'reports',
                    screens: {
                      ReportsScreen: '',
                      SubmissionReportScreen: 'submission-report',
                      PaymentReportScreen: 'payment-report',
                      PolicyDataXMLScreen: 'policy-data-xml',
                      PerformanceReportScreen: 'performance-report-compensation',
                      ReportManagementScreen: 'report-management',
                      ErrorHandlerReportScreen: 'error-handler-report',
                      ClaimPayoutReportScreen: 'claim-payout-report',
                      CommunicationTrackingReportScreen: 'communication-tracking',
                      CheckingPayoutReportScreen: 'checking-payout',
                      ReportAutoDebitScreen: 'Report-Auto-Debit-Topup-Account-Monthly'
                    }
                  },
                  ErrorHandlerReportScreen: 'error-handler-report',
                  VerificationClaimStack: {
                    path: 'verification',
                    screens: {
                      VerificationClaimScreen: 'claim'
                    }
                  },
                  StaffSubmissionStack: {
                    path: 'staff-submission',
                    screens: {
                      StaffSubmissionScreen: 'policy',
                      ClaimStack: {
                        path: 'claim',
                        screens: {
                          ClaimListScreen: 'list',
                          ClaimScreen: 'register-new-claim',
                          AddLackOfDocumentsScreen: 'add-lack-of-documents',
                          AddLackOfPayeeScreen: 'add-lack-of-payee',
                          PayoutScreen: 'payout-submission'
                        }
                      },
                      InquiryStack: {
                        path: 'inquiry',
                        screens: {
                          CEPSearchScreen: 'cep-search',
                          CEPPolicyDetailScreen: 'cep-policy-detail'
                        }
                      },
                      ContactCenterScreen: 'contact-center',
                      UpdateInfoScreen: 'update-info-contact',
                      PolicyDetail: 'policy-detail',
                      PSSubmissionStack: {
                        path: 'submission-transaction-list',
                        screens: {
                          PSSubmissionListScreen: 'transaction-list',
                          PSSubmissionScreen: 'transaction-detail'
                        }
                      },
                      ComplainInquiryScreen: 'complain-inquiry',
                      AmlRequestStack: {
                        path: 'aml-request',
                        screens: {
                          AmlRequestListScreen: 'list',
                          AmlRequestSubmissionCommonScreen: 'aml-request-detail'
                        }
                      }
                    }
                  },
                  PCRequestStack: {
                    path: 'pc-request',
                    screens: {
                      PCRequestScreen: 'policy',
                      RequestRelatedCashlessCollectionStack: {
                        path: 'request-related-cashless',
                        screens: {
                          RequestRelatedCashlessCollectionScreen: 'request-cashless-collection-list',
                          WriteOffSubmissionCommonScreen: 'write-off-submission'
                        }
                      }
                    }
                  },
                  InquiryStack: {
                    path: 'inquiry',
                    screens: {
                      CEPSearchScreen: 'cep-search',
                      CEPPolicyDetailScreen: 'cep-policy-detail'
                    }
                  },
                  UtilitiesStack: {
                    path: 'utility',
                    screens: {
                      Utilities: 'utilities',
                      InquiryIheatScreen: 'inquiryiheat',
                      UploadDocumentScreen: 'uploaddocument',
                      UploadDocumentReportScreen: 'upload-documents-report',
                      DsAdminScreen: 'DS-Admin',
                      AgentSearchScreen: 'agent-search-screen',
                      UnitFundFundPriceScreen: 'unit-link-fund-price-history',
                      DSScreen: 'ds-add-lack-document',
                      AddLackScreen: 'add-lack-upload',
                      UpdateDocumentGuideScreen: 'upload-document-guide',
                      ViewDocumentGuideScreen: 'view-document-guide',
                      ImagingUploadScreen: 'imaging-upload-screen'
                    }
                  },
                  DATaskDetailStack: {
                    path: 'da',
                    screens: {
                      DATaskDetailScreen: 'detail',
                      DsAdminScreen: 'DS-Admin'
                    }
                  },
                  CashoutStack: {
                    path: 'cash-out',
                    screens: {
                      CashOutHistoryList: 'list',
                      CashOutScreen: 'cash-out-request'
                    }
                  },
                  ReleasePolicyScreen: 'release-policy',
                  AccountingStack: {
                    path: 'accounting',
                    screens: {
                      AccountingList: 'list',
                      AccountingCreateNoteScreen: 'create-note',
                      AccountingUpdateNoteScreen: 'update-note'
                    }
                  },
                  OBTaskManagementScreen: 'ob-task-management',
                  OutboundStack: {
                    path: 'outbound',
                    screens: {
                      OBCallOutDetailScreen: 'ob-call-out-detail',
                      // OBServiceInquiryScreen: 'ob-service-inquiry',
                      OBReportScreen: 'ob-report'
                    }
                  },
                  OBServiceInquiryScreen: 'ob-service-inquiry',
                  OBImportFileScreen: 'ob-import-file',
                  SettingStack: {
                    path: 'setting-screen',
                    screens: {
                      SettingScreen: '',
                      ConfigT4S7MaxCaseScreen: 'config-t4s7',
                      ConfigT4S14ManagerTeamScreen: 'config-t4s14',
                      ConfigT4S15OutboundStaffListScreen: 'config-t4s15',
                      ConfigT4S17TransferTransaction: 'config-t4s17',
                      ConfigT4S18InboundStaffListScreen: 'config-t4s18',
                      ConfigTableScreen: 'config-table',
                      ConfigTableT8Screen: 'config-table-t8',
                      ConfigTableT6Screen: 'config-table-t6',
                      ConfigA2S3AnswersListScreen: 'config-a2s3',
                      ConfigA2S1A2S2QAListScreen:'config-a2s1-a2s2'
                    }
                  },
                  InboundStack: {
                    path: 'inbound',
                    screens: {
                      IBSearchScreen: 'ib-search',
                      IBClientScreenStack: {
                        path: 'ib-client',
                        screens: {
                          DashBoardScreen: 'ib-dashboard',
                          IBClientPolicyScreen: 'ib-client-policy'
                        }
                      },
                      IBDraftTransaction: 'ib-draft-transaction',
                      IBSubmitTransaction: 'ib-submit-transaction',
                      IBCallbackAppointment: 'ib-callback-appointment'
                    }
                  },
                  IDCDetailStack: {
                    path: 'independence-check',
                    screens: {
                      IDCDetailScreen: 'idc-detail-screen',
                      IBEmailManagement: 'ib-email-management'
                    }
                  }
                }
              }
            }
          },
          LandingPageStack: {
            path: 'landing-page',
            screens: {
              HomeScreen: '',
              PolicyListScreen: 'policy-list',
              SpecialBonusScreen: 'special-bonus',
              SuccessScreen: 'success',
              BankInfoScreen: 'bank-info'
            }
          },
          LandingPageCCCDStack: {
            path: 'landing-page-national-id',
            screens: {
              HomeScreen: '',
              PolicyListScreen: 'policy-list',
              ChangeNationalIdCard: 'cccd',
              SuccessScreen: 'success'
            }
          },
          LandingPageBankStack: {
            path: 'landing-page-bank-info',
            screens: {
              HomeScreen: '',
            }
          },
          LandingPageIDCStack: {
            path: 'landing-page-idc',
            screens: {
              HomeScreen: '',
            }
          },
          InternalFeedbackStack: {
            path: 'internal-feedback',
            screens: {
              InternalFeedbackScreen: ''
            }
          },
          OBInternalFeedbackStack: {
            path: 'outbound-internal-feedback',
            screens: {
              OBInternalFeedbackScreen: ''
            }
          },
          AdminPageStack: {
            path: 'admin-page',
            screens: {
              AdminPageScreen: '',
              ConfigT33OfficeScreen: 'config-t33-office',
              ConfigBankListScreen: 'config-bank-list',
              ConfigBankBranchListScreen: 'config-bank-branch-list',
              ConfigGAInformationListScreen: 'config-ga-information-list'
            }
          }
        }
      },
      BusinessEditHistoryModal: 'business-edit-history',
      BusinessViewEmployeeOverFCLModal: 'business-view-employee-over-fcl',
      BusinessViewEmployeeModal: 'business-view-employee'
    }
  }
}
