import { View, Text } from 'react-native'
import styled from 'styled-components/native'

declare type TextCustom = {
  fontSize?: number
  fontWeight?: string
  fontStyle?: string
  color?: string
}

export const TextCustom = styled(Text)<TextCustom>`
  color: ${(props) => props.color ?? 'black'};
  font-size: ${(props) => `${props.fontSize}px` ?? '15px'};
  font-weight: ${(props) => props.fontWeight ?? '500'};
  font-style: ${(props) => props.fontStyle ?? 'normal'};
`

export const APSStyle = {
  Header: styled(View)`
    width: auto;
    height: auto;
  `,

  Body: styled(View)`
    margin-top: 18px;
  `
}
