import { View, Text } from 'react-native'
import styled from 'styled-components/native'
import { COLORS } from './ConfigTableT6Const'
import { TextCustomI } from './models'

export const TextCustom = styled(Text)<TextCustomI>`
  color: ${(props) => props?.textColor ?? 'black'};
  font-size: ${(props) => props?.fontSize ?? '16px'};
  font-weight: ${(props) => props?.fontWeight ?? 'normal'};
  font-style: ${(props) => props?.fontStyle ?? 'normal'}; ;
`

export const CF = {
  Container: styled(View)`
    width: 100%;
    height: 100%;
    background-color: ${COLORS.SNOW};
  `,

  Body: styled(View)`
    padding: 20px 24px;
    width: 100%;
    height: calc(100vh - 40px);
  `,

  SearchArea: styled(View)`
    border-radius: 8px;
    background-color: ${COLORS.WHITE};
    width: 100%;
    padding: 12px;
  `,

  SearchAreaRow: styled(View)`
    display: flex;
    flex-direction: row;
  `,

  COL_4: styled(View)`
    width: 100%;
    max-width: 33.3333333333%;
    padding-right: 15px;
    padding-left: 15px;
  `,

  TableArea: styled(View)`
    width: 100%;
    border-radius: 8px;
  `,

  Row: styled(View)`
    display: flex;
    flex-direction: row;
  `
}
