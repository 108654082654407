// React Lib
import React, { useState, forwardRef, useImperativeHandle } from 'react'
import { Controller, useForm } from 'react-hook-form'

// Common
import { SelectSearch, assets } from '@pulseops/common'
import { CF } from '../ConfigTableT6Styles'
import { FormGroupSearchT6I, T6SearchFormData } from '../models'
import { defaultValuesSearch } from '../ConfigTableT6Const'

const { COL_4, SearchAreaRow } = CF

export interface FormAreaRef {
  getValuesSearchForm?: () => void
  submit?: () => void
  clearForm?: () => void
  setFieldsSearch: React.Dispatch<React.SetStateAction<FormGroupSearchT6I[]>>
}

interface Props {
  ref: FormAreaRef
}

const searchs: FormGroupSearchT6I[] = [
  {
    type: 'suspendBy',
    required: false,
    label: 'Suspend By',
    options: [],
    placeholder: 'Chọn',
    popupIcon: <assets.ArrowDownDropdownIcon />
  },
  {
    type: 'suspendCode',
    required: false,
    label: 'Suspend Code',
    options: [],
    placeholder: 'Chọn',
    popupIcon: <assets.ArrowDownDropdownIcon />
  },
  {
    type: 'status',
    required: false,
    label: 'Status',
    options: [],
    placeholder: 'Chọn',
    popupIcon: <assets.ArrowDownDropdownIcon />
  }
]

const SearchAreaFormComp = forwardRef<FormAreaRef, Props>((props, ref) => {
  const [fieldsSearch, setFieldsSearch] = useState<FormGroupSearchT6I[]>(() => searchs)

  const T6SearchForm = useForm<T6SearchFormData>({ defaultValues: defaultValuesSearch })

  const { control, watch, reset } = T6SearchForm

  const getValuesForm = () => {
    const suspendByValue = watch('suspendBy')
    const suspendCodeValue = watch('suspendCode')
    const statusValue = watch('status')

    return {
      suspendByValue,
      suspendCodeValue,
      statusValue
    }
  }

  useImperativeHandle(ref, () => ({
    fieldsSearch,
    setFieldsSearch,
    getValuesSearchForm: () => getValuesForm(),
    clearForm: () =>
      reset({
        ...defaultValuesSearch
      })
  }))

  const renderSearchAreaForm = () => {
    return fieldsSearch.map((_field) => {
      const { type, label, popupIcon, options, placeholder } = _field

      const isSuspendCodeField = type === 'suspendCode'

      return (
        <COL_4>
          <Controller
            name={type}
            control={control}
            render={({ field: { onChange, onBlur } }) => (
              <SelectSearch
                label={label}
                options={options}
                placeholder={placeholder}
                onChange={onChange}
                onBlur={onBlur}
                popupIcon={popupIcon}
                {...(isSuspendCodeField ? { multiple: true } : { multiple: false })}
              />
            )}
          />
        </COL_4>
      )
    })
  }

  return <SearchAreaRow>{renderSearchAreaForm()}</SearchAreaRow>
})

export default SearchAreaFormComp
