import { AppContext, TabList, TASK_DETAIL_ROLES, useMobile, Tab, TaskType } from '@pulseops/common'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { CommentTab, DocumentTab, HistoryTab } from './index'
import { OnGoingRequestTab } from './on-going-request/OnGoingRequest'
export interface Props {
  processId: string
  taskId: string
  policyNum: string
  roles: string[]
  isInquiry: boolean
  isSuspend: boolean
  isQC: boolean
  disabled?: boolean
  category?: string
  filesNamePC?: string[] | null
  p40ProcessInstanceId?: string
}

export const RelatedInfo = (props: Props) => {
  const { disabled = false } = props
  const { isWide } = useMobile()
  const [subTabIndex, onTabChange] = React.useState(0)
  const { getFooter } = React.useContext(AppContext.AppContextInstance)
  const { t } = useTranslation()

  const relatedInfo = [
    {
      id: 0,
      title: t('Tab:Comment'),
      permission: TASK_DETAIL_ROLES.ViewRelatedInfoCommentTaskDetail
    },
    {
      id: 1,
      title: t('Tab:History'),
      permission: TASK_DETAIL_ROLES.ViewRelatedInfoHistoryTaskDetail
    },
    {
      id: 2,
      title: t('Tab:Document'),
      permission: TASK_DETAIL_ROLES.ViewRelatedInfoDocumentTaskDetail
    },
    {
      id: 3,
      title: t('Tab:OGR'),
      permission: TASK_DETAIL_ROLES.ViewRelatedInfoOnGoingRequestTaskDetail
    }
  ]

  const relatedInfoDS = [
    {
      id: 0,
      title: t('Tab:Comment'),
      permission: TASK_DETAIL_ROLES.ViewRelatedInfoCommentTaskDetail
    },
    {
      id: 1,
      title: t('Tab:History'),
      permission: TASK_DETAIL_ROLES.ViewRelatedInfoHistoryTaskDetail
    }
  ]

  const relatedInfoPCWO = [
    {
      id: 2,
      title: t('Tab:Document'),
      permission: TASK_DETAIL_ROLES.ViewRelatedInfoDocumentTaskDetail
    }
  ]
  const relatedInfoIDC = [
    {
      id: 1,
      title: t('Tab:History'),
      permission: TASK_DETAIL_ROLES.ViewRelatedInfoHistoryTaskDetail
    },
    {
      id: 3,
      title: t('Tab:OGR'),
      permission: TASK_DETAIL_ROLES.ViewRelatedInfoOnGoingRequestTaskDetail
    }
  ]
  React.useEffect(() => {
    if (props.category === TaskType.GENERAL) {
      onTabChange(2)
    }
  }, [props.category])

  const hasTab = (tabId: number, tabs: Tab[]) => {
    return tabs.find((item) => item.id === tabId) !== undefined
  }

  const getTabs = () => {
    if (props.roles.length > 0) {
      const visibleTabs = (
        props.category === TaskType.DistributionService
          ? relatedInfoDS
          : props.filesNamePC
          ? relatedInfoPCWO
          : props.category === "IDC" ? relatedInfoIDC:  relatedInfo
      ).filter((item) => props.roles.includes(item.permission))

      if (visibleTabs.length > 0 && !hasTab(subTabIndex, visibleTabs)) {
        onTabChange(visibleTabs[0].id)
      }

      return visibleTabs
    }
    return []
  }

  const tabs = getTabs()

  console.log(tabs)

  const renderTab = (): JSX.Element | undefined => {
    const commentsEditabled =
      props.roles.includes(TASK_DETAIL_ROLES.ADD_COMMENT) && !props.isInquiry && !props.isSuspend
    if (isWide) {
      switch (subTabIndex) {
        case 0:
          return hasTab(0, tabs) ? (
            <CommentTab processId={props.processId} taskId={props.taskId} readonly={!commentsEditabled} category={props.category} />
          ) : undefined
        case 1:
          return hasTab(1, tabs) ? <HistoryTab processId={props.processId} category={props.category} p40ProcessInstanceId={props.p40ProcessInstanceId} /> : undefined
        case 2:
          return hasTab(2, tabs) && props.category !== TaskType.DistributionService ? (
            <DocumentTab
              disabled={disabled}
              processId={props.processId}
              policyNum={props.policyNum}
              isQC={props.isQC}
              category={props.category}
              filesNamePC={props.filesNamePC}
            />
          ) : undefined
        case 3:
          return hasTab(3, tabs) && props.category !== TaskType.DistributionService ? (
            <OnGoingRequestTab policyNum={props.policyNum} />
          ) : undefined
        default:
          break
      }
    }
    return
  }

  return (
    <SafeAreaView style={[styles.tabContainer, { paddingBottom: 60, flex: 1 }]}>
      <TabList
        gutterBottom
        isSubTab={true}
        useOriginalId={true}
        menus={tabs}
        tabIndex={subTabIndex}
        onChangeTab={(index) => {
          getFooter(<></>)
          onTabChange(index)
        }}
      />
      <View style={{ flex: 1 }}>
        <View>{props.processId && renderTab()}</View>
      </View>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  tabContainer: {
    marginHorizontal: 15,
    marginBottom: 15,
    flex: 1
  }
})
