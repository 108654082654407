import { AppContext, assets, ErrorHandling, PolicyInfoHeader, RBAC } from '@pulseops/common'
import { GeneralInfoDigitalScreen, GeneralInfoScreen, TaskDetailStackParamList } from '@pulseops/task'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import { pipe } from 'fp-ts/lib/function'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'

type Props = StackScreenProps<TaskDetailStackParamList, 'PolicyDetail'>

export const PolicyDetailScreen = ({ navigation, route }: Props) => {
  const { t } = useTranslation()

  const isFocused = useIsFocused()
  const { navigate } = useNavigation()
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)

  const roles: string[] | null = pipe(RBAC.permissions, ErrorHandling.runDidMount())

  const getPolicyOwnerHeaderName = () => {
    return !!route.params?.primaryId && !!route.params?.policyOwner
      ? route.params?.primaryId + ' - ' + route.params?.policyOwner
      : ''
  }

  const menus = [
    {
      label: t('common:PolicyNumber'),
      icon: <assets.ProfileIcon />,
      value: route.params?.policyNum
    },
    {
      label: t('common:PolicyOwner'),
      // value: route.params?.policyOwner
      value: getPolicyOwnerHeaderName()
    },
    {
      label: t('common:Office'),
      // value: route.params?.officeCodeName || '-'
      value: `${route.params?.officeCode ?? ''} - ${route.params?.officeName ?? ''}`
    }
  ]

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigation.replace('HomeScreen')
          }
        },
        {
          title: t('Submission:EForm'),
          navigate: () =>
            // navigation.replace('UserDrawer', {
            //   screen: 'StaffSubmissionStack'
            // })
            navigate('StaffSubmissionStack', { screen: 'StaffSubmissionScreen' })
        },

        { title: t('common:PolicyDetail'), navigate: null }
      ])
    }
  }, [isFocused])

  return (
    <View style={{ flex: 1 }}>
      <ScrollView>
        <PolicyInfoHeader menus={menus} />
        <View style={{ backgroundColor: '#FFF' }}>
          {roles &&
            route.params &&
            (route.params.policyNum.length === 8 ? (
              <GeneralInfoScreen
                navigation={navigation}
                policyNum={route.params.policyNum}
                roles={roles}
                isGeneral={false}
                officeCode={route.params?.officeCode}
              ></GeneralInfoScreen>
            ) : (
              <GeneralInfoDigitalScreen
                roles={roles || []}
                navigation={navigation}
                policyNum={route.params.policyNum}
                isGeneral={false}
              ></GeneralInfoDigitalScreen>
            ))}
        </View>
      </ScrollView>
    </View>
  )
}
