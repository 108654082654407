import React from 'react'
import { SafeAreaView, ScrollView, View, StyleSheet, TextStyle, TouchableOpacity, Text, Pressable } from 'react-native'
import { DrawerContentComponentProps, DrawerContentOptions } from '@react-navigation/drawer'
import { useIsFocused } from '@react-navigation/native'
import {
  AppContext,
  assets,
  AuthService,
  CashOutService,
  Checkbox,
  DatePicker,
  downloadURI,
  ErrorHandling,
  GeneralService,
  HistoricTask,
  Input,
  mapTransactionType,
  ModalComponent,
  Permission,
  PulseOpsFormat,
  RBAC,
  SelectOption,
  SelectSearch,
  StorageBlobApi,
  TaskAccountingOP,
  TaskDetailService,
  TaskService,
  TransactionType
} from '@pulseops/common'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { NoteListSearch } from './note-list-form'
import {
  LinearProgress,
  makeStyles,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TablePagination,
  TableBody
} from '@material-ui/core'
import { useLoading } from '@mxt/zio-react'
import { pipe } from 'fp-ts/function'
import { ZIO } from '@mxt/zio'
import moment from 'moment'
import { FontAwesome5 } from '@expo/vector-icons'
import { Status } from '../cash-out-request/cashout-history-list'
import { BorderInput } from '@pulseops/business/core'

const useStyles = makeStyles({
  tableHeader: {
    backgroundColor: '#E5EAEF'
  },
  tableCell: {
    fontWeight: 'bold',
    whiteSpace: 'nowrap'
  },
  cellNoWrap: {
    whiteSpace: 'nowrap'
  }
})

export interface FileDocs {
  url: string;
  metaData: {
      doctype: string | null | undefined
      docid: string | null | undefined
      maindoc: string | null | undefined
      subdoc: string | null | undefined
      batchno: string | null | undefined
  };
  source: string;
}

interface Info {
  policyNum: string
  category: string
}

export const listDocId = ['10408071', '10408121', '10408051', '10408381', '10409261',
 '10408321', '10409041', '10408261', '10408332', '10408333', '10406021', '10408411', '102050119']

export const showLabelDoc = (docid: string, language: string) => {
  switch(docid) {
    case '10408071':
    case '10408121':
    case '10408051':
    case '10408381':
    case '10409261':
    case '10408321':
    case '10409041':
    case '10408261':
    case '10408332':
    case '10408333': {
      if(language === 'vi')
        return 'Phiếu yêu cầu'
      return 'Request form'
    }
    case '10406021': {
      if(language === 'vi')
        return 'Giấy ủy quyền'
      return 'Authority letter'
    }
    case '10408411':  {
      if(language === 'vi')
        return 'Phiếu chi'
      return 'Payment Receipt'
    }
    case '102050119': {
      if(language === 'vi')
        return 'CMND/Khai sinh/Hộ chiếu'
      return 'Personal ID'
    }
    default:
      return ''
  }
}

export const NoteListScreen = (props: DrawerContentComponentProps<DrawerContentOptions>) => {
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [pageNum, setPageNum] = React.useState<number>(0)
  const [messageResponse, setMessageResponse] = React.useState<string | null>(null)
  const [valueAmount, setValueAmount] = React.useState<number | null>(null)
  const [officeCodes, setOfficeCodes] = React.useState<SelectOption[]>([])
  const [submittedData, setSubmittedData] = React.useState<HistoricTask.Filter>({})
  const [totalItem, setTotalItem] = React.useState<number>(0)
  const [rows, setRows] = React.useState<TaskAccountingOP[]>([])
  const { t, i18n } = useTranslation()
  const classes = useStyles()
  const isFocused = useIsFocused()
  const [isEditing, setIsEditing] = React.useState<boolean>(false)
  const [loading, bindLoading] = useLoading(false)
  const [loadingCircle, bindLoadingCircle] = useLoading(false)

  const [relatedDocVisible, setRelatedDocVisible] = React.useState<boolean>(false)
  const [createdByName, setCreatedByName] = React.useState<string>('')
  const [policyNumCaterogy, setPolicyNumCaterogy] = React.useState<Info>({
    policyNum: '',
    category: ''
  })
  const [checkChange, setCheckChange] = React.useState<boolean>(false)
  const [docList, setDocList] = React.useState<FileDocs[]>([])
  const currentDate = new Date()
  const { showGlobalLoading, showToast, changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)
  const noteListSearchForm = useForm<NoteListSearch.NoteListSearchForm>({
    defaultValues: {
      fromDate: currentDate,
      toDate: currentDate,
      bankcode: undefined,
      caseId: undefined,
      pvNumber: undefined,
      paymentNumber: undefined,
      authorizedDate: undefined,
      payeeName: undefined,
      amount: undefined,
      paymentService: []
    }
  })
  const noteListUpdateForm = useForm<NoteListSearch.NoteListUpdateForm>({
    defaultValues: {
      listStatus: []
    }
  })
  const permissions: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([]))

  const { control } = noteListSearchForm
  const TransactionLabel = (type: TransactionType | null): string => (type ? mapTransactionType.get(type) || '-' : '-')
  const transactions = (type: TransactionType) => ({
    label: TransactionLabel(type),
    value: type
  })

  const transactionTypeCashoutOption = [
    transactions(TransactionType.BONUS_SURRENDER),
    transactions(TransactionType.CANCEL_FROM_INCEPTION),
    transactions(TransactionType.EXCELLENT_STUDENT_AWARD),
    transactions(TransactionType.EXCESS_PREMIUM),
    transactions(TransactionType.FULL_SURRENDER),
    transactions(TransactionType.MATURITY),
    transactions(TransactionType.LOAN),
    transactions(TransactionType.PARTIAL_SURRENDER),
    transactions(TransactionType.PARTIAL_WITHDRAWAL),
    transactions(TransactionType.PRUCASH_PAYMENT),
    transactions(TransactionType.PRUKID369),
    transactions(TransactionType.SPECIAL_BONUS)
  ]
  React.useEffect(() => {
    showGlobalLoading(loadingCircle)
  }, [loadingCircle])

  const getCashoutAccounting = (submittedData: HistoricTask.Filter, pageSize: number, pageNum: number, loading?: boolean) => {
    pipe(
      TaskService.getHistoryTasksAccountingCashout({
        pagination: {
          pageIndex: pageNum,
          pageSize: pageSize
        },
        order: 'desc',
        filter: submittedData
      }),
      ZIO.tap((res) => {
        setIsEditing(false)
        noteListUpdateForm.reset()
        setTotalItem(res.total)
        setValueAmount(res.items.length === 0 ? 0 : res.items[0].sumAmount ?? 0)
        setRows(res.items)
        setMessageResponse(res.items.length === 0 ? t('message:MS030029') : null)
        return ZIO.unit
      }),
      loading ? bindLoadingCircle : bindLoading,
      ErrorHandling.run()
    )
  }

  const checkValidDate = () => {
    const dateTo = noteListSearchForm.watch('toDate')
    const dateFrom = noteListSearchForm.watch('fromDate')
    const authorizedDate = noteListSearchForm.watch('authorizedDate')
    if (!moment(dateTo).isValid() || !moment(dateFrom).isValid() || (authorizedDate && !moment(authorizedDate).isValid()))
      return false
    else if (
      !moment(dateTo).isSameOrBefore(currentDate) ||
      !moment(dateFrom).isSameOrBefore(currentDate) ||
      (authorizedDate && !moment(authorizedDate).isSameOrBefore(currentDate))
    )
      return false
    return true
  }

  const search = async () => {
    const isValidate = await noteListSearchForm.trigger()
    if (isEditing) {
      showToast(t('message:MS030037'), 'warning')
    } else if (isValidate && checkValidDate()) {
      const data = noteListSearchForm.watch()
      const getSubmitData = (): HistoricTask.Filter => {
        return {
          fromDate: PulseOpsFormat.serviceInquiry_StartOfDate(data.fromDate, true),
          toDate: PulseOpsFormat.serviceInquiry_endOfDate(data.toDate, true),
          bankCode: data.bankcode?.value || undefined,
          caseId: data.caseId || undefined,
          pvNumber: data.paymentNumber || undefined,
          paymentNumber: data.pvNumber || undefined,
          authorizedDate: data.authorizedDate ? PulseOpsFormat.serviceInquiry_endOfDate(data.authorizedDate) : undefined,
          payeeName: data.payeeName || undefined,
          amount: data.amount || undefined,
          transactionType:
            data.paymentService.length > 0 ? data.paymentService.map((x) => x.value as TransactionType) : undefined
        }
      }
      const submitData: HistoricTask.Filter = getSubmitData()
      setSubmittedData(submitData)
      getCashoutAccounting(submitData, pageSize, 0)
    }
  }

  const transTypeOptions = React.useMemo(
    () => transactionTypeCashoutOption.map((item) => ({ ...item, label: t(item.label) })),
    []
  )

  const setValueFormStatus = () => {
    rows.map((item, index) => {
      noteListUpdateForm.setValue(`listStatus.${index}.caseId`, item.caseId)
      noteListUpdateForm.setValue(`listStatus.${index}.createdBy`, item.createdBy ?? '')
      noteListUpdateForm.setValue(`listStatus.${index}.valid`, item.statusNote === Status.VALID)
      if(item.statusNote === Status.INVALID) 
        noteListUpdateForm.setValue(`listStatus.${index}.invalid`, item.notes ?? '')
      else if(item.statusNote === Status.INCOMPLETE) 
        noteListUpdateForm.setValue(`listStatus.${index}.notComplete`, item.notes ?? '')
      else if(item.statusNote === Status.NOTUPDATE) 
        noteListUpdateForm.setValue(`listStatus.${index}.notUpdate`, item.notes ?? '')
    })
  }

  const updateChangeStatus = async () => {
    const validateForm = await noteListUpdateForm.trigger()
    if (validateForm) {
      if(checkChange) {
        const listUpdate = noteListUpdateForm.watch('listStatus').filter((item) => item.statusNote)
        const dataStatusChange: CashOutService.CashoutStatusInfo[] = listUpdate.map((item, index) => ({
          caseId: item.caseId,
          notes: (item.invalid ?? '') + (item.notComplete ?? '') + (item.notUpdate ?? ''),
          status: item.statusNote,
          createdBy: item.createdBy ?? createdByName,
          updatedBy: createdByName
        }))
        pipe(
          CashOutService.updateCashoutAccountingStatus(dataStatusChange),
          ZIO.tap(() => {
            getCashoutAccounting(submittedData, pageSize, 0)
            setCheckChange(false)
            return ZIO.unit
          }),
          bindLoadingCircle,
          ErrorHandling.run()
        )
      } else {
        getCashoutAccounting(submittedData, pageSize, 0)
      }
    }
  }

  const resetValueOtherColumns = (index: number) => {
    noteListUpdateForm.setValue(`listStatus.${index}.invalid`, '')
    noteListUpdateForm.setValue(`listStatus.${index}.notComplete`, '')
    noteListUpdateForm.setValue(`listStatus.${index}.notUpdate`, '')
  }

  const validateTwoNotes = (index: number, statusType: string) => {
    const statusNotComplete = noteListUpdateForm.watch(`listStatus.${index}.notComplete`)
    const statusNotUpdate = noteListUpdateForm.watch(`listStatus.${index}.notUpdate`)
    const statusInvalid = noteListUpdateForm.watch(`listStatus.${index}.invalid`)

    switch (statusType) {
      case Status.INCOMPLETE: {
        if(statusInvalid || statusNotUpdate)
          return false 
        return true
      }
      case Status.NOTUPDATE: {
        if(statusNotComplete || statusInvalid)
          return false 
        return true
      }
      case Status.INVALID: {
        if(statusNotComplete || statusNotUpdate)
          return false 
        return true
      }
      default:
        return true
    }
  }

  const changeStatus = (index: number, statusType: string, value: string | null) => {
    if(!!value)
      noteListUpdateForm.setValue(`listStatus.${index}.statusNote`, statusType)
  }

  const getDocList = (processId: string, createDate: string) => {
    pipe(
      TaskDetailService.GetRelatedDoc(processId),
      ZIO.map((x) => ({
        azureDocuments: x.azureDocuments || [],
        archivedDocuments: x.archivedDocuments || []
      })),
      ZIO.map((res) => [
        ...res.azureDocuments.map((x) => {
          const docType = !!x.metaData.Doctype ? x.metaData.Doctype : x.metaData.doctype
          const docid = !!x.metaData.Docid ? x.metaData.Docid : x.metaData.docid
          const maindoc = !!x.metaData.Maindoc ? x.metaData.Maindoc : x.metaData.maindoc
          const subdoc = !!x.metaData.Subdoc ? x.metaData.Subdoc : x.metaData.subdoc
          const batchno = !!x.metaData.Batchno ? x.metaData.Batchno : x.metaData.batchno
          return {
            url: x.url,
            metaData: {
              doctype: docType,
              docid: docid,
              maindoc: maindoc,
              subdoc: subdoc,
              batchno: batchno
            },
            source: 'Azure'
          }
        }),
        ...res.archivedDocuments.map((x) => ({ ...x, source: 'Filenet' }))
      ]),
      ZIO.map((res) => {
        const dataCashout = res.filter((item) => 
          (item.metaData.docid && listDocId.includes(item.metaData.docid)) &&
          (item.metaData.batchno && item.metaData.batchno.split('-').length > 0 && 
          item.metaData.batchno.split('-')[0] === createDate)
        )
        setDocList(dataCashout)
        setRelatedDocVisible(true)
        return ZIO.succeed(res)
      }),
      bindLoadingCircle,
      ZIO.unsafeRun({})
    )
  }

  const getLink = (url: string, source: string) => {
    source !== 'Azure'
      ? pipe(
          ZIO.zipPar(AuthService.token, AuthService.getLoginType),
          ZIO.flatMap(([token, loginType]) => {
            return ZIO.fromPromise(() =>
              fetch(url, {
                method: 'GET',
                headers: {
                  Authorization: `Bearer ${token}`,
                  'X-Authen-Vendor': loginType
                }
              })
            )
          }),
          ZIO.flatMap((res) =>
            ZIO.zipPar(
              ZIO.succeed(res),
              ZIO.fromPromise(() => res.blob())
            )
          ),
          ZIO.map(([res, blob]) => {
            const type = res.headers.get('content-type') || ''
              downloadURI(window.URL.createObjectURL(blob), `file.${type.split('/')[1]}`)
          }),
          ErrorHandling.run()
        )
      : pipe(
          ZIO.zipPar(
            StorageBlobApi.getRemoteConfig(policyNumCaterogy.category, policyNumCaterogy.policyNum),
            AuthService.token,
            AuthService.getLoginType
          ),
          ZIO.flatMap(([storageConfig, token, loginType]) => {
            return pipe(
              ZIO.fromPromise(() => {
                const apiUrl = url.split('?')
                const storageUrl = apiUrl[0]
                const paramInfo = apiUrl[1]
                const paramList = paramInfo.split('&')
                const containerName = !!paramList[0] ? paramList[0].split('=')[1] : ''
                const blobName = !!paramList[1] ? paramList[1].split('=')[1] : ''
                return fetch(storageUrl, {
                  method: 'GET',
                  headers: {
                    'Cache-Control': 'no-cache',
                    Pragma: 'no-cache',
                    Container: containerName,
                    Token: storageConfig.sas,
                    Blob: blobName,
                    Authorization: `Bearer ${token}`,
                    'X-Authen-Vendor': loginType
                  }
                })
              })
            )
          }),
          ZIO.flatMap((res) =>
            ZIO.zipPar(
              ZIO.succeed(res),
              ZIO.fromPromise(() => res.blob())
            )
          ),
          ZIO.tap(([res, blob]) => {
            const type = res.headers.get('content-type') || ''
            downloadURI(window.URL.createObjectURL(blob), `file.${type.split('/')[1]}`)
            return ZIO.unit
          }),
          bindLoading,
          ErrorHandling.run()
        )
  }

  const CheckboxStatusValid = (index: number, statusIntial: string | null) => {
    const statusValue = statusIntial === Status.VALID
    return (
      <Controller
        name={`listStatus.${index}.valid`}
        control={noteListUpdateForm.control}
        render={({ field: { onChange, value }}) => (
          <Checkbox 
            value={isEditing ? value : statusValue} 
            onChange={(valueChange) => {
              if(valueChange)
                noteListUpdateForm.setValue(`listStatus.${index}.statusNote`, Status.VALID)
              onChange(valueChange)
              resetValueOtherColumns(index)
              setCheckChange(true)
            }}
            disabled={!isEditing}
          />
        )}
      />
    )
  }

  const InputStatusHasNote = (index: number, statusType: string, data: string | null) => {
    const statusValid = noteListUpdateForm.watch(`listStatus.${index}.valid`)
    return (
      <Controller
        name={`listStatus.${index}.${statusType === Status.NOTUPDATE ? 'notUpdate' : statusType === Status.INCOMPLETE ? 'notComplete' : 'invalid'}`}
        control={noteListUpdateForm.control}
        rules={{
          validate: (value) => {
            const statusValid = noteListUpdateForm.watch(`listStatus.${index}.valid`)
            if(value && !validateTwoNotes(index, statusType))
              return `${t('message:MS030039')}`
            else if(!value && validateTwoNotes(index, statusType) && data && !statusValid)
              return `${t('message:MS030031')}`
            return true
          }
        }}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
          <BorderInput
            value={value}
            disabled={statusValid}
            placeholder={statusValid ? ' ' : t('Accounting:Input')}
            maxLength={100}
            onChange={(note) => {
              changeStatus(index, statusType, note)
              onChange(note)
              setCheckChange(true)
            }}
            errorMessage={error?.message}
          />
        )}
      />
    )
  }

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            props.navigation.navigate('HomeScreen')
          }
        },
        {
          title: t('menu:Accounting'),
          navigate: () => {
            props.navigation.navigate('AccountingList')
          }
        },
        {
          title: t('Accounting:CreateNote'),
          navigate: null
        }
      ])
    }

    pipe(
      GeneralService.getOfficeCashOut(),
      ZIO.map((codes) => {
        setOfficeCodes(
          codes.map((code) => ({
            value: code.bankCode ?? '',
            label: (i18n.language === 'vi' ? code.nameVi : code.nameEn) + ' - ' + (code?.bankCode ? code.bankCode : '')
          }))
        )
      }),
      ZIO.unsafeRun({})
    )

    pipe(
      AuthService.userInfo,
      ZIO.map((userInfo) => {
        setCreatedByName(userInfo.isGaLogin ? userInfo.name : userInfo.email)
      }),
      ZIO.unsafeRun({})
    )
  }, [isFocused])

  if(permissions.includes(Permission['ViewCreatenote'])) {
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: '#FFF' }}>
        <ScrollView>
          <View style={{ flex: 1 }}>
            <View style={styles.searchContainer}>
              {/* From Date, To Date */}
              <View style={styles.row}>
                <View style={styles.col}>
                  <Controller
                    name="fromDate"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: `${t('message:MS020009', { field: t('ServiceInquiry:FromDate') })}`
                      },
                      validate: (dateFrom) => {
                        const dateTo = noteListSearchForm.watch('toDate')
                        if (dateFrom && dateTo && moment(dateTo).isBefore(dateFrom)) return `${t('message:MS030044')}`
                        else if (moment(dateTo).diff(dateFrom, 'days', true) > 92)
                          return `${t('message:MS080004', { k: 3 })}`
                        return true
                      }
                    }}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <DatePicker
                        required
                        label={t('ServiceInquiry:FromDate')}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        maxDate={currentDate}
                        maxDateMessage={t('message:MS990032')}
                        errorMessage={error?.message}
                      />
                    )}
                  />
                </View>
                <View style={styles.col}>
                  <Controller
                    name="toDate"
                    rules={{
                      required: {
                        value: true,
                        message: `${t('message:MS020009', { field: t('ServiceInquiry:ToDate') })}`
                      },
                      validate: (dateTo) => {
                        const dateFrom = noteListSearchForm.watch('fromDate')
                        if (dateTo && dateFrom && moment(dateTo).isBefore(dateFrom)) return `${t('message:MS030044')}`
                        else if (moment(dateTo).diff(dateFrom, 'days', true) > 92)
                          return `${t('message:MS080004', { k: 3 })}`
                        return true
                      }
                    }}
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <DatePicker
                        required
                        label={t('ServiceInquiry:ToDate')}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        maxDate={currentDate}
                        maxDateMessage={t('message:MS990032')}
                        errorMessage={error?.message}
                      />
                    )}
                  />
                </View>
                <View style={styles.lastCol}>
                  <Controller
                    name="bankcode"
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <SelectSearch
                        label={t('Accounting:Bankcode')}
                        options={officeCodes}
                        placeholder={t('common:Select')}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        // disabled={officeGA !== null}
                        // showPopupIcon={officeGA === null}
                      />
                    )}
                  />
                </View>
              </View>

              <View style={styles.row}>
                <View style={styles.col}>
                  <Controller
                    name="caseId"
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <Input
                        title={t('common:CaseId')}
                        value={value ?? ''}
                        maxLength={50}
                        inputType="text"
                        onChange={onChange}
                        errorMessage={error?.message}
                      />
                    )}
                  />
                </View>
                <View style={styles.col}>
                  <Controller
                    name="pvNumber"
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <Input
                        title={t('Accounting:PvNumber')}
                        value={value ?? ''}
                        maxLength={8}
                        inputType="number"
                        onChange={onChange}
                        errorMessage={error?.message}
                      />
                    )}
                  />
                </View>
                <View style={styles.lastCol}>
                  <Controller
                    name="paymentNumber"
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <Input
                        title={t('Accounting:PaymentNumber')}
                        value={value ?? ''}
                        maxLength={16}
                        inputType="text"
                        onChange={onChange}
                        errorMessage={error?.message}
                      />
                    )}
                  />
                </View>
              </View>

              <View style={styles.row}>
                <View style={styles.col}>
                  <Controller
                    name="authorizedDate"
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <DatePicker
                        alwaysShow
                        label={t('Accounting:AuthorizeDate')}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        maxDate={currentDate}
                        maxDateMessage={t('message:MS990032')}
                        errorMessage={error?.message}
                      />
                    )}
                  />
                </View>
                <View style={styles.col}>
                  <Controller
                    name="payeeName"
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <Input
                        title={t('Accounting:Payee')}
                        value={value ?? ''}
                        maxLength={50}
                        inputType="text"
                        onChange={onChange}
                        errorMessage={error?.message}
                      />
                    )}
                  />
                </View>
                <View style={styles.lastCol}>
                  <Controller
                    name="amount"
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <Input
                        title={t('Accounting:Amount')}
                        value={value ?? ''}
                        maxLength={15}
                        inputType="money"
                        onChange={onChange}
                        errorMessage={error?.message}
                      />
                    )}
                  />
                </View>
              </View>

              <View style={styles.row}>
                <View style={styles.col}>
                  <Controller
                    name="paymentService"
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <SelectSearch
                        label={t('Accounting:PaymentService')}
                        options={transTypeOptions}
                        placeholder={t('common:Select')}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        multiple={true}
                      />
                    )}
                  />
                </View>
              </View>

              <View style={[styles.row, { alignItems: 'center', paddingLeft: 25 }]}>
                <TouchableOpacity style={{ width: 118, height: 40 }} onPress={search}>
                  <View style={styles.btn}>
                    <Text style={{ textAlign: 'center', color: '#fff' }}>{t('common:Search')}</Text>
                  </View>
                </TouchableOpacity>
              </View>

              <View style={[styles.rowTotal]}>
                <View style={styles.rowFooter}>
                  <Text style={styles.labelTitle}>{t('Accounting:TotalPayment')}</Text>
                  <View style={styles.labelValue}>
                    <Text style={{ textAlign: 'center', color: '#fff' }}>{totalItem || 0}</Text>
                  </View>
                </View>

                <View style={styles.rowFooter}>
                  <Text style={styles.labelTitle}>{t('Accounting:TotalAmount')}</Text>
                  <View style={styles.labelValue}>
                    <Text style={{ textAlign: 'center', color: '#fff' }}>
                      {PulseOpsFormat.thousandSepartor(valueAmount) || 0}
                    </Text>
                  </View>
                </View>
              </View>
            </View>

            <View style={[styles.row, { marginLeft: 20 }]}>
              <Text style={{ color: '#ED1B2E' }}>{messageResponse}</Text>
            </View>

            {loading && <LinearProgress color="secondary" />}
            {rows.length > 0 && (
              <View style={[styles.section, { marginTop: 10, marginHorizontal: 20 }]}>
                <TableContainer component={Paper} style={{ maxHeight: 500 }} elevation={1}>
                  <Table style={{ userSelect: 'text' }} stickyHeader>
                    <TableHead className={classes.tableHeader}>
                      <TableRow>
                        <TableCell className={classes.tableCell}>{t('Accounting:table:UpdateDetail')}</TableCell>
                        <TableCell className={classes.tableCell}>{t('Accounting:table:CaseID')}</TableCell>
                        <TableCell className={classes.tableCell}>{t('Accounting:table:PvNumber')}</TableCell>
                        <TableCell className={classes.tableCell}>{t('Accounting:table:PaymentNumber')}</TableCell>
                        <TableCell className={classes.tableCell}>{t('Accounting:table:AuthorizeDate')}</TableCell>
                        <TableCell className={classes.tableCell}>{t('Accounting:table:Payee')}</TableCell>
                        <TableCell className={classes.tableCell}>{t('Accounting:table:Amount')}</TableCell>
                        <TableCell className={classes.tableCell}>{t('Accounting:table:PaymentService')}</TableCell>
                        <TableCell className={classes.tableCell}>{t('Accounting:ValidDoc')}</TableCell>
                        <TableCell className={classes.tableCell}>{t('Accounting:InvalidDoc')}</TableCell>
                        <TableCell className={classes.tableCell}>{t('Accounting:NotCompleteDoc')}</TableCell>
                        <TableCell className={classes.tableCell}>{t('Accounting:NotUpdateDoc')}</TableCell>
                        <TableCell className={classes.tableCell}>{t('Accounting:table:RelatedDoc')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row, i) => {
                        return (
                          <TableRow key={`TableRow-${i}`}>
                            <TableCell>
                              <View style={{ alignItems: 'center' }}>
                                <TouchableOpacity
                                  onPress={() =>
                                    props.navigation.navigate('AccountingUpdateNoteScreen', {
                                      pvNumber: row.paymentNumber
                                    })
                                  }
                                  disabled={!permissions.includes(Permission['ViewUpdatenote'])}
                                >
                                  <assets.PencilIcon />
                                </TouchableOpacity>
                              </View>
                            </TableCell>
                            <TableCell>
                              {row.caseId && (
                                <Pressable
                                  onPress={() =>
                                    props.navigation.navigate('TaskDetailStack', {
                                      screen: 'TaskDetailScreen',
                                      params: {
                                        id: row.id,
                                        caterogy: row.category,
                                        basket: '',
                                        basketName: '',
                                        isInquiry: '1',
                                        isHistory: 'true',
                                        isBreadcrumb: '1'
                                      }
                                    })
                                  }
                                >
                                  <Text style={[{ color: 'blue', textDecorationLine: 'underline' }, styles.cellNoWrap]}>
                                    {row.caseId}
                                  </Text>
                                </Pressable>
                              )}
                            </TableCell>
                            <TableCell>{row.paymentNumber}</TableCell>
                            <TableCell>{row.pvNumber}</TableCell>
                            <TableCell>
                              {PulseOpsFormat.dateStringtoFormat(row.authorizedDate?.toString(), 'DD/MM/YYYY')}
                            </TableCell>
                            <TableCell>{row.payee}</TableCell>
                            <TableCell>{PulseOpsFormat.thousandSepartor(Number(row.amount ?? '0'))}</TableCell>
                            <TableCell>{t(TransactionLabel(row.transactionType))}</TableCell>
                            <TableCell>
                              {CheckboxStatusValid(i, row.statusNote)}
                            </TableCell>
                            <TableCell>
                              {isEditing ? 
                                InputStatusHasNote(i, Status.INVALID, row.statusNote) 
                                : row.statusNote === Status.INVALID ? row.notes: ''}
                            </TableCell>
                            <TableCell>
                              {isEditing ? 
                                InputStatusHasNote(i, Status.INCOMPLETE, row.statusNote) 
                                : row.statusNote === Status.INCOMPLETE ? row.notes: ''}
                            </TableCell>
                            <TableCell>
                              {isEditing ? 
                                InputStatusHasNote(i, Status.NOTUPDATE, row.statusNote) 
                                : row.statusNote === Status.NOTUPDATE ? row.notes: ''}
                            </TableCell>
                            <TableCell>
                              <TouchableOpacity
                                style={{ flexDirection: 'row' }}
                                onPress={() => {
                                  setPolicyNumCaterogy({
                                    policyNum: row.policyNumber,
                                    category: row.category
                                  })
                                  if(row.processInstanceId && row.createdDate)
                                    getDocList(row.processInstanceId, PulseOpsFormat.dateStringtoFormat(row.createdDate.toString(), 'DD/MM/YYYY'))
                                }}
                              >
                                <FontAwesome5 name="eye" color="#ED1B2E" style={{ marginTop: 5 }} />
                                <Text style={{ color: '#ED1B2E', textDecorationLine: 'underline', paddingLeft: 6 }}>
                                  {t('Accounting:ViewDocument')}
                                </Text>
                              </TouchableOpacity>
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  page={pageNum}
                  rowsPerPage={pageSize}
                  count={totalItem}
                  onPageChange={(e, page) => {
                    if (!isEditing) {
                      setPageNum(page)
                      getCashoutAccounting(submittedData, pageSize, page)
                    }
                  }}
                  onRowsPerPageChange={(e) => {
                    if (!isEditing) {
                      setPageSize(Number(e.target.value))
                      setPageNum(0)
                      getCashoutAccounting(submittedData, Number(e.target.value), 0)
                    }
                  }}
                  labelRowsPerPage={t('common:PaginationSize')}
                  labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
                  component={View}
                />
              </View>
            )}

            {permissions.includes(Permission['EditableCreatenote']) && (
              <View style={[styles.row, { paddingTop: 15, paddingLeft: 30 }]}>
                <View style={{ paddingRight: 25 }}>
                  <TouchableOpacity
                    style={isEditing ? styles.btnCancel : [styles.btnCancel, { borderColor: 'gray' }]}
                    disabled={!isEditing || rows.length === 0}
                    onPress={() => {
                      setIsEditing(false)
                      noteListUpdateForm.reset()
                      setCheckChange(false)
                    }}
                  >
                    <Text style={{ textAlign: 'center', color: isEditing ? '#ed1b2e' : 'gray' }}>
                      {t('Accounting:Cancel')}
                    </Text>
                  </TouchableOpacity>
                </View>

                <View style={{ paddingRight: 25 }}>
                  <TouchableOpacity
                    style={isEditing ? styles.btnCancel : [styles.btnCancel, { borderColor: 'gray' }]}
                    disabled={!isEditing || rows.length === 0}
                    onPress={() => {
                      updateChangeStatus()
                    }}
                  >
                    <Text style={{ textAlign: 'center', color: isEditing ? '#ed1b2e' : 'gray' }}>{t('common:Save')}</Text>
                  </TouchableOpacity>
                </View>

                <TouchableOpacity
                  style={isEditing || rows.length === 0 ? [styles.btnCancel, { borderColor: 'gray' }] : styles.btnCancel}
                  disabled={isEditing || rows.length === 0}
                  onPress={() => {
                    setIsEditing(true)
                    setValueFormStatus()
                  }}
                >
                  <Text style={{ textAlign: 'center', color: isEditing || rows.length === 0 ? 'gray' : '#ed1b2e' }}>
                    {t('common:Update')}
                  </Text>
                </TouchableOpacity>
              </View>
            )}

            <ModalComponent
              title={t('Accounting:table:RelatedDoc')}
              visible={relatedDocVisible}
              onClose={() => {
                setRelatedDocVisible(false)
                //setUploadDocumentTaskId(undefined)
              }}
              actions={[
                {
                  text: t('common:Cancel'),
                  type: 'outline',
                  disabled: false,
                  loading,
                  onlyWide: false,
                  style: { height: 35 },
                  action: () => {
                    setRelatedDocVisible(false)
                  }
                }
              ]}
            >
              <View style={styles.relatedDocContainer}>
                <View style={[{ paddingVertical: 20 }, styles.row]}>
                  {docList.map((doc, index) => {
                    return (
                      <View key={`doc_${index}`} style={{ width: '33%', paddingBottom: 20, paddingLeft: 20 }}>
                        <TouchableOpacity onPress={() => getLink(doc.url, doc.source)}>
                          <Text style={{ color: '#1EA5FC', textDecorationLine: 'underline', paddingLeft: 6 }}>
                            {showLabelDoc(doc.metaData.docid ?? '', i18n.language)}
                          </Text>
                        </TouchableOpacity>
                      </View>
                    )
                  })}
                </View>
              </View>
            </ModalComponent>
          </View>
        </ScrollView>
      </SafeAreaView>
    )
  }
  else {
    return(
      <SafeAreaView style={{ flex: 1, backgroundColor: '#FFF' }}></SafeAreaView>
    )
  }
}

const styles = StyleSheet.create({
  section: {
    marginLeft: 30,
    marginRight: 30
  },

  searchContainer: {
    backgroundColor: '#FAFAFA',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    marginHorizontal: 30,
    marginVertical: 20,
    paddingTop: 16,
    flexDirection: 'column'
  },

  relatedDocContainer: {
    backgroundColor: '#FAFAFA',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    marginHorizontal: 20,
    paddingBottom: 16,
    flexDirection: 'column',
    minHeight: 40
  },

  titleContainer: {
    backgroundColor: '#EAEAEA',
    paddingHorizontal: 0,
    marginBottom: 16
  },

  row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingHorizontal: 16,
    paddingBottom: 15
  },

  rowTotal: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingHorizontal: 16,
    paddingBottom: 10
  },

  rowFooter: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingHorizontal: 16,
    paddingBottom: 15,
    alignItems: 'center'
  },

  col: {
    width: '26.66%',
    marginBottom: 16,
    paddingHorizontal: 16,
    marginRight: '10%'
  },

  lastCol: {
    width: '26.66%',
    marginBottom: 16,
    paddingHorizontal: 16
  },

  btn: {
    width: 118,
    height: 40,
    backgroundColor: '#ed1b2e',
    borderRadius: 100,
    padding: 10,
    boxShadow: 'none'
  },

  btnSave: {
    width: 80,
    height: 40,
    backgroundColor: '#ed1b2e',
    borderRadius: 100,
    padding: 10,
    boxShadow: 'none'
  },

  btnCancel: {
    width: 80,
    height: 40,
    backgroundColor: '#fff',
    border: '1px solid #ed1b2e',
    borderRadius: 100,
    padding: 10,
    boxShadow: 'none'
  },

  btnSaveDisable: {
    width: 80,
    height: 40,
    backgroundColor: '#ed1b2e',
    borderRadius: 100,
    padding: 10,
    boxShadow: 'none'
  },

  labelTitle: {
    fontWeight: 'bold',
    textAlign: 'center',
    paddingRight: 10
  },

  labelValue: {
    height: 40,
    backgroundColor: '#ed1b2e',
    borderRadius: 100,
    padding: 10,
    boxShadow: 'none'
  },

  btnDetail: {
    width: 80,
    height: 40,
    backgroundColor: '#ebe9e9',
    borderRadius: 100,
    padding: 10
  },

  tableHeader: {
    backgroundColor: '#E5EAEF'
  },

  cellNoWrap: {
    whiteSpace: 'nowrap'
  } as TextStyle
})
