import React from 'react'
import { Pressable, StyleSheet, Text, View, useWindowDimensions } from 'react-native'
import {
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  makeStyles,
  TablePagination
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useNavigation } from '@react-navigation/native'
import { OBCallProgramTypeCode, OBTaskQueryData, getLastActivityName, mapCallProgram } from '../../ob-service'
import { BasketCount } from './OBBasketTab'
import { AuthService, Checkbox, ErrorHandling, Permission, SubTaskType, TableHeaderSort, PulseOpsFormat } from '@pulseops/common'
import { TaskManagementContext } from '../OBTaskManagementScreen'
import { OBFormat } from '../../ob-common'
import { pipe } from 'fp-ts/lib/function'
import { OBTaskStatus } from './OBTaskStatus'
import { TaskConst } from './OBTaskConst'
import * as O from 'fp-ts/lib/Option'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { UserDrawerParamList } from '@pulseops/main'

type OBTaskTableProps = {
  activeTabindex: number
  selectedTaskList?: Map<string, OBTaskQueryData>
  dataSource: Array<OBTaskQueryData>
  selectedBasket?: BasketCount
  onSelectedTaskEvent?: (task: OBTaskQueryData) => void
  onUnSelectedTaskEvent?: (task: OBTaskQueryData) => void

  onPageChange?: (pageNo: number, pageSi: number) => void
  onRowsPerPageChange?: (pageNo: number, pageSi: number) => void
  pageSize?: number
  setPageSize?: (size: number) => void
  pageNum?: number
  setPageNum?: (number: number) => void
  totalRecord?: number
  setTotalRecord?: (number: number) => void
  onSelectedAllRowEvent?: (taskList: OBTaskQueryData[]) => void
  onUnSelectedAllRowEvent?: (taskList: OBTaskQueryData[]) => void
}

export const OBTaskTable = (props: OBTaskTableProps) => {
  const { t } = useTranslation()
  const { height } = useWindowDimensions()
  const { pageSize = 10, totalRecord = 0, pageNum = 0, selectedBasket } = props
  const [taskList, setTaskList] = React.useState<Array<OBTaskQueryData>>([])
  const navigation = useNavigation<DrawerNavigationProp<UserDrawerParamList>>()
  const [softItem, setSoftItem] = React.useState<{ field: string; order: 'asc' | 'desc' | undefined }>({
    field: '',
    order: 'asc'
  })
  const { permissions } = React.useContext(TaskManagementContext)
  const userInfo = pipe(AuthService.userInfo, ErrorHandling.runDidMount())
  const useStyles = makeStyles({
    container: {
      border: `1px solid #D3DCE6`,
      borderWidth: 1,
      borderRadius: 8,
      maxHeight: height - 337,
      marginTop: 24
    },
    cellNoWrap: {
      whiteSpace: 'nowrap'
    }
  })

  const classes = useStyles()
  const onCaseDetailView = (item: OBTaskQueryData) => {
    if (permissions.includes(Permission['ViewDetailOBTaskList']))
      navigation.navigate('OutboundStack',
        {
          screen: 'OBCallOutDetailScreen',
          params: {
            caseID: item.businessKey,
            basket: props.selectedBasket?.basketType || '',
            policyNumber: item.policyNumber || '',
            isInquiry: userInfo?.email !== item.assignee ? '2' : '0',
            callProgram: item.transactionType ? getLabelCallProgram(item.transactionType) : '',
            policyOwner: item.poName || '',
            processInstanceId: item.processInstanceId || '',
            clientNumber: item.clientNumber || '',
            assignee: item.assignee || '',
            teamLeader: item.leader || '',
            taskId: item.id ? item.id : '',
            category: item.category || '',
            transactionType: item.transactionType || '',
            executionTime: item.executionTime || 0,
            ...(item.taskAgingDays !== undefined && { agingDays: item.taskAgingDays ?? 0 })
          }
        })
  }

  React.useEffect(() => {
    setTaskList(props.dataSource)
  }, [props.dataSource])

  const getLabelCallProgram = (callProgram: string) => {
    const findCallProgram = mapCallProgram.get(callProgram as OBCallProgramTypeCode)
    return findCallProgram || ''
  }

  const onRowSelectEvent = (isChecked: boolean, task: OBTaskQueryData) => {
    if (isChecked) {
      props.onSelectedTaskEvent && props.onSelectedTaskEvent(task)
    } else {
      props.onUnSelectedTaskEvent && props.onUnSelectedTaskEvent(task)
    }
  }

  const onAllSelectedRowEvent = (isSelectedAll: boolean, list: OBTaskQueryData[]) => {
    if (isSelectedAll) {
      props.onSelectedAllRowEvent && props.onSelectedAllRowEvent(list)
    } else {
      props.onUnSelectedAllRowEvent && props.onUnSelectedAllRowEvent(list)
    }
  }

  const getAgencyName = (agencyCode: string) => {
    return pipe(TaskConst.agencyList.find((x) => x.value === agencyCode),
      O.fromNullable,
      O.map((item) => item.label),
      O.getOrElse(() => '')
    )
  }

  const getColumnsByBasketType = () => {
    let columnList: any = []
    switch (selectedBasket?.basketType) {
      case SubTaskType.REQC:
      case SubTaskType.QC: {
        columnList = [
          {
            label: '',
            disabled: true
          },
          {
            label: t('Outbound:OBTaskManagement:PolicyNumber'),
            field: 'policyNumber',
            disabled: true
          },
          {
            label: t('Outbound:OBTaskManagement:CallingProgram'),
            field: 'transactionType'
          },
          {
            label: t('Outbound:OBTaskManagement:ClientNumber'),
            field: 'clientNumber'
          },
          {
            label: t('Outbound:OBTaskManagement:ClientName'),
            field: 'clientName',
            disabled: true
          },
          {
            label: t('Outbound:OBTaskManagement:LastAssignee'),
            field: 'lastAssignee',
            visible: true
          },
          {
            label: t('Outbound:OBTaskManagement:LastActivity'),
            field: 'lastActivity'
          },
          {
            label: t('Outbound:OBTaskManagement:AppointmentDate'),
            field: 'appointmentDate',
          },
          {
            label: t('Outbound:OBTaskManagement:FirstIssueDate'),
            field: 'policyFirstIssuedDate',
          },
          {
            label: t('Outbound:OBTaskManagement:LastIssueDate'),
            field: 'policyLastIssuedDate',
          },
          {
            label: t('Outbound:OBTaskManagement:CreatedDate'),
            field: 'createdDate'
          },
          {
            label: t('Outbound:OBTaskManagement:SubmitDate'),
            field: 'lastUpdateByVer',
            visible: true,
            disabled: true
          },
          {
            label: t('Outbound:OBTaskManagement:ExecutionTime'),
            field: 'executionTime',
            disabled: true
          },
          {
            label: t('Outbound:OBTaskManagement:VIP/NonVIPofPolicyowner'),
            field: 'vip',
            disabled: true
          },
          {
            label: t('Outbound:OBTaskManagement:Leader'),
            field: 'leader'
          },
          {
            label: t('Outbound:OBTaskManagement:Agency'),
            field: 'agency',
            disabled: true
          },
          {
            label: t('Outbound:OBTaskManagement:CaseID'),
            field: 'caseId',
            disabled: true
          },
          {
            label: t('Outbound:OBTaskManagement:QCAssignee'),
            field: 'assignee',
            visible: true
          }
        ]
        break
      }
      case SubTaskType.ErrorHandlingOB: {
        columnList = [
          {
            label: t('Outbound:OBTaskManagement:CallingProgram'),
            field: 'transactionType'
          },
          {
            label: t('Outbound:OBTaskManagement:PolicyNumber'),
            field: 'policyNumber',
            disabled: true
          },
          {
            label: t('Outbound:OBTaskManagement:ClientNumber'),
            field: 'clientNumber'
          },
          {
            label: t('Outbound:OBTaskManagement:ClientName'),
            field: 'clientName',
            disabled: true
          },
          {
            label: t('Outbound:OBTaskManagement:Agency'),
            field: 'agency',
            disabled: true
          },
          {
            label: t('Outbound:OBTaskManagement:DuplicatePO'),
            field: 'isDuplicatePo',
            visible: true
          },
          {
            label: t('Outbound:OBTaskManagement:AppointmentDate'),
            field: 'appointmentDate',
          },
          {
            label: t('Outbound:OBTaskManagement:CreatedDate'),
            field: 'createdDate'
          },
          {
            label: t('Outbound:OBTaskManagement:TotalAging'),
            field: 'agingDays',
            visible: true
          },
          {
            label: t('Outbound:OBTaskManagement:ExecutionTime'),
            field: 'executionTime',
            disabled: true
          },
          {
            label: t('Outbound:OBTaskManagement:CurrentAssignee'),
            field: 'assignee',
            visible: true
          },
          {
            label: t('Outbound:OBTaskManagement:Leader'),
            field: 'leader'
          },
          {
            label: t('Outbound:OBTaskManagement:StuckTime'),
            field: 'stuckDate'
          },
          {
            label: t('Outbound:OBTaskManagement:CurrentActivity'),
            field: 'currentActivityName'
          },
          {
            label: t('Outbound:OBTaskManagement:CaseID'),
            field: 'caseId',
            disabled: true
          }
        ]
        break
      }
      case SubTaskType.Suspend:
      case SubTaskType.Verification:
      default:
        {
          columnList = [
            {
              label: '',
              disabled: true,
              columnRender: (itemList: any) => {
                return [SubTaskType.Suspend].includes(selectedBasket?.basketType as SubTaskType) ? (
                  <Checkbox
                    value={taskList && taskList.length > 0 ? props.selectedTaskList?.size === taskList.length : false}
                    onChange={(val) => {
                      onAllSelectedRowEvent(val, itemList)
                    }}
                    disabled={false}
                    checkBoxStyle={tableStyles.tableCheckBox}
                  />
                ) : undefined
              }
            },
            {
              label: t('Outbound:OBTaskManagement:PolicyNumber'),
              field: 'policyNumber',
              disabled: true
            },
            {
              label: t('Outbound:OBTaskManagement:CallingProgram'),
              field: 'transactionType'
            },
            {
              label: t('Outbound:OBTaskManagement:Agency'),
              field: 'agency',
              disabled: true
            },
            {
              label: t('Outbound:OBTaskManagement:ExecutionTime'),
              field: 'executionTime',
              disabled: true
            },
            {
              label: t('Outbound:OBTaskManagement:ClientNumber'),
              field: 'clientNumber'
            },
            {
              label: t('Outbound:OBTaskManagement:ClientName'),
              field: 'clientName',
              disabled: true
            },
            {
              label: t('Outbound:OBTaskManagement:CurrentAssignee'),
              field: 'assignee',
              visible: true
            },
            {
              label: t('Outbound:OBTaskManagement:LastAssignee'),
              field: 'lastVerUpdate',
              visible: [SubTaskType.Suspend].includes(selectedBasket?.basketType as SubTaskType)
            },
            {
              label: t('Outbound:OBTaskManagement:CaseStatus'),
              field: 'status',
              disabled: true,
              visible: true
            },
            {
              label: t('Outbound:OBTaskManagement:LastActivity'),
              field: 'lastActivity'
            },
            {
              label: t('Outbound:OBTaskManagement:DuplicatePO'),
              field: 'isDuplicatePo',
              visible: true
            },
            {
              label: t('Outbound:OBTaskManagement:AppointmentDate'),
              field: 'appointmentDate',
            },
            {
              label: t('Outbound:OBTaskManagement:CreatedDate'),
              field: 'createdDate'
            },
            {
              label: t('Outbound:OBTaskManagement:FirstIssueDate'),
              field: 'policyFirstIssuedDate',
            },
            {
              label: t('Outbound:OBTaskManagement:LastIssueDate'),
              field: 'policyLastIssuedDate',
            },
            {
              label: t('Outbound:OBTaskManagement:TotalAging'),
              field: 'agingDays',
              visible: true
            },
            {
              label: t('Outbound:OBTaskManagement:AgingDays'),
              field: 'taskAgingDays',
              visible: true
            },
            {
              label: t('Outbound:OBTaskManagement:VIP/NonVIPofPolicyowner'),
              field: 'vip',
              disabled: true
            },
            {
              label: t('Outbound:OBTaskManagement:Leader'),
              field: 'leader'
            },
            {
              label: t('Outbound:OBTaskManagement:CaseID'),
              field: 'caseId',
              disabled: true
            }
          ]
          break
        }

    }
    return columnList
  }

  const getTableCellByBasketType = (dataItem: OBTaskQueryData): JSX.Element => {
    switch (selectedBasket?.basketType) {
      case SubTaskType.REQC:
      case SubTaskType.QC:
        return (
          <>
            <TableCell className={classes.cellNoWrap}>
              <Checkbox
                value={props.selectedTaskList?.has(dataItem.id ?? '')}
                onChange={(val) => {
                  onRowSelectEvent(val, dataItem)
                }}
                disabled={false}
                checkBoxStyle={tableStyles.tableCheckBox}
              />
            </TableCell>
            <TableCell className={classes.cellNoWrap}>
              <Pressable onPress={() => onCaseDetailView(dataItem)}>
                <Text style={tableStyles.tableCellText}>{dataItem.policyNumber}</Text>
              </Pressable>
            </TableCell>
            <TableCell className={classes.cellNoWrap}>{dataItem.transactionType ? getLabelCallProgram(dataItem.transactionType) : ''}</TableCell>
            <TableCell className={classes.cellNoWrap}>{dataItem.clientNumber}</TableCell>
            <TableCell className={classes.cellNoWrap}>{dataItem.poName}</TableCell>
            <TableCell>{dataItem.lastAssignee || ''}</TableCell>
            <TableCell className={classes.cellNoWrap}>{getLastActivityName(dataItem.lastActivity || '')}</TableCell>
            <TableCell className={classes.cellNoWrap}>{dataItem.appointmentDate ? PulseOpsFormat.dateStringtoFormat(dataItem.appointmentDate, 'DD/MM/YYYY - HH:mm:ss') : ''}</TableCell>
            <TableCell className={classes.cellNoWrap}>{dataItem.policyFirstIssuedDate ? PulseOpsFormat.dateStringtoFormat(dataItem.policyFirstIssuedDate, 'DD/MM/YYYY') : ''}</TableCell>
            <TableCell className={classes.cellNoWrap}>{dataItem.policyLastIssuedDate ? PulseOpsFormat.dateStringtoFormat(dataItem.policyLastIssuedDate, 'DD/MM/YYYY') : ''}</TableCell>
            <TableCell className={classes.cellNoWrap} style={{ minWidth: 170 }}>{!!dataItem.createdDate ? OBFormat.formatDateToDateString(dataItem.createdDate) : ''}</TableCell>
            <TableCell className={classes.cellNoWrap}>{!!dataItem.lastUpdateByVer ? OBFormat.formatDateToDateString(new Date(dataItem.lastUpdateByVer)) : ''}</TableCell>
            <TableCell className={classes.cellNoWrap}>{dataItem.executionTime ?? 0}</TableCell>
            <TableCell className={classes.cellNoWrap}>{dataItem.vip}</TableCell>
            <TableCell className={classes.cellNoWrap}>
              {(dataItem.assignee ? (dataItem.leader ?? '') : '')}
            </TableCell>
            <TableCell className={classes.cellNoWrap}>{getAgencyName(dataItem.agency || '')}</TableCell>
            <TableCell className={classes.cellNoWrap} style={{ minWidth: 350 }}>{dataItem.businessKey}</TableCell>
            <TableCell className={classes.cellNoWrap}>{dataItem.assignee ?? ''}</TableCell>
          </>
        )
      case SubTaskType.ErrorHandlingOB:
        return (
          <>
            <TableCell className={classes.cellNoWrap}>{dataItem.transactionType ? getLabelCallProgram(dataItem.transactionType) : ''}</TableCell>
            <TableCell className={classes.cellNoWrap}>{dataItem.policyNumber}</TableCell>
            <TableCell className={classes.cellNoWrap}>{dataItem.clientNumber}</TableCell>
            <TableCell className={classes.cellNoWrap}>{dataItem.poName}</TableCell>
            <TableCell className={classes.cellNoWrap}>{getAgencyName(dataItem.agency || '')}</TableCell>
            <TableCell className={classes.cellNoWrap}>{dataItem.isDuplicatePo ? 'True' : 'False'}</TableCell>
            <TableCell className={classes.cellNoWrap}>{dataItem.appointmentDate ? PulseOpsFormat.dateStringtoFormat(dataItem.appointmentDate, 'DD/MM/YYYY - HH:mm:ss') : ''}</TableCell>
            <TableCell className={classes.cellNoWrap} style={{ minWidth: 170 }}>{!!dataItem.createdDate ? OBFormat.formatDateToDateString(dataItem.createdDate) : ''}</TableCell>
            <TableCell className={classes.cellNoWrap}>{dataItem.agingDays}</TableCell>
            <TableCell className={classes.cellNoWrap}>{dataItem.executionNumber ?? 0}</TableCell>
            <TableCell className={classes.cellNoWrap}>{dataItem.assignee ?? ''}</TableCell>
            <TableCell className={classes.cellNoWrap}>{(dataItem.assignee ? (dataItem.leader ?? '') : '')}</TableCell>
            <TableCell className={classes.cellNoWrap}>{dataItem.stuckDate ? PulseOpsFormat.dateStringtoFormat(dataItem.stuckDate, 'DD/MM/YYYY - HH:mm:ss') : ''}</TableCell>
            <TableCell className={classes.cellNoWrap}>{dataItem.currentActivityName}</TableCell>
            <TableCell className={classes.cellNoWrap} style={{ minWidth: 350 }}>{dataItem.businessKey}</TableCell>
          </>
        )
      case SubTaskType.Suspend:
      case SubTaskType.Verification:
      default:
        return (
          <>
            <TableCell className={classes.cellNoWrap}>
              <Checkbox
                value={props.selectedTaskList?.has(dataItem.id ?? '')}
                onChange={(val) => {
                  onRowSelectEvent(val, dataItem)
                }}
                disabled={false}
                checkBoxStyle={tableStyles.tableCheckBox}
              />
            </TableCell>
            <TableCell className={classes.cellNoWrap}>
              <Pressable onPress={() => onCaseDetailView(dataItem)}>
                <Text style={tableStyles.tableCellText}>{dataItem.policyNumber}</Text>
              </Pressable>
            </TableCell>
            <TableCell className={classes.cellNoWrap}>{dataItem.transactionType ? getLabelCallProgram(dataItem.transactionType) : ''}</TableCell>
            <TableCell className={classes.cellNoWrap}>{getAgencyName(dataItem.agency || '')}</TableCell>
            <TableCell className={classes.cellNoWrap}>{dataItem.executionTime ?? 0}</TableCell>
            <TableCell className={classes.cellNoWrap}>{dataItem.clientNumber}</TableCell>
            <TableCell className={classes.cellNoWrap}>{dataItem.poName}</TableCell>
            {[SubTaskType.Verification, SubTaskType.Suspend].includes(selectedBasket?.basketType as SubTaskType) && (<><TableCell className={classes.cellNoWrap}>{dataItem.assignee ?? ''}</TableCell></>)}
            {[SubTaskType.Suspend].includes(selectedBasket?.basketType as SubTaskType) && <><TableCell>{dataItem.lastVerUpdate || ''}</TableCell></>}
            {[SubTaskType.Verification, SubTaskType.Suspend].includes(selectedBasket?.basketType as SubTaskType) &&
              <>
                <TableCell className={classes.cellNoWrap} style={{ minWidth: 120 }}>
                  <OBTaskStatus statusText={dataItem.status || ''}></OBTaskStatus>
                </TableCell>
              </>
            }
            <TableCell className={classes.cellNoWrap}>{getLastActivityName(dataItem.lastActivity || '')}</TableCell>
            {[SubTaskType.Verification, SubTaskType.Suspend].includes(selectedBasket?.basketType as SubTaskType) && <><TableCell className={classes.cellNoWrap}>{dataItem.isDuplicatePo ? 'True' : 'False'}</TableCell></>}
            <TableCell className={classes.cellNoWrap}>{dataItem.appointmentDate ? PulseOpsFormat.dateStringtoFormat(dataItem.appointmentDate, 'DD/MM/YYYY - HH:mm:ss') : ''}</TableCell>
            <TableCell className={classes.cellNoWrap} style={{ minWidth: 170 }}>{!!dataItem.createdDate ? OBFormat.formatDateToDateString(dataItem.createdDate) : ''}</TableCell>
            <TableCell className={classes.cellNoWrap}>{dataItem.policyFirstIssuedDate ? PulseOpsFormat.dateStringtoFormat(dataItem.policyFirstIssuedDate, 'DD/MM/YYYY') : ''}</TableCell>
            <TableCell className={classes.cellNoWrap}>{dataItem.policyLastIssuedDate ? PulseOpsFormat.dateStringtoFormat(dataItem.policyLastIssuedDate, 'DD/MM/YYYY') : ''}</TableCell>
            {[SubTaskType.Verification, SubTaskType.Suspend].includes(selectedBasket?.basketType as SubTaskType) && (
              <>
                <TableCell className={classes.cellNoWrap}>{dataItem.agingDays}</TableCell>
                <TableCell className={classes.cellNoWrap}>{selectedBasket?.basketType === 'SUSPENDED' ? dataItem.suspendAgingDays : dataItem.taskAgingDays}</TableCell>
              </>
            )}
            <TableCell className={classes.cellNoWrap}>{dataItem.vip}</TableCell>
            <TableCell className={classes.cellNoWrap}>
              {SubTaskType.Suspend === selectedBasket?.basketType ?
                (dataItem.lastVerUpdate ? (dataItem.leader ?? '') : '') :
                (dataItem.assignee ? (dataItem.leader ?? '') : '')}
            </TableCell>
            <TableCell className={classes.cellNoWrap} style={{ minWidth: 350 }}>{dataItem.businessKey}</TableCell>
          </>
        )
    }
  }

  return (
    <View>
      <TableContainer component={Paper} className={classes.container}>
        <Table stickyHeader>
          <TableHead>
            <TableHeaderSort
              data={taskList}
              setData={setTaskList}
              setSortItem={setSoftItem}
              sortItem={softItem}
              column={[
                ...getColumnsByBasketType()
                // {
                //   label: '',
                //   disabled: true
                // },
                // {
                //   label: t('Outbound:OBTaskManagement:PolicyNumber'),
                //   field: 'policyNumber',
                //   disabled: true
                // },
                // {
                //   label: t('Outbound:OBTaskManagement:CallingProgram'),
                //   field: 'transactionType'
                // },
                // {
                //   label: t('Outbound:OBTaskManagement:ClientNumber'),
                //   field: 'clientNumber'
                // },
                // {
                //   label: t('Outbound:OBTaskManagement:ClientName'),
                //   field: 'clientName',
                //   disabled: true
                // },
                // {
                //   label: t('Outbound:OBTaskManagement:CurrentAssignee'),
                //   field: 'assignee',
                //   visible: [SubTaskType.Verification, SubTaskType.Suspend].includes(selectedBasket?.basketType as SubTaskType)
                // },
                // {
                //   label: t('Outbound:OBTaskManagement:LastAssignee'),
                //   field: SubTaskType.Suspend === selectedBasket?.basketType ? 'lastVerUpdate' : 'lastAssignee',
                //   visible: [SubTaskType.QC, SubTaskType.Suspend].includes(selectedBasket?.basketType as SubTaskType)
                // },
                // {
                //   label: t('Outbound:OBTaskManagement:CaseStatus'),
                //   field: 'status',
                //   disabled: true,
                //   visible: [SubTaskType.Verification, SubTaskType.Suspend].includes(selectedBasket?.basketType as SubTaskType)
                // },
                // {
                //   label: t('Outbound:OBTaskManagement:DuplicatePO'),
                //   field: 'isDuplicatePo',
                //   visible: [SubTaskType.Verification, SubTaskType.Suspend].includes(selectedBasket?.basketType as SubTaskType)
                // },
                // {
                //   label: t('Outbound:OBTaskManagement:LastActivity'),
                //   field: 'lastActivity'
                // },
                // {
                //   label: t('Outbound:OBTaskManagement:AppointmentDate'),
                //   field: 'appointmentDate',
                // },
                // {
                //   label: t('Outbound:OBTaskManagement:FirstIssueDate'),
                //   field: 'policyFirstIssuedDate',
                // },
                // {
                //   label: t('Outbound:OBTaskManagement:LastIssueDate'),
                //   field: 'policyLastIssuedDate',
                // },
                // {
                //   label: t('Outbound:OBTaskManagement:CreatedDate'),
                //   field: 'createdDate'
                // },
                // {
                //   label: t('Outbound:OBTaskManagement:SubmitDate'),
                //   field: 'lastUpdateByVer',
                //   visible: [SubTaskType.QC].includes(selectedBasket?.basketType as SubTaskType),
                //   disabled: true
                // },
                // {
                //   label: t('Outbound:OBTaskManagement:TotalAging'),
                //   field: 'agingDays',
                //   visible: [SubTaskType.Verification, SubTaskType.Suspend].includes(selectedBasket?.basketType as SubTaskType)
                // },
                // {
                //   label: t('Outbound:OBTaskManagement:AgingDays'),
                //   field: selectedBasket?.basketType === SubTaskType.Suspend ? 'suspendAgingDays' : 'taskAgingDays',
                //   visible: [SubTaskType.Verification, SubTaskType.Suspend].includes(selectedBasket?.basketType as SubTaskType)
                // },
                // {
                //   label: t('Outbound:OBTaskManagement:ExecutionTime'),
                //   field: 'executionTime',
                //   disabled: true
                // },
                // {
                //   label: t('Outbound:OBTaskManagement:VIP/NonVIPofPolicyowner'),
                //   field: 'vip',
                //   disabled: true
                // },
                // {
                //   label: t('Outbound:OBTaskManagement:Leader'),
                //   field: 'leader'
                // },
                // {
                //   label: t('Outbound:OBTaskManagement:Agency'),
                //   field: 'agency',
                //   disabled: true
                // },
                // {
                //   label: t('Outbound:OBTaskManagement:CaseID'),
                //   field: 'caseId',
                //   disabled: true
                // },
                // {
                //   label: t('Outbound:OBTaskManagement:QCAssignee'),
                //   field: 'assignee',
                //   visible: [SubTaskType.QC].includes(selectedBasket?.basketType as SubTaskType)
                // }
              ]}
            />
          </TableHead>
          <TableBody>
            {taskList &&
              taskList.length > 0 &&
              taskList.map((dataItem, index) => {
                // console.log('selectedTaskMap: ' + props.selectedTaskList?.has(dataItem.id ?? ''))
                return (
                  <TableRow key={`dataItem_${index}`} style={{ backgroundColor: dataItem.isDuplicatePo ? '#ed1b2e26' : '#fff' }}>
                    {getTableCellByBasketType(dataItem)}
                    {/* <TableCell className={classes.cellNoWrap}>
                      <Checkbox
                        value={props.selectedTaskList?.has(dataItem.id ?? '')}
                        onChange={(val) => {
                          onRowSelectEvent(val, dataItem)
                        }}
                        disabled={false}
                        checkBoxStyle={tableStyles.tableCheckBox}
                      />
                    </TableCell>
                    <TableCell className={classes.cellNoWrap}>
                      <Pressable onPress={() => onCaseDetailView(dataItem)}>
                        <Text style={tableStyles.tableCellText}>{dataItem.policyNumber}</Text>
                      </Pressable>
                    </TableCell>
                    <TableCell className={classes.cellNoWrap}>{dataItem.transactionType ? getLabelCallProgram(dataItem.transactionType) : ''}</TableCell>
                    <TableCell className={classes.cellNoWrap}>{dataItem.clientNumber}</TableCell>
                    <TableCell className={classes.cellNoWrap}>{dataItem.poName}</TableCell>
                    {[SubTaskType.Verification, SubTaskType.Suspend].includes(selectedBasket?.basketType as SubTaskType) && (<><TableCell className={classes.cellNoWrap}>{dataItem.assignee ?? ''}</TableCell></>)}
                    {[SubTaskType.QC, SubTaskType.REQC, SubTaskType.Suspend].includes(selectedBasket?.basketType as SubTaskType) && <><TableCell>{selectedBasket?.basketType === SubTaskType.Suspend ? dataItem.lastVerUpdate : dataItem.lastAssignee || ''}</TableCell></>}
                    {[SubTaskType.Verification, SubTaskType.Suspend].includes(selectedBasket?.basketType as SubTaskType) &&
                      <>
                        <TableCell className={classes.cellNoWrap} style={{ minWidth: 120 }}>
                          <OBTaskStatus statusText={dataItem.status || ''}></OBTaskStatus>
                        </TableCell>
                      </>
                    }
                    {[SubTaskType.Verification, SubTaskType.Suspend].includes(selectedBasket?.basketType as SubTaskType) && <><TableCell className={classes.cellNoWrap}>{dataItem.isDuplicatePo ? 'True' : 'False'}</TableCell></>}
                    <TableCell className={classes.cellNoWrap}>{getLastActivityName(dataItem.lastActivity || '')}</TableCell>
                    <TableCell className={classes.cellNoWrap}>{dataItem.appointmentDate ? PulseOpsFormat.dateStringtoFormat(dataItem.appointmentDate, 'DD/MM/YYYY - HH:mm:ss') : ''}</TableCell>
                    <TableCell className={classes.cellNoWrap}>{dataItem.policyFirstIssuedDate ? PulseOpsFormat.dateStringtoFormat(dataItem.policyFirstIssuedDate, 'DD/MM/YYYY') : ''}</TableCell>
                    <TableCell className={classes.cellNoWrap}>{dataItem.policyLastIssuedDate ? PulseOpsFormat.dateStringtoFormat(dataItem.policyLastIssuedDate, 'DD/MM/YYYY') : ''}</TableCell>
                    <TableCell className={classes.cellNoWrap} style={{ minWidth: 170 }}>{!!dataItem.createdDate ? OBFormat.formatDateToDateString(dataItem.createdDate) : ''}</TableCell>
                    {[SubTaskType.QC, SubTaskType.REQC].includes(selectedBasket?.basketType as SubTaskType) && (<TableCell className={classes.cellNoWrap}>{!!dataItem.lastUpdateByVer ? OBFormat.formatDateToDateString(new Date(dataItem.lastUpdateByVer)) : ''}</TableCell>)}
                    {[SubTaskType.Verification, SubTaskType.Suspend].includes(selectedBasket?.basketType as SubTaskType) && (
                      <>
                        <TableCell className={classes.cellNoWrap}>{dataItem.agingDays}</TableCell>
                        <TableCell className={classes.cellNoWrap}>{selectedBasket?.basketType === 'SUSPENDED' ? dataItem.suspendAgingDays : dataItem.taskAgingDays}</TableCell>
                      </>
                    )}
                    <TableCell className={classes.cellNoWrap}>{dataItem.executionTime ?? 0}</TableCell>
                    <TableCell className={classes.cellNoWrap}>{dataItem.vip}</TableCell>
                    <TableCell className={classes.cellNoWrap}>
                      {SubTaskType.Suspend === selectedBasket?.basketType ?
                        (dataItem.lastVerUpdate ? (dataItem.leader ?? '') : '') :
                        (dataItem.assignee ? (dataItem.leader ?? '') : '')}
                    </TableCell>

                    <TableCell className={classes.cellNoWrap}>{getAgencyName(dataItem.agency || '')}</TableCell>
                    <TableCell className={classes.cellNoWrap} style={{ minWidth: 350 }}>{dataItem.businessKey}</TableCell>
                    {[SubTaskType.QC, SubTaskType.REQC].includes(selectedBasket?.basketType as SubTaskType) && (<TableCell className={classes.cellNoWrap}>{dataItem.assignee ?? ''}</TableCell>)} */}
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 50, 100]}
        count={totalRecord}
        rowsPerPage={pageSize}
        page={pageNum}
        onPageChange={(e, page) => {
          props.setPageNum && props.setPageNum(page)
          props.onPageChange && props.onPageChange(page, pageSize)
        }}
        onRowsPerPageChange={(e) => {
          const pageS = Number(e.target.value)
          props.setPageSize && props.setPageSize(pageS)
          if (totalRecord <= (pageNum * pageS)) {
            props.setPageNum && props.setPageNum(0)
            props.onRowsPerPageChange && props.onRowsPerPageChange(0, pageS)
          } else {
            props.onRowsPerPageChange && props.onRowsPerPageChange(pageNum, pageS)
          }
        }}
        labelRowsPerPage={t('common:PaginationSize')}
        labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
        component={View}
      ></TablePagination>
    </View>
  )
}
const tableStyles = StyleSheet.create({
  headerText: {
    color: '#000',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: '600'
  },
  tableCellText: {
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 24,
    textDecorationLine: 'underline',
    color: '#1EA5FC'
  },
  tableCheckBox: {
    padding: 0,
    marginTop: 0,
    paddingLeft: 10
  }
})
