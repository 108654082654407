import * as api from '../task-detail-api'
import { TransactionType } from '../TransactionType'
import { GeneralData } from '../../GeneralService'
import { pipe } from 'fp-ts/function'
import * as O from 'fp-ts/lib/Option'
import { ReinstatementModel } from '../Reinstatement'
import { MajorCombined } from '../MajorCombined'
import { LifeAssured } from './Reinstatement'
import { Frequency, mapFrequency } from '../Frequency'
import { RiderReinstatementPayload } from './RiderReinstatement'
import { InquiryComplaintData, PulseOpsFormat } from '@pulseops/common'
import { BillingChange as BillingChangeType } from '../BillingChange'
import { VeriCanRiderClientInfo } from './CancelRider'
import { VERiderAlterationInfo, VeriLARiderInfo } from './RiderAlteration'
export interface MajorCombinedPayload {
  majorType: string
  changeContract: MajorCombined.ChangeContractType | undefined | null
  billingChange: (MajorCombined.BillingChange & { suspendAmount?: string; advancePremium?: string }) | undefined | null
  productOptionSwitching: (MajorCombined.ProductOptionSwitching & ValueProductOptionSwitching) | undefined | null
  riderAlteration: (MajorCombined.VeRiderAlteration & { waiverList?: string[] }) | undefined | null
  riderCancellation: (MajorCombined.CancelRider & { totalPremium?: string }) | undefined | null
  reinstatement: ReinstatementData | undefined | null
  riderReinstatement: RiderReinstatementPayload | undefined | null
  requirePayinAmount: string | undefined | null
}
export interface VERIMajorCombined {
  tag: TransactionType.MAJOR_COMBINED_CHANGE
  clientName: string
  submissionData: MajorCombinedPayload
  riderNameList: InquiryComplaintData.IACSources[] | undefined
}

interface ValueProductOptionSwitching {
  coverageCode?: string
  benefitOption?: string
  currentSumAssured?: number
  curRenewalPremium?: number
}
interface ReinstatementData {
  riskCommencementDate: string
  lapsedDate: string
  paymentFrequency: string
  installmentPremium: number
  reinstatementFee: number | null
  totalReinsAmount: number | null
  apl: number
  opl: number
  lifeAssured: LifeAssured[]
  agentNumber: string
  servicingAgent: string
}

const mapDataReins = (payloadData: api.MajorCombined, occupationList: GeneralData[]): ReinstatementData | undefined => {
  const dataReins = payloadData.payload.body.reinstatement
  if (dataReins) {
    const data = {
      lifeAssured: dataReins?.lifeAssured.length
        ? dataReins.lifeAssured.map(
            (fund): LifeAssured => ({
              clientNumber: fund.clientId || '-',
              laName: fund.name || '-',
              curOccupation: pipe(
                occupationList.find((x) => x.code === fund.attributesExt?.CUR_OCCUPATION),
                O.fromNullable,
                O.map((item) => item && item.name),
                O.getOrElse(() => '')
              ),
              newOccupation: pipe(
                occupationList.find((x) => x.code === fund.attributesExt?.NEW_OCCUPATION ?? '-'),
                O.fromNullable,
                O.map((item) => item && item.name),
                O.getOrElse(() => '')
              ),
              newActivity: fund.attributesExt?.NEW_ACTIVITY || '',
              newCompany: fund.attributesExt?.COMPANY_NAME || '',
              newHeathState: !!fund.attributesExt?.IS_CHANGE_HEALTH_STATE
            })
          )
        : [],
      agentNumber: dataReins?.attributesExt.AGENT_CODE || '',
      servicingAgent: dataReins?.attributesExt.AGENT_NAME || ''
    }
    const { APL, OPL, REINS_FEE, TOTAL_REINS_AMOUNT, LAPSED_DATE, RISK_COMM_DATE, FREQUENCY, INSTALLMENT_PREM } =
      dataReins.attributesExt

    return {
      riskCommencementDate: RISK_COMM_DATE?.length ? PulseOpsFormat.dateStr(RISK_COMM_DATE) : '-',
      paymentFrequency: FREQUENCY ? mapFrequency.get(FREQUENCY as Frequency) ?? '-' : '-', //missing
      apl: APL ?? 0,
      opl: OPL ?? 0,
      installmentPremium: Number(INSTALLMENT_PREM),
      reinstatementFee: Number(REINS_FEE),
      totalReinsAmount: Number(TOTAL_REINS_AMOUNT),
      lapsedDate: LAPSED_DATE?.length ? PulseOpsFormat.dateStr(LAPSED_DATE) : '-',
      lifeAssured: data?.lifeAssured ?? [],
      agentNumber: data.agentNumber ?? '',
      servicingAgent: data.servicingAgent ?? ''
    }
  }
  return undefined
}

export const MajorCombinedChange = (
  detail: api.MajorCombined,
  poName: string,
  occupationList: GeneralData[],
  reinData?: ReinstatementModel.ReinData,
  valueProductOption?: ValueProductOptionSwitching,
  valueBillingChange?: BillingChangeType.Detail,
  riderClientInfo?: VeriCanRiderClientInfo[],
  totalPremium?: number | null | undefined,
  waiverList?: string[],
  riderNameList?: InquiryComplaintData.IACSources[]
): VERIMajorCombined => {
  let payloadData = detail.payload.body
  const LAListRider = payloadData.riderReinstatement?.lifeAssuredList.map((LAItem) => {
    let currentOccupation = '',
      newOccupation = ''
    if (LAItem.occupationChange) {
      currentOccupation = pipe(
        occupationList.find((x) => x.code === LAItem.curOccupation),
        O.fromNullable,
        O.map((item) => item && item.name),
        O.getOrElse(() => '')
      )
      newOccupation = pipe(
        occupationList.find((x) => x.code === LAItem.newOccupation),
        O.fromNullable,
        O.map((item) => item && item.name),
        O.getOrElse(() => '')
      )
    }
    const clientName = pipe(
      reinData?.lifeAssured.find((x) => x.clientId === LAItem.clientNo),
      O.fromNullable,
      O.map((item) => item && item.name),
      O.getOrElse(() => '')
    )
    return {
      ...LAItem,
      clientName: clientName,
      curOccupation: currentOccupation,
      newOccupation: newOccupation
    }
  })

  const laRiderList: VeriLARiderInfo[] =
    payloadData.riderAlteration?.lifeAssuredList?.map((item) => {
      return {
        lifeAssured: item.clientNo,
        clientName: riderClientInfo?.find((x) => x.clientNumberOfLA === item.clientNo)?.clientName || '',
        coreRiders: item.riderList.map((x) => {
          return {
            productCode: x.coverageCode,
            lifeNo: x.lifeNo,
            coverageNo: x.coverageNo,
            riderNo: x.riderNo,
            riderStatus: x.riderStatus,
            riskCommDate: x.riskCommDate,
            riskCessDate: x.riskCessDate,
            sumAssured: x.sumAssured,
            installmentPremium: x.installmentPremium,
            alteredSumAssured: String(x.alteredSumAssured ?? '')
          }
        }) as VERiderAlterationInfo[]
      }
    }) || []

  const lifeAssuredAdditions = payloadData.riderAlteration
    ? payloadData.riderAlteration?.lifeAssuredAdditions.map((item) => {
        return {
          lifeAssured: {
            ...item.lifeAssured,
            isNewLA: item.lifeAssured.isNewLA,
            isUSTax: item.lifeAssured.isUSTax,
            issuePlace: item.lifeAssured.issuePlace ?? '',
            lifeNo: item.lifeAssured.lifeNo ?? '',
            clientNumber: item.lifeAssured.clientNumber ?? '',
            addressDetails: {
              ADDRESS: {
                line1: item.lifeAssured.addressDetails?.ADDRESS?.line1 ?? '',
                city: item.lifeAssured.addressDetails?.ADDRESS?.city ?? '',
                district: item.lifeAssured.addressDetails?.ADDRESS?.district ?? '',
                subDistrict: item.lifeAssured.addressDetails?.ADDRESS?.subDistrict ?? '',
                countryCode: item.lifeAssured.addressDetails?.ADDRESS?.countryCode ?? ''
              },
              FOREIGN_ADDRESS: {
                line1: item.lifeAssured.addressDetails?.FOREIGN_ADDRESS?.line1 ?? '',
                countryCode: item.lifeAssured.addressDetails?.FOREIGN_ADDRESS?.countryCode ?? ''
              }
            },
            attributesExt: {
              NATIONALITY_2: item.lifeAssured.attributesExt?.NATIONALITY_2 ?? '',
              nationality2Name: item.lifeAssured.attributesExt?.nationality2Name ?? '',
              countryName: item.lifeAssured.attributesExt?.countryName ?? '',
              districtName: item.lifeAssured.attributesExt?.districtName ?? '',
              wardName: item.lifeAssured.attributesExt?.wardName ?? '',
              cityCode: item.lifeAssured.attributesExt?.cityCode ?? '',
              districtCode: item.lifeAssured.attributesExt?.districtCode ?? '',
              subDistrictCode: item.lifeAssured.attributesExt?.subDistrictCode ?? ''
            }
          }
        }
      })
    : []

  const sumRider = payloadData.riderCancellation?.lifeAssureds?.map((item) => {
    return item.riders.reduce((total, item) => total + Number(item.installmentPremium), 0)
  })
  const totalRider = sumRider?.reduce((total, item) => total + item, 0) || 0
  const totalPremiumCalc = Number(totalPremium ? totalPremium : 0) - totalRider

  const cancelRiderList =
    payloadData.riderCancellation?.lifeAssureds?.map((item) => {
      return {
        lifeAssured: item.lifeAssured,
        clientName: riderClientInfo?.find((x) => x.clientNumberOfLA === item.lifeAssured)?.clientName || '',
        riders: item.riders.map((x) => ({
          ...x,
          riskCommDate: PulseOpsFormat.dateStrWF(x.riskCommDate, 'DD/MM/YYYY'),
          riskCessDate: PulseOpsFormat.dateStrWF(x.riskCessDate, 'DD/MM/YYYY'),
          sumAssured: x.sumAssured,
          installmentPremium: x.installmentPremium ?? 0,
          status: x.riderStatus ?? ''
        }))
      }
    }) || []

  const reinstatementData = payloadData.reinstatement ? mapDataReins(detail, occupationList) : undefined

  const riderReinstatementData = payloadData.riderReinstatement
    ? {
        reinsFee: payloadData.riderReinstatement.reinsFee,
        lifeAssuredList: LAListRider || []
      }
    : undefined

  const riderAlterationData = payloadData.riderAlteration
    ? {
        isAddLA: payloadData.riderAlteration.isAddLA,
        isRiderAlteration: payloadData.riderAlteration.isRiderAlteration,
        lifeAssuredAdditions: lifeAssuredAdditions,
        lifeAssuredList: laRiderList,
        waiverList: waiverList ?? []
      }
    : undefined

  const riderCancelationData = payloadData.riderCancellation
    ? {
        policyNo: payloadData.riderCancellation.policyNo ?? '',
        lifeAssureds: cancelRiderList,
        totalPremium: PulseOpsFormat.thousandSepartor(totalPremiumCalc)
      }
    : undefined

  const productOptionData = payloadData.productOptionSwitching
    ? {
        ...payloadData.productOptionSwitching,
        ...valueProductOption
      }
    : undefined

  const billingChangeData = payloadData.billingChange
    ? {
        ...payloadData.billingChange,
        suspendAmount: valueBillingChange?.suspenseAmount,
        advancePremium: valueBillingChange?.advancePremium
      }
    : undefined

  const submissionData: MajorCombinedPayload = {
    majorType: payloadData.majorType,
    billingChange: billingChangeData,
    changeContract: payloadData.changeContract,
    productOptionSwitching: productOptionData,
    riderAlteration: riderAlterationData,
    riderCancellation: riderCancelationData,
    reinstatement: reinstatementData,
    riderReinstatement: riderReinstatementData,
    requirePayinAmount: payloadData.requirePayinAmount
  }

  return {
    tag: TransactionType.MAJOR_COMBINED_CHANGE,
    clientName: poName,
    submissionData: submissionData,
    riderNameList: riderNameList ?? []
  }
}
