import { pipe } from 'fp-ts/lib/function'
import { ReinstatementService } from './ReinstatementService'
import { ZIO, Task } from '@mxt/zio'
import { GeneralService } from './GeneralService'
import { LoanService } from './LoanService'
import { SubmissionService } from './SubmissionService'
import { SUFullSurrenderReversalData } from './model'
import * as O from 'fp-ts/lib/Option'

export namespace FullSurrenderReversalService {
  export const getDetail = (policyNum: string): Task<SUFullSurrenderReversalData.DetailData> =>
    pipe(
      ZIO.zipPar(
        LoanService.getPolicyLoanEnquiry(policyNum),
        // SubmissionService.getAccountBalance(policyNum, 'T512', [{ sacsCode: 'LP', sacsType: 'PS' }]),
        getSurrenderValueByAccountBalance(policyNum, 'T512TOTAL', 'T512', { sacsCode: 'LP', sacsType: 'PS' }),
        SubmissionService.getAccountBalance(policyNum, 'TV9U', [
          { sacsCode: 'LP', sacsType: 'EO' },
          { sacsCode: 'LN', sacsType: 'EI' }
        ]),
        SubmissionService.getPolicy(policyNum),
        ReinstatementService.getReinData(policyNum),
        GeneralService.getOccupations,
        ZIO.zipPar(
          getSubAccountBalanceByLXTF_LPSCode(policyNum),
          SubmissionService.getPolicies([{ policyNo: policyNum, function: "PAIDPREM" }])
        )
      ),
      ZIO.map(([loanEnquiry, surrenderValue, accountBalance2, policyInfo, reinData, occupationList, [LXTF_LPSBalance, policies]]) => {
        const balanceAPLOPL = Number(loanEnquiry.balanceAPL) + Number(loanEnquiry.balanceOPL)
        const interestAPLOPL = Number(loanEnquiry.interestAPL) + Number(loanEnquiry.interestOPL)
        const policyLoans = balanceAPLOPL + interestAPLOPL
        // const surrenderValue = accountBalance1.body.accountGroups?.reduce((total, next) => {
        //   return next.sacsType === 'PS' && next.amount ? total + Number(next.amount) : total
        // }, 0)
        const totalPaidCashBenefits = accountBalance2.body.accountGroups?.reduce((total, next) => {
          return ['EO', 'EI'].includes(next.sacsType) ? total + Number(next.amount) : total
        }, 0)
        const filterOccupationList = occupationList.map((item) => ({ ...item, id: item.id ?? '' }))
        const totalPrem = policies && policies.length > 0 ? policies[0].totalPremium : 0
        return {
          policyLoans: policyLoans,
          surrenderValue: surrenderValue,
          ptdateAdvance: policyInfo.body.paidToDateAdvance ?? '',
          billFreq: policyInfo.body.billFreq ?? '',
          // basicPrem: policyInfo.body.attributesExt?.basicPremium ?? 0,
          // riderPrem: policyInfo.body.attributesExt?.riderPremium ?? 0,
          totalPaidCashBenefits: totalPaidCashBenefits ?? 0,
          reinData,
          occupationList: filterOccupationList,
          LXTFval: LXTF_LPSBalance.LXTFval,
          LPSBalanceValue: LXTF_LPSBalance.LPSBalanceValue,
          totalPrem
        }
      })
    )

  export const getSurrenderValueByAccountBalance = (policyNum: string, functionType1: string, functionType2: string, accountGroup: {
    sacsCode: string,
    sacsType: string
  }) => pipe(
    SubmissionService.getAccountBalance(policyNum, functionType1, [accountGroup]),
    ZIO.flatMap((accountBalance) => {
      return pipe(accountBalance.body.accountGroups?.find(x => x.sacsCode == accountGroup.sacsCode && x.sacsType === accountGroup.sacsType && x.amount >= 0),
        O.fromNullable,
        O.fold(
          () => ZIO.succeed(0),
          () => pipe(
            SubmissionService.getAccountBalance(policyNum, functionType2, [accountGroup]),
            ZIO.map((subBalance) => {
              const surrenderValue = subBalance.body.accountGroups?.reduce((total, next) => {
                return next.sacsType === accountGroup.sacsType && next.amount ? total + Number(next.amount) : total
              }, 0)
              return surrenderValue ?? 0
            })
          ),
        )
      )
    })
  )

  export const getSubAccountBalanceByLXTF_LPSCode = (policyNum: string) => pipe(
    ZIO.zipPar(
      getSurrenderValueByAccountBalance(policyNum, 'T512TOTAL', 'T512', { sacsCode: 'LX', sacsType: 'TF' }),
      SubmissionService.getAccountBalance(policyNum, 'TOTAL', [{ sacsCode: 'LP', sacsType: 'S' }])
    ),
    ZIO.map(([LXTFval, LPSBalance]) => {
      const LPSBalanceValue = pipe(
        LPSBalance.body.accountGroups,
        O.fromNullable,
        O.map((accountGroups) => accountGroups.reduce((total, item) => total + item.amount, 0)),
        O.getOrElse(() => 0)
      )
      return {
        LXTFval, LPSBalanceValue
      }
    })
  )

  export const getAccessData = (policyNum: string) =>
    pipe(
      ZIO.zipPar(
        SubmissionService.getPolicy(policyNum),
        SubmissionService.getAccountBalance(policyNum, 'T512TOTAL', [{ sacsCode: 'LP', sacsType: 'PS' }])
      ),
      ZIO.map(([policyInfo, accountBalanceInfo]) => {
        const accountBalance = accountBalanceInfo.body.accountGroups?.reduce((total, next) => {
          return next.sacsType === 'PS' && !!next.amount ? total + Number(next.amount) : total
        }, 0)
        return {
          contractStatus: policyInfo.body.status ?? '',
          surrenderDate: policyInfo.body.surrenderDate,
          accountBalance: accountBalance ?? 0
        }
      })
    )
}
