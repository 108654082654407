import { FileUploadData, SelectOption } from '../../component'
import { RiderAlterationService } from '../RiderAlterationService'

export namespace RiderAlterationData {
  export type LifeAssuredAdditions = {
    lifeAssured: NewLifeAssured
  }

  export type RiderListAdditional = {
    coverageCode: string
    sumAssured: number
    policyPeriod: number
  }

  export type NewLifeAssured = {
    lastName: string
    firstName: string
    fullName: string
    isNewLA: boolean
    documentType: string
    idNumber: string
    issueDate: string
    issuePlace: string
    isUSTax: boolean
    dateOfBirth: string
    gender: string
    nationality: string
    mobileCode: string
    mobilePhone: string
    email: string
    occupation: string
    jobDescription: string
    jobTitle: string
    companyName: string
    companyAddress: string
    averageIncome: string
    lifeNo: string
    clientNumber: string
    riderListAdditional: Array<RiderListAdditional>
    duplicatePhones: {
      clientName: string
      clientID: string
      relationshipType: string
    }[]
    duplicateEmails: {
      clientName: string
      clientID: string
      relationshipType: string
    }[]
    addressDetails?: {
      ADDRESS?: {
        line1: string
        city: string
        district: string
        subDistrict: string
        countryCode: string
        location?: string
      },
      FOREIGN_ADDRESS?: {
        line1: string
        countryCode: string
      }
    },
    attributesExt?: {
      NATIONALITY_2: string
      nationality2Name: string
      countryName: string
      districtName: string
      wardName: string
      cityCode?: string
      districtCode?: string
      subDistrictCode?: string
    }
  }

  export type RiderList = {
    coverageCode: string
    riskCommDate: string
    riskCessDate: string
    sumAssured: number
    installmentPremium: number
    newInstallmentPremium: number
    alteredSumAssured: number
    lifeNo: string
    coverageNo: string
    riderNo: string
    riderStatus: string
  }
  export type LifeAssuredList = {
    clientNo: string
    riderList: Array<RiderList>
  }

  export type SubmissionData = {
    isRiderAlteration: boolean
    isAddLA: boolean
    requiredPayInAmount: number
    lifeAssuredList: Array<LifeAssuredList>
    lifeAssuredAdditions: Array<LifeAssuredAdditions>
  }

  export type RelationShip = {
    clientId: string | null
    clientName: string | null
    relationship: SelectOption | null
    role: string | null
    secuityNo: string | null
    dob: string | null
    gender: string | null
    poName: string | null
  }

  export type PolicyInfoData = {
    customerId: string
    poName: string
    secuityNo: string
    dob: string
    gender: string
  }
}

export namespace RiderAlterationFormData {
  export interface RiderAlteration {
    isRiderAlteration: boolean
    LARiderAlterationList: Array<LARiderAlterationData>
    isAddRider: boolean
    requiredPayinAmount: string
    healthDocuments: FileUploadData[]
    totalPremium: number
    newRiderLAList: Array<NewRiderLAData>
  }

  export interface LARiderAlterationData {
    // selectAllForLA: boolean
    LAName: string
    lifeAssured: string
    currentRiders: Array<CurrentRiderForm>
  }

  export interface NewRiderLAData {
    LAName: string
    lifeAssured: string
    isNewLA: boolean
    newRiderList: Array<NewRider>
    newLAInfo?: NewLAInfo
    lifeNo?: string
    detailData?: RiderAlterationData.NewLifeAssured
    // alteredRiderList: SelectOption[]
    alteredRiderList: RiderAlterationService.RiderNameInfo[]
  }

  export interface CurrentRiderForm {
    riderCode: string
    riderName: string
    isWaiverProduct: boolean
    isClassNumber: boolean
    classNumberList: string[]
    // SelectRowForRider: boolean
    riskCommDate: string
    riskCessDate: string
    sumAssured: number
    alteredSumAssured: string
    installmentPrem: number
    newInstallmentPremium: number
    lifeNo: string
    coverageNo: string
    riderNo: string
    riderStatus: string
  }

  export interface NewRider {
    riderName: SelectOption
    sumAssured: string
    policyPeriod: string
    isWaiverProduct: boolean
    isClassNumber: boolean
    classNumberList: string[]
  }

  export interface NewLAInfo {
    surname: string
    firstName: string
    documentType: SelectOption
    IDNumber: string
    issuePlace: SelectOption
    issuedDate: Date
    USTaxDeclaration: string
    dateOfBirth: Date
    gender: SelectOption
    mobilePhoneCode: SelectOption
    mobilePhone: string
    email: string
    nationality: SelectOption
    occupation: SelectOption
    jobDescription: string
    jobTitle: string
    companyName: string
    companyAddress: string
    averageIncome: SelectOption
    identifyDocument: FileUploadData[]
    dupPhone: RiderAlterationData.RelationShip[]
    dupEmail: RiderAlterationData.RelationShip[]
    dupPhoneMessage: string
    dupEmailMessage: string
    country?: SelectOption
    city?: SelectOption
    district?: SelectOption
    ward?: SelectOption
    street?: string
    taxResidencyCountry?: SelectOption
    nationality2?: SelectOption
    foreignAddress?: string
    countryOfForeignAddress?: SelectOption
    foreignAddressRequired?: boolean
  }
}

export namespace RiderAlterationConst {
  export const taxDeclarationList: Array<{ id: string; label: string }> = [
    { id: 'Y', label: 'common:Yes' },
    { id: 'N', label: 'common:No' }
  ]

  export const DocumentTypeList: Array<{ code: string; nameEn: string; nameVi: string }> = [
    {
      code: '00',
      nameEn: 'National ID',
      nameVi: 'CMND'
    },
    {
      code: '01',
      nameEn: 'National Citizen ID',
      nameVi: 'CCCD'
    },
    {
      code: '02',
      nameEn: 'Birth certificate',
      nameVi: 'Giấy khai sinh'
    },
    {
      code: '03',
      nameEn: 'Passport',
      nameVi: 'Hộ chiếu'
    }
  ]
  export const DocumentPasport: Array<{ code: string; nameEn: string; nameVi: string }> = [
    {
      code: '03',
      nameEn: 'Administration of Immigration',
      nameVi: 'Cục Quản lý xuất nhập cảnh'
    }
  ]
  export const DocumentNationalID: Array<{ code: string; nameEn: string; nameVi: string }> = [
    {
      code: '00',
      nameEn: 'Cục Trưởng Cục Cảnh Sát Quản lý Hành Chính về Trật tự Xã hội',
      nameVi: 'Cục Trưởng Cục Cảnh Sát Quản lý Hành Chính về Trật tự Xã hội'
    },
    {
      code: '01',
      nameEn: 'Cục Trưởng Cục Cảnh Sát DKQL CƯ TRÚ VÀ DLQG VỀ DÂN CƯ',
      nameVi: 'Cục Trưởng Cục Cảnh Sát DKQL CƯ TRÚ VÀ DLQG VỀ DÂN CƯ'
    },
    {
      code: '02',
      nameEn: 'BỘ CÔNG AN',
      nameVi: 'BỘ CÔNG AN'
    }
  ]

  export const AverageIncomeList: Array<{ code: string; descriptionEn: string; descriptionVi: string }> = [
    {
      code: '00',
      descriptionEn: 'Not declared',
      descriptionVi: 'Không khai báo'
    },
    {
      code: '01',
      descriptionEn: 'Below 2.5 mil',
      descriptionVi: 'Dưới 2,5 triệu'
    },

    {
      code: '02',
      descriptionEn: 'From 2.5 mil to below 4.5 mil',
      descriptionVi: 'Từ 2.5 triệu đến dưới 4.5 triệu'
    },
    {
      code: '03',
      descriptionEn: 'From 4.5 mil to below 7.5 mil',
      descriptionVi: 'Từ 4.5 triệu đến dưới 7.5 triệu'
    },
    {
      code: '04',
      descriptionEn: 'From 7.5 mil to below 15 mil',
      descriptionVi: 'Từ 7.5 triệu đến dưới 15 triệu'
    },
    {
      code: '05',
      descriptionEn: 'From 15 mil and above',
      descriptionVi: 'Từ 15 triệu trở lên'
    }
  ]

  export const Gender = [
    {
      value: 'M',
      nameVi: 'Nam',
      nameEn: 'Male'
    },
    {
      value: 'F',
      nameVi: 'Nữ',
      nameEn: 'Female'
    }
  ]
}
