import { LinearProgress } from '@material-ui/core'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import {
  Checkbox,
  ErrorHandling,
  PaymentType,
  Select,
  SelectOption,
  SubmissionService,
  useMobile,
  Input
} from '@pulseops/common'
import { pipe } from 'fp-ts/function'
import React from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'react-native'
import { ClaimService } from '../ClaimService'
import { ClaimType, generalStyles as styles } from '../common'
import { BankTransfer } from './bank-transfer'
// import { PaymentMethodForm } from './PaymentMethodForm'
import moment from 'moment'
import * as AddLackOfPayeeForm from '../add-lack-of-payee/add-lack-of-payee-form'

export const PaymentMethod = ({
  form,
  isLoading,
  policyNum,
  officeCode,
  triggerOnReset,
  triggerOnNext,
  claimType,
  dob,
  transaction
}: {
  policyNum: string
  form: UseFormReturn<AddLackOfPayeeForm.Form>
  isLoading: boolean
  officeCode: string
  triggerOnReset: (actions: () => void) => void
  triggerOnNext: (actions: () => void) => void
  claimType: string
  dob: string
  transaction?: string
}) => {
  const { isMobile } = useMobile()
  const { t } = useTranslation(['claim', 'requestInfo', 'common'])

  const { watch, control } = form

  const clonePaymentForm = React.useRef<AddLackOfPayeeForm.Form>(form.getValues())

  const { paymentMode, payeeIsNotPolicyOwner, payeeIsPolicyOwner, changePayeeInfo } = watch()

  const [editableForm, setEditableForm] = React.useState<boolean>(
    transaction === 'PAYOUT' ? (!payeeIsPolicyOwner && changePayeeInfo ? false : true) : false
  )

  const [loading, bindLoading] = useLoading()

  const paymentMethods = [
    {
      label: t('BankTransfer'),
      value: PaymentType.BANKTRANSFER
    }
    // ,
    // {
    //   label: t('CashAtTheCounter'),
    //   value: PaymentType.CASH_AT_COUNTER
    // },
    // {
    //   label: t('PayAtBank'),
    //   value: PaymentType.PAID_AT_BANK
    // }
  ]

  const getPaymentComponent = () => {
    switch (paymentMode?.value) {
      case PaymentType.BANKTRANSFER:
        return <BankTransfer form={form} banks={banks} policyNum={policyNum} editableForm={editableForm} />
      // case PaymentType.CASH_AT_COUNTER:
      //   return (
      //     <PayAtTheCounter
      //       formInfo={{
      //         form: form,
      //         policyNum: policyNum
      //       }}
      //       officeCode={officeCode}
      //     />
      //   )
      // case PaymentType.PAID_AT_BANK:
      //   return <PaidAtBank form={form} banks={banks} policyNum={policyNum} />
      default:
        return <></>
    }
  }

  const banks = pipe(ClaimService.getBanks("2"), ErrorHandling.runDidMount())

  const ownerInfo = pipe(
    SubmissionService.getOwnerInfoDigital(policyNum),
    ZIO.tap((res) => {
      if (!payeeIsNotPolicyOwner) {
        form.setValue('bankAccountName', res.fullname)
        form.setValue('bankIdNumber', res.nationalID)
        form.setValue('payeeName', res.fullname)
        form.setValue('payeeId', res.nationalID)
      }
      return ZIO.unit
    }),
    ZIO.map((res) => ({
      fullname: res.fullname,
      nationalID: res.nationalID
    })),
    bindLoading,
    ErrorHandling.runDidMount()
  )

  triggerOnReset(() => {
    form.reset()
    form.setValue('bankAccountName', ownerInfo?.fullname || '-')
    form.setValue('bankIdNumber', ownerInfo?.nationalID || '-')
    form.setValue('payeeName', ownerInfo?.fullname || '-')
    form.setValue('payeeId', ownerInfo?.nationalID || '-')
  })

  triggerOnNext(() => {
    form.setValue('payeeIsNotPolicyOwner', payeeIsNotPolicyOwner || false)
  })

  const onCheckClaimant = (e: boolean) => {
    // form.setValue('payeeIsNotPolicyOwner', e)
    if (e === false && ownerInfo && paymentMode?.value) {
      form.clearErrors()
      switch (paymentMode?.value) {
        case PaymentType.BANKTRANSFER:
          form.setValue('bankAccountName', ownerInfo.fullname || '-')
          form.setValue('bankIdNumber', ownerInfo.nationalID || '-')
          form.setValue('dob', moment(dob).toString())
          break
        // case PaymentType.CASH_AT_COUNTER:
        //   form.setValue('payeeName', ownerInfo.fullname || '-')
        //   form.setValue('payeeId', ownerInfo.nationalID || '-')
        //   form.setValue('dob', moment(dob).toDate())
        //   break
        // case PaymentType.PAID_AT_BANK:
        //   form.setValue('payeeName', ownerInfo.fullname || '-')
        //   form.setValue('payeeId', ownerInfo.nationalID || '-')
        //   form.setValue('dob', moment(dob).toDate())
        //   break
      }
    } else {
      const firstData = clonePaymentForm.current
      switch (paymentMode?.value) {
        case PaymentType.BANKTRANSFER:
          form.setValue('bankAccountName', firstData.payeeIsNotPolicyOwner ? firstData.bankAccountName : '')
          form.setValue('bankIdNumber', firstData.payeeIsNotPolicyOwner ? firstData.bankIdNumber : '')
          form.setValue('dob', firstData.payeeIsNotPolicyOwner ? firstData.dob : null)
          break
        // case PaymentType.CASH_AT_COUNTER:
        //   form.setValue('payeeName', '')
        //   form.setValue('payeeId', '')
        //   form.setValue('dob', null)
        //   break
        // case PaymentType.PAID_AT_BANK:
        //   form.setValue('payeeName', '')
        //   form.setValue('payeeId', '')
        //   form.setValue('dob', null)
        //   break
      }
    }
  }

  const onPaymentChange = (e: SelectOption | null) => {
    form.clearErrors()
    if (e?.value && ownerInfo) {
      switch (e?.value) {
        case PaymentType.BANKTRANSFER:
          form.setValue(
            'bankAccountName',
            payeeIsNotPolicyOwner ? clonePaymentForm.current.bankAccountName || '' : ownerInfo.fullname
          )
          form.setValue(
            'bankIdNumber',
            payeeIsNotPolicyOwner ? clonePaymentForm.current.bankIdNumber || '' : ownerInfo.nationalID
          )
          form.setValue('dob', payeeIsNotPolicyOwner ? clonePaymentForm.current.dob || null : moment(dob).toString())
          break
        // case PaymentType.CASH_AT_COUNTER:
        //   form.setValue('payeeName', payeeIsNotPolicyOwner ? '' : ownerInfo.fullname)
        //   form.setValue('payeeId', payeeIsNotPolicyOwner ? '' : ownerInfo.nationalID)
        //   form.setValue('dob', payeeIsNotPolicyOwner ? null : moment(dob).toDate())
        //   break
        // case PaymentType.PAID_AT_BANK:
        //   form.setValue('payeeName', payeeIsNotPolicyOwner ? '' : ownerInfo.fullname)
        //   form.setValue('payeeId', payeeIsNotPolicyOwner ? '' : ownerInfo.nationalID)
        //   form.setValue('dob', payeeIsNotPolicyOwner ? null : moment(dob).toDate())
        //   break
      }
    }
  }

  const handleEditableForm = (payeeIsPolicyOwner: boolean, changePayeeInfo: boolean) => {
    setEditableForm(!payeeIsPolicyOwner && changePayeeInfo ? false : true)
  }

  return (
    <View style={{ paddingLeft: 16, paddingRight: 16 }}>
      <Text style={[styles.sectionTitle, { paddingBottom: 10 }]}>{t('PaymentMethod')}</Text>
      <View style={styles.sectionContainer}>
        <View style={{ flexDirection: 'row' }}>
          <View style={[isMobile ? styles.mobileField : styles.tabletField, { paddingBottom: 15 }]}>
            <Controller
              name="paymentMode"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t('message:MS020009', { field: t('PaymentMethod') })
                }
              }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <Select
                  required
                  label={t('PaymentMethod')}
                  onChange={(e) => {
                    onChange(e)
                    onPaymentChange(e)
                  }}
                  onBlur={onBlur}
                  value={value}
                  errorMessage={error?.message}
                  placeholder={t('common:Select')}
                  options={paymentMethods}
                />
              )}
            />
          </View>
          {transaction === 'PAYOUT' && (
            <View style={[isMobile ? styles.mobileField : styles.tabletField, { paddingBottom: 15 }]}>
              <Controller
                name="paymentAmount"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t('message:MS020009', { field: t('PaymentAmount') })
                  }
                }}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <Input
                    title={t('PaymentAmount')}
                    value={value || ''}
                    onChange={(val) => {
                      onChange(val)
                    }}
                    required
                    disabled
                  />
                )}
              />
            </View>
          )}
        </View>
        {transaction === 'PAYOUT' ? (
          <View style={{ flexDirection: 'row' }}>
            <View style={[isMobile ? styles.mobileField : styles.tabletField, { paddingBottom: 15 }]}>
              <Controller
                name="payeeIsPolicyOwner"
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <Checkbox
                    textStyle={{ fontWeight: 'bold' }}
                    title={t('PayeeIsPolicyOwner')}
                    value={value || false}
                    onBlur={onBlur}
                    onChange={(e) => {
                      if (clonePaymentForm.current.payeeIsNotPolicyOwner) {
                        form.setValue('changePayeeInfo', e)
                        handleEditableForm(e, e || false)
                      } else {
                        form.setValue('changePayeeInfo', !e)
                        handleEditableForm(e, !e)
                      }
                      onChange(e)
                      form.setValue('payeeIsNotPolicyOwner', !e)
                      onCheckClaimant(!e)
                    }}
                    errorMessage={error?.message}
                  />
                )}
              />
            </View>
            <View style={[isMobile ? styles.mobileField : styles.tabletField, { paddingBottom: 15 }]}>
              <Controller
                name="changePayeeInfo"
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <Checkbox
                    textStyle={{ fontWeight: 'bold' }}
                    title={t('ChangePayeeInfo')}
                    value={value || false}
                    onBlur={onBlur}
                    onChange={(e) => {
                      if (!e) {
                        if (clonePaymentForm.current.payeeIsNotPolicyOwner) {
                          form.reset({ ...clonePaymentForm.current, paymentMode: watch().paymentMode })
                        } else {
                          form.setValue('payeeIsPolicyOwner', true)
                          form.setValue('payeeIsNotPolicyOwner', false)
                          onCheckClaimant(false)
                        }
                      }

                      onChange(e)
                      handleEditableForm(payeeIsPolicyOwner || false, e)
                    }}
                    errorMessage={error?.message}
                    disabled={payeeIsPolicyOwner || false}
                  />
                )}
              />
            </View>
          </View>
        ) : (
          <Controller
            name="payeeIsNotPolicyOwner"
            control={control}
            rules={{
              required: {
                value: claimType === ClaimType.DEATH,
                message: `${t('message:MS020009', { field: t('PayeeIsNotPolicyOwner') })}`
              }
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <Checkbox
                textStyle={{ fontWeight: 'bold' }}
                title={t('PayeeIsNotPolicyOwner')}
                value={value}
                onBlur={onBlur}
                onChange={(e) => {
                  onChange(e)
                  onCheckClaimant(e)
                }}
                errorMessage={error?.message}
              />
            )}
          />
        )}
        {loading && <LinearProgress color="secondary" />}
        {getPaymentComponent()}
      </View>
    </View>
  )
}
