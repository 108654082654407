import {
  AppContext,
  ChangeContactInfoService,
  ChangeContactInfo,
  DuplicateTable,
  ErrorHandling,
  GeneralService,
  Input,
  OBSC,
  SC,
  Select,
  SelectOption,
  SelectSearch,
  SubmissionService,
  TabList,
  TransactionType,
  assets,
  form2,
  useMobile,
  StorageBlob,
  RequestAuthFormDataSubmit,
  sharedStyle,
  Checkbox,
  SourceType
} from '@pulseops/common'
import { CallHeaderInfo, CallOutDetailContext, OBChangeContactHeader, OBPageLoading, OBSharedStyles, calloutPopupContext } from '@pulseops/outbound'
import React from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Modal, Text, TouchableOpacity, View, StyleSheet, ScrollView, useWindowDimensions } from 'react-native'
import _ from 'lodash'
import { pipe } from 'fp-ts/lib/function'
import { Task, ZIO } from '@mxt/zio'
import { ChangeContactInfoForm } from './OBChangeContactInforForm'
import { ChangeContactOfficeInfo, EmailIndicator, SMSIndicator } from './OBChangeContactInfoConst'
import { ConfirmView } from '../OBConfirmedView'
import { RequestAuthen, RequestAuthenticateData, RequestInfoPanel, UploadedFilesInfo } from '@pulseops/submission'
import { useLoading } from '@mxt/zio-react'
import * as O from 'fp-ts/lib/Option'
import * as A from 'fp-ts/lib/Array'
import * as tc from 'io-ts'
// import { LinearProgress } from '@material-ui/core'

type OBChangeContactInfoPopupProps = {
  visible: boolean
  policyNumber: string
  clientNumber: string
  otpRegistered?: boolean
  onPopupCloseEvent: () => void
  // setIsUpdatedChangeContactInfo?: (val: boolean) => void
}

export const OBChangeContactInfoPopup = (props: OBChangeContactInfoPopupProps) => {
  const { width, height } = useWindowDimensions()
  const { isMobile } = useMobile()
  const { t, i18n } = useTranslation()
  const { visible, onPopupCloseEvent, policyNumber, clientNumber } = props
  const [relationOptions, setRelationOptions] = React.useState<SelectOption[]>([])
  const [currentTab, setCurrentTab] = React.useState<number>(0)
  const [eformData, setEformData] = React.useState<RequestAuthenticateData.EformRequestAuthenticateData>(
    RequestAuthenticateData.DefaultRequestAuthenticateData
  )
  const { showToast } = React.useContext(AppContext.AppContextInstance)
  const [detail, setDetail] = React.useState<ChangeContactInfo.Detail>()
  const [POName, setPOName] = React.useState('')
  const [POOwnerID, setPOOwnerID] = React.useState('')
  const [countryOptions, setCountryOptions] = React.useState<SelectOption[]>([])
  const [provinceOptions, setProvinceOptions] = React.useState<SelectOption[]>([])
  const [districOptions, setDistrictOptions] = React.useState<SelectOption[]>([])
  const [wardOptions, setWardOptions] = React.useState<SelectOption[]>([])
  const [bindLoading, bindLoader] = useLoading(false)
  const [isShowLoadingIcon, setIsShowLoadingIcon] = React.useState(false)
  const isConfirmed = currentTab === 1
  const isCCE = true
  const { setIsReloadedPolicyOwnerTab, setIsUpdatedChangeContactInfo } = React.useContext(CallOutDetailContext)

  const requestAuthenForm = useForm<RequestAuthenticateData.RequestAuthFormValue>({
    defaultValues: {
      authOption: RequestAuthenticateData.RequestAuthOption.OTP,
      otp: {
        otpOption: RequestAuthenticateData.OTPOption.PHONE,
        otpInput: undefined,
        otpConsent: false
      },
      paper: {
        data: [],
        consent: false
      }
    }
  })
  const {
    base: {
      control,
      reset,
      getValues,
      trigger,
      formState: { errors, isValid },
      setValue,
      watch,
      clearErrors
    }
  } = form2.useForm(ChangeContactInfoForm.codec, {
    defaultValues: {
      chooseAddress: false,
      chooseEmail: false,
      choosePhone: false
    }
  })

  const headers: CallHeaderInfo[] = [
    {
      title: t('common:PolicyNumber'),
      value: policyNumber
    },
    {
      title: t('common:PolicyOwner'),
      value: `${POOwnerID} - ${POName}`
    },
    {
      title: t('common:Office'),
      value: `${ChangeContactOfficeInfo.officeCode} - ${ChangeContactOfficeInfo.officeName}`
    }
  ]

  const menus = [
    {
      id: 1,
      title: t('submission:Request')
    },
    {
      id: 2,
      title: t('submission:Confirm')
    }
  ]

  const requestInfo = [
    { label: t('submission:category'), value: t('submission:PolicyServices') },
    { label: t('submission:serviceType'), value: t('TransactionGroup:Alteration') },
    { label: t('submission:transactionType'), value: t(`TransactionType:CHANGE_CONTACT_INFO`) }
  ]

  const applyPolicyAddress = useWatch({
    control,
    name: 'address.applyPolicies'
  })

  const phoneRelationShip = useWatch({
    control,
    name: 'phone.relationships'
  })

  const emailRelationShip = useWatch({
    control,
    name: 'email.relationships'
  })

  const predicateAgent = (relationship: ChangeContactInfoForm.RelationShip) => relationship.role === 'AG'
  const anyAgentPhone = React.useMemo(() => phoneRelationShip?.find(predicateAgent), [phoneRelationShip])
  const anyAgentEmail = React.useMemo(() => emailRelationShip?.find(predicateAgent), [emailRelationShip])

  const phoneCodeOptions: SelectOption[] = pipe(
    GeneralService.getMobileCodes,
    ZIO.map((codes) => {
      return codes.map((code) => ({
        label: `+${code.code}`,
        value: code.code
      }))
    }),
    ErrorHandling.runDidMount({})
  )

  pipe(
    visible ? GeneralService.getRelationship() : ZIO.succeed([]),
    ZIO.tap((relations) => {
      //console.log(relations)
      const relationOptions = relations
        .filter((r) => r.changeContactInfo === 'Y')
        .map((relation) => {
          return {
            label: i18n.language === 'vi' ? relation.nameVn : relation.nameEn,
            value: relation.code
          }
        })
      setRelationOptions(relationOptions)
      return ZIO.unit
    }),
    ErrorHandling.runDidUpdate([visible])
  )

  React.useEffect(() => {
    if (visible) {
      setCurrentTab(0)
      reset({
        chooseAddress: false,
        chooseEmail: false,
        choosePhone: false
      })
      clearErrors()
    }
    if (visible && policyNumber) {
      pipe(
        ZIO.zipPar(ChangeContactInfoService.getDetail(policyNumber!), SubmissionService.getNewOwnerInfo(policyNumber)),
        ZIO.map(([contactData, customerInfo]) => {
          setDetail(contactData)
          setEformData({
            ...eformData,
            policyNum: policyNumber,
            phoneData: {
              phoneCode: customerInfo.attributesExt.MOBILE_CODE,
              phoneNum: customerInfo.mobilePhone ?? ''
            },
            otpRegistered: customerInfo.otpConsentStatus === '1',
            isCCE: true
          })
          setPOName(customerInfo.name)
          setPOOwnerID(customerInfo.clientId)
          return contactData
        }),
        bindLoader,
        ErrorHandling.run()
      )
    }
  }, [visible, policyNumber])

  React.useEffect(() => {
    setIsShowLoadingIcon(bindLoading)
  }, [bindLoading])

  // React.useEffect(() => {
  //   !!detail && pathPhone()
  // }, [detail])

  const SMSOptions = React.useMemo(() => {
    return SMSIndicator.map((sms) => {
      return {
        value: sms.value,
        label: i18n.language === 'vi' ? sms.labelVI : sms.labelEN
      }
    })
  }, [i18n.language])

  const EmailOptions = React.useMemo(() => {
    return EmailIndicator.map((email) => {
      return {
        value: email.value,
        label: i18n.language === 'vi' ? email.labelVI : email.labelEN
      }
    })
  }, [i18n.language])

  const getWidthForPopup = () => {
    return isMobile ? width : width > 1024 ? width - 450 : width - 200
  }

  const getDistricts = (provinceCode: string) =>
    pipe(
      GeneralService.getDistricts(provinceCode),
      ZIO.map((districts) => {
        const districtOptions = districts.map((district) => {
          return {
            value: district.code,
            label: i18n.language === 'vi' ? district.name : district.name
          }
        })
        setDistrictOptions(districtOptions)
        return ZIO.unit
      }),
      ErrorHandling.run()
    )

  const getWards = (provinceCode: string, districtCode: string) =>
    pipe(
      GeneralService.getWards({ districtCode, provinceCode }),
      ZIO.map((wards) => {
        const wardOptions = wards.map((ward) => {
          return {
            value: ward.code,
            label: i18n.language === 'vi' ? ward.name : ward.name
          }
        })
        setWardOptions(wardOptions)
        return ZIO.unit
      }),
      ErrorHandling.run()
    )
  const applyAllPolicy = (value: boolean) => {
    const newApply = applyPolicyAddress && applyPolicyAddress.map((a) => ({ ...a, selected: value }))
    setValue('address.applyPolicies', newApply)
  }

  const pathAddress = () => {
    if (
      provinceOptions.length > 0 &&
      districOptions.length > 0 &&
      wardOptions.length > 0 &&
      getValues('address.country') &&
      getValues('address.street') &&
      getValues('address.province') &&
      getValues('address.district') &&
      getValues('address.ward')
    ) {
      return
    }
    return pipe(
      detail,
      O.fromNullable,
      O.fold(
        () => showToast('Error', 'error'),
        (detail) => {
          setIsShowLoadingIcon(true)
          return pipe(
            ZIO.zipPar(ZIO.succeed(detail), GeneralService.getCountries, GeneralService.getProvinces),
            ZIO.flatMap(([detail, countries, provinces]) => {
              // setDetail(detail)
              const countriesOptions = countries.map((country) => {
                return {
                  label: country.name,
                  value: country.code
                }
              })

              const provinceOptions = provinces.map((province) => {
                return {
                  label: province.name,
                  value: province.code
                }
              })

              setCountryOptions(countriesOptions)
              setProvinceOptions(provinceOptions)

              const chooseCountry = countriesOptions.find((value) => value.value === detail.nationality)
              const chooseProvince = provinceOptions.find(
                (value) => value.value === getValues('address.province.value') || value.value === detail.province
              )

              return pipe(
                GeneralService.getDistricts(getValues('address.province.value') || detail.province),
                ZIO.flatMap((districts) => {
                  const districtOptions = districts.map((district) => {
                    return {
                      label: district.name,
                      value: district.code
                    }
                  })

                  const chooseDistrict = districtOptions.find(
                    (value) => value.value === getValues('address.district.value') || value.value === detail.district
                  )
                  setDistrictOptions(districtOptions)

                  return pipe(
                    GeneralService.getWards({
                      provinceCode: getValues('address.province.value') || detail.province,
                      districtCode: getValues('address.district.value') || detail.district
                    }),
                    ZIO.map((wards) => {
                      const wardOptions = wards.map((ward) => {
                        return {
                          label: ward.name,
                          value: ward.code
                        }
                      })
                      const chooseWards = wardOptions.find(
                        (value) => value.value === getValues('address.ward.value') || value.value === detail.ward
                      )

                      setWardOptions(wardOptions)

                      setValue('address.street', getValues('address.street') || detail.street)
                      setValue(
                        'address.country',
                        getValues('address.country') || chooseCountry || { label: '', value: '' }
                      )
                      setValue(
                        'address.province',
                        getValues('address.province') || chooseProvince || { label: '', value: '' }
                      )
                      setValue(
                        'address.district',
                        getValues('address.district') || chooseDistrict || { label: '', value: '' }
                      )
                      setValue('address.ward', getValues('address.ward') || chooseWards || { label: '', value: '' })
                      //console.log('dasdasdas asdasd asd', detail.clientPolicyList)
                      const clientPolicy =
                        getValues('address.applyPolicies') ||
                        detail.clientPolicyList
                          .filter((c) => !!c.policyNum && !!c.address)
                          .map((policy) => {
                            return { selected: false, policyNum: policy.policyNum, address: policy.address }
                          })
                      setValue('address.applyPolicies', clientPolicy)
                      //setValue('address.applyPolicies', [{selected: false, policyNum: '7000001', address: '2B nguyen thi huynh'}, {selected: false, policyNum: '7000001', address: '2B nguyen thi huynh'}, {selected: false, policyNum: '7000001', address: '2B nguyen thi huynh'}, {selected: false, policyNum: '7000001', address: '2B nguyen thi huynh'}, {selected: false, policyNum: '7000001', address: '2B nguyen thi huynh'}])
                      return ZIO.unit
                    })
                  )
                })
              )
            }),
            ZIO.tap((_) => {
              setIsShowLoadingIcon(false)
              return ZIO.unit
            }),
            ZIO.tapError((_) => {
              setIsShowLoadingIcon(false)
              return ZIO.unit
            }),
            ErrorHandling.run({})
          )
        }
      )
    )
  }

  const pathEmail = () => {
    return pipe(
      detail,
      O.fromNullable,
      O.fold(
        () => console.log('Error'),
        (detail) => {
          setValue('email.email', detail.email)
          const mapEmail = EmailIndicator.find((email) => email.value === detail.emailIndicator)
          if (mapEmail) {
            setValue('email.receiveInfoVia', {
              value: mapEmail.value,
              label: i18n.language === 'vi' ? mapEmail.labelVI : mapEmail.labelEN
            })
          } else {
            const receiveDefaultValue = EmailOptions.find((x) => x.value === '3') ?? { value: '', label: '' }
            setValue('email.receiveInfoVia', receiveDefaultValue)
          }

          getDuplicateEmail(detail.email)
        }
      )
    )
  }

  const pathPhone = () => {
    return pipe(
      detail,
      O.fromNullable,
      O.fold(
        () => console.log('Error'),
        (detail) => {
          setValue('phone.mobileCode', { value: detail.mobilePhoneCode, label: `+${detail.mobilePhoneCode}` })
          setValue('phone.mobileNum', detail.mobilePhoneNumber)
          setValue('phone.officeMobileCode', { value: detail.officePhoneCode, label: `+${detail.officePhoneCode}` })
          setValue('phone.officePhoneNum', detail.officePhoneNumber)
          const mapRelationShip = EmailIndicator.find((phone) => phone.value === detail.smsIndicator)
          if (mapRelationShip) {
            setValue('phone.receiveInfoVia', {
              value: mapRelationShip.value,
              label: i18n.language === 'vi' ? mapRelationShip.labelVI : mapRelationShip.labelEN
            })
          } else {
            const receiveDefaultValue = SMSOptions.find((x) => x.value === '3') ?? { value: '', label: '' }
            setValue('phone.receiveInfoVia', receiveDefaultValue)
          }
          getDuplicatePhone(detail.mobilePhoneNumber || '')
        }
      )
    )
  }

  const isSameClient = (relationship: ChangeContactInfo.DuplicateInfo): boolean => {
    return (
      relationship.clientName === detail?.poName &&
      relationship.dob === detail?.dob &&
      relationship.gender === detail?.gender &&
      relationship.secuityNo === detail?.secuityNo
    )
  }

  const getDuplicatePhone = (phoneNumber: string) => {
    pipe(
      detail,
      O.fromNullable,
      O.fold(
        () => showToast(t('common:noData'), 'error'),
        (detail) => {
          setIsShowLoadingIcon(true)
          return pipe(
            ChangeContactInfoService.getDuplicatePhoneInfo(phoneNumber, detail.customerId),
            ZIO.tap((info) => {
              const phones = info.map((e) => {
                return {
                  clientId: e.clientId,
                  clientName: e.clientName,
                  poName: detail.poName,
                  role: e.role,
                  //role: 'AG',
                  relationship: null,
                  dob: e.dob,
                  secuityNo: e.secuityNo,
                  gender: e.gender
                  //sameClient: isSameClient(e)
                }
              })
              setValue(
                'phone.relationships',
                phones.filter((p) => !isSameClient(p))
              )
              setIsShowLoadingIcon(false)
              return ZIO.unit
            }),
            ZIO.tapError((_) => {
              setIsShowLoadingIcon(false)
              return ZIO.unit
            }),
            bindLoader,
            ErrorHandling.run()
          )
        }
      )
    )
  }

  const getDuplicateEmail = (email: string) => {
    pipe(
      detail,
      O.fromNullable,
      O.fold(
        () => showToast('Khong co du lieu', 'error'),
        (detail) => {
          setIsShowLoadingIcon(true)
          return pipe(
            ChangeContactInfoService.getDuplicateEmailInfo(email, detail.customerId),
            ZIO.tap((info) => {
              const email = info.map((e) => {
                // const validationFlag = (e?.role !== 'AG' || e?.role === 'AG') && e?.clientName !== detail.poName
                return {
                  clientId: e.clientId,
                  clientName: e.clientName,
                  poName: detail.poName,
                  role: e.role,
                  relationship: null,
                  dob: e.dob,
                  secuityNo: e.secuityNo,
                  gender: e.gender
                  //sameClient: isSameClient(e)
                }
              })
              //console.log('duplication email', email)
              setValue(
                'email.relationships',
                email.filter((e) => !isSameClient(e))
              )
              setIsShowLoadingIcon(false)
              return ZIO.unit
            }),
            ZIO.tapError((_) => {
              setIsShowLoadingIcon(false)
              return ZIO.unit
            }),
            bindLoader,
            ErrorHandling.run()
          )
        }
      )
    )
  }

  /// debounce email input to get duplicate email
  const handlerEmail = React.useCallback(
    _.debounce((email) => {
      if (ChangeContactInfoForm.emailValidate(email)) {
        getDuplicateEmail(email)
      } else {
        setValue('email.relationships', [])
      }
    }, 300),
    [detail]
  )

  const getTransactionName = (currentForm: ChangeContactInfoForm.Raw) => {
    const isAll = currentForm.chooseAddress && (currentForm.chooseEmail || currentForm.choosePhone)
    const isAddress = currentForm.chooseAddress && !currentForm.chooseEmail && !currentForm.choosePhone
    const isPhone = !currentForm.chooseAddress && !currentForm.chooseEmail && currentForm.choosePhone
    const isEmail = !currentForm.chooseAddress && currentForm.chooseEmail && !currentForm.choosePhone
    const isPhoneAndEmail = !currentForm.chooseAddress && currentForm.chooseEmail && currentForm.choosePhone
    return isCCE
      ? getTransactionShortNameForCCE(isPhone, isEmail, isAddress, isPhoneAndEmail, currentForm)
      : isAll
        ? RequestAuthenticateData.TransactionLabelShort(TransactionType.CHANGE_CONTACT_INFO)
        : isPhoneAndEmail
          ? RequestAuthenticateData.TransactionLabelShort(TransactionType.CHANGE_CONTACT_INFO_PHONE_AND_EMAIL)
          : isAddress
            ? RequestAuthenticateData.TransactionLabelShort(TransactionType.CHANGE_CONTACT_INFO_ADDRESS)
            : isEmail
              ? RequestAuthenticateData.TransactionLabelShort(TransactionType.CHANGE_CONTACT_INFO_EMAIL)
              : isPhone
                ? RequestAuthenticateData.TransactionLabelShort(TransactionType.CHANGE_CONTACT_INFO_PHONE)
                : '-'
  }

  const getTransactionShortNameForCCE = (
    isPhone: boolean,
    isEmail: boolean,
    isAddress: boolean,
    isPhoneAndEmail: boolean,
    currentForm: ChangeContactInfoForm.Raw
  ) => {
    let smsShortMessage = ''
    const phone = _.get(currentForm, 'phone') as ChangeContactInfoForm.PhoneForm
    const email = _.get(currentForm, 'email') as ChangeContactInfoForm.EmailForm
    const isPhoneAndAddress = currentForm.chooseAddress && !currentForm.chooseEmail && currentForm.choosePhone
    const isEmailAndAddress = currentForm.chooseAddress && currentForm.chooseEmail && !currentForm.choosePhone
    const isALL = currentForm.chooseAddress && currentForm.chooseEmail && currentForm.choosePhone
    if (isPhone || isPhoneAndEmail || isPhoneAndAddress || isALL) {
      smsShortMessage =
        RequestAuthenticateData.TransactionLabelShort(TransactionType.CHANGE_CONTACT_INFO_PHONE) + ' ' + phone.mobileNum
    } else if (isEmail || isEmailAndAddress) {
      smsShortMessage = 'Cap nhat dia chi thu dien tu ' + email?.email ?? ''
    } else if (isAddress) {
      smsShortMessage = RequestAuthenticateData.TransactionLabelShort(TransactionType.CHANGE_CONTACT_INFO_ADDRESS)
    }
    return smsShortMessage
  }

  const onClickContinuedButtonEvent = async () => {
    const isValidData = await trigger()
    const isChosenOption = getValues('chooseAddress') || getValues('choosePhone') || getValues('chooseEmail')
    if (isValidData && isChosenOption) {
      const changeContactFormValue = getValues()
      const transactionName = getTransactionName(changeContactFormValue)
      setEformData({
        ...eformData,
        transactionName: transactionName,
        collerationId: policyNumber,
        transaction: TransactionType.CHANGE_CONTACT_INFO
      })
      setCurrentTab(1)
    }
  }

  const validateRequestAuthenForm = () => {
    if (requestAuthenForm.watch('authOption') === RequestAuthenticateData.RequestAuthOption.PAPER) {
      return requestAuthenForm.watch('paper.consent')
    } else {
      return !!requestAuthenForm.watch('otp.otpInput') && requestAuthenForm.watch('otp.otpConsent') && requestAuthenForm.watch('otp.optConsent1')
    }
  }

  const mapDataSubmit = (currentForm: ChangeContactInfoForm.Raw) => {
    const address = _.get(currentForm, 'address') as ChangeContactInfoForm.AddressForm
    const phone = _.get(currentForm, 'phone') as ChangeContactInfoForm.PhoneForm
    const email = _.get(currentForm, 'email') as ChangeContactInfoForm.EmailForm
    const chooseAddress = currentForm.chooseAddress
    const choosePhone = currentForm.choosePhone
    const chooseEmail = currentForm.chooseEmail

    let contactDetails = {}

    if (chooseEmail) {
      contactDetails = {
        ...contactDetails,
        EMAIL: {
          value: email?.email?.toString() || ''
        },

        EMAIL_IND: {
          value: email.receiveInfoVia?.value
        }
      }
    }

    if (choosePhone) {
      contactDetails = {
        ...contactDetails,
        PHONE: {
          value: phone.mobileNum,
          countryCode: phone.mobileCode.value
        },
        OFFICE_PHONE: {
          value: _.get(phone, 'officePhoneNum') || '',
          countryCode: _.get(phone, 'officeMobileCode.value') || ''
        },
        SMS_IND: {
          value: phone.receiveInfoVia?.value
        }
      }
    }

    const data: ChangeContactInfo.DataSubmit = {
      policyNo: policyNumber!,
      owners: {
        clientId: detail?.customerId || ''
      },
      priorPolicies:
        chooseAddress && address.applyPolicies
          ? [
            { policyNo: policyNumber! },
            ...address.applyPolicies
              .filter((e) => e.selected && !!e.policyNum)
              .map((policy) => ({ policyNo: policy.policyNum.toString() }))
          ]
          : [{ policyNo: policyNumber! }],
      attributesExt: {
        PHONES_DUP:
          choosePhone && phone.relationships
            ? phone.relationships
              ?.filter((v) => v.relationship !== null)
              .map((e) => ({ clientId: e.clientId || '', relationshipType: e.relationship?.value || '' }))
            : [],
        EMAILS_DUP:
          chooseEmail && email.relationships
            ? email.relationships
              ?.filter((v) => v.relationship !== null)
              .map((e) => ({ clientId: e.clientId || '', relationshipType: e.relationship?.value || '' }))
            : [],
        PO_OWNER_ID: detail?.customerId || ''
      }
    }

    const requestdata = {
      ...data,
      dispatchAddress: chooseAddress
        ? {
          line1: address.street,
          zipcode: address.ward.value,
          countryCode: address.country.value,
          city: address.province.value,
          district: address.district.value,
          subDistrict: address.ward.value
        }
        : undefined,
      contactDetails,
      isCCE: false
    }
    return requestdata
  }

  const updateSendDate = (sendDate: string) => {
    let eFormItem = eformData
    eFormItem = {
      ...eFormItem,
      sendDate: sendDate
    }
    setEformData(eFormItem)
  }

  const updatedocReviewed = (docReview: boolean) => {
    let eFormItem = eformData
    eFormItem = {
      ...eFormItem,
      docReviewed: docReview
    }
    setEformData(eFormItem)
  }

  const transactionTypeByPassAuthPaper = [
    TransactionType.FATCA_DECLARATION,
    TransactionType.SIGNATURE_REGISTRATION,
    TransactionType.HEALTH_DECLARATION,
    TransactionType.COMMON_REQUEST_FORM
  ]

  const isByPassAuthPaper = (): boolean => {
    return eformData.transaction !== null && transactionTypeByPassAuthPaper.includes(eformData.transaction)
  }

  const onSubmit =
    (
      url: string,
      body: unknown,
      documents: StorageBlob.FileContentSubmit[],
      method?: 'POST' | 'PUT',
      uploadedFilesInfo?: UploadedFilesInfo[]
    ) =>
      (requestauth: RequestAuthFormDataSubmit) => {
        const requestAuthInfo = requestauth
        // showGlobalLoading(true)
        return pipe(
          !!uploadedFilesInfo && uploadedFilesInfo.length > 0
            ? pipe(
              uploadedFilesInfo,
              A.map((uploadedItem) => {
                return GeneralService.getAzureStorageFiles(
                  uploadedItem?.uploadFiles,
                  uploadedItem.transactionType,
                  uploadedItem.docTypeCode,
                  uploadedItem.category,
                  uploadedItem.policyNumber,
                  uploadedItem.officeCode
                )
              }),
              ZIO.sequence,
              ZIO.map((uploadedList) => {
                let uploadFiles: StorageBlob.FileContentSubmit[] = []
                uploadedList.map((arrayItem) => {
                  uploadFiles = [...arrayItem, ...uploadFiles]
                })
                return uploadFiles
              })
            )
            : ZIO.succeed([]),
          ZIO.flatMap((azuredDocList) =>
            SubmissionService.submit(tc.unknown, method || 'POST')(
              url,
              body,
              policyNumber,
              requestAuthInfo,
              [...documents, ...azuredDocList],
              detail?.customerId || '',
              isCCE,
              ChangeContactOfficeInfo.officeCode,
              undefined,
              undefined,
              undefined,
              SourceType.OUTBOUND
            )
          ),
          ZIO.foldM(
            (err) => {
              // showGlobalLoading(false)
              return ZIO.fail(err)
            },
            (_) => {
              // showGlobalLoading(false)
              return ZIO.unit
            }
          )
        )
      }

  const onSubmitDataWithAuthen = (submitService: (_: RequestAuthFormDataSubmit) => Task<unknown>) => {
    return (verify: RequestAuthenticateData.RequestAuthFormValue) => {
      const isValidOTP: boolean =
        verify.authOption === RequestAuthenticateData.RequestAuthOption.OTP &&
        verify.otp.otpInput.toString().length === 6
      const isValidAuthFlag =
        verify.authOption === RequestAuthenticateData.RequestAuthOption.PAPER && eformData.docReviewed
      const isValidAuthDoc =
        verify.authOption === RequestAuthenticateData.RequestAuthOption.PAPER &&
        (verify.paper.data.length > 0 || isByPassAuthPaper())
      // showGlobalLoading(true)
      return isValidOTP || (isValidAuthFlag && isValidAuthDoc)
        ? pipe(
          verify.authOption === RequestAuthenticateData.RequestAuthOption.OTP
            ? submitService({
              type: RequestAuthenticateData.RequestAuthOption.OTP,
              data: {
                otp: verify.otp.otpInput,
                transactionName: eformData.transactionName,
                transactionCode: `${eformData.transactionType}-${eformData.collerationId}-${eformData.sendDate}`,
                receiverPhone: eformData.phoneData.phoneNum,
                payload: ''
              }
            })
            : pipe(
              !!eformData.metaData
                ? StorageBlob.uploadToSubmit(
                  'PS',
                  eformData.collerationId
                )(
                  verify.paper.data.map((x) => ({
                    file: x.file,
                    metaData: eformData.metaData
                  }))
                )
                : ZIO.succeed([]),
              ZIO.flatMap((x) =>
                submitService({
                  type: RequestAuthenticateData.RequestAuthOption.PAPER,
                  data: {
                    authFlag: eformData.docReviewed,
                    attachments: x
                  }
                })
              )
            ),
          ZIO.tap((e) => {
            showToast(t('message:RequestSendSuccessfully'), 'success')
            // showGlobalLoading(false)
            requestAuthenForm.reset({
              authOption: RequestAuthenticateData.RequestAuthOption.OTP,
              otp: {
                otpOption: RequestAuthenticateData.OTPOption.PHONE,
                otpInput: undefined,
                otpConsent: false
              },
              paper: {
                data: [],
                consent: false
              }
            })
            setIsReloadedPolicyOwnerTab && setIsReloadedPolicyOwnerTab(true)
            onPopupCloseEvent()
            return ZIO.unit
          }),
          ZIO.mapError((err) => {
            let getErrorMess = ''
            if (err.source && err.source.message.includes(' - ')) {
              const code = err.source.message.split(' - ')[0]
              const time = err.source.message.split(' - ')[1].replace('t=', '')
              getErrorMess =
                code === 'MS050252'
                  ? t('message:MS050252', { t: time })
                  : code === 'MS050254'
                    ? t('message:MS050254', { t: time })
                    : code === 'MS050253'
                      ? t('message:MS050253')
                      : code === 'MS050255'
                        ? t('message:MS050255')
                        : 'Unknown error'
            } else {
              getErrorMess = t('message:MS050001')
            }
            showToast(getErrorMess, 'error')
            setIsReloadedPolicyOwnerTab && setIsReloadedPolicyOwnerTab(false)
            return getErrorMess
          }),
          ZIO.unsafeRun({})
        )
        : showOTPErrorMsg(verify, isValidAuthDoc, isValidAuthFlag)
    }
  }

  const showOTPErrorMsg = (
    verify: RequestAuthenticateData.RequestAuthFormValue,
    isValidAuthDoc: boolean,
    isValidAuthFlag: boolean
  ) => {
    // showGlobalLoading(false)
    showToast(
      verify.authOption === RequestAuthenticateData.RequestAuthOption.OTP
        ? t('OTP:inputValid')
        : !isValidAuthDoc
          ? t('message:MS050262')
          : !isValidAuthFlag
            ? t('message:MS050261')
            : 'Unknown Error',
      'error'
    )
    return false
  }

  const onClickSubmitButtonEvent = async () => {
    const isValidData = await trigger()
    if (isValidData) {
      const changeContactFormValue = getValues()
      const submitedData = mapDataSubmit(changeContactFormValue)
      const requestAuthenItem = requestAuthenForm.getValues()
      onSubmitDataWithAuthen(
        onSubmit(`wf-api/customer/${detail?.customerId}/contact/address`, { body: submitedData }, [], 'POST', [])
      )(requestAuthenItem)
    }
  }

  return (
    <Modal visible={visible} transparent={true} animationType={'fade'}>
      <View style={transferStyles.pageContainer}>
        <View style={[transferStyles.modalContainer, { width: getWidthForPopup(), height: height - 160 }]}>
          <View style={transferStyles.modalHeader}>
            <View>
              <Text style={transferStyles.modalHeaderText}>{t(`TransactionType:CHANGE_CONTACT_INFO`)}</Text>
            </View>
            <TouchableOpacity
              onPress={() => {
                onPopupCloseEvent()
              }}
            >
              <assets.CloseTaskModalIcon />
            </TouchableOpacity>
          </View>
          <ScrollView showsVerticalScrollIndicator={true}>
            <View style={transferStyles.modalBody}>
              {/* <PolicyInfoHeader menus={headers} headerContainerStyle={{marginHorizontal: 0}}/> */}
              {/* <OBCallOutHeader headerData={headers} headerStyles={{ marginHorizontal: 0, marginVertical: 20, paddingHorizontal: 20, justifyContent: 'space-between', backgroundColor: 'rgb(229, 234, 239)' }} OBCallOutHeaderMessage={() => <></>}></OBCallOutHeader> */}
              <OBChangeContactHeader headerData={headers} />
              <TabList
                menus={menus}
                isSubTab={false}
                tabIndex={currentTab}
                onChangeTab={setCurrentTab}
                predicateDisable={(index) => index === 1}
              />
              <View style={{ marginTop: 15 }}>
                <Text style={sharedStyle.sectionTitle}>{t('submission:requestType')}</Text>
                <View
                  style={{
                    backgroundColor: '#FAFAFA',
                    borderRadius: 8,
                    borderWidth: 1,
                    borderColor: '#d3dce6',
                    padding: 20,
                    marginTop: 15
                  }}
                >
                  <RequestInfoPanel dataSource={requestInfo}></RequestInfoPanel>
                </View>
              </View>

              {/* Change Address */}
              <Controller
                control={control}
                name={'chooseAddress'}
                defaultValue={getValues('chooseAddress')}
                render={({ field: { value, onChange, onBlur } }) => {
                  return (
                    <View>
                      <Checkbox
                        title={t('submission:ChangeAdress').toUpperCase()}
                        value={value}
                        onChange={(val) => {
                          onChange(val)
                          if (val) pathAddress()
                        }}
                        onBlur={onBlur}
                        textStyle={{ fontWeight: 'bold' }}
                        disabled={isConfirmed}
                      />
                      {value && (
                        <OBSC.PanelContainer backgroundColor={'#fff'}>
                          <OBSC.Row>
                            <View style={{ flex: 1, minWidth: 250 }}>
                              <Controller
                                control={control}
                                name={'address.country'}
                                render={({ field: { value, onChange, onBlur } }) => {
                                  return (
                                    <ConfirmView
                                      title={t('submission:Country')}
                                      value={value?.label ?? ''}
                                      isConfirmed={isConfirmed}
                                    >
                                      <SelectSearch
                                        label={t('submission:Country')}
                                        options={countryOptions || []}
                                        popupIcon={<assets.ArrowDownDropdownIcon />}
                                        value={value}
                                        placeholder={t('common:Select')}
                                        onChange={onChange}
                                        errorMessage={_.get(errors, 'address.country')?.message}
                                        disabled
                                        required
                                      />
                                    </ConfirmView>
                                  )
                                }}
                              />
                            </View>
                            <OBSC.Divider />
                            <View style={{ flex: 1 }}>
                              <Controller
                                control={control}
                                name={'address.province'}
                                render={({ field: { value, onChange, onBlur } }) => {
                                  return (
                                    <ConfirmView
                                      title={t('submission:CityProvince')}
                                      value={value?.label ?? ''}
                                      isConfirmed={isConfirmed}
                                    >
                                      <SelectSearch
                                        label={t('submission:CityProvince')}
                                        value={value}
                                        options={provinceOptions || []}
                                        popupIcon={<assets.ArrowDownDropdownIcon />}
                                        placeholder={t('common:Select')}
                                        onChange={(value) => {
                                          if (value) {
                                            onChange(value)
                                            setValue('address.district', { label: '', value: '' })
                                            setValue('address.ward', { label: '', value: '' })
                                            getDistricts(value.value)
                                          }
                                        }}
                                        errorMessage={_.get(errors, 'address.province')?.message}
                                        required
                                      />
                                    </ConfirmView>
                                  )
                                }}
                              />
                            </View>
                            <OBSC.Divider />
                            <View style={{ flex: 1 }}>
                              <Controller
                                control={control}
                                name={'address.district'}
                                render={({ field: { value, onChange, onBlur } }) => {
                                  return (
                                    <ConfirmView
                                      title={t('submission:District')}
                                      value={value?.label ?? ''}
                                      isConfirmed={isConfirmed}
                                    >
                                      <SelectSearch
                                        label={t('submission:District')}
                                        value={value}
                                        options={districOptions || []}
                                        popupIcon={<assets.ArrowDownDropdownIcon />}
                                        placeholder={t('common:Select')}
                                        onChange={(value) => {
                                          if (value) {
                                            onChange(value)
                                            setValue('address.ward', { label: '', value: '' })
                                            getWards(watch('address.province.value'), value.value)
                                          }
                                        }}
                                        errorMessage={_.get(errors, 'address.district')?.message}
                                        required
                                      />
                                    </ConfirmView>
                                  )
                                }}
                              />
                            </View>
                          </OBSC.Row>
                          <OBSC.Divider height={15} />
                          <OBSC.Row>
                            <View style={{ flex: 1, minWidth: 250 }}>
                              <Controller
                                control={control}
                                name={'address.ward'}
                                render={({ field: { value, onChange, onBlur } }) => {
                                  return (
                                    <ConfirmView
                                      title={t('submission:Ward')}
                                      value={value?.label ?? ''}
                                      isConfirmed={isConfirmed}
                                    >
                                      <SelectSearch
                                        label={t('submission:Ward')}
                                        value={value}
                                        placeholder={t('common:Select')}
                                        options={wardOptions || []}
                                        popupIcon={<assets.ArrowDownDropdownIcon />}
                                        onChange={onChange}
                                        errorMessage={_.get(errors, 'address.ward')?.message}
                                        required
                                      />
                                    </ConfirmView>
                                  )
                                }}
                              />
                            </View>
                            <OBSC.Divider />
                            <View style={{ flex: 2, minWidth: 250, paddingTop: 5 }}>
                              <Controller
                                control={control}
                                name={'address.street'}
                                render={({ field: { value, onChange, onBlur } }) => {
                                  return (
                                    <ConfirmView
                                      title={t('submission:Address')}
                                      value={value ?? ''}
                                      isConfirmed={isConfirmed}
                                    >
                                      <Input
                                        title={t('submission:Address')}
                                        required
                                        onChange={(text) => {
                                          onChange(text)
                                        }}
                                        onBlur={() => {
                                          onBlur()
                                          onChange(value?.trim())
                                        }}
                                        value={value}
                                        maxLength={50}
                                        errorMessage={_.get(errors, 'address.street')?.message}
                                      />
                                    </ConfirmView>
                                  )
                                }}
                              />
                            </View>
                          </OBSC.Row>
                          {applyPolicyAddress && applyPolicyAddress.length > 0 && (
                            <OBSC.Padding top={20}>
                              <OBSC.TitleText>{t('submission:AppliedTo')}</OBSC.TitleText>
                            </OBSC.Padding>
                          )}
                          {applyPolicyAddress && applyPolicyAddress.length > 0 && (
                            <Checkbox
                              value={!applyPolicyAddress.some((a) => !a.selected)}
                              title={t('submission:AllPolicies')}
                              onChange={(val) => {
                                applyAllPolicy(val)
                              }}
                              disabled={isConfirmed}
                            />
                          )}
                          {applyPolicyAddress &&
                            applyPolicyAddress.map((value, index) => {
                              return (
                                <Controller
                                  key={`list_address${value?.policyNum}${value?.selected}`}
                                  control={control}
                                  name={`address.applyPolicies.${index}`}
                                  render={({ field: { value, onChange, onBlur } }) => {
                                    return (
                                      <Checkbox
                                        value={value.selected}
                                        onChange={(checked) => onChange({ ...value, selected: checked })}
                                        title={`${value.policyNum || ''} ${value.address || ''}`}
                                        disabled={isConfirmed}
                                      />
                                    )
                                  }}
                                />
                              )
                            })}
                        </OBSC.PanelContainer>
                      )}
                    </View>
                  )
                }}
              />
              {/* Change Phone number */}
              <Controller
                control={control}
                name={'choosePhone'}
                defaultValue={getValues('choosePhone')}
                render={({ field: { value, onChange, onBlur } }) => {
                  return (
                    <View>
                      <Checkbox
                        title={t('submission:ChangePhone').toUpperCase()}
                        value={value}
                        onChange={(val) => {
                          if (val) pathPhone()
                          onChange(val)
                        }}
                        onBlur={onBlur}
                        textStyle={{ fontWeight: 'bold' }}
                        disabled={isConfirmed}
                      />
                      {value && (
                        <SC.Padding top={5} bottom={5} horizontal={0}>
                          <SC.ErrorText>{t('message:MS050224')}</SC.ErrorText>
                        </SC.Padding>
                      )}
                      {value && (
                        <OBSC.PanelContainer backgroundColor={'#fff'}>
                          <OBSC.Row>
                            <View style={{ flex: 1, paddingTop: 4 }}>
                              <ConfirmView
                                title={t('submission:MobilePhone')}
                                value={`${getValues('phone.mobileCode.label') ?? ''} ${getValues('phone.mobileNum') ?? ''
                                  }`}
                                isConfirmed={isConfirmed}
                              >
                                <View>
                                  <OBSC.TitleText>
                                    {t('submission:MobilePhone')} <OBSC.RequiredMark>*</OBSC.RequiredMark>
                                  </OBSC.TitleText>
                                  <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
                                    <Controller
                                      control={control}
                                      name={'phone.mobileCode'}
                                      render={({ field: { value, onChange, onBlur } }) => {
                                        return (
                                          <View style={{ width: 110 }}>
                                            <SelectSearch
                                              options={phoneCodeOptions}
                                              onChange={onChange}
                                              value={value}
                                              popupIcon={<assets.ArrowDownDropdownIcon />}
                                              hideLabel
                                            />
                                          </View>
                                        )
                                      }}
                                    />
                                    <OBSC.Divider />
                                    <Controller
                                      control={control}
                                      name={'phone.mobileNum'}
                                      render={({ field: { value, onChange, onBlur } }) => {
                                        return (
                                          <View style={{ flex: 1 }}>
                                            <Input
                                              onChange={(value) => {
                                                onChange(value)
                                                if (/\b\d{10}\b/.test(value)) {
                                                  getDuplicatePhone(value)
                                                } else {
                                                  setValue('phone.relationships', [])
                                                }
                                              }}
                                              value={value || undefined}
                                              maxLength={10}
                                            />
                                          </View>
                                        )
                                      }}
                                    />
                                  </View>
                                  {(_.get(errors, 'phone.mobileCode')?.message ||
                                    _.get(errors, 'phone.mobileNum')?.message) && (
                                      <View>
                                        <OBSC.ErrorText>
                                          {_.get(errors, 'phone.mobileCode')?.message ||
                                            _.get(errors, 'phone.mobileNum')?.message}
                                        </OBSC.ErrorText>
                                      </View>
                                    )}
                                </View>
                              </ConfirmView>
                            </View>
                            <OBSC.Divider />
                            <View style={{ flex: 1, paddingTop: 4 }}>
                              <ConfirmView
                                title={t('submission:SecondaryPhone')}
                                value={!!getValues('phone.officePhoneNum') ? (`${getValues('phone.officeMobileCode.label') ?? ''} ${getValues('phone.officePhoneNum') ?? ''}`) : ''}
                                isConfirmed={isConfirmed}
                                required={false}
                              >
                                <View>
                                  <OBSC.TitleText>{t('submission:SecondaryPhone')}</OBSC.TitleText>
                                  <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
                                    <Controller
                                      control={control}
                                      name={'phone.officeMobileCode'}
                                      render={({ field: { value, onChange, onBlur } }) => {
                                        return (
                                          <View style={{ width: 110 }}>
                                            <SelectSearch
                                              options={phoneCodeOptions}
                                              onChange={onChange}
                                              value={value}
                                              popupIcon={<assets.ArrowDownDropdownIcon />}
                                              hideLabel
                                            />
                                          </View>
                                        )
                                      }}
                                    />
                                    <OBSC.Divider />
                                    <Controller
                                      control={control}
                                      name={'phone.officePhoneNum'}
                                      render={({ field: { value, onChange, onBlur } }) => {
                                        return (
                                          <View style={{ flex: 1 }}>
                                            <Input
                                              onChange={(value) => {
                                                onChange(value)
                                                trigger()
                                              }}
                                              onBlur={onBlur}
                                              value={value}
                                              maxLength={10}
                                            />
                                          </View>
                                        )
                                      }}
                                    />
                                  </View>
                                  {(_.get(errors, 'phone.officeMobileCode')?.message ||
                                    _.get(errors, 'phone.officePhoneNum')?.message) && (
                                      <View>
                                        <OBSC.ErrorText>
                                          {_.get(errors, 'phone.officeMobileCode')?.message ||
                                            _.get(errors, 'phone.officePhoneNum')?.message}
                                        </OBSC.ErrorText>
                                      </View>
                                    )}
                                </View>
                              </ConfirmView>
                            </View>
                            <OBSC.Divider />
                            <View style={{ flex: 1 }}>
                              <Controller
                                control={control}
                                name={'phone.receiveInfoVia'}
                                render={({ field: { value, onChange, onBlur } }) => {
                                  return (
                                    <ConfirmView
                                      title={t('submission:ReceiveInfoViaSms')}
                                      value={value?.label ?? ''}
                                      required={false}
                                      isConfirmed={isConfirmed}
                                    >
                                      <Select
                                        options={SMSOptions}
                                        // popupIcon={<View />}
                                        onChange={onChange}
                                        value={value}
                                        label={t('submission:ReceiveInfoViaSms')}
                                      />
                                    </ConfirmView>
                                  )
                                }}
                              />
                            </View>
                          </OBSC.Row>

                          {anyAgentPhone ? (
                            <OBSC.Padding vertical={15}>
                              <OBSC.ErrorText>
                                {t('message:MS050225', { agent: _.get(anyAgentPhone, 'clientName') })}
                              </OBSC.ErrorText>
                            </OBSC.Padding>
                          ) : (
                            phoneRelationShip &&
                            phoneRelationShip.length > 0 && (
                              <OBSC.Container>
                                {!isConfirmed && (
                                  <OBSC.Padding vertical={10}>
                                    <OBSC.ErrorText>{t('submission:PhoneDuplicatedWithOther')}</OBSC.ErrorText>
                                  </OBSC.Padding>
                                )}
                                {_.get(errors, 'phone.relationships') && (
                                  <OBSC.Padding vertical={5}>
                                    <OBSC.ErrorText>{t('message:MS050228')}</OBSC.ErrorText>
                                  </OBSC.Padding>
                                )}
                                <Controller
                                  control={control}
                                  name={'phone.relationships'}
                                  render={({ field: { value, onChange } }) => {
                                    return (
                                      <DuplicateTable
                                        key={`phone_table_${isConfirmed}`}
                                        value={value ?? []}
                                        onChange={onChange}
                                        disable={isConfirmed}
                                        relationshipOptions={relationOptions ?? []}
                                      />
                                    )
                                  }}
                                />
                              </OBSC.Container>
                            )
                          )}
                        </OBSC.PanelContainer>
                      )}
                    </View>
                  )
                }}
              />
              {/* Change Email */}
              <Controller
                control={control}
                name={'chooseEmail'}
                defaultValue={getValues('chooseEmail')}
                render={({ field: { value, onChange, onBlur } }) => {
                  return (
                    <View>
                      <Checkbox
                        title={t('submission:ChangeEmail').toUpperCase()}
                        value={value}
                        onChange={(val) => {
                          if (val) pathEmail()
                          onChange(val)
                        }}
                        onBlur={onBlur}
                        textStyle={{ fontWeight: 'bold' }}
                        // checkedColor={'red'}
                        disabled={isConfirmed}
                      />
                      {value && (
                        <OBSC.Padding top={5} bottom={5} horizontal={0}>
                          <OBSC.ErrorText>{t('message:MS050226')}</OBSC.ErrorText>
                        </OBSC.Padding>
                      )}
                      {value && (
                        <OBSC.PanelContainer backgroundColor={'#fff'}>
                          <OBSC.Row>
                            <View style={{ width: '33%', paddingTop: 5 }}>
                              <Controller
                                control={control}
                                name={'email.email'}
                                render={({ field: { value, onChange, onBlur } }) => {
                                  return (
                                    <ConfirmView
                                      title={t('submission:Email')}
                                      value={value ?? ''}
                                      isConfirmed={isConfirmed}
                                    >
                                      <Input
                                        value={value}
                                        onChange={(value) => {
                                          onChange(value)
                                          handlerEmail(value)
                                        }}
                                        onBlur={onBlur}
                                        required
                                        title={t('submission:Email')}
                                        errorMessage={_.get(errors, 'email.email')?.message}
                                      //onSubmit={() => getDuplicateEmail(value)}
                                      />
                                    </ConfirmView>
                                  )
                                }}
                              />
                            </View>
                            <OBSC.Divider />
                            <View style={{ width: '30%' }}>
                              <Controller
                                control={control}
                                name={'email.receiveInfoVia'}
                                render={({ field: { value, onChange, onBlur } }) => {
                                  return (
                                    <ConfirmView
                                      title={t('submission:ReceiveInfoViaEmail')}
                                      value={value?.label ?? ''}
                                      isConfirmed={isConfirmed}
                                    >
                                      <Select
                                        options={EmailOptions}
                                        onChange={onChange}
                                        value={value}
                                        label={t('submission:ReceiveInfoViaEmail')}
                                      />
                                    </ConfirmView>
                                  )
                                }}
                              />
                            </View>
                          </OBSC.Row>

                          <OBSC.Divider />

                          {anyAgentEmail ? (
                            <OBSC.Padding vertical={10}>
                              <OBSC.ErrorText>
                                {t('message:MS050227', { agent: _.get(anyAgentEmail, 'clientName') })}
                              </OBSC.ErrorText>
                            </OBSC.Padding>
                          ) : (
                            emailRelationShip &&
                            emailRelationShip.length > 0 && (
                              <OBSC.Container>
                                {!isConfirmed && (
                                  <OBSC.Padding vertical={10}>
                                    <OBSC.ErrorText>{t('submission:EmailDuplicatedWithOther')}</OBSC.ErrorText>
                                  </OBSC.Padding>
                                )}
                                {_.get(errors, 'email.relationships') && (
                                  <OBSC.Padding vertical={5}>
                                    <OBSC.ErrorText>{t('message:MS050228')}</OBSC.ErrorText>
                                  </OBSC.Padding>
                                )}
                                <Controller
                                  control={control}
                                  name={'email.relationships'}
                                  render={({ field: { value, onChange } }) => {
                                    return (
                                      <DuplicateTable
                                        key={`email_table_${isConfirmed}`}
                                        value={value ?? []}
                                        onChange={onChange}
                                        disable={isConfirmed}
                                        relationshipOptions={relationOptions ?? []}
                                      />
                                    )
                                  }}
                                />
                              </OBSC.Container>
                            )
                          )}
                        </OBSC.PanelContainer>
                      )}
                    </View>
                  )
                }}
              />
              {currentTab === 1 && (
                <>
                  <RequestAuthen
                    requestAuthenData={eformData}
                    authenForm={requestAuthenForm}
                    updateSendDate={updateSendDate}
                    updatedocReviewed={updatedocReviewed}
                    updateRequestAuthenData={setEformData}
                    isByPassAuthPaper={true}
                    officeCode={''}
                    transactionType={TransactionType.CHANGE_CONTACT_INFO}
                    isHiddenRequestForm={true}
                  />
                </>
              )}

            </View>
          </ScrollView>
          <View style={[transferStyles.modalButtonContent, transferStyles.modalSecondLine]}>
            <TouchableOpacity onPress={() => props.onPopupCloseEvent && props.onPopupCloseEvent()}>
              <View style={[OBSharedStyles.actionContent, transferStyles.actionLine, transferStyles.modalButton]}>
                <Text style={OBSharedStyles.actionContentText}>{t('common:Cancel')}</Text>
              </View>
            </TouchableOpacity>
            {currentTab === 0 ? (
              <>
                <TouchableOpacity onPress={() => onClickContinuedButtonEvent()}>
                  <View
                    style={[
                      OBSharedStyles.actionContent,
                      // isValidForm() ? OBSharedStyles.actionHightLight : OBSharedStyles.actionDisable,
                      transferStyles.actionLine,
                      transferStyles.modalButton
                    ]}
                  >
                    <Text style={[OBSharedStyles.actionContentText]}>{t('submission:Continue')}</Text>
                  </View>
                </TouchableOpacity>
              </>
            ) : (
              <>
                <TouchableOpacity
                  disabled={!validateRequestAuthenForm()}
                  onPress={() => onClickSubmitButtonEvent()}
                >
                  <View
                    style={[
                      OBSharedStyles.actionContent,
                      !validateRequestAuthenForm() && OBSharedStyles.actionNonBackgroundDisable,
                      transferStyles.actionLine,
                      transferStyles.modalButton
                    ]}
                  >
                    <Text
                      style={[
                        validateRequestAuthenForm()
                          ? OBSharedStyles.actionContentText
                          : OBSharedStyles.actionNonBackgroundDisableText
                      ]}
                    >
                      {t('submission:SendRequest')}
                    </Text>
                  </View>
                </TouchableOpacity>
              </>
            )}
          </View>
          <OBPageLoading isShowLoading={isShowLoadingIcon}></OBPageLoading>
        </View>
      </View>
    </Modal>
  )
}
const transferStyles = StyleSheet.create({
  pageContainer: {
    flex: 1,
    backgroundColor: 'rgba(0,0,0,0.25)',
    justifyContent: 'center',
    alignItems: 'center'
  },
  modalContainer: {
    width: 850,
    height: 600,
    paddingHorizontal: 24,
    paddingVertical: 20,
    backgroundColor: 'background-color: rgb(242, 242, 242)',
    borderRadius: 8,
    boxShadow: '2px 4px 16px 0px rgba(0, 0, 0, 0.08)'
  },
  modalHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  modalHeaderText: {
    fontSize: 20,
    fontStyle: 'normal',
    fontWeight: '600',
    color: '#4F4F4F'
  },
  modalBody: {
    paddingVertical: 16
    // display: 'flex',
    // height: '100%',
    // width: '100%'
  },
  actionLine: {
    marginLeft: 8
  },
  modalSecondLine: {
    marginTop: 16
  },
  modalButtonContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  modalButton: {
    // width: 155
  }
})
