import { FormModalData, ModalFormsAddAndEdit, T6SearchFormData } from './models'

export const COLORS = {
  SNOW: '#fafafa',
  HOWKES_BLUE: '#d3dce6',
  ALIZARIN: '#ED1B2E',
  WHITE: '#ffff',
  ROLLING_STONE: '#70777e',
  BLACK: '#000000',
  MYSTIC: '#E5EAEF',
  BISCAY: '#1B365D'
}

export const formsModal: ModalFormsAddAndEdit[] = [
  {
    isVisible: true,
    groupName: 'col_1',
    group: [
      {
        type: 'suspendBy',
        typeField: 'DROPDOWN_LIST',
        label: 'Suspend By',
        editable: true,
        placeholder: '_',
        required: false,
        options: [{ label: '222', value: '222' }]
      },

      {
        type: 'suspendGroupVN',
        typeField: 'TEXT',
        label: 'Suspend Group (VN)',
        editable: true,
        placeholder: '-',
        required: false
      },
      {
        type: 'suspendTypeVN',
        typeField: 'TEXT',
        label: 'Suspend Type (VN)',
        editable: true,
        placeholder: '-',
        required: false
      },
      {
        type: 'doctypeCode',
        typeField: 'TEXT',
        label: 'DocTypeCode',
        editable: true,
        placeholder: '-',
        required: false
      },
      {
        type: 'timeOut',
        typeField: 'TEXT',
        label: 'Time-Out (hours)',
        editable: true,
        placeholder: '-',
        required: false
      },
      {
        type: 'sendCommunication',
        typeField: 'RADIO',
        label: '',
        editable: true,
        required: false,
        placeholder: ''
      }
    ]
  },
  {
    isVisible: true,
    groupName: 'col_2',
    group: [
      {
        type: 'suspendGroupEN',
        typeField: 'TEXT',
        label: 'Suspend Group (EN)',
        editable: true,
        placeholder: '-',
        required: false
      },
      {
        type: 'suspendCode',
        typeField: 'TEXT',
        label: 'Suspend Code',
        editable: true,
        placeholder: '-',
        required: false
      },
      {
        type: 'description',
        typeField: 'TEXT',
        label: 'Description (Letter content)',
        editable: true,
        placeholder: '-',
        required: false
      },
      {
        type: 'department',
        typeField: 'DROPDOWN_LIST',
        label: 'Department',
        editable: true,
        placeholder: '-',
        required: false,
        options: [{ label: '2', value: '2' }]
      },
      {
        type: 'autoReject',
        typeField: 'DROPDOWN_LIST',
        label: 'Suspend Group (EN)',
        editable: true,
        placeholder: '-',
        required: false,
        options: [
          { label: '1', value: '1' },
          { label: '3', value: '3' }
        ]
      }
    ]
  },
  {
    isVisible: true,
    groupName: 'col_3',
    group: [
      {
        type: 'suspendGroupCode',
        typeField: 'TEXT',
        label: 'Suspend Group Code',
        editable: true,
        placeholder: '-',
        required: false
      },
      {
        type: 'suspendTypeEN',
        typeField: 'TEXT',
        label: 'Suspend Type (EN)',
        editable: true,
        placeholder: '-',
        required: false
      },
      {
        type: 'docType',
        typeField: 'TEXT',
        label: 'DocType',
        editable: true,
        placeholder: '-',
        required: false
      },
      {
        type: 'departmentShortName',
        typeField: 'TEXT',
        label: 'Department Short Name',
        editable: false,
        placeholder: '-',
        required: false
      },
      {
        type: 'status',
        typeField: 'DROPDOWN_LIST',
        label: 'Status',
        editable: true,
        placeholder: '-',
        required: false,
        options: [{ label: '5', value: '5' }]
      }
    ]
  }
]

export const defaultValuesSearch: T6SearchFormData = {
  suspendBy: undefined,
  suspendCode: undefined,
  status: undefined
}

export const defaultValuesModal: FormModalData = {
  suspendBy: undefined,
  suspendGroupEN: '',
  suspendGroupCode: '',
  suspendGroupVN: '',
  suspendCode: '',
  suspendTypeEN: '',
  suspendTypeVN: '',
  description: '',
  docType: '',
  doctypeCode: '',
  department: undefined,
  departmentShortName: '',
  timeOut: '',
  autoReject: undefined,
  status: undefined,
  sendCommunication: ''
}

export const titleHeaders = [
  { title: 'No', style: { minWidth: 60, fontWeight: 'bold', fontSize: 15 } },
  { title: 'Suspend by', style: { minWidth: 150, fontWeight: 'bold', fontSize: 15 } },
  { title: 'Suspend group (EN)', style: { minWidth: 200, fontWeight: 'bold', fontSize: 15 } },
  { title: 'Suspend group code', style: { minWidth: 200, fontWeight: 'bold', fontSize: 15 } },
  { title: 'Suspend group (VN)', style: { minWidth: 200, fontWeight: 'bold', fontSize: 15 } },
  { title: 'Suspend code', style: { minWidth: 200, fontWeight: 'bold', fontSize: 15 } },
  { title: 'Suspend type (EN)', style: { minWidth: 312, fontWeight: 'bold', fontSize: 15 } },
  { title: 'Suspend type (VN)', style: { minWidth: 180, fontWeight: 'bold', fontSize: 15 } },
  { title: 'Description (Letter content)', style: { minWidth: 232, fontWeight: 'bold', fontSize: 15 } },
  { title: 'Doctype', style: { minWidth: 180, fontWeight: 'bold', fontSize: 15 } },
  { title: 'Doctype code', style: { minWidth: 180, fontWeight: 'bold', fontSize: 15 } },
  { title: 'Department', style: { minWidth: 192, fontWeight: 'bold', fontSize: 15 } },
  { title: 'Department short name', style: { minWidth: 222, fontWeight: 'bold', fontSize: 15 } },
  { title: 'Time-Out', style: { minWidth: 122, fontWeight: 'bold', fontSize: 15 } },
  { title: 'Auto Reject', style: { minWidth: 122, fontWeight: 'bold', fontSize: 15 } },
  { title: 'Status', style: { minWidth: 92, fontWeight: 'bold', fontSize: 15 } },
  { title: 'Send Communication', style: { minWidth: 162, fontWeight: 'bold', fontSize: 15 } },
  { title: '', style: { minWidth: 120, fontWeight: 'bold', fontSize: 15 } }
]
