// React Lib
import React, { useMemo, useRef, lazy, Suspense } from 'react'
import { View, TouchableOpacity, Text, StyleSheet } from 'react-native'

// Common
import { COLORS } from './ConfigTableT6Const'
import { CF } from './ConfigTableT6Styles'
import ListAreaComp, { onActionRowType } from './ListArea/ListAreaComp'
import { ModalRef, onActionType } from './ModalAddAndEditRow/ModalAddAndEditRowComp'
import { ModalDeleteRef } from './ModalDeleteRow/ModalDeleteRow'
import { Actions, actionsType, modeViewType, Mode_View } from './models'

// Component
import SearchAreaFormComp, { FormAreaRef } from './SearchAreaForm/SearchAreaFormComp'
const ModalAddAndEditRow = lazy(() => import('./ModalAddAndEditRow/ModalAddAndEditRowComp'))
const ModalDeleteRow = lazy(() => import('./ModalDeleteRow/ModalDeleteRow'))

const { Container, Body, SearchArea, Row } = CF

const actions = [
  {
    type: Actions['SEARCH'],
    text: 'Search',
    isHavePermission: true
  },
  {
    type: Actions['ADD_ROW'],
    text: 'Add row',
    isHavePermission: true
  }
]

const ConfigTableT6Screen: React.FC = () => {
  const searchFormRef = useRef<FormAreaRef | any>()
  const modalRef = useRef<ModalRef | any>()
  const modalDeleteRef = useRef<ModalDeleteRef | any>()

  const handleOpenModalWithMode = (mode: modeViewType) => {
    const { setMode, setIsShowModal } = modalRef?.current ?? {}
    setIsShowModal(true)
    setMode(mode)
  }

  const handleActionModal = ({ type, valuesForm }: onActionType) => {
    console.log({
      type,
      valuesForm
    })
  }

  const handleActionListRow = ({ type, payload }: onActionRowType) => {
    const { setValuesModalForm } = modalRef?.current ?? {}

    const actionTypeHandler = new Map<actionsType | 'DEFAULT', Function>([
      [
        Actions['DELETE_ROW'],
        () => {
          const { setIsShowModal } = modalDeleteRef?.current ?? {}
          setIsShowModal(true)
        }
      ],
      [
        Actions['EDIT_ROW'],
        () => {
          handleOpenModalWithMode(Mode_View['EDIT'])
          setValuesModalForm(payload)
        }
      ]
    ])

    return actionTypeHandler.get(type)?.()
  }

  const renderSearchArea = useMemo(() => {
    const handleClickActions = (type: actionsType) => {
      const actionTypeHandler = new Map<actionsType | 'DEFAULT', Function>([
        [
          Actions['SEARCH'],
          () => {
            const { getValuesSearchForm } = searchFormRef.current ?? {}
            const payload = getValuesSearchForm()
            console.log('payload:', payload)
          }
        ],
        [Actions['ADD_ROW'], () => handleOpenModalWithMode(Mode_View['ADD'])]
      ])
      return actionTypeHandler.get(type)?.()
    }

    const renderButtonsActions = () => {
      return actions.map((action, index) => {
        const { text, type, isHavePermission } = action
        const isButtonSearch = type === Actions.SEARCH

        return (
          <React.Fragment key={index}>
            {isHavePermission && (
              <TouchableOpacity
                style={{ width: 118, height: 40, ...(isButtonSearch && { marginRight: 30 }) }}
                onPress={() => handleClickActions(type as actionsType)}
              >
                <View style={PRStyle.primaryBtn}>
                  <Text style={{ textAlign: 'center', color: COLORS.WHITE }}>{text}</Text>
                </View>
              </TouchableOpacity>
            )}
          </React.Fragment>
        )
      })
    }

    return (
      <SearchArea>
        <SearchAreaFormComp ref={searchFormRef} />
        <Row style={{ marginTop: 30 }}> {renderButtonsActions()}</Row>
      </SearchArea>
    )
  }, [])

  const renderListArea = useMemo(() => {
    return <ListAreaComp onAction={handleActionListRow} />
  }, [])

  return (
    <Container>
      <Body>
        {renderSearchArea}
        {renderListArea}
      </Body>

      <Suspense fallback={<div>Loading...</div>}>
        <ModalAddAndEditRow ref={modalRef} onAction={handleActionModal} />
      </Suspense>

      <Suspense fallback={<div>Loading...</div>}>
        <ModalDeleteRow ref={modalDeleteRef} />
      </Suspense>
    </Container>
  )
}

export default ConfigTableT6Screen

const PRStyle = StyleSheet.create({
  primaryBtn: {
    width: 118,
    height: 40,
    backgroundColor: `${COLORS.ALIZARIN}`,
    borderRadius: 100,
    padding: 10,
    boxShadow: 'none'
  }
})
