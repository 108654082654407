import { form2 } from '@pulseops/common'
import * as t from 'io-ts'
import { pipe } from 'fp-ts/function'
import { withMinStringLength, withMaxStringLength, withStringPattern } from '@pulseops/submission/common'
import i18next from 'i18next'
export namespace PolicyOfOrtherClientForm {

  type SearchedPolicyBrand = {
    readonly MinLength: unique symbol
  }
  type SearchedPolicyType = t.Branded<string, SearchedPolicyBrand>
  const POCform = t.type({
    policyNumber: pipe(
      form2.string.required,
      withStringPattern('^[0-9]*$', 'message:MS070018'),
      withMinStringLength(8),
      withMaxStringLength(8)
    ),
    poName: form2.string.required,
    paidToDate: form2.string.required,
    installmentPremium: form2.number.required,
    totalPremium: form2.number.required,
    finalPolicyNum: form2.string.required
  })

  export const codec = t.type({
    list: t.array(POCform),
    // searchedPolicy: pipe(
    //   form2.string.optional,
    //   form2.refine(
    //     (l): l is SearchedPolicyType => !l || l.length === 8,
    //     () => i18next.t('message:MinLength', { field: 8 }),
    //     'MinLength'
    //   )
    // )
  })

  export const payPremiumPolicyDetail = t.type({
    selectedAllRow: t.boolean,
    searchedPolicy: pipe(
      form2.string.optional,
      form2.refine(
        (l): l is SearchedPolicyType => !l || l.length === 8,
        () => i18next.t('message:MinLength', { field: 8 }),
        'MinLength'
      )
    ),
    payPreOptionalPolicyList: t.array(t.type({
      policyNumber: form2.string.optional,
      paidToDate: form2.string.optional,
      installmentPremium: form2.number.optional,
      selectedRow: t.boolean,
      poName: t.string,
      totalPremium: t.number
    })),
    payPremiumChosenPolicyArr: t.array(t.type({
      policyNumber: form2.string.optional,
      paidToDate: form2.string.optional,
      installmentPremium: form2.number.optional,
      selectedRow: t.boolean,
      poName: t.string,
      totalPremium: t.number
    }))
  })

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.OutputOf<typeof codec>

  export type payPreOptionalPolicyListRaw = t.OutputOf<typeof payPremiumPolicyDetail>
}
