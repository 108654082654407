import { SelectOption } from "@pulseops/common"

export namespace OBCallingResultDetailFrom {
    export type OBQuestionAnswerInfo = {
        questionItem: SelectOption
        answerItem: SelectOption | undefined | null
        calendar: Date | null
        answerNote: string
    }
    export type OBCallingResultDetailData = {
        questionAnswerList: Array<OBQuestionAnswerInfo>,
        detailNote: string,
        customerBehavior: string,
        isCustomerPhoneUse: boolean
    }
}