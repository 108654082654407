import { FundCode } from '@pulseops/common'
import i18next from 'i18next'

export interface Fund {
  code: string
  name: string
}

export namespace UnitLinkFormConst {
  export const TargetPremium: Fund[] = [
    {
      code: FundCode.UBL1,
      name: 'fund:PRUlinkBalanced'
    },
    {
      code: FundCode.UEQ1,
      name: 'fund:PRUlinkVNEquity'
    },
    {
      code: FundCode.UFI1,
      name: 'fund:PRUlinkVNFixedInc'
    },
    {
      code: FundCode.UGR1,
      name: 'fund:PRUlinkGrowth'
    },
    {
      code: FundCode.UPS1,
      name: 'fund:PRUlinkPreserver'
    },
    {
      code: FundCode.USB1,
      name: 'fund:PRUlinkStable'
    }
  ]

  export const TopUpAcc: Fund[] = [
    {
      code: FundCode.UBL2,
      name: 'fund:PRUlinkBalancedTopup'
    },
    {
      code: FundCode.UEQ2,
      name: 'fund:PRUlinkVNEquityTopup'
    },
    {
      code: FundCode.UFI2,
      name: 'fund:PRUlinkVNFixedIncTopup'
    },
    {
      code: FundCode.UGR2,
      name: 'fund:PRUlinkGrowthTopup'
    },
    {
      code: FundCode.UPS2,
      name: 'fund:PRUlinkPreserverTopup'
    },
    {
      code: FundCode.USB2,
      name: 'fund:PRUlinkStableTopup'
    }
  ]

  export const TotalFunds = TargetPremium.concat(TopUpAcc)

  export const ListAllFunds = [
    {
      code: '',
      name: i18next.language === 'vi' ? 'Tất cả' : 'All'
    },
    ...TotalFunds
  ]
}
