import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text, ActivityIndicator } from 'react-native'
import { IBGeneralTable, IBStyles } from '../../../common'
import { useLoading } from '@mxt/zio-react'
import { IBService, MedicalInfoData } from '../../../ib-service'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { PulseOpsFormat } from '@pulseops/common'
import moment from 'moment'
interface Props {
  medicalInfo: MedicalInfoData[]
  setMedicalInfo: (data: MedicalInfoData[]) => void
  claimNumber: string
  totalActualExpense: number
}

export const IBMedicalInfo = ({medicalInfo, setMedicalInfo, claimNumber, totalActualExpense}: Props) => {
  const { t } = useTranslation('Inbound')
  const [loading, bindLoading] = useLoading(false)

  React.useEffect(() => {
    if(claimNumber && medicalInfo.length === 0) {
      pipe(
        IBService.getMedicalInfo(claimNumber),
        ZIO.map((res) => {
          res && setMedicalInfo(res.map((i) => ({
            ...i,
            diagnosises: i.diagnosises && i.diagnosises.map((elm) => ({
              code: elm.code ?? '-',
              description: elm.description ?? '-'
            })),
            surgeryInfos: i.surgeryInfos && i.surgeryInfos.map((elm) => ({
              surgeryDate: elm.surgeryDate ? moment(elm.surgeryDate, 'DD/MM/YYYY').format('YYYYMMDD') : '-',
              surgeryCode: (elm.surgeryCode ?? '') + '-' + (elm.surgeryName ?? ''),
              surgeryName: (elm.surgeryName ?? '-')
            })),
          })))
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    }
  }, [claimNumber])

  const tableDiagnosis = [
    {
      label: t('Diagnosis'),
      field: 'code'
    },
    {
      label: t('MedDescription'),
      field: 'description'
    }
  ]

  const tableSurgery = [
    {
      label: t('SurgeryDate'),
      field: 'surgeryDate',
      format: 'date'
    },
    {
      label: t('SurgeryCode'),
      field: 'surgeryCode'
    }
  ]

  const dataHospital = (data: MedicalInfoData) => [
    {
      title: t('Hospital'),
      value: (data?.hospital?.code ?? '-') + ' ' + (data?.hospital?.name ?? '')
    },
    {
      title: t('StartDateMedical'),
      value: data?.hospitalStartDate ?? ''
    },
    {
      title: t('EndDateMedical'),
      value: data?.hospitalEndDate ?? ''
    },
    {
      title: t('TotalDays'),
      value: data?.totalDays
    },
    {
      title: t('TreatingDoctor'),
      value: data?.treatingDoctor
    }
  ]

  return (
    <View>
      {loading ? (
        <View>
          <ActivityIndicator size="large" animating={true} hidesWhenStopped={true} color="red" />
        </View>
      ) : (
        <View>
          <View style={[IBStyles.sectionCol4, { paddingBottom: 24, paddingRight: 8 }]}>
            <View style={{ flexDirection: 'row', marginTop: 5, marginBottom: 4 }}>
              <Text style={{ fontWeight: '700', color: '#58647A', fontSize: 15 }}>{t('TotalActualExpense')}</Text>
            </View>
            <Text style={{ fontSize: 15, marginTop: 4 }}>{PulseOpsFormat.currency(totalActualExpense)}</Text>
          </View>
          {medicalInfo.map((item) => (
            <View style={IBStyles.container}>
              <View style={IBStyles.row}>
                {dataHospital(item).map((x) =>
                  <View style={[IBStyles.sectionCol4, { paddingBottom: 24, paddingRight: 8 }]}>
                    <View style={{ flexDirection: 'row', marginTop: 5, marginBottom: 4 }}>
                      <Text style={{ fontWeight: '700', color: '#58647A', fontSize: 15 }}>{x.title}</Text>
                    </View>
                    <Text style={{ fontSize: 15, marginTop: 4 }}>{x.value ?? '-'}</Text>
                  </View>
                )}
              </View>
              <View style={[IBStyles.row, { paddingBottom: 24, overflow: 'hidden' }]}>
                <IBGeneralTable
                  dataTable={tableDiagnosis}
                  data={item?.diagnosises ?? []}
                  loading={loading}
                  minWidth={800}
                />  
              </View>
              <View style={[IBStyles.row, { paddingBottom: 24, overflow: 'hidden' }]}>
                <IBGeneralTable
                  dataTable={tableSurgery}
                  data={item?.surgeryInfos ?? []}
                  loading={loading}
                  minWidth={800}
                />  
              </View>
              <View style={IBStyles.row}>
                <View style={[IBStyles.sectionCol12]}>
                  <View style={{ flexDirection: 'row', marginTop: 5, marginBottom: 4 }}>
                    <Text style={{ fontWeight: '700', color: '#58647A', fontSize: 15 }}>{t('Remark')}</Text>
                  </View>
                  <Text style={{ fontSize: 15, marginTop: 4 }}>{item?.remark ?? '-'}</Text>
                </View>
              </View>
            </View>
          ))}
        </View>
      )}
    </View>
  )
}