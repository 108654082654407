import { Task, ZIO } from '@mxt/zio'
import { Maybe } from '@mxt/zio/codec'
import { Duration } from '@mxt/zio/date'
import * as A from 'fp-ts/Array'
import { identity, pipe } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import { Option } from 'fp-ts/Option'
import * as t from 'io-ts'
import { AuthService } from '../auth'
import { PageResult } from '../pagination'
import { POApi } from '../POApi'
import {
  HistoricTask,
  QueryTask,
  ServiceInquiryNote,
  SubTaskCode,
  SubTaskCodeFromString,
  SubTaskLabel,
  TaskLabel,
  TaskOp,
  TaskPremiumOp,
  TaskType,
  TaskTypeC,
  TransactionStatus,
  TaskDSOp,
  ErrorActivity,
  HistoricProcessInstances,
  TaskAccountingOP,
  SubTaskType,
  TaskIDCOp
} from './model'

export interface TaskCount {
  type: TaskType
  label: (converter: (val: string) => string) => string
  value: number
  subTasks: SubTaskCount[]
}

export interface SubTaskCount {
  code: SubTaskCode
  label: (converter: (val: string) => string) => string
  value: number
}

export namespace TaskService {

  export const PSSLAStandard = t.type({
    id: Maybe(t.string),
    category: t.string,
    transactionTypeWf: t.string,
    slaStandard: t.string
  })

  export type PSSLAStandard = t.TypeOf<typeof PSSLAStandard>

  export const countTasks = (endpoint: string): Task<TaskCount[]> => {
    const CountTasksResponse = t.type(
      {
        body: t.array(
          t.type({
            code: TaskTypeC,
            values: t.array(
              t.type({
                code: t.string,
                value: t.number
              })
            )
          })
        )
      },
      'CountTasksResponse'
    )

    return pipe(
      POApi.get(endpoint)(CountTasksResponse),
      ZIO.map(({ body }) => {
        return pipe(
          body,
          A.map(
            ({ code, values }): TaskCount => ({
              type: code,
              label: TaskLabel(code),
              value: pipe(
                values,
                A.findFirst(({ code }) => code === 'TOTAL'),
                O.fold(
                  () => -1,
                  ({ value }) => value
                )
              ),
              subTasks: pipe(
                values,
                A.map(
                  ({ code, value }): Option<SubTaskCount> =>
                    code !== 'UW-QC' ?
                      pipe(
                        SubTaskCodeFromString.decode(code),
                        O.fromEither,
                        O.map((code): SubTaskCount => ({ code, label: SubTaskLabel(code), value }))
                      )
                    :
                      O.of({ 
                        code: { task: TaskType.PolicyService, subTask: SubTaskType.QCUnderwriting}, 
                        label: SubTaskLabel({ task: TaskType.PolicyService, subTask: SubTaskType.QCUnderwriting }), 
                        value 
                      })
                ),
                A.filterMap(identity)
              )
            })
          )
        )
      })
    )
  }

  export const countMyTasks: Task<TaskCount[]> = pipe(
    AuthService.userInfo,
    ZIO.flatMap((user) => countTasks(`pulseops/api/v1/tasks/my-tasks/count?assignee=${user.email}`)),
    // ZIO.map((myTasks) => [
    //   ...myTasks,
    //   {
    //     type: TaskType.IDC,
    //     label: TaskLabel(TaskType.IDC),
    //     value: 0,
    //     subTasks: [
    //       {
    //         code: {
    //           task: TaskType.IDC,
    //           subTask: SubTaskType.Verification
    //         },
    //         label: SubTaskLabel({
    //           task: TaskType.IDC,
    //           subTask: SubTaskType.Verification
    //         }),
    //         value: 0
    //       },
    //       {
    //         code: {
    //           task: TaskType.IDC,
    //           subTask: SubTaskType.QC
    //         },
    //         label: SubTaskLabel({
    //           task: TaskType.IDC,
    //           subTask: SubTaskType.QC
    //         }),
    //         value: 0
    //       }
    //     ]
    //   }
    // ])
  )

  export const countTeamTasks: Task<TaskCount[]> = pipe(
    AuthService.userInfo,
    ZIO.flatMap((user) => countTasks(`pulseops/api/v1/tasks/team-tasks/count?assignee=${user.email}`)),
    // ZIO.delay(Duration.seconds(10)),
    ZIO.cached(Duration.minutes(5)),
    ZIO.map((teamTasks) => {
      // const newCountTasks = [...teamTasks, {
      //   type: TaskType.IDC,
      //   label: TaskLabel(TaskType.IDC),
      //   value: 0,
      //   subTasks: [
      //     {
      //       code: {
      //         task: TaskType.IDC,
      //         subTask: SubTaskType.Verification
      //       },
      //       label: SubTaskLabel({
      //         task: TaskType.IDC,
      //         subTask: SubTaskType.Verification
      //       }),
      //       value: 0
      //     },
      //     {
      //       code: {
      //         task: TaskType.IDC,
      //         subTask: SubTaskType.QC
      //       },
      //       label: SubTaskLabel({
      //         task: TaskType.IDC,
      //         subTask: SubTaskType.QC
      //       }),
      //       value: 0
      //     },
      //     {
      //       code: {
      //         task: TaskType.IDC,
      //         subTask: SubTaskType.Suspend
      //       },
      //       label: SubTaskLabel({
      //         task: TaskType.IDC,
      //         subTask: SubTaskType.Suspend
      //       }),
      //       value: 0
      //     }
      //   ]
      // }]
      return teamTasks.map((item) => {
        let subTasks = item.subTasks
        let subTasksLength = subTasks.length
        if (subTasks[subTasksLength - 1].code.subTask !== SubTaskType.ErrorHandling) {
          let errorHandlerSubTask = Object.assign(
            { ...subTasks[subTasksLength - 1], value: 0 },
            {
              code: {
                task: subTasks[subTasksLength - 1].code.task,
                subTask: SubTaskType.ErrorHandling
              }
            }
          )
          subTasks.push(errorHandlerSubTask)
        }
        return item
      })
    })
    // ZIO.map((myTasks) => [
    //   ...myTasks,
    //   {
    //     type: TaskType.DistributionService,
    //     label: TaskLabel(TaskType.DistributionService),
    //     value: 0,
    //     subTasks: [
    //       {
    //         code: {
    //           task: TaskType.DistributionService,
    //           subTask: SubTaskType.Verification
    //         },
    //         label: SubTaskLabel({
    //           task: TaskType.DistributionService,
    //           subTask: SubTaskType.Verification
    //         }),
    //         value: 96
    //       },
    //       {
    //         code: {
    //           task: TaskType.DistributionService,
    //           subTask: SubTaskType.QC
    //         },
    //         label: SubTaskLabel({
    //           task: TaskType.DistributionService,
    //           subTask: SubTaskType.QC
    //         }),
    //         value: 0
    //       },
    //       {
    //         code: {
    //           task: TaskType.DistributionService,
    //           subTask: SubTaskType.Suspend
    //         },
    //         label: SubTaskLabel({
    //           task: TaskType.DistributionService,
    //           subTask: SubTaskType.Suspend
    //         }),
    //         value: 0
    //       }
    //     ]
    //   }
    // ])
  )

  //pulseops/api/v1/query/process-instances
  //pulseops/api/v1/query/tasks
  export const queryTasks = (data: QueryTask.InputData): Task<PageResult<TaskOp>> => {
    return data.suspended
      ? pipe(
          POApi.post(
            data.taskCategory?.subTask === SubTaskType.WriteOff
              ? `pulseops/api/v1/query/process-instances/suspend-write-off`
              : `pulseops/api/v1/query/process-instances`
          )(QueryTask.Response)(QueryTask.PostSuspendData(data)),
          ZIO.map(QueryTask.toPageResult(data.pagination, TransactionStatus.PENDING))
        )
      : pipe(
          POApi.post(data.isTeam ? 'pulseops/api/v1/query/team-tasks' : `pulseops/api/v1/query/tasks`)(
            QueryTask.Response
          )(QueryTask.PostData(data)),
          ZIO.map(QueryTask.toPageResult(data.pagination, TransactionStatus.IN_PROGRESS))
        )
  }

  export const getHistoricTasks = (data: HistoricTask.InputData): Task<PageResult<TaskOp>> =>
    pipe(
      // getAuth({ params: { order: data.order || 'desc', size: data.pagination.pageSize, start: data.pagination.pageIndex } })(
      //   `pulseops/api/v1/history/historic-task-instances?finished=true&processFinished=true`,
      //   QueryTask.Response
      // ),
      // `pulseops/api/v1/history/historic-task-instances`
      POApi.post(`pulseops/history/historic-process-instances`)(QueryTask.Response)(
        QueryTask.PostData({ ...data, isTeam: false, active: undefined })
      ),
      ZIO.map(QueryTask.toPageResult(data.pagination, null))
    )
  export const getHistoricTasksInbound = (data: HistoricTask.InputData): Task<PageResult<TaskOp>> =>
    pipe(
      // getAuth({ params: { order: data.order || 'desc', size: data.pagination.pageSize, start: data.pagination.pageIndex } })(
      //   `pulseops/api/v1/history/historic-task-instances?finished=true&processFinished=true`,
      //   QueryTask.Response
      // ),
      // `pulseops/api/v1/inbound/history/historic-process-instances`
      POApi.post(`pulseops/history/historic-process-instances`)(QueryTask.Response)(
        QueryTask.PostData({ ...data, isTeam: false, active: undefined })
      ),
      ZIO.map(QueryTask.toPageResult(data.pagination, null))
    )

  export const getHistoricTraditionalClaimTasks = (data: HistoricTask.InputData): Task<PageResult<TaskOp>> =>
    pipe(
      POApi.post(`pulseops/history/historic-traditional-claim-process-instances`)(QueryTask.Response)(
        QueryTask.PostData({ ...data, isTeam: false, active: undefined })
      ),
      ZIO.map(QueryTask.toPageResult(data.pagination, null))
    )

  /**
   * Specify for Premium Collection Search
   *
   * @param data HistorcTask.InputData
   * @returns PageResult<TaskOp>
   */
  export const getHistoryTasks = (data: HistoricTask.InputData): Task<PageResult<TaskPremiumOp>> =>
    pipe(
      // history -> historic :)))
      POApi.post(`pulseops/history/historic-process-instances`)(QueryTask.Response)(
        QueryTask.PostData({ ...data, isTeam: false, active: undefined })
      ),
      ZIO.map(QueryTask.toPagePCresult(data.pagination, null))
    )

  export const getHistoricCashOut = (data: HistoricTask.InputData, suspendCode: string): Task<PageResult<TaskOp>> =>
    pipe(
      // getAuth({ params: { order: data.order || 'desc', size: data.pagination.pageSize, start: data.pagination.pageIndex } })(
      //   `pulseops/api/v1/history/historic-task-instances?finished=true&processFinished=true`,
      //   QueryTask.Response
      // ),
      // `pulseops/api/v1/history/historic-task-instances`
      POApi.post(`pulseops/api/v1/query/pending/cash-out/${suspendCode}`)(QueryTask.Response)(
        QueryTask.PostDataCashOut({ ...data, isTeam: false, active: false, suspended: true })
      ),
      ZIO.map(QueryTask.toPageResult(data.pagination, null))
    )

  export const getHistoryTasksAccountingCashout = (data: HistoricTask.InputData): Task<PageResult<TaskAccountingOP>> =>
    pipe(
      POApi.post(`pulseops/history/historic-process-instances`)(QueryTask.Response)(
        QueryTask.PostDataAccounting({
          ...data,
          isTeam: false,
          active: false,
          isPayout: true,
          paymentMethod: 'CASH_AT_COUNTER',
          caseStatus: '1'
        })
      ),
      ZIO.map(QueryTask.toPageAccounting(data.pagination, null))
    )

  export const getCashOutComplete = (data: HistoricTask.InputData): Task<PageResult<TaskOp>> =>
    pipe(
      // getAuth({ params: { order: data.order || 'desc', size: data.pagination.pageSize, start: data.pagination.pageIndex } })(
      //   `pulseops/api/v1/history/historic-task-instances?finished=true&processFinished=true`,
      //   QueryTask.Response
      // ),
      // `pulseops/api/v1/history/historic-task-instances`
      POApi.post(`pulseops/history/historic-process-instances`)(QueryTask.Response)(
        QueryTask.PostDataCashOut({
          ...data,
          isTeam: false,
          active: false,
          isPayout: true,
          paymentMethod: 'CASH_AT_COUNTER'
        })
      ),
      ZIO.map(QueryTask.toPageResult(data.pagination, null))
    )

  export const getServiceInquiryNote = (taskId: string): Task<ServiceInquiryNote> =>
    pipe(
      POApi.get(`pulseops/history/historic-process-instances/${taskId}`)(
        t.type({
          payload: Maybe(
            t.type({
              body: Maybe(
                t.type({
                  attributesExt: Maybe(
                    t.type({
                      CUSTOMER_CMT: Maybe(t.string),
                      CLOSED_INFO: Maybe(t.string),
                      RES_TO_CUSTOMER: Maybe(t.string)
                    })
                  )
                })
              )
            })
          )
        })
      ),
      ZIO.map((data) => ({
        customerComment: data.payload?.body?.attributesExt?.CUSTOMER_CMT || '',
        closedInfo: data.payload?.body?.attributesExt?.CLOSED_INFO || '',
        responseToCustomer: data.payload?.body?.attributesExt?.RES_TO_CUSTOMER || ''
      }))
    )
  export const getHistoryTasksDS = (data: HistoricTask.InputData): Task<PageResult<TaskDSOp>> =>
    pipe(
      POApi.post(`pulseops/history/historic-process-instances`)(QueryTask.Response)(
        QueryTask.PostData({ ...data, isTeam: false, active: undefined })
      ),
      ZIO.map(QueryTask.toPageDSresult(data.pagination, null))
    )

  export const getErrorActivity = (data: ErrorActivity.InputData): Task<PageResult<ErrorActivity.TaskOp>> =>
    pipe(
      POApi.post(`pulseops/api/v1/query/error-activity`)(ErrorActivity.Response)(ErrorActivity.PostData({ ...data })),
      ZIO.map(ErrorActivity.toPageResult(data.pagination, null))
    )
  
  export const getHistoricProcessInstances = (
    data: HistoricProcessInstances.InputData
  ): Task<PageResult<ErrorActivity.TaskOp>> =>
    pipe(
      POApi.post(`pulseops/history/historic-process-instances`)(HistoricProcessInstances.Response)(
        HistoricProcessInstances.PostData({ ...data })
      ),
      ZIO.map(HistoricProcessInstances.toPageResult(data.pagination, null))
    )

  export const getPSSLAStandardList = () => pipe(
    POApi.post(`wf-api/table/t34s1/query`)(t.type({
      body: t.array(PSSLAStandard)
    }))({body: {}}),
    ZIO.map((res)=> res.body),
    ZIO.cached(Duration.minutes(5))
  )

  export const getCustomerServiceStandardSLAList = () => pipe(
    POApi.get(`wf-api/table/t34s2-customer-service/get-all`)(t.type({
      body: t.array(t.type({
        id: Maybe(t.string),
        group: t.string,
        groupCode: t.string,
        subServiceType: t.string,
        subServiceCode: t.string,
        subTransactionType: t.string,
        subTransactionTypeWF: t.string,
        subTransactionTypeVN: t.string,
        subTransactionCode: t.string,
        slaDays: t.string,
        slaHours: t.string
      }))
    })),
    ZIO.map((data)=> data.body),
    ZIO.cached(Duration.minutes(5))
  )

  export const getUserGroup = (taskId: string): Task<string> =>
    pipe(
      POApi.get(`pulseops/history/historic-process-instances/${taskId}`)(
        t.type({
          userGroupCode: Maybe(t.string)
        })
      ),
      ZIO.map((data) => data.userGroupCode ?? ''),
      ZIO.cached(Duration.minutes(5))
    )

  export const getHistoryTasksIDC = (data: HistoricTask.InputData): Task<PageResult<TaskIDCOp>> =>
    pipe(
      POApi.post(`pulseops/history/historic-process-instances`)(QueryTask.Response)(
        QueryTask.PostData({ ...data, isTeam: false, active: undefined, category: 'IDC' })
      ),
      ZIO.map(QueryTask.toPageIDCresult(data.pagination, null))
    )
  
}
