import { ZIO } from '@mxt/zio'
import {
  AppContext,
  BillingChange,
  BillingChangeService,
  formatNumberWithComma,
  Frequency,
  FrequencyMapping,
  Select,
  MajorCombined
} from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import React from 'react'
import { Controller, useForm, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SafeAreaView, StyleSheet, Text, View } from 'react-native'
import { MajorCombinedForm } from '../major-combined-form'

type Option = {
  value: Frequency
  label: string
}
interface Props {
  policyNum: string
  validateBeforeContinue: ({ validateBillingChange }: { validateBillingChange: () => Promise<boolean> }) => void
  isConfirmed: boolean
  form: UseFormReturn<MajorCombinedForm.MajorCombined, object>
}

export const BillingChangeComponent: React.FC<Props> = ({ isConfirmed, policyNum, validateBeforeContinue, form }) => {
  const mapFrequency = FrequencyMapping.mapFrequency
  const policyNumber = policyNum ?? ''
  const { t } = useTranslation()
  const { showGlobalLoading, showToast } = React.useContext(AppContext.AppContextInstance)

  const [isNextInstallValid, setIsNextInstallValid] = React.useState<boolean>(false)
  const [isError, setIsError] = React.useState<boolean>(false)

  let billingChangeWatch = form.watch('billingChange')

  const [detailData, setDetailData] = React.useState<BillingChange.Detail>({
    curBillingFrequency: '',
    curInstallmentPremium: '',
    newBillingFrequency: null,
    newInstallmentPremium: '',
    suspenseAmount: '',
    advancePremium: '',
    effectiveDateNewFrequency: '',
    requirePayinAmount: '',
    billToDate: '',
    paidToDate: '',
    curRenewalPremium: '',
    validNewInstallmentPremium: false,
    validProduct: false
  })
  React.useEffect(() => {
    loadingDetailData()
  }, [])

  const MapFrequencies = FrequencyMapping.FrequencyOption.filter(
    (freq) => freq.value !== detailData.curBillingFrequency
  )

  const loadingDetailData = () => {
    showGlobalLoading(true)
    pipe(
      BillingChangeService.getDetail(policyNumber),
      ZIO.map((responseData) => {
        if (!responseData.validNewInstallmentPremium) {
          showToast(t('message:MS050220'), 'error')
          setIsError(true)
        } else {
          setDetailData(responseData)
          const newFrequency = detailData?.newBillingFrequency
            ? FrequencyMapping.FrequencyOption.filter((item) => item.value === detailData.newBillingFrequency).map(
                (item: Option) => ({
                  label: t(`${item.label}`),
                  value: item.value
                })
              )[0]
            : null
          form.setValue('billingChange', {
            curBillingFrequency: responseData?.curBillingFrequency,
            curInstallmentPremium: responseData?.curInstallmentPremium,
            newBillingFrequency: newFrequency ?? undefined,
            newInstallmentPremium: responseData?.newInstallmentPremium || '',
            effectiveDateNewFrequency: responseData?.effectiveDateNewFrequency || '',
            requirePayinAmount: responseData.requirePayinAmount
          })
        }
        return responseData
      }),
      ZIO.tap((_) => {
        showGlobalLoading(false)
        return ZIO.unit
      }),
      ZIO.tapError((_) => {
        showGlobalLoading(false)
        return ZIO.unit
      }),
      ZIO.unsafeRun({})
    )
  }

  const loadingDetailWithNextInstall = (frequency: string) => {
    showGlobalLoading(true)
    pipe(
      BillingChangeService.getDetailWithNextInstall(policyNumber, frequency),
      ZIO.fold(
        (err) => {
          const source: any = err.source
          const code = source['response']?.data?.responseStatus?.errors[0]?.code
          const message = source['response']?.data?.responseStatus?.message

          if (code && message) {
            if (code === 'RL11') {
              showToast(t('message:MS050220'), 'error')
            } else if (code === 'F009') {
              const currFrequency = mapFrequency.get(frequency)
              const codeAvailable = MapFrequencies.reduce((acc, curr) => {
                return curr.value === frequency ? acc : acc.length ? acc + ' hoặc ' + curr.label : curr.label
              }, '')
              showToast(t('message:MS050215', { currFrequency, codeAvailable }), 'error')
            } else {
              showToast(message, 'error')
            }
            setIsError(true)
          }
          setIsNextInstallValid(false)
          const reqPayin = -(Number(detailData.suspenseAmount) + Number(detailData.advancePremium))
          if (!detailData.validNewInstallmentPremium) {
            showToast(t('message:MS050220'), 'error')
            setIsError(true)
          } else {
            setDetailData({
              ...detailData,
              newInstallmentPremium: '0',
              requirePayinAmount: reqPayin > 0 ? reqPayin.toString() : '0'
            })

            let newBillingChange = Object.assign(
              { ...billingChangeWatch },
              {
                curBillingFrequency: detailData.curBillingFrequency,
                curInstallmentPremium: detailData.curInstallmentPremium,
                newInstallmentPremium: '0',
                effectiveDateNewFrequency: detailData.effectiveDateNewFrequency,
                requirePayinAmount: reqPayin > 0 ? reqPayin.toString() : '0'
              }
            )
            form.setValue('billingChange', newBillingChange)
          }
        },
        (detail) => {
          if (!detail.validProduct && frequency === Frequency.Monthly) {
            setIsError(true)
            showToast(t('message:MS050217'), 'error')
          }
          // else if (detail.curInstallmentPremium === detail.advancePremium) {
          //   setIsError(true)
          //   showToast(t('message:MS050218'), 'error')
          // }
          else if (detail.newInstallmentPremium === '0') {
            setIsError(true)
            showToast(t('message:MS050219'), 'error')
          } else {
            setIsError(false)
            setIsNextInstallValid(true)
            if (!detail.validNewInstallmentPremium) {
              showToast(t('message:MS050220'), 'error')
              setIsError(true)
            } else {
              setDetailData(detail)

              let newBillingChange = Object.assign(
                { ...billingChangeWatch },
                {
                  curBillingFrequency: detail?.curBillingFrequency,
                  curInstallmentPremium: detail?.curInstallmentPremium,
                  newInstallmentPremium: detail?.newInstallmentPremium || '',
                  effectiveDateNewFrequency: detail?.effectiveDateNewFrequency || '',
                  requirePayinAmount: detail.requirePayinAmount
                }
              )

              form.setValue('billingChange', newBillingChange)
            }
          }
          return detail
        }
      ),
      ZIO.tap((_) => {
        showGlobalLoading(false)
        return ZIO.unit
      }),
      ZIO.tapError((_) => {
        showGlobalLoading(false)
        return ZIO.unit
      }),
      ZIO.unsafeRun({})
    )
  }

  const onChangeFrequency = (frequency: Frequency) => {
    loadingDetailWithNextInstall(frequency)
  }

  validateBeforeContinue({
    validateBillingChange: async () => {
      loadingDetailWithNextInstall(form.watch('billingChange.newBillingFrequency')?.value || '')
      return !!isNextInstallValid && !!!isError && !!detailData
    }
  })

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View style={styles.sectionContent}>
        <View style={styles.sectionRow}>
          <View style={styles.col_4}>
            <Text style={styles.field_title}>{t('requestInfo:CurrentBillingFrequency')}</Text>
            <Text style={styles.field_description}>
              {detailData?.curBillingFrequency
                ? t(mapFrequency.get(detailData.curBillingFrequency as Frequency) ?? '') ?? ''
                : ''}
            </Text>
          </View>
          <View style={styles.col_4}>
            <Text style={styles.field_title}>{t('requestInfo:CurentInstallmentPremium')}</Text>
            <Text style={styles.field_description}>
              {detailData.curInstallmentPremium ? formatNumberWithComma(detailData.curInstallmentPremium) : '0'} VND
            </Text>
          </View>
          <View style={styles.col_4}>
            <Text style={styles.field_title}>{t('requestInfo:EffectiveDateOfNewFrequency')}</Text>
            <Text style={styles.field_description}>{detailData?.effectiveDateNewFrequency || ''}</Text>
          </View>
        </View>

        <View style={[styles.sectionRow, styles.secondLine]}>
          <View style={styles.col_4}>
            <Controller
              name="billingChange.newBillingFrequency"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: `${t('message:MS020009', { field: t('requestInfo:NewBillingFrequency') })}`
                }
              }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <Select
                  required
                  onChange={(val) => {
                    const selectValue = val?.value as Frequency
                    onChange(val)
                    onChangeFrequency(selectValue)
                  }}
                  onBlur={onBlur}
                  value={value}
                  label={t('requestInfo:NewBillingFrequency')}
                  disabled={isConfirmed}
                  showUnderline={!isConfirmed}
                  errorMessage={error?.message}
                  options={MapFrequencies.map((item) => ({
                    label: t(`${item.label}`),
                    value: item.value
                  }))}
                ></Select>
              )}
            />
          </View>
          <View style={styles.col_4}>
            <Text style={styles.field_title}>{t('requestInfo:NewInstallmentPremium')}</Text>
            <Text style={styles.field_description}>
              {detailData.newInstallmentPremium ? formatNumberWithComma(detailData.newInstallmentPremium) : '0'} VND
            </Text>
          </View>
          <View style={styles.col_4}>
            <Text style={styles.field_title}>{t('requestInfo:RequirePayinAmount')}</Text>
            <Text style={[styles.field_description, styles.red_line]}>
              {detailData.requirePayinAmount && Number(detailData.requirePayinAmount) > 0
                ? formatNumberWithComma(detailData.requirePayinAmount)
                : '0'}{' '}
              VND
            </Text>
          </View>
        </View>
      </View>
    </SafeAreaView>
  )
}
const styles = StyleSheet.create({
  headerField: {
    fontSize: 15,
    fontWeight: '700'
  },
  sectionContent: {
    padding: 20,
    backgroundColor: '#fafafa',
    border: '1px solid #d3dce6',
    boxSizing: 'border-box',
    borderRadius: 8
  },
  sectionRow: {
    flex: 1,
    flexDirection: 'row',
    marginRight: -15,
    marginLeft: -15
  },
  secondLine: {
    marginTop: 20
  },
  col_4: {
    width: '100%',
    maxWidth: '33.3333333333%',
    paddingRight: 15,
    paddingLeft: 15
  },
  field_title: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  },
  field_description: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 15,
    lineHeight: 22,
    color: '#000000'
  },
  red_line: {
    fontWeight: 'bold',
    color: '#ed1c2e'
  }
})
