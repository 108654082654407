// import { AppBar, Box, createTheme, makeStyles, Paper, Tab, Tabs, withStyles } from '@material-ui/core'
import {
  AppContext,
  AuthService,
  CanView,
  ErrorHandling,
  GeneralService,
  OBMyTaskPermissions,
  Permission,
  PulseOpsApi,
  PulseOpsFormat,
  PulseOpsService,
  SubTaskLabel,
  SubTaskType,
  TaskCount,
  TaskService,
  TaskType,
  TransactionStatus,
  assets
} from '@pulseops/common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { TouchableOpacity, StyleSheet, View, Text } from 'react-native'
import {
  BasketCount,
  CategoryTabInfo,
  OBBasketTab,
  OBFilterSearchInput,
  OBTaskFilterForm,
  OBTaskFilterModal,
  OBTaskGroupTab,
  OBTaskList,
  OBTaskTable,
  TaskConst,
  clearRefreshTaskManager,
  isRefreshAllBasket
} from './ob-common'
import {
  OBBasketType,
  OBTaskQueryData,
  OBTaskQueryInputParam,
  OBTaskService,
  OBUserGroupData,
  OBUserInfo
} from '../ob-service'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import * as O from 'fp-ts/lib/Option'
import { TaskManagementContext } from './OBTaskManagementScreen'
import { OBEndPopup, OBGetCasesPopup, OBTaskTransferData, OBTaskTransferPopup } from '../ob-common'
import { useIsFocused } from '@react-navigation/native'

type OBMyTaskProps = {
  isActive: boolean
  parentTabIndex: number
}

export const OBMyTask = (props: OBMyTaskProps) => {
  const { t, i18n } = useTranslation('TaskManagement')
  const [isLoading, bindLoader] = useLoading(false)
  const [subTabIndex, setSubTabIndex] = React.useState<number>(0)
  const [isGridView, setIsGridView] = React.useState(false)
  const [filter, setFilter] = React.useState<OBTaskFilterForm.FilterForm>()
  const [searchedInput, setSearchedInput] = React.useState<string>('')
  const [isFilterModalVisible, setFilterModalVisible] = React.useState<boolean>(false)
  const [taskList, setTaskList] = React.useState<Array<OBTaskQueryData>>([])
  const [userInfo, setUserInfo] = React.useState<OBUserInfo>()
  const [selectedTaskList, setSelectedTaskList] = React.useState<Map<string, OBTaskQueryData>>(new Map())
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [pageNum, setPageNum] = React.useState<number>(0)
  const [basketTabList, setBasketTabList] = React.useState<Array<CategoryTabInfo>>([])
  const [totalRecord, setTotalRecord] = React.useState(0)
  const [selectedBasket, setSelectedBasket] = React.useState<BasketCount>()
  const [taskGroupSelected, setTaskGroupSelected] = React.useState<TaskCount>()
  const [isShowTransferPopup, setIsShowTransferPopup] = React.useState(false)
  const [userGroupArr, setUserGroupArr] = React.useState<OBUserGroupData[]>([])
  const [OBTaskCount, setOBTaskCount] = React.useState<TaskCount[]>([])
  const [isSearchText, setIsSearchTex] = React.useState(false)
  const { showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const isFocused = useIsFocused()
  const { permissions } = React.useContext(TaskManagementContext)
  const [isShowEndPopup, setIsShowEndPopup] = React.useState(false)
  const [isResetField, setIsResetField] = React.useState(false)
  const [isShowGetCasePopup, setIsShowGetCasePopup] = React.useState(false)

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  React.useEffect(() => {
    if (props.parentTabIndex === 0 && checkPermissionMyTask()) {
      getTaskCount()
      setIsResetField(true)
    }
  }, [props.parentTabIndex, permissions, i18n.language])

  React.useEffect(() => {
    if (isRefreshAllBasket() && props.isActive) {
      getTaskCount()
      setIsResetField(true)
      clearRefreshTaskManager()
    }
  }, [isFocused])

  const getTaskCount = () => {
    pipe(
      ZIO.zipPar(
        AuthService.userInfo,
        OBTaskService.countOBMyTasks,
      ),
      ZIO.tap(([userInfo, taskCounts]) => {
        const taskCountList = taskCounts.filter((x) => x.type === TaskType.Outbound)
        const defaultSelectedGroup = taskCountList.find((x) => x.type === TaskType.Outbound)
        setUserInfo(userInfo)
        setOBTaskCount(taskCountList)
        setTaskGroupSelected(taskCountList.find((x) => x.type === TaskType.Outbound))
        setBasketTabList(checkPermissionBasketListShow(defaultSelectedGroup, permissions))
        return ZIO.unit
      }),
      ZIO.catchAll((_error) => {
        return ZIO.fail(_error)
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }

  React.useEffect(() => {
    setSubTabIndex(0)
  }, [taskGroupSelected])

  React.useEffect(() => {
    if (!!selectedBasket) {
      setPageSize(10)
      setPageNum(0)
      getTaskList(0, 10)
    }
  }, [selectedBasket, filter])

  const checkPermissionMyTask = () => {
    if (
      permissions.includes(OBMyTaskPermissions.ViewVerificationOBMyTask) ||
      permissions.includes(OBMyTaskPermissions.ViewQCOBMyTask) ||
      permissions.includes(OBMyTaskPermissions.ViewQAOBMyTask)
    ) {
      return true
    }
    return false
  }

  const getTaskList = (pageNo: number, pageSi: number) => {
    const isDuplicatePo = pipe(
      filter?.duplicatePO,
      O.fromNullable,
      O.fold(
        () => undefined,
        (duplicatedData) => {
          const isSamePO =
            [SubTaskType.QC, SubTaskType.REQC].includes(selectedBasket?.basketType as SubTaskType)  || duplicatedData.value === 'all'
              ? undefined
              : duplicatedData.value === 'true'
              ? true
              : false
          return isSamePO
        }
      )
    )
    const taskCategory = `${taskGroupSelected?.type}-${selectedBasket?.basketType ?? ''}`
    const vipRank = filter?.vipRank && !!filter?.vipRank.value ? [filter?.vipRank?.value || ''] : undefined
    const transactionTypeIn =
      filter?.callingProgram && !!filter?.callingProgram?.value ? [filter?.callingProgram?.value] : undefined
    const leader = !!filter?.leader && filter?.leader.value ? filter?.leader.value : undefined
    const lastQCAssignee =
      selectedBasket?.basketType === SubTaskType.QC && !!filter?.lastAssignee && !!filter?.lastAssignee.value
        ? filter?.lastAssignee.value
        : undefined
    // const lastVerUpdateDateBefore = selectedBasket?.basketType === SubTaskType.QC ? filter?.submitToDate?.toISOString() : undefined
    // const lastVerUpdateDateAfter = selectedBasket?.basketType === SubTaskType.QC ? filter?.submitFromDate?.toISOString() : undefined
    const lastVerUpdateDateBefore =
      [SubTaskType.QC, SubTaskType.REQC].includes(selectedBasket?.basketType as SubTaskType) && filter?.submitToDate
        ? PulseOpsFormat.serviceInquiry_endOfDate(filter?.submitToDate, true).toISOString()
        : undefined
    const lastVerUpdateDateAfter =
      [SubTaskType.QC, SubTaskType.REQC].includes(selectedBasket?.basketType as SubTaskType) && filter?.submitFromDate
        ? PulseOpsFormat.serviceInquiry_StartOfDate(filter?.submitFromDate, true).toISOString()
        : undefined
    const totalAgingDays =
      [SubTaskType.Verification].includes(selectedBasket?.basketType as SubTaskType) && !!filter?.totalAgingDays
        ? Number(filter?.totalAgingDays)
        : undefined
    let aging = ![SubTaskType.QC, SubTaskType.REQC].includes(selectedBasket?.basketType as SubTaskType) && filter?.aging ? Number(filter?.aging) : undefined
    aging =
      [SubTaskType.Suspend].includes(selectedBasket?.basketType as SubTaskType) && filter?.totalAgingDays
        ? Number(filter?.totalAgingDays)
        : aging
    const policyFirstIssuedDateBefore = !!filter?.firstIssueToDate
      ? PulseOpsFormat.serviceInquiry_endOfDate(filter?.firstIssueToDate, true).toISOString()
      : undefined
    const policyFirstIssuedDateAfter = !!filter?.firstIssueFromDate
      ? PulseOpsFormat.serviceInquiry_StartOfDate(filter?.firstIssueFromDate, true).toISOString()
      : undefined
    const policyLastIssuedDateBefore = !!filter?.lastIssueToDate
      ? PulseOpsFormat.serviceInquiry_endOfDate(filter?.lastIssueToDate, true).toISOString()
      : undefined
    const policyLastIssuedDateAfter = !!filter?.lastIssueFromDate
      ? PulseOpsFormat.serviceInquiry_StartOfDate(filter?.lastIssueFromDate, true).toISOString()
      : undefined
    const lastVerAssignee =
      selectedBasket?.basketType === SubTaskType.Suspend && !!filter?.lastAssignee && !!filter?.lastAssignee.value
        ? filter?.lastAssignee.value
        : undefined
    const listPolicyNumber = filter?.policyNumber && filter?.policyNumber.split(';')
    const data: OBTaskQueryInputParam = {
      start: pageNo,
      size: pageSi,
      vipIn: vipRank,
      sourceIn: undefined,
      transactionTypeIn: transactionTypeIn,
      businessKey: filter?.caseId || '',
      category: taskGroupSelected?.type as string, // OB,IB
      taskCategory: taskCategory, //chinh la field activityBasket
      assignee: userInfo?.email || '', //currentAssignee
      status: filter?.caseStatus?.value || '',
      aging: aging,
      totalAgingDays: totalAgingDays,
      clientNumber: filter?.clientNumber || '',
      policyOwnerName: filter?.clientName || '',
      policyNumberIn: listPolicyNumber ? [...listPolicyNumber] : [],
      saleChannel: filter?.agency?.value || '',
      appointedBefore: !!filter?.appointmentToDate
        ? PulseOpsFormat.serviceInquiry_endOfDate(filter?.appointmentToDate, true).toISOString()
        : undefined,
      appointedAfter: !!filter?.appointmentFromDate
        ? PulseOpsFormat.serviceInquiry_StartOfDate(filter?.appointmentFromDate, true).toISOString()
        : undefined,
      executionTime: filter?.numberOfAssignment,
      isDuplicatePo: isDuplicatePo,
      createdBefore: !!filter?.createdToDate
        ? PulseOpsFormat.serviceInquiry_endOfDate(filter?.createdToDate, true).toISOString()
        : undefined,
      createdAfter: !!filter?.createdFromDate
        ? PulseOpsFormat.serviceInquiry_StartOfDate(filter?.createdFromDate, true).toISOString()
        : undefined,
      leader: leader,
      suspended: undefined,
      verificationAssignee: lastQCAssignee,
      lastVerUpdateDateBefore: lastVerUpdateDateBefore,
      lastVerUpdateDateAfter: lastVerUpdateDateAfter,
      policyFirstIssuedDateBefore: policyFirstIssuedDateBefore,
      policyFirstIssuedDateAfter: policyFirstIssuedDateAfter,
      policyLastIssuedDateBefore: policyLastIssuedDateBefore,
      policyLastIssuedDateAfter: policyLastIssuedDateAfter,
      lastVerAssignee: lastVerAssignee
    }
    pipe(
      OBTaskService.getMyTaskList(data),
      ZIO.map((responseData) => {
        const list =
          responseData && !!responseData.data && responseData.data.length > 0
            ? responseData.data.map((data) => {
                const vipRank = pipe(
                  TaskConst.VIPList.find((x) => x.code === data.vip),
                  O.fromNullable,
                  O.map((vipItem) => (i18n.language === 'en' ? vipItem.descriptionEN : vipItem.descriptionVN)),
                  O.getOrElse(() => '')
                )
                return {
                  ...data,
                  poName: data.poName ?? '',
                  vip: vipRank,
                  agency: data.saleChannel ?? '',
                  // isDuplicatePo: !data.isDuplicatePo ? false : true,
                  appointmentDate: data.appointmentDate ?? '',
                  executionTime: data.executionTime ?? 0,
                  assignee: data.assignee ?? '',
                  leader: data.leader ?? '',
                  status: TransactionStatus.IN_PROGRESS
                }
              })
            : []
        if (isSearchText && list.length <= 0) {
          showToast(t('message:OB0032'), 'error')
        } else {
          setIsSearchTex(false)
        }
        setTaskList(list)
        setTotalRecord(responseData.total)
        setSelectedTaskList(new Map())
        return responseData
      }),
      ZIO.mapError((err) => {
        setTaskList([])
        setTotalRecord(0)
        setSelectedTaskList(new Map())
        return err
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }

  const onHandleListTypeEvent = () => {
    setIsGridView(!isGridView)
    setSelectedTaskList(new Map())
  }

  const onSubmitTaskFilterForm = (data: OBTaskFilterForm.FilterForm) => {
    setFilter(data)
    setFilterModalVisible(false)
  }

  const onChangeBasketTab = (index: number) => {
    const selectedItem = basketTabList.find((_, idx) => idx === index)
    setTaskList([])
    setSelectedTaskList(new Map())
    setSelectedBasket({ basketType: selectedItem?.value ?? '' })
    setSubTabIndex(index)
  }

  const checkBasketPermission = (subTask: SubTaskType, permissions: string[]) => {
    const basketType = subTask as unknown as OBBasketType
    switch (basketType) {
      case OBBasketType.Verification:
        return permissions.includes(OBMyTaskPermissions.ViewVerificationOBMyTask)
      case OBBasketType.REQC:
        return permissions.includes(OBMyTaskPermissions.ViewReQCOBMyTask)
      case OBBasketType.QC:
        return permissions.includes(OBMyTaskPermissions.ViewQCOBMyTask)
      case OBBasketType.QA:
        return permissions.includes(OBMyTaskPermissions.ViewQAOBMyTask)
      default:
        return false
    }
  }

  const checkPermissionBasketListShow = (countTaskData: TaskCount | undefined, permissions: string[]) => {
    let basketList: Array<CategoryTabInfo> = []
    countTaskData?.subTasks.map((item, index) => {
      if (checkBasketPermission(item.code.subTask, permissions)) {
        // const subTaskCode = `${item.code.task}-${item.code.subTask}`
        basketList.push({
          label: SubTaskLabel(item.code)(t),
          key: `${index + 1}`,
          value: item.code.subTask,
          count: item.value
        })
      }
    })
    basketList.length > 0 && setSelectedBasket({ basketType: basketList[0].value ?? '' })
    setSubTabIndex(0)
    return basketList
  }

  const onSelectedTaskEvent = (task: OBTaskQueryData) => {
    selectedTaskList.set(task.id ?? '', task)
    setSelectedTaskList(new Map(selectedTaskList))
  }

  const onUnSelectedTaskEvent = (task: OBTaskQueryData) => {
    selectedTaskList.delete(task.id ?? '')
    setSelectedTaskList(new Map(selectedTaskList))
  }

  const isMultipleSelectedTask = () => {
    return selectedTaskList.size >= 2
  }

  const isOpenedTransferButton = () => {
    let transactionTypeList: string[] = []
    selectedTaskList.forEach((item) => {
      if (!transactionTypeList.some((x) => x === item.transactionType)) {
        transactionTypeList.push(item.transactionType || '')
      }
    })
    return transactionTypeList.length <= 1 ? true : false
  }

  const isSelectedTask = () => {
    return selectedTaskList.size >= 1
  }

  const onChangeSearchedTextEvent = (text: string) => {
    setSearchedInput(text)
    !text && onFilterReInput('')
  }

  const onSearchTextEvent = () => {
    if (!searchedInput) {
      showToast(t('message:MS020001', { field: t('common:ClientNumber') }), 'error')
    } else {
      setIsSearchTex(true)
      onFilterReInput(searchedInput)
    }
  }

  const onFilterReInput = (clientNumber: string) => {
    const filterData: OBTaskFilterForm.FilterForm = {
      policyNumber: filter?.policyNumber ?? '',
      caseId: filter?.caseId ?? '',
      callingProgram: filter?.callingProgram,
      clientNumber: clientNumber,
      clientName: filter?.clientName ?? '',
      vipRank: filter?.vipRank,
      agency: filter?.agency,
      aging: filter?.aging,
      totalAgingDays: filter?.totalAgingDays,
      numberOfAssignment: filter?.numberOfAssignment,
      currentAssignee: filter?.currentAssignee,
      leader: filter?.leader,
      caseStatus: filter?.caseStatus,
      duplicatePO: filter?.duplicatePO,
      createdFromDate: filter?.createdFromDate,
      createdToDate: filter?.createdToDate,
      appointmentFromDate: filter?.appointmentFromDate,
      appointmentToDate: filter?.appointmentToDate,
      lastAssignee: filter?.lastAssignee,
      submitFromDate: filter?.submitFromDate,
      submitToDate: filter?.submitToDate,
      firstIssueFromDate: filter?.firstIssueFromDate,
      firstIssueToDate: filter?.firstIssueToDate,
      lastIssueFromDate: filter?.lastIssueFromDate,
      lastIssueToDate: filter?.lastIssueToDate
    }
    setFilter(filterData)
  }

  const onOpenTransferPopupEvent = () => {
    setUserGroupArr([])
    const activityBasket = `${taskGroupSelected?.type}-${selectedBasket?.basketType ?? ''}`
    const taskTransferInfo = {
      categoryCode: String(taskGroupSelected?.type),
      activityBasket: activityBasket,
      transactionType:
        selectedTaskList.size > 0
          ? selectedTaskList.get(selectedTaskList.keys().next().value)?.transactionType ?? ''
          : ''
    }
    pipe(
      [SubTaskType.QC, SubTaskType.REQC].includes(selectedBasket?.basketType as SubTaskType)
        ? pipe(
            OBTaskService.getUserQCGroupLevelByGroupType(
              taskTransferInfo.categoryCode,
              taskTransferInfo.transactionType,
              activityBasket
            ),
            ZIO.map((groupArr) => {
              let userGroupList: OBUserGroupData[] = []
              groupArr.forEach((groupItem) => {
                //filter duplication groups
                if (
                  !!groupItem &&
                  !userGroupList.some((group) => group.userGroupCodeToTransfer === groupItem.userQCGroupLevelToTransfer)
                ) {
                  let data = {
                    userGroupCodeToTransfer: groupItem.userQCGroupLevelToTransfer,
                    activityBasketToTransfer: `${taskGroupSelected?.type}-${selectedBasket?.basketType ?? ''}`
                  }
                  userGroupList.push(data)
                }
              })
              return userGroupList
            })
          )
        : pipe(
            OBTaskService.getUserGroupList(
              taskTransferInfo.categoryCode,
              taskTransferInfo.activityBasket ?? '',
              taskTransferInfo.transactionType
            ),
            ZIO.map((responsedGroups) => {
              return responsedGroups
            })
          ),
      ZIO.map((groupList) => {
        setUserGroupArr(groupList)
        setIsShowTransferPopup(true)
        return groupList
      }),
      bindLoader,
      ErrorHandling.run()
    )
  }

  const onSuccessEndCase = () => {
    setIsShowEndPopup(false)
    getTaskCount()
    getTaskList(pageNum, pageSize)
    setSelectedTaskList(new Map())
  }

  const onHandleTransferEvent = (transferData: OBTaskTransferData) => {
    let groupList = transferData && transferData.group ? transferData.group.split('-') : []
    let assigneeGroup =
      selectedBasket?.basketType === SubTaskType.QC
        ? groupList && groupList.length > 2
          ? groupList[0].concat('-', groupList[1])
          : ''
        : transferData.group
    pipe(
      selectedTaskList.size === 1
        ? pipe(
            AuthService.userInfo,
            ZIO.flatMap(({ email }) => {
              return OBTaskService.transferOBTask(
                [...selectedTaskList.keys()][0],
                transferData.user,
                assigneeGroup,
                { author: email, message: transferData.comment },
                false,
                String(taskGroupSelected?.type)
              )
            })
          )
        : OBTaskService.transferList({
            assignee: transferData.user,
            assigneeGroup: assigneeGroup,
            taskList: [...selectedTaskList.keys()],
            category: String(taskGroupSelected?.type)
          }),
      ZIO.catchAll((_error) => {
        setIsShowTransferPopup(false)
        showToast(t('TransferFailed'), 'error')
        return ZIO.fail(_error)
      }),
      ZIO.tap((responseData: any) => {
        if (responseData && responseData.code && responseData.code === 'OB0050') {
          setIsShowTransferPopup(false)
          showToast(t('message:OB0050'), 'error')
        } else {
          setIsShowTransferPopup(false)
          getTaskList(pageNum, pageSize)
          setSelectedTaskList(new Map())
          getTaskCount()
          showToast(t('TransferSuccess'), 'success')
        }
        return ZIO.unit
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }
  const onCloseTransferPopup = () => {
    setIsShowTransferPopup(false)
  }

  return props.isActive ? (
    <View style={taskStyles.container}>
      <OBTaskGroupTab
        dataSource={OBTaskCount}
        onChangeTab={setTaskGroupSelected}
        activeTabValue={taskGroupSelected}
      ></OBTaskGroupTab>

      <View style={{ marginTop: 20 }}>
        {/* task management header */}
        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          {/* Actions area */}
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <TouchableOpacity onPress={() => onHandleListTypeEvent()}>
              <View
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  minHeight: 30
                }}
              >
                {isGridView ? <assets.TableViewIcon /> : <assets.GridViewIcon />}
                <Text style={{ marginHorizontal: 10, color: '#ED1B2E', fontWeight: '700', fontSize: 16 }}>
                  {!isGridView ? t('TaskManagement:GridView') : t('TaskManagement:ListView')}
                </Text>
              </View>
            </TouchableOpacity>
            <View style={{ borderLeftWidth: 2, borderLeftColor: '#D3DCE6', width: 2, marginLeft: 24 }}></View>

            {selectedBasket?.basketType === OBBasketType.Verification &&
              permissions.includes(OBMyTaskPermissions.GetcaseOBTaskList) ? (
              <TouchableOpacity onPress={() => setIsShowGetCasePopup(true)}>
                <View style={[taskStyles.actionContent, taskStyles.actionLine]}>
                  <Text style={taskStyles.actionText}>{t('TaskManagement:GetCases')}</Text>
                </View>
              </TouchableOpacity>
            ) : null}

            {isSelectedTask() && isOpenedTransferButton() && permissions.includes(Permission['TransferOBTaskList']) && (
              <>
                <TouchableOpacity onPress={() => onOpenTransferPopupEvent()}>
                  <View style={[taskStyles.actionContent, taskStyles.actionLine]}>
                    <Text style={taskStyles.actionText}>{t('Transfer')}</Text>
                  </View>
                </TouchableOpacity>
              </>
            )}
            {isSelectedTask() &&
              !isMultipleSelectedTask() &&
              selectedBasket?.basketType !== 'SUSPENDED' &&
              permissions.includes(Permission['EndOBTaskList']) && (
                <TouchableOpacity onPress={() => selectedTaskList.size > 0 && setIsShowEndPopup(true)}>
                  <View style={[taskStyles.actionContent, taskStyles.actionLine, { marginRight: 20 }]}>
                    <Text style={taskStyles.actionText}>{t('requestInfo:End')}</Text>
                  </View>
                </TouchableOpacity>
              )}
          </View>
          <OBFilterSearchInput
            inputValue={searchedInput}
            onChangeInput={onChangeSearchedTextEvent}
            isFilterApplied={!!filter}
            onPressFilter={() => setFilterModalVisible(true)}
            onSearchTextEvent={onSearchTextEvent}
          />
        </View>
        {/* backet container */}
        <View style={taskStyles.basketContainer}>
          {/* basket tabs */}
          <OBBasketTab
            dataSource={basketTabList}
            onChangeTab={onChangeBasketTab}
            activeTabIndex={subTabIndex}
            tabValue={selectedBasket?.basketType ?? ''}
          ></OBBasketTab>
          {/* task table,task list */}
          {isGridView ? (
            <CanView condition={!!basketTabList && basketTabList.length > 0}>
              <OBTaskList
                dataSource={taskList}
                pageSize={pageSize}
                onSeeMoreCaseEvent={getTaskList}
                selectedTaskList={selectedTaskList}
                onSelectedTaskEvent={onSelectedTaskEvent}
                onUnSelectedTaskEvent={onUnSelectedTaskEvent}
                selectedBasket={selectedBasket}
              ></OBTaskList>
            </CanView>
          ) : (
            <CanView condition={!!basketTabList && basketTabList.length > 0}>
              <OBTaskTable
                dataSource={taskList}
                activeTabindex={subTabIndex}
                onPageChange={getTaskList}
                onRowsPerPageChange={getTaskList}
                totalRecord={totalRecord}
                selectedTaskList={selectedTaskList}
                onSelectedTaskEvent={onSelectedTaskEvent}
                onUnSelectedTaskEvent={onUnSelectedTaskEvent}
                selectedBasket={selectedBasket}
                pageSize={pageSize}
                setPageSize={setPageSize}
                pageNum={pageNum}
                setPageNum={setPageNum}
                setTotalRecord={setTotalRecord}
              ></OBTaskTable>
            </CanView>
          )}
        </View>
      </View>
      <OBTaskFilterModal
        visible={isFilterModalVisible}
        groupName={''}
        categroryName={''}
        onPressSubmit={onSubmitTaskFilterForm}
        onPressClear={(filterData) => {
          setFilter(filterData)
          setIsResetField(false)
        }}
        onPressClose={() => setFilterModalVisible(false)}
        basket={selectedBasket?.basketType ?? 'VER'}
        isResetField={isResetField}
      />
      <OBTaskTransferPopup
        title={t('TaskManagement:Transfer')}
        visible={isShowTransferPopup}
        userGroupArr={userGroupArr}
        basket={selectedBasket?.basketType || ''}
        selectedTaskList={selectedTaskList}
        onPopupCloseEvent={onCloseTransferPopup}
        onHandleTransferEvent={onHandleTransferEvent}
      ></OBTaskTransferPopup>
      <OBEndPopup
        open={isShowEndPopup}
        onClose={() => setIsShowEndPopup(false)}
        onSuccess={() => onSuccessEndCase()}
        taskId={(selectedTaskList.size > 0 && selectedTaskList.get(selectedTaskList.keys().next().value)?.id) || ''}
      />
      {isShowGetCasePopup ? (
        <OBGetCasesPopup
          visible={isShowGetCasePopup}
          onConfirm={(res) => {
            setIsShowGetCasePopup(false)
            getTaskCount()
            getTaskList(pageNum, pageSize)
            setSelectedTaskList(new Map())
          }}
          onCancel={() => setIsShowGetCasePopup(false)}
        />
      ) : null}
    </View>
  ) : (
    <></>
  )
}

type TabPanelProps = {
  value: number
  tabIndex: number
}

export const TabPanel: React.FC<TabPanelProps> = ({ children, ...props }) => {
  const { value, tabIndex } = props
  return value === tabIndex ? <View key={'TabPanel_' + tabIndex}>{children}</View> : <></>
}

const taskStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    paddingHorizontal: 20,
    paddingVertical: 16
  },
  categoryContainer: {},
  activeText: {
    color: '#ED1B2E'
  },
  actionContent: {
    minHeight: 30,
    paddingHorizontal: 26,
    paddingVertical: 8,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
    backgroundColor: 'rgba(237, 27, 46, 0.15)'
  },
  actionLine: {
    marginLeft: 24
  },
  actionText: {
    color: '#ED1B2E',
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: '600'
  },

  basketContainer: {
    display: 'flex',
    marginTop: 24
  }
})
