import { Task, Throwable, ZIO } from '@mxt/zio'
import { pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'
import { POApi, TransactionTypeC } from '@pulseops/common'
import { Maybe, Nullable } from '@mxt/zio/codec'
import { DateFromISOString } from 'io-ts-types'
export namespace CepPolicyDetailServices {
  export const ClientName = t.type({
    surName: t.string,
    middleName: t.string,
    firstName: t.string,
    name: t.string
  })

  export const AgentInfoC = t.type({
    data: t.type({
      policyAgent: Maybe(
        t.type({
          policyNumber: Maybe(t.string),
          servicingAgentCode: Maybe(t.string),
          servicingAgentName: Maybe(ClientName),
          servicingAgentMobilePhone: Maybe(t.string),
          sharingAgentCode: Maybe(t.string),
          sharingAgentName: Maybe(ClientName),
          sharingAgentMobilePhone: Maybe(t.string),
          office: Maybe(t.string),
          gaCode: Maybe(t.string),
          gaName: Maybe(t.string),
          BDM: Maybe(t.string),
          BDD: Maybe(t.string),
          unitManager: Maybe(t.string),
          unitManagerName: Maybe(t.string),
          unitManagerMobilePhone: Maybe(t.string),
          branchManager: Maybe(t.string),
          branchManagerName: Maybe(t.string),
          branchManagerMobilePhone: Maybe(t.string),
          officeName: Maybe(t.string),
          gad: Maybe(t.string)
        })
      ),
      servicingHistory: Nullable(
        t.array(
          Maybe(
            t.type({
              agentCode: Maybe(t.string),
              agentName: Maybe(ClientName),
              fromDate: Maybe(t.string),
              toDate: Maybe(t.string)
            })
          )
        )
      )
    })
  })

  export type AgentInfoC = t.TypeOf<typeof AgentInfoC>

  export const AgentServicesHistory = t.type({
    traceId: Maybe(t.string),
    system: Maybe(t.string),
    timestamp: Maybe(t.string),
    total: t.number,
    start: t.number,
    size: t.number,
    data: t.array(
      Maybe(
        t.type({
          agentCode: Maybe(t.string),
          agentName: Maybe(ClientName),
          fromDate: Maybe(t.number),
          toDate: Maybe(t.number)
        })
      )
    ),
    error: Maybe(
      t.type({
        code: Maybe(t.number),
        message: Maybe(t.string),
        errors: Maybe(
          t.array(
            t.type({
              domain: Maybe(t.string),
              reason: Maybe(t.string),
              message: Maybe(t.string)
            })
          )
        )
      })
    )
  })

  export type AgentServicesHistory = t.TypeOf<typeof AgentServicesHistory>

  export const ProductPolicyRes = t.type({
    data: Maybe(
      t.type({
        lifeAssureds: Maybe(
          t.array(
            t.type({
              lifeAssuredCode: Maybe(t.string),
              lifeAssuredName: Maybe(ClientName),
              life: t.string,
              products: Maybe(
                t.array(
                  t.type({
                    componentName: Maybe(t.string),
                    productCode: Maybe(t.string),
                    coverageStatus: Maybe(t.string),
                    premiumStatus: Maybe(t.string),
                    rerateDate: Maybe(t.string),
                    sumAssured: Maybe(t.number),
                    installmentPremium: Maybe(t.number),
                    paidToDate: Maybe(t.string),
                    premCessDate: Maybe(t.string),
                    covRiskCessDate: Maybe(t.string),
                    riskCessDate: Maybe(t.string),
                    rider: Maybe(t.string),
                    coverage: Maybe(t.string),
                    benefitOption: Maybe(t.string),
                    planCode: Maybe(t.string)
                  })
                )
              )
            })
          )
        ),
        policyNumber: Maybe(t.string)
      })
    )
  })

  export type ProductPolicyRes = t.TypeOf<typeof ProductPolicyRes>

  export const Error = Maybe(
    t.type({
      code: Maybe(t.number),
      message: Maybe(t.string),
      errors: Maybe(
        t.array(
          t.type({
            domain: Maybe(t.string),
            reason: Maybe(t.string),
            message: Maybe(t.string),
            errorCode: Maybe(t.string)
          })
        )
      )
    })
  )

  export const CustomerInfoC = t.type({
    data: Maybe(
      t.type({
        contact: Maybe(
          t.type({
            companyPhone: Maybe(t.string),
            vip: Maybe(t.string),
            country: Maybe(t.string),
            city: Maybe(t.string),
            district: Maybe(t.string),
            ward: Maybe(t.string),
            address: Maybe(t.string),
            ape: Maybe(t.string),
            longName: Maybe(t.string),
            point: Maybe(t.number),
            expiredPoint: Maybe(t.number),
            usedPoint: Maybe(t.number),
            pruOnlineUserName: Maybe(t.string),
            accountStatus: Maybe(t.string),
            expiredPointInNextMonth: Maybe(t.number),
            expiredPointIn03Month: Maybe(t.number),
            expiredPointIn06Month: Maybe(t.number),
            contactChannels: Maybe(
              t.array(
                t.type({
                  channel: Maybe(t.string),
                  contactValue: Maybe(t.string),
                  indicator: Maybe(t.string),
                  zaloId: Maybe(t.string),
                  zaloName: Maybe(t.string)
                })
              )
            ),
            riskCessDate: Maybe(t.string),
            enrolledDate: Maybe(t.string),
            otpConsent: Maybe(t.string)
          })
        ),
        policyOwners: Maybe(
          t.array(
            t.type({
              clientNumber: Maybe(t.string),
              role: Maybe(t.string),
              clientName: Maybe(ClientName),
              nationalID: Maybe(t.string),
              gender: Maybe(t.string),
              dateOfBirth: Maybe(t.string),
              ageLatBirth: Maybe(t.number),
              mortalityClass: Maybe(t.string),
              poOwner: Maybe(t.boolean),
              occupationCode: Maybe(t.string),
              occupationName: Maybe(t.string),
              currentAge: Maybe(t.number),
              lifeNo: Maybe(t.string),
              usedToBe: Maybe(t.string)
            })
          )
        ),
        fao: Maybe(
          t.type({
            clientNumber: Maybe(t.string),
            clientName: Maybe(t.string),
            title: Maybe(t.string),
            nationalId: Maybe(t.string),
            gender: Maybe(t.string),
            dateOfBirth: Maybe(t.string)
          })
        )
      })
    ),
    error: Error
  })

  export type CustomerInfoC = t.TypeOf<typeof CustomerInfoC>

  export const PolicyInfoC = t.type({
    data: t.type({
      policy: t.type({
        policyStatus: Maybe(t.string),
        premiumStatus: Maybe(t.string),
        sumAssured: Maybe(t.number),
        billingFrequency: Maybe(t.string),
        policyOwnerCode: Maybe(t.string),
        policyOwnerName: Maybe(ClientName),
        mainLifeAssuredCode: Maybe(t.string),
        mainLifeAssuredName: Maybe(ClientName),
        productCode: Maybe(t.string),
        productNameVN: Maybe(t.string),
        productNameEN: Maybe(t.string),
        installmentPremium: Maybe(t.number),
        riskCommencementDate: Maybe(t.string),
        aplDate: Maybe(t.string),
        policyIssueDate: Maybe(t.string),
        paidToDateBasic: Maybe(t.string),
        premiumCessationDate: Maybe(t.string),
        riskCessationDate: Maybe(t.string),
        customerReceiveDate: Maybe(t.string),
        customerReceiveMethod: Maybe(t.string),
        firstIssueDate: Maybe(t.string)
      }),
      beneficiaries: Maybe(
        t.array(
          t.type({
            beneficiaryCode: Maybe(t.string),
            beneficiaryPercentage: Maybe(t.number),
            beneficiaryRelationship: Maybe(t.string),
            beneficiaryName: Maybe(ClientName)
          })
        )
      ),
      transactionHistories: Maybe(
        t.array(
          t.type({
            id: Maybe(t.string),
            name: Maybe(t.string),
            businessKey: Maybe(t.string),
            source: Maybe(t.string),
            category: Maybe(t.string),
            transactionType: TransactionTypeC,
            policyNumber: Maybe(t.string),
            clientNumber: Maybe(t.string),
            poName: Maybe(t.string),
            lastUpdateDate: Maybe(t.string),
            subTransactionType: Maybe(t.string),
            agingDate: Maybe(t.number),
            assignee: Maybe(t.string),
            assigneeName: Maybe(t.string),
            activity: Maybe(t.string),
            createdDate: Maybe(t.string),
            request: Maybe(t.string),
            status: Maybe(t.string),
            createdBy: Maybe(t.string),
            lastUpdateByVer: Maybe(t.string),
            lastUpdateByAdj: Maybe(t.string),
            poClientNumber: Maybe(t.string),
            poPhoneNumber: Maybe(t.string),
            serviceType: Maybe(t.string),
            group: Maybe(t.string),
            subServiceType: Maybe(t.string),
            comment: Maybe(t.string),
            closeInfo: Maybe(t.string),
            note: Maybe(t.string),
            action: Maybe(t.string),
            endTime: Maybe(t.string),
            createTime: Maybe(t.string),
            lastUpdateDateAdj: Maybe(t.string),
            lastUpdateDateVer: Maybe(t.string),
            lifeAssuredName: Maybe(t.string),
            startTime: Maybe(t.string),
            completed: Maybe(t.boolean),
            suspended: Maybe(t.boolean)
          })
        )
      )
    })
  })

  export type PolicyInfoC = t.TypeOf<typeof PolicyInfoC>

  export interface policySearch {
    start: number
    size: number
    data: {
      policyNo: string
      poIDNumber: string
      poMobilePhone: string
      poClientNumber: string
      officeCode?: string
    }
  }

  export const PolicySearchItem = t.type({
    policyNumber: Maybe(t.string),
    policyStatus: Maybe(t.string),
    premiumStatus: Maybe(t.string),
    clientNumber: Maybe(t.string),
    proposalNumber: Maybe(t.string),
    policyOwnerName: Maybe(ClientName),
    mainLifeAssuredName: Maybe(ClientName),
    idnumber: Maybe(t.string),
    roles: Maybe(t.array(t.string))
  })

  export type PolicySearchItem = t.TypeOf<typeof PolicySearchItem>

  export const IPolicySearch = t.type({
    policyNumber: Maybe(t.string),
    policyStatus: Maybe(t.string),
    premiumStatus: Maybe(t.string),
    clientNumber: Maybe(t.string),
    policyOwnerName: Maybe(t.string),
    mainLifeAssuredName: Maybe(t.string),
    idnumber: Maybe(t.string),
    roles: Maybe(t.array(t.string)),
    proposalNumber: Maybe(t.string)
  })

  export type IPolicySearch = t.TypeOf<typeof IPolicySearch>

  export const policySearchResponse = t.type({
    data: t.type({
      policies: Maybe(t.array(PolicySearchItem)),
      gacodeInValid: t.boolean
    }),
    size: Maybe(t.number),
    start: Maybe(t.number),
    total: Maybe(t.number)
  })

  export type policySearchResponse = t.TypeOf<typeof policySearchResponse>

  export const ProductTypeC = t.type({
    data: t.type({
      productType: t.string,
      productCode: t.string,
      basicCode: t.string,
      status: t.string,
      salaryIncome: t.number,
      lastIssueDate: t.string,
      paidToDateAdvance: t.string,
      anniDate: t.string,
      paidToDateBasic: t.string,
      paidToDateRider: t.string,
      billToDate: t.string,
      billFreq: t.string,
      mainLifeAssuredNumber: t.string,
      clientDespatchAddress: t.string,
      riskCommenceDate: t.string,
      surrenderDate: t.string,
      lapsedDate: t.string,
      policyNo: t.string,
      totalPremium: t.number,
      contractDate: t.string,
      firstIssueDate: t.string,
      premiumStatus: t.string
    })
  })

  export type ProductTypeC = t.TypeOf<typeof ProductTypeC>

  export const extraInfoC = t.type({
    data: t.type({
      function: t.string,
      policies: t.array(t.string)
    })
  })
  export type extraInfoC = t.TypeOf<typeof extraInfoC>

  export const ExtraInfoResponse = t.type({
    data: t.type({
      policyExtraInfoDetails: t.array(
        t.type({
          productCode: t.string,
          lifeNo: t.string,
          coverageNo: t.string,
          riderNo: t.string,
          benefitOption: t.string,
          mortClass: t.string,
          sumAssured: t.number,
          installmentPremium: t.number,
          totalPaidBasicYearly: t.number,
          riskCommDate: t.string,
          premCessDate: t.string,
          riskCessDate: t.string,
          dealingDate: t.string,
          rerateDate: t.string,
          debtAmount: t.number,
          targetPremium: t.number,
          minTopUp: t.number,
          maxTopUp: t.number,
          policyNum: t.string,
          riderStatus: t.string,
          riderPremiumStatus: t.string
        })
      ),
      policyExtraInfoFunds: t.array(
        t.type({
          fundCode: t.string,
          unitBalance: t.number,
          price: t.number,
          priceDate: t.string,
          policyNum: t.string,
          estimateValue: t.number,
          fundNameEN: t.string,
          fundNameVN: t.string,
          fundTypeCode: t.string,
          fundTypeName: t.string
        })
      )
    })
  })

  export type ExtraInfoResponse = t.TypeOf<typeof ExtraInfoResponse>

  export const getAgentInfo = (policyNum: string): Task<AgentInfoC> =>
    pipe(
      POApi.get(`cep-rest/policy/${policyNum}/agent`)(AgentInfoC),
      ZIO.map((res) => res)
    )

  export const getProductInfo = (policyNum: string): Task<ProductPolicyRes> =>
    pipe(
      POApi.get(`cep-rest/policy/${policyNum}/product`)(ProductPolicyRes),
      ZIO.map((res) => {
        return res
      })
    )
  export const getCustomerInfo = (policyNum: string): Task<CustomerInfoC> =>
    pipe(
      POApi.get(`cep-rest/policy/${policyNum}/customer`)(CustomerInfoC),
      ZIO.mapError((err) => {
        return Throwable(err)
      }),
      ZIO.flatMap((response) => {
        if (!response.error) {
          return ZIO.succeed(response)
        } else {
          return ZIO.fail(Throwable('Customer error'))
        }
      })
    )

  export const getPolicyInfo = (policyNum: string): Task<PolicyInfoC> =>
    pipe(
      POApi.get(`cep-rest/policy/${policyNum}`)(PolicyInfoC),
      ZIO.map((res) => {
        return res
      })
    )

  export const searchPolicyInfo = (data: policySearch): Task<policySearchResponse> =>
    pipe(
      POApi.post(`cep-rest/policy/search`)(policySearchResponse)(data),
      ZIO.map((res) => {
        return res
      })
    )
  export const getProductType = (policyNum: string): Task<ProductTypeC> =>
    pipe(
      POApi.get(`cep-rest/policy/${policyNum}/detail`)(ProductTypeC),
      ZIO.map((res) => res)
    )

  export const getInvestmentInfo = (data: extraInfoC): Task<ExtraInfoResponse> =>
    pipe(
      POApi.post(`cep-rest/policy/extra-info`)(ExtraInfoResponse)(data),
      ZIO.map((res) => res)
    )

  export const PolicyValueC = t.type({
    data: t.type({
      contractNumber: Maybe(t.string),
      effectiveDate: Maybe(t.string),
      contractStatus: Maybe(t.string),
      productType: Maybe(t.string),
      coverageId: Maybe(t.string)
    })
  })
  export type PolicyValueC = t.TypeOf<typeof PolicyValueC>

  export const PolicyValueInfoData = t.type({
    contractValue: t.type({
      contractSurrenderValue: Maybe(t.number),
      loanAmountAvailable: Maybe(t.number),
      automaticPremiumLoan: Maybe(t.number),
      originalPolicyLoan: Maybe(t.number),
      loanExceedSurrenderValue: Maybe(t.number),
      bonusValue: Maybe(t.number),
      specialBonus: Maybe(t.number),
      bonusSurrender: Maybe(t.number),
      suspenseAmount: Maybe(t.number),
      maturityBenefit: Maybe(t.number),
      totalCashBenefit: Maybe(t.number),
      maxWithDraw: Maybe(t.number),
      pruKidBenefit: Maybe(t.number),
      prevMaturityAdvance: Maybe(t.number),
      totalMaturityAdvance: Maybe(t.number)
    }),
    billingChangeInfo: t.type({
      currentBillingFrequency: Maybe(t.string),
      currentInstallmentPremium: Maybe(t.number),
      automaticPolicyLoanDate: Maybe(t.string),
      oldBillingFrequency: Maybe(t.string),
      oldInstallmentPremium: Maybe(t.number),
      automaticPolicyLoanInterest: Maybe(t.number),
      requirePayingAmount: Maybe(t.number),
      paymentLastDay: Maybe(t.string)
    }),
    billingChange: t.type({
      currentBillingFrequency: Maybe(t.string),
      currentInstallmentPremium: Maybe(t.number),
      suspenseAmount: Maybe(t.number),
      advancePremium: Maybe(t.number),
      effectiveDateOfNewFrequency: Maybe(t.string)
    }),
    bonusSurrender: t.type({
      bonusValue: Maybe(t.number),
      currentBalance: Maybe(t.number),
      interest: Maybe(t.number),
      bonusReserveRemainValue: Maybe(t.number)
    }),
    fullSurrender: t.type({
      cashDeposits: Maybe(t.number),
      suspenseAmount: Maybe(t.number),
      policyLoan: Maybe(t.number),
      policyDebt: Maybe(t.number),
      surrenderCharge: Maybe(t.number),
      total: Maybe(t.number),
      surrenderChargePercent: Maybe(t.number),
      netSurrenderValueAmount: Maybe(t.number)
    }),
    aplValue: t.array(
      t.type({
        currency: Maybe(t.string),
        accuredInterest: Maybe(t.number),
        currentBalance: Maybe(t.number),
        policyLoanTotal: Maybe(t.number),
        pendingInterest: Maybe(t.number),
        principalAmount: Maybe(t.number),
        loanNumber: Maybe(t.number),
        loanStartDate: Maybe(t.string),
        loanType: Maybe(t.string),
        dutyStamp: Maybe(t.number),
        receivedDate: Maybe(t.string),
        receivedAmount: Maybe(t.number)
      })
    ),
    oplValue: t.array(
      t.type({
        currency: Maybe(t.string),
        accuredInterest: Maybe(t.number),
        currentBalance: Maybe(t.number),
        policyLoanTotal: Maybe(t.number),
        pendingInterest: Maybe(t.number),
        principalAmount: Maybe(t.number),
        loanNumber: Maybe(t.number),
        loanStartDate: Maybe(t.string),
        loanType: Maybe(t.string),
        dutyStamp: Maybe(t.number),
        receivedDate: Maybe(t.string),
        receivedAmount: Maybe(t.number)
      })
    ),
    productInfo: Maybe(
      t.type({
        productType: Maybe(t.string),
        productCode: Maybe(t.string),
        basicCode: Maybe(t.string),
        status: Maybe(t.string)
      })
    ),
    validProduct: Maybe(t.boolean)
  })

  export type PolicyValueInfoData = t.TypeOf<typeof PolicyValueInfoData>

  export const PolicyValueResponseC = t.type({
    data: PolicyValueInfoData
  })

  export type PolicyValueResponseC = t.TypeOf<typeof PolicyValueResponseC>

  export const getPolicyValueInfo = (data: PolicyValueC): Task<PolicyValueResponseC> =>
    pipe(
      POApi.post(`cep-rest/policy/value`)(PolicyValueResponseC)(data),
      ZIO.map((res) => res)
    )

  export const BonusReverseInfoC = t.type({
    data: t.type({
      bonusReserveValue: t.number,
      bonusValue: t.number,
      bonusReserveRemainValue: t.number
    })
  })

  export type BonusReverseInfoC = t.TypeOf<typeof BonusReverseInfoC>

  export const BonusReverseDataC = t.type({
    data: t.type({
      contractNumber: Maybe(t.string),
      effectiveDate: Maybe(t.string),
      contractStatus: Maybe(t.string),
      productType: Maybe(t.string),
      coverageId: Maybe(t.string),
      amountToSurrender: Maybe(t.number),
      bonusValue: Maybe(t.number),
      bonusReserveRemainValue: Maybe(t.number)
    })
  })

  export type BonusReverseDataC = t.TypeOf<typeof BonusReverseDataC>

  export const getPolicyBonusReverse = (data: BonusReverseDataC): Task<BonusReverseInfoC> =>
    pipe(
      POApi.post(`cep-rest/policy/calc-bonus-reverse`)(BonusReverseInfoC)(data),
      ZIO.map((res) => res)
    )

  export const TransactionHistoryC = t.type({
    start: t.number,
    size: t.number,
    data: Maybe(
      t.type({
        fromDate: Maybe(DateFromISOString),
        toDate: Maybe(DateFromISOString),
        category: t.string,
        transactionType: Maybe(t.array(t.string)),
        subServiceType: t.array(t.string),
        subTransactionType: t.array(t.string),
        source: t.array(t.string)
      })
    )
  })

  export type TransactionHistoryC = t.TypeOf<typeof TransactionHistoryC>

  export const ItemTransactionHistory = t.type({
    id: Maybe(t.string),
    name: Maybe(t.string),
    businessKey: Maybe(t.string),
    source: Maybe(t.string),
    category: Maybe(t.string),
    transactionType: TransactionTypeC,
    policyNumber: Maybe(t.string),
    clientNumber: Maybe(t.string),
    poName: Maybe(t.string),
    lastUpdateDate: Maybe(t.string),
    subTransactionType: Maybe(t.string),
    agingDate: Maybe(t.number),
    assignee: Maybe(t.string),
    assigneeName: Maybe(t.string),
    activity: Maybe(t.string),
    createdDate: Maybe(t.string),
    request: Maybe(t.string),
    status: Maybe(t.string),
    createdBy: Maybe(t.string),
    lastUpdateByVer: Maybe(t.string),
    lastUpdateByAdj: Maybe(t.string),
    poClientNumber: Maybe(t.string),
    poPhoneNumber: Maybe(t.string),
    serviceType: Maybe(t.string),
    group: Maybe(t.string),
    subServiceType: Maybe(t.string),
    comment: Maybe(t.string),
    closeInfo: Maybe(t.string),
    note: Maybe(t.string),
    action: Maybe(t.string),
    endTime: Maybe(t.string),
    createTime: Maybe(t.string),
    lastUpdateDateAdj: Maybe(t.string),
    lastUpdateDateVer: Maybe(t.string),
    lifeAssuredName: Maybe(t.string),
    startTime: Maybe(t.string),
    completed: Maybe(t.boolean),
    suspended: Maybe(t.boolean)
  })

  export type ItemTransactionHistory = t.TypeOf<typeof ItemTransactionHistory>

  export const TransactionHistoryRes = t.type({
    start: Maybe(t.number),
    size: Maybe(t.number),
    total: Maybe(t.number),
    data: Maybe(t.array(ItemTransactionHistory))
  })
  export type TransactionHistoryRes = t.TypeOf<typeof TransactionHistoryRes>

  export const getTransactionHistory = (policyNo: string, data: TransactionHistoryC): Task<TransactionHistoryRes> =>
    pipe(
      POApi.post(`cep-rest/policy/${policyNo}/transaction-history`)(TransactionHistoryRes)(data),
      ZIO.map((res) => res)
    )

  export const dataBillingFreq = t.type({
    data: t.type({
      contractNumber: t.string,
      billingFrequency: t.string
    })
  })

  export type dataBillingFreq = t.TypeOf<typeof dataBillingFreq>

  export const BillingFreqRes = t.type({
    data: Maybe(
      t.type({
        nextInstallmentPremium: t.number,
        minimumContribution: t.number
      })
    ),
    error: Error
  })

  export type BillingFreqRes = t.TypeOf<typeof BillingFreqRes>

  export const getBillingFrequency = (data: dataBillingFreq) =>
    pipe(
      POApi.post(`cep-rest/policy/billing-frequency-change`)(BillingFreqRes)(data),
      ZIO.map((res) => res)
    )

  export const PremiumHisReq = t.type({
    start: t.number,
    size: t.number,
    data: t.type({
      policyNo: t.string
    })
  })

  export type PremiumHisReq = t.TypeOf<typeof PremiumHisReq>

  export const PremiumHistoryItem = t.type({
    transactionId: Maybe(t.string),
    paymentType: Maybe(t.string),
    bankCode: Maybe(t.string),
    transDate: Maybe(t.string),
    amount: Maybe(t.number),
    rcpDate: Maybe(t.string),
    orNo: Maybe(t.string),
    agtCol: Maybe(t.string),
    user: Maybe(t.string),
    channel: Maybe(t.string),
    sacsCode: Maybe(t.string),
    sacsType: Maybe(t.string),
    gtAmount: Maybe(t.number),
    content: Maybe(t.string),
    transactionCode: Maybe(t.string)
  })

  export type PremiumHistoryItem = t.TypeOf<typeof PremiumHistoryItem>

  export const PremiumHistoryInfo = t.type({
    total: Maybe(t.number),
    start: Maybe(t.number),
    size: Maybe(t.number),
    data: Maybe(t.type({}))
  })
  export type PremiumHistoryInfo = t.TypeOf<typeof PremiumHistoryInfo>

  export const getPremiumHistory = (data: PremiumHisReq) =>
    pipe(
      POApi.post(`cep-rest/policy/premium-history`)(PremiumHistoryInfo)(data),
      ZIO.map((res) => res)
    )

  export const PremiumHistoryDetailItem = t.type({
    sacsCode: Maybe(t.string),
    sacsType: Maybe(t.string),
    gtAmount: Maybe(t.number),
    content: Maybe(t.string)
  })

  export type PremiumHistoryDetailItem = t.TypeOf<typeof PremiumHistoryDetailItem>

  export const PremiumHistoryDetailC = t.type({
    data: t.array(PremiumHistoryDetailItem)
  })

  export type PremiumHistoryDetailC = t.TypeOf<typeof PremiumHistoryDetailC>

  export const PremiumHisDetailReq = t.type({
    data: t.type({
      policyNo: t.string,
      transactionId: t.string
    })
  })

  export type PremiumHisDetailReq = t.TypeOf<typeof PremiumHisDetailReq>

  export const getPremiumHistoryDetail = (data: PremiumHisDetailReq) =>
    pipe(
      POApi.post(`cep-rest/policy/premium-history/detail`)(PremiumHistoryDetailC)(data),
      ZIO.map((res) => res)
    )
  export const CoreTransactionHistoryC = t.type({
    start: t.number,
    size: t.number,
    data: t.type({
      policyNo: t.string,
      codes: t.array(t.string)
    })
  })

  export type CoreTransactionHistoryC = t.TypeOf<typeof CoreTransactionHistoryC>

  export const ItemCoreTransaction = t.type({
    tranDate: t.string,
    code: t.string
  })

  export const CoreTransactionData = t.type({
    data: t.array(ItemCoreTransaction)
  })

  export type CoreTransactionData = t.TypeOf<typeof CoreTransactionData>

  export const getCoreTransaction = (data: CoreTransactionHistoryC) =>
    pipe(
      POApi.post(`cep-rest/policy/core-transaction-history`)(CoreTransactionData)(data),
      ZIO.map((res) => res)
    )
  export const CustomerNotesC = t.type({
    noteId: Maybe(t.number),
    noteCreatedBy: Maybe(t.string),
    noteCreatedDate: Maybe(t.string),
    noteContent: Maybe(t.string)
  })

  export type CustomerNotesC = t.TypeOf<typeof CustomerNotesC>

  export const TierHistory = t.type({
    activePoint: Maybe(t.number),
    oldAPE: Maybe(t.number),
    newAPE: Maybe(t.number),
    oldTier: Maybe(t.string),
    newTier: Maybe(t.string),
    createdDate: Maybe(t.string)
  })

  export const CustomerTierHistoryC = t.type({
    tierHistory: Maybe(t.array(TierHistory)),
    clientName: Maybe(ClientName)
  })

  export type CustomerTierHistoryC = t.TypeOf<typeof CustomerTierHistoryC>

  export const GiftRedemptionC = t.type({
    clientId: Maybe(t.string),
    transactionId: Maybe(t.string),
    promotionId: Maybe(t.string),
    promotionName: Maybe(t.string),
    providerName: Maybe(t.string),
    promotionCreateDate: Maybe(t.string),
    promotionReceivedDate: Maybe(t.string),
    deliveryStatus: Maybe(t.string),
    deliveryDate: Maybe(t.string),
    deliveryNote: Maybe(t.string)
  })

  export type GiftRedemptionC = t.TypeOf<typeof GiftRedemptionC>

  export const RewardPointC = t.type({
    issuer: Maybe(t.string),
    status: Maybe(t.string),
    pointType: Maybe(t.string),
    pointValue: Maybe(t.number),
    pointComment: Maybe(t.string),
    pointRemark: Maybe(t.string),
    pointTransferCreatedAt: Maybe(t.string),
    pointTransferExpiredAt: Maybe(t.string)
  })

  export type RewardPointC = t.TypeOf<typeof RewardPointC>

  export const PruRewardDataC = t.type({
    data: t.type({
      customerNotes: t.array(CustomerNotesC),
      customerTierHistories: CustomerTierHistoryC,
      giftRedemptions: t.array(GiftRedemptionC),
      rewardPoints: t.array(RewardPointC)
    })
  })

  export type PruRewardDataC = t.TypeOf<typeof PruRewardDataC>

  export const getDataPreReward = (policyNo: string) =>
    pipe(
      POApi.get(`cep-rest/client/${policyNo}/pru-reward`)(PruRewardDataC),
      ZIO.map((res) => res)
    )

  export const DuplicationPhoneC = t.type({
    start: t.number,
    size: t.number,
    data: t.type({
      phoneNumber: t.string,
      clientNumber: t.string
    })
  })

  export type DuplicationPhoneC = t.TypeOf<typeof DuplicationPhoneC>

  export const DuplicationPhoneItemC = t.type({
    phoneNumber: Maybe(t.string),
    clientNumber: Maybe(t.string),
    clientSurName: Maybe(t.string),
    clientGivenName: Maybe(t.string),
    agentCode: Maybe(t.string),
    agentSurname: Maybe(t.string),
    agentGivenName: Maybe(t.string)
  })

  export type DuplicationPhoneItemC = t.TypeOf<typeof DuplicationPhoneItemC>

  export const DuplicationPhoneResponseC = t.type({
    // start: t.number,
    // size: t.number,
    // total: t.number,
    data: t.array(DuplicationPhoneItemC),
    error: Error
  })

  export const getDuplicationPhone = (data: DuplicationPhoneC) =>
    pipe(
      POApi.post(`cep-rest/policy/duplicate-phone-number`)(DuplicationPhoneResponseC)(data),
      ZIO.map((res) => res)
    )

  export const NBUWRequestInfoC = t.type({
    data: t.type({
      proposalNo: t.string
    })
  })
  export type NBUWRequestInfoC = t.TypeOf<typeof NBUWRequestInfoC>

  export const NbuwRequestInfos = t.type({
    requestRole: Maybe(t.string),
    roleName: Maybe(t.string),
    remark: Maybe(t.string),
    requestStatus: Maybe(t.string),
    requestType: Maybe(t.string),
    createdDate: Maybe(t.string),
    createdBy: Maybe(t.string),
    lastUpdatedDate: Maybe(t.string),
    lastUpdatedBy: Maybe(t.string)
  })

  export type NbuwRequestInfos = t.TypeOf<typeof NbuwRequestInfos>

  export const UwRequestInfo = t.type({
    proposalFinalUWDecision: Maybe(t.string),
    remarkForCallCenter: Maybe(t.string),
    reason: Maybe(t.string)
  })

  const ReinsuranceRequestDetails = t.type({
    isRole: Maybe(t.string),
    clientNumber: Maybe(t.string),
    clientName: ClientName,
    isDescription: Maybe(t.string),
    meDescription: Maybe(t.string),
    reinDescription: Maybe(t.string),
    lifeNo: Maybe(t.string),
    uwDecisionBasic: Maybe(t.string),
    letterSummaryContent: Maybe(t.string),
    uwDecision: Maybe(t.string)
  })

  const ReinsuranceRequestInfo = t.type({
    province: Maybe(t.string),
    meServiceLevel: Maybe(t.string),
    panelDoctor: Maybe(t.string),
    suspendRemark: Maybe(t.string),
    conditionAcceptContent: Maybe(t.string),
    reinsuranceRequestDetails: t.array(ReinsuranceRequestDetails)
  })

  export const NBUWRequestInfoData = t.type({
    policyNumber: Maybe(t.string),
    proposalNumber: Maybe(t.string),
    policyStatus: Maybe(t.string),
    premiumStatus: Maybe(t.string),
    nbuwRequestInfos: Maybe(t.array(NbuwRequestInfos)),
    uwRequestInfo: Maybe(UwRequestInfo),
    reinsuranceRequestInfo: Maybe(ReinsuranceRequestInfo)
  })

  export const NBUWRootResponseC = t.type({
    data: NBUWRequestInfoData,
    error: Error
  })

  export type NBUWRootResponseC = t.TypeOf<typeof NBUWRootResponseC>

  export const getNBUWRequestInfo = (data: NBUWRequestInfoC) =>
    pipe(
      POApi.post(`cep-rest/new-business-underwriting/request-info`)(NBUWRootResponseC)(data),
      ZIO.map((res) => res)
    )
}
