import { QCTaskList, SubTasksResponse, SubTaskType, SupendedTaskList, SupplementaryInfo, TaskDetail, TaskType } from '@pulseops/common'
import { VeriPayloadData } from 'libs/common/src/service/model/task-detail/InquiryComplaint'
import React from 'react'
import { UseFormReturn } from 'react-hook-form'
import { View } from 'react-native'
import { InquiryComplaintForm } from './inquiry-complaint'
import { RequestInfoTransactions } from './RequestInfoTransactions'

interface Props {
  detail: TaskDetail.Type | null
  updateToCore: boolean
  basket: SubTaskType
  category: TaskType
  subTask: SubTasksResponse
  inquiryComplaintForm: UseFormReturn<InquiryComplaintForm>
  onSubmitInquiry: (isSubmit: boolean) => void
  roles: string[]
  isNotCheckValidator: boolean
  isHistory: boolean | null
  isInquiry: boolean | null
  onCheckValidator: (isValidator: boolean) => void
  UWData?: {
    isUWTransaction: boolean
    UWDecision: string
    isUWCase: boolean
  }
  setUpdatedIACPayloadData: (data: VeriPayloadData) => void
  updatedIACPayloadData?: VeriPayloadData
  isChangeTab?: boolean
  setIsScroll?: (ischeck: boolean) => void
  isScroll?: boolean
  // historyList?: SupplementaryInfo.HistoryList
  setIsHiddenButtonsForIACHistory?: (val: boolean) => void
}

export const RequestInfo: React.FC<Props> = (props) => {
  const {
    detail,
    basket,
    category,
    inquiryComplaintForm,
    subTask,
    onSubmitInquiry,
    roles,
    isNotCheckValidator,
    isHistory,
    isInquiry,
    onCheckValidator,
    UWData,
    setUpdatedIACPayloadData,
    updatedIACPayloadData,
    isChangeTab,
    setIsScroll,
    isScroll,
    // historyList,
    setIsHiddenButtonsForIACHistory
  } = props

  const supendedTaskList = SupendedTaskList

  return (
    <View style={{ flex: 1 }}>
      <View style={{ flex: 1 }}>
        <View style={{ marginTop: 10 }}>
          {
            <RequestInfoTransactions
              detail={detail}
              inquiryComplaintForm={inquiryComplaintForm}
              subTaskCode={category}
              isHistory={isHistory}
              isInquiry={isInquiry}
              isSuspend={supendedTaskList.includes(subTask)}
              isQC={QCTaskList.includes(subTask)}
              onSubmitInquiry={onSubmitInquiry}
              roles={roles}
              isNotCheckValidator={isNotCheckValidator}
              onCheckValidator={onCheckValidator}
              basket={basket}
              setUpdatedIACPayloadData={setUpdatedIACPayloadData}
              updatedIACPayloadData={updatedIACPayloadData as VeriPayloadData}
              isChangeTab={isChangeTab}
              setIsScroll={setIsScroll}
              isScroll={isScroll}
              setIsHiddenButtonsForIACHistory={setIsHiddenButtonsForIACHistory}
            />
          }
        </View>
      </View>
      {/* {!isHistory && (
        <CompletePopup
          open={completeOpen}
          onClose={() => setCompleteOpen(false)}
          onSuccess={() => onHandleActionSuccess(true)}
          selectedValue={{
            taskId: detail?.id || '-',
            processId: detail?.processId || '-',
            updateToCore: updateToCore,
            policyNum: detail?.primaryPolicyNo || '-',
            category: detail?.category,
            isUWTransaction: UWData?.isUWTransaction,
            UWDecision: UWData?.UWDecision,
            isUWCase: UWData?.isUWCase,
            isCancelPayment: detail?.isCancelPayment,
            pvNumberCashout: detail?.pvNumberCashout,
            transaction: detail?.transaction
          }}
        />
      )}

      {detail && !isHistory && caterogy !== TaskType.CustomerService && (
        <EndPopup
          open={isEndPopupOpen}
          onClose={() => setIsEndPopupOpen(false)}
          onSuccess={() => onHandleActionSuccess(true)}
          selectedValue={{
            taskId: detail.id,
            updateToCore: updateToCore,
            category: caterogy as string
          }}
        />
      )}

      {detail &&
        !isHistory &&
        !isInquiry &&
        caterogy !== TaskType.CustomerService &&
        ![SubTaskType.Suspend, SubTaskType.Underwriting].includes(basket) && (
          <RejectPopup
            open={isRejectPopupOpen}
            onClose={() => setIsRejectPopupOpen(false)}
            onSuccess={() => onHandleActionSuccess(true)}
            selectedValue={{
              taskId: detail.id,
              updateToCore: updateToCore,
              processId: detail.processId,
              category: caterogy as string
            }}
          />
        )}

      {detail && caterogy && basket && (
        <TransferPopup
          open={isTransferPopupOpen}
          onClose={() => setIsTransferPopupOpen(false)}
          onSuccess={onHandleActionSuccess}
          selectedValue={{
            category: caterogy as TaskType,
            basket: basket,
            taskIds: [detail.id],
            updateToCore: updateToCore,
            transactionType: detail.payload.tag,
            isUWCase: UWData?.isUWCase
          }}
        />
      )}

      {detail && planSuspendDate && (
        <SuspendPopup
          open={isSuspendPopupOpen}
          onClose={() => setIsSuspendPopupOpen(false)}
          onSuccess={() => onHandleActionSuccess(true)}
          selectedValue={{
            processId: detail.processId,
            taskId: detail.id,
            planSuspendDate: planSuspendDate,
            updateToCore: updateToCore,
            isCancelPayment: detail?.isCancelPayment
            historyList: historyList
          }}
          category={caterogy}
        />
      )} */}
      
       {/* {detail && caterogy && basket && (
        <UnsuspendPopup
          open={isUnSuspendPopupOpen}
          onClose={() => setIsUnSuspendPopupOpen(false)}
          onSuccess={onHandleActionSuccess}
          selectedValue={{
            category: caterogy as TaskType,
            basket: basket,
            taskIds: [detail.id],
            updateToCore: updateToCore,
            currAssignee: detail.assignee
          }}
        />
      )} */}
    </View>
  )
}
