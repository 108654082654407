import { PageParameter } from '../../pagination'
import { TaskSource } from './TaskSource'
import { TransactionType } from './TransactionType'

export namespace HistoricTask {
  export type Filter = {
    source?: TaskSource[]
    policyNum?: string
    clientNum?: string
    caseId?: string
    transactionType?: TransactionType[]
    fromDate?: Date
    toDate?: Date
    assignee?: string
    categories?: string[]
    subServiceTypes?: string[]
    subTransactionTypes?: string[]
    offices?: string[]
    claimNumber?: string
    claimTypes?: string[]
    businessKeyEmpty?: boolean
    idNumber?: string
    phoneNumber?: string
    proposalNumber?: string
    amount?: string
    bankCode?: string
    eref?: string
    agentCode?: string
    clientId?: string
    referenceNumber?: string
    caseStatus?: number
    pvNumber?: string
    paymentNumber?: string
    paymentDate?: Date
    payeeName?: string,
    authorizedDate?: Date,
    category?: string
    taxNumber?: string
    agentCertificate?: string
    signDateCompany?: Date
  }

  export type InputData = {
    pagination: PageParameter
    order?: 'desc' | 'asc'
    filter: Filter
  }

  export type PostData = {
    start: number
    size: number
    order?: 'desc' | 'asc'
    sourceIn?: TaskSource[]
    transactionTypeIn?: TransactionType[]
    correlationId?: string
    startedAfter?: Date
    startedBefore?: Date
    involvedUser?: string
    businessKey?: string
  }
  export const PostData = ({ pagination, filter, order }: InputData): PostData => ({
    start: pagination.pageIndex * pagination.pageSize,
    size: pagination.pageSize,
    order,
    sourceIn: filter.source,
    transactionTypeIn: filter.transactionType,
    correlationId: filter.policyNum,
    businessKey: filter.caseId,
    startedAfter: filter.fromDate,
    startedBefore: filter.toDate,
    involvedUser: filter.assignee
  })
}
