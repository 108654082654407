import { ErrorHandling, PulseOpsFormat, TaskDetailService, TransactionType } from '@pulseops/common'
import * as React from 'react'
import { ScrollView, View } from 'react-native'
import {
  LinearProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
  tableHeader: {
    backgroundColor: '#E5EAEF',
    borderColor: '#D3DCE6'
  },
  tableCell: {
    whiteSpace: 'nowrap'
  }
})

interface OGRModel {
  caseId: string
  transactionType: string
  claimNumber: string
  createdDate: string
  activity: string
  status: string
  assignee: string
  source: string
}

export const OnGoingRequestTab = (props: { policyNum: string }) => {
  const classes = useStyles()

  const [pageNum, setPageNum] = React.useState<number>(0)
  const [pageSize, setPageSize] = React.useState<number>(10)

  const [orderBy, setOrderBy] = React.useState<string>('startTime')
  const [order, setOrder] = React.useState<'asc' | 'desc'>('desc')

  const [total, setTotal] = React.useState<number>(0)

  const [rows, setRows] = React.useState<OGRModel[]>([])
  const [loading, bindLoading] = useLoading()

  const { t } = useTranslation()

  const setOrderTable = () => {
    setOrderBy('startTime')
    setOrder('desc')
  }

  const loadData = (pageNum: number, pageSize: number) =>
    pipe(
      TaskDetailService.GetOGR(
        props.policyNum,
        {
          pageSize,
          pageNum
        },
        {
          order: order,
          field: orderBy
        }
      ),
      ZIO.map((res) => {
        setTotal(res.total)
        return res.data
          ? res.data
              .filter((c) => c.transactionType !== TransactionType.PAYOUT)
              .map((x) => ({
                // caseId: this.caseId,
                caseId: x.businessKey ?? '-',
                createdDate: x.createdDate
                  ? PulseOpsFormat.datetoFormat(new Date(x.createdDate), 'DD/MM/yyyy HH:mm:ss')
                  : '-',
                transactionType: x.transactionType,
                activity: x.activity ?? '-',
                status: x.status ?? '-',
                assignee: x.assignee || '-',
                source: x.source ?? '-',
                claimNumber: x.claimNumber ?? '-'
              }))
          : []
      }),
      ZIO.tap((res) => {
        setRows(res)
        return ZIO.unit
      }),
      bindLoading
    )

  pipe(loadData(pageNum, pageSize), ErrorHandling.runDidMount())

  const update = (pageNum: number, pageSize: number) => {
    pipe(loadData(pageNum, pageSize), ErrorHandling.run())
  }
  return (
    <ScrollView>
      <View>
        {loading && <LinearProgress color="secondary" />}
        <TableContainer component={Paper}>
          <Table>
            <TableHead className={classes.tableHeader}>
              <TableRow>
                <TableCell className={classes.tableCell}>{t('common:CaseId')}</TableCell>
                <TableCell className={classes.tableCell}>{t('common:ClaimNumber')}</TableCell>
                <TableCell className={classes.tableCell}>{t('common:CreatedDate')}</TableCell>
                <TableCell className={classes.tableCell}>{t('common:TransactionType')}</TableCell>
                <TableCell className={classes.tableCell}>{t('common:Activity')} </TableCell>
                <TableCell className={classes.tableCell}>{t('common:Status')}</TableCell>
                <TableCell className={classes.tableCell}>{t('common:Assignee')}</TableCell>
                <TableCell className={classes.tableCell}>{t('common:Source')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.caseId}>
                  <TableCell component="th" scope="row">
                    {row.caseId}
                  </TableCell>

                  <TableCell>{row.claimNumber}</TableCell>
                  <TableCell>{row.createdDate}</TableCell>
                  <TableCell>{row.transactionType}</TableCell>
                  <TableCell>{row.activity}</TableCell>
                  <TableCell>{row.status}</TableCell>
                  <TableCell>{row.assignee}</TableCell>
                  <TableCell>{row.source}</TableCell>
                </TableRow>
              ))}
              <TableRow></TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          page={pageNum}
          rowsPerPage={pageSize}
          count={total}
          onPageChange={(e, page) => {
            setPageNum(page)
            update(page, pageSize)
          }}
          onRowsPerPageChange={(e) => {
            const newPageSize = Number(e.target.value)
            setPageSize(newPageSize)
            update(pageNum, newPageSize)
            setOrderTable()
          }}
          labelRowsPerPage={t('common:PaginationSize')}
          labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
          component={View}
        />
      </View>
    </ScrollView>
  )
}
