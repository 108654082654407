import React, { CSSProperties } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { StyleSheet, View } from 'react-native'

type TypeSelect<T> = {
  onChange?: (data: T[]) => void
  getOptionLabel?: (option: T) => string
  value?: T[]
  defaultValues?: T[]
  options: T[]
  id?: string
  inputLabel?: string
  placeholder?: string
  size?: 'small' | 'medium'
  style?: CSSProperties
}

function Select<T>(props: TypeSelect<T>) {
  const { id, inputLabel, placeholder, options, value, defaultValues, size, style, onChange, getOptionLabel } = props
  return (
    <View style={styles.container}>
      <Autocomplete
        size={size}
        multiple
        id={id}
        options={options}
        getOptionLabel={getOptionLabel}
        defaultValue={defaultValues}
        filterSelectedOptions
        limitTags={1}
        style={{ backgroundColor: '#fff', ...style }}
        value={value}
        onChange={(_, value) => onChange?.(value)}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label={inputLabel} placeholder={placeholder} />
        )}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 300
  }
})

export default Select
