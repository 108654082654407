import React from 'react'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useTranslation } from 'react-i18next'
import { View, Text, StyleSheet, TouchableOpacity, FlatList } from 'react-native'
import {
  AppContext,
  assets,
  Checkbox,
  ErrorHandling,
  GeneralService,
  ImgUploadMutiple,
  Input,
  RadioButton,
  sharedStyle,
  SubmissionService,
  TabList,
  TransactionType
} from '@pulseops/common'
import { RequestAuthenticateData } from './OtpRequestData'
import moment from 'moment'
import { pipe } from 'fp-ts/lib/function'
import { Throwable, ZIO } from '@mxt/zio'
import { of, Observable, timer } from 'rxjs'
import * as RO from 'rxjs/operators'
import { Controller, UseFormReturn } from 'react-hook-form'

export interface TransactionAuthen {
  showOtp?: boolean
  showPaper?: boolean
  requestAuthenData: RequestAuthenticateData.EformRequestAuthenticateData
  authenForm: UseFormReturn<RequestAuthenticateData.RequestAuthFormValue>
  isByPassAuthPaper: boolean
  updateSendDate: (sendDate: string) => void
  updatedocReviewed: (docReview: boolean) => void
  updateRequestAuthenData: (data: RequestAuthenticateData.EformRequestAuthenticateData) => void
  officeCode: string
  transactionType: string
  hideOTP?: boolean
}

interface tabOption {
  id: number
  title: string
}

export const OtpRequest = (props: TransactionAuthen) => {
  const { hideOTP = false } = props
  const otpCounter = 120
  const { t } = useTranslation()
  const requestAuthenData = props.requestAuthenData
  const docTypeCode = requestAuthenData.docTypeCode
  const { showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const MapOTPOptions: { value: RequestAuthenticateData.OTPOption; label: string } = {
    value: RequestAuthenticateData.OTPOption.PHONE,
    label: `+${requestAuthenData.phoneData.phoneCode} ${requestAuthenData.phoneData.phoneNum}`
  }

  const [tabIndex, setTabIndex] = React.useState<number>(0)
  const [otpState, setOTPState] = React.useState<number>(0)
  const [otpCounterStr, setOTPCounterStr] = React.useState<Observable<string>>(of(''))
  const [otpTimer, setOTPTimer] = React.useState<string>('')
  const { control, setValue } = props.authenForm

  React.useEffect(() => {
    const subscription = otpCounterStr.subscribe(setOTPTimer)
    return () => subscription.unsubscribe()
  }, [otpCounterStr])

  const getTransactionName = () => {
    switch (props.transactionType) {
      case TransactionType.AGENT_TERMINATION_AG: {
        return 'yeu cau thanh ly hop dong'
      }
      case TransactionType.AGENT_UNIT_TRANSFER: {
        return 'yeu cau thay doi phong (Unit)'
      }
      case TransactionType.AGENT_OFFICE_MOVING: {
        return 'yeu cau thay doi van phong hoat dong'
      }
      case TransactionType.AGENT_TRANSFER_POLICY: {
        return 'yeu cau thay doi TVV phuc vu hoat dong'
      }
      default:
        return props.transactionType || ''
    }
  }

  const checkTransactionType = () =>{
    return props.transactionType === TransactionType.AGENT_TERMINATION_AG || props.transactionType === TransactionType.AGENT_TRANSFER_POLICY
  }

  const DATA: tabOption[] = [
    { id: 1, title: t('OTP:Note1') },
    { id: 2, title: t('OTP:Note2') },
    { id: 3, title: t('OTP:Note3') }
  ]

  const ItemView = (text: string) => {
    return (
      <View style={[{ flexDirection: 'row', marginBottom: 4 }]}>
        <Text style={otpStyles.itemStyle}></Text>
        <Text>{text}</Text>
      </View>
    )
  }

  const requestOTP = () => {
    const sendDate = moment().format('yyMMDDhhmmss').substr(2, 12)
    const transactionName = getTransactionName()
    props.updateSendDate(sendDate)
    showGlobalLoading(true)
    pipe(
      SubmissionService.otpRequestAgent(
        transactionName,
        `${requestAuthenData.transactionType}-${requestAuthenData.agentNum}-${sendDate}`,
        requestAuthenData.phoneData.phoneNum,
        requestAuthenData.agentNum,
        props.transactionType === TransactionType.AGENT_TRANSFER_POLICY ? true : false
      ),
      ZIO.mapError((err) => {
        const code = err?.source?.message.split(' - ')[0]
        const time = err?.source?.message.split(' - ')[1].replace('t=', '')
        const getErrorMess =
          code === 'MS050252'
            ? t('message:MS050252', { t: time })
            : code === 'MS050254'
            ? t('message:MS050254', { t: time })
            : code === 'MS050253'
            ? t('message:MS050253')
            : code === 'MS050255'
            ? t('message:MS050255')
            : 'Unknown error'
        showToast(getErrorMess, 'error')
        showGlobalLoading(false)
        return Throwable('Done')
      }),
      ZIO.unsafeRun({})
    ).then((_) => {
      showGlobalLoading(false)
      setOTPState(1)
      let countDown = otpCounter
      const otpCounter$ = timer(0, 1000).pipe(
        RO.take(countDown),
        RO.map(() => --countDown),
        RO.takeWhile((x) => x >= 0)
      )
      const otpCounterStr$ = otpCounter$.pipe(
        RO.map((x) => `0${Math.floor(x / 60)}:${x % 60 < 10 ? `0${x % 60}` : x % 60}`)
      )

      setOTPCounterStr(otpCounterStr$)
    })
  }

  const onChangeTabEvent = (index: number) => {
    const authOptionItem =
      index === 0 ? RequestAuthenticateData.RequestAuthOption.OTP : RequestAuthenticateData.RequestAuthOption.PAPER
    setValue('authOption', authOptionItem)
    setTabIndex(index)
  }

  const menus = React.useMemo(() => {
    const tabs: tabOption[] = []
    if (!hideOTP) {
      tabs.push({
        id: 1,
        title: t('submission:OTP')
      })
    }

    // tabs.push({
    //   id: 2,
    //   title: t('submission:requestForm')
    // })

    onChangeTabEvent(hideOTP ? 1 : 0)
    return tabs
  }, [hideOTP])

  return (
    <SafeAreaView style={{ flex: 1 }}>
      {!checkTransactionType() && <>
        <Text style={[sharedStyle.sectionTitle, { marginTop: 15 }]}>{t('common:Note')}</Text>
        <Text
          style={[
            { fontSize: 12.8, opacity: 0.6, lineHeight: 18 },
            { marginTop: 5, marginBottom: 5 }
          ]}
        >
          <FlatList
            data={DATA}
            renderItem={({ item }) => ItemView(item.title)}
            keyExtractor={(item, index) => `${index}`}
          />
        </Text>
        </>
      }
      <Text style={[sharedStyle.sectionTitle, { marginTop: 15 }]}>{t('submission:requestAuth')}</Text>
      <Text
        style={[
          { fontSize: 12.8, opacity: 0.6 },
          { marginTop: 5, marginBottom: 15 }
        ]}
      >
        {t('submission:AuthenticationMethod')} <Text style={{ color: '#ED2B1C' }}>*</Text>{' '}
      </Text>

      <View style={{ backgroundColor: 'rgb(211, 220, 230)', borderTopLeftRadius: 14, borderTopRightRadius: 14 }}>
        <TabList menus={menus} isSubTab={false} tabIndex={tabIndex} isOTPStyle={true} onChangeTab={onChangeTabEvent} />
      </View>
      <View style={otpStyles.authenContainer}>
        {/* Registered by OTP */}
        {tabIndex === 0 && (
          <View style={{ padding: 20 }}>
            {/* {props.requestAuthenData.otpRegistered && ( */}
            <View>
              <View style={{ paddingLeft: 10 }}>
                {MapOTPOptions && (
                  <Controller
                    name="otp.otpOption"
                    control={control}
                    defaultValue={MapOTPOptions.value}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <RadioButton
                        label={MapOTPOptions.label}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        selected={true}
                      />
                    )}
                  />
                )}
                {/* {MapOTPOptions && <RadioButton label={MapOTPOptions.label} selected={true} />} */}
                {otpState === 0 && (
                  <TouchableOpacity style={{ width: 110, marginTop: 30 }} onPress={requestOTP}>
                    <View style={otpStyles.sendBtn}>
                      <Text style={otpStyles.sendBtnText}>{t('OTP:send')}</Text>
                    </View>
                  </TouchableOpacity>
                )}
                {otpState === 1 && (
                  <View style={[otpStyles.sectionRow, { marginTop: 10 }]}>
                    <View style={otpStyles.col_4}>
                      <Controller
                        name="otp.otpInput"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: 'OTP is not valid'
                          }
                        }}
                        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                          <Input
                            title={''}
                            onChange={(val) => onChange && onChange(val.replace(/[^0-9]/g, ''))}
                            onBlur={onBlur}
                            value={String(value ?? '')}
                            errorMessage={error?.message}
                            // disabled={isDisabled || disabledAgentCode}
                            maxLength={6}
                          />
                        )}
                      />
                      <Text style={{ color: '#ef1c2e' }}>
                        {t('OTP:timeInput')} ({otpTimer})
                      </Text>
                      <TouchableOpacity style={{ width: 110, marginTop: 10 }} onPress={requestOTP}>
                        <View style={otpStyles.sendBtn}>
                          <Text style={otpStyles.sendBtnText}>{t('OTP:resend')}</Text>
                        </View>
                      </TouchableOpacity>
                    </View>
                  </View>
                )}
              </View>
              <View>
                <Controller
                  name="otp.otpConsent"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: ''
                    }
                  }}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <Checkbox
                      title={t('OTP:consent2')}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      errorMessage={error?.message}
                    />
                  )}
                />
              </View>
            </View>
            {/* )} */}
            {/* unregistered OTP */}
            {/* {!props.requestAuthenData.otpRegistered && (
              <Text style={otpStyles.errorMessage}>{t('message:MS050268')}</Text>
            )} */}
          </View>
        )}
      </View>
    </SafeAreaView>
  )
}
const otpStyles = StyleSheet.create({
  authenContainer: {
    borderWidth: 1,
    borderBottomLeftRadius: 14,
    borderBottomRightRadius: 14,
    minHeight: 50,
    borderTopWidth: 0,
    padding: 20,
    borderColor: 'rgb(211, 220, 230)'
  },
  errorMessage: {
    color: '#ef1c2e',
    fontSize: 15
  },
  sendBtn: {
    paddingHorizontal: 16,
    paddingVertical: 0,
    backgroundColor: 'white',
    borderRadius: 100,
    color: '#ED1B2E',
    border: 'solid 1px #ED1B2E',
    boxShadow: 'none'
  },
  sendBtnText: {
    textAlign: 'center',
    color: '#ef1c2e',
    lineHeight: 30
  },
  sectionRow: {
    flex: 1,
    flexDirection: 'row',
    marginRight: -15,
    marginLeft: -15
  },
  col_4: {
    width: '100%',
    maxWidth: '33.3333333333%',
    paddingRight: 15,
    paddingLeft: 15
  },
  col_12: {
    width: '100%',
    maxWidth: '100%',
    paddingRight: 15,
    paddingLeft: 15
  },
  paperContainer: {
    padding: 20
  },
  paperHeader: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  signatureContent: {
    marginLeft: 25,
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  signatureText: {
    marginLeft: 10
  },
  itemStyle: {
    width: 5,
    minWidth: 5,
    height: 5,
    borderRadius: 200,
    backgroundColor: 'black',
    marginTop: 8,
    marginRight: 10
  },
  itemWrap: {
    lineHeight: 18
  }
})
