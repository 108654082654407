import React, { useState, useContext } from 'react'
import { View } from 'react-native'
import {
  ModalComponent,
  useMobile,
  TextInput,
  SelectOption,
  PulseOpsService,
  Toast,
  AppContext,
  GeneralService,
  SelectSearch,
  assets,
} from '@pulseops/common'
import { useLoading } from '@mxt/zio-react'
import { useTranslation } from 'react-i18next'
import { pipe } from 'fp-ts/function'
import { ZIO } from '@mxt/zio'
import { Color } from '@material-ui/lab'
import { OBTaskService } from '@pulseops/outbound'

export type OBUnsuspendPopupProps = {
  onClose: () => void
  open: boolean
  onSuccess?: (mustRefreshTaskGroup?: boolean) => void
  currAssignee: string
  processInstanceId: string
  callProgram: string
}

export const OBUnsuspendPopup = (props: OBUnsuspendPopupProps) => {
  const { onClose, onSuccess, open, currAssignee, processInstanceId, callProgram } = props
  const { isWide } = useMobile()
  const { t } = useTranslation()
  const [loading, bindLoader] = useLoading(false)
  const { showGlobalLoading, isGlobalLoadingVisible, showToast } = useContext(AppContext.AppContextInstance)
  const [toast, setToast] = React.useState<{
    open: boolean
    message: string
    type?: Color
  }>({ open: false, message: '' })
  const [note, setNote] = useState<string>('')
  const [groupSelected, setGroupSelected] = useState<SelectOption | null>(null)
  const [userSelected, setUserSelected] = useState<SelectOption | null>(null)
  const [assignmentGroups, setAssignmentGroups] = useState<SelectOption[]>([])
  const [users, setUsers] = useState<SelectOption[]>([])

  const showToastInternal = (message: string, type: Color | undefined = undefined) => {
    setToast({ open: true, message, type })
  }

  const handleClose = () => {
    setNote('')
    setUsers([])
    setAssignmentGroups([])
    setUserSelected(null)
    setGroupSelected(null)
    onClose()
  }

  React.useEffect(() => {
    if (open) {
      pipe(
        GeneralService.getUsersGroupsByTransaction('OB'),
        ZIO.tap((res) => {
          const filterGroupByCallProgram = res.filter((x) => {
            const filterGroupList = x.userGroup.split('-')
            return filterGroupList && !x.groupName.includes('Admin') && filterGroupList.length >= 3 ? filterGroupList[1] === callProgram : false
          })
          filterGroupByCallProgram.length > 0 && setAssignmentGroups(
            filterGroupByCallProgram.map((x) => ({
              value: x.userGroup,
              label: x.groupName
            }))
          )
          return ZIO.unit
        }),
        bindLoader,
        ZIO.unsafeRun({})
      )
    }
  }, [open])

  React.useEffect(() => {
    setUsers([])
    setUserSelected(null)
    if (groupSelected) {
      pipe(
        OBTaskService.getUsersByGroups(
          [groupSelected.value.split('OB-')?.[1] ?? ''],
          currAssignee || '',
          'OB',
          'SUSPENDED'
        ),
        ZIO.catchAll((_error) => ZIO.fail(null)),
        ZIO.tap((users) => {
          setUsers(users.map((item) => ({ label: item.name, value: item.email })))
          return ZIO.unit
        }),
        ZIO.unsafeRun({})
      )
    }
  }, [groupSelected])

  // const checkUserOff = (userMail: string) => {
  //   return pipe(
  //     OBAssignmentService.checkStatus(userMail, callProgram),
  //     ZIO.map((res) => {
  //       if(res === 'OFF' || !res) {
  //         showToastinternal(t('message:OB0035'), 'info')
  //       }
  //     }),
  //     ZIO.unsafeRun({})
  //   )
  // }

  const onHandleUnsuspend = () => {
    const userLevel = groupSelected?.value ? groupSelected?.value.charAt(groupSelected?.value.length-1) : ''
    console.log(groupSelected?.value.length, userLevel, 'userLevel')
    if (userSelected && userLevel) {
      pipe(
        OBTaskService.unSuspendAction({
          processInstanceIds: [processInstanceId],
          assignee: userSelected.value,
          comment: note,
          userGroupCode: groupSelected?.value ? `OB-${callProgram}` : '',
          userLevel: groupSelected?.value ? groupSelected?.value.charAt(groupSelected?.value.length-1) : ''
        }),
        ZIO.catchAll((_error) => {
          showToastInternal(t('requestInfo:UnsuspendFailed'), 'error')
          return ZIO.fail(null)
        }),
        ZIO.tap((res: any) => {
          if(res && Object.keys(res).length !== 0 && res?.code === 'OB0035') {
            showToastInternal(t('message:OB0035'), 'error')
          }
          else {
            handleClose()
            showToast(t('requestInfo:UnsuspendSuccess'), 'success')
            onSuccess && onSuccess()
          }
          return ZIO.unit
        }),
        bindLoader,
        ZIO.unsafeRun({})
      )
    }
  }

  return (
    <ModalComponent
      title={t('claim:Unsuspend')}
      visible={open && !isGlobalLoadingVisible}
      onClose={handleClose}
      actions={[
        {
          text: t('claim:Cancel'),
          type: 'outline',
          disabled: false,
          loading: false,
          onlyWide: false,
          style: { height: 39, marginEnd: 15 },
          action: handleClose
        },
        {
          text: t('claim:Unsuspend'),
          type: 'filled',
          disabled: !groupSelected || !userSelected,
          loading,
          onlyWide: false,
          style: { height: 39 },
          action: onHandleUnsuspend
        }
      ]}
    >
      <View
        style={{
          marginHorizontal: 20,
          marginTop: isWide ? 20 : 16
        }}
      >
        <View style={{ marginBottom: 16, flexDirection: isWide ? 'row' : 'column' }}>
          <View style={{ flex: 1, marginEnd: isWide ? 32 : 0, marginBottom: isWide ? 0 : 16 }}>
            <SelectSearch
              value={groupSelected}
              label={t('claim:Group')}
              showPopupIcon={true}
              required={true}
              popupIcon={<assets.ArrowDownDropdownIcon />}
              options={assignmentGroups}
              onChange={setGroupSelected}
            />
          </View>
          <View style={{ flex: 1 }}>
            <SelectSearch
              value={userSelected}
              label={t('claim:User')}
              required={true}
              showPopupIcon={true}
              popupIcon={<assets.ArrowDownDropdownIcon />}
              options={users}
              onChange={(user) => {
                setUserSelected(user)
                // user && checkUserOff(user.value)
              }}
            />
          </View>
        </View>
        <TextInput
          title={t('claim:Comment')}
          value={note}
          onChange={setNote}
          placeholder={'...'}
          textInputProps={{
            maxLength: 500,
            multiline: true,
            style: [
              {
                borderWidth: 1,
                borderColor: '#D3DCE6',
                borderRadius: 8,
                padding: 16,
                fontSize: 15,
                lineHeight: 22,
                color: '#000000'
              },
              { marginTop: 10, height: isWide ? 140 : 60 }
            ],
            placeholderTextColor: '#B0B0B0'
          }}
        />
      </View>
      <Toast
        message={toast.message}
        visible={toast.open}
        type={toast.type}
        onClose={() => setToast({ open: false, message: '', type: undefined })}
      />
    </ModalComponent>
  )
}
