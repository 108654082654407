import { AppContext, Container, IDCDetailService, PulseOpsFormat, TaskDetailService } from '@pulseops/common'
import { OBDataSource, OBGeneralTable, OBGeneralTableColum } from '@pulseops/outbound'
import { pipe } from 'fp-ts/lib/function'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { ZIO } from '@mxt/zio'
import { IDCDetailContext } from '../IDCDetailContext'
import { useIsFocused } from '@react-navigation/native'
import { useLoading } from '@mxt/zio-react'
import * as O from 'fp-ts/lib/Option'

type Pagination = {
  pageNum: number
  pageSize: number
  total: number
}

type ErrLogModel = {
  transactionDate: string
  system: string
  errorCode: string
  desp: string
  isAllowRetry: boolean
  isRetried: boolean
}

export const IDCErrorLog = () => {
  const { t } = useTranslation()
  const { processInstanceID, transactionType, policyNumber, category } = React.useContext(IDCDetailContext)
  const [orderBy, setOrderBy] = React.useState<string>('startTime')
  const [order, setOrder] = React.useState<'asc' | 'desc'>('desc')
  const isFocused = useIsFocused()
  const [isLoading, bindLoader] = useLoading(false)
  const [isGlobalLoading, globalBindLoader] = useLoading(false)
  const { showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const [rows, setRows] = React.useState<ErrLogModel[]>([])
  const [{ pageNum, pageSize, total }, setPagination] = React.useState<Pagination>({
    pageNum: 0,
    pageSize: 10,
    total: 0
  })
  const displayedColumns: OBGeneralTableColum[] = [
    {
      title: t('ErrorLog:transDate'),
      fieldName: 'transactionDate',
      minWidth: 200,
      disabled: true
    },
    {
      title: t('common:system'),
      fieldName: 'system',
      minWidth: 150,
      disabled: true
    },
    {
      title: t('common:errorCode'),
      fieldName: 'errorCode',
      minWidth: 200,
      disabled: true
    },
    {
      title: t('common:description'),
      fieldName: 'desp',
      minWidth: 150,
      disabled: true
    },
    {
      title: '',
      fieldName: 'isAllowRetry',
      minWidth: 100,
      onRender: (selectedItem) => {
        const allowRetry = selectedItem.selectItem["isAllowRetry"]
        const isRetried = selectedItem.selectItem["isRetried"]
        const isShowRetryButton = allowRetry && !isRetried
        return isShowRetryButton ? (
          <TouchableOpacity onPress={onRetryHandler}>
            <View style={errorStyles.btnAdd}>
              <Text style={errorStyles.btn_text}>Retry</Text>
            </View>
          </TouchableOpacity>
        ) : <></>
      }
    }
  ]

  React.useEffect(() => {
    if (isFocused) {
      loadData(pageNum, pageSize)
    }
    return () => {
      setRows([])
    }
  }, [isFocused])

  React.useEffect(() => { showGlobalLoading(isGlobalLoading) }, [isGlobalLoading])

  const loadData = (pageNum: number, pageSize: number) =>
    pipe(
      TaskDetailService.GetWFComment(
        processInstanceID,
        {
          pageSize: pageSize,
          pageNum: pageNum
        },
        {
          order: order,
          field: orderBy
        }
      ),
      ZIO.map((res) => {
        setPagination({
          pageSize: pageSize,
          pageNum: pageNum,
          total: res.total
        })
        return res.data
          ? res.data.map((x) => ({
            transactionDate: PulseOpsFormat.dateStrWF(x.createDate, 'DD/MM/YYYY'),
            system: x.systemName ?? '',
            errorCode: x.errorCode ?? '',
            desp: x.description ?? '',
            isAllowRetry: pipe(x.isAllowRetry, O.fromNullable, O.map((isAllowRetry) => isAllowRetry), O.getOrElse(() => false)),
            isRetried: pipe(x.isRetried, O.fromNullable, O.map((isRetry) => isRetry), O.getOrElse(() => false))
          }))
          : []
      }),
      ZIO.tap((res) => {
        setRows(res)
        return ZIO.unit
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )

  const onRetryHandler = () => {
    const inputData: IDCDetailService.RetryUpdateToCoreInput = {
      processInstanceId: processInstanceID,
      category: category,
      transaction: transactionType,
      policyNumber: policyNumber
    }
    pipe(
      IDCDetailService.retryUpdateToCore(inputData),
      ZIO.map((retryResult) => {
        if (retryResult.responseStatus?.message === 'SUCCESS') {
          showToast('Retry successfully', 'success')
          loadData(pageNum, pageSize)
        } else {
          showToast(retryResult.responseStatus?.message || '', 'error')
        }
        return retryResult
      }),
      globalBindLoader,
      ZIO.unsafeRun({})
    )
  }

  const onPageChange = (pageNum: number, pageSize: number) => {
    loadData(pageNum, pageSize)
  }

  const onRowsPerPageChange = (pageNum: number, pageSize: number) => {
    loadData(pageNum, pageSize)
  }

  return (
    <Container loading={isLoading}>
      <View>
        <OBGeneralTable
          displayedColumns={displayedColumns}
          dataSource={rows as OBDataSource[]}
          isPagination
          totalRecord={total}
          pageNum={pageNum}
          pageSize={pageSize}
          onPageChange={(pageNum, pageSize) => onPageChange(pageNum, pageSize)}
          onRowsPerPageChange={(pageNum, pageSize) => {
            onRowsPerPageChange(pageNum, pageSize)
          }}
          setPageNum={(pageNum) => setPagination((state) => ({ ...state, pageNum }))}
          setPageSize={(pageSize) => setPagination((state) => ({ ...state, pageSize }))}
          setTotalRecord={(total) => setPagination((state) => ({ ...state, total }))}
        >
        </OBGeneralTable>
      </View>
    </Container>

  )
}
const errorStyles = StyleSheet.create({
  btnAdd: {
    minHeight: 40,
    width: 125,
    display: 'flex',
    alignItems: 'center',
    borderRadius: 100,
    paddingHorizontal: 25,
    paddingVertical: 10,
    backgroundColor: '#ED1B2E'
  },
  btn_text: {
    fontSize: 15,
    lineHeight: 20,
    color: '#fff',
    fontWeight: 'bold'
  }
})
