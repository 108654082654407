import * as React from 'react'
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import { DrawerNavigationHelpers } from '@react-navigation/drawer/lib/typescript/src/types'
import { useNavigation } from '@react-navigation/native'

const T_TABLE_MAX = 8

const menuList = Array.from({ length: T_TABLE_MAX }, (_, i) => ({ id: `T${i + 1}` }))

export const ConfigTableTab: React.FC = () => {
  const { t } = useTranslation()
  const navigation = useNavigation()
  const handlePress = (id: string) => () => navigation.navigate('SettingStack', { screen: `ConfigTable${id}Screen` })

  const renderItems = () => {
    return menuList.map((item) => (
      <Grid key={item.id} item xs={3}>
        <TouchableOpacity onPress={handlePress(item.id)}>
          <Text style={styles.item}>{item.id}</Text>
        </TouchableOpacity>
      </Grid>
    ))
  }

  return (
    <View style={styles.container}>
      <Text style={styles.header}>{t('Setting:ConfigTable')}</Text>

      <View style={styles.content}>
        <Grid container spacing={3}>
          {renderItems()}
        </Grid>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 30
  },
  header: {
    color: '#000000',
    fontSize: 24,
    fontWeight: 'bold'
  },
  content: {
    marginTop: 20
  },
  item: {
    border: '2px solid #ed1b2e',
    width: '100%',
    height: 40,
    backgroundColor: '#fff',
    borderRadius: 8,
    color: '#ed1b2e',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
})
