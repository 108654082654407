import React from 'react'
import { StyleSheet, Text, Touchable, View } from 'react-native'
import { OBFormat, OBGeneralTable, OBGeneralTableColum } from '../../ob-common'
import { useTranslation } from 'react-i18next'
import { pipe } from 'fp-ts/lib/function'
import { OBActionHistoryService, OBCallOutPopupService, OBDataSource } from '../../ob-service'
import { CallOutDetailContext } from '../ob-common'
import { ZIO, zio } from '@mxt/zio'
import { AppContext, Container, ErrorHandling, RBAC } from '@pulseops/common'
import { OBCallOutPopupConst } from '../ob-call-out-popup'
import * as O from 'fp-ts/lib/Option'
import { useLoading } from '@mxt/zio-react'
import { OBAvayaCallDetailPopup } from './OBAvayaCallDetailPopup'
import { TouchableOpacity } from 'react-native-gesture-handler'
import moment from 'moment'
import { useIsFocused } from '@react-navigation/native'
import { OBActionHistoryConst } from './OBActionHistoryConst'
import { isNil } from 'lodash'

type actionHistoryInfo = {
  STT: number
  callId: string
  assignee: string
  teamLeader: string
  callProgram: string
  actionDate: string
  callingReceiver: string
  manualDuration: string
  callingResult: string
  quickNote: string
  suggestion: string
  saveDate: string
  actionType: string
  communicationHistory: string
  calledDate: string
  originSaveDate: string
}

type CallDetailInfo = {
  connectionStatus: string
  callDuration: string
  avayaRecordLink: string
  callId: string
  errorMessage: string
}

export const OBActionHistory = () => {
  const { t, i18n } = useTranslation()
  const isFocused = useIsFocused()
  const { processInstanceId, isSavedCalloutPopupData } = React.useContext(CallOutDetailContext)
  const [isLoading, bindLoader] = useLoading(false)
  const { showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const [isOpenedCallDetailPopup, setIsOpenedCallDetailPopup] = React.useState(false)
  const [callDetailData, setCallDetailData] = React.useState<CallDetailInfo>()
  const [pageNum, setPageNum] = React.useState<number>(0)
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [total, setTotal] = React.useState<number>(0)
  const displayedColumns: OBGeneralTableColum[] = [
    {
      title: t('Outbound:OBActionHistory:No'),
      fieldName: 'STT'
    },
    {
      title: t('Call ID'),
      fieldName: 'callId',
      onRender: (rowItem) => {
        const callId = rowItem.selectItem["callId"] as string
        const actionItem = rowItem.selectItem as actionHistoryInfo
        return !!callId ? (
          <TouchableOpacity onPress={() => onOpenCallDetailEvent(actionItem)}>
            <Text style={actionStyles.hightLightText}>{callId}</Text>
          </TouchableOpacity>
        ) : (
          <Text>{callId}</Text>
        )
      }
    },
    // {
    //   title: t('Outbound:OBActionHistory:ContentChecking'),
    //   fieldName: '',
    //   minWidth: 150
    // },
    {
      title: t('Outbound:OBTaskManagement:CurrentAssignee'),
      fieldName: 'assignee'
    },
    {
      title: t('Outbound:OBActionHistory:TeamLeader'),
      fieldName: 'teamLeader'
    },
    {
      title: t('Outbound:OBTaskManagement:CallingProgram'),
      fieldName: 'callProgram'
    },
    {
      title: t('Outbound:OBActionHistory:ActionDate'),
      fieldName: 'actionDate',
      minWidth: 150
    },
    {
      title: t('Outbound:OBActionHistory:ActionType'),
      fieldName: 'actionType',
      minWidth: 150
    },
    // {
    //   title: t('Outbound:OBActionHistory:ConnectionStatus'),
    //   fieldName: '', // dang thieu
    //   minWidth: 160
    // },
    {
      title: t('Outbound:OBActionHistory:CallReceiver'),
      fieldName: 'callingReceiver',
      minWidth: 170
    },
    // {
    //   title: t('Outbound:OBActionHistory:CallDuration'),
    //   fieldName: 'manualDuration'
    // },
    {
      title: t('Outbound:OBActionHistory:CallResult'),
      fieldName: 'callingResult'
    },
    {
      title: t('Outbound:OBCallOutPopup:CallbackTime'),
      fieldName: 'callbackTime'
    },
    {
      title: t('Outbound:OBActionHistory:QuickNote'),
      fieldName: 'quickNote'
    },
    {
      title: t('Outbound:OBCallOutPopup:CallIndicator'),
      fieldName: 'callIndicator'
    },
    {
      title: t('Outbound:OBActionHistory:CallIndicatorStatus'),
      fieldName: 'callIndicatorStatus'
    },
    {
      title: t('Outbound:OBActionHistory:CommunicationHistory'),
      fieldName: 'communicationHistory',
      minWidth: 160
    },
    {
      title: t('Outbound:OBActionHistory:ChangeContactInfo'),
      fieldName: 'changeContactInfo',
      minWidth: 160
    },
    
    {
      title: t('Outbound:OBActionHistory:SavedDate'),
      fieldName: 'saveDate',
      minWidth: 170
    },
    {
      title: t('Outbound:OBActionHistory:Hint'),
      fieldName: 'suggestion',
      minWidth: 140
    }
  ]

  const roles: string[] =
    pipe(
      RBAC.permissions,
      ZIO.map((permissions) => {
        return permissions
      }),
      ErrorHandling.runDidUpdate([isFocused])
    ) || []

  const getActionType = (callingMethod: string, phoneNumber: string) => {
    switch (callingMethod) {
      case 'CM02': {
        const actionType = 'M' + '-' + phoneNumber
        return actionType
      }
      case 'CM03': {
        const actionType = 'H' + '-' + phoneNumber
        return actionType
      }
      case 'CM04': {
        const actionType = 'C' + '-' + phoneNumber
        return actionType
      }
      case 'CM05': {
        const actionType = 'O' + '-' + phoneNumber
        return actionType
      }
      default: {
        return ''
      }
    }
  }

  const [callReceiverListData, setCallReceiverList] = React.useState<OBCallOutPopupService.CallReceiverInfo[]>()
  const [callingResultListData, setCallingResultList] = React.useState<OBCallOutPopupService.CallingResultInfo[]>()
  const [doNotCallListData, setDoNotCallList] = React.useState<OBCallOutPopupService.DoNotCallInfo[]>()

  const actionHistoryList =
    pipe(
      ZIO.zipPar(
        isNil(callReceiverListData) ? OBCallOutPopupService.getCallReceiverList() : ZIO.succeed([]),
        OBActionHistoryService.getActionHistoryList(processInstanceId, pageNum, pageSize),
        isNil(callingResultListData) ? OBCallOutPopupService.getCallingResultList() : ZIO.succeed([]),
        isNil(doNotCallListData) ? OBCallOutPopupService.getDoNotCallList() : ZIO.succeed([])
      ),
      ZIO.map(([callReceiverList, responseData, callingResultList, doNotCallList]) => {
        setTotal(responseData.paging?.total || 0)
        setCallReceiverList(callReceiverList.length > 0 ? callReceiverList : callReceiverListData)
        setCallingResultList(callingResultList.length > 0 ? callingResultList : callingResultListData)
        setDoNotCallList(doNotCallList.length > 0 ? doNotCallList : doNotCallListData)

        const filterData = responseData.records.map((item, index) => {
          const suggestionItem = pipe(
            OBCallOutPopupConst.HintList.find((x) => x.code === item.callingInfoDTO.hint),
            O.fromNullable,
            O.map((item) => (i18n.language === 'en' ? item.nameEn : item.nameVi)),
            O.getOrElse(() => '')
          )
          const savedDate = item.savedDate ? OBFormat.formatDateToDateString(new Date(item.savedDate)) : ''
          const calledDate = item.calledDate ? OBFormat.formatDateToDateString(new Date(item.calledDate)) : ''
          const actionType = getActionType(
            item.callingInfoDTO.callingMethod || '',
            item.callingInfoDTO.phoneNumber || ''
          )
          const callingReceiver = pipe(
            (callReceiverList.length > 0 ? callReceiverList : callReceiverListData || []).find(
              (x) => x.code === item.callingInfoDTO.callReceiver
            ),
            O.fromNullable,
            O.map((item) => (i18n.language === 'en' ? item.nameEn : item.nameVi)),
            O.getOrElse(() => '')
          )
          const callingResult = pipe(
            (callingResultList.length > 0 ? callingResultList : callingResultListData || []).find(
              (x) => x.code === item.callingInfoDTO.callingResult
            ),
            O.fromNullable,
            O.map((item) => (i18n.language === 'en' ? item.nameEn : item.nameVi)),
            O.getOrElse(() => '')
          )
          const callbackTime = pipe(
            item.callingInfoDTO.callbackTime,
            O.fromNullable,
            O.map((callBackItem) => OBFormat.formatDateToDateString(new Date(callBackItem))),
            O.getOrElse(() => '')
          )
          const callIndicator = pipe(
            (doNotCallList.length > 0 ? doNotCallList : doNotCallListData || []).find(
              (p) => p.code === item.callingInfoDTO.callIndicator
            ),
            O.fromNullable,
            O.map((callIndicatorItem) =>
              i18n.language === 'en' ? callIndicatorItem.nameEn : callIndicatorItem.nameVi
            ),
            O.getOrElse(() => '')
          )
          return {
            STT: pageNum * pageSize + (index + 1),
            callId: pipe(
              item.callingInfoDTO.callId,
              O.fromNullable,
              O.map((item) => item),
              O.getOrElse(() => '')
            ),
            assignee: item.assignee || '',
            teamLeader: item.teamLeader || '',
            callProgram: item.transactionType || '',
            actionDate: calledDate,
            calledDate: item.calledDate || '',
            callingReceiver: callingReceiver,
            manualDuration: item.callingInfoDTO.callingDuration || '',
            callingResult: callingResult,
            callIndicator: callIndicator,
            callIndicatorStatus: item.callingInfoDTO.callIndicatorStatus || '',
            callbackTime: callbackTime,
            quickNote: item.callingInfoDTO.quickNote || '',
            suggestion: suggestionItem,
            saveDate: savedDate,
            actionType: actionType,
            communicationHistory: item.sendingFileInfoDTO.communicationHistory || '',
            originSaveDate: item.savedDate || ''
          }
        })
        return filterData
      }),
      ZIO.catchAll((err) => {
        return ZIO.fail(err)
      }),
      bindLoader,
      ErrorHandling.runDidUpdate([processInstanceId, isSavedCalloutPopupData, pageSize, pageNum])
    ) || []

  const onOpenCallDetailEvent = (actionItem: actionHistoryInfo) => {
    const startTime = pipe(
      actionItem.calledDate,
      O.fromNullable,
      O.fold(
        () => '',
        (item) => moment(item).utc().format('YYYY-MM-DDTHH:mm:ss')
      )
    )
    const endTime = pipe(
      actionItem.calledDate,
      O.fromNullable,
      O.fold(
        () => '',
        (item) => moment(item).add(6, 'hours').utc().format('YYYY-MM-DDTHH:mm:ss')
      )
    )
    // console.log('startTime:' + startTime)
    // console.log('endTime' + endTime)
    showGlobalLoading(true)
    pipe(
      OBCallOutPopupService.searchAvayaCall(actionItem.callId, startTime, endTime, processInstanceId),
      ZIO.flatMap((callingSearchArr) => {
        const resultCode = callingSearchArr && callingSearchArr.length > 0 ? callingSearchArr[0].resultCode : ''
        if (callingSearchArr && callingSearchArr.length > 0 && resultCode === '1') {
          const searchItem = callingSearchArr[0]
          return pipe(
            OBCallOutPopupService.replayAvayaCall(searchItem.interactionId, processInstanceId),
            ZIO.map((replayData) => {
              setCallDetailData({
                connectionStatus: `${t('message:OBConnected')}`,
                callDuration: String(searchItem.duration),
                avayaRecordLink: replayData?.recordLink || '',
                callId: actionItem.callId,
                errorMessage: ''
              })
              setIsOpenedCallDetailPopup(true)
            })
          )
        } else {
          setCallDetailData({
            connectionStatus: `${t('message:OBNotConnect')}`,
            callDuration: '',
            avayaRecordLink: '',
            callId: actionItem.callId,
            errorMessage: `${t('message:OB0054')}`
          })
          setIsOpenedCallDetailPopup(true)
        }
        return ZIO.unit
      }),
      ZIO.tap((_) => {
        showGlobalLoading(false)
        return ZIO.unit
      }),
      ZIO.tapError((_) => {
        setCallDetailData({
          connectionStatus: `${t('message:OBNotConnect')}`,
          callDuration: '',
          avayaRecordLink: '',
          callId: actionItem.callId,
          errorMessage: `${t('message:OB0054')}`
        })
        showGlobalLoading(false)
        return ZIO.unit
      }),
      ZIO.unsafeRun({})
    )
  }

  const onCloseCallDetailEvent = () => {
    setIsOpenedCallDetailPopup(false)
  }

  // const onPageChange = (pageNum: number, pageSize: number) => {
  //   loadOnGoingRequestList(pageSize, pageNum)
  // }

  // const onRowsPerPageChange = (pageNum: number, pageSize: number) => {
  //   loadOnGoingRequestList(pageSize, pageNum)
  // }

  return (
    <View style={actionStyles.container}>
      <Container loading={isLoading}>
        <OBGeneralTable
          displayedColumns={displayedColumns}
          dataSource={actionHistoryList as OBDataSource[]}
          isPagination={true}
          totalRecord={total}
          pageSize={pageSize}
          pageNum={pageNum}
          setPageNum={setPageNum}
          setPageSize={setPageSize}
          setTotalRecord={setTotal}
          rowsPerPageOptions={[10, 25, 50, 100]}
          // onPageChange={onPageChange}
          // onRowsPerPageChange={onRowsPerPageChange}
        ></OBGeneralTable>
      </Container>
      <OBAvayaCallDetailPopup
        isVisible={isOpenedCallDetailPopup}
        onPopupCloseEvent={onCloseCallDetailEvent}
        connectionStatus={callDetailData?.connectionStatus}
        callDuration={callDetailData?.callDuration}
        avayaRecordLink={callDetailData?.avayaRecordLink}
        callId={callDetailData?.callId}
        roles={roles}
        errorMessage={callDetailData?.errorMessage}
      ></OBAvayaCallDetailPopup>
    </View>
  )
}

const actionStyles = StyleSheet.create({
  container: {
    display: 'flex',
    // minHeight: '100%',
    backgroundColor: '#fff',
    paddingHorizontal: 24
  },
  hightLightText: {
    textDecorationLine: 'underline',
    color: 'rgb(30, 165, 252)'
  }
})
