import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import { ReportsStackParamList } from './ReportsStackParamList'
import { ReportsScreen } from './ReportsScreen'
import { SubmissionReportScreen } from './SubmissionReportScreen'
import { PolicyDataXMLScreen } from './PolicyDataXMLScreen'
import { PerformanceReportScreen } from './PerformanceReportScreen'
import { ReportManagementScreen } from './ReportManagementScreen'
import { PaymentReportScreen } from './PaymentReportScreen'
import { CheckingPayoutReportScreen } from './CheckingPayoutReport/CheckingPayoutReport'
import { CommunicationTrackingReportScreen } from './CommunicationTrackingReport/CommunicationTracking'

import { ErrorHandlerReportScreen } from './ErrorHandlerReport/ErrorHandlerReportScreen'
import { ClaimPayoutReportScreen } from './ClaimPayoutReportScreen'
import { pipe } from 'fp-ts/lib/function'
import { AuthState, ErrorHandling, GeneralService, subscribe } from '@pulseops/common'
import { ZIO } from '@mxt/zio'
import { ReportAutoDebitScreen } from './SeaAutoDebitMonthlyReport/SEAReportAutoDebitScreen'
import { AutoDebitRegisterReportScreen } from './SeaAutoDebitMonthlyRegisterReport'
const stack = createStackNavigator<ReportsStackParamList>()

export const ReportsStack = () => {
  const authData = pipe(AuthState.auth.watch, subscribe())
  const isShowSubmissionReport =
    pipe(
      authData !== null && authData.tag !== AuthState.Status.Unauthenticated
        ? GeneralService.getVisiableReportScreenStatus()
        : ZIO.succeed(true),
      ErrorHandling.runDidUpdate([authData])
    ) || false

  return (
    <stack.Navigator initialRouteName="ReportsScreen" screenOptions={{ headerShown: false }}>
      {isShowSubmissionReport ? (
        <>
          <stack.Screen name="ReportsScreen" component={ReportsScreen}></stack.Screen>
          <stack.Screen name="SubmissionReportScreen" component={SubmissionReportScreen}></stack.Screen>
          <stack.Screen name="PolicyDataXMLScreen" component={PolicyDataXMLScreen}></stack.Screen>
          <stack.Screen name="PerformanceReportScreen" component={PerformanceReportScreen}></stack.Screen>
          <stack.Screen name="PaymentReportScreen" component={PaymentReportScreen}></stack.Screen>
          <stack.Screen name="ReportManagementScreen" component={ReportManagementScreen}></stack.Screen>
          <stack.Screen name="ErrorHandlerReportScreen" component={ErrorHandlerReportScreen}></stack.Screen>
          <stack.Screen name="ClaimPayoutReportScreen" component={ClaimPayoutReportScreen}></stack.Screen>
          <stack.Screen
            name="CommunicationTrackingReportScreen"
            component={CommunicationTrackingReportScreen}
          ></stack.Screen>
          <stack.Screen name="CheckingPayoutReportScreen" component={CheckingPayoutReportScreen}></stack.Screen>
          <stack.Screen name="ReportAutoDebitScreen" component={ReportAutoDebitScreen}></stack.Screen>
          <stack.Screen name="AutoDebitRegisterReportScreen" component={AutoDebitRegisterReportScreen}></stack.Screen>
        </>
      ) : (
        <>
          <stack.Screen name="ReportsScreen" component={ReportsScreen}></stack.Screen>
          {/* <stack.Screen name="SubmissionReportScreen" component={SubmissionReportScreen}></stack.Screen> */}
          <stack.Screen name="PolicyDataXMLScreen" component={PolicyDataXMLScreen}></stack.Screen>
          <stack.Screen name="PerformanceReportScreen" component={PerformanceReportScreen}></stack.Screen>
          <stack.Screen name="PaymentReportScreen" component={PaymentReportScreen}></stack.Screen>
          <stack.Screen name="ReportManagementScreen" component={ReportManagementScreen}></stack.Screen>
          <stack.Screen name="ErrorHandlerReportScreen" component={ErrorHandlerReportScreen}></stack.Screen>
          <stack.Screen name="ClaimPayoutReportScreen" component={ClaimPayoutReportScreen}></stack.Screen>
          <stack.Screen
            name="CommunicationTrackingReportScreen"
            component={CommunicationTrackingReportScreen}
          ></stack.Screen>
          <stack.Screen name="CheckingPayoutReportScreen" component={CheckingPayoutReportScreen}></stack.Screen>
          <stack.Screen name="ReportAutoDebitScreen" component={ReportAutoDebitScreen}></stack.Screen>
          <stack.Screen name="AutoDebitRegisterReportScreen" component={AutoDebitRegisterReportScreen}></stack.Screen>
        </>
      )}
    </stack.Navigator>
  )
}
