import {
  AppContext,
  assets,
  AuthService,
  ErrorHandling,
  form2,
  Input,
  InternalFeedbackService,
  InternalConst,
  sharedStyle,
  SubmissionService,
  Title
} from '@pulseops/common'
import { FieldText, SectionCol, SectionContent, SectionRow } from '@pulseops/submission/common'
import { StackScreenProps } from '@react-navigation/stack'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, StyleSheet, Text, View, useWindowDimensions, TouchableOpacity } from 'react-native'
import { InternalFeedbackStackParamList } from './InternalFeedbackStackParamList'
import { InternalFeedbackForm } from './InternalFeedback.form'
import { Controller } from 'react-hook-form'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import moment from 'moment'
import { useLoading } from '@mxt/zio-react'
import {
  InternalHistoryContentModal,
  InternalHistoryFeedbackModal,
  InternalSuspendInformationConst
} from '@pulseops/task'
import { DisplayedColumn, InternalHistoryTable } from './InternalHistoryTable'
import { FontAwesome5 } from '@expo/vector-icons'

type Props = StackScreenProps<InternalFeedbackStackParamList, 'InternalFeedbackScreen'>

export const InternalFeedbackScreen = (props: Props) => {
  const { t, i18n } = useTranslation()
  const { height } = useWindowDimensions()
  const policyNum = props.route.params ? props.route.params?.policyNum ?? '' : ''
  const suspendID = props.route.params ? props.route.params?.suspendID ?? '' : ''
  // console.log('policyNum: ' + policyNum)
  // console.log('caseid: ' + suspendID)
  const {
    base: { control, setValue, watch, trigger, getValues, reset }
  } = form2.useForm(InternalFeedbackForm.codec, {
    defaultValues: {
      caseID: ''
    }
  })
  const [isLoading, bindLoader] = useLoading(false)
  const [isDisabledCase, setIsDisabledCase] = React.useState<boolean>(true)
  const [internalHistoryArr, setInternalHistoryArr] =
    React.useState<InternalFeedbackService.ResponseInternalSuspendDataList>([])
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [pageNum, setPageNum] = React.useState<number>(0)
  const [totalItem, setTotalItem] = React.useState<number>(0)
  const [isSubmitedFeedback, setIsSubmitedFeedback] = React.useState<boolean>(false)
  const { showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const [T31DepartmentArr, setT31DepartmentList] = React.useState<InternalFeedbackService.T31DepartmentList>([])

  const [isContentModalOpen, setIsContentModalOpen] = React.useState<boolean>(false)
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = React.useState<boolean>(false)
  const [feedbackDetail, setFeedbackDetail] = React.useState<InternalFeedbackService.InternalFeedbackDetail>()
  const [contentView, setContentView] = React.useState<string>('')

  const displayedColumns: Array<DisplayedColumn> = [
    { title: 'InternalSuspend:Department', name: 'department', minWidth: 220 },
    { title: 'InternalSuspend:Level', name: 'level', minWidth: 150 },
    { title: 'InternalSuspend:EffectiveTime', name: 'effectiveDate', minWidth: 200 },
    { title: 'InternalSuspend:ServiceLevelAgreement', name: 'slaHour', minWidth: 250 },
    { title: 'InternalSuspend:DueDate', name: 'dueDate', minWidth: 200 },
    { title: 'common:Status', name: 'status', minWidth: 200 },
    { title: 'SupplementaryInfo:CreatedUser', name: 'createdBy', minWidth: 200 },
    { title: 'common:CreatedDate', name: 'createdDate', minWidth: 200 },
    { title: 'SupplementaryInfo:UpdatedUser', name: 'modifiedBy', minWidth: 200 },
    { title: 'SupplementaryInfo:UpdatedDate', name: 'modifiedDate', minWidth: 200 },
    {
      title: 'InternalSuspend:Content',
      name: 'content',
      minWidth: 200,
      renderItem: (itemValue: string) => {
        return (
          <TouchableOpacity onPress={() => openSuspendContentModal(itemValue)}>
            <FontAwesome5 name="eye" color="#ED1B2E" />
          </TouchableOpacity>
        )
      }
    },
    {
      title: 'InternalSuspend:FeedbackContent',
      name: 'historyID',
      minWidth: 200,
      renderItem: (itemValue: string) => {
        return (
          <TouchableOpacity onPress={() => openFeedbackContentModal(itemValue)}>
            <FontAwesome5 name="eye" color="#ED1B2E" />
          </TouchableOpacity>
        )
      }
    }
  ]

  pipe(
    ZIO.zipPar(
      AuthService.userInfo,
      InternalFeedbackService.getDepartmentList(),
      InternalFeedbackService.getInternalSuspendDetail(suspendID),
      SubmissionService.getOwnerInfo(policyNum)
    ),
    ZIO.flatMap(([userInfo, T31DepartmentList, detailInfo, customerInfo]) => {
      const caseID = detailInfo.caseId ?? ''
      if (detailInfo && T31DepartmentList && T31DepartmentList.length > 0) {
        const dueDate = moment(detailInfo.dueDate).format('DD/MM/YYYY HH:mm')
        const departmentItem = T31DepartmentList.find((x) => x.departmentCode === detailInfo.department)
        const departmentName = i18n.language === 'en' ? departmentItem?.departmentEN : departmentItem?.departmentVN
        const policyOwner = customerInfo.body.name
        // setEditableRightForInternalFeedback(detailInfo, userInfo.email)
        setT31DepartmentList(T31DepartmentList)
        setValue('internalID', detailInfo.id)
        setValue('caseID', detailInfo.caseId ?? '')
        setValue('policyOwner', policyOwner)
        setValue('dueDate', dueDate)
        setValue('content', detailInfo.content)
        setValue('departmentCode', detailInfo.department)
        setValue('departmentName', departmentName ?? '')
        !!userInfo && setValue('userEmail', userInfo.email)
      }
      return pipe(
        ZIO.zipPar(
          InternalFeedbackService.checkSubmitedFeedback(caseID),
          InternalFeedbackService.getCaseStatusByCaseID(caseID),
          ZIO.fromPromise(() =>
            getInternalHistoryList(
              T31DepartmentList,
              caseID,
              pageNum,
              pageSize,
              caseID.startsWith('CH') ? detailInfo.department : ''
            )
          )
        ),
        ZIO.map(([response, caseStatus, _]) => {
          const isNoActive = detailInfo.status !== InternalConst.Status.ACTIVE || caseStatus !== 'Pending'
          setIsDisabledCase(isNoActive)
          isNoActive && showToast(t('message:MS080006'), 'error')
          !!response && setIsSubmitedFeedback(response.isFeedback)
          return response
        })
      )
    }),
    bindLoader,
    ErrorHandling.runDidUpdate([suspendID])
  )

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  // const setEditableRightForInternalFeedback = (
  //   suspendDetail: InternalFeedbackService.ResponseInternalSuspendData,
  //   userEmail: string
  // ) => {
  //   let emailList: string[] = []
  //   let toEmailList =
  //     suspendDetail.emailTo && suspendDetail.emailTo.indexOf('@')
  //       ? suspendDetail.emailTo.indexOf(';') > 0
  //         ? suspendDetail.emailTo.split(';')
  //         : [suspendDetail.emailTo]
  //       : []
  //   let ccEmailList =
  //     suspendDetail.emailCc && suspendDetail.emailCc.indexOf('@')
  //       ? suspendDetail.emailCc.indexOf(';') > 0
  //         ? suspendDetail.emailCc.split(';')
  //         : [suspendDetail.emailCc]
  //       : []
  //   toEmailList = toEmailList.map((toEmail) => toEmail.replace(/\s+/g, '').toLocaleLowerCase())
  //   ccEmailList = ccEmailList.map((ccEmail) => ccEmail.replace(/\s+/g, '').toLocaleLowerCase())
  //   emailList = [...toEmailList, ...ccEmailList]
  //   const isValidEmail = emailList.includes(userEmail.toLocaleLowerCase())
  //   setIsEditableRight(isValidEmail)
  // }

  const getInternalHistoryList = (
    departmentList: InternalFeedbackService.T31DepartmentList,
    caseID: string,
    pageNum: number,
    pageSize: number,
    departmentCode?: string
  ) => {
    return pipe(
      InternalFeedbackService.getInternalHistoryList(caseID, pageNum, pageSize, departmentCode),
      ZIO.map((historialList) => {
        const historyList =
          historialList && historialList.data.length > 0
            ? historialList.data.map((item) => {
                const departmentItem = departmentList.find((x) => x.departmentCode === item.department)
                const departmentName =
                  item.department +
                    '-' +
                    (i18n.language === 'en' ? departmentItem?.departmentEN : departmentItem?.departmentVN) ?? ''
                const statusItem =
                  InternalSuspendInformationConst.statusList().find((x) => x.value === item.status)?.label ?? ''
                const levelItem = InternalSuspendInformationConst.levelList.find((x) => x.code === item.level)
                const levelName = (i18n.language === 'en' ? levelItem?.description : levelItem?.descriptionVi) ?? ''
                return {
                  id: item.id,
                  suspendGroupCode: item.suspendGroupCode,
                  suspendGroupEN: item.suspendGroupEN,
                  suspendGroupVN: item.suspendGroupVN,
                  suspendCode: item.suspendCode,
                  suspendType: item.suspendType,
                  suspendTypeVN: item.suspendTypeVN,
                  slaHour: item.slaHour,
                  effectiveDate: item.effectiveDate,
                  dueDate: item.dueDate,
                  status: statusItem,
                  department: departmentName,
                  level: levelName,
                  content: item.content,
                  emailTo: item.emailTo,
                  emailCc: item.emailCc,
                  createdBy: item.createdBy,
                  createdDate: item.createdDate,
                  modifiedBy: item.modifiedBy,
                  modifiedDate: item.modifiedDate,
                  caseId: item.caseId
                }
              })
            : []
        setInternalHistoryArr(historyList)
        setPageSize(historialList.size)
        setTotalItem(historialList.total)
        setPageNum(historialList.page)
        return historyList
      }),
      bindLoader,
      ErrorHandling.run()
    )
  }

  const onHistoryPageChange = (pageNum: number, pageSize: number) => {
    getInternalHistoryList(
      T31DepartmentArr,
      watch('caseID') ?? '',
      pageNum,
      pageSize,
      (watch('caseID') ?? '').includes('CH') ? watch('departmentCode') ?? '' : ''
    )
  }

  const closeSuspendContentModal = () => {
    setIsContentModalOpen(false)
  }

  const closeFeedbackContentModal = () => {
    setIsFeedbackModalOpen(false)
  }

  const openSuspendContentModal = (content: string) => {
    setIsContentModalOpen(true)
    setContentView(content)
  }

  const openFeedbackContentModal = (internalSuspendID: string) => {
    pipe(
      InternalFeedbackService.getInternalFeedbackDetail(internalSuspendID),
      ZIO.map((responseData) => {
        if (!!responseData) {
          const feedbackDate = formatStringToDatetime(responseData.feedbackDate)
          const departmentItem = T31DepartmentArr.find((x) => x.departmentCode === responseData.feedbackDepartment)
          const departmentName =
            responseData.feedbackDepartment +
              '-' +
              (i18n.language === 'en' ? departmentItem?.departmentEN : departmentItem?.departmentVN) ?? ''
          setFeedbackDetail({
            ...responseData,
            feedbackDepartment: departmentName,
            feedbackDate: feedbackDate
          })
          setIsFeedbackModalOpen(true)
        }
      }),
      bindLoader,
      ErrorHandling.run()
    )
  }

  const mappingDataForFeedbackSubmit = () => {
    const submitedData: InternalFeedbackService.SubmitedInternalFeedback = {
      data: {
        parentId: watch('internalID') ?? '',
        feedbackDepartment: watch('departmentCode') ?? '',
        feedbackDetail: watch('feedbackDetail') ?? '',
        feedbackUser: watch('userEmail') ?? ''
      },
      processInstanceId: watch('caseID') ?? ''
    }
    return submitedData
  }

  const submitInternalFeedback = async () => {
    const isValid = await trigger()
    if (isValid) {
      const submitedData = mappingDataForFeedbackSubmit()
      pipe(
        InternalFeedbackService.submitInternalFeedback(submitedData),
        ZIO.map((responseData) => {
          if (!!responseData) {
            if (responseData.responseError.status === 'Success') {
              // clearAllEvent()
              showToast(t('message:MS080005'), 'success')
            } else {
              showToast(t(`message:${responseData.responseError.code}`), 'error')
            }
          }
          return responseData
        }),
        bindLoader,
        ZIO.unsafeRun({})
      )
    }
  }

  const clearAllEvent = () => {
    const formValue = getValues()
    reset({
      ...formValue,
      feedbackDetail: ''
    })
  }

  const formatStringToDatetime = (value: string) => {
    const newDateFormat = !!value ? moment(value).format('DD/MM/YYYY HH:mm') : ''
    return newDateFormat
  }

  return (
    <View
      style={{
        flex: 1,
        maxHeight: height,
        backgroundColor: '#fff'
      }}
    >
      <ScrollView style={internalFeedbackStyles.internalContainer}>
        <SectionRow sectionStyles={internalFeedbackStyles.bannerContainer}>
          <SectionCol sectionStyles={internalFeedbackStyles.bannerContent}>
            <assets.HeaderLogoWide />
          </SectionCol>
        </SectionRow>
        <View style={{ marginVertical: 30 }}>
          <SectionRow>
            <SectionCol sectionStyles={[internalFeedbackStyles.col_12]}>
              <View style={internalFeedbackStyles.headerContent}>
                <Text style={internalFeedbackStyles.headerText}>{t('InternalFeedback:InternalFeedback')}</Text>
              </View>
            </SectionCol>
          </SectionRow>
        </View>
        <View>
          <Title title={t('Tab:InternalSuspendHistory')}></Title>
          {/* internal history list */}
          <InternalHistoryTable
            displayedColumns={displayedColumns}
            dataSources={
              internalHistoryArr && internalHistoryArr.length > 0
                ? internalHistoryArr.map((item) => {
                    const effectiveDate = formatStringToDatetime(item.effectiveDate)
                    const dueDate = formatStringToDatetime(item.dueDate)
                    const createdDate = formatStringToDatetime(item.createdDate)
                    const modifiedDate = formatStringToDatetime(item.modifiedDate ?? '')
                    return {
                      department: item.department,
                      level: item.level,
                      effectiveDate: effectiveDate,
                      slaHour: item.slaHour,
                      dueDate: dueDate,
                      status: item.status,
                      createdBy: item.createdBy,
                      createdDate: createdDate,
                      modifiedBy: item.modifiedBy ?? '',
                      modifiedDate: modifiedDate,
                      content: item.content,
                      historyID: item.id
                    }
                  })
                : []
            }
            paginationData={{
              onPageChange: onHistoryPageChange,
              onRowsPerPageChange: onHistoryPageChange,
              setTotalItem: setTotalItem,
              totalItem: totalItem,
              setPageSize: setPageSize,
              pageSize: pageSize,
              setPageNum: setPageNum,
              pageNum: pageNum
            }}
          ></InternalHistoryTable>

          <Title wrapperStyle={internalFeedbackStyles.titleMargin} title={t('InternalFeedback:Information')}></Title>
          <SectionContent sectionStyles={internalFeedbackStyles.noMarginTop}>
            <SectionRow>
              <SectionCol sectionStyles={internalFeedbackStyles.col_7}>
                <Text>
                  <FieldText
                    fieldStyles={internalFeedbackStyles.field_description}
                    text={t('InternalFeedback:CaseID') + ':       '}
                  ></FieldText>
                  <FieldText text={watch('caseID') ?? ''}></FieldText>
                </Text>
              </SectionCol>
              <SectionCol sectionStyles={internalFeedbackStyles.col_5}>
                <Text>
                  <FieldText
                    fieldStyles={internalFeedbackStyles.field_description}
                    text={t('InternalFeedback:DueDate') + ':    '}
                  ></FieldText>
                  <FieldText text={watch('dueDate') ?? ''}></FieldText>
                </Text>
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={internalFeedbackStyles.secondLine}>
              <SectionCol sectionStyles={internalFeedbackStyles.col_12}>
                <Text>
                  <FieldText
                    fieldStyles={internalFeedbackStyles.field_description}
                    text={t('InternalFeedback:PolicyOwner') + ':    '}
                  ></FieldText>
                  <FieldText text={watch('policyOwner') ?? ''}></FieldText>
                </Text>
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={internalFeedbackStyles.secondLine}>
              <SectionCol sectionStyles={internalFeedbackStyles.col_12}>
                <FieldText
                  fieldStyles={internalFeedbackStyles.field_description}
                  text={t('InternalSuspend:Content')}
                ></FieldText>
                <Input
                  disabled={true}
                  maxLength={5000}
                  multiline
                  numberOfLines={5}
                  value={watch('content')}
                  alwayShowUnderline={true}
                />
              </SectionCol>
            </SectionRow>
          </SectionContent>
          <Title
            wrapperStyle={internalFeedbackStyles.titleMargin}
            title={t('InternalFeedback:InternalFeedback')}
          ></Title>
          <SectionContent
            sectionStyles={[
              internalFeedbackStyles.noMarginTop,
              isSubmitedFeedback && internalFeedbackStyles.marginBottomLine
            ]}
          >
            {isSubmitedFeedback ? (
              <View style={internalFeedbackStyles.submitedContent}>
                <assets.IsSubmitedIcon />
                <Text style={internalFeedbackStyles.submitedText}> {t('message:MS100012')}</Text>
              </View>
            ) : (
              <View>
                <SectionRow>
                  <SectionCol sectionStyles={internalFeedbackStyles.col_6}>
                    <Text>
                      <FieldText
                        fieldStyles={internalFeedbackStyles.field_description}
                        text={t('InternalFeedback:Department') + ':  '}
                      ></FieldText>
                      <FieldText text={watch('departmentName') ?? ''}></FieldText>
                    </Text>
                  </SectionCol>
                  <SectionCol sectionStyles={internalFeedbackStyles.col_6}>
                    <Text>
                      <FieldText
                        fieldStyles={internalFeedbackStyles.field_description}
                        text={t('InternalFeedback:Email') + ':  '}
                      ></FieldText>
                      <FieldText text={watch('userEmail') ?? ''}></FieldText>
                    </Text>
                  </SectionCol>
                </SectionRow>
                <SectionRow sectionStyles={internalFeedbackStyles.secondLine}>
                  <SectionCol sectionStyles={internalFeedbackStyles.col_12}>
                    <Controller
                      control={control}
                      name="feedbackDetail"
                      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                        <Input
                          title={t('InternalFeedback:FeedbackDetails')}
                          required
                          maxLength={5000}
                          multiline
                          numberOfLines={5}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value ?? ''}
                          errorMessage={!!value ? '' : error?.message}
                        />
                      )}
                    />
                  </SectionCol>
                </SectionRow>
              </View>
            )}
          </SectionContent>
          {isSubmitedFeedback ? (
            <></>
          ) : (
            <SectionRow>
              <SectionCol sectionStyles={internalFeedbackStyles.col_12}>
                <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
                  <TouchableOpacity
                    disabled={isDisabledCase}
                    style={[sharedStyle.button, { marginRight: 15, borderColor: '#ED1B2E' }]}
                    onPress={clearAllEvent}
                  >
                    <Text style={sharedStyle.textButton}>{t('submission:ClearAll')}</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    disabled={isDisabledCase}
                    style={[sharedStyle.button, sharedStyle.btnRed]}
                    onPress={submitInternalFeedback}
                  >
                    <Text style={sharedStyle.textButtonRed}>{t('submission:Submit')}</Text>
                  </TouchableOpacity>
                </View>
              </SectionCol>
            </SectionRow>
          )}
        </View>

        <InternalHistoryContentModal
          title={t('InternalSuspend:Content')}
          open={isContentModalOpen}
          onClose={closeSuspendContentModal}
          content={contentView}
        />

        <InternalHistoryFeedbackModal
          title={t('InternalFeedback:FEEDBACKCONTENT')}
          open={isFeedbackModalOpen}
          onClose={closeFeedbackContentModal}
          feedbackDetail={feedbackDetail}
        />
      </ScrollView>
    </View>
  )
}
const internalFeedbackStyles = StyleSheet.create({
  bannerContainer: {
    height: 68,
    shadowColor: 'rgba(0, 0, 0, 0.05)',
    shadowOffset: { width: 0, height: 26 },
    shadowOpacity: 0.5,
    shadowRadius: 30
  },
  bannerContent: {
    paddingLeft: 30,
    paddingTop: 12,
    paddingBottom: 13
  },
  internalContainer: {
    paddingLeft: 15,
    paddingRight: 15
  },
  col_2: {
    display: 'flex',
    width: '100%',
    maxWidth: '16.666666667%',
    paddingLeft: 15,
    paddingRight: 15
  },
  col_5: {
    display: 'flex',
    width: '100%',
    maxWidth: '41.666666667%',
    paddingLeft: 15,
    paddingRight: 15
  },
  col_6: {
    display: 'flex',
    width: '100%',
    maxWidth: '50%',
    paddingLeft: 15,
    paddingRight: 15
  },
  col_7: {
    display: 'flex',
    width: '100%',
    maxWidth: '58.333333333%',
    paddingLeft: 15,
    paddingRight: 15
  },
  col_10: {
    display: 'flex',
    width: '100%',
    maxWidth: '83.333333333%',
    paddingLeft: 15,
    paddingRight: 15
  },
  col_12: {
    display: 'flex',
    width: '100%',
    maxWidth: '100%',
    paddingLeft: 15,
    paddingRight: 15
  },
  headerContent: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  headerText: {
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 32,
    lineHeight: 44,
    color: '#000000'
  },
  secondLine: {
    marginTop: 20
  },
  titleMargin: {
    marginTop: 30
  },
  noMarginTop: {
    marginTop: 0
  },
  marginBottomLine: {
    marginBottom: 80
  },
  field_description: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 15,
    lineHeight: 22,
    color: '#000000'
  },
  feedbackContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  unAuthorText: {
    fontSize: 20,
    lineHeight: 30,
    fontWeight: '400',
    marginTop: 35,
    color: '#4F4F4F'
  },
  unAuthorContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 90
  },
  submitedContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingVertical: 45
  },
  submitedText: {
    fontWeight: '400',
    fontSize: 18,
    lineHeight: 22,
    color: '#000000'
  }
})
