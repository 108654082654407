import {
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core'
import {
  AppContext,
  Input,
  Select,
  sharedStyle,
  Title,
  useMobile,
  ErrorHandling,
  UnderwrittingService,
  SubmissionService,
  TransactionType,
  TaskType,
  SubTaskType,
  TaskDetail,
  SupplementaryInfoService,
  mapTransactionType,
  TopUpService,
  ChangeClientInfoService
} from '@pulseops/common'
import React from 'react'
import { StyleSheet, TouchableOpacity, Text, View } from 'react-native'
import { useIsFocused } from '@react-navigation/native'
// import { UnderwrittingConst } from './Underwritting.const'
import { useTranslation } from 'react-i18next'
import { NoteDetail, UnderwrittingNotesModal } from './UnderwrittingNotesModal'
import { pipe } from 'fp-ts/lib/function'
import { Throwable, ZIO } from '@mxt/zio'
import { Controller, FieldArrayWithId, useFieldArray, useForm, useWatch } from 'react-hook-form'
import { UnderwrittingForm } from './UnderwrittingForm'
import { useLoading } from '@mxt/zio-react'
import { CompletePopup, SuspendPopup, TransferPopup } from '../../actions'
import * as O from 'fp-ts/Option'
import moment from 'moment'

export interface UnderwrittingScreenProps {
  veriPayloadData: TaskDetail.Type | null
  taskId: string
  processInstanceID: string
  transactionType: string
  policyNum: string
  caseId: string
  category: string
  basket: SubTaskType
  updateToCore: boolean
  disabled: boolean
  isSupActive: boolean
  UWDecisionTypeArr: UnderwrittingService.UWDecisionInfo[]
  onHandleActionSuccess?: (mustRefreshTaskGroup?: boolean) => void
}

export interface MainProductData {
  productType: string
  riderHCR: string
  occupation: string
  dob: string
}

export interface UWClientInfo {
  processInstanceId: string
  userRole: string
  clientNumber: string
  clientName: string
  transactionType: string
  uwDecision: string
  suspendContent: string
  caseId: string | null | undefined
}

export const UnderwrittingScreen = (props: UnderwrittingScreenProps) => {
  const { t } = useTranslation()
  const { isWide } = useMobile()
  const { getFooter } = React.useContext(AppContext.AppContextInstance)
  const isFocused = useIsFocused()
  const [isLoading, bindLoader] = useLoading(false)
  const [isShowedNotesModal, setIsShowedNotesModal] = React.useState<boolean>(false)
  // const [UWDecisionList, setUWDecisionList] = React.useState<UnderwrittingService.UWDecisionInfo[]>([])
  const [detailNote, setDetailNote] = React.useState<NoteDetail>()
  const { showGlobalLoading, showToast } = React.useContext(AppContext.AppContextInstance)
  const [completeOpen, setCompleteOpen] = React.useState<boolean>(false)
  const [isTransferPopupOpen, setIsTransferPopupOpen] = React.useState<boolean>(false)
  const [isSuspendPopupOpen, setIsSuspendPopupOpen] = React.useState<boolean>(false)
  const [finalDecision, setFinalDecision] = React.useState<string>('')
  const [nextStep, setNextStep] = React.useState<string>('')
  const [planSuspendDate, setPlanSuspendDate] = React.useState<Date>()
  const UWDecisionTypeArr = props.UWDecisionTypeArr
  const underwrittingForm = useForm<UnderwrittingForm.UnderwrittingFormInfo & { isUpdatedDecision: boolean }>({
    defaultValues: {
      UWDecisionList: [],
      isUpdatedDecision: false
    }
  })

  const { fields, append } = useFieldArray({
    control: underwrittingForm.control,
    name: 'UWDecisionList'
  })

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  React.useEffect(() => {
    if (isFocused) {
      setButtonsToFooter()
    }
    return () => {
      getFooter(<></>)
    }
  }, [isFocused])

  const setButtonsToFooter = () => {
    props.disabled
      ? getFooter(<></>)
      : getFooter(
        <View
          style={[sharedStyle.footer, isWide ? { marginRight: 19 } : { width: '100%', justifyContent: 'center' }]}
        >
          {props.isSupActive ? (
            <TouchableOpacity
              style={[sharedStyle.button, { marginRight: 15, borderColor: '#ED1B2E' }]}
              onPress={async () => await onOpenSuspendPopup(props.processInstanceID)}
            >
              <Text style={sharedStyle.textButton}>{t('claim:Suspend')}</Text>
            </TouchableOpacity>
          ) : (
            <TouchableOpacity
              style={[sharedStyle.button, { marginRight: 15, borderColor: '#ED1B2E' }]}
              onPress={() => openCompletePopUp(initialData)}
            >
              <Text style={sharedStyle.textButton}>{t('claim:Complete')}</Text>
            </TouchableOpacity>
          )}
          <TouchableOpacity
            style={[sharedStyle.button, { marginRight: 15, borderColor: '#ED1B2E' }]}
            onPress={() => setIsTransferPopupOpen(true)}
          >
            <Text style={sharedStyle.textButton}>{t('claim:Transfer')}</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[sharedStyle.button, { marginRight: 15, borderColor: '#ED1B2E' }]}
            onPress={() => (underwrittingForm.watch('isUpdatedDecision') ? updateDecision() : saveDecision())}
          >
            <Text style={sharedStyle.textButton}>{t('common:Save')}</Text>
          </TouchableOpacity>
        </View>
      )
  }

  const getPolicyInfo = (policyNum: string) => {
    return pipe(
      SubmissionService.getPolicy(policyNum),
      ZIO.flatMap((policyInfo) =>
        pipe(
          policyInfo.body.owners.id,
          O.fromNullable,
          O.filter((id) => id.length > 0),
          O.fold(
            () => ZIO.succeed({ occupation: '', dob: '', basicCode: '' }),
            (clientId) =>
              pipe(
                SubmissionService.getCustomer(clientId),
                ZIO.map((customerInfo) => {
                  return {
                    occupation: customerInfo.body.occupation,
                    dob: customerInfo.body.dob,
                    basicCode: policyInfo.body.basicCode ?? ''
                  }
                })
              )
          )
        )
      )
    )
  }

  const onOpenSuspendPopup = (processInstanceId: string) => {
    return pipe(
      SupplementaryInfoService.checkExpireDate(processInstanceId),
      ZIO.catchAll(() => {
        setPlanSuspendDate(undefined)
        return ZIO.fail(null)
      }),
      ZIO.tap(({ expiredDate }) => {
        setIsSuspendPopupOpen(true)
        setPlanSuspendDate(moment(expiredDate, 'DD/MM/yyyy HH:mm:ss').toDate())
        return ZIO.unit
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }

  const getFilterClients = (UWClientList: UWClientInfo[], policyNum: string) => {
    let filteredClients: Array<UnderwrittingService.SaveDecisionData & { isSubmitedEdit: boolean }> = []
    switch (props.veriPayloadData?.payload.tag) {
      case TransactionType.GO_ABROAD:
        const abroadPayload = props.veriPayloadData.payload as TaskDetail.GoAbroad
        return pipe(
          ZIO.succeed(abroadPayload),
          ZIO.map((abroadPayloadInfo) => {
            filteredClients = UWClientList.map((clientItem) => {
              const isSubmitedEdit = pipe(
                O.fromNullable(abroadPayloadInfo.goAbroadClients.some((x) => x.clientCode === clientItem.clientNumber)),
                O.fold(
                  () => false,
                  (isChangedLA) => isChangedLA
                )
              )
              return {
                ...clientItem,
                isSubmitedEdit: isSubmitedEdit
              }
            })
            return filteredClients
          })
        )
      case TransactionType.CHANGE_DOB_GENDER:
        const changeBODData = props.veriPayloadData.payload as TaskDetail.ChangeDOBGender
        return pipe(
          ZIO.succeed(changeBODData),
          ZIO.map((changeBODDataInfo) => {
            filteredClients = UWClientList.map((UWClientItem) => {
              const isSubmitedEdit = pipe(
                O.fromNullable(changeBODDataInfo.data.clientNum === UWClientItem.clientNumber),
                O.fold(
                  () => false,
                  (isChangedLA) => isChangedLA
                )
              )
              return {
                ...UWClientItem,
                isSubmitedEdit: isSubmitedEdit
              }
            })
            return filteredClients
          })
        )
      case TransactionType.CHANGE_OCCUPATION_PERSONAL:
        const changeOccupationData = props.veriPayloadData.payload as TaskDetail.ChangeOccupationPersonal
        return pipe(
          ZIO.succeed(changeOccupationData),
          ZIO.map((changeBODDataInfo) => {
            filteredClients = UWClientList.map((UWClientItem) => {
              const isSubmitedEdit = pipe(
                O.fromNullable(changeBODDataInfo.clientNum === UWClientItem.clientNumber),
                O.fold(
                  () => false,
                  (isChangedLA) => isChangedLA
                )
              )
              return {
                ...UWClientItem,
                isSubmitedEdit: isSubmitedEdit
              }
            })
            return filteredClients
          })
        )
      case TransactionType.CHANGE_CLIENT_INFO:
        const clientPayload = props.veriPayloadData.payload as TaskDetail.ChangeClientInfo
        return pipe(
          ZIO.succeed(clientPayload),
          ZIO.map((clientPayloadInfo) => {
            filteredClients = UWClientList.map((clientItem) => {
              const isSubmitedEdit = pipe(
                O.fromNullable(clientPayloadInfo.clientNumber === clientItem.clientNumber),
                O.fold(
                  () => false,
                  (isChangedLA) => isChangedLA
                )
              )
              return {
                ...clientItem,
                isSubmitedEdit: isSubmitedEdit
              }
            })
            return filteredClients
          })
        )
      case TransactionType.RIDER_REINSTATEMENT:
        return pipe(
          ZIO.effect(() => {
            const payloadData = props.veriPayloadData?.payload as TaskDetail.VERIRiderRein
            filteredClients = UWClientList.map((clientItem) => {
              const isSubmitedEdit = pipe(
                O.fromNullable(
                  payloadData.submissionData.lifeAssuredList.some((item) => item.clientNo === clientItem.clientNumber)
                ),
                O.fold(
                  () => false,
                  (isSubmissionLA) => isSubmissionLA
                )
              )
              return {
                ...clientItem,
                isSubmitedEdit: isSubmitedEdit
              }
            })
            return filteredClients
          })
        )
      case TransactionType.PRODUCT_OPTION_SWITCHING:
      case TransactionType.CHANGE_SUM_ASSURED:
        return pipe(
          SubmissionService.getPolicy(props.policyNum),
          ZIO.map((responseData) => {
            const mainLifeAssuredNumber = responseData.body.mainLifeAssuredNumber
            filteredClients = UWClientList.map((item) => {
              return { ...item, isSubmitedEdit: item.clientNumber === mainLifeAssuredNumber }
            })
            return filteredClients
          })
        )
      case TransactionType.RIDER_ALTERATION:
        return pipe(
          ZIO.effect(() => {
            const payload = props.veriPayloadData?.payload as TaskDetail.VERIRiderAlteration
            filteredClients = UWClientList.map((clientItem) => {
              const isNewLA =
                clientItem.clientNumber.length === 11
                  ? ['01', '02', '03', '04'].includes(clientItem.clientNumber.split('-')[1])
                  : false
              // check life assureds are value updated or not to allow to edit in UW list screen for RIDER_ALTERATION
              const editedLA = pipe(
                payload.lifeAssureds.find((x) => x.lifeAssured === clientItem.clientNumber),
                O.fromNullable,
                O.fold(
                  () =>
                    pipe(
                      payload.lifeAssuredAdditions.find((p) => p.lifeAssured.clientNumber === clientItem.clientNumber),
                      O.fromNullable,
                      O.map((newLA) => newLA.lifeAssured && newLA.lifeAssured.riderListAdditional.length > 0),
                      O.getOrElse(() => (isNewLA ? true : false))
                    ),
                  (lifeItem) => {
                    //checking users change sumAssured or not, if users change sumAssured and checked value is true
                    const isEditedOldRider = lifeItem.coreRiders.some(
                      (x) => x.sumAssured !== Number(x.alteredSumAssured) && Number(x.alteredSumAssured) > 0
                    )
                    ///checking users add new rider or not, if users add new rider and checked value is true
                    const isEditeNewRider = pipe(
                      payload.lifeAssuredAdditions.find((p) => p.lifeAssured.clientNumber === lifeItem.lifeAssured),
                      O.fromNullable,
                      O.map((newLA) => newLA.lifeAssured && newLA.lifeAssured.riderListAdditional.length > 0),
                      O.getOrElse(() => false)
                    )
                    return isEditedOldRider || isEditeNewRider
                  }
                )
              )
              // console.log('editedLA:' + editedLA)
              return {
                ...clientItem,
                isSubmitedEdit: editedLA,
                isNewLA: isNewLA
              }
            })
            if (UWClientList.some((clientItem) => !clientItem.processInstanceId && !clientItem.caseId)) {
              payload.lifeAssuredAdditions.map((newLA) => {
                if (newLA.lifeAssured.isNewLA) {
                  const newLifeAssure = {
                    processInstanceId: '',
                    userRole: getRole('LifeAssured'),
                    clientNumber: policyNum + '-' + newLA.lifeAssured.lifeNo,
                    clientName: newLA.lifeAssured.fullName,
                    transactionType: props.transactionType,
                    uwDecision: '',
                    suspendContent: '',
                    caseId: '',
                    isSubmitedEdit: true,
                    isNewLA: true
                  }
                  filteredClients.push(newLifeAssure)
                }
              })
            }
            return filteredClients
          })
        )
      case TransactionType.MAJOR_COMBINED_CHANGE:
        return pipe(
          ZIO.effect(() => {
            const payloadInfo = props.veriPayloadData?.payload as TaskDetail.VERIMajorCombined
            filteredClients = pipe(
              O.fromNullable(payloadInfo.submissionData.riderAlteration),
              O.fold(
                () =>
                  UWClientList.map((item) => {
                    const isNewLAItem =
                      item.clientNumber.length === 11
                        ? ['01', '02', '03', '04'].includes(item.clientNumber.split('-')[1])
                        : false
                    return {
                      ...item,
                      isSubmitedEdit: true,
                      isNewLA: isNewLAItem
                    }
                  }),
                (riderAlterationData) => {
                  let UWLifeAssuredArr: Array<UnderwrittingService.SaveDecisionData & { isSubmitedEdit: boolean }> = []
                  if (UWClientList.some((clientItem) => !clientItem.processInstanceId && !clientItem.caseId)) {
                    UWLifeAssuredArr = UWClientList.map((item) => ({ ...item, isSubmitedEdit: true }))
                    riderAlterationData.lifeAssuredAdditions.map((newLA) => {
                      if (newLA.lifeAssured.isNewLA) {
                        const newLifeAssure = {
                          processInstanceId: '',
                          userRole: getRole('LifeAssured'),
                          clientNumber: policyNum + '-' + newLA.lifeAssured.lifeNo,
                          clientName: newLA.lifeAssured.fullName,
                          transactionType: props.transactionType,
                          uwDecision: '',
                          suspendContent: '',
                          caseId: '',
                          isSubmitedEdit: true,
                          isNewLA: true
                        }
                        UWLifeAssuredArr.push(newLifeAssure)
                      }
                    })
                  } else {
                    UWLifeAssuredArr = UWClientList.map((item) => {
                      const isNewLAItem =
                        item.clientNumber.length === 11
                          ? ['01', '02', '03', '04'].includes(item.clientNumber.split('-')[1])
                          : false
                      return {
                        ...item,
                        isSubmitedEdit: true,
                        isNewLA: isNewLAItem
                      }
                    })
                  }
                  return UWLifeAssuredArr
                }
              )
            )
            return filteredClients
          })
        )
      default:
        return ZIO.succeed(UWClientList.map((item) => ({ ...item, isSubmitedEdit: true })))
    }
  }

  const initialData = pipe(
    ZIO.zipPar(
      getPolicyInfo(props.policyNum),
      pipe(
        UnderwrittingService.getUWClientDecisionList(props.processInstanceID),
        ZIO.flatMap((decisionData) => {
          return decisionData && decisionData.length > 0
            ? ZIO.effect(() => {
              underwrittingForm.setValue('isUpdatedDecision', true)
              const decisionList = decisionData.map((item) => {
                // const transactionType = t(mapTransactionType.get(item.transactionType as TransactionType) ?? '-')
                return {
                  processInstanceId: item.processInstanceId,
                  userRole: item.userRole ?? '',
                  clientNumber: item.clientNumber,
                  clientName: item.clientName,
                  transactionType: item.transactionType,
                  uwDecision: item.uwDecision,
                  suspendContent: item.suspendContent,
                  caseId: item.caseId
                }
              })
              return decisionList
            })
            : pipe(
              UnderwrittingService.getLifeAssuredForUWList(props.policyNum),
              ZIO.map((LAInfoList) => {
                const customerData =
                  // if policy has only role(PolicyOwner), we update role of it to become LifeAssured
                  LAInfoList.customerData && LAInfoList.customerData.length <= 1
                    ? LAInfoList.customerData.map((x) => ({ ...x, role: 'LifeAssured' }))
                    : LAInfoList.customerData
                const dataList = customerData.map((item) => {
                  const clientName = `${item.surName ?? ''} ${item.name}`
                  return {
                    processInstanceId: '',
                    userRole: getRole(item.role),
                    clientNumber: item.customerId,
                    clientName: clientName,
                    transactionType: props.transactionType,
                    uwDecision: '',
                    suspendContent: '',
                    caseId: ''
                  }
                })
                return dataList
              })
            )
        })
      )
    ),
    ZIO.flatMap(([policyInfo, UWClientList]) => {
      return pipe(
        ZIO.zipPar(ZIO.succeed(policyInfo), getFilterClients(UWClientList, props.policyNum)),
        ZIO.flatMap(([policyData, UWClientArr]) => {
          const updateToCore = !!props.veriPayloadData ? (props.veriPayloadData?.updateToCore ? 'Y' : 'N') : ''
          const productType =
            !policyInfo.basicCode.startsWith('V') && !policyInfo.basicCode.startsWith('U')
              ? 'Traditional'
              : policyInfo.basicCode.startsWith('U')
                ? 'ILP'
                : policyInfo.basicCode.startsWith('V')
                  ? 'ULP'
                  : ''
          return [TransactionType.REINSTATEMENT, TransactionType.RIDER_REINSTATEMENT].includes(
            props.transactionType as TransactionType
          )
            ? pipe(
              TopUpService.GetPolicyExtraInfo([props.policyNum], ''),
              ZIO.flatMap((policyExtra) => {
                const riderProductArr = policyExtra.policyExtraInfoDetail
                const isRiderHCR = riderProductArr
                  .filter((x) => x.lifeNo !== '01' || x.coverageNo !== '01' || x.riderNo !== '00')
                  .some((item) =>
                    // item.productCode.startsWith('V') ||
                    // item.productCode.startsWith('U') ||
                    item.productCode.startsWith('HCR')
                  )
                const riderHCR = isRiderHCR ? 'Y' : 'N'
                return ZIO.zipPar(
                  ZIO.succeed({
                    ...policyData,
                    productType: productType,
                    riderHCR: riderHCR,
                    updateToCore: updateToCore
                  }),
                  ZIO.succeed(UWClientArr)
                )
              })
            )
            : pipe(
              ZIO.zipPar(
                ZIO.succeed({ ...policyData, productType: productType, riderHCR: 'N', updateToCore: updateToCore }),
                ZIO.succeed(UWClientArr)
              )
            )
        })
      )
    }),
    ZIO.map(([policyInfo, UWClientList]) => {
      for (let i = 0; i < UWClientList.length; i++) {
        addUWDecisionItem(i, UWClientList[i], UWDecisionTypeArr)
      }
      return policyInfo
    }),
    ErrorHandling.runDidUpdate([props.processInstanceID])
  )

  const UWDecisionList = useWatch({ control: underwrittingForm.control, name: 'UWDecisionList' })

  const isChangeData = React.useMemo(() => {
    if (!!UWDecisionList && UWDecisionList.length > 0 && underwrittingForm.watch('isUpdatedDecision')) {
      return UWDecisionList.some((x) => !!x.UWDecision && x.UWDecision.value !== x.oldUWDecision)
    } else {
      return false
    }
  }, [UWDecisionList])

  console.log('isChangeData', isChangeData)
  console.log('UWDecisionList', UWDecisionList)

  React.useEffect(() => {
    if (initialData && !!initialData.riderHCR) {
      setButtonsToFooter()
    }
  }, [initialData, isChangeData])

  const addUWDecisionItem = (
    index: number,
    item: UnderwrittingService.SaveDecisionData & { isSubmitedEdit: boolean; isNewLA?: boolean },
    decisionList: UnderwrittingService.UWDecisionInfo[]
  ) => {
    const UWDecision = getOptionArrayByList(decisionList).find((x) => x.value === item.uwDecision)
    append({
      role: item.userRole ?? '',
      clientNumber: item.clientNumber,
      clientName: item.clientName,
      transactionType: item.transactionType,
      UWDecision: UWDecision,
      oldUWDecision: UWDecision?.value ?? '',
      suspendContent: item.suspendContent,
      historyNotes: [],
      isSubmitedEdit: item.isSubmitedEdit,
      isNewLA: item.isNewLA
    })
    underwrittingForm.setValue(`UWDecisionList.${index}.suspendContent`, item.suspendContent)
    !!UWDecision && underwrittingForm.setValue(`UWDecisionList.${index}.UWDecision`, UWDecision)
  }

  const removeSpaceInText = (value: string) => value.replace(/\s+/g, '').trim()

  const getHighestUWDecision = () => {
    const UWDecisions = underwrittingForm.watch('UWDecisionList')
    const finalUWLevel = UWDecisions.reduce((topUWLevel, item) => {
      const UWPriority =
        item && item.UWDecision
          ? UWDecisionTypeArr.find((x) => x.codeUWDecision === item.UWDecision.value)?.priority ?? -1
          : -1
      if (topUWLevel === 0 || topUWLevel < UWPriority) {
        return UWPriority
      } else {
        return topUWLevel
      }
    }, 0)
    const decisionItem = UWDecisions.some((p) => p.isSubmitedEdit)
      ? UWDecisionTypeArr.find((x) => x.priority === finalUWLevel)
      : UWDecisionTypeArr.find((p) => p.codeUWDecision === 'DC-ACCEPT-AT-EXISTING')

    const finalDecision = ['DC-ACCEPT-AT-EXISTING', 'DC-ME/BSTT', 'DC-TRANSFER'].includes(
      decisionItem?.codeUWDecision ?? ''
    )
      ? removeSpaceInText(decisionItem?.codeUWDecision ?? '')
      : 'OTHERS'
    return finalDecision
  }

  const openCompletePopUp = (
    data: { productType: string; riderHCR: string; occupation: string; dob: string; updateToCore: string } | null
  ) => {
    if (!isChangeData && underwrittingForm.watch('isUpdatedDecision')) {
      pipe(
        ZIO.succeed(props.veriPayloadData?.payload),
        ZIO.flatMap((detailPayload) => {
          switch (detailPayload?.tag) {
            case TransactionType.CHANGE_CLIENT_INFO: {
              const changeClientDetail: TaskDetail.ChangeClientInfo = detailPayload
              const newOccupationCode = changeClientDetail.occupation?.occupationCode ?? ''
              const oldClientNumber = changeClientDetail.clientNumber
              if (changeClientDetail.occupation?.isChangeOccupation) {
                return pipe(
                  SubmissionService.getCustomer(oldClientNumber),
                  ZIO.flatMap((customerInfo) =>
                    ZIO.zipPar(
                      ChangeClientInfoService.getOccupationItemByCode(customerInfo.body.occupation),
                      ChangeClientInfoService.getOccupationItemByCode(newOccupationCode)
                    )
                  ),
                  ZIO.map(([oldOccupationItem, newOccupationItem]) => {
                    const otherCondition =
                      oldOccupationItem?.classOccupation === newOccupationItem?.classOccupation
                        ? 'CHANGE_OCCUPATION_SAME_CLASS'
                        : 'CHANGE_OCCUPATION_DIFFERENT_CLASS'
                    return { otherCondition }
                  })
                )
              } else if (changeClientDetail.dob !== moment(data?.dob).format('DD/MM/YYYY')) {
                return ZIO.succeed({ otherCondition: 'CHANGE_DOB' })
              } else {
                return ZIO.succeed({ otherCondition: '-' })
              }
            }
            case TransactionType.CHANGE_OCCUPATION_PERSONAL: {
              return pipe(
                O.fromNullable(detailPayload.occupation.occupationDetail?.code),
                O.fold(
                  () => ZIO.succeed({ otherCondition: '-' }),
                  (newOccupationCode) => pipe(
                    ZIO.succeed(detailPayload.clientNum),
                    ZIO.flatMap((clientID) => SubmissionService.getCustomer(clientID)),
                    ZIO.flatMap((customerInfo) => ZIO.zipPar(
                      ChangeClientInfoService.getOccupationItemByCode(customerInfo.body.occupation),
                      ChangeClientInfoService.getOccupationItemByCode(newOccupationCode)
                    )),
                    ZIO.map(([oldOccupationItem, newOccupationItem]) => {
                      const otherCondition = oldOccupationItem && Object.keys(oldOccupationItem).length > 0 && newOccupationItem && Object.keys(newOccupationItem).length > 0
                        && (oldOccupationItem as ChangeClientInfoService.OccupationItem)?.classOccupation === (newOccupationItem as ChangeClientInfoService.OccupationItem)?.classOccupation
                        ? 'CHANGE_OCCUPATION_SAME_CLASS'
                        : 'CHANGE_OCCUPATION_DIFFERENT_CLASS'
                      return { otherCondition }
                    })
                  )
                )
              )
            }
            default: {
              return ZIO.succeed({ otherCondition: '-' })
            }
          }
        }),
        ZIO.map((conditionData) => {
          let productType = '-',
            riderHCR = '-',
            updateToCoreVal = ''
          const topLevelDecision = getHighestUWDecision()
          const conditionOther = topLevelDecision === 'DC-ACCEPT-AT-EXISTING' ? conditionData.otherCondition : '-'
          switch (props.transactionType) {
            case TransactionType.REINSTATEMENT: {
              productType =
                props.transactionType === TransactionType.REINSTATEMENT && topLevelDecision === 'DC-ACCEPT-AT-EXISTING'
                  ? data?.productType ?? ''
                  : '-'
              riderHCR =
                TransactionType.REINSTATEMENT === props.transactionType &&
                  topLevelDecision === 'DC-ACCEPT-AT-EXISTING' &&
                  !['ILP', 'ULP'].includes(productType)
                  ? data?.riderHCR ?? ''
                  : '-'
              updateToCoreVal = productType === 'Traditional' && riderHCR === 'N' ? data?.updateToCore ?? '' : '-'
              break
            }
            case TransactionType.RIDER_REINSTATEMENT: {
              riderHCR = topLevelDecision === 'DC-ACCEPT-AT-EXISTING' ? data?.riderHCR ?? '' : '-'
              updateToCoreVal = riderHCR === 'N' ? data?.updateToCore ?? '' : '-'
              break
            }
            case TransactionType.CHANGE_OCCUPATION_PERSONAL:
            case TransactionType.CHANGE_CLIENT_INFO: {
              updateToCoreVal =
                topLevelDecision === 'DC-ACCEPT-AT-EXISTING' &&
                  ['CHANGE_OCCUPATION_SAME_CLASS'].includes(conditionOther)
                  ? data?.updateToCore ?? ''
                  : '-'
              break
            }
            case TransactionType.GO_ABROAD:
            case TransactionType.CHANGE_PREMIUM:
            case TransactionType.CHANGE_SUM_ASSURED:
            case TransactionType.PRODUCT_OPTION_SWITCHING:
            case TransactionType.FULL_SURRENDER_REVERSAL:
            case TransactionType.REDATING:
            case TransactionType.CHANGE_DOB_GENDER:
            case TransactionType.HEALTH_DECLARATION: {
              updateToCoreVal = topLevelDecision === 'DC-ACCEPT-AT-EXISTING' ? data?.updateToCore ?? '' : '-'
              break
            }
            default: {
              productType = '-'
              riderHCR = '-'
              break
            }
          }
          const decisionData: UnderwrittingService.T82InputData = {
            transactionTypeWF: props.transactionType,
            processDecision: 'Complete',
            uwDecision: topLevelDecision,
            productType: productType,
            riderHCR: riderHCR,
            conditionOther: conditionOther,
            updateToCore: updateToCoreVal
          }
          setFinalDecision(topLevelDecision)
          return decisionData
        }),
        ZIO.flatMap((decisionData) => UnderwrittingService.getNextStepFromT82(decisionData)),
        ZIO.map((nextStepResponse) => {
          if (nextStepResponse === 'MESSAGE') {
            showToast(t('message:MS020023'), 'warning')
          } else {
            setNextStep(nextStepResponse)
            setCompleteOpen(true)
          }
          return nextStepResponse
        }),
        ZIO.catchAll((error) => {
          showToast(t('message:MS050001'), 'error')
          return ZIO.fail(error)
        }),
        bindLoader,
        ZIO.unsafeRun({})
      )
    } else {
      showToast(t('message:MS070020', { field: t('Tab:Underwritting') }), 'error')
    }
  }

  const getRole = (role: string) => {
    return role === 'PolicyOwner'
      ? t('roles:PolicyOwner')
      : role === 'Beneficiary'
        ? t('roles:Beneficiary')
        : role === 'LifeAssured'
          ? t('roles:LifeAssured')
          : '-'
  }

  const openNotesModal = (
    fieldItem: FieldArrayWithId<UnderwrittingForm.UnderwrittingFormInfo, 'UWDecisionList', 'id'>,
    selectedIndex: number,
    isDisabledItem: boolean
  ) => {
    setIsShowedNotesModal(true)
    setDetailNote({
      clientName: fieldItem.clientName,
      clientNumber: fieldItem.clientNumber,
      parentID: selectedIndex,
      isDisabledItem: isDisabledItem,
      isNewLA: fieldItem.isNewLA
    })
  }

  const getOptionArrayByList = (decisionArr: UnderwrittingService.UWDecisionInfo[]) => {
    return decisionArr.map((item) => ({
      label: item.uwDecision,
      value: item.codeUWDecision
    }))
  }

  const closeNotesModal = () => {
    setIsShowedNotesModal(false)
  }

  const onsaveNotesModal = (itemList: UnderwrittingForm.HistoryNote[], parentID: number) => {
    underwrittingForm.setValue(`UWDecisionList.${parentID}.historyNotes`, itemList)
  }

  const loadUnderwrittingWNotesModal = () => {
    return isShowedNotesModal ? (
      <UnderwrittingNotesModal
        title={t('Underwritting:Notes').toLocaleUpperCase()}
        isVisible={isShowedNotesModal}
        onClose={closeNotesModal}
        policyNum={props.policyNum}
        detailData={detailNote}
        disabled={props.disabled || (!!detailNote ? detailNote.isDisabledItem : false)}
        saveNotes={onsaveNotesModal}
        underwrittingForm={underwrittingForm}
      />
    ) : (
      <></>
    )
  }

  const getSavedDecisionData = () => {
    const saveDecisionDataList: UnderwrittingService.SaveDecisionDataList = underwrittingForm
      .watch('UWDecisionList')
      .map((item) => {
        return {
          processInstanceId: props.processInstanceID,
          userRole: item.role,
          clientNumber: item.clientNumber,
          clientName: item.clientName,
          transactionType: item.transactionType,
          uwDecision: item.UWDecision ? item.UWDecision.value : '',
          suspendContent: item.suspendContent,
          caseId: props.caseId
        }
      })
    return saveDecisionDataList
  }

  const getSavedNoteData = () => {
    let noteList: UnderwrittingService.SaveNoteData[] = []
    underwrittingForm.watch('UWDecisionList').map((item) => {
      const historyNotes = item.historyNotes.map((historyItem) => ({
        clientNumber: historyItem.clientNumber,
        eventDate: historyItem.eventDate.toISOString(),
        policyNumber: historyItem.policyNo,
        note: historyItem.notes
      }))
      noteList = [...noteList, ...historyNotes]
    })
    return noteList
  }

  const saveDecision = async () => {
    const isValid = await underwrittingForm.trigger()
    if (isValid && !underwrittingForm.watch('isUpdatedDecision')) {
      const decisionData = getSavedDecisionData()
      const noteData = getSavedNoteData()
      pipe(
        noteData && noteData.length > 0 ? UnderwrittingService.saveNote(noteData) : ZIO.succeed([]),
        ZIO.flatMap((_) => UnderwrittingService.saveDecision(decisionData)),
        ZIO.foldM(
          (err) =>
            ZIO.effect(() => {
              showToast(t('message:MS050001'), 'error')
              return err
            }),
          (responseData) =>
            ZIO.effect(() => {
              // setIsUpdatedDecision(true)
              underwrittingForm.setValue('isUpdatedDecision', true)
              underwrittingForm.watch('UWDecisionList').map((item, index) => {
                underwrittingForm.setValue(`UWDecisionList.${index}.historyNotes`, [])
                underwrittingForm.setValue(
                  `UWDecisionList.${index}.oldUWDecision`,
                  item.UWDecision ? item.UWDecision.value : ''
                )
              })
              showToast(t('message:MS100003'), 'success')
              return responseData
            })
        ),
        bindLoader,
        ZIO.unsafeRun({})
      )
    }
  }

  const updateDecision = async () => {
    const isValid = await underwrittingForm.trigger()
    if (isValid && underwrittingForm.watch('isUpdatedDecision')) {
      const decisionData = getSavedDecisionData()
      const noteData = getSavedNoteData()
      pipe(
        noteData && noteData.length > 0 ? UnderwrittingService.saveNote(noteData) : ZIO.succeed([]),
        ZIO.flatMap((_) => UnderwrittingService.updateUWClientDecisions(decisionData)),
        ZIO.foldM(
          (err) =>
            ZIO.effect(() => {
              showToast(t('message:MS050001'), 'error')
              return err
            }),
          (responseData) =>
            ZIO.effect(() => {
              underwrittingForm.setValue('isUpdatedDecision', true)
              underwrittingForm.watch('UWDecisionList').map((item, index) => {
                underwrittingForm.setValue(`UWDecisionList.${index}.historyNotes`, [])
                underwrittingForm.setValue(
                  `UWDecisionList.${index}.oldUWDecision`,
                  item.UWDecision ? item.UWDecision.value : ''
                )
              })
              showToast(t('message:MS100003'), 'success')
              return responseData
            })
        ),
        bindLoader,
        ZIO.unsafeRun({})
      )
    }
  }

  return (
    <View style={writtingStyles.container}>
      <Title title={t('Underwritting:UNDERWRITTINGDECISION')} />
      {fields && fields.length <= 0 ? (
        <LinearProgress style={{ marginTop: 15 }} color="secondary" />
      ) : (
        <View>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow style={{ backgroundColor: '#e2e7ea' }}>
                  <TableCell align="left" style={{ minWidth: 220, fontWeight: 'bold', fontSize: 15 }}>
                    {t('contact:Role')}
                  </TableCell>
                  <TableCell align="left" style={{ minWidth: 220, fontWeight: 'bold', fontSize: 15 }}>
                    {t('common:ClientNumber')}
                  </TableCell>
                  <TableCell align="left" style={{ minWidth: 220, fontWeight: 'bold', fontSize: 15 }}>
                    {t('submission:ClientName')}
                  </TableCell>
                  <TableCell align="left" style={{ minWidth: 220, fontWeight: 'bold', fontSize: 15 }}>
                    {t('common:TransactionType')}
                  </TableCell>
                  <TableCell align="left" style={{ minWidth: 220, fontWeight: 'bold', fontSize: 15 }}>
                    {t('Underwritting:UWDecision')}
                  </TableCell>
                  <TableCell align="left" style={{ minWidth: 280, fontWeight: 'bold', fontSize: 15 }}>
                    {t('Underwritting:SuspendContent')}
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: 'bold', fontSize: 15 }}>
                    {/* {t('Underwritting:Notes')} */}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fields &&
                  fields.map((fieldItem, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell align="left">{fieldItem.role}</TableCell>
                        <TableCell align="left">{!fieldItem.isNewLA ? fieldItem.clientNumber : ''}</TableCell>
                        <TableCell align="left">{fieldItem.clientName}</TableCell>
                        <TableCell align="left">
                          {fieldItem.isSubmitedEdit
                            ? t(mapTransactionType.get(fieldItem.transactionType as TransactionType) ?? '-')
                            : ''}
                        </TableCell>
                        <TableCell align="left">
                          <Controller
                            control={underwrittingForm.control}
                            name={`UWDecisionList.${index}.UWDecision`}
                            rules={{
                              required: {
                                value: !!fieldItem.isSubmitedEdit,
                                message: `${t('message:MS020009', { field: t('Underwritting:UWDecision') })}`
                              }
                            }}
                            render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                              <Select
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                filled={true}
                                disabled={props.disabled || !fieldItem.isSubmitedEdit}
                                options={getOptionArrayByList(UWDecisionTypeArr)}
                                errorMessage={!value || value.value === '' ? error?.message : ''}
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell align="left">
                          <Controller
                            control={underwrittingForm.control}
                            name={`UWDecisionList.${index}.suspendContent`}
                            render={({ field: { value, onChange, onBlur } }) => (
                              <Input
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                numberOfLines={2}
                                multiline={true}
                                disabled={props.disabled || !fieldItem.isSubmitedEdit}
                                alwayShowUnderline={false}
                                inputStyle={{ padding: 5 }}
                                filled={true}
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell>
                          <TouchableOpacity
                            style={writtingStyles.noteButton}
                            onPress={() => openNotesModal(fieldItem, index, !fieldItem.isSubmitedEdit)}
                          >
                            <Text style={sharedStyle.textButton}>{t('Underwritting:Notes')}</Text>
                          </TouchableOpacity>
                        </TableCell>
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </View>
      )}

      {loadUnderwrittingWNotesModal()}
      {completeOpen && (
        <CompletePopup
          open={completeOpen}
          onClose={() => setCompleteOpen(false)}
          onSuccess={() => props.onHandleActionSuccess && props.onHandleActionSuccess(true)}
          selectedValue={{
            taskId: props.taskId,
            processId: props.processInstanceID,
            updateToCore: props.updateToCore,
            policyNum: props.policyNum,
            category: props.category,
            isUWTransaction: true,
            UWDecision: finalDecision,
            nextStep: nextStep,
            isUWCase: true
          }}
        />
      )}
      {isSuspendPopupOpen && props.isSupActive && planSuspendDate && (
        <SuspendPopup
          open={isSuspendPopupOpen}
          onClose={() => setIsSuspendPopupOpen(false)}
          onSuccess={() => props.onHandleActionSuccess && props.onHandleActionSuccess(true)}
          selectedValue={{
            processId: props.processInstanceID,
            taskId: props.taskId,
            planSuspendDate: planSuspendDate,
            updateToCore: props.updateToCore
          }}
          category={props.category}
        />
      )}
      {isTransferPopupOpen && (
        <TransferPopup
          open={isTransferPopupOpen}
          onClose={() => setIsTransferPopupOpen(false)}
          onSuccess={() => props.onHandleActionSuccess && props.onHandleActionSuccess(true)}
          selectedValue={{
            category: props.category as TaskType,
            basket: props.basket,
            taskIds: [props.taskId],
            updateToCore: props.updateToCore,
            transactionType: props.transactionType as TransactionType,
            isUWCase: true
          }}
        />
      )}
    </View>
  )
}
const writtingStyles = StyleSheet.create({
  container: {
    flex: 1,
    marginVertical: 15,
    marginHorizontal: 15
  },
  noteButton: {
    justifyContent: 'center',
    borderRadius: 100,
    borderWidth: 1,
    borderColor: '#ED1B2E',
    minWidth: 100,
    paddingHorizontal: 13,
    paddingVertical: 8
  }
})
