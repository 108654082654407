
import { Throwable, ZIO } from '@mxt/zio'
import { Maybe } from '@mxt/zio/codec'
import { POApi } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'

export namespace OBQcService {

  export const QCCommonInfo = t.type({
    lastAssignee: Maybe(t.string),
    callResult: Maybe(t.string),
    manualInternalFeedback: Maybe(t.string),
    qcTotalPassScore: Maybe(t.number),
    qcTotalScore: Maybe(t.number),
    comment: Maybe(t.string),
    qcRating: Maybe(t.number),
    qcResult: Maybe(t.string),
    updateContactInfo: Maybe(t.string)
  })
  export type QCCommonInfo = t.TypeOf<typeof QCCommonInfo>

  export const QCList = t.array(t.type({
    a12s1Criteria: t.type({
      id: Maybe(t.string),
      categoryENDesc: Maybe(t.string),
      categoryVNDesc: Maybe(t.string),
      criteriaCode: Maybe(t.string),
      criteriaENName: Maybe(t.string),
      criteriaVNName: Maybe(t.string),
      criteriaDescEN: Maybe(t.string),
      criteriaDescVN: Maybe(t.string),
      score: Maybe(t.number),
      topicCode: Maybe(t.string),
      topicENDesc: Maybe(t.string),
      topicVNDesc: Maybe(t.string),
      answerTypeCode: Maybe(t.string),
      failureMechanism: Maybe(t.string)
    }),
    answerValueCode: Maybe(t.string)
  }))
  export type QCList = t.TypeOf<typeof QCList>

  export const OBQcHistory = t.type({
    qcCommonInfo: QCCommonInfo,
    qcCriteriaList: QCList
  })
  export type OBQcHistory = t.TypeOf<typeof OBQcHistory>

  export const A12S2Data = t.type({
    valueCode: t.string,
    valueEN: t.string,
    valueVN: t.string,
    valueScore: t.string
  })
  export type A12S2Data = t.TypeOf<typeof A12S2Data>

  export const OBSaveDataQC = t.type({
    businessKey: t.string,
    processInstanceId: t.string,
    policyNumber: t.string,
    assignee: t.string,
    teamLeader: t.string,
    transactionType: t.string,
    createdBy: t.string,
    createdDate: t.string,
    updatedBy: t.string,
    updatedDate: t.string,
    qcCommonInfo: QCCommonInfo,
    qcCriteriaList: QCList
  })

  export const A12S3Table = t.type({
    resultCode: t.string,
    resultEN: t.string,
    resultVN: t.string,
    topicCode: t.string,
    resultTypeCode: t.string,
    resultType: t.string,
    operator: t.string,
    failedValue: t.number
  })
  export const A12S4Table = t.type({
    actionCode: t.string,
    operator: t.string,
    serviceCode: t.string,
    outcomeCode: t.string
  })

  export const A12S6Table = t.type({
    id: t.string,
    transactionTypeWF: t.string,
    comparisonOperator: t.string,
    qcRating: t.number,
    outcomeCode: t.string
  })
  export type A12S6Table = t.TypeOf<typeof A12S6Table>

  export type OBSaveDataQC = t.TypeOf<typeof OBSaveDataQC>

  export const getA12S3Table = () => pipe(
    POApi.get(`pulseops/api/v1/outbound/qc/a12s3`)(t.type({
      data: t.array(A12S3Table)
    })),
    ZIO.foldM(
      (err) => ZIO.fail(Throwable(err)),
      (response) => {
        return ZIO.succeed(response.data)
      }
    )
  )

  export const getA12S4Table = () => pipe(
    POApi.get(`pulseops/api/v1/outbound/qc/a12s4`)(t.type({
      data: t.array(A12S4Table)
    })),
    ZIO.foldM(
      (err) => ZIO.fail(Throwable(err)),
      (response) => {
        return ZIO.succeed(response.data)
      }
    )
  )

  export const getHistorySave = (processInstanceId: string) => pipe(
    POApi.get(`pulseops/api/v1/outbound/qc/${processInstanceId}`)(OBQcHistory),
    ZIO.map((res) => res)
  )

  export const saveHistoryQC = (dataSave: OBSaveDataQC) => pipe(
    POApi.post(`pulseops/api/v1/outbound/qc/save-history`)(t.unknown)(dataSave),
    ZIO.map((res) => res)
  )

  export const getA12S6Table = () => pipe(
    POApi.get(`pulseops/api/v1/outbound/qc/a12s6`)(t.type({
      data: Maybe(t.array(A12S6Table))
    })),
    ZIO.foldM(
      (err) => ZIO.fail(Throwable(err)),
      (response) => {
        return ZIO.succeed(response.data)
      }
    )
  )

  export const getDataScoreA12S2 = () => pipe(
    POApi.get(`pulseops/api/v1/outbound/qc/a12s2`)(t.type({
      data: t.array(A12S2Data)
    })),
    ZIO.foldM(
      (err) => ZIO.fail(Throwable(err)),
      (response) => {
        return ZIO.succeed(response.data)
      }
    )
  )
}