import { ZIO, Task, UIO, Throwable } from '@mxt/zio'
import { Duration } from '@mxt/zio/date'
import { ErrorHandling, GeneralService, POApi, SelectOption, SubmissionService } from '@pulseops/common'
import { pipe } from 'fp-ts/function'
import { Bank, Branch, ClaimDataAccess, ClaimSubmitData, ClaimPayoutSubmitData } from './common'
import * as t from 'io-ts'
import { Nullable, Maybe } from '@mxt/zio/codec'

import { ClaimPayoutSubmissionC } from './common/SubmissionClaimPayout'
export namespace ClaimService {
  const getOptionsMemoize = pipe(
    ZIO.succeed([
      {
        label: 'Nguyen Van B',
        value: 'value'
      },
      {
        label: 'Nguyen Van C',
        value: 'value 2'
      }
    ]),
    ZIO.tap(() => ZIO.sleep(Duration.seconds(3)))
  )

  export const searchOptions = (inputValue: string) =>
    pipe(getOptionsMemoize, ZIO.provide({ inputValue }), ErrorHandling.catchAll([]))

  export const searchDiagnostic =
    (translator: (valEn: string, valVi: string) => string) =>
      (
        inputValue: string
      ): UIO<
        {
          label: string
          value: string
          labelVn: string
        }[]
      > =>
        pipe(
          GeneralService.searchDiagnostic(inputValue),
          ZIO.map((d) =>
            d.map((di) => ({
              value: di.icdcode,
              label: translator(di.diseaseNameEn, di.diseaseNameVn),
              labelVn: di.diseaseNameVn
            }))
          ),
          ZIO.provide({ inputValue }),
          ErrorHandling.catchAll([])
        )

  export const getDataAccessPayout = (policyNum: string, uuid: string): Task<ClaimDataAccess | null> =>
    pipe(
      ZIO.zipPar(
        SubmissionService.getPolicyInquiry(policyNum),
        GeneralService.getClaimType(),
        GeneralService.getSubClaimType(),
        GeneralService.getSubClaimTypeBenefit(),
        GeneralService.getClaimSubBenefit,
        GeneralService.getListClaimNo(policyNum)
      ),
      ZIO.map(([policyInquiry, claimTypes, subClaimTypes, claimBenefit, subClaimBenefit, listClaimNo]) => {
        const owner = policyInquiry?.customerRoles?.find((x) => x.role === 'Owner')
        const productCode = policyInquiry?.product?.code
        const cTypes = claimTypes.find((c) => c.productCode === productCode)
        if (!owner || !cTypes) return null
        const totalClaimTypes = subClaimTypes.filter((s) => cTypes.claimType?.includes(s.claimTypeCode))
        const claimTypeCodes: string[] = totalClaimTypes.reduce(
          (acc: string[], curr) => acc.concat(curr.subClaimType),
          []
        )
        const subBenefit = claimBenefit.filter((b) => claimTypeCodes.includes(b.subClaimTypeCode))
        const { firstName, surName, externalIds, contactDetails, addressDetails, dob } = owner.customer
        const { PHONE, EMAIL, phone, email } = contactDetails
        const { Current } = addressDetails
        const product = claimTypes.find((_) => _.productCode === productCode)
        return {
          policyNum,
          mobileCodes: [{ value: '', label: '' }],
          ownerName: `${firstName} ${surName}`,
          ownerId: externalIds.NATIONAL_ID,
          ownerPhoneCode: {
            value: PHONE?.countryCode || phone?.countryCode || '-',
            label: `+${PHONE?.countryCode || phone?.countryCode}`
          },
          ownerPhoneNumber: PHONE?.value || phone?.value || '-',
          ownerEmail: EMAIL?.value || email?.value || '-',
          ownerAddress: `${Current.line1} ${Current.line2 || ''} ${Current.line3 || ''} ${Current.line4 || ''}`.trim(),
          laList:
            policyInquiry?.lifeAssured?.map(({ firstName, surName }) => ({
              value: `${surName} ${firstName}`,
              label: `${surName} ${firstName}`
            })) || [],
          claimantName: `${firstName} ${surName}`,
          claimantPhoneCode: {
            value: PHONE?.countryCode || phone?.countryCode || '-',
            label: `+${PHONE?.countryCode || phone?.countryCode}`
          },
          claimantPhoneNumber: PHONE?.value || phone?.value || '-',
          claimTypes: totalClaimTypes,
          claimSubBenefit: subClaimBenefit.filter((s) => subBenefit.some((sub) => sub.benefit?.includes('N/A'))),
          claimNo: '',
          dob: dob,
          contractName: `${product?.productCode} - ${product?.productEN}`,
          listClaimNo: listClaimNo.map((item) => ({ value: item.parentProcessInstanceId, label: item.claimNumber })),
          status: policyInquiry.status || '-'
        }
      })
    )

  export const getDataAccess = (policyNum: string, uuid: string): Task<ClaimDataAccess | null> =>
    pipe(
      ZIO.zipPar(
        SubmissionService.getPolicyInquiry(policyNum),
        GeneralService.getMobileCodes,
        GeneralService.getClaimType(),
        GeneralService.getSubClaimType(),
        GeneralService.getSubClaimTypeBenefit(),
        GeneralService.getClaimSubBenefit,
        GeneralService.getClaimNumber(uuid)
      ),
      ZIO.map(([policyInquiry, mobileCodes, claimTypes, subClaimTypes, claimBenefit, subClaimBenefit, claimNumber]) => {
        const owner = policyInquiry?.customerRoles?.find((x) => x.role === 'Owner')
        const productCode = policyInquiry?.product?.code
        const cTypes = claimTypes.find((c) => c.productCode === productCode)
        if (!owner || !cTypes) return null
        const totalClaimTypes = subClaimTypes.filter((s) => cTypes.claimType?.includes(s.claimTypeCode))
        const claimTypeCodes: string[] = totalClaimTypes.reduce(
          (acc: string[], curr) => acc.concat(curr.subClaimType),
          []
        )
        const subBenefit = claimBenefit.filter((b) => claimTypeCodes.includes(b.subClaimTypeCode))
        const { firstName, surName, externalIds, contactDetails, addressDetails, dob } = owner.customer
        const { PHONE, EMAIL, phone, email } = contactDetails
        const { Current } = addressDetails
        const product = claimTypes.find((_) => _.productCode === productCode)
        return {
          policyNum,
          mobileCodes: mobileCodes.map((m) => ({ value: m.code, label: `+${m.code}` })),
          ownerName: `${firstName} ${surName}`,
          ownerId: externalIds.NATIONAL_ID,
          ownerPhoneCode: {
            value: PHONE?.countryCode || phone?.countryCode || '-',
            label: `+${PHONE?.countryCode || phone?.countryCode}`
          },
          ownerPhoneNumber: PHONE?.value || phone?.value || '-',
          ownerEmail: EMAIL?.value || email?.value || '-',
          ownerAddress: `${Current.line1} ${Current.line2 || ''} ${Current.line3 || ''} ${Current.line4 || ''}`.trim(),
          laList:
            policyInquiry?.lifeAssured?.map(({ firstName, surName }) => ({
              value: `${surName} ${firstName}`,
              label: `${surName} ${firstName}`
            })) || [],
          claimantName: `${firstName} ${surName}`,
          claimantPhoneCode: {
            value: PHONE?.countryCode || phone?.countryCode || '-',
            label: `+${PHONE?.countryCode || phone?.countryCode}`
          },
          claimantPhoneNumber: PHONE?.value || phone?.value || '-',
          claimTypes: totalClaimTypes,
          claimSubBenefit: subClaimBenefit.filter((s) => subBenefit.some((sub) => sub.benefit?.includes('N/A'))),
          claimNo: claimNumber,
          dob: dob,
          contractName: `${product?.productCode} - ${product?.productEN}`,
          listClaimNo: []
        }
      })
    )


  const bankInfo =  t.type({
    code: t.string,
    name: t.string,
    codePulse: Maybe(t.string)
  })

  const bankRes = t.type({
    body: t.array(bankInfo)
  })

  export const getBanks = (status?: string): Task<Array<Bank>> =>
    pipe(
      POApi.post(`wf-api/bank/search`)(bankRes)({
        body: { status: status }
      }),
      ZIO.map((banks) => banks.body.filter((bank) => bank.code !== 'NULL' && bank.code !== 'VRB'))
    )

  const branchRes = t.type({
    body: t.array(
      t.type({
        code: t.string,
        name: t.string
      })
    )
  })
  export const getBankBranch = (code: string): Task<Array<Branch>> =>
    pipe(
      POApi.get(`wf-api/bank/${code}/branch/search`)(branchRes),
      ZIO.map((branches) =>
        branches.body.map((x) => ({
          code: x.code,
          name: x.name
        }))
      )
    )

  export const submit = (
    policyNum: string,
    data: ClaimSubmitData,
    documents: { name: string; url: string }[],
    exchangeId: string,
    officeCode: string,
    isClaim: boolean
  ) =>
    SubmissionService.submit(t.unknown)(
      `digitalclaim/partner/claim`,
      { body: data },
      policyNum,
      null,
      documents,
      data.policy.owners.clientId,
      undefined,
      officeCode,
      isClaim,
      exchangeId
    )

  export enum RequestAuthOption {
    OTP = 'OTP',
    SIGNATURE = 'SIGN_ON_AP',
    PAPER = 'ATTACH_REQUEST_FORM',
    BYPASS = 'BY_PASS'
  }

  export const submitPayout = (
    policyNum: string,
    data: ClaimPayoutSubmissionC,
    exchangeId: string,
    officeCode: string,
    isClaim: boolean,
    clientId: string
  ) =>
    SubmissionService.submit(t.unknown)(
      `digitalclaim/claim-payout`,
      { body: data },
      policyNum,
      { type: RequestAuthOption.BYPASS, data: {} },
      [],
      clientId,
      undefined,
      officeCode,
      isClaim,
      exchangeId
    )

  export const StageItem = t.type({
    stage: Nullable(t.string),
    date: Nullable(t.string),
    assignee: Nullable(t.string),
    status: Nullable(t.string),
    suspendGroupCodes: Nullable(t.array(t.string)),
    claimDecision: Nullable(t.string),
    suspendDetails: Nullable(
      t.array(
        t.type({
          suspendGroupDoc: t.string,
          suspendTypes: Nullable(
            t.array(
              t.type({
                suspendTypeCode: Nullable(t.string),
                suspendTypeVN: Nullable(t.string),
                suspendType: Nullable(t.string),
                suspendTypeMessageCode: Nullable(t.string),
                createdDate: Nullable(t.string)
              })
            )
          )
        })
      )
    ),
    unsuspendDetails: Nullable(
      t.array(
        t.type({
          suspendGroupDoc: t.string,
          unsuspendTypes: Nullable(
            t.array(
              t.type({
                suspendTypeCode: Nullable(t.string),
                suspendTypeVN: Nullable(t.string),
                suspendType: Nullable(t.string),
                suspendTypeMessageCode: Nullable(t.string),
                modifiedDate: Nullable(t.string)
              })
            )
          )
        })
      )
    )
  })

  export const StageList = t.array(StageItem)

  export type StageList = t.TypeOf<typeof StageList>

  export const StageData = t.type({
    data: Nullable(StageList)
  })

  export type StageData = t.TypeOf<typeof StageData>

  export const getStage = (processInstanceId: string, sourceOfBusiness: string) =>
    pipe(
      POApi.get(
        `pulseops/api/v1/claim/journey/tracking-status?processInstanceId=${processInstanceId}&sourceOfBusiness=${sourceOfBusiness}`
      )(StageData),
      ZIO.map((res) => res.data)
    )

  export interface BankPayload {
    accountName: string
    accountNo: string
    bankName: string
    branchCode: string
  }

  export const CheckBank = t.type({
    body: Nullable(
      t.type({
        systemName: Nullable(t.string),
        exchangeId: Nullable(t.string),
        createdBy: Nullable(t.string),
        functionUI: Nullable(t.string),
        status: Nullable(t.string),
        oerrorResult: Nullable(t.string),
        msgId: Nullable(t.string),
        timestamp: Nullable(t.string),
        source: Nullable(t.string),
        correlationId: Nullable(t.string),
        access_token: Nullable(t.string),
        refresh_token: Nullable(t.string),
        fullName: Nullable(t.string)
      })
    ),
    status: Nullable(
      t.type({
        code: Nullable(t.number),
        message: Nullable(t.string),
        errorMessage: Nullable(t.string)
      })
    ),
    poll: Nullable(t.string),
    responseStatus: Nullable(t.string)
  })

  export const checkBankNapas = (data: BankPayload) =>
    pipe(
      POApi.post(`claim-rest/api/check-account-napas`)(CheckBank)(data),
      ZIO.map((res) => res)
    )

  export const checkBenID = (bankKey: string) =>
    pipe(
      POApi.get(`claim-rest/api/get-benid-by-bank-key/${bankKey}`)(
        t.type({
          bankKey: t.string,
          citiTad: t.string,
          benId: t.string,
          napasFlag: t.string
        })
      ),
      ZIO.map((res) => res)
    )
  export const getStatusPV = (claimNumber: string) =>
    pipe(
      POApi.get(`claim-rest/api/payout-log/get-latest-status-payout?claimNumber=${claimNumber}`)(
        t.type({ logStatus: Nullable(t.string), pvType: Nullable(t.string), paidDate: Nullable(t.string), returnDate: Nullable(t.string) })
      ),
      ZIO.map((res) => res)
    )
}
