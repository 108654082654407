import { enumC } from '@mxt/zio/codec'

export enum TransactionType {
  //policy_service
  BENEFICIARY_DESIGNATION = 'BENEFICIARY_DESIGNATION',

  CHANGE_CONTACT_INFO = 'CHANGE_CONTACT_INFO',
  CHANGE_CONTACT_INFO_PHONE = 'CHANGE_CONTACT_INFO_PHONE',
  CHANGE_CONTACT_INFO_EMAIL = 'CHANGE_CONTACT_INFO_EMAIL',
  CHANGE_CONTACT_INFO_PHONE_AND_EMAIL = 'CHANGE_CONTACT_INFO_PHONE_AND_EMAIL',
  CHANGE_CONTACT_INFO_ADDRESS = 'CHANGE_CONTACT_INFO_ADDRESS',
  CHANGE_CONTACT_INFO_DIGITAL = 'CHANGE_CONTACT_INFO_DIGITAL',
  CHANGE_CONTACT_INFO_PHONE_DIGITAL = 'CHANGE_CONTACT_INFO_PHONE_DIGITAL',
  CHANGE_CONTACT_INFO_EMAIL_DIGITAL = 'CHANGE_CONTACT_INFO_EMAIL_DIGITAL',
  CHANGE_CONTACT_INFO_PHONE_AND_EMAIL_DIGITAL = 'CHANGE_CONTACT_INFO_PHONE_AND_EMAIL_DIGITAL',
  CHANGE_CONTACT_INFO_ADDRESS_DIGITAL = 'CHANGE_CONTACT_INFO_ADDRESS_DIGITAL',
  CHANGE_CLIENT_INFO = 'CHANGE_CLIENT_INFO',
  SIGNATURE_REGISTRATION = 'SIGNATURE_REGISTRATION',
  CHANGE_OCCUPATION_PERSONAL = 'CHANGE_OCCUPATION_PERSONAL',
  CHANGE_DOB_GENDER = 'CHANGE_DOB_GENDER',
  CHANGE_OTHER_INFORMATION_PERSONAL = 'CHANGE_OTHER_INFORMATION_PERSONAL',

  LOAN = 'LOAN',
  MATURITY = 'MATURITY',
  PRUCASH_PAYMENT = 'PRUCASH_PAYMENT',
  MATURITY_ADVANCE = 'MATURITY_ADVANCE',
  PARTIAL_SURRENDER = 'PARTIAL_SURRENDER',

  BILLING_CHANGE = 'BILLING_CHANGE',
  BILLING_FREQUENCY_REVERSAL = 'BILLING_FREQUENCY_REVERSAL',
  REINSTATEMENT = 'REINSTATEMENT',
  RIDER_REINSTATEMENT = 'RIDER_REINSTATEMENT',
  TOP_UP = 'TOP_UP',
  CANCEL_FROM_INCEPTION = 'CANCEL_FROM_INCEPTION',
  CANCEL_RIDER = 'CANCEL_RIDER',
  EXCESS_PREMIUM = 'EXCESS_PREMIUM',
  PARTIAL_WITHDRAWAL = 'PARTIAL_WITHDRAWAL',
  CONFIRMATION_OF_PREMIUM_RECEIPT = 'CONFIRMATION_OF_PREMIUM_RECEIPT',
  LOAN_STATEMENTS = 'LOAN_STATEMENTS',
  POLICY_CERTIFICATE = 'POLICY_CERTIFICATE',
  SPECIAL_BONUS = 'SPECIAL_BONUS',
  SPECIAL_BONUS_CUSTOMER = 'SPECIAL_BONUS_CUSTOMER',
  BONUS_SURRENDER = 'BONUS_SURRENDER',
  ASSIGNMENT = 'ASSIGNMENT',
  PAID_UP = 'PAID_UP',
  SCHEDULED_PAYMENT_STATEMENT = 'SCHEDULED_PAYMENT_STATEMENT',

  ZALO_CLAIM_DECISION = 'ZALO_CLAIM_DECISION',
  ZALO_PREMIUM_COLLECTION = 'ZALO_PREMIUM_COLLECTION',
  ZALO_RECOMMENDATION = 'ZALO_RECOMMENDATION',
  ZALO_REQUEST_PROCESS = 'ZALO_REQUEST_PROCESS',
  ZALO_OTHERS = 'ZALO_OTHERS',

  GO_ABROAD = 'GO_ABROAD',
  E_SERVICE_REGISTRATION = 'E_SERVICE_REGISTRATION',
  EXCELLENT_STUDENT_AWARD = 'EXCELLENT_STUDENT_AWARD',
  COMPLAINT_AND_INQUIRY = 'COMPLAINT_AND_INQUIRY',
  FUND_SWITCHING = 'FUND_SWITCHING',
  PREMIUM_REDIRECTION = 'PREMIUM_REDIRECTION',
  FATCA_DECLARATION = 'FATCA_DECLARATION',
  STOP_APL = 'STOP_APL',
  INHERITANCE = 'INHERITANCE',
  ADD_LACK_OF_DOCUMENT = 'ADD_LACK_OF_DOCUMENT',
  ADD_LACK_OF_PAYEE_INFO = 'ADD_LACK_OF_PAYEE_INFO',
  PRUKID369 = 'PRUKID369',
  HEALTH_DECLARATION = 'HEALTH_REDECLARATION',
  //Register
  NEW_CLAIM_REGISTER = 'NEW_CLAIM_REGISTER',
  // Supplementary
  CLAIM_SUPPLEMENTARY = 'CLAIM_SUPPLEMENTARY',
  COMPLAINT = 'COMPLAINT',
  INQUIRY = 'INQUIRY',
  CONFIRMATION_LETTER_OF_POLICY_ACCOUNT_AND_SURRENDER_VALUE = 'CONFIRMATION_LETTER_OF_POLICY_ACCOUNT_AND_SURRENDER_VALUE',
  FULL_SURRENDER_REVERSAL = 'FULL_SURRENDER_REVERSAL',
  FULL_SURRENDER = 'FULL_SURRENDER',
  PRODUCT_OPTION_SWITCHING = 'PRODUCT_OPTION_SWITCHING',
  CHANGE_PREMIUM = 'CHANGE_PREMIUM',
  CHANGE_SUM_ASSURED = 'CHANGE_SUM_ASSURED',
  REDATING = 'REDATING',
  COMMON_REQUEST_FORM = 'COMMON_REQUEST_FORM',
  MAJOR_CHANGE_AFTER_FREE_LOOK = 'MAJOR_CHANGE_AFTER_FREE_LOOK',
  AGENT_TRANSFER = 'AGENT_TRANSFER',
  MAJOR_CHANGE_IN_FREE_LOOK = 'MAJOR_CHANGE_IN_FREE_LOOK',
  OTHER_REQUEST = 'OTHER_REQUEST',
  RIDER_ALTERATION = 'RIDER_ALTERATION',
  MAJOR_COMBINED_CHANGE = 'MAJOR_COMBINED_CHANGE',
  OTHER_MAJOR_ALTERATION = 'OTHER_MAJOR_ALTERATION',
  OTHER_MINOR_ALTERATION = 'OTHER_MINOR_ALTERATION',
  OTHER_PAYOUT = 'OTHER_PAYOUT',

  //Utilities Transaction
  UPLOAD_DOCUMENT = 'UPLOAD_DOCUMENT',
  IHEAT_DATA = 'IHEAT_DATA',
  UPLOAD_MANUAL_AND_GUIDELINE = 'UPLOAD_MANUAL_AND_GUIDELINE',
  IMAGING_UPLOAD = 'IMAGING_UPLOAD',

  //PC Web
  CASHLESS_O1 = 'CASHLESS_O1',
  CASHLESS_O2 = 'CASHLESS_O2',
  CASHLESS_O3 = 'CASHLESS_O3',
  CASHLESS_DK = 'CASHLESS_DK',
  CASHLESS_C9 = 'CASHLESS_C9',
  CASHLESS_P2 = 'CASHLESS_P2',
  CASHLESS_V2 = 'CASHLESS_V2',
  CASHLESS_M2 = 'CASHLESS_M2',
  CASHLESS_A9 = 'CASHLESS_A9',
  CASHLESS_S6 = 'CASHLESS_S6',
  CASHLESS_L3 = 'CASHLESS_L3',
  CASHLESS_D7 = 'CASHLESS_D7',
  CASHLESS_M3 = 'CASHLESS_M3',
  CASHLESS_V4 = 'CASHLESS_V4',
  CASHLESS_A0 = 'CASHLESS_A0',
  CASHLESS_I4 = 'CASHLESS_I4',
  CASHLESS_P9 = 'CASHLESS_P9',
  CASHLESS_I2 = 'CASHLESS_I2',
  CASHLESS_M7 = 'CASHLESS_M7',
  CASHLESS_W1 = 'CASHLESS_W1',
  CASHLESS_A7 = 'CASHLESS_A7',
  CASHLESS_S1 = 'CASHLESS_S1',
  CASHLESS_P3 = 'CASHLESS_P3',
  CASHLESS_S2 = 'CASHLESS_S2',
  CASHLESS_B3 = 'CASHLESS_B3',
  CASHLESS_S3 = 'CASHLESS_S3',
  CASHLESS_C3 = 'CASHLESS_C3',
  CASHLESS_U3 = 'CASHLESS_U3',
  CASHLESS_V3 = 'CASHLESS_V3',
  CASHLESS_I3 = 'CASHLESS_I3',
  CASHLESS_C7 = 'CASHLESS_C7',
  CASHLESS_X1 = 'CASHLESS_X1',
  CASHLESS_T3 = 'CASHLESS_T3',
  CASHLESS_M9 = 'CASHLESS_M9',
  CASHLESS_D6 = 'CASHLESS_D6',
  CASHLESS_D8 = 'CASHLESS_D8',
  CASHLESS_D9 = 'CASHLESS_D9',
  CASHLESS_V5 = 'CASHLESS_V5',
  //DA web
  AGENT_ONBOARD_NEW = 'AGENT_ONBOARD_NEW',
  AGENT_ONBOARD_REINS = 'AGENT_ONBOARD_REINS',
  AGENT_TERMINATION_AG = 'AGENT_TERMINATION_AG',
  AGENT_OFFICE_MOVING = 'AGENT_OFFICE_MOVING',
  AGENT_TRANSFER_POLICY = 'AGENT_TRANSFER_POLICY',
  AGENT_UNIT_TRANSFER = 'AGENT_UNIT_TRANSFER',

  //Cashout
  CASH_OUT_REQUEST = 'CASH-OUT_REQUEST', //For T17
  LACK_OF_CASHOUT = 'LACK_OF_CASHOUT', // For service
  TRANSFER_JOURNAL = 'TRANSFER_JOURNAL',
  JOURNAL_PAYOUT = 'JOURNAL_PAYOUT',
  JOURNAL_ALTERATION = 'JOURNAL_ALTERATION',
  AGENT_TERMINATION = 'AGENT_TERMINATION',
  PAYOUT = 'PAYOUT',
  ADDITIONAL_INFORMATION_UW = 'ADDITIONAL_INFORMATION_UW',
  SUPPLEMENTARY_INFORMATION_UW = 'SUPPLEMENTARY_INFORMATION_UW',

  //PC Write-off
  CUSTOMERS_ADD_PAYMENT_INFORMATION = 'CUSTOMERS_ADD_PAYMENT_INFORMATION',
  REFUND_ACCORDING_TO_CUSTOMER_REQUEST = 'REFUND_ACCORDING_TO_CUSTOMER_REQUEST',
  REQUEST_A_CARD_REFUND = 'REQUEST_A_CARD_REFUND',
  REQUEST_TO_ADJUST_PAYMENT_CONTENT = 'REQUEST_TO_ADJUST_PAYMENT_CONTENT',
  CHANGE_NATIONAL_ID_CARD = 'CHANGE_NATIONAL_ID_CARD',
  AML_RED_FLAG_ACCEPT = 'AML_RED_FLAG_ACCEPT',
  AML_RED_FLAG_DECLINE = 'AML_RED_FLAG_DECLINE',

  // Auto Partial Withdraw
  AUTO_DEBIT_TOPUP_ACCOUNT_CANCEL = 'AUTO_DEBIT_TOPUP_ACCOUNT_CANCEL',
  AUTO_DEBIT_TOPUP_ACCOUNT_REQUEST = 'AUTO_DEBIT_TOPUP_ACCOUNT_REQUEST',
  BILLING_CHANGE_CORPORATE = 'BILLING_CHANGE_CORPORATE',
  INDEPENDENT_CHECK= 'INDEPENDENT_CHECK',
  VOICE_RECORDING = 'VOICE_RECORDING'
}
export const TransactionTypeC = enumC(TransactionType)

export const mapTransactionType = new Map<TransactionType, string>([
  [TransactionType.NEW_CLAIM_REGISTER, 'TransactionType:NEW_CLAIM_REGISTER'],
  [TransactionType.PAYOUT, 'TransactionType:PAYOUT'],
  [TransactionType.BENEFICIARY_DESIGNATION, 'TransactionType:BENEFICIARY_DESIGNATION'],

  [TransactionType.CHANGE_CONTACT_INFO, 'TransactionType:CHANGE_CONTACT_INFO'],
  [TransactionType.CHANGE_CONTACT_INFO_PHONE, 'TransactionType:CHANGE_CONTACT_INFO_PHONE'],
  [TransactionType.CHANGE_CONTACT_INFO_EMAIL, 'TransactionType:CHANGE_CONTACT_INFO_EMAIL'],
  [TransactionType.CHANGE_CONTACT_INFO_PHONE_AND_EMAIL, 'TransactionType:CHANGE_CONTACT_INFO_PHONE_AND_EMAIL'],
  [TransactionType.CHANGE_CONTACT_INFO_ADDRESS, 'TransactionType:CHANGE_CONTACT_INFO_ADDRESS'],
  [TransactionType.CHANGE_CONTACT_INFO_DIGITAL, 'TransactionType:CHANGE_CONTACT_INFO_DIGITAL'],
  [TransactionType.CHANGE_CONTACT_INFO_PHONE_DIGITAL, 'TransactionType:CHANGE_CONTACT_INFO_PHONE_DIGITAL'],
  [TransactionType.CHANGE_CONTACT_INFO_EMAIL_DIGITAL, 'TransactionType:CHANGE_CONTACT_INFO_EMAIL_DIGITAL'],
  [
    TransactionType.CHANGE_CONTACT_INFO_PHONE_AND_EMAIL_DIGITAL,
    'TransactionType:CHANGE_CONTACT_INFO_PHONE_AND_EMAIL_DIGITAL'
  ],
  [TransactionType.CHANGE_CONTACT_INFO_ADDRESS_DIGITAL, 'TransactionType:CHANGE_CONTACT_INFO_ADDRESS_DIGITAL'],
  [TransactionType.CHANGE_CLIENT_INFO, 'TransactionType:CHANGE_CLIENT_INFO'],
  [TransactionType.CHANGE_SUM_ASSURED, 'TransactionType:CHANGE_SUM_ASSURED'],
  [TransactionType.CHANGE_PREMIUM, 'TransactionType:CHANGE_PREMIUM'],

  [TransactionType.LOAN, 'TransactionType:LOAN'],
  [TransactionType.LOAN_STATEMENTS, 'TransactionType:LOAN_STATEMENTS'],
  [TransactionType.PRUCASH_PAYMENT, 'TransactionType:PRUCASH_PAYMENT'],
  [TransactionType.MATURITY, 'TransactionType:MATURITY'],
  [TransactionType.MATURITY_ADVANCE, 'TransactionType:MATURITY_ADVANCE'],
  [TransactionType.PARTIAL_SURRENDER, 'TransactionType:PARTIAL_SURRENDER'],
  [TransactionType.FULL_SURRENDER, 'TransactionType:FULL_SURRENDER'],

  [TransactionType.BILLING_CHANGE, 'TransactionType:BILLING_CHANGE'],
  [TransactionType.BILLING_FREQUENCY_REVERSAL, 'TransactionType:BILLING_FREQUENCY_REVERSAL'],
  [TransactionType.REINSTATEMENT, 'TransactionType:REINSTATEMENT'],
  [TransactionType.TOP_UP, 'TransactionType:TOP_UP'],
  [TransactionType.CONFIRMATION_OF_PREMIUM_RECEIPT, 'TransactionType:CONFIRMATION_OF_PREMIUM_RECEIPT'],
  [TransactionType.CANCEL_FROM_INCEPTION, 'TransactionType:CANCEL_FROM_INCEPTION'],
  [TransactionType.CANCEL_RIDER, 'TransactionType:CANCEL_RIDER'],
  [TransactionType.EXCESS_PREMIUM, 'TransactionType:EXCESS_PREMIUM'],
  [TransactionType.PARTIAL_WITHDRAWAL, 'TransactionType:PARTIAL_WITHDRAWAL'],
  [TransactionType.ASSIGNMENT, 'TransactionType:ASSIGNMENT'],
  [TransactionType.PAID_UP, 'TransactionType:PAID_UP'],
  [TransactionType.SCHEDULED_PAYMENT_STATEMENT, 'TransactionType:SCHEDULED_PAYMENT_STATEMENT'],
  [TransactionType.PRODUCT_OPTION_SWITCHING, 'TransactionType:PRODUCT_OPTION_SWITCHING'],

  [TransactionType.ZALO_CLAIM_DECISION, 'TransactionType:ZALO_CLAIM_DECISION'],
  [TransactionType.ZALO_PREMIUM_COLLECTION, 'TransactionType:ZALO_PREMIUM_COLLECTION'],
  [TransactionType.ZALO_RECOMMENDATION, 'TransactionType:ZALO_RECOMMENDATION'],
  [TransactionType.ZALO_REQUEST_PROCESS, 'TransactionType:ZALO_REQUEST_PROCESS'],
  [TransactionType.ZALO_OTHERS, 'TransactionType:ZALO_OTHERS'],

  [TransactionType.GO_ABROAD, 'TransactionType:GO_ABROAD'],
  [TransactionType.E_SERVICE_REGISTRATION, 'TransactionType:E_SERVICE_REGISTRATION'],
  [TransactionType.COMPLAINT_AND_INQUIRY, 'TransactionType:COMPLAINT_AND_INQUIRY'],
  [TransactionType.SPECIAL_BONUS, 'TransactionType:SPECIAL_BONUS'],
  [TransactionType.SPECIAL_BONUS_CUSTOMER, 'TransactionType:SPECIAL_BONUS_CUSTOMER'],
  [TransactionType.POLICY_CERTIFICATE, 'TransactionType:POLICY_CERTIFICATE'],
  [TransactionType.PREMIUM_REDIRECTION, 'TransactionType:PREMIUM_REDIRECTION'],
  [TransactionType.FUND_SWITCHING, 'TransactionType:FUND_SWITCHING'],
  [TransactionType.BONUS_SURRENDER, 'TransactionType:BONUS_SURRENDER'],
  [TransactionType.STOP_APL, 'TransactionType:STOP_APL'],
  [TransactionType.FATCA_DECLARATION, 'TransactionType:FATCA_DECLARATION'],
  [TransactionType.EXCELLENT_STUDENT_AWARD, 'TransactionType:EXCELLENT_STUDENT_AWARD'],
  [TransactionType.INHERITANCE, 'TransactionType:INHERITANCE'],
  [TransactionType.COMPLAINT, 'TransactionType:COMPLAINT'],
  [TransactionType.INQUIRY, 'TransactionType:INQUIRY'],
  [
    TransactionType.CONFIRMATION_LETTER_OF_POLICY_ACCOUNT_AND_SURRENDER_VALUE,
    'TransactionType:CONFIRMATION_LETTER_OF_POLICY_ACCOUNT_AND_SURRENDER_VALUE'
  ],
  [TransactionType.FULL_SURRENDER_REVERSAL, 'TransactionType:FULL_SURRENDER_REVERSAL'],

  [TransactionType.COMMON_REQUEST_FORM, 'TransactionType:COMMON_REQUEST_FORM'],
  [TransactionType.SIGNATURE_REGISTRATION, 'TransactionType:SIGNATURE_REGISTRATION'],
  [TransactionType.HEALTH_DECLARATION, 'TransactionType:HEALTH_REDECLARATION'],
  [TransactionType.PRUKID369, 'TransactionType:PRUKID369'],
  [TransactionType.RIDER_ALTERATION, 'TransactionType:RIDER_ALTERATION'],
  [TransactionType.MAJOR_CHANGE_IN_FREE_LOOK, 'TransactionType:MAJOR_CHANGE_IN_FREE_LOOK'],
  [TransactionType.MAJOR_CHANGE_AFTER_FREE_LOOK, 'TransactionType:MAJOR_CHANGE_AFTER_FREE_LOOK'],
  [TransactionType.UPLOAD_DOCUMENT, 'TransactionType:UPLOAD_DOCUMENT'],
  [TransactionType.REDATING, 'TransactionType:REDATING'],
  [TransactionType.PRODUCT_OPTION_SWITCHING, 'TransactionType:PRODUCT_OPTION_SWITCHING'],
  [TransactionType.RIDER_REINSTATEMENT, 'TransactionType:RIDER_REINSTATEMENT'],
  [TransactionType.IMAGING_UPLOAD, 'TransactionType:IMAGING_UPLOAD'],

  [TransactionType.CASHLESS_O1, 'TransactionType:CASHLESS_O1'],
  [TransactionType.CASHLESS_O2, 'TransactionType:CASHLESS_O2'],
  [TransactionType.CASHLESS_O3, 'TransactionType:CASHLESS_O3'],
  [TransactionType.CASHLESS_DK, 'TransactionType:CASHLESS_DK'],
  [TransactionType.CASHLESS_C9, 'TransactionType:CASHLESS_C9'],
  [TransactionType.CASHLESS_M7, 'TransactionType:CASHLESS_M7'],
  [TransactionType.CASHLESS_W1, 'TransactionType:CASHLESS_W1'],

  [TransactionType.AGENT_ONBOARD_NEW, 'TransactionType:AGENT_ONBOARD_NEW'],
  [TransactionType.AGENT_ONBOARD_REINS, 'TransactionType:AGENT_ONBOARD_REINS'],
  [TransactionType.AGENT_TERMINATION_AG, 'TransactionType:AGENT_TERMINATION_AG'],
  [TransactionType.AGENT_UNIT_TRANSFER, 'TransactionType:AGENT_UNIT_TRANSFER'],
  [TransactionType.AGENT_OFFICE_MOVING, 'TransactionType:AGENT_OFFICE_MOVING'],
  [TransactionType.AGENT_TRANSFER_POLICY, 'TransactionType:AGENT_TRANSFER_POLICY'],
  [TransactionType.CASH_OUT_REQUEST, 'TransactionType:CASH-OUT_REQUEST'],
  [TransactionType.LACK_OF_CASHOUT, 'TransactionType:LACK_OF_CASHOUT'],
  [TransactionType.CASHLESS_O1, 'TransactionType:CASHLESS_O1'],
  [TransactionType.CASHLESS_O2, 'TransactionType:CASHLESS_O2'],
  [TransactionType.CASHLESS_O3, 'TransactionType:CASHLESS_O3'],
  [TransactionType.CASHLESS_DK, 'TransactionType:CASHLESS_DK'],
  [TransactionType.CASHLESS_C9, 'TransactionType:CASHLESS_C9'],
  [TransactionType.CASHLESS_P2, 'TransactionType:CASHLESS_P2'],
  [TransactionType.CASHLESS_V2, 'TransactionType:CASHLESS_V2'],
  [TransactionType.CASHLESS_M2, 'TransactionType:CASHLESS_M2'],
  [TransactionType.CASHLESS_A9, 'TransactionType:CASHLESS_A9'],
  [TransactionType.CASHLESS_S6, 'TransactionType:CASHLESS_S6'],
  [TransactionType.CASHLESS_L3, 'TransactionType:CASHLESS_L3'],
  [TransactionType.CASHLESS_D7, 'TransactionType:CASHLESS_D7'],
  [TransactionType.CASHLESS_M3, 'TransactionType:CASHLESS_M3'],
  [TransactionType.CASHLESS_V4, 'TransactionType:CASHLESS_V4'],
  [TransactionType.CASHLESS_A0, 'TransactionType:CASHLESS_A0'],
  [TransactionType.CASHLESS_I4, 'TransactionType:CASHLESS_I4'],
  [TransactionType.CASHLESS_P9, 'TransactionType:CASHLESS_P9'],
  [TransactionType.CASHLESS_I2, 'TransactionType:CASHLESS_I2'],
  [TransactionType.TRANSFER_JOURNAL, 'TransactionType:TRANSFER_JOURNAL'],
  [TransactionType.JOURNAL_PAYOUT, 'TransactionType:JOURNAL_PAYOUT'],
  [TransactionType.JOURNAL_ALTERATION, 'TransactionType:JOURNAL_ALTERATION'],
  [TransactionType.MAJOR_COMBINED_CHANGE, 'TransactionType:MAJOR_COMBINED_CHANGE'],
  [TransactionType.CHANGE_NATIONAL_ID_CARD, 'TransactionType:CHANGE_NATIONAL_ID_CARD'],
  [TransactionType.CASHLESS_A7, 'TransactionType:CASHLESS_A7'],
  [TransactionType.CASHLESS_S1, 'TransactionType:CASHLESS_S1'],
  [TransactionType.CASHLESS_P3, 'TransactionType:CASHLESS_P3'],
  [TransactionType.CASHLESS_S2, 'TransactionType:CASHLESS_S2'],
  [TransactionType.CASHLESS_B3, 'TransactionType:CASHLESS_B3'],
  [TransactionType.CASHLESS_S3, 'TransactionType:CASHLESS_S3'],
  [TransactionType.CASHLESS_C3, 'TransactionType:CASHLESS_C3'],
  [TransactionType.CASHLESS_U3, 'TransactionType:CASHLESS_U3'],
  [TransactionType.CASHLESS_V3, 'TransactionType:CASHLESS_V3'],
  [TransactionType.CASHLESS_I3, 'TransactionType:CASHLESS_I3'],
  [TransactionType.CASHLESS_C7, 'TransactionType:CASHLESS_C7'],
  [TransactionType.CASHLESS_X1, 'TransactionType:CASHLESS_X1'],
  [TransactionType.CASHLESS_T3, 'TransactionType:CASHLESS_T3'],
  [TransactionType.CASHLESS_M9, 'TransactionType:CASHLESS_M9'],
  [TransactionType.CASHLESS_D6, 'TransactionType:CASHLESS_D6'],
  [TransactionType.CASHLESS_D8, 'TransactionType:CASHLESS_D8'],
  [TransactionType.CASHLESS_D9, 'TransactionType:CASHLESS_D9'],
  [TransactionType.CASHLESS_V5, 'TransactionType:CASHLESS_V5'],
  [TransactionType.AGENT_TERMINATION, 'TransactionType:AGENT_TERMINATION'],
  [TransactionType.PAYOUT, 'TransactionType:PAYOUT'],
  [TransactionType.MAJOR_COMBINED_CHANGE, 'TransactionType:MAJOR_COMBINED_CHANGE'],
  [TransactionType.ADDITIONAL_INFORMATION_UW, 'TransactionType:ADDITIONAL_INFORMATION_UW'],
  [TransactionType.SUPPLEMENTARY_INFORMATION_UW, 'TransactionType:SUPPLEMENTARY_INFORMATION_UW'],
  [TransactionType.CUSTOMERS_ADD_PAYMENT_INFORMATION, 'TransactionType:CUSTOMERS_ADD_PAYMENT_INFORMATION'],
  [TransactionType.REFUND_ACCORDING_TO_CUSTOMER_REQUEST, 'TransactionType:REFUND_ACCORDING_TO_CUSTOMER_REQUEST'],
  [TransactionType.REQUEST_A_CARD_REFUND, 'TransactionType:REQUEST_A_CARD_REFUND'],
  [TransactionType.REQUEST_TO_ADJUST_PAYMENT_CONTENT, 'TransactionType:REQUEST_TO_ADJUST_PAYMENT_CONTENT'],
  [TransactionType.CHANGE_NATIONAL_ID_CARD, 'TransactionType:CHANGE_NATIONAL_ID_CARD'],
  [TransactionType.CHANGE_OCCUPATION_PERSONAL, 'TransactionType:CHANGE_OCCUPATION_PERSONAL'],
  [TransactionType.AML_RED_FLAG_ACCEPT, 'TransactionType:AML_RED_FLAG_ACCEPT'],
  [TransactionType.AML_RED_FLAG_DECLINE, 'TransactionType:AML_RED_FLAG_DECLINE'],
  [TransactionType.CHANGE_DOB_GENDER, 'TransactionType:CHANGE_DOB_GENDER'],
  [TransactionType.CHANGE_OTHER_INFORMATION_PERSONAL, 'TransactionType:CHANGE_OTHER_INFORMATION_PERSONAL'],
  [TransactionType.OTHER_MAJOR_ALTERATION, 'TransactionType:OTHER_MAJOR_ALTERATION'],
  [TransactionType.OTHER_MINOR_ALTERATION, 'TransactionType:OTHER_MINOR_ALTERATION'],
  [TransactionType.OTHER_PAYOUT, 'TransactionType:OTHER_PAYOUT'],
  [TransactionType.AUTO_DEBIT_TOPUP_ACCOUNT_CANCEL, 'TransactionType:AUTO_DEBIT_TOPUP_ACCOUNT_CANCEL'],
  [TransactionType.AUTO_DEBIT_TOPUP_ACCOUNT_REQUEST, 'TransactionType:AUTO_DEBIT_TOPUP_ACCOUNT_REQUEST'],
  [TransactionType.BILLING_CHANGE_CORPORATE, 'TransactionType:BILLING_CHANGE_CORPORATE'],
  [TransactionType.VOICE_RECORDING, 'TransactionType:VOICE_RECORDING'],
  [TransactionType.INDEPENDENT_CHECK, 'TransactionType:INDEPENDENT_CHECK']
])

export const mapTransactionTypeWithCategory = new Map<TransactionType, string>([
  [TransactionType.COMPLAINT, 'CH'],
  // [TransactionType.NEW_CLAIM_REGISTER_NON_DIGITAL,'CL'],
  [TransactionType.NEW_CLAIM_REGISTER, 'CL'],
  [TransactionType.PAYOUT, 'CL'],
  // [TransactionType.NEW_CLAIM_REGISTER_CORPORATE,'CL'],
  [TransactionType.INQUIRY, 'CS'],
  [TransactionType.AGENT_TRANSFER, 'DA'],
  // [TransactionType.AGENT_MODIFICATION,'DA'],
  // [TransactionType.AGENT_ONBOARD,'DA'],
  // [TransactionType.AGENT_REINSTATEMENT,'DA'],
  // [TransactionType.AGENT_TERMINATION,'DA'],
  // [TransactionType.PROMOTE_AGENT,'DA'],
  // [TransactionType.DEMOTE_AGENT,'DA'],
  // [TransactionType.TRANSFER_UNIT,'DA'],
  // [TransactionType.TRANSFER_OFFICE,'DA'],
  // [TransactionType.OPEN_OFFICE,'DA'],
  // [TransactionType.CLOSE_OFFICE,'DA'],
  // [TransactionType.UPGRADE_OFFICE,'DA'],
  // [TransactionType.TRANSFER_GAD,'DA'],
  // [TransactionType.MODIFY_BANK_ACCOUNT,'DA'],
  // [TransactionType.MODIFY_CLIENT_INFORMATION,'DA'],
  // [TransactionType.INTERNAL_FEEDBACK,'GI'],
  // [TransactionType.CASH-OUT_REQUEST,'GI'],
  [TransactionType.ADD_LACK_OF_DOCUMENT, 'GI'],
  // [TransactionType.SUPPLEMENTARY_INFO,'GI'],
  // [TransactionType.ADD_LACK_OF_PAYEE_INFO,'GI'],
  // [TransactionType.CUSTOMER_CARE,'GI'],
  // [TransactionType.EVIDENCE_FOR_ORPHAN,'GI'],
  [TransactionType.UPLOAD_DOCUMENT, 'GI'],
  [TransactionType.IMAGING_UPLOAD, 'GI'],
  // [TransactionType.RETURN_MAIL,'GI'],
  // [TransactionType.GA_CASH_ADVANCE,'GI'],
  // [TransactionType.GA_CASH_DEPOSIT,'GI'],
  // [TransactionType.UPLOAD_MANUAL_AND_GUIDELINE,'GI'],
  // [TransactionType.UPLOAD_TEMPLATE,'GI'],
  // [TransactionType.VIEW_MANUAL_AND_GUIDELINE,'GI'],
  // [TransactionType.INQUIRY_IHEAT_DATA,'GI'],
  // [TransactionType.VIEW_TEMPLATE,'GI'],
  // [TransactionType.PROPOSAL_FORM,'NB'],
  // [TransactionType.ADDITIONAL_INFORMATION_NB,'NB'],
  // [TransactionType.RETURN_LETTER,'NB'],
  // [TransactionType.UPLOAD_FILE_PREMIUM_COLLECTION,'PC'],
  // [TransactionType.AUTO_DEBIT_REGISTRATION,'PC'],
  // [TransactionType.CASH_AT_GA_PROPOSAL,'PC'],
  // [TransactionType.CASH_AT_GA_POLICY,'PC'],
  // [TransactionType.PAYMENT_FOR_PROPOSAL,'PC'],
  // [TransactionType.PAYMENT_FOR_ALTERATION,'PC'],
  // [TransactionType.PAYMENT_FOR_RENEWAL,'PC'],
  [TransactionType.PRUCASH_PAYMENT, 'PS'],
  [TransactionType.MATURITY, 'PS'],
  [TransactionType.LOAN, 'PS'],
  [TransactionType.CANCEL_FROM_INCEPTION, 'PS'],
  [TransactionType.EXCESS_PREMIUM, 'PS'],
  [TransactionType.PARTIAL_WITHDRAWAL, 'PS'],
  [TransactionType.MATURITY_ADVANCE, 'PS'],
  [TransactionType.PARTIAL_SURRENDER, 'PS'],
  [TransactionType.BONUS_SURRENDER, 'PS'],
  [TransactionType.SPECIAL_BONUS, 'PS'],
  [TransactionType.EXCELLENT_STUDENT_AWARD, 'PS'],
  [TransactionType.PRUKID369, 'PS'],
  [TransactionType.FULL_SURRENDER, 'PS'],
  [TransactionType.CHANGE_CONTACT_INFO, 'PS'],
  [TransactionType.CHANGE_CONTACT_INFO_PHONE, 'PS'],
  [TransactionType.CHANGE_CONTACT_INFO_EMAIL, 'PS'],
  [TransactionType.CHANGE_CONTACT_INFO_ADDRESS, 'PS'],
  [TransactionType.CHANGE_CONTACT_INFO_ADDRESS_DIGITAL, 'PS'],
  [TransactionType.BENEFICIARY_DESIGNATION, 'PS'],
  [TransactionType.BILLING_CHANGE, 'PS'],
  [TransactionType.BILLING_FREQUENCY_REVERSAL, 'PS'],
  [TransactionType.TOP_UP, 'PS'],
  [TransactionType.REINSTATEMENT, 'PS'],
  [TransactionType.CHANGE_CLIENT_INFO, 'PS'],
  [TransactionType.GO_ABROAD, 'PS'],
  [TransactionType.CANCEL_RIDER, 'PS'],
  [TransactionType.FUND_SWITCHING, 'PS'],
  [TransactionType.PREMIUM_REDIRECTION, 'PS'],
  [TransactionType.STOP_APL, 'PS'],
  [TransactionType.FATCA_DECLARATION, 'PS'],
  [TransactionType.HEALTH_DECLARATION, 'PS'],
  [TransactionType.FULL_SURRENDER_REVERSAL, 'PS'],
  [TransactionType.PAID_UP, 'PS'],
  [TransactionType.INHERITANCE, 'PS'],
  [TransactionType.ASSIGNMENT, 'PS'],
  [TransactionType.SIGNATURE_REGISTRATION, 'PS'],
  [TransactionType.PRODUCT_OPTION_SWITCHING, 'PS'],
  [TransactionType.CHANGE_SUM_ASSURED, 'PS'],
  [TransactionType.CHANGE_PREMIUM, 'PS'],
  [TransactionType.RIDER_ALTERATION, 'PS'],
  [TransactionType.MAJOR_CHANGE_IN_FREE_LOOK, 'PS'],
  [TransactionType.MAJOR_CHANGE_AFTER_FREE_LOOK, 'PS'],
  [TransactionType.E_SERVICE_REGISTRATION, 'PS'],
  // [TransactionType.RIDER_REINSTATEMENT,'PS'],
  // [TransactionType.EKIT_CONFIRMATION,'PS'],
  [TransactionType.REDATING, 'PS'],
  [TransactionType.CHANGE_CONTACT_INFO_PHONE_AND_EMAIL, 'PS'],
  [TransactionType.CONFIRMATION_OF_PREMIUM_RECEIPT, 'PS'],
  [TransactionType.LOAN_STATEMENTS, 'PS'],
  [TransactionType.SCHEDULED_PAYMENT_STATEMENT, 'PS'],
  [TransactionType.POLICY_CERTIFICATE, 'PS'],
  [TransactionType.CONFIRMATION_LETTER_OF_POLICY_ACCOUNT_AND_SURRENDER_VALUE, 'PS'],
  [TransactionType.COMMON_REQUEST_FORM, 'PS'],
  [TransactionType.SPECIAL_BONUS_CUSTOMER, 'PS'],
  // [TransactionType.ADD_MEMBER,'PS'],
  // [TransactionType.TERMINATE_MEMBER,'PS'],
  // [TransactionType.UPGRADE_DOWNGRADE_MEMBER,'PS'],
  // [TransactionType.UPDATE_SALARY,'PS'],
  // [TransactionType.CHANGE_CONTACT_INFO_MEMBER,'PS'],
  // [TransactionType.CHANGE_CLIENT_INFO_MEMBER,'PS'],
  // [TransactionType.CHANGE_CONTACT_INFO_CORPORATE,'PS'],
  // [TransactionType.BILLING_CHANGE_MEMBER,'PS'],
  // [TransactionType.ADDITIONAL_INFORMATION_UW,'UW'],
  [TransactionType.CASHLESS_O1, 'PC'],
  [TransactionType.CASHLESS_O2, 'PC'],
  [TransactionType.CASHLESS_O3, 'PC'],
  [TransactionType.CASHLESS_DK, 'PC'],
  [TransactionType.CASHLESS_C9, 'PC'],
  [TransactionType.CASHLESS_A7, 'PC'],
  [TransactionType.CASHLESS_S1, 'PC'],
  [TransactionType.CASHLESS_P3, 'PC'],
  [TransactionType.CASHLESS_S2, 'PC'],
  [TransactionType.CASHLESS_B3, 'PC'],
  [TransactionType.CASHLESS_S3, 'PC'],
  [TransactionType.CASHLESS_C3, 'PC'],
  [TransactionType.CASHLESS_U3, 'PC'],
  [TransactionType.CASHLESS_V3, 'PC'],
  [TransactionType.CASHLESS_I3, 'PC'],
  [TransactionType.CASHLESS_C7, 'PC'],
  [TransactionType.CASHLESS_T3, 'PC'],
  [TransactionType.CASHLESS_X1, 'PC'],

  // [TransactionType.LACK_OF_CASHOUT, 'PS'],
  // [TransactionType.CASH_OUT_REQUEST, 'PS'],
  [TransactionType.MAJOR_COMBINED_CHANGE, 'PS'],
  [TransactionType.AGENT_ONBOARD_NEW, 'DS'],
  [TransactionType.AGENT_ONBOARD_REINS, 'DS'],
  [TransactionType.CASHLESS_DK, 'PC'],
  [TransactionType.CASHLESS_C9, 'PC'],
  [TransactionType.CASHLESS_P2, 'PC'],
  [TransactionType.CASHLESS_V2, 'PC'],
  [TransactionType.CASHLESS_M2, 'PC'],
  [TransactionType.CASHLESS_A9, 'PC'],
  [TransactionType.CASHLESS_S6, 'PC'],
  [TransactionType.CASHLESS_L3, 'PC'],
  [TransactionType.CASHLESS_D7, 'PC'],
  [TransactionType.CASHLESS_M3, 'PC'],
  [TransactionType.CASHLESS_V4, 'PC'],
  [TransactionType.CASHLESS_A0, 'PC'],
  [TransactionType.CASHLESS_I4, 'PC'],
  [TransactionType.CASHLESS_P9, 'PC'],
  [TransactionType.CASHLESS_I2, 'PC'],
  [TransactionType.CASHLESS_V5, 'PC'],
  [TransactionType.AGENT_TERMINATION_AG, 'DS'],
  [TransactionType.AGENT_UNIT_TRANSFER, 'DS'],
  [TransactionType.AGENT_OFFICE_MOVING, 'DS'],
  [TransactionType.AGENT_TRANSFER_POLICY, 'DS'],
  [TransactionType.JOURNAL_PAYOUT, 'PS'],
  [TransactionType.JOURNAL_ALTERATION, 'PS'],
  [TransactionType.CHANGE_NATIONAL_ID_CARD, 'PS'],
  [TransactionType.CUSTOMERS_ADD_PAYMENT_INFORMATION, 'CL'],
  [TransactionType.REFUND_ACCORDING_TO_CUSTOMER_REQUEST, 'CL'],
  [TransactionType.REQUEST_A_CARD_REFUND, 'CL'],
  [TransactionType.REQUEST_TO_ADJUST_PAYMENT_CONTENT, 'CL'],
  [TransactionType.CHANGE_OCCUPATION_PERSONAL, 'PS'],
  [TransactionType.CHANGE_DOB_GENDER, 'PS'],
  [TransactionType.CASHLESS_M9, 'PC'],
  [TransactionType.CASHLESS_D6, 'PC'],
  [TransactionType.CASHLESS_D8, 'PC'],
  [TransactionType.CASHLESS_D9, 'PC'],
  // [TransactionType.AML_RED_FLAG_ACCEPT, 'PC'],
  // [TransactionType.AML_RED_FLAG_DECLINE, 'PC']
  [TransactionType.OTHER_MAJOR_ALTERATION, 'PS'],
  [TransactionType.OTHER_MINOR_ALTERATION, 'PS'],
  [TransactionType.OTHER_PAYOUT, 'PS'],
  [TransactionType.AUTO_DEBIT_TOPUP_ACCOUNT_CANCEL, 'PS'],
  [TransactionType.AUTO_DEBIT_TOPUP_ACCOUNT_REQUEST, 'PS'],
  [TransactionType.BILLING_CHANGE_CORPORATE, 'PS']
])

export const TransactionLabel = (type: TransactionType | null): string =>
  type ? mapTransactionType.get(type) || '-' : '-'

interface SelectOption {
  label: string
  value: string
  category: string
}

export const TransactionTypeSelectOptions: SelectOption[] = Object.keys(TransactionType)
  .map((_transactionType) => {
    const transactionType = _transactionType as keyof typeof TransactionType
    return {
      label: mapTransactionType.get(TransactionType[transactionType]),
      value: TransactionType[_transactionType as keyof typeof TransactionType],
      category: mapTransactionTypeWithCategory.get(TransactionType[transactionType])
    } as SelectOption
  })
  .filter((x) => {
    return !!x.label && !!x.value && !!x.category
  })

export const getTransactionTypeToServiceType2DArray = (): Array<[TransactionType, string]> =>
  Object.keys(TransactionType).map((_transactionType) => {
    return [TransactionType[_transactionType as keyof typeof TransactionType], `ServiceType:${_transactionType}`]
  })

export const mapTransactionTypeToServiceType = new Map<TransactionType, string>(
  getTransactionTypeToServiceType2DArray()
)
