import { Maybe } from '@mxt/zio/codec'
import * as t from 'io-ts'
import { LasStatus } from '../LasStatus'
import { PoliciesReceived } from '../PoliciesReceived'
import { SourceType } from '../SourceType'
import { TransactionType } from '../TransactionType'
import { PaymentData } from './PaymentData'

const Base = <C extends t.Mixed>(codec: C) =>
  t.intersection([
    t.type({
      transactionType: t.literal(TransactionType.PRUCASH_PAYMENT)
    }),
    codec
  ])

const payload = t.type({
  body: Maybe(
    t.type({
      cashOutOptions: Maybe(
        t.array(
          t.union([
            PaymentData.BankTransfer,
            PaymentData.EWalletMomo,
            PaymentData.PayPremium,
            PaymentData.Premium,
            PaymentData.PayLoan,
            PaymentData.TopUp,
            PaymentData.Reinstatement,
            PaymentData.NoType,
            PaymentData.RepayOpl,
            PaymentData.RepayApl,
            PaymentData.Other,
            PaymentData.Reins,
            PaymentData.PaidAtBank,
            PaymentData.CashAtCounter
          ])
        )
      ),
      totalPayoutAmount: Maybe(t.string),
      policiesReceived: Maybe(t.array(PoliciesReceived))
    })
  ),
  additionalProperties: Maybe(
    t.type({
      totalPayoutAmount: t.string,
      deathDate: Maybe(t.string),
      inheritanceFlag: Maybe(t.string)
    })
  ),
  lasStatus: Maybe(t.union([t.literal(LasStatus.SUCCESS), t.literal(LasStatus.FAILED), t.undefined]))
})

const payloadPulse4Ops = t.type({
  body: Maybe(
    t.type({
      cashOutOptions: Maybe(
        t.array(
          t.union([
            PaymentData.BankTransfer,
            PaymentData.EWalletMomo,
            PaymentData.PayPremium,
            PaymentData.Premium,
            PaymentData.PayLoan,
            PaymentData.TopUp,
            PaymentData.Reinstatement,
            PaymentData.NoType,
            PaymentData.RepayOpl,
            PaymentData.RepayApl,
            PaymentData.Other,
            PaymentData.Reins,
            PaymentData.PaidAtBank,
            PaymentData.CashAtCounter
          ])
        )
      ),
      policy: Maybe(
        t.type({
          attributesExt: t.type({
            inheritanceFlag: t.string
          }),
          owners: t.type({
            clientId: t.string,
            dateOfDeath: t.string
          })
        })
      )
    })
  ),
  documents: Maybe(
    t.array(
      t.type({
        name: t.string,
        url: t.string
      })
    )
  ),
  additionalProperties: Maybe(
    t.type({
      totalPayoutAmount: t.string,
      deathDate: Maybe(t.string),
      inheritanceFlag: Maybe(t.string)
    })
  ),
  lasStatus: Maybe(t.union([t.literal(LasStatus.SUCCESS), t.literal(LasStatus.FAILED), t.undefined]))
})

const Ekiosk = Base(
  t.type({
    source: t.literal(SourceType.EKIOSK),
    payload
  })
)

const Pulse = Base(
  t.type({
    source: t.literal(SourceType.PULSE),
    payload
  })
)

const Pulse4Ops = Base(
  t.type({
    source: t.literal(SourceType.PULSE4OPS),
    payload: payloadPulse4Ops
  })
)

const Zalo = Base(
  t.type({
    source: t.literal(SourceType.ZALO),
    payload: t.type({
      body: Maybe(
        t.type({
          cashOutOptions: Maybe(
            t.array(
              t.union([
                PaymentData.BankTransfer,
                PaymentData.EWalletMomo,
                PaymentData.PayPremium,
                PaymentData.Premium,
                PaymentData.PayLoan,
                PaymentData.TopUp,
                PaymentData.Reinstatement,
                PaymentData.NoType,
                PaymentData.RepayOpl,
                PaymentData.RepayApl,
                PaymentData.Other,
                PaymentData.Reins,
                PaymentData.PaidAtBank,
                PaymentData.CashAtCounter
              ])
            )
          ),
          policy: Maybe(
            t.type({
              attributesExt: t.type({
                inheritanceFlag: t.string
              }),
              owners: t.type({
                clientId: t.string,
                dateOfDeath: t.string
              })
            })
          )
        })
      ),
      additionalProperties: Maybe(
        t.type({
          totalPayoutAmount: t.string,
          deathDate: Maybe(t.string),
          inheritanceFlag: Maybe(t.string)
        })
      ),
      lasStatus: Maybe(t.union([t.literal(LasStatus.SUCCESS), t.literal(LasStatus.FAILED), t.undefined]))
    })
  })
)

const Banca = Base(
  t.type({
    source: t.literal(SourceType.BANCA),
    payload
  })
)

const PruGreat = Base(
  t.type({
    source: t.literal(SourceType.PRUGREAT),
    payload
  })
)

const PruOnline = Base(
  t.type({
    source: t.literal(SourceType.PRUONLINE),
    payload: payloadPulse4Ops
  })
)

export const CashOutPrucash = t.union([Ekiosk, Pulse, Pulse4Ops, Zalo, Banca, PruGreat, PruOnline])
export type CashOutPrucash = t.TypeOf<typeof CashOutPrucash>
