import { Task, Throwable, ZIO } from '@mxt/zio'
import { HttpClient } from '@mxt/zio-http-client'
import { Alert } from '@pulseops/common'
import * as A from 'fp-ts/Array'
import { pipe } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as R from 'fp-ts/Record'
import * as t from 'io-ts'
import * as NEA from 'fp-ts/NonEmptyArray'
import { NbuwState } from '../nbuw/nbuw-state'
import { GeneralInfoApi } from './general-info-api'
import { GeneralInfoState } from './general-info-state'
import { Benefit } from './model/benefit'
import { ContractDetail } from './model/contract-detail'
import { ContractId } from './model/contract-id'
import { Employee } from './model/employee'
import { HistoryId, HistoryType } from './model/history-type'
import { Shareholder } from './model/shareholder'
import i18next from 'i18next'

export namespace DetailService {
  export const contract = (contractId: ContractId) => {
    const state = GeneralInfoState.forContract(contractId)
    const nbuwState = NbuwState.forContract(contractId)

    const getInfo: Task<ContractDetail> = pipe(
      GeneralInfoApi.getContractInfo(contractId),
      ZIO.map(({ dataContractInfo }): ContractDetail => dataContractInfo),
      GeneralInfoState.cache(state.detail.lens)
    )

    const refreshInfo = pipe(
      GeneralInfoApi.getContractInfo(contractId),
      ZIO.map(({ dataContractInfo }): ContractDetail => dataContractInfo),
      ZIO.flatMap(state.detail.set)
    )

    const updateInfo = (data: ContractDetail) =>
      pipe(
        GeneralInfoApi.updateContractInfo(data),
        ZIO.flatMap(state.detail.set),
        ZIO.flatMap(() => Alert.alertM('Cập nhật thành công'))
      )

    const getShareholderList = ({ size, page, isFocused, calledTimes }: { size: number; page: number, isFocused: boolean, calledTimes: number }): Task<string[]> => {
      const service = state.shareholder.size(size)
      const pageLens = service.pageLens(page)

      return pipe(
        GeneralInfoApi.getAllShareholder({ ...contractId, size, page }),
        ZIO.flatMap(service.updatePage),
        GeneralInfoState.cacheWithRule(pageLens, isFocused && page === 0 && calledTimes === 0)
      )
    }

    const refreshShareholder = (shareHolderKey: string) =>
      pipe(
        GeneralInfoApi.getShareholder({
          ...contractId,
          shareHolderKey
        }),
        ZIO.absolveOption(() => Throwable(`shareholder shareHolderKey not found: ${shareHolderKey}`)),
        ZIO.flatMap((item) => state.shareholder.updateItems([item]))
      )

    const updateShareholder = (data: Shareholder) =>
      pipe(
        GeneralInfoApi.updateShareholder(data),
        ZIO.flatMap(state.shareholder.updateItem),
        ZIO.flatMap(() => Alert.alertM('Cập nhật thành công'))
      )

    const getEmployeeList = ({
      size,
      page,
      isFocused,
      calledTimes
    }: {
      size: number
      page: number
      isFocused: boolean
      calledTimes: number
    }): Task<string[]> => {
      const service = state.employee.size(size)
      const pageLens = service.pageLens(page)

      return pipe(
        GeneralInfoApi.getAllEmployee({ ...contractId, size, page }),
        ZIO.flatMap(service.updatePage),
        GeneralInfoState.cacheWithRule(pageLens, isFocused && page === 0 && calledTimes === 0)
      )
    }

    const updateEmployeeStatus = ({
      memberRemovalEffectiveDate,
      refundedPremium,
      employeeKey
    }: {
      memberRemovalEffectiveDate: string
      refundedPremium: number
      employeeKey: string
    }) => {
      return pipe(
        GeneralInfoApi.updateStatus({ ...contractId, memberRemovalEffectiveDate, refundedPremium, employeeKey }),
        ZIO.flatMap(() => refreshEmployee(employeeKey)),
        ZIO.flatMap(() => Alert.alertM('Lưu thành công'))
      )
    }

    const deleteEmployee = (employeeKey: string) => {
      return pipe(
        GeneralInfoApi.deleteEmployee({ ...contractId, employeeKey }),
        ZIO.map(({ errorCodes }) => pipe(errorCodes, O.fromNullable, O.chain(NEA.fromArray))),
        ZIO.tap(
          O.fold(
            () => ZIO.unit,
            (errorCodes) => pipe(errorCodes.map((err) => i18next.t(`business:${err.code}`)).join('\n'), Alert.alertM)
          )
        )
      )
    }

    const refreshEmployee = (employeeKey: string) =>
      pipe(
        GeneralInfoApi.getEmployee({
          ...contractId,
          employeeKey
        }),
        ZIO.absolveOption(() => Throwable(`employee employeeKey not found: ${employeeKey}`)),
        ZIO.flatMap((item) => state.employee.updateItems([item]))
      )

    const updateEmployee = (data: Employee) =>
      pipe(
        GeneralInfoApi.updateEmployee(data),
        ZIO.flatMap(state.employee.updateItem),
        ZIO.tap(() => nbuwState.employee.clear),
        ZIO.tap(() => nbuwState.employeeUw.clear),
        ZIO.flatMap(() => Alert.alertM('Cập nhật thành công'))
      )

    const getBenefit = pipe(
      GeneralInfoApi.getAllBenefit(contractId),
      ZIO.map((res) => res.data),
      GeneralInfoState.cache(state.benefit.lens)
    )

    const updateBenefit = (benefits: Benefit[]) =>
      pipe(
        GeneralInfoApi.updateBenefit(benefits),
        ZIO.flatMap(state.benefit.set),
        ZIO.flatMap(() => Alert.alertM('Cập nhật thành công')),
        ZIO.catchAll((err) =>
          pipe(
            err.source,
            O.fromPredicate(HttpClient.isAxiosError),
            O.filter(({ response }) => response?.status === 400),
            O.chain(({ response }) =>
              pipe(
                response?.data,
                t.type({
                  errorDescription: t.record(t.string, t.string)
                }).decode,
                O.fromEither,
                O.map((r) =>
                  pipe(
                    r.errorDescription,
                    R.toArray,
                    A.reduce('', (msg, [k, v]) => `${k}: ${v}\n${msg}`)
                  )
                )
              )
            ),
            O.fold(
              (): Task<void> => ZIO.fail(err),
              (msg) => Alert.alertM(msg)
            )
          )
        )
      )

    const getHistory =
      (id: HistoryId) =>
      ({ size, page }: { size: number; page: number }) => {
        const service = state.history.size(size)
        const pageLens = service.pageLens(page)

        return pipe(
          id,
          (id) => {
            switch (id.historyType) {
              case HistoryType.Contract:
                return GeneralInfoApi.getContractInfoHistory({ ...contractId, size, page })
              case HistoryType.Shareholder:
                return GeneralInfoApi.getShareholderHistory({
                  ...contractId,
                  size,
                  page,
                  shareHolderKey: id.shareHolderKey
                })
              case HistoryType.Employee:
                return GeneralInfoApi.getEmployeeHistory({
                  ...contractId,
                  size,
                  page,
                  employeeKey: id.employeeKey
                })
              case HistoryType.Benefit:
                return GeneralInfoApi.getBenefitHistory({
                  ...contractId,
                  size,
                  page
                })
            }
          },
          ZIO.flatMap(service.updatePage),
          GeneralInfoState.cache(pageLens)
        )
      }

    return {
      state,
      getInfo,
      refreshInfo,
      updateInfo,
      getShareholderList,
      refreshShareholder,
      updateShareholder,
      getEmployeeList,
      refreshEmployee,
      updateEmployee,
      getBenefit,
      updateBenefit,
      getHistory,
      updateEmployeeStatus,
      deleteEmployee
    }
  }
}
