import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { CollapseView, PulseOpsFormat, TaskDetail } from '@pulseops/common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text } from 'react-native'
import { PCReason } from '../mock'
import { styles, useStyles } from './styles'

export const HistoryReceipt = (props: { detail: TaskDetail.VeriCashLess }) => {
  const {
    detail: { coreRecipts, coreJournals, rawCoreData, coreDiscounts }
  } = props

  const classes = useStyles()

  const { t: ca } = useTranslation('Cashless')

  const coreNoncash =
    rawCoreData.coreNonCash && rawCoreData.coreNonCash.length > 0
      ? rawCoreData.coreNonCash.map((c) => ({
          ...c,
          transactions: c.transactions ?? [],
          totalAmount: c.transactions?.reduce<number>((pre, cur) => pre + cur.amount ?? 0, 0) ?? 0
        }))
      : undefined

  const successReceipt = React.useMemo(
    () =>
      [
        ...(coreRecipts ?? []),
        ...(coreDiscounts ?? []),
        ...(coreJournals ?? []),
        ...(coreNoncash ? coreNoncash : [])
      ].filter((c) => !!c.receiptNo),
    [coreRecipts, coreJournals, coreNoncash]
  )

  const totalAmount = React.useMemo(() => {
    const totalReceiptAmount = successReceipt.reduce<number>((pre, cur) => pre + (cur.totalAmount ?? 0), 0) ?? 0
    return totalReceiptAmount
  }, [successReceipt])

  if (successReceipt.length === 0) {
    return <View />
  }

  return (
    <View>
      <CollapseView initCollapse={true} label={<Text style={styles.expandTitle}>{ca('receiptList')}</Text>}>
        <View>
          <TableContainer component={Paper}>
            <Table>
              <TableHead className={classes.tableHeader}>
                <TableCell className={classes.cellNoWrap}>
                  <Text style={styles.titleColumn}>{ca('no.')}</Text>
                </TableCell>
                <TableCell className={classes.cellNoWrap}>
                  <Text style={styles.titleColumn}>{ca('policyNo')}</Text>
                </TableCell>
                <TableCell className={classes.cellNoWrap}>
                  <Text style={styles.titleColumn}>{ca('receiptNo')}</Text>
                </TableCell>
                <TableCell className={classes.cellNoWrap}>
                  <Text style={styles.titleColumn}>{ca('receiptDate')}</Text>
                </TableCell>
                <TableCell className={classes.cellNoWrap}>
                  <Text style={styles.titleColumn}>{ca('receiptAmount')}</Text>
                </TableCell>
                <TableCell className={classes.cellNoWrap}>
                  <Text style={styles.titleColumn}>{ca('Action')}</Text>
                </TableCell>
                <TableCell className={classes.cellNoWrap}>
                  <Text style={styles.titleColumn}>{ca('paymentType')}</Text>
                </TableCell>
                <TableCell className={classes.cellNoWrap}>
                  <Text style={styles.titleColumn}>{ca('user')}</Text>
                </TableCell>
              </TableHead>
              <TableBody>
                {(successReceipt ?? []).map((receipt, index) => {
                  return (
                    <TableRow key={`list_row_table_re${index}`}>
                      {/* 'no.' */}
                      <TableCell>
                        <Text style={styles.rowTableText}>{index + 1}</Text>
                      </TableCell>
                      {/* 'policy no.' */}
                      <TableCell>
                        <Text style={styles.rowTableText}>{receipt.policyNumber ?? receipt.proposalNumber ?? '-'}</Text>
                      </TableCell>
                      {/* receiptNo */}
                      <TableCell>
                        <Text style={styles.rowTableText}>{receipt.receiptNo ?? '-'}</Text>
                      </TableCell>
                      {/* receiptDate */}
                      <TableCell>
                        <Text style={styles.rowTableText}>{receipt.receiptDate ?? '-'}</Text>
                      </TableCell>
                      {/* receiptAmount */}
                      <TableCell>
                        <Text style={styles.rowTableText}>
                          {PulseOpsFormat.thousandSepartor(receipt.totalAmount) + ' VND'}
                        </Text>
                      </TableCell>
                      {/* Action */}
                      <TableCell>
                        <Text style={styles.rowTableText}>{PCReason.getDescriptionActionByCode(receipt.action)}</Text>
                      </TableCell>
                      {/* paymentType */}
                      <TableCell>
                        <Text style={styles.rowTableText}>{receipt.paymentType ?? '-'}</Text>
                      </TableCell>
                      {/* user */}
                      <TableCell>
                        <Text style={styles.rowTableText}>{receipt.lastUpdatedBy ?? '-'}</Text>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Text style={styles.totalText}>
            {ca('totalAmount')}:{' '}
            <Text style={styles.titleColumn}>{PulseOpsFormat.thousandSepartor(totalAmount)} VND</Text>
          </Text>
        </View>
      </CollapseView>
    </View>
  )
}
