import { AdminPageService, AppContext, assets, BreadCrumb, DataSource, ErrorHandling, Input, ModalComponent, SelectSearch, sharedStyle, useMobile } from '@pulseops/common'
import { useIsFocused } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View, Text, useWindowDimensions, TouchableOpacity, ScrollView, LayoutChangeEvent } from 'react-native'
import { AdminPageStackParamList } from './AdminPageStackParamList'
import { IBGeneralTable } from '@pulseops/inbound'
import { SectionCol, SectionRow } from '@pulseops/submission/common'
import { Controller, useForm } from 'react-hook-form'
import { ConfigBankFormData } from './ConfigTableForm'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
export const ConfigBankListScreen = (props: StackScreenProps<AdminPageStackParamList>) => {
  const { isWide } = useMobile()
  const isFocused = useIsFocused()
  const { t } = useTranslation()
  const { width, height } = useWindowDimensions()
  const [headerWidth, setHeaderWidth] = React.useState(0)
  const { showToast } = React.useContext(AppContext.AppContextInstance)
  const [isOpenAddPopup, setIsOpenAddPopup] = React.useState<boolean>(false)
  const [bankList, setBankList] = React.useState<Array<AdminPageService.BankInfo>>([])
  const configBankForm = useForm<ConfigBankFormData>({
    defaultValues: {
      bankCode: '',
      pulseBankCode: '',
      bankName: '',
      status: undefined
    }
  })
  const getBreadCrumbList = React.useMemo(() => {
    const navigationList = isFocused ? [
      {
        title: t('Admin page'),
        navigate: () => props.navigation.navigate("AdminPageScreen")
      },
      {
        title: t('Bank List'),
        navigate: null
      }
    ] : []
    return navigationList
  }, [isFocused])

  const filterColumnList = {
    code: "",
    codePulse: "",
    name: ""
  }

  const displayedColumns = [
    { label: 'No', field: 'no' },
    { label: 'Bank Code', field: 'code' },
    { label: 'Pulse Bank Code', field: 'codePulse' },
    // { label: 'Bank NameVi', field: 'name' },
    { label: 'Bank Name', field: 'name' },
    { label: 'Status', field: 'status' },
    // {
    //   label: '',
    //   field: '',
    //   disabled: true,
    //   render: () => {
    //     return (
    //       <TouchableOpacity onPress={() => { }}>
    //         <assets.OBPencilGray />
    //       </TouchableOpacity>
    //     )
    //   }
    // }
  ]
  React.useEffect(() => {
    if (isFocused) {
      getInitialBankList()
    }
  }, [isFocused])

  const getInitialBankList = () => {
    pipe(
      AdminPageService.getBankList(),
      ZIO.tap((bankResponse) => {
        const customedBanks = bankResponse.data.map((item, index) => ({
          ...item,
          no: index + 1,
          codePulse: item.codePulse || '',
          name: item.name || '',
          nameEn: item.nameEn || '',
        }))
        setBankList(customedBanks)
        return ZIO.unit
      }),
      ZIO.tapError((_) => {
        setBankList([])
        return ZIO.unit
      }),
      ErrorHandling.run()
    )
  }

  const onOpenCreateNewBankPopup = () => {
    configBankForm.reset({
      bankCode: '',
      pulseBankCode: '',
      bankName: '',
      status: { label: '2', value: '2' }
    })
    setIsOpenAddPopup(true)
  }

  const validateInsertedNewData = (formData: ConfigBankFormData) => {
    let isValidData = true
    if (bankList.some((x) => x.code === formData.bankCode)) {
      showToast('Duplicate Bank Code, Please check again', 'error')
      isValidData = false
    } else if (bankList.some((x) => x.codePulse === formData.pulseBankCode)) {
      showToast('Duplicate Pulse Bank Code, Please check again', 'error')
      isValidData = false
    } else if (bankList.some((x) => x.name === formData.bankName)) {
      showToast('Duplicate Bank Name, Please check again', 'error')
      isValidData = false
    }
    return isValidData
  }

  const onAddNewBankEvent = async () => {
    const isValidForm = await configBankForm.trigger()
    const formData = configBankForm.getValues()
    if (isValidForm && validateInsertedNewData(formData)) {
      const submitedData: AdminPageService.NewBankBodyInput = {
        code: formData.bankCode,
        codePulse: formData.pulseBankCode,
        name: formData.bankName,
        nameEn: formData.bankName,
        status: formData.status?.value || '',
      }
      pipe(
        AdminPageService.addNewBank(submitedData),
        ZIO.map((response) => {
          if (response) {
            showToast(t('message:OB0040'), 'success')
            getInitialBankList()
            setIsOpenAddPopup(false)
          } else {
            showToast(t('message:OB0042'), 'error')
          }
        }),
        ZIO.mapError((error) => {
          showToast(t('message:OB0042'), 'error')
          return error
        }),
        ZIO.unsafeRun({})
      )
    }
  }

  const getAddModalPopup = () => {
    return (
      <ModalComponent
        children={
          <View style={configStyles.modalBodyContainer}>
            <SectionRow>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={configBankForm.control}
                  name="bankCode"
                  rules={{
                    required: {
                      value: true,
                      message: t(`message:MS020001`, { field: t('Bank Code') })
                    },
                    validate: (value) => {
                      if (!value || !value.trim()) {
                        return `${t(`message:MS020001`, { field: t('Bank Code') })}`
                      } else {
                        return true
                      }
                    }
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                    return (
                      <Input
                        title={t('Bank Code')}
                        placeholder={''}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        disabled={false}
                        required
                        maxLength={120}
                        errorMessage={!!value ? "" : error?.message}
                      />
                    )
                  }}
                />

              </SectionCol>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={configBankForm.control}
                  name="pulseBankCode"
                  rules={{
                    required: {
                      value: true,
                      message: t(`message:MS020001`, { field: t('Pulse Bank Code') })
                    },
                    validate: (value) => {
                      if (!value || !value.trim()) {
                        return `${t(`message:MS020001`, { field: t('Pulse Bank Code') })}`
                      } else {
                        return true
                      }
                    }
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                    return (
                      <Input
                        title={t('Pulse Bank Code')}
                        placeholder={''}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        disabled={false}
                        required
                        maxLength={120}
                        errorMessage={!!value ? "" : error?.message}
                      />
                    )
                  }}
                />
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={configStyles.modalSecondLine}>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={configBankForm.control}
                  name="bankName"
                  rules={{
                    required: {
                      value: true,
                      message: t(`message:MS020001`, { field: t('Bank Name') })
                    },
                    validate: (value) => {
                      if (!value || !value.trim()) {
                        return `${t(`message:MS020001`, { field: t('Bank Name') })}`
                      } else {
                        return true
                      }
                    }
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                    return (
                      <Input
                        title={t('Bank Name')}
                        placeholder={''}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        disabled={false}
                        required
                        maxLength={120}
                        errorMessage={!!value ? "" : error?.message}
                      />
                    )
                  }}
                />
              </SectionCol>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={configBankForm.control}
                  name={"status"}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <SelectSearch
                      label={t('Status')}
                      options={[{ label: '2', value: '2' }]}
                      value={value}
                      onChange={(val) => {
                        onChange(val)
                      }}
                      onBlur={onBlur}
                      disabled={true}
                      popupIcon={<assets.ArrowDownDropdownIcon />}
                      placeholder={t('common:Select')}
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
          </View>
        }
        visible={isOpenAddPopup}
        onClose={() => setIsOpenAddPopup(false)}
        title={'Create New Bank'}
        actions={[
          {
            text: t('Cancel'),
            type: 'outline',
            disabled: false,
            loading: false,
            onlyWide: false,
            style: { marginRight: 10 },
            action: () => setIsOpenAddPopup(false)
          },
          {
            text: t('Add'),
            type: 'filled',
            disabled: false,
            loading: false,
            onlyWide: false,
            style: {},
            action: () => onAddNewBankEvent()
          }
        ]}
        centerTitle
        modalWidth={850}
      >
      </ModalComponent>
    )
  }

  return (
    <View style={[configStyles.container]}>
      <View style={configStyles.headerContainer}>
        <BreadCrumb navigation={getBreadCrumbList} />
      </View>
      <View style={[configStyles.bodyContainer]}>
          {/* <Text style={configStyles.bodyTitle}>{t("Bank List")}</Text> */}
          <View style={configStyles.buttonContainer}>
            <TouchableOpacity onPress={() => onOpenCreateNewBankPopup()}>
              <View style={[sharedStyle.button, sharedStyle.btnRed, configStyles.buttonContent]}>
                <Text style={[sharedStyle.textButtonRed]}>{t('Add')}</Text>
              </View>
            </TouchableOpacity>
          </View>

          <IBGeneralTable
            dataTable={displayedColumns}
            data={bankList}
            autoPaging
            maxHeight={height - height * 0.25}
            maxWidth={width - width * 0.025}
            maxWidthContainer
            filterColumn={filterColumnList}
          >
          </IBGeneralTable>
      </View>
      {getAddModalPopup()}
    </View>
  )
}
const configStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff'
  },
  headerContainer: {
    flexDirection: 'row',
    backgroundColor: '#FFFFFF',
    alignItems: 'center',
    paddingHorizontal: 16,
    shadowColor: 'rgba(0, 0, 0, 0.04)',
    shadowOffset: {
      width: 0,
      height: 4
    },
    shadowOpacity: 0.3,
    shadowRadius: 4.65,
    elevation: 8
  },
  bodyContainer: {
    width: '100%',
    paddingHorizontal: 20,
    // paddingTop: 10
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  buttonContent: {
    paddingHorizontal: 10,
    marginVertical: 10
  },
  bodyTitle: {
    color: '#000000',
    fontSize: 24,
    fontWeight: 'bold',
    // marginBottom: 10
  },
  modalBodyContainer: {
    paddingHorizontal: 15,
    paddingVertical: 20
  },
  modalSecondLine: {
    marginTop: 20
  },
})