import React from 'react'
import { StyleSheet, ScrollView, Text, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import {
  OBAcceptPopup,
  OBRejectPopup,
  OBResendSMSPopup,
  OBTabView,
  OBTabViewInfo,
  OBUnsuspendPopup
} from '../ob-common'
import { OBGeneralInfomation, OBSpecialNotePopup } from './ob-general-information'
import { OBRelatedInformation } from './ob-related-information'
import {
  CallHeaderInfo,
  CallOutDetailContext,
  MessageInfo,
  OBCallOutFooter,
  OBCallOutHeader,
  OBCallOutHeaderMessage
} from './ob-common'
import { OBCallingResultDetails } from './ob-calling-result-details'
import { OBActionHistory } from './ob-action-history'
import { OBCallOutPopup, OBCallOutPopupConst, OBCallingAvayaBlock, OBCallingRecordForm } from './ob-call-out-popup'
import { StackScreenProps } from '@react-navigation/stack'
import { OutboundParamList } from '../OutboundParamList'
import {
  AppContext,
  AuthService,
  AuthState,
  ErrorHandling,
  InternalConst,
  InternalFeedbackService,
  ModalComponent,
  Permission,
  PulseOpsApi,
  PulseOpsFormat,
  PulseOpsService,
  RBAC,
  SubmissionService,
  assets
} from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import {
  OBActionHistoryService,
  OBGeneralService,
  OBTaskService,
  OBQCScreen,
  OBQcService,
  OBInternalSuspendService,
  OBResponseInternalSuspendDataList,
  OBCallingResultDetailService,
  OBCallOutPopupService,
  requestRefreshAllBasket,
  OBCallProgramTypeCode,
  OBCustomerAnswer,
  OBTaskDetail,
  CustomerAnswerPayload,
  ADDHOCImportedParamPayload,
  OBBasketType,
  OBREQCScreen,
  OBREQCService,
  OBCallProgram
} from '@pulseops/outbound'
import { ZIO } from '@mxt/zio'
import { useIsFocused } from '@react-navigation/native'
import { OBInternalSuspend } from './ob-internal-suspend'
import { useLoading } from '@mxt/zio-react'
import moment from 'moment'
import { useForm } from 'react-hook-form'
import * as O from 'fp-ts/lib/Option'

type NotificationData = {
  isSpecialPolicy: boolean | null | undefined
  contactIndicator: string | null | undefined
  customerBehavior: string | null | undefined
  promotionInfos: string[] | null | undefined
}

export type SpecialList = {
  id: string | null | undefined
  policyNum: string
  warningDate: string
  userId: string
  shortDesc: string
  detailDesc: string
}

type CallingResult = {
  nameVi: string
  nameEn: string
  code: string
  hasCallBackTime: boolean | null | undefined
  isManualSuspend: boolean | null | undefined
}

export const OBCallOutDetailScreen = (props: StackScreenProps<OutboundParamList, 'OBCallOutDetailScreen'>) => {
  const { t, i18n } = useTranslation()
  const [showSpecialNote, setShowSpecialNote] = React.useState<boolean>(false)
  const [isActiveTabIndex, setIsActiveTabIndex] = React.useState<number>(0)
  const policyNumber = props.route.params?.policyNumber || ''
  const caseId = props.route.params?.caseID || ''
  const isQC = props.route.params?.basket === 'QC'
  const isREQC = props.route.params?.basket === OBBasketType.REQC
  const authData = pipe(AuthState.auth.get)
  const permissions: string[] = pipe(RBAC.permissionWithNoCache, ErrorHandling.runDidUpdate([authData])) || []
  const [tabList, setTabList] = React.useState<OBTabViewInfo[]>([])
  const [notification, setNotification] = React.useState<NotificationData | null>(null)
  const [notifyCustomerBehavior, setNotifyCustomerBehavior] = React.useState<string | null>(null)
  const [isSuspendEnable, setSuspendEnable] = React.useState<boolean>(false)
  const [isDisabledSubmit, setDisabledSubmit] = React.useState<boolean>(true)
  const [channel, setChannel] = React.useState<string>('')
  const [detail, setDetail] = React.useState<OBTaskDetail>()
  const processInstanceId = props.route.params?.processInstanceId || ''
  const taskId = props.route.params?.taskId || ''
  const [showUnsuspendPopup, setShowUnsuspendPopup] = React.useState<boolean>(false)
  const isSuspendBasket = props.route.params?.basket === 'SUSPENDED'
  const isVerBasket = props.route.params?.basket === 'VER'
  const [isSavedCalloutPopupData, setIsSavedCalloutPopupData] = React.useState<boolean>(true)
  const [isSavedCallingResultDetail, setIsSavedCallingResultDetail] = React.useState<boolean>(true)
  const [specialList, setSpecialList] = React.useState<SpecialList[]>([])
  const [isShowCallOutPopup, setIsShowCallOutPopup] = React.useState<boolean>(false)
  const [isSavedQCScreen, setIsSavedQCScreen] = React.useState(true)
  const [isNotInputedRequiredField, setIsNotInputedRequiredField] = React.useState(false)
  const [isNotREQCInputedRequiredField, setIsNotREQCInputedRequiredField] = React.useState(false)
  const [callingResultList, setCallingResultList] = React.useState<CallingResult[]>([])
  const [isUpdatedChangeContactInfo, setIsUpdatedChangeContactInfo] = React.useState<boolean>(false)
  const [isSendComunication, setIsSendComunication] = React.useState(false)
  const [isOpenedAvayaCallBlock, setIsOpenedAvayaCallBlock] = React.useState(false)
  const [avayaCallID, setAvayaCallID] = React.useState<string>('')
  const [extensionNumber, setExtensionNumber] = React.useState<string>('')
  const [avayaCallingPhoneNumber, setAvayaCallingPhoneNumber] = React.useState<string>('')
  const [avayaCallDate, setAvayaCallDate] = React.useState<Date>()
  const [callTypeName, setCallTypeName] = React.useState<string>('')
  const [isSavedAfterCallingAvaya, setIsSavedAfterCallingAvaya] = React.useState<boolean>(true)
  const [phoneType, setPhoneType] = React.useState<string>('')
  const isFocused = useIsFocused()
  const userMail = pipe(AuthService.userInfo, ErrorHandling.runDidMount())?.email ?? ''
  const isInquiry = props.route.params?.isInquiry !== '0' || userMail !== props.route.params?.assignee
  const category = props.route.params?.category || ''
  const transactionType = props.route.params?.transactionType || ''
  // const executionTime = detail && detail?.outBoundExecutionTime ? detail?.outBoundExecutionTime : 0
  const [loading, bindLoader] = useLoading(false)
  const callingRecordForm = useForm<OBCallingRecordForm.OBCallingRecord>({
    defaultValues: OBCallingRecordForm.defaultFormValues,
    mode: 'onChange'
  })

  const [isOpenAcceptModal, setIsOpenAcceptModal] = React.useState<boolean>(false)
  const [isOpenRejectModal, setIsOpenRejectModal] = React.useState<boolean>(false)
  const [isOpenResendSMSModal, setIsOpenResendSMSModal] = React.useState<boolean>(false)
  const [IDCCustomerAnswerPayload, setIDCCustomerAnswerPayload] = React.useState<CustomerAnswerPayload>()
  const [ADDHOCImportedParamData, setADDHOCImportedParamData] = React.useState<ADDHOCImportedParamPayload>()
  const [A7s10Config, setA7s10Config] = React.useState<string[]>([])
  const [isSavedREQCScreen, setIsSavedREQCScreen] = React.useState(true)
  const scrollRef = React.useRef<ScrollView | null>(null)

  const [isDisableSuspend, setIsDisableSuspend] = React.useState<boolean>(true)
  const [isReloadedPolicyOwnerTab, setIsReloadedPolicyOwnerTab] = React.useState<boolean>(false)
  const [promotionFlag, setPromotionFlag] = React.useState<{
    hasPromotion: boolean
    countPromotion: number
    typePromotion: string | null
    premiumRein: string | null
  }>()

  const userEmail = pipe(
    AuthService.userInfo,
    ZIO.map((res) => res.email),
    ErrorHandling.runDidMount()
  )

  // const { A12S3List, A12S4List } = pipe(
  //   ZIO.zipPar(
  //     isQC && props.route.params?.isInquiry === '0' && isFocused ? OBQcService.getA12S3Table() : ZIO.succeed([]),
  //     isQC && props.route.params?.isInquiry === '0' && isFocused ? OBQcService.getA12S4Table() : ZIO.succeed([])
  //   ),
  //   ZIO.map(([A12S3List, A12S4List]) => {
  //     return {
  //       A12S3List: A12S3List,
  //       A12S4List: A12S4List
  //     }
  //   }),
  //   ErrorHandling.runDidUpdate([isQC, isFocused, isInquiry])
  // ) || { A12S3List: [], A12S4List: [] }

  const { changeBreadcrumb, showGlobalLoading, showToast, setIsHiddenTopMenu } = React.useContext(
    AppContext.AppContextInstance
  )
  const headerData: CallHeaderInfo[] = [
    {
      title: t('Outbound:OBTaskManagement:CallingProgram'),
      value: props.route.params?.callProgram || '-'
    },
    {
      title: t('Outbound:OBTaskManagement:Channel'),
      value: channel ?? '-'
    },
    {
      title: t('Outbound:OBTaskManagement:PolicyNumber'),
      value: policyNumber
    },
    {
      title: t('common:PolicyOwner'),
      value: (props.route.params?.clientNumber ?? '') + ' - ' + (props.route.params?.policyOwner ?? '')
    }
  ]

  const messageList: Array<MessageInfo> = [
    {
      messageText: t('Outbound:OBCalloutDetail:SpecialNote'),
      textStyle: callDetailStyles.messageContainerText3,
      onClickTextEvent: () => {
        setShowSpecialNote(true)
      },
      visible: notification && notification?.isSpecialPolicy ? notification?.isSpecialPolicy : false,
      icon: <assets.OBSpecialNote />
    },
    {
      messageText: notifyCustomerBehavior ?? '',
      textStyle: callDetailStyles.messageContainerText2,
      visible: !!notifyCustomerBehavior,
      icon: <assets.OBCustomerBehavior />,
      messageTitle: t('Outbound:OBCalloutDetail:CustomerBehaviorField')
    },
    {
      messageText: notification?.contactIndicator ?? '',
      textStyle: callDetailStyles.messageContainerText1,
      visible: !!notification?.contactIndicator,
      icon: <assets.OBCallIndicator />,
      messageTitle: t('Outbound:OBCallOutPopup:CallIndicator')
    },
    {
      messageText: notification?.promotionInfos ?? [],
      textStyle: callDetailStyles.messageContainerText1,
      visible: !!notification?.promotionInfos,
      icon: <assets.OBPromotionIcon />,
      messageTitle: t('Outbound:OBCalloutDetail:Promotion')
    }
  ]

  console.log('isOpenedAvayaCallBlock:' + isOpenedAvayaCallBlock)

  window.onbeforeunload = function (event) {
    if (isOpenedAvayaCallBlock || !isSavedAfterCallingAvaya) {
      event.preventDefault()
      console.log('beforeunload event triggered')
      event.returnValue = true
    }
  }

  React.useEffect(() => {
    if (!!props.route.params?.clientNumber) {
      pipe(
        transactionType !== OBCallProgramTypeCode.IDC
          ? OBCallingResultDetailService.getCustomerBehaviorByClientNumber(props.route.params?.clientNumber)
          : ZIO.succeed({} as OBCallingResultDetailService.OBCustomerBehaviorResponse),
        ZIO.map((responseData) => {
          responseData && responseData?.behavior
            ? setNotifyCustomerBehavior(responseData?.behavior)
            : setNotifyCustomerBehavior('')
        }),
        bindLoader,
        ZIO.unsafeRun({})
      )
    }
  }, [props.route.params?.clientNumber])

  React.useEffect(() => {
    showGlobalLoading(loading)
  }, [loading])

  // React.useEffect(() => {
  //   if (userMail) {
  //     setIsInquiry(props.route.params?.isInquiry !== '0' || userMail !== props.route.params?.assignee)
  //   } else {
  //     setIsInquiry(props.route.params?.isInquiry !== '0')
  //   }
  // }, [userMail, props.route.params?.isInquiry])

  React.useEffect(() => {
    if (permissions.length > 0 && isFocused && detail) {
      checkPermissionTabView()
    }
  }, [permissions, i18n.language, isFocused, detail])

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            props.navigation.replace('HomeScreen')
          }
        },
        props.route.params?.isInquiry !== '1'
          ? {
            title: t('home:TaskManagement'),
            navigate: () => props.navigation.navigate('UserDrawer', { screen: 'OBTaskManagementScreen' })
          }
          : {
            title: t('menu:ServiceInquiry'),
            navigate: () => props.navigation.navigate('UserDrawer', { screen: 'OBServiceInquiryScreen' })
          },
        { title: caseId ? caseId : '-', navigate: null }
      ])
    }
  }, [isFocused])

  React.useEffect(() => {
    if (processInstanceId || (isSuspendBasket && taskId && isFocused))
      pipe(
        OBTaskService.getCaseDetails(isSuspendBasket ? taskId : processInstanceId),
        ZIO.map((res) => {
          if (res.payload && !!res.payload?.payloadJSONString) {
            let IDCAnswerPayload: CustomerAnswerPayload | ADDHOCImportedParamPayload = JSON.parse(
              res.payload?.payloadJSONString
            )
            console.log('IDCAnswerPayload:' + JSON.stringify(IDCAnswerPayload))
            if (isIDCCustomerAnswerPayload(IDCAnswerPayload)) {
              setIDCCustomerAnswerPayload(IDCAnswerPayload)
            } else if (isADDHOCImportedParamData(IDCAnswerPayload)) {
              setADDHOCImportedParamData(IDCAnswerPayload)
            }
          }
          res.payload?.saleChannel && setChannel(res.payload.saleChannel)
          setDetail(res)
        }),
        ZIO.unsafeRun({})
      )
  }, [processInstanceId, taskId, isFocused])

  React.useEffect(() => {
    ResetCallOutDetailData()
    if (caseId && !isInquiry && isFocused) {
      pipe(
        ZIO.zipPar(
          InternalFeedbackService.getCaseStatusByCaseID(caseId),
          OBInternalSuspendService.getInternalSuspendHistoryListOB(caseId),
          isVerBasket && !isInquiry
            ? OBActionHistoryService.getActionHistoryList(processInstanceId, 0, 10)
            : ZIO.succeed(undefined),
          isVerBasket && !isInquiry ? OBCallOutPopupService.getCallingResultList() : ZIO.succeed([])
        ),
        ZIO.map(([caseStatus, historyList, actionHistory, callResultList]) => {
          const status = historyList && historyList?.find((item) => item.status === InternalConst.Status.ACTIVE)
          const actionHistoryData = actionHistory?.records || []
          setDisabledSubmit(true)
          // setCallingResultList(callResultList)
          setSuspendEnable(!!status && caseStatus !== 'Pending')
          if (actionHistoryData.length > 0) {
            setIsDisableSuspend(false)
            const actionDate = actionHistoryData[0].savedDate ? new Date(actionHistoryData[0].savedDate) : undefined
            const agingAction = actionDate && PulseOpsFormat.getDifferenceInDays(new Date(), actionDate)
            agingAction !== undefined && checkDisableSubmitButton(agingAction)
          } else {
            setIsDisableSuspend(true)
          }
        }),
        bindLoader,
        ZIO.unsafeRun({})
      )
    }
  }, [caseId, props.route.params?.basket, isFocused, isInquiry])

  React.useEffect(() => {
    if (
      transactionType === OBCallProgramTypeCode.IDC &&
      isFocused &&
      !isInquiry &&
      !!IDCCustomerAnswerPayload &&
      processInstanceId &&
      detail
    ) {
      showGlobalLoading(true)
      pipe(
        OBActionHistoryService.getActionHistoryList(processInstanceId, 0, 10),
        ZIO.flatMap((historyActionList) => {
          const historyActionListData = historyActionList.records
          if (historyActionList && historyActionListData.length > 0) {
            const actionItem = historyActionListData[0]
            const isCallBack = actionItem.callingInfoDTO && !!actionItem.callingInfoDTO.callbackTime ? 'Y' : 'N'
            const executionTime = detail && detail?.outBoundExecutionTime ? detail?.outBoundExecutionTime : 0
            const A7s10RequestData: OBCallOutPopupService.A7s10RequestInfo = {
              transactionTypeWF: transactionType,
              idcType: IDCCustomerAnswerPayload.idcType,
              callReceiver: actionItem.callingInfoDTO.callReceiver || '',
              callOutCode: actionItem.callingInfoDTO.callingResult || '',
              callOutCount: executionTime,
              isCallBack: isCallBack
            }
            return pipe(OBCallOutPopupService.getA7s10ConfigList(A7s10RequestData))
          } else {
            return ZIO.succeed([])
          }
        }),
        ZIO.map((A7s10ConfigResponse) => {
          setA7s10Config(A7s10ConfigResponse)
          showGlobalLoading(false)
          return A7s10ConfigResponse
        }),
        ZIO.mapError((err) => {
          setA7s10Config([])
          showGlobalLoading(false)
          return err
        }),
        ZIO.unsafeRun({})
      )
    }
  }, [IDCCustomerAnswerPayload, isSavedCalloutPopupData, detail])

  React.useEffect(() => {
    if (policyNumber && isFocused && permissions) {
      pipe(
        ZIO.zipPar(
          OBGeneralService.getNotification(policyNumber),
          OBCallOutPopupService.getDoNotCallList(),
          permissions.includes(Permission['ViewPromotionInfoOBTaskDetail'])
            ? OBGeneralService.getPromotionInfo(policyNumber)
            : ZIO.succeed(undefined)
        ),
        ZIO.flatMap(([res, doNotCallList, promotionInfos]) => {
          if (res && res.data) {
            const filteredData = {
              ...res.data,
              contactIndicator: pipe(
                doNotCallList.find((p) => p.codeLas === res.data?.contactIndicator),
                O.fromNullable,
                O.fold(
                  () => '',
                  (doNotItem) => (i18n.language === 'en' ? doNotItem.nameEn : doNotItem.nameVi)
                )
              ),
              promotionInfos: promotionInfos?.data?.havePromotion
                ? promotionInfos?.data.promotions?.map(
                  (pro) =>
                    `• ${pro.name} - ${pro.description} - ${t('Outbound:OBNotification:From')}: ${moment(
                      pro.fromDate
                    ).format('DD/MM/YYYY')} - ${t('Outbound:OBNotification:To')}: ${moment(pro.toDate).format(
                      'DD/MM/YYYY'
                    )}`
                )
                : undefined
            }
            setNotification(filteredData)
            setPromotionFlag({
              hasPromotion: promotionInfos?.data?.havePromotion ?? false,
              countPromotion: promotionInfos?.data?.promotions?.length ?? 0,
              typePromotion:
                promotionInfos?.data?.promotions?.length === 1 ? promotionInfos?.data?.promotions[0].type : null,
              premiumRein:
                promotionInfos?.data?.promotions?.length === 1
                  ? promotionInfos?.data?.promotions[0].reinAmount || '0'
                  : null
            })
          }
          if (res && res.data && res.data.isSpecialPolicy) {
            return pipe(
              SubmissionService.getPolicyWarning(policyNumber),
              ZIO.map((res) => {
                setSpecialList(res)
                return ZIO.unit
              })
            )
          }
          return ZIO.succeed({})
        }),
        ZIO.unsafeRun({})
      )
    }
  }, [policyNumber, isFocused, permissions])

  React.useEffect(() => {
    setIsHiddenTopMenu(isOpenedAvayaCallBlock || !isSavedAfterCallingAvaya)
  }, [isOpenedAvayaCallBlock, isSavedAfterCallingAvaya])

  function isIDCCustomerAnswerPayload(arg: any): arg is CustomerAnswerPayload {
    return arg.idcType !== undefined
  }

  function isADDHOCImportedParamData(arg: any): arg is ADDHOCImportedParamPayload {
    return arg.adhocCode !== undefined
  }

  const ResetCallOutDetailData = () => {
    setIsSavedCallingResultDetail(true)
    setIsUpdatedChangeContactInfo(false)
    setIsShowCallOutPopup(false)
    setIsSendComunication(false)
    callingRecordForm.reset(OBCallingRecordForm.defaultFormValues)
    callingRecordForm.setValue('callScript', null)
    setIsSavedQCScreen(true)
    setIsNotInputedRequiredField(false)
    setExtensionNumber('')
    setAvayaCallID('')
    setAvayaCallingPhoneNumber('')
    setAvayaCallDate(undefined)
    setCallTypeName('')
    setIsSavedAfterCallingAvaya(true)
    setChannel('')
    setIsOpenAcceptModal(false)
    setIsOpenRejectModal(false)
    setIsOpenResendSMSModal(false)
    setIDCCustomerAnswerPayload(undefined)
    setSuspendEnable(false)
    setIsSavedREQCScreen(true)
    setIsNotREQCInputedRequiredField(false)
    setDetail(undefined)
    setIsReloadedPolicyOwnerTab(false)
  }

  const checkPermissionTabView = () => {
    const listTabPermission: OBTabViewInfo[] = []

    if (
      permissions.includes(Permission['ViewGeneralInfoPolicyOBTaskDetail']) ||
      permissions.includes(Permission['ViewGeneralInfoPolicyOwnerOBTaskDetail']) ||
      permissions.includes(Permission['ViewGeneralInfoLifeAssuredOBTaskDetail']) ||
      permissions.includes(Permission['ViewGeneralInfoAgentOBTaskDetail']) ||
      permissions.includes(Permission['ViewGeneralInfoSpecialOBTaskDetail'])
    ) {
      listTabPermission.push({
        tabName: t('Tab:GeneralInfo'),
        tabKey: 1
      })
    }

    transactionType === OBCallProgramTypeCode.IDC &&
      permissions.includes(Permission['ViewCustomerAnswerOBTaskDetail']) &&
      listTabPermission.push({
        tabName: t('Outbound:OBCustomerAnwser:CustomerAnwser'),
        tabKey: 7
      })

    permissions.includes(Permission['ViewCallingResultDetailsOBTaskDetail']) &&
      listTabPermission.push({
        tabName: t('Outbound:OBCalloutDetail:CallingResult'),
        tabKey: 2
      })

    permissions.includes(Permission['ViewInternalSuspendOBTaskDetail']) &&
      transactionType !== OBCallProgramTypeCode.IDC &&
      listTabPermission.push({
        tabName: t('Tab:InternalSuspend'),
        tabKey: 3
      })

    permissions.includes(Permission['ViewActionHistoryOBTaskDetail']) &&
      listTabPermission.push({
        tabName: t('Outbound:OBCalloutDetail:ActionHistory'),
        tabKey: 4
      })

    if (
      permissions.includes(Permission['ViewRelatedInfoCommentOBTaskDetail']) ||
      permissions.includes(Permission['ViewRelatedInfoHistoryOBTaskDetail']) ||
      permissions.includes(Permission['ViewRelatedInfoDocumentOBTaskDetail']) ||
      permissions.includes(Permission['ViewRelatedInfoGoingRequestOBTaskDetail'])
    ) {
      listTabPermission.push({
        tabName: t('Outbound:OBCalloutDetail:RelatedInfomation'),
        tabKey: 5
      })
    }

    if (
      isQC ||
      isREQC ||
      (isInquiry && detail && detail.isQCCheck && detail.isQCCount && detail.isQCSampleCase && detail.isQCVolumeCase)
    ) {
      listTabPermission.push({
        tabName: t('Outbound:OBCalloutDetail:QC'),
        tabKey: 6
      })
    }

    if (isREQC || (isInquiry && detail && detail.isReQCCheck && !detail.isReQCOverdue)) {
      listTabPermission.push({
        tabName: t('Outbound:OBCalloutDetail:REQC'),
        tabKey: 8
      })
    }

    listTabPermission.length > 0 && setIsActiveTabIndex(listTabPermission[0].tabKey)
    setTabList([...listTabPermission])
  }

  const getParamsSubmit = () =>
    pipe(
      ZIO.zipPar(
        OBActionHistoryService.getActionHistoryList(processInstanceId, 0, 10),
        OBGeneralService.getPolicyOwner(policyNumber),
        OBCallingResultDetailService.getCallingResultDetailList(processInstanceId),
        OBCallOutPopupService.getA7s9ConfigList(''),
        OBCallOutPopupService.getA7S2ConfigList('')
      ),
      ZIO.map(([actionList, poInfo, callingResult, A7s9ConfigList, A7s2ConfigList]) => {
        let checkValidateSaveCallingDetail = true
        let checkValidateMustSuspend = true
        let variables = []
        const actionListData = actionList.records || []
        const userInfoMail = userMail
        if (actionListData && actionListData.length > 0) {
          // const isCheckSaveDetail = OBCallOutPopupConst.A7S2List.some((p)=>p.code === actionList[0].callingInfoDTO.callReceiver && p.flag === 'Y')
          const isCheckSaveDetail = A7s2ConfigList.some(
            (p) => p.callReceiverCode === actionListData[0].callingInfoDTO.callReceiver && p.haveCallResultDetails
          )
          if (isCheckSaveDetail) {
            checkValidateSaveCallingDetail = false
            if (callingResult.details && callingResult.details?.length > 0) {
              const checkCallingResultNotNA = callingResult.details.filter((item) => item.answerCode !== 'A001')
              if (checkCallingResultNotNA.length > 0 || callingResult.note) checkValidateSaveCallingDetail = true
            }
          }

          if (A7s9ConfigList.length > 0 && actionListData[0].callingInfoDTO.callingResult) {
            const callResultCode = actionListData[0].callingInfoDTO.callingResult
            const isManualSuspend = A7s9ConfigList.some(
              (p) => p.callOutCode === callResultCode && p.functionCode === 'MS'
            )
            // const findCallResult = callingResultList.find((item) => item.code === callResult)
            if (isManualSuspend) checkValidateMustSuspend = false
          }

          if (actionListData[0].callingInfoDTO.callReceiver) {
            variables.push({
              name: 'callReceiver',
              type: 'string',
              value: actionListData[0].callingInfoDTO.callReceiver
            })
          }
          if (actionListData[0].callingInfoDTO.callingResult) {
            variables.push({
              name: 'callOutCode',
              type: 'string',
              value: actionListData[0].callingInfoDTO.callingResult
            })
          }
          if (actionListData[0].callingInfoDTO.callbackTime) {
            variables.push({
              name: 'callBackAppointmentTime',
              type: 'string',
              value: actionListData[0].callingInfoDTO.callbackTime
            })
          } else {
            variables.push({ name: 'callBackAppointmentTime', type: 'string', value: '' })
          }
        }

        if (
          poInfo &&
          poInfo.mobilePhoneLatest &&
          detail &&
          detail?.payload?.mobilePhoneNumber &&
          poInfo.mobilePhoneLatest !== detail?.payload?.mobilePhoneNumber
        ) {
          variables.push({ name: 'compareMobileFlag', type: 'boolean', value: true })
        } else {
          variables.push({ name: 'compareMobileFlag', type: 'boolean', value: false })
        }

        return { checkValidateSaveCallingDetail, checkValidateMustSuspend, userInfoMail, variables }
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )

  const onSubmitActionEvent = async (outcome?: string) => {
    const { checkValidateSaveCallingDetail, checkValidateMustSuspend, userInfoMail, variables } =
      await getParamsSubmit()
    if (!isSavedCalloutPopupData || !isSavedCallingResultDetail) {
      showToast(t('message:OB0007'), 'error')
    } else if (!checkValidateSaveCallingDetail) {
      showToast(t('message:OB0012'), 'error')
    } else if (!checkValidateMustSuspend) {
      showToast(t('message:OB0047'), 'error')
    } else {
      return submitOBCaseID(userInfoMail, variables, outcome)
    }
  }

  const submitOBCaseID = (userInfoMail: string, variables: any, outcome?: string) => {
    return pipe(
      OBTaskService.submitOBTask({
        taskId,
        author: userInfoMail,
        variables: variables,
        outcome: outcome
      }),
      ZIO.tap((res: any) => {
        transactionType === OBCallProgramTypeCode.IDC && closeAcceptRejectResendPopup()
        if (res && 'code' in res && res.code === 'OB0051') {
          showToast(t('message:OB0051'), 'error')
        } else {
          showToast(t('requestInfo:CompleteSuccess'), 'success')
          onHandleActionSuccess()
        }
        showGlobalLoading(false)
        return ZIO.unit
      }),
      ZIO.catchAll((e) => {
        transactionType === OBCallProgramTypeCode.IDC && closeAcceptRejectResendPopup()
        showToast(e?.source?.message ?? '', 'error')
        showGlobalLoading(false)
        return ZIO.fail(e)
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }

  const onUnSuspendEvent = () => {
    setShowUnsuspendPopup(true)
  }
  // const onEndActionEvent = () => {}
  const onCalloutClickEvent = () => {
    setIsShowCallOutPopup(true)
  }

  const onCloseCallOutPopup = () => {
    setIsShowCallOutPopup(false)
  }

  const validateForPlanDate = (planSuspendDate: string) => {
    const MIN_DATE = new Date()
    if (planSuspendDate) {
      const getHours = moment(planSuspendDate, 'DD/MM/yyyy HH:mm:ss').toDate().getHours()
      const planDate = moment(planSuspendDate, 'DD/MM/yyyy HH:mm:ss').toDate()
      planDate.setHours(getHours + 7)
      if (planDate.getTime() < MIN_DATE.getTime()) {
        showToast(t('message:MS990006'), 'error')
        return false
      }
    }
    return true
  }

  // console.log('isNotInputedRequiredField:' + isNotInputedRequiredField)

  const onQCCompleteEvent = () => {
    if (!isSavedQCScreen) {
      showToast(t('message:OB0007'), 'error')
    } else {
      pipe(
        ZIO.zipPar(AuthService.userInfo, OBQcService.getHistorySave(processInstanceId)),
        ZIO.flatMap(([userInfo, qcHistoryInfo]) => {
          let variables = []
          let qcScoreRating = 0
          const isNotAnwserQuestion = qcHistoryInfo.qcCriteriaList.some((p) => !p.answerValueCode)
          const isNotComment = !qcHistoryInfo.qcCommonInfo.comment
          if (isNotInputedRequiredField || isNotAnwserQuestion || isNotComment) {
            showToast(t('message:OB0013'), 'error')
            return ZIO.unit
          } else {
            if (qcHistoryInfo.qcCommonInfo) {
              qcScoreRating = Number(
                (
                  ((qcHistoryInfo.qcCommonInfo.qcTotalPassScore || 0) /
                    (qcHistoryInfo.qcCommonInfo.qcTotalScore || 0)) *
                  100
                ).toFixed(2)
              )
            }
            // const topicList = [
            //   ...new Map(
            //     qcHistoryInfo.qcCriteriaList.map((item) => [
            //       item['a12s1Criteria'].topicCode,
            //       {
            //         topicCode: item.a12s1Criteria.topicCode ?? '',
            //         topicENDesc: item.a12s1Criteria.topicENDesc ?? '',
            //         topicVNDesc: item.a12s1Criteria.topicVNDesc ?? ''
            //       }
            //     ])
            //   ).values()
            // ]
            // if (!isQcCCE) {
            //   if (topicList && topicList.length > 0) {
            //     const ADHTopicCode = topicList[1].topicCode
            //     const CCMTopicCode = topicList[0].topicCode
            //     actionResult = pipe(
            //       A12S3List.filter((x) => x.topicCode === ADHTopicCode),
            //       O.fromNullable,
            //       O.map((actionList) => {
            //         let resultCode = ''
            //         const failedADHQuestNo = qcHistoryInfo.qcCriteriaList.filter(
            //           (p) => p.a12s1Criteria.topicCode === ADHTopicCode && p.answerValueCode === 'F'
            //         ).length
            //         switch (failedADHQuestNo) {
            //           case 0:
            //             resultCode = pipe(
            //               actionList.find(
            //                 (p) =>
            //                   p.resultTypeCode === 'ACTION' &&
            //                   p.topicCode === ADHTopicCode &&
            //                   p.failedValue === 0 &&
            //                   p.operator === '=='
            //               ),
            //               O.fromNullable,
            //               O.map((topicItem) => topicItem.resultCode),
            //               O.getOrElse(() => '')
            //             )
            //             break
            //           default:
            //             resultCode = pipe(
            //               actionList.find(
            //                 (p) =>
            //                   p.resultTypeCode === 'ACTION' &&
            //                   p.topicCode === ADHTopicCode &&
            //                   p.failedValue === 1 &&
            //                   p.operator === '>='
            //               ),
            //               O.fromNullable,
            //               O.map((topicItem) => topicItem.resultCode),
            //               O.getOrElse(() => '')
            //             )
            //             break
            //         }
            //         return resultCode
            //       }),
            //       O.getOrElse(() => '')
            //     )
            //     serviceResult = pipe(
            //       A12S3List.filter((x) => x.topicCode === CCMTopicCode),
            //       O.fromNullable,
            //       O.map((actionList) => {
            //         const failedADHQuestNo = qcHistoryInfo.qcCriteriaList.filter(
            //           (p) => p.a12s1Criteria.topicCode === CCMTopicCode && p.answerValueCode === 'F'
            //         ).length
            //         switch (failedADHQuestNo) {
            //           case 0:
            //             return pipe(
            //               actionList.find(
            //                 (p) =>
            //                   p.resultTypeCode === 'SERVICE' &&
            //                   p.topicCode === CCMTopicCode &&
            //                   p.failedValue === 0 &&
            //                   p.operator === '=='
            //               ),
            //               O.fromNullable,
            //               O.map((topicItem) => topicItem.resultCode),
            //               O.getOrElse(() => '')
            //             )
            //           case 1:
            //             return pipe(
            //               actionList.find(
            //                 (p) =>
            //                   p.resultTypeCode === 'SERVICE' &&
            //                   p.topicCode === CCMTopicCode &&
            //                   p.failedValue === 1 &&
            //                   p.operator === '=='
            //               ),
            //               O.fromNullable,
            //               O.map((topicItem) => topicItem.resultCode),
            //               O.getOrElse(() => '')
            //             )
            //           case 2:
            //             return pipe(
            //               actionList.find(
            //                 (p) =>
            //                   p.resultTypeCode === 'SERVICE' &&
            //                   p.topicCode === CCMTopicCode &&
            //                   p.failedValue === 2 &&
            //                   p.operator === '=='
            //               ),
            //               O.fromNullable,
            //               O.map((topicItem) => topicItem.resultCode),
            //               O.getOrElse(() => '')
            //             )
            //           default:
            //             return pipe(
            //               actionList.find(
            //                 (p) =>
            //                   p.resultTypeCode === 'SERVICE' &&
            //                   p.topicCode === CCMTopicCode &&
            //                   p.failedValue === 3 &&
            //                   p.operator === '>='
            //               ),
            //               O.fromNullable,
            //               O.map((topicItem) => topicItem.resultCode),
            //               O.getOrElse(() => '')
            //             )
            //         }
            //       }),
            //       O.getOrElse(() => '')
            //     )
            //     outcome = pipe(
            //       A12S4List.find(
            //         (item) =>
            //           item.actionCode === actionResult && item.operator === '&' && item.serviceCode === serviceResult
            //       ),
            //       O.fromNullable,
            //       O.map((matrixItem) => matrixItem.outcomeCode),
            //       O.getOrElse(() => '')
            //     )
            //   }
            // }
            const outcome = qcHistoryInfo.qcCommonInfo?.qcResult ?? ''

            variables.push({ name: 'qcScoreRating', type: 'double', value: qcScoreRating })
            variables.push({ name: 'outcome', type: 'string', value: outcome })

            return pipe(
              PulseOpsApi.taskAction({
                taskId,
                action: 'complete',
                outcome: 'complete',
                author: userInfo.email,
                comment: '',
                variables: variables,
                isCancelPayment: undefined,
                category: category
              }),
              ZIO.tap((_) => {
                showToast(t('requestInfo:CompleteSuccess'), 'success')
                onHandleActionSuccess()
                showGlobalLoading(false)
                return ZIO.unit
              }),
              ZIO.catchAll((e) => {
                showToast(e.source?.message, 'error')
                showGlobalLoading(false)
                return ZIO.fail(e)
              })
            )
          }
        }),
        bindLoader,
        ZIO.unsafeRun({})
      )
    }
  }

  const onREQCCompleteEvent = () => {
    if (!isSavedREQCScreen) {
      showToast(t('message:OB0007'), 'error')
    } else {
      pipe(
        ZIO.zipPar(AuthService.userInfo, OBREQCService.getHistorySave(processInstanceId)),
        ZIO.flatMap(([userInfo, qcHistoryInfo]) => {
          // let reQCActionResult = '',
          // reQCServiceResult = '',
          // reQCOutcome = ''
          let variables = []
          let reQCScoreRating = 0
          const isNotAnwserQuestion = qcHistoryInfo.reQCCriteriaList
            .filter((p) => !!p.a12s1Criteria)
            .some((p) => !p.answerValueCode)
          const isNotComment = !qcHistoryInfo.reQCCommonInfo.comment
          if (isNotREQCInputedRequiredField || isNotAnwserQuestion || isNotComment) {
            showToast(t('message:OB0013'), 'error')
            return ZIO.unit
          } else {
            if (qcHistoryInfo.reQCCommonInfo) {
              reQCScoreRating = Number(
                (
                  ((qcHistoryInfo.reQCCommonInfo.reQCTotalPassScore || 0) /
                    (qcHistoryInfo.reQCCommonInfo.reQCTotalScore || 0)) *
                  100
                ).toFixed(2)
              )
            }
            // const topicList = [
            //   ...new Map(
            //     qcHistoryInfo.reQCCriteriaList.map((item) => [
            //       item['a12s1Criteria'].topicCode,
            //       {
            //         topicCode: item.a12s1Criteria.topicCode ?? '',
            //         topicENDesc: item.a12s1Criteria.topicENDesc ?? '',
            //         topicVNDesc: item.a12s1Criteria.topicVNDesc ?? ''
            //       }
            //     ])
            //   ).values()
            // ]
            // if (topicList && topicList.length > 0) {
            //   const ADHTopicCode = topicList[1].topicCode
            //   const CCMTopicCode = topicList[0].topicCode
            //   reQCActionResult = pipe(
            //     A12S3List.filter((x) => x.topicCode === ADHTopicCode),
            //     O.fromNullable,
            //     O.map((actionList) => {
            //       let resultCode = ''
            //       const failedADHQuestNo = qcHistoryInfo.reQCCriteriaList.filter(
            //         (p) => p.a12s1Criteria.topicCode === ADHTopicCode && p.answerValueCode === 'F'
            //       ).length
            //       switch (failedADHQuestNo) {
            //         case 0:
            //           resultCode = pipe(
            //             actionList.find(
            //               (p) =>
            //                 p.resultTypeCode === 'ACTION' &&
            //                 p.topicCode === ADHTopicCode &&
            //                 p.failedValue === 0 &&
            //                 p.operator === '=='
            //             ),
            //             O.fromNullable,
            //             O.map((topicItem) => topicItem.resultCode),
            //             O.getOrElse(() => '')
            //           )
            //           break
            //         default:
            //           resultCode = pipe(
            //             actionList.find(
            //               (p) =>
            //                 p.resultTypeCode === 'ACTION' &&
            //                 p.topicCode === ADHTopicCode &&
            //                 p.failedValue === 1 &&
            //                 p.operator === '>='
            //             ),
            //             O.fromNullable,
            //             O.map((topicItem) => topicItem.resultCode),
            //             O.getOrElse(() => '')
            //           )
            //           break
            //       }
            //       return resultCode
            //     }),
            //     O.getOrElse(() => '')
            //   )
            //   reQCServiceResult = pipe(
            //     A12S3List.filter((x) => x.topicCode === CCMTopicCode),
            //     O.fromNullable,
            //     O.map((actionList) => {
            //       const failedADHQuestNo = qcHistoryInfo.reQCCriteriaList.filter(
            //         (p) => p.a12s1Criteria.topicCode === CCMTopicCode && p.answerValueCode === 'F'
            //       ).length
            //       switch (failedADHQuestNo) {
            //         case 0:
            //           return pipe(
            //             actionList.find(
            //               (p) =>
            //                 p.resultTypeCode === 'SERVICE' &&
            //                 p.topicCode === CCMTopicCode &&
            //                 p.failedValue === 0 &&
            //                 p.operator === '=='
            //             ),
            //             O.fromNullable,
            //             O.map((topicItem) => topicItem.resultCode),
            //             O.getOrElse(() => '')
            //           )
            //         case 1:
            //           return pipe(
            //             actionList.find(
            //               (p) =>
            //                 p.resultTypeCode === 'SERVICE' &&
            //                 p.topicCode === CCMTopicCode &&
            //                 p.failedValue === 1 &&
            //                 p.operator === '=='
            //             ),
            //             O.fromNullable,
            //             O.map((topicItem) => topicItem.resultCode),
            //             O.getOrElse(() => '')
            //           )
            //         case 2:
            //           return pipe(
            //             actionList.find(
            //               (p) =>
            //                 p.resultTypeCode === 'SERVICE' &&
            //                 p.topicCode === CCMTopicCode &&
            //                 p.failedValue === 2 &&
            //                 p.operator === '=='
            //             ),
            //             O.fromNullable,
            //             O.map((topicItem) => topicItem.resultCode),
            //             O.getOrElse(() => '')
            //           )
            //         default:
            //           return pipe(
            //             actionList.find(
            //               (p) =>
            //                 p.resultTypeCode === 'SERVICE' &&
            //                 p.topicCode === CCMTopicCode &&
            //                 p.failedValue === 3 &&
            //                 p.operator === '>='
            //             ),
            //             O.fromNullable,
            //             O.map((topicItem) => topicItem.resultCode),
            //             O.getOrElse(() => '')
            //           )
            //       }
            //     }),
            //     O.getOrElse(() => '')
            //   )
            //   reQCOutcome = pipe(
            //     A12S4List.find(
            //       (item) =>
            //         item.actionCode === reQCActionResult && item.operator === '&' && item.serviceCode === reQCServiceResult
            //     ),
            //     O.fromNullable,
            //     O.map((matrixItem) => matrixItem.outcomeCode),
            //     O.getOrElse(() => '')
            //   )
            // }
            const reQCOutcome = qcHistoryInfo.reQCCommonInfo ? qcHistoryInfo.reQCCommonInfo?.reQCOutcome ?? '' : ''
            variables.push({ name: 'reQCScoreRating', type: 'double', value: reQCScoreRating })
            // variables.push({ name: 'reQCActionResult', type: 'string', value: reQCActionResult })
            // variables.push({ name: 'reQCServiceResult', type: 'string', value: reQCServiceResult })
            variables.push({ name: 'reQCOutcome', type: 'string', value: reQCOutcome })

            return pipe(
              PulseOpsApi.taskAction({
                taskId,
                action: 'complete',
                outcome: 'complete',
                author: userInfo.email,
                comment: '',
                variables: variables,
                isCancelPayment: undefined,
                category: category
              }),
              ZIO.tap((_) => {
                showToast(t('requestInfo:CompleteSuccess'), 'success')
                onHandleActionSuccess()
                showGlobalLoading(false)
                return ZIO.unit
              }),
              ZIO.catchAll((e) => {
                showToast(e.source?.message, 'error')
                showGlobalLoading(false)
                return ZIO.fail(e)
              })
            )
          }
        }),
        bindLoader,
        ZIO.unsafeRun({})
      )
    }
  }

  const validateForDueDate = (historyInternal: OBResponseInternalSuspendDataList | null) => {
    const MIN_DATE = new Date()
    const statusActiveList =
      historyInternal && historyInternal?.filter((item) => item.status === InternalConst.Status.ACTIVE)
    const findInvalidSuspendCase =
      statusActiveList &&
      statusActiveList?.find((item) => Number(new Date(item.dueDate)?.getTime()) < MIN_DATE.getTime())
    if (findInvalidSuspendCase) {
      showToast(t('message:OB0046'), 'error')
      return false
    }
    return true
  }

  const checkDisableSubmitButton = (agingActionDate: number) => {
    const agingDays = props.route.params?.agingDays
    // console.log(agingActionDate, '<=', agingDays, agingDays !== undefined && agingActionDate <= agingDays, 'checkDisableSubmitButton')
    if (agingDays !== undefined && agingActionDate <= agingDays) setDisabledSubmit(false)
    else setDisabledSubmit(true)
  }

  const onSuspendEvent = () => {
    showGlobalLoading(true)
    pipe(
      ZIO.zipPar(
        InternalFeedbackService.getInternalSuspendExpiredDate(processInstanceId),
        OBInternalSuspendService.getInternalSuspendHistoryListOB(caseId)
      ),
      ZIO.flatMap(([planSuspendDate, historyInternal]) => {
        if (planSuspendDate && validateForPlanDate(planSuspendDate) && validateForDueDate(historyInternal))
          return PulseOpsService.suspendTask(
            taskId ? taskId : '',
            {
              author: userMail,
              message: '',
              expiryDate: PulseOpsFormat.datetoFormat(
                moment(planSuspendDate, 'DD/MM/yyyy HH:mm:ss').toDate(),
                'DD/MM/yyyy HH:mm:ss'
              ),
              communication: false
            },
            false,
            'OB'
          )
        return ZIO.fail(null)
      }),
      ZIO.catchAll((_error) => {
        showGlobalLoading(false)
        if (_error) showToast(t('requestInfo:SuspendFailed'), 'error')
        return ZIO.fail(null)
      }),
      ZIO.tap(() => {
        showGlobalLoading(false)
        showToast(t('requestInfo:SuspendSuccess'), 'success')
        onHandleActionSuccess()
        return ZIO.unit
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }

  const onHandleActionSuccess = () => {
    // props.navigation.navigate('OBTaskManagementScreen')
    requestRefreshAllBasket()
    props.navigation.navigate('UserDrawer', { screen: 'OBTaskManagementScreen' })
  }

  const checkShowCalloutPopup = () => !isInquiry && isShowCallOutPopup && isVerBasket

  const OBHeaderMessageComponent = () => <OBCallOutHeaderMessage messageList={messageList}></OBCallOutHeaderMessage>

  const getPhoneTypeName = (phoneType: string) => {
    switch (phoneType) {
      case 'M': {
        setCallTypeName(t('Outbound:OBCallOutPopup:MobilePhone'))
        break
      }
      case 'H': {
        setCallTypeName(t('Outbound:OBCallOutPopup:HomePhone'))
        break
      }
      case 'C': {
        setCallTypeName(t('Outbound:OBCallOutPopup:CompanyPhone'))
        break
      }
      case 'O': {
        setCallTypeName(t('Outbound:OBCallOutPopup:CallOther'))
        break
      }
      default:
    }
  }

  const onAvayaCallClickEvent = (phoneNumber: string, phoneType: string) => {
    setAvayaCallDate(new Date())
    setAvayaCallingPhoneNumber(phoneNumber)
    getPhoneTypeName(phoneType)
    pipe(
      !!extensionNumber ? ZIO.succeed(extensionNumber) : OBCallOutPopupService.getExtensionNumberList(),
      ZIO.flatMap((extensionNo) => {
        setExtensionNumber(extensionNo)
        return OBCallOutPopupService.makeAvayaCall(extensionNo, phoneNumber, processInstanceId)
      }),
      ZIO.map((callData) => {
        if (!!callData && !callData.includes('Call Failed')) {
          setIsSavedAfterCallingAvaya(false)
          setIsOpenedAvayaCallBlock(true)
          setAvayaCallID(callData)
        } else {
          showToast(t('message:OB0054'), 'error')
          setAvayaCallID('')
        }
        return callData
      }),
      ZIO.mapError((error) => {
        setAvayaCallID('')
        return error
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }

  const onReleaseAvayaCallEvent = () => {
    pipe(
      OBCallOutPopupService.releaseAvayaCall(extensionNumber, processInstanceId),
      ZIO.map((releaseData) => {
        // if(releaseData && !releaseData.includes('Failed')){
        // }
        setIsOpenedAvayaCallBlock(false)
        setIsShowCallOutPopup(true)
        return releaseData
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }

  const onRecheckEndCallEvent = () => {
    const startTime = pipe(
      avayaCallDate,
      O.fromNullable,
      O.fold(
        () => '',
        (item) => moment(item).utc().format('YYYY-MM-DDTHH:mm:ss')
      )
    )
    const endTime = pipe(
      avayaCallDate,
      O.fromNullable,
      O.fold(
        () => '',
        (item) => moment(item).add(6, 'hours').utc().format('YYYY-MM-DDTHH:mm:ss')
      )
    )
    pipe(
      OBCallOutPopupService.searchAvayaCall(avayaCallID, startTime, endTime, processInstanceId),
      ZIO.map((callingSearchArr) => {
        if (callingSearchArr && callingSearchArr.length > 0) {
          setIsOpenedAvayaCallBlock(false)
          setIsShowCallOutPopup(true)
        }
        return callingSearchArr
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }

  const onAcceptClickEvent = () => {
    setIsOpenAcceptModal(true)
  }

  const onRejectClickEvent = () => {
    setIsOpenRejectModal(true)
  }
  const onResendSMSClickEvent = () => {
    setIsOpenResendSMSModal(true)
  }
  const onCloseAcceptModalPopup = () => {
    setIsOpenAcceptModal(false)
  }
  const onCloseRejectModalPopup = () => {
    setIsOpenRejectModal(false)
  }
  const onCloseResendSMSModalPopup = () => {
    setIsOpenResendSMSModal(false)
  }

  const onConfirmAcceptEvent = async () => {
    const { checkValidateSaveCallingDetail, userInfoMail, variables } = await getParamsSubmit()
    if (!isSavedCalloutPopupData || !isSavedCallingResultDetail) {
      showToast(t('message:OB0007'), 'error')
    } else if (!checkValidateSaveCallingDetail) {
      showToast(t('message:OB0012'), 'error')
    } else {
      return submitOBCaseID(userInfoMail, variables, 'complete')
    }
  }

  const onConfirmRejectEvent = async () => {
    const { checkValidateSaveCallingDetail, userInfoMail, variables } = await getParamsSubmit()
    if (!isSavedCalloutPopupData || !isSavedCallingResultDetail) {
      showToast(t('message:OB0007'), 'error')
    } else if (!checkValidateSaveCallingDetail) {
      showToast(t('message:OB0012'), 'error')
    } else {
      return submitOBCaseID(userInfoMail, variables, 'reject')
    }
  }

  const onConfirmResendSMSEvent = async () => {
    const { userInfoMail, variables } = await getParamsSubmit()
    return submitOBCaseID(userInfoMail, variables, 'resendSMS')
  }

  const closeAcceptRejectResendPopup = () => {
    setIsOpenAcceptModal(false)
    setIsOpenRejectModal(false)
    setIsOpenResendSMSModal(false)
  }

  return (
    <CallOutDetailContext.Provider
      value={{
        policyNumber: policyNumber,
        permissions: permissions,
        caseId: caseId,
        processInstanceId: isSuspendBasket ? taskId : processInstanceId,
        taskId: taskId,
        callProgram: props.route.params?.callProgram || '',
        assignee: props.route.params?.assignee || '',
        teamLeader: props.route.params?.teamLeader || '',
        clientNumber: props.route.params?.clientNumber || '',
        transactionType: transactionType,
        isDisabled: isInquiry,
        isSuspendEnable: isSuspendEnable,
        setSuspendEnable: setSuspendEnable,
        isDisableSubmit: isDisabledSubmit,
        setDisabledSubmit: setDisabledSubmit,
        mobilePhoneNumber:
          detail?.payload && detail?.payload?.mobilePhoneNumber ? String(detail?.payload?.mobilePhoneNumber) : '',
        channel: channel,
        isSavedCalloutPopupData: isSavedCalloutPopupData,
        setIsSavedCalloutPopupData: setIsSavedCalloutPopupData,
        isSavedCallingResultDetail: isSavedCallingResultDetail,
        setIsSavedCallingResultDetail: setIsSavedCallingResultDetail,
        callingRecordForm: callingRecordForm,
        aging: props.route.params?.agingDays,
        category: category,
        setIsSavedQCScreen: setIsSavedQCScreen,
        setIsNotInputedRequiredField: setIsNotInputedRequiredField,
        isUpdatedChangeContactInfo: isUpdatedChangeContactInfo,
        setIsUpdatedChangeContactInfo: setIsUpdatedChangeContactInfo,
        isSendComunication: isSendComunication,
        setIsSendComunication: setIsSendComunication,
        isOpenedAvayaCallBlock: isOpenedAvayaCallBlock,
        setIsOpenedAvayaCallBlock: setIsOpenedAvayaCallBlock,
        onAvayaCallClickEvent: onAvayaCallClickEvent,
        avayaCallID: avayaCallID,
        avayaCallingPhoneNumber: avayaCallingPhoneNumber,
        avayaCallDate: avayaCallDate,
        setAvayaCallID: setAvayaCallID,
        setAvayaCallDate: setAvayaCallDate,
        isSavedAfterCallingAvaya: isSavedAfterCallingAvaya,
        setIsSavedAfterCallingAvaya: setIsSavedAfterCallingAvaya,
        phoneType: phoneType,
        setPhoneType: setPhoneType,
        IDCCustomerAnswerPayload: IDCCustomerAnswerPayload,
        scrollRef: scrollRef,
        A7s10Config: A7s10Config,
        executionTime: detail && detail?.outBoundExecutionTime ? detail?.outBoundExecutionTime : 0,
        ADDHOCImportedParamData: ADDHOCImportedParamData,
        setIsSavedREQCScreen: setIsSavedREQCScreen,
        setIsNotREQCInputedRequiredField: setIsNotREQCInputedRequiredField,
        setIsDisableSuspend: setIsDisableSuspend,
        isDisableSuspend: isDisableSuspend,
        isReloadedPolicyOwnerTab: isReloadedPolicyOwnerTab,
        setIsReloadedPolicyOwnerTab: setIsReloadedPolicyOwnerTab,
        promotionFlag: promotionFlag
      }}
    >
      <View style={callDetailStyles.wrapper}>
        {/* call out header */}
        <OBCallOutHeader headerData={headerData} OBCallOutHeaderMessage={OBHeaderMessageComponent}></OBCallOutHeader>
        <ScrollView style={callDetailStyles.container} ref={scrollRef}>
          {/* call out header message */}
          {/* detail tabs */}
          <OBTabView
            isActiveTabIndex={isActiveTabIndex}
            setIsActiveTabIndex={setIsActiveTabIndex}
            data={tabList}
          ></OBTabView>
          {isActiveTabIndex === 1 && <OBGeneralInfomation></OBGeneralInfomation>}
          {isActiveTabIndex === 2 && <OBCallingResultDetails></OBCallingResultDetails>}
          {isActiveTabIndex === 4 && <OBActionHistory></OBActionHistory>}
          {isActiveTabIndex === 3 && (
            <OBInternalSuspend
              disabled={isInquiry}
              processInstanceId={isSuspendBasket ? taskId : processInstanceId}
              businessKey={caseId}
            ></OBInternalSuspend>
          )}
          {isActiveTabIndex === 5 && <OBRelatedInformation></OBRelatedInformation>}
          {isActiveTabIndex === 6 && (isQC || isREQC) && (
            <OBQCScreen mailInfo={userEmail ?? ''} isREQC={isREQC}></OBQCScreen>
          )}
          {isActiveTabIndex === 7 && <OBCustomerAnswer></OBCustomerAnswer>}
          {isActiveTabIndex === 8 && <OBREQCScreen mailInfo={userEmail ?? ''}></OBREQCScreen>}
        </ScrollView>
        {/* call out footer */}
        {(!isInquiry || isSuspendBasket) && (
          <>
            <OBCallOutFooter
              onSubmitActionEvent={onSubmitActionEvent}
              onUnsuspendActionEvent={onUnSuspendEvent}
              onSuspendActionEvent={onSuspendEvent}
              onCalloutClickEvent={onCalloutClickEvent}
              permissions={permissions}
              isSuspendEnable={isSuspendEnable}
              isQC={isQC && !isInquiry}
              isREQC={isREQC}
              onQCCompleteEvent={onQCCompleteEvent}
              isShowUnsuspend={isSuspendBasket}
              isInquiry={isInquiry}
              isDisableSubmit={isDisabledSubmit || isOpenedAvayaCallBlock}
              isShowCallButton={isVerBasket && !isInquiry && !isOpenedAvayaCallBlock}
              onAcceptClickEvent={onAcceptClickEvent}
              onRejectClickEvent={onRejectClickEvent}
              onResendSMSClickEvent={onResendSMSClickEvent}
              onREQCCompleteEvent={onREQCCompleteEvent}
              isDisableSuspend={isDisableSuspend || isOpenedAvayaCallBlock}
            ></OBCallOutFooter>
          </>
        )}
        {checkShowCalloutPopup() && (
          <>
            <OBCallOutPopup onCloseCallOutPopup={onCloseCallOutPopup}></OBCallOutPopup>
          </>
        )}

        {!isInquiry && isVerBasket && (
          <OBCallingAvayaBlock
            isOpenedCallingBlock={isOpenedAvayaCallBlock}
            setIsShowCallOutPopup={setIsShowCallOutPopup}
            onEndCallEvent={onReleaseAvayaCallEvent}
            onRecheckEndCallEvent={onRecheckEndCallEvent}
            callTypeName={callTypeName}
          ></OBCallingAvayaBlock>
        )}
      </View>

      <ModalComponent
        title={t('Outbound:OBNotification:SpecialNote')}
        titleStyle={{ display: 'flex', flexWrap: 'wrap', alignContent: 'center' }}
        visible={showSpecialNote}
        onClose={() => setShowSpecialNote(false)}
        actions={[]}
        centerTitle
        modalWidth={900}
        modalHeight={550}
      >
        <OBSpecialNotePopup dataSource={specialList}></OBSpecialNotePopup>
      </ModalComponent>

      <OBUnsuspendPopup
        onClose={() => setShowUnsuspendPopup(false)}
        open={showUnsuspendPopup}
        onSuccess={() => onHandleActionSuccess()}
        currAssignee={detail?.assignee ?? ''}
        processInstanceId={taskId} // suspend id = processIns
        callProgram={props.route.params?.callProgram ?? ''}
      ></OBUnsuspendPopup>
      <OBAcceptPopup
        isVisible={isOpenAcceptModal}
        onCloseEvent={onCloseAcceptModalPopup}
        onConfirmAcceptEvent={onConfirmAcceptEvent}
      ></OBAcceptPopup>
      <OBRejectPopup
        isVisible={isOpenRejectModal}
        onCloseEvent={onCloseRejectModalPopup}
        onConfirmRejectEvent={onConfirmRejectEvent}
      ></OBRejectPopup>
      <OBResendSMSPopup
        isVisible={isOpenResendSMSModal}
        onCloseEvent={onCloseResendSMSModalPopup}
        onConfirmResendSMSEvent={onConfirmResendSMSEvent}
      ></OBResendSMSPopup>
    </CallOutDetailContext.Provider>
  )
}
const callDetailStyles = StyleSheet.create({
  wrapper: {
    flex: 1
  },
  container: {
    display: 'flex'
  },
  messageContainerText1: {
    color: '#09A4D8',
    display: 'flex',
    gap: '16px',
    lineHeight: 21,
    fontWeight: '500',
    backgroundColor: '#ECF8FC',
    paddingHorizontal: 8,
    borderRadius: 4,
    width: 'fit-content'
  },
  messageContainerText2: {
    marginTop: 10,
    color: '#1C1D1F',
    backgroundColor: '#0000000a',
    borderRadius: 4,
    lineHeight: 21,
    paddingHorizontal: 8,
    fontWeight: '500',
    gap: '4px',
    width: 'fit-content'
  },
  messageContainerText3: {
    // marginTop: 10,
    color: '#ED1B2D',
    textDecorationLine: 'underline',
    backgroundColor: '#FEEDEE',
    borderRadius: 4,
    lineHeight: 21,
    width: 'fit-content',
    fontWeight: '500',
    paddingHorizontal: 8,
    gap: '10px',
    marginLeft: 0
  }
})
