import { Maybe } from '@mxt/zio/codec'
import * as t from 'io-ts'
import { DateFromISOString } from 'io-ts-types'

export const FinalDecisionApiC = t.type({
  faceAmount: t.string,
  closeReInsurance: t.boolean,
  transferToReInsurance: t.boolean
})

export type FinalDecisionApi = t.TypeOf<typeof FinalDecisionApiC>

export const EvaluationApiC = t.type({
  evaluations: Maybe(
    t.array(
      t.type({
        evaluationCode: t.string,
        evaluationType: t.string,
        subject: t.string,
        description: t.string,
        createdDate: t.string,
        createdBy: t.string
      })
    )
  ),
  finalInformation: Maybe(t.string)
})

export type EvaluationApi = t.TypeOf<typeof EvaluationApiC>

// DecisionDetail
export const DecisionDetailC = t.array(
  t.type({
    lifeAssuredNumber: t.string,
    productCode: t.string,
    effectiveDate: t.string,
    productStatus: t.string,
    policyStatusOnEventdate: t.string,
    recission: t.union([t.string, t.type({ label: t.string, value: t.string })]),
    // recission: t.string,
    recissionDate: t.string,
    refundFrom: t.string,
    refundTo: t.string,
    WOP: t.boolean,
    WOPFrom: Maybe(t.string),
    WOPTo: Maybe(t.string)
    // WOPFrom: t.string,
    // WOPTo: t.string
  })
)
export type DecisionDetailApi = t.TypeOf<typeof DecisionDetailC>

// BenefitDetail
export const BenefitDetailApiC = t.array(
  t.type({
    benefitName: t.string,
    eventDate: t.string,
    autoDecision: t.string,
    adjudicatorDecision: t.string,
    declineReason: t.string,
    claimBenefitAmount: t.string,
    excludedAmount: t.string,
    CMPExGAmount: t.string,
    remarks: t.string
  })
)
export type BenefitDetailApi = t.TypeOf<typeof BenefitDetailApiC>

// CalculationDetail
export const CalculationDetailApiC = t.type({
  benefitName: t.string,
  actualExpense: t.string,
  excludedAmount: t.string,
  thirdPartyPaid: t.string,
  socialInsuranceAmount: t.string,
  duration: t.string,
  occurence: t.string,
  paymentAmount: t.string
})
export type CalculationDetailApi = t.TypeOf<typeof CalculationDetailApiC>

export const ReceiptItems = t.type({
  id: Maybe(t.string),
  receiptItemCode: t.type({ label: t.string, value: t.string }),
  dayNumber: t.string,
  duration: t.string,
  // surgeryCode: Maybe(t.type({ label: t.string, value: t.string })),
  surgeryCode: t.union([
    // t.undefined,
    t.null,
    t.type({ label: t.string, value: t.string }),
    t.array(t.type({ label: t.string, value: t.string }))
  ]),
  expenseAmt: t.string,
  thirdPartyAmt: t.string,
  siAmt: t.string,
  claimAmt: t.string,
  excludedAmt: t.string,
  payableAmt: t.string,
  isToggle: t.boolean,
  receiptItemType: Maybe(t.string)
})

export type ReceiptItems = t.TypeOf<typeof ReceiptItems>

// CalculationSummary
export const CalculationSummaryApiC = t.type({
  productCode: t.string,
  claimDecision: t.string,
  // grossPayableAmount: t.string,
  payableAmount: t.string,
  WOP: t.boolean,
  WOPFrom: t.string,
  WOPTo: t.string
  // deductedExpenses: t.string,
  // totalPayableAmount: t.string,
})
export type CalculationSummaryApi = t.TypeOf<typeof CalculationSummaryApiC>

// Allocation
export const AllocationApiC = t.type({
  productCode: t.string,
  payableAmount: t.string,
  schedule: t.string,
  lumpsumPayDateInstallmentDate: t.string,
  paymentFrequency: t.string,
  totalInstallmentNumber: t.string
})
export type AllocationApi = t.TypeOf<typeof AllocationApiC>

// Allocation
export const PayeeInformationApiC = t.type({
  paymentPercentage: t.string,
  remarks: t.string
})
export type PayeeInformationApi = t.TypeOf<typeof PayeeInformationApiC>

export const adjudicationC = t.type({
  finalDecision: Maybe(
    t.type({
      finalInfo: FinalDecisionApiC,
      decisionDetail: Maybe(DecisionDetailC),
      benefitDetail: Maybe(BenefitDetailApiC),
      evaluationInfo: Maybe(EvaluationApiC)
    })
  ),
  calculation: Maybe(
    t.type({
      calculationDetail: Maybe(t.array(CalculationDetailApiC)),
      calculationSummary: Maybe(t.array(CalculationSummaryApiC)),
      summaryDetail: t.type({
        totalNetPayableAmount: Maybe(t.string),
        deductedExpenses: Maybe(t.string),
        totalPayableAmount: Maybe(t.string)
      })
    })
  ),
  allocation: Maybe(
    t.type({
      allocationDetail: t.array(AllocationApiC),
      payeeInformation: PayeeInformationApiC
    })
  ),
  productName: Maybe(t.string),
  claimDecision: Maybe(t.string)
})

export const BenefitClaim = t.type({
  benefitClaimCode: t.string,
  benefitClaimEN: t.string,
  benefitClaimVN: t.string,
  benefitClaimWF: t.string,
  id: t.string,
  eligible: Maybe(t.boolean),
  claimAmount: Maybe(t.string),
  benefitName: Maybe(t.string),
  eventDate: t.union([t.string, t.undefined]),
  autoDecision: t.union([t.string, t.undefined]),
  adjudicatorDecision: Maybe(
    t.type({
      value: t.string,
      label: t.string
    })
  ),
  declineReason: Maybe(
    t.type({
      value: t.string,
      label: t.string
    })
  ),
  claimBenefitAmount: t.union([t.string, t.undefined]),
  excludedAmount: t.union([t.string, t.undefined]),
  CMPExGAmount: t.union([t.string, t.undefined]),
  remarks: t.union([t.string, t.undefined]),
  paymentAmount: t.union([t.string, t.undefined])
})

export const PolicyRiderOrigin = t.type({
  benefitClaimCode: t.array(BenefitClaim),
  benefitClaimEN: t.string,
  componentCode: t.string,
  componentEN: t.string,
  componentVN: t.string,
  componentWF: t.string,
  id: t.string,
  planName: t.string,
  productCode: t.string,
  productEN: t.string,
  sumAssured: Maybe(t.string),
  subTotalSumAssured: Maybe(t.string),
  subStatus: Maybe(t.string),
  lifeAssuredNumber: t.union([t.string, t.undefined]),
  effectiveDate: t.union([t.string, t.undefined]),
  productStatus: t.union([t.string, t.undefined]),
  policyStatusOnEventdate: t.union([t.string, t.undefined]),
  recission: Maybe(
    t.type({
      value: t.string,
      label: t.string
    })
  ),
  recissionDate: Maybe(DateFromISOString),
  refundFrom: Maybe(DateFromISOString),
  refundTo: Maybe(DateFromISOString),
  WOP: t.union([t.boolean, t.undefined]),
  WOPFrom: Maybe(DateFromISOString),
  WOPTo: Maybe(DateFromISOString),
  WOPAmount: t.union([t.string, t.undefined]),
  numberOfWaiver: t.union([t.string, t.undefined])
})
export type PolicyRiderOrigin = t.TypeOf<typeof PolicyRiderOrigin>

export const policyRider = t.type({
  components: t.union([t.array(PolicyRiderOrigin), t.type({})]),
  effectiveDate: Maybe(DateFromISOString),
  status: Maybe(t.string)
})

export const verificationC = t.type({
  subBenefit: Maybe(
    t.array(
      t.type({
        label: t.string,
        value: t.string,
        receiptItems: t.array(ReceiptItems)
      })
    )
  )
})
export type adjudicationApi = t.TypeOf<typeof adjudicationC>
