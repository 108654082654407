// import { pipe } from 'fp-ts/lib/pipeable'
import * as t from 'io-ts'
import { ZIO, Task } from '@mxt/zio'
import { BeneficiaryDesignationData, ChangeClientInfo, RequestAuthFormDataSubmit } from './model'
import { pipe } from 'fp-ts/lib/function'
import { SubmissionService } from './SubmissionService'
import { PulseOpsFormat } from '../Formatter'
import * as O from 'fp-ts/Option'
import { POApi } from '../POApi'
import { Maybe } from '@mxt/zio/codec'
// import { PulseOpsAuth } from '@pulseops/auth'
// import { BeneficiaryDesignationData, ChangeClientInfo } from '../model'
// import { SubmissionService } from '../SubmissionService'
// import { RequestAuthFormDataSubmit } from 'packages/pulse-ops/core/src/entity'
// import { PulseOpsFormat } from '@pulseops/core'

export namespace ChangeClientInfoService {
  export const OccupationItem = t.type({
    id: t.string,
    code: t.string,
    classOccupation: t.string,
    nameEn: t.string,
    nameVn: t.string,
    isEnableCompanyInfo: t.string,
    positionCode: t.string,
    positionName: t.string
  })
  export type OccupationItem = t.TypeOf<typeof OccupationItem>

  export const getDetail = (policyNum: string): Task<ChangeClientInfo.Detail> =>
    pipe(
      ZIO.zipPar(SubmissionService.getClientsByPolicy(policyNum), SubmissionService.getPolicy(policyNum)),
      ZIO.flatMap(([customerList, policyInfo]) =>
        pipe(
          SubmissionService.getClients(
            [
              ...customerList.benList.map((x) => x.clientNumberOfBen),
              ...customerList.laList
                .filter((x) => !(x.clientNumberOfLA === policyInfo.body.owners.id && x.life === '01'))
                .map((x) => x.clientNumberOfLA),
              policyInfo.body.owners.id || '-'
            ]
              .filter(PulseOpsFormat.onlyUniqueArrayFilter)
              .map((x) => ({ clientId: x }))
          ),
          ZIO.map((ClientPolicyList): ChangeClientInfo.Detail => {
            const getRole = (id: string) =>
              id === policyInfo.body.owners.id
                ? 'PolicyOwner'
                : customerList.benList.map((x) => x.clientNumberOfBen).includes(id)
                  ? 'Beneficiary'
                  : customerList.laList.map((x) => x.clientNumberOfLA).includes(id)
                    ? 'LifeAssured'
                    : '-'
            return {
              customerId: policyInfo.body.owners.id || '-',
              customerData: ClientPolicyList.body
                .filter((x) => x.dod === '99999999')
                .map((x) => {
                  return {
                    customerId: x.clientId || '-',
                    idType: x.externalIds?.SOE || '-',
                    idNum: x.externalIds?.SOE_VALUE || '-',
                    issuedAt: '',
                    name: x.firstName,
                    surName: x.surName || '',
                    dod: x.dod || '-',
                    fatca: x.attributesExt.FATCA_TAX_DECLARE !== 'NONE',
                    dob: x.dob || '-',
                    gender: x.sex || '-',
                    nationality: x.countryCode || '-',
                    mobilePhoneCode: x.attributesExt.MOBILE_CODE || '-',
                    mobilePhoneNumber: x.mobilePhone || '-',
                    // email: x.email || '-',
                    email: x.email || '',
                    role: getRole(x.clientId || '-'),
                    countryCode: x.countryCode ?? '',
                    city: x.location?.slice(0, 2) ?? '',
                    district: x.location?.slice(2, 4) ?? '',
                    ward: x.location ?? '',
                    address: x.addressDetails?.PRIMARY.line1 ?? '',
                    foreignAddress: x.attributesExt.FOREIGN_STREET ?? '',
                    foreignCountry: x.attributesExt.FOREIGN_COUNTRY ?? '',
                    nationality2: x.attributesExt.NATIONALITY_2 ?? ''
                  }
                }),
              occupation: {
                job: '-',
                jobSpec: '-',
                title: '-',
                companyName: '-',
                companyAddress: '-',
                salary: '-'
              }
            }
          })
        )
      )
    )

  export const getDataAccess = (policyNum: string): Task<boolean> =>
    pipe(
      ZIO.zipPar(SubmissionService.getClientsByPolicy(policyNum), SubmissionService.getPolicy(policyNum)),
      ZIO.flatMap(([customerList, policyInfo]) =>
        pipe(
          SubmissionService.getClients(
            [
              ...customerList.benList.map((x) => x.clientNumberOfBen),
              ...customerList.laList
                .filter((x) => !(x.clientNumberOfLA === policyInfo.body.owners.id && x.life === '01'))
                .map((x) => x.clientNumberOfLA),
              policyInfo.body.owners.id || '-'
            ]
              .filter(PulseOpsFormat.onlyUniqueArrayFilter)
              .map((x) => ({ clientId: x }))
          ),
          ZIO.map((ClientPolicyList): boolean => {
            return ClientPolicyList.body.some((x) => x.dod === '99999999')
          })
        )
      )
    )

  export const getDuplicatePhoneInfo = (
    phone: string,
    clientNum: string
  ): Task<Array<ChangeClientInfo.DuplicateInfo>> =>
    pipe(
      SubmissionService.checkDuplicatePhone(phone),
      ZIO.flatMap((clientPolicies) =>
        pipe(
          clientPolicies,
          O.fromNullable,
          O.filter((e) => e.body.length > 0),
          O.map((e) => e.body.filter((x) => x.clientNum !== clientNum) ?? []),
          O.fold(
            () => ZIO.succeed([]),
            (clients) =>
              pipe(
                SubmissionService.getClients(
                  clients.map((e) => ({
                    clientId: e.clientNum ?? ''
                  }))
                ),
                ZIO.map((res): Array<ChangeClientInfo.DuplicateInfo> => {
                  return res.body.map(
                    (e, i): ChangeClientInfo.DuplicateInfo => ({
                      clientId: e.clientId ?? '',
                      clientName: e.name ?? '',
                      role: clients[i].role ?? '',
                      secuityNo: res.body && e ? String(e.externalIds?.SOE_VALUE) : '',
                      dob: res.body && e ? String(e.dob) : '',
                      gender: res.body && e ? String(e.sex) : ''
                    })
                  )
                })
              )
          )
        )
      )
    )

  export const getDuplicateEmailInfo = (
    email: string,
    clientNum: string
  ): Task<Array<ChangeClientInfo.DuplicateInfo>> =>
    pipe(
      SubmissionService.checkDuplicateEmail(email),
      ZIO.flatMap((clientPolicies) =>
        pipe(
          clientPolicies,
          O.fromNullable,
          O.filter((e) => e.body.length > 0),
          O.map((e) => e.body.filter((x) => x.clientNum !== clientNum) ?? []),
          O.fold(
            () => ZIO.succeed([]),
            (clients) =>
              pipe(
                SubmissionService.getClients(
                  clients.map((e) => ({
                    clientId: e.clientNum ?? ''
                  }))
                ),
                ZIO.map((res): Array<ChangeClientInfo.DuplicateInfo> => {
                  const clientList =
                    res.body && res.body.length > 0
                      ? res.body.map(
                        (e, i): ChangeClientInfo.DuplicateInfo => ({
                          clientId: res.body && e ? String(e.clientId) : '',
                          clientName: res.body && e ? String(e.name) : '',
                          role: clients[i].role ?? '',
                          secuityNo: res.body && e ? String(e.externalIds?.SOE_VALUE) : '',
                          dob: res.body && e ? String(e.dob) : '',
                          gender: res.body && e ? String(e.sex) : ''
                        })
                      )
                      : []

                  return clientList
                })
              )
          )
        )
      )
    )

  export const submit =
    (
      primaryPolicyNo: string,
      customerId: string,
      data: ChangeClientInfo.DataSubmit,
      documents: BeneficiaryDesignationData.BeneficiaryDocumentFile[]
    ) =>
    (requestauth: RequestAuthFormDataSubmit) =>
      SubmissionService.submit(t.unknown)(
        `wf-api/customer/${customerId}`,
        {
          body: data
        },
        primaryPolicyNo,
        requestauth,
        documents,
        customerId
      )

  export const getOccupationItemByCode = (code: string) =>
    pipe(
      POApi.get(`wf-api/table/t80s1/get-by-code?code=${code}`)(
        t.type({
          // body: Maybe(OccupationItem),
          body: t.union([t.type({}), Maybe(OccupationItem)]),
          responseStatus: Maybe(
            t.type({
              code: Maybe(t.number),
              message: Maybe(t.string)
            })
          )
        })
      ),
      ZIO.map((occupationItem) => {
        return occupationItem.body
      }),
      ZIO.foldM(
        (error) => ZIO.fail(error),
        (data) => ZIO.succeed(data)
      )
    )
}
