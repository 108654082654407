import { AdminPageService, AppContext, assets, BreadCrumb, ErrorHandling, Input, ModalComponent, SelectSearch, sharedStyle } from '@pulseops/common'
import { useIsFocused } from '@react-navigation/native'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, StyleSheet, Text, TouchableOpacity, View, useWindowDimensions } from 'react-native'
import { AdminPageStackParamList } from './AdminPageStackParamList'
import { StackScreenProps } from '@react-navigation/stack'
import { IBGeneralTable } from '@pulseops/inbound'
import { SectionCol, SectionRow } from '@pulseops/submission/common'
import { Controller, useForm } from 'react-hook-form'
import { ConfigBankBranchFormData } from './ConfigTableForm'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import * as O from 'fp-ts/lib/Option'

export const ConfigBankBranchListScreen = (props: StackScreenProps<AdminPageStackParamList>) => {
  const isFocused = useIsFocused()
  const { t, i18n } = useTranslation()
  const { height, width } = useWindowDimensions()
  const [headerWidth, setHeaderWidth] = React.useState(0)
  const [isOpenAddPopup, setIsOpenAddPopup] = React.useState<boolean>(false)
  const { showToast } = React.useContext(AppContext.AppContextInstance)
  const [branchkList, setBranchList] = React.useState<Array<AdminPageService.BankBranchInfo>>([])
  const configBranchForm = useForm<ConfigBankBranchFormData>({
    defaultValues: {
      bank: undefined,
      branchCode: '',
      pulseBankCode: '',
      pulseCode: '',
      branchName: '',
      branchNameEn: '',
    }
  })
  const getBreadCrumbList = React.useMemo(() => {
    const breakCrumbArr = isFocused ? [
      { title: t('Admin page'), navigate: () => props.navigation.navigate("AdminPageScreen") },
      { title: t('Bank Branch List'), navigate: null },
    ] : []
    return breakCrumbArr
  }, [isFocused])

  const filterColumn = {
    code: '',
    bankName: '',
    bankCodePulse: '',
    codePulse: '',
    name: '',
    branchNameEn: ''
  }

  const displayColumns = [
    {
      label: 'No',
      field: 'no'
    },
    {
      label: 'Bank',
      field: 'bankName'
    },
    {
      label: 'Branch Code',
      field: 'code'
    },
    {
      label: 'Branch Name',
      field: 'name'
    },
    {
      label: 'Branch NameEn',
      field: 'branchNameEn'
    },
    {
      label: 'Pulse Bank Code',
      field: 'bankCodePulse'
    },
    {
      label: 'Pulse Code',
      field: 'codePulse'
    },

    // {
    //   label: '',
    //   field: '',
    //   disabled: true,
    //   render: () => {
    //     return (
    //       <TouchableOpacity onPress={() => { }}>
    //         <assets.OBPencilGray />
    //       </TouchableOpacity>
    //     )
    //   }
    // }
  ]

  const { bankOptions } = pipe(
    AdminPageService.getBankList(),
    ZIO.map((response) => {
      const bankOptions = response.data.map((item) => ({ code: item.code, name: item.name || '', nameEn: item.nameEn || '', bankCodePulse: item.codePulse }))
      return {
        bankOptions
      }
    }),
    ErrorHandling.runDidMount({})
  ) || {
    bankOptions: []
  }

  React.useEffect(() => {
    if (isFocused) {
      getInitialBankBranchList()
    }
  }, [isFocused])

  const getInitialBankBranchList = () => {
    pipe(
      AdminPageService.getBankBranchList(),
      ZIO.tap((branchResponse) => {
        const customedBranchs = branchResponse.map((item, index) => ({
          ...item,
          no: index + 1,
          codePulse: item.codePulse || '',
          name: item.name || '',
          branchNameEn: item.branchNameEn || '',
        }))
        setBranchList(customedBranchs)
        return ZIO.unit
      }),
      ZIO.tapError((_) => {
        setBranchList([])
        return ZIO.unit
      }),
      ErrorHandling.run()
    )
  }

  const onChangeBankEvent = (bankCode: string) => {
    const bankCodePulse = pipe(
      bankOptions.find((x) => x.code === bankCode),
      O.fromNullable,
      O.fold(
        () => '',
        (bankItem) => {
          return bankItem.bankCodePulse || ''
        }
      )
    )
    configBranchForm.setValue("pulseBankCode", bankCodePulse)
  }

  const onOpenCreateNewBranchPopup = () => {
    configBranchForm.reset({
      bank: undefined,
      branchCode: '',
      pulseBankCode: '',
      pulseCode: '',
      branchName: '',
      branchNameEn: '',
    })
    setIsOpenAddPopup(true)
  }

  const validateInsertedNewData = (formData: ConfigBankBranchFormData) => {
    let isValidData = true
    if (branchkList.some((x) => x.code === formData.branchCode)) {
      showToast('Duplicate Branch Code, Please check again', 'error')
      isValidData = false
    } else if (branchkList.some((x) => x.codePulse === formData.pulseCode)) {
      showToast('Duplicate Pulse Code, Please check again', 'error')
      isValidData = false
    } else if (branchkList.some((x) => x.name === formData.branchName)) {
      showToast('Duplicate Branch Name, Please check again', 'error')
      isValidData = false
    }
    return isValidData
  }


  const onAddNewBranchEvent = async () => {
    const isValidForm = await configBranchForm.trigger()
    const formData = configBranchForm.getValues()
    if (isValidForm && validateInsertedNewData(formData)) {
      const inputData: AdminPageService.BankBranchInfo = {
        id: '',
        code: formData.branchCode,
        codePulse: formData.pulseCode,
        bankCode: formData.bank?.value || '',
        bankCodePulse: formData.pulseBankCode || '',
        bankName: formData.bank?.label || '',
        name: formData.branchName,
        branchNameEn: formData.branchNameEn
      }
      pipe(
        AdminPageService.addNewBankBranch(inputData),
        ZIO.map((response) => {
          if (response) {
            showToast(t('message:OB0040'), 'success')
            getInitialBankBranchList()
            setIsOpenAddPopup(false)
          } else {
            showToast(t('message:OB0042'), 'error')
          }
        }),
        ZIO.mapError((error) => {
          showToast(t('message:OB0042'), 'error')
          return error
        }),
        ZIO.unsafeRun({})
      )
    }
  }

  const getAddNewBranchModalPopup = () => {
    return (
      <ModalComponent
        children={
          <View style={configStyles.modalBodyContainer}>
            <SectionRow>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={configBranchForm.control}
                  name={'bank'}
                  rules={{
                    required: {
                      value: true,
                      message: t(`message:MS020009`, { field: t('Bank') })
                    },
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <SelectSearch
                      label={t('Bank')}
                      options={bankOptions.map((item) => ({ label: i18n.language === 'en' ? item.nameEn : item.name, value: item.code }))}
                      value={value}
                      onChange={(val) => {
                        onChange(val)
                        onChangeBankEvent(val?.value || '')
                      }}
                      onBlur={onBlur}
                      disabled={false}
                      errorMessage={!value || !value.value ? error?.message : ''}
                      popupIcon={<assets.ArrowDownDropdownIcon />}
                      required={true}
                      placeholder={t('common:Select')}
                    />
                  )}
                />
              </SectionCol>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={configBranchForm.control}
                  name="branchCode"
                  rules={{
                    required: {
                      value: true,
                      message: t(`message:MS020001`, { field: t('Branch Code') })
                    },
                    validate: (value) => {
                      if (!value || !value.trim()) {
                        return `${t(`message:MS020001`, { field: t('Branch Code') })}`
                      } else {
                        return true
                      }
                    }
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                    return (
                      <Input
                        title={t('Bank Code')}
                        placeholder={''}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        disabled={false}
                        required
                        maxLength={120}
                        errorMessage={!!value ? "" : error?.message}
                      />
                    )
                  }}
                />

              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={configStyles.modalSecondLine}>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={configBranchForm.control}
                  name="branchName"
                  rules={{
                    required: {
                      value: true,
                      message: t(`message:MS020001`, { field: t('Branch Name') })
                    },
                    validate: (value) => {
                      if (!value || !value.trim()) {
                        return `${t(`message:MS020001`, { field: t('Branch Name') })}`
                      } else {
                        return true
                      }
                    }
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                    return (
                      <Input
                        title={t('Branch Name')}
                        placeholder={''}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        disabled={false}
                        required
                        maxLength={120}
                        errorMessage={!!value ? "" : error?.message}
                      />
                    )
                  }}
                />
              </SectionCol>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={configBranchForm.control}
                  name="branchNameEn"
                  rules={{
                    required: {
                      value: true,
                      message: t(`message:MS020001`, { field: t('Branch NameEn') })
                    },
                    validate: (value) => {
                      if (!value || !value.trim()) {
                        return `${t(`message:MS020001`, { field: t('Branch NameEn') })}`
                      } else {
                        return true
                      }
                    }
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                    return (
                      <Input
                        title={t('Branch NameEn')}
                        placeholder={''}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        disabled={false}
                        required
                        maxLength={120}
                        errorMessage={!!value ? "" : error?.message}
                      />
                    )
                  }}
                />
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={configStyles.modalSecondLine}>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={configBranchForm.control}
                  name="pulseBankCode"
                  rules={{
                    required: {
                      value: true,
                      message: t(`message:MS020001`, { field: t('Pulse Bank Code') })
                    },
                    validate: (value) => {
                      if (!value || !value.trim()) {
                        return `${t(`message:MS020001`, { field: t('Pulse Bank Code') })}`
                      } else {
                        return true
                      }
                    }
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                    return (
                      <Input
                        title={t('Pulse Bank Code')}
                        placeholder={''}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        disabled={true}
                        required
                        maxLength={120}
                        errorMessage={!!value ? "" : error?.message}
                      />
                    )
                  }}
                />
              </SectionCol>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={configBranchForm.control}
                  name="pulseCode"
                  rules={{
                    required: {
                      value: true,
                      message: t(`message:MS020001`, { field: t('Pulse Code') })
                    },
                    validate: (value) => {
                      if (!value || !value.trim()) {
                        return `${t(`message:MS020001`, { field: t('Pulse Code') })}`
                      } else {
                        return true
                      }
                    }
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                    return (
                      <Input
                        title={t('Pulse Code')}
                        placeholder={''}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        disabled={false}
                        required
                        maxLength={120}
                        errorMessage={!!value ? "" : error?.message}
                      />
                    )
                  }}
                />
              </SectionCol>
            </SectionRow>
          </View>
        }
        visible={isOpenAddPopup}
        onClose={() => setIsOpenAddPopup(false)}
        title={'Create New Bank Branch'}
        actions={[
          {
            text: t('Cancel'),
            type: 'outline',
            disabled: false,
            loading: false,
            onlyWide: false,
            style: { marginRight: 10 },
            action: () => setIsOpenAddPopup(false)
          },
          {
            text: t('Add'),
            type: 'filled',
            disabled: false,
            loading: false,
            onlyWide: false,
            style: {},
            action: () => onAddNewBranchEvent()
          }
        ]}
        centerTitle
        modalWidth={850}
      >
      </ModalComponent>
    )
  }


  return (
    <View style={configStyles.container}>
      <View style={configStyles.headerContainer}>
        <BreadCrumb navigation={getBreadCrumbList} />
      </View>
      <View style={[configStyles.bodyContainer]}>
          {/* <Text style={configStyles.bodyTitle}>{t('Bank Branch List')}</Text> */}
          <View style={configStyles.buttonContainer}>
            <TouchableOpacity onPress={() => onOpenCreateNewBranchPopup()}>
              <View style={[sharedStyle.button, sharedStyle.btnRed, configStyles.buttonContent]}>
                <Text style={[sharedStyle.textButtonRed]}>{t('Add')}</Text>
              </View>
            </TouchableOpacity>
          </View>
          <IBGeneralTable
            dataTable={displayColumns}
            data={branchkList}
            autoPaging
            maxWidthContainer
            maxHeight={height - height * 0.25}
            maxWidth={width - width * 0.025}
            filterColumn={filterColumn}
          >
          </IBGeneralTable>
      </View>
      {getAddNewBranchModalPopup()}
    </View>
  )
}
const configStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff'
  },
  headerContainer: {
    flexDirection: 'row',
    backgroundColor: '#FFFFFF',
    alignItems: 'center',
    paddingHorizontal: 16,
    shadowColor: 'rgba(0, 0, 0, 0.04)',
    shadowOffset: {
      width: 0,
      height: 4
    },
    shadowOpacity: 0.3,
    shadowRadius: 4.65,
    elevation: 8
  },
  bodyContainer: {
    width: '100%',
    paddingHorizontal: 20,
    // paddingTop: 10
  },
  bodyContent: {
    paddingHorizontal: 20,
  },
  bodyTitle: {
    color: '#000000',
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 10
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  buttonContent: {
    paddingHorizontal: 10,
    marginVertical: 10
  },
  modalBodyContainer: {
    paddingHorizontal: 15,
    paddingVertical: 20
  },
  modalSecondLine: {
    marginTop: 20
  },
})