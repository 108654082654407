import { Pressable, StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { ClientScreenContext, IBButtonMultiTasks } from '../../common'
import { IBPolicyPermission, assets } from '@pulseops/common'
import { Fab } from '@material-ui/core'
import { IBModalChangeContactInfo } from './IBModalChangeContactInfo'
import { isEmpty } from 'lodash'

export const IBChangeContactInfo = ({
  setFlagModal,
  flagOpenModal
}: {
  setFlagModal: (open: boolean) => void
  flagOpenModal: boolean | undefined
}) => {
  const { permissions } = React.useContext(ClientScreenContext)
  const [open, setOpen] = React.useState<boolean>(false)

  React.useEffect(() => {
    setFlagModal(open)
  }, [open])

  React.useEffect(() => {
    if (!flagOpenModal) {
      setOpen(false)
    }
  }, [flagOpenModal])
  return (
    <>
      {!permissions.includes(IBPolicyPermission.ViewIBComplainInquiryPopup) ? (
        <></>
      ) : (
        <View>
          {open ? (
            <View style={[styles.fabModal, { display: !open ? 'none' : 'flex' }]}>
              <IBModalChangeContactInfo showPopup={open} setShowPopup={setOpen} />
            </View>
          ) : (
            <></>
          )}
          <Pressable
            style={[
              styles.CircleFab,
              {
                backgroundColor: open ? '#FFFFFF' : '#ED1B2E',
                opacity: flagOpenModal ? 0 : 1
                // bottom: open ? -128 : 10
              }
            ]}
            onPress={() => {
              setOpen(!open)
            }}
            disabled={flagOpenModal}
          >
            {open ? <assets.IBMultiTasksXIcon /> : <assets.IBContactIcon />}
          </Pressable>
        </View>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  fab: {
    margin: 0,
    position: 'absolute',
    right: 30,
    bottom: 0
  },
  fabModal: {
    margin: 0,
    position: 'absolute',
    right: 30,
    bottom: -140
  },
  CircleFab: {
    borderColor: '#ED1B2E',
    padding: 15,
    borderRadius: 40,
    borderWidth: 1
  }
})
