import {
  assets,
  ErrorHandling,
  GeneralService,
  SelectOption,
  SelectSearch,
  SubTaskType,
  TextInput,
  useMobile
} from '@pulseops/common'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TouchableOpacity, Text, Modal, View, StyleSheet } from 'react-native'
import { OBTaskQueryData, OBTaskService, OBUserGroupData } from '../../../ob-service'
import { OBSharedStyles } from '../../ob-shared'
import { OBSectionCol, OBSectionRow } from '../OBSectionContent'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'

interface OBTaskTransferFormData {
  group: SelectOption | null
  manager: SelectOption | null
  user: SelectOption | null
  comment: string
}

export interface OBTaskTransferData {
  group: string
  manager: string
  user: string
  comment: string
}

type OBTaskTransferProps = {
  title: string
  visible: boolean
  userGroupArr: OBUserGroupData[]
  basket: string
  selectedTaskList?: Map<string, OBTaskQueryData>
  onHandleTransferEvent?: (data: OBTaskTransferData) => void
  onPopupCloseEvent?: () => void
}

export const OBTaskTransferPopup = (props: OBTaskTransferProps) => {
  const { t } = useTranslation()
  const { isWide } = useMobile()
  const { userGroupArr, visible, basket, selectedTaskList } = props
  const [managerOptions, setManagerOptions] = React.useState<SelectOption[]>([])
  const [userEmailOptions, setUserEmailOptions] = React.useState<SelectOption[]>([])
  const { trigger, watch, setValue, getValues, control, reset } = useForm<OBTaskTransferFormData>({
    defaultValues: {
      group: null,
      manager: null,
      user: null,
      comment: ''
    }
  })

  React.useEffect(() => {
    if (visible) {
      reset({
        group: null,
        manager: null,
        user: null,
        comment: ''
      })
      setManagerOptions([])
      setUserEmailOptions([])
    }
  }, [visible])

  const getManagerList = (userGroupCode: string) => {
    setValue('manager', null)
    setValue('user', null)
    setManagerOptions([])
    setUserEmailOptions([])
    if (!!userGroupCode) {
      pipe(
        OBTaskService.getManagerList(userGroupCode),
        ZIO.map((managerData) => {
          const filteredManagers = managerData
            ? managerData.managerList.map((item) => ({ label: item, value: item }))
            : []
          setManagerOptions(filteredManagers)
          return filteredManagers
        }),
        ZIO.unsafeRun({})
      )
    }
  }

  const getUserEmailArr = (manager: string) => {
    const userGroupCode = watch('group.value')
    setValue('user', null)
    setUserEmailOptions([])
    if (!!manager) {
      pipe(
        OBTaskService.getUserEmailList(manager, userGroupCode),
        ZIO.map((userEmailData) => {
          const userEmails = userEmailData.map((item) => ({ label: item, value: item }))
          setUserEmailOptions(userEmails)
          return userEmails
        }),
        ZIO.unsafeRun({})
      )
    }
  }

  const getUserEmailForQC = () => {
    setValue('user', null)
    setUserEmailOptions([])
    if (!!watch('group.value')) {
      pipe(
        OBTaskService.getUsersByGroupsQC([watch('group.value')]),
        ZIO.tap((users) => {
          let lastAssignees: string [] = []
          selectedTaskList && selectedTaskList.size > 0 && selectedTaskList?.forEach((selectedItem)=> {
            if(!lastAssignees.some((x)=> x === selectedItem.lastAssignee)){
              lastAssignees.push(selectedItem.lastAssignee || '')
            }
          })
          const userEmails = users.filter((p)=> !lastAssignees.includes(p.email)).map((item) => ({ label: item.email, value: item.email }))
          setUserEmailOptions(userEmails)
          return ZIO.unit
        }),
        ZIO.unsafeRun({})
      )
    }
  }

  const onSubmitTranfer = () => {
    const formData = getValues()
    const transferData = {
      group: formData.group?.value || '',
      manager: formData.manager?.value || '',
      user: formData.user?.value || '',
      comment: formData.comment
    }
    props.onHandleTransferEvent && props.onHandleTransferEvent(transferData)
  }

  const isValidForm = () => {
    return [SubTaskType.QC, SubTaskType.REQC].includes(basket as SubTaskType)
      ? !!watch('group')?.value && !!watch('user')?.value
      : !!watch('group')?.value && !!watch('manager')?.value && !!watch('user')?.value
  }

  return (
    <Modal visible={visible} transparent={true} animationType={'fade'}>
      <View style={transferStyles.pageContainer}>
        <View style={transferStyles.modalContainer}>
          <View style={transferStyles.modalHeader}>
            <Text style={transferStyles.modalHeaderText}>{props.title}</Text>
            <TouchableOpacity
              onPress={() => {
                props.onPopupCloseEvent && props.onPopupCloseEvent()
              }}
            >
              <assets.CloseTaskModalIcon />
            </TouchableOpacity>
          </View>
          <View style={transferStyles.modalBody}>
            <OBSectionRow>
              <OBSectionCol>
                <Controller
                  control={control}
                  name={'group'}
                  // rules={{
                  //   required: `${t('MS020009', { field: t('TaskManagement:Group') })}`
                  // }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                    return (
                      <SelectSearch
                        label={t('TaskManagement:Group')}
                        showPopupIcon={true}
                        popupIcon={<assets.ArrowDownDropdownIcon />}
                        value={value}
                        onChange={(val) => {
                          onChange(val)
                          if ([SubTaskType.QC, SubTaskType.REQC].includes(basket as SubTaskType)) {
                            getUserEmailForQC()
                          } else {
                            getManagerList(val?.value ?? '')
                          }
                        }}
                        onBlur={onBlur}
                        options={userGroupArr.map((groupItem) => ({
                          label: groupItem.userGroupCodeToTransfer,
                          value: groupItem.userGroupCodeToTransfer
                        }))}
                        // errorMessage={error?.message}
                      />
                    )
                  }}
                />
              </OBSectionCol>
              {![SubTaskType.QC, SubTaskType.REQC].includes(basket as SubTaskType) && (
                <OBSectionCol>
                  <Controller
                    control={control}
                    name={'manager'}
                    // rules={{
                    //   required: `${t('MS020009', { field: t('Outbound:OBTaskManagement:Manager') })}`
                    // }}
                    render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                      return (
                        <SelectSearch
                          label={t('Outbound:OBTaskManagement:Manager')}
                          showPopupIcon={true}
                          popupIcon={<assets.ArrowDownDropdownIcon />}
                          isShowFullText={true}
                          value={value}
                          onChange={(val) => {
                            onChange(val)
                            getUserEmailArr(val?.value ?? '')
                          }}
                          onBlur={onBlur}
                          options={managerOptions}
                          // errorMessage={error?.message}
                        />
                      )
                    }}
                  />
                </OBSectionCol>
              )}

              <OBSectionCol>
                <Controller
                  control={control}
                  name={'user'}
                  // rules={{
                  //   required: `${t('MS020009', { field: t('Outbound:OBTaskManagement:Manager') })}`
                  // }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                    return (
                      <SelectSearch
                        label={t('Outbound:OBTaskManagement:Member')}
                        showPopupIcon={true}
                        popupIcon={<assets.ArrowDownDropdownIcon />}
                        value={value}
                        isShowFullText={true}
                        onChange={onChange}
                        onBlur={onBlur}
                        options={userEmailOptions}
                        // errorMessage={error?.message}
                      />
                    )
                  }}
                />
              </OBSectionCol>
            </OBSectionRow>
            <OBSectionRow style={transferStyles.modalSecondLine}>
              <OBSectionCol style={OBSharedStyles.sectionCol12}>
                <Controller
                  control={control}
                  name="comment"
                  render={({ field: { value, onChange }, fieldState }) => {
                    return (
                      <TextInput
                        title={t('claim:Comment')}
                        value={value}
                        onChange={onChange}
                        placeholder={'...'}
                        textInputProps={{
                          maxLength: 500,
                          multiline: true,
                          style: [
                            {
                              borderWidth: 1,
                              borderColor: '#D3DCE6',
                              borderRadius: 8,
                              padding: 16,
                              fontSize: 15,
                              lineHeight: 22,
                              color: '#000000'
                            },
                            { marginTop: 10, height: isWide ? 140 : 60 }
                          ],
                          placeholderTextColor: '#B0B0B0'
                        }}
                      />
                    )
                  }}
                />
              </OBSectionCol>
            </OBSectionRow>
            <View style={[transferStyles.modalButtonContent, transferStyles.modalSecondLine]}>
              <TouchableOpacity onPress={() => props.onPopupCloseEvent && props.onPopupCloseEvent()}>
                <View style={[OBSharedStyles.actionContent, transferStyles.actionLine, transferStyles.modalButton]}>
                  <Text style={OBSharedStyles.actionContentText}>{t('common:Cancel')}</Text>
                </View>
              </TouchableOpacity>
              <TouchableOpacity disabled={!isValidForm()} onPress={() => onSubmitTranfer()}>
                <View
                  style={[
                    OBSharedStyles.actionContent,
                    isValidForm() ? OBSharedStyles.actionHightLight : OBSharedStyles.actionDisable,
                    transferStyles.actionLine,
                    transferStyles.modalButton
                  ]}
                >
                  <Text
                    style={[isValidForm() ? OBSharedStyles.actionHightLightText : OBSharedStyles.actionDisableText]}
                  >
                    {t('TaskManagement:Transfer')}
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    </Modal>
  )
}
const transferStyles = StyleSheet.create({
  pageContainer: {
    flex: 1,
    backgroundColor: 'rgba(0,0,0,0.25)',
    justifyContent: 'center',
    alignItems: 'center'
  },
  modalContainer: {
    minWidth: 785,
    minHeight: 300,
    paddingHorizontal: 24,
    paddingVertical: 20,
    backgroundColor: '#fff',
    borderRadius: 8,
    boxShadow: '2px 4px 16px 0px rgba(0, 0, 0, 0.08)'
  },
  modalHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  modalHeaderText: {
    fontSize: 20,
    fontStyle: 'normal',
    fontWeight: '600',
    color: '#4F4F4F'
  },
  modalBody: {
    paddingVertical: 16,
    display: 'flex',
    height: '100%',
    width: '100%'
  },
  actionLine: {
    marginLeft: 8
  },
  modalSecondLine: {
    marginTop: 16
  },
  modalButtonContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  modalButton: {
    // width: 155
  }
})
