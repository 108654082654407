import React from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import {
  AppContext,
  DatePicker,
  InquiryComplaintData,
  InquiryComplaintService,
  PulseOpsFormat,
  SelectOption,
  T0Data,
  TransactionType,
  CommonRequestService,
  SUCommonRequestData,
  ReportPermission,
  RBAC,
  ErrorHandling,
  TaskSource,
  SelectSearch,
  assets,
  SourceList
} from '@pulseops/common'
import { SubmissionReportConst } from './SubmissionReportConst'
import { useIsFocused } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import { ReportsStackParamList } from './ReportsStackParamList'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core'
import { Throwable, ZIO } from '@mxt/zio'
import { SubmissionReportModal } from '../lib/model/SubmissionReportModal'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/Option'
import { ReportSubmissionService } from './Service/ReportSubmissionService'
import { ReportService } from './Service/ReportExcelService'
import { addMonths, isAfter, startOfDay } from 'date-fns/fp'
import { Controller, useForm } from 'react-hook-form'
import moment from 'moment'
import _ from 'lodash'

export interface ReportItem {
  no: string
  policyNum: string
  category: string
  transactionType: string
  subServiceType: string
  subTransactionType: string
  createdDate: string
  closeDate: string
  submissionUser: string
  lastUpdateBy: string
  assignee: string
  userGroup: string
  status: string
  source: string
  office: string
  request: string
  newType: string
  newSubType: string
}

export interface SubmissionReportFormData {
  fromDate: Date | null
  toDate: Date | null
  template: SelectOption
  category: SelectOption
  source: SelectOption[]
}

type props = StackScreenProps<ReportsStackParamList, 'SubmissionReportScreen'>

export const SubmissionReportScreen = ({ navigation }: props) => {
  let isView: boolean = false
  // const { width } = useWindowDimensions()
  const { t, i18n } = useTranslation()
  const ht = useTranslation('menu').t
  const submissionReportForm = useForm<SubmissionReportFormData>({
    defaultValues: {
      fromDate: undefined,
      toDate: undefined,
      category: {
        label: 'All',
        value: SubmissionReportModal.Category.ALL
      },
      template: {
        value: SubmissionReportConst.Extension.EXCEL,
        label: 'Excel'
      },
      source: []
    }
  })
  const { control, setValue, watch } = submissionReportForm
  const [maxDate] = React.useState<Date>(new Date())
  const [dataSource, setDataSource] = React.useState<ReportItem[]>([])
  const [subServiceTypeList, setSubServiceTypeList] = React.useState<InquiryComplaintData.IACType[]>([])
  const [subTransactionTypeList, setSubTransactionTypeList] = React.useState<InquiryComplaintData.IACSubtypes[]>([])
  const isFocused = useIsFocused()
  // const [loading, bindLoader] = useLoading(false)
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [pageNum, setPageNum] = React.useState<number>(0)
  const [totalItem, setTotalItem] = React.useState<number>(0)
  const { changeBreadcrumb, showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const [commonRequests, setCommonRequests] = React.useState<SUCommonRequestData.CommonRequest[]>([])

  const permissions: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([]))

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigation.navigate('HomeScreen')
          }
        },
        {
          title: ht('Reports'),
          navigate: () => {
            navigation.navigate('ReportsScreen')
          }
        },
        {
          title: t('Reports:SubmissionReport'),
          navigate: null
        }
      ])

      // load subservice type
      pipe(
        InquiryComplaintService.getTypes(),
        ZIO.map((typeList) => {
          if (typeList && typeList.length > 0) {
            setSubServiceTypeList(typeList.slice())
          } else {
            setSubServiceTypeList([])
          }
        }),
        ZIO.unsafeRun({})
      )
      //load subtransactionType
      pipe(
        InquiryComplaintService.getSubType(),
        ZIO.map((typeList) => {
          if (typeList && typeList.length > 0) {
            setSubTransactionTypeList(typeList.slice())
          } else {
            setSubTransactionTypeList([])
          }
        }),
        ZIO.unsafeRun({})
      )
    }

    pipe(
      CommonRequestService.getCommonRequestList(),
      ZIO.map((common) => {
        if (common && common.length > 0) {
          setCommonRequests(common)
        } else {
          setCommonRequests([])
        }
      }),
      ZIO.unsafeRun({})
    )
  }, [isFocused])

  //load data for report review
  const getCategoryLabel = (code: string) => {
    const categoryLabel = SubmissionReportConst.CategoryList.find(({ value }) => value === code)?.label ?? '-'
    return t(`Reports:${categoryLabel}`)
  }

  const getSubServiceTypeName = (code: string) => {
    const subServiceItem = subServiceTypeList.find((subServiceType) => subServiceType.code === code)
    if (subServiceItem) {
      return getItemNameByLanguage(subServiceItem)
    } else {
      return code
    }
  }

  const getSubTransactionTypeName = (code: string) => {
    const subTransactionItem = subTransactionTypeList.find((subTransactionType) => subTransactionType.code === code)
    if (subTransactionItem) {
      return getItemNameByLanguage(subTransactionItem)
    } else {
      return code
    }
  }

  const getItemNameByLanguage = (item: any) => {
    return item ? (i18n.language === 'en' ? item.name : item.nameVi) : ''
  }

  const getTransactionTypeLabel = (transactionType: string) => {
    const transactionItem = T0Data.getValue(transactionType)
    return transactionItem
      ? i18n.language === 'en'
        ? transactionItem.transactionType
        : transactionItem.transactionTypeVn
      : ''
  }

  const getQuery = (input: {
    fromDate: Date
    toDate: Date
    category: SubmissionReportModal.Category
    start: number
    size: number
    sources: TaskSource[] | undefined
  }) => {
    const { fromDate, toDate, category, start, size, sources } = input
    return {
      fromDate: PulseOpsFormat.datetoFormat(fromDate, 'yyyy/MM/DD'),
      toDate: PulseOpsFormat.datetoFormat(toDate, 'yyyy/MM/DD'),
      order: SubmissionReportModal.Order.DESC,
      category,
      sort: 'startTime',
      start,
      size,
      sources
    }
  }

  const mapRequestName = (requestCode: string) => {
    const request = commonRequests.find((c) => c.requestCode === requestCode)
    return request ? (i18n.language === 'vi' ? request.requestNameVn : request.requestNameEn) : '-'
  }

  const mappingDataSource = (data: SubmissionReportModal.ReportData, start: number = 0) => {
    const filteredDataSource = data.map((item, index) => {
      return {
        no: (start + index + 1).toString(),
        policyNum: item.policyNum,
        category: getCategoryLabel(item.category),
        transactionType: getTransactionTypeLabel(item.transactionType),
        subServiceType: getSubServiceTypeName(item.subServiceTypeCode),
        subTransactionType: getSubTransactionTypeName(item.subTransactionCode),
        createdDate: PulseOpsFormat.date(new Date(item.createdDate)),
        closeDate: item.closedDate ? PulseOpsFormat.date(new Date(item.closedDate)) : '-',
        submissionUser: item.requestUser,
        lastUpdateBy: item.lastUpdateUser ?? '-',
        assignee: item.assignee ?? '-',
        userGroup: item.userGroup ?? '-',
        status: item.status,
        source: item.source,
        office: item.officeCode,
        newType: getSubServiceTypeName(item.newType ?? ''),
        newSubType: getSubTransactionTypeName(item.newSubType ?? ''),
        request:
          item.transactionType === TransactionType.COMMON_REQUEST_FORM && item.request
            ? mapRequestName(item.request)
            : '-'
      }
    })
    if (isView) {
      setDataSource(filteredDataSource)
    }
    return filteredDataSource
  }

  const handleReportData = async (handle: (report: SubmissionReportModal.ReportsData) => ZIO<any, Throwable, any>) => {
    const size = isView ? pageSize : 0
    const customedFromDate = watch('fromDate') as Date
    const customedToDate = watch('toDate') as Date
    const selectedCategory = watch('category') || null
    const customedCate = selectedCategory?.value as SubmissionReportModal.Category
    const source = watch('source')
    const customSource = source.length > 0 ? source.map((x) => x.value as TaskSource) : undefined
    if (await validateDataFields(customedFromDate, customedToDate)) {
      const query = getQuery({
        fromDate: customedFromDate,
        toDate: customedToDate,
        category: customedCate || '',
        start: 0,
        sources: customSource,
        size
      })
      showGlobalLoading(true)
      pipe(
        ReportSubmissionService.getReportData(query),
        ZIO.flatMap((report) =>
          pipe(
            O.fromNullable(report.data),
            O.filter((data) => data.length > 0),
            O.fold(
              () => {
                showToast(t('message:MS030029'), 'error')
                showGlobalLoading(false)
                return ZIO.unit
              },
              (data) => {
                if (isView) {
                  mappingDataSource(data)
                }
                showGlobalLoading(false)
                return handle(report)
              }
            )
          )
        ),
        ZIO.catchAll(() => {
          showToast(t('message:MS030029'), 'error')
          showGlobalLoading(false)
          return ZIO.unit
        }),
        // bindLoader,
        ZIO.unsafeRun({})
      )
    }
  }

  const handleReportDataToExport = async (
    handle: (reportData: SubmissionReportModal.ReportData) => ZIO<any, Throwable, any>
  ) => {
    const customedFromDate = watch('fromDate') as Date
    const customedToDate = watch('toDate') as Date
    const selectedCategory = watch('category')
    const source = watch('source')
    const customSource = source.length > 0 ? source.map((x) => x.value as TaskSource) : undefined
    const customedCate = selectedCategory.value as SubmissionReportModal.Category
    const isDataFieldValid = await validateDataFields(customedFromDate, customedToDate)
    if (isDataFieldValid) {
      const query = getQuery({
        fromDate: customedFromDate,
        toDate: customedToDate,
        category: customedCate,
        start: 0,
        size: 1,
        sources: customSource
      })

      const totalItems = await pipe(
        ReportSubmissionService.getReportData(query),
        ZIO.catchAll((_) => ZIO.succeed({ total: 0 })),
        ZIO.map((response) => response.total),
        ZIO.unsafeRun({})
      )

      if (totalItems > 0) {
        const PAGE_SIZE = 5000
        const totalPages = Math.ceil(totalItems / PAGE_SIZE)

        await pipe(
          ZIO.sequencePar(
            _.times(totalPages, (page) =>
              pipe(
                ReportSubmissionService.getReportData({ ...query, start: page * PAGE_SIZE, size: PAGE_SIZE }),
                ZIO.catchAll((_) => ZIO.succeed({ data: [] })),
                ZIO.map((response) => response?.data || [])
              )
            )
          ),
          ZIO.map(_.flatten),
          ZIO.flatMap((data) => {
            if (data.length === totalItems) {
              return handle(data)
            } else {
              showToast(t('message:MS030029'), 'error')
            }
            return ZIO.unit
          }),
          ZIO.unsafeRun({})
        )
      } else {
        showToast(t('message:MS030029'), 'error')
      }
    }
  }

  const handlePaging = (pageIndex: number, currentpageSize: number) => {
    const start = currentpageSize * pageIndex
    const customedFromDate = watch('fromDate') as Date
    const customedToDate = watch('toDate') as Date
    const selectedCategory = watch('category')
    const source = watch('source')
    const customSource = source.length > 0 ? source.map((x) => x.value as TaskSource) : undefined
    const customedCate = selectedCategory.value as SubmissionReportModal.Category
    const query = getQuery({
      fromDate: customedFromDate,
      toDate: customedToDate,
      category: customedCate,
      start,
      size: currentpageSize,
      sources: customSource
    })
    showGlobalLoading(true)
    isView = true
    pipe(
      ReportSubmissionService.getReportData(query),
      ZIO.flatMap((report) =>
        pipe(
          O.fromNullable(report.data),
          O.filter((data) => data.length > 0),
          O.fold(
            () => {
              showToast(t('message:MS030029'), 'error')
              showGlobalLoading(false)
              return ZIO.unit
            },
            (data) => {
              mappingDataSource(data, start)
              showGlobalLoading(false)
              return ZIO.unit
            }
          )
        )
      ),
      ZIO.catchAll(() => {
        showToast(t('message:MS030029'), 'error')
        showGlobalLoading(false)
        return ZIO.unit
      }),
      // bindLoader,
      ZIO.unsafeRun({})
    )
  }

  const setDefaultValues = () => {
    const currentDate = new Date()
    if (!watch('fromDate') && !watch('toDate')) {
      setValue('fromDate', currentDate)
      setValue('toDate', currentDate)
    }
  }

  const validateDataFields = async (fromDateItem: Date, toDateItem: Date) => {
    const customedFromDate = moment(fromDateItem).format('YYYY/MM/DD')
    const customedToDate = moment(toDateItem).format('YYYY/MM/DD')
    const isValidForm = await submissionReportForm.trigger()
    if (!isValidForm) {
      return false
    } else if (!moment(fromDateItem).isValid()) {
      return false
    } else if (!moment(customedToDate).isValid()) {
      return false
    } else if (fromDateItem && !toDateItem) {
      return false
    } else if (!fromDateItem && toDateItem) {
      return false
    } else if (moment(customedFromDate) > moment(customedToDate)) {
      showToast(t('message:MS030044'), 'error')
      return false
    } else if (
      moment(customedFromDate).valueOf() > moment().valueOf() ||
      moment(customedToDate).valueOf() > moment().valueOf()
    ) {
      return false
    } else if (
      pipe(fromDateItem, startOfDay, addMonths(3), (fromDateAdd3Months) =>
        isAfter(fromDateAdd3Months)(startOfDay(toDateItem))
      )
    ) {
      showToast(t('message:MS080004', { k: 3 }), 'error')
      return false
    }
    return true
  }

  const onViewReportClick = () => {
    isView = true
    setDefaultValues()
    handleReportData((report) => {
      setPageNum(0)
      setTotalItem(report.total)
      return ZIO.unit
    })
  }

  // export data to exceljs

  const onExcelExport = async () => {
    showGlobalLoading(true)
    isView = false
    setDefaultValues()
    await handleReportDataToExport((reportData) => {
      const customedFromDate = watch('fromDate') as Date
      const customedToDate = watch('toDate') as Date
      const selectedCategory = watch('category')
      const nameCategory = selectedCategory.label ?? '-'
      const templateType = watch('template').value
      const summary = {
        fromDate: customedFromDate,
        toDate: customedToDate,
        title: t('SubmissionReport'),
        category: nameCategory
      }
      const columns = SubmissionReportConst.displayedColumns.map(({ name, widthPx, horizontal }) => ({
        name: t(name),
        horizontal,
        width: widthPx
      }))
      const exportDataSource = mappingDataSource(reportData)
      const rows = exportDataSource.map(
        ({
          no,
          policyNum,
          category,
          transactionType,
          request,
          subServiceType,
          subTransactionType,
          newType,
          newSubType,
          createdDate,
          closeDate,
          submissionUser,
          lastUpdateBy,
          assignee,
          userGroup,
          status,
          source,
          office
        }) => [
          no,
          policyNum,
          category,
          transactionType,
          request,
          subServiceType,
          subTransactionType,
          newType,
          newSubType,
          createdDate,
          closeDate,
          submissionUser,
          lastUpdateBy,
          assignee,
          userGroup,
          status,
          source,
          office
        ]
      )
      isView = true
      return ReportService.exportTemplateRP0001(
        { ref: 'B8', colRef: 2, rowRef: 8 },
        summary,
        columns,
        rows,
        templateType
      )
    })
    showGlobalLoading(false)
  }

  return (
    <View style={SUREStyle.pageContent}>
      <ScrollView style={[SUREStyle.container]}>
        <View style={SUREStyle.sectionContent}>
          <View style={SUREStyle.sectionRow}>
            <View style={SUREStyle.col_4}>
              <Controller
                name="fromDate"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: `${t('message:MS020009', { field: t('common:FromDate') })}`
                  }
                }}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <DatePicker
                    label={t('common:FromDate')}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    errorMessage={!!value ? '' : error?.message}
                    maxDate={maxDate}
                    alwaysShow={true}
                    maxDateMessage={'Date should not be future'}
                  />
                )}
              />
            </View>
            <View style={SUREStyle.col_4}>
              <Controller
                name="toDate"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: `${t('message:MS020009', { field: t('common:ToDate') })}`
                  }
                }}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <DatePicker
                    label={t('common:ToDate')}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    errorMessage={!!value ? '' : error?.message}
                    maxDate={maxDate}
                    alwaysShow={true}
                    maxDateMessage={'Date should not be future'}
                  />
                )}
              />
            </View>
            <View style={SUREStyle.col_4}>
              <Controller
                name="category"
                control={control}
                render={({ field }) => (
                  <SelectSearch
                    // value={category}
                    // onChange={(value) => setCategory(value as SelectOption)}
                    {...field}
                    popupIcon={<assets.ArrowDownDropdownIcon />}
                    label={t('Reports:Category')}
                    options={SubmissionReportConst.CategoryList.map((item) => ({
                      label: t(`Reports:${item.label}`),
                      value: item.value
                    }))}
                    placeholder={t('common:Select')}
                  />
                )}
              />
            </View>
          </View>

          <View style={[SUREStyle.sectionRow, SUREStyle.secondLine]}>
            <View style={SUREStyle.col_4}>
              <Controller
                name="template"
                control={control}
                render={({ field }) => (
                  <SelectSearch
                    {...field}
                    popupIcon={<assets.ArrowDownDropdownIcon />}
                    label={t('Reports:Template')}
                    options={SubmissionReportConst.TemplateList}
                    placeholder={t('common:Select')}
                  />
                )}
              />
            </View>
            <View style={SUREStyle.col_4}>
              <Controller
                name="source"
                control={control}
                render={({ field }) => (
                  <SelectSearch
                    {...field}
                    popupIcon={<assets.ArrowDownDropdownIcon />}
                    label={t('TaskManagement:Source')}
                    options={SourceList}
                    placeholder={t('common:Select')}
                    multiple={true}
                  />
                )}
              />
            </View>
          </View>

          <View style={[SUREStyle.sectionRow, SUREStyle.secondLine]}>
            <View style={[SUREStyle.col_4, { flex: 1, flexDirection: 'row', alignItems: 'center' }]}>
              {permissions.includes(ReportPermission.ViewSubmissionReport) && (
                <TouchableOpacity style={{ width: 118, height: 40 }} onPress={onViewReportClick}>
                  <View style={SUREStyle.primaryBtn}>
                    <Text style={{ textAlign: 'center', color: '#fff' }}>{t('View')}</Text>
                  </View>
                </TouchableOpacity>
              )}
              {permissions.includes(ReportPermission.ExportSubmissionReport) && (
                <TouchableOpacity style={{ width: 118, height: 40, marginLeft: 30 }} onPress={onExcelExport}>
                  <View style={SUREStyle.primaryBtn}>
                    <Text style={{ textAlign: 'center', color: '#fff' }}>{t('Export')}</Text>
                  </View>
                </TouchableOpacity>
              )}
            </View>
          </View>
        </View>
        {dataSource && dataSource.length > 0 && (
          <View style={{ marginTop: 20, marginBottom: 20 }}>
            <TableContainer component={Paper} style={{ maxHeight: 350 }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow style={{ backgroundColor: '#e2e7ea' }}>
                    <TableCell align="center" style={{ fontWeight: 'bold', fontSize: 15 }}>
                      #
                    </TableCell>
                    <TableCell align="center" style={{ minWidth: 110, fontWeight: 'bold', fontSize: 15 }}>
                      {t('Reports:PolicyNumber')}
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: 180, fontWeight: 'bold', fontSize: 15 }}>
                      {t('Reports:Category')}
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: 200, fontWeight: 'bold', fontSize: 15 }}>
                      {t('Reports:TransactionType')}
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: 200, fontWeight: 'bold', fontSize: 15 }}>
                      {t('Reports:Request')}
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: 200, fontWeight: 'bold', fontSize: 15 }}>
                      {t('Reports:SubServiceType')}
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: 200, fontWeight: 'bold', fontSize: 15 }}>
                      {t('Reports:SubTransactionType')}
                    </TableCell>

                    <TableCell align="left" style={{ minWidth: 200, fontWeight: 'bold', fontSize: 15 }}>
                      {t('Reports:FinalSubServiceType')}
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: 200, fontWeight: 'bold', fontSize: 15 }}>
                      {t('Reports:FinalSubTransactionType')}
                    </TableCell>

                    <TableCell align="center" style={{ minWidth: 120, fontWeight: 'bold', fontSize: 15 }}>
                      {t('Reports:CreatedDate')}
                    </TableCell>
                    <TableCell align="center" style={{ minWidth: 130, fontWeight: 'bold', fontSize: 15 }}>
                      {t('Reports:ClosedDate')}
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: 220, fontWeight: 'bold', fontSize: 15 }}>
                      {t('Reports:SubmissionUser')}
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: 220, fontWeight: 'bold', fontSize: 15 }}>
                      {t('Reports:LastUpdateBy')}
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: 220, fontWeight: 'bold', fontSize: 15 }}>
                      {t('Reports:LastAssignee')}
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: 150, fontWeight: 'bold', fontSize: 15 }}>
                      {t('Reports:UserGroup')}
                    </TableCell>
                    <TableCell align="center" style={{ minWidth: 130, fontWeight: 'bold', fontSize: 15 }}>
                      {t('Reports:Status')}
                    </TableCell>
                    <TableCell align="center" style={{ minWidth: 130, fontWeight: 'bold', fontSize: 15 }}>
                      {t('Reports:Source')}
                    </TableCell>
                    <TableCell align="center" style={{ minWidth: 130, fontWeight: 'bold', fontSize: 15 }}>
                      {t('Reports:Office')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataSource.map((row) => (
                    <TableRow key={row.no}>
                      <TableCell align="center">{row.no}</TableCell>
                      <TableCell align="center">{row.policyNum}</TableCell>
                      <TableCell align="left">{row.category}</TableCell>
                      <TableCell align="left">{row.transactionType}</TableCell>
                      <TableCell align="left">{row.request}</TableCell>
                      <TableCell align="left">{row.subServiceType}</TableCell>
                      <TableCell align="left">{row.subTransactionType}</TableCell>
                      <TableCell align="left">{row.newType}</TableCell>
                      <TableCell align="left">{row.newSubType}</TableCell>

                      <TableCell align="center">{row.createdDate}</TableCell>
                      <TableCell align="center">{row.closeDate}</TableCell>
                      <TableCell align="left">{row.submissionUser}</TableCell>
                      <TableCell align="left">{row.lastUpdateBy}</TableCell>
                      <TableCell align="left">{row.assignee}</TableCell>
                      <TableCell align="left">{row.userGroup}</TableCell>
                      <TableCell align="center">{row.status}</TableCell>
                      <TableCell align="center">{row.source}</TableCell>
                      <TableCell align="center">{row.office}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              page={pageNum}
              rowsPerPageOptions={[5, 10, 25]}
              rowsPerPage={pageSize}
              count={totalItem}
              onPageChange={(e, page) => {
                setPageNum(page)
                handlePaging(page, pageSize)
              }}
              onRowsPerPageChange={(e) => {
                const rowsPerPage = Number(e.target.value)
                setPageSize(rowsPerPage)
                if (pageNum * rowsPerPage >= totalItem) {
                  setPageNum(0)
                  handlePaging(0, rowsPerPage)
                } else {
                  handlePaging(pageNum, rowsPerPage)
                }
              }}
              labelRowsPerPage={t('common:PaginationSize')}
              labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
              component={View}
            ></TablePagination>
          </View>
        )}
      </ScrollView>
    </View>
  )
}

const SUREStyle = StyleSheet.create({
  pageContent: {
    backgroundColor: '#fff',
    height: '100%',
    width: '100%'
  },
  container: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    paddingLeft: 15,
    paddingRight: 15
  },
  sectionContent: {
    padding: 20,
    backgroundColor: '#fafafa',
    border: '1px solid #d3dce6',
    boxSizing: 'border-box',
    borderRadius: 8,
    marginTop: 30
  },
  sectionRow: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    marginRight: -15,
    marginLeft: -15
  },
  secondLine: {
    marginTop: 20
  },
  col_4: {
    width: '100%',
    maxWidth: '33.3333333333%',
    paddingRight: 15,
    paddingLeft: 15
  },
  primaryBtn: {
    width: 118,
    height: 40,
    backgroundColor: '#ed1b2e',
    borderRadius: 100,
    padding: 10,
    boxShadow: 'none'
  },
  cellWidth200: {
    minWidth: '200px'
  }
})
