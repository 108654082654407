import { UIO, ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { pipe } from 'fp-ts/lib/function'
import React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { View, Text, TextInput } from 'react-native'
import {
  Alert,
  AppContext,
  CashLessService,
  ErrorHandling,
  PulseOpsFormat,
  ReactableView,
  TaskDetail,
  SourceType
} from '@pulseops/common'
import { PartnerChannel, PCReason } from '../mock'
import { CashLessForm, CashLessFormBase } from './cash-less-form'
import { GridView } from './gridview'
import { styles, ContainerView } from './styles'
import _ from 'lodash'
export const TransactionInfoView = (props: {
  form: CashLessFormBase
  detail: TaskDetail.VeriCashLess
  remainingAmount: number
  canAction: boolean
  processId: string
  taskId: string
}) => {
  const { detail, form, canAction, remainingAmount } = props
  const { t: ca, i18n } = useTranslation('Cashless')

  const { showGlobalLoading } = React.useContext(AppContext.AppContextInstance)

  const [loading, bindLoader] = useLoading(false)

  const adjustPolicy = React.useMemo(() => {
    const checkAdjusted = detail.priorProposal
      ? detail.proposalNumber === detail.rawData.proposalNumber
      : detail.policyNumber === detail.rawData.policyNumber
    return checkAdjusted || _.isUndefined(detail.priorProposal)
      ? null
      : detail.priorProposal
      ? detail.proposalNumber
      : detail.policyNumber // adject policy exist if only policy number old exist
  }, [detail])
  const transactionFields = React.useMemo(() => {
    return [
      {
        label: ca('bankPartnerName'),
        value: (
          <View style={{ flexDirection: 'row' }}>
            {detail.source === SourceType.Offline ? (
              <>
                <Text style={{ fontSize: 15, marginRight: 5 }}>Offline</Text>
                <Text style={{ marginRight: 15, fontSize: 15 }}>
                  ({PartnerChannel.getPartnerNameByCode(detail.bankCode ?? '')(i18n.language)})
                </Text>
                <Text style={{ fontSize: 15 }}>{detail.bankCode ?? ''}</Text>
              </>
            ) : (
              <>
                <Text style={{ marginRight: 15, fontSize: 15 }}>
                  {PartnerChannel.getPartnerNameByCode(detail.bankCode ?? '')(i18n.language)}
                </Text>
                <Text style={{ fontSize: 15 }}>{detail.bankCode ?? ''}</Text>
              </>
            )}
          </View>
        )
      },
      {
        label: ca('customerSubmitDate'),
        value: PulseOpsFormat.date(detail.transactionDate)
      },
      {
        label: ca('partnerTransferDate'),
        value: PulseOpsFormat.date(detail.receiptDate)
      },
      {
        label: ca('spendAmount'),
        value: PulseOpsFormat.thousandSepartor(detail.amount) + ' VND'
      },
      {
        label: ca('refNo'),
        value: detail.referenceNumber
      },
      {
        label: ca('reason'),
        value: detail.hasVoucher
          ? `${PCReason.getDescriptionReasonByCode(detail.paymentReason)} - ${
              detail.vouchers[0].code
            } - ${PulseOpsFormat.thousandSepartor(parseInt(detail.vouchers[0].amount))}`
          : detail.paymentReason && detail.remark
          ? PCReason.getDescriptionReasonByCode(detail.paymentReason ?? '') + ' - ' + detail.remark
          : detail.remark && !detail.paymentReason
          ? detail.remark
          : !detail.remark && detail.paymentReason
          ? PCReason.getDescriptionReasonByCode(detail.paymentReason ?? '')
          : '-'
      },
      {
        label: ca('policyNumberAppNumber'),
        value: detail.rawData.proposalNumber ?? detail.rawData.policyNumber
      },
      {
        label: ca('adjustedPolicyNumber'),
        value: (
          <Controller
            control={form.control}
            name={'adjustPolicy'}
            render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
              return (
                <ReactableView disable={!canAction || detail.hasVoucher}>
                  <TextInput
                    value={value ?? ''}
                    onChangeText={(value) => {
                      onChange(value)
                    }}
                    maxLength={9}
                    onBlur={() => {
                      if (
                        value &&
                        CashLessForm.validatePolicyNumber(value) &&
                        value !== detail.policyNumber &&
                        value !== detail.proposalNumber
                      ) {
                        handleSavePolicyNumber(value)
                      } else {
                        const checkAdjusted = detail.priorProposal
                          ? detail.proposalNumber === detail.rawData.proposalNumber
                          : detail.policyNumber === detail.rawData.policyNumber
                        onChange(
                          checkAdjusted || _.isUndefined(detail.priorProposal)
                            ? null
                            : detail.priorProposal
                            ? detail.proposalNumber
                            : detail.policyNumber
                        )
                      }
                    }}
                    style={{ paddingBottom: 5, borderBottomWidth: 1, borderBottomColor: '#D3DCE6' }}
                  />
                  {error?.message ? <Text style={{ color: 'red', fontSize: 12 }}>{error.message}</Text> : <View />}
                </ReactableView>
              )
            }}
          />
        )
      },
      {
        label: ca('remainingAmount'),
        value: PulseOpsFormat.thousandSepartor(remainingAmount ?? 0) + ' VND'
      },
      {
        label: ca('cardType'),
        value: detail.cardType ?? '-'
      },
      {
        label: ca('payeeName'),
        value: detail.payerName ?? '-'
      },
      {
        label: ca('mobileNumber'),
        value: detail.phoneNumber ?? '-'
      },
      {
        label: ca('bankTransferPendingNo'),
        value: detail.bankTransferNo ?? '-'
      }
    ]
  }, [remainingAmount, detail, i18n.language])

  React.useEffect(() => {
    showGlobalLoading(loading)
  }, [loading])

  const saveNewPolicyNumber = React.useCallback(
    (newPolicyNumber: string) => {
      const payloadUpdate = {
        ...detail?.rawData.payload,
        body: {
          ...detail?.rawData.payload.body,
          policyNumberOld:
            detail.rawData.policyNumber && detail.rawData.policyNumber !== 'null' ? detail.rawData.policyNumber : null,
          policyNumber: newPolicyNumber,
          proposalNumber: null,
          proposalNumberOld: detail.rawData.proposalNumber ? detail.rawData.proposalNumber : null,
          priorProposal: false
        }
      }
      return CashLessService.updatePolicyNumber(props.taskId ?? '', payloadUpdate, props.processId ?? '')
    },
    [detail]
  )

  const saveNewProposal = React.useCallback(
    (newPorposalNumber: string) => {
      const payloadUpdate = {
        ...detail?.rawData.payload,
        body: {
          ...detail?.rawData.payload.body,
          proposalNumberOld: detail.rawData.proposalNumber ? detail.rawData.proposalNumber : null,
          proposalNumber: newPorposalNumber,
          policyNumberOld:
            detail.rawData.policyNumber && detail.rawData.policyNumber !== 'null' ? detail.rawData.policyNumber : null,
          policyNumber: null,
          priorProposal: true // update payload ưu tiên show field này trong ở trường adjustedPolicyNumber
        }
      }

      return CashLessService.updatePolicyNumber(props.taskId ?? '', payloadUpdate, props.processId ?? '')
    },
    [detail]
  )

  const handleSavePolicyNumber = React.useCallback((adjustNumber: string) => {
    return Alert.modal({
      title: ca('warning'),
      content: ca('warningChangePolicy', {
        old: detail.priorProposal ? detail.proposalNumber ?? detail.proposalNumber : detail.policyNumber,
        new: adjustNumber
      }),
      onOK: () => {
        return pipe(
          ZIO.effectTotal((): UIO<{ policyNumber?: string; proposalNumber?: string }> => {
            if (adjustNumber.length === 8) {
              return pipe(
                CashLessService.getPolicy(adjustNumber),
                ZIO.map((data) => {
                  return {
                    policyNumber: data ?? undefined,
                    proposalNumber: undefined
                  }
                })
              )
              // return ZIO.succeed({
              //   policyNumber: adjustNumber,
              //   proposalNumber: undefined
              // })
            } else if (adjustNumber.length === 9) {
              return pipe(
                CashLessService.getProposalInfo(adjustNumber), // lúc này policyNumber có 9 số nên đang hiểu là proposalNumber
                ZIO.map((data) => {
                  return {
                    policyNumber: data ?? undefined,
                    proposalNumber: adjustNumber
                  }
                })
              )
            } else {
              return ZIO.succeed({ policyNumber: adjustNumber, proposalNumber: undefined })
            }
          }),
          ZIO.flatMap((data) =>
            pipe(
              data,
              ZIO.flatMap((data) => {
                if (data.policyNumber) {
                  return pipe(
                    data.proposalNumber,
                    ZIO.succeed,
                    ZIO.flatMap((proposalNumber) => {
                      if (proposalNumber) {
                        return saveNewProposal(proposalNumber ?? '')
                      } else {
                        return saveNewPolicyNumber(data.policyNumber ?? '')
                      }
                    }),
                    ZIO.tap((_) => {
                      window.location.reload()
                      return ZIO.unit
                    })
                  )
                } else {
                  // form.setValue('adjustPolicy', adjustPolicy ?? '')
                  form.setError('adjustPolicy', {
                    type: 'custom',
                    message: ca('adjustNoError')
                  })
                  // return Alert.modalM({
                  //   title: ca('warning'),
                  //   content: 'Không tìm thấy số hợp đồng ứng với số hồ sơ này',
                  //   size: {
                  //     width: '30%'
                  //   },
                  //   onOK: () => {
                  //     form.setValue('adjustPolicy', adjustPolicy ?? '')
                  //   },
                  //   onClose: () => {
                  //     form.setValue('adjustPolicy', adjustPolicy ?? '')
                  //   }
                  // })
                  return ZIO.unit
                }
              })
            )
          ),
          bindLoader,
          ErrorHandling.run()
        )
      },
      onClose: () => {
        form.setValue('adjustPolicy', adjustPolicy ?? '')
      }
    })
  }, [])

  return (
    <View>
      <Text style={styles.sessonText}>{ca('transactionInfo')}</Text>
      <ContainerView key={remainingAmount.toString()}>
        <GridView data={transactionFields} />
      </ContainerView>
    </View>
  )
}
