import React from 'react'
import { View, Text, Platform } from 'react-native'
import { Controller, UseFormReturn, useFieldArray } from 'react-hook-form'
import { RiderReinstatementForm, ChooseView } from './common'
import {
  Panel,
  SelectOption,
  AppContext,
  sharedStyle,
  SelectSearch,
  ImgUploadMutiple,
  assets,
  BuildConfig
} from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import { useLoading } from '@mxt/zio-react'
import { SC, Input, ValueShow } from '../../common'
import _ from 'lodash'

export const RequestTab: React.FC<{
  dataLapsedDate: string
  form: Omit<UseFormReturn<RiderReinstatementForm.Raw>, 'handleSubmit'>
  occupations: SelectOption[]
  errorMessage: string
}> = ({ dataLapsedDate, form, occupations, errorMessage }) => {
  const { t } = useTranslation()
  const {
    control,
    watch,
    formState: { errors }
  } = form
  // const { lifeAssuredList } = watch()
  const { fields } = useFieldArray<RiderReinstatementForm.Raw>({
    control,
    name: 'formRein.lifeAssuredList'
  })

  const [loading, bindLoader] = useLoading(false)
  const { showGlobalLoading } = React.useContext(AppContext.AppContextInstance)

  React.useEffect(() => {
    showGlobalLoading(loading)
  }, [loading])

  return (
    <SC.Container>
      <View style={{ marginVertical: 10 }}>
        <Text style={sharedStyle.sectionTitle}>{t('submission:StatementOfInsurability')}</Text>
      </View>
      {fields &&
        fields.map((value, index) => {
          return (
            <Controller
              key={`list_rein_${index}`}
              control={control}
              name={`formRein.lifeAssuredList.${index}`}
              render={({ field: { value, onChange, onBlur } }) => {
                return (
                  <Panel title={value.name || ''} containerStyle={{ backgroundColor: '#fafafa' }}>
                    <ChooseView
                      title={t('submission:AnyChangesInStateOfHealth')}
                      selected={value.healthAnswer}
                      onChange={(select) => onChange({ ...value, healthAnswer: select })}
                    />

                    <ChooseView
                      title={t('submission:AnyChangesInOccupation')}
                      selected={value.occupationAnswer}
                      onChange={(select) => onChange({ ...value, occupationAnswer: select })}
                    />
                    {value.occupationAnswer && (
                      <SC.Row>
                        <SC.InputContainer maxWidth={300}>
                          <SC.Padding vertical={10}>
                            <ValueShow
                              title={t('submission:CurrentOccupation')}
                              value={value.curOccupation?.label || ''}
                            />
                          </SC.Padding>
                        </SC.InputContainer>

                        <SC.Divider width={20} />

                        <SC.InputContainer maxWidth={300}>
                          <SC.Padding vertical={10}>
                            <SelectSearch
                              options={occupations}
                              value={value.newOccupation}
                              label={t('submission:NewOccupation')}
                              required
                              onChange={(select) => {
                                onChange({ ...value, newOccupation: select })
                              }}
                              placeholder={t('common:Select')}
                              popupIcon={<assets.ArrowDownDropdownIcon />}
                              errorMessage={_.get(errors, `formRein.lifeAssuredList.${index}.newOccupation`)?.message}
                            />
                          </SC.Padding>
                        </SC.InputContainer>
                      </SC.Row>
                    )}

                    <ChooseView
                      title={t('submission:AnyChangeInActivities')}
                      selected={value.activitiesAnswer}
                      onChange={(select) => onChange({ ...value, activitiesAnswer: select })}
                    />

                    {value.activitiesAnswer && (
                      <SC.Row>
                        <SC.InputContainer maxWidth={300}>
                          <SC.Padding vertical={10}>
                            <Input
                              title={t('submission:NewActivity')}
                              value={value.newActivity}
                              onChange={(text) => onChange({ ...value, newActivity: text })}
                              required
                              onBlur={onBlur}
                              maxLength={100}
                              // placeholder={'Input'}
                              errorMessage={_.get(errors, `formRein.lifeAssuredList.${index}.newActivity`)?.message}
                            />
                          </SC.Padding>
                        </SC.InputContainer>
                      </SC.Row>
                    )}
                    <ChooseView
                      title={t('submission:AnyChangesInInsurancePolicy/Requesting')}
                      selected={value.companyAnswer}
                      onChange={(select) => onChange({ ...value, companyAnswer: select })}
                    />

                    {value.companyAnswer && (
                      <SC.Row>
                        <SC.InputContainer>
                          <SC.Padding vertical={5}>
                            <Input
                              title={t('submission:CompanyName')}
                              value={value.newCompany}
                              onChange={(text) => onChange({ ...value, newCompany: text })}
                              required
                              onBlur={onBlur}
                              // placeholder={'Input'}
                              errorMessage={_.get(errors, `formRein.lifeAssuredList.${index}.newCompany`)?.message}
                            />
                          </SC.Padding>
                        </SC.InputContainer>
                      </SC.Row>
                    )}
                  </Panel>
                )
              }}
            />
          )
        })}
      <SC.Padding>
        {Platform.OS === 'web' && (
          <SC.BoldText>
            {t('submission:HealthNote')}
            <a href={`${assets.UpdatedRedatingHealthyTemplate}`} download style={{ color: 'red' }}>
              {t('submission:Here')}
            </a>
          </SC.BoldText>
        )}
        <Controller
          control={control}
          name="formRein.attachmentFiles"
          render={({ field: { value, onChange } }) => {
            const wrapFile =
              value?.map((file) => ({ ...file, uploadedDate: file.uploadedDate!, file: file.file! })) || []
            return (
              <SC.Padding>
                <SC.Padding top={8}>
                  <SC.BoldText>
                    {t('submission:HealthDeclaration')} <SC.ErrorText>*</SC.ErrorText>
                  </SC.BoldText>
                </SC.Padding>
                <ImgUploadMutiple value={wrapFile} onChange={onChange} />
                <SC.ErrorText>{errorMessage}</SC.ErrorText>
              </SC.Padding>
            )
          }}
        />
      </SC.Padding>
    </SC.Container>
  )
}
