import { assets, BreadCrumb, DataSource, ErrorHandling, GeneralService, Input, ModalComponent, RadioButtonGroup, SelectOption, SelectSearch, sharedStyle, AdminPageData, AdminPageService, AppContext, Alert } from '@pulseops/common'
import { IBGeneralTable } from '@pulseops/inbound'
import { useIsFocused } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, TouchableOpacity, useWindowDimensions, View } from 'react-native'
import { AdminPageStackParamList } from './AdminPageStackParamList'
import { Controller, useForm } from 'react-hook-form'
import { ConfigT33OfficeConst, ConfigT33OfficeFormData } from './ConfigTableForm'
import { SectionCol, SectionRow } from '@pulseops/submission/common'
import { useLoading } from '@mxt/zio-react'
import { pipe } from 'fp-ts/lib/function'
import { Throwable, ZIO } from '@mxt/zio'
import * as O from 'fp-ts/lib/Option'

export const ConfigT33OfficeScreen = (props: StackScreenProps<AdminPageStackParamList>) => {
  const { height, width } = useWindowDimensions()
  // const { isWide } = useMobile()
  const { t } = useTranslation()
  const isFocused = useIsFocused()
  const [isLoading, bindLoader] = useLoading(false)
  const [isOpenAddPopup, setIsOpenAddPopup] = React.useState<boolean>(false)
  const [T33OfficeList, setT33OfficeList] = React.useState<Array<AdminPageData.T33OfficeInfo>>([])
  const [modalTitle, setModalTitle] = React.useState('')
  const [isUpdatedOfficeAction, setIsUpdatedOfficeAction] = React.useState(false)
  const { showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const configT33OfficeForm = useForm<ConfigT33OfficeFormData>({
    defaultValues: {
      id: '',
      officeCode: '',
      officeNameEn: '',
      officeNameVi: '',
      type: undefined,
      GACode: undefined,
      status: undefined,
      bankCode: undefined,
      isEFM: '',
    }
  })

  const getBreadCrumbList = React.useMemo(() => {
    const subItems = isFocused ? [
      { title: t('Admin page'), navigate: () => props.navigation.navigate("AdminPageScreen") },
      { title: t('T33 Office List'), navigate: null }
    ] : []
    return subItems
  }, [isFocused])

  const displayedColumns = [
    { label: 'No', field: 'no', disabled: true },
    { label: 'Office Code', field: 'code' },
    { label: 'Office NameEn', field: 'nameEn' },
    { label: 'Office NameVn', field: 'nameVi' },
    { label: 'Type', field: 'type' },
    { label: 'GA Code', field: 'gaCode' },
    { label: 'Bank Code', field: 'bankCode' },
    { label: 'EFM', field: 'isEFM' },
    { label: 'Status', field: 'status' },
    {
      label: '',
      field: 'id',
      disabled: true,
      render: (value: string) => {
        return <TouchableOpacity onPress={() => onOpenEditOfficeModalPopup(value)}>
          <assets.OBPencilGray />
        </TouchableOpacity>
      }
    },
    {
      label: '',
      field: 'id',
      disabled: true,
      render: (id: string) => {
        return <TouchableOpacity onPress={() => onOpenDeleteModalPopup(id)}>
          <assets.DeleteBin />
        </TouchableOpacity>
      }
    },
  ]

  const filterColumnList = {
    nameEn: '',
    nameVi: '',
    code: '',
    type: '',
    gaCode: '',
    bankCode: ''
  }

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  React.useEffect(() => {
    if (isFocused) {
      getInitialT33OfficeList()
    }
    return () => {
      setIsOpenAddPopup(false)
      setT33OfficeList([])
      setModalTitle('')
      setIsUpdatedOfficeAction(false)
    }
  }, [isFocused])

  const getInitialT33OfficeList = () => {
    pipe(
      AdminPageService.getT33OfficeList(),
      ZIO.map((responseData) => {
        const customedList = responseData.map((item, index) => ({
          ...item,
          no: index + 1,
          gaCode: item.gaCode || '',
          isEFM: item.isEFM ? 'Y' : 'N'
        }))
        setT33OfficeList(customedList)
        return customedList
      }),
      ZIO.mapError((error) => {
        setT33OfficeList([])
        return error
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }

  const onOpenCreateNewOfficePopup = () => {
    setModalTitle('Create New Office')
    setIsUpdatedOfficeAction(false)
    configT33OfficeForm.reset({
      id: '',
      officeCode: '',
      officeNameEn: '',
      officeNameVi: '',
      type: '',
      GACode: '',
      status: { label: '1', value: '1' },
      bankCode: '',
      isEFM: '',
    })
    setIsOpenAddPopup(true)
  }

  const validateInsertedNewData = (formData: ConfigT33OfficeFormData) => {
    let isValidData = true
    if (T33OfficeList.some((x) => x.code === formData.officeCode)) {
      showToast('Duplicate Office Code, Please check again', 'error')
      isValidData = false
    } else if (T33OfficeList.some((x) => x.nameEn === formData.officeNameEn)) {
      showToast('Duplicate Office NameEn, Please check again', 'error')
      isValidData = false
    } else if (T33OfficeList.some((x) => x.gaCode === formData.GACode)) {
      showToast('Duplicate GA Code, Please check again', 'error')
      isValidData = false
    }
    return isValidData
  }

  const onAddNewOfficeEvent = async () => {
    const isValidForm = await configT33OfficeForm.trigger()
    const formData = configT33OfficeForm.getValues()
    if (isValidForm && validateInsertedNewData(formData)) {
      const isEFM = formData.isEFM === 'Y' ? true : false
      const submitedData: AdminPageService.NewT33OfficeBodyInput = {
        bankCode: formData.bankCode || '',
        code: formData.officeCode,
        gaCode: formData.GACode,
        isEFM: isEFM,
        nameEn: formData.officeNameEn,
        nameVi: formData.officeNameVi,
        status: formData.status?.value || '',
        type: formData.type,
      }
      pipe(
        AdminPageService.addNewOffice(submitedData),
        ZIO.map((response) => {
          showToast(t('message:OB0040'), 'success')
          getInitialT33OfficeList()
          setIsOpenAddPopup(false)
        }),
        ZIO.catchAll((errorInfo) => {
          showToast(`${t('message:OB0042')}. ${errorInfo.source.message}`, 'error')
          // setIsOpenAddPopup(false)
          return ZIO.succeed('')
        }),
        bindLoader,
        ZIO.unsafeRun({})
      )
    }
  }

  const onOpenEditOfficeModalPopup = (id: string) => {
    setModalTitle('Update Office')
    setIsUpdatedOfficeAction(true)
    pipe(
      T33OfficeList.find((p) => p.id === id),
      O.fromNullable,
      O.map((officeInfo) => {
        configT33OfficeForm.setValue("id", id)
        configT33OfficeForm.setValue("officeCode", officeInfo.code)
        configT33OfficeForm.setValue("officeNameEn", officeInfo.nameEn)
        configT33OfficeForm.setValue("officeNameVi", officeInfo.nameVi)
        configT33OfficeForm.setValue("type", officeInfo.type)
        configT33OfficeForm.setValue("GACode", officeInfo.gaCode)
        configT33OfficeForm.setValue("bankCode", officeInfo.bankCode)
        configT33OfficeForm.setValue("isEFM", officeInfo.isEFM)
        configT33OfficeForm.setValue("status", { label: officeInfo.status, value: officeInfo.status })
        return setIsOpenAddPopup(true)
      }),
      O.getOrElse(() => { })
    )
  }

  const validateUpdatedData = (formData: ConfigT33OfficeFormData) => {
    let isValidData = true
    if (T33OfficeList.some((x) => x.code === formData.officeCode && x.id !== formData.id)) {
      showToast('Duplicate Office Code, Please check again', 'error')
      isValidData = false
    } else if (T33OfficeList.some((x) => x.nameEn === formData.officeNameEn && x.id !== formData.id)) {
      showToast('Duplicate Office NameEn, Please check again', 'error')
      isValidData = false
    } else if (T33OfficeList.some((x) => x.gaCode === formData.GACode && x.id !== formData.id)) {
      showToast('Duplicate GA Code, Please check again', 'error')
      isValidData = false
    }
    return isValidData
  }

  const onUpdateOfficeEvent = async () => {
    const isValidForm = await configT33OfficeForm.trigger()
    const formData = configT33OfficeForm.getValues()
    if (isValidForm && validateUpdatedData(formData)) {
      const isEFM = formData.isEFM === 'Y' ? true : false
      const updatedData: AdminPageService.T33OfficeInfo = {
        id: formData.id,
        bankCode: formData.bankCode,
        code: formData.officeCode,
        gaCode: formData.GACode,
        isEFM: isEFM,
        nameEn: formData.officeNameEn,
        nameVi: formData.officeNameVi,
        status: formData.status?.value || '',
        type: formData.type
      }
      pipe(
        AdminPageService.updateOffice(updatedData),
        ZIO.map((response) => {
          // if (response) {
          //   showToast(t('message:OB0040'), 'success')
          //   getInitialT33OfficeList()
          //   setIsOpenAddPopup(false)
          // } else {
          //   showToast(t('message:OB0042'), 'error')
          // }
          showToast(t('message:OB0040'), 'success')
          getInitialT33OfficeList()
          setIsOpenAddPopup(false)
        }),
        ZIO.catchAll((error) => {
          showToast(`${t('message:OB0042')}. ${error.source.message}`, 'error')
          return ZIO.succeed('')
        }),
        bindLoader,
        ZIO.unsafeRun({})
      )
    }
  }

  const onOpenDeleteModalPopup = (officeID: string) => {
    const officeName = pipe(
      T33OfficeList.find((p) => p.id === officeID),
      O.fromNullable,
      O.fold(
        () => '',
        (officeItem) => `${officeItem.code} - ${officeItem.nameEn}`
      )
    )
    Alert.modal({
      title: 'Delete Office',
      content: t(`Do you want to delete this ${officeName} office ?`),
      size: {
        width: '30%'
      },
      onOK: () => {
        pipe(
          AdminPageService.deleteOffice(officeID),
          ZIO.map((response) => {
            showToast(t('Delete Successfully'), 'success')
            getInitialT33OfficeList()
          }),
          ZIO.catchAll((error) => {
            showToast(`Delete Failed. ${error.source.message}`, 'error')
            return ZIO.succeed('')
          }),
          bindLoader,
          ZIO.unsafeRun({})
        )
      },
      onClose: () => {
      }
    })
  }

  const getAddNewOfficeModalPopup = () => {
    return (
      <ModalComponent
        children={
          <View style={configStyles.modalBodyContainer}>
            <SectionRow>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={configT33OfficeForm.control}
                  name="officeCode"
                  rules={{
                    required: {
                      value: true,
                      message: t(`message:MS020001`, { field: t('Office Code') })
                    },
                    validate: (value) => {
                      if (!value || !value.trim()) {
                        return `${t(`message:MS020001`, { field: t('Office Code') })}`
                      } else {
                        return true
                      }
                    }
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                    return (
                      <Input
                        title={t('Office Code')}
                        placeholder={''}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        disabled={false}
                        required
                        maxLength={5}
                        errorMessage={!!value ? "" : error?.message}
                      />
                    )
                  }}
                />

              </SectionCol>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={configT33OfficeForm.control}
                  name="officeNameVi"
                  rules={{
                    required: {
                      value: true,
                      message: t(`message:MS020001`, { field: t('Office NameVn') })
                    },
                    validate: (value) => {
                      if (!value || !value.trim()) {
                        return `${t(`message:MS020001`, { field: t('Office NameVn') })}`
                      } else {
                        return true
                      }
                    }
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                    return (
                      <Input
                        title={t('Office NameVn')}
                        placeholder={''}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        disabled={false}
                        required
                        maxLength={150}
                        errorMessage={!!value ? "" : error?.message}
                      />
                    )
                  }}
                />
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={configStyles.modalSecondLine}>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={configT33OfficeForm.control}
                  name="officeNameEn"
                  rules={{
                    required: {
                      value: true,
                      message: t(`message:MS020001`, { field: t('Office NameEn') })
                    },
                    validate: (value) => {
                      if (!value || !value.trim()) {
                        return `${t(`message:MS020001`, { field: t('Office NameEn') })}`
                      } else {
                        return true
                      }
                    }
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                    return (
                      <Input
                        title={t('Office NameEn')}
                        placeholder={''}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        disabled={false}
                        required
                        maxLength={150}
                        errorMessage={!!value ? "" : error?.message}
                      />
                    )
                  }}
                />

              </SectionCol>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={configT33OfficeForm.control}
                  name={'type'}
                  rules={{
                    required: {
                      value: true,
                      message: t(`message:MS020001`, { field: t('Type') })
                    },
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    // <SelectSearch
                    //   label={t('Type')}
                    //   options={typeOptionList}
                    //   value={value}
                    //   onChange={(val) => {
                    //     onChange(val)
                    //   }}
                    //   onBlur={onBlur}
                    //   disabled={false}
                    //   errorMessage={!value || !value.value ? error?.message : ''}
                    //   popupIcon={<assets.ArrowDownDropdownIcon />}
                    //   required={true}
                    //   placeholder={t('common:Select')}
                    // />
                    <Input
                      title={t('Type')}
                      placeholder={''}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      disabled={false}
                      required
                      maxLength={8}
                      errorMessage={!!value ? "" : error?.message}
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={configStyles.modalSecondLine}>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={configT33OfficeForm.control}
                  name={'GACode'}
                  // rules={{
                  //   required: {
                  //     value: true,
                  //     message: t(`message:MS020009`, { field: t('GA Code') })
                  //   },
                  // }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    // <SelectSearch
                    //   label={t('GA Code')}
                    //   options={GACodeOptions}
                    //   value={value}
                    //   onChange={(val) => {
                    //     onChange(val)
                    //   }}
                    //   onBlur={onBlur}
                    //   disabled={false}
                    //   // errorMessage={!value || !value.value ? error?.message : ''}
                    //   popupIcon={<assets.ArrowDownDropdownIcon />}
                    //   // required={true}
                    //   placeholder={t('common:Select')}
                    // />
                    <Input
                      title={t('GA Code')}
                      placeholder={''}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      disabled={false}
                      maxLength={8}
                      errorMessage={!!value ? "" : error?.message}
                    />
                  )}
                />
              </SectionCol>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={configT33OfficeForm.control}
                  name={'bankCode'}
                  // rules={{
                  //   required: {
                  //     value: true,
                  //     message: t(`message:MS020009`, { field: t('Bank Code') })
                  //   },
                  // }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    // <SelectSearch
                    //   label={t('Bank Code')}
                    //   options={bankCodeOptions}
                    //   value={value}
                    //   onChange={(val) => {
                    //     onChange(val)
                    //   }}
                    //   onBlur={onBlur}
                    //   disabled={false}
                    //   // errorMessage={!value || !value.value ? error?.message : ''}
                    //   popupIcon={<assets.ArrowDownDropdownIcon />}
                    //   // required={true}
                    //   placeholder={t('common:Select')}
                    // />
                    <Input
                      title={t('Bank Code')}
                      placeholder={''}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      disabled={false}
                      maxLength={8}
                      errorMessage={!!value ? "" : error?.message}
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={configStyles.modalSecondLine}>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={configT33OfficeForm.control}
                  name={'isEFM'}
                  rules={{
                    required: {
                      value: true,
                      message: t(`message:MS020009`, { field: t('EFM') })
                    },
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <RadioButtonGroup
                      title={t('EFM')}
                      required
                      colLength={'100%'}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      options={ConfigT33OfficeConst.EFMConst.map((item) => ({
                        id: item.id,
                        label: t(item.label)
                      }))}
                      disabled={false}
                      errorMessage={!value && !value.trim() ? error?.message : ''}
                    />
                  )}
                />
              </SectionCol>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={configT33OfficeForm.control}
                  name={"status"}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <SelectSearch
                      label={t('Status')}
                      options={[{ label: '1', value: '1' }]}
                      value={value}
                      onChange={(val) => {
                        onChange(val)
                      }}
                      onBlur={onBlur}
                      disabled={true}
                      popupIcon={<assets.ArrowDownDropdownIcon />}
                      placeholder={t('common:Select')}
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
          </View>
        }
        visible={isOpenAddPopup}
        onClose={() => setIsOpenAddPopup(false)}
        title={modalTitle}
        actions={[
          {
            text: t('Cancel'),
            type: 'outline',
            disabled: false,
            loading: false,
            onlyWide: false,
            style: { marginRight: 10 },
            action: () => setIsOpenAddPopup(false)
          },
          isUpdatedOfficeAction ?
            {
              text: t('Update'),
              type: 'filled',
              disabled: false,
              loading: false,
              onlyWide: false,
              style: {},
              action: () => onUpdateOfficeEvent()
            } :
            {
              text: t('Add'),
              type: 'filled',
              disabled: false,
              loading: false,
              onlyWide: false,
              style: {},
              action: () => onAddNewOfficeEvent()
            }
        ]}
        centerTitle
        modalWidth={850}
      >
      </ModalComponent>
    )
  }
  return (
    <View style={configStyles.container}>
      <View style={configStyles.headerContainer}>
        <BreadCrumb navigation={getBreadCrumbList} />
      </View>
      <View style={configStyles.bodyContainer}>
        <View style={configStyles.buttonContainer}>
          <TouchableOpacity onPress={() => onOpenCreateNewOfficePopup()}>
            <View style={[sharedStyle.button, sharedStyle.btnRed, configStyles.buttonContent]}>
              <Text style={[sharedStyle.textButtonRed]}>{t('Add')}</Text>
            </View>
          </TouchableOpacity>
        </View>
        <IBGeneralTable
          dataTable={displayedColumns}
          data={T33OfficeList}
          autoPaging
          maxHeight={height - height * 0.25}
          maxWidth={width - width * 0.025}
          maxWidthContainer
          filterColumn={filterColumnList}
        >
        </IBGeneralTable>
      </View>
      {getAddNewOfficeModalPopup()}
    </View>
  )
}
const configStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff'
  },
  headerContainer: {
    flexDirection: 'row',
    backgroundColor: '#FFFFFF',
    alignItems: 'center',
    paddingHorizontal: 16,
    shadowColor: 'rgba(0, 0, 0, 0.04)',
    shadowOffset: {
      width: 0,
      height: 4
    },
    shadowOpacity: 0.3,
    shadowRadius: 4.65,
    elevation: 8
  },
  bodyContainer: {
    width: '100%',
    paddingHorizontal: 20,
    // paddingTop: 30
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  buttonContent: {
    paddingHorizontal: 10,
    marginVertical: 10
  },
  bodyTitle: {
    color: '#000000',
    fontSize: 24,
    fontWeight: 'bold'
  },
  modalBodyContainer: {
    paddingHorizontal: 15,
    paddingVertical: 20
  },
  modalSecondLine: {
    marginTop: 20
  },
})