
export namespace VoiceRecordingConst {
  interface RecordingTypeInfo {
    code: string
    nameVi: string
    nameEn: string
  }
  export const RecordingTypeConstList: Array<RecordingTypeInfo> = [
    { code: 'NO_AI_CHECK', nameEn: 'With out checking AI', nameVi: 'Không kiểm tra AI' },
    { code: 'PASS', nameEn: 'Voice Recording Passing', nameVi: 'Kết quả ghi âm - Passing' },
    { code: 'SAMPLING', nameEn: 'Voice Recording Sampling', nameVi: 'Kết quả ghi âm - Sampling' },
    { code: 'FAIL', nameEn: 'Voice Recording Failing', nameVi: 'Kết quả ghi âm - Fail' },
  ]
} 