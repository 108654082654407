import { pipe } from 'fp-ts/lib/function'
import { POApi } from '../POApi'
import * as t from 'io-ts'
import { ZIO } from '@mxt/zio'

export const APSPrintLetterData = t.type({
  printLetterID: t.string,
  emailAddress: t.string,
  letterType: t.string,
  policyNum: t.string,
  dataName: t.string,
  fromDate: t.string,
  toDate: t.string,
  letterDate: t.string,
  url: t.string
})

export namespace APSService {
  export const getAPSPrintLetter =
    (policyNumber: string) =>
    ({ size = 10, start }: { size?: number; start: number }) => {
      return pipe(
        POApi.post(`wf-api/policy/${policyNumber}/get-aps-print-letter`)(
          t.type({
            body: t.type({
              data: t.array(APSPrintLetterData),
              size: t.number,
              start: t.number,
              total: t.number
            })
          })
        )({
          size,
          start
        }),
        ZIO.catchAll(() => ZIO.succeed(null)),
        ZIO.map((res) => {
          const { data, size, start, total } = res?.body ?? {}
          return {
            data,
            size,
            start,
            total
          }
        })
      )
    }
}
