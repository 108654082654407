import * as t from 'io-ts'
import { TransactionType } from '../TransactionType'
import { SourceType } from '../SourceType'
import { Maybe } from '@mxt/zio/codec'

const Base = <C extends t.Mixed>(codec: C) =>
  t.intersection([
    t.type({
      transactionType: t.literal(TransactionType.INHERITANCE)
    }),
    codec
  ])

const Pulse4Ops = Base(
  t.type({
    source: t.literal(SourceType.PULSE4OPS),
    payload: t.type({
      body: t.type({
        clientId: Maybe(t.string),
        status: Maybe(t.string),
        policyNo: Maybe(t.string),
        clientNo: Maybe(t.string),
        idNumber: Maybe(t.string),
        relationship1: Maybe(t.string),
        surname: Maybe(t.string),
        givenName: Maybe(t.string),
        birthDate: Maybe(t.string),
        sex: Maybe(t.string),
        nationality: Maybe(t.string),
        telCode: Maybe(t.string),
        mobilePhone: Maybe(t.string),
        email: Maybe(t.string),
        isFatca: Maybe(t.boolean),
        street: Maybe(t.string),
        country: Maybe(t.string),
        city: Maybe(t.string),
        district: Maybe(t.string),
        ward: Maybe(t.string),
        occupation: Maybe(t.string),
        foreignAdd: Maybe(t.string),
        nationality2: Maybe(t.string),
        clientNo2: Maybe(t.string),
        relationship2: Maybe(t.string),
        clientNo3: Maybe(t.string),
        relationship3: Maybe(t.string),
        clientNo4: Maybe(t.string),
        relationship4: Maybe(t.string),
        appliedPolicys: t.array(t.type({ appliedPolicy: t.string })),
        deathDate: Maybe(t.string),
        smsIndicator: Maybe(t.string),
        emailIndicator: Maybe(t.string),
        foreignCountry: Maybe(t.string),
        attributesExt: Maybe(
          t.type({
            EMAILS_DUP: Maybe(
              t.array(
                t.type({
                  clientId: t.string,
                  relationshipType: t.string
                })
              )
            ),
            PHONES_DUP: Maybe(
              t.array(
                t.type({
                  clientId: t.string,
                  relationshipType: t.string
                })
              )
            ),
            LOCATION_CODE: Maybe(t.string),
            taxResidencyCountry: Maybe(t.string)
          })
        ),
        inheritanceDocuments: t.array(
          t.type({
            name: t.string,
            url: t.string
          })
        ),
        relativeDocuments: t.array(
          t.type({
            name: t.string,
            url: t.string
          })
        )
      })
    })
  })
)

export const Inheritance = Pulse4Ops
export type Inheritance = t.TypeOf<typeof Inheritance>
