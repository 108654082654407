/* eslint-disable @typescript-eslint/ban-types */
import { Maybe } from '@mxt/zio/codec'
import * as t from 'io-ts'
import { POApi } from '../POApi'

export namespace PartialWithDrawalDebitServices {
  export interface ExportData {
    order?: string
    sort?: string
    start?: number
    page?: number
    size?: number
    policyNumber?: string
  }
  export const ReportDebitDataItem = t.array(
    t.type({
      policyNumber: Maybe(t.string),
      fromDate: Maybe(t.string),
      toDate: Maybe(t.string),
      currentFunction: Maybe(t.string),
      fundTopup1: Maybe(t.string),
      fundTopup2: Maybe(t.string),
      fundTopup3: Maybe(t.string),
      fundTopup4: Maybe(t.string),
      fundTopup5: Maybe(t.string),
      fundTopup6: Maybe(t.string),
      fundTopup7: Maybe(t.string),
      installPremium: Maybe(t.string),
      paymentStatus: Maybe(t.string),
      chargeDate: Maybe(t.string),
      reasonFail: Maybe(t.string),
      dateTime: Maybe(t.string),
      ptd: Maybe(t.string),
      amlRegister: Maybe(t.string),
    })
  )
  export type ReportDebitDataItem = t.TypeOf<typeof ReportDebitDataItem>

  export const ReportsDebitData = t.type({
    data: Maybe(ReportDebitDataItem),
    total: t.number,
    page: Maybe(t.number),
    size: Maybe(t.number),
    order: Maybe(t.string),
    sort: Maybe(t.string)
  })
  export type ReportsDebitData = t.TypeOf<typeof ReportsDebitData>

  export const getReportData = (query: ExportData) =>
    POApi.post(`banca-sea/report/partial-withdraw/register`)(ReportsDebitData)(query)

  const ActionName: {
    code: string
    vi: string
    en: string
  }[] = [
    {
      code: 'N',
      vi: 'Đăng ký yêu cầu',
      en: 'Register'
    },
    {
      code: 'D',
      vi: 'Huỷ đăng ký',
      en: 'Cancel'
    }
  ]

  const ActionNameMapData = new Map<String, Map<string, string>>(
    ActionName.map((item) => [
      item.code,
      new Map<string, string>([
        ['vi', item.vi],
        ['en', item.en]
      ])
    ])
  )

  export const getActionName = (code: string) => (languageCode: string) =>
    ActionNameMapData.get(code)?.get(languageCode) ?? '-'
}
