import * as React from 'react'
import { PolicyServiceProps, UploadedFilesInfo } from '../policy-service-props'
import { Column, FieldText, Error } from '@pulseops/submission/common'
import {
  AppContext,
  FieldList,
  Panel,
  Title,
  Select,
  ProductOptionSwitchingService,
  ErrorHandling,
  formatNumberWithComma,
  ProductOptionSwitching as ProductOptionSwitchingData,
  SelectOption,
  form2,
  TransactionType,
  ImgUploadMutiple,
  TaskType
} from '@pulseops/common'
import { View, Text } from 'react-native'
import { useTranslation } from 'react-i18next'
import { pipe } from 'fp-ts/function'
import { Controller } from 'react-hook-form'
import { RequestAuthenticateData } from '../../request-authen'
import { ZIO } from '@mxt/zio'
import { ProductOptionSwitchingConst } from './product-option-switching.const'
import * as t from 'io-ts'
import { FileAttachmentItem, FileAttachments } from '../../common'
import i18next from 'i18next'
import { withMessage } from 'io-ts-types'
import { useIsFocused } from '@react-navigation/native'

export namespace ProductOptionSwitchingForm {
  function isValidatedBenefits() {
    const checkedBenefit =
      ProductOptionDefaultData?.benefits?.value === 'OPT1' || ProductOptionDefaultData?.benefits?.value === 'HSC'
    return checkedBenefit
  }

  type FileAttachmentsBrandType = {
    readonly FileAttachments: unique symbol
  }

  type FileAttachmentList = t.Branded<FileAttachments, FileAttachmentsBrandType>

  const ProductOptionSwitching = t.intersection([
    t.type({
      isVLR: t.boolean,
      newSumAssured: t.number,
      newInstallmentPremium: t.number,
      healthDeclaration: pipe(
        t.array(FileAttachmentItem),
        form2.refine(
          (l): l is FileAttachmentList => isValidatedBenefits() || l.length > 0,
          () => i18next.t('message:MS020001', { field: i18next.t('submission:HealthDeclaration') }),
          'FileAttachments'
        )
      )
    }),
    t.union([
      t.type({
        isVLR: t.literal(true),
        benefits: pipe(
          withMessage(form2.selectOption.required, () =>
            i18next.t('message:MS020001', { field: i18next.t('submission:NewInsuranceBenefits') })
          )
        )
      }),
      t.type({
        isVLR: t.literal(false),
        benefits: pipe(
          withMessage(form2.selectOption.required, () =>
            i18next.t('message:MS020001', { field: i18next.t('submission:NewEducationalBenefits') })
          )
        )
      })
    ])
  ])

  export type ProductOptionSwitching = t.TypeOf<typeof ProductOptionSwitching>

  export const codec = ProductOptionSwitching

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.OutputOf<typeof codec>
}

let ProductOptionDefaultData: ProductOptionSwitchingForm.Raw | null = null

interface Props extends PolicyServiceProps<ProductOptionSwitchingData.SubmitData> {
  policyNumber: string
}

export const ProductOptionSwitching = ({ policyNumber, isConfirmed, initSubmission, officeCode }: Props) => {
  const { t, i18n } = useTranslation()
  const isFocused = useIsFocused()
  const { showToast } = React.useContext(AppContext.AppContextInstance)
  const detail = pipe(ProductOptionSwitchingService.getDetail(policyNumber), ErrorHandling.runDidUpdate([policyNumber]))

  const { base } = form2.useForm(ProductOptionSwitchingForm.codec, {
    defaultValues: {
      healthDeclaration: [],
      benefits: null,
      newSumAssured: 0,
      newInstallmentPremium: 0
    }
  })

  ProductOptionDefaultData = Object.assign(ProductOptionDefaultData ?? {}, base.watch())

  React.useEffect(() => {
    return () => {
      base.reset({
        healthDeclaration: [],
        benefits: null,
        newSumAssured: 0,
        newInstallmentPremium: 0
      })
    }
  }, [isFocused])

  React.useEffect(() => {
    if (detail) {
      base.setValue('isVLR', isVLR(detail.coverageCode))
    }
  }, [detail])

  const isVLR = (coverageCode: string) => {
    return coverageCode.startsWith('VLR') || coverageCode.startsWith('U')
  }

  const mapVLROptions = (tpdBenefitList: ProductOptionSwitchingData.TpdBenefit[]): SelectOption[] => {
    const isExistedBenefit = tpdBenefitList.some((x) => x.tpdBenefit === 'OPT1')
    if (detail?.coverageCode === ProductOptionSwitchingConst.ULPProduct.ULR6 && !isExistedBenefit) {
      tpdBenefitList.push({
        tpdBenefit: 'OPT1',
        description: 'Death/TPD benefit option',
        descriptionVi: 'Quyền lợi Tử vong/Thương tật toàn bộ và vĩnh viễn sau 70 tuổi'
      })
    }
    return tpdBenefitList
      .filter((_) => _.tpdBenefit !== detail?.benefitOption)
      .map((item) => ({
        label: i18n.language === 'en' ? item.description : item.descriptionVi,
        value: item.tpdBenefit
      }))
  }
  const mapEUSOptions = (eduBenefitList: ProductOptionSwitchingData.EduBenefit[]): SelectOption[] => {
    const filteredEduBenefitList: ProductOptionSwitchingData.EduBenefit[] = detail
      ? eduBenefitList.filter((eduItem) => eduItem.coverageCode === detail?.coverageCode)
      : []
    let eduOptionList: SelectOption[] = []
    if (filteredEduBenefitList.length > 0) {
      eduOptionList = [
        {
          label:
            i18n.language === 'en' ? filteredEduBenefitList[0].description1 : filteredEduBenefitList[0].description1Vi,
          value: filteredEduBenefitList[0].option1
        },
        {
          label:
            i18n.language === 'en' ? filteredEduBenefitList[0].description2 : filteredEduBenefitList[0].description2Vi,
          value: filteredEduBenefitList[0].option2
        }
      ].filter((_) => _.value !== detail?.benefitOption)
    }
    return eduOptionList
  }

  const renderTextValue = (value: string) => {
    return (
      <Text
        style={{
          fontSize: 15,
          color: '#000000'
        }}
      >
        {t(`${detail?.coverageCode}:${value}`)}
      </Text>
    )
  }

  const getNewSumAssured = (policyNumber: string, benefitOption: string) => {
    pipe(
      [ProductOptionSwitchingConst.ULPProduct.VLR2, ProductOptionSwitchingConst.ULPProduct.VLR3].includes(
        detail?.coverageCode as ProductOptionSwitchingConst.ULPProduct
      )
        ? pipe(
          ProductOptionSwitchingService.getNewSumAssured(policyNumber, benefitOption),
          ZIO.map((response) => ({
            newSumAssured: response.newSumAssured || 0,
            newInstallmentPremium: detail?.curRenewalPremium || 0
          }))
        )
        : ZIO.succeed({
          newSumAssured: detail?.currentSumAssured || 0,
          newInstallmentPremium: detail?.curRenewalPremium || 0
        }),
      ZIO.map((responseData) => {
        base.setValue('newSumAssured', responseData.newSumAssured)
        base.setValue('newInstallmentPremium', responseData.newInstallmentPremium)
      }),
      ZIO.unsafeRun({})
    )
  }

  const isRequiredHealthDeclaration = () => {
    return base.watch('benefits')?.value !== 'OPT1' && base.watch('benefits')?.value !== 'HSC'
  }

  const getFieldListData = (coverageCode: string): FieldList.FieldType[] => {
    const isEUS = !isVLR(coverageCode)
    const fields: FieldList.FieldType[] = [
      {
        label: isEUS ? t('submission:CurrentEducationalBenefits') : t('submission:CurrentInsuranceBenefits'),
        value: '',
        labelStyle: { marginBottom: 6 },
        render: () => renderTextValue(detail?.benefitOption || '-')
      },

      {
        label: t('submission:CurrentSumAssured'),
        labelStyle: { marginBottom: 6 },
        value: '',
        suffix: 'VND',
        render: () => renderTextValue(`${formatNumberWithComma(detail?.currentSumAssured || 0)} VND`)
      },
      {
        label: t('submission:InstallmentPremium'),
        labelStyle: { marginBottom: 6 },
        value: '',
        suffix: 'VND',
        render: () => renderTextValue(`${formatNumberWithComma(detail?.curRenewalPremium || 0)} VND`)
      },
      {
        label: isEUS ? t('submission:NewEducationalBenefits') : t('submission:NewInsuranceBenefits'),
        required: !isConfirmed,
        labelStyle: { marginBottom: isConfirmed ? 6 : 0 },
        render: () => {
          return (
            <Controller
              control={base.control}
              name="benefits"
              render={({ field, fieldState: { error } }) => {
                const isReadOnly = isConfirmed
                if (isReadOnly) {
                  return renderTextValue(field.value?.label || '-')
                }
                return (
                  <View>
                    <Select
                      {...field}
                      onChange={(val) => {
                        field.onChange(val)
                        getNewSumAssured(policyNumber, val?.value ?? '')
                      }}
                      placeholder={t('common:Select')}
                      disabled={isConfirmed}
                      options={
                        isEUS
                          ? mapEUSOptions(detail?.eduBenefitList || [])
                          : mapVLROptions(detail?.tpdBenefitList || [])
                      }
                      errorMessage={error?.message}
                    />
                  </View>
                )
              }}
            />
          )
        }
      },
      {
        label: t('submission:NewSumAssured'),
        labelStyle: { marginBottom: 6 },
        value: '',
        suffix: 'VND',
        render: () => renderTextValue(`${formatNumberWithComma(base.watch('newSumAssured'))} VND`)
      },
      {
        label: t('submission:NewInstallmentPremium'),
        labelStyle: { marginBottom: 6 },
        value: '',
        suffix: 'VND',
        render: () => renderTextValue(`${formatNumberWithComma(base.watch('newInstallmentPremium'))} VND`)
      }
    ]
    return fields
  }

  const validateNextStep = async () => {
    const MMItem = ProductOptionSwitchingConst.getProductMM(detail?.coverageCode)
    if (detail?.coverageCode === 'EUS3') {
      return await checkRiskCessDate()
    } else {
      switch (detail?.coverageCode) {
        // case ProductOptionSwitchingConst.ULPProduct.VLR3:
        case ProductOptionSwitchingConst.ULPProduct.VLR2: {
          if (base.watch('newSumAssured') < MMItem.minSA || base.watch('newSumAssured') > MMItem.maxSA) {
            showToast(t('message:MS050034'), 'error')
            return false
          } else {
            return true
          }
        }
        default:
          return true
      }
    }
  }

  const checkRiskCessDate = async () => {
    const riskCessDateEUS3 =
      detail?.coverageCode === 'EUS3'
        ? await pipe(ProductOptionSwitchingService.getRiskCessDate(policyNumber), ErrorHandling.run())
        : true
    if (!riskCessDateEUS3) {
      showToast(t('message:MS050034'), 'error')
    }
    return riskCessDateEUS3
  }

  const getUploadedFilesInfo = () => {
    let uploadedFileList: UploadedFilesInfo[] = []
    if (base.watch('healthDeclaration').length > 0) {
      uploadedFileList.push({
        uploadFiles: base.watch('healthDeclaration') as FileAttachmentItem[],
        transactionType: TransactionType.PRODUCT_OPTION_SWITCHING,
        docTypeCode: 'DPS09',
        category: TaskType.PolicyService,
        policyNumber: policyNumber ?? '',
        officeCode: officeCode ?? ''
      })
    }
    return uploadedFileList
  }

  initSubmission({
    validate: async () => {
      const isFormValid = await base.trigger()
      const isNextStep = await validateNextStep()
      if (!isFormValid || !detail || !isNextStep) return false
      // const formValues = base.getValues()
      return {
        url: (policyNum) => `wf-api/policy/${policyNum}/product-option-switching`,
        body: {
          benefits: base.getValues('benefits.value'),
          currentSumAssured: detail.currentSumAssured,
          oldBenefit: detail?.benefitOption,
          minimumSumAssured: 0,
          newSumAssured: base.watch('newSumAssured'),
          riskSumAssured: 0
        },
        collerationId: policyNumber,
        transaction: TransactionType.PRODUCT_OPTION_SWITCHING,
        transactionName: RequestAuthenticateData.TransactionLabelShort(TransactionType.PRODUCT_OPTION_SWITCHING),
        uploadedFilesInfo: getUploadedFilesInfo()
      }
    },
    clear: () => {
      base.setValue('benefits', null)
    }
  })

  return (
    <Column flex={1}>
      {detail && (
        <>
          <Title title={t('submission:REQUEST_DETAIL')} wrapperStyle={{ marginTop: 30 }} />
          <Panel isExand={false} containerStyle={{ backgroundColor: '#FAFAFA' }}>
            <FieldList dataSource={getFieldListData(detail.coverageCode)} viewStyle={{ paddingHorizontal: 15 }} />
            <View style={{ paddingHorizontal: 15 }}>
              <FieldText
                text={t('submission:HealthDeclaration')}
                isRequired={isRequiredHealthDeclaration()}
              ></FieldText>
              <Controller
                control={base.control}
                name={'healthDeclaration'}
                render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                  <Column>
                    <ImgUploadMutiple
                      value={value as FileAttachments}
                      onChange={onChange}
                      onBlur={onBlur}
                      timeFormat={'DD/MM/YYYY HH:mm'}
                      disabled={isConfirmed}
                    />
                    {error?.message && isRequiredHealthDeclaration() && (
                      <Error message={!!value && value.length > 0 ? '' : error?.message} />
                    )}
                  </Column>
                )}
              />
            </View>
          </Panel>
        </>
      )}
    </Column>
  )
}