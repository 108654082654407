import * as React from 'react'
import {
  ModalComponent,
  PayoutPopup,
  ModalComponentRef,
  ModalAction,
  Input,
  form2,
  SelectSearch,
  Title,
  Panel,
  assets,
  ImgUploadMutiple,
  GeneralService,
  ErrorHandling,
  GeneralData,
  DatePicker,
  PayoutService,
  PulseOpsFormat,
  SelectOption,
  removeAbundantSpacesInText
} from '@pulseops/common'
import { Button } from '@pulseops/business/core'
import { useWindowDimensions, ScrollView, StyleSheet, View, Text } from 'react-native'
import {
  Checkbox,
  Error,
  withMaxDate,
  withMaxStringLength,
  withMinDate,
  withMinStringLength,
  withNumberGreater
} from '@pulseops/submission/common'
import { useTranslation } from 'react-i18next'
// import { PaidAtBankform } from './paid-at-bank.form'
import { Controller } from 'react-hook-form'
import { TableCustom } from '../../common/table'
import _ from 'lodash'
import { ZIO } from '@mxt/zio'
import { pipe } from 'fp-ts/function'
// import { OwnerInfo } from './constants'
import { Client, OwnerInfo, specialCharacters } from '../cash-at-counter'
import moment from 'moment'
import * as t from 'io-ts'
import { NonEmptyString, withMessage } from 'io-ts-types'
import i18next from 'i18next'
import * as O from 'fp-ts/lib/Option'
export namespace PaidAtBankform {
  const File = t.type({
    fileName: t.string,
    fileExtension: t.string,
    size: t.number,
    base64: t.string,
    uploadedDate: form2.date.required,
    file: form2.file.required
  })
  type File = t.TypeOf<typeof File>

  type FileListBrand = {
    readonly FileList: any // unique symbol
  }

  type IssueDateBrand = {
    readonly MS050014: unique symbol
    readonly MS090010: unique symbol
  }
  type ForeignAddressBrand = {
    readonly MS020001: unique symbol
    readonly MS050223: unique symbol
  }

  export type FileList = t.Branded<File[], FileListBrand>
  type ForeignAddress = t.Branded<string | null, ForeignAddressBrand>
  export type IssueDate = t.Branded<form2.date.ValidDate, IssueDateBrand>

  const PaidAtBankform = t.intersection([
    t.type({
      amount: pipe(
        withMessage(form2.number.required, () =>
          i18next.t('message:MS020001', { field: i18next.t('requestInfo:Amount') })
        ),
        withNumberGreater(0)
      ),
      issueDate: pipe(
        withMessage(form2.date.required, () => i18next.t('message:MS020001', { field: i18next.t('claim:IssuedDate') })),
        // withMaxDate(new Date(), 'message:MS050013', 'claim:IssuedDate')
        form2.refine(
          (l): l is IssueDate =>
            PulseOpsFormat.diffTwoDateAsMonth(new Date(), moment(l.toDateString()).toDate()) < 15 * 12,
          () => i18next.t('message:MS050014'),
          'MS050014'
        ),
        form2.refine(
          (l): l is IssueDate => moment(l.toDateString()).valueOf() <= moment().valueOf(),
          () => i18next.t('message:MS090010'),
          'MS090010'
        )
      ),
      issueBy: withMessage(form2.selectOption.required, () =>
        i18next.t('message:MS020001', { field: i18next.t('claim:IssuedBy') })
      ),
      bank: withMessage(form2.selectOption.required, () =>
        i18next.t('message:MS020001', { field: i18next.t('submission:Bank') })
      ),
      branch: withMessage(form2.selectOption.required, () =>
        i18next.t('message:MS020001', { field: i18next.t('submission:Branch') })
      )
    }),
    t.union([
      t.type({
        payeeNotPO: t.literal(false),
        payeeName: pipe(form2.string.required),
        idNumber: pipe(form2.string.required)
      }),
      t.type({
        payeeNotPO: t.literal(true),
        payeeName: t.string,
        surname: withMessage(NonEmptyString, () =>
          i18next.t('message:MS020001', { field: i18next.t('submission:CASurname') })
        ),
        givenName: withMessage(NonEmptyString, () =>
          i18next.t('message:MS020001', { field: i18next.t('submission:GivenName') })
        ),
        dob: pipe(
          withMessage(form2.date.required, () =>
            i18next.t('message:MS020001', { field: i18next.t('requestInfo:DOB') })
          ),
          withMaxDate(new Date(), 'message:MS050013', 'submission:DateOfBirth'),
          withMinDate(new Date('1900-01-01'))
        ),
        relationshipWithPO: withMessage(form2.selectOption.required, () =>
          i18next.t('message:MS020001', { field: i18next.t('submission:RelationshipWithPO') })
        ),
        mobileNumber: form2.string.optional,
        // pipe(
        //   withMessage(form2.string.required, () =>
        //     i18next.t('message:MS020001', { field: i18next.t('submission:PhoneNumber') })
        //   )
        // ),
        email: pipe(
          // withMessage(form2.string.required, () =>
          //   i18next.t('message:MS020001', { field: i18next.t('requestInfo:Email') })
          // )
          withMessage(form2.email.optional, () =>
            i18next.t('message:MS050013', { field: i18next.t('requestInfo:Email') })
          )
        ),
        nationality: withMessage(form2.selectOption.required, () =>
          i18next.t('message:MS020001', { field: i18next.t('submission:Nationality') })
        ),
        residenceCountry: withMessage(form2.selectOption.required, () =>
          i18next.t('message:MS020001', { field: i18next.t('submission:ResidenceCountry') })
        ),
        occupation: pipe(form2.selectOption.optional),
        gender: withMessage(form2.selectOption.required, () =>
          i18next.t('message:MS020001', { field: i18next.t('submission:Gender') })
        ),
        salutation: withMessage(form2.selectOption.required, () =>
          i18next.t('message:MS020001', { field: i18next.t('submission:Salutation') })
        ),
        married: withMessage(form2.selectOption.required, () =>
          i18next.t('message:MS020001', { field: i18next.t('submission:SubMarried') })
        ),
        address: t.string,
        city: withMessage(form2.selectOption.required, () =>
          i18next.t('message:MS020001', { field: i18next.t('submission:CityProvince') })
        ),
        district: withMessage(form2.selectOption.required, () =>
          i18next.t('message:MS020001', { field: i18next.t('submission:District') })
        ),
        ward: withMessage(form2.selectOption.required, () =>
          i18next.t('message:MS020001', { field: i18next.t('submission:Ward') })
        ),
        street: withMessage(NonEmptyString, () =>
          i18next.t('message:MS020001', { field: i18next.t('submission:HouseNumber') })
        ),
        // foreignAddress: pipe(form2.string.optional),
        foreignAddress: pipe(
          form2.string.optional,
          form2.refine(
            (l): l is ForeignAddress =>
              !!l ||
              (paidAtBankFormData.payeeNotPO &&
                (['VN'].includes(paidAtBankFormData?.nationality?.value ?? '') ||
                  ['VN'].includes(paidAtBankFormData?.nationality2?.value ?? ''))),
            () => i18next.t('message:MS020001', { field: i18next.t('submission:ForeignAddress') }),
            'MS020001'
          ),
          form2.refine(
            (l): l is ForeignAddress => !l || !specialCharacters.some((item) => l.includes(item)),
            () => i18next.t('message:MS050223'),
            'MS050223'
          )
        ),
        nationality2: pipe(form2.selectOption.optional),
        foreignCountry: pipe(form2.selectOption.optional),
        relativeDocument: pipe(
          t.array(File),
          form2.refine(
            (l): l is FileList => l && l.length > 0,
            () => i18next.t('message:MS020001', { field: i18next.t('submission:RelativeDocument') }),
            'FileList'
          )
        ),
        idNumber: pipe(
          withMessage(form2.string.required, () =>
            i18next.t('message:MS020001', { field: i18next.t('requestInfo:IDNumber') })
          ),
          withMinStringLength(8),
          withMaxStringLength(24)
        ),
        idNumberSearch: pipe(
          withMessage(form2.string.required, () =>
            i18next.t('message:MS020001', { field: i18next.t('requestInfo:IDNumber') })
          ),
          withMinStringLength(8),
          withMaxStringLength(24)
        )
      })
    ])
  ])

  export const codec = PaidAtBankform

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.OutputOf<typeof codec>
}

let paidAtBankFormData: PaidAtBankform.Raw = {
  amount: '',
  payeeNotPO: true,
  payeeName: '',
  idNumber: '',
  issueDate: null,
  issueBy: null,
  bank: null,
  branch: null,
  nationality: null,
  nationality2: null,
  dob: undefined,
  gender: null,
  mobileNumber: '',
  email: '',
  salutation: null,
  married: null,
  occupation: null,
  relationshipWithPO: null,
  address: '',
  foreignAddress: '',
  foreignCountry: null,
  residenceCountry: null,
  surname: '',
  givenName: '',
  city: null,
  district: null,
  ward: null,
  street: '',
  relativeDocument: [],
  idNumberSearch: ''
}
interface Props {
  primaryPolicy: string
  visible: boolean
  maxAmount: number
  ownerInfo: OwnerInfo
  onClose: () => void
  onConfirm: (payouts: PayoutPopup.PayoutData) => void
  isShowPayeeNotPoOption?: boolean
  isFractionFormat?: boolean
}
export type DataSource = {
  [key: string]: string | number | JSX.Element
}
export const PaidAtBankPopup = ({
  visible,
  primaryPolicy,
  maxAmount,
  ownerInfo,
  isShowPayeeNotPoOption = false,
  onClose,
  onConfirm,
  isFractionFormat
}: Props) => {
  const { width, height } = useWindowDimensions()
  const { t, i18n } = useTranslation()
  const modalRef = React.useRef<ModalComponentRef | null>(null)

  const { iacRela, nationalityOptions, occupations, salution, married, genders, provinces, banks, issuedPlaceList } =
    pipe(
      ZIO.zipPar(
        GeneralService.getCountries,
        GeneralService.getOccupations,
        GeneralService.getIACRelationships,
        GeneralService.getProvinces,
        PayoutService.getBanks("2"),
        GeneralService.getIssuedPlaceList()
      ),
      ZIO.map(([countries, occupations, iacRela, provinces, banks, issuedPlaceList]) => {
        const nationalityOptions = countries
        const salution = [
          {
            value: 'BÀ',
            label: t('submission:Mrs')
          },
          {
            value: 'CHÁU',
            label: t('submission:Child')
          },
          {
            value: 'CÔ',
            label: t('submission:Ms')
          },
          {
            value: 'ÔNG',
            label: t('submission:Mr')
          },
          {
            value: 'QUÝ',
            label: t('submission:Quy')
          }
        ]
        const married = [
          {
            value: 'M',
            label: t('submission:Married')
          },
          {
            value: 'S',
            label: t('submission:Single')
          },
          {
            value: 'W',
            label: t('submission:Widowed')
          },
          {
            value: 'U',
            label: t('submission:Unknown')
          },
          {
            value: 'D',
            label: t('submission:Divorced')
          }
        ]
        const genders = [
          {
            value: 'F',
            label: t('common:Female')
          },
          {
            value: 'M',
            label: t('common:Male')
          }
        ]

        const issuedPlaceArr =
          issuedPlaceList && issuedPlaceList.length > 0
            ? issuedPlaceList?.map((item) => {
              return {
                value: item.code ?? '',
                label: item.issueBy ?? ''
              }
            })
            : []
        return {
          iacRela: iacRela?.map((item) => {
            return {
              value: item.code,
              // label: t(`submission:${item.name}`)
              name: item.name,
              nameEn: item.nameEn
            }
          }),
          nationalityOptions: nationalityOptions.map((item: GeneralData) => {
            return {
              value: item.code,
              label: t(`submission:${item.name}`)
            }
          }),
          occupations: occupations.map((item: GeneralData) => {
            return {
              value: item.code,
              label: t(`submission:${item.name}`)
            }
          }),
          provinces: provinces.map((item) => {
            return {
              value: item.code,
              label: item.name
            }
          }),
          banks,
          salution,
          married,
          genders,
          issuedPlaceList: issuedPlaceArr
        }
      }),
      ErrorHandling.runDidMount()
    ) || {
      iaRela: [],
      nationalityOptions: [],
      occupations: [],
      salution: [],
      married: [],
      genders: [],
      banks: [],
      issuedPlaceList: []
    }

  const [doubClients, setDoubClients] = React.useState<Client[] | null>([])
  const [copyInfo, setCopyInfo] = React.useState<boolean>(false)
  const [bankBranchs, setBankBranchs] = React.useState<{ code: string; name: string }[]>([])
  const [districtOptions, setDistrictOptions] = React.useState<SelectOption[]>([])
  const [wardOptions, setWardOptions] = React.useState<SelectOption[]>([])
  const [searchMsg, setSearchMsg] = React.useState<string>('')
  const paidAtBankForm = form2.useForm(
    PaidAtBankform.codec,
    {
      defaultValues: { payeeNotPO: false, issueDate: null }
    },
    'onChange'
  )
  const formData = paidAtBankForm.base.watch()
  const { payeeNotPO, amount } = formData
  paidAtBankFormData = Object.assign(paidAtBankFormData, {
    ...formData
  })

  const getBankBranch = (code: string) =>
    pipe(
      ZIO.effect(() => {
        const codePulse = pipe(banks.find(x => x.code === code), O.fromNullable, O.map((item) => item.codePulse ?? ''), O.getOrElse(() => ''))
        return {
          code: code, codePulse: codePulse
        }
      }),
      ZIO.flatMap((bankCodeInfo) => PayoutService.bankBranch(bankCodeInfo.code, bankCodeInfo.codePulse)),
      ZIO.tap((branchs) => {
        setBankBranchs(branchs.length ? branchs : [])
        return ZIO.unit
      }),
      ErrorHandling.run()
    )

  React.useEffect(() => {
    paidAtBankForm.base.setValue('amount', `${maxAmount}`)
  }, [maxAmount])

  React.useEffect(() => {
    // paidAtBankForm.base.setValue('office', 'A70 - Quận 5')
    paidAtBankForm.base.setValue('payeeName', ownerInfo.ownerName)
    paidAtBankForm.base.setValue('idNumber', ownerInfo.nationalId)
  }, [ownerInfo])

  React.useEffect(() => {
    if (payeeNotPO) {
      paidAtBankForm.base.setValue('idNumberSearch', '')
      paidAtBankForm.base.setValue('relativeDocument', [])
    } else {
      setDoubClients(null)
      // paidAtBankForm.base.reset({
      //   amount: maxAmount.toString(),
      //   payeeName: ownerInfo?.ownerName,
      //   idNumber: ownerInfo?.nationalId,
      //   payeeNotPO: false
      // })
      paidAtBankForm.base.setValue('payeeName', ownerInfo?.ownerName)
      paidAtBankForm.base.setValue('idNumber', ownerInfo?.nationalId)
      paidAtBankForm.base.setValue('payeeNotPO', false)
    }
  }, [payeeNotPO])

  const getDistricts = (provinceCode: string) => {
    paidAtBankForm.base.setValue('district', null)
    paidAtBankForm.base.setValue('ward', null)
    return pipe(
      GeneralService.getDistricts(provinceCode),
      ZIO.map((districts) => {
        const districtOptions = districts.map((district) => {
          return {
            value: district.code,
            label: i18n.language === 'vi' ? district.name : district.name
          }
        })
        setDistrictOptions(districtOptions)
        return districtOptions
      }),
      ErrorHandling.run()
    )
  }

  const getWards = (provinceCode: string, districtCode: string) => {
    paidAtBankForm.base.setValue('ward', null)
    return pipe(
      GeneralService.getWards({ districtCode, provinceCode }),
      ZIO.map((wards) => {
        const wardOptions = wards.map((ward) => {
          return {
            value: ward.code,
            label: i18n.language === 'vi' ? ward.name : ward.name
          }
        })
        setWardOptions(wardOptions)
        return wardOptions
      }),
      ErrorHandling.run()
    )
  }

  const isForeignAddressRequired = () => {
    return (
      (paidAtBankForm.base.watch('nationality.value') && !['VN'].includes(paidAtBankForm.base.watch('nationality.value'))) ||
      (paidAtBankForm.base.watch('nationality2.value') && !['VN'].includes(paidAtBankForm.base.watch('nationality2.value')))
    )
  }

  const onChangeNationality = (nationality: SelectOption | null, nationality2: SelectOption | null) => {
    if (nationality?.value === 'VN' || nationality2?.value === 'VN') {
      paidAtBankForm.base.clearErrors('foreignAddress')
    }
  }

  const getClientsInfo = () => {
    if (_.get(formData, 'idNumberSearch')) {
      pipe(
        PayoutService.getClients(_.get(formData, 'idNumberSearch')),
        ZIO.tap((clients) => {
          const checkDouble = clients.reduce((unique: Client[], o) => {
            if (
              !unique.some(
                (obj: Client) => obj?.sureName === o.sureName && obj?.gender === o.gender && obj?.dob === o.dob
              )
            ) {
              unique.push({
                ...o,
                sureName: o.sureName,
                giveName: o.giveName,
                fullName: o.sureName + ' ' + o.giveName,
                address01: o.address01 ?? '',
                address02: o.address02 ?? '',
                address03: o.address03 ?? '',
                address04: o.address04 ?? '',
                idDate: o.idDate ?? '',
                nationality2: o.nationality2 ?? '',
                email: o.email.trim(),
                foreignStreet: o.foreignStreet ?? '',
                foreignCountry: o.foreignCountry ?? ''
              })
            }
            return unique
          }, [])
          setDoubClients(checkDouble)
          if (_.isEmpty(clients)) {
            setCopyInfo(false)
            // paidAtBankForm.base.setValue('amount', maxAmount.toString())
            paidAtBankForm.base.setValue('payeeName', '')
            paidAtBankForm.base.setValue('surname', '')
            paidAtBankForm.base.setValue('givenName', '')
            paidAtBankForm.base.setValue('idNumber', _.get(formData, 'idNumberSearch') ?? '')
            paidAtBankForm.base.setValue('relativeDocument', [])
            setSearchMsg(t('message:MS030033'))
          } else {
            setSearchMsg('')
          }
          return ZIO.unit
        }),
        ErrorHandling.run()
      )
    }
  }

  const getItemByList = (list: SelectOption[] | null | undefined, oldItem: string) => {
    return pipe(
      list?.find((x) => oldItem.trimEnd().toLowerCase().includes(x.label.toLowerCase())),
      O.some,
      O.toUndefined
      // O.getOrElse(() => ({ value: '', label: '' }))
    )
  }

  const getAddressInfo = (proviceVal: string, districtVal: string, wardVal: string) => {
    return pipe(
      ZIO.effect(() => {
        const cityItem = getItemByList(provinces, proviceVal)
        return cityItem
      }),
      ZIO.flatMap((cityItem) => {
        return !!cityItem
          ? pipe(
            ZIO.fromPromise(() => getDistricts(cityItem?.value ?? '')),
            ZIO.map((districtList) => {
              const districtItem = getItemByList(districtList, districtVal)
              return {
                cityItem,
                districtItem
              }
            })
          )
          : ZIO.succeed(cityItem)
      }),
      ZIO.flatMap((dataItem) =>
        !!dataItem
          ? pipe(
            ZIO.fromPromise(() => getWards(dataItem.cityItem.value, dataItem.districtItem?.value ?? '')),
            ZIO.map((wardList) => {
              const wardItem = getItemByList(wardList, wardVal)
              return {
                cityItem: dataItem.cityItem,
                districtItem: dataItem.districtItem,
                wardItem
              }
            })
          )
          : ZIO.succeed(dataItem)
      ),
      ZIO.unsafeRun({})
    )
  }

  const getForeignCountry = (foreignCode: string) => {
    return nationalityOptions.find((item) => item.value === foreignCode)
  }

  const getFilteredBankList = () => {
    return banks.map((item) => ({
      value: item.code,
      label: item.name
    }))
  }

  const handleCopyInfo = async (index: number, data: DataSource) => {
    const dataItem = await getAddressInfo(
      data?.address04 as string,
      data?.address03 as string,
      data?.address02 as string
    )
    paidAtBankForm.base.reset({
      ...formData,
      ...{
        // payeeName: data?.sureName.toString(),
        surname: data?.sureName.toString(),
        givenName: data?.giveName.toString(),
        idNumber: data?.securityNo.toString(),
        payeeName: data?.sureName.toString(),
        married: married.find((_) => _.value === data.married) || null,
        salutation: salution.find((_) => _.value === data.salutation) || null,
        occupation: occupations.find((_) => _.value === data.occupationCode) || null,
        nationality: nationalityOptions.find((_) => _.value === data.nationality) || null,
        nationality2: nationalityOptions.find((_) => _.value === data.nationality2) || null,
        gender: genders.find((_) => _.value === data.gender) || null,
        residenceCountry: nationalityOptions.find((_) => _.value === data.countryCode) || null,
        dob: PulseOpsFormat.getStringToDate(data.dob.toString(), 'YYYYMMDD'),
        // address: `${data?.address01} ${data?.address02} ${data?.address03} ${data?.address04}`,
        address: '',
        mobileNumber: data?.mobileNumber?.toString().trim(),
        email: data?.email?.toString(),
        city: dataItem?.cityItem,
        district: dataItem?.districtItem,
        ward: dataItem?.wardItem,
        street: `${data?.address01 ?? ''}`,
        foreignAddress: data?.foreignStreet.toString(),
        foreignCountry: getForeignCountry(data?.foreignCountry.toString())
      }
    })
    setCopyInfo(true)
    setDoubClients([])
  }

  const onPressClose = () => {
    onClose()
  }

  const onPressConfirm = paidAtBankForm.handleSubmit(async (data) => {
    // const amount = typeof data.amount === 'string' ? Number(data.amount) : data.amount
    const amount = Number(paidAtBankForm.base.watch('amount'))
    const payeeName = payeeNotPO
      ? removeAbundantSpacesInText(_.get(formData, 'surname') ?? '') +
      ' ' +
      removeAbundantSpacesInText(_.get(formData, 'givenName') ?? '')
      : _.get(formData, 'payeeName') ?? ''
    const relativeDocument = payeeNotPO
      ? _.get(formData, 'relativeDocument').map((item) => ({
        ...item,
        uploadedDate: item.uploadedDate as Date,
        file: item.file as File
      }))
      : undefined
    const payout: PayoutPopup.PayoutData = {
      method: PayoutPopup.PayoutMethods.PAID_AT_BANK,
      amount: amount,
      policyNum: primaryPolicy,
      poName: payeeName,
      officeCode: '',
      // bankCode: data.bank.value,
      // bankName: data.bank.label,
      bankCode: _.get(formData, 'bank.value') ?? '',
      bankName: _.get(formData, 'bank.label') ?? '',
      bankAccountNum: '',
      bankAccountName: '',
      bankBranchCode: _.get(formData, 'branch.value') ?? '',
      bankBranchName: _.get(formData, 'branch.label') ?? '',
      nationalId: formData.idNumber ?? '',
      // issueDate: moment(formData.issueDate).format('YYYYMMDD'),
      // issueBy: formData.issueBy?.value ?? '',
      officeBankCode: undefined,
      officeType: undefined,
      payee: payeeNotPO
        ? {
          paymentMethod: PayoutPopup.PayoutMethods.PAID_AT_BANK,
          isPayeeNotPO: true,
          dob: moment(_.get(formData, 'dob')).format('YYYYMMDD') ?? '',
          gender: _.get(formData, 'gender.value') ?? '',
          phone: _.get(formData, 'mobileNumber') ?? '',
          email: _.get(formData, 'email') ?? '',
          salutation: _.get(formData, 'salutation.value') ?? '',
          married: _.get(formData, 'married.value') ?? '',
          occupation: _.get(formData, 'occupation.value') ?? '',
          relationshipWithPO: _.get(formData, 'relationshipWithPO.value') ?? '',
          nationality: _.get(formData, 'nationality.value') ?? '',
          address: _.get(formData, 'address') ?? '',
          nationality2: _.get(formData, 'nationality2.value') ?? '',
          foreignAddress: removeAbundantSpacesInText(_.get(formData, 'foreignAddress') ?? ''),
          foreignCountry: _.get(formData, 'foreignCountry.value') ?? '',
          isNewClient: !copyInfo,
          residenceCountry: _.get(formData, 'residenceCountry.value') ?? '',
          payeeName: payeeName,
          issuedDate: moment(formData.issueDate).format('YYYYMMDD'),
          issuedBy: formData.issueBy?.label ?? '',
          issueCode: formData.issueBy?.value ?? '',
          name: payeeName,
          idNumber: _.get(formData, 'idNumber') ?? '',
          dod: '',
          surName: removeAbundantSpacesInText(_.get(formData, 'surname') ?? ''),
          giveName: removeAbundantSpacesInText(_.get(formData, 'givenName') ?? ''),
          city: _.get(formData, 'city.label') ?? '',
          district: _.get(formData, 'district.label') ?? '',
          ward: _.get(formData, 'ward.label') ?? '',
          street: removeAbundantSpacesInText(_.get(formData, 'street') ?? ''),
          relativeDocument: relativeDocument
        }
        : {
          paymentMethod: PayoutPopup.PayoutMethods.PAID_AT_BANK,
          isPayeeNotPO: false,
          isNewClient: false,
          payeeName: payeeName,
          idNumber: _.get(formData, 'idNumber') ?? '',
          issuedDate: moment(formData.issueDate).format('YYYYMMDD'),
          issuedBy: formData.issueBy?.label ?? '',
          issueCode: formData.issueBy?.value ?? '',
          dob: undefined,
          gender: undefined,
          phone: undefined,
          email: undefined,
          salutation: undefined,
          married: undefined,
          occupation: undefined,
          relationshipWithPO: undefined,
          nationality: undefined,
          address: undefined,
          nationality2: undefined,
          foreignAddress: undefined,
          foreignCountry: undefined,
          residenceCountry: undefined,
          name: undefined,
          dod: undefined,
          surName: undefined,
          giveName: undefined,
          city: undefined,
          district: undefined,
          ward: undefined,
          street: undefined,
          relativeDocument: relativeDocument
        }
    }
    onConfirm(payout)
  })

  const modalActions: ModalAction[] = [
    {
      text: t('common:Cancel'),
      type: 'outline',
      action: onPressClose,
      disabled: false,
      loading: false,
      onlyWide: false,
      style: styles.btnCancel
    },
    {
      text: t('submission:Confirm'),
      type: 'filled',
      action: onPressConfirm,
      disabled: !paidAtBankForm.base.formState.isValid || Number(amount || 0) > maxAmount,
      loading: false,
      onlyWide: false,
      style: styles.btnConfirm
    }
  ]
  return (
    <ModalComponent
      ref={modalRef}
      title={t('submission:PaidAtBank')}
      titleStyle={styles.modalTitle}
      contentStyle={styles.modalContent}
      modalWidth={Math.min(width * 0.9, 1024)}
      modalHeight={!_.isNull(doubClients) && _.isEmpty(doubClients) ? Math.min(height * 0.9, 768) : undefined}
      visible={visible}
      onClose={onPressClose}
      actions={modalActions}
    >
      <ScrollView style={styles.container}>
        <View
          style={{
            flex: 1,
            backgroundColor: '#FAFAFA',
            borderWidth: 1,
            borderColor: '#D3DCE6',
            marginTop: 20,
            paddingHorizontal: 30,
            paddingVertical: 22
          }}
        >
          {isShowPayeeNotPoOption && (
            <Controller
              control={paidAtBankForm.base.control}
              name="payeeNotPO"
              render={({ field }) => <Checkbox {...field} title={t('submission:PayeeIsNotPO')} fontWeight="bold" />}
            />
          )}

          {payeeNotPO ? (
            <>
              <View style={{ flexDirection: 'row', marginTop: 22, width: '50%' }}>
                <Controller
                  control={paidAtBankForm.base.control}
                  name="idNumberSearch"
                  render={({ field, fieldState: { error } }) => (
                    <View>
                      <Input
                        {...field}
                        title={t('submission:IDNumber')}
                        containerStyle={{ flex: 1 }}
                        maxLength={24}
                        disabled={!payeeNotPO}
                        errorMessage={payeeNotPO ? error?.message : ''}
                        inputType={'number'}
                        required
                      />
                      {!error?.message && !!searchMsg && <Text style={styles.searchMessage}>{searchMsg}</Text>}
                    </View>
                  )}
                />
                <Button
                  title={t('Search')}
                  onPress={getClientsInfo}
                  disabled={_.isEmpty(_.get(formData, 'idNumberSearch'))}
                  style={{ marginTop: 20 }}
                />
              </View>
              {!_.isEmpty(doubClients) && (
                <View style={{ flexDirection: 'row', marginTop: 22 }}>
                  <TableCustom
                    columns={[
                      {
                        key: '0',
                        title: t('submission:FullName'),
                        name: 'fullName'
                      },
                      {
                        key: '1',
                        title: t('submission:DOB'),
                        name: 'dob',
                        render: ({ value }) => <Text>{moment(value).format('DD/MM/YYYY')}</Text>
                      },
                      {
                        key: '2',
                        title: t('submission:IDNumber'),
                        name: 'securityNo'
                      },
                      {
                        key: '3',
                        title: t('submission:Gender'),
                        name: 'gender',
                        render: ({ value }) => <Text>{t(`submission:${value === 'F' ? 'Female' : 'Male'}`)}</Text>
                      }
                    ]}
                    dataSource={doubClients as any[]}
                    onRowSelected={handleCopyInfo}
                  />
                </View>
              )}
              {!_.isNull(doubClients) && _.isEmpty(doubClients) && (
                <Panel isExand={false} containerStyle={{ backgroundColor: '#FAFAFA', marginTop: 22 }}>
                  <Title title={t('submission:PayeeInformation')} />
                  <View style={{ flexDirection: 'row', marginTop: 22 }}>
                    <Controller
                      control={paidAtBankForm.base.control}
                      name="surname"
                      render={({ field, fieldState: { error } }) => (
                        <Input
                          {...field}
                          title={t('submission:CASurname')}
                          containerStyle={{ flex: 1, marginEnd: 30 }}
                          disabled={!payeeNotPO || copyInfo}
                          maxLength={120}
                          required
                          errorMessage={payeeNotPO ? error?.message : ''}
                        />
                      )}
                    />
                    <Controller
                      control={paidAtBankForm.base.control}
                      name="givenName"
                      render={({ field, fieldState: { error } }) => (
                        <Input
                          {...field}
                          title={t('submission:GivenName')}
                          containerStyle={{ flex: 1 }}
                          disabled={!payeeNotPO || copyInfo}
                          maxLength={120}
                          required
                          errorMessage={payeeNotPO ? error?.message : ''}
                        />
                      )}
                    />
                  </View>
                  <View style={{ flexDirection: 'row', marginTop: 22 }}>
                    <Controller
                      control={paidAtBankForm.base.control}
                      name="idNumber"
                      render={({ field, fieldState: { error } }) => (
                        <Input
                          {...field}
                          title={t('submission:IDNumber')}
                          containerStyle={{ flex: 1, marginEnd: 30 }}
                          maxLength={24}
                          // disabled={true}
                          errorMessage={payeeNotPO ? error?.message : ''}
                          required
                        />
                      )}
                    />
                    <View style={{ flex: 1 }}>
                      <View>
                        <Text style={{ fontWeight: 'bold', color: '#70777E', fontSize: 15, marginBottom: 2 }}>
                          {t('claim:IssuedDate')}
                          <Text style={{ color: '#ed1b2c', marginLeft: 5, fontSize: 15, fontWeight: 'bold' }}>*</Text>
                        </Text>
                      </View>
                      <Controller
                        control={paidAtBankForm.base.control}
                        name="issueDate"
                        render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                          <DatePicker
                            value={value}
                            onBlur={onBlur}
                            errorMessage={error?.message || ''}
                            maxDate={new Date()}
                            maxDateMessage={t('message:MS050028')}
                            onChange={(date) => {
                              // console.log(date)
                              onChange(date)
                            }}
                          />
                        )}
                      />
                    </View>
                  </View>
                  <View style={{ flexDirection: 'row', marginTop: 22 }}>
                    <Controller
                      control={paidAtBankForm.base.control}
                      name="issueBy"
                      render={({ field, fieldState: { error } }) => (
                        <View style={{ flex: 1, marginEnd: 30 }}>
                          <SelectSearch
                            key={paidAtBankForm.base.getValues('issueBy')?.value}
                            {...field}
                            required={true}
                            label={t('claim:IssuedBy')}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                            options={issuedPlaceList}
                            errorMessage={error?.message}
                          />
                        </View>
                      )}
                    />
                    <Controller
                      control={paidAtBankForm.base.control}
                      name="bank"
                      render={({ field, fieldState: { error } }) => (
                        <View style={{ flex: 1 }}>
                          <SelectSearch
                            {...field}
                            required={true}
                            label={t('submission:Bank')}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                            options={getFilteredBankList()}
                            errorMessage={error?.message}
                            onChange={(option) => {
                              setBankBranchs([])
                              paidAtBankForm.base.setValue('branch', null)
                              if (option) {
                                getBankBranch(option.value)
                              }
                              field.onChange(option)
                            }}
                          />
                        </View>
                      )}
                    />
                  </View>
                  <View style={{ flexDirection: 'row', marginTop: 30 }}>
                    <Controller
                      control={paidAtBankForm.base.control}
                      name="branch"
                      render={({ field, fieldState: { error } }) => (
                        <View style={{ flex: 1, marginEnd: 30 }}>
                          <SelectSearch
                            key={paidAtBankForm.base.getValues('branch')?.value}
                            {...field}
                            required={true}
                            label={t('submission:Branch')}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                            options={bankBranchs.map((item) => {
                              return { value: item.code, label: item.name }
                            })}
                            disabled={_.isUndefined(formData.bank)}
                            errorMessage={error?.message}
                          />
                        </View>
                      )}
                    />
                    <Controller
                      control={paidAtBankForm.base.control}
                      name="amount"
                      render={({ field, fieldState: { error } }) => (
                        <Input
                          {...field}
                          required={true}
                          title={t('requestInfo:Amount')}
                          containerStyle={{ flex: 1 }}
                          inputType={'money'}
                          value={field.value || ''}
                          isFractionFormat={isFractionFormat}
                          errorMessage={Number(field.value || 0) > maxAmount ? t('message:MS050159') : error?.message}
                          disabled={!payeeNotPO || copyInfo}
                        />
                      )}
                    />
                  </View>
                  <View style={{ flexDirection: 'row', marginTop: 22 }}>
                    <View style={{ flex: 1, marginEnd: 30 }}>
                      <View style={{}}>
                        <Text style={{ fontWeight: 'bold', color: '#70777E', fontSize: 15, marginBottom: 2 }}>
                          {t('submission:DateOfBirth')}
                          <Text style={{ color: '#ed1b2c', marginLeft: 5, fontSize: 15, fontWeight: 'bold' }}>*</Text>
                        </Text>
                      </View>
                      <Controller
                        control={paidAtBankForm.base.control}
                        name="dob"
                        render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                          <DatePicker
                            value={value}
                            onBlur={onBlur}
                            errorMessage={error?.message || ''}
                            maxDate={new Date()}
                            maxDateMessage={t('message:MS050028')}
                            minDateMessage={''}
                            onChange={(date) => {
                              // console.log(date)
                              onChange(date)
                            }}
                            disabled={!payeeNotPO || copyInfo}
                          />
                        )}
                      />
                    </View>
                    <Controller
                      control={paidAtBankForm.base.control}
                      name="mobileNumber"
                      render={({ field, fieldState: { error } }) => (
                        <Input
                          {...field}
                          title={t('submission:PhoneNumber')}
                          containerStyle={{ flex: 1 }}
                          disabled={!payeeNotPO}
                          errorMessage={payeeNotPO ? error?.message : ''}
                          value={field.value || ''}
                          // required
                          maxLength={10}
                          inputType={'number'}
                        />
                      )}
                    />
                  </View>
                  <View style={{ flexDirection: 'row', marginTop: 22 }}>
                    <Controller
                      control={paidAtBankForm.base.control}
                      name="email"
                      render={({ field, fieldState: { error } }) => (
                        <Input
                          {...field}
                          title={t('submission:Email')}
                          containerStyle={{ flex: 1, marginEnd: 30 }}
                          disabled={!payeeNotPO}
                          errorMessage={payeeNotPO ? error?.message : ''}
                          value={field.value || ''}
                        // required
                        />
                      )}
                    />
                    <Controller
                      control={paidAtBankForm.base.control}
                      name="relationshipWithPO"
                      render={({ field, fieldState: { error } }) => (
                        <View style={{ flex: 1 }}>
                          <SelectSearch
                            key={paidAtBankForm.base.getValues('relationshipWithPO')?.value}
                            {...field}
                            required={true}
                            label={t('submission:RelationshipWithPO')}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                            options={iacRela?.map((x) => ({
                              value: x.value,
                              label: i18n.language === 'en' ? x.nameEn : x.name
                            }))}
                            errorMessage={error?.message}
                          />
                        </View>
                      )}
                    />
                  </View>
                  <View style={{ flexDirection: 'row', marginTop: 22 }}>
                    <Controller
                      control={paidAtBankForm.base.control}
                      name="occupation"
                      render={({ field, fieldState: { error } }) => (
                        <View style={{ flex: 1, marginEnd: 30 }}>
                          <SelectSearch
                            key={paidAtBankForm.base.getValues('occupation')?.value}
                            {...field}
                            // required={true}
                            label={t('submission:Occupation')}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                            options={occupations}
                            errorMessage={error?.message}
                            disabled={!payeeNotPO}
                          />
                        </View>
                      )}
                    />
                    <Controller
                      control={paidAtBankForm.base.control}
                      name="gender"
                      render={({ field, fieldState: { error } }) => (
                        <View style={{ flex: 1 }}>
                          <SelectSearch
                            key={paidAtBankForm.base.getValues('gender')?.value}
                            {...field}
                            required={true}
                            label={t('submission:Gender')}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                            options={genders}
                            errorMessage={error?.message}
                            disabled={!payeeNotPO || copyInfo}
                          />
                        </View>
                      )}
                    />
                  </View>
                  <View style={{ flexDirection: 'row', marginTop: 22 }}>
                    <Controller
                      control={paidAtBankForm.base.control}
                      name="salutation"
                      render={({ field, fieldState: { error } }) => (
                        <View style={{ flex: 1, marginEnd: 30 }}>
                          <SelectSearch
                            key={paidAtBankForm.base.getValues('salutation')?.value}
                            {...field}
                            required={true}
                            label={t('submission:Salutation')}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                            options={salution}
                            errorMessage={error?.message}
                            disabled={!payeeNotPO || copyInfo}
                          />
                        </View>
                      )}
                    />
                    <Controller
                      control={paidAtBankForm.base.control}
                      name="married"
                      render={({ field, fieldState: { error } }) => (
                        <View style={{ flex: 1 }}>
                          <SelectSearch
                            key={paidAtBankForm.base.getValues('married')?.value}
                            {...field}
                            required={true}
                            label={t('submission:SubMarried')}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                            options={married}
                            errorMessage={error?.message}
                            disabled={!payeeNotPO || copyInfo}
                          />
                        </View>
                      )}
                    />
                  </View>
                  <View style={{ flexDirection: 'row', marginTop: 22 }}>
                    <Controller
                      control={paidAtBankForm.base.control}
                      name="residenceCountry"
                      render={({ field, fieldState: { error } }) => (
                        <View style={{ flex: 1, marginEnd: 30 }}>
                          <SelectSearch
                            key={paidAtBankForm.base.getValues('residenceCountry')?.value}
                            {...field}
                            required={true}
                            label={t('submission:ResidenceCountry')}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                            options={nationalityOptions}
                            errorMessage={error?.message}
                            disabled={!payeeNotPO || copyInfo}
                          />
                        </View>
                      )}
                    />
                    <Controller
                      control={paidAtBankForm.base.control}
                      name="nationality"
                      render={({ field, fieldState: { error } }) => (
                        <View style={{ flex: 1 }}>
                          <SelectSearch
                            key={paidAtBankForm.base.getValues('nationality')?.value}
                            {...field}
                            required={true}
                            label={t('submission:Nationality')}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                            options={nationalityOptions}
                            onChange={(val) => {
                              field.onChange(val)
                              onChangeNationality(val, paidAtBankForm.base.watch('nationality2'))
                            }}
                            errorMessage={error?.message}
                            disabled={!payeeNotPO || copyInfo}
                          />
                        </View>
                      )}
                    />
                  </View>
                  <View style={{ flexDirection: 'row', marginTop: 22 }}>
                    {/* <Controller
                      control={paidAtBankForm.base.control}
                      name="address"
                      render={({ field, fieldState: { error } }) => (
                        <Input
                          {...field}
                          title={t('submission:Address')}
                          containerStyle={{ flex: 1, marginEnd: 30 }}
                          disabled={!payeeNotPO || copyInfo}
                          errorMessage={payeeNotPO ? error?.message : ''}
                          value={field.value || ''}
                          required
                        />
                      )}
                    /> */}
                    <Controller
                      control={paidAtBankForm.base.control}
                      name="city"
                      render={({ field, fieldState: { error } }) => (
                        <View style={{ flex: 1, marginEnd: 30 }}>
                          <SelectSearch
                            key={paidAtBankForm.base.getValues('city')?.value}
                            {...field}
                            onChange={(provinceItem) => {
                              field.onChange(provinceItem)
                              getDistricts(provinceItem?.value ?? '')
                            }}
                            required={true}
                            label={t('submission:CityProvince')}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                            options={provinces}
                            errorMessage={payeeNotPO && !_.isUndefined(field.value) ? error?.message : ''}
                          // disabled={!payeeNotPO || copyInfo}
                          />
                        </View>
                      )}
                    />
                    <Controller
                      control={paidAtBankForm.base.control}
                      name="district"
                      render={({ field, fieldState: { error } }) => (
                        <View style={{ flex: 1 }}>
                          <SelectSearch
                            key={paidAtBankForm.base.getValues('district')?.value}
                            {...field}
                            onChange={(districtItem) => {
                              field.onChange(districtItem)
                              getWards(paidAtBankForm.base.watch('city.value') ?? '', districtItem?.value ?? '')
                            }}
                            required={true}
                            label={t('submission:District')}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                            options={districtOptions}
                            errorMessage={payeeNotPO && !_.isUndefined(field.value) ? error?.message : ''}
                          // disabled={!payeeNotPO || copyInfo}
                          />
                        </View>
                      )}
                    />
                  </View>
                  <View style={{ flexDirection: 'row', marginTop: 22 }}>
                    <Controller
                      control={paidAtBankForm.base.control}
                      name="ward"
                      render={({ field, fieldState: { error } }) => (
                        <View style={{ flex: 1, marginEnd: 30 }}>
                          <SelectSearch
                            key={paidAtBankForm.base.getValues('ward')?.value}
                            {...field}
                            required={true}
                            label={t('submission:Ward')}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                            options={wardOptions}
                            errorMessage={payeeNotPO && !_.isUndefined(field.value) ? error?.message : ''}
                          // disabled={!payeeNotPO || copyInfo}
                          />
                        </View>
                      )}
                    />
                    <View style={{ flex: 1 }}></View>
                  </View>
                  <View style={{ flexDirection: 'row', marginTop: 22 }}>
                    <Controller
                      control={paidAtBankForm.base.control}
                      name="street"
                      render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                        <Input
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          title={t('submission:HouseNumber')}
                          containerStyle={{ flex: 1 }}
                          disabled={!payeeNotPO || copyInfo}
                          maxLength={50}
                          required
                          errorMessage={payeeNotPO ? error?.message : ''}
                        />
                      )}
                    />
                  </View>
                  <View style={{ flexDirection: 'row', marginTop: 22 }}>
                    <Controller
                      control={paidAtBankForm.base.control}
                      name="foreignCountry"
                      render={({ field, fieldState: { error } }) => (
                        <View style={{ flex: 1, marginEnd: 30 }}>
                          <SelectSearch
                            key={paidAtBankForm.base.getValues('foreignCountry')?.value}
                            {...field}
                            label={t('submission:ForeignCountry')}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                            options={nationalityOptions}
                            errorMessage={error?.message}
                            disabled={!payeeNotPO}
                          />
                        </View>
                      )}
                    />
                    <Controller
                      control={paidAtBankForm.base.control}
                      name="nationality2"
                      render={({ field, fieldState: { error } }) => (
                        <View style={{ flex: 1 }}>
                          <SelectSearch
                            key={paidAtBankForm.base.getValues('nationality2')?.value}
                            {...field}
                            label={t('submission:Nationality2')}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                            options={nationalityOptions}
                            onChange={(val) => {
                              field.onChange(val)
                              onChangeNationality(paidAtBankForm.base.watch('nationality'), val)
                            }}
                            errorMessage={error?.message}
                            disabled={!payeeNotPO}
                          />
                        </View>
                      )}
                    />
                  </View>
                  <View style={{ flexDirection: 'row', marginTop: 22 }}>
                    <Controller
                      control={paidAtBankForm.base.control}
                      name="foreignAddress"
                      render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                        <Input
                          value={value || ''}
                          onChange={onChange}
                          onBlur={onBlur}
                          title={t('submission:ForeignAddress')}
                          containerStyle={{ flex: 1, marginEnd: 30 }}
                          disabled={!payeeNotPO}
                          required={isForeignAddressRequired()}
                          errorMessage={payeeNotPO ? error?.message : ''}
                          maxLength={30}
                        />
                      )}
                    />
                  </View>
                  <View style={{ flexDirection: 'row', marginTop: 22 }}>
                    <Controller
                      control={paidAtBankForm.base.control}
                      name="relativeDocument"
                      render={({ field, fieldState: { error } }) => {
                        return (
                          <View style={{ width: '100%' }}>
                            <View style={{ flexDirection: 'row' }}>
                              <Text style={{ fontWeight: 'bold', color: '#70777E', fontSize: 15, marginBottom: 2 }}>
                                {t('submission:RelativeDocument')}
                              </Text>
                              <Text style={{ color: '#ed1b2c', marginLeft: 5, fontSize: 15, fontWeight: 'bold' }}>
                                *
                              </Text>
                            </View>
                            <ImgUploadMutiple {...field} value={field.value as any[]} />
                            {error?.message && <Error message={error.message} />}
                          </View>
                        )
                      }}
                    />
                  </View>
                </Panel>
              )}
            </>
          ) : (
            <>
              <View style={{ flexDirection: 'row', marginTop: 22 }}>
                <Controller
                  control={paidAtBankForm.base.control}
                  name="payeeName"
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      {...field}
                      value={field.value ?? ''}
                      title={t('submission:PayeeName')}
                      containerStyle={{ flex: 1, marginEnd: 30 }}
                      disabled={!payeeNotPO}
                      maxLength={120}
                      errorMessage={payeeNotPO ? error?.message : ''}
                    />
                  )}
                />

                <Controller
                  control={paidAtBankForm.base.control}
                  name="idNumber"
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      {...field}
                      title={t('submission:CCCD_IDNumber')}
                      containerStyle={{ flex: 1 }}
                      maxLength={24}
                      disabled={!payeeNotPO}
                      errorMessage={!payeeNotPO ? error?.message : ''}
                    />
                  )}
                />
              </View>
              <View style={{ flexDirection: 'row', marginTop: 22 }}>
                <View style={{ flexDirection: 'column', flex: 1, marginEnd: 30 }}>
                  <View style={{ flexDirection: 'row' }}>
                    <Text style={{ fontWeight: 'bold', color: '#70777E', fontSize: 15, marginBottom: 2 }}>
                      {t('claim:IssuedDate')}
                    </Text>
                    <Text style={{ color: '#ed1b2c', marginLeft: 5, fontSize: 15, fontWeight: 'bold' }}>*</Text>
                  </View>
                  <Controller
                    control={paidAtBankForm.base.control}
                    name="issueDate"
                    render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                      <DatePicker
                        value={value}
                        onBlur={onBlur}
                        errorMessage={!payeeNotPO ? error?.message : ''}
                        maxDate={new Date()}
                        maxDateMessage={t('message:MS050028')}
                        onChange={(date) => {
                          // console.log(date)
                          onChange(date)
                        }}
                        disabled={payeeNotPO}
                      />
                    )}
                  />
                </View>
                <Controller
                  control={paidAtBankForm.base.control}
                  name="issueBy"
                  render={({ field, fieldState: { error } }) => (
                    <View style={{ flex: 1 }}>
                      <SelectSearch
                        key={paidAtBankForm.base.getValues('issueBy')?.value}
                        {...field}
                        required={true}
                        label={t('claim:IssuedBy')}
                        popupIcon={<assets.ArrowDownDropdownIcon />}
                        options={issuedPlaceList}
                        errorMessage={!payeeNotPO ? error?.message : ''}
                      />
                    </View>
                  )}
                />
              </View>
              <View style={{ flexDirection: 'row', marginTop: 30 }}>
                <Controller
                  control={paidAtBankForm.base.control}
                  name="bank"
                  render={({ field, fieldState: { error } }) => (
                    <View style={{ flex: 1, marginEnd: 30 }}>
                      <SelectSearch
                        {...field}
                        required={true}
                        label={t('submission:Bank')}
                        popupIcon={<assets.ArrowDownDropdownIcon />}
                        options={getFilteredBankList()}
                        errorMessage={!payeeNotPO ? error?.message : ''}
                        onChange={(option) => {
                          setBankBranchs([])
                          paidAtBankForm.base.setValue('branch', null)
                          if (option) {
                            getBankBranch(option.value)
                          }
                          field.onChange(option)
                        }}
                      />
                    </View>
                  )}
                />

                <Controller
                  control={paidAtBankForm.base.control}
                  name="branch"
                  render={({ field, fieldState: { error } }) => (
                    <View style={{ flex: 1 }}>
                      <SelectSearch
                        key={paidAtBankForm.base.getValues('branch')?.value}
                        {...field}
                        required={true}
                        label={t('submission:Branch')}
                        popupIcon={<assets.ArrowDownDropdownIcon />}
                        options={bankBranchs.map((item) => {
                          return { value: item.code, label: item.name }
                        })}
                        // disabled={_.isUndefined(formData.bank)}
                        errorMessage={!payeeNotPO ? error?.message : ''}
                      />
                    </View>
                  )}
                />
              </View>
              <View style={{ flexDirection: 'row', marginTop: 22, width: '50%' }}>
                <Controller
                  control={paidAtBankForm.base.control}
                  name="amount"
                  render={({ field, fieldState: { error } }) => (
                    <View style={{ flex: 1, marginEnd: 15 }}>
                      <Input
                        {...field}
                        required={true}
                        title={t('requestInfo:Amount')}
                        containerStyle={{ flex: 1 }}
                        inputType={'money'}
                        value={field.value || ''}
                        isFractionFormat={isFractionFormat}
                        errorMessage={Number(field.value || 0) > maxAmount ? t('message:MS050159') : error?.message}
                      />
                    </View>
                  )}
                />
              </View>
            </>
          )}
        </View>
      </ScrollView>
    </ModalComponent>
  )
}

const styles = StyleSheet.create({
  modalTitle: {
    textAlign: 'center'
  },

  modalContent: {
    backgroundColor: '#EEEEEE'
  },

  btnCancel: {
    height: 39,
    marginEnd: 15
  },

  btnConfirm: {
    height: 39
  },

  container: {
    paddingHorizontal: 30
  },
  label: {
    color: '#70777E',
    fontWeight: 'bold',
    fontSize: 15,
    marginBottom: 2
  },
  searchMessage: {
    color: '#ED1B2C',
    fontSize: 11.25
  }
})
