import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core'
import {
  AppContext,
  ErrorHandling,
  formatNumberWithComma,
  Panel,
  PartialWithdrawalService,
  PartialWithDrawalDebitServices,
  TaskDetail,
  useMobile,
  FundCode
} from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Platform, StyleSheet, Text, View } from 'react-native'
import { InfoViewListDebit } from './common'
import { Throwable, ZIO } from '@mxt/zio'
import * as O from 'fp-ts/Option'
import { useLoading } from '@mxt/zio-react'
import moment from 'moment'

const useStyles = makeStyles({
  tableHeader: {
    backgroundColor: '#E5EAEF',
    '& >tr > td': {
      color: '#000000',
      fontWeight: 'bold'
    }
  }
})
export interface Fund {
  code: string
  name: string
}

export const TopUpAcc: Fund[] = [
  {
    code: FundCode.UBL2,
    name: 'fund:PRUlinkBalancedTopup'
  },
  {
    code: FundCode.UEQ2,
    name: 'fund:PRUlinkVNEquityTopup'
  },
  {
    code: FundCode.UFI2,
    name: 'fund:PRUlinkVNFixedIncTopup'
  },
  {
    code: FundCode.UGR2,
    name: 'fund:PRUlinkGrowthTopup'
  },
  {
    code: FundCode.UPS2,
    name: 'fund:PRUlinkPreserverTopup'
  },
  {
    code: FundCode.USB2,
    name: 'fund:PRUlinkStableTopup'
  },
  {
    code: FundCode.USE2,
    name: 'fund:PrulinkVietnamSustainableDevelopmentEquityFundTopup'
  }
]

export const mapFunds = new Map<FundCode | string, string>(TopUpAcc.map((t) => [t.code, t.name]))

export const getFunds = (key?: string) => (key ? mapFunds.get(key) ?? '-' : '-')
type Output = {
  label: string
  value: string | number | JSX.Element
  suffix?: string
  isHighlight?: boolean
}
export interface WithdrawDetailItem {
  policyNumber: string
  fromDate: string
  toDate: string
  currentFunction: string
  fundTopup1: string
  fundTopup2: string
  fundTopup3: string
  fundTopup4: string
  fundTopup5: string
  fundTopup6: string
  fundTopup7: string
  installPremium: string
  paymentStatus: string
  chargeDate: string
  reasonFail: string
  dateTime: string
  ptd: string
  amlRegister: string
}
export const PartialWithDrawalDebit = ({ detail }: { detail: TaskDetail.PartialWithdrawalAutoDebit }) => {
  const { t, i18n } = useTranslation(['requestInfo', 'fund', 'BillingChange'])
  const classes = useStyles()
  const { isWide } = useMobile()
  const { showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [pageNum, setPageNum] = React.useState<number>(0)
  const [totalItem, setTotalItem] = React.useState<number>(0)
  let dataPartialInfo: Output[] = []
  const [dataSource, setDataSource] = React.useState<WithdrawDetailItem[]>([])
  const [loading, bindLoading] = useLoading()
  const getDetail = pipe(
    PartialWithdrawalService.getDetail(detail.policyNumber),
    ZIO.map((res) => res),
    ZIO.tap((data) => {
      return ZIO.unit
    }),
    ErrorHandling.runDidMount()
  )
  const getFundAll =
    pipe(
      PartialWithdrawalService.getfundAll,
      ZIO.map((res) => {
        return res
      }),
      ErrorHandling.runDidMount()
    ) || []
  React.useEffect(() => {
    onViewReportClick()
  }, [detail])

  if (getDetail?.ilp) {
    dataPartialInfo = [
      {
        label: t('EstimatedValue'),
        value: formatNumberWithComma(
          getDetail?.ilp?.fundList
            .filter((fund: any) => getFunds(fund.fundCode) !== '-')
            .reduce((sum: any, x: { fundValue: any }) => x.fundValue + sum, 0) ?? 0
        ),
        suffix: 'VND'
      },
      {
        label: t('Maximumpartialwithdrawal'),
        value: formatNumberWithComma(getDetail?.ilp?.maximumPartialWithdrawal ?? 0),
        suffix: 'VND'
      }
    ]
  } else if (getDetail?.ulp) {
    dataPartialInfo = [
      {
        label: t('EstimatedValue'),
        value: formatNumberWithComma(getDetail?.ulp?.estimatedValue ?? 0),
        suffix: 'VND'
      },
      {
        label: t('Maximumpartialwithdrawal'),
        value: formatNumberWithComma(getDetail?.ulp?.maximumPartialWithdrawal ?? 0),
        suffix: 'VND'
      }
    ]
  }

  const getQuery = (input: {
    order: string | undefined
    sort: string | undefined
    start: number
    page: number
    size: number | undefined
    policyNumber: string | undefined
  }) => {
    const { policyNumber, size, start, page, order, sort } = input
    return {
      order,
      sort,
      start,
      page,
      size,
      policyNumber
    }
  }
  const nameCurrentFrequency = (value: string) => {
    if (value === 'NOTIF') {
      return i18n.language === 'en' ? 'The contract is not IF' : 'Hợp đồng khác IF'
    } else if (value === 'NOTEM') {
      return i18n.language === 'en' ? 'Fund value not enough money' : 'Quỹ thiếu tiền đóng phí'
    } else if (value === 'LACKP') {
      return i18n.language === 'en' ? 'Max P/W is less than Premium' : 'Số tiền tối đa nhỏ hơn phí bảo hiểm'
    } else if (value === '	OTHER') {
      return i18n.language === 'en' ? '	OTHER' : 'KHÁC'
    } else {
      return value
    }
  }
  const nameTranslateStatus = (value: string) => {
    if (value === 'N') {
      return i18n.language === 'en' ? 'Register' : 'Đăng ký'
    } else if (value === 'D') {
      return i18n.language === 'en' ? 'Cancel' : 'Hủy'
    } else {
      return value
    }
  }
  const mappingDataSource = (data: PartialWithDrawalDebitServices.ReportDebitDataItem, start = 0) => {
    const filteredDataSource = data.map((item, index) => ({
      policyNumber: item.policyNumber || '-',
      fromDate: moment(item.fromDate).format('DD/MM/YYYY') || '-',
      toDate: moment(item.toDate).format('DD/MM/YYYY') || '-',
      currentFunction: item.currentFunction || '-',
      fundTopup1: item.fundTopup1 || '-',
      fundTopup2: item.fundTopup2 || '-',
      fundTopup3: item.fundTopup3 || '-',
      fundTopup4: item.fundTopup4 || '-',
      fundTopup5: item.fundTopup5 || '-',
      fundTopup6: item.fundTopup6 || '-',
      fundTopup7: item.fundTopup7 || '-',
      installPremium: item.installPremium || '-',
      paymentStatus: item.paymentStatus || '-',
      chargeDate: moment(item.chargeDate).format('DD/MM/YYYY') || '-',
      reasonFail: nameCurrentFrequency(item.reasonFail ?? '') || '-',
      dateTime: moment(item.dateTime).format('DD/MM/YYYY') || '-',
      ptd: item.ptd || '-',
      amlRegister: item.amlRegister || '-',
    }))
    setDataSource(filteredDataSource)
    return filteredDataSource
  }

  const handleReportData = async (
    handle: (report: PartialWithDrawalDebitServices.ReportsDebitData) => ZIO<any, Throwable, any>
  ) => {
    const query = getQuery({
      order: 'desc',
      sort: 'createdDate',
      start: 0,
      page: 0,
      size: pageSize,
      policyNumber: detail.policyNumber ?? ''
    })
    showGlobalLoading(true)
    pipe(
      PartialWithDrawalDebitServices.getReportData(query),
      ZIO.flatMap((report) =>
        pipe(
          O.fromNullable(report.data),
          O.filter((data) => data.length > 0),
          O.fold(
            () => {
              setDataSource([])
              showGlobalLoading(false)
              return ZIO.unit
            },
            (data) => {
              mappingDataSource(data)
              showGlobalLoading(false)
              return handle(report)
            }
          )
        )
      ),
      ZIO.catchAll(() => {
        setDataSource([])
        showGlobalLoading(false)
        return ZIO.unit
      }),
      bindLoading,
      ZIO.unsafeRun({})
    )
  }

  const handlePaging = (pageIndex: number, currentpageSize: number) => {
    const start = currentpageSize * pageIndex
    const query = getQuery({
      order: 'desc',
      sort: 'createdDate',
      start,
      page: pageIndex,
      size: currentpageSize,
      policyNumber: detail.policyNumber ?? ''
    })
    showGlobalLoading(true)
    pipe(
      PartialWithDrawalDebitServices.getReportData(query),
      ZIO.flatMap((report) =>
        pipe(
          O.fromNullable(report.data),
          O.filter((data) => data.length > 0),
          O.fold(
            () => {
              showToast(t('message:MS030029'), 'error')
              setDataSource([])
              showGlobalLoading(false)
              return ZIO.unit
            },
            (data) => {
              mappingDataSource(data, pageIndex)
              showGlobalLoading(false)
              return ZIO.unit
            }
          )
        )
      ),
      ZIO.catchAll(() => {
        showToast(t('message:MS030029'), 'error')
        setDataSource([])
        showGlobalLoading(false)
        return ZIO.unit
      }),
      bindLoading,
      ZIO.unsafeRun({})
    )
  }

  const onViewReportClick = () => {
    handleReportData((report) => {
      setPageNum(0)
      setTotalItem(report.total)
      return ZIO.unit
    })
  }

  return (
    <View>
      <Panel title={t('BillingChange:PartialWithDrawalInfo')} isExand={false}>
        <InfoViewListDebit dataSource={dataPartialInfo} />
        <View>
          {getDetail?.ilp && (
            <>
              <Text style={styles.text}>{t('Fundlist')}</Text>
              <View style={styles.totalWithDraw}>
                <Text style={styles.text}>{t('BillingChange:TotalWithdrawalamount')}:</Text>
                <View>
                  <Text style={[styles.text, styles.isHighlight]}>
                    {formatNumberWithComma(getDetail?.instalmentPremium ?? 0) + ' VND'}
                  </Text>
                </View>
              </View>
            </>
          )}
          {getDetail?.ulp && (
            <>
              <Text style={styles.text}>{t('Fundlist')}</Text>
              <View style={styles.totalWithDraw}>
                <Text style={styles.text}>{t('BillingChange:TotalWithdrawalamount')}:</Text>
                <View>
                  <Text style={[styles.text, styles.isHighlight]}>
                    {formatNumberWithComma(getDetail?.ulp?.currentSumAssured ?? 0) + ' VND'}
                  </Text>
                </View>
              </View>
            </>
          )}
          {isWide && Platform.OS === 'web' && getDetail?.ilp && (
            <View style={styles.tableWrapper}>
              <View style={styles.currency}>
                <Text style={[styles.text, styles.italic]}>{t('BillingChange:Currency: ') + 'VND'}</Text>
              </View>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead className={classes.tableHeader}>
                    <TableRow>
                      <TableCell>{t('BillingChange:Fund')}</TableCell>
                      <TableCell>{t('Fundvalue')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {detail?.FUND_LIST && detail?.FUND_LIST[0]?.vFund01} -{' '}
                        {t(getFunds(detail?.FUND_LIST[0]?.vFund01 ?? ''))}
                      </TableCell>
                      <TableCell>
                        {formatNumberWithComma(
                          getDetail?.ilp?.fundList.find((item: any) => item?.fundCode === detail?.FUND_LIST[0]?.vFund01)
                            ?.fundValue ?? 0
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow></TableRow>
                  </TableBody>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {detail?.FUND_LIST && detail.FUND_LIST[0]?.vFund02} -{' '}
                        {t(getFunds(detail?.FUND_LIST[0]?.vFund02 ?? ''))}
                      </TableCell>
                      <TableCell>
                        {formatNumberWithComma(
                          getDetail?.ilp?.fundList.find((item: any) => item?.fundCode === detail?.FUND_LIST[0]?.vFund02)
                            ?.fundValue ?? 0
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {detail?.FUND_LIST && detail.FUND_LIST[0]?.vFund03} -{' '}
                        {t(getFunds(detail?.FUND_LIST[0]?.vFund03 ?? ''))}
                      </TableCell>
                      <TableCell>
                        {formatNumberWithComma(
                          getDetail?.ilp?.fundList.find((item: any) => item?.fundCode === detail?.FUND_LIST[0]?.vFund03)
                            ?.fundValue ?? 0
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {detail?.FUND_LIST && detail.FUND_LIST[0]?.vFund04} -{' '}
                        {t(getFunds(detail?.FUND_LIST[0]?.vFund04 ?? ''))}
                      </TableCell>
                      <TableCell>
                        {formatNumberWithComma(
                          getDetail?.ilp?.fundList.find((item: any) => item?.fundCode === detail?.FUND_LIST[0]?.vFund04)
                            ?.fundValue ?? 0
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {detail?.FUND_LIST && detail.FUND_LIST[0]?.vFund05} -{' '}
                        {t(getFunds(detail?.FUND_LIST[0]?.vFund05 ?? ''))}
                      </TableCell>
                      <TableCell>
                        {formatNumberWithComma(
                          getDetail?.ilp?.fundList.find((item: any) => item?.fundCode === detail?.FUND_LIST[0]?.vFund05)
                            ?.fundValue ?? 0
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {detail?.FUND_LIST && detail.FUND_LIST[0]?.vFund06} -{' '}
                        {t(getFunds(detail?.FUND_LIST[0]?.vFund06 ?? ''))}
                      </TableCell>
                      <TableCell>
                        {formatNumberWithComma(
                          getDetail?.ilp?.fundList.find((item: any) => item?.fundCode === detail?.FUND_LIST[0]?.vFund06)
                            ?.fundValue ?? 0
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {detail?.FUND_LIST && detail.FUND_LIST[0]?.vFund07} -{' '}
                        {t(getFunds(detail?.FUND_LIST[0]?.vFund07 ?? ''))}
                      </TableCell>
                      <TableCell>
                        {formatNumberWithComma(
                          getDetail?.ilp?.fundList.find((item: any) => item?.fundCode === detail?.FUND_LIST[0]?.vFund07)
                            ?.fundValue ?? 0
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </View>
          )}

          {isWide && Platform.OS === 'web' && getDetail?.ulp && (
            <View style={styles.tableWrapper}>
              <View style={styles.currency}>
                <Text style={[styles.text, styles.italic]}>{t('BillingChange:Currency: ') + 'VND'}</Text>
              </View>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead className={classes.tableHeader}>
                    <TableRow>
                      <TableCell>{t('BillingChange:Fund')}</TableCell>
                      <TableCell>{t('Fundvalue')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getDetail?.ulp?.fundList
                      .map((fund: any) => ({
                        fundName: `${fund?.fundCode} - ${t(getFunds(fund?.fundCode))}` ?? '',
                        fundValue: fund?.fundValue ?? ' '
                      }))
                      .map((fund: any, i: number) => (
                        <TableRow key={i}>
                          <TableCell component="th" scope="row">
                            {fund?.fundName}
                          </TableCell>
                          <TableCell>{formatNumberWithComma(fund?.fundValue ?? 0)}</TableCell>
                        </TableRow>
                      ))}
                    <TableRow></TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </View>
          )}
        </View>
      </Panel>

      <Panel title={t('BillingChange:PaymentRegisterinfo')} isExand={false}>
        <View>
          {isWide && Platform.OS === 'web' && (
            <View style={styles.tableWrapper}>
              <View style={[styles.currency, styles.flexBetween]}></View>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead className={classes.tableHeader}>
                    <TableRow>
                      <TableCell>{t('BillingChange:PayoutMethod')}</TableCell>
                      <TableCell>{t('BillingChange:FromDate')}</TableCell>
                      <TableCell>{t('BillingChange:ToDate')}</TableCell>
                      <TableCell>{t('BillingChange:RegisterDate')}</TableCell>
                      <TableCell>{t('BillingChange:Action')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataSource.map((item: WithdrawDetailItem, i: number) => (
                      <TableRow key={i}>
                        <TableCell component="th" scope="row">
                          {t('CFI:InstallmentPremium')}
                        </TableCell>
                        <TableCell>{item.fromDate}</TableCell>
                        <TableCell>{item.toDate}</TableCell>
                        <TableCell>{item.dateTime}</TableCell>
                        <TableCell>{nameTranslateStatus(item.paymentStatus)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </View>
          )}
        </View>
      </Panel>
      {dataSource && dataSource.length > 0 && (
        <TablePagination
          page={pageNum}
          rowsPerPageOptions={[5, 10, 25, 50]}
          rowsPerPage={pageSize}
          count={totalItem}
          onPageChange={(e, page) => {
            setPageNum(page)
            handlePaging(page, pageSize)
          }}
          onRowsPerPageChange={(e) => {
            const rowsPerPage = Number(e.target.value)
            setPageSize(rowsPerPage)
            if (pageNum * rowsPerPage >= totalItem) {
              setPageNum(0)
              handlePaging(0, rowsPerPage)
            } else {
              handlePaging(pageNum, rowsPerPage)
            }
          }}
          labelRowsPerPage={t('common:PaginationSize')}
          labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
          component={View}
        ></TablePagination>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  text: {
    fontSize: 16,
    marginRight: 10
  },
  totalWithDraw: {
    flexDirection: 'row',
    marginTop: 10
  },
  isHighlight: {
    color: '#EF1C2E',
    fontWeight: 'bold'
  },
  tableWrapper: {
    flex: 1,
    marginTop: 16
  },
  currency: {
    width: '100%',
    marginBottom: 10,
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  flexBetween: {
    justifyContent: 'space-between'
  },
  italic: {
    fontStyle: 'italic'
  }
})
