import { ZIO } from '@mxt/zio'
import {
  AppContext,
  assets,
  ErrorHandling,
  form2,
  PulseOpsFormat,
  jsFileDownload,
  YearMonthPicker,
  Permission,
  RBAC
} from '@pulseops/common'
import { useIsFocused } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import { pipe } from 'fp-ts/lib/function'
import i18next from 'i18next'
import * as t from 'io-ts'
import React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SafeAreaView, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import _ from 'lodash'
import { ReportsStackParamList } from './ReportsStackParamList'
import { PerformanceReportService } from './Service/PerformanceReportService'
import * as ExcelJS from 'exceljs'
import moment from 'moment'
import { FormService } from '@pulseops/business/core'
import { excelColumnsSheet1, excelColumnsSheet2, excelType } from './model/Sheet'

const formC = t.type({
  tag: t.literal('TRANSACTIONMONTH'),
  transactionMonth: form2.date.requiredM(() =>
    i18next.t('message:MS020001', {
      field: i18next.t('Reports:TransactionMonth')
    })
  )
})

type props = StackScreenProps<ReportsStackParamList, 'PerformanceReportScreen'>

export const PerformanceReportScreen = ({ navigation }: props) => {
  const { t } = useTranslation()
  const isFocused = useIsFocused()
  const { changeBreadcrumb, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const { getGenderName, getMaritalStatusName } = FormService

  const roles: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([]))

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigation.navigate('HomeScreen')
          }
        },
        {
          title: t('menu:Reports'),
          navigate: () => {
            navigation.navigate('ReportsScreen')
          }
        },
        {
          title: t('Reports:PerformanceReport'),
          navigate: null
        }
      ])
    }
  }, [isFocused])

  const {
    base: {
      control,
      formState: { errors }
    },
    handleSubmit
  } = form2.useForm(formC, {
    defaultValues: {
      tag: 'TRANSACTIONMONTH'
    }
  })

  const mergeCells = (columns: excelType[], ws: ExcelJS.Worksheet) => {
    columns.forEach((_, index) => {
      const headerGroupCell = ws.getCell(1, index + 1)
      headerGroupCell.value = ''
      headerGroupCell.alignment = {
        wrapText: true,
        horizontal: 'center',
        vertical: 'middle'
      }
      headerGroupCell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      }
    })
    ws.mergeCells('A1:H1')
    ws.getCell('A1').value = 'Thông tin Doanh nghiệp'

    ws.mergeCells('I1:V1')
    ws.getCell('I1').value = 'Thông tin Hợp đồng BHN'

    ws.mergeCells('W1:AK1')
    ws.getCell('W1').value = 'Thông tin người bán'

    ws.mergeCells('AL1:AP1')
    ws.getCell('AL1').value = 'Thông tin người refer (Người refer là nhân viên Bank)'

    ws.mergeCells('AQ1:AY1')
    ws.getCell('AQ1').value = 'Thông tin thanh toán'

    ws.mergeCells('AZ1:BF1')
    ws.getCell('AZ1').value = 'MIS IMPUT'
  }

  const loadDataToSheet = (
    excelColumn: excelType[],
    sheet: ExcelJS.Worksheet,
    response: PerformanceReportService.ExportFile[] | PerformanceReportService.ContractInfo[],
    startRow: number
  ) => {
    excelColumn.forEach((column, index) => {
      const headerCell = sheet.getCell(startRow, index + 1)
      headerCell.value = column.name
      headerCell.alignment = {
        wrapText: true,
        horizontal: 'left',
        vertical: 'middle'
      }
      headerCell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      }
    })
    const getValue = (key: string, value: any, index: any) => {
      switch (key) {
        case 'dateOfIssue':
        case 'dateOfBirth':
        case 'proposalDate':
        case 'effectiveDate':
          return getTime(value)
        case 'gender':
          return getGenderName(value)
        case 'maritalStatus':
          return getMaritalStatusName(value)
        case 'stt':
          return index
        default:
          return value
      }
    }

    let maxLength = 0
    response.forEach((report, i) => {
      excelColumn.forEach((column, j) => {
        const headerCell = sheet.getCell(startRow, j + 1)
        const cell = sheet.getCell(startRow + 1 + i, j + 1)
        cell.value = getValue(column.value, _.get(report, column.value), i)
        cell.alignment = { horizontal: 'left' }
        const lengths = _.get(report, column.value) ? _.get(report, column.value).length : 10
        if (lengths > maxLength) {
          maxLength = lengths
        }
        headerCell.worksheet.columns[j].width = maxLength < 10 ? 10 : maxLength
      })
    })
  }

  const extract = handleSubmit((validated) => {
    const transaction = validated.transactionMonth
    const months = transaction.getMonth() + 1
    showGlobalLoading(true)
    return pipe(
      ZIO.zipPar(
        PerformanceReportService.exportFile({
          month: months < 10 ? '0' + months : months,
          year: transaction.getFullYear()
        }),
        PerformanceReportService.contractInfoExport({
          month: months < 10 ? '0' + months : months,
          year: transaction.getFullYear()
        })
      ),
      ZIO.flatMap(([LaList, DealList]) =>
        ZIO.fromPromise(async () => {
          const wb = new ExcelJS.Workbook()
          const ws = wb.addWorksheet('LA')
          const ws2 = wb.addWorksheet('DEAL')

          loadDataToSheet(excelColumnsSheet1, ws, LaList, 1)
          loadDataToSheet(excelColumnsSheet2, ws2, DealList, 2)

          mergeCells(excelColumnsSheet2, ws2)

          const buffer = await wb.xlsx.writeBuffer({
            useStyles: true
          })
          jsFileDownload(buffer, `PerformanceAndReporting${moment(new Date()).format('DDMMyyyy_HHmm')}.xlsx`)
        })
      ),
      ZIO.tapBoth(
        () => ZIO.succeed(showGlobalLoading(false)),
        () => ZIO.succeed(showGlobalLoading(false))
      ),
      ErrorHandling.run()
    )
  })

  const getTime = (value: Date) => {
    return PulseOpsFormat.datetoFormat(value, 'DD/MM/yyyy')
  }

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView>
        <View style={{ flex: 1 }}>
          <View style={styles.searchContainer}>
            <View style={styles.row}>
              <View style={styles.col}>
                <View style={{ flex: 1 }}>
                  <Controller
                    name="transactionMonth"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <YearMonthPicker
                        required
                        label={t('Reports:TransactionMonth')}
                        onChange={onChange}
                        value={value}
                        maxDateMessage={t('message:MS990032')}
                        errorMessage={errors.transactionMonth?.message}
                      />
                    )}
                  />
                </View>
              </View>
            </View>
            <View style={[styles.row, { justifyContent: 'flex-end' }]}>
              {roles.includes(Permission['BusinessPulseExport']) && (
                <TouchableOpacity onPress={extract}>
                  <View style={styles.btn}>
                    <assets.DownloadWhite />
                    <Text style={styles.btnText}>{t('Reports:Export')}</Text>
                  </View>
                </TouchableOpacity>
              )}
            </View>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  searchContainer: {
    backgroundColor: '#FAFAFA',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    marginHorizontal: 16,
    marginVertical: 16,
    paddingVertical: 16,
    paddingHorizontal: 16
  },

  row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 16
  },

  col: {
    width: '33.33%',
    minWidth: 300,
    flexDirection: 'row',
    padding: 16
  },

  btn: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#ed1b2e',
    borderRadius: 100,
    boxShadow: 'none',
    paddingVertical: 10,
    paddingHorizontal: 32
  },

  btnText: {
    textAlign: 'center',
    color: '#fff',
    marginLeft: 6
  }
} as const)
