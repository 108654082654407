import React from 'react'
import { SafeAreaView, ScrollView } from 'react-native'
import { useTranslation } from 'react-i18next'
import {
  AppContext,
  assets,
  AuthState,
  ErrorHandling,
  GeneralService,
  PanelProp,
  Permission,
  RBAC,
  ReportPermission,
  SearchPanel,
  subscribe
} from '@pulseops/common'
import { useIsFocused } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import { ReportsStackParamList } from './ReportsStackParamList'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'

type Props = StackScreenProps<ReportsStackParamList, 'ReportsScreen'>

export const ReportsScreen = ({ navigation }: Props) => {
  const { t } = useTranslation()
  const ht = useTranslation('menu').t
  const isFocused = useIsFocused()
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)

  const roles: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([]))
  const authData = pipe(AuthState.auth.watch, subscribe())
  const isShowSubmissionReport =
    pipe(
      authData != null && authData.tag !== AuthState.Status.Unauthenticated
        ? GeneralService.getVisiableReportScreenStatus()
        : ZIO.succeed(true),
      ErrorHandling.runDidUpdate([authData])
    ) || false

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigation.navigate('HomeScreen')
          }
        },
        {
          title: ht('Reports'),
          navigate: null
        }
      ])
    }
  }, [isFocused])

  let itemSearchList = [
    // {
    //   id: ReportPermission.SubmissionReportReports,
    //   name: t('Reports:SubmissionReport'),
    //   navigator: () => {
    //     navigation.navigate('SubmissionReportScreen')
    //   }
    // },
    {
      id: ReportPermission.PaymentReportPayoutReports,
      name: t('Reports:PaymentReport'),
      navigator: () => {
        navigation.navigate('PaymentReportScreen')
      }
    },
    {
      id: Permission['BusinessPulseReports'],
      name: t('Reports:PolicyDataXML'),
      navigator: () => {
        navigation.navigate('PolicyDataXMLScreen')
      }
    },
    {
      id: Permission['BusinessPulseReports'],
      name: t('Reports:PerformanceReport'),
      navigator: () => {
        navigation.navigate('PerformanceReportScreen')
      }
    },
    {
      id: Permission['ReportManagement'],
      name: t('Reports:ReportManagement'),
      navigator: () => {
        navigation.navigate('ReportManagementScreen')
      }
    },
    {
      id: Permission['SearchErrorHandlerReports'],
      name: t('Reports:ErrorHandler.ErrorHandlerReport'),
      navigator: () => {
        navigation.navigate('ErrorHandlerReportScreen')
      }
    },
    {
      id: Permission['ClaimPayoutReport'],
      name: t('Reports:ClaimPayoutReport'),
      navigator: () => {
        navigation.navigate('ClaimPayoutReportScreen')
      }
    },
    {
      id: ReportPermission.CommunicationTrackingReport,
      name: t('Reports:CommunicationTracking'),
      navigator: () => {
        navigation.navigate('CommunicationTrackingReportScreen')
      }
    },
    {
      id: ReportPermission.CheckingPayoutMethodReports,
      name: t('Reports:CheckingPayout'),
      navigator: () => {
        navigation.navigate('CheckingPayoutReportScreen')
      }
    },
    {
      id: Permission['ViewReportAutoDebitTopupAccountMonthly'],
      name: t('BillingChange:ReportAutoDebitTopupAccountMonthly'),
      navigator: () => {
        navigation.navigate('ReportAutoDebitScreen')
      }
    },
    {
      id: Permission['ViewReportAutoDebitMonthlyRegister'],
      name: t('BillingChange:AutoDebitTopupAccountMonthlyRegisterReport'),
      navigator: () => {
        navigation.navigate('AutoDebitRegisterReportScreen')
      }
    }
  ]

  if (isShowSubmissionReport) {
    itemSearchList = [
      {
        id: ReportPermission.SubmissionReportReports,
        name: t('Reports:SubmissionReport'),
        navigator: () => {
          navigation.navigate('SubmissionReportScreen')
        }
      },
      ...itemSearchList
    ]
  }

  const getChildrenList = () => {
    let subReport = [
      {
        id: ReportPermission.PaymentReportPayoutReports,
        name: t('Reports:PaymentReport'),
        navigator: () => {
          navigation.navigate('PaymentReportScreen')
        }
      },
      {
        id: Permission['ReportManagement'],
        name: t('Reports:ReportManagement'),
        navigator: () => {
          navigation.navigate('ReportManagementScreen')
        }
      },
      {
        id: Permission['SearchErrorHandlerReports'],
        name: t('Reports:ErrorHandler.ErrorHandlerReport'),
        navigator: () => {
          navigation.navigate('ErrorHandlerReportScreen')
        }
      },
      {
        id: ReportPermission.ClaimPayoutReports,
        name: t('Reports:ClaimPayoutReport'),
        navigator: () => {
          navigation.navigate('ClaimPayoutReportScreen')
        }
      },
      {
        id: Permission['ViewReportAutoDebitTopupAccountMonthly'],
        name: t('BillingChange:ReportAutoDebitTopupAccountMonthly'),
        navigator: () => {
          navigation.navigate('ReportAutoDebitScreen')
        }
      },
      {
        id: Permission['ViewReportAutoDebitMonthlyRegister'],
        name: t('BillingChange:AutoDebitTopupAccountMonthlyRegisterReport'),
        navigator: () => {
          navigation.navigate('AutoDebitRegisterReportScreen')
        }
      }
    ]
    if (isShowSubmissionReport) {
      subReport = [
        {
          id: ReportPermission.SubmissionReportReports,
          name: t('Reports:SubmissionReport'),
          navigator: () => {
            navigation.navigate('SubmissionReportScreen')
          }
        },
        ...subReport
      ]
    }
    return subReport
  }

  const data: PanelProp = {
    itemsSearch: itemSearchList.filter((_) => roles.includes(_.id)),
    itemsGroup: [
      {
        id: 'ReportsEform',
        name: t('Reports:ReportsEform'),
        icon: () => <assets.ReportEform />,
        children: getChildrenList().filter((_) => roles.includes(_.id))
      },
      {
        id: 'ReportsBusinessPulse',
        name: t('Reports:ReportsBusinessPulse'),
        icon: () => <assets.ReportBusinessPulse />,
        children: [
          {
            id: Permission['BusinessPulseReports'],
            name: t('Reports:PolicyDataXML'),
            navigator: () => {
              navigation.navigate('PolicyDataXMLScreen')
            }
          },
          {
            id: Permission['BusinessPulseReports'],
            name: t('Reports:PerformanceReport'),
            navigator: () => {
              navigation.navigate('PerformanceReportScreen')
            }
          }
        ].filter((_) => roles.includes(_.id))
      },
      {
        id: 'ReportsErrorHandler',
        name: t('Reports:ErrorHandler.Title'),
        icon: () => <assets.ReportErrorHandler />,
        children: [
          {
            id: Permission['ViewErrorHandlerReports'],
            name: t('Reports:ErrorHandler.ErrorHandlerReport'),
            navigator: () => {
              navigation.navigate('ErrorHandlerReportScreen')
            }
          }
        ].filter((_) => roles.includes(_.id))
      },
      {
        id: 'ReportsCommunicationTracking',
        name: t('Reports:CommunicationTracking'),
        icon: () => <assets.ReportEform />,
        children: [
          {
            id: ReportPermission.CommunicationTrackingReport,
            name: t('Reports:CommunicationTracking'),
            navigator: () => {
              navigation.navigate('CommunicationTrackingReportScreen')
            }
          },
          {
            id: ReportPermission.CheckingPayoutMethodReports,
            name: t('Reports:CheckingPayout'),
            navigator: () => {
              navigation.navigate('CheckingPayoutReportScreen')
            }
          }
        ].filter((_) => roles.includes(_.id))
      }
    ].filter((_) => (_.id === 'ReportsBusinessPulse' ? roles.includes(Permission['BusinessPulseReports']) : true))
  }

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView>{data && <SearchPanel data={data} />}</ScrollView>
    </SafeAreaView>
  )
}
