import {
  AppContext,
  assets,
  AssignmentModel,
  AssignmentService,
  ChangeContactInfo,
  ChangeContactInfoService,
  convertUnicodeToViqr,
  DatePicker,
  ErrorHandling,
  form2,
  GeneralService,
  ImgUploadMutiple,
  Input,
  PulseOpsFormat,
  RadioButton,
  Select,
  SelectOption,
  SelectSearch,
  sharedStyle,
  // StorageBlob,
  TaskType,
  TransactionType
} from '@pulseops/common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View, StyleSheet } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { RequestAuthenticateData } from '../request-authen'
import { PolicyServiceProps, UploadedFilesInfo } from './policy-service-props'
import * as t from 'io-ts'
import * as E from 'fp-ts/Either'
import { Controller } from 'react-hook-form'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { LinearProgress } from '@material-ui/core'
import { DuplicateTable, FilePresent, SC } from '../common'
import i18next from 'i18next'
import { NonEmptyString, withMessage } from 'io-ts-types'
import _ from 'lodash'
import { Column, Label, Row } from '@pulseops/submission/common'
import moment from 'moment'
type RelationshipS2Map = {
  value: string
  label: string
  code: string
  id: string
  la1: string
  la2: string
  la3: string
  la4: string
  relationshipEN: string
}

const FileMeta = t.type({
  fileName: t.string,
  fileExtension: t.string,
  size: t.number,
  base64: t.string,
  uploadedDate: form2.date.required,
  file: form2.file.required
})
type FileMeta = t.TypeOf<typeof FileMeta>
type FileAttachments = t.Branded<FileMeta[], { readonly FileAttachments: unique symbol }>

const RelationShip = t.type({
  clientId: form2.string.optional,
  clientName: form2.string.optional,
  relationship: form2.selectOption.optional,
  role: form2.string.optional,
  secuityNo: form2.string.optional,
  dob: form2.string.optional,
  gender: form2.string.optional,
  poName: form2.string.optional
  //sameClient: t.boolean
})

type RelationShip = t.TypeOf<typeof RelationShip>

type PhoneRelationShipBrand = {
  readonly PhoneRelationShip: unique symbol
}

type PhoneRelationShip = t.Branded<RelationShip, PhoneRelationShipBrand>

const PhoneRelationShip = pipe(
  RelationShip,
  form2.refine(
    (l): l is PhoneRelationShip => {
      if (l.role === 'AG') {
        return false
      }
      return true
    },
    (l) => `Client co phone trung voi tu van vien ${l.clientName}`,
    'PhoneRelationShip'
  ),
  form2.refine(
    (l): l is PhoneRelationShip => {
      if (l.role !== 'AG') {
        return l.relationship !== null
      }
      return true
    },
    (l) => i18next.t('message:MS050228'),
    'PhoneRelationShip'
  )
)

type EmailRelationShipBrand = {
  readonly EmailRelationShip: unique symbol
}

type EmailRelationShip = t.Branded<RelationShip, EmailRelationShipBrand>

const EmailRelationShip = pipe(
  RelationShip,
  form2.refine(
    (l): l is EmailRelationShip => {
      if (l.role === 'AG') {
        return false
      }
      return true
    },
    (l) => `Client co email trung voi tu van vien ${l.clientName}`,
    'EmailRelationShip'
  ),
  form2.refine(
    (l): l is EmailRelationShip => {
      if (l.role !== 'AG') {
        return l.relationship !== null
      }
      return true
    },
    (l) => i18next.t('message:MS050228'),
    'EmailRelationShip'
  )
)

const FormData = t.intersection([
  t.type({
    newPolicyOwner: form2.selectOption.requiredM(() =>
      i18next.t('message:MS020009', {
        field: i18next.t('submission:NewPolicyOwner')
      })
    ),
    relationShipWithMainLifeAssured: form2.selectOption.requiredM(() =>
      i18next.t('message:MS020009', {
        field: i18next.t('submission:RelationshipWithMainLifeAssurance')
      })
    ),
    usTaxRegister: t.boolean,
    email: withMessage(form2.email.optional, () =>
      i18next.t('message:MS050013', { field: i18next.t('requestInfo:Email') })
    ),
    occupation: form2.selectOption.requiredM(() =>
      i18next.t('message:MS020009', {
        field: i18next.t('submission:Occupation')
      })
    ),
    country: form2.selectOption.requiredM(() =>
      i18next.t('message:MS020009', {
        field: i18next.t('submission:Country')
      })
    ),
    dupPhones: form2.optional(t.array(PhoneRelationShip)),
    dupEmails: form2.optional(t.array(EmailRelationShip)),
    relationShipWithMainLifeAssuredLA2: t.union([
      t.type({
        hasLA: t.literal(false),
        name: t.literal(''),
        clientNumberOfLA: t.literal(''),
        relationship: t.null
      }),
      t.type({
        hasLA: t.literal(true),
        name: t.string,
        clientNumberOfLA: t.string,
        relationship: form2.selectOption.required
      })
    ]),
    relationShipWithMainLifeAssuredLA3: t.union([
      t.type({
        hasLA: t.literal(false),
        name: t.literal(''),
        clientNumberOfLA: t.literal(''),
        relationship: t.null
      }),
      t.type({
        hasLA: t.literal(true),
        name: t.string,
        clientNumberOfLA: t.string,
        relationship: form2.selectOption.required
      })
    ]),
    relationShipWithMainLifeAssuredLA4: t.union([
      t.type({
        hasLA: t.literal(false),
        name: t.literal(''),
        clientNumberOfLA: t.literal(''),
        relationship: t.null
      }),
      t.type({
        hasLA: t.literal(true),
        name: t.string,
        clientNumberOfLA: t.string,
        relationship: form2.selectOption.required
      })
    ]),
    fileAttachments: pipe(
      t.array(
        t.type({
          fileName: t.string,
          fileExtension: t.string,
          size: t.number,
          base64: t.string,
          uploadedDate: form2.date.required,
          file: form2.file.required
        })
      ),
      form2.refine(
        (files): files is FileAttachments => files.length > 0,
        () => i18next.t('message:MS020001', { field: i18next.t('submission:RelativeDocument') }),
        'FileAttachments'
      )
    ),
    nationality2: form2.selectOption.optional,
    laRelationshipNeedChange: t.boolean,
    isSelectedOtherClient: t.boolean,
    smsIndicator: form2.selectOption.requiredM(() =>
      i18next.t('message:MS020009', {
        field: i18next.t('submission:SMSIndicator')
      })
    ),
    emailIndicator: form2.selectOption.requiredM(() =>
      i18next.t('message:MS020009', {
        field: i18next.t('submission:EmailIndicator')
      })
    ),
    taxResidencyCountry: form2.selectOption.requiredM(() =>
      i18next.t('message:MS020009', {
        field: i18next.t('submission:TaxResidencyCountry')
      })
    )
  }),
  t.union([
    t.type({
      isSelectedOtherClient: t.literal(false),
      idNumber: form2.string.optional,
      surName: form2.string.optional,
      givenName: form2.string.optional,
      dob: form2.date.optional,
      gender: form2.selectOption.optional,
      nationality: form2.selectOption.optional,
      mobileCode: form2.selectOption.optional,
      mobileNumber: form2.string.optional,
      street: form2.string.optional,
      city: form2.selectOption.optional,
      district: form2.selectOption.optional,
      ward: form2.selectOption.optional
    }),
    t.type({
      isSelectedOtherClient: t.literal(true),
      idNumber: pipe(
        form2.string.requiredM(() =>
          i18next.t('message:MS020001', {
            field: i18next.t('submission:IDNumber')
          })
        ),
        form2.string.minLength(8, () => i18next.t('message:MS050013', { field: i18next.t('submission:IDNumber') }))
      ),
      surName: form2.string.requiredM(() =>
        i18next.t('message:MS020001', {
          field: i18next.t('submission:SurName')
        })
      ),
      givenName: form2.string.requiredM(() =>
        i18next.t('message:MS020001', {
          field: i18next.t('submission:GivenName')
        })
      ),
      dob: form2.date.requiredM(() =>
        i18next.t('message:MS020009', {
          field: i18next.t('submission:DateOfBirth')
        })
      ),
      gender: form2.selectOption.requiredM(() =>
        i18next.t('message:MS020009', {
          field: i18next.t('submission:Gender')
        })
      ),
      nationality: form2.selectOption.requiredM(() =>
        i18next.t('message:MS020009', {
          field: i18next.t('submission:Nationality')
        })
      ),
      mobileCode: form2.selectOption.requiredM(() =>
        i18next.t('message:MS020009', {
          field: i18next.t('submission:AboardMobilePhone')
        })
      ),
      mobileNumber: pipe(
        form2.string.requiredM(() =>
          i18next.t('message:MS020001', {
            field: i18next.t('submission:AboardMobilePhone')
          })
        )
      ),
      email: pipe(
        form2.string.requiredM(() =>
          i18next.t('message:MS020001', {
            field: i18next.t('submission:Email')
          })
        )
      ),
      street: pipe(
        form2.string.requiredM(() =>
          i18next.t('message:MS020001', {
            field: i18next.t('submission:Street')
          })
        ),
        form2.refine(
          (data): data is ValidStreet => !invalidStreet(data) && data.match(new RegExp(/[!@#$%^&*"]/g)) === null,
          () => i18next.t('message:MS050223'),
          'ValidStreet'
        )
      ),
      city: form2.selectOption.requiredM(() =>
        i18next.t('message:MS020009', {
          field: i18next.t('submission:CityProvince')
        })
      ),
      district: form2.selectOption.requiredM(() =>
        i18next.t('message:MS020009', {
          field: i18next.t('submission:District')
        })
      ),
      ward: form2.selectOption.requiredM(() =>
        i18next.t('message:MS020009', {
          field: i18next.t('submission:WardSubDistrict')
        })
      )
    })
  ]),
  t.union([
    t.type({
      foreignAddressRequired: t.literal(true),
      foreignAddress: pipe(
        form2.string.requiredM(() =>
          i18next.t('message:MS020001', {
            field: i18next.t('submission:ForeignAddress')
          })
        ),
        form2.refine(
          (data): data is ValidStreet => !invalidStreet(data) && data.match(new RegExp(/[!@#$%^&*"]/g)) === null,
          () => i18next.t('message:MS050223'),
          'ValidStreet'
        ),
        form2.refine(
          (l): l is ValidStreet => convertUnicodeToViqr(l).length <= 30,
          () => i18next.t('message:MS050047', { maxLen: 30 }),
          'MS050047'
        )
      ),
      foreignCountry: form2.selectOption.requiredM(() =>
        i18next.t('message:MS020009', {
          field: i18next.t('submission:CountryOfForeignAddress')
        })
      )
    }),
    t.type({
      foreignAddressRequired: t.literal(false),
      foreignAddress: pipe(
        form2.string.optional,
        form2.refine(
          (data): data is ValidStreet =>
            !data || (!invalidStreet(data) && data.match(new RegExp(/[!@#$%^&*"]/g)) === null),
          () => i18next.t('message:MS050223'),
          'ValidStreet'
        ),
        form2.refine(
          (l): l is ValidStreet => !l || convertUnicodeToViqr(l).length <= 30,
          () => i18next.t('message:MS050047'),
          'MS050047'
        )
      ),
      foreignCountry: form2.selectOption.optional
    })
  ]),
  // base on requirement: if Relationship is not exist in T16s2, this param check value of relationShipWithMainLifeAssured
  // and show error through messageLA param
  t.union([
    t.type({
      laRelationshipNeedChange: t.literal(true),
      messageLA: withMessage(NonEmptyString, () => i18next.t('message:MS050282'))
    }),
    t.type({
      laRelationshipNeedChange: t.literal(false),
      messageLA: form2.string.optional
    })
  ])
])
type FormData = t.TypeOf<typeof FormData>
type FormDataRaw = t.OutputOf<typeof FormData>

const endOfYesterday = PulseOpsFormat.endOfDate(PulseOpsFormat.getYesterday())

const invalidStreet = (text: string): boolean => {
  return /[");']/.test(text) || /-{2}/.test(text) || /Xp_/.test(text) || /\/\*.+\*\//.test(text)
}
type ValidStreetBrand = {
  readonly ValidStreet: unique symbol
  readonly MS050047: unique symbol
}
type ValidStreet = t.Branded<NonEmptyString, ValidStreetBrand>

export const Assignment = (props: PolicyServiceProps<AssignmentModel.DataSubmit>) => {
  const { t, i18n } = useTranslation()
  const { showGlobalLoading } = React.useContext(AppContext.AppContextInstance)

  const [districtOptions, setDistrictOptions] = React.useState<SelectOption[]>([])
  const [wardOptions, setWardOptions] = React.useState<SelectOption[]>([])

  const [anyAgentPhone, setAnyAgentPhone] = React.useState<ChangeContactInfo.DuplicateInfo>()
  const [anyAgentEmail, setAnyAgentEmail] = React.useState<ChangeContactInfo.DuplicateInfo>()

  const genders = [
    { label: t(`submission:Male`), value: 'M' },
    { label: t(`submission:Female`), value: 'F' }
  ]

  const policyNumber = props.policyNumber!
  const officeCode = props.officeCode
  const defaultNewPolicyOwner = {
    label: t('submission:Other'),
    value: 'Other'
  }

  const smsIndicators = [
    { label: t('submission:SMSIndicatorOptions.PolInfo'), value: '1' },
    { label: t('submission:SMSIndicatorOptions.Pol&PruInfo'), value: '3' },
    { label: t('submission:SMSIndicatorOptions.RefuseSMS'), value: '4' }
  ]

  const emailIndicators = [
    { label: t('submission:EmailIndicatorOptions.PolInfo'), value: '1' },
    { label: t('submission:EmailIndicatorOptions.Pol&PruInfo'), value: '3' },
    { label: t('submission:EmailIndicatorOptions.RefuseEmail'), value: '4' }
  ]

  const defaultValues: Partial<FormDataRaw> = {
    newPolicyOwner: defaultNewPolicyOwner,
    isSelectedOtherClient: true,
    idNumber: '',
    relationShipWithMainLifeAssured: null,
    laRelationshipNeedChange: false,
    surName: '',
    givenName: '',
    usTaxRegister: false,
    dob: undefined,
    gender: null,
    nationality: null,
    mobileCode: null,
    mobileNumber: '',
    email: '',
    occupation: null,
    street: '',
    country: null,
    city: null,
    district: null,
    ward: null,
    foreignAddress: '',
    nationality2: null,
    dupPhones: [],
    dupEmails: [],
    relationShipWithMainLifeAssuredLA2: {
      hasLA: false,
      name: '',
      clientNumberOfLA: '',
      relationship: null
    },
    relationShipWithMainLifeAssuredLA3: {
      hasLA: false,
      name: '',
      clientNumberOfLA: '',
      relationship: null
    },
    relationShipWithMainLifeAssuredLA4: {
      hasLA: false,
      name: '',
      clientNumberOfLA: '',
      relationship: null
    },
    fileAttachments: [],
    foreignAddressRequired: false,
    messageLA: null,
    smsIndicator: null,
    emailIndicator: null,
    foreignCountry: null,
    taxResidencyCountry: null
  }

  const { base, handleSubmit } = form2.useForm(FormData, { defaultValues })

  const { mobileCode, mobileNumber } = base.watch()

  const nationality = base.watch('nationality')
  const nationality2 = base.watch('nationality2')
  const isSelectedOtherClient = base.watch('isSelectedOtherClient')

  React.useEffect(() => {
    base.setValue(
      'foreignAddressRequired',
      (!!nationality?.value && nationality?.value !== 'VN') || (!!nationality2?.value && nationality2?.value !== 'VN')
    )
    base.clearErrors('foreignAddress')
  }, [nationality, nationality2])

  const data = pipe(
    ZIO.zipPar(
      AssignmentService.getDetail(policyNumber),
      GeneralService.getProvinces,
      GeneralService.getOccupations,
      GeneralService.getCountries,
      GeneralService.getMobileCodes,
      GeneralService.getSalaries
    ),
    ZIO.map(([detail, provinces, occupations, countries, mobileCodes, salaries]) => {
      const provinceOptions = provinces.map((c) => ({
        value: c.code,
        label: c.name
      }))
      const careers = occupations.map((c) => ({
        value: c.code,
        label: c.name
      }))
      const nationOptions = countries.map((c) => ({
        value: c.code,
        label: c.name
      }))
      const telCodeOptions = mobileCodes.map((c) => ({
        value: c.code,
        label: `+${c.code}`
      }))
      const incomeRanges = salaries.map((c) => ({
        value: c.code,
        label: c.name
      }))
      return {
        detail,
        provinceOptions,
        careers,
        nationOptions,
        telCodeOptions,
        incomeRanges
      }
    }),
    ZIO.tap(({ detail, nationOptions, telCodeOptions }) =>
      ZIO.effect(() => {
        const defaulCountry = nationOptions.find((_) => _.value === 'VN')
        defaulCountry && base.setValue('country', defaulCountry)
        const defaulTelCode = telCodeOptions.find((_) => _.value === '84')
        defaulTelCode && base.setValue('mobileCode', defaulTelCode)
        updateLA('relationShipWithMainLifeAssuredLA2', detail, 0)
        updateLA('relationShipWithMainLifeAssuredLA3', detail, 1)
        updateLA('relationShipWithMainLifeAssuredLA4', detail, 2)
      })
    ),
    ErrorHandling.runDidMount(null)
  )

  const { relationshipt16, relationshipt16s2, relationshipLA2, relationshipLA3, relationshipLA4 } = pipe(
    ZIO.zipPar(GeneralService.getRelationship(), GeneralService.getRelationshipt16s2),
    ZIO.map(([relationships, relationshipS2]) => {
      const relationshipt16 = relationships
        .filter((r) => r.assignment === 'Y')
        .map((r) => ({ value: r.code, label: i18n.language === 'vi' ? r.relationshipVN : r.relationshipEN }))
      const relationshipt16s2 = relationshipS2
        .map((r) => {
          const relationship = relationshipt16.find((e) => e.value === r.code)
          return { ...relationship, ...r }
        })
        .filter((e): e is RelationshipS2Map => !!e)
      const filterLA = (f: (r: RelationshipS2Map) => boolean): SelectOption[] => {
        return relationshipt16s2.filter(f).map((r) => ({ value: r.value, label: r.label }))
      }

      const relationshipLA2 = filterLA((r) => r.la2 === 'T')
      const relationshipLA3 = filterLA((r) => r.la3 === 'T')
      const relationshipLA4 = filterLA((r) => r.la4 === 'T')
      return {
        relationshipt16,
        relationshipt16s2: relationshipS2,
        relationshipLA2,
        relationshipLA3,
        relationshipLA4
      }
    }),
    ErrorHandling.runDidUpdate([i18n.language])
  ) ?? {
    relationshipt16: [],
    relationshipt16s2: [],
    relationshipLA: [],
    relationshipLA2: [],
    relationshipLA3: [],
    relationshipLA4: []
  }

  const updateLA = (
    nameLA:
      | 'relationShipWithMainLifeAssuredLA2'
      | 'relationShipWithMainLifeAssuredLA3'
      | 'relationShipWithMainLifeAssuredLA4',
    detail: AssignmentModel.Detail,
    lifeIndex?: number
  ) => {
    const lifeId =
      nameLA === 'relationShipWithMainLifeAssuredLA2'
        ? '02'
        : nameLA === 'relationShipWithMainLifeAssuredLA3'
          ? '03'
          : '04'
    const hasLA = detail.laList.find(
      ({ life, clientNumberOfLA }, index) =>
        (detail.laList.length >= 4 ? life === lifeId : index === lifeIndex) &&
        base.watch('newPolicyOwner')?.value !== clientNumberOfLA
    )
    if (hasLA) {
      base.setValue(nameLA, {
        hasLA: true,
        name: hasLA.name,
        clientNumberOfLA: hasLA.clientNumberOfLA,
        relationship: null
      })
    } else {
      base.setValue(nameLA, {
        hasLA: false,
        name: '',
        clientNumberOfLA: '',
        relationship: null
      })
    }
  }

  const getRole = (role: string) => {
    return role === 'PolicyOwner'
      ? t(`submission:PolicyOwner`)
      : role === 'LifeAssured'
        ? t(`submission:LifeAssured`)
        : role === 'Beneficiary'
          ? t(`submission:Beneficiary`)
          : '-'
  }

  const loadDistrictOptions = (provinceCode?: string, customerDistrict?: string) => {
    if (!provinceCode) {
      setDistrictOptions([])
      return base.setValue('district', null)
    }
    return pipe(
      ZIO.effect(() => showGlobalLoading(true)),
      ZIO.flatMap(() => GeneralService.getDistricts(provinceCode)),
      ZIO.map((districts) => {
        const mapDistricts = districts.map((_) => ({
          value: _.code,
          label: _.name
        }))
        setDistrictOptions(mapDistricts)
        return base.setValue('district', mapDistricts.find((_) => _.value === customerDistrict) || null)
      }),
      ZIO.tap(() => {
        showGlobalLoading(false)
        return ZIO.unit
      }),
      ZIO.tapError(() => {
        showGlobalLoading(false)
        return ZIO.unit
      }),
      ZIO.unsafeRun({})
    )
  }

  const loadWardOptions = (provinceCode?: string, districtCode?: string, customerWard?: string) => {
    if (!provinceCode || !districtCode) {
      setWardOptions([])
      return base.setValue('ward', null)
    }
    return pipe(
      ZIO.effect(() => showGlobalLoading(true)),
      ZIO.flatMap(() => GeneralService.getWards({ provinceCode, districtCode })),
      ZIO.map((wards) => {
        const mapWards = wards.map((_) => ({
          value: _.code,
          label: _.name
        }))
        setWardOptions(mapWards)
        return base.setValue('ward', mapWards.find((_) => _.value === customerWard) || null)
      }),
      ZIO.tap(() => {
        showGlobalLoading(false)
        return ZIO.unit
      }),
      ZIO.tapError(() => {
        showGlobalLoading(false)
        return ZIO.unit
      }),
      ZIO.unsafeRun({})
    )
  }

  const isSameClient = (relationship: RelationShip, clientInfo?: AssignmentModel.CustomerData): boolean => {
    if (clientInfo) {
      return (
        relationship.clientName === `${clientInfo.surName} ${clientInfo.name}` &&
        relationship.dob === clientInfo.dob &&
        relationship.gender === clientInfo.gender &&
        relationship.secuityNo === clientInfo.idNum
      )
    }
    return false
  }

  const loadDuplicatePhoneInfo = (phone?: string) => {
    const customer = data?.detail.customerData.find((_) => _.customerId === base.watch('newPolicyOwner')?.value)
    if (phone) {
      pipe(
        ZIO.effect(() => showGlobalLoading(true)),
        ZIO.flatMap(() => ChangeContactInfoService.getDuplicatePhoneInfo(phone, customer?.customerId || '')),
        ZIO.map((duplicateInfos) => {
          const listDupPhoneNotSameClient = duplicateInfos
            .map((e) => ({
              role: e.role,
              clientName: e.clientName,
              clientId: e.clientId,
              relationship: null,
              dob: e.dob,
              secuityNo: e.secuityNo,
              gender: e.gender,
              poName: e.clientName
            }))
            .filter((e) => !isSameClient(e, customer))

          const clientRoleAgent = listDupPhoneNotSameClient.find(predicateAgent)
          setAnyAgentPhone(clientRoleAgent)
          if (!clientRoleAgent) {
            base.setValue('dupPhones', listDupPhoneNotSameClient)
          }
        }),
        ZIO.tap(() => {
          showGlobalLoading(false)
          return ZIO.unit
        }),
        ZIO.tapError(() => {
          setAnyAgentPhone(undefined)
          showGlobalLoading(false)
          return ZIO.unit
        }),
        ZIO.unsafeRun({})
      )
    } else {
      setAnyAgentPhone(undefined)
    }
  }

  const loadDuplicateEmail = (email?: string) => {
    const customer = data?.detail.customerData.find((_) => _.customerId === base.watch('newPolicyOwner')?.value)
    if (email) {
      pipe(
        ZIO.effect(() => showGlobalLoading(true)),
        ZIO.flatMap(() => ChangeContactInfoService.getDuplicateEmailInfo(email, customer?.customerId || '')),
        ZIO.map((duplicateEmails) => {
          const listDupEmailNotSameClient = duplicateEmails
            .map((e) => ({
              role: e.role,
              clientName: e.clientName,
              clientId: e.clientId,
              relationship: null,
              dob: e.dob,
              secuityNo: e.secuityNo,
              gender: e.gender,
              poName: e.clientName
            }))
            .filter((e) => !isSameClient(e, customer))

          const clientRoleAgent = listDupEmailNotSameClient.find(predicateAgent)
          setAnyAgentEmail(clientRoleAgent)

          if (!clientRoleAgent) {
            base.setValue('dupEmails', listDupEmailNotSameClient)
          }
        }),
        ZIO.tap(() => {
          showGlobalLoading(false)
          return ZIO.unit
        }),
        ZIO.tapError(() => {
          setAnyAgentEmail(undefined)
          showGlobalLoading(false)
          return ZIO.unit
        }),
        ZIO.unsafeRun({})
      )
    } else {
      setAnyAgentEmail(undefined)
    }
  }

  const patchData = (customerId?: string) => {
    const customer = data?.detail.customerData.find((_) => _.customerId === customerId)
    if (customer && data) {
      const occupation = data.careers.find((_) => _.value === customer.occupation) || { value: '', label: '-' }
      const nationality2 = data.nationOptions.find((_) => _.value === customer.nationality2) || {
        value: '',
        label: '-'
      }
      base.reset({
        newPolicyOwner: base.watch('newPolicyOwner'),
        isSelectedOtherClient: false,
        idNumber: customer.idNum,
        relationShipWithMainLifeAssured: defaultValues.relationShipWithMainLifeAssured,
        surName: customer.surName,
        givenName: customer.name,
        usTaxRegister: customer.fatca,
        dob: PulseOpsFormat.getStringToDate(customer.dob, 'YYYYMMDD'),
        gender: genders.find((_) => _.value === customer.gender) || null,
        nationality: data.nationOptions.find((_) => _.value === customer.nationality) || null,
        mobileCode: data.telCodeOptions.find((_) => _.value === customer.mobilePhoneCode) || null,
        mobileNumber: customer.mobilePhoneNumber,
        email: customer.email,
        occupation: occupation,
        street: customer.address,
        country: data.nationOptions.find((_) => _.value === customer.countryCode) || null,
        city: data.provinceOptions.find((_) => _.value === customer.city) || null,
        district: districtOptions.find((_) => _.value === customer.district) || null,
        ward: wardOptions.find((_) => _.value === customer.ward) || null,
        foreignAddress: customer.foreignAddress,
        nationality2: nationality2,
        dupPhones: defaultValues.dupPhones,
        dupEmails: defaultValues.dupEmails,
        relationShipWithMainLifeAssuredLA2: defaultValues.relationShipWithMainLifeAssuredLA2,
        relationShipWithMainLifeAssuredLA3: defaultValues.relationShipWithMainLifeAssuredLA3,
        relationShipWithMainLifeAssuredLA4: defaultValues.relationShipWithMainLifeAssuredLA4,
        fileAttachments: defaultValues.fileAttachments,
        foreignAddressRequired: defaultValues.foreignAddressRequired,
        foreignCountry: data.nationOptions.find((_) => _.value === customer.foreignCountry) || null,
      })
      loadDuplicatePhoneInfo(customer.mobilePhoneNumber)
      loadDuplicateEmail(customer.email)
      loadDistrictOptions(customer.city, customer.district)
      loadWardOptions(customer.city, customer.district, customer.ward)
    } else {
      base.reset({
        ...defaultValues,
        country: data?.nationOptions.find((_) => _.value === 'VN') || null,
        mobileCode: data?.telCodeOptions.find((_) => _.value === '84') || null
      })
      setDistrictOptions([])
      setWardOptions([])
    }
  }

  const getUploadedFilesInfo = (formData: FormDataRaw) => {
    let uploadedFileList: UploadedFilesInfo[] = []
    uploadedFileList.push({
      uploadFiles: formData.fileAttachments as FileMeta[],
      transactionType: TransactionType.ASSIGNMENT,
      docTypeCode: 'DPS10',
      category: TaskType.PolicyService,
      policyNumber: policyNumber ?? '',
      officeCode: officeCode ?? ''
    })
    return uploadedFileList
  }

  props.initSubmission({
    validate: async (isContinue) => {
      const _form = await handleSubmit(() => undefined)()

      if (E.isRight(_form) && data && !anyAgentPhone && !anyAgentEmail) {
        const form = _form.right
        // let fileMeta: StorageBlob.FileContentSubmit[] = []
        // if (!isContinue) {
        //   showGlobalLoading(true)
        //   fileMeta = await pipe(
        //     GeneralService.getMetaData(TransactionType.ASSIGNMENT || '', 'DPS10'),
        //     ZIO.flatMap((metaDataRes) => {
        //       const metaDataRaw: StorageBlob.MetaDataUpload = {
        //         type: metaDataRes.data.transactionType,
        //         doctype: metaDataRes.data.doctypeEn,
        //         class: metaDataRes.data.classFilenet,
        //         docid: metaDataRes.data.docID,
        //         maindoc: metaDataRes.data.mainDoc,
        //         subdoc: metaDataRes.data.subDoc,
        //         polnum: policyNumber,
        //         batchno: metaDataRes.data.batchNo
        //       }
        //       const files: StorageBlob.FileContent[] = form.fileAttachments.map((x) => {
        //         return {
        //           file: x.file,
        //           metaData: metaDataRaw
        //         }
        //       })
        //       return pipe(StorageBlob.uploadToSubmit('PS', policyNumber)(files))
        //     }),
        //     ErrorHandling.run({})
        //   )
        //   showGlobalLoading(false)
        // }
        return {
          url: (policyNumber: string) => `wf-api/policy/${policyNumber}/assignment`,
          body: {
            clientId: data.detail.customerId,
            surName: `${form.surName || ''}`,
            firstName: `${form.givenName || ''}`,
            dob: PulseOpsFormat.datetoFormat(form.dob, 'yyyyMMDD'),
            dateOfDeath: '',
            sex: form.gender?.value || '',
            nationality: form.nationality?.value || '',
            occupation: form.occupation.value,
            smsIndicator: form.smsIndicator?.value || '',
            emailIndicator: form.emailIndicator?.value || '',
            foreignCountry: form.foreignCountry?.value || '',
            contactDetails: {
              PHONE: {
                countryCode: form.mobileCode?.value || '',
                value: `${form.mobileNumber || ''}`
              },
              EMAIL: {
                value: form.email
              }
            },
            addressDetails: {
              ADDRESS: {
                line1: `${form.street || ''}`,
                countryCode: form.country.value,
                city: form.city?.label || '',
                district: form.district?.label || '',
                subDistrict: form.ward?.label || '',
                location: form.ward?.value || ''
              },
              FOREIGN_ADDRESS: {
                line1: `${form.foreignAddress || ''}`
              }
            },
            attributesExt: {
              POLICY_NO: policyNumber,
              CLIENT_NO: form.newPolicyOwner.value === defaultNewPolicyOwner.value ? '' : form.newPolicyOwner.value,
              ID_NUMBER: `${form.idNumber || ''}`,
              RELATIONSHIP_1: form.relationShipWithMainLifeAssured.value,
              NATIONALITY_2: form.nationality2?.value || '',
              IS_FATCA: form.usTaxRegister ? 'Y' : 'N',
              POLICY_HAS_BEN: data.detail.policyHasBen ? 'Y' : 'N',
              LOCATION_CODE: form.ward?.value || '',
              DUPLICATE_PHONES:
                form.dupPhones
                  ?.filter(({ role }) => role !== 'AG')
                  .map((item) => ({
                    CLIENT_NUM: item.clientId || '',
                    CLIENT_NAME: item.clientName || '',
                    RELATIONSHIP: item.relationship?.value || ''
                  })) ?? [],
              DUPLICATE_EMAILS:
                form.dupEmails
                  ?.filter(({ role }) => role !== 'AG')
                  .map((item) => ({
                    CLIENT_NUM: item.clientId || '',
                    CLIENT_NAME: item.clientName || '',
                    RELATIONSHIP: item.relationship?.value || ''
                  })) ?? [],
              ...(form.relationShipWithMainLifeAssuredLA2.hasLA
                ? {
                  [`CLIENT_NO_2`]: form.relationShipWithMainLifeAssuredLA2.clientNumberOfLA,
                  [`RELATIONSHIP_2`]: form.relationShipWithMainLifeAssuredLA2.relationship.value,
                  [`CLIENT_NAME_2`]: form.relationShipWithMainLifeAssuredLA2.name
                }
                : {}),
              ...(form.relationShipWithMainLifeAssuredLA3.hasLA
                ? {
                  [`CLIENT_NO_3`]: form.relationShipWithMainLifeAssuredLA3.clientNumberOfLA,
                  [`RELATIONSHIP_3`]: form.relationShipWithMainLifeAssuredLA3.relationship.value,
                  [`CLIENT_NAME_3`]: form.relationShipWithMainLifeAssuredLA3.name
                }
                : {}),
              ...(form.relationShipWithMainLifeAssuredLA4.hasLA
                ? {
                  [`CLIENT_NO_4`]: form.relationShipWithMainLifeAssuredLA4.clientNumberOfLA,
                  [`RELATIONSHIP_4`]: form.relationShipWithMainLifeAssuredLA4.relationship.value,
                  [`CLIENT_NAME_4`]: form.relationShipWithMainLifeAssuredLA4.name
                }
                : {}),
              newPolicyOwner: form.newPolicyOwner.label,
              nationality1Name: form.nationality?.label || '',
              nationality2Name: form.nationality2?.label || '',
              occupationName: form.occupation.label,
              dobFormated: PulseOpsFormat.datetoFormat(form.dob, 'DD/MM/yyyy'),
              countryName: form.country.label,
              taxResidencyCountry: form.taxResidencyCountry?.value || ''
            }
          },
          transactionName: RequestAuthenticateData.TransactionLabelShort(TransactionType.ASSIGNMENT),
          collerationId: props.policyNumber ?? '',
          transaction: TransactionType.ASSIGNMENT,
          // documents: fileMeta,
          uploadedFilesInfo: getUploadedFilesInfo(form)
        }
      }

      return false
    },
    clear: () => {
      base.reset(defaultValues)
    }
  })

  const predicateAgent = (relationship: RelationShip) => relationship.role === 'AG'

  const phoneDups = base.watch('dupPhones') ?? []
  const emailDups = base.watch('dupEmails') ?? []

  // const anyAgentPhone = React.useMemo(() => phoneDups?.find(predicateAgent), [phoneDups])
  // const anyAgentEmail = React.useMemo(() => emailDups?.find(predicateAgent), [emailDups])

  const renderReadOnlyValue = (title: string, value: string | null | undefined, required: boolean = false) => {
    return (
      <Column>
        <Label title={title} fontWeight="bold" required={required} />
        <Column marginTop={10}>
          <Label title={value || '-'} color={'#000000'} />
        </Column>
      </Column>
    )
  }

  return data === null ? (
    <LinearProgress style={{ marginTop: 15 }} color="secondary" />
  ) : (
    <SafeAreaView style={{ flex: 1, marginTop: 15 }}>
      <View style={{ backgroundColor: '#FFF' }}>
        <Text style={sharedStyle.sectionTitle}>{t('submission:Assignment')}</Text>
        <View style={sharedStyle.sectionContent}>
          {/* Row 1 */}
          <View style={styles.row}>
            <View style={styles.col}>
              <Controller
                name="newPolicyOwner"
                control={base.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                  const isReadOnly = props.isConfirmed

                  if (isReadOnly) {
                    return renderReadOnlyValue(t('submission:NewPolicyOwner'), value?.label)
                  }

                  return (
                    <Select
                      required={true}
                      label={t('submission:NewPolicyOwner')}
                      options={data.detail.customerData
                        .map((o) => ({
                          label: `${o.surName} ${o.name} - ${getRole(o.role)}`,
                          value: o.customerId
                        }))
                        .concat([defaultNewPolicyOwner])}
                      placeholder={t('common:Select')}
                      onChange={(e) => {
                        onChange(e)
                        patchData(base.watch('newPolicyOwner')?.value)
                        updateLA('relationShipWithMainLifeAssuredLA2', data.detail, 0)
                        updateLA('relationShipWithMainLifeAssuredLA3', data.detail, 1)
                        updateLA('relationShipWithMainLifeAssuredLA4', data.detail, 2)
                      }}
                      onBlur={onBlur}
                      value={value}
                      errorMessage={error?.message}
                    />
                  )
                }}
              />
            </View>
            <View style={styles.col}>
              <Controller
                name="idNumber"
                control={base.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                  const isRequired = isSelectedOtherClient
                  const isReadOnly = !isSelectedOtherClient || props.isConfirmed

                  if (isReadOnly) {
                    return renderReadOnlyValue(t('submission:IDNumber'), value)
                  }

                  return (
                    <Input
                      required={isRequired}
                      title={t('submission:IDNumber')}
                      placeholder={t('submission:idNumberPlaceHolder')}
                      inputStyle={{ marginTop: 6 }}
                      value={value ?? undefined}
                      maxLength={24}
                      onChange={onChange}
                      onBlur={onBlur}
                      errorMessage={error?.message}
                    />
                  )
                }}
              />
            </View>
            <View style={styles.col}>
              <Controller
                name="relationShipWithMainLifeAssured"
                control={base.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                  const isReadOnly = props.isConfirmed

                  if (isReadOnly) {
                    return renderReadOnlyValue(t('submission:RelationshipWithMainLifeAssurance'), value?.label)
                  }

                  return (
                    <Select
                      required={true}
                      label={t('submission:RelationshipWithMainLifeAssurance')}
                      options={relationshipt16}
                      placeholder={t('common:Select')}
                      onChange={(v) => {
                        onChange(v)
                        if (v) {
                          // check value relation exist on t16s2 and have value(la1) === F
                          const rTableS2 = relationshipt16s2.find((r) => r.code === v.value && r.la1 === 'F')
                          if (rTableS2) {
                            base.setValue('laRelationshipNeedChange', true)
                          } else {
                            base.setValue('laRelationshipNeedChange', false)
                          }
                        } else {
                          base.setValue('laRelationshipNeedChange', false)
                        }
                        base.trigger('messageLA')
                      }}
                      onBlur={onBlur}
                      value={value}
                      errorMessage={error?.message || _.get(base.formState.errors, 'messageLA.message')}
                    />
                  )
                }}
              />
            </View>
          </View>
          {/* Row 2 */}
          <View style={styles.row}>
            <View style={styles.col}>
              <Controller
                name="surName"
                control={base.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                  const isRequired = isSelectedOtherClient
                  const isReadOnly = !isSelectedOtherClient || props.isConfirmed

                  if (isReadOnly) {
                    return renderReadOnlyValue(t('submission:SurName'), value)
                  }

                  return (
                    <Input
                      required={isRequired}
                      title={t('submission:SurName')}
                      placeholder={t('submission:SurNamePlaceHolder')}
                      value={value ?? undefined}
                      maxLength={150}
                      onChange={onChange}
                      onBlur={onBlur}
                      errorMessage={error?.message}
                    />
                  )
                }}
              />
            </View>
            <View style={styles.col}>
              <Controller
                name="givenName"
                control={base.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                  const isRequired = isSelectedOtherClient
                  const isReadOnly = !isSelectedOtherClient || props.isConfirmed

                  if (isReadOnly) {
                    return renderReadOnlyValue(t('submission:GivenName'), value)
                  }

                  return (
                    <Input
                      required={isRequired}
                      title={t('submission:GivenName')}
                      placeholder={t('submission:GivenNamePlaceHolder')}
                      value={value ?? undefined}
                      maxLength={150}
                      onChange={onChange}
                      onBlur={onBlur}
                      errorMessage={error?.message}
                    />
                  )
                }}
              />
            </View>
            <View style={styles.col}>
              <Controller
                name="usTaxRegister"
                control={base.control}
                render={({ field: { onChange, value } }) => {
                  const isRequired = !props.isConfirmed
                  const isReadOnly = !isSelectedOtherClient || props.isConfirmed
                  return (
                    <SC.Padding>
                      <SC.BoldText>
                        {t('submission:CurrentlyFatca')} {isRequired && <SC.ErrorText>*</SC.ErrorText>}
                      </SC.BoldText>
                      <SC.Divider />
                      <SC.Row>
                        <RadioButton
                          label={t('common:Yes')}
                          selected={value}
                          onChange={() => onChange.call(null, true)}
                          disable={isReadOnly}
                        />
                        <RadioButton
                          label={t('common:No')}
                          selected={!value}
                          onChange={() => onChange.call(null, false)}
                          disable={isReadOnly}
                        />
                      </SC.Row>
                    </SC.Padding>
                  )
                }}
              />
            </View>
          </View>
          {/* Row 3 */}
          <View style={styles.row}>
            <View style={styles.col}>
              <Controller
                name="dob"
                control={base.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                  const isRequired = isSelectedOtherClient
                  const isReadOnly = !isSelectedOtherClient || props.isConfirmed

                  if (isReadOnly) {
                    return renderReadOnlyValue(
                      t('submission:DateOfBirth'),
                      value ? moment(value).format('DD/MM/YYYY') : null
                    )
                  }

                  return (
                    <DatePicker
                      required={isRequired}
                      label={t('submission:DateOfBirth')}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      maxDate={endOfYesterday}
                      maxDateMessage={t('message:MS050022')}
                      errorMessage={error?.message}
                    />
                  )
                }}
              />
            </View>
            <View style={styles.col}>
              <Controller
                name="gender"
                control={base.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                  const isRequired = isSelectedOtherClient
                  const isReadOnly = !isSelectedOtherClient || props.isConfirmed

                  if (isReadOnly) {
                    return renderReadOnlyValue(t('submission:Gender'), value?.label)
                  }

                  return (
                    <Select
                      required={isRequired}
                      label={t('submission:Gender')}
                      options={genders}
                      placeholder={t('common:Select')}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      errorMessage={error?.message}
                    />
                  )
                }}
              />
            </View>
            <View style={styles.col}>
              <Controller
                name="nationality"
                control={base.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                  const isRequired = isSelectedOtherClient
                  const isReadOnly = !isSelectedOtherClient || props.isConfirmed

                  if (isReadOnly) {
                    return renderReadOnlyValue(t('submission:Nationality'), value?.label)
                  }

                  return (
                    <SelectSearch
                      required={isRequired}
                      label={t('submission:Nationality')}
                      options={data.nationOptions}
                      placeholder={t('common:Select')}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      errorMessage={error?.message}
                      popupIcon={<assets.ArrowDownDropdownIcon />}
                    />
                  )
                }}
              />
            </View>
          </View>
          {/* Row 4 */}
          <View style={styles.row}>
            <View style={styles.col}>
              {!isSelectedOtherClient || props.isConfirmed ? (
                renderReadOnlyValue(
                  t('submission:AboardMobilePhone'),
                  !mobileCode?.value && !mobileNumber
                    ? '-'
                    : `${mobileCode?.value ? `+${mobileCode?.value}` : ''} ${mobileNumber || ''}`
                )
              ) : (
                <View style={{ flex: 1, paddingTop: 4 }}>
                  <SC.TitleText>
                    {t('submission:AboardMobilePhone')} {isSelectedOtherClient && <SC.ErrorText>*</SC.ErrorText>}
                  </SC.TitleText>
                  <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
                    <Controller
                      name={'mobileCode'}
                      control={base.control}
                      render={({ field: { value, onChange, onBlur } }) => (
                        <View style={{ width: 110 }}>
                          <SelectSearch
                            options={data.telCodeOptions}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                            hideLabel
                            placeholder={'+84'}
                          />
                        </View>
                      )}
                    />
                    <SC.Divider />
                    <Controller
                      control={base.control}
                      name={'mobileNumber'}
                      render={({ field: { value, onChange, onBlur } }) => (
                        <View style={{ flex: 1 }}>
                          <Input
                            onChange={onChange}
                            onBlur={() => {
                              onBlur()
                              loadDuplicatePhoneInfo(base.watch('mobileNumber') || undefined)
                            }}
                            value={value ?? undefined}
                            maxLength={20}
                            inputType="number"
                            placeholder={t('submission:InputPhone')}
                          />
                        </View>
                      )}
                    />
                  </View>
                  {(_.get(base.formState.errors.mobileCode, 'message') ||
                    _.get(base.formState.errors.mobileNumber, 'message')) && (
                      <View>
                        <SC.ErrorText fontSize={11.25}>
                          {_.get(base.formState.errors.mobileCode, 'message') ||
                            _.get(base.formState.errors.mobileNumber, 'message')}
                        </SC.ErrorText>
                      </View>
                    )}
                </View>
              )}
            </View>
            <View style={styles.col}>
              <Controller
                name="email"
                control={base.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                  const isReadOnly = !isSelectedOtherClient || props.isConfirmed

                  if (isReadOnly) {
                    return renderReadOnlyValue(t('submission:Email'), value)
                  }

                  return (
                    <Input
                      title={t('submission:Email')}
                      placeholder={t('submission:EmailPlaceHolder')}
                      inputStyle={{ marginTop: 6 }}
                      value={value ?? undefined}
                      maxLength={50}
                      onChange={onChange}
                      required={isSelectedOtherClient}
                      onBlur={() => {
                        onBlur()
                        loadDuplicateEmail(base.watch('email') || undefined)
                      }}
                      errorMessage={error?.message}
                    />
                  )
                }}
              />
            </View>
            <View style={styles.col}>
              <Controller
                name="occupation"
                control={base.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                  const isReadOnly = !isSelectedOtherClient || props.isConfirmed

                  if (isReadOnly) {
                    return renderReadOnlyValue(t('submission:Occupation'), value?.label)
                  }

                  return (
                    <SelectSearch
                      required={true}
                      label={t('submission:Occupation')}
                      options={data.careers}
                      placeholder={t('common:Select')}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      errorMessage={error?.message}
                      popupIcon={<assets.ArrowDownDropdownIcon />}
                    />
                  )
                }}
              />
            </View>
          </View>
          {/* Row 5 */}
          <View style={styles.row}>
            <View style={styles.col}>
              <Controller
                name="smsIndicator"
                control={base.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <Select
                    required
                    label={t('submission:SMSIndicator')}
                    options={smsIndicators}
                    placeholder={t('common:Select')}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    errorMessage={error?.message}
                  />
                )}
              />
            </View>
            <View style={styles.col}>
              <Controller
                name="emailIndicator"
                control={base.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <Select
                    required
                    label={t('submission:EmailIndicator')}
                    options={emailIndicators}
                    placeholder={t('common:Select')}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    errorMessage={error?.message}
                  />
                )}
              />
            </View>
          </View>
          {/* Dup Phones, Dup Emails */}
          {anyAgentPhone ? (
            <SC.Padding left={30} bottom={10}>
              <SC.ErrorText>{t('message:MS050225', { agent: _.get(anyAgentPhone, 'clientName') })}</SC.ErrorText>
            </SC.Padding>
          ) : (
            phoneDups &&
            phoneDups.length > 0 && (
              <SC.Padding horizontal={25}>
                <SC.Padding vertical={10}>
                  <SC.ErrorText>{t('submission:PhoneDuplicatedWithOther')}</SC.ErrorText>
                </SC.Padding>
                {_.get(base.formState.errors, 'dupPhones') && (
                  <SC.Padding vertical={5}>
                    <SC.ErrorText>{t('message:MS050228')}</SC.ErrorText>
                  </SC.Padding>
                )}
                <Controller
                  control={base.control}
                  name={'dupPhones'}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <DuplicateTable
                        key={`dup_phone_table${props.isConfirmed}`}
                        value={value ?? []}
                        onChange={onChange}
                        disable={props.isConfirmed}
                        relationshipOptions={relationshipt16 ?? []}
                      />
                    )
                  }}
                />
              </SC.Padding>
            )
          )}

          {anyAgentEmail ? (
            <SC.Padding left={30} bottom={10}>
              <SC.ErrorText>{t('message:MS050227', { agent: _.get(anyAgentEmail, 'clientName') })}</SC.ErrorText>
            </SC.Padding>
          ) : (
            emailDups &&
            emailDups.length > 0 && (
              <SC.Padding vertical={15} horizontal={25}>
                <SC.Padding vertical={10}>
                  <SC.ErrorText>{t('submission:EmailDuplicatedWithOther')}</SC.ErrorText>
                </SC.Padding>
                {_.get(base.formState.errors, 'dupEmails') && (
                  <SC.Padding vertical={5}>
                    <SC.ErrorText>{t('message:MS050228')}</SC.ErrorText>
                  </SC.Padding>
                )}
                <Controller
                  control={base.control}
                  name={'dupEmails'}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <DuplicateTable
                        key={`dup_mail_table${props.isConfirmed}`}
                        value={value ?? []}
                        onChange={onChange}
                        disable={props.isConfirmed}
                        relationshipOptions={relationshipt16 ?? []}
                      />
                    )
                  }}
                />
              </SC.Padding>
            )
          )}
          {/* Row Country, CityProvince, District */}
          <View style={styles.row}>
            <View style={styles.col}>
              <Controller
                name="country"
                control={base.control}
                render={({ field: { value } }) => {
                  return renderReadOnlyValue(t('submission:Country'), value?.label)
                  //   return (
                  //     <SelectSearch
                  //       // required={!props.isConfirmed}
                  //       label={t('submission:Country')}
                  //       options={data.nationOptions}
                  //       placeholder={t('common:Select')}
                  //       onChange={onChange}
                  //       onBlur={onBlur}
                  //       value={value}
                  //       // disabled={
                  //       //   props.isConfirmed || base.watch('newPolicyOwner')?.value !== defaultNewPolicyOwner.value
                  //       // }
                  //       errorMessage={error?.message}
                  //       // disableUnderline={props.isConfirmed}
                  //       popupIcon={<assets.ArrowDownDropdownIcon />}
                  //       // showPopupIcon={!props.isConfirmed}
                  //     />
                  //   )
                }}
              />
            </View>
            <View style={styles.col}>
              <Controller
                name="city"
                control={base.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                  const isRequired = isSelectedOtherClient
                  const isReadOnly = !isSelectedOtherClient || props.isConfirmed

                  if (isReadOnly) {
                    return renderReadOnlyValue(t('submission:CityProvince'), value?.label)
                  }

                  return (
                    <SelectSearch
                      required={isRequired}
                      label={t('submission:CityProvince')}
                      options={data.provinceOptions}
                      placeholder={t('common:Select')}
                      onChange={(e) => {
                        onChange(e)
                        loadDistrictOptions(base.watch('city')?.value)
                        loadWardOptions()
                      }}
                      onBlur={onBlur}
                      value={value}
                      errorMessage={error?.message}
                      popupIcon={<assets.ArrowDownDropdownIcon />}
                    />
                  )
                }}
              />
            </View>
            <View style={styles.col}>
              <Controller
                name="district"
                control={base.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                  const isRequired = isSelectedOtherClient
                  const isReadOnly = !isSelectedOtherClient || props.isConfirmed

                  if (isReadOnly) {
                    return renderReadOnlyValue(t('submission:District'), value?.label)
                  }

                  return (
                    <SelectSearch
                      required={isRequired}
                      label={t('submission:District')}
                      options={districtOptions}
                      placeholder={t('common:Select')}
                      onChange={(e) => {
                        onChange(e)
                        loadWardOptions(base.watch('city')?.value, base.watch('district')?.value)
                      }}
                      onBlur={onBlur}
                      value={value}
                      errorMessage={error?.message}
                      popupIcon={<assets.ArrowDownDropdownIcon />}
                    />
                  )
                }}
              />
            </View>
          </View>
          {/* Row WardSubDistrict, Street */}
          <View style={styles.row}>
            <View style={styles.col}>
              <Controller
                name="ward"
                control={base.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                  const isRequired = isSelectedOtherClient
                  const isReadOnly = !isSelectedOtherClient || props.isConfirmed

                  if (isReadOnly) {
                    return renderReadOnlyValue(t('submission:WardSubDistrict'), value?.label)
                  }

                  return (
                    <SelectSearch
                      required={isRequired}
                      label={t('submission:WardSubDistrict')}
                      options={wardOptions}
                      placeholder={t('common:Select')}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      errorMessage={error?.message}
                      popupIcon={<assets.ArrowDownDropdownIcon />}
                    />
                  )
                }}
              />
            </View>
            <View style={styles.col2}>
              <Controller
                name="street"
                control={base.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                  const isRequired = isSelectedOtherClient
                  const isReadOnly = !isSelectedOtherClient || props.isConfirmed

                  if (isReadOnly) {
                    return renderReadOnlyValue(t('submission:Street'), value)
                  }
                  return (
                    <Input
                      required={isRequired}
                      title={t('submission:Street')}
                      inputStyle={{ marginTop: 6 }}
                      value={value ?? undefined}
                      maxLength={50}
                      onChange={onChange}
                      onBlur={onBlur}
                      errorMessage={error?.message}
                    />
                  )
                }}
              />
            </View>
          </View>
          {/* Row ForeignAddress, ForeignCountry */}
          <View style={styles.row}>
            <View style={styles.col2}>
              <Controller
                name="foreignAddress"
                control={base.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                  const isRequired = base.watch('foreignAddressRequired')
                  const isReadOnly = props.isConfirmed

                  if (isReadOnly) {
                    return renderReadOnlyValue(t('submission:ForeignAddress'), value)
                  }

                  return (
                    <Input
                      required={isRequired}
                      title={t('submission:ForeignAddress')}
                      placeholder={t('submission:InputAddress')}
                      inputStyle={{ marginTop: 6 }}
                      value={value ?? undefined}
                      maxLength={120}
                      onChange={onChange}
                      onBlur={onBlur}
                      errorMessage={error?.message}
                    />
                  )
                }}
              />
            </View>
            <View style={styles.col}>
              <Controller
                name="foreignCountry"
                control={base.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                  const isReadOnly = props.isConfirmed
                  if (isReadOnly) {
                    return renderReadOnlyValue(t('submission:CountryOfForeignAddress'), value?.label)
                  }
                  return (
                    <SelectSearch
                      required={base.watch('foreignAddressRequired')}
                      label={t('submission:CountryOfForeignAddress')}
                      options={data.nationOptions}
                      placeholder={t('common:Select')}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      errorMessage={error?.message}
                      popupIcon={<assets.ArrowDownDropdownIcon />}
                    />
                  )
                }}
              />
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.col}>
              <Controller
                name="taxResidencyCountry"
                control={base.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                  const isReadOnly = props.isConfirmed
                  if (isReadOnly) {
                    return renderReadOnlyValue(t('submission:TaxResidencyCountry'), value?.label)
                  }
                  return (
                    <SelectSearch
                      required={true}
                      label={t('submission:TaxResidencyCountry')}
                      options={data.nationOptions}
                      placeholder={t('common:Select')}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      maxLength={100}
                      errorMessage={error?.message}
                      popupIcon={<assets.ArrowDownDropdownIcon />}
                    />
                  )
                }}
              />
            </View>
            <View style={styles.col}>
              <Controller
                name="nationality2"
                control={base.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                  const isReadOnly = props.isConfirmed

                  if (isReadOnly) {
                    return renderReadOnlyValue(t('submission:Nationality2'), value?.label)
                  }

                  return (
                    <SelectSearch
                      label={t('submission:Nationality2')}
                      options={data.nationOptions}
                      placeholder={t('common:Select')}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      errorMessage={error?.message}
                      popupIcon={<assets.ArrowDownDropdownIcon />}
                    />
                  )
                }}
              />
            </View>
          </View>
          {/* Row LA2, LA3, LA4 */}
          <View style={styles.row}>
            {base.watch('relationShipWithMainLifeAssuredLA2')?.hasLA && (
              <View style={styles.col}>
                <Controller
                  name="relationShipWithMainLifeAssuredLA2.relationship"
                  control={base.control}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                    const relationShipWithMainLifeAssuredLA2 = base.watch('relationShipWithMainLifeAssuredLA2')
                    const isRequired = !!relationShipWithMainLifeAssuredLA2.name

                    const isReadOnly = props.isConfirmed

                    if (isReadOnly) {
                      return renderReadOnlyValue(
                        t('submission:RelationshipWithLALong', {
                          LAName: relationShipWithMainLifeAssuredLA2.name
                        }),
                        value?.label
                      )
                    }

                    return (
                      <Select
                        required={isRequired}
                        label={t('submission:RelationshipWithLALong', {
                          LAName: relationShipWithMainLifeAssuredLA2.name
                        })}
                        options={relationshipLA2}
                        placeholder={t('common:Select')}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        errorMessage={error?.message}
                      />
                    )
                  }}
                />
              </View>
            )}
            {base.watch('relationShipWithMainLifeAssuredLA3')?.hasLA && (
              <View style={styles.col}>
                <Controller
                  name="relationShipWithMainLifeAssuredLA3.relationship"
                  control={base.control}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                    const relationShipWithMainLifeAssuredLA3 = base.watch('relationShipWithMainLifeAssuredLA3')
                    const isRequired = !!relationShipWithMainLifeAssuredLA3.name
                    const isReadOnly = props.isConfirmed

                    if (isReadOnly) {
                      return renderReadOnlyValue(
                        t('submission:RelationshipWithLALong', {
                          LAName: relationShipWithMainLifeAssuredLA3.name
                        }),
                        value?.label
                      )
                    }

                    return (
                      <Select
                        required={isRequired}
                        label={t('submission:RelationshipWithLALong', {
                          LAName: relationShipWithMainLifeAssuredLA3.name
                        })}
                        options={relationshipLA3}
                        placeholder={t('common:Select')}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        errorMessage={error?.message}
                      />
                    )
                  }}
                />
              </View>
            )}
            {base.watch('relationShipWithMainLifeAssuredLA4')?.hasLA && (
              <View style={styles.col}>
                <Controller
                  name="relationShipWithMainLifeAssuredLA4.relationship"
                  control={base.control}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                    const relationShipWithMainLifeAssuredLA4 = base.watch('relationShipWithMainLifeAssuredLA4')
                    const isRequired = !!relationShipWithMainLifeAssuredLA4.name
                    const isReadOnly = props.isConfirmed

                    if (isReadOnly) {
                      return renderReadOnlyValue(
                        t('submission:RelationshipWithLALong', {
                          LAName: relationShipWithMainLifeAssuredLA4.name
                        }),
                        value?.label
                      )
                    }

                    return (
                      <Select
                        required={isRequired}
                        label={t('submission:RelationshipWithLALong', {
                          LAName: relationShipWithMainLifeAssuredLA4.name
                        })}
                        options={relationshipLA4}
                        placeholder={t('common:Select')}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        errorMessage={error?.message}
                      />
                    )
                  }}
                />
              </View>
            )}
          </View>
          <View style={styles.row}>
            <SC.Container style={{ paddingHorizontal: 16 }}>
              <SC.Padding vertical={10}>
                <SC.BoldText>
                  {t('submission:RelativeDocument')}
                  {!props.isConfirmed && <SC.ErrorText>*</SC.ErrorText>}
                </SC.BoldText>
              </SC.Padding>
              <Controller
                name={'fileAttachments'}
                control={base.control}
                render={({ field: { value, onChange } }) => {
                  const mapValue =
                    value?.map((file) => ({
                      ...file,
                      uploadedDate: file.uploadedDate!,
                      file: file.file!
                    })) || []

                  const isReadOnly = props.isConfirmed

                  if (isReadOnly) {
                    return (
                      <Row flexWrap={'wrap'} flexGrow={1}>
                        {(value as any[]).map((item) => (
                          <Column marginEnd={20}>
                            <FilePresent file={item} />
                          </Column>
                        ))}
                      </Row>
                    )
                  }

                  return (
                    <SC.PanelContainer>
                      <ImgUploadMutiple
                        value={mapValue}
                        onChange={onChange}
                        errorMessage={_.get(base.formState.errors, 'fileAttachments.message')}
                      />
                      {base.formState.errors.fileAttachments && (
                        <SC.ErrorText>{_.get(base.formState.errors, 'fileAttachments.message')}</SC.ErrorText>
                      )}
                    </SC.PanelContainer>
                  )
                }}
              />
            </SC.Container>
          </View>
        </View>
      </View>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    paddingHorizontal: 16
  },

  col: {
    width: '33.33%',
    marginBottom: 16,
    paddingHorizontal: 16
  },

  col2: {
    width: '66.66%',
    marginBottom: 16,
    paddingHorizontal: 16
  }
} as const)
