import { POApi } from '@pulseops/common'
import * as t from 'io-ts'
import { ZIO } from '@mxt/zio'
import { pipe } from 'fp-ts/function'
import { Maybe } from '@mxt/zio/codec'
import { NumberFromString } from 'io-ts-types'
export namespace ReportManagementService {
  // export const exportComplaintHandling = (params: { fromDate: string; toDate: string }) =>
  //   POApi.getConfig({ params })('data-extraction/1-0-0/api/v1/data/export-ch-field')(t.unknown)

  export const exportIncentiveReport = (params: { fromDate: string; toDate: string; author?: string }) =>
    POApi.post('data-extraction/api/v1/data/get-incentive')(t.unknown)({
      fromDate: params.fromDate,
      toDate: params.toDate,
      author: params.author
    })

  export const exportRawData = (params: { fromDate: string; toDate: string; author?: string }) =>
    POApi.post(`data-extraction/api/v1/data/get-da-conversation`)(t.unknown)({
      fromDate: params.fromDate,
      toDate: params.toDate,
      author: params.author
    })

  // export const exportFileManual = (params: { fromDate: string; toDate: string }) =>
  //   POApi.getConfig({ params })('data-extraction/1-0-0/api/v1/data/run-da-and-i-manual')(t.unknown)

  export const exportFileConservation = (params: { fromDate: string; toDate: string; author?: string }) =>
    POApi.post('data-extraction/api/v1/data/get-conversation')(t.unknown)({
      fromDate: params.fromDate,
      toDate: params.toDate,
      author: params.author
    })

  export const exportAssignment = (params: { fromDate: string; toDate: string; author?: string; timezone: string }) =>
    pipe(
      POApi.post(`data-extraction/api/v1/data/get-assignment-on-off`)(t.string)({
        fromDate: params.fromDate,
        toDate: params.toDate,
        author: params.author,
        timezone: params.timezone
      }),
      ZIO.map((res) => res)
    )

  const ListC = t.type({
    data: t.array(
      t.type({
        fromDate: t.string,
        toDate: t.string,
        filename: t.array(t.string),
        createdDate: t.string,
        createdBy: Maybe(t.string),
        folderAzure: Maybe(t.string),
        status: NumberFromString,
        author: Maybe(t.string)
      })
    )
  })
  export const getList = (params: { role: string; start: string; size?: string }) =>
    pipe(
      POApi.post('pulseops/api/v1/conservation/get-list', { params: { ...params, size: params.size ?? 10 } })(ListC)(
        {}
      ),
      ZIO.map((response) => {
        // const filenames = response.data.reduce<
        //   { createdDate: string; author: string; file: string; status: number; fromDate: string; toDate: string }[]
        // >((pre, cur) => {
        //   const _listFileMeta = (cur.filename.length > 0 ? cur.filename : ['']).map((f) => ({
        //     createdDate: cur.createdDate,
        //     author: cur.author ?? '-',
        //     file: f,
        //     status: cur.status,
        //     fromDate: cur.fromDate,
        //     toDate: cur.toDate
        //   }))
        //   return [...pre, ..._listFileMeta]
        // }, [])
        // return filenames
        return response.data
      }),
      ZIO.catchAll(() => ZIO.succeed([]))
    )
}
