import * as React from 'react'
import { ScrollView, View, Text, TouchableOpacity } from 'react-native'
import {
  LinearProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel
} from '@material-ui/core'
import {
  AuthService,
  ErrorHandling,
  InputTable,
  TaskDetailService,
  CanView,
  assets,
  StorageBlobApi
} from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'

import * as A from 'fp-ts/Array'
import { useLoading } from '@mxt/zio-react'
import { ImageDialog } from './ImageDialog'
import { SignCheckDialog } from './SignCheckDialog'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
const useStyles = makeStyles({
  tableHeader: {
    backgroundColor: '#E5EAEF',
    borderColor: '#D3DCE6'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
})

interface Data {
  source: string
  url: string
  metaData: {
    doctype: string | null | undefined
    docid: string | null | undefined
    maindoc: string | null | undefined
    subdoc: string | null | undefined
    batchno: string | null | undefined
  }
}

interface RelatedDocRequestForm {
  url: string
  docType: string
  docId: string
  mainDoc: string
  subDoc: string
  batchNum: string
  source: string
}

export const DocumentTab = (props: {
  policyNum: string
  processId: string
  isQC: boolean
  disabled?: boolean
  category?: string
  filesNamePC?: string[] | null
}) => {
  const { disabled = false } = props

  const classes = useStyles()

  const [totalData, setTotalData] = React.useState<Data[]>([])
  const [pageNum, setPageNum] = React.useState<number>(0)
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [rows, setRows] = React.useState<RelatedDocRequestForm[]>([])

  const [orderBy, setOrderBy] = React.useState<string>('source')
  const [order, setOrder] = React.useState<'asc' | 'desc'>('desc')

  const [docIdFilter, setdocIdFilter] = React.useState<string>('')
  const [docTypeFilter, setdocTypeFilter] = React.useState<string>('')
  const [mainDocFilter, setmainDocFilter] = React.useState<string>('')
  const [subDocFilter, setsubDocFilter] = React.useState<string>('')
  const [batchNumFilter, setbatchNumFilter] = React.useState<string>('')
  const [sourceFilter, setsourceFilter] = React.useState<string>('')

  const { t } = useTranslation('')

  const [loading, bindLoading] = useLoading()

  const setFilter = (field: string, value: 'asc' | 'desc') => {
    setOrderBy(field)
    setOrder(value)
  }

  const like = (a: string | null | undefined, b: string) => (a ? a.includes(b.trim()) : false)

  pipe(
    props.filesNamePC
      ? TaskDetailService.getRelatedDocPCWriteOff(
          props.processId === 'null' ? props.filesNamePC : undefined,
          props.processId === 'null' ? undefined : props.policyNum
        )
      : TaskDetailService.GetRelatedDoc(props.processId),
    ZIO.map((x) => ({
      azureDocuments: x.azureDocuments || [],
      archivedDocuments: x.archivedDocuments || []
    })),
    ZIO.map((res) => [
      ...res.azureDocuments.map((x) => {
        const docType = !!x.metaData.Doctype ? x.metaData.Doctype : x.metaData.doctype
        const docid = !!x.metaData.Docid ? x.metaData.Docid : x.metaData.docid
        const maindoc = !!x.metaData.Maindoc ? x.metaData.Maindoc : x.metaData.maindoc
        const subdoc = !!x.metaData.Subdoc ? x.metaData.Subdoc : x.metaData.subdoc
        const batchno = !!x.metaData.Batchno ? x.metaData.Batchno : x.metaData.batchno
        return {
          url: x.url,
          metaData: {
            doctype: docType,
            docid: docid,
            maindoc: maindoc,
            subdoc: subdoc,
            batchno: batchno
          },
          source: 'Azure'
        }
      }),
      ...res.archivedDocuments.map((x) => ({ ...x, source: 'Filenet' }))
    ]),
    ZIO.flatMap((res) => {
      return mapData(res)
    }),
    bindLoading,
    ErrorHandling.runDidMount()
  )

  const mapData = (res: Data[]) =>
    pipe(
      ZIO.succeed(res),
      ZIO.map(
        (x) =>
          x.filter(
            (x) =>
              (!docIdFilter || (!!docIdFilter && like(x.metaData.docid, docIdFilter))) &&
              (!docTypeFilter || (!!docTypeFilter && like(x.metaData.doctype, docTypeFilter))) &&
              (!mainDocFilter || (!!mainDocFilter && like(x.metaData.maindoc, mainDocFilter))) &&
              (!subDocFilter || (!!subDocFilter && like(x.metaData.subdoc, subDocFilter))) &&
              (!batchNumFilter || (!!batchNumFilter && like(x.metaData.batchno, batchNumFilter))) &&
              (!sourceFilter || (!!sourceFilter && like(x.source, sourceFilter)))
          ) || []
      ),
      ZIO.map((x) => {
        if (totalData.length === 0 || x.length === 0) {
          setTotalData(x)
        }
        const chunks = A.chunksOf(pageSize)(x)
        return pageNum <= chunks.length - 1 ? chunks[pageNum] : new Array<Data>()
      }),
      ZIO.map((data) => {
        return data
          ? data.map((x) => ({
              // caseId: this.caseId,
              url: x.url,
              docType: x.metaData.doctype || '-',
              docId: x.metaData.docid || 'File',
              mainDoc: x.metaData.maindoc || '-',
              subDoc: x.metaData.subdoc || '-',
              batchNum: x.metaData.batchno || '-',
              source: x.source
            }))
          : []
      }),
      ZIO.map((x) =>
        x.sort((a, b) => {
          return (
            (a[orderBy as keyof RelatedDocRequestForm] > b[orderBy as keyof RelatedDocRequestForm] ? 1 : -1) *
            (order === 'asc' ? -1 : 1)
          )
        })
      ),
      ZIO.tap((x) => {
        setRows(x)
        return ZIO.unit
      })
    )

  const updateData = () => {
    pipe(mapData(totalData), bindLoading, ErrorHandling.run())
  }

  React.useEffect(() => {
    if (totalData.length >= 0) {
      updateData()
    }
  }, [pageNum, pageSize, orderBy, order])

  const headerName = [
    { title: t('RelatedDoc:docType'), id: 'docType' },
    { title: t('RelatedDoc:docId'), id: 'docId' },
    { title: t('RelatedDoc:mainDoc'), id: 'mainDoc' },
    { title: t('RelatedDoc:subDoc'), id: 'subDoc' },
    { title: t('RelatedDoc:batchNum'), id: 'batchNum' },
    { title: t('RelatedDoc:source'), id: 'source' }
  ]

  const [openPopup, setOpen] = React.useState<boolean>(false)
  const [openSignCheck, setOpenSignCheck] = React.useState<boolean>(false)
  const [image, setImage] = React.useState<string>('')

  const handleClose = () => {
    setOpen(false)
  }

  const handleCloseSignCheck = () => {
    setOpenSignCheck(false)
  }

  const openImage = (image: string) => {
    setImage(image)
    setOpen(true)
  }

  const downloadURI = (uri: string, name: string) => {
    const link = document.createElement('a')
    link.download = name
    link.target = '_blank'
    link.href = uri
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
  const getLink = (url: string, source: string) => {
    source !== 'Azure'
      ? pipe(
          ZIO.zipPar(AuthService.token, AuthService.getLoginType),
          ZIO.flatMap(([token, loginType]) => {
            return ZIO.fromPromise(() =>
              fetch(url, {
                method: 'GET',
                headers: {
                  Authorization: `Bearer ${token}`,
                  'X-Authen-Vendor': loginType
                }
              })
            )
          }),
          ZIO.flatMap((res) =>
            ZIO.zipPar(
              ZIO.succeed(res),
              ZIO.fromPromise(() => res.blob())
            )
          ),
          ZIO.map(([res, blob]) => {
            const type = res.headers.get('content-type') || ''
            if (['image/png', '	image/jpg', 'image/jpeg'].includes(type)) {
              const fileReaderInstance = new FileReader()
              fileReaderInstance.readAsDataURL(blob)
              fileReaderInstance.onload = () => {
                openImage(fileReaderInstance.result as string)
              }
            } else {
              downloadURI(window.URL.createObjectURL(blob), `file.${type.split('/')[1]}`)
            }
          }),
          ErrorHandling.run()
        )
      : pipe(
          ZIO.zipPar(
            StorageBlobApi.getRemoteConfig(props?.category ?? '', props.policyNum),
            AuthService.token,
            AuthService.getLoginType
          ),
          ZIO.flatMap(([storageConfig, token, loginType]) => {
            return pipe(
              ZIO.fromPromise(() => {
                const apiUrl = url.split('?')
                const storageUrl = apiUrl[0]
                const paramInfo = apiUrl[1]
                const paramList = paramInfo.split('&')
                const containerName = !!paramList[0] ? paramList[0].split('=')[1] : ''
                const blobName = !!paramList[1] ? paramList[1].split('=')[1] : ''
                return fetch(storageUrl, {
                  method: 'GET',
                  headers: {
                    'Cache-Control': 'no-cache',
                    Pragma: 'no-cache',
                    Container: _.isNull(props.filesNamePC) || props.filesNamePC ? 'premiumcollection' : containerName,
                    Token: storageConfig.sas,
                    Blob: blobName,
                    Authorization: `Bearer ${token}`,
                    'X-Authen-Vendor': loginType
                  }
                })
              })
            )
          }),
          ZIO.flatMap((res) =>
            ZIO.zipPar(
              ZIO.succeed(res),
              ZIO.fromPromise(() => res.blob())
            )
          ),
          ZIO.tap(([res, blob]) => {
            const type = res.headers.get('content-type') || ''
            downloadURI(window.URL.createObjectURL(blob), `file.${type.split('/')[1]}`)
            return ZIO.unit
          }),
          bindLoading,
          ErrorHandling.run()
        )
  }

  return (
    <ScrollView>
      <View>
        <CanView condition={!props.isQC && props.filesNamePC ? false : true}>
          <TouchableOpacity
            style={{ flexDirection: 'row', alignItems: 'center', padding: 10 }}
            onPress={() => {
              setOpenSignCheck(true)
            }}
          >
            <assets.RedEye />
            <Text style={{ color: '#ed1b2e', marginLeft: 10 }}>{t('SignCheck:btn')}</Text>
          </TouchableOpacity>
        </CanView>
        {loading && <LinearProgress color="secondary" />}
        <TableContainer component={Paper}>
          <Table>
            <TableHead className={classes.tableHeader}>
              <TableRow>
                {headerName.map((header) => (
                  <TableCell key={header.id} align={'left'} sortDirection={orderBy === header.id ? order : false}>
                    <TableSortLabel
                      active={orderBy === header.id}
                      direction={orderBy === header.id ? order : 'asc'}
                      onClick={() => setFilter(header.id, order === 'desc' ? 'asc' : 'desc')}
                    >
                      {header.title}
                      {orderBy === header.id ? (
                        <span className={classes.visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell>
                  <InputTable disabled={disabled} onChange={(val) => setdocTypeFilter(val)}></InputTable>
                </TableCell>
                <TableCell>
                  <InputTable disabled={disabled} onChange={(val) => setdocIdFilter(val)}></InputTable>
                </TableCell>
                <TableCell>
                  <InputTable disabled={disabled} onChange={(val) => setmainDocFilter(val)}></InputTable>
                </TableCell>
                <TableCell>
                  <InputTable disabled={disabled} onChange={(val) => setsubDocFilter(val)}></InputTable>
                </TableCell>
                <TableCell>
                  <InputTable disabled={disabled} onChange={(val) => setbatchNumFilter(val)}></InputTable>
                </TableCell>
                <TableCell>
                  <InputTable disabled={disabled} onChange={(val) => setsourceFilter(val)}></InputTable>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => (
                <TableRow key={`doc-row-${i}`}>
                  <TableCell component="th" scope="row">
                    {row.docType}
                  </TableCell>

                  <TableCell>
                    <Text
                      style={{ color: row.source === 'Azure' ? '#1EA5FC' : '#ED1B2E' }}
                      onPress={() => {
                        getLink(row.url, row.source)
                      }}
                    >
                      {row.docId}
                    </Text>
                  </TableCell>
                  <TableCell>{row.mainDoc}</TableCell>
                  <TableCell>{row.subDoc}</TableCell>
                  <TableCell>{row.batchNum}</TableCell>
                  <TableCell>{row.source}</TableCell>
                </TableRow>
              ))}
              <TableRow></TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          page={pageNum}
          rowsPerPage={pageSize}
          count={totalData.length}
          onPageChange={(e, page) => {
            setPageNum(page)
          }}
          component={View}
          onRowsPerPageChange={(e) => {
            setPageSize(Number(e.target.value))
            setPageNum(0)
          }}
          labelRowsPerPage={t('common:PaginationSize')}
          labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
        />
      </View>
      <ImageDialog selectedValue={image} open={openPopup} onClose={handleClose}></ImageDialog>
      <SignCheckDialog
        open={openSignCheck}
        onClose={handleCloseSignCheck}
        processInstanceId={props.processId}
        policyNum={props.policyNum}
        onDocReviewed={() => {}}
        isRequestForm={false}
        docReviewed={false}
      ></SignCheckDialog>
    </ScrollView>
  )
}
