import { Color } from '@material-ui/lab'
import { ZIO } from '@mxt/zio'
import {
  Alert,
  GeneralService,
  PulseOpsService,
  QCService,
  QCTaskList,
  RequestAuthOption,
  ShowToastFunc,
  SubmissionService,
  SubTasksResponse,
  TaskDetail,
  TASK_DETAIL_ROLES,
  TransactionType,
  TaskType,
  TaskService,
  ProductEnum
} from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import { TFunction } from 'i18next'
import { UseFormTrigger } from 'react-hook-form'
import { Mistake, MistakeAdju } from '../data'
import { InquiryComplaintForm } from '../request-info'
import { TaskDetailState } from '../state'
import { VoiceDataGridForm } from '../container/voice-recording'

export function isEmptyObject(value: Object) {
  return Object.keys(value).length === 0 && value.constructor === Object
}

export const checkRolesForButton = (subTaskCode: string, roles: string[], isQc: boolean) => (buttonType: string) => {
  switch (buttonType) {
    case 'Complete': {
      if (['PS'].includes(subTaskCode) && roles.includes(TASK_DETAIL_ROLES.COMPLETE_PS)) {
        return true
      } else if (['DS'].includes(subTaskCode) && roles.includes(TASK_DETAIL_ROLES.COMPLETE_DS)) {
        return true
      } else if (['CH'].includes(subTaskCode) && roles.includes(TASK_DETAIL_ROLES.COMPLETE_CH)) {
        return true
      } else if (['CS'].includes(subTaskCode) && roles.includes(TASK_DETAIL_ROLES.COMPLETE_CS)) {
        return true
      } else if (['CL'].includes(subTaskCode) && roles.includes(TASK_DETAIL_ROLES.COMPLETE_CL)) {
        return true
      } else if (['PC'].includes(subTaskCode) && roles.includes(TASK_DETAIL_ROLES.COMPLETE_PC)) {
        return true
      } else if (['IDC'].includes(subTaskCode) && roles.includes(TASK_DETAIL_ROLES.COMPLETE_IDC)) {
        return true
      } else {
        return false
      }
    }
    case 'Transfer': {
      if (subTaskCode === 'PS' && roles.includes(TASK_DETAIL_ROLES.TRANSFER_PS)) {
        return true
      } else if (subTaskCode === 'DS' && roles.includes(TASK_DETAIL_ROLES.TRANSFER_DS)) {
        return true
      } else if (['CH'].includes(subTaskCode) && roles.includes(TASK_DETAIL_ROLES.TRANSFER_CH)) {
        return true
      } else if (['CS'].includes(subTaskCode) && roles.includes(TASK_DETAIL_ROLES.TRANSFER_CS)) {
        return true
      } else if (subTaskCode === 'CL' && roles.includes(TASK_DETAIL_ROLES.TRANSFER_CL)) {
        return true
      } else if (['PC'].includes(subTaskCode) && roles.includes(TASK_DETAIL_ROLES.TRANSFER_PC)) {
        return true
      } else if (['IDC'].includes(subTaskCode) && roles.includes(TASK_DETAIL_ROLES.TRANSFER_IDC)) {
        return true
      } else {
        return false
      }
    }
    case 'Suspend': {
      if (subTaskCode === 'PS' && roles.includes(TASK_DETAIL_ROLES.SUSPEND_PS)) {
        return true
      } else if (['DS'].includes(subTaskCode) && roles.includes(TASK_DETAIL_ROLES.SUSPEND_DS)) {
        return true
      } else if (['CH'].includes(subTaskCode) && roles.includes(TASK_DETAIL_ROLES.SUSPEND_CH)) {
        return true
      } else if (['CS'].includes(subTaskCode) && roles.includes(TASK_DETAIL_ROLES.SUSPEND_CS)) {
        return true
      } else if (['CL'].includes(subTaskCode) && roles.includes(TASK_DETAIL_ROLES.SUSPEND_CL)) {
        return true
      } else if (['PC'].includes(subTaskCode) && roles.includes(TASK_DETAIL_ROLES.SUSPEND_PC)) {
        return true
      } else if (['IDC'].includes(subTaskCode) && roles.includes(TASK_DETAIL_ROLES.SUSPEND_IDC)) {
        return true
      } else {
        return false
      }
    }
    case 'Reject': {
      if (subTaskCode === 'PS' && roles.includes(TASK_DETAIL_ROLES.REJECT_PS)) {
        return true
      } else if (subTaskCode === 'DS' && roles.includes(TASK_DETAIL_ROLES.REJECT_DS)) {
        return true
      } else if (['CH'].includes(subTaskCode) && roles.includes(TASK_DETAIL_ROLES.REJECT_CH)) {
        return true
      }
      // else if (['CS'].includes(subTaskCode) && roles.includes(TASK_DETAIL_ROLES.REJECT_CS)) {
      //   return true
      // }
      else if (subTaskCode === 'CL' && roles.includes(TASK_DETAIL_ROLES.REJECT_CL)) {
        return true
      } else if (isQc && ['PC'].includes(subTaskCode)) {
        // hide this button for QC Screen
        return false
      } else if (['PC'].includes(subTaskCode) && roles.includes(TASK_DETAIL_ROLES.REJECT_PC)) {
        return true
      } else if (subTaskCode === 'IDC' && roles.includes(TASK_DETAIL_ROLES.REJECT_IDC)) {
        return true
      } else {
        return false
      }
    }
    case 'End': {
      if (subTaskCode === 'PS' && roles.includes(TASK_DETAIL_ROLES.END_PS)) {
        return true
      } else if (['CH'].includes(subTaskCode) && roles.includes(TASK_DETAIL_ROLES.END_CH)) {
        return true
      }
      // else if (['CS'].includes(subTaskCode) && roles.includes(TASK_DETAIL_ROLES.END_CS)) {
      //   return true
      // }
      else if (['CL'].includes(subTaskCode) && roles.includes(TASK_DETAIL_ROLES.END_CL)) {
        return true
      } else if (isQc && ['PC'].includes(subTaskCode)) {
        // hide this button for QC Screen
        return false
      } else if (['PC'].includes(subTaskCode) && roles.includes(TASK_DETAIL_ROLES.END_PC)) {
        return true
      } else if (['DS'].includes(subTaskCode) && roles.includes(TASK_DETAIL_ROLES.END_DS)) {
        return true
      } else {
        return false
      }
    }
    case 'Accept': {
      //Accept = Complete in QC Veri page
      if (isQc && subTaskCode === 'PS' && roles.includes(TASK_DETAIL_ROLES.ACCEPT_PS)) {
        return true
      }
      else if (isQc && ['CH'].includes(subTaskCode) && roles.includes(TASK_DETAIL_ROLES.ACCEPT_CH)) {
        return true
      }
      else if (isQc && ['CS'].includes(subTaskCode) && roles.includes(TASK_DETAIL_ROLES.ACCEPT_CS)) {
        return true
      }

      else if (isQc && ['PC'].includes(subTaskCode) && roles.includes(TASK_DETAIL_ROLES.ACCEPT_PC)) {
        return true
      }
      else if (isQc && ['DS'].includes(subTaskCode) && roles.includes(TASK_DETAIL_ROLES.ACCEPT_DS)) {
        return true
      }
      else if (isQc && ['IDC'].includes(subTaskCode) && roles.includes(TASK_DETAIL_ROLES.ACCEPT_IDC)) {
        return true
      } else {
        return false
      }
    }
    case 'Decline': {
      //Decline = Transfer in QC Veri page
      if (isQc && subTaskCode === 'PS' && roles.includes(TASK_DETAIL_ROLES.DECLINE_PS)) {
        return true
      }
      else if (isQc && ['DS'].includes(subTaskCode) && roles.includes(TASK_DETAIL_ROLES.DECLINE_DS)) {
        return true
      }
      else if (isQc && ['CH'].includes(subTaskCode) && roles.includes(TASK_DETAIL_ROLES.DECLINE_CH)) {
        return true
      } else if (isQc && ['CS'].includes(subTaskCode) && roles.includes(TASK_DETAIL_ROLES.DECLINE_CS)) {
        return true
      } else if (isQc && ['PC'].includes(subTaskCode) && roles.includes(TASK_DETAIL_ROLES.DECLINE_PC)) {
        return true
      } else if (isQc && ['IDC'].includes(subTaskCode) && roles.includes(TASK_DETAIL_ROLES.DECLINE_IDC)) {
        return true
      } else {
        return false
      }
    }
    default:
      return false
  }
}
export const checkRolesForButtonAgent = (subTaskCode: string, roles: string[]) => {
  if (['DS'].includes(subTaskCode) && roles.includes(TASK_DETAIL_ROLES.END_DS)) {
    return true
  } else {
    return false
  }
}
export const onEndAction =
  (showToast: ShowToastFunc = () => { }, t: (val: string) => string) =>
    (detail?: TaskDetail.Type, isSubmitInquiry: boolean = true) =>
      (setEndPopupOpen: (val: boolean) => void, setIsNotCheckValidator: (val: boolean) => void) =>
        () => {
          let canGoCompleteAction = true
          let isInQuiry: boolean = false

          switch (detail?.payload?.tag) {
            // case TransactionType.AGENT_TERMINATION:
            case TransactionType.INQUIRY:
            case TransactionType.COMPLAINT:
            case TransactionType.COMPLAINT_AND_INQUIRY:
              canGoCompleteAction = isSubmitInquiry
              isInQuiry = true
              setIsNotCheckValidator(true)
              if (!canGoCompleteAction) {
                // showToast(t('message:SaveBeforeComplete'), 'error')
                showToast(t('message:MS100001'), 'error')
              }
              break
            default:
              break
          }
          if (isInQuiry) {
            if (canGoCompleteAction) {
              setEndPopupOpen(true)
            }
          } else {
            setEndPopupOpen(true)
          }
        }

export const onEndActionHandleClose =
  (setToast: (val: { open: boolean; message: string; type?: Color }) => void) => (done: boolean) => {
    done &&
      setToast({
        open: true,
        message: 'End Successfully !',
        type: 'success'
      })
  }

export const onCompleteAction =
  (showLoading: (val: boolean) => void, showToast: ShowToastFunc, t: (val: string) => string) =>
    (
      subtask: SubTasksResponse,
      subTaskCode: string,
      detail: TaskDetail.Type,
      isSubmitInquiry: boolean = true,
      trigger?: UseFormTrigger<InquiryComplaintForm>,
      isValid: boolean = false,
      dynamicKeywordData?: Array<VoiceDataGridForm.DynamicKeywordData>
    ) =>
      (
        setCompleteOpen: (val: boolean) => void,
        setIsNotCheckValidator: (val: boolean) => void,
        showErrorMessage?: () => boolean
      ) =>
        () => {
          let canGoCompleteAction = true
          const { authenOption, processId, policyNumber } = detail

          const isNotAuthPaper =
            subtask.includes('-QC')
              ? true
              : subTaskCode !== 'PS'
                ? true
                : authenOption === RequestAuthOption.PAPER
                  ? false
                  : true
          const { payload } = detail
          showLoading(true)
          pipe(
            isNotAuthPaper ? ZIO.succeed(true) : GeneralService.authFormUserCheckFlag(processId),
            ZIO.catchAll((_error) => {
              showLoading(false)
              return ZIO.fail(null)
            }),
            ZIO.flatMap((x) => {
              showLoading(false)
              if (!x) {
                showToast(t('message:MS050261'), 'error')
                return ZIO.succeed(false)
              } else {
                let isInQuiry: boolean = false
                if (!QCTaskList.includes(subtask)) {
                  switch (payload.tag) {
                    case TransactionType.BILLING_CHANGE:
                      canGoCompleteAction = payload.requirePayinAmount !== null && payload.requirePayinAmount <= 0
                      if (!canGoCompleteAction) {
                        showToast('Not enough amount to cover request', 'error')
                      }
                      break
                    case TransactionType.REINSTATEMENT:
                      if ((payload.totalReinsAmount ?? 0) > 0) {
                        showToast(t('message:MS990002'), 'warning')
                      }
                      break
                    case TransactionType.FULL_SURRENDER_REVERSAL:
                      const REINSTATEMENT_FEE = payload.submissionData.attributesExt.REINSTATEMENT_FEE
                      return pipe(
                        SubmissionService.getAccountBalance(policyNumber, 'TOTAL', [{ sacsCode: 'LP', sacsType: 'S' }]),
                        ZIO.foldM(
                          (error) => ZIO.succeed(false),
                          (accountResponse) => {
                            const requirePayinAmount = Number(REINSTATEMENT_FEE) ?? 0
                            const accountBalance =
                              accountResponse.body.accountGroups?.reduce((total, item) => total + Number(item.amount), 0) ?? 0
                            const isInvalid = requirePayinAmount > accountBalance
                            if (isInvalid) {
                              showToast(t('message:MS030034'), 'error')
                              return ZIO.succeed(false)
                            }
                            if (Number(REINSTATEMENT_FEE) > 0) {
                              showToast(t('message:MS990002'), 'warning')
                            }
                            return ZIO.succeed(true)
                          }
                        )
                      )
                    case TransactionType.BILLING_FREQUENCY_REVERSAL: {
                      return pipe(
                        SubmissionService.getAccountBalance(policyNumber, 'TOTAL', [{ sacsCode: 'LP', sacsType: 'S' }]),
                        ZIO.foldM(
                          (error) => ZIO.succeed(false),
                          (accountResponse) => {
                            const requirePayinAmount = payload.requirePayinAmount ?? 0
                            const accountBalance =
                              accountResponse.body.accountGroups?.reduce((total, item) => total + Number(item.amount), 0) ?? 0
                            const isInvalid = requirePayinAmount > accountBalance
                            if (isInvalid) {
                              showToast(t('message:MS030034'), 'error')
                            }
                            return ZIO.succeed(!isInvalid)
                          }
                        )
                      )
                    }
                    case TransactionType.INQUIRY:
                      isInQuiry = true
                      setIsNotCheckValidator(false)
                      if (!isValid) {
                        showToast(t('message:MS100007'), 'error')
                        canGoCompleteAction = isValid
                      } else if (!isSubmitInquiry) {
                        canGoCompleteAction = isSubmitInquiry
                        showToast(t('message:MS100006'), 'error')
                      } else {
                        canGoCompleteAction = true
                      }
                      break
                    case TransactionType.COMPLAINT:
                    case TransactionType.COMPLAINT_AND_INQUIRY:
                      isInQuiry = true
                      // canGoCompleteAction = isSubmitInquiry
                      setIsNotCheckValidator(false)
                      if (!isValid) {
                        showToast(t('message:MS100002'), 'error')
                        canGoCompleteAction = isValid
                      } else if (!isSubmitInquiry) {
                        canGoCompleteAction = isSubmitInquiry
                        showToast(t('message:MS100006'), 'error')
                      } else {
                        canGoCompleteAction = true
                      }
                      break
                    case TransactionType.CASHLESS_O1:
                    case TransactionType.CASHLESS_O2:
                    case TransactionType.CASHLESS_O3:
                    case TransactionType.CASHLESS_DK:
                    case TransactionType.CASHLESS_C9:
                    case TransactionType.CASHLESS_A7:
                    case TransactionType.CASHLESS_S1:
                    case TransactionType.CASHLESS_P3:
                    case TransactionType.CASHLESS_S2:
                    case TransactionType.CASHLESS_B3:
                    case TransactionType.CASHLESS_S3:
                    case TransactionType.CASHLESS_C3:
                    case TransactionType.CASHLESS_U3:
                    case TransactionType.CASHLESS_V3:
                    case TransactionType.CASHLESS_I3:
                    case TransactionType.CASHLESS_C7:
                    case TransactionType.CASHLESS_X1:
                    case TransactionType.CASHLESS_T3:
                    case TransactionType.CASHLESS_V2:
                    case TransactionType.CASHLESS_M2:
                    case TransactionType.CASHLESS_A9:
                    case TransactionType.CASHLESS_S6:
                    case TransactionType.CASHLESS_L3:
                    case TransactionType.CASHLESS_D7:
                    case TransactionType.CASHLESS_I4:
                    case TransactionType.CASHLESS_P2:
                    case TransactionType.CASHLESS_M3:
                    case TransactionType.CASHLESS_V4:
                    case TransactionType.CASHLESS_A0:
                    case TransactionType.CASHLESS_P9:
                    case TransactionType.CASHLESS_M9:
                    case TransactionType.CASHLESS_D6:
                    case TransactionType.CASHLESS_D8:
                    case TransactionType.CASHLESS_D9:
                    case TransactionType.CASHLESS_I2:
                    case TransactionType.CASHLESS_V5: {
                      return pipe(
                        TaskDetailState.formValid.get,
                        // ZIO.mapFoldOptionM(
                        //   () => ZIO.succeed(false),
                        //   (valid) => {
                        //     return pipe(
                        //       TaskDetailState.errorMessage.getOption,
                        //       ZIO.mapFoldOptionM(
                        //         () => {
                        //           if (!valid) {
                        //             Alert.snackbar('Unknow error', '', 'error')
                        //           }
                        //           return ZIO.succeed(valid)
                        //         },
                        //         (message) => {
                        //           if (!valid) {
                        //             Alert.snackbar(message, '', 'error')

                        //           }

                        //           return ZIO.succeed(valid)
                        //         }
                        //       )
                        //     )
                        //   }
                        // )
                        ZIO.flatMap((valid) => {
                          return pipe(
                            TaskDetailState.errorMessage.getOption,
                            ZIO.mapFoldOptionM(
                              () => {
                                if (!valid) {
                                  Alert.snackbar('Unknow error', '', 'error')
                                }
                                return ZIO.succeed(valid)
                              },
                              (message) => {
                                if (!valid) {
                                  Alert.snackbar(message, '', 'error')
                                }
                                return ZIO.succeed(valid)
                              }
                            )
                          )
                        })
                      )
                    }
                    case TransactionType.AGENT_ONBOARD_NEW:
                    case TransactionType.AGENT_ONBOARD_REINS:
                      return pipe(
                        TaskDetailState.formValid.get,
                        ZIO.flatMap((valid) => {
                          return pipe(
                            TaskDetailState.errorMessage.getOption,
                            ZIO.mapFoldOptionM(
                              () => {
                                if (!valid) {
                                  Alert.snackbar('Unknow error', '', 'error')
                                }
                                return ZIO.succeed(valid)
                              },
                              (message) => {
                                if (!valid) {
                                  Alert.snackbar(message, '', 'error')
                                }
                                return ZIO.succeed(valid)
                              }
                            )
                          )
                        })
                      )
                    case TransactionType.NEW_CLAIM_REGISTER:
                      const flag = showErrorMessage && showErrorMessage()
                      if (!flag && flag !== undefined) {
                        showToast(t('message:MS070031'), 'error')
                        canGoCompleteAction = false
                        break
                      }
                      canGoCompleteAction = true
                      break
                    case TransactionType.LOAN:
                      {
                        return pipe(
                          SubmissionService.checkLoanNumber99And00ForPolicy(policyNumber),
                          ZIO.foldM(
                            (err) => ZIO.succeed(false),
                            (responseData) => {
                              const filterLoanList = responseData.loanEnquiry.filter(
                                (x) =>
                                  (x.loanType === 'A' && (x.loanNumber === 0 || x.loanNumber === 99)) ||
                                  (x.loanType === 'P' && (x.loanNumber === 0 || x.loanNumber === 99))
                              )
                              if (filterLoanList && filterLoanList.length >= 2) {
                                showToast(t('message:MS050275'), 'error')
                                return ZIO.succeed(false)
                              } else {
                                return ZIO.succeed(true)
                              }
                            }
                          )
                        )
                      }
                    case TransactionType.VOICE_RECORDING:
                      {
                        return pipe(
                          ZIO.effect(() => {
                            let isValidData: boolean = true
                            if (dynamicKeywordData && dynamicKeywordData.length > 0) {
                              if (dynamicKeywordData.some((x) => x.isChangedData)) {
                                showToast(t('message:MS020063'), 'error')
                                isValidData = false
                              } else if (dynamicKeywordData.some((x) => x.result?.value !== 'pass')) {
                                showToast(t('message:MS020062'), 'error')
                                isValidData = false
                              }
                            }
                            return isValidData
                          }),
                          ZIO.flatMap((isValidflag) => isValidflag ?
                            pipe(
                              SubmissionService.getPolicy(policyNumber),
                              ZIO.foldM(
                                () => ZIO.succeed(false),
                                (policyInfo) => {
                                  if (policyInfo.body.status !== ProductEnum.Status.PS) {
                                    showToast(t('message:MS020059'), 'error')
                                    return ZIO.succeed(false)
                                  } else {
                                    return ZIO.succeed(true)
                                  }
                                }
                              )
                            )
                            : ZIO.succeed(isValidflag)
                          )
                        )
                      }
                    default:
                      break
                  }
                } else if (subtask.includes(SubTasksResponse.IDC_QC)) {
                  switch (payload.tag) {
                    case TransactionType.VOICE_RECORDING:
                      {
                        return pipe(
                          SubmissionService.getPolicy(policyNumber),
                          ZIO.foldM(
                            () => ZIO.succeed(false),
                            (policyInfo) => {
                              if (policyInfo.body.status !== ProductEnum.Status.PS) {
                                showToast(t('message:MS020059'), 'error')
                                return ZIO.succeed(false)
                              } else {
                                return ZIO.succeed(true)
                              }
                            }
                          )
                        )
                      }
                    default:
                      break
                  }
                }

                if (isInQuiry) {
                  if (canGoCompleteAction && isValid) {
                    //setCompleteOpen(true)
                    return ZIO.succeed(true)
                  }
                } else {
                  if (canGoCompleteAction) {
                    //setCompleteOpen(true)
                    return ZIO.succeed(true)
                  }
                }

                return ZIO.succeed(false)
              }
              //return ZIO.unit
            }),
            ZIO.tap((canOpen) => {
              if (canOpen) {
                setCompleteOpen(true)
              }
              return ZIO.unit
            }),
            ZIO.unsafeRun({})
          )
        }

export const onCompleteActionHandleClose =
  (setToast: (val: { open: boolean; message: string; type?: Color }) => void) => (done: boolean) => {
    done &&
      setToast({
        open: true,
        message: 'Completed Successfully',
        type: 'success'
      })
  }

export const onTransferAction =
  (showToast: ShowToastFunc = () => { }, t: (val: string) => string) =>
    (detail?: TaskDetail.Type, isSubmitInquiry: boolean = true) =>
      (setPopupOpen: (val: boolean) => void, setIsNotCheckValidator: (val: boolean) => void) =>
        () => {
          let canGoCompleteAction = true
          let isInQuiry: boolean = false

          switch (detail?.payload?.tag) {
            case TransactionType.INQUIRY:
            case TransactionType.COMPLAINT:
            case TransactionType.COMPLAINT_AND_INQUIRY:
              isInQuiry = true
              setIsNotCheckValidator(true)
              canGoCompleteAction = isSubmitInquiry
              if (!canGoCompleteAction) {
                showToast(t('message:SaveBeforeComplete'), 'error')
              }
              break
            default:
              break
          }
          if (isInQuiry) {
            if (canGoCompleteAction) {
              setPopupOpen(true)
            }
          } else {
            setPopupOpen(true)
          }
        }

export const onTransferActionHandleClose =
  (setToast: (val: { open: boolean; message: string; type?: Color }) => void) => (done: boolean) => {
    done &&
      setToast({
        open: true,
        message: 'Transfer Successfully !',
        type: 'success'
      })
  }

export const onSuspendAction =
  (showToast: ShowToastFunc = () => { }, t: (val: string) => string) =>
    (detail?: TaskDetail.Type, isSubmitInquiry: boolean = true) =>
      (setPopupOpen: (val: boolean) => void, setIsNotCheckValidator?: (val: boolean) => void) =>
        () => {
          let canGoCompleteAction = true
          let isInQuiry: boolean = false
          switch (detail?.payload?.tag) {
            case TransactionType.INQUIRY:
            case TransactionType.COMPLAINT:
            case TransactionType.COMPLAINT_AND_INQUIRY:
              isInQuiry = true
              if (setIsNotCheckValidator) setIsNotCheckValidator(true)
              canGoCompleteAction = isSubmitInquiry
              if (!canGoCompleteAction) {
                showToast(t('message:SaveBeforeComplete'), 'error')
              }
              break
            default:
              break
          }
          console.log('isInQuiry', isInQuiry)
          if (isInQuiry) {
            if (canGoCompleteAction) {
              setPopupOpen(true)
            }
          } else {
            setPopupOpen(true)
          }
        }

export const onSuspendActionHandleClose =
  (setToast: (val: { open: boolean; message: string; type?: Color }) => void) => (done: boolean) => {
    done &&
      setToast({
        open: true,
        message: 'Suspend Successfully !',
        type: 'success'
      })
  }

export const onUnsuspendAction = (setPopupOpen: (val: boolean) => void) => () => {
  setPopupOpen(true)
}

export const onUnsuspendActionHandleClose =
  (setToast: (val: { open: boolean; message: string; type?: Color }) => void) => (done: boolean) => {
    done &&
      setToast({
        open: true,
        message: 'Unsuspend Successfully !',
        type: 'success'
      })
  }

export const onRejectAction =
  (showToast: ShowToastFunc = () => { }, t: (val: string) => string) =>
    (detail?: TaskDetail.Type, isSubmitInquiry: boolean = true, dynamicKeywordData?: Array<VoiceDataGridForm.DynamicKeywordData>) =>
      (
        setPopupOpen: (val: boolean) => void,
        setIsNotCheckValidator: (val: boolean) => void,
        trigger?: UseFormTrigger<InquiryComplaintForm>,
        isValid: boolean = true
      ) =>
        () => {
          let isInQuiry: boolean = false
          let canGoCompleteAction = true
          switch (detail?.payload?.tag) {
            case TransactionType.INQUIRY:
            case TransactionType.COMPLAINT:
            case TransactionType.COMPLAINT_AND_INQUIRY:
              isInQuiry = true
              // if (trigger) trigger()
              // canGoCompleteAction = isSubmitInquiry
              setIsNotCheckValidator(false)
              if (!isValid) {
                canGoCompleteAction = isValid
                showToast(t('message:ValidForm'), 'error')
              } else if (!isSubmitInquiry) {
                canGoCompleteAction = isSubmitInquiry
                showToast(t('message:MS100001'), 'error')
              } else {
                canGoCompleteAction = true
              }
              if (canGoCompleteAction && isValid) {
                setPopupOpen(true)
              }
              break
            case TransactionType.VOICE_RECORDING:
              let isValidData: boolean = true
              // console.log('dynamicKeywordData:',JSON.stringify(dynamicKeywordData))
              if (dynamicKeywordData && dynamicKeywordData.length > 0) {
                if (dynamicKeywordData.some((x) => x.isChangedData)) {
                  showToast(t('message:MS020063'), 'error')
                  isValidData = false
                } else if (!dynamicKeywordData.some((x) => x.result?.value !== 'pass')) {
                  showToast(t('message:MS020062'), 'error')
                  isValidData = false
                }
              }
              if (isValidData) {
                setPopupOpen(true)
              }
              break
            default:
              setPopupOpen(true)
              break
          }
          // if (isInQuiry) {
          //   if (canGoCompleteAction && isValid) {
          //     setPopupOpen(true)
          //   }
          // } else {
          //   setPopupOpen(true)
          // }
        }

export const onRejectActionHandleClose =
  (setToast: (val: { open: boolean; message: string; type?: Color }) => void) => (done: boolean) => {
    done &&
      setToast({
        open: true,
        message: 'Reject Successfully !',
        type: 'success'
      })
  }

export const onRecheckAction =
  (showGlobalLoading: (isloading: boolean) => void, showToast: ShowToastFunc, t: TFunction) =>
    (
      task: TaskDetail.Type,
      mistakes: Mistake[] | MistakeAdju[],
      category?: string,
      isUWQC?: boolean,
      uwResult?: string
    ) =>
      (onSuccess: (mustRefreshTaskGroup?: boolean) => void) =>
        () => {
          if (task && task.id) {
            showGlobalLoading(true)
            if (task.category === TaskType.Claim) {
              pipe(
                GeneralService.getHistoryQCScore(task.caseId, 'Administrator'),
                ZIO.catchAll((_error) => {
                  showGlobalLoading(false)
                  return ZIO.fail(null)
                }),
                ZIO.flatMap((res) => {
                  const isNull = res.qcScoreDetailTopics?.every((x) => !x.qcCheck)
                  return isNull
                    ? (() => {
                      showGlobalLoading(false)
                      const hasQCCheckedButNotSave = mistakes.some((x) => x.qcCheck)
                      if (hasQCCheckedButNotSave) {
                        showToast(t('message:MS990003', { tab: t('Tab:QC'), action: t('claim:Transfer') }), 'error')
                      } else {
                        showToast(t('message:MS990021'), 'error')
                      }
                      return ZIO.succeed(false)
                    })()
                    : pipe(
                      PulseOpsService.reCheckTask(task?.id),
                      ZIO.catchAll((_error) => {
                        showGlobalLoading(false)
                        showToast(t('requestInfo:TransferFailed'), 'error')
                        return ZIO.fail(null)
                      }),
                      ZIO.map((_) => {
                        showToast(t('requestInfo:TransferSuccess'), 'success')
                        showGlobalLoading(false)
                        onSuccess()
                        return true
                      })
                    )
                }),
                ZIO.unsafeRun({})
              )
            } else {
              pipe(
                isUWQC ? QCService.getQCUWSave(task.processId) : QCService.getQCSave(task.processId),
                ZIO.catchAll((_error) => {
                  showGlobalLoading(false)
                  return ZIO.fail(null)
                }),
                ZIO.flatMap((res) => {
                  const isNull = res.detailList?.every((x) => !x.qcCheck) && task.category !== 'PC' // temporary bypass this check for PC-QC, use below line instead this line after remark QC done
                  return isNull
                    ? (() => {
                      showGlobalLoading(false)
                      const hasQCCheckedButNotSave = mistakes.some((x) => x.qcCheck)
                      if (hasQCCheckedButNotSave) {
                        showToast(t('message:MS990003', { tab: t('Tab:QC'), action: t('claim:Transfer') }), 'error')
                      } else {
                        showToast(t('message:MS990021'), 'error')
                      }
                      return ZIO.succeed(false)
                    })()
                    : pipe(
                      PulseOpsService.reCheckTask(task?.id, isUWQC ?? false, uwResult,
                        task.category === TaskType.IDC ? 'recheck' : undefined,
                        task.category === TaskType.IDC ? TaskType.IDC : undefined
                      ),
                      ZIO.catchAll((_error) => {
                        showGlobalLoading(false)
                        showToast(t('requestInfo:TransferFailed'), 'error')
                        return ZIO.fail(null)
                      }),
                      ZIO.map((_) => {
                        showToast(t('requestInfo:TransferSuccess'), 'success')
                        showGlobalLoading(false)
                        onSuccess()
                        return true
                      })
                    )
                }),
                ZIO.unsafeRun({})
              )
            }
          }
        }

// export const onManualTransfertActionForQC =
//   (showGlobalLoading: (isloading: boolean) => void, showToast: ShowToastFunc, t: TFunction) =>
//     (task: TaskDetail.Type, mistakes: Mistake[] | MistakeAdju[], category?: string) =>
//       (setPopupOpen: (val: boolean) => void) =>
//         () => {
//           if (task && task.id) {
//             showGlobalLoading(true)
//             pipe(
//               QCService.getQCSave(task.processId),
//               ZIO.catchAll((_error) => {
//                 showGlobalLoading(false)
//                 setPopupOpen(false)
//                 return ZIO.fail(null)
//               }),
//               ZIO.flatMap((res) => {
//                 const isNull = res.detailList?.every((x) => !x.qcCheck) && task.category !== 'PC' // temporary bypass this check for PC-QC, use below line instead this line after remark QC done
//                 return isNull
//                   ? (() => {
//                     showGlobalLoading(false)
//                     const hasQCCheckedButNotSave = mistakes.some((x) => x.qcCheck)
//                     if (hasQCCheckedButNotSave) {
//                       showToast(t('message:MS990003', { tab: t('Tab:QC'), action: t('claim:Transfer') }), 'error')
//                     } else {
//                       showToast(t('message:MS990021'), 'error')
//                     }
//                     setPopupOpen(false)
//                     return ZIO.succeed(false)
//                   })()
//                   : pipe(
//                     PulseOpsService.reCheckTask(task?.id),
//                     ZIO.catchAll((_error) => {
//                       showGlobalLoading(false)
//                       showToast(t('requestInfo:TransferFailed'), 'error')
//                       setPopupOpen(false)
//                       return ZIO.fail(null)
//                     }),
//                     ZIO.map((_) => {
//                       // showToast(t('requestInfo:TransferSuccess'), 'success')
//                       showGlobalLoading(false)
//                       // onSuccess()
//                       setPopupOpen(true)
//                       return true
//                     })
//                   )
//               }),
//               ZIO.unsafeRun({})
//             )

//           }
//         }

export const onRecheckActionHandleClose =
  (setToast: (val: { open: boolean; message: string; type?: Color }) => void) => (done: boolean) => {
    done &&
      setToast({
        open: true,
        message: 'Transfer Successfully !',
        type: 'success'
      })
  }
