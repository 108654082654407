import { StyleSheet, View } from 'react-native'
import React, { useState, useEffect } from 'react'
import {
  LinearProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core'
import { APSStyle, TextCustom } from './styles'
import { COLORS } from './constants'
import { useTranslation } from 'react-i18next'
import { APSService, downloadURI, RoundedButton, Container } from '@pulseops/common'
import { TouchableOpacity } from 'react-native'
import { isEmpty } from 'lodash'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { RouteProp, useRoute } from '@react-navigation/native'
import { InquiryStackParamList } from '@pulseops/inquiry'
import { useLoading } from '@mxt/zio-react'
const { Header, Body } = APSStyle

const useStyles = makeStyles({
  tableHeader: {
    backgroundColor: COLORS.WILD_SAND,
    borderColor: COLORS.WILD_SAND
  },

  styleCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
})

declare interface Rows {
  id: string
  emailAddress: string
  letterDate: string
  index: number
  url: string
}

declare interface Pagination {
  pageNum: number
  pageSize: number
  total: number
}

export const APS = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dataTableHeader = [t('APS:PrintingDate'), t('APS:Download'), t('APS:EmailAddress'), t('APS:SendEmail')]

  const {
    params: { policyNum = '' }
  } = useRoute<RouteProp<InquiryStackParamList, 'PolicyDetail'>>()

  const [loading, bindLoading] = useLoading()

  const [rows, setRows] = React.useState<Rows[]>([])
  const [loadingButtons, setLoadingButtons] = useState<{ index: number; isLoading: boolean }[]>([])
  const [hasError, setHasError] = useState<boolean>(false)
  const [{ pageNum, pageSize, total }, setPagination] = useState<Pagination>({
    pageNum: 0,
    pageSize: 10,
    total: 0
  })

  const apsServiceWithPolicyNumber = APSService.getAPSPrintLetter(policyNum)

  const handleCallAPIPagination = (pageNum: number, pageSize: number, callBack: (res: Rows[]) => void) => {
    pipe(
      apsServiceWithPolicyNumber({ start: pageNum, size: pageSize }),
      ZIO.catchAll((_error) => ZIO.fail(null)),
      ZIO.map((res) => {
        setPagination((prev: Pagination) => ({
          ...prev,
          total: res.total ?? 0,
          pageNum: res.start ?? 0,
          pageSize: res.size ?? 10
        }))

        return res.data
          ? res.data.map((elm, index) => ({
              index,
              id: elm.printLetterID,
              emailAddress: elm?.emailAddress ?? '',
              letterDate: elm.letterDate,
              url: elm.url
            }))
          : []
      }),
      ZIO.tap((res) => {
        const checkHaveShowError = isEmpty(res?.[0]?.emailAddress)
        setHasError(checkHaveShowError)
        callBack(res)
        return ZIO.unit
      }),
      bindLoading,
      ZIO.unsafeRun({})
    )
  }

  // Call API get data initial page
  useEffect(() => {
    if (isEmpty(policyNum)) return

    return handleCallAPIPagination(0, 10, (res) => setRows(res))
  }, [policyNum])

  /**
   *  Function
   */
  const handleChangePage = (_pageNum: number) => handleCallAPIPagination(_pageNum, pageSize, (res) => setRows(res))

  const handleChangePerPage = (_pageSize: number) => handleCallAPIPagination(0, _pageSize, (res) => setRows(res))

  const handleSendEmail = (row: Rows) => {
    const loadingButtonsClone = [...loadingButtons]
    const index = loadingButtonsClone.findIndex((elm) => elm.index === row.index)
    const loadingButtonsFuture = [
      ...loadingButtonsClone.slice(0, index),
      {
        index: row.index,
        isLoading: true
      },
      ...loadingButtonsClone.slice(index + 1)
    ]
    setLoadingButtons([...loadingButtonsFuture])
  }

  const handleDownload = (url: string) => downloadURI(url, 'your_pdf')

  /**
   *  Views
   */
  const renderTableHeader = () => {
    return dataTableHeader.map((text, index) => (
      <TableCell key={index} align="center">
        <TextCustom fontSize={15} fontWeight="700" color={COLORS.ROLLING_STONE}>
          {text}
        </TextCustom>
      </TableCell>
    ))
  }

  const renderTableBody = () => {
    return !isEmpty(rows) ? (
      rows?.map((row, index) => (
        <TableRow key={row.id}>
          <TableCell align="center">{row.letterDate}</TableCell>
          <TableCell align="center">
            <TouchableOpacity onPress={() => handleDownload(row.url)}>
              <TextCustom fontSize={13} fontWeight="400" color={COLORS.DODGER_BLUE} style={styles.downloadTitle}>
                download
              </TextCustom>
            </TouchableOpacity>
          </TableCell>
          <TableCell align="center">{row.emailAddress || '-'}</TableCell>
          <TableCell align="center" className={classes.styleCenter}>
            <RoundedButton
              text={t('APS:Send')}
              showBorder={false}
              filled={true}
              activeOpacity={0.7}
              textStyle={styles.btnTitle}
              style={{ height: 30, width: 107, margin: 0 }}
              textColorDisable={COLORS.WHITE}
              textColorEnable={COLORS.WHITE}
              bgColorDisable={COLORS.SILVER_CHALICE}
              bgColorEnable={COLORS.CRIMSON}
              borderColorDisable={COLORS.SILVER_CHALICE}
              borderColorEnable={COLORS.CRIMSON}
              onPress={() => handleSendEmail(row)}
              isLoading={loadingButtons[index]?.isLoading}
              loadingColor={COLORS.WHITE}
              {...(isEmpty(row.emailAddress) && { disabled: true })}
            />
          </TableCell>
        </TableRow>
      ))
    ) : (
      <TableRow>
        <TableCell colSpan={4} style={{ textAlign: 'center' }}>
          {t('common:noRecord')}
        </TableCell>
      </TableRow>
    )
  }

  const renderTable = () => {
    return (
      <Container loading={loading}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead className={classes.tableHeader}>
              <TableRow>{renderTableHeader()}</TableRow>
            </TableHead>

            <TableBody>{renderTableBody()}</TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          page={pageNum}
          rowsPerPage={pageSize}
          count={total}
          onPageChange={(_, page) => handleChangePage(page)}
          onRowsPerPageChange={(e) => handleChangePerPage(+e.target.value)}
          labelRowsPerPage={t('common:PaginationSize')}
          labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
          component={View}
        />
      </Container>
    )
  }

  return (
    <>
      <Header>
        <View style={styles.flexRowHeader}>
          <TextCustom color={COLORS.EMPEROR} fontSize={16} fontWeight="700">
            {t('APS:AnnualPS').toUpperCase()}
          </TextCustom>

          {hasError && (
            <TextCustom color={COLORS.CRIMSON} fontSize={14} fontWeight="400" fontStyle="italic">
              {t('APS:Error')}
            </TextCustom>
          )}
        </View>

        {loading && <LinearProgress color="secondary" />}
      </Header>

      <Body>{renderTable()}</Body>
    </>
  )
}

const styles = StyleSheet.create({
  btnTitle: {
    fontSize: 15,
    fontWeight: 'bold'
  },

  downloadTitle: {
    textDecorationLine: 'underline',
    cursor: 'pointer'
  },

  flexRowHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row'
  }
})
