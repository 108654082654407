import {
  AppContext,
  assets,
  InheritanceService,
  ChangeContactInfoService,
  DatePicker,
  ErrorHandling,
  form2,
  GeneralService,
  ImgUploadMutiple,
  Input,
  PulseOpsFormat,
  RadioButton,
  Select,
  SelectOption,
  SelectSearch,
  sharedStyle,
  StorageBlob,
  TransactionType,
  Inheritance as InheritanceModel,
  convertUnicodeToViqr
} from '@pulseops/common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View, StyleSheet } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { RequestAuthenticateData } from '../request-authen'
import { PolicyServiceProps } from './policy-service-props'
import * as t from 'io-ts'
//import * as E from 'fp-ts/Either'
import { Controller } from 'react-hook-form'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { LinearProgress } from '@material-ui/core'
import { CustomCheckBox, DuplicateTable, SC } from '../common'
import i18next from 'i18next'
import { NonEmptyString, withMessage } from 'io-ts-types'
import _ from 'lodash'

const FileMeta = t.type({
  fileName: t.string,
  fileExtension: t.string,
  size: t.number,
  base64: t.string,
  uploadedDate: form2.date.required,
  file: form2.file.required
})
type FileMeta = t.TypeOf<typeof FileMeta>
type FileAttachments = t.Branded<FileMeta[], { readonly FileAttachments: unique symbol }>
const RelationShip = t.type({
  clientId: form2.string.optional,
  clientName: form2.string.optional,
  relationship: form2.selectOption.optional,
  role: form2.string.optional,
  secuityNo: form2.string.optional,
  dob: form2.string.optional,
  gender: form2.string.optional,
  poName: form2.string.optional
  //sameClient: t.boolean
})

type RelationshipS3Map = {
  value: string
  label: string
  code: string
  id: string
  la1: string
  la2: string
  la3: string
  la4: string
  relationshipEN: string
}
type RelationShip = t.TypeOf<typeof RelationShip>

type PhoneRelationShipBrand = {
  readonly PhoneRelationShip: unique symbol
}

type PhoneRelationShip = t.Branded<RelationShip, PhoneRelationShipBrand>

const PhoneRelationShip = pipe(
  RelationShip,
  form2.refine(
    (l): l is PhoneRelationShip => {
      if (l.role === 'AG') {
        return false
      }
      return true
    },
    (l) => `Client co phone trung voi tu van vien ${l.clientName}`,
    'PhoneRelationShip'
  ),
  form2.refine(
    (l): l is PhoneRelationShip => {
      if (l.role !== 'AG') {
        return l.relationship !== null
      }
      return true
    },
    (l) => i18next.t('message:MS050228'),
    'PhoneRelationShip'
  )
)

type EmailRelationShipBrand = {
  readonly EmailRelationShip: unique symbol
}

type EmailRelationShip = t.Branded<RelationShip, EmailRelationShipBrand>

const EmailRelationShip = pipe(
  RelationShip,
  form2.refine(
    (l): l is EmailRelationShip => {
      if (l.role === 'AG') {
        return false
      }
      return true
    },
    (l) => `Client co email trung voi tu van vien ${l.clientName}`,
    'EmailRelationShip'
  ),
  form2.refine(
    (l): l is EmailRelationShip => {
      if (l.role !== 'AG') {
        return l.relationship !== null
      }
      return true
    },
    (l) => i18next.t('message:MS050228'),
    'EmailRelationShip'
  )
)
type NumberStringBrand = {
  readonly NumberString: unique symbol
}

type foreignAddressBrand = {
  readonly MS050047: unique symbol
}

type NumberString = t.Branded<NonEmptyString, NumberStringBrand>
type foreignAddress = t.Branded<NonEmptyString, foreignAddressBrand>
// const PhoneNumber = pipe(
//   withMessage(NonEmptyString, () => i18next.t('message:MS020001', { field: i18next.t('submission:MobilePhone') })),
//   form2.refine(
//     (l): l is NumberString => /\b\d{10}\b/.test(l),
//     (l) => i18next.t('message:MS050013', { field: i18next.t('submission:MobilePhone') }),
//     'NumberString'
//   )
// )

const IDNumber = pipe(
  withMessage(NonEmptyString, () => i18next.t('message:MS020001', { field: i18next.t('submission:IDNumber') })),
  form2.refine(
    (l): l is NumberString => l.length >= 8 && l.length <= 24,
    (l) => i18next.t('message:MS050216'),
    'NumberString'
  )
)

const FormData = t.intersection([
  t.type({
    newPolicyOwner: form2.selectOption.requiredM(() =>
      i18next.t('message:MS020009', {
        field: i18next.t('submission:NewPolicyOwner')
      })
    ),
    idNumber: IDNumber,
    relationShipWithMainLifeAssured: form2.selectOption.requiredM(() =>
      i18next.t('message:MS020009', {
        field: i18next.t('submission:RelationshipWithMainLifeAssurance')
      })
    ),
    usTaxRegister: t.boolean,
    dupPhones: form2.optional(t.array(PhoneRelationShip)),
    dupEmails: form2.optional(t.array(EmailRelationShip)),
    relationShipWithMainLifeAssuredLA2: t.union([
      t.type({
        hasLA: t.literal(false),
        name: t.literal(''),
        clientNumberOfLA: t.literal(''),
        relationship: t.null
      }),
      t.type({
        hasLA: t.literal(true),
        name: t.string,
        clientNumberOfLA: t.string,
        relationship: form2.selectOption.required
      })
    ]),
    relationShipWithMainLifeAssuredLA3: t.union([
      t.type({
        hasLA: t.literal(false),
        name: t.literal(''),
        clientNumberOfLA: t.literal(''),
        relationship: t.null
      }),
      t.type({
        hasLA: t.literal(true),
        name: t.string,
        clientNumberOfLA: t.string,
        relationship: form2.selectOption.required
      })
    ]),
    relationShipWithMainLifeAssuredLA4: t.union([
      t.type({
        hasLA: t.literal(false),
        name: t.literal(''),
        clientNumberOfLA: t.literal(''),
        relationship: t.null
      }),
      t.type({
        hasLA: t.literal(true),
        name: t.string,
        clientNumberOfLA: t.string,
        relationship: form2.selectOption.required
      })
    ]),
    relativeDocument: pipe(
      t.array(
        t.type({
          fileName: t.string,
          fileExtension: t.string,
          size: t.number,
          base64: t.string,
          uploadedDate: form2.date.required,
          file: form2.file.required
        })
      ),
      form2.refine(
        (files): files is FileAttachments => files.length > 0,
        () => i18next.t('message:MS020001', { field: i18next.t('submission:RelativeDocument') }),
        'FileAttachments'
      )
    ),
    nationality2: form2.selectOption.optional,
    dod: form2.date.requiredM(() =>
      i18next.t('message:MS020009', {
        field: i18next.t('submission:PODeathDate')
      })
    ),
    fileAttachment: pipe(
      t.array(
        t.type({
          fileName: t.string,
          fileExtension: t.string,
          size: t.number,
          base64: t.string,
          uploadedDate: form2.date.required,
          file: form2.file.required
        })
      ),
      form2.refine(
        (files): files is FileAttachments => files.length > 0,
        () => i18next.t('message:MS020001', { field: i18next.t('submission:FileAttachment') }),
        'FileAttachments'
      )
    ),
    appliedPolicys: t.array(
      t.type({
        selected: t.boolean,
        policyNum: t.string,
        clientName: t.string
      })
    ),
    smsIndicator: form2.selectOption.requiredM(() =>
      i18next.t('message:MS020009', {
        field: i18next.t('submission:SMSIndicator')
      })
    ),
    emailIndicator: form2.selectOption.requiredM(() =>
      i18next.t('message:MS020009', {
        field: i18next.t('submission:EmailIndicator')
      })
    ),
    taxResidencyCountry: form2.selectOption.requiredM(() =>
      i18next.t('message:MS020009', {
        field: i18next.t('submission:TaxResidencyCountry')
      })
    )
  }),
  t.union([
    t.type({
      foreignAddressRequired: t.literal(true),
      foreignAddress: pipe(
        form2.string.requiredM(() =>
          i18next.t('message:MS020001', {
            field: i18next.t('submission:ForeignAddress')
          })
        ),
        form2.refine(
          (l): l is foreignAddress => convertUnicodeToViqr(l).length <= 30,
          () => i18next.t('message:MS050047', { maxLen: 30 }),
          'MS050047'
        )
      ),
      foreignCountry: form2.selectOption.requiredM(() =>
        i18next.t('message:MS020009', {
          field: i18next.t('submission:CountryOfForeignAddress')
        })
      )
    }),
    t.type({
      foreignAddressRequired: t.literal(false),
      foreignAddress:
        pipe(
          form2.string.optional,
          form2.refine(
            (l): l is foreignAddress => !l || convertUnicodeToViqr(l).length <= 30,
            () => i18next.t('message:MS050047'),
            'MS050047'
          )
        ),
      foreignCountry: form2.selectOption.optional
    })
  ]),
  t.union([
    t.type({
      requireFill: t.literal(true),
      mobileCode: form2.selectOption.requiredM(() =>
        i18next.t('message:MS020009', {
          field: i18next.t('submission:AboardMobilePhone')
        })
      ),
      mobileNumber: withMessage(form2.phone.required, () =>
        i18next.t('message:MS050013', { field: i18next.t('submission:MobilePhone') })
      ),
      surName: form2.string.requiredM(() =>
        i18next.t('message:MS020001', {
          field: i18next.t('submission:SurName')
        })
      ),
      givenName: form2.string.requiredM(() =>
        i18next.t('message:MS020001', {
          field: i18next.t('submission:GivenName')
        })
      ),
      dob: form2.date.requiredM(() =>
        i18next.t('message:MS020009', {
          field: i18next.t('submission:DateOfBirth')
        })
      ),
      gender: form2.selectOption.requiredM(() =>
        i18next.t('message:MS020009', {
          field: i18next.t('submission:Gender')
        })
      ),
      nationality: form2.selectOption.requiredM(() =>
        i18next.t('message:MS020009', {
          field: i18next.t('submission:Nationality')
        })
      ),
      email: withMessage(form2.email.required, () =>
        i18next.t('message:MS050013', { field: i18next.t('requestInfo:Email') })
      ),
      street: form2.string.requiredM(() =>
        i18next.t('message:MS020001', {
          field: i18next.t('submission:Street')
        })
      ),
      city: form2.selectOption.requiredM(() =>
        i18next.t('message:MS020009', {
          field: i18next.t('submission:CityProvince')
        })
      ),
      district: form2.selectOption.requiredM(() =>
        i18next.t('message:MS020009', {
          field: i18next.t('submission:District')
        })
      ),
      ward: form2.selectOption.requiredM(() =>
        i18next.t('message:MS020009', {
          field: i18next.t('submission:WardSubDistrict')
        })
      ),
      occupation: form2.selectOption.requiredM(() =>
        i18next.t('message:MS020009', {
          field: i18next.t('submission:Occupation')
        })
      ),
      country: form2.selectOption.requiredM(() =>
        i18next.t('message:MS020009', {
          field: i18next.t('submission:Country')
        })
      )
    }),
    t.type({
      requireFill: t.literal(false),
      mobileCode: form2.selectOption.optional,
      mobileNumber: form2.phone.optional,
      surName: form2.string.optional,
      givenName: form2.string.optional,
      dob: form2.date.optional,
      gender: form2.selectOption.optional,
      nationality: form2.selectOption.optional,
      email: form2.email.optional,
      street: form2.string.optional,
      city: form2.selectOption.optional,
      district: form2.selectOption.optional,
      ward: form2.selectOption.optional,
      occupation: form2.selectOption.optional,
      country: form2.selectOption.optional
    })
  ])
])
type FormData = t.TypeOf<typeof FormData>
type FormDataRaw = t.OutputOf<typeof FormData>

const endOfYesterday = PulseOpsFormat.endOfDate(PulseOpsFormat.getYesterday())
const currentDate = new Date()

export const Inheritance = (props: PolicyServiceProps<InheritanceModel.Submit>) => {
  const { t, i18n } = useTranslation()
  const { showGlobalLoading, showToast } = React.useContext(AppContext.AppContextInstance)

  const [districtOptions, setDistrictOptions] = React.useState<SelectOption[]>([])
  const [wardOptions, setWardOptions] = React.useState<SelectOption[]>([])

  const genders = [
    { label: t(`submission:Male`), value: 'M' },
    { label: t(`submission:Female`), value: 'F' }
  ]

  const policyNumber = props.policyNumber!
  const defaultNewPolicyOwner = {
    label: t('submission:Other'),
    value: 'Other'
  }

  const smsIndicators = [
    { label: t('submission:SMSIndicatorOptions.PolInfo'), value: '1' },
    { label: t('submission:SMSIndicatorOptions.Pol&PruInfo'), value: '3' },
    { label: t('submission:SMSIndicatorOptions.RefuseSMS'), value: '4' }
  ]

  const emailIndicators = [
    { label: t('submission:EmailIndicatorOptions.PolInfo'), value: '1' },
    { label: t('submission:EmailIndicatorOptions.Pol&PruInfo'), value: '3' },
    { label: t('submission:EmailIndicatorOptions.RefuseEmail'), value: '4' }
  ]

  const defaultValues: Omit<FormDataRaw, 'dob' | 'foreignAddressRequired' | 'dod'> & {
    dob: Date | undefined
    dod: Date | undefined
    foreignAddressRequired: false | undefined
    requireFill: false | undefined
  } = {
    newPolicyOwner: defaultNewPolicyOwner,
    requireFill: false,
    idNumber: '',
    relationShipWithMainLifeAssured: null,
    surName: '',
    givenName: '',
    usTaxRegister: false,
    dob: undefined,
    gender: null,
    nationality: null,
    mobileCode: null,
    mobileNumber: '',
    email: '',
    occupation: null,
    street: '',
    country: null,
    city: null,
    district: null,
    ward: null,
    foreignAddress: '',
    nationality2: null,
    dupPhones: [],
    dupEmails: [],
    relationShipWithMainLifeAssuredLA2: {
      hasLA: false,
      name: '',
      clientNumberOfLA: '',
      relationship: null
    },
    relationShipWithMainLifeAssuredLA3: {
      hasLA: false,
      name: '',
      clientNumberOfLA: '',
      relationship: null
    },
    relationShipWithMainLifeAssuredLA4: {
      hasLA: false,
      name: '',
      clientNumberOfLA: '',
      relationship: null
    },
    relativeDocument: [],
    foreignAddressRequired: undefined,
    dod: undefined,
    fileAttachment: [],
    appliedPolicys: [],
    smsIndicator: null,
    emailIndicator: null,
    foreignCountry: null,
    taxResidencyCountry: null
  }

  const { base } = form2.useForm(FormData, { defaultValues })

  React.useEffect(() => {
    base.setValue('requireFill', true)
  }, [])

  const nationality = base.watch('nationality')
  const nationality2 = base.watch('nationality2')

  React.useEffect(() => {
    base.setValue(
      'foreignAddressRequired',
      (!!nationality?.value && nationality?.value !== 'VN') || (!!nationality2?.value && nationality2?.value !== 'VN')
    )
    base.clearErrors('foreignAddress')
  }, [nationality, nationality2])

  const data = pipe(
    ZIO.zipPar(
      InheritanceService.getDetail(policyNumber),
      GeneralService.getProvinces,
      GeneralService.getOccupations,
      GeneralService.getCountries,
      GeneralService.getMobileCodes,
      GeneralService.getSalaries
      //GeneralService.getIARelationship(),
    ),
    ZIO.map(([detail, provinces, occupations, countries, mobileCodes, salaries]) => {
      const provinceOptions = provinces.map((c) => ({
        value: c.code,
        label: c.name
      }))
      const careers = occupations.map((c) => ({
        value: c.code,
        label: c.name
      }))
      const nationOptions = countries.map((c) => ({
        value: c.code,
        label: c.name
      }))
      const telCodeOptions = mobileCodes.map((c) => ({
        value: c.code,
        label: `+${c.code}`
      }))
      const incomeRanges = salaries.map((c) => ({
        value: c.code,
        label: c.name
      }))
      return {
        detail,
        provinceOptions,
        careers,
        nationOptions,
        telCodeOptions,
        incomeRanges
        // relationshipLA1YOptions,
        // relationshipLAOptions
      }
    }),
    ZIO.tap(({ detail, nationOptions, telCodeOptions }) =>
      ZIO.effect(() => {
        const defaulCountry = nationOptions.find((_) => _.value === 'VN')
        defaulCountry && base.setValue('country', defaulCountry)
        const defaulTelCode = telCodeOptions.find((_) => _.value === '84')
        defaulTelCode && base.setValue('mobileCode', defaulTelCode)
        updateLA('relationShipWithMainLifeAssuredLA2', detail)
        updateLA('relationShipWithMainLifeAssuredLA3', detail)
        updateLA('relationShipWithMainLifeAssuredLA4', detail)

        base.setValue(
          'appliedPolicys',
          detail.clientPolicyList.map((clientPolicy) => ({
            selected: false,
            policyNum: clientPolicy.policyNum,
            clientName: clientPolicy.name || ''
          }))
        )
      })
    ),
    ErrorHandling.runDidMount(null)
  )

  const { relationshipt16, relationshipLA, relationshipLA2, relationshipLA3, relationshipLA4 } = pipe(
    ZIO.zipPar(GeneralService.getRelationship(), GeneralService.getRelationshipt16s3),
    ZIO.map(([relationships, relationshipS3]) => {
      const relationshipt16 = relationships
        .filter((r) => r.inheritance === 'Y')
        .map((r) => ({ value: r.code, label: i18n.language === 'vi' ? r.relationshipVN : r.relationshipEN }))
      const relationshipt16s3 = relationshipS3
        .map((r) => {
          const relationship = relationshipt16.find((e) => e.value === r.code)
          return { ...relationship, ...r }
        })
        .filter((e): e is RelationshipS3Map => !!e)
      const filterLA = (f: (r: RelationshipS3Map) => boolean): SelectOption[] => {
        return relationshipt16s3.filter(f).map((r) => ({ value: r.value, label: r.label }))
      }

      const relationshipLA = filterLA((r) => r.la1 === 'T')
      const relationshipLA2 = filterLA((r) => r.la2 === 'T')
      const relationshipLA3 = filterLA((r) => r.la3 === 'T')
      const relationshipLA4 = filterLA((r) => r.la4 === 'T')
      return {
        relationshipt16,
        relationshipt16s3,
        relationshipLA,
        relationshipLA2,
        relationshipLA3,
        relationshipLA4
      }
    }),
    ErrorHandling.runDidUpdate([i18n.language])
  ) ?? {
    relationshipt16: [],
    relationshipt16s3: [],
    relationshipLA: [],
    relationshipLA2: [],
    relationshipLA3: [],
    relationshipLA4: []
  }

  const updateLA = (
    nameLA:
      | 'relationShipWithMainLifeAssuredLA2'
      | 'relationShipWithMainLifeAssuredLA3'
      | 'relationShipWithMainLifeAssuredLA4',
    detail: InheritanceModel.Detail
  ) => {
    const lifeId =
      nameLA === 'relationShipWithMainLifeAssuredLA2'
        ? '02'
        : nameLA === 'relationShipWithMainLifeAssuredLA3'
          ? '03'
          : '04'
    const hasLA = detail.laList.find(
      ({ life, clientNumberOfLA }) => life === lifeId && base.watch('newPolicyOwner')?.value !== clientNumberOfLA
    )
    if (hasLA) {
      base.setValue(nameLA, {
        hasLA: true,
        name: hasLA.name,
        clientNumberOfLA: hasLA.clientNumberOfLA,
        relationship: null
      })
    } else {
      base.setValue(nameLA, {
        hasLA: false,
        name: '',
        clientNumberOfLA: '',
        relationship: null
      })
    }
  }

  const getRole = (role: string) => {
    return role === 'PolicyOwner'
      ? t(`submission:PolicyOwner`)
      : role === 'LifeAssured'
        ? t(`submission:LifeAssured`)
        : role === 'Beneficiary'
          ? t(`submission:Beneficiary`)
          : '-'
  }

  const loadDistrictOptions = (provinceCode?: string, customerDistrict?: string) => {
    if (!provinceCode) {
      setDistrictOptions([])
      return base.setValue('district', null)
    }
    return pipe(
      ZIO.effect(() => showGlobalLoading(true)),
      ZIO.flatMap(() => GeneralService.getDistricts(provinceCode)),
      ZIO.map((districts) => {
        const mapDistricts = districts.map((_) => ({
          value: _.code,
          label: _.name
        }))
        setDistrictOptions(mapDistricts)
        return base.setValue('district', mapDistricts.find((_) => _.value === customerDistrict) || null)
      }),
      ZIO.tap(() => {
        showGlobalLoading(false)
        return ZIO.unit
      }),
      ZIO.tapError(() => {
        showGlobalLoading(false)
        return ZIO.unit
      }),
      ZIO.unsafeRun({})
    )
  }

  const loadWardOptions = (provinceCode?: string, districtCode?: string, customerWard?: string) => {
    if (!provinceCode || !districtCode) {
      setWardOptions([])
      return base.setValue('ward', null)
    }
    return pipe(
      ZIO.effect(() => showGlobalLoading(true)),
      ZIO.flatMap(() => GeneralService.getWards({ provinceCode, districtCode })),
      ZIO.map((wards) => {
        const mapWards = wards.map((_) => ({
          value: _.code,
          label: _.name
        }))
        setWardOptions(mapWards)
        return base.setValue('ward', mapWards.find((_) => _.value === customerWard) || null)
      }),
      ZIO.tap(() => {
        showGlobalLoading(false)
        return ZIO.unit
      }),
      ZIO.tapError(() => {
        showGlobalLoading(false)
        return ZIO.unit
      }),
      ZIO.unsafeRun({})
    )
  }

  const isSameClient = (relationship: RelationShip, clientInfo?: InheritanceModel.CustomerData): boolean => {
    if (clientInfo) {
      return (
        relationship.clientName === `${clientInfo.surName} ${clientInfo.name}` &&
        relationship.dob === clientInfo.dob &&
        relationship.gender === clientInfo.gender &&
        relationship.secuityNo === clientInfo.idNum
      )
    }
    return false
  }

  const loadDuplicatePhoneInfo = (phone?: string) => {
    const customer = data?.detail.customerData.find((_) => _.customerId === base.watch('newPolicyOwner')?.value)
    if (phone) {
      pipe(
        ZIO.effect(() => showGlobalLoading(true)),
        ZIO.flatMap(() => ChangeContactInfoService.getDuplicatePhoneInfo(phone, customer?.customerId || '')),
        ZIO.map((duplicateInfos) => {
          base.setValue(
            'dupPhones',
            duplicateInfos
              .map((e) => ({
                role: e.role,
                clientName: e.clientName,
                clientId: e.clientId,
                relationship: null,
                dob: e.dob,
                secuityNo: e.secuityNo,
                gender: e.gender,
                poName: e.clientName
              }))
              .filter((e) => !isSameClient(e, customer))
          )
        }),
        ZIO.tap(() => {
          showGlobalLoading(false)
          return ZIO.unit
        }),
        ZIO.tapError(() => {
          showGlobalLoading(false)
          return ZIO.unit
        }),
        ZIO.unsafeRun({})
      )
    }
  }

  const loadDuplicateEmail = (email?: string) => {
    const customer = data?.detail.customerData.find((_) => _.customerId === base.watch('newPolicyOwner')?.value)
    if (email) {
      pipe(
        ZIO.effect(() => showGlobalLoading(true)),
        ZIO.flatMap(() => ChangeContactInfoService.getDuplicateEmailInfo(email, customer?.customerId || '')),
        ZIO.map((duplicateEmails) => {
          base.setValue(
            'dupEmails',
            duplicateEmails
              .map((e) => ({
                role: e.role,
                clientName: e.clientName,
                clientId: e.clientId,
                relationship: null,
                dob: e.dob,
                secuityNo: e.secuityNo,
                gender: e.gender,
                poName: e.clientName
              }))
              .filter((e) => !isSameClient(e, customer))
          )
        }),
        ZIO.tap(() => {
          showGlobalLoading(false)
          return ZIO.unit
        }),
        ZIO.tapError(() => {
          showGlobalLoading(false)
          return ZIO.unit
        }),
        ZIO.unsafeRun({})
      )
    }
  }

  const patchData = (customerId?: string) => {
    const customer = data?.detail.customerData.find((_) => _.customerId === customerId)
    if (customer && data) {
      base.reset({
        newPolicyOwner: base.watch('newPolicyOwner'),
        idNumber: customer.idNum,
        relationShipWithMainLifeAssured: defaultValues.relationShipWithMainLifeAssured,
        surName: customer.surName,
        givenName: customer.name,
        usTaxRegister: customer.fatca,
        dob: PulseOpsFormat.getStringToDate(customer.dob, 'YYYYMMDD'),
        gender: genders.find((_) => _.value === customer.gender) || null,
        nationality: data.nationOptions.find((_) => _.value === customer.nationality) || null,
        mobileCode: data.telCodeOptions.find((_) => _.value === customer.mobilePhoneCode) || null,
        mobileNumber: customer.mobilePhoneNumber,
        email: customer.email,
        occupation: data.careers.find((_) => _.value === customer.occupation) || null,
        street: customer.address,
        country: data.nationOptions.find((_) => _.value === customer.countryCode) || null,
        city: data.provinceOptions.find((_) => _.value === customer.city) || null,
        district: districtOptions.find((_) => _.value === customer.district) || null,
        ward: wardOptions.find((_) => _.value === customer.ward) || null,
        foreignAddress: customer.foreignAddress,
        nationality2: data.nationOptions.find((_) => _.value === customer.nationality2) || null,
        dupPhones: defaultValues.dupPhones,
        dupEmails: defaultValues.dupEmails,
        relationShipWithMainLifeAssuredLA2: defaultValues.relationShipWithMainLifeAssuredLA2,
        relationShipWithMainLifeAssuredLA3: defaultValues.relationShipWithMainLifeAssuredLA3,
        relationShipWithMainLifeAssuredLA4: defaultValues.relationShipWithMainLifeAssuredLA4,
        relativeDocument: defaultValues.relativeDocument,
        foreignAddressRequired: defaultValues.foreignAddressRequired,
        dod: defaultValues.dod,
        fileAttachment: defaultValues.fileAttachment,
        appliedPolicys: base.watch('appliedPolicys'),
        foreignCountry: data.nationOptions.find((_) => _.value === customer.foreignCountry) || null,
      })
      loadDuplicatePhoneInfo(customer.mobilePhoneNumber)
      loadDuplicateEmail(customer.email)
      loadDistrictOptions(customer.city, customer.district)
      loadWardOptions(customer.city, customer.district, customer.ward)
    } else {
      base.reset({
        ...defaultValues,
        country: data?.nationOptions.find((_) => _.value === 'VN') || null,
        mobileCode: data?.telCodeOptions.find((_) => _.value === '84') || null,
        appliedPolicys: base.watch('appliedPolicys')
      })
      setDistrictOptions([])
      setWardOptions([])
    }
  }

  props.initSubmission({
    validate: async (isContinue) => {
      //const _form = await handleSubmit(() => undefined)()
      await base.trigger()
      // console.log('asdasdasdasdas', base.getValues())
      if (base.formState.isValid && data) {
        //const form = _form.right
        const form = base.getValues()
        let fileMeta: { rDoc: StorageBlob.FileContentSubmit[]; iDoc: StorageBlob.FileContentSubmit[] } = {
          rDoc: [],
          iDoc: []
        }

        if (!isContinue) {
          showGlobalLoading(true)
          fileMeta = await pipe(
            // ZIO.zipPar(
            //   GeneralService.getMetaData(TransactionType.INHERITANCE || '', 'DPS10'),
            //   GeneralService.getMetaData(TransactionType.INHERITANCE || '', 'DPS11')
            // ),
            // ZIO.flatMap(([metaDataRes1, metaDateRes2]) => {
            //   const metaDataRaw1: StorageBlob.MetaDataUpload = {
            //     type: metaDataRes1.data.transactionType,
            //     doctype: metaDataRes1.data.doctypeEn,
            //     class: metaDataRes1.data.classFilenet,
            //     docid: metaDataRes1.data.docID,
            //     maindoc: metaDataRes1.data.mainDoc,
            //     subdoc: metaDataRes1.data.subDoc,
            //     polnum: policyNumber,
            //     batchno: metaDataRes1.data.batchNo
            //   }
            //   const metaDataRaw2: StorageBlob.MetaDataUpload = {
            //     type: metaDateRes2.data.transactionType,
            //     doctype: metaDateRes2.data.doctypeEn,
            //     class: metaDateRes2.data.classFilenet,
            //     docid: metaDateRes2.data.docID,
            //     maindoc: metaDateRes2.data.mainDoc,
            //     subdoc: metaDateRes2.data.subDoc,
            //     polnum: policyNumber,
            //     batchno: metaDateRes2.data.batchNo
            //   }
            //   const relativeDoc: StorageBlob.FileContent[] = form.relativeDocument.map((x) => {
            //     return {
            //       file: x.file!,
            //       metaData: metaDataRaw1
            //     }
            //   })
            //   const inheritDoc: StorageBlob.FileContent[] = form.fileAttachment.map((x) => {
            //     return {
            //       file: x.file!,
            //       metaData: metaDataRaw2
            //     }
            //   })
            //   return ZIO.zipPar(
            //     StorageBlob.uploadToSubmit('PS', policyNumber)(relativeDoc),
            //     StorageBlob.uploadToSubmit('PS', policyNumber)(inheritDoc)
            //   )
            // }),
            ZIO.zipPar(
              GeneralService.getMetaFilesByAttachedFiles(
                form.relativeDocument as FileMeta[],
                TransactionType.INHERITANCE,
                'DPS10',
                policyNumber,
                props.officeCode ?? ''
              ),
              GeneralService.getMetaFilesByAttachedFiles(
                form.fileAttachment as FileMeta[],
                TransactionType.INHERITANCE,
                'DPS11',
                policyNumber,
                props.officeCode ?? ''
              )
            ),
            ZIO.flatMap(([relativeDoc, inheritDoc]) =>
              ZIO.zipPar(
                StorageBlob.uploadToSubmit('PS', policyNumber)(relativeDoc),
                StorageBlob.uploadToSubmit('PS', policyNumber)(inheritDoc)
              )
            ),
            ZIO.map(([rDoc, iDoc]) => ({ rDoc, iDoc })),
            ZIO.mapError((error) => {
              showToast(t('message:MS050001'), 'error')
              return error
            }),
            ErrorHandling.run({})
          )
          showGlobalLoading(false)
        }

        const dupPhones = form.dupPhones
          ?.filter(({ role }) => role !== 'AG')
          .map((item) => ({
            clientId: item.clientId,
            relationshipType: item.relationship?.value || ''
          }))

        const dupEmails = form.dupEmails
          ?.filter(({ role }) => role !== 'AG')
          .map((item) => ({
            clientId: item.clientId,
            relationshipType: item.relationship?.value || ''
          }))

        return {
          url: (policyNumber) => `wf-api/policy/${policyNumber}/inheritance`,
          body: {
            clientId: data.detail.customerId,
            policyNo: policyNumber,
            clientNo: form.newPolicyOwner?.value === defaultNewPolicyOwner.value ? '' : form.newPolicyOwner?.value,
            idNumber: form.idNumber,
            relationship1: form.relationShipWithMainLifeAssured?.value,
            surname: form.surName,
            givenName: form.givenName,
            birthDate: PulseOpsFormat.datetoFormat(form.dob, 'yyyyMMDD'),
            sex: form.gender?.value,
            nationality: form.nationality?.value,
            telCode: form.mobileCode?.value ?? '',
            mobilePhone: form.mobileNumber,
            email: form.email,
            isFatca: form.usTaxRegister ? 'true' : 'false',
            street: form.street,
            country: form.country?.value,
            city: form.city?.label,
            district: form.district?.label,
            ward: form.ward?.label,
            occupation: form.occupation?.value,
            smsIndicator: form.smsIndicator?.value || '',
            emailIndicator: form.emailIndicator?.value || '',
            foreignAdd: form.foreignAddress || '',
            foreignCountry: form.foreignCountry?.value || '',
            nationality2: form.nationality2?.value || '',

            relativeDocuments: fileMeta.rDoc,
            inheritanceDocuments: fileMeta.iDoc,

            attributesExt: {
              PHONES_DUP: dupPhones && dupPhones.length > 0 ? dupPhones : undefined,
              EMAILS_DUP: dupEmails && dupEmails.length > 0 ? dupEmails : undefined,
              LOCATION_CODE: form.ward?.value || undefined,
              taxResidencyCountry: form.taxResidencyCountry?.value || ''
            },

            ...(form.relationShipWithMainLifeAssuredLA2.hasLA
              ? {
                [`clientNo2`]: form.relationShipWithMainLifeAssuredLA2.name,
                [`relationship2`]: form.relationShipWithMainLifeAssuredLA2?.relationship?.value
              }
              : {}),
            ...(form.relationShipWithMainLifeAssuredLA3.hasLA
              ? {
                [`clientNo3`]: form.relationShipWithMainLifeAssuredLA3.clientNumberOfLA,
                [`relationship3`]: form.relationShipWithMainLifeAssuredLA3?.relationship?.value
              }
              : {}),
            ...(form.relationShipWithMainLifeAssuredLA4.hasLA
              ? {
                [`clientNo4`]: form.relationShipWithMainLifeAssuredLA4.clientNumberOfLA,
                [`relationship4`]: form.relationShipWithMainLifeAssuredLA4?.relationship?.value
              }
              : {}),

            deathDate: PulseOpsFormat.datetoFormat(form.dod, 'yyyyMMDD'),
            appliedPolicys: form.appliedPolicys.filter((_) => _.selected).map((p) => ({ appliedPolicy: p.policyNum }))
          } as InheritanceModel.Submit,
          transactionName: RequestAuthenticateData.TransactionLabelShort(TransactionType.INHERITANCE),
          collerationId: props.policyNumber ?? '',
          transaction: TransactionType.INHERITANCE,
          documents: fileMeta.iDoc.concat(fileMeta.rDoc)
        }
      } else {
        return false
      }
    },
    clear: () => {
      base.reset(defaultValues)
      base.setValue('requireFill', true)
    }
  })

  const predicateAgent = (relationship: RelationShip) => relationship.role === 'AG'

  const phoneDups = base.watch('dupPhones') ?? []
  const emailDups = base.watch('dupEmails') ?? []

  const anyAgentPhone = React.useMemo(() => phoneDups?.find(predicateAgent), [phoneDups])
  const anyAgentEmail = React.useMemo(() => emailDups?.find(predicateAgent), [emailDups])

  const requireFill = base.watch('requireFill')

  return data === null ? (
    <LinearProgress style={{ marginTop: 15 }} color="secondary" />
  ) : (
    <SafeAreaView style={{ flex: 1, marginTop: 15 }}>
      <View style={{ backgroundColor: '#FFF' }}>
        <Text style={sharedStyle.sectionTitle}>{t('submission:Inheritance')}</Text>
        <View style={sharedStyle.sectionContent}>
          {/* Row 1 */}
          <View style={styles.row}>
            <View style={styles.col}>
              <Controller
                name="newPolicyOwner"
                control={base.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <Select
                    required
                    label={t('submission:SelectClient1')}
                    options={data.detail.customerData
                      .map((o) => ({
                        label: `${o.surName} ${o.name} - ${getRole(o.role)}`,
                        value: o.customerId
                      }))
                      .concat([defaultNewPolicyOwner])}
                    placeholder={t('common:Select')}
                    onChange={(e) => {
                      onChange(e)
                      patchData(base.watch('newPolicyOwner')?.value)
                      updateLA('relationShipWithMainLifeAssuredLA2', data.detail)
                      updateLA('relationShipWithMainLifeAssuredLA3', data.detail)
                      updateLA('relationShipWithMainLifeAssuredLA4', data.detail)
                      console.log('adasdaewqeewe', e)
                      if (e?.value === defaultNewPolicyOwner.value) {
                        base.setValue('requireFill', true)
                      } else {
                        base.setValue('requireFill', false)
                      }
                    }}
                    onBlur={onBlur}
                    value={value}
                    disabled={props.isConfirmed}
                    errorMessage={error?.message}
                  />
                )}
              />
            </View>
            <View style={styles.col}>
              <Controller
                name="idNumber"
                control={base.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <Input
                    required
                    title={t('submission:idNumber')}
                    placeholder={t('submission:idNumberPlaceHolder')}
                    inputStyle={{ marginTop: 6 }}
                    value={value ?? undefined}
                    maxLength={24}
                    onChange={onChange}
                    onBlur={onBlur}
                    disabled={props.isConfirmed || base.watch('newPolicyOwner')?.value !== defaultNewPolicyOwner.value}
                    errorMessage={error?.message}
                  />
                )}
              />
            </View>
            <View style={styles.col}>
              <Controller
                name="relationShipWithMainLifeAssured"
                control={base.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <SelectSearch
                    required
                    label={t('submission:RelationshipWithMainLifeAssurance')}
                    options={relationshipLA}
                    placeholder={t('common:Select')}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    disabled={props.isConfirmed}
                    errorMessage={error?.message}
                    popupIcon={<assets.ArrowDownDropdownIcon />}
                  />
                )}
              />
            </View>
          </View>
          {/* Row 2 */}
          <View style={styles.row}>
            <View style={styles.col}>
              <Controller
                name="surName"
                control={base.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <Input
                    required={requireFill}
                    title={t('submission:SurName')}
                    placeholder={t('submission:SurNamePlaceHolder')}
                    value={value ?? undefined}
                    maxLength={150}
                    onChange={onChange}
                    onBlur={onBlur}
                    disabled={props.isConfirmed || base.watch('newPolicyOwner')?.value !== defaultNewPolicyOwner.value}
                    errorMessage={error?.message}
                  />
                )}
              />
            </View>
            <View style={styles.col}>
              <Controller
                name="givenName"
                control={base.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <Input
                    required={requireFill}
                    title={t('submission:GivenName')}
                    placeholder={t('submission:GivenNamePlaceHolder')}
                    value={value ?? undefined}
                    maxLength={150}
                    onChange={onChange}
                    onBlur={onBlur}
                    disabled={props.isConfirmed || base.watch('newPolicyOwner')?.value !== defaultNewPolicyOwner.value}
                    errorMessage={error?.message}
                  />
                )}
              />
            </View>
            <View style={styles.col}>
              <Controller
                name="usTaxRegister"
                control={base.control}
                render={({ field: { onChange, value } }) => (
                  <SC.Padding>
                    <SC.BoldText>
                      {t('submission:CurrentlyFatca')} <SC.ErrorText>*</SC.ErrorText>
                    </SC.BoldText>
                    <SC.Divider />
                    <SC.Row>
                      <RadioButton
                        label={t('common:Yes')}
                        selected={value}
                        onChange={() => onChange.call(null, true)}
                        disable={
                          props.isConfirmed || base.watch('newPolicyOwner')?.value !== defaultNewPolicyOwner.value
                        }
                      />
                      <RadioButton
                        label={t('common:No')}
                        selected={!value}
                        onChange={() => onChange.call(null, false)}
                        disable={
                          props.isConfirmed || base.watch('newPolicyOwner')?.value !== defaultNewPolicyOwner.value
                        }
                      />
                    </SC.Row>
                  </SC.Padding>
                )}
              />
            </View>
          </View>
          {/* Row 3 */}
          <View style={styles.row}>
            <View style={styles.col}>
              <Controller
                name="dob"
                control={base.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <View>
                    <SC.Padding bottom={5}>
                      <SC.TitleText>
                        {t('submission:DateOfBirth')} {requireFill && <SC.ErrorText>*</SC.ErrorText>}
                      </SC.TitleText>
                    </SC.Padding>
                    <DatePicker
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      maxDate={endOfYesterday}
                      maxDateMessage={t('message:MS050022')}
                      disabled={
                        props.isConfirmed || base.watch('newPolicyOwner')?.value !== defaultNewPolicyOwner.value
                      }
                      errorMessage={error?.message}
                    />
                  </View>
                )}
              />
            </View>
            <View style={styles.col}>
              <Controller
                name="gender"
                control={base.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <Select
                    required={requireFill}
                    label={t('submission:Gender')}
                    options={genders}
                    placeholder={t('common:Select')}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    disabled={props.isConfirmed || base.watch('newPolicyOwner')?.value !== defaultNewPolicyOwner.value}
                    errorMessage={error?.message}
                  />
                )}
              />
            </View>
            <View style={styles.col}>
              <Controller
                name="nationality"
                control={base.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <SelectSearch
                    required={requireFill}
                    label={t('submission:Nationality')}
                    options={data.nationOptions}
                    placeholder={t('common:Select')}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    disabled={props.isConfirmed || base.watch('newPolicyOwner')?.value !== defaultNewPolicyOwner.value}
                    errorMessage={error?.message}
                    popupIcon={<assets.ArrowDownDropdownIcon />}
                  />
                )}
              />
            </View>
          </View>
          {/* Row 4 */}
          <View style={styles.row}>
            <View style={styles.col}>
              <View style={{ flex: 1, paddingTop: 4 }}>
                <SC.TitleText>
                  {t('submission:MobilePhoneNumber')} {requireFill && <SC.ErrorText>*</SC.ErrorText>}
                </SC.TitleText>
                <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
                  <Controller
                    name={'mobileCode'}
                    control={base.control}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <View style={{ width: 110 }}>
                        <SelectSearch
                          options={data.telCodeOptions}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          popupIcon={<assets.ArrowDownDropdownIcon />}
                          hideLabel
                          placeholder={'+84'}
                          disabled={
                            props.isConfirmed || base.watch('newPolicyOwner')?.value !== defaultNewPolicyOwner.value
                          }
                        />
                      </View>
                    )}
                  />
                  <SC.Divider />
                  <Controller
                    control={base.control}
                    name={'mobileNumber'}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <View style={{ flex: 1 }}>
                        <Input
                          onChange={onChange}
                          onBlur={() => {
                            onBlur()
                            if (value && /^[0-9]+$/.test(value)) {
                              loadDuplicatePhoneInfo(value)
                            }
                          }}
                          value={value ?? undefined}
                          maxLength={20}
                          placeholder={t('submission:InputPhone')}
                          disabled={
                            props.isConfirmed || base.watch('newPolicyOwner')?.value !== defaultNewPolicyOwner.value
                          }
                        />
                      </View>
                    )}
                  />
                </View>
                {(_.get(base.formState.errors.mobileCode, 'message') ||
                  _.get(base.formState.errors.mobileNumber, 'message')) && (
                    <View>
                      <SC.ErrorText fontSize={11.25}>
                        {_.get(base.formState.errors.mobileCode, 'message') ||
                          _.get(base.formState.errors.mobileNumber, 'message')}
                      </SC.ErrorText>
                    </View>
                  )}
              </View>
            </View>
            <View style={styles.col}>
              <Controller
                name="email"
                control={base.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <Input
                    required={requireFill}
                    title={t('submission:Email')}
                    placeholder={t('submission:EmailPlaceHolder')}
                    inputStyle={{ marginTop: 6 }}
                    value={value ?? undefined}
                    maxLength={50}
                    onChange={onChange}
                    onBlur={() => {
                      onBlur()
                      loadDuplicateEmail(base.watch('email') || undefined)
                    }}
                    disabled={props.isConfirmed || base.watch('newPolicyOwner')?.value !== defaultNewPolicyOwner.value}
                    errorMessage={error?.message}
                  />
                )}
              />
            </View>
            <View style={styles.col}>
              <Controller
                name="occupation"
                control={base.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <SelectSearch
                    required={requireFill}
                    label={t('submission:Occupation')}
                    options={data.careers}
                    placeholder={t('common:Select')}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    disabled={props.isConfirmed || base.watch('newPolicyOwner')?.value !== defaultNewPolicyOwner.value}
                    errorMessage={error?.message}
                    popupIcon={<assets.ArrowDownDropdownIcon />}
                  />
                )}
              />
            </View>
          </View>
          {/* Row 5 */}
          <View style={styles.row}>
            <View style={styles.col}>
              <Controller
                name="smsIndicator"
                control={base.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <Select
                    required
                    label={t('submission:SMSIndicator')}
                    options={smsIndicators}
                    placeholder={t('common:Select')}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    errorMessage={error?.message}
                  />
                )}
              />
            </View>
            <View style={styles.col}>
              <Controller
                name="emailIndicator"
                control={base.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <Select
                    required
                    label={t('submission:EmailIndicator')}
                    options={emailIndicators}
                    placeholder={t('common:Select')}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    errorMessage={error?.message}
                  />
                )}
              />
            </View>
          </View>
          {/* Dup Phones, Dup Emails */}
          {anyAgentPhone ? (
            <SC.Padding>
              <SC.ErrorText>{t('message:MS050225', { agent: _.get(anyAgentPhone, 'clientName') })}</SC.ErrorText>
            </SC.Padding>
          ) : (
            phoneDups &&
            phoneDups.length > 0 && (
              <SC.Padding horizontal={25} vertical={10}>
                <SC.Padding vertical={10}>
                  <SC.ErrorText>{t('submission:PhoneDuplicatedWithOther')}</SC.ErrorText>
                </SC.Padding>
                {_.get(base.formState.errors, 'dupPhones') && (
                  <SC.Padding vertical={5}>
                    <SC.ErrorText>{t('message:MS050228')}</SC.ErrorText>
                  </SC.Padding>
                )}
                <Controller
                  control={base.control}
                  name={'dupPhones'}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <DuplicateTable
                        key={`dup_phone_table${props.isConfirmed}`}
                        value={value ?? []}
                        onChange={onChange}
                        disable={props.isConfirmed}
                        relationshipOptions={relationshipt16 ?? []}
                      />
                    )
                  }}
                />
              </SC.Padding>
            )
          )}
          {anyAgentEmail ? (
            <SC.Padding vertical={10}>
              <SC.ErrorText>{t('message:MS050227', { agent: _.get(anyAgentEmail, 'clientName') })}</SC.ErrorText>
            </SC.Padding>
          ) : (
            emailDups &&
            emailDups.length > 0 && (
              <SC.Padding vertical={15} horizontal={25}>
                <SC.Padding vertical={10}>
                  <SC.ErrorText>{t('submission:EmailDuplicatedWithOther')}</SC.ErrorText>
                </SC.Padding>
                {_.get(base.formState.errors, 'dupEmails') && (
                  <SC.Padding vertical={5}>
                    <SC.ErrorText>{t('message:MS050228')}</SC.ErrorText>
                  </SC.Padding>
                )}
                <Controller
                  control={base.control}
                  name={'dupEmails'}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <DuplicateTable
                        key={`dup_mail_table${props.isConfirmed}`}
                        value={value ?? []}
                        onChange={onChange}
                        disable={props.isConfirmed}
                        relationshipOptions={relationshipt16 ?? []}
                      />
                    )
                  }}
                />
              </SC.Padding>
            )
          )}
          {/* Row Country, CityProvince, District */}
          <View style={styles.row}>
            <View style={styles.col}>
              <Controller
                name="country"
                control={base.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <SelectSearch
                    required={requireFill}
                    label={t('submission:Country')}
                    options={data.nationOptions}
                    placeholder={t('common:Select')}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    //disabled={props.isConfirmed || base.watch('newPolicyOwner')?.value !== defaultNewPolicyOwner.value}
                    disabled={true}
                    errorMessage={error?.message}
                    popupIcon={<assets.ArrowDownDropdownIcon />}
                  />
                )}
              />
            </View>
            <View style={styles.col}>
              <Controller
                name="city"
                control={base.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <SelectSearch
                    required={requireFill}
                    label={t('submission:CityProvince')}
                    options={data.provinceOptions}
                    placeholder={t('common:Select')}
                    onChange={(e) => {
                      onChange(e)
                      loadDistrictOptions(base.watch('city')?.value)
                      loadWardOptions()
                    }}
                    onBlur={onBlur}
                    value={value}
                    disabled={props.isConfirmed || base.watch('newPolicyOwner')?.value !== defaultNewPolicyOwner.value}
                    errorMessage={error?.message}
                    popupIcon={<assets.ArrowDownDropdownIcon />}
                  />
                )}
              />
            </View>
            <View style={styles.col}>
              <Controller
                name="district"
                control={base.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <SelectSearch
                    required={requireFill}
                    label={t('submission:District')}
                    options={districtOptions}
                    placeholder={t('common:Select')}
                    onChange={(e) => {
                      onChange(e)
                      loadWardOptions(base.watch('city')?.value, base.watch('district')?.value)
                    }}
                    onBlur={onBlur}
                    value={value}
                    disabled={props.isConfirmed || base.watch('newPolicyOwner')?.value !== defaultNewPolicyOwner.value}
                    errorMessage={error?.message}
                    popupIcon={<assets.ArrowDownDropdownIcon />}
                  />
                )}
              />
            </View>
          </View>
          {/* Row WardSubDistrict, Street */}
          <View style={styles.row}>
            <View style={styles.col}>
              <Controller
                name="ward"
                control={base.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <SelectSearch
                    required={requireFill}
                    label={t('submission:WardSubDistrict')}
                    options={wardOptions}
                    placeholder={t('common:Select')}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    disabled={props.isConfirmed || base.watch('newPolicyOwner')?.value !== defaultNewPolicyOwner.value}
                    errorMessage={error?.message}
                    popupIcon={<assets.ArrowDownDropdownIcon />}
                  />
                )}
              />
            </View>
            <View style={styles.col2}>
              <Controller
                name="street"
                control={base.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <Input
                    required={requireFill}
                    title={t('submission:Street')}
                    placeholder={t('submission:InputAddress')}
                    inputStyle={{ marginTop: 6 }}
                    value={value ?? undefined}
                    maxLength={150}
                    onChange={onChange}
                    onBlur={onBlur}
                    disabled={props.isConfirmed || base.watch('newPolicyOwner')?.value !== defaultNewPolicyOwner.value}
                    errorMessage={error?.message}
                  />
                )}
              />
            </View>
          </View>
          {/* Row ForeignAddress, foreignCountry */}
          <View style={styles.row}>
            <View style={styles.col2}>
              <Controller
                name="foreignAddress"
                control={base.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <Input
                    required={base.watch('foreignAddressRequired')}
                    title={t('submission:ForeignAddress')}
                    placeholder={t('submission:InputAddress')}
                    inputStyle={{ marginTop: 6 }}
                    value={value ?? undefined}
                    maxLength={120}
                    onChange={onChange}
                    onBlur={onBlur}
                    disabled={props.isConfirmed}
                    errorMessage={error?.message}
                  />
                )}
              />
            </View>
            <View style={styles.col}>
              <Controller
                name="foreignCountry"
                control={base.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                  return (
                    <SelectSearch
                      required={base.watch('foreignAddressRequired')}
                      label={t('submission:CountryOfForeignAddress')}
                      options={data.nationOptions}
                      placeholder={t('common:Select')}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      disabled={props.isConfirmed}
                      errorMessage={error?.message}
                      popupIcon={<assets.ArrowDownDropdownIcon />}
                    />
                  )
                }}
              />
            </View>
          </View>
          {/* Row TaxResidencyCountry, nationality2 */}
          <View style={styles.row}>
            <View style={styles.col}>
              <Controller
                name="taxResidencyCountry"
                control={base.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                  return (
                    <SelectSearch
                      required={!props.isConfirmed}
                      label={t('submission:TaxResidencyCountry')}
                      options={data.nationOptions}
                      placeholder={t('common:Select')}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      maxLength={100}
                      disabled={props.isConfirmed}
                      errorMessage={error?.message}
                      popupIcon={<assets.ArrowDownDropdownIcon />}
                    />
                  )
                }}
              />
            </View>
            <View style={styles.col}>
              <Controller
                name="nationality2"
                control={base.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <SelectSearch
                    label={t('submission:Nationality2')}
                    options={data.nationOptions}
                    placeholder={t('common:Select')}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    disabled={props.isConfirmed}
                    errorMessage={error?.message}
                    popupIcon={<assets.ArrowDownDropdownIcon />}
                  />
                )}
              />
            </View>
          </View>
          {/* Row LA2, LA3, LA4 */}
          <View style={styles.row}>
            {base.watch('relationShipWithMainLifeAssuredLA2')?.hasLA && (
              <View style={styles.col}>
                <Controller
                  name="relationShipWithMainLifeAssuredLA2.relationship"
                  control={base.control}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <Select
                      label={t('submission:RelationshipWithLALong', {
                        LAName: base.watch('relationShipWithMainLifeAssuredLA2').name
                      })}
                      options={relationshipLA2}
                      placeholder={t('common:Select')}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      disabled={props.isConfirmed}
                      required
                      errorMessage={error?.message}
                    />
                  )}
                />
              </View>
            )}
            {base.watch('relationShipWithMainLifeAssuredLA3')?.hasLA && (
              <View style={styles.col}>
                <Controller
                  name="relationShipWithMainLifeAssuredLA3.relationship"
                  control={base.control}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <Select
                      label={t('submission:RelationshipWithLALong', {
                        LAName: base.watch('relationShipWithMainLifeAssuredLA3').name
                      })}
                      options={relationshipLA3}
                      placeholder={t('common:Select')}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      disabled={props.isConfirmed}
                      required
                      errorMessage={error?.message}
                    />
                  )}
                />
              </View>
            )}
            {base.watch('relationShipWithMainLifeAssuredLA4')?.hasLA && (
              <View style={styles.col}>
                <Controller
                  name="relationShipWithMainLifeAssuredLA4.relationship"
                  control={base.control}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <Select
                      label={t('submission:RelationshipWithLALong', {
                        LAName: base.watch('relationShipWithMainLifeAssuredLA4').name
                      })}
                      options={relationshipLA4}
                      placeholder={t('common:Select')}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      disabled={props.isConfirmed}
                      required
                      errorMessage={error?.message}
                    />
                  )}
                />
              </View>
            )}
          </View>
          {/* Row RelativeDocument */}
          <View style={styles.row}>
            <SC.Container style={{ paddingHorizontal: 16 }}>
              <SC.Padding vertical={10}>
                <SC.BoldText>
                  {t('submission:RelativeDocument')} <SC.ErrorText>*</SC.ErrorText>
                </SC.BoldText>
              </SC.Padding>
              <Controller
                name={'relativeDocument'}
                control={base.control}
                render={({ field: { value, onChange } }) => {
                  const mapValue =
                    value?.map((file) => ({
                      ...file,
                      uploadedDate: file.uploadedDate!,
                      file: file.file!
                    })) || []
                  return (
                    <SC.PanelContainer>
                      <ImgUploadMutiple
                        value={mapValue}
                        onChange={onChange}
                        errorMessage={_.get(base.formState.errors, 'relativeDocument.message')}
                      />
                      {base.formState.errors.relativeDocument && (
                        <SC.ErrorText>{_.get(base.formState.errors, 'relativeDocument.message')}</SC.ErrorText>
                      )}
                    </SC.PanelContainer>
                  )
                }}
              />
            </SC.Container>
          </View>
          {/* Row AppliedPolicys */}
          <View style={styles.row}>
            <View style={styles.col2}>
              {base.watch('appliedPolicys')?.length > 0 && (
                <SC.Padding vertical={10}>
                  <SC.BoldText>{t('submission:AppliedTo1')}</SC.BoldText>
                </SC.Padding>
              )}
              {base.watch('appliedPolicys')?.map((_, index) => (
                <Controller
                  control={base.control}
                  name={`appliedPolicys.${index}`}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <CustomCheckBox
                        checked={value.selected}
                        onPress={() =>
                          onChange({
                            ...value,
                            selected: !value.selected
                          })
                        }
                        title={`${value.policyNum}    ${value.clientName}`}
                        disabled={props.isConfirmed}
                      />
                    )
                  }}
                />
              ))}
            </View>
          </View>
        </View>
      </View>
      <View style={{ backgroundColor: '#FFF', marginTop: 15 }}>
        <Text style={sharedStyle.sectionTitle}>{t('submission:InheritanceInfo')}</Text>
        <View style={sharedStyle.sectionContent}>
          <View style={styles.row}>
            <View style={styles.col}>
              <Controller
                name="dod"
                control={base.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <DatePicker
                    required
                    label={t('submission:PODeathDate')}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    maxDate={currentDate}
                    maxDateMessage={t('message:MS990032')}
                    disabled={props.isConfirmed}
                    errorMessage={error?.message}
                  />
                )}
              />
            </View>
          </View>
          <View style={styles.row}>
            <SC.Container style={{ paddingHorizontal: 16 }}>
              <SC.Padding vertical={10}>
                <SC.BoldText>
                  {t('utilities:FileAttachment')} <SC.ErrorText>*</SC.ErrorText>
                </SC.BoldText>
              </SC.Padding>
              <Controller
                name={'fileAttachment'}
                control={base.control}
                render={({ field: { value, onChange } }) => {
                  const mapValue =
                    value?.map((file) => ({
                      ...file,
                      uploadedDate: file.uploadedDate!,
                      file: file.file!
                    })) || []
                  return (
                    <SC.PanelContainer>
                      {data.detail.sumAssured <= 50 * 1000000 && (
                        <View>
                          <Text style={styles.fontSize15}>
                            ● Giấy tờ chứng minh quan hệ thừa kế của đại diện nhận tiền với BMBH và bản sao CMND/CCCD
                          </Text>
                          <Text style={styles.fontSize15}>● Giấy cam kết tự chịu trách nhiệm</Text>
                          <Text style={styles.fontSize15}>
                            ● PO mới/Người nhận QLBH là hàng thừa kế thứ nhất với BMBH và phải có mối quan hệ bảo hiểm
                            đối với Người được BH chính.
                          </Text>
                        </View>
                      )}
                      {data.detail.sumAssured > 50 * 1000000 && data.detail.sumAssured <= 100 * 1000000 && (
                        <View>
                          <Text style={styles.fontSize15}>
                            ● Văn bản thỏa thuận cử người đại diện nhận QLBH/đứng tên BMBH mới: có đầy đủ chữ ký của
                            hàng thừa kế (không cần địa phương chứng thực về chữ ký)
                          </Text>
                          <Text style={styles.fontSize15}>
                            ● Bảng tường trình quan hệ nhân thân: có chính quyền địa phương chứng thực về chữ ký của
                            người đại diện nhận quyền lợi. Các trang trên cùng văn bản phải có giáp lai giữa các
                            trang.Chữ ký của người đại diện nhận QLBH phải trên Bảng tường trình quan hệ nhân thân phải
                            khớp với chữ ký trên Văn bản thỏa thuận cử người đại diện nhận QLBH.
                          </Text>
                          <Text style={styles.fontSize15}>
                            ● Giấy tờ chứng minh quan hệ thừa kế của người đại diện nhận QLBH đối với BMBH và bản sao
                            CMND/CCCD
                          </Text>
                        </View>
                      )}
                      {data.detail.sumAssured > 100 * 1000000 && data.detail.sumAssured <= 300 * 1000000 && (
                        <View>
                          <Text style={styles.fontSize15}>
                            ● Văn bản thỏa thuận cử người đại diện nhận QLBH/đứng tên BMBH mới: Phải được chính quyền
                            địa phương chứng thực chữ ký của tất cả những người được liệt kê, các trang trên cùng văn
                            bản phải có dấu mộc giáp lai giữa các trang.
                          </Text>
                          <Text style={styles.fontSize15}>
                            ● Giấy tờ chứng minh quan hệ thừa kế của tất cả hàng thừa kế và giấy chứng tử của hàng thừa
                            kế đã qua đời
                          </Text>
                          <Text style={styles.fontSize15}>● Bản sao CMND/CCCD của người đại diện nhận tiền</Text>
                        </View>
                      )}
                      {data.detail.sumAssured >= 300 * 1000000 && (
                        <View>
                          <Text style={styles.fontSize15}>● Văn bản thỏa thuận phân chia di sản; hoặc</Text>
                          <Text style={styles.fontSize15}>● Văn bản khai nhận di sản</Text>
                        </View>
                      )}
                      <ImgUploadMutiple
                        value={mapValue}
                        onChange={onChange}
                        errorMessage={_.get(base.formState.errors, 'fileAttachment.message')}
                      />
                      {base.formState.errors.relativeDocument && (
                        <SC.ErrorText>{_.get(base.formState.errors, 'fileAttachment.message')}</SC.ErrorText>
                      )}
                    </SC.PanelContainer>
                  )
                }}
              />
            </SC.Container>
          </View>
        </View>
      </View>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    paddingHorizontal: 16
  },

  col: {
    width: '33.33%',
    marginBottom: 16,
    paddingHorizontal: 16
  },

  col2: {
    width: '66.66%',
    marginBottom: 16,
    paddingHorizontal: 16
  },

  fontSize15: {
    fontSize: 15
  }
} as const)
