import { AuthService, Checkbox, ErrorHandling, Permission, SubTaskType, assets, PulseOpsFormat } from '@pulseops/common'
import React from 'react'
import { ScrollView, Text, View, StyleSheet, Pressable } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { useTranslation } from 'react-i18next'
import { OBCallProgramTypeCode, OBTaskQueryData, getLastActivityName, mapCallProgram } from '../../ob-service'
import { OBFormat } from '../../ob-common'
import { TaskManagementContext } from '../OBTaskManagementScreen'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { OutboundParamList } from '../../OutboundParamList'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { OBTaskStatus } from './OBTaskStatus'
import { BasketCount } from './OBBasketTab'
import { TaskConst } from './OBTaskConst'
import * as O from 'fp-ts/lib/Option'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { UserDrawerParamList } from '@pulseops/main'

interface OBTaskListProps {
  dataSource?: Array<OBTaskQueryData>
  pageSize?: number
  onSeeMoreCaseEvent?: (pageNo: number, pageSi: number) => void
  selectedTaskList?: Map<string, OBTaskQueryData>
  onSelectedTaskEvent?: (task: OBTaskQueryData) => void
  onUnSelectedTaskEvent?: (task: OBTaskQueryData) => void
  selectedBasket?: BasketCount
}

type ShowMore = {
  id: number
  showMore: boolean
}

export const OBTaskList = (props: OBTaskListProps) => {
  const [taskList, setTaskList] = React.useState<Array<OBTaskQueryData>>([])
  const { dataSource, onSeeMoreCaseEvent, pageSize, selectedTaskList, onSelectedTaskEvent, onUnSelectedTaskEvent, selectedBasket } = props
  const { t } = useTranslation()
  const [listShowMore, setShowMore] = React.useState<ShowMore[]>([])
  const [moreSize, setMoreSize] = React.useState<number>(pageSize ?? 0)
  // const navigation = useNavigation<StackNavigationProp<OutboundParamList, 'OBTaskManagementScreen'>>()
  const navigation = useNavigation<DrawerNavigationProp<UserDrawerParamList>>()
  const { permissions } = React.useContext(TaskManagementContext)
  const addShowMore = () => {
    const list = dataSource && dataSource.length > 0 ? dataSource.map((item, index) => ({
      id: index,
      showMore: false
    })) : []
    setShowMore(list)
  }

  const userInfo = pipe(
    AuthService.userInfo,
    ZIO.map((data) => data),
    ErrorHandling.runDidMount()
  )

  const checkShowMore = (index: number) => {
    return listShowMore && listShowMore.length > 0 && listShowMore[index].showMore
  }

  const changeShowMore = (id: number) => {
    let newChangeList = listShowMore
    const getIndex = newChangeList.findIndex((item) => item.id === id)
    newChangeList[getIndex].showMore = !newChangeList[getIndex].showMore
    setShowMore([...newChangeList])
  }

  React.useEffect(() => {
    addShowMore()
    dataSource && setTaskList(dataSource)
  }, [dataSource])

  const onCaseDetailView = (item: OBTaskQueryData) => {
    if (permissions.includes(Permission['ViewDetailOBTaskList']))
      // navigation.navigate('OBCallOutDetailScreen', {
      //   caseID: item.businessKey,
      //   basket: props.selectedBasket?.basketType || '',
      //   policyNumber: item.policyNumber || '',
      //   isInquiry: userInfo?.email !== item.assignee ? '2' : '0',
      //   callProgram: item.transactionType || '',
      //   policyOwner: item.poName || '',
      //   processInstanceId: item.processInstanceId || '',
      //   clientNumber: item.clientNumber || '',
      //   assignee: item.assignee || '',
      //   teamLeader: item.leader || '',
      //   taskId: item.id ? item.id : '',
      //   category: item.category || '',
      //   transactionType: item.transactionType || '',
      //   executionTime: item.executionTime || 0,
      //   ...(item.taskAgingDays && { agingDays: item.taskAgingDays })
      // })
      navigation.navigate('OutboundStack',
        {
          screen: 'OBCallOutDetailScreen',
          params: {
            caseID: item.businessKey,
            basket: props.selectedBasket?.basketType || '',
            policyNumber: item.policyNumber || '',
            isInquiry: userInfo?.email !== item.assignee ? '2' : '0',
            callProgram: item.transactionType ? getLabelCallProgram(item.transactionType) : '',
            policyOwner: item.poName || '',
            processInstanceId: item.processInstanceId || '',
            clientNumber: item.clientNumber || '',
            assignee: item.assignee || '',
            teamLeader: item.leader || '',
            taskId: item.id ? item.id : '',
            category: item.category || '',
            transactionType: item.transactionType || '',
            executionTime: item.executionTime || 0,
            ...(item.taskAgingDays !== undefined && { agingDays: item.taskAgingDays ?? 0 })
          }
        })
  }

  const getLabelCallProgram = (callProgram: string) => {
    const findCallProgram = mapCallProgram.get(callProgram as OBCallProgramTypeCode)
    return findCallProgram || ''
  }

  const onRowSelectEvent = (isChecked: boolean, task: OBTaskQueryData) => {
    if (isChecked) {
      onSelectedTaskEvent && onSelectedTaskEvent(task)
    } else {
      onUnSelectedTaskEvent && onUnSelectedTaskEvent(task)
    }
  }

  const getShortTextForCaseID = (value: string) => {
    return value.length > 22 ? value.slice(0, 23) : value
  }

  const getAgencyName = (agencyCode: string) => {
    return pipe(TaskConst.agencyList.find((x) => x.value === agencyCode),
      O.fromNullable,
      O.map((item) => item.label),
      O.getOrElse(() => '')
    )
  }

  const getColumnsByBasketType = (index: number) => {
    let headerColumn: JSX.Element = <></>
    switch (selectedBasket?.basketType) {
      case SubTaskType.REQC:
      case SubTaskType.QC:
        {
          headerColumn = (
            <>
              <View>
                <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:CallingProgram')}:</Text>
                <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:ClientNumber')}:</Text>
                <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:ClientName')}:</Text>
                <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:LastAssignee')}:</Text>
                <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:LastActivity')}:</Text>
                <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:AppointmentDate')}:</Text>
                <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:FirstIssueDate')}:</Text>
                <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:LastIssueDate')}:</Text>
                <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:CreatedDate')}:</Text>
                <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:SubmitDate')}:</Text>
                <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:ExecutionTime')}:</Text>
              </View>
              {listShowMore.length > 0 && index < listShowMore.length && listShowMore[index].showMore && (
                <View>
                  <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:Leader')}:</Text>
                  <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:Agency')}:</Text>
                  <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:CaseID')}:</Text>
                  <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:QCAssignee')}:</Text>
                </View>
              )}
            </>
          )
          break
        }
      case SubTaskType.ErrorHandlingOB: {
        headerColumn = (
          <>
            <View>
              <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:CallingProgram')}:</Text>
              <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:ClientNumber')}:</Text>
              <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:ClientName')}:</Text>
              <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:Agency')}:</Text>
              <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:DuplicatePO')}:</Text>
              <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:AppointmentDate')}:</Text>
              <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:CreatedDate')}:</Text>
              <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:TotalAging')}:</Text>
              <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:ExecutionTime')}:</Text>
              <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:CurrentAssignee')}:</Text>
            </View>
            {listShowMore.length > 0 && index < listShowMore.length && listShowMore[index].showMore && (
              <View>
                <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:Leader')}:</Text>
                <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:StuckTime')}:</Text>
                <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:CurrentActivity')}:</Text>
                <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:CaseID')}:</Text>
              </View>
            )}
          </>
        )
        break
      }
      case SubTaskType.Suspend:
      case SubTaskType.Verification:
      default:
        {
          headerColumn = (
            <>
              <View>
                <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:CallingProgram')}:</Text>
                <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:Agency')}:</Text>
                <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:ExecutionTime')}:</Text>
                <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:ClientNumber')}:</Text>
                <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:ClientName')}:</Text>
                <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:CurrentAssignee')}:</Text>
                <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:LastAssignee')}:</Text>
                <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:CaseStatus')}:</Text>
                <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:LastActivity')}:</Text>
                <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:DuplicatePO')}:</Text>
                <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:AppointmentDate')}:</Text>
                <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:CreatedDate')}:</Text>
                <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:FirstIssueDate')}:</Text>
                <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:LastIssueDate')}:</Text>
              </View>
              {listShowMore.length > 0 && index < listShowMore.length && listShowMore[index].showMore && (
                <View>
                  <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:TotalAging')}:</Text>
                  <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:AgingDays')}:</Text>
                  <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:VIP/NonVIPofPolicyowner')}:</Text>
                  <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:Leader')}:</Text>
                  <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:CaseID')}:</Text>
                </View>
              )}
            </>
          )
          break
        }
    }
    return headerColumn
  }

  const getTableCellByBasketType = (dataItem: OBTaskQueryData, index: number) => {
    switch (selectedBasket?.basketType) {
      case SubTaskType.REQC:
      case SubTaskType.QC:
        {
          return (
            <>
              <View>
                <Text style={taskStyles.blockTitle}>{dataItem.transactionType ? getLabelCallProgram(dataItem.transactionType) : ''}</Text>
                <Text style={taskStyles.blockTitle}>{dataItem.clientNumber}</Text>
                <Text style={taskStyles.blockTitle}>{dataItem.poName || ''}</Text>
                <Text style={taskStyles.blockTitle}>
                  {dataItem.lastAssignee}
                </Text>
                <Text style={taskStyles.blockTitle}>{getLastActivityName(dataItem.lastActivity || '')}</Text>
                <Text style={taskStyles.blockTitle}>{dataItem.appointmentDate ? PulseOpsFormat.dateStringtoFormat(dataItem.appointmentDate, 'DD/MM/YYYY - HH:mm:ss') : ''}</Text>
                <Text style={taskStyles.blockTitle}>{dataItem.policyFirstIssuedDate ? PulseOpsFormat.dateStringtoFormat(dataItem.policyFirstIssuedDate, 'DD/MM/YYYY') : ''}</Text>
                <Text style={taskStyles.blockTitle}>{dataItem.policyLastIssuedDate ? PulseOpsFormat.dateStringtoFormat(dataItem.policyLastIssuedDate, 'DD/MM/YYYY') : ''}</Text>
                <Text style={taskStyles.blockTitle}>{!!dataItem.createdDate ? OBFormat.formatDateToDateString(dataItem.createdDate) : ''}</Text>
                <Text style={taskStyles.blockTitle}>{!!dataItem.lastUpdateByVer ? OBFormat.formatDateToDateString(new Date(dataItem.lastUpdateByVer)) : ''}</Text>
                <Text style={taskStyles.blockTitle}>{dataItem.executionTime || 0}</Text>
              </View>
              {listShowMore.length > 0 && index < listShowMore.length && listShowMore[index].showMore && (
                <View>
                  <Text style={taskStyles.blockTitle}>{dataItem.vip ?? ''}</Text>
                  <Text style={taskStyles.blockTitle}>{dataItem.leader || ''}</Text>
                  <Text style={taskStyles.blockTitle}>{getAgencyName(dataItem.agency || '')}</Text>
                  <Text style={taskStyles.blockTitle}>{getShortTextForCaseID(dataItem.businessKey || '')}</Text>
                  {[SubTaskType.QC, SubTaskType.REQC].includes(selectedBasket?.basketType as SubTaskType) && <><Text style={taskStyles.blockTitle}>{dataItem.assignee}</Text></>}
                </View>
              )}
            </>
          )
        }
      case SubTaskType.ErrorHandlingOB:
      {
        return (
          <>
            <View>
              <Text style={taskStyles.blockTitle}>{dataItem.transactionType ? getLabelCallProgram(dataItem.transactionType) : ''}</Text>
              <Text style={taskStyles.blockTitle}>{dataItem.clientNumber}</Text>
              <Text style={taskStyles.blockTitle}>{dataItem.poName || ''}</Text>
              <Text style={taskStyles.blockTitle}>{getAgencyName(dataItem.agency || '')}</Text>
              <Text style={taskStyles.blockTitle}>{dataItem.isDuplicatePo ? 'True' : 'False'}</Text>
              <Text style={taskStyles.blockTitle}>{dataItem.appointmentDate ? PulseOpsFormat.dateStringtoFormat(dataItem.appointmentDate, 'DD/MM/YYYY - HH:mm:ss') : ''}</Text>
              <Text style={taskStyles.blockTitle}>{!!dataItem.createdDate ? OBFormat.formatDateToDateString(dataItem.createdDate) : ''}</Text>
              <Text style={taskStyles.blockTitle}>{dataItem.agingDays}</Text>
              <Text style={taskStyles.blockTitle}>{dataItem.executionNumber || 0}</Text>
              <Text style={taskStyles.blockTitle}>{dataItem.assignee || ''}</Text>
            </View>
            {listShowMore.length > 0 && index < listShowMore.length && listShowMore[index].showMore && (
              <View>
                <Text style={taskStyles.blockTitle}>{dataItem.leader || ''}</Text>
                <Text style={taskStyles.blockTitle}>{dataItem.stuckDate ? PulseOpsFormat.dateStringtoFormat(dataItem.stuckDate, 'DD/MM/YYYY - HH:mm:ss') : ''}</Text>
                <Text style={taskStyles.blockTitle}>{dataItem.currentActivityName}</Text>
                <Text style={taskStyles.blockTitle}>{getShortTextForCaseID(dataItem.businessKey || '')}</Text>
              </View>
            )}
          </>
        )
      }
      case SubTaskType.Suspend:
      case SubTaskType.Verification:
      default: {
        return (
          <>
            <View>
              <Text style={taskStyles.blockTitle}>{dataItem.transactionType ? getLabelCallProgram(dataItem.transactionType) : ''}</Text>
              <Text style={taskStyles.blockTitle}>{getAgencyName(dataItem.agency || '')}</Text>
              <Text style={taskStyles.blockTitle}>{dataItem.executionTime || 0}</Text>
              <Text style={taskStyles.blockTitle}>{dataItem.clientNumber}</Text>
              <Text style={taskStyles.blockTitle}>{dataItem.poName || ''}</Text>
              <Text style={taskStyles.blockTitle}>{dataItem.assignee || ''}</Text>
              <Text style={taskStyles.blockTitle}>{dataItem.lastVerUpdate}</Text>
              <Text style={taskStyles.blockTitle}>
                <OBTaskStatus statusText={dataItem.status || ''}></OBTaskStatus>
              </Text>
              <Text style={taskStyles.blockTitle}>{getLastActivityName(dataItem.lastActivity || '')}</Text>
              <Text style={taskStyles.blockTitle}>{dataItem.isDuplicatePo ? 'True' : 'False'}</Text>
              <Text style={taskStyles.blockTitle}>{dataItem.appointmentDate ? PulseOpsFormat.dateStringtoFormat(dataItem.appointmentDate, 'DD/MM/YYYY - HH:mm:ss') : ''}</Text>
              <Text style={taskStyles.blockTitle}>{!!dataItem.createdDate ? OBFormat.formatDateToDateString(dataItem.createdDate) : ''}</Text>
              <Text style={taskStyles.blockTitle}>{dataItem.policyFirstIssuedDate ? PulseOpsFormat.dateStringtoFormat(dataItem.policyFirstIssuedDate, 'DD/MM/YYYY') : ''}</Text>
              <Text style={taskStyles.blockTitle}>{dataItem.policyLastIssuedDate ? PulseOpsFormat.dateStringtoFormat(dataItem.policyLastIssuedDate, 'DD/MM/YYYY') : ''}</Text>
            </View>
            {listShowMore.length > 0 && index < listShowMore.length && listShowMore[index].showMore && (
              <View>
                <Text style={taskStyles.blockTitle}>{dataItem.agingDays}</Text>
                <Text style={taskStyles.blockTitle}>{selectedBasket?.basketType === 'SUSPENDED' ? dataItem.suspendAgingDays : dataItem.taskAgingDays}</Text>
                <Text style={taskStyles.blockTitle}>{dataItem.vip ?? ''}</Text>
                <Text style={taskStyles.blockTitle}>{dataItem.leader || ''}</Text>
                <Text style={taskStyles.blockTitle}>{getShortTextForCaseID(dataItem.businessKey || '')}</Text>
              </View>
            )}
          </>
        )
      }
    }
  }

  return (
    <View style={taskStyles.container}>
      <ScrollView showsVerticalScrollIndicator={true}>
        <View style={taskStyles.row}>
          {taskList && taskList.length > 0 && taskList.map((dataItem, index) => {
            return (
              <View style={[taskStyles.blockContainer, listShowMore.length > 0 && index < listShowMore.length && listShowMore[index].showMore && taskStyles.showMoreInfo]} key={`item${index}`}>
                <View style={taskStyles.blockHeader}>
                  {selectedBasket?.basketType !== SubTaskType.ErrorHandlingOB &&
                    <Checkbox
                      onChange={(checked) => {
                        onRowSelectEvent(checked, dataItem)
                      }}
                      value={selectedTaskList?.has(dataItem.id ?? '')}
                      disabled={false}
                      checkBoxStyle={taskStyles.checkBox}
                    />
                  }
                  {selectedBasket?.basketType !== SubTaskType.ErrorHandlingOB ?
                    <Pressable onPress={() => onCaseDetailView(dataItem)}>
                      <Text style={taskStyles.blockPolicyText}>{dataItem.policyNumber}</Text>
                    </Pressable> :
                    <Text style={taskStyles.blockTextNormal}>{dataItem.policyNumber}</Text>
                  }
                </View>
                <View style={taskStyles.blockBody}>
                  <View style={{ flex: 50 }}>
                    {/* <View>
                      <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:CallingProgram')}:</Text>
                      <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:ClientNumber')}:</Text>
                      <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:ClientName')}:</Text>
                      {[SubTaskType.Verification, SubTaskType.Suspend].includes(selectedBasket?.basketType as SubTaskType) && (
                        <>
                          <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:CurrentAssignee')}:</Text>
                          <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:CaseStatus')}:</Text>
                          <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:DuplicatePO')}:</Text>
                        </>
                      )}
                      {[SubTaskType.QC, SubTaskType.REQC, SubTaskType.Suspend].includes(selectedBasket?.basketType as SubTaskType) && <><Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:LastAssignee')}:</Text></>}
                      <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:LastActivity')}:</Text>
                      <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:AppointmentDate')}:</Text>
                      <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:FirstIssueDate')}:</Text>
                      <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:LastIssueDate')}:</Text>
                      <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:CreatedDate')}:</Text>
                      {[SubTaskType.QC, SubTaskType.REQC].includes(selectedBasket?.basketType as SubTaskType) && <><Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:SubmitDate')}:</Text></>}
                      {[SubTaskType.Verification, SubTaskType.Suspend].includes(selectedBasket?.basketType as SubTaskType) && (
                        <>
                          <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:TotalAging')}:</Text>
                          <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:AgingDays')}:</Text>
                        </>
                      )}
                      <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:ExecutionTime')}:</Text>
                    </View>
                    {listShowMore.length > 0 && index < listShowMore.length && listShowMore[index].showMore && (
                      <View>
                        <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:VIP/NonVIPofPolicyowner')}:</Text>
                        <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:Leader')}:</Text>
                        <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:Agency')}:</Text>
                        <Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:CaseID')}:</Text>
                        {[SubTaskType.QC, SubTaskType.REQC].includes(selectedBasket?.basketType as SubTaskType) && <><Text style={taskStyles.blockTitle}>{t('Outbound:OBTaskManagement:QCAssignee')}:</Text></>}
                      </View>
                    )} */}
                    {getColumnsByBasketType(index)}
                  </View>
                  <View style={{ flex: 50 }}>
                    {/* <View>
                      <Text style={taskStyles.blockTitle}>{dataItem.transactionType ? getLabelCallProgram(dataItem.transactionType) : ''}</Text>
                      <Text style={taskStyles.blockTitle}>{dataItem.clientNumber}</Text>
                      <Text style={taskStyles.blockTitle}>{dataItem.poName || ''}</Text>
                      {[SubTaskType.Verification, SubTaskType.Suspend].includes(selectedBasket?.basketType as SubTaskType) && (
                        <>
                          <Text style={taskStyles.blockTitle}>{dataItem.assignee || ''}</Text>
                          <Text style={taskStyles.blockTitle}>
                            <OBTaskStatus statusText={dataItem.status || ''}></OBTaskStatus>
                          </Text>
                          <Text style={taskStyles.blockTitle}>{dataItem.isDuplicatePo ? 'True' : 'False'}</Text>
                        </>
                      )}
                      {[SubTaskType.QC, SubTaskType.REQC, SubTaskType.Suspend].includes(selectedBasket?.basketType as SubTaskType) && <>
                        <Text style={taskStyles.blockTitle}>
                          {selectedBasket?.basketType === SubTaskType.Suspend ? dataItem.lastVerUpdate : dataItem.lastAssignee}
                        </Text>
                      </>
                      }
                      <Text style={taskStyles.blockTitle}>{getLastActivityName(dataItem.lastActivity || '')}</Text>
                      <Text style={taskStyles.blockTitle}>{dataItem.appointmentDate ? PulseOpsFormat.dateStringtoFormat(dataItem.appointmentDate, 'DD/MM/YYYY - HH:mm:ss') : ''}</Text>
                      <Text style={taskStyles.blockTitle}>{dataItem.policyFirstIssuedDate ? PulseOpsFormat.dateStringtoFormat(dataItem.policyFirstIssuedDate, 'DD/MM/YYYY') : ''}</Text>
                      <Text style={taskStyles.blockTitle}>{dataItem.policyLastIssuedDate ? PulseOpsFormat.dateStringtoFormat(dataItem.policyLastIssuedDate, 'DD/MM/YYYY') : ''}</Text>
                      <Text style={taskStyles.blockTitle}>{!!dataItem.createdDate ? OBFormat.formatDateToDateString(dataItem.createdDate) : ''}</Text>
                      {[SubTaskType.QC, SubTaskType.REQC].includes(selectedBasket?.basketType as SubTaskType) && <><Text style={taskStyles.blockTitle}>{!!dataItem.lastUpdateByVer ? OBFormat.formatDateToDateString(new Date(dataItem.lastUpdateByVer)) : ''}</Text></>}
                      {[SubTaskType.Verification, SubTaskType.Suspend].includes(selectedBasket?.basketType as SubTaskType) && (
                        <>
                          <Text style={taskStyles.blockTitle}>{dataItem.agingDays}</Text>
                          <Text style={taskStyles.blockTitle}>{selectedBasket?.basketType === 'SUSPENDED' ? dataItem.suspendAgingDays : dataItem.taskAgingDays}</Text>
                        </>
                      )}
                      <Text style={taskStyles.blockTitle}>{dataItem.executionTime || 0}</Text>
                    </View>
                    {listShowMore.length > 0 && index < listShowMore.length && listShowMore[index].showMore && (
                      <View>
                        <Text style={taskStyles.blockTitle}>{dataItem.vip ?? ''}</Text>
                        <Text style={taskStyles.blockTitle}>{dataItem.leader || ''}</Text>
                        <Text style={taskStyles.blockTitle}>{getAgencyName(dataItem.agency || '')}</Text>
                        <Text style={taskStyles.blockTitle}>{getShortTextForCaseID(dataItem.businessKey || '')}</Text>
                        {[SubTaskType.QC, SubTaskType.REQC].includes(selectedBasket?.basketType as SubTaskType) && <><Text style={taskStyles.blockTitle}>{dataItem.assignee}</Text></>}
                      </View>
                    )} */}
                    {getTableCellByBasketType(dataItem, index)}
                  </View>
                </View>
                <View style={taskStyles.blockFooter}>
                  <TouchableOpacity style={taskStyles.btnMoreInfo} onPress={() => changeShowMore(index)} activeOpacity={0.7}>
                    <Text style={taskStyles.btnMoreInfoTitle}>
                      {checkShowMore(index) ? t('TaskManagement:SeeLess') : t('TaskManagement:Moreinfo')}
                    </Text>
                    {checkShowMore(index) ? <assets.SeeLessTaskIcon /> : <assets.ShowMoreTaskIcon />}
                  </TouchableOpacity>
                </View>
              </View>
            )
          })}
        </View>
        {taskList && taskList.length > 0 && (
          <View style={taskStyles.seeMoreContainer}>
            <TouchableOpacity onPress={() => {
              const moreSi = moreSize + (pageSize ?? 0)
              setMoreSize(moreSi)
              onSeeMoreCaseEvent && onSeeMoreCaseEvent(0, moreSi)
            }}>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <Text style={taskStyles.btnMoreInfoTitle}>{t('TaskManagement:Seemorecases')}</Text>
                <assets.ShowMoreTaskIcon />
              </View>
            </TouchableOpacity>
          </View>
        )}

      </ScrollView>
    </View>

  )
}
const taskStyles = StyleSheet.create({
  container: {
    marginBottom: 24,
    backgroundColor: '#F4F4F4',
    borderRadius: 8,
    maxHeight: '54vh',
    width: '100%'
  },
  blockContainer: {
    marginTop: 24,
    marginLeft: 24,
    borderRadius: 6,
    backgroundColor: '#fff',
    minHeight: 380,
    width: '30%',
    boxShadow: '2px 2px 10px 0px rgba(0, 0, 0, 0.06)'
  },
  blockHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 10,
    paddingTop: 10
  },
  blockBody: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 8,
    paddingHorizontal: 10
  },
  showMoreInfo: {
    minHeight: 467,
  },
  checkBox: {
    padding: 0,
    margin: 0
  },
  blockPolicyText: {
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 24,
    textDecorationLine: 'underline',
    color: '#1EA5FC'
  },
  blockTitle: {
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: '400',
    marginBottom: 8,
    // lineHeight: 21,
    // minHeight: 21
    lineHeight: 15,
    minHeight: 28
  },
  blockText: {
    color: '#4F4F4F',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: '600',
    marginBottom: 8,
    lineHeight: 21
  },
  blockFooter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    borderTopWidth: 1,
    borderTopColor: '#d6d4d4'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 20,
    flexWrap: 'wrap',
  },
  btnMoreInfo: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 12,
    marginBottom: 12,
    marginEnd: 2,
    alignSelf: 'baseline'
  },
  btnMoreInfoTitle: {
    marginEnd: 4,
    color: '#333333',
    fontSize: 15,
    fontWeight: '500',
    lineHeight: 17
  },
  seeMoreContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingVertical: 24
  },
  blockTextNormal: {
    fontSize: 15,
    lineHeight: 24
  },
})
