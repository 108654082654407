import { Task, Throwable, ZIO } from '@mxt/zio'
import { pipe } from 'fp-ts/lib/function'
import { PartialWithdrawal, POApi } from '@pulseops/common'
import { AgentInfoC } from './CepAgentModels'
import { CustomerInfoC, DuplicationPhoneC, DuplicationPhoneResponseC } from './CepCustomerModels'
import { ProductPolicyRes } from './CepProductModels'
import { PolicyInfoC, TransactionHistoryC, TransactionHistoryRes } from './CepPolicyModels'
import { policySearch, policySearchResponse } from './CepSearchModels'
import {
  BillingFreqRes,
  BonusReverseDataC,
  BonusReverseInfoC,
  CoreTransactionData,
  CoreTransactionHistoryC,
  dataBillingFreq,
  PolicyValueC,
  PolicyValueResponseC,
  BonusHistoryDataInfo,
  PruCashBenefitDataInfo,
  PruCashBenefitReq,
  BillingChangeHistory
} from './CepPolicyValueModel'
import {
  extraInfoC,
  ExtraInfoResponse,
  FundAllocate,
  ILPTopupHistory,
  PartialWithDraw,
  PartialWithDrawRequest,
  PartialWithDrawResponse,
  PartialWithDrawResponseILP,
  ProductTypeC,
  ULPTopupHistory
} from './CepInvesmentModels'
import { PremiumHisDetailReq, PremiumHisReq, PremiumHistoryDetailC, PremiumHistoryInfo } from './CepPremiumModels'
import {
  PruRewardDataC,
  PruRewardDataCusNoteC,
  PruRewardDataGiftC,
  PruRewardDataPointC,
  RewardPointSearchC,
  PruRewardDataTierHistoryC,
  GiftRedemptionSearchC,
  PruRewardDataCodeC,
  CodeRedemptionSearchC,
  PruRewardDataAgentC
} from './CepPreRewardModels'
import { NBUWRequestInfoC, NBUWRootResponseC } from './CepNbwuModels'
import { PaymentData, TranPaymentData, PaymentDataP2P } from './CepPaymentModels'

export const getAgentInfo = (policyNum: string): Task<AgentInfoC> =>
  pipe(
    POApi.get(`cep-rest/policy/${policyNum}/agent`)(AgentInfoC),
    ZIO.map((res) => res)
  )

export const getProductInfo = (policyNum: string): Task<ProductPolicyRes> =>
  pipe(
    POApi.get(`cep-rest/policy/${policyNum}/product`)(ProductPolicyRes),
    ZIO.map((res) => {
      return res
    })
  )

export const getCustomerInfo = (policyNum: string): Task<CustomerInfoC> =>
  pipe(
    POApi.get(`cep-rest/policy/${policyNum}/customer`)(CustomerInfoC),
    ZIO.mapError((err) => {
      return Throwable(err)
    }),
    ZIO.flatMap((response) => {
      if (!response.error) {
        return ZIO.succeed(response)
      } else {
        return ZIO.fail(Throwable('Customer error'))
      }
    })
  )

export const getPolicyInfo = (policyNum: string): Task<PolicyInfoC> =>
  pipe(
    POApi.get(`cep-rest/policy/${policyNum}`)(PolicyInfoC),
    ZIO.map((res) => {
      return res
    })
  )

export const searchPolicyInfo = (data: policySearch): Task<policySearchResponse> =>
  pipe(
    POApi.post(`cep-rest/policy/search`)(policySearchResponse)(data),
    ZIO.map((res) => {
      return res
    })
  )
export const getProductType = (policyNum: string): Task<ProductTypeC> =>
  pipe(
    POApi.get(`cep-rest/policy/${policyNum}/detail`)(ProductTypeC),
    ZIO.map((res) => res)
  )

export const getInvestmentInfo = (data: extraInfoC): Task<ExtraInfoResponse> =>
  pipe(
    POApi.post(`cep-rest/policy/extra-info`)(ExtraInfoResponse)(data),
    ZIO.map((res) => res)
  )

export const getPolicyValueInfo = (data: PolicyValueC): Task<PolicyValueResponseC> =>
  pipe(
    POApi.post(`cep-rest/policy/value`)(PolicyValueResponseC)(data),
    ZIO.map((res) => res)
  )

export const getPolicyBonusReverse = (data: BonusReverseDataC): Task<BonusReverseInfoC> =>
  pipe(
    POApi.post(`cep-rest/policy/calc-bonus-reverse`)(BonusReverseInfoC)(data),
    ZIO.map((res) => res)
  )

export const getTransactionHistory = (policyNo: string, data: TransactionHistoryC): Task<TransactionHistoryRes> =>
  pipe(
    POApi.post(`cep-rest/policy/${policyNo}/transaction-history`)(TransactionHistoryRes)(data),
    ZIO.map((res) => res)
  )

export const getBillingFrequency = (data: dataBillingFreq) =>
  pipe(
    POApi.post(`cep-rest/policy/billing-frequency-change`)(BillingFreqRes)(data),
    ZIO.map((res) => res)
  )

export const getPremiumHistory = (data: PremiumHisReq) =>
  pipe(
    POApi.post(`cep-rest/policy/premium-history`)(PremiumHistoryInfo)(data),
    ZIO.map((res) => res)
  )

export const getPremiumHistoryDetail = (data: PremiumHisDetailReq) =>
  pipe(
    POApi.post(`cep-rest/policy/premium-history/detail`)(PremiumHistoryDetailC)(data),
    ZIO.map((res) => res)
  )

export const getCoreTransaction = (data: CoreTransactionHistoryC) =>
  pipe(
    POApi.post(`cep-rest/policy/core-transaction-history`)(CoreTransactionData)(data),
    ZIO.map((res) => res)
  )

export const getDataPreReward = (policyNo: string) =>
  pipe(
    POApi.get(`cep-rest/client/${policyNo}/pru-reward`)(PruRewardDataC),
    ZIO.map((res) => res)
  )

export const getDuplicationPhone = (data: DuplicationPhoneC) =>
  pipe(
    POApi.post(`cep-rest/client/duplicate-phone-number`)(DuplicationPhoneResponseC)(data),
    ZIO.map((res) => res)
  )

export const getNBUWRequestInfo = (data: NBUWRequestInfoC) =>
  pipe(
    POApi.post(`cep-rest/new-business-underwriting/request-info`)(NBUWRootResponseC)(data),
    ZIO.map((res) => res)
  )

export const getPruCashBenInfo = (data: PruCashBenefitReq) =>
  pipe(
    POApi.post(`cep-rest/policy/loan-detail`)(PruCashBenefitDataInfo)(data),
    ZIO.map((res) => res.data)
  )

export const getBonusHistory = (policyNum: string): Task<BonusHistoryDataInfo> =>
  pipe(
    POApi.get(`cep-rest/policy/${policyNum}/bonus-history`)(BonusHistoryDataInfo),
    ZIO.map((res) => res)
  )

export const getILPTopupHistory = (policyNum: string): Task<ILPTopupHistory> =>
  pipe(
    POApi.get(`cep-rest/policy/${policyNum}/topup-ilp-history`)(ILPTopupHistory),
    ZIO.map((res) => {
      return {
        ...res,
        data: res.data.map((item) => ({
          transactionDate: item.transactionDate ?? '',
          fundId: item.fundId ?? '',
          effectivePrice: item.effectivePrice ?? 0,
          fundAmount: item.fundAmount ?? 0,
          realUnit: item.realUnit ?? 0,
          contractAmount: item.contractAmount ?? 0,
          topUpPremium: item.topUpPremium ?? 0,
          sourcePercentAmount: item.sourcePercentAmount ?? 0
        }))
      }
    })
  )

export const getULPTopupHistory = (policyNum: string): Task<ULPTopupHistory> =>
  pipe(
    POApi.get(`cep-rest/top-up/ulp-history/${policyNum}`)(ULPTopupHistory),
    ZIO.map((res) => res)
  )

export const getFundAllocate = (policyNum: string): Task<FundAllocate> =>
  pipe(
    POApi.get(`cep-rest/policy/${policyNum}/fund-allocate`)(FundAllocate),
    ZIO.map((res) => res)
  )

export const getPaymentData = (policyNum: string): Task<PaymentData> =>
  pipe(
    POApi.get(`cep-rest/policy/${policyNum}/payment-info`)(PaymentData),
    ZIO.map((res) => res)
  )

export const getPartialWithDrawILP = (policyNum: string): Task<PartialWithDrawResponseILP> =>
  pipe(
    POApi.get(`cep-rest/policy/${policyNum}/partial-with-draw-ilp`)(PartialWithDrawResponseILP),
    ZIO.map((res) => {
      return res
    })
  )

export const getPartialWithDrawIULP = (policyNum: string): Task<PartialWithDrawResponse> =>
  pipe(
    POApi.get(`cep-rest/policy/${policyNum}/partial-with-draw-ulp`)(PartialWithDrawResponse),
    ZIO.map((res) => {
      return res
    })
  )

export const calcPWAmount = (policyNum: string, res: PartialWithDrawRequest) =>
  pipe(
    POApi.post(`cep-rest/policy/${policyNum}/partial-with-draw-ulp`)(PartialWithDrawResponse)(res),
    ZIO.map((res) => res)
  )

export const calcPWAmountILP = (policyNum: string, res: PartialWithDrawRequest) =>
  pipe(
    POApi.post(`cep-rest/policy/${policyNum}/partial-with-draw-ilp`)(PartialWithDrawResponseILP)(res),
    ZIO.foldM(
      (error) => {
        return ZIO.fail(error)
      },
      (data) => {
        return ZIO.succeed(data)
      }
    )
  )

export const getBillingChangeHistory = (policyNum: string): Task<BillingChangeHistory> =>
  pipe(
    POApi.get(`cep-rest/policy/${policyNum}/billing-change-history`)(BillingChangeHistory),
    ZIO.map((res) => {
      return res
    })
  )

export const getTranPayment = (policyNum: string, paymentNo: string): Task<TranPaymentData> =>
  pipe(
    POApi.get(`cep-rest/payment/transaction/${policyNum}?paymentNo=${paymentNo}`)(TranPaymentData),
    ZIO.map((res) => {
      return res
    }))

export const getPaymentP2P = (policyNum: string) =>
  pipe(
    POApi.get(`cep-rest/payment/returned-transaction/${policyNum}`)(PaymentDataP2P),
    ZIO.map((res) => res.data)
  )
  
export const getDataPreRewardGiftRed = (policyNo: string, data: GiftRedemptionSearchC) =>
  pipe(
    POApi.post(`cep-rest/client/${policyNo}/gift-redemption-history`)(PruRewardDataGiftC)(data),
    ZIO.map((res) => res)
  )

export const getDataPreRewardCodeRed = (policyNo: string, data: CodeRedemptionSearchC) =>
  pipe(
    POApi.post(`cep-rest/client/${policyNo}/redeem-code-history`)(PruRewardDataCodeC)(data),
    ZIO.map((res) => res)
  )

export const getDataPreRewardPoint = (policyNo: string, data: RewardPointSearchC) =>
  pipe(
    POApi.post(`cep-rest/client/${policyNo}/reward-point-history`)(PruRewardDataPointC)(data),
    ZIO.map((res) => res)
  )

export const getDataPreRewardCusNote = (policyNo: string) =>
  pipe(
    POApi.get(`cep-rest/client/${policyNo}/customer-note`)(PruRewardDataCusNoteC),
    ZIO.map((res) => res)
  )

export const getDataPreRewardTierHistory = (policyNo: string) =>
  pipe(
    POApi.get(`cep-rest/client/${policyNo}/tier-history`)(PruRewardDataTierHistoryC),
    ZIO.map((res) => res)
  )

export const getDataPruRewardInit = (
  PolicyNo: string,
  seachPoint: RewardPointSearchC,
  searchGift: GiftRedemptionSearchC,
  searchRedeem: CodeRedemptionSearchC
) =>
  pipe(
    ZIO.zipPar(
      getDataPreRewardPoint(PolicyNo, seachPoint),
      getDataPreRewardGiftRed(PolicyNo, searchGift),
      getDataPreRewardCodeRed(PolicyNo, searchRedeem),
      getDataPreRewardCusNote(PolicyNo),
      getDataPreRewardTierHistory(PolicyNo)
    ),
    ZIO.map(([points, gifts, redeems, notes, tiers]) => {
      return {
        rewardPoints: points ?? {},
        giftRedemptions: gifts ?? {},
        codeRedeems: redeems ?? {},
        customerNotes: notes.data ?? [],
        customerTierHistories: tiers ?? {}
      }
    })
  )

export const getDataPreRewardAgent = (policyNo: string) =>
  pipe(
    POApi.get(`cep-rest/pru-reward/${policyNo}/agent`)(PruRewardDataAgentC),
    ZIO.map((res) => res)
  )
