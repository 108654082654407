import { ZIO } from '@mxt/zio'
import { pipe } from 'fp-ts/lib/function'
import { POApi } from '../POApi'
import { ReleasePolicy } from './model/ReleasePolicy'

export namespace ReleasePolicyService {
  export const getListReleasePolicy = (input: ReleasePolicy.InputData) =>
    pipe(
      POApi.post(`wf-api/aml-tool/get-aml-release`)(ReleasePolicy.Response)(input),
      ZIO.map((result) => {
        return result
      })
    )
  export const importReleasePolicyFile = (file: File) => {
    const formData = new FormData()
    formData.append('file', file)
    return pipe(
      POApi.post(`wf-api/aml-tool/import-aml-release`, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })(ReleasePolicy.ResponseStatus)(formData),
      ZIO.map((status) => {
        return status
      })
    )
  }
}
