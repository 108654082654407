import UTIF from 'utif'

export enum TypeInputComponent {
  INPUT = 'INPUT',
  DATE = 'DATE',
  SELECT = 'SELECT',
  IMPORT = 'IMPORT',
  CHECKBOX = 'CHECKBOX',
  RADIOGROUP = 'RADIOGROUP',
  COMBINED = 'COMBINED',
  CKEDITOR = 'CKEDITOR'
}
export type InputType = 'text' | 'number' | 'money'

export enum TypeDashBoardComponent {
  HEADER = 'HEADER',
  CONTENT = 'CONTENT',
  TREEVIEW = 'TREEVIEW',
  CONTENT_ICON = 'CONTENT_ICON',
  DIVIDER = 'DIVIDER',
  CONTENT_POPUP = 'CONTENT_POPUP'
}

export interface DashBoardHeader {
  type?: TypeDashBoardComponent.HEADER
  clientName: string
  nationName: string
  gender: string
  level?: string | null
  divider?: IBDividerProps
  hidden?: boolean
}

export interface DashBoardContent {
  type?: TypeDashBoardComponent.CONTENT
  label: string
  value: string | JSX.Element
  color?: string
  bold?: boolean
  visible?: boolean
  textDecorate?: 'none' | 'underline' | 'line-through' | 'underline line-through' | undefined
  divider?: IBDividerProps
  hidden?: boolean
  dropDownShow?: boolean
  onClick?: () => void
}

export interface DashBoardTreeView {
  type?: TypeDashBoardComponent.TREEVIEW
  label: string
  value: string
  treeView: {
    label: string
    value: string
    color?: string
    bold?: boolean
    textDecorate?: 'none' | 'underline' | 'line-through' | 'underline line-through' | undefined
    visible?: boolean
    onClick?: () => void
  }[]
  divider?: IBDividerProps
  hidden?: boolean
}

export interface IBDashBoardContentIconProps {
  type?: TypeDashBoardComponent.CONTENT_ICON
  icon: JSX.Element
  content: {
    value: JSX.Element
  }[]
  textNoti?: string | null
  divider?: IBDividerProps
  hidden?: boolean
}

export interface IBDashBoardContentPopupProps {
  type?: TypeDashBoardComponent.CONTENT_POPUP
  label: string
  value: string
  openPopup: () => void
  divider?: IBDividerProps
  hidden?: boolean
}

export interface IBDividerProps {
  type?: TypeDashBoardComponent.DIVIDER
  borderStyle?: 'dashed' | 'solid' | 'dotted' | undefined
  marginHorizontal?: number
  hidden?: boolean
}

export const viewTiffFileByNewTab = (blobUrl: string) => {
  const xhr = new XMLHttpRequest()
  xhr.open('GET', blobUrl)
  xhr.responseType = 'arraybuffer'
  xhr.onload = function (this, e) {
    var buffer = this.response
    const ifds = UTIF.decode(buffer)
    const firstPageOfTif = ifds[0]
    UTIF.decodeImage(buffer, firstPageOfTif)
    const rgba = UTIF.toRGBA8(firstPageOfTif)

    const imageWidth = firstPageOfTif.width
    const imageHeight = firstPageOfTif.height

    const cnv = document.createElement('canvas')
    cnv.width = imageWidth
    cnv.height = imageHeight

    const ctx = cnv.getContext('2d')
    const imageData = ctx?.createImageData(imageWidth, imageHeight)
    if (imageData) {
      for (let i = 0; i < rgba.length; i++) {
        imageData.data[i] = rgba[i]
      }
      ctx?.putImageData(imageData, 0, 0)
    }
    const dataUrl = cnv.toDataURL('image/png', 1)
    var image = new Image()
    image.src = dataUrl
    var w = window.open('')
    w?.document.write(image.outerHTML)
  }
  xhr.send()
}

export const generateNameImg = (length: number) => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  let counter = 0
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
    counter += 1
  }
  return result
}

export const atob = (str: string) => {
  return Buffer.from(str, 'base64').toString('binary')
}

export function b64toBlob(dataURI: string, sliceSize = 512) {
  dataURI = dataURI.replace(/^data:/, '')

  const base64 = dataURI.replace(/^[^,]+,/, '')
  const type = dataURI.match(/image\/[^;]+/)?.[0]
  const byteCharacters = atob(base64)
  const byteArrays = []
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: type })
  return blob
}

export enum IBButtonMultiTasks {
  ChangeContactInfo = 'CHANGE_CONTACT_INFO',
  ClientCallingResult = 'CLIENT_CALLING_RESULT',
  CustomerBehavior = 'CUSTOMER_BEHAVIOR',
  ClickToSend = 'CLICK_TO_SEND'
}
