import React from 'react'
import { pipe } from 'fp-ts/lib/function'
import { useLoading } from '@mxt/zio-react'
import { Throwable, ZIO } from '@mxt/zio'
import {
  Columns,
  Container,
  ErrorHandling,
  FieldList,
  GeneralInfoService,
  Panel,
  PolicyValueInfo,
  PVI_APL_OPL,
  PVI_bens,
  TableField,
  useMobile,
  useTranslator
} from '@pulseops/common'
import { StyleSheet, Text, View } from 'react-native'

export interface PolicyValueInfoData {
  policyValue: PolicyValueInfo | null
  prucashBenefitDatasources: PVI_bens[]
  AOValueDetailDatasources: PVI_APL_OPL[]
}

export const PolicyValueScreen = (props: {
  id: string
  data: PolicyValueInfoData | null
  setData: (val: PolicyValueInfoData | null) => void
}) => {
  const { t } = useTranslator('TaskManagement')
  const [loading, bindLoading] = useLoading()
  const { isMobile } = useMobile()
  const [warningMessage, setWarningMessage] = React.useState<string>('')

  // const [policyValue, setPolicyValue] = useState<PolicyValueInfo>()
  // const [prucashBenefitDatasources, setPrucashBenefitDatasources] = useState<PVI_bens[]>([])
  // const [AOValueDetailDatasources, setAOValueDetailDatasources] = useState<PVI_APL_OPL[]>([])

  const getData = (): ZIO<unknown, Throwable, PolicyValueInfoData> =>
    pipe(
      GeneralInfoService.getPolicyValue(props.id),
      ZIO.map((_policyValue) => {
        // this.initTable(x)
        // this.APL_OPL_detail.dataSource = x.apl_opl_detail as never[]
        // this.prucash_ben_detail.dataSource = x.prucash_ben as never[]
        // this.errorMessage = x.apl_opl_detail.some(d => d.loanNumber === 0 || d.loanNumber === 99) ? PulseOpsMessage.MS090004 : ''
        // if (this.errorMessage) {
        //   this.contractTitle += `<p class="text-danger ml-3" style="font-size: 13px; padding-top: 2px;">${this.errorMessage}</p>`
        // }
        return {
          policyValue: _policyValue,
          prucashBenefitDatasources: _policyValue.prucash_ben,
          AOValueDetailDatasources: _policyValue.apl_opl_detail
        }
      })
    )

  React.useEffect(() => {
    return () => {
      setWarningMessage('')
    }
  }, [])

  const { policyValue, prucashBenefitDatasources, AOValueDetailDatasources } = pipe(
    props.data !== null ? ZIO.succeed(props.data) : getData(),
    ZIO.tap((x) => {
      if (x !== null && props.data === null) props.setData(x)
      const loanNumber99And00Row =  x.policyValue?.apl_opl_detail.filter(d => d.loanNumber === 0 || d.loanNumber === 99)
      loanNumber99And00Row && loanNumber99And00Row?.length >= 2 ? setWarningMessage(t('message:MS050275')) : setWarningMessage('')
      return ZIO.unit
    }),
    bindLoading,
    ErrorHandling.runDidMount()
  ) || {
    policyValue: null,
    prucashBenefitDatasources: [],
    AOValueDetailDatasources: []
  }

  const contractValue = [
    {
      id: 1,
      label: t('AutomaticPremiumLoanAPL'),
      value: policyValue?.APL
    },
    {
      id: 2,
      label: t('OriginalPolicyLoanOPL'),
      value: policyValue?.OPL
    },
    {
      id: 3,
      label: t('SurrenderValue'),
      value: policyValue?.surrVal
    },
    {
      id: 4,
      label: t('LoanAvailable'),
      value: policyValue?.loanAvalable
    },
    {
      id: 5,
      label: t('LoanAmount80SV'),
      value: policyValue?.loan80
    },
    {
      id: 6,
      label: t('BonusValue'),
      value: policyValue?.bonusVal
    },
    {
      id: 7,
      label: t('SpecialBonus'),
      value: policyValue?.specialBonus
    },
    {
      id: 8,
      label: t('BonusSurrender'),
      value: policyValue?.bonusSurr
    },
    {
      id: 9,
      label: t('SuspendAmount'),
      value: policyValue?.suspAmt
    },
    {
      id: 10,
      label: t('PrucashBenefit'),
      value: policyValue?.prucashBen
    },
    {
      id: 11,
      label: t('Prukid369'),
      value: policyValue?.PruKid
    },
    {
      id: 12,
      label: t('MaturityBenefit'),
      value: policyValue?.maturityBen
    },
    {
      id: 13,
      label: t('MaxPartialWithdrawal'),
      value: policyValue?.maxPwAmt
    }
  ]

  const prucashbenefitColumns = [
    {
      title: t('CashReleaseDate'),
      name: 'cashReleaseDate'
    },
    {
      title: t('PrincipalAmount'),
      name: 'principalAmount'
    },
    {
      title: t('InterestAmount'),
      name: 'interestAmount'
    },
    {
      title: t('TotalAmount'),
      name: 'totalPerPeriod'
    },
    {
      title: t('ReceivedDate'),
      name: 'receivedDate'
    },
    {
      title: t('ReceivedAmount'),
      name: 'receiveAmount'
    }
  ]
  const AOValueDetailColumns: Columns[] = [
    {
      key: 'ld',
      title: t('LoanDescription'),
      name: 'loanDesc'
    },
    {
      key: 'td',
      title: t('TransactionDate'),
      name: 'transDate'
    },
    {
      key: 'pa',
      title: t('PrincipalAmount'),
      name: 'principalAmount'
    },
    {
      key: 'cb',
      title: t('CurrentBalance'),
      name: 'currBalance'
    },
    {
      key: 'ai',
      title: t('AssuredInterest'),
      name: 'accuredInterest'
    },
    {
      key: 'pi',
      title: t('PendingInterest'),
      name: 'pendingInterest',
      styles: { textAlign: 'right' }
    }
  ]

  const existPrucash = prucashBenefitDatasources.length > 0
  const existAOValue = AOValueDetailDatasources.length > 0

  return (
    <Container loading={loading} display={true /*existPrucash || existAOValue */}>
      {isMobile ? (
        <>
          {warningMessage && (
            <View style={policyValueStyle.errorContainer}>
              <Text style={policyValueStyle.errorMessage}>{warningMessage}</Text>
            </View>
          )}
          <Panel title={t('ContractValue')}>
            <FieldList dataSource={contractValue} />
          </Panel>
          {existPrucash && (
            <Panel title={t('PrucashBenefitDetail')}>
              <TableField columns={prucashbenefitColumns} dataSource={prucashBenefitDatasources} />
            </Panel>
          )}
        </>
      ) : (
        <>
          {warningMessage && (
            <View style={policyValueStyle.errorContainer}>
              <Text style={policyValueStyle.errorMessage}>{warningMessage}</Text>
            </View>
          )}
          <Panel title={t('ContractValue')}>
            <FieldList dataSource={contractValue} />
            <TableField
              columns={prucashbenefitColumns}
              dataSource={prucashBenefitDatasources}
              title={t('PrucashBenefitDetail')}
              display={existPrucash}
            />
          </Panel>
        </>
      )}
      {existAOValue && (
        <Panel title={t('APL/OPLValueDetail')}>
          <TableField columns={AOValueDetailColumns} dataSource={AOValueDetailDatasources} />
        </Panel>
      )}
    </Container>
  )
}
const policyValueStyle = StyleSheet.create({
  errorContainer: {
    marginBottom: 10
  },
  errorMessage: {
    color: 'red',
    fontSize: 13
  }
})
