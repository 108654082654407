import { ZIO } from '@mxt/zio'
import {
  AddLackOfDocumentService,
  AppContext,
  assets,
  form2,
  RoundedButton,
  StorageBlob,
  Title,
  useMobile
} from '@pulseops/common'
import { ClaimStackParamList } from '@pulseops/submission'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import { pipe } from 'fp-ts/lib/function'
import * as _ from 'lodash'
import React from 'react'
import { useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SafeAreaView, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { ClaimInfoHeader } from '../common/claim-header'
import { AddLackForm } from './add-lack-of-documents-form'
import { RequestType } from './request-type'
import { SupplementaryInfomation } from './supplementary-infomation'

type Props = StackScreenProps<ClaimStackParamList, 'AddLackOfDocumentsScreen'>

export const AddLackOfDocumentsScreen = (props: Props) => {
  const { t } = useTranslation('claim')
  const at = useTranslation('claim').t
  const { isMobile } = useMobile()

  const isFocused = useIsFocused()
  const { changeBreadcrumb, showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const navigation = useNavigation()

  const { base, handleSubmit } = form2.useForm(AddLackForm.codec, {
    defaultValues: {
      addLackOfDocuments: [
        {
          caseId: null,
          suspendCode: null,
          attachments: []
        }
      ]
    }
  })

  const {
    reset,
    control,
    formState: { errors }
  } = base

  const { fields, append, remove } = useFieldArray<AddLackForm.Raw>({
    control,
    name: 'addLackOfDocuments'
  })

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigation.navigate('HomeScreen')
          }
        },
        {
          title: at('Submission:EForm'),
          navigate: () => navigation.navigate('StaffSubmissionStack', { screen: 'StaffSubmissionScreen' })
        },
        {
          title: at('common:ClaimServices'),
          navigate: () =>
            props.navigation.navigate('ClaimListScreen', {
              policyNum: props.route.params.policyNum,
              policyOwner: props.route.params.policyOwner,
              officeCode: props.route.params.officeCode,
              officeName: props.route.params.officeName,
              clientNumber: props.route.params.clientNumber
            })
        },
        { title: t('claim:AddLackOfDocuments'), navigate: null }
      ])
    }
  }, [isFocused])

  const onAddSupplementary = () => {
    append({
      caseId: null,
      suspendCode: null,
      attachments: []
    })
  }

  const validSuspendType = () => {
    const { addLackOfDocuments } = base.getValues()
    const notValidSuspendType = addLackOfDocuments.some(({ caseId, suspendCode }, index) =>
      addLackOfDocuments.some(
        (cItem, cIndex) =>
          index !== cIndex && caseId?.value === cItem.caseId?.value && suspendCode?.value === cItem.suspendCode?.value
      )
    )
    notValidSuspendType && showToast(t('message:MS050291'), 'error')
    return !notValidSuspendType
  }

  const onSubmit = (values: AddLackForm.Raw) => {
    if (validSuspendType()) {
      showGlobalLoading(true)
      const policyNumber = props.route.params.policyNum
      pipe(
        ZIO.sequence(
          values.addLackOfDocuments.map(({ attachments, metaData }) =>
            StorageBlob.uploadToSubmit(
              'CL',
              policyNumber
            )(
              attachments.map((fileItem) => {
                return {
                  file: fileItem.file as File,
                  metaData: metaData as StorageBlob.MetaDataUpload | undefined
                }
              })
            )
          )
        ),
        ZIO.tapError((_) => {
          showGlobalLoading(false)
          showToast(t('message:MS050001'), 'error')
          return ZIO.unit
        }),
        ZIO.flatMap((documents) => {
          const data = {
            addLackOfDocuments: values.addLackOfDocuments.map(({ transactionType, suspendCode, caseId }, index) => ({
              transactionType: transactionType as string,
              caseId: caseId?.value as string,
              suspendCode: suspendCode?.value as string,
              attachments: documents[index]
            }))
          }
          return pipe(
            AddLackOfDocumentService.submit(policyNumber, data, true),
            ZIO.catchAll((error) => {
              showGlobalLoading(false)
              showToast(t('message:MS050001'), 'error')
              return ZIO.fail(null)
            }),
            ZIO.tap((response) => {
              showGlobalLoading(false)
              showToast(t('message:RequestSendSuccessfully'), 'success')
              props.navigation.navigate('ClaimListScreen', {
                policyNum: props.route.params.policyNum,
                policyOwner: props.route.params.policyOwner,
                officeCode: props.route.params.officeCode,
                officeName: props.route.params.officeName,
                clientNumber: props.route.params.clientNumber
              })
              return ZIO.succeed(response)
            })
          )
        }),
        ZIO.unsafeRun({})
      )
    }
  }

  const onReset = () => {
    reset()
  }

  return (
    <SafeAreaView style={{ flex: 1, width: '100%' }}>
      <ScrollView>
        <View style={{ flex: 1, width: '100%' }}>
          <ClaimInfoHeader
            menus={{
              policyNum: props.route.params.policyNum,
              policyOwner: props.route.params.policyOwner,
              officeName: props.route.params.officeName,
              clientNumber: props.route.params?.clientNumber ?? ''
            }}
          />
          <View style={[{ marginHorizontal: 'auto', width: '100%', paddingHorizontal: 15 }]}>
            <RequestType />
            <View>
              <Title title={t('submission:SupplementaryInfomation')} />
              {fields.map((field, index) => (
                <SupplementaryInfomation
                  form={base}
                  policyNum={props.route.params.policyNum}
                  key={field.id}
                  index={index}
                  remove={remove}
                  officeCode={props.route.params.officeCode}
                />
              ))}
              {fields.length === 0 && (
                <View style={isMobile ? styles.rowMobile : styles.row}>
                  <View style={styles.colFull}>
                    <Text style={{ color: '#ED1B2E' }}>{_.get(errors.addLackOfDocuments as unknown, 'message')}</Text>
                  </View>
                </View>
              )}

              <View style={isMobile ? styles.rowMobile : styles.row}>
                <View style={styles.colFull}>
                  <TouchableOpacity onPress={onAddSupplementary} style={[styles.row, { alignItems: 'center' }]}>
                    <assets.PlusCircleIcon />
                    <Text style={{ marginLeft: 10, fontWeight: '700' }}>{t('common:Add')}</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </View>
          <View style={[styles.rowBtn]}>
            <RoundedButton
              showBorder={true}
              filled={false}
              activeOpacity={0.7}
              text={t('submission:ClearAll')}
              textStyle={styles.btnTitle}
              style={{ height: 39, marginEnd: 15, paddingHorizontal: 20 }}
              textColorDisable="#B0B0B0"
              textColorEnable="#ED1B2E"
              bgColorDisable={'#B0B0B0'}
              bgColorEnable={'#ED1B2E'}
              borderColorDisable={'#B0B0B0'}
              borderColorEnable={'#ED1B2E'}
              onPress={() => onReset()}
            />
            <RoundedButton
              showBorder={false}
              filled={true}
              activeOpacity={0.7}
              text={t('submission:SendRequest')}
              textStyle={styles.btnTitle}
              style={{ height: 39, paddingHorizontal: 20 }}
              textColorDisable="#FFFFFF"
              textColorEnable="#FFFFFF"
              bgColorDisable={'#B0B0B0'}
              bgColorEnable={'#ED1B2E'}
              borderColorDisable={'#B0B0B0'}
              borderColorEnable={'#ED1B2E'}
              onPress={handleSubmit(onSubmit)}
            />
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  rowBtn: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    marginBottom: 30
  },
  btnTitle: {
    fontSize: 15,
    fontWeight: 'bold',
    marginVertical: 5,
    marginHorizontal: 29
  },
  row: {
    flexDirection: 'row'
  },
  rowMobile: {
    flexDirection: 'column'
  },
  col: {
    width: '33.33333333333%',
    marginBottom: 32,
    paddingHorizontal: 16
  },
  colFull: {
    width: '100%',
    paddingHorizontal: 16,
    marginBottom: 32
  },
  label: {
    fontSize: 15,
    color: '#70777e',
    fontWeight: 'bold'
  },
  required: {
    color: '#ed1b2e'
  },
  mR5: {
    marginRight: 5
  },
  mB5: {
    marginBottom: 15
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center'
  }
})
