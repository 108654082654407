import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import {
  AppContext,
  assets,
  Checkbox,
  ErrorHandling,
  // ErrorHandling,
  Form,
  Input,
  InquiryComplaintData,
  InquiryComplaintService,
  Panel,
  Select,
  SelectOption,
  SelectSearch,
  useMobile
} from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import { VeriInquiryComplaintData, VeriPayloadData } from 'libs/common/src/service/model/task-detail/InquiryComplaint'
import React, { useEffect, useState } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
// import { Text } from 'react-native-elements'
import { Title } from '../../common'
import { InquiryComplaintConst } from './InquiryComplaintConst'
import { InquiryComplaintForm } from './InquiryComplaintForm'
interface Props {
  detail: VeriInquiryComplaintData
  form: UseFormReturn<InquiryComplaintForm>
  validateReadOnly: () => boolean
  subTaskCode: string
  updatedPayloadData: VeriPayloadData
}

export type CaseInfoRef = {
  resetCaseInfoBlock: () => void
}

type Option = {
  label: string
  value: string
}

export const _CaseInfo = (
  { detail, form, validateReadOnly, subTaskCode, updatedPayloadData }: Props,
  ref: React.Ref<CaseInfoRef>
) => {
  const { t, i18n } = useTranslation(['requestInfo', 'roles'])
  const { isMobile } = useMobile()

  const [VERIGroupList, setVERIGroupList] = useState<InquiryComplaintData.IACGroup[]>([])
  const [subServiceTypeList, setSubServiceTypeList] = useState<InquiryComplaintData.IACType[]>([])
  const [subTransactionTypeList, setSubTransactionTypeList] = useState<InquiryComplaintData.IACSubtypes[]>([])
  const [requestorRoleList, setRequestorRoleList] = useState<Option[]>(
    InquiryComplaintConst.requestorRoleList.map((item) => ({
      label: t(`roles:${item.label}`) as string,
      value: item.value
    })) || []
  )
  const [relationshipList, setRelationshipList] = useState<InquiryComplaintConst.Group[]>([])

  const { showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const [isLoading, bindLoader] = useLoading(false)
  const isDisabledrequestorFullName = React.useRef<boolean>(false)
  const isDisabledRelationshipWPO = React.useRef<boolean>(false)

  // const register = Form.register(form)
  const submissionData = updatedPayloadData?.body.attributesExt
  const { setValue, watch, control } = form

  const {
    benList,
    laList,
    BORelationshipList,
    clientName,
    CBrelationshipList,
    BERelationshipList,
    methodList,
    sourceList,
    createdBy,
    createdDate,
    groupList,
    estimationClosingDate,
    tat
  } = detail

  // const relationshipT16s1 = pipe(GeneralService.getRelationship(), ErrorHandling.runDidMount()) || []
  // const getRelationshipFromT16s1 = (code: string) => {
  //   const found = relationshipT16s1.find((item) => item.code === code)
  //   return {
  //     relationshipEN: found?.nameEn || '',
  //     relationshipVI: found?.nameVn || ''
  //   }
  // }

  React.useImperativeHandle(
    ref,
    () => ({
      resetCaseInfoBlock: () => {
        getInitialCaseInfoData()
      }
    }),
    []
  )

  const getRelationshipFromT16s1 = (code: string) => {
    const found = BERelationshipList.find((item) => item.code === code)
    return {
      relationshipEN: found?.nameEn || '',
      relationshipVI: found?.name || ''
    }
  }

  useEffect(() => {
    if (benList && benList.length <= 0) {
      setRequestorRoleList(requestorRoleList.filter((x) => x.value !== '3'))
    }
  }, [detail, i18n.language])

  useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  const isDisabled = validateReadOnly()

  // pipe(
  //   ZIO.effect(() => {
  //     let requestorRelationshipList: InquiryComplaintConst.Group[] = []
  //     const filteredGroupArr = groupList && groupList.length > 0 ? filterGroupListByCagetory(groupList) : []
  //     const typeList = detail.subServiceTypeList.filter((item) => item.groupCode === submissionData.GROUP_CODE)
  //     const subTypeList = detail.subTransactionTypeList.filter(
  //       (item) => item.typeCode === submissionData.SUB_SVC_TYPE_CODE
  //     )
  //     setVERIGroupList(filteredGroupArr)
  //     setSubServiceTypeList(typeList.slice().sort((item1, item2) => compareItemsByName(item1, item2)))
  //     setSubTransactionTypeList(subTypeList.slice().sort((item1, item2) => compareItemsByName(item1, item2)))
  //     if (submissionData.REQUEST_WITH_ROLE === '2') {
  //       requestorRelationshipList = getRelationshipListByLA(submissionData.REQUESTER_FULL_NAME)
  //     }
  //     if (submissionData.REQUEST_WITH_ROLE === '3') {
  //       requestorRelationshipList = getRelationshipListByBE(submissionData.REQUESTER_FULL_NAME)
  //     }
  //     if (submissionData.REQUEST_WITH_ROLE === '4') {
  //       requestorRelationshipList = CBrelationshipList.map((relationshipItem) => {
  //         return {
  //           code: relationshipItem.code,
  //           nameVi: relationshipItem.name,
  //           name: relationshipItem.nameEn,
  //           parentCode: ''
  //         }
  //       })
  //       setRelationshipList(requestorRelationshipList)
  //     }
  //     return {
  //       serviceTypeList: typeList,
  //       transactionTypeList: subTypeList,
  //       requestorRelationshipList,
  //       filteredGroupArr: filteredGroupArr
  //     }
  //   }),
  //   ZIO.map((caseInfoData) => {
  //     const groupItem =
  //       converOptions(caseInfoData.filteredGroupArr).find((x) => x.value === submissionData.GROUP_CODE) || null
  //     const type =
  //       converOptions(caseInfoData.serviceTypeList).find(
  //         (subServiceType) => subServiceType.value === submissionData.SUB_SVC_TYPE_CODE
  //       ) || null

  //     const subType =
  //       converOptions(caseInfoData.transactionTypeList).find(
  //         (subTransactionServiceType) => subTransactionServiceType.value === submissionData.SUB_TRANS_TYPE_CODE
  //       ) || null

  //     const requestorRole = requestorRoleList.find((item) => item.value === submissionData.REQUEST_WITH_ROLE) || null

  //     const methodData = converOptions(methodList).find((item) => item.value === submissionData.METHOD) || null

  //     const sourceData = converOptions(sourceList).find((item) => item.value === submissionData.SOURCE) || null

  //     setValue('caseInfo.group', groupItem)
  //     setValue('caseInfo.type', type)
  //     setValue('caseInfo.subType', subType)
  //     setValue('caseInfo.requestorRole', requestorRole)
  //     onChangeRequestRole(requestorRole)
  //     setValue('caseInfo.requestorNationalID', submissionData.REQUESTER_NATIONAL_ID)
  //     setValue('caseInfo.method', methodData)
  //     setValue('caseInfo.source', sourceData)
  //     setValue('caseInfo.hot', submissionData.HOT)

  //     if (submissionData.REQUEST_WITH_ROLE === '1') {
  //       setValue('caseInfo.requestorFullName', submissionData.REQUESTER_FULL_NAME)
  //     }

  //     if (submissionData.REQUEST_WITH_ROLE !== '1') {
  //       const relationshipWithPolicyOwnerData =
  //         converOptions(caseInfoData.requestorRelationshipList).find(
  //           (item) => item.value === submissionData.RLA_WITH_PO
  //         ) || null
  //       setValue('caseInfo.relationshipWithPolicyOwner', relationshipWithPolicyOwnerData)
  //     }

  //     if (submissionData.REQUEST_WITH_ROLE === '2') {
  //       const requestorFullNameData =
  //         laList
  //           .map((item) => ({
  //             label: item.clientName,
  //             value: item.clientNumberOfLA
  //           }))
  //           .find((item) => item.value === submissionData.REQUESTER_FULL_NAME) || null
  //       setValue('caseInfo.requestorFullName1', requestorFullNameData)
  //       onChangeRequestorFullName(requestorFullNameData)
  //     } else if (submissionData.REQUEST_WITH_ROLE === '3') {
  //       const requestorFullNameData =
  //         benList
  //           .map((item) => ({
  //             label: item.clientName,
  //             value: item.clientNumberOfBen
  //           }))
  //           .find((item) => item.value === submissionData.REQUESTER_FULL_NAME) ?? null
  //       setValue('caseInfo.requestorFullName1', requestorFullNameData)
  //       onChangeRequestorFullName(requestorFullNameData)
  //     } else if (submissionData.REQUEST_WITH_ROLE === '4') {
  //       setValue('caseInfo.requestorFullName', submissionData.REQUESTER_FULL_NAME)
  //     }
  //   }),
  //   bindLoader,
  //   ErrorHandling.runDidUpdate([submissionData])
  // )

  React.useEffect(() => {
    getInitialCaseInfoData()
  }, [submissionData])

  const getInitialCaseInfoData = () => {
    pipe(
      ZIO.effect(() => {
        let requestorRelationshipList: InquiryComplaintConst.Group[] = []
        const filteredGroupArr = groupList && groupList.length > 0 ? filterGroupListByCagetory(groupList) : []
        const typeList = detail.subServiceTypeList.filter((item) => item.groupCode === submissionData.GROUP_CODE)
        const subTypeList = detail.subTransactionTypeList.filter(
          (item) => item.typeCode === submissionData.SUB_SVC_TYPE_CODE
        )
        setVERIGroupList(filteredGroupArr)
        setSubServiceTypeList(typeList.slice().sort((item1, item2) => compareItemsByName(item1, item2)))
        setSubTransactionTypeList(subTypeList.slice().sort((item1, item2) => compareItemsByName(item1, item2)))
        if (submissionData.REQUEST_WITH_ROLE === '2') {
          requestorRelationshipList = getRelationshipListByLA(submissionData.REQUESTER_FULL_NAME)
        }
        if (submissionData.REQUEST_WITH_ROLE === '3') {
          requestorRelationshipList = getRelationshipListByBE(submissionData.REQUESTER_FULL_NAME)
        }
        if (submissionData.REQUEST_WITH_ROLE === '4') {
          requestorRelationshipList = CBrelationshipList.map((relationshipItem) => {
            return {
              code: relationshipItem.code,
              nameVi: relationshipItem.name,
              name: relationshipItem.nameEn,
              parentCode: ''
            }
          })
          setRelationshipList(requestorRelationshipList)
        }
        return {
          serviceTypeList: typeList,
          transactionTypeList: subTypeList,
          requestorRelationshipList,
          filteredGroupArr: filteredGroupArr
        }
      }),
      ZIO.map((caseInfoData) => {
        const groupItem =
          converOptions(caseInfoData.filteredGroupArr).find((x) => x.value === submissionData.GROUP_CODE) || null
        const type =
          converOptions(caseInfoData.serviceTypeList).find(
            (subServiceType) => subServiceType.value === submissionData.SUB_SVC_TYPE_CODE
          ) || null

        const subType =
          converOptions(caseInfoData.transactionTypeList).find(
            (subTransactionServiceType) => subTransactionServiceType.value === submissionData.SUB_TRANS_TYPE_CODE
          ) || null

        const requestorRole = requestorRoleList.find((item) => item.value === submissionData.REQUEST_WITH_ROLE) || null

        const methodData = converOptions(methodList).find((item) => item.value === submissionData.METHOD) || null

        const sourceData = converOptions(sourceList).find((item) => item.value === submissionData.SOURCE) || null

        setValue('caseInfo.group', groupItem)
        setValue('caseInfo.type', type)
        setValue('caseInfo.subType', subType)
        setValue('caseInfo.requestorRole', requestorRole)
        onChangeRequestRole(requestorRole)
        setValue('caseInfo.requestorNationalID', submissionData.REQUESTER_NATIONAL_ID)
        setValue('caseInfo.method', methodData)
        setValue('caseInfo.source', sourceData)
        setValue('caseInfo.hot', submissionData.HOT)
        submissionData.CALL_ID && setValue('callId', submissionData.CALL_ID)

        if (submissionData.REQUEST_WITH_ROLE === '1') {
          setValue('caseInfo.requestorFullName', submissionData.REQUESTER_FULL_NAME)
        }

        if (submissionData.REQUEST_WITH_ROLE !== '1') {
          const relationshipWithPolicyOwnerData =
            converOptions(caseInfoData.requestorRelationshipList).find(
              (item) => item.value === submissionData.RLA_WITH_PO
            ) || null
          setValue('caseInfo.relationshipWithPolicyOwner', relationshipWithPolicyOwnerData)
        }

        if (submissionData.REQUEST_WITH_ROLE === '2') {
          const requestorFullNameData =
            laList
              .map((item) => ({
                label: item.clientName,
                value: item.clientNumberOfLA
              }))
              .find((item) => item.value === submissionData.REQUESTER_FULL_NAME) || null
          setValue('caseInfo.requestorFullName1', requestorFullNameData)
          onChangeRequestorFullName(requestorFullNameData)
        } else if (submissionData.REQUEST_WITH_ROLE === '3') {
          const requestorFullNameData =
            benList
              .map((item) => ({
                label: item.clientName,
                value: item.clientNumberOfBen
              }))
              .find((item) => item.value === submissionData.REQUESTER_FULL_NAME) ?? null
          setValue('caseInfo.requestorFullName1', requestorFullNameData)
          onChangeRequestorFullName(requestorFullNameData)
        } else if (submissionData.REQUEST_WITH_ROLE === '4') {
          setValue('caseInfo.requestorFullName', submissionData.REQUESTER_FULL_NAME)
        }
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }

  // pipe(
  //   ZIO.fromPromise(() => getServiceTypeList(watch('caseInfo.group')?.value ?? '')),
  //   ZIO.map((typeList) => {
  //     const type =
  //       converOptions(typeList).find((subServiceType) => subServiceType.value === submissionData.SUB_SVC_TYPE_CODE) ||
  //       null
  //     setValue('caseInfo.type', type)
  //   }),
  //   bindLoader,
  //   ErrorHandling.runDidUpdate([watch('caseInfo.group')])
  // )

  // React.useEffect(() => {
  //   if (!!submissionData.SUB_SVC_TYPE_CODE) {
  //     pipe(
  //       ZIO.fromPromise(() => getSubTransactionTypeList(submissionData.SUB_SVC_TYPE_CODE)),
  //       ZIO.map((subTypeList) => {
  //         const subType =
  //           converOptions(subTypeList).find(
  //             (subTransactionServiceType) => subTransactionServiceType.value === submissionData.SUB_TRANS_TYPE_CODE
  //           ) || null
  //         setValue('caseInfo.subType', subType)
  //       }),
  //       bindLoader,
  //       ErrorHandling.run()
  //     )
  //   }
  // }, [submissionData.SUB_SVC_TYPE_CODE])

  const filterGroupListByCagetory = (groupArr: InquiryComplaintData.IACGroup[]) => {
    const categoryName = subTaskCode === 'CH' ? 'Complaint' : 'Inquiry'
    let itemList: InquiryComplaintData.IACGroup[] = groupArr.filter((x) => x.categoryName === categoryName)
    itemList = itemList.sort((item1, item2) => compareItemsByName(item1, item2))
    return itemList
  }

  const showOptionLanguage = () => {
    return i18n.language === 'en' ? 'Select' : 'Chọn'
  }

  const getStatusName = () => {
    return i18n.language === 'en' ? 'In process' : 'Đang xử lý'
  }

  const converOptions = (arr: any[]): Option[] => {
    return (
      arr.map((item) => ({
        label: i18n.language === 'en' ? item.name : item.nameVi,
        value: item.code
      })) || []
    )
  }

  const compareItemsByName = (item1: any, item2: any) => {
    return i18n.language === 'en' ? item1.name.localeCompare(item2.name) : item1.nameVi.localeCompare(item2.nameVi)
  }

  const getRelationshipListByLA = (clientNumberOfLA: string) => {
    setRelationshipList([])
    const relationships: InquiryComplaintConst.Group[] = []
    if (BORelationshipList.length > 0) {
      let clientNumInfo = BORelationshipList.find((x) => x.relationship === clientNumberOfLA)
      const relationshipFilterItem = CBrelationshipList.find((p) => p.code === clientNumInfo?.clientNum)
      if (relationshipFilterItem) {
        const reletionship = getRelationshipFromT16s1(relationshipFilterItem?.code ?? '')
        relationships.push({
          code: relationshipFilterItem?.code ?? '',
          nameVi: reletionship.relationshipVI,
          name: reletionship.relationshipEN,
          parentCode: ''
        })
        setValue('caseInfo.relationshipWithPolicyOwner', {
          value: relationshipFilterItem?.code ?? '',
          label: i18n.language === 'en' ? relationshipFilterItem?.nameEn ?? '' : relationshipFilterItem?.name ?? ''
        })
        isDisabledRelationshipWPO.current = false
        setRelationshipList(relationships)
      }
    }
    return relationships
  }

  const getRelationshipListByBE = (clientNumberOfBen: string) => {
    setRelationshipList([])
    const relationships: InquiryComplaintConst.Group[] = []
    if (benList.length > 0) {
      const benItem = benList.find((x) => x.clientNumberOfBen === clientNumberOfBen)
      const relationshipFilterItem = BERelationshipList.find((p) => p.code === benItem?.relationShipCode)
      if (relationshipFilterItem) {
        const reletionship = getRelationshipFromT16s1(relationshipFilterItem?.code ?? '')
        relationships.push({
          code: relationshipFilterItem?.code ?? '',
          nameVi: reletionship.relationshipVI,
          name: reletionship.relationshipEN,
          parentCode: ''
        })
        setValue('caseInfo.relationshipWithPolicyOwner', {
          value: relationshipFilterItem?.code ?? '',
          label: i18n.language === 'en' ? relationshipFilterItem?.nameEn ?? '' : relationshipFilterItem?.name ?? ''
        })
        isDisabledRelationshipWPO.current = false
      }
      setRelationshipList(relationships)
    }
    return relationships
  }

  const onChangeRequestRole = (e: any): void => {
    if (!!e.value) {
      switch (e.value) {
        case '1':
          setValue('caseInfo.requestorFullName', clientName)
          setValue('caseInfo.relationshipWithPolicyOwner', null)
          // clearErrors('caseInfo.relationshipWithPolicyOwner')
          isDisabledrequestorFullName.current = true
          isDisabledRelationshipWPO.current = true
          break
        case '2':
          setValue('caseInfo.requestorFullName1', null)
          setValue('caseInfo.relationshipWithPolicyOwner', null)
          // clearErrors('caseInfo.relationshipWithPolicyOwner')
          isDisabledrequestorFullName.current = false
          isDisabledRelationshipWPO.current = true
          break
        case '3':
          setValue('caseInfo.requestorFullName1', null)
          setValue('caseInfo.relationshipWithPolicyOwner', null)
          // clearErrors('caseInfo.relationshipWithPolicyOwner')
          isDisabledrequestorFullName.current = false
          isDisabledRelationshipWPO.current = true
          break
        case '4':
          setValue('caseInfo.requestorFullName', '')
          setValue('caseInfo.relationshipWithPolicyOwner', null)
          isDisabledrequestorFullName.current = false
          isDisabledRelationshipWPO.current = false
          setRelationshipList(
            CBrelationshipList.map((relationshipItem) => {
              const relationship = getRelationshipFromT16s1(relationshipItem.code)
              return {
                code: relationshipItem.code,
                nameVi: relationship.relationshipVI,
                name: relationship.relationshipEN,
                parentCode: ''
              }
            })
          )
          break
      }
    }
  }

  const onChangeRequestorFullName = (e: any) => {
    if (!!e?.value) {
      const requestorRole = form.getValues('caseInfo.requestorRole')
      if (requestorRole?.value) {
        if (requestorRole.value === '2') {
          getRelationshipListByLA(e.value)
        }
        if (requestorRole.value === '3') {
          getRelationshipListByBE(e.value)
        }
      }
    }
  }

  const getSubTransactionTypeList = (typeCode: string) => {
    const subTypeList = detail.subTransactionTypeList.filter((item) => item.typeCode === typeCode)
    setSubTransactionTypeList(subTypeList.slice().sort((item1, item2) => compareItemsByName(item1, item2)))
    // return pipe(
    //   InquiryComplaintService.getSubType(typeCode),
    //   ZIO.map((typeList) => {
    //     if (typeList && typeList.length > 0) {
    //       setSubTransactionTypeList(typeList.slice().sort((item1, item2) => compareItemsByName(item1, item2)))
    //     } else {
    //       setSubTransactionTypeList([])
    //     }
    //     return typeList
    //   }),
    //   ZIO.catchAll((error) => {
    //     return ZIO.fail(error)
    //   }),
    //   bindLoader,
    //   ZIO.unsafeRun({})
    // )
  }

  const getServiceTypeList = (groupCode: string) => {
    const typeList = detail.subServiceTypeList.filter((item) => item.groupCode === groupCode)
    setSubServiceTypeList(typeList.slice().sort((item1, item2) => compareItemsByName(item1, item2)))
    // return pipe(
    //   InquiryComplaintService.getTypes(groupCode),
    //   ZIO.map((groupList) => {
    //     if (groupList && groupList.length > 0) {
    //       setSubServiceTypeList(groupList.slice().sort((item1, item2) => compareItemsByName(item1, item2)))
    //     } else {
    //       setSubServiceTypeList([])
    //     }
    //     return groupList
    //   }),
    //   ZIO.catchAll((error) => {
    //     return ZIO.fail(error)
    //   }),
    //   bindLoader,
    //   ZIO.unsafeRun({})
    // )
  }

  const onTypeChangeEvent = (typeVal: SelectOption | null) => {
    setValue('caseInfo.subType', null)
    setSubTransactionTypeList([])
    if (!!typeVal) {
      getSubTransactionTypeList(typeVal.value)
    }
  }

  const onGroupChangeEvent = (val: SelectOption | null) => {
    setValue('caseInfo.type', null)
    setSubServiceTypeList([])
    onTypeChangeEvent(null)
    if (!!val && !!val.value) {
      getServiceTypeList(val.value)
    }
  }

  return (
    <View>
      <Title title={t('CaseInfo')} />
      <Panel title="">
        <View style={isMobile ? styles.rowMobile : styles.row}>
          <View style={isMobile ? styles.colFull : styles.col}>
            {/* <Input title={t('Group')} {...register('caseInfo.group')} disabled /> */}
            <Controller
              control={control}
              name="caseInfo.group"
              rules={{
                required: {
                  value: true,
                  message: `${t('message:MS020009', { field: t('Group') })}`
                }
              }}
              render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                <SelectSearch
                  label={t('Group')}
                  required
                  disabled={isDisabled}
                  options={converOptions(VERIGroupList)}
                  popupIcon={<assets.ArrowDownDropdownIcon />}
                  placeholder={showOptionLanguage()}
                  onChange={(val) => {
                    onChange(val)
                    onGroupChangeEvent(val)
                  }}
                  onBlur={onBlur}
                  value={value}
                  key={value?.value}
                  errorMessage={error?.message}
                />
              )}
            />
          </View>
          <View style={isMobile ? styles.colFull : styles.col}>
            <Controller
              name="caseInfo.type"
              rules={{
                required: {
                  value: true,
                  message: `${t('message:MS020009', { field: t('Type') })}`
                }
              }}
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <SelectSearch
                  label={t('Type')}
                  required
                  disabled={isDisabled}
                  options={converOptions(subServiceTypeList)}
                  popupIcon={<assets.ArrowDownDropdownIcon />}
                  placeholder={showOptionLanguage()}
                  onChange={(val) => {
                    onTypeChangeEvent(val)
                    onChange(val)
                  }}
                  onBlur={onBlur}
                  value={value}
                  key={value?.value}
                  errorMessage={error?.message}
                  isShowFullText={true}
                />
              )}
            />
          </View>
          <View style={isMobile ? styles.colFull : styles.col}>
            <Controller
              name="caseInfo.subType"
              rules={{
                required: {
                  value: true,
                  message: `${t('message:MS020009', { field: t('SubType') })}`
                }
              }}
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <SelectSearch
                  label={t('SubType')}
                  required
                  disabled={isDisabled}
                  options={converOptions(subTransactionTypeList)}
                  // showUnderline={false}
                  popupIcon={<assets.ArrowDownDropdownIcon />}
                  placeholder={showOptionLanguage()}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  key={value?.value}
                  errorMessage={error?.message}
                  isShowFullText={true}
                />
              )}
            />
          </View>
        </View>

        <View style={isMobile ? styles.rowMobile : styles.row}>
          <View style={isMobile ? styles.colFull : styles.col}>
            <Controller
              name="caseInfo.requestorRole"
              rules={{
                required: {
                  value: true,
                  message: `${t('message:MS020009', { field: t('RequestorRole') })}`
                }
              }}
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <Select
                  label={t('RequestorRole')}
                  required
                  disabled={isDisabled}
                  options={requestorRoleList}
                  placeholder={showOptionLanguage()}
                  onChange={(e) => {
                    onChangeRequestRole(e)
                    onChange(e)
                  }}
                  onBlur={onBlur}
                  value={value}
                  errorMessage={error?.message}
                />
              )}
            />
          </View>
          {!['2', '3'].includes(watch('caseInfo.requestorRole')?.value || '') && (
            <View style={isMobile ? styles.colFull : styles.col}>
              <Controller
                name="caseInfo.requestorFullName"
                rules={{
                  required: {
                    value: true,
                    message: `${t('message:MS020001', { field: t('RequestorFullname') })}`
                  }
                }}
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <Input
                    title={t('RequestorFullname')}
                    disabled={isDisabledrequestorFullName.current || isDisabled}
                    required
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    errorMessage={error?.message}
                    maxLength={120}
                  />
                )}
              />
            </View>
          )}
          {watch('caseInfo.requestorRole')?.value === '2' && (
            <View style={isMobile ? styles.colFull : styles.col}>
              <Controller
                name="caseInfo.requestorFullName1"
                rules={{
                  required: {
                    value: true,
                    message: `${t('message:MS020009', { field: t('RequestorFullname') })}`
                  }
                }}
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <Select
                    label={t('RequestorFullname')}
                    required
                    disabled={isDisabledrequestorFullName.current || isDisabled}
                    options={laList.map((item) => ({
                      label: item.clientName,
                      value: item.clientNumberOfLA
                    }))}
                    showUnderline={!isDisabledrequestorFullName.current}
                    placeholder={showOptionLanguage()}
                    onChange={(e) => {
                      onChangeRequestorFullName(e)
                      onChange(e)
                    }}
                    onBlur={onBlur}
                    value={value}
                    errorMessage={error?.message}
                  />
                )}
              />
            </View>
          )}
          {watch('caseInfo.requestorRole')?.value === '3' && (
            <View style={isMobile ? styles.colFull : styles.col}>
              <Controller
                name="caseInfo.requestorFullName1"
                rules={{
                  required: {
                    value: true,
                    message: `${t('message:MS020009', { field: t('RequestorFullname') })}`
                  }
                }}
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <Select
                    label={t('RequestorFullname')}
                    required
                    disabled={isDisabledrequestorFullName.current || isDisabled}
                    options={benList.map((item) => ({
                      label: item.clientName,
                      value: item.clientNumberOfBen
                    }))}
                    showUnderline={!isDisabledrequestorFullName.current}
                    placeholder={showOptionLanguage()}
                    onChange={(e) => {
                      onChangeRequestorFullName(e)
                      onChange(e)
                    }}
                    onBlur={onBlur}
                    value={value}
                    errorMessage={error?.message}
                  />
                )}
              />
            </View>
          )}
          <View style={isMobile ? styles.colFull : styles.col}>
            <Controller
              name="caseInfo.relationshipWithPolicyOwner"
              rules={{
                required: {
                  value: !isDisabledRelationshipWPO.current,
                  message: `${t('message:MS020009', { field: t('RelationshipWithPolicyOwner') })}`
                }
              }}
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <Select
                  label={t('RelationshipWithPolicyOwner')}
                  required={!isDisabledRelationshipWPO.current}
                  disabled={isDisabledRelationshipWPO.current || isDisabled}
                  options={converOptions(relationshipList)}
                  showUnderline={isDisabledRelationshipWPO.current}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  placeholder={showOptionLanguage()}
                  errorMessage={error?.message}
                />
              )}
            />
          </View>
        </View>
        <View style={isMobile ? styles.rowMobile : styles.row}>
          <View style={isMobile ? styles.colFull : styles.col}>
            <Controller
              name="caseInfo.requestorNationalID"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <Input
                  title={t('RequestorNationalID')}
                  disabled={isDisabled}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  errorMessage={error?.message}
                  maxLength={24}
                />
              )}
            />
          </View>

          <View style={isMobile ? styles.colFull : styles.col}>
            <Controller
              name="caseInfo.method"
              rules={{
                required: {
                  value: true,
                  message: `${t('message:MS020009', { field: t('Method') })}`
                }
              }}
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <SelectSearch
                  label={t('Method')}
                  required
                  disabled={isDisabled}
                  options={converOptions(methodList)}
                  // showUnderline={false}
                  popupIcon={<assets.ArrowDownDropdownIcon />}
                  placeholder={showOptionLanguage()}
                  onChange={(e) => {
                    onChange(e)
                  }}
                  onBlur={onBlur}
                  value={value}
                  key={value?.value}
                  errorMessage={error?.message}
                  isShowFullText={true}
                />
              )}
            />
          </View>
          <View style={isMobile ? styles.colFull : styles.col}>
            <Controller
              name="caseInfo.source"
              rules={{
                required: {
                  value: true,
                  message: `${t('message:MS020009', { field: t('Source') })}`
                }
              }}
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <SelectSearch
                  label={t('Source')}
                  required
                  disabled={isDisabled}
                  options={converOptions(sourceList)}
                  popupIcon={<assets.ArrowDownDropdownIcon />}
                  // showUnderline={false}
                  placeholder={showOptionLanguage()}
                  onChange={(e) => {
                    onChange(e)
                  }}
                  onBlur={onBlur}
                  value={value}
                  key={value?.value}
                  errorMessage={error?.message}
                  isShowFullText={true}
                />
              )}
            />
          </View>
        </View>
        <View style={isMobile ? styles.rowMobile : styles.row}>
          <View style={isMobile ? styles.colFull : styles.col}>
            <Input title={t('ReceivedBy')} value={createdBy || '-'} disabled />
          </View>
          <View style={isMobile ? styles.colFull : styles.col}>
            <Input title={t('ReceivedFrom')} value="-" disabled />
          </View>
          <View style={isMobile ? styles.colFull : styles.col}>
            <Input title={t('OfficeLocation')} value="-" disabled />
          </View>
        </View>

        <View style={isMobile ? styles.rowMobile : styles.row}>
          <View style={isMobile ? styles.colFull : styles.col}>
            <Input title={t('ReceivedDate')} value={createdDate || '-'} disabled />
          </View>
          <View style={isMobile ? styles.colFull : styles.col}>
            <Input title={t('EstimationClosingDate')} value={estimationClosingDate || '-'} disabled />
          </View>
          <View style={isMobile ? styles.colFull : styles.col}>
            <Input title={t('TAT')} value={tat || '-'} disabled />
          </View>
        </View>

        <View style={isMobile ? styles.rowMobile : styles.row}>
          <View style={isMobile ? styles.colFull : styles.col}>
            <Controller
              name="caseInfo.hot"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Checkbox title={t('Hot')} value={value} disabled={isDisabled} onBlur={onBlur} onChange={onChange} />
              )}
            />
          </View>
          <View style={isMobile ? styles.colFull : styles.col}>
            <Input title={t('Status')} value={getStatusName()} disabled />
          </View>
          {watch('caseInfo.source')?.value === 'SO14' && (
            <View style={isMobile ? styles.colFull : styles.col}>
              <Input
                title={t('requestInfo:CallId')}
                value={submissionData.CALL_ID ?? ''}
                disabled
                inputType={'number'}
              />
            </View>
          )}
        </View>
      </Panel>
    </View>
  )
}

export const CaseInfo = React.forwardRef(_CaseInfo)

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row'
  },
  rowMobile: {
    flexDirection: 'column'
  },
  col: {
    width: '33.33333333333%',
    marginBottom: 32,
    paddingHorizontal: 16
  },
  colFull: {
    width: '100%',
    paddingHorizontal: 16,
    marginBottom: 32
  },
  required: {
    color: '#ed1b2e'
  },
  mR5: {
    marginRight: 5
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center'
  }
})
