import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import {
  AppContext,
  AuthService,
  Container,
  ErrorHandling,
  PulseOpsFormat,
  PulseOpsService,
  sharedStyle,
  TaskComment,
  useMobile
} from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { ActivityIndicator, ScrollView, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native'
import { Panel } from './CommentPanel'

export interface CommentNode {
  info: {
    name: string
    time: Date
  }
  children?: CommentNode[]
}

export const CommentTab = (props: { processId: string; taskId: string; readonly: boolean; category?: string }) => {
  const [commentData, setCommentData] = React.useState<TaskComment[]>([])
  const [comment, setComment] = React.useState<string>('')
  const [loading, bindLoading] = useLoading(false)
  const { isWide } = useMobile()
  const { t } = useTranslation()

  const { readonly, category = false } = props

  const { getFooter, showToast } = React.useContext(AppContext.AppContextInstance)

  pipe(
    PulseOpsService.getCommentList(props.processId),
    ZIO.tap((comments) => {
      setCommentData(comments)
      return ZIO.unit
    }),
    bindLoading,
    ErrorHandling.runDidMount()
  )

  const saveComment = () => {
    comment !== ''
      ? pipe(
          AuthService.userInfo,
          ZIO.flatMap((user) =>
            pipe(
              PulseOpsService.addComment(props.taskId, props.processId, {
                author: user.email,
                message: comment
              }),
              ZIO.tap((res) => {
                setCommentData([res, ...commentData])
                setComment('')
                return ZIO.unit
              })
            )
          ),
          bindLoading,
          ErrorHandling.run()
        )
      : showToast(`${t('message:MS020001', { field: t('common:Comment') })}`)
  }

  React.useEffect(() => {
    if (
      category && !['PS','CH','CS'].includes(category)
    ) {
      getFooter(
        !props.readonly ? (
          <View style={[sharedStyle.footer, isWide ? { marginRight: 19 } : { width: '100%', justifyContent: 'center' }]}>
            <TouchableOpacity
              style={[sharedStyle.button, { marginRight: 15, borderColor: '#ED1B2E' }]}
              onPress={() => setComment('')}
            >
              <Text style={sharedStyle.textButton}>{t('common:Cancel')}</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.actionBtn} onPress={() => saveComment()}>
              <Text style={{ color: '#FFF' }}>
                {t('common:Add')} {loading && comment !== '' && <ActivityIndicator />}
              </Text>
            </TouchableOpacity>
          </View>
        ) : (
          <></>
        )
      )
    }

    return () => {
      getFooter(<></>)
    }
  }, [comment, readonly, category])

  return (
    <Container loading={loading}>
      {commentData.length > 0 && (
        <ScrollView style={{ height: 200 }}>
          {commentData.map((user, index) => (
            <Panel
              key={index}
              title={user.username}
              comment={PulseOpsFormat.datetoFormat(user.time, 'DD/MM/YYYY HH:mm:ss')}
              isHiddenTitle={false}
              isExand={true}
              isHaveComment
            >
              {/*  */}
              <Text>{user.message}</Text>
            </Panel>
          ))}
        </ScrollView>
      )}
      {!readonly && (
        <React.Fragment>
          <Text style={styles.titleSectionText}>{t('Tab:Comment')}</Text>
          <View style={{ marginBottom: 32 }}>
            <View style={styles.toolbar}>
              <TextInput
                style={{ alignItems: 'flex-start', padding: 5, height: 190 }}
                numberOfLines={5}
                maxLength={500}
                multiline
                onChangeText={(text) => {
                  setComment(text)
                }}
                value={comment}
              />
            </View>
          </View>
        </React.Fragment>
      )}
      {!props.readonly && category && ['PS','CH','CS'].includes(category) ? (
        <View
          style={[styles.buttonContainer, isWide ? { marginRight: 19 } : { width: '100%', justifyContent: 'center' }]}
        >
          <TouchableOpacity
            style={[sharedStyle.button, { marginRight: 15, borderColor: '#ED1B2E' }]}
            onPress={() => setComment('')}
          >
            <Text style={sharedStyle.textButton}>{t('common:Cancel')}</Text>
          </TouchableOpacity>
          <TouchableOpacity style={styles.actionBtn} onPress={() => saveComment()}>
            <Text style={{ color: '#FFF' }}>
              {t('common:Add')} {loading && comment !== '' && <ActivityIndicator />}
            </Text>
          </TouchableOpacity>
        </View>
      ) : (
        <></>
      )}
    </Container>
  )
}

const styles = StyleSheet.create({
  sectionInfo: {
    width: '100%',
    padding: 30,
    backgroundColor: '#FFF',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#D3DCE6',
    borderRadius: 8,
    marginBottom: 16
  },
  titleSectionText: {
    fontSize: 16,
    lineHeight: 15,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginBottom: 10
  },
  toolbar: {
    width: '100%',
    backgroundColor: '#FFF',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#D3DCE6',
    borderRadius: 8
  },
  actionBtn: {
    backgroundColor: '#ED1B2E',
    borderRadius: 100,
    paddingHorizontal: 20,
    paddingVertical: 10
  },
  cancelBtn: {
    paddingHorizontal: 20,
    paddingVertical: 10
  },
  buttonContainer: {
    flex: 1,
    justifyContent: 'center',
    flexDirection: 'row',
    height: 70,
    alignItems: 'center'
  }
})
