import { form2, convertUnicodeToViqr, InvalidStreet } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import i18next from 'i18next'
import * as t from 'io-ts'
import * as D from 'date-fns'
import { NonEmptyString, withMessage, date as DateCode } from 'io-ts-types'

export namespace ChangeClientInfoForm {
  const FileMeta = t.type({
    fileName: t.string,
    fileExtension: t.string,
    size: t.number,
    base64: t.string,
    uploadedDate: form2.date.required,
    file: form2.file.required
  })

  export type FileMeta = t.TypeOf<typeof FileMeta>

  type ListFileBrand = {
    readonly ListFile: unique symbol
  }

  type ListFile = t.Branded<FileMeta[], ListFileBrand>

  const ListFile = pipe(
    t.array(FileMeta),
    form2.refine(
      (l): l is ListFile => l.length >= 1,
      (l) => i18next.t('message:MS020001', { field: i18next.t('utilities:FileAttachment').toLowerCase() }),
      'ListFile'
    )
  )

  const RelationShip = t.type({
    clientId: form2.string.optional,
    clientName: form2.string.optional,
    relationship: form2.selectOption.optional,
    role: form2.string.optional,
    secuityNo: form2.string.optional,
    dob: form2.string.optional,
    gender: form2.string.optional,
    poName: form2.string.optional
    //sameClient: t.boolean
  })

  export type RelationShip = t.TypeOf<typeof RelationShip>

  type PhoneRelationShipBrand = {
    readonly PhoneRelationShip: unique symbol
  }

  type PhoneRelationShip = t.Branded<RelationShip, PhoneRelationShipBrand>

  const PhoneRelationShip = pipe(
    RelationShip,
    form2.refine(
      (l): l is PhoneRelationShip => {
        if (l.role === 'AG') {
          return false
        }
        return true
      },
      (l) => `Client co phone trung voi tu van vien ${l.clientName}`,
      'PhoneRelationShip'
    ),
    form2.refine(
      (l): l is PhoneRelationShip => {
        if (l.role !== 'AG') {
          return l.relationship !== null
        }
        return true
      },
      (l) => i18next.t('message:MS050228'),
      'PhoneRelationShip'
    )
  )

  type EmailRelationShipBrand = {
    readonly EmailRelationShip: unique symbol
  }

  type EmailRelationShip = t.Branded<RelationShip, EmailRelationShipBrand>

  const EmailRelationShip = pipe(
    RelationShip,
    form2.refine(
      (l): l is EmailRelationShip => {
        if (l.role === 'AG') {
          return false
        }
        return true
      },
      (l) => `Client co email trung voi tu van vien ${l.clientName}`,
      'EmailRelationShip'
    ),
    form2.refine(
      (l): l is EmailRelationShip => {
        if (l.role !== 'AG') {
          return l.relationship !== null
        }
        return true
      },
      (l) => i18next.t('message:MS050228'),
      'EmailRelationShip'
    )
  )

  type NumberStringBrand = {
    readonly NumberString: unique symbol
  }

  type foreignAddressBrand = {
    readonly MS050047: unique symbol
    readonly MS050223: unique symbol
  }

  type NumberString = t.Branded<NonEmptyString, NumberStringBrand>
  type foreignAddress = t.Branded<string | null, foreignAddressBrand>


  const IdNumber = pipe(
    withMessage(NonEmptyString, () =>
      i18next.t('message:MS020001', { field: i18next.t('submission:IDNumber').toLowerCase() })
    ),
    form2.refine(
      (l): l is NumberString => l.length >= 2 && l.length <= 24,
      (l) => i18next.t('message:MS050216'),
      'NumberString'
    )
  )

  // const ClientDetail = t.type({
  //   client: form2.selectOption.required,
  //   idType: form2.selectOption.required,
  //   idNumber: IdNumber,
  //   issueBy: form2.selectOption.required,
  //   surname: form2.string.required,
  //   givenName: form2.string.required,
  //   taxInUS: t.boolean,
  //   dob: form2.date.required,
  //   gender: form2.selectOption.required,
  //   nationality: form2.selectOption.required,
  //   mobileNumber: form2.string.optional,
  //   mobileCode: form2.selectOption.required,
  //   email: form2.email.optional,
  //   dupEmail: t.array(EmailRelationShip),
  //   dupPhone: t.array(PhoneRelationShip),
  //   files: ListFile
  // })

  const PhoneNumber = pipe(
    withMessage(NonEmptyString, () => i18next.t('message:MS020001', { field: i18next.t('submission:MobilePhone') })),
    form2.refine(
      (l): l is NumberString => /\b\d{10}\b/.test(l),
      (l) => i18next.t('message:MS050013', { field: i18next.t('submission:MobilePhone') }),
      'NumberString'
    )
  )

  const ClientDetail = t.intersection([
    t.type({
      requireIssue: t.boolean,
      client: withMessage(form2.selectOption.required, () =>
        i18next.t('message:MS020001', { field: i18next.t('submission:ChangeClientDetail').toLowerCase() })
      ),
      idType: withMessage(form2.selectOption.required, () =>
        i18next.t('message:MS020001', { field: i18next.t('submission:IdType').toLowerCase() })
      ),
      idNumber: IdNumber,
      surname: withMessage(form2.string.required, () =>
        i18next.t('message:MS020001', { field: i18next.t('submission:Surname').toLowerCase() })
      ),
      givenName: withMessage(form2.string.required, () =>
        i18next.t('message:MS020001', { field: i18next.t('submission:GiveName').toLowerCase() })
      ),
      taxInUS: t.boolean,
      // dob: withMessage(form2.date.requiredMax(new Date()), () =>
      //   i18next.t('message:MS050022', { field: i18next.t('submission:Gender').toLowerCase() })
      // ),
      dob: pipe(
        withMessage(form2.date.required, () =>
          i18next.t('message:MS020001', { field: i18next.t('submission:DateOfBirth').toLowerCase() })
        ),
        form2.refine(
          (l): l is form2.date.ValidDate => !Number.isNaN(l?.getTime()),
          (l) => i18next.t('common:InvalidDateFormat'),
          'ValidDate'
        ),
        form2.refine(
          (l): l is form2.date.ValidDate => D.isBefore(l, new Date()),
          (l) => i18next.t('message:MS050022'),
          'ValidDate'
        )
      ),
      oldDob: DateCode,
      gender: withMessage(form2.selectOption.required, () =>
        i18next.t('message:MS020001', { field: i18next.t('submission:Gender').toLowerCase() })
      ),
      nationality: withMessage(form2.selectOption.required, () =>
        i18next.t('message:MS020001', { field: i18next.t('submission:Nationality').toLowerCase() })
      ),
      mobileNumber: PhoneNumber,
      mobileCode: withMessage(form2.selectOption.required, () =>
        i18next.t('message:MS020001', { field: i18next.t('submission:MobilePhone').toLowerCase() })
      ),
      email: withMessage(form2.email.optional, () =>
        i18next.t('message:MS020001', { field: i18next.t('submission:Email').toLowerCase() })
      ),
      dupEmail: t.array(EmailRelationShip),
      dupPhone: t.array(PhoneRelationShip),
      files: ListFile,
      requireIssueDate: t.boolean,
      country: form2.selectOption.optional,
      city: withMessage(form2.selectOption.required, () => i18next.t('message:MS020009', { field: i18next.t('submission:CityProvince') })),
      district: withMessage(form2.selectOption.required, () => i18next.t('message:MS020009', { field: i18next.t('submission:District') })),
      ward: withMessage(form2.selectOption.required, () => i18next.t('message:MS020009', { field: i18next.t('submission:WardSubDistrict') })),
      street: withMessage(form2.string.required, () => i18next.t('message:MS020001', { field: i18next.t('submission:Street') })),
      foreignAddress: pipe(
        form2.string.optional,
        form2.refine(
          (l): l is foreignAddress => !l || (!InvalidStreet(l) && l.match(new RegExp(/[!@#$%^&*"]/g)) === null),
          () => i18next.t('message:MS050223'),
          'MS050223'
        ),
        form2.refine(
          (l): l is foreignAddress => !l || convertUnicodeToViqr(l).length <= 30,
          () => i18next.t('message:MS050047'),
          'MS050047'
        )
      ),
      countryOfForeignAddress: form2.selectOption.optional,
      taxResidencyCountry: withMessage(form2.selectOption.required, () => i18next.t('message:MS020009', { field: i18next.t('submission:TaxResidencyCountry') })),
      nationality2: form2.selectOption.optional
    }),
    t.union([
      t.type({
        requireIssue: t.literal(true),
        issueBy: withMessage(form2.selectOption.required, () =>
          i18next.t('message:MS020001', { field: i18next.t('submission:IssueBy').toLowerCase() })
        )
      }),
      t.type({
        requireIssue: t.literal(false),
        issueBy: form2.selectOption.optional
      })
    ]),
    t.union([
      t.type({
        requireIssueDate: t.literal(true),
        issuedDate: pipe(
          withMessage(form2.date.required, () =>
            i18next.t('message:MS020001', { field: i18next.t('submission:issuedDate').toLowerCase() })
          ),
          form2.refine(
            (l): l is form2.date.ValidDate => !Number.isNaN(l?.getTime()),
            (l) => i18next.t('common:InvalidDateFormat'),
            'ValidDate'
          ),
          form2.refine(
            (l): l is form2.date.ValidDate => D.isBefore(l, new Date()),
            (l) => i18next.t('message:MS050292'),
            'ValidDate'
          )
        )
      }),
      t.type({
        requireIssueDate: t.literal(false),
        issuedDate: pipe(
          form2.date.optional,
          form2.refine(
            (l): l is form2.date.ValidDate => !l || !Number.isNaN(l?.getTime()),
            (l) => i18next.t('common:InvalidDateFormat'),
            'ValidDate'
          ),
          form2.refine(
            (l): l is form2.date.ValidDate => !l || D.isBefore(l, new Date()),
            (l) => i18next.t('message:MS050292'),
            'ValidDate'
          )
        )
      })
    ])
  ])

  type ClientDetail = t.TypeOf<typeof ClientDetail>

  const OccupationDetail = t.type({
    occupation: withMessage(form2.selectOption.required, () =>
      i18next.t('message:MS020001', { field: i18next.t('submission:Occupation').toLowerCase() })
    ),
    jobDescription: form2.string.optional,
    jobTitle: form2.string.optional,
    companyName: form2.string.optional,
    companyAddress: form2.string.optional,
    salary: form2.selectOption.optional
  })

  export const codec = t.intersection([
    t.type({
      clientDetail: ClientDetail,
      chooseOccupation: t.boolean
    }),
    t.union([
      t.type({
        chooseOccupation: t.literal(false)
      }),
      t.type({
        chooseOccupation: t.literal(true),
        occupation: OccupationDetail
      })
    ])
  ])

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.OutputOf<typeof codec>
}
