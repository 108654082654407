import * as React from 'react'
import { View, Text } from 'react-native'
import { PolicyServiceProps, UploadedFilesInfo } from '../../../policy-service-props'
import {
  capFisrtLetterEachWord,
  FieldList,
  form2,
  formatNumberWithComma,
  addCommasToCurrencyString,
  getNumberFromString,
  getLanguage,
  HeaderTable,
  InputTable,
  Label,
  Panel,
  PartialWithdrawal,
  PartialWithdrawalService,
  PayoutPopup,
  Title,
  TopUpConst,
  TransactionType,
  formatStringToDecimalNumber,
  GeneralService,
  AppContext,
  TaskType
} from '@pulseops/common'
import { mapCashOutOption, PayoutMethod, PayoutMethodRef } from '../../../../payout-method'
import { useTranslation } from 'react-i18next'
import { ILPForm } from './ilp.form'
import { Controller, useFieldArray } from 'react-hook-form'
import { Column, Error, Warning } from '@pulseops/submission/common'
import { RequestAuthenticateData } from '../../../../request-authen'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'

enum FundType {
  TargetPremium = '1',
  TopUp = '2'
}

interface Props extends PolicyServiceProps<PartialWithdrawal.SubmitData> {
  policyNumber: string
  detail: any
}

export const ILP = ({ detail, initSubmission, isConfirmed, policyNumber, officeCode }: Props) => {
  const { t } = useTranslation()

  const payoutRef = React.useRef<PayoutMethodRef | null>(null)

  const [enableWATagetPremium, setEnableWATagetPremium] = React.useState<boolean>(false)
  const { showGlobalLoading, showToast } = React.useContext(AppContext.AppContextInstance)
  const [warningMsg, setWarningMsg] = React.useState<string>('')
  const [isLoading, bindLoader] = useLoading(false)

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  const isTargetPremiumFundType = (fundCode: string) => {
    return fundCode.endsWith(FundType.TargetPremium)
  }

  const isTopupFundType = (fundCode: string) => {
    return fundCode.endsWith(FundType.TopUp)
  }

  const getTotalAmountByFundType = (fundList: PartialWithdrawal.FundData[], fundType: FundType) => {
    return (
      fundList
        .filter((item) => item.fundCode.endsWith(fundType))
        // .reduce((total, item) => total + item.fundValue, 0)
        .reduce((total, item) => Number((total + item.fundValue).toFixed(2)), 0)
    )
  }

  const { totalAmountAllTargetPremiumFund, totalAmountAllTopUpFund } = React.useMemo(() => {
    const fundList = detail.ilp?.fundList || []
    const totalAmountAllTopUpFund = getTotalAmountByFundType(fundList, FundType.TopUp)
    const totalAmountAllTargetPremiumFund = getTotalAmountByFundType(fundList, FundType.TargetPremium)
    return {
      totalAmountAllTopUpFund,
      totalAmountAllTargetPremiumFund
    }
  }, [detail])

  const { base } = form2.useForm(ILPForm.codec, {
    defaultValues: { isLASMessage: false, fundList: [], payout: [] }
  })

  const fundListForm = useFieldArray({
    control: base.control,
    name: 'fundList'
  })

  const initFundListForm = (fundList: PartialWithdrawal.FundData[]) => {
    const subForm = fundList.map((item) => ({
      isRequired: isTopupFundType(item.fundCode),
      withdrawAmount: '0'
    }))
    fundListForm.remove()
    fundListForm.append(subForm)
  }

  React.useEffect(() => {
    const fundList = detail?.ilp?.fundList || []
    initFundListForm(fundList)
    base.setValue('withdrawFee', detail.ilp.withdrawFee)
    // return () => {
    //   base.reset({
    //     fundList: [],
    //     payout: [],
    //     withdrawFee: undefined,
    //     isLASMessage: false,
    //     LASMessage: undefined
    //   })
    // }
  }, [detail])

  React.useEffect(()=> {
    return () => {
      base.reset({
        fundList: [],
        payout: [],
        withdrawFee: undefined,
        isLASMessage: false,
        LASMessage: undefined
      })
    }
  },[policyNumber])

  const convertFundListToDataSource = (fundList: PartialWithdrawal.FundData[]) => {
    return fundList
      .filter((item) => item.fundValue > 0)
      .map((item) => {
        const fundName = TopUpConst.FundList.find((fund) => fund.fundCode === item.fundCode)
        return {
          ...item,
          fundName: `${item.fundCode} - ${getLanguage() === 'en' ? fundName?.desEn : fundName?.desVi}`,
          minPWRemain: formatNumberWithComma(item.minPWRemain),
          // fundValue: formatNumberWithComma(item.fundValue)
          fundValue: formatStringToDecimalNumber(item.fundValue.toFixed(2))
        }
      }) as any[]
  }

  const totalWithdrawAmount = React.useMemo(() => {
    const result = fundListForm.fields.reduce((total, item) => {
      const numberString = getNumberFromString(item.withdrawAmount ?? '')
      const amount = Number(numberString)
      const totalSum = Number((total + amount).toFixed(2))
      return totalSum
    }, 0)
    return result
  }, [base.watch('fundList')])

  const isFillAllWithdrawalAmountTopUp = () => {
    const fundList = detail?.ilp?.fundList || []
    const isFillAll =
      fundListForm.fields
        .filter((_, index) => isTopupFundType(fundList[index].fundCode))
        .find((item) => !item.withdrawAmount) === undefined
    return isFillAll
  }

  React.useEffect(() => {
    if (totalAmountAllTopUpFund === 0 && totalAmountAllTargetPremiumFund > 0) {
      setEnableWATagetPremium(true)
    } else {
      setEnableWATagetPremium(false)
    }
  }, [totalAmountAllTopUpFund, totalAmountAllTargetPremiumFund])

  const totalPayoutAmount = React.useMemo(() => {
    return base.getValues().payout.reduce((total, item) => Number((total + item.amount).toFixed(2)), 0)
  }, [base.watch('payout')])

  React.useEffect(() => {
    validateTotalWithdrawAmount()
  }, [totalPayoutAmount, base.formState.isValidating])

  React.useEffect(() => {
    validateTotalWithdrawAmount(true)
  }, [totalWithdrawAmount])

  React.useEffect(() => {
    const fundList = detail?.ilp?.fundList || []
    fundList.forEach((item: any, index: number) => {
      if (isTargetPremiumFundType(item.fundCode)) {
        base.setValue(
          `fundList.${index}`,
          { isRequired: enableWATagetPremium, withdrawAmount: '0' },
          { shouldValidate: true }
        )
      }
    })
  }, [enableWATagetPremium])

  const validateValueFor_ULR1 = (index: number, E: number, D: number, minPWRemain: number) => {
    let flag: boolean = true
    const remain = Number((D - E).toFixed(2))
    if (E > D) {
      base.setError(`fundList.${index}.withdrawAmount`, {
        message: t('message:MS050159')
      })
      flag = false
    } else if (remain < minPWRemain) {
      base.setError(`fundList.${index}.withdrawAmount`, {
        message: t('message:MS050247', { min: minPWRemain })
      })
      flag = false
    } else {
      base.clearErrors(`fundList.${index}.withdrawAmount`)
    }
    return flag
  }

  const validateValueFor_ULR4_URL5 = (index: number, E: number, D: number) => {
    let flag: boolean = true
    if (E > D) {
      base.setError(`fundList.${index}.withdrawAmount`, {
        message: t('message:MS050248')
      })
      flag = false
    } else {
      base.clearErrors(`fundList.${index}.withdrawAmount`)
    }
    return flag
  }

  const validateValueFor_ULR6 = (index: number, E: number, D: number, minPWRemain: number) => {
    let flag: boolean = true
    if (E > D) {
      base.setError(`fundList.${index}.withdrawAmount`, {
        message: t('message:MS050159')
      })
      flag = false
    } else {
      const fundList = detail?.ilp?.fundList || []
      const totalWATopup = base
        .watch('fundList')
        .filter((_, idx) => isTopupFundType(fundList[idx].fundCode))
        .reduce((total, item) => {
          // total + Number(item.withdrawAmount)
          const numberString = getNumberFromString(item.withdrawAmount ?? '')
          const amount = Number(numberString)
          const totalSum = Number((total + amount).toFixed(2))
          return totalSum
        }, 0)
      const isFillAllTopupWithDrawal = isFillAllWithdrawalAmountTopUp()

      if (
        (isFillAllTopupWithDrawal && totalWATopup === totalAmountAllTopUpFund) ||
        (totalAmountAllTopUpFund === 0 && totalAmountAllTargetPremiumFund > 0)
      ) {
        setEnableWATagetPremium(true)
        const remaining = D - E
        const isTargetPremium = isTargetPremiumFundType(fundList[index].fundCode)
        if (remaining < minPWRemain && isTargetPremium) {
          base.setError(`fundList.${index}.withdrawAmount`, {
            message: t('message:MS050247', { min: minPWRemain })
          })
          flag = false
        } else {
          base.clearErrors(`fundList.${index}.withdrawAmount`)
        }
      } else {
        base.clearErrors(`fundList.${index}.withdrawAmount`)
        setEnableWATagetPremium(false)
      }
    }
    return flag
  }

  const fundListDataSource = React.useMemo(() => convertFundListToDataSource(detail?.ilp?.fundList || []), [detail])

  const renderFundName = (index: number) => {
    const fund = detail?.ilp?.fundList[index]
    const withdrawAmount = base.watch('fundList')[index]?.withdrawAmount
    const isShowWarning =
      isTopupFundType(fund?.fundCode || '') && withdrawAmount && Number(withdrawAmount) !== fund?.fundValue

    return (
      <View>
        <Text>{fundListDataSource[index].fundName}</Text>
        {isShowWarning && !isConfirmed && <Error message={t('message:MS050249')} style={{ paddingEnd: 16 }} />}
      </View>
    )
  }

  const getTotalFee = () => {
    if (base.watch('isLASMessage')) {
      showToast(base.watch('LASMessage'), 'error')
    } else {
      const formValue = base.getValues()
      let fundDetailList =
        detail?.ilp && detail?.ilp?.fundList
          ? detail?.ilp?.fundList.map((fundItem: any, index: number) => {
              const withdrawAmount = getNumberFromString(formValue.fundList[index].withdrawAmount ?? '')
              return {
                coverage: '01',
                fundCode: fundItem.fundCode,
                amount: Number(withdrawAmount)
              }
            })
          : []
      fundDetailList = fundDetailList.filter((x: any) => Number(x.amount) > 0)
      const inputData: PartialWithdrawal.PWIlpEnquiryReq = {
        contractNumber: policyNumber,
        details: fundDetailList
      }
      pipe(
        PartialWithdrawalService.partialWithdrawalIlpEnquiryErrorCase(inputData),
        ZIO.foldM(
          (error) =>
            ZIO.effect(() => {
              if (!!error) {
                const errorSour = error.source as Error
                const messageList = errorSour.message.split('-')
                // messageList && messageList.length > 1 && showToast(t('message:MS050034') + messageList[1],'error')
                if (messageList && messageList.length > 1) {
                  const errorMsg = t('message:MS050034') + messageList[1]
                  base.setValue('isLASMessage', true)
                  base.setValue('LASMessage', errorMsg)
                  showToast(errorMsg, 'error')
                }
                base.setValue('withdrawFee', 0)
              }
            }),
          (success) =>
            ZIO.effect(() => {
              if (!!success) {
                const partialWithdrawFee = PartialWithdrawalService.round(success.totalFee ?? '0')
                base.setValue('withdrawFee', partialWithdrawFee)
              }
            })
        ),
        ZIO.unsafeRun({})
      )
    }
  }

  const checkValidPolicyFromLAS = () => {
    const formValue = base.getValues()
    let fundDetailList =
      detail?.ilp && detail?.ilp?.fundList
        ? detail?.ilp?.fundList.map((fundItem: any, index: number) => {
            const withdrawAmount = getNumberFromString(formValue.fundList[index].withdrawAmount ?? '')
            return {
              coverage: '01',
              fundCode: fundItem.fundCode,
              amount: Number(withdrawAmount)
            }
          })
        : []
    fundDetailList = fundDetailList.filter((x: any) => Number(x.amount) > 0)
    const inputData: PartialWithdrawal.PWIlpEnquiryReq = {
      contractNumber: policyNumber + 'VL',
      details: fundDetailList
    }
    return pipe(
      PartialWithdrawalService.validatePWIlpEnquiryErrorCase(inputData),
      ZIO.foldM(
        (error) => {
          const errorSour = error.source as Error
          const messageList = errorSour.message.split('-')
          showToast(t('message:MS050034') + messageList[1], 'error')
          return ZIO.fail(false)
        },
        (success) => {
          if (success.code === 1 && success.message === 'Success') {
            return ZIO.succeed(true)
          } else {
            showToast(t('message:MS050034') + success.message, 'error')
            return ZIO.succeed(false)
          }
        }
      )
    )
  }

  const validateTotalWithdrawAmount = (isTotalFee?: boolean) => {
    const P = totalWithdrawAmount
    const B = detail?.ilp?.minimumPartialWithdrawal || 0
    const C = detail?.ilp?.maximumPartialWithdrawal || 0
    const coverageCode = detail?.coverageCode || ''

    let isFormValid = false
    setWarningMsg('')
    if (base.watch('isLASMessage')) {
      showToast(base.watch('LASMessage'), 'error')
    } else {
      if (['ULR1', 'ULR4', 'ULR5', 'ULR6'].includes(coverageCode)) {
        if (P < B) {
          base.setError('fundListError', { message: t('message:MS050242', { Min: `${formatNumberWithComma(B)} VND` }) })
        } else if (P > C) {
          base.setError('fundListError', { message: t('message:MS050243', { Max: `${formatNumberWithComma(C)} VND` }) })
        } else if (coverageCode === 'ULR6' && P === C) {
          // base.setError('fundListError', { message: t('message:MS050245') })
          isFormValid = true
          base.clearErrors('fundListError')
          setWarningMsg(t('message:MS050245'))
        } else {
          isFormValid = true
          base.clearErrors('fundListError')
        }
      } else {
        isFormValid = true
        base.clearErrors('fundListError')
      }
    }
    isFormValid && isTotalFee === true && getTotalFee()
    return isFormValid
  }

  const validatePayout = () => {
    return pipe(
      ZIO.effect(() => {
        let isFormValid = false
        if (base.watch('payout').length === 0) {
          base.setError('payout', {
            message: t('message:MS020001', { field: capFisrtLetterEachWord(t('submission:PAYOUT_INFO')) })
          })
        } else if (totalPayoutAmount === 0) {
          base.setError('payout', { message: t('message:MS050241') })
        } else if (totalPayoutAmount != totalWithdrawAmount) {
          base.setError('payout', { message: t('message:MS050237') })
        } else {
          isFormValid = true
          base.clearErrors('payout')
        }
        return isFormValid
      }),
      ZIO.flatMap((isValid) => (isValid ? checkValidPolicyFromLAS() : ZIO.succeed(isValid))),
      ZIO.unsafeRun({})
    )
  }

  const mapBodySubmit = (detail: PartialWithdrawal.Detail): PartialWithdrawal.AttributesExt => {
    const formValue = base.getValues()
    const isULP = detail.productCode.startsWith('V')
    return {
      ESTIMATED_VALUE: detail?.ilp?.estimatedValue || 0,
      MAXIMUM_PARTIAL_WITHDRAWAL: detail?.ilp?.maximumPartialWithdrawal || 0,
      MINIMUM_PARTIAL_WITHDRAWAL: detail?.ilp?.minimumPartialWithdrawal || 0,
      WITHDRAW_FEE: detail?.ilp?.withdrawFee || 0,
      TOTAL_PAYOUT_AMOUNT: totalPayoutAmount,
      FUND_LIST: (detail?.ilp?.fundList || []).map((item, index) => {
        const ILPwithdrawAmount = Number(getNumberFromString(formValue.fundList[index].withdrawAmount ?? ''))
        return {
          'MIN_P/W_REMAIN': item.minPWRemain,
          FUND_CODE: item.fundCode,
          FUND_VALUE: Number(item.fundValue),
          WITHDRAW_AMOUNT: ILPwithdrawAmount
        }
      }),
      ...(isULP
        ? {
            NEW_SUM_ASSURED: 0,
            CURRENT_SUM_ASSURED: 0,
            WITHDRAW_AMOUNT: 0
          }
        : { TOTAL_WITHDRAW_AMOUNT: totalWithdrawAmount })
    }
  }

  const getUploadedFilesInfo = (formData: ILPForm.Raw) => {
    let uploadedFileList: UploadedFilesInfo[] = []
    const uploadedItem: UploadedFilesInfo = {
      uploadFiles: GeneralService.getPayoutDocuments(formData.payout),
      transactionType: TransactionType.PARTIAL_WITHDRAWAL,
      docTypeCode: 'DPS01',
      category: TaskType.PolicyService,
      policyNumber: policyNumber ?? '',
      officeCode: officeCode ?? ''
    }
    uploadedFileList.push(uploadedItem)
    return uploadedFileList
  }

  initSubmission({
    validate: async (isContinue) => {
      let isFormValid = true
      isFormValid = validateTotalWithdrawAmount() && (await validatePayout()) && (await base.trigger('fundList'))
      if (isFormValid && detail) {
        const formValue = base.getValues()
        return {
          url: (policyNum) => `wf-api/policy/${policyNum}/partial-withdrawal`,
          collerationId: policyNumber,
          transactionName: RequestAuthenticateData.TransactionLabelShort(TransactionType.PARTIAL_WITHDRAWAL),
          transaction: TransactionType.PARTIAL_WITHDRAWAL,
          body: {
            cashOutOptions: mapCashOutOption(formValue.payout),
            policy: {
              policyNo: policyNumber
            },
            attributesExt: mapBodySubmit(detail)
          },
          // documents: filesUploaded
          uploadedFilesInfo: getUploadedFilesInfo(formValue)
        }
      }
      return false
    },
    clear: () => {
      const fundList = detail?.ilp?.fundList || []
      initFundListForm(fundList)
      setEnableWATagetPremium(false)
      payoutRef.current?.clearData()
    }
  })

  return (
    <Column flex={1}>
      <Title title={t('requestInfo:PARTIAL_WITHDRAWAL_INFO')} wrapperStyle={{ marginTop: 30 }} />
      <Panel isExand={false} containerStyle={{ backgroundColor: '#FAFAFA' }}>
        <FieldList
          dataSource={[
            {
              label: t('requestInfo:EstimatedValue'),
              value: formatNumberWithComma(detail.ilp.estimatedValue),
              suffix: 'VND'
            },
            {
              label: t('requestInfo:Minimumpartialwithdrawal'),
              value: formatNumberWithComma(detail.ilp.minimumPartialWithdrawal),
              suffix: 'VND'
            },
            {
              label: t('requestInfo:Maximumpartialwithdrawal'),
              value: formatNumberWithComma(detail.ilp.maximumPartialWithdrawal),
              suffix: 'VND'
            },
            {
              label: t('requestInfo:Withdrawfee'),
              // value: formatNumberWithComma(detail.ilp.withdrawFee),
              value: formatNumberWithComma(base.watch('withdrawFee')),
              suffix: 'VND'
            }
          ]}
        />

        <Label title={t('requestInfo:Fundlist')} />
        <Text style={{ fontSize: 15, fontWeight: '400', color: '#000000', marginTop: 14, marginBottom: 33 }}>
          {`${t('requestInfo:TotalWithdrawAmount')}:`}
          <Text
            style={{ fontSize: 18, fontWeight: 'bold', color: '#ED1B2E', marginStart: 4 }}
          >{`${addCommasToCurrencyString(String(totalWithdrawAmount))} VND`}</Text>
        </Text>

        <Text style={{ color: '#70777E', fontStyle: 'italic', fontSize: 15, textAlign: 'right', marginBottom: 12 }}>
          {`${t('Payout:Currency')}: VND`}
        </Text>
        <HeaderTable
          columns={[
            {
              key: 0,
              title: t('requestInfo:Fund'),
              name: 'fundName',
              render: ({ index }) => renderFundName(index)
            },
            {
              key: 1,
              title: t('requestInfo:MinP/Wremain'),
              name: 'minPWRemain'
            },
            {
              key: 2,
              title: t('requestInfo:Fundvalue'),
              name: 'fundValue'
            },
            {
              key: 3,
              title: t('requestInfo:WithdrawAmount'),
              name: '',
              require: !isConfirmed,
              styles: {
                textAlign: 'right'
              },
              render: ({ index }) => {
                const fundForm = fundListForm.fields[index]
                if (fundForm) {
                  return (
                    <Controller
                      control={base.control}
                      name={`fundList.${index}.withdrawAmount`}
                      render={({ field, fieldState: { error } }) => {
                        const fundList = detail.ilp?.fundList || []
                        const isTargePremium = isTargetPremiumFundType(fundList[index].fundCode)
                        const isDisable = (isTargePremium && !enableWATagetPremium) || isConfirmed

                        return (
                          <InputTable
                            disabled={isDisable}
                            value={field.value || ''}
                            inputType="money"
                            isFractionFormat={true}
                            textAlign="right"
                            inputStyle={{
                              backgroundColor: isDisable ? '#FAFAFA' : '#FFFFFF',
                              borderColor: '#D3DCE6'
                            }}
                            errorMessage={error?.message}
                            onChange={(value) => {
                              fundListForm.update(index, { isRequired: !isDisable, withdrawAmount: value })
                              if (value) {
                                // const withdrawAmount = Number(value || 0)
                                const withdrawAmount = Number(getNumberFromString(value ?? ''))
                                const fundValue = fundList[index]?.fundValue || 0
                                const minPWRemain = Number(fundList[index]?.minPWRemain || 0)
                                if (detail.coverageCode === 'ULR1') {
                                  validateValueFor_ULR1(index, withdrawAmount, fundValue, minPWRemain)
                                } else if (detail.coverageCode === 'ULR4' || detail.coverageCode === 'ULR5') {
                                  validateValueFor_ULR4_URL5(index, withdrawAmount, fundValue)
                                } else if (detail.coverageCode === 'ULR6') {
                                  validateValueFor_ULR6(index, withdrawAmount, fundValue, minPWRemain)
                                }
                              } else if (fundForm.isRequired) {
                                base.setError(`fundList.${index}.withdrawAmount`, {
                                  message: t('form:error_required_field')
                                })
                              }
                            }}
                          />
                        )
                      }}
                    />
                  )
                }
                return <View />
              }
            }
          ]}
          dataSource={fundListDataSource}
          rowStyle={{ backgroundColor: '#FAFAFA' }}
        />
        <Error message={base.formState?.errors?.fundListError?.message || ''} style={{ marginTop: 10 }} />
        <Warning message={warningMsg} style={{ marginTop: 10 }} />
      </Panel>

      <Controller
        control={base.control}
        name="payout"
        render={({ field, fieldState: { error } }) => (
          <PayoutMethod
            {...field}
            ref={payoutRef}
            policyNum={policyNumber}
            transactionType={TransactionType.PARTIAL_WITHDRAWAL}
            otherPremiumTitle={t('submission:OtherFee_Alteration')}
            errorMessage={error?.message}
            editable={!isConfirmed}
            maxAmount={totalWithdrawAmount}
            isFractionFormat={true}
            methods={[
              PayoutPopup.PayoutMethods.PAYPREMIUM,
              PayoutPopup.PayoutMethods.PAYLOAN,
              PayoutPopup.PayoutMethods.CASHLESS,
              PayoutPopup.PayoutMethods.OTHER,
              PayoutPopup.PayoutMethods.CASH_AT_COUNTER,
              PayoutPopup.PayoutMethods.PAID_AT_BANK,
              PayoutPopup.PayoutMethods.BANKTRANSFER,
              PayoutPopup.PayoutMethods.MOMO
            ]}
            officeCode={officeCode}
            isSeaBankPolicy={!!detail.warningMessage}
          />
        )}
      />
    </Column>
  )
}
