import React, { useEffect } from 'react'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useTranslation } from 'react-i18next'
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native'
import {
  AppContext,
  assets,
  Checkbox,
  ErrorHandling,
  GeneralService,
  ImgUploadMutiple,
  Input,
  RadioButton,
  sharedStyle,
  SubmissionService,
  TabList,
  TransactionType
  // TransactionType
} from '@pulseops/common'
import { RequestAuthenticateData } from './request-authen.data'
import moment from 'moment'
import { pipe } from 'fp-ts/lib/function'
import { Throwable, ZIO } from '@mxt/zio'
import { of, Observable, timer } from 'rxjs'
import * as RO from 'rxjs/operators'
import { Controller, UseFormReturn } from 'react-hook-form'
import { SignCheckDialog } from '@pulseops/task'

export interface TransactionAuthen {
  showOtp?: boolean
  showPaper?: boolean
  requestAuthenData: RequestAuthenticateData.EformRequestAuthenticateData
  authenForm: UseFormReturn<RequestAuthenticateData.RequestAuthFormValue>
  isByPassAuthPaper: boolean
  updateSendDate: (sendDate: string) => void
  updatedocReviewed: (docReview: boolean) => void
  updateRequestAuthenData: (data: RequestAuthenticateData.EformRequestAuthenticateData) => void
  officeCode: string
  transactionType: string
  hideOTP?: boolean
  isHiddenRequestForm?: boolean
  isHiddenConsentOtp?: boolean
  selectOPTOption?: boolean
  setOTPOption?: (e: boolean) => void
}

export const RequestAuthen = (props: TransactionAuthen) => {
  const { hideOTP = false } = props
  const otpCounter = 120
  const { t } = useTranslation()
  // const currentTransactionType = props.transactionType as TransactionType
  const requestAuthenData = props.requestAuthenData
  const docTypeCode = requestAuthenData.docTypeCode
  const { showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const [tabIndex, setTabIndex] = React.useState<number>(0)
  const [otpState, setOTPState] = React.useState<number>(0)
  const [otpCounterStr, setOTPCounterStr] = React.useState<Observable<string>>(of(''))
  const [otpTimer, setOTPTimer] = React.useState<string>('')
  const [isSignaturePopupOpen, setIsSignaturePopupOpen] = React.useState<boolean>(false)
  const { control, setValue, watch, reset } = props.authenForm
  const [selectOPT, setSelectOTP] = React.useState<boolean>(true)
  React.useEffect(() => {
    const subscription = otpCounterStr.subscribe(setOTPTimer)
    return () => subscription.unsubscribe()
  }, [otpCounterStr])

  if (!props.isByPassAuthPaper) {
    pipe(
      docTypeCode
        ? GeneralService.getMetaData(requestAuthenData.transaction || '', docTypeCode, props.officeCode)
        : GeneralService.getMetaData(requestAuthenData.transaction || '', 'DPS17', props.officeCode),
      ZIO.tap((x) => {
        props.updateRequestAuthenData({
          ...requestAuthenData,
          metaData: {
            type: x.data.transactionType,
            doctype: x.data.doctypeEn,
            class: x.data.classFilenet,
            docid: x.data.docID,
            maindoc: x.data.mainDoc,
            subdoc: x.data.subDoc,
            polnum: requestAuthenData.policyNum,
            batchno: x.data.batchNo
          }
        })
        return ZIO.unit
      }),
      ZIO.catchAll(() => ZIO.unit),
      ErrorHandling.runDidMount()
    )
  }

  const requestOTP = () => {
    const sendDate = moment().format('yyMMDDhhmmss').substr(2, 12)
    props.updateSendDate(sendDate)
    showGlobalLoading(true)
    pipe(
      SubmissionService.otpRequest(
        requestAuthenData.transactionName,
        `${requestAuthenData.transactionType}-${requestAuthenData.collerationId}-${sendDate}`,
        requestAuthenData.phoneData.phoneNum,
        requestAuthenData.policyNum,
        requestAuthenData.isCCE
      ),
      ZIO.mapError((err) => {
        const code = err.source.message.split(' - ')[0]
        const time = err.source.message.split(' - ')[1].replace('t=', '')
        const getErrorMess =
          code === 'MS050252'
            ? t('message:MS050252', { t: time })
            : code === 'MS050254'
            ? t('message:MS050254', { t: time })
            : code === 'MS050253'
            ? t('message:MS050253')
            : code === 'MS050255'
            ? t('message:MS050255')
            : 'Unknown error'
        showToast(getErrorMess, 'error')
        showGlobalLoading(false)
        return Throwable('Done')
      }),
      ZIO.unsafeRun({})
    ).then((_) => {
      showGlobalLoading(false)
      setOTPState(1)
      let countDown = otpCounter
      const otpCounter$ = timer(0, 1000).pipe(
        RO.take(countDown),
        RO.map(() => --countDown),
        RO.takeWhile((x) => x >= 0)
      )
      const otpCounterStr$ = otpCounter$.pipe(
        RO.map((x) => `0${Math.floor(x / 60)}:${x % 60 < 10 ? `0${x % 60}` : x % 60}`)
      )
      setOTPCounterStr(otpCounterStr$)
    })
  }

  const onChangeTabEvent = (index: number) => {
    setTabIndex(index)
    reset({
      otp: {
        otpOption: RequestAuthenticateData.OTPOption.PHONE,
        otpInput: undefined,
        otpConsent: false,
        authentication_option: false,
        authentication_data: []
      },
      paper: {
        data: [],
        consent: false
      }
    })
    switch (index) {
      case 0:
        setValue('authOption', RequestAuthenticateData.RequestAuthOption.OTP)
        return
      case 1:
        setValue('authOption', RequestAuthenticateData.RequestAuthOption.PAPER)
        return
      case 2:
        setValue('authOption', RequestAuthenticateData.RequestAuthOption.OTP_AND_ATTACH_REQUEST_FORM)
        return
      case 3:
        setValue(
          'authOption',
          RequestAuthenticateData.RequestAuthOption.OWNER_SUBSCRIPTION_DOCUMENT_AND_ATTACH_REQUEST_FORM
        )
        return
      default:
        setValue('authOption', RequestAuthenticateData.RequestAuthOption.OTP)
        return
    }
  }

  const openSignatureCheckPopup = () => {
    setIsSignaturePopupOpen(true)
  }

  const handleCloseSignCheck = () => {
    setIsSignaturePopupOpen(false)
  }

  const menus = React.useMemo(() => {
    let tabs = []
    if (!hideOTP) {
      tabs.push({
        id: 1,
        title: t('submission:OTP')
      })
    }

    !props.isHiddenRequestForm &&
      tabs.push({
        id: 2,
        title: t('submission:requestForm')
      })

    props.transactionType === TransactionType.CHANGE_CONTACT_INFO &&
      props.isHiddenRequestForm &&
      tabs.push({
        id: 3,
        title: t('submission:RequestFormOTP')
      })

    props.transactionType === TransactionType.CHANGE_CONTACT_INFO &&
      props.isHiddenRequestForm &&
      tabs.push({
        id: 4,
        title: t('submission:RequestFormOwnerSubcriptionDocument')
      })
    !props.isHiddenRequestForm ? onChangeTabEvent(hideOTP ? 1 : 0) : onChangeTabEvent(2)
    return tabs
  }, [hideOTP])

  const timeName = () => {
    return (
      <Text style={{ marginTop: 15, marginLeft: 10, fontWeight: 'normal', color: '#43484c' }}>
        {t('OTP:consent3')}
        <Text style={{ fontWeight: 'bold' }}>{t('OTP:timeConsent3')}</Text>
        <Text>{t('OTP:timeConsentEnd')}</Text>
      </Text>
    )
  }


  useEffect(() => {
    switch (watch('authOption')) {
      case RequestAuthenticateData.RequestAuthOption.OTP_AND_ATTACH_REQUEST_FORM:
        props.updateRequestAuthenData({
          ...requestAuthenData,
          mainPhoneNumber: false
        })
        return
      case RequestAuthenticateData.RequestAuthOption.OWNER_SUBSCRIPTION_DOCUMENT_AND_ATTACH_REQUEST_FORM:
        props.updateRequestAuthenData({
          ...requestAuthenData,
          mainPhoneNumber: true
        })
        return
      default:
        props.updateRequestAuthenData({
          ...requestAuthenData,
          mainPhoneNumber: false
        })
        return
    }
  }, [watch('authOption')])

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <Text style={[sharedStyle.sectionTitle, { marginTop: 15 }]}>{t('submission:requestAuth')}</Text>
      <Text
        style={[
          { fontSize: 12.8, opacity: 0.6 },
          { marginTop: 5, marginBottom: 15 }
        ]}
      >
        {t('submission:AuthenticationMethod')} <Text style={{ color: '#ED2B1C' }}>*</Text>{' '}
      </Text>

      <View style={{ backgroundColor: 'rgb(211, 220, 230)', borderTopLeftRadius: 14, borderTopRightRadius: 14 }}>
        <TabList menus={menus} isSubTab={false} tabIndex={tabIndex} isOTPStyle={true} onChangeTab={onChangeTabEvent} />
      </View>
      <View style={otpStyles.authenContainer}>
        {/* Registered by OTP */}
        {tabIndex === 0 && <OTPRequestForm {...props} />}
        {/* registered by PAPER */}
        {tabIndex === 1 && (
          <View style={otpStyles.paperContainer}>
            <View style={otpStyles.paperHeader}>
              {!props.isByPassAuthPaper && (
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <Text style={{ fontSize: 16, marginRight: 5 }}>{t('OTP:PaperAttachment')}</Text>
                  <Text style={{ color: '#ed1b2e', fontWeight: 'bold' }}> *</Text>
                </View>
              )}
              <TouchableOpacity onPress={openSignatureCheckPopup}>
                <View style={[otpStyles.signatureContent, props.isByPassAuthPaper && otpStyles.signatureText]}>
                  <assets.EyesIcon />
                  <Text style={{ marginLeft: 16, fontSize: 16 }}>{t('OTP:SignCheck')}</Text>
                </View>
              </TouchableOpacity>
            </View>
            <View>
              {!props.isByPassAuthPaper && (
                <Controller
                  name="paper.data"
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <ImgUploadMutiple value={value} onChange={onChange} timeFormat={'DD/MM/YYYY HH:mm'} />
                  )}
                />
              )}
            </View>
            <View>
              <Controller
                name="paper.consent"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: ''
                  }
                }}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <Checkbox
                    title={t('OTP:consent')}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    errorMessage={error?.message}
                  />
                )}
              />
            </View>
          </View>
        )}
        {tabIndex === 2 && (
          <>
            <View style={[otpStyles.paperContainer, { display: 'flex', flexDirection: 'row', flex: 1 }]}>
              <View style={{ flex: 1 }}>
                <OTPRequestForm {...{ ...props, isHiddenConsentOtp: true, selectOPTOption: selectOPT, setOTPOption: setSelectOTP }} />
              </View>
            </View>
            <View style={otpStyles.paperContainer}>
              <View style={otpStyles.paperHeader}>
                {!props.isByPassAuthPaper && (
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Text style={{ fontSize: 16, marginRight: 5 }}>{t('OTP:PaperAttachment')}</Text>
                    <Text style={{ color: '#ed1b2e', fontWeight: 'bold' }}> *</Text>
                  </View>
                )}
                <TouchableOpacity onPress={openSignatureCheckPopup}>
                  <View style={[otpStyles.signatureContent, props.isByPassAuthPaper && otpStyles.signatureText]}>
                    <assets.EyesIcon />
                    <Text style={{ marginLeft: 16, fontSize: 16 }}>{t('OTP:SignCheck')}</Text>
                  </View>
                </TouchableOpacity>
              </View>
              <View>
                {!props.isByPassAuthPaper && (
                  <Controller
                    name="paper.data"
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <ImgUploadMutiple value={value} onChange={onChange} timeFormat={'DD/MM/YYYY HH:mm'} />
                    )}
                  />
                )}
              </View>
              <View>
                <Controller
                  name="paper.consent"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: ''
                    }
                  }}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <Checkbox
                      title={t('OTP:consent')}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      errorMessage={error?.message}
                    />
                  )}
                />
              </View>
            </View>
          </>
        )}
        {tabIndex === 3 && (
          <>
            <View style={[otpStyles.paperContainer, { display: 'flex', flexDirection: 'row', flex: 1 }]}>
              <View style={{ flex: 1 }}>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <Text style={{ fontSize: 16, marginRight: 5 }}>{t('submission:OwnerSubcriptionDocument')}</Text>
                  <Text style={{ color: '#ed1b2e', fontWeight: 'bold' }}> *</Text>
                </View>
                <Controller
                  name="otp.authentication_data"
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <ImgUploadMutiple value={value} onChange={onChange} timeFormat={'DD/MM/YYYY HH:mm'} />
                  )}
                />
              </View>
            </View>
            <View style={otpStyles.paperContainer}>
              <View style={otpStyles.paperHeader}>
                {!props.isByPassAuthPaper && (
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Text style={{ fontSize: 16, marginRight: 5 }}>{t('OTP:PaperAttachment')}</Text>
                    <Text style={{ color: '#ed1b2e', fontWeight: 'bold' }}> *</Text>
                  </View>
                )}
                <TouchableOpacity onPress={openSignatureCheckPopup}>
                  <View style={[otpStyles.signatureContent, props.isByPassAuthPaper && otpStyles.signatureText]}>
                    <assets.EyesIcon />
                    <Text style={{ marginLeft: 16, fontSize: 16 }}>{t('OTP:SignCheck')}</Text>
                  </View>
                </TouchableOpacity>
              </View>
              <View>
                {!props.isByPassAuthPaper && (
                  <Controller
                    name="paper.data"
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <ImgUploadMutiple value={value} onChange={onChange} timeFormat={'DD/MM/YYYY HH:mm'} />
                    )}
                  />
                )}
              </View>
              <View>
                <Controller
                  name="paper.consent"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: ''
                    }
                  }}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <Checkbox
                      title={t('OTP:consent')}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      errorMessage={error?.message}
                    />
                  )}
                />
              </View>
              <View style={{ marginTop: -20 }}>
                <Controller
                  name="paper.consent1"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: ''
                    }
                  }}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <Checkbox
                      title={timeName()}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      errorMessage={error?.message}
                    />
                  )}
                />
              </View>
            </View>
          </>
        )}
      </View>
      {isSignaturePopupOpen && (
        <SignCheckDialog
          open={isSignaturePopupOpen}
          onClose={handleCloseSignCheck}
          processInstanceId={''}
          policyNum={requestAuthenData.policyNum}
          onDocReviewed={(value) => props.updatedocReviewed(value)}
          isRequestForm={true}
          docReviewed={requestAuthenData.docReviewed}
        ></SignCheckDialog>
      )}
    </SafeAreaView>
  )
}

const OTPRequestForm = (props: TransactionAuthen) => {
  const [otpState, setOTPState] = React.useState<number>(0)
  const [otpCounterStr, setOTPCounterStr] = React.useState<Observable<string>>(of(''))
  const [otpTimer, setOTPTimer] = React.useState<string>('')
  const { control, setValue } = props.authenForm
  const requestAuthenData = props.requestAuthenData
  const { showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const { t } = useTranslation()
  const otpCounter = 120
  const MapOTPOptions: { value: RequestAuthenticateData.OTPOption; label: string } = {
    value: RequestAuthenticateData.OTPOption.PHONE,
    label: `+${requestAuthenData.phoneData.phoneCode} ${requestAuthenData.phoneData.phoneNum}`
  }
  React.useEffect(() => {
    const subscription = otpCounterStr.subscribe(setOTPTimer)
    return () => subscription.unsubscribe()
  }, [otpCounterStr])

  const timeName = () => {
    return (
      <Text style={{ marginTop: 15, marginLeft: 10, fontWeight: 'normal', color: '#43484c' }}>
        {t('OTP:consent3')}
        <Text style={{ fontWeight: 'bold' }}>{t('OTP:timeConsent3')}</Text>
        <Text>{t('OTP:timeConsentEnd')}</Text>
      </Text>
    )
  }

  const requestOTP = () => {
    const sendDate = moment().format('yyMMDDhhmmss').substr(2, 12)
    props.updateSendDate(sendDate)
    showGlobalLoading(true)
    pipe(
      SubmissionService.otpRequest(
        requestAuthenData.transactionName,
        `${requestAuthenData.transactionType}-${requestAuthenData.collerationId}-${sendDate}`,
        requestAuthenData.phoneData.phoneNum,
        requestAuthenData.policyNum,
        requestAuthenData.isCCE
      ),
      ZIO.mapError((err) => {
        const code = err.source.message.split(' - ')[0]
        const time = err.source.message.split(' - ')[1].replace('t=', '')
        const getErrorMess =
          code === 'MS050252'
            ? t('message:MS050252', { t: time })
            : code === 'MS050254'
            ? t('message:MS050254', { t: time })
            : code === 'MS050253'
            ? t('message:MS050253')
            : code === 'MS050255'
            ? t('message:MS050255')
            : 'Unknown error'
        showToast(getErrorMess, 'error')
        showGlobalLoading(false)
        return Throwable('Done')
      }),
      ZIO.unsafeRun({})
    ).then((_) => {
      showGlobalLoading(false)
      setOTPState(1)
      let countDown = otpCounter
      const otpCounter$ = timer(0, 1000).pipe(
        RO.take(countDown),
        RO.map(() => --countDown),
        RO.takeWhile((x) => x >= 0)
      )
      const otpCounterStr$ = otpCounter$.pipe(
        RO.map((x) => `0${Math.floor(x / 60)}:${x % 60 < 10 ? `0${x % 60}` : x % 60}`)
      )
      setOTPCounterStr(otpCounterStr$)
    })
  }
  const {selectOPTOption = true} = props

  return (
    <View style={{ padding: 20 }}>
      {props.requestAuthenData.otpRegistered && (
        <View>
          <View style={{ paddingLeft: 10 }}>
            {MapOTPOptions && (
              <Controller
                name="otp.otpOption"
                control={control}
                defaultValue={MapOTPOptions.value}
                render={({ field: { onChange, onBlur, value } }) => (
                  <RadioButton
                    label={MapOTPOptions.label}
                    onChange={(e) => {
                      onChange(e)
                      props?.setOTPOption?.(true)
                    }}
                    onBlur={onBlur}
                    value={value}
                    selected={selectOPTOption}
                  />
                )}
              />
            )}
            {/* {MapOTPOptions && <RadioButton label={MapOTPOptions.label} selected={true} />} */}
            {otpState === 0 && (
              <TouchableOpacity style={{ width: 110, marginTop: 30 }} onPress={requestOTP}>
                <View style={otpStyles.sendBtn}>
                  <Text style={otpStyles.sendBtnText}>{t('OTP:send')}</Text>
                </View>
              </TouchableOpacity>
            )}
            {otpState === 1 && (
              <View style={[otpStyles.sectionRow, { marginTop: 10 }]}>
                <View style={otpStyles.col_4}>
                  <Controller
                    name="otp.otpInput"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'OTP is not valid'
                      }
                    }}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <Input
                        title={''}
                        onChange={(val) => onChange && onChange(val.replace(/[^0-9]/g, ''))}
                        onBlur={onBlur}
                        value={String(value ?? '')}
                        errorMessage={error?.message}
                        // disabled={isDisabled || disabledAgentCode}
                        maxLength={6}
                      />
                    )}
                  />
                  <Text style={{ color: '#ef1c2e' }}>
                    {t('OTP:timeInput')} ({otpTimer})
                  </Text>
                  <TouchableOpacity style={{ width: 110, marginTop: 10 }} onPress={requestOTP}>
                    <View style={otpStyles.sendBtn}>
                      <Text style={otpStyles.sendBtnText}>{t('OTP:resend')}</Text>
                    </View>
                  </TouchableOpacity>
                </View>
              </View>
            )}
          </View>
          {!props?.isHiddenConsentOtp ? (
            <>
              <View>
                <Controller
                  name="otp.otpConsent"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: ''
                    }
                  }}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <Checkbox
                      title={t('OTP:consent')}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      errorMessage={error?.message}
                    />
                  )}
                />
              </View>
              <View style={{ marginTop: -20 }}>
                <Controller
                  name="otp.optConsent1"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: ''
                    }
                  }}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <Checkbox
                      title={timeName()}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      errorMessage={error?.message}
                    />
                  )}
                />
              </View>
            </>
          ) : (
            ''
          )}
        </View>
      )}
      {/* unregistered OTP */}
      {!props.requestAuthenData.otpRegistered && <Text style={otpStyles.errorMessage}>{t('message:MS050268')}</Text>}
    </View>
  )
}
const otpStyles = StyleSheet.create({
  authenContainer: {
    borderWidth: 1,
    borderBottomLeftRadius: 14,
    borderBottomRightRadius: 14,
    minHeight: 50,
    borderTopWidth: 0,
    padding: 20,
    borderColor: 'rgb(211, 220, 230)'
  },
  errorMessage: {
    color: '#ef1c2e',
    fontSize: 15
  },
  sendBtn: {
    paddingHorizontal: 16,
    paddingVertical: 0,
    backgroundColor: 'white',
    borderRadius: 100,
    color: '#ED1B2E',
    border: 'solid 1px #ED1B2E',
    boxShadow: 'none'
  },
  sendBtnText: {
    textAlign: 'center',
    color: '#ef1c2e',
    lineHeight: 30
  },
  sectionRow: {
    flex: 1,
    flexDirection: 'row',
    marginRight: -15,
    marginLeft: -15
  },
  col_4: {
    width: '100%',
    maxWidth: '33.3333333333%',
    paddingRight: 15,
    paddingLeft: 15
  },
  col_12: {
    width: '100%',
    maxWidth: '100%',
    paddingRight: 15,
    paddingLeft: 15
  },
  paperContainer: {
    padding: 20
  },
  paperHeader: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  signatureContent: {
    marginLeft: 25,
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  signatureText: {
    marginLeft: 10
  }
})
