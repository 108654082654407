import { Throwable, ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import {
  AppContext,
  Columns,
  Container,
  DatePicker,
  ErrorHandling,
  FieldList,
  formatNumberWithComma,
  FundMapping,
  Input,
  InvestmentInfo_ILP,
  Panel,
  PulseOpsFormat,
  Select,
  TableField,
  useTranslator,
  PartialWithdrawal as PartialWithdrawalData,
  FrequencyMapping,
  Frequency,
  form2,
  Alert
} from '@pulseops/common'
import { RouteProp, useIsFocused, useNavigation, useRoute } from '@react-navigation/native'
import { pipe } from 'fp-ts/lib/function'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { CepILPPartialWithdraw, CepTableField } from '../cep-components'
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native'
import { StackNavigationProp } from '@react-navigation/stack'
import { InquiryStackParamList } from '../../InquiryStackParamList'
import { CepFormConst } from '../CEPFormConst'
import { codesPremium, FundList, FundType, IProductDetail, PropsPartial } from '../CEPInterface'
import {
  FundAllocateList,
  ILPTopupHistoryList,
  PartialWithDraw,
  PartialWithDrawILP,
  ProductTypeC,
  ULPTopupHistoryList
} from '../../cep-api-services/CepInvesmentModels'
import {
  calcPWAmount,
  CepService,
  getCoreTransaction,
  getFundAllocate,
  getILPTopupHistory,
  getInvestmentInfo,
  getPartialWithDrawILP,
  getPartialWithDrawIULP,
  getProductType,
  getULPTopupHistory
} from '@pulseops/inquiry'
import { isEmpty } from 'lodash'
import _ from 'lodash'
import i18next from 'i18next'
import { Controller } from 'react-hook-form'
import { NonEmptyString, withMessage } from 'io-ts-types'
import * as t from 'io-ts'
import { PolicyServiceClear, PolicyServiceSubmit } from '@pulseops/submission'

export interface InvestmentInfoData {
  fundValue: TableField.DataSources
  pwAmount: TableField.DataSources
  ulpInfo: FieldList.DataSources
  productType: string
  ILPTopUp?: TableField.DataSources
  ULPTopUp?: TableField.DataSources 
  fundAllocate: TableField.DataSources
  pwInfoULP?: PartialWithDraw
  pwInfoILP?: PartialWithDrawILP
}

export interface InvestmentInfo_ULPItem {
  targetPremium?: string
  topupPremium?: string
  ilpPartialWithdrawal?: string
}
export interface CepInvestmentInfo {
  ilp?: InvestmentInfo_ILP
  ulp?: InvestmentInfo_ULPItem
  productType: string
  ILPTopUp?: Array<{
    transactionDate: string,
    fundId: string,
    effectivePrice: number,
    fundAmount: number,
    realUnit: number,
    contractAmount: number,
    topUpPremium: number,
    sourcePercentAmount: number,
  }>
  ULPTopUp?: ULPTopupHistoryList
  fundAllocate: Array<{
    fundId: string
    fundName: string
    percentage: string
  }>
  pwInfoULP?: PartialWithDraw
  pwInfoILP?: PartialWithDrawILP
}
type withdrawalAmountBrand = {
  readonly MS050242: unique symbol
  readonly MS050243: unique symbol
  readonly MS050244: unique symbol
  readonly MS050245: unique symbol
}

type withdrawalAmount = t.Branded<NonEmptyString, withdrawalAmountBrand>
export namespace CepULPForm {
  const ULP = t.type({
    withdrawalAmount: pipe(
      withMessage(form2.string.required, () =>
        i18next.t('message:MS020001', { field: i18next.t('requestInfo:WithdrawAmount') })
      ),
      form2.refine(
        (l): l is withdrawalAmount => Number(l) >= withdrawFormData.minimumPartialWithdrawal,
        () => 'MS050242',
        'MS050242'
      ),
      form2.refine(
        (l): l is withdrawalAmount => Number(l) <= withdrawFormData.maximumPartialWithdrawal,
        () => 'MS050243',
        'MS050243'
      ),
      form2.refine(
        (value): value is withdrawalAmount => Number(value) <= withdrawFormData.estimatedValue,
        () => 'MS050244',
        'MS050244'
      ),
      form2.refine(
        (value): value is withdrawalAmount => Number(value) !== withdrawFormData.estimatedValue,
        () => 'MS050245',
        'MS050245'
      )
    ),
    // newSumAssuredError: form2.string.optional,
    isValid: t.boolean,
    minSA: form2.number.optional,
    newSumAssured: form2.number.optional,
    withDrawFee: form2.number.optional
  })

  export const codec = ULP

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.OutputOf<typeof codec>
}

let withdrawFormData: CepULPForm.Raw & {
  minimumPartialWithdrawal: number
  maximumPartialWithdrawal: number
  estimatedValue: number
} = {
  withdrawalAmount: '',
  // newSumAssuredError: '',
  minSA: '',
  isValid: false,
  newSumAssured: '',
  withDrawFee: '',
  minimumPartialWithdrawal: 0,
  maximumPartialWithdrawal: 0,
  estimatedValue: 0
}

export const CepInvestmentScreen = (props: {
  id: string
  data: InvestmentInfoData | null
  setData: (val: InvestmentInfoData | null) => void
  productTypeInfo: ProductTypeC | null
  setProductTypeInfo: (val: IProductDetail) => void
}) => {
  const { t } = useTranslation()
  const [loading, bindLoading] = useLoading()
  const cep = useTranslator('CustomerEngagement').t
  const isFocused = useIsFocused()
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)
  const { navigate } = useNavigation()

  const navigation = useNavigation<StackNavigationProp<InquiryStackParamList, 'InquiryScreen'>>()
  const route = useRoute<RouteProp<InquiryStackParamList, 'CEPPolicyDetailScreen'>>()
  const [visibleInp, setVisibleInp] = React.useState<boolean>(false)

  const { base } = form2.useForm(CepULPForm.codec)
  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => navigate('HomeScreen')
        },
        {
          title: route?.params?.isSubmission === 'true' ? t('Submission:EForm') : t('menu:360Inquiry'),
          navigate: () =>
            route?.params?.isSubmission === 'true'
              ? navigation.replace('UserDrawer', {
                  screen: 'StaffSubmissionStack'
                })
              : navigation.navigate('CEPSearchScreen')
        },
        {
          title: t('Tab:InvestmentInfo'),
          navigate: null
        }
      ])
    }
  }, [isFocused])

  const getData = (): ZIO<unknown, Throwable, InvestmentInfoData | null> =>
    pipe(
      props.productTypeInfo !== null ? ZIO.succeed(props.productTypeInfo) : getProductType(props.id),
      ZIO.flatMap((pruduct) => {
        return pipe(
          ZIO.zipPar(
            ZIO.succeed(pruduct),
            getInvestmentInfo({ data: { function: '', policies: [`${props.id}`] } }),
            getInvestmentInfo({ data: { function: 'PARTIAL', policies: [`${props.id}`] } }),
            getILPTopupHistory(props.id),
            getULPTopupHistory(props.id),
            getFundAllocate(props.id),
            CepService.getPWData(props.id, pruduct.data.productType)
          )
        )
      }),
      ZIO.map(([productInfo, policyExt, partialWD, topUpILP, topULP, fundAllocate, pwInfo]) => {
        const investmentInfo: CepInvestmentInfo = {
          productType: '',
          fundAllocate: []
        }

        investmentInfo.productType = productInfo?.data.productType ?? ''
        props.setProductTypeInfo?.(productInfo)
        investmentInfo.fundAllocate = fundAllocate.data.map((item: { fundId: any; fundName: any; percentage: any }) => ({
          fundId: item?.fundId ?? '',
          fundName: item?.fundName ?? '',
          percentage: item?.percentage ?? ''
        }))

        investmentInfo.pwInfoULP = pwInfo.ulp

        investmentInfo.pwInfoILP = pwInfo.ilp
        console.log( topUpILP.data, ' topUpILP.data')
        if (productInfo?.data.productType === 'ILP') {
          (investmentInfo.ilp = {
            fundValue: policyExt.data.policyExtraInfoFunds.map((f: { fundCode: any; unitBalance: { toString: () => string }; estimateValue: number }) => ({
              fundName: f.fundCode,
              // unitBalance: PulseOpsFormat.thousandSepartor(f.unitBalance),
              unitBalance: f.unitBalance ? f.unitBalance.toString() : '0',
              // .replace(/(\d)(?=(?:[0-9]{3})+\b)/g, '$1,')
              estimatedValue: PulseOpsFormat.thousandSepartor(Number(Math.round(f.estimateValue).toFixed(0)))
            })),
            partialWithdrawalAmount: partialWD.data.policyExtraInfoFunds
              .filter((f: { estimateValue: number }) => f.estimateValue > 0)
              .map((f: { fundCode: any; estimateValue: number }) => ({
                fundName: f.fundCode,
                partialWithdrawal: PulseOpsFormat.thousandSepartor(Math.round(f.estimateValue))
              }))
          }),
            (investmentInfo.ILPTopUp = topUpILP.data
              ? topUpILP.data.map((item: { transactionDate: any; fundId: any; effectivePrice: any; fundAmount: any; realUnit: any; contractAmount: any; topUpPremium: any; sourcePercentAmount: any }) => ({
                  transactionDate: item?.transactionDate ?? '',
                  fundId: item?.fundId ?? '',
                  effectivePrice: item.effectivePrice ?? 0,
                  fundAmount: item?.fundAmount ?? 0,
                  realUnit: item?.realUnit ?? 0,
                  contractAmount: item?.contractAmount ?? 0,
                  topUpPremium: item?.topUpPremium ?? 0,
                  sourcePercentAmount: item?.sourcePercentAmount ?? 0
                }))
              : [])
        } else {
          investmentInfo.ulp = {
            targetPremium: PulseOpsFormat.thousandSepartor(
              policyExt.data.policyExtraInfoFunds
                .filter((x: { fundTypeName: FundType }) => x.fundTypeName !== FundType.TopUp)
                .reduce((acc: number, curr: { estimateValue: number }) => acc + curr.estimateValue, 0)
            ),
            topupPremium: PulseOpsFormat.thousandSepartor(
              policyExt.data.policyExtraInfoFunds
                .filter((x: { fundTypeName: FundType }) => x.fundTypeName !== FundType.TargetPremium)
                .reduce((acc: number, curr: { estimateValue: number }) => acc + curr.estimateValue, 0)
            ),
            ilpPartialWithdrawal: PulseOpsFormat.thousandSepartor(
              partialWD.data.policyExtraInfoFunds.reduce((acc: number, curr: { estimateValue: number }) => acc + curr.estimateValue, 0)
            )
          }
          const isCheckShowULP = ["VLR6","VLR7","VLR8"].includes(productInfo.data.basicCode)
          investmentInfo.ULPTopUp = isCheckShowULP ? topULP.data : []
        }
        return investmentInfo
      }),
      ZIO.map((investmentInfo) => {
        console.log(investmentInfo.ILPTopUp, 'investmentInfo.ILPTopUp')
        return {
          fundValue: investmentInfo.ilp ? investmentInfo.ilp.fundValue : [],
          pwAmount: investmentInfo.ilp ? investmentInfo.ilp.partialWithdrawalAmount : [],
          ulpInfo:
            !investmentInfo.ilp && investmentInfo.ulp
              ? [
                  {
                    label: 'TaskManagement:TargetPremiumEstimateValue',
                    value: investmentInfo.ulp.targetPremium,
                    suffix: 'VND'
                  },
                  {
                    label: 'TaskManagement:TopupPremiumEstimateValue',
                    value: investmentInfo.ulp.topupPremium,
                    suffix: 'VND'
                  },
                  {
                    label: 'TaskManagement:PartialWithdrawalAmount',
                    value: investmentInfo.ulp.ilpPartialWithdrawal,
                    suffix: 'VND'
                  }
                ]
              : [],
          productType: investmentInfo ? investmentInfo.productType : '',
          ILPTopUp: investmentInfo ? investmentInfo.ILPTopUp?.map((item: { topUpPremium: any; fundAmount: any; realUnit: any; effectivePrice: any; contractAmount: any; transactionDate?: string; fundId?: string; sourcePercentAmount?: number }) => ({
            ...item,
            topUpPremium: formatNumberWithComma(item.topUpPremium ?? 0),
            fundAmount: formatNumberWithComma(item.fundAmount ?? 0),
            realUnit: formatNumberWithComma(item.realUnit ?? 0),
            effectivePrice: formatNumberWithComma(item.effectivePrice ?? 0),
            contractAmount: formatNumberWithComma(item.contractAmount ?? 0)
          })) : [],
          ULPTopUp: investmentInfo ? investmentInfo.ULPTopUp?.map((item: { transDate: any; accountingAmount: any; fundAmount: any }) => ({
            topUpDate: item.transDate ?? '',
            premiumAmount: formatNumberWithComma(item.accountingAmount ?? 0) ?? '-',
            fundAmount: formatNumberWithComma(item.fundAmount ?? 0) ?? '-'
          })) : [],
          fundAllocate: investmentInfo.fundAllocate ?? [],
          pwInfoULP: investmentInfo.pwInfoULP,
          pwInfoILP: investmentInfo.pwInfoILP
        }
      })
    )

  const { fundValue, pwAmount, ulpInfo, productType, ILPTopUp, ULPTopUp, fundAllocate, pwInfoUPL, pwInfoIPL } = pipe(
    props.data != null ? ZIO.succeed(props.data) : getData(),
    bindLoading,
    ZIO.map((res) => {
      if (res !== null && props.data === null) props.setData(res)

      return {
        fundValue: res?.fundValue,
        pwAmount: res?.pwAmount,
        ulpInfo: res?.ulpInfo,
        productType: res?.productType,
        ILPTopUp: res?.ILPTopUp,
        ULPTopUp: res?.ULPTopUp,
        fundAllocate: res?.fundAllocate,
        pwInfoUPL: res?.pwInfoULP,
        pwInfoIPL: res?.pwInfoILP
      }
    }),
    ErrorHandling.runDidMount()
  ) || {
    fundValue: [],
    pwAmount: [],
    ulpInfo: [],
    productType: '',
    ILPTopUp: [],
    ULPTopUp: [],
    fundAllocate: [],
    pwInfoULP: {
      estimatedValue: 0,
      minimumPartialWithdrawal: 0,
      maximumPartialWithdrawal: 0,
      withDrawFee: 0,
      newSumAssured: 0,
      sumAssured: 0,
      fundList: []
    },
    pwInfoILP: {
      estimatedValue: 0,
      minimumPartialWithdrawal: 0,
      maximumPartialWithdrawal: 0,
      withDrawFee: 0,
      newSumAssured: 0,
      sumAssured: 0,
      fundList: []
    }
  }
  console.log(ILPTopUp, 'ILPTopUp')
  const { premiumRedirectionHistory } = pipe(
    getCoreTransaction({
      start: 0,
      size: 100,
      data: {
        policyNo: props.id,
        codes: codesPremium
      }
    }),
    ZIO.map((res) => {
      const premiumData = res.data.sort(function (a, b) {
        const timeB = b?.tranDate?.split('/').reverse().join('/') ?? ''
        const timeA = a?.tranDate?.split('/').reverse().join('/') ?? ''

        const newB = new Date(timeB)

        const newA = new Date(timeA)

        return newB.valueOf() - newA.valueOf()
      })

      return {
        premiumRedirectionHistory: premiumData.map((item) => ({
          tranDate: item.tranDate,
          code: item.code,
          description: item.code === 'T551' ? CepFormConst.DescriptionCoreTH.T551 : '-',
          oldbillfrenquency: FrequencyMapping.get(t)(item.oldBillingFrequency as Frequency) ?? '-',
          newbillfrenquency: FrequencyMapping.get(t)(item.newBillingFrequency as Frequency) ?? '-'
        }))
      }
    }),
    ErrorHandling.runDidMount()
  ) || {
    premiumRedirectionHistory: []
  }

  React.useEffect(() => {
    if (isEmpty(props.productTypeInfo)) return
    checkRulePwAmount()
  }, [props.productTypeInfo])

  withdrawFormData = Object.assign(withdrawFormData, {
    ...base.watch(),
    minimumPartialWithdrawal: pwInfoUPL?.minimumPartialWithdrawal,
    maximumPartialWithdrawal: pwInfoUPL?.maximumPartialWithdrawal,
    estimatedValue: pwInfoUPL?.estimatedValue
  })

  const validateWithdrawalAmount = (value: number, isUpdatedWithdrawAmount = false): boolean => {
    const A = pwInfoUPL?.minimumPartialWithdrawal || 0
    const B = pwInfoUPL?.maximumPartialWithdrawal || 0
    const C = pwInfoUPL?.estimatedValue || 0
    const P = value
    let flag = true

    if (P) {
      if (P < A) {
        flag = false
      } else if (P > B) {
        flag = false
      } else if (P > C) {
        flag = true
      } else if (P === C) {
        Alert.alert(t('message:MS050246'))
        flag = false
      } else {
        flag = true
        base.clearErrors('withdrawalAmount')
      }
    }

    return flag
  }

  const checkRulePwAmount = () => {
    let flag = false
    if (props.productTypeInfo?.data.productType === 'ULP' || props.productTypeInfo?.data.productType === 'TRAD') {
      if (
        !props.productTypeInfo.data.productCode.startsWith('V') &&
        !props.productTypeInfo.data.productCode.startsWith('U')
      ) {
        flag = true
        base.setError('withdrawalAmount', {
          message: 'MS050168'
        })
      } else if (props.productTypeInfo.data.productCode === 'IPD1') {
        flag = true
        base.setError('withdrawalAmount', {
          message: 'MS050280'
        })
      } else if (props.productTypeInfo.data.status !== 'IF') {
        flag = true
        base.setError('withdrawalAmount', {
          message: 'MS050034'
        })
      }
    }
    setVisibleInp(flag)
    return flag
  }

  const calcWithDrawalAmount = (
    amount: number,
    fundList: Array<{
      isEPA: boolean
      actualValue: string
      estimatedValue: string
      fundCode: string
    }>
  ) => {
    pipe(
      ZIO.effect(() => {
        let topupFundAmount = 0,
          targetFundAmount = 0

        let fundEPA = '',
          fundTPA = ''

        if (fundList.length > 0) {
          fundEPA = fundList.find((x: PartialWithdrawalData.fundULPList) => x.isEPA)?.fundCode ?? ''
          fundTPA = fundList.find((x: PartialWithdrawalData.fundULPList) => !x.isEPA)?.fundCode ?? ''
          topupFundAmount = Number(
            fundList.find((x: PartialWithdrawalData.fundULPList) => x.isEPA)?.estimatedValue ?? 0
          )
          targetFundAmount = Number(
            fundList.find((x: PartialWithdrawalData.fundULPList) => !x.isEPA)?.estimatedValue ?? 0
          )
        }
        return {
          fundEPA,
          fundTPA,
          topupFundAmount,
          targetFundAmount
        }
      }),
      ZIO.flatMap((res) => {
        let detailsPayload: Array<{ coverage: string; fundCode: string; amount: number }> = []

        if (amount > res.topupFundAmount) {
          !!res.fundEPA && detailsPayload.push({ coverage: '01', fundCode: res.fundEPA, amount: res.topupFundAmount })
          !!res.fundTPA &&
            detailsPayload.push({ coverage: '01', fundCode: res.fundTPA, amount: amount - res.topupFundAmount })
        } else {
          detailsPayload = [
            {
              coverage: '01',
              fundCode: res.fundEPA,
              amount: amount
            }
          ]
        }

        return calcPWAmount(props.id, {
          data: {
            details: detailsPayload
          }
        })
      }),
      ZIO.map((res) => {
        base.setValue('newSumAssured', String(res.data.newSumAssured))
        base.setValue('withDrawFee', String(res.data.withDrawFee))
      }),
      ZIO.unsafeRun({})
    )
  }

  const debounceWithFrawalAmuntFn = React.useCallback(_.debounce(calcWithDrawalAmount, 500), [])

  const fundValueColumns: Columns[] = [
    {
      key: 'fn',
      title: t('TaskManagement:FundName'),
      name: 'fundName'
    },
    {
      key: 'ub',
      title: t('TaskManagement:UnitBalance'),
      name: 'unitBalance'
    },
    {
      key: 'efv',
      title: t('TaskManagement:EstimateFundValue'),
      name: 'estimatedValue'
    }
  ]
  const pwColumns: Columns[] = [
    {
      key: 'fn',
      title: t('TaskManagement:FundName'),
      name: 'fundName',
      flex: 1
    },
    {
      key: 'pwA',
      title: t('TaskManagement:PartialWithdrawalAmount'),
      name: 'partialWithdrawal',
      flex: 2
      // styles: { textAlign: 'right'}
    }
  ]

  const propsPartialWithDraw: PropsPartial = {
    policyNumber: props.id
  }
  const topUpHistory: Columns[] = [
    {
      key: 1,
      title: cep('TopUpTrandate'),
      name: 'transactionDate',
      flex: 1
    },
    {
      key: 2,
      title: cep('TopUpFund'),
      name: 'fundId',
      flex: 2
    },
    {
      key: 3,
      title: cep('AmountPercent'),
      name: 'sourcePercentAmount',
      flex: 2
    },
    {
      key: 4,
      title: cep('TopUpPremium'),
      name: 'topUpPremium',
      flex: 2
    },
    {
      key: 5,
      title: cep('TopUpInvestment'),
      name: 'fundAmount',
      flex: 2
    },
    {
      key: 6,
      title: cep('RealUnits'),
      name: 'realUnit',
      flex: 2
    },
    {
      key: 7,
      title: cep('EffectivePrice'),
      name: 'effectivePrice',
      flex: 2
    },
    {
      key: 8,
      title: cep('NonInvest'),
      name: 'contractAmount',
      flex: 2
    }
  ]

  const topUpULPHistory: Columns[] = [
    {
      key: 1,
      title: cep('TopupDate'),
      name: 'topUpDate',
      flex: 1
    },
    {
      key: 2,
      title: cep('TopupPremium'),
      name: 'premiumAmount',
      flex: 2
    },
    {
      key: 3,
      title: cep('TopupInvestment'),
      name: 'fundAmount',
      flex: 2
    }
  ]

  const premiumRedictionHistoryColumn: Columns[] = [
    {
      key: 1,
      title: cep('Trandate'),
      name: 'tranDate',
      flex: 1
    },
    {
      key: 2,
      title: cep('Code'),
      name: 'code',
      flex: 2
    },
    {
      key: 3,
      title: cep('Description'),
      name: 'description',
      flex: 2
    }
  ]

  const currentFundAllocationColumn: Columns[] = [
    {
      key: 1,
      title: cep('FundCode'),
      name: 'fundId',
      flex: 1
    },
    {
      key: 2,
      title: cep('FundName'),
      name: 'fundName',
      flex: 2
    },
    {
      key: 3,
      title: cep('Percentage'),
      name: 'percentage',
      flex: 2
    }
  ]


  const getMessageByCode = (code: string | undefined) => {
    let message = ''
    const A = pwInfoUPL?.minimumPartialWithdrawal || 0
    const B = pwInfoUPL?.maximumPartialWithdrawal || 0
    switch (code) {
      case 'MS050168':
        message = t('message:MS050168')
        break
      case 'MS050034':
        message = t('message:MS050034')
        break
      case 'MS050280':
        message = t('message:MS050034')
        break
      case 'MS050242':
        message = t('message:MS050242', { Min: `${formatNumberWithComma(A)} VND` })
        break
      case 'MS050243':
        message = t('message:MS050243', { Max: `${formatNumberWithComma(B)} VND` })
        break
      case 'MS050244':
        message = t('message:MS050244')
        break
      case 'MS050245':
        message = t('message:MS050245')
        break
      default:
        message = (!visibleInp ?  code : '') ?? ''
    }
    return message
  }
  return (
    <Container loading={loading}>
      {/* {fundValue.length > 0 && ( */}
      {!!productType && (productType === 'ULP' || productType === 'TRAD') && (
        <>
          <Panel title={t('TaskManagement:AccountValue')}>
            <FieldList
              dataSource={
                ulpInfo
                  ? ulpInfo.map(
                      (item: any) =>
                        ({
                          ...item,
                          label: typeof item.label === 'string' ? t(item.label) : item.label
                        } as FieldList.FieldType)
                    )
                  : []
              }
            />
          </Panel>
          <Panel title={t('requestInfo:PARTIAL_WITHDRAWAL_INFO')}>
            <FieldList
              dataSource={[
                {
                  label: t('requestInfo:EstimatedValue'),
                  value: formatNumberWithComma(pwInfoUPL?.estimatedValue ?? 0),
                  suffix: 'VND'
                },
                {
                  label: t('requestInfo:Minimumpartialwithdrawal'),
                  value: formatNumberWithComma(pwInfoUPL?.minimumPartialWithdrawal ?? 0),
                  suffix: 'VND'
                },
                {
                  label: t('requestInfo:Maximumpartialwithdrawal'),
                  value: formatNumberWithComma(pwInfoUPL?.maximumPartialWithdrawal ?? 0),
                  suffix: 'VND'
                },
                {
                  label: t('requestInfo:CurrentSumAssured'),
                  value: formatNumberWithComma(pwInfoUPL?.sumAssured ?? 0),
                  suffix: 'VND'
                },
                {
                  label: t('requestInfo:NewSumAssured'),
                  // value: formatNumberWithComma(detail?.ulp?.newSumAssured || ''),
                  value: formatNumberWithComma(base.watch('newSumAssured')),
                  suffix: 'VND'
                },
                {
                  label: cep('WithdrawFee'),
                  value: formatNumberWithComma(base.watch('withDrawFee')),
                  suffix: 'VND'
                },
                {
                  label: t('requestInfo:WithdrawAmount'),
                  suffix: 'VND',
                  required: true,
                  // visible: !visibleInp,
                  render: () => {
                    return (
                      <Controller
                        control={base.control}
                        name="withdrawalAmount"
                        render={({ field, formState: { errors } }) => (
                          <Input
                            value={field.value || ''}
                            inputType="money"
                            disabled={visibleInp ? true : false}
                            containerStyle={{ marginEnd: 30 }}
                            inputStyle={{ color: '#ED1B2E', fontSize: 18, fontWeight: 'bold' }}
                            errorMessage={getMessageByCode(_.get(errors, 'withdrawalAmount')?.message)}
                            onBlur={field.onBlur}
                            onChange={(val) => {
                              const withdrawVal = Number(val || 0)
                              const fundData = pwInfoUPL?.fundList
                                ? pwInfoUPL?.fundList?.map((item) => ({
                                    actualValue: item.actualValue.toString() ?? '',
                                    estimatedValue: item.estimatedValue.toString() ?? '',
                                    fundCode: item.fundCode ?? '',
                                    isEPA: !!item.isEPA
                                  }))
                                : []

                              field.onChange(val)
                              if (validateWithdrawalAmount(withdrawVal)) {
                                debounceWithFrawalAmuntFn(withdrawVal, fundData)
                                base.clearErrors('withdrawalAmount')
                              }
                            }}
                            maxLength={12}
                          />
                        )}
                      />
                    )
                  }
                }
              ]}
            />
          </Panel>
          <Panel title={cep('TopUpHistory')} containerStyle={{ backgroundColor: '#FAFAFA' }}>
            <CepTableField columns={topUpULPHistory} dataSource={ULPTopUp ?? []} />
          </Panel>
        </>
      )}

      {!!productType && productType === 'ILP' && (
        <>
          <Panel title={t('TaskManagement:FundValue')}>
            <CepTableField
              columns={fundValueColumns}
              dataSource={
                fundValue
                  ? fundValue.map((item: any) => ({ ...item, fundName: t(FundMapping.getFunds(item.fundName)) }))
                  : []
              }
            />
          </Panel>
          <Panel title={t('TaskManagement:PartialWithdrawalAmount')}>
            <CepTableField
              columns={pwColumns}
              dataSource={
                pwAmount
                  ? pwAmount.map((item: any) => ({ ...item, fundName: t(FundMapping.getFunds(item.fundName)) }))
                  : []
              }
            />
          </Panel>
          <CepILPPartialWithdraw
            detail={pwInfoIPL}
            {...propsPartialWithDraw}
            basicCode={props.productTypeInfo?.data.basicCode ?? ''}
          />

          <Panel title={cep('TopUpHistory')} containerStyle={{ backgroundColor: '#FFFFFF' }}>
            <CepTableField columns={topUpHistory} dataSource={ILPTopUp ?? []} />
          </Panel>
          <Panel title={cep('PremiumRedictionHistory')}>
            <CepTableField columns={premiumRedictionHistoryColumn} dataSource={premiumRedirectionHistory} />
          </Panel>
          <Panel title={cep('CurrentFundAllocation')} containerStyle={{ backgroundColor: '#FAFAFA' }}>
            <CepTableField columns={currentFundAllocationColumn} dataSource={fundAllocate ?? []} />
          </Panel>
        </>
      )}
    </Container>
  )
}
const SUREStyle = StyleSheet.create({
  pageContent: {
    backgroundColor: '#fff',
    height: '100%',
    width: '100%'
  },
  container: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    paddingLeft: 15,
    paddingRight: 15
  },
  sectionContent: {
    backgroundColor: '#F4F4F4',
    border: '1px solid #d3dce6',
    boxSizing: 'border-box',
    borderRadius: 8
  },
  sectionRow: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row'
  },
  secondLine: {
    marginTop: 20
  },
  col_4: {
    width: '100%',
    maxWidth: '33%',
    paddingRight: 15,
    paddingLeft: 15
  },
  primaryBtn: {
    width: 118,
    height: 40,
    backgroundColor: '#ed1b2e',
    borderRadius: 100,
    padding: 10,
    boxShadow: 'none'
  },
  cellWidth200: {
    minWidth: '200px'
  },
  sectionHeading: {
    padding: 20,
    // fontFamily: 'Noto Sans',
    fontFamily: 'NotoSans_700Bold',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 16
  },
  sectionContentForm: {
    backgroundColor: '#FFFFFF',
    width: '100%',
    padding: 20,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8
  },
  tabHeading: {
    flex: 1,
    flexDirection: 'row'
  },
  tabHeadingBox: {
    paddingVertical: 15,
    paddingHorizontal: 40,
    color: '#000000',
    fontFamily: 'NotoSans_700Bold',
    backgroundColor: '#FFFFFF',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    marginLeft: 10,
    marginTop: 10
  },
  sectionTitle: {
    padding: 5,
    fontFamily: 'NotoSans_700Bold',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 13,
    color: '#70777E'
  },
  btn: {
    width: 118,
    height: 40,
    backgroundColor: '#ed1b2e',
    borderRadius: 100,
    padding: 10,
    boxShadow: 'none'
  }
})
