import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import {
  AppContext,
  // AppContext,
  DatePicker,
  ErrorHandling,
  formatNumberWithComma,
  Input,
  InquiryComplaintService,
  Panel,
  RadioButtonGroup,
  Select,
  SelectOption,
  useMobile
} from '@pulseops/common'
import { useIsFocused } from '@react-navigation/native'
import { pipe } from 'fp-ts/lib/function'
// import { unknown } from 'io-ts'
import { VeriInquiryComplaintData, VeriPayloadData } from 'libs/common/src/service/model/task-detail/InquiryComplaint'
import moment from 'moment'
import React, { useEffect } from 'react'
import { Controller, useFieldArray, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View } from 'react-native'
import { Title } from '../../common'
import { InquiryComplaintForm } from './InquiryComplaintForm'
interface Props {
  detail: VeriInquiryComplaintData
  form: UseFormReturn<InquiryComplaintForm>
  validateReadOnly: () => boolean
  updatedPayloadData: VeriPayloadData
}

export type MoneyMisappropriationRef = {
  resetMoneyMisappropriationBlock: () => void
}

export const _MoneyMisappropriation = (
  { detail, form, validateReadOnly, updatedPayloadData }: Props,
  ref: React.Ref<MoneyMisappropriationRef>
) => {
  const { t, i18n } = useTranslation(['requestInfo', 'roles'])
  const { isMobile } = useMobile()
  const isFocused = useIsFocused()
  const { showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const [isLoading, bindLoader] = useLoading(false)
  const { control, setValue, watch } = form
  const { fields, append, remove } = useFieldArray<InquiryComplaintForm>({
    control,
    name: 'moneyMissappropriation.list'
  })

  // const { submissionData } = detail
  const submissionData = updatedPayloadData.body.attributesExt
  const [isReceipt, setIsReceipt] = React.useState<boolean>(false)
  const [isReceiptType, setIsReceiptType] = React.useState<boolean>(false)
  const isDisabled = validateReadOnly()

  React.useImperativeHandle(
    ref,
    () => ({
      resetMoneyMisappropriationBlock: () => {
        getInitialMoneyMisData()
      }
    }),
    []
  )

  const receiptTypeList =
    pipe(
      // InquiryComplaintService.getT22Data('receipt-type'),
      ZIO.effect(() => {
        return detail.complaintedDataList.receiptTypeList.map((item) => ({
          label: i18n.language === 'en' ? item.nameEn : item.name,
          value: item.code
        }))
      }),
      bindLoader,
      ErrorHandling.runDidMount()
    ) ?? []

  const evidenceStatusList =
    pipe(
      ZIO.effect(() => {
        return detail.complaintedDataList.evidenceStatusArr.map((item) => ({
          label: i18n.language === 'en' ? item.nameEn : item.name,
          value: item.code
        }))
      }),
      ErrorHandling.runDidUpdate([i18n.language])
    ) ?? []

  // pipe(
  //   InquiryComplaintService.getT22Data('evidence-status'),
  //   ZIO.map((evidenceList) => {
  //     return evidenceList.map((item) => ({
  //       label: i18n.language === 'en' ? item.nameEn : item.name,
  //       value: item.code
  //     }))
  //   }),
  //   ErrorHandling.runDidUpdate([i18n.language])
  // ) ?? []

  useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  useEffect(() => {
    // const complaintMoneyDetail = submissionData.COMPLAINT_MIS_MONEY_DETAILS
    // if (
    //   complaintMoneyDetail &&
    //   complaintMoneyDetail.length > 0 &&
    //   receiptTypeList.length > 0 &&
    //   evidenceStatusList.length > 0 &&
    //   fields.length <= 0
    // ) {
    //   console.log('Complaint Money Detail data')
    //   let totalComplaintAmount = 0
    //   for (let i = 0; i < complaintMoneyDetail.length; i++) {
    //     const item = complaintMoneyDetail[i]
    //     totalComplaintAmount += item.complaintAmount
    //     const receiptType =
    //       receiptTypeList.find((receiptTypeItem) => receiptTypeItem.value === item.recieptType) ?? null
    //     const evidenceStatus =
    //       evidenceStatusList.find((evidenceItem) => evidenceItem.value === item.evidenceStatus) ?? null
    //     const verifiedAmount = item.verifiedAmount ? String(item.verifiedAmount) : ''
    //     const complaintMoneyItem = {
    //       receipt: item.hasReciept,
    //       receiptType: receiptType,
    //       receiptNumber: item.recieptNum,
    //       complaintAmount: item.complaintAmount.toString(),
    //       benefit_PremiumMisappropriatedDate: moment(item.misPremiumDate),
    //       evidenceStatus: evidenceStatus,
    //       verifiedAmount: verifiedAmount
    //     }
    //     // setValue(`moneyMissappropriation.list.${i}`, complaintMoneyItem)
    //     append(complaintMoneyItem)
    //   }
    //   setValue(`moneyMissappropriation.totalComplaintAmount`, String(totalComplaintAmount))
    //   submissionData.TOTAL_VERIFIED_AMOUNT &&
    //     setValue('moneyMissappropriation.totalVerifiedAmount', submissionData.TOTAL_VERIFIED_AMOUNT.toString())
    // } else if (
    //   complaintMoneyDetail &&
    //   complaintMoneyDetail.length <= 0 &&
    //   watch('moneyMissappropriation.list').length <= 0
    // ) {
    //   append({
    //     receipt: '',
    //     receiptType: { label: '', value: '' },
    //     receiptNumber: '',
    //     complaintAmount: '',
    //     benefit_PremiumMisappropriatedDate: null,
    //     evidenceStatus: { label: '', value: '' },
    //     verifiedAmount: ''
    //   })
    // }
    getInitialMoneyMisData()
  }, [submissionData, receiptTypeList, evidenceStatusList])

  React.useEffect(() => {
    return () => {
      // console.log('remove moneyMissappropriation list1')
      remove()
    }
  }, [isFocused])

  const getInitialMoneyMisData = () => {
    const complaintMoneyDetail = submissionData.COMPLAINT_MIS_MONEY_DETAILS
    if (
      complaintMoneyDetail &&
      complaintMoneyDetail.length > 0 &&
      receiptTypeList.length > 0 &&
      evidenceStatusList.length > 0 &&
      fields.length <= 0
    ) {
      console.log('Complaint Money Detail data')
      let totalComplaintAmount = 0
      for (let i = 0; i < complaintMoneyDetail.length; i++) {
        const item = complaintMoneyDetail[i]
        totalComplaintAmount += item.complaintAmount
        const receiptType =
          receiptTypeList.find((receiptTypeItem) => receiptTypeItem.value === item.recieptType) ?? null
        const evidenceStatus =
          evidenceStatusList.find((evidenceItem) => evidenceItem.value === item.evidenceStatus) ?? null
        const verifiedAmount = item.verifiedAmount ? String(item.verifiedAmount) : ''
        const complaintMoneyItem = {
          receipt: item.hasReciept,
          receiptType: receiptType,
          receiptNumber: item.recieptNum,
          complaintAmount: item.complaintAmount.toString(),
          benefit_PremiumMisappropriatedDate: moment(item.misPremiumDate),
          evidenceStatus: evidenceStatus,
          verifiedAmount: verifiedAmount
        }
        // setValue(`moneyMissappropriation.list.${i}`, complaintMoneyItem)
        append(complaintMoneyItem)
      }
      setValue(`moneyMissappropriation.totalComplaintAmount`, String(totalComplaintAmount))
      submissionData.TOTAL_VERIFIED_AMOUNT &&
        setValue('moneyMissappropriation.totalVerifiedAmount', submissionData.TOTAL_VERIFIED_AMOUNT.toString())
    } else if (
      complaintMoneyDetail &&
      complaintMoneyDetail.length <= 0 &&
      watch('moneyMissappropriation.list').length <= 0
    ) {
      append({
        receipt: '',
        receiptType: { label: '', value: '' },
        receiptNumber: '',
        complaintAmount: '',
        benefit_PremiumMisappropriatedDate: null,
        evidenceStatus: { label: '', value: '' },
        verifiedAmount: ''
      })
      setValue(`moneyMissappropriation.totalComplaintAmount`, '0')
      setValue('moneyMissappropriation.totalVerifiedAmount', '0')
    }
  }

  const showOptionLanguage = () => {
    return i18n.language === 'en' ? 'Select' : 'Chọn'
  }

  const onReceiptChangeEvent = (value: string) => {
    if (value === 'yes') {
      setIsReceipt(true)
    } else {
      setIsReceipt(false)
    }
  }

  const onReceiptTypeChangeEvent = (value: SelectOption | null) => {
    if (!['RC05', 'RC06'].includes(value?.value ?? '')) {
      setIsReceiptType(true)
    } else {
      setIsReceiptType(false)
    }
  }

  const onVerifiedAmountChangeEvent = (value: string, itemIndex: number) => {
    let totalVeriAmount = 0
    const semiTotalAmount = watch('moneyMissappropriation.list').reduce(function (total, item, index) {
      return itemIndex !== index ? total + Number(item.verifiedAmount) : total
    }, 0)
    totalVeriAmount = Number(value) + semiTotalAmount
    setValue('moneyMissappropriation.totalVerifiedAmount', String(totalVeriAmount))
  }

  const onComplaintAmountChangeEvent = (value: string, itemIndex: number) => {
    let totalComplaintAmount = 0
    const semiTotalComplaintAmount = watch('moneyMissappropriation.list').reduce(function (total, item, index) {
      return itemIndex !== index ? total + Number(item.complaintAmount) : total
    }, 0)
    totalComplaintAmount = Number(value) + semiTotalComplaintAmount
    setValue('moneyMissappropriation.totalComplaintAmount', String(totalComplaintAmount))
  }

  return (
    <View>
      <Title title={t('ComplaintedMoneyMisappropriationDetails')} />
      {fields.map((field, index) => (
        <Panel title="" key={field.id}>
          <View style={isMobile ? styles.rowMobile : styles.row}>
            <View style={isMobile ? styles.colFull : styles.col}>
              <View style={styles.row}>
                <Controller
                  name={`moneyMissappropriation.list.${index}.receipt` as 'moneyMissappropriation.list.0.receipt'}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: t('message:MS020009', { field: t('Receipt') })
                    }
                  }}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                    return (
                      <View>
                        <RadioButtonGroup
                          title={t('Receipt')}
                          colLength={'100%'}
                          required
                          disabled={isDisabled}
                          value={value}
                          options={[
                            { id: 'yes', label: t('common:Yes') },
                            { id: 'no', label: t('common:No') }
                          ]}
                          onChange={(val) => {
                            onChange(val)
                            onReceiptChangeEvent(val)
                          }}
                          onBlur={onBlur}
                          errorMessage={error?.message}
                        />
                      </View>
                    )
                  }}
                />
              </View>
            </View>
            <View style={isMobile ? styles.colFull : styles.col}>
              <Controller
                name={`moneyMissappropriation.list.${index}.receiptType` as 'moneyMissappropriation.list.0.receiptType'}
                control={control}
                rules={{
                  required: {
                    value: isReceipt,
                    message: t('message:MS020009', { field: t('ReceiptType') })
                  },
                  validate: (val) => {
                    return isReceipt && val && !val?.value
                      ? `${t('message:MS020009', { field: t('ReceiptType') })}`
                      : true
                  }
                }}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <Select
                    label={t('ReceiptType')}
                    disabled={isDisabled}
                    required={isReceipt}
                    onChange={(val) => {
                      onChange(val)
                      onReceiptTypeChangeEvent(val)
                    }}
                    onBlur={onBlur}
                    value={value}
                    errorMessage={isReceipt ? error?.message : ''}
                    placeholder={showOptionLanguage()}
                    options={receiptTypeList}
                  />
                )}
              />
            </View>
            <View style={isMobile ? styles.colFull : styles.col}>
              <Controller
                name={
                  `moneyMissappropriation.list.${index}.receiptNumber` as 'moneyMissappropriation.list.0.receiptNumber'
                }
                control={control}
                rules={{
                  required: {
                    value: isReceiptType,
                    message: t('message:MS020001', { field: t('ReceiptNumber') })
                  }
                }}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <Input
                    title={t('ReceiptNumber')}
                    disabled={isDisabled}
                    required={isReceiptType}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    errorMessage={isReceiptType ? error?.message : ''}
                  />
                )}
              />
            </View>
          </View>
          <View style={isMobile ? styles.rowMobile : styles.row}>
            <View style={isMobile ? styles.colFull : styles.col}>
              <Controller
                name={
                  `moneyMissappropriation.list.${index}.complaintAmount` as 'moneyMissappropriation.list.0.complaintAmount'
                }
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t('message:MS020001', { field: t('ComplaintAmount') })
                  }
                }}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <Input
                    title={t('ComplaintAmount')}
                    disabled={isDisabled}
                    required
                    onChange={(val) => {
                      const complaintAmountVal = val.replace(/[^0-9]/g, '')
                      onChange(complaintAmountVal)
                      onComplaintAmountChangeEvent(complaintAmountVal, index)
                    }}
                    onBlur={onBlur}
                    value={formatNumberWithComma(value)}
                    errorMessage={error?.message}
                    maxLength={12}
                    isAllowComma={true}
                    inputType="number"
                    suffix={'VND'}
                  />
                )}
              />
            </View>
            <View style={isMobile ? styles.colFull : styles.col}>
              <Controller
                name={
                  `moneyMissappropriation.list.${index}.benefit_PremiumMisappropriatedDate` as 'moneyMissappropriation.list.0.benefit_PremiumMisappropriatedDate'
                }
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t('message:MS020009', { field: t('Benefit/PremiumMisappropriatedDate') })
                  }
                }}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <DatePicker
                    label={t('Benefit/PremiumMisappropriatedDate')}
                    required
                    disabled={isDisabled}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    errorMessage={error?.message}
                  />
                )}
              />
            </View>
          </View>
          <View style={isMobile ? styles.rowMobile : styles.row}>
            <View style={isMobile ? styles.colFull : styles.col}>
              <Controller
                control={control}
                name={`moneyMissappropriation.list.${index}.evidenceStatus`}
                rules={{
                  required: {
                    value: true,
                    message: t('message:MS020009', { field: t('EvidenceStatus') })
                  },
                  validate: (val) => {
                    return val && !val?.value ? `${t('message:MS020009', { field: t('EvidenceStatus') })}` : true
                  }
                }}
                render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                  <Select
                    label={t('EvidenceStatus')}
                    disabled={isDisabled}
                    options={evidenceStatusList}
                    // popupIcon={<assets.ArrowDownDropdownIcon />}
                    placeholder={showOptionLanguage()}
                    onChange={(val) => {
                      onChange(val)
                    }}
                    onBlur={onBlur}
                    value={value}
                    required
                    // key={value?.value}
                    errorMessage={isDisabled ? '' : error?.message}
                  />
                )}
              />
            </View>
            <View style={isMobile ? styles.colFull : styles.col}>
              <Controller
                control={control}
                name={`moneyMissappropriation.list.${index}.verifiedAmount`}
                rules={{
                  required: {
                    value: true,
                    message: t('message:MS020001', { field: t('VerifiedAmount') })
                  }
                }}
                render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                  <Input
                    title={t('VerifiedAmount')}
                    value={formatNumberWithComma(value)}
                    onChange={(val: string) => {
                      const verifiedVal = val.replace(/[^0-9]/g, '')
                      onChange(verifiedVal)
                      onVerifiedAmountChangeEvent(verifiedVal, index)
                    }}
                    onBlur={onBlur}
                    required={true}
                    disabled={isDisabled}
                    isAllowComma={true}
                    maxLength={12}
                    suffix={'VND'}
                    inputType={'number'}
                    errorMessage={isDisabled ? '' : error?.message}
                  />
                )}
              />
            </View>
          </View>
        </Panel>
      ))}
      <View style={isMobile ? styles.rowMobile : styles.row}>
        <View style={isMobile ? styles.colFull : styles.col}>
          <Controller
            name="moneyMissappropriation.totalComplaintAmount"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <Input
                title={t('TotalComplaintAmount')}
                disabled
                onChange={onChange}
                onBlur={onBlur}
                value={formatNumberWithComma(value) + ' VND'}
                errorMessage={error?.message}
              />
            )}
          />
        </View>
        <View style={isMobile ? styles.colFull : styles.col}>
          <Controller
            name="moneyMissappropriation.totalVerifiedAmount"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <Input
                title={t('TotalVerifiedAmount')}
                disabled
                onChange={onChange}
                onBlur={onBlur}
                value={formatNumberWithComma(value) + ' VND'}
                errorMessage={error?.message}
              />
            )}
          />
        </View>
      </View>
    </View>
  )
}

export const MoneyMisappropriation = React.forwardRef(_MoneyMisappropriation)

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row'
  },
  rowMobile: {
    flexDirection: 'column'
  },
  col: {
    width: '33.33333333333%',
    marginBottom: 32,
    paddingHorizontal: 16
  },
  colFull: {
    width: '100%',
    paddingHorizontal: 16,
    marginBottom: 32
  },
  label: {
    fontSize: 15,
    color: '#70777e',
    fontWeight: 'bold'
  },
  required: {
    color: '#ed1b2e'
  },
  mR5: {
    marginRight: 5
  },
  mB5: {
    marginBottom: 15
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center'
  }
})
