import { Task, Throwable, ZIO } from '@mxt/zio'
import { Maybe, Nullable } from '@mxt/zio/codec'
import { AuthService, GeneralService, POApi, PulseOpsApi, PulseOpsFormat, PulseOpsService, SubTaskType, TaskCount, TaskService, TaskType, TransferData } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'
import * as O from 'fp-ts/lib/Option'
import { OBBasketType, OBCallType } from './ob-model'
import { DateFromISOString } from 'io-ts-types'
import { Subject } from 'rxjs'

export interface OBRelatedDocRequestForm {
  url: string
  docType: string
  docId: string
  mainDoc: string
  subDoc: string
  batchNum: string
  source: string
}

export type ErrorActivityInputData = {
  size: number,
  start: number,
  sourceIn?: string,
  transactionTypeIn?: string[],
  policyNumber?: string,
  createdAfter?: string,
  createdBefore?: string,
  taskCategory?: string,
  offices?: string,
  activities?: string,
  category: string
}

export const OBTaskDetail = t.type({
  businessKey: t.string,
  taskId: Maybe(t.string),
  name: Maybe(t.string),
  assignee: Maybe(t.string),
  createdDate: Maybe(t.string),
  activityBasket: Maybe(t.string),
  transactionType: Maybe(t.string),
  policyNumber: t.string,
  poClientNumber: Maybe(t.string),
  callProgramDesc: Maybe(t.string),
  poName: Maybe(t.string),
  vip: Maybe(t.string),
  outboundAgency: Maybe(t.string),
  isDuplicatePo: Maybe(t.boolean),
  outBoundCallBackAppointmentDate: Maybe(t.string),
  outBoundExecutionTime: Maybe(t.number),
  outBoundLeaderOfStaff: Maybe(t.string),
  processInstanceId: Maybe(t.string),
  payload: Maybe(t.type({
    saleChannel: Maybe(t.string),
    mobilePhoneNumber: Maybe(t.union([t.string, t.number])),
    payloadJSONString: Maybe(t.string)
  })),
  idNumber: Maybe(t.string),
  // mobilePhoneNumber: Maybe(t.string),
  // homePhoneNumber: Maybe(t.string),
  // companyPhoneNumber: Maybe(t.string)
  isQCCheck: Maybe(t.boolean),
  isQCCount: Maybe(t.boolean),
  isQCSampleCase: Maybe(t.boolean),
  isQCVolumeCase: Maybe(t.boolean),
  isReQCCheck: Maybe(t.boolean),
  isReQCOverdue: Maybe(t.boolean)
})

export const OBTaskData = t.type({
  businessKey: Maybe(t.string),
  policyNumber: Maybe(t.string),
  transactionCode: Maybe(t.string),
  transactionType: Maybe(t.string),
  poClientNumber: Maybe(t.string),
  poName: Maybe(t.string),
  vip: Maybe(t.string),
  outboundAgency: Maybe(t.string),
  outBoundCallBackAppointmentDate: Maybe(t.string),
  createdDate: Maybe(t.string),
  agingDays: Maybe(t.number),
  outBoundExecutionTime: Maybe(t.number),
  outBoundLatestHandleStaff: Maybe(t.string),
  outBoundLeaderOfStaff: Maybe(t.string),
  status: Maybe(t.string),
  activity: Maybe(t.string),
  processInstanceId: Maybe(t.string),
  categoryInsurance: Maybe(t.string),
  policyFirstIssuedDate: Maybe(t.string),
  policyLastIssuedDate: Maybe(t.string),
  caseSubmittedDate: Maybe(t.string),
  paidToDate: Maybe(t.string),
  callOutCode: Maybe(t.string),
  assignee: Maybe(t.string),
  agentCode: Maybe(t.string)
})

export type ParamInquiry = {
  policyNumber?: string,
  idNumber?: string,
  clientNumber?: string,
  phoneNumber?: string,
  createdAfter?: Date,
  createdBefore?: Date,
  start: number,
  size: number,
  order?: string
  category?: string,
  sort?: string
  policyFirstIssuedDateBefore: string | null | undefined,
  policyFirstIssuedDateAfter: string | null | undefined,
  policyLastIssuedDateBefore: string | null | undefined,
  policyLastIssuedDateAfter: string | null | undefined,
  caseSubmittedBefore: string | null | undefined,
  caseSubmittedAfter: string | null | undefined,
  paidToDateBefore: string | null | undefined,
  paidToDateAfter: string | null | undefined,
  callOutCode: string | null | undefined,
  latestAssignee: string | null | undefined,
  leaderOfAssignee: string | null | undefined,
  currentActivity: string | null | undefined,
  caseStatus: string | null | undefined,
  saleChannel: string | null | undefined,
  transactionTypeIn: string[] | null | undefined
}

export const OBTaskQueryInputParam = t.type({
  // activityBasket: t.string,
  // isTeamTask: t.boolean,
  // callProgramCode: t.string,// chinh la transaction type
  // leader: t.string,
  // caseId: t.string,
  // vip: t.string,
  // sort: Maybe(t.string),
  // orders: Maybe(t.array(t.string)),
  start: Maybe(t.number),
  size: Maybe(t.number),
  vipIn: Maybe(t.array(t.string)),
  sourceIn: Maybe(t.array(t.string)),
  transactionTypeIn: Maybe(t.array(t.string)),
  businessKey: Maybe(t.string),
  category: t.string, // OB,IB
  taskCategory: t.string, //chinh la field activityBasket hoac subtaskcode
  assignee: Maybe(t.string), //currentAssignee
  status: t.string,
  aging: Maybe(t.number),
  totalAgingDays: Maybe(t.number),
  clientNumber: t.string,
  // policyNumber: t.string,
  policyNumberIn: Maybe(t.array(t.string)),
  policyOwnerName: Maybe(t.string),
  saleChannel: Maybe(t.string),
  appointedBefore: Maybe(t.string),
  appointedAfter: Maybe(t.string),
  executionTime: Maybe(t.string),
  isDuplicatePo: Maybe(t.boolean),
  createdBefore: Maybe(t.string),
  createdAfter: Maybe(t.string),
  leader: Maybe(t.string),
  suspended: Maybe(t.boolean),
  verificationAssignee: Maybe(t.string),
  lastVerUpdateDateBefore: Maybe(t.string),
  lastVerUpdateDateAfter: Maybe(t.string),
  policyFirstIssuedDateBefore: Maybe(t.string),
  policyFirstIssuedDateAfter: Maybe(t.string),
  policyLastIssuedDateBefore: Maybe(t.string),
  policyLastIssuedDateAfter: Maybe(t.string),
  lastVerAssignee: Maybe(t.string)
})

export const OBTaskQueryData = t.type({
  id: Maybe(t.string), // taskID
  businessKey: t.string, //caseId
  vip: Maybe(t.string),
  category: Maybe(t.string),
  transactionType: Maybe(t.string), // callProgramCode
  processInstanceId: Maybe(t.string),
  policyNumber: t.string,
  clientNumber: t.string,
  // clientName: Maybe(t.string),
  poName: Maybe(t.string),
  agingDays: Maybe(t.number), //aging
  createdDate: Maybe(DateFromISOString),
  lastAssignee: Maybe(t.string),
  lastUpdateByVer: Maybe(t.string),
  agency: Maybe(t.string),
  appointmentDate: Maybe(t.string),
  isDuplicatePo: Maybe(t.boolean),
  assignee: Maybe(t.string),
  taskAgingDays: Maybe(t.number),
  leader: Maybe(t.string),
  status: Maybe(t.string),
  executionTime: Maybe(t.number),
  saleChannel: Maybe(t.string),
  suspendAgingDays: Maybe(t.number), //aging
  lastActivity: Maybe(t.string),
  policyFirstIssuedDate: Maybe(t.string),
  policyLastIssuedDate: Maybe(t.string),
  lastVerUpdate: Maybe(t.string),
  currentActivityName: Maybe(t.string),
  stuckDate: Maybe(t.string),
  executionNumber: Maybe(t.number),
})

export const OBTaskResponseData = t.type({
  data: Maybe(t.array(OBTaskQueryData)),
  total: t.number,
  start: t.number,
  size: t.number,
})

export const OBInquiryData = t.type({
  data: Nullable(t.array(OBTaskData)),
  total: t.number
})

export const OBAssignTaskParam = t.type({
  targetAssignee: t.string,
  userGroupCode: t.string,
  activityBasket: t.string,
  taskIdList: t.array(t.string),
  comment: t.string
})

export const OBRelatedDocumentInfo = t.type({
  url: t.string,
  metaData: t.type({
    docid: t.string,
    maindoc: Maybe(t.string),
    subdoc: Maybe(t.string),
    batchno: Maybe(t.string)
  })
})

export const OBUserGroupData = t.type({
  userGroupCodeToTransfer: t.string,
  activityBasketToTransfer: t.string
})

export const OBUserEmailInfo = t.type({
  managerEmail: t.string,
  userEmail: t.string,
  // levelQC: t.string,
  // isBlackList: t.string,
  // extensionCode: t.string,
})

export const OBGroupManagerInfo = t.type({
  managerEmail: t.string,
  isSQCB: t.boolean,
  isCallMeBack: t.boolean,
  isWCC: t.boolean,
  isPremiumReminder: t.boolean,
  isAPL: t.boolean,
  isOPL: t.boolean,
  isPremiumOverdue: t.boolean,
  isCare1: t.boolean,
  isCare2: t.boolean,
  isMaturity3M: t.boolean,
  isMaturity12M: t.boolean
})

export const OBHistoryData = t.type({
  processInstanceId: t.string,
  user: Maybe(t.string),
  userName: Maybe(t.string),
  createdDate: Maybe(t.string),
  workFlowStage: Maybe(t.string),
  fields: Nullable(
    t.array(
      t.type({
        name: t.string,
        newValue: Nullable(t.string),
        oldValue: Nullable(t.string)
      })
    )
  ),
})

export const ManagerInfo = t.type({
  userGroupCode: t.string,
  managerList: t.array(t.string)
})

export const OBAssignmentUserInfo = t.type({
  name: t.string,
  email: t.string,
  status: Maybe(t.boolean)
})

export const EndReasonInfo = t.type({
  reasonCode: t.string,
  reasonDesc: t.string,
  reasonDescVN: t.string
})

export const CallProgramInfo = t.type({
  code: t.string,
  transactionTypeEn: t.string,
  transactionTypeWF: t.string,
  categoryCode: t.string,
  serviceCode: t.string
})

export const CustomerAnswerPayload = t.type({
  p4oCaseId: Maybe(t.string),
  // p4oCaseID: Maybe(t.string),
  idcProcessInstanceId: t.string,
  parentProcessInstanceId: t.string,
  idcType: t.string
})

export const ADDHOCImportedParamPayload = t.type({
  "adhocCode": Maybe(t.string),
  "adhocName": Maybe(t.string),
  "departmentCode": Maybe(t.string),
  "paramData": t.type({
    "PARAM01": t.string,
    "PARAM02": t.string,
    "PARAM03": t.string,
    "PARAM04": t.string,
    "PARAM05": t.string,
    "PARAM06": t.string,
    "PARAM07": t.string,
    "PARAM08": t.string,
    "PARAM09": t.string,
    "PARAM10": t.string,
    "PARAM11": t.string,
    "PARAM12": t.string,
    "PARAM13": t.string,
    "PARAM14": t.string,
    "PARAM15": t.string,
    "PARAM16": t.string,
    "PARAM17": t.string,
    "PARAM18": t.string,
    "PARAM19": t.string,
    "PARAM20": t.string
  })
})

export const OBUnsupendResponse = t.union([
  Maybe(t.type({
    code: Maybe(t.string),
    message: Maybe(t.string),
    messageVn: Maybe(t.string)
  })),
  t.unknown
])
export type OBUnsupendResponse = t.TypeOf<typeof OBUnsupendResponse>

export type OBInquiryData = t.TypeOf<typeof OBInquiryData>
export type OBTaskQueryInputParam = t.TypeOf<typeof OBTaskQueryInputParam>
export type OBTaskQueryData = t.TypeOf<typeof OBTaskQueryData>
export type OBTaskResponseData = t.TypeOf<typeof OBTaskResponseData>
export type OBTaskData = t.TypeOf<typeof OBTaskData>
export type OBAssignTaskParam = t.TypeOf<typeof OBAssignTaskParam>
export type OBRelatedDocumentInfo = t.TypeOf<typeof OBRelatedDocumentInfo>
export type OBUserGroupData = t.TypeOf<typeof OBUserGroupData>
export type OBUserEmailInfo = t.TypeOf<typeof OBUserEmailInfo>
export type OBGroupManagerInfo = t.TypeOf<typeof OBGroupManagerInfo>
export type OBTaskDetail = t.TypeOf<typeof OBTaskDetail>
export type OBHistoryData = t.TypeOf<typeof OBHistoryData>
export type ManagerInfo = t.TypeOf<typeof ManagerInfo>
export type OBAssignmentUserInfo = t.TypeOf<typeof OBAssignmentUserInfo>
export type CallProgramInfo = t.TypeOf<typeof CallProgramInfo>
export type CustomerAnswerPayload = t.TypeOf<typeof CustomerAnswerPayload>
export type ADDHOCImportedParamPayload = t.TypeOf<typeof ADDHOCImportedParamPayload>
export namespace OBTaskService {

  const cleanObj = (obj: any) => {
    for (const propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName]
      }
    }
    return obj
  }

  export const getCaseInquiry = (dataQuery: ParamInquiry) =>
    pipe(
      POApi.post(`pulseops/history/historic-process-instances`)(OBInquiryData)(dataQuery),
      // ZIO.map((res) => res)
      ZIO.foldM(
        (err) => ZIO.fail(err),
        (success) => ZIO.succeed(success)
      )
    )

  export const getMyTaskList = (inputData: OBTaskQueryInputParam) => pipe(
    POApi.post(`pulseops/api/v1/query/tasks`)(OBTaskResponseData)(inputData),
    ZIO.foldM(
      (error) => ZIO.fail(error),
      (reponseData) => ZIO.succeed(reponseData)
    )
  )

  export const getTeamTaskList = (inputData: OBTaskQueryInputParam, isSuspend?: boolean) =>
    isSuspend ?
      pipe(
        POApi.post(`pulseops/api/v1/query/process-instances`)(OBTaskResponseData)(inputData),
        ZIO.foldM(
          (error) => ZIO.fail(error),
          (reponseData) => ZIO.succeed(reponseData)
        )
      ) :
      pipe(
        POApi.post(`pulseops/api/v1/query/team-tasks`)(OBTaskResponseData)(inputData),
        ZIO.foldM(
          (error) => ZIO.fail(error),
          (reponseData) => ZIO.succeed(reponseData)
        )
      )

  export const getTaskCount = (isTeamTask: boolean) => pipe(
    POApi.get(`cs-rest/api/v1/tasks/count?isTeamTask=${isTeamTask}`)(t.type({
      data: t.array(t.type({
        callProgramCode: t.string,
        totalCase: t.number,
        basketDetailDTO: t.array(t.type({
          activityBasket: t.string,
          activeTaskCount: t.number
        }))
      })),
      message: t.string
    })),
    ZIO.map((countResult) => {
      const VERITaskCount = countResult.data.reduce((total, item) => {
        const activeTaskCount = pipe(
          item.basketDetailDTO.find((x) => x.activityBasket.endsWith(OBBasketType.Verification)),
          O.fromNullable,
          O.map((taskCount) => taskCount.activeTaskCount),
          O.getOrElse(() => 0)
        )
        return activeTaskCount + total
      }, 0)
      const QCTaskCount = countResult.data.reduce((total, item) => {
        const activeTaskCount = pipe(
          item.basketDetailDTO.find((x) => x.activityBasket.endsWith(OBBasketType.QC)),
          O.fromNullable,
          O.map((taskCount) => taskCount.activeTaskCount),
          O.getOrElse(() => 0)
        )
        return activeTaskCount + total
      }, 0)
      const QATaskCount = countResult.data.reduce((total, item) => {
        const activeTaskCount = pipe(
          item.basketDetailDTO.find((x) => x.activityBasket.endsWith(OBBasketType.QA)),
          O.fromNullable,
          O.map((taskCount) => taskCount.activeTaskCount),
          O.getOrElse(() => 0)
        )
        return activeTaskCount + total
      }, 0)
      const VERActivityBasket = pipe(
        countResult.data.find(x => x.callProgramCode === OBCallType.SQCB),
        O.fromNullable,
        O.map((data) => {
          const basketName = data.basketDetailDTO && data.basketDetailDTO.length > 0 ? data.basketDetailDTO[0].activityBasket : ''
          return basketName
        }),
        O.getOrElse(() => '')
      )
      const QCActivityBasket = pipe(
        countResult.data.find(x => x.callProgramCode === OBCallType.SQCB),
        O.fromNullable,
        O.map((data) => {
          const basketName = data.basketDetailDTO && data.basketDetailDTO.length > 0 ? data.basketDetailDTO[1].activityBasket : ''
          return basketName
        }),
        O.getOrElse(() => '')
      )
      const QAActivityBasket = pipe(
        countResult.data.find(x => x.callProgramCode === OBCallType.SQCB),
        O.fromNullable,
        O.map((data) => {
          const basketName = data.basketDetailDTO && data.basketDetailDTO.length > 0 ? data.basketDetailDTO[2].activityBasket : ''
          return basketName
        }),
        O.getOrElse(() => '')
      )
      return {
        veriTaskCount: VERITaskCount,
        VERActivityBasket: VERActivityBasket,
        QCTaskCount: QCTaskCount,
        QCActivityBasket: QCActivityBasket,
        QATaskCount: QATaskCount,
        QAActivityBasket: QAActivityBasket
      }
    })
  )

  export const getAssignToMeTask = (data: OBAssignTaskParam) => pipe(
    POApi.post(`cs-rest/api/v1/tasks/assign`)(t.type({
      responseStatus: Maybe(t.array(t.type({
        // data: t.string,
        code: t.string,
        message: t.string
      }))),
      code: t.string,
      message: t.string
    }))(data),
    ZIO.foldM(
      (e) => ZIO.fail(e),
      (data) => ZIO.succeed(data)
    )
  )

  export const getUserGroupList = (categoryCode: string, activityBasket: string, transactionType: string): Task<OBUserGroupData[]> => pipe(
    POApi.post(`pulseops/api/v1/tasks/manual-transfer-config`)(t.array(OBUserGroupData))({
      categoryCode: categoryCode,
      activityBasket: activityBasket,
      transactionType: transactionType
    }),
    ZIO.map((groupData) => {
      return groupData
    })
  )

  export const getUserQCGroupLevelByGroupType = (category: string, transactionType: string, activityBasket: string) =>
    pipe(
      ZIO.effect(() => {
        const transferData: TransferData = {
          transactionType: transactionType,
          category: category,
          activityBasket: activityBasket,
          userQCGroupLevel: '',
          manager: true
        }
        return transferData
      }),
      ZIO.flatMap((transferData) => {
        return GeneralService.getQCGroupLevelToTransfer(transferData)
      }),
      ZIO.mapError((error) => {
        return Throwable(error)
      })
    )

  export const getUserEmailList = (managerEmail: string, userGroupCode: string): Task<string[]> => pipe(
    POApi.get(`cs-api/outbound/config-data/list-staff-by-manager-email?email=${managerEmail}&userGroupCode=${userGroupCode}`)(t.type({
      data: t.type({
        records: t.array(t.string)
      }),
      code: Maybe(t.string),
      message: Maybe(t.string)
    })),
    ZIO.foldM(
      (error) => ZIO.fail(error),
      (response) => ZIO.succeed(response.data.records)
    )
  )

  export const getUserEmailListByLeader = (managerEmail: string): Task<string[]> => pipe(
    POApi.get(`cs-api/outbound/config-data/list-staff-by-manager-email?email=${managerEmail}`)(t.type({
      data: t.type({
        records: t.array(t.string)
      }),
      code: Maybe(t.string),
      message: Maybe(t.string)
    })),
    ZIO.foldM(
      (error) => ZIO.fail(error),
      (response) => ZIO.succeed(response.data.records)
    )
  )

  export const getManagerList = (userGroupCode: string) => pipe(
    POApi.get(`cs-api/outbound/config-data/t4s17config/${userGroupCode}`)(t.type({
      data: ManagerInfo,
      code: Maybe(t.string),
      message: Maybe(t.string)
    })),
    ZIO.map((groupData) => {
      return groupData.data
    })
  )

  export const getEndReasonList = () => pipe(
    POApi.get(`cs-api/outbound/table/a4-reason-end-case`)(t.type({
      data: Maybe(t.array(EndReasonInfo)),
      code: Maybe(t.string),
      message: Maybe(t.string)
    })),
    ZIO.map((response) => {
      const list = response && response.data && response.data.length > 0 ? response.data : []
      return list
    })
  )

  export const getDocuments = (policyNum: string) => pipe(
    POApi.get(`cs-api/outbound/policy/${policyNum}/documents`)(t.type({
      data: Maybe(t.array(OBRelatedDocumentInfo)),
      code: Maybe(t.string),
      message: t.string
    })),
    ZIO.map((resDocuments) => {
      return resDocuments
    })
  )

  export const getCaseDetails = (processInstanceId: string) => pipe(
    POApi.get(`pulseops/history/historic-process-instances/${processInstanceId}`)(OBTaskDetail),
    ZIO.map((taskDetails) => {
      return taskDetails
    })
  )

  export const getHistory = (processInstanceId: string) => pipe(
    POApi.get(`pulseops/api/v1/query/activity-log?processInstanceId=${processInstanceId}`)(Maybe(
      t.array(OBHistoryData)),
    ),
    ZIO.map((data) =>
      data && data.map(({ user, workFlowStage, createdDate, fields, userName, processInstanceId }) => {
        const fieldsData = fields
          ? fields.map((x) => ({
            ...x,
            oldValue: x.oldValue || '',
            newValue: x.newValue || ''
          }))
          : []
        return {
          processInstanceId: processInstanceId,
          fields: fieldsData,
          // field: fieldsData.map(({ name }) => name).join('\n'),
          newValue: fieldsData.map(({ newValue }) => newValue).join('\n'),
          originalValue: fieldsData.map(({ oldValue }) => oldValue || '').join('\n'),
          user: user || '',
          userName: userName,
          createdDate: PulseOpsFormat.toCurrentTimeZone(
            createdDate + ':00 GMT+00:00',
            'DD/MM/YYYY HH:mm:ss Z',
            'DD/MM/YYYY HH:mm'
          ),
          workFlowStage: workFlowStage || ''
        }
      })
    )
  )

  export const getStaffList = () => pipe(
    POApi.get(`cs-api/outbound/config-data/list-staff-by-manager-email`)(t.type({
      data: t.type({
        records: t.array(t.string)
      }),
      message: Maybe(t.string)
    })),
    ZIO.foldM(
      (error) => ZIO.fail(error),
      (response) => ZIO.succeed(response.data.records)
    )
  )

  export const getManagerNameArr = () => pipe(
    POApi.get(`cs-api/outbound/config-data/list-manager`)(t.type({
      data: t.type({
        records: t.array(t.string)
      }),
      message: Maybe(t.string)
    })),
    ZIO.foldM(
      (error) => ZIO.fail(error),
      (response) => ZIO.succeed(response.data.records)
    )
  )

  export const getUsersByGroups = (groups: string[], currAssignee: string, category: string, basket: string) =>
    pipe(
      POApi.post(`pulseops/api/v1/permission/users/group`)(
        t.type({
          data: t.array(
            OBAssignmentUserInfo
          )
        })
      )({ basket, category, groups }),
      // ZIO.map((policy) => policy.data.filter((x) => x.email !== currAssignee) || [])
      ZIO.map((policy) => policy.data || [])
    )

  export const getCallProgramList = () => pipe(
    POApi.get(`cs-api/outbound/table/t0s3-transaction-type`)(t.type({
      data: Maybe(t.array(CallProgramInfo)),
      code: Maybe(t.string),
      message: Maybe(t.string)
    })),
    ZIO.map((response) => {
      const callProgramList = response && response.data && response.data.length > 0 ? response.data : []
      return callProgramList
    })
  )

  export const endTaskOB = (
    taskId: string,
    data: { author: string, message: string, reason: string },
  ) => {
    let variableArr: Array<{ name: string, type: string, value: any }> = [
      {
        name: 'endCaseReasonCode',
        type: 'string',
        value: data.reason
      }
    ]
    return pipe(
      PulseOpsApi.taskAction({
        taskId,
        action: 'complete',
        outcome: 'end',
        author: data.author,
        comment: data.message,
        variables: variableArr,
        reason: data.reason,
        category: 'OB'
      }),
      ZIO.tap(() =>
        ZIO.effect(() => {
          (new Subject).next(null)
        }))
    )
  }

  export const unSuspendAction = ({
    processInstanceIds,
    assignee,
    comment,
    userGroupCode,
    userLevel,
    isReturnToLastAssignee
  }: {
    processInstanceIds: string[]
    assignee: string
    comment: string
    userGroupCode: string
    userLevel: string,
    isReturnToLastAssignee?: boolean
  }) =>
    pipe(
      AuthService.userInfo,
      ZIO.flatMap((x) =>
        POApi.put(`pulseops/api/v1/runtime/process-instances/unsuspend`)(OBUnsupendResponse)({
          processInstanceIds,
          assignee,
          comment,
          author: x.email,
          userGroupCode,
          userLevel,
          isReturnToLastAssignee
        })
      ),
      ZIO.map((res) => res)
    )

  export const countOBTeamTasks: Task<TaskCount[]> = pipe(
    AuthService.userInfo,
    ZIO.flatMap((user) => TaskService.countTasks(`pulseops/api/v1/tasks/team-tasks/count?assignee=${user.email}`)),
    ZIO.map((teamTasks) => {
      return teamTasks.map((item) => {
        let subTasks = item.subTasks
        let subTasksLength = subTasks.length
        if (subTasks[subTasksLength - 1].code.subTask !== SubTaskType.ErrorHandling) {
          let errorHandlerSubTask = Object.assign(
            { ...subTasks[subTasksLength - 1], value: 0 },
            {
              code: {
                task: subTasks[subTasksLength - 1].code.task,
                subTask: SubTaskType.ErrorHandling
              }
            }
          )
          subTasks.push(errorHandlerSubTask)
        }
        return item
      })
    })
  )

  export const countOBMyTasks: Task<TaskCount[]> = pipe(
    AuthService.userInfo,
    ZIO.flatMap((user) => TaskService.countTasks(`pulseops/api/v1/tasks/my-tasks/count?assignee=${user.email}`))
  )

  export const getUsersByGroupsQC = (groups: string[]) =>
    pipe(
      POApi.get(`pulseops/api/v1/manual-transfer/qc-users?qcGroupLevel=${groups}`)(
        t.type({
          data: t.array(
            OBAssignmentUserInfo
          )
        })
      ),
      ZIO.map((policy) => policy.data)
    )

  export const transferOBTask = (
    taskId: string,
    userEmail: string,
    assigneeGroup: string,
    data: { author: string; message: string },
    updateToCore: boolean,
    category?: string
  ) =>
    pipe(
      transferAction({
        taskId,
        assignee: userEmail,
        author: data.author,
        comment: data.message,
        assigneeGroup,
        category,
        variables: []
      })
    )

  const transferAction = ({
    taskId,
    assignee,
    author,
    variables,
    comment,
    assigneeGroup,
    category
  }: {
    taskId: string
    assignee: string
    author: string
    variables: any[]
    comment?: string
    assigneeGroup?: string,
    category?: string
  }) =>
    pipe(
      AuthService.userInfo,
      ZIO.flatMap((x) =>
        POApi.post(`pulseops/api/v1/tasks/${taskId}`)(t.unknown)(
          cleanObj({
            action: 'assign',
            assignee,
            author: x.email,
            comment,
            assigneeGroup,
            variables,
            category
          })
        )
      ),
      ZIO.foldM(
        (error) => ZIO.fail(error),
        (success) => ZIO.succeed(success),
      )
    )

  export const saveIsClickChangeMobileInfo = (processInstanceId: string) =>
    pipe(
      POApi.put(`pulseops/api/v1/outbound/process-instance/${processInstanceId}/variables`)(t.unknown)(
        {
          isChangeMobilePhone: true
        }
      ),
      ZIO.asVoid
    )
  export const transferList = ({
    taskList,
    assignee,
    assigneeGroup,
    category
  }: {
    taskList: string[]
    assignee: string
    assigneeGroup?: string,
    category?: string
  }) =>
    pipe(
      AuthService.userInfo,
      ZIO.flatMap((token) =>
        POApi.put(`pulseops/api/v1/tasks/assign-task-list`)(t.unknown)({
          assignee: assignee,
          assigneeGroup: assigneeGroup,
          taskIds: taskList,
          user: token.email,
          category
        })
      )
    )

  export const submitOBTask = ({
    taskId,
    author,
    variables,
    outcome = 'complete'
  }: {
    taskId: string
    author: string
    variables: any[] | undefined
    outcome?: string
  }) =>
    pipe(
      POApi.post(`pulseops/api/v1/tasks/${taskId}`)(t.unknown)(
        {
          action: 'complete',
          outcome: outcome,
          variables,
          author,
          category: 'OB'
        }
      ),
      ZIO.map((res) => res)
    )

  export const getErrorActivity = (data: ErrorActivityInputData) =>
    pipe(
      POApi.post(`pulseops/api/v1/query/error-activity`)(OBTaskResponseData)(data),
      ZIO.foldM(
        (error) => ZIO.fail(error),
        (reponseData) => ZIO.succeed(reponseData)
      )
    )
}
