export namespace T0Data {
  interface T0Item {
    transactionType: string
    transactionTypeVn: string
  }

  const rawData = [
    { transactionTypeCode: 'COMPLAINT', transactionType: 'Complaint', transactionTypeVn: 'Giải quyết khiếu nại' },
    {
      transactionTypeCode: 'NEW_CLAIM_REGISTER',
      transactionType: 'New Claim Register',
      transactionTypeVn: 'Đăng ký giải quyết quyền lợi bảo hiểm'
    },
    {
      transactionTypeCode: 'CLAIM_SUPPLEMENTARY',
      transactionType: 'Claim Supplementary',
      transactionTypeVn: 'Bổ sung chứng từ quyền lợi bảo hiểm'
    },
    {
      transactionTypeCode: 'CLAIM_PAYMENT',
      transactionType: 'Claim Payment',
      transactionTypeVn: 'Chi trả quyền lợi bảo hiểm'
    },
    { transactionTypeCode: 'INQUIRY', transactionType: 'Inquiry', transactionTypeVn: 'Truy vấn ' },
    {
      transactionTypeCode: 'CUSTOMER_CARE',
      transactionType: 'Customer Care',
      transactionTypeVn: 'Chăm sóc Khách hàng'
    },
    {
      transactionTypeCode: 'OPERATING_LOGISTICS',
      transactionType: 'Operating Logistics',
      transactionTypeVn: 'Quản trị hành chính'
    },
    {
      transactionTypeCode: 'AGENT_ONBOARD',
      transactionType: 'Agent Onboard',
      transactionTypeVn: 'Đăng ký làm đại lý'
    },
    {
      transactionTypeCode: 'AGENT_TRANSFER',
      transactionType: 'Agent Transfer',
      transactionTypeVn: 'Yêu cầu thay đổi đại lý phục vụ'
    },
    {
      transactionTypeCode: 'AGENT_MODIFICATION',
      transactionType: 'Agent Modification',
      transactionTypeVn: 'Yêu cầu điều chỉnh thông tin đại lý'
    },
    {
      transactionTypeCode: 'AGENT_TERMINATION',
      transactionType: 'Agent Termination',
      transactionTypeVn: 'Yêu cầu thanh lý Hợp đồng Đại lý'
    },
    {
      transactionTypeCode: 'UPLOAD_DOCUMENT',
      // transactionType: 'Upload Document',
      transactionType: 'Upload Documents',
      // transactionTypeVn: 'Cập nhật chứng từ vào Filenet'
      transactionTypeVn: 'Nộp các chứng từ của hợp đồng'
    },
    {
      transactionTypeCode: 'CUSTOMER_CONTACT_UPDATE',
      transactionType: 'Customer Contact Update',
      transactionTypeVn: 'Bổ sung thông tin liên lạc'
    },
    {
      transactionTypeCode: 'GA_CASH_ADVANCE',
      transactionType: 'GA Cash Advance',
      transactionTypeVn: 'Yêu cầu tạm ứng'
    },
    {
      transactionTypeCode: 'GA_CASH_DEPOSIT',
      transactionType: 'GA Cash Deposit',
      transactionTypeVn: 'GA nộp phí bảo hiểm/nộp hoàn tạm ứng/nộp tiền chi thưởng nóng'
    },
    { transactionTypeCode: 'PROPOSAL_FORM', transactionType: 'Proposal Form', transactionTypeVn: 'Hồ sơ mới' },
    {
      transactionTypeCode: 'ADDITIONAL_INFORMATION NB',
      transactionType: 'Additional information NB',
      transactionTypeVn: 'Bổ sung thông tin hồ sơ/HĐ'
    },
    { transactionTypeCode: 'RETURN_LETTER', transactionType: 'Return Letter', transactionTypeVn: 'Thư trả về' },
    {
      transactionTypeCode: 'UPLOAD_FILE_PREMIUM_COLLECTION',
      transactionType: 'Upload File Premium Collection',
      transactionTypeVn: 'Tải tập tin và xử lý dữ liệu'
    },
    {
      transactionTypeCode: 'AUTO_DEBIT_REGISTRATION',
      transactionType: 'Auto Debit Registration',
      transactionTypeVn: 'Đăng ký nộp phí bảo hiểm tự động'
    },
    {
      transactionTypeCode: 'CASH_AT_GA_PROPOSAL',
      transactionType: 'Cash at GA - Proposal',
      transactionTypeVn: 'Nộp phí hồ sơ yêu cầu bảo hiểm'
    },
    {
      transactionTypeCode: 'CASH_AT_GA_POLICY',
      transactionType: 'Cash at GA - Policy',
      transactionTypeVn: 'Nộp phí và hoàn trả hợp đồng bảo hiểm'
    },
    {
      transactionTypeCode: 'PRUCASH_PAYMENT',
      transactionType: 'Prucash Payment',
      transactionTypeVn: 'Nhận quyền lợi định kỳ'
    },
    { transactionTypeCode: 'MATURITY', transactionType: 'Maturity', transactionTypeVn: 'Nhận quyền lợi đáo hạn' },
    { transactionTypeCode: 'LOAN', transactionType: 'Loan', transactionTypeVn: 'Tạm ứng từ giá trị hoàn lại' },
    {
      transactionTypeCode: 'CANCEL_FROM_INCEPTION',
      transactionType: 'Cancel From Inception',
      transactionTypeVn: 'Hủy hợp đồng trong thời gian cân nhắc'
    },
    {
      transactionTypeCode: 'EXCESS_PREMIUM',
      transactionType: 'Excess Premium',
      transactionTypeVn: 'Chi trả phí dư'
    },
    {
      transactionTypeCode: 'PARTIAL_WITHDRAWAL',
      transactionType: 'Partial Withdrawal',
      transactionTypeVn: 'Rút trước một phần giá trị tài khoản/ giá trị quỹ'
    },
    {
      transactionTypeCode: 'MATURITY_ADVANCE',
      transactionType: 'Maturity Advance',
      transactionTypeVn: 'Ứng trước quyền lợi đáo hạn'
    },
    {
      transactionTypeCode: 'CASH_OUT_REQUEST',
      transactionType: 'Cash-out Request',
      transactionTypeVn: 'Yêu cầu chi trả tiền mặt tại quầy'
    },
    {
      transactionTypeCode: 'PARTIAL_SURRENDER',
      transactionType: 'Partial Surrender',
      transactionTypeVn: 'Giảm số tiền BH nhận một phần giá trị hoàn lại'
    },
    {
      transactionTypeCode: 'BONUS_SURRENDER',
      transactionType: 'Bonus Surrender',
      transactionTypeVn: 'Yêu cầu rút trước bảo tức'
    },
    {
      transactionTypeCode: 'SPECIAL_BONUS',
      transactionType: 'Special Bonus',
      transactionTypeVn: 'Nhận bảo tức đặc biệt'
    },
    {
      transactionTypeCode: 'EXCELLENT_STUDENT_AWARD',
      transactionType: 'Excellent Student Award',
      transactionTypeVn: 'Nhận quyền lợi đăng khoa'
    },
    { transactionTypeCode: 'PRUKID369', transactionType: 'PruKid369', transactionTypeVn: 'Nhận quyền lợi hoàn phí' },
    {
      transactionTypeCode: 'FULL_SURRENDER',
      transactionType: 'Full Surrender',
      transactionTypeVn: 'Hủy hợp đồng nhận giá trị hoàn lại/Rút toàn bộ giá trị đầu tư '
    },
    {
      transactionTypeCode: 'CHANGE_CONTACT_INFO',
      transactionType: 'Change Contact Info',
      transactionTypeVn: 'Cập nhật thông tin liên lạc'
    },
    {
      transactionTypeCode: 'CHANGE_CONTACT_INFO_PHONE',
      transactionType: 'Change Contact Info - Phone',
      transactionTypeVn: 'Cập nhật thông tin liên lạc - Số điện thoại'
    },
    {
      transactionTypeCode: 'CHANGE_CONTACT_INFO_EMAIL',
      transactionType: 'Change Contact Info - Email',
      transactionTypeVn: 'Cập nhật thông tin liên lạc - Email'
    },
    {
      transactionTypeCode: 'CHANGE_CONTACT_INFO_ADDRESS',
      transactionType: 'Change Contact Info - Address',
      transactionTypeVn: 'Cập nhật thông tin liên lạc - Địa chỉ'
    },
    {
      transactionTypeCode: 'BENEFICIARY_DESIGNATION',
      transactionType: 'Beneficiary Designation',
      transactionTypeVn: 'Điều chỉnh người thụ hưởng'
    },
    {
      transactionTypeCode: 'BILLING_CHANGE',
      transactionType: 'Billing Change',
      transactionTypeVn: 'Điều chỉnh định kỳ nộp phí'
    },
    {
      transactionTypeCode: 'BILLING_FREQUENCY_REVERSAL',
      transactionType: 'Billing Frequency Reversal',
      transactionTypeVn: 'Chuyển trả định kỳ tự động'
    },
    { transactionTypeCode: 'TOP_UP', transactionType: 'Top Up', transactionTypeVn: 'Đầu tư thêm' },
    {
      transactionTypeCode: 'REINSTATEMENT',
      transactionType: 'Reinstatement',
      transactionTypeVn: 'Khôi phục hiệu lực hợp đồng'
    },
    {
      transactionTypeCode: 'CHANGE_CLIENT_INFO',
      transactionType: 'Change Client Info',
      transactionTypeVn: 'Điều chỉnh thông tin nhân thân'
    },
    { transactionTypeCode: 'GO_ABROAD', transactionType: 'Go Abroad', transactionTypeVn: 'Thông báo ra nước ngoài' },
    {
      transactionTypeCode: 'CANCEL_RIDER',
      transactionType: 'Cancel Rider',
      transactionTypeVn: 'Hủy bỏ sản phẩm bổ trợ'
    },
    { transactionTypeCode: 'FUND_SWITCHING', transactionType: 'Fund Switching', transactionTypeVn: 'Hoán đổi quỹ' },
    {
      transactionTypeCode: 'PREMIUM_REDIRECTION',
      transactionType: 'Premium Redirection',
      transactionTypeVn: 'Thay đổi tỷ lệ đầu tư'
    },
    {
      transactionTypeCode: 'STOP_APL',
      transactionType: 'Stop APL',
      transactionTypeVn: 'Tạm ngưng sử dụng GTHL để đóng phí'
    },
    {
      transactionTypeCode: 'FATCA_DECLARATION',
      transactionType: 'FATCA Declaration',
      transactionTypeVn: 'Khai báo tình trạng nộp thuế tại Hoa kỳ'
    },
    {
      // transactionTypeCode: 'HEALTH_DECLARATION',
      transactionTypeCode: 'HEALTH_REDECLARATION',
      transactionType: 'Health Redeclaration',
      transactionTypeVn: 'Khai lại thông tin sức khỏe'
    },
    {
      transactionTypeCode: 'FULL_SURRENDER_REVERSAL',
      transactionType: 'Full Surrender Reversal',
      transactionTypeVn: 'Khôi phục hiệu lực hợp đồng sau khi hủy'
    },
    {
      transactionTypeCode: 'PAID_UP',
      transactionType: 'Paid Up',
      transactionTypeVn: 'Dừng nộp phí và duy trì hợp đồng với số tiền bảo hiểm giảm'
    },
    { transactionTypeCode: 'INHERITANCE', transactionType: 'Inheritance', transactionTypeVn: 'Chuyển chủ hợp đồng' },
    {
      transactionTypeCode: 'ASSIGNMENT',
      transactionType: 'Assignment',
      transactionTypeVn: 'Chuyển nhượng hợp đồng'
    },
    {
      transactionTypeCode: 'SIGNATURE_REGISTRATION',
      transactionType: 'Signature Registration',
      transactionTypeVn: 'Đăng ký chữ ký mẫu'
    },
    {
      transactionTypeCode: 'PRODUCT_OPTION_SWITCHING',
      transactionType: 'Product Option Switching',
      transactionTypeVn: 'Thay đổi quyền lợi bảo hiểm'
    },
    {
      transactionTypeCode: 'SUM_ASSURED',
      transactionType: 'Sum Assured',
      transactionTypeVn: 'Điều chỉnh số tiền bảo hiểm'
    },
    {
      transactionTypeCode: 'CHANGE_PREMIUM',
      transactionType: 'Change Premium',
      transactionTypeVn: 'Điều chỉnh phí bảo hiểm'
    },
    {
      transactionTypeCode: 'ADD_RIDER',
      transactionType: 'Add Rider',
      transactionTypeVn: 'Điều chỉnh sản phẩm bổ trợ'
    },
    {
      transactionTypeCode: 'MAJOR_CHANGE_IN_FREE_LOOK',
      transactionType: 'Major Change In Free Look',
      transactionTypeVn: 'Điều chỉnh hợp đồng trong thời gian cân nhắc'
    },
    {
      transactionTypeCode: 'MAJOR_CHANGE_AFTER_FREE_LOOK',
      transactionType: 'Major Change After Free Look',
      transactionTypeVn: 'Điều chỉnh hợp đồng sau thời gian cân nhắc'
    },
    {
      transactionTypeCode: 'E_SERVICE_REGISTRATION',
      transactionType: 'eService Registration',
      transactionTypeVn: 'Đăng ký dịch vụ trực tuyến'
    },
    {
      transactionTypeCode: 'RIDER_REINSTATEMENT',
      transactionType: 'Rider Reinstatement',
      transactionTypeVn: 'Khôi phục hiệu lực sản phẩm bổ trợ'
    },
    {
      transactionTypeCode: 'EKIT_CONFIRMATION',
      transactionType: 'eKit Confirmation',
      transactionTypeVn: 'Xác nhận bộ hợp đồng điện tử'
    },
    {
      transactionTypeCode: 'REDATING',
      transactionType: 'Redating',
      transactionTypeVn: 'Khôi phục hiệu lực theo phương thức dời ngày hiệu lực'
    },
    {
      transactionTypeCode: 'CHANGE_CONTACT_INFO_PHONE_AND_EMAIL',
      transactionType: 'Change Contact Info - Phone & Email',
      transactionTypeVn: 'Cập nhật thông tin liên lạc - Số điện thoại & Email'
    },
    {
      transactionTypeCode: 'CONFIRMATION_OF_PREMIUM_RECEIPT',
      transactionType: 'Confirmation of premium receipt',
      transactionTypeVn: 'Xác nhận nộp phí'
    },
    {
      transactionTypeCode: 'LOAN_STATEMENTS',
      transactionType: 'Loan statements',
      transactionTypeVn: 'Sao kê tạm ứng giá trị hoàn lại'
    },
    {
      transactionTypeCode: 'SCHEDULED_PAYMENT_STATEMENT',
      transactionType: 'Scheduled payment statement',
      transactionTypeVn: 'Bản kê chi tiết quyền lợi định kỳ trên hợp đồng'
    },
    {
      transactionTypeCode: 'POLICY_CERTIFICATE',
      transactionType: 'Policy Certificate (hard copy)',
      transactionTypeVn: 'Cấp phó bản (Life Certificate)'
    },
    {
      transactionTypeCode: 'CONFIRMATION_LETTER_OF_POLICY_ACCOUNT_AND_SURRENDER_VALUE',
      transactionType: 'Confirmation letter of policy account & surrender value',
      transactionTypeVn: 'Thư xác nhận giá trị hợp đồng và giá trị hoàn lại'
    },
    {
      transactionTypeCode: 'ADD_LACK_OF_DOCUMENTS',
      transactionType: 'Add lack of documents',
      transactionTypeVn: 'Bổ sung thông tin'
    },
    {
      transactionTypeCode: 'POLICY_SERVICE_REQUEST',
      transactionType: 'Policy Service Request',
      transactionTypeVn: 'Yêu cầu chung'
    },
    {
      transactionTypeCode: 'SUPPLEMENTARY_INFO',
      transactionType: 'Supplementary Info',
      transactionTypeVn: 'Bổ sung thông tin chi trả/điều chỉnh hợp đồng'
    },
    {
      transactionTypeCode: 'ADDITIONAL_INFORMATION_UW',
      transactionType: 'Additional Information UW',
      transactionTypeVn: 'Bổ sung thông tin thẩm định'
    },
    {
      transactionTypeCode: 'COMMON_REQUEST_FORM',
      transactionType: 'Common Request Form',
      transactionTypeVn: 'Gửi yêu cầu chung'
    },
    {
      transactionTypeCode: 'RIDER_ALTERATION',
      transactionType: 'Rider Alteration',
      transactionTypeVn: 'Điều chỉnh sản phẩm bổ trợ'
    },
    {
      transactionTypeCode: 'SPECIAL_BONUS_CUSTOMER',
      transactionType: 'Special Bonus (Customer)',
      transactionTypeVn: 'Bảo tức tích lũy đặc biệt (KH)'
    },
    {
      transactionTypeCode: 'CASHLESS_O1',
      transactionType: 'Premium payment with SCB International Card (Visa/Mastercard)',
      transactionTypeVn: 'Nộp phí bằng thẻ quốc tế SCB'
    },
    {
      transactionTypeCode: 'CASHLESS_O2',
      transactionType: 'Premium payment with SCB Domestic Card (ATM)',
      transactionTypeVn: 'Nộp phí bằng thẻ nội địa SCB'
    },
    {
      transactionTypeCode: 'CASHLESS_O3',
      transactionType: 'Register the recurring premium payment with SCB International Card (Visa/Mastercard)',
      transactionTypeVn: 'Đăng ký thanh toán phí định kỳ bằng thẻ quốc tế SCB'
    },
    {
      transactionTypeCode: 'CASHLESS_DK',
      transactionType: 'Premium Payment by VNPay',
      transactionTypeVn: 'Nộp phí bằng VNPay'
    },
    {
      transactionTypeCode: 'CASHLESS_C9',
      transactionType: 'Premium Payment by Zalo',
      transactionTypeVn: 'Nộp phí bằng Zalo'
    },
    {
      transactionTypeCode: 'CASHLESS_P2',
      transactionType: 'Premium Payment by Shopee',
      transactionTypeVn: 'Nộp phí bằng Shopee'
    },
    {
      transactionTypeCode: 'CASHLESS_V2',
      transactionType: 'Premium Payment by MyVIB',
      transactionTypeVn: 'Nộp phí bằng MyVIB'
    },
    {
      transactionTypeCode: 'CASHLESS_M2',
      transactionType: 'Premium Payment by Momo',
      transactionTypeVn: 'Nộp phí bằng Momo'
    },
    {
      transactionTypeCode: 'CASHLESS_A9',
      transactionType: 'Premium Payment by VNPost',
      transactionTypeVn: 'Nộp phí bằng VNPost'
    },
    {
      transactionTypeCode: 'CASHLESS_S6',
      transactionType: 'Premium Payment by VBA',
      transactionTypeVn: 'Nộp phí bằng VBA'
    },
    {
      transactionTypeCode: 'CASHLESS_L3',
      transactionType: 'Premium Payment by Viettel',
      transactionTypeVn: 'Nộp phí bằng Viettel'
    },
    {
      transactionTypeCode: 'CASHLESS_D7',
      transactionType: 'Premium Payment by POS SeaBank',
      transactionTypeVn: 'Nộp phí bằng POS SeaBank'
    },
    {
      transactionTypeCode: 'CASHLESS_M3',
      transactionType: 'Premium Payment by Momo for agent',
      transactionTypeVn: 'Nộp phí bằng Momo for agent'
    },
    {
      transactionTypeCode: 'CASHLESS_V4',
      transactionType: 'Premium Payment by VIB AutoDebit',
      transactionTypeVn: 'Nộp phí bằng VIB AutoDebit'
    },
    {
      transactionTypeCode: 'CASHLESS_A0',
      transactionType: 'Premium Payment by ShopeePay',
      transactionTypeVn: 'Nộp phí bằng ShopeePay'
    },
    {
      transactionTypeCode: 'CASHLESS_I4',
      transactionType: 'Premium Payment by MSB Bank',
      transactionTypeVn: 'Nộp phí bằng MSB Bank'
    },
    {
      transactionTypeCode: 'CASHLESS_P9',
      transactionType: 'Premium Payment by Payoo',
      transactionTypeVn: 'Nộp phí bằng Payoo'
    },
    {
      transactionTypeCode: 'CASHLESS_I2',
      transactionType: 'Premium Payment by Ebanking Seabank',
      transactionTypeVn: 'Nộp phí bằng Ebanking Seabank'
    },
    {
      transactionTypeCode: 'OTHER_MINOR_ALTERATION',
      transactionType: 'Other Minor Alteration',
      transactionTypeVn: 'Các điều chỉnh hợp đồng khác - Minor'
    },
    {
      transactionTypeCode: 'OTHER_MAJOR_ALTERATION',
      transactionType: 'Other Major Alteration',
      transactionTypeVn: 'Các điều chỉnh hợp đồng khác - Major'
    },
    {
      transactionTypeCode: 'OTHER_PAYOUT',
      transactionType: 'Other Payout',
      transactionTypeVn: 'Các yêu cầu chi trả khác'
    },
    {
      transactionTypeCode: 'CHANGE_NATIONAL_ID_CARD',
      transactionType: 'Change national id card',
      transactionTypeVn: 'Điều chỉnh căn cước công dân'
    },
    {
      transactionTypeCode: 'CHANGE_OTHER_INFORMATION_PERSONAL',
      transactionType: 'Change Other Infomation Personal',
      transactionTypeVn: 'Thay đổi thông tin khác'
    },
    {
      transactionTypeCode: 'CHANGE_DOB_GENDER',
      transactionType: 'Change DOB & Gender',
      transactionTypeVn: 'Điều chỉnh ngày sinh và giới tính'
    },
    {
      transactionTypeCode: 'CHANGE_OCCUPATION_PERSONAL',
      transactionType: 'Change Occupation Personal',
      transactionTypeVn: 'Điều chỉnh nghề nghiệp'
    }
  ]

  const MapData = new Map<string, T0Item>(
    rawData.map(({ transactionTypeCode, transactionType, transactionTypeVn }) => [
      transactionTypeCode,
      { transactionType, transactionTypeVn }
    ])
  )

  const MapDataFromLabelVn = new Map<string, T0Item>(
    rawData.map(({ transactionType, transactionTypeVn }) => [transactionTypeVn, { transactionType, transactionTypeVn }])
  )

  export const getValue = (code: string) => MapData.get(code)

  export const getValueFromLabel = (code: string) => MapDataFromLabelVn.get(code)
}
