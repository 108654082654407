import * as t from 'io-ts'
import { ZIO, Throwable } from '@mxt/zio'
import { pipe } from 'fp-ts/function'
import { PulseOpsFormat } from '../Formatter'
import { POApi } from '../POApi'
import { PartialWithdrawal, FundMapping, ProductEnum, RequestAuthFormDataSubmit } from './model'
import { SubmissionService } from './SubmissionService'
import { Maybe } from '@mxt/zio/codec'
// import { ULPConst } from '@pulseops/submission/common'

export namespace PartialWithdrawalService {
  const wf = 'wf-api'
  const bo = `${wf}/bo`
  const table = `${wf}/table`

  const getAuth = POApi.get
  const postAuth = POApi.post

  const getNumber = (num: string | number) => +num || 0

  export const round = (num: string | number) => Math.floor(getNumber(num))

  export const ResponseStatus = t.type({
    code: Maybe(t.number),
    message: Maybe(t.string),
    errors: Maybe(
      t.array(
        t.type({
          code: Maybe(t.string),
          type: Maybe(t.string),
          message: Maybe(t.string)
        })
      )
    )
  })

  export const UlpEnquiryResponse = t.type({
    responseStatus: ResponseStatus
  })

  export type ResponseStatus = t.TypeOf<typeof ResponseStatus>

  const partialWithdrawalUlpEnquiry = (body: PartialWithdrawal.PWUlpEnquiryReq) =>
    pipe(
      postAuth(`${bo}/partial-withdrawal-ulp-enquiry`)(PartialWithdrawal.PWUlpEnquiryRes)({ body }),
      ZIO.map(({ body }) => ({
        estimatedValue: round(body.estimateTotal),
        minimumPartialWithdrawal: round(body.minWithdraw),
        maximumPartialWithdrawal: round(body.maxWithdraw),
        currentSumAssured: round(body.sumAssured),
        newSumAssured: round(body.newSumAssured),
        partialWithdrawFee: round(body.totalFee),
        coverageRiderDetails: body.coverageRiderDetails
      }))
    )

  const validatePartialWithdrawalUlpEnquiry = (body: PartialWithdrawal.PWUlpEnquiryReq) =>
    pipe(
      POApi.postError()(`${bo}/partial-withdrawal-ulp-enquiry`)(UlpEnquiryResponse)({ body }),
      ZIO.foldM(
        (error) => {
          return ZIO.fail(error)
        },
        (success) => {
          const responseStatus = {
            code: success.responseStatus.code ?? 0,
            message: success.responseStatus.message ?? ''
          }
          return ZIO.succeed(responseStatus)
        }
      )
    )

    export const getfundAll = pipe(
      POApi.get(`banca-sea/general-config/t25s1/get-all`)(
        t.type({
          body: t.array(PartialWithdrawal.getFundAll)
        })
      ),
      ZIO.map((data) => data.body)
    )
  export const partialWithdrawalIlpEnquiry = (body: PartialWithdrawal.PWIlpEnquiryReq) =>
    pipe(
      postAuth(`${bo}/partial-withdrawal-ilp-enquiry`)(PartialWithdrawal.PWIlpEnquiryRes)({ body }),
      ZIO.map(({ body }) => ({
        estimatedValue: round(body.estimateTotal),
        minimumPartialWithdrawal: Number(body.minWithdraw),
        maximumPartialWithdrawal: Number(body.maxWithdraw),
        partialWithdrawFee: round(body.totalFee),
        coverageRiderDetails: body.coverageRiderDetails.map(({ minRemain, estimatedValue, fundCode }) => ({
          minRemain: round(minRemain),
          // estimatedValue: round(estimatedValue),
          estimatedValue: Number(estimatedValue),
          fundCode
        }))
      }))
    )

  export const partialWithdrawalIlpEnquiryErrorCase = (body: PartialWithdrawal.PWIlpEnquiryReq) =>
    pipe(
      POApi.postError()(`${bo}/partial-withdrawal-ilp-enquiry`)(PartialWithdrawal.PWIlpEnquiryResError)({ body }),
      ZIO.foldM(
        (error) => {
          return ZIO.fail(error)
        },
        (data) => {
          return ZIO.succeed(data.body)
        }
      )
    )
  export const validatePWIlpEnquiryErrorCase = (body: PartialWithdrawal.PWIlpEnquiryReq) =>
    pipe(
      POApi.postError()(`${bo}/partial-withdrawal-ilp-enquiry`)(UlpEnquiryResponse)({ body }),
      ZIO.foldM(
        (error) => {
          return ZIO.fail(error)
        },
        (success) => {
          const responseStatus = {
            code: success.responseStatus.code ?? 0,
            message: success.responseStatus.message ?? ''
          }
          return ZIO.succeed(responseStatus)
        }
      )
    )

  const getT5538 = pipe(
    getAuth(`${table}/t5538/get-all`)(PartialWithdrawal.T5538),
    ZIO.map(({ body }) => body)
  )

  const getTU538 = pipe(
    getAuth(`${table}/tu538/get-all`)(PartialWithdrawal.TU538),
    ZIO.map(({ body }) => {
      if (body.length === 1) {
        return body.map((item) => ({
          id: item.id,
          min_1: getNumber(item.min_1),
          min_2: getNumber(item.min_2),
          min_3: getNumber(item.min_3),
          min_4: getNumber(item.min_4),
          min_5: getNumber(item.min_5),
          min_6: getNumber(item.min_6),
          min_7: getNumber(item.min_7),
          min_8: getNumber(item.min_8),
          min_9: getNumber(item.min_9),
          min_10: getNumber(item.min_10),
          max_1: getNumber(item.max_1),
          max_2: getNumber(item.max_2),
          max_3: getNumber(item.max_3),
          max_4: getNumber(item.max_4),
          max_5: getNumber(item.max_5),
          max_6: getNumber(item.max_6),
          max_7: getNumber(item.max_7),
          max_8: getNumber(item.max_8),
          max_9: getNumber(item.max_9),
          max_10: getNumber(item.max_10),
          age_1: getNumber(item.age_1),
          age_2: getNumber(item.age_2),
          age_3: getNumber(item.age_3),
          age_4: getNumber(item.age_4),
          age_5: getNumber(item.age_5),
          age_6: getNumber(item.age_6),
          age_7: getNumber(item.age_7),
          age_8: getNumber(item.age_8),
          age_9: getNumber(item.age_9),
          age_10: getNumber(item.age_10)
        }))[0]
      }
      throw Throwable('PartialWithdrawalService: getTU538 invalid data')
    })
  )

  const checkExistInT5538 = (code: string) =>
    pipe(
      getT5538,
      ZIO.map((T5538) => T5538.map(({ code }) => code).includes(code))
    )

  const getUlpValidateValue = ({
    instalmentPremium,
    frequency,
    productCode,
    policyNum
  }: {
    instalmentPremium: number
    frequency: number
    productCode: string
    policyNum: string
  }) =>
    pipe(
      checkExistInT5538(productCode),
      ZIO.flatMap((existInT5538: boolean) =>
        existInT5538
          ? pipe(
              ZIO.zipPar(getTU538, SubmissionService.getOwnerInfo(policyNum)),
              ZIO.map(([TU538, { body }]) => {
                type KeyTU538 = PartialWithdrawal.KeyTU538
                const age = PulseOpsFormat.getAge(PulseOpsFormat.getStringToDate(body.dob, 'YYYYMMDD'))
                const SA = instalmentPremium * frequency
                let minSAM = TU538.min_8
                let maxSAM = TU538.max_8

                for (let i = 1; i < 9; i++) {
                  if (age <= TU538[`age_${i}` as KeyTU538]) {
                    minSAM = TU538[`min_${i}` as KeyTU538]
                    maxSAM = TU538[`max_${i}` as KeyTU538]
                    break
                  }
                }
                // console.log(TU538, age, minSAM, maxSAM)
                return { existInT5538, minSAM, minSA: SA * minSAM, maxSA: SA * maxSAM }
              })
            )
          : ZIO.succeed({ existInT5538: false, minSAM: Number.NaN, minSA: Number.NaN, maxSA: Number.NaN })
      )
    )

  export const getMaxPW = (policyNum: string) =>
    pipe(
      getDataAccess(policyNum),
      ZIO.flatMap(({ coverageCode, contractStatus }) =>
        coverageCode.startsWith('V') &&
        [ProductEnum.Status.IF, ProductEnum.Status.PU].includes(contractStatus as ProductEnum.Status)
          ? pipe(
              partialWithdrawalUlpEnquiry({ contractNumber: policyNum }),
              ZIO.map(({ maximumPartialWithdrawal }) => maximumPartialWithdrawal)
            )
          : coverageCode.startsWith('U') &&
            [ProductEnum.Status.IF, ProductEnum.Status.PU].includes(contractStatus as ProductEnum.Status)
          ? pipe(
              partialWithdrawalIlpEnquiry({ contractNumber: policyNum }),
              ZIO.map(({ maximumPartialWithdrawal }) => maximumPartialWithdrawal)
            )
          : ZIO.succeed(Number.NaN)
      )
    )

  export const getDetail = (policyNum: string) =>
    pipe(
      SubmissionService.getPolicy(policyNum),
      ZIO.flatMap((policy) => {
        const { body } = policy
        const { attributesExt } = body
        const instalmentPremium =
          body.totalPremium ?? (attributesExt?.basicPremium ?? 0) + (attributesExt?.riderPremium ?? 0)
        const frequency = body.billFreq ? +body.billFreq : 0
        const premiumStatus = body.premiumStatus ?? '-'

        const coverageCode = body.basicCode ?? '-'
        // const coverageCode = PartialWithdrawal.U.LR6

        const productCode = body.productCode ?? '-'
        // const productCode = PartialWithdrawal.U.R2
        // const productCode = PartialWithdrawal.U.R6
        // const productCode = PartialWithdrawal.V.L3
        // const productCode = PartialWithdrawal.V.L5

        const isUlp = productCode.startsWith('V')
        // const isUlp = productCode.startsWith('U')

        const common = {
          policyNum,
          productCode,
          coverageCode,
          instalmentPremium,
          frequency,
          premiumStatus
        }

        return isUlp
          ? pipe(
              ZIO.zipPar(
                getUlpValidateValue({ instalmentPremium, frequency, productCode, policyNum }),
                partialWithdrawalUlpEnquiry({ contractNumber: policyNum })
              ),
              ZIO.map(([validateValue, ulp]) => ({
                ...common,
                ilp: null as any,
                ulp: {
                  ...validateValue,
                  estimatedValue: ulp.estimatedValue,
                  minimumPartialWithdrawal: ulp.minimumPartialWithdrawal,
                  maximumPartialWithdrawal: ulp.maximumPartialWithdrawal,
                  // estimatedValue: 3000000,
                  currentSumAssured: ulp.currentSumAssured,
                  newSumAssured: ulp.newSumAssured,
                  partialWithdrawFee: ulp.partialWithdrawFee,
                  withdrawAmount: 0,
                  fundList: ulp.coverageRiderDetails
                    .filter((x) => !!x.fundCode)
                    .map((item) => {
                      const isEPA = PartialWithdrawal.TopupFundList.find((x) => x.fundCode === item.fundCode)
                      // const isTPA = PartialWithdrawal.TargetFundList.find(x=> x.fundCode === item.fundCode)
                      return {
                        ...item,
                        isEPA: !!isEPA
                      }
                    })
                }
              }))
            )
          : pipe(
              partialWithdrawalIlpEnquiry({ contractNumber: policyNum }),
              ZIO.map((ilp) => ({
                ...common,
                ilp: {
                  estimatedValue: ilp.estimatedValue,
                  minimumPartialWithdrawal: coverageCode !== 'ULR6' ? ilp.minimumPartialWithdrawal : 0,
                  maximumPartialWithdrawal: ilp.maximumPartialWithdrawal,
                  withdrawFee: ilp.partialWithdrawFee,
                  fundList: ilp.coverageRiderDetails
                    .filter((f) => Math.round(f.estimatedValue) > 0 && FundMapping.isValidAcc(f.fundCode))
                    .map((f, i) => ({
                      fundCode: f.fundCode,
                      // fundCode: i === 0 ? `UBL1` : i === 1 ? `UEQ2` : `UFI1`,
                      minPWRemain: f.minRemain,
                      // fundValue: Math.round(+f.estimatedValue),
                      fundValue: f.estimatedValue,
                      withdrawalAmount: 0,
                      errorMessage: ''
                    }))
                },
                ulp: null as any
              }))
            )
      })
    )

  export const getDataAccess = (policyNum: string) =>
    pipe(
      SubmissionService.getPolicy(policyNum),
      ZIO.map((policy) => ({
        coverageCode: policy.body.basicCode ?? '',
        contractStatus: policy.body.status ?? ''
      }))
    )

  export const checkAccessData = (policyNum: string) =>
    pipe(
      getDataAccess(policyNum),
      ZIO.map((policyInfo) => {
        let status = false,
          message = ''
        if (policyInfo.coverageCode === 'IPD1') {
          message = 'MS050280'
        } else if (!['V', 'U'].some((code) => policyInfo.coverageCode.startsWith(code))) {
          message = 'MS050168'
        } else if (policyInfo.contractStatus !== ProductEnum.Status.IF) {
          message = 'MS050034'
        } else {
          status = true
        }
        return { status, message }
      })
    )

  export const getTopUpFundAmount = (policyNum: string, fundItem: { fundEPA: string; fundTPA: string }) =>
    pipe(
      partialWithdrawalUlpEnquiry({
        contractNumber: policyNum,
        details: [{ fundCode: PartialWithdrawal.Fund.EPA }, { fundCode: PartialWithdrawal.Fund.TPA }]
        // details: [{ fundCode: fundItem.fundEPA }, { fundCode: fundItem.fundTPA }]
      }),
      ZIO.map(({ coverageRiderDetails }) => {
        const topUpFundAmount = round(
          coverageRiderDetails
            .filter(({ fundCode }) => fundCode === fundItem.fundEPA)
            .reduce((acc, { estimatedValue }) => acc + getNumber(estimatedValue), 0)
        )
        return {
          topUpFundAmount,
          fundItem
        }
      })
    )

  export const getUlpValue1 = (policyNum: string, actualValue: number, fundItem: string) => {
    return pipe(
      partialWithdrawalUlpEnquiry({
        contractNumber: policyNum,
        details: [
          {
            coverage: '01',
            // fund: PartialWithdrawal.Fund.EPA,
            fundCode: fundItem,
            amount: actualValue
          }
        ]
      }),
      ZIO.map(({ newSumAssured, partialWithdrawFee }) => ({ newSumAssured, partialWithdrawFee }))
    )
  }

  export const getUlpValue2 = (
    policyNum: string,
    topUpFundAmount: number,
    requiredAmount: number,
    fundEPA: string,
    fundTPA: string
  ) => {
    let fundList: Array<{ coverage: string; fundCode: string; amount: number }> = []
    !!fundEPA && fundList.push({ coverage: '01', fundCode: fundEPA, amount: topUpFundAmount })
    !!fundTPA && fundList.push({ coverage: '01', fundCode: fundTPA, amount: requiredAmount - topUpFundAmount })
    return pipe(
      partialWithdrawalUlpEnquiry({
        contractNumber: policyNum,
        details: fundList
      }),
      ZIO.map(({ newSumAssured, partialWithdrawFee }) => ({
        newSumAssured,
        partialWithdrawFee
      }))
    )
  }

  export const getIlpValue3 = (policyNum: string, actualValue: number, fundCode: string) =>
    pipe(
      // partialWithdrawalIlpEnquiry({
      //   contractNumber: policyNum,
      //   details: [{ coRD: '01', fund: PartialWithdrawal.Fund.TPA, actualValue }]
      // }),
      partialWithdrawalUlpEnquiry({
        contractNumber: policyNum,
        details: [
          {
            coverage: '01',
            // fund: PartialWithdrawal.Fund.TPA,
            fundCode: fundCode,
            amount: actualValue
          }
        ]
      }),
      ZIO.map(({ newSumAssured, partialWithdrawFee }) => ({ newSumAssured, partialWithdrawFee }))
    )

  export const validateUlpValue1 = (policyNum: string, actualValue: number, fundItem: string) => {
    return pipe(
      validatePartialWithdrawalUlpEnquiry({
        contractNumber: policyNum,
        details: [
          {
            coverage: '01',
            // fund: PartialWithdrawal.Fund.EPA,
            fundCode: fundItem,
            amount: actualValue
          }
        ]
      })
    )
  }

  export const validateUlpValue2 = (
    policyNum: string,
    topUpFundAmount: number,
    requiredAmount: number,
    fundEPA: string,
    fundTPA: string
  ) => {
    let fundList: Array<{ coverage: string; fundCode: string; amount: number }> = []
    !!fundEPA && fundList.push({ coverage: '01', fundCode: fundEPA, amount: topUpFundAmount })
    !!fundTPA && fundList.push({ coverage: '01', fundCode: fundTPA, amount: requiredAmount - topUpFundAmount })
    return pipe(
      validatePartialWithdrawalUlpEnquiry({
        contractNumber: policyNum,
        details: fundList
      }),
      ZIO.map((response) => {
        return response
      })
    )
  }

  export const validateIlpValue3 = (policyNum: string, actualValue: number, fundCode: string) =>
    pipe(
      validatePartialWithdrawalUlpEnquiry({
        contractNumber: policyNum,
        details: [
          {
            coverage: '01',
            // fund: PartialWithdrawal.Fund.TPA,
            fundCode: fundCode,
            amount: actualValue
          }
        ]
      }),
      ZIO.map((response) => {
        return response
      })
    )

  export const getNewSumAssuredValidation = (policyNo: string, newSumAssured: number) => {
    return pipe(
      postAuth('wf-api/policy/validate-partial-withdraw')(
        t.type({
          body: t.type({
            messageCode: t.string,
            minSA: t.number,
            isValid: t.boolean
          })
        })
      )({
        body: {
          policyNo: policyNo,
          newSumAssured: newSumAssured
        }
      }),
      ZIO.map((responseData) => {
        return responseData.body
      })
    )
  }

  export const submit = (body: PartialWithdrawal.SubmitData) => (requestauth: RequestAuthFormDataSubmit) =>
    SubmissionService.submit(t.unknown)(
      `wf-api/policy/${body.policy.policyNo}/partial-withdrawal`,
      { body },
      body.policy.policyNo,
      requestauth,
      []
    )
}
