import React from 'react'
import { pipe } from 'fp-ts/lib/function'
import { Throwable, ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import {
  Container,
  ErrorHandling,
  FieldList,
  Panel,
  useTranslator,
  TableField,
  AppContext,
  ModalComponent,
  useMobile,
  Input,
  AuthService,
  GeneralService
} from '@pulseops/common'
import { CepPolicyDetailServices } from '../CEPPolicyDetailServices'
import { CepTableField, CepFieldList } from '../cep-components'
import { useIsFocused, useNavigation, useRoute, RouteProp } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { InquiryStackParamList } from '../../InquiryStackParamList'
import { TouchableOpacity, View, Text, StyleSheet, TextStyle } from 'react-native'
import _ from 'lodash'
import { CustomerInfoData } from './CepCustomerScreen'
import { getCustomerInfo } from '@pulseops/inquiry'

export interface PruOnlineInfoData {
  contact: FieldList.DataSources
}

export enum EStatusAccount {
  O = 'O',
  C = 'C'
}

export const CepPruOnlinecreen = (props: {
  id: string
  data: PruOnlineInfoData | null
  setData: (val: PruOnlineInfoData | null) => void
  isGA?: boolean
}) => {
  const { t } = useTranslator('TaskManagement')
  const cep = useTranslator('CustomerEngagement').t
  const [loading, bindLoading] = useLoading()

  const isFocused = useIsFocused()
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)
  const { navigate } = useNavigation()
  const { isWide } = useMobile()
  const navigation = useNavigation<StackNavigationProp<InquiryStackParamList, 'InquiryScreen'>>()
  const route = useRoute<RouteProp<InquiryStackParamList, 'CEPPolicyDetailScreen'>>()


  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => navigate('HomeScreen')
        },
        {
          title: route?.params?.isSubmission === 'true' ? t('Submission:EForm') : t('menu:360Inquiry'),
          navigate: () =>
            route?.params?.isSubmission === 'true'
              ? navigation.replace('UserDrawer', {
                  screen: 'StaffSubmissionStack'
                })
              : navigation.navigate('CEPSearchScreen')
        },
        {
          title: t('PruOnline'),
          navigate: null
        }
      ])
    }
  }, [isFocused])

  const getData = (): ZIO<unknown, Throwable, PruOnlineInfoData> =>
  pipe(
    getCustomerInfo(props.id),
    ZIO.map((res) => {
      const { contact } = res.data ?? {}
      const phoneContact =  contact?.contactChannels?.find((x) => x.channel === 'Phone')
      const emailContact =  contact?.contactChannels?.find((x) => x.channel === 'Email')
      if(props.isGA){
        return{
          contact: [
            {
              label: cep('AccountStatus'),
              value: contact && contact.accountStatus ? getStatusAccount(contact.accountStatus) : cep('NoAccount'),
              colLength: '33%'
            },
            {
              label: cep('PolicyAdjustment'),
              value: contact && contact.signature === '1' ? cep("Unlock") : cep("Locked"),
              colLength: '33%'
            },
            {
              label: cep('MobileOTP'),
              value: phoneContact?.contactValue ?? '-',
              colLength: '33%'
            },
            {
              label: cep('EmailOTP'),
              value: emailContact?.contactValue ?? '-',
              colLength: '33%'
            }
          ]
        }
      }
      return {
        contact: [
          {
            label: cep('AccountName'),
            value: contact && contact.pruOnlineUserName ? contact.pruOnlineUserName : '-',
            colLength: '33%'
          },
          {
            label: cep('AccountStatus'),
            value: contact && contact.accountStatus ? getStatusAccount(contact.accountStatus) : cep('NoAccount'),
            colLength: '33%'
          },
          {
            label: cep('PolicyAdjustment'),
            value: contact && contact.signature === '1' ? cep("Unlock") : cep("Locked"),
            colLength: '33%'
          },
          {
            label: cep('MobileOTP'),
            value: phoneContact?.contactValue ?? '-',
            colLength: '33%'
          },
          {
            label: cep('EmailOTP'),
            value: emailContact?.contactValue ?? '-',
            colLength: '33%'
          }
        ]
      }
    })
  )
  const { contact } = pipe(
    props.data ? ZIO.succeed(props.data) : getData(),
    ZIO.tap((x) => {
      if (x !== null && props.data === null) props.setData(x)
      return ZIO.unit
    }),
    ZIO.map((res) => {
      return {
        contact: res.contact
      }
    }),
    bindLoading,
    ErrorHandling.runDidUpdate([])
  ) || {
    contact: []
  }

  const getStatusAccount = (status: string) => {
    switch (status) {
      case EStatusAccount.O:
        return cep('Open')
      case EStatusAccount.C:
        return cep('Cancel')
      default:
        return cep('NoAccount')
    }
  }


  return (
    <Container loading={loading}>
      <Panel title={cep('GENERAL INFO')}>
        <CepFieldList dataSource={contact} />
      </Panel>
    </Container>
  )
}
const styles = StyleSheet.create({
  sectionRow: {
    flex: 1,
    flexWrap: 'wrap',
    flexDirection: 'row'
  },
  col_45: {
    width: '100%',
    maxWidth: '48%'
  }
})
