import React, { useEffect, useState } from 'react'
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  IconButton
} from '@material-ui/core'
import { useLoading } from '@mxt/zio-react'
import { useTranslation } from 'react-i18next'
import { ActivityIndicator, StyleSheet, View, TouchableOpacity, Text, TextInput, ScrollView } from 'react-native'
import { uniqueId } from 'lodash'
import { ErrorHandling, Alert, RBAC, AppContext, assets } from '@pulseops/common'
import { pipe } from 'fp-ts/function'
import { useIsFocused } from '@react-navigation/native'
import FilterBox from './FilterBox'

const useStyles = makeStyles({
  container: {
    border: `1px solid #D3DCE6`,
    borderWidth: 1,
    borderRadius: 8
    // maxHeight: ''
  },
  table: {},
  tableHeader: {
    backgroundColor: '#E7E7E7'
  },
  tableBody: {
    backgroundColor: '#FFFFFF'
  },
  tableCellHeader: {
    backgroundColor: '#F4F4F4',
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
    color: '#70777E'
  },
  tableCell: {
    whiteSpace: 'nowrap'
  }
})

const dummyData = [{ id: '1' }, { id: '2' }, { id: '3' }, { id: '4' }]

interface IPagination {
  page: number
  pageSize: number
  totalObject: number
  totalPage: number
}

const ConfigTableScreen: React.FC = ({ navigation }: any) => {
  const { t } = useTranslation()
  const [isLoading, bindLoading] = useLoading(false)
  const isFocused = useIsFocused()
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)

  const [dataList, setDataList] = useState(dummyData)
  const [{ page, pageSize, totalObject }, setPagination] = useState<IPagination>({
    page: 0,
    pageSize: 10,
    totalObject: 10,
    totalPage: 1
  })

  const classes = useStyles()

  const roles: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([]))

  useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigation.navigate('HomeScreen')
          }
        },
        { title: t('Setting:ConfigTable'), navigate: null }
      ])
    }
  }, [isFocused])

  const handleChangeFilter = (data: any) => {
    console.log('filter data', data)
  }

  const handleAddNewRow = () => {
    const newList = dataList.concat([{ id: uniqueId('uid-') }])
    setDataList(newList)
  }

  const handlePressDeleteRow = (id: string) => {
    Alert.modal({
      title: t('warning'),
      content: t('warningDeleteLine'),
      size: { width: '30%' },
      onOK: () => handleDeleteRow(id)
    })
  }

  const handleDeleteRow = (id: string) => {
    const newList = dataList.filter((item) => item.id !== id)
    setDataList(newList)
  }

  const renderTableHeader = () => {
    return (
      <TableRow>
        <TableCell className={classes.tableCellHeader}>{t('Category')}</TableCell>
        <TableCell className={classes.tableCellHeader}>{t('TransactionType')}</TableCell>
        <TableCell className={classes.tableCellHeader}>{t('TransactionCode')}</TableCell>
        <TableCell className={classes.tableCellHeader}>{t('Group')}</TableCell>
        <TableCell className={classes.tableCellHeader}>{t('GroupCode')}</TableCell>
        <TableCell className={classes.tableCellHeader}>{t('SubServiceType')}</TableCell>
        <TableCell className={classes.tableCellHeader}>{t('SubServiceCode')}</TableCell>
        <TableCell className={classes.tableCellHeader}>{t('SubClaimType')}</TableCell>
        <TableCell className={classes.tableCellHeader}>{t('Assignee')}</TableCell>
        <TableCell className={classes.tableCellHeader}>{t('UserGroup')}</TableCell>
        <TableCell className={classes.tableCellHeader}>{t('UserLevel')}</TableCell>
        <TableCell className={classes.tableCellHeader}>{t('ProcessDecision')}</TableCell>
        <TableCell className={classes.tableCellHeader}>{t('ClaimDecision')}</TableCell>
        <TableCell className={classes.tableCellHeader}>{t('Sampling')}</TableCell>
        <TableCell className={classes.tableCellHeader}>{t('QCCount')}</TableCell>
        <TableCell className={classes.tableCellHeader}>{t('Action')}</TableCell>
      </TableRow>
    )
  }

  const renderTableBody = () => {
    if (isLoading)
      return (
        <TableRow>
          <TableCell style={{ textAlign: 'center' }} colSpan={12}>
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
              <ActivityIndicator size="large" color="red" />
            </View>
          </TableCell>
        </TableRow>
      )

    if (!dataList.length) {
      return (
        <TableRow>
          <TableCell style={{ textAlign: 'center' }} colSpan={12}>
            {t('common:Nodata')}
          </TableCell>
        </TableRow>
      )
    }

    return dataList.map(({ id }, index) => (
      <TableRow key={index}>
        <TableCell className={classes.tableCell}>Category</TableCell>
        <TableCell className={classes.tableCell}>TransactionType {id}</TableCell>
        <TableCell className={classes.tableCell}>TransactionCode</TableCell>
        <TableCell className={classes.tableCell}>Group</TableCell>
        <TableCell className={classes.tableCell}>GroupCode</TableCell>
        <TableCell className={classes.tableCell}>Sub Service Type</TableCell>
        <TableCell className={classes.tableCell}>Sub Service Code</TableCell>
        <TableCell className={classes.tableCell}>Sub Claim Type</TableCell>
        <TableCell className={classes.tableCell}>Assignee</TableCell>
        <TableCell className={classes.tableCell}>UserGroup</TableCell>
        <TableCell className={classes.tableCell}>UserLevel</TableCell>
        <TableCell className={classes.tableCell}>ProcessDecision</TableCell>
        <TableCell className={classes.tableCell}>Claim Decision</TableCell>
        <TableCell className={classes.tableCell}>
          <InputTextCustom />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <InputTextCustom />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <TouchableOpacity onPress={() => handlePressDeleteRow(id)}>
            <Text style={styles.delBtn}>{t('Delete')}</Text>
          </TouchableOpacity>
        </TableCell>
      </TableRow>
    ))
  }

  return (
    <ScrollView style={styles.container}>
      <View style={styles.headerContainer}>
        <View style={styles.filterBox}>
          <FilterBox onChange={handleChangeFilter} />
        </View>
        <View style={styles.actionBox}>
          <TouchableOpacity onPress={handleAddNewRow}>
            <Text
              style={[
                styles.btn,
                {
                  backgroundColor: '#fff',
                  borderColor: '#ed1b2e',
                  color: '#ed1b2e',
                  display: 'flex',
                  alignItems: 'center',
                  paddingTop: 5,
                  paddingBottom: 5
                }
              ]}
            >
              <assets.PlusCircleIcon fontSize={10} /> {t('AddRow')}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity>
            <Text style={styles.btn}>{t('Save')}</Text>
          </TouchableOpacity>
        </View>
      </View>

      <TableContainer component={Paper} className={classes.container} elevation={1}>
        <Table className={classes.table} stickyHeader>
          <TableHead className={classes.tableHeader}>{renderTableHeader()}</TableHead>
          <TableBody className={classes.tableBody}>{renderTableBody()}</TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        page={page}
        rowsPerPage={pageSize}
        count={totalObject}
        onPageChange={(e, page) => {
          //
        }}
        onRowsPerPageChange={(e) => {
          //
        }}
        labelRowsPerPage={t('common:PaginationSize')}
        labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
        component={View}
      />
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 20
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
    marginBottom: 20,
    alignItems: 'center'
  },
  filterBox: {},
  actionBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginBottom: 20,
    marginTop: 20
  },
  btn: {
    backgroundColor: '#ed1b2e',
    color: '#fff',
    padding: 10,
    borderRadius: 6,
    marginLeft: 10,
    minWidth: 80,
    textAlign: 'center',
    fontWeight: '600'
  },
  bgBlue: {
    backgroundColor: '#296ca3'
  },
  textInput: {
    maxWidth: 100,
    padding: 10,
    border: '1px solid #ccc',
    textAlign: 'right',
    borderRadius: 6
  },
  delBtn: {
    padding: 4,
    borderRadius: 4,
    backgroundColor: '#ed1b2e',
    color: '#fff',
    cursor: 'pointer',
    textAlign: 'center',
    fontSize: 13
  },
  delBtnDisabled: {
    opacity: 0.6,
    cursor: 'default'
  }
})

export default ConfigTableScreen

const InputTextCustom = () => {
  const [value, setValue] = useState<number | undefined>(0)

  const handleChangeInput = (val: string) => {
    const numberRegex = /^\d+$/
    numberRegex.test(val) ? setValue(+val) : setValue(0)
  }

  return (
    <TextInput style={styles.textInput} selectTextOnFocus value={value?.toString()} onChangeText={handleChangeInput} />
  )
}
