import { makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { ZIO } from '@mxt/zio'
import {
  assets,
  AuthService,
  Checkbox,
  /*DatePicker,*/ DatePickerTable,
  ErrorHandling,
  FieldList,
  GeneralService,
  Input,
  Panel,
  RoundedButton,
  SelectOption,
  SelectSearch,
  TaskDetail,
  useMobile,
  AppContext,
  formatNumberWithComma
} from '@pulseops/common'
import { compareOnlyDate } from '@pulseops/submission/common'
import { TaskDetailStackParamList } from '@pulseops/task'
import { StackNavigationProp } from '@react-navigation/stack'
import { pipe } from 'fp-ts/lib/function'
import _ from 'lodash'
import moment from 'moment'
import * as React from 'react'
import { Controller, useFieldArray, useForm, UseFormReturn, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Platform, SafeAreaView, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native'
import { HeaderTable, InputTable } from '../../common'
import { Evaluation } from './Adjudication'
import { EvaluationForm, FinalDecisionForm, BenefitClaimDetail } from './AdjudicationForm'
import { EvaluationModal } from './EvaluationModal'
import { EvaluationModalView } from './EvaluationModalView'
import * as D from 'date-fns'

interface Props {
  props: {
    navigation: StackNavigationProp<TaskDetailStackParamList, 'TaskDetailScreen'>
    faceAmount: string
    payload: TaskDetail.VerificationClaim
    adjuDecisionOptions: {
      value: string
      label: string
    }[]
    declinesOptions: {
      value: string
      label: string
    }[]
  }
  form: UseFormReturn<FinalDecisionForm>
  disabled?: boolean
  setEvaluationList: (evaluation: Evaluation[]) => void
  evaluations: Evaluation[]
  setIsCanChangeTab: (isCanChangeTab: boolean) => void
}

type EvaluationType = {
  evaluationEN: string
  evaluationVN: string
  evaluationCode: string
}

const useStyles = makeStyles({
  tableHeader: {
    backgroundColor: '#F4F4F4',
    borderColor: '#D3DCE6'
  },
  tableBody: {
    backgroundColor: '#FAFAFA'
  },
  tableStyle: {
    width: 'max-content',
    overflowX: 'auto'
  },
  tableHeaderText: {
    fontWeight: 'bold',
    color: '#70777E'
  }
})

export const FinalDecision: React.FC<Props> = ({
  props,
  form,
  disabled,
  setEvaluationList,
  evaluations,
  setIsCanChangeTab
}) => {
  const { t, i18n } = useTranslation(['Adjudication', 'claim'])
  const { isWide } = useMobile()
  const classes = useStyles()
  const { faceAmount, payload, adjuDecisionOptions, declinesOptions } = props
  const { claimNumber } = payload
  const [isOpenEvaluationModal, setIsOpenEvaluationModal] = React.useState<boolean>(false)
  const [isOpenEvaluationViewModal, setIsOpenEvaluationViewModal] = React.useState<boolean>(false)
  const [recissions, setRecissions] = React.useState<SelectOption[]>([])
  // const [declines, setDeclines] = React.useState<SelectOption[]>([])
  const { showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const [evaluationTypes, setEvaluationTypes] = React.useState<EvaluationType[]>([])
  const [contentView, setContentView] = React.useState<string>('')
  const [isDisabledClosedReInsurance, setIsDisabledClosedReInsurance] = React.useState<boolean>(true)

  const [userInfo, setUserInfo] = React.useState<{ email: string; name: string }>({
    email: '',
    name: ''
  })

  const evaluationForm = useForm<EvaluationForm>({ mode: 'all' })

  const policyStatus = (): string => {
    let result = '-'
    if (payload?.policyRider.status === 'INFORCE') {
      result = i18n.language === 'en' ? 'Inforce' : 'Thực thi'
    }
    return result
  }

  const productName = (): string => {
    let result = '-'
    const rider = payload?.policyRider?.productOptions.map((item) => item.productMainCode + ' ' + item.productMainName)
    if (rider.length > 0) {
      result = rider[0]
    }
    return result
  }

  const policyInfoList = [
    {
      label: t('claim:PolicyNumber'),
      value: payload?.policyRider?.policyNo || '-'
    },
    {
      label: t('claim:EffectiveDate'),
      value: payload?.policyRider?.effectiveDate
        ? moment(payload?.policyRider?.effectiveDate).format('DD/MM/YYYY')
        : '-'
    },
    {
      label: t('claim:PolicyStatus'),
      value: t(`claim:${payload?.policyRider.status}`),
      isHighlightWarning: payload?.policyRider.status !== 'INFORCE',
      bold: true
    },
    {
      label: t('claim:ProductName'),
      value: productName()
    },
    {
      label: t('claim:ClaimNumber1'),
      value: payload.claimNumber
    }
  ]

  const { control, watch } = form

  const decisionTableForm = useFieldArray<FinalDecisionForm>({
    control,
    name: 'decisionDetail'
  })

  const benefitDetailForm = useFieldArray<FinalDecisionForm>({
    control,
    name: 'benefitDetail'
  })

  const decisionDetail = useWatch({
    control,
    name: 'decisionDetail'
  })

  const benefitDetail = useWatch({
    control,
    name: 'benefitDetail'
  })

  pipe(
    GeneralService.getDecision('s4'),
    ZIO.map((recissionList) => {
      setRecissions(
        recissionList?.map(
          (recision) =>
            ({
              value: recision.code,
              label: i18n.language === 'vi' ? recision?.nameVi : recision?.nameEn
            } || [])
        )
      )
    }),
    ErrorHandling.runDidMount()
  )

  pipe(
    AuthService.userInfo,
    ZIO.map((user) => {
      setUserInfo(user)
    }),
    ErrorHandling.runDidUpdate([])
  )

  pipe(
    GeneralService.getEvaluationData(),
    ZIO.map((evaluavationList) => {
      setEvaluationTypes(evaluavationList || [])
    }),
    ErrorHandling.runDidMount()
  )

  React.useEffect(() => {
    if (form.watch('transferToReInsurance')) {
      setIsDisabledClosedReInsurance(false)
    }
  }, [form.watch('transferToReInsurance')])

  const columns = [
    {
      key: '1',
      title: t('claim:EvaluationType'),
      name: 'evaluationType'
    },
    {
      key: '2',
      title: t('claim:Subject'),
      name: 'subject'
    },
    {
      key: '3',
      title: t('claim:CreatedDate'),
      name: 'createdDate'
    },
    {
      key: '4',
      title: t('claim:CreatedBy'),
      name: 'createdBy'
    },
    {
      key: '5',
      title: t('claim:Description'),
      name: 'description'
    }
  ]

  const compareItemsByCreateDate = (item1: string, item2: string) => {
    const temp1 = moment(item1).valueOf()
    const temp2 = moment(item2).valueOf()
    return temp1 > temp2 ? -1 : 1
  }

  const formatDiaryDate = (createdDate: string) => {
    return createdDate ? moment(new Date(createdDate)).format('DD/MM/YYYY HH:mm') : ''
  }

  const showEvaluation = (code: string): string => {
    let evaluationName = ''
    if (evaluationTypes.length > 0) {
      evaluationTypes.forEach((evaluation) => {
        if (evaluation.evaluationCode === code) {
          evaluationName = i18n.language === 'vi' ? evaluation?.evaluationVN : evaluation?.evaluationEN
        }
      })
    }

    return evaluationName
  }

  const onShowModalAddEvaluation = () => {
    evaluationForm.reset()
    setIsOpenEvaluationModal(true)
  }

  const showContentView = (item: any) => {
    setContentView(item.description)
    setIsOpenEvaluationViewModal(true)
  }

  const onChangeReInsurance = (e: boolean) => {
    setIsDisabledClosedReInsurance(!e)
    setIsCanChangeTab(false)
    if (!e && form.watch('closeReInsurance')) {
      form.setValue('closeReInsurance', false)
    }
  }

  const onSubmit = (values: EvaluationForm) => {
    const evaluationList = [...evaluations]
    evaluationList.push({
      ...values,
      evaluationCode: values.evaluationType?.value || '',
      evaluationType: values.evaluationType?.label || '',
      createdBy: userInfo.name,
      createdDate: moment().format()
    })
    setEvaluationList(evaluationList)
    setIsOpenEvaluationModal(false)
    evaluationForm.reset()
    setIsCanChangeTab(false)
  }

  const adjuDecisionListOptions = React.useMemo(() => {
    return adjuDecisionOptions.filter((item) => item.value !== 'DS05')
  }, [])

  const declinesOptionsMemo = React.useMemo(() => {
    return declinesOptions.sort(function (a, b) {
      if (a.label < b.label) {
        return -1
      }
      if (a.label > b.label) {
        return 1
      }
      return 0
    })
  }, [declinesOptions])

  return (
    <SafeAreaView>
      <Panel title={''} titleOutline={t('Adjudication:FaceAmount')} isExand={true}>
        <View style={style.row}>
          <View style={style.col}>
            <Text style={style.label}>{t('Adjudication:FaceAmount')}</Text>
            <Text style={[style.value, { color: '#ED1B2E', fontWeight: '700' }]}>{faceAmount} VND</Text>
          </View>
          <View style={style.col}>
            <Controller
              name="transferToReInsurance"
              control={control}
              defaultValue={false}
              render={({ field: { onChange, onBlur, value } }) => (
                <Checkbox
                  title={t('Adjudication:TransferToReInsurance') + '?'}
                  value={value}
                  onBlur={onBlur}
                  onChange={(e) => {
                    onChange(e)
                    onChangeReInsurance(e)
                  }}
                />
              )}
            />
          </View>
          <View style={style.col}>
            <Controller
              name="closeReInsurance"
              control={control}
              defaultValue={false}
              render={({ field: { onChange, onBlur, value } }) => (
                <Checkbox
                  title={t('Adjudication:CloseReInsurance')}
                  value={value}
                  onBlur={onBlur}
                  onChange={(e) => {
                    onChange(e)
                    onChangeReInsurance(e)
                  }}
                  disabled={isDisabledClosedReInsurance}
                />
              )}
            />
          </View>
        </View>
      </Panel>
      <Panel title={`${t('Adjudication:PolicyInformation')} - ${payload?.policyRider?.policyNo || '-'}`} isExand={true}>
        <FieldList dataSource={policyInfoList} />
        {/* <View style={style.row}>
          <View style={style.col}>
            <Text style={style.label}>{t('claim:PolicyStatusOnEventDate')}</Text>
            <Input value={policyStatus()} disabled />
          </View>
        </View> */}
        {isWide && Platform.OS === 'web' && (
          <View>
            <Text style={[style.sectionTitle, { paddingBottom: 10 }]}>{t('claim:DecisionDetail')}</Text>
            <View style={{ marginBottom: 30 }}>
              <TableContainer component={Paper}>
                <Table className={classes.tableStyle}>
                  <TableHead className={classes.tableHeader}>
                    <TableRow>
                      <TableCell className={classes.tableHeaderText}>{t('Adjudication:LifeNumber')}</TableCell>
                      <TableCell className={classes.tableHeaderText}>{t('Adjudication:Product')}</TableCell>
                      <TableCell className={classes.tableHeaderText}>{t('Adjudication:EffectiveDate')}</TableCell>
                      <TableCell className={classes.tableHeaderText}>{t('Adjudication:Status')}</TableCell>
                      <TableCell className={classes.tableHeaderText}>
                        {t('Adjudication:PolicyStatusOnEventDate')}
                      </TableCell>
                      <TableCell className={classes.tableHeaderText}>{t('Adjudication:Rescission')}</TableCell>
                      <TableCell className={classes.tableHeaderText}>{t('Adjudication:RescissionDate')}</TableCell>
                      <TableCell className={classes.tableHeaderText}>{t('Adjudication:RefundFrom')}</TableCell>
                      <TableCell className={classes.tableHeaderText}>{t('Adjudication:RefundTo')}</TableCell>
                      <TableCell className={classes.tableHeaderText}>{t('Adjudication:WOP')}</TableCell>
                      <TableCell className={classes.tableHeaderText}>{t('Adjudication:WOPFrom')}</TableCell>
                      <TableCell className={classes.tableHeaderText}>{t('Adjudication:WOPTo')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className={classes.tableBody}>
                    {decisionTableForm.fields.map((row, rIndex) => {
                      // const checkDirty = form.formState.dirtyFields.decisionDetail
                      // const dirty = checkDirty ? !checkDirty[rIndex] : false
                      return (
                        <TableRow key={row.id}>
                          <TableCell component="th" scope="row">
                            {decisionDetail[rIndex].lifeAssuredNumber}
                          </TableCell>
                          {/* ${form.watch(`decisionDetail.${rIndex}.componentCode`)} -  */}
                          <TableCell>{`${
                            i18n.language === 'vi'
                              ? form.watch(`decisionDetail.${rIndex}.componentVN`)
                              : form.watch(`decisionDetail.${rIndex}.componentEN`)
                          }`}</TableCell>
                          <TableCell>
                            {decisionDetail[rIndex].effectiveDate
                              ? moment(decisionDetail[rIndex].effectiveDate).format('DD/MM/YYYY')
                              : '-'}
                          </TableCell>
                          <TableCell>{decisionDetail[rIndex].productStatus}</TableCell>
                          <TableCell>{decisionDetail[rIndex].policyStatusOnEventdate}</TableCell>
                          <TableCell>
                            <Controller
                              name={`decisionDetail.${rIndex}.recission` as 'decisionDetail.0.recission'}
                              control={control}
                              rules={{
                                required: {
                                  value: true,
                                  message: `${t('message:MS020009', { field: t('Adjudication:Rescission') })}`
                                }
                              }}
                              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                <SelectSearch
                                  popupIcon={<assets.ArrowDownDropdownIcon />}
                                  disableUnderline={true}
                                  inputStyle={[style.selectStyle, { minWidth: 150 }]}
                                  errorMessage={error?.message}
                                  onChange={(e) => {
                                    onChange(e)
                                    setIsCanChangeTab(false)
                                  }}
                                  onBlur={onBlur}
                                  value={value}
                                  options={recissions || []}
                                  placeholder={t('common:Select')}
                                  key={value?.value}
                                />
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            <Controller
                              name={`decisionDetail.${rIndex}.recissionDate` as 'decisionDetail.0.recissionDate'}
                              control={control}
                              // rules={{required:`${t('message:MS020001', { field: t('Adjudication:RescissionDate') })}`}}
                              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                <DatePickerTable
                                  value={value}
                                  // disabled={
                                  //   disabled || form.watch(`decisionDetail.${rIndex}.recission`)?.value !== 'RC03'
                                  // }
                                  onChange={(e) => {
                                    onChange(e)
                                    setIsCanChangeTab(false)
                                  }}
                                  onBlur={onBlur}
                                  styles={{ backgroundColor: '#FFF', padding: 13 }}
                                  errorMessage={error?.message}
                                />
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            <Controller
                              name={`decisionDetail.${rIndex}.refundFrom` as 'decisionDetail.0.refundFrom'}
                              control={control}
                              rules={{
                                required: {
                                  value: form.watch(`decisionDetail.${rIndex}.recission`)?.value === 'RC03',
                                  message: `${t('message:MS020001', { field: t('Adjudication:RefundFrom') })}`
                                },
                                validate: (refundFrom) => {
                                  const refundTo = form.watch(`decisionDetail.${rIndex}.refundTo`)
                                  const compareDate =
                                    !!refundTo && !!refundFrom && compareOnlyDate(refundTo, refundFrom)
                                  const message = t('message:MS050013', { field: t('Adjudication:RefundFrom') })
                                  if (compareDate) return message
                                  return true
                                }
                              }}
                              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                <DatePickerTable
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e)
                                    setIsCanChangeTab(false)
                                  }}
                                  onBlur={onBlur}
                                  disabled={form.watch(`decisionDetail.${rIndex}.recission`)?.value !== 'RC03'}
                                  styles={{ backgroundColor: '#FFF', padding: 13 }}
                                  errorMessage={error?.message}
                                />
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            <Controller
                              name={`decisionDetail.${rIndex}.refundTo` as 'decisionDetail.0.refundTo'}
                              rules={{
                                required: {
                                  value: form.watch(`decisionDetail.${rIndex}.recission`)?.value === 'RC03',
                                  message: `${t('message:MS020001', { field: t('Adjudication:RefundTo') })}`
                                },
                                validate: (refundTo) => {
                                  const refundFrom = form.watch(`decisionDetail.${rIndex}.refundFrom`)
                                  const compareDate =
                                    !!refundFrom && !!refundTo && !compareOnlyDate(refundFrom, refundTo)
                                  const message = t('message:MS050013', { field: t('Adjudication:RefundTo') })
                                  if (compareDate) return message
                                  return true
                                }
                              }}
                              control={control}
                              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                <DatePickerTable
                                  value={value}
                                  disabled={form.watch(`decisionDetail.${rIndex}.recission`)?.value !== 'RC03'}
                                  onChange={(e) => {
                                    onChange(e)
                                    setIsCanChangeTab(false)
                                  }}
                                  onBlur={onBlur}
                                  styles={{ backgroundColor: '#FFF', padding: 13 }}
                                  errorMessage={error?.message}
                                />
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            <Controller
                              name={`decisionDetail.${rIndex}.WOP` as 'decisionDetail.0.WOP'}
                              control={control}
                              render={({ field: { onChange, onBlur, value } }) => (
                                <Checkbox
                                  title={''}
                                  value={value}
                                  onBlur={onBlur}
                                  onChange={(e) => {
                                    onChange(e)
                                    setIsCanChangeTab(false)
                                    if (!e) {
                                      form.setValue(`decisionDetail.${rIndex}.WOPFrom`, null)
                                      form.setValue(`decisionDetail.${rIndex}.WOPTo`, null)
                                    }
                                  }}
                                />
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            <Controller
                              name={`decisionDetail.${rIndex}.WOPFrom`}
                              rules={{
                                required: {
                                  value: !!form.watch(`decisionDetail.${rIndex}.WOP`),
                                  message: `${t('message:MS020001', { field: t('Adjudication:WOPFrom') })}`
                                },
                                validate: (WOPFrom) => {
                                  const WOPTo = form.watch(`decisionDetail.${rIndex}.WOPTo`)
                                  const compareDate = !!WOPTo && !!WOPFrom && compareOnlyDate(WOPTo, WOPFrom)
                                  const message = t('message:MS050013', { field: t('Adjudication:WOPFrom') })
                                  if (compareDate) return message
                                  return true
                                }
                              }}
                              control={control}
                              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                <DatePickerTable
                                  value={value}
                                  disabled={!form.watch(`decisionDetail.${rIndex}.WOP`) || disabled}
                                  onChange={(e) => {
                                    onChange(e)
                                    setIsCanChangeTab(false)
                                  }}
                                  onBlur={onBlur}
                                  styles={{ backgroundColor: '#FFF', padding: 13 }}
                                  errorMessage={error?.message}
                                />
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            <Controller
                              name={`decisionDetail.${rIndex}.WOPTo`}
                              rules={{
                                required: {
                                  value: !!form.watch(`decisionDetail.${rIndex}.WOP`),
                                  message: `${t('message:MS020001', { field: t('Adjudication:WOPTo') })}`
                                },
                                validate: (WOPTo) => {
                                  const WOPFrom = form.watch(`decisionDetail.${rIndex}.WOPFrom`)
                                  const compareDate = !!WOPFrom && !!WOPTo && !compareOnlyDate(WOPFrom, WOPTo)
                                  const message = t('message:MS050013', { field: t('Adjudication:WOPTo') })
                                  if (compareDate) return message
                                  return true
                                }
                              }}
                              control={control}
                              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                <DatePickerTable
                                  value={value}
                                  disabled={!form.watch(`decisionDetail.${rIndex}.WOP`) || disabled}
                                  onChange={(e) => {
                                    onChange(e)
                                    setIsCanChangeTab(false)
                                  }}
                                  onBlur={onBlur}
                                  styles={{ backgroundColor: '#FFF', padding: 13 }}
                                  errorMessage={error?.message}
                                />
                              )}
                            />
                          </TableCell>
                        </TableRow>
                      )
                    })}
                    {decisionTableForm.fields.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={12}>
                          <View>
                            <Text style={{ textAlign: 'center' }}>{t('common:Nodata')}</Text>
                          </View>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </View>

            <View style={{ marginBottom: 30 }}>
              <Text style={[style.sectionTitle, { paddingBottom: 10 }]}>{t('BenefitDetail')}</Text>
              <TableContainer component={Paper}>
                <Table className={classes.tableStyle}>
                  <TableHead className={classes.tableHeader}>
                    <TableRow>
                      <TableCell className={classes.tableHeaderText} style={{ width: 300 }}>
                        {t('Adjudication:BenefitName')}
                      </TableCell>
                      <TableCell className={classes.tableHeaderText}>{t('Adjudication:EventDate')}</TableCell>
                      <TableCell className={classes.tableHeaderText}>{t('Adjudication:AutoDecision')}</TableCell>
                      <TableCell className={classes.tableHeaderText}>{t('Adjudication:AdjudicatorDecision')}</TableCell>
                      <TableCell className={classes.tableHeaderText}>{t('Adjudication:ClaimBenefitAmount')}</TableCell>
                      <TableCell className={classes.tableHeaderText}>{t('Adjudication:DeclineReason')}</TableCell>
                      <TableCell className={classes.tableHeaderText}>{t('Adjudication:AnotherReason')}</TableCell>
                      {/* <TableCell className={classes.tableHeaderText}>{t('Adjudication:NoteDeclineReason')}</TableCell> */}
                      <TableCell className={classes.tableHeaderText}>{t('Adjudication:Remark')}</TableCell>
                      <TableCell className={classes.tableHeaderText}>{t('Adjudication:ExcludedAmount')}</TableCell>
                      <TableCell className={classes.tableHeaderText}>{t('Adjudication:CMPExG-amount')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className={classes.tableBody}>
                    {benefitDetailForm.fields.map((data, index) => (
                      <React.Fragment key={data.id}>
                        <TableRow>
                          <TableCell colSpan={11}>
                            <Controller
                              name={`benefitDetail.${index}.componentCode` as 'benefitDetail.0.componentCode'}
                              control={control}
                              render={({ field: { onChange, onBlur, value } }) => (
                                <Input
                                  disabled
                                  onBlur={onBlur}
                                  onChange={onChange}
                                  inputStyle={{
                                    fontWeight: 'bold'
                                  }}
                                  value={`${
                                    i18n.language === 'en'
                                      ? watch(`benefitDetail.${index}.componentEN`)
                                      : watch(`benefitDetail.${index}.componentVN`)
                                  }`}
                                />
                              )}
                            />
                          </TableCell>
                        </TableRow>
                        {form.watch(`benefitDetail.${index}.benefitClaimCode`)?.map((item, key) => {
                          const isDeclineDecision = ['DS02', 'DS03', 'DS05'].includes(
                            benefitDetail[index].benefitClaimCode[key].adjudicatorDecision?.value || ''
                          )
                          return (
                            <TableRow key={item.id}>
                              <TableCell component="th" scope="row">
                                <Controller
                                  name={
                                    `benefitDetail.${index}.benefitClaimCode.${key}.benefitClaimCode` as 'benefitDetail.0.benefitClaimCode.0.benefitClaimCode'
                                  }
                                  control={control}
                                  render={({ field: { onChange, onBlur, value } }) => (
                                    <Input
                                      disabled
                                      onBlur={onBlur}
                                      onChange={onChange}
                                      value={`${watch(
                                        `benefitDetail.${index}.benefitClaimCode.${key}.benefitClaimCode`
                                      )} ${
                                        i18n.language === 'en'
                                          ? watch(`benefitDetail.${index}.benefitClaimCode.${key}.benefitClaimEN`)
                                          : watch(`benefitDetail.${index}.benefitClaimCode.${key}.benefitClaimVN`)
                                      }`}
                                    />
                                  )}
                                />
                              </TableCell>
                              <TableCell>
                                <Controller
                                  name={
                                    `benefitDetail.${index}.benefitClaimCode.${key}.eventDate` as 'benefitDetail.0.benefitClaimCode.0.eventDate'
                                  }
                                  control={control}
                                  render={({ field: { onChange, onBlur, value } }) => (
                                    <Input disabled onBlur={onBlur} onChange={onChange} value={value} />
                                  )}
                                />
                              </TableCell>
                              <TableCell>
                                <Controller
                                  name={
                                    `benefitDetail.${index}.benefitClaimCode.${key}.autoDecision` as 'benefitDetail.0.benefitClaimCode.0.autoDecision'
                                  }
                                  control={control}
                                  render={({ field: { onChange, onBlur, value } }) => (
                                    <Input disabled onBlur={onBlur} onChange={onChange} value={value} />
                                  )}
                                />
                              </TableCell>
                              <TableCell>
                                <Controller
                                  name={
                                    `benefitDetail.${index}.benefitClaimCode.${key}.adjudicatorDecision` as 'benefitDetail.0.benefitClaimCode.0.adjudicatorDecision'
                                  }
                                  rules={{
                                    required: {
                                      value: true,
                                      message: `${t('message:MS020009', {
                                        field: t('Adjudication:AdjudicatorDecision')
                                      })}`
                                    }
                                  }}
                                  control={control}
                                  defaultValue={adjuDecisionListOptions[0]}
                                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                    <SelectSearch
                                      popupIcon={<assets.ArrowDownDropdownIcon />}
                                      disableUnderline={true}
                                      inputStyle={[style.selectStyle, { minWidth: 250 }]}
                                      errorMessage={error?.message}
                                      onChange={(e) => {
                                        onChange(e)
                                        form.setValue(
                                          `benefitDetail.${index}.benefitClaimCode.${key}.declineReason`,
                                          null
                                        )

                                        if (e?.value !== 'DS02') {
                                          form.setValue(
                                            `benefitDetail.${index}.benefitClaimCode.${key}.claimBenefitAmount`,
                                            item.claimAmount || '0'
                                          )
                                        } else {
                                          form.setValue(
                                            `benefitDetail.${index}.benefitClaimCode.${key}.claimBenefitAmount`,
                                            '0'
                                          )
                                        }
                                        setIsCanChangeTab(false)
                                      }}
                                      onBlur={onBlur}
                                      value={value}
                                      options={adjuDecisionListOptions || []}
                                      placeholder={t('common:Select')}
                                      key={value?.value}
                                    />
                                  )}
                                />
                              </TableCell>
                              <TableCell>
                                <Controller
                                  name={
                                    `benefitDetail.${index}.benefitClaimCode.${key}.claimBenefitAmount` as 'benefitDetail.0.benefitClaimCode.0.claimBenefitAmount'
                                  }
                                  control={control}
                                  rules={{
                                    maxLength: {
                                      value: 15,
                                      message: t('message:MS070016', { field: 12 })
                                    }
                                  }}
                                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <InputTable
                                      value={formatNumberWithComma(value || '0')}
                                      onChange={(val: string) => {
                                        const newVal = val.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                        onChange(newVal)
                                        setIsCanChangeTab(false)
                                      }}
                                      errorMessage={error?.message}
                                      disabled
                                    />
                                  )}
                                />
                              </TableCell>
                              <TableCell>
                                <Controller
                                  name={
                                    `benefitDetail.${index}.benefitClaimCode.${key}.declineReason` as 'benefitDetail.0.benefitClaimCode.0.declineReason'
                                  }
                                  control={control}
                                  rules={{
                                    required: {
                                      value:
                                        isDeclineDecision &&
                                        benefitDetail[index].benefitClaimCode[key].adjudicatorDecision?.value ===
                                          'DS02',
                                      message: `${t('message:MS020009', { field: t('Adjudication:DeclineReason') })}`
                                    }
                                  }}
                                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                    <SelectSearch
                                      popupIcon={
                                        !isDeclineDecision || disabled ? <></> : <assets.ArrowDownDropdownIcon />
                                      }
                                      disableUnderline={true}
                                      disabled={!isDeclineDecision || disabled}
                                      inputStyle={[style.selectStyle, { minWidth: 250 }]}
                                      errorMessage={error?.message}
                                      onChange={(e) => {
                                        onChange(e)
                                        setIsCanChangeTab(false)
                                      }}
                                      onBlur={onBlur}
                                      value={value}
                                      options={declinesOptionsMemo}
                                      placeholder={t('common:Select')}
                                      key={value?.value}
                                    />
                                  )}
                                />
                              </TableCell>
                              <TableCell>
                                <Controller
                                  name={
                                    `benefitDetail.${index}.benefitClaimCode.${key}.anotherDeclineReason` as 'benefitDetail.0.benefitClaimCode.0.anotherDeclineReason'
                                  }
                                  control={control}
                                  // rules={{
                                  //   required: {
                                  //     value: isDeclineDecision,
                                  //     message: `${t('message:MS020009', { field: t('Adjudication:AnotherReason') })}`
                                  //   }
                                  // }}
                                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                    <SelectSearch
                                      popupIcon={
                                        !isDeclineDecision || disabled ? <></> : <assets.ArrowDownDropdownIcon />
                                      }
                                      disableUnderline={true}
                                      disabled={!isDeclineDecision || disabled}
                                      inputStyle={[style.selectStyle, { minWidth: 250 }]}
                                      errorMessage={error?.message}
                                      onChange={(e) => {
                                        onChange(e)
                                        setIsCanChangeTab(false)
                                      }}
                                      onBlur={onBlur}
                                      value={value}
                                      options={declinesOptionsMemo}
                                      placeholder={t('common:Select')}
                                      key={value?.value}
                                    />
                                  )}
                                />
                              </TableCell>
                              <TableCell>
                                <Controller
                                  name={
                                    `benefitDetail.${index}.benefitClaimCode.${key}.remarks` as 'benefitDetail.0.benefitClaimCode.0.remarks'
                                  }
                                  rules={{
                                    required: {
                                      value:
                                        benefitDetail[index].benefitClaimCode[key].adjudicatorDecision?.value ===
                                          'DS02' &&
                                        (benefitDetail[index].benefitClaimCode[key]?.declineReason?.value === 'EX99' ||
                                          benefitDetail[index].benefitClaimCode[key]?.anotherDeclineReason?.value ===
                                            'EX99'),
                                      message: `${t('message:MS020009', { field: t('Adjudication:Remark') })}`
                                    }
                                  }}
                                  control={control}
                                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                    <InputTable
                                      value={value ?? ''}
                                      onChange={(e) => {
                                        onChange(e)
                                        setIsCanChangeTab(false)
                                      }}
                                      maxLength={200}
                                      errorMessage={error?.message}
                                    />
                                  )}
                                />
                              </TableCell>
                              {/* <TableCell>
                                <Controller
                                  name={
                                    `benefitDetail.${index}.benefitClaimCode.${key}.noteDeclineReason` as 'benefitDetail.0.benefitClaimCode.0.noteDeclineReason'
                                  }
                                  control={control}
                                  // rules={{
                                  //   required: {
                                  //     value: isDeclineDecision,
                                  //     message: `${t('message:MS020009', { field: t('Adjudication:NoteDeclineReason') })}`
                                  //   }
                                  // }}
                                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                    <InputTable
                                      value={value ?? ''}
                                      onChange={(e) => {
                                        onChange(e)
                                      }}
                                      errorMessage={error?.message}
                                      disabled={!isDeclineDecision || disabled}
                                    />
                                  )}
                                />
                              </TableCell> */}
                              <TableCell>
                                <Controller
                                  name={
                                    `benefitDetail.${index}.benefitClaimCode.${key}.excludedAmount` as 'benefitDetail.0.benefitClaimCode.0.excludedAmount'
                                  }
                                  control={control}
                                  defaultValue={'0'}
                                  rules={{
                                    maxLength: {
                                      value: 15,
                                      message: t('message:MS070016', { field: 12 })
                                    }
                                  }}
                                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <InputTable
                                      value={value + ''}
                                      onChange={(val: string) => {
                                        const newVal = val.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                        onChange(newVal)
                                        setIsCanChangeTab(false)
                                      }}
                                      errorMessage={error?.message}
                                    />
                                  )}
                                />
                              </TableCell>
                              <TableCell>
                                <Controller
                                  name={
                                    `benefitDetail.${index}.benefitClaimCode.${key}.CMPExGAmount` as 'benefitDetail.0.benefitClaimCode.0.CMPExGAmount'
                                  }
                                  control={control}
                                  defaultValue={'0'}
                                  rules={{
                                    maxLength: {
                                      value: 15,
                                      message: t('message:MS070016', { field: 12 })
                                    }
                                  }}
                                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <InputTable
                                      value={value + ''}
                                      onChange={(val: string) => {
                                        const newVal = val.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                        onChange(newVal)
                                        setIsCanChangeTab(false)
                                      }}
                                      errorMessage={error?.message}
                                    />
                                  )}
                                />
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </React.Fragment>
                    ))}
                    {benefitDetailForm.fields.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={9}>
                          <View>
                            <Text style={{ textAlign: 'center' }}>{t('common:Nodata')}</Text>
                          </View>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </View>
          </View>
        )}
      </Panel>
      <Panel title={''} titleOutline={t('claim:Evaluation')} isExand={false}>
        <Text style={{ fontSize: 16, fontWeight: 'bold', color: 'rgb(112, 119, 126)' }}>{t('claim:Evaluation')}</Text>
        <HeaderTable
          headerContainerStyle={{ backgroundColor: '#F4F4F4' }}
          headerTitleStyle={{ color: '#70777E' }}
          columns={columns}
          dataSource={evaluations
            .sort((item1, item2) => compareItemsByCreateDate(item1.createdDate, item2.createdDate))
            .map((item: Evaluation) => ({
              evaluationType: showEvaluation(item.evaluationCode) || '-',
              subject: item.subject,
              createdBy: item.createdBy,
              createdDate: formatDiaryDate(item.createdDate),
              description: (
                <TouchableOpacity onPress={() => showContentView(item)}>
                  <Text style={{ color: '#1ea5fc', fontWeight: '300', fontSize: 16 }}>View</Text>
                </TouchableOpacity>
              )
            }))}
        />
        <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginVertical: 15 }}>
          <RoundedButton
            showBorder={true}
            filled={false}
            activeOpacity={0.7}
            text={t('claim:ViewAllEvaluation')}
            textStyle={style.btnTitle}
            style={{ height: 35, marginEnd: 15, paddingHorizontal: 15 }}
            textColorDisable="#B0B0B0"
            textColorEnable="#ED1B2E"
            bgColorDisable={'#B0B0B0'}
            bgColorEnable={'#ED1B2E'}
            borderColorDisable={'#B0B0B0'}
            borderColorEnable={'#ED1B2E'}
            onPress={() => {
              props.navigation.navigate('EvaluationScreen', {
                evaluations: evaluations,
                claimNumber: claimNumber
              })
            }}
          />
          <RoundedButton
            showBorder={true}
            filled={false}
            activeOpacity={0.7}
            text={t('claim:AddNew')}
            textStyle={style.btnTitle}
            style={{ height: 35, paddingHorizontal: 15 }}
            textColorDisable="#B0B0B0"
            textColorEnable="#ED1B2E"
            bgColorDisable={'#B0B0B0'}
            bgColorEnable={'#ED1B2E'}
            borderColorDisable={'#B0B0B0'}
            borderColorEnable={'#ED1B2E'}
            onPress={onShowModalAddEvaluation}
          />
        </View>
        <View>
          <Controller
            name="finalInformation"
            control={control}
            rules={{
              required: {
                value: true,
                message: `${t('message:MS020001', { field: t('claim:FinalInformation') })}`
              },
              maxLength: {
                value: 1000,
                message: `${t('message:MaxLength', { field: '1000' })}`
              }
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <Input
                required
                title={t('claim:FinalInformation')}
                onChange={(e) => {
                  onChange(e)
                  setIsCanChangeTab(false)
                }}
                onBlur={onBlur}
                value={value}
                errorMessage={error?.message}
                multiline
                maxLength={1000}
                numberOfLines={6}
              />
            )}
          />
        </View>
      </Panel>
      <EvaluationModal
        open={isOpenEvaluationModal}
        onClose={() => {
          setIsOpenEvaluationModal(false)
          evaluationForm.reset()
        }}
        selectedValue={{}}
        form={evaluationForm}
        onSubmit={evaluationForm.handleSubmit(onSubmit)}
        diaryTypeList={[]}
      />
      <EvaluationModalView
        open={isOpenEvaluationViewModal}
        onClose={() => setIsOpenEvaluationViewModal(false)}
        selectedValue={{}}
        content={contentView}
      />
    </SafeAreaView>
  )
}

const style = StyleSheet.create({
  sectionTitle: {
    fontSize: 15,
    fontWeight: '800',
    paddingTop: 14,
    textTransform: 'uppercase'
  },
  sectionInfo: {
    width: '100%',
    padding: 20,
    backgroundColor: '#FFF',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#D3DCE6',
    borderTopStartRadius: 8,
    borderTopEndRadius: 8
  },
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  col: {
    flex: 1 / 3,
    marginBottom: 32
  },
  label: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  },
  value: {
    color: '#000000',
    fontSize: 15,
    lineHeight: 18
  },
  btnTitle: {
    fontSize: 15,
    marginVertical: 5,
    marginHorizontal: 29
  },
  selectStyle: {
    borderColor: '#BABABA',
    backgroundColor: '#FFF',
    borderWidth: 1,
    padding: 5,
    borderRadius: 8
  }
})
