import { ZIO } from '@mxt/zio'
import { pipe } from 'fp-ts/lib/function'
import React from 'react'
import { useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { View, Text, TouchableOpacity, ScrollView } from 'react-native'
import { defaultNonCash, mapReciepsUpdatePayload } from './cash-less-form'
import {
  Alert,
  AppContext,
  CashLessService,
  ErrorHandling,
  form2,
  PulseOpsFormat,
  SelectOption,
  TaskDetail,
  TaskDetailService,
  InternalFeedbackService,
  Toast
} from '@pulseops/common'
import { PCReason, SacsData } from '../mock'
import { defaultReceipt, CashLessForm, defaultJournal, defaultSelect } from './cash-less-form'
import { calAmountAction, calNonCash } from './helper'
import _ from 'lodash'
import { xorBy } from 'lodash'
import { useLoading } from '@mxt/zio-react'
import { Receip } from 'libs/common/src/service/model/task-detail/CashLess'
import { TransactionInfoView } from './transaction-info'
import { PolicyInfoView } from './policy-info'
import { Reciepts } from './receipt'
import { NonCash } from './noncash'
import { Journals } from './journal'
import { HistoryReceipt } from './history-receipt'
import { TaskDetailState } from '../../state'
import { styles } from './styles'
import { Color } from '@material-ui/lab'
import { SnackbarOrigin } from '@material-ui/core'
import moment from 'moment'
import styled from 'styled-components/native'
const StickyHeader = styled.View`
  position: sticky;
`

type Props = {
  detail: TaskDetail.VeriCashLess
  isInquiry: boolean | null
  isQC: boolean
  processId: string
  taskId: string
  isSuspend: boolean
}

export const CashLess = (props: Props) => {
  const { detail, isInquiry, isQC, isSuspend } = props
  const { base: form, handleSubmit } = form2.useForm(CashLessForm.codec)
  const { showGlobalLoading, showToast } = React.useContext(AppContext.AppContextInstance)
  const [loading, bindLoader] = useLoading(false)
  const {
    t: ca,
    i18n: { language }
  } = useTranslation('Cashless')

  const { t } = useTranslation()

  const {
    formState: { errors }
  } = form
  const status = pipe(InternalFeedbackService.getCaseStatusByCaseID(detail.businessKey), ErrorHandling.runDidMount())

  const canAction = !(isInquiry || isQC || isSuspend)
  const [toast, setToast] = React.useState<{
    open: boolean
    message: string
    type?: Color
    duration?: number
    canClose?: boolean
  }>({ open: false, message: '' })

  const showToastInternal = (
    message: string,
    type: Color | undefined = undefined,
    duration: number,
    canClose: boolean
  ) => {
    setToast({ open: true, message, type, duration, canClose })
  }

  React.useEffect(() => {
    form.reset({
      amount: detail.amount,
      receips: [defaultReceipt],
      nonCash: defaultReceipt,
      journal: []
    })
  }, [])

  React.useEffect(() => {
    console.log(status)
    if (detail.hasVoucher && status && status !== 'Completed') {
      showToastInternal(t(`message:MS990092`, { voucherCode: detail.vouchers[0].code }), 'warning', 99999999999, false)
    }
  }, [status])

  React.useEffect(() => {
    pipe(TaskDetailState.action.reset(), ZIO.unsafeRun({}))
    if (detail.stpFunction === 'MS990081') {
      showToast(t(`message:${detail.stpFunction}`), 'warning', undefined, 10000)
    }
  }, [])

  const pendingCodeSelections = pipe(
    CashLessService.getTV636,
    ZIO.map((transCode) => {
      const pendingCodeSelections = transCode.map((e) => ({ label: `${e.shortDesc} - ${e.longDesc}`, value: e.item }))
      return [defaultSelect, ...pendingCodeSelections]
    }),
    ErrorHandling.runDidMount([])
  )

  const pendingSelectionMap = React.useMemo(() => {
    return new Map<PCReason.ActionEnum, SelectOption[]>([
      [PCReason.ActionEnum.N, pendingCodeSelections],
      [PCReason.ActionEnum.A, [defaultSelect]],
      [PCReason.ActionEnum.G, [defaultSelect]],
      [PCReason.ActionEnum.L, [defaultSelect]],
      [PCReason.ActionEnum.J, [defaultSelect]],
      [PCReason.ActionEnum.R, [defaultSelect, ...pendingCodeSelections.filter((p) => p.value === 'PTR')]]
    ])
  }, [pendingCodeSelections])

  React.useEffect(() => {
    showGlobalLoading(loading)
  }, [loading])

  const pathData = React.useCallback(() => {
    const coreReceipt =
      detail.coreRecipts
        ?.filter((c) => {
          return (
            !!c.receiptNo || (detail.receipts ?? []).findIndex((r) => c.transactionCode === r.transactionCode) === -1
          )
        })
        .map((cReceipt) => {
          return {
            about: { label: cReceipt.action, value: cReceipt.action },
            readonly: true,
            pendingCode: !!cReceipt.newDescription
              ? {
                  label: cReceipt.newDescription,
                  value: cReceipt.newDescription
                }
              : null,
            transactionCode: cReceipt.transactionCode,
            receiptNo: cReceipt.receiptNo,
            info: cReceipt.transactions.map((t, index) => {
              const calA = calAmountAction({
                detail,
                action: cReceipt.action as PCReason.ActionEnum,
                sacCode: t.subAccountCode,
                sacType: t.subAccountType,
                index
              })
              return {
                sacsCode: SacsData.getSacsCodeByCode(t.subAccountCode)(language),
                sacsType: SacsData.getSacsTypeByCode(t.subAccountType)(language),
                sacsTypeSelection: SacsData.getOptionsTypeByCode(t.subAccountType)(language),
                amount: PulseOpsFormat.addThousandSeparator(String(t.amount)),
                allowEdit: calA.allowEdit,
                allowDelete: calA.allowDelete
              }
            })
          }
        }) ?? []

    const coreJournals =
      detail.coreJournals
        ?.filter((c) => {
          return (
            !!c.receiptNo || (detail.journals ?? []).findIndex((r) => c.transactionCode === r.transactionCode) === -1
          )
        })
        .map((cReceipt) => {
          return {
            about: { label: cReceipt.action, value: cReceipt.action },
            readonly: true,
            pendingCode: !!cReceipt.newDescription
              ? {
                  label: cReceipt.newDescription,
                  value: cReceipt.newDescription
                }
              : null,
            transactionCode: cReceipt.transactionCode,
            receiptNo: cReceipt.receiptNo,
            info: cReceipt.transactions.map((t) => {
              const calA = calAmountAction({
                detail,
                action: cReceipt.action as PCReason.ActionEnum,
                sacCode: t.subAccountCode,
                sacType: t.subAccountType
              })
              return {
                sacsCode: SacsData.getSacsCodeByCode(t.subAccountCode)(language),
                sacsType: SacsData.getSacsTypeByCode(t.subAccountType)(language),
                sacsTypeSelection: SacsData.getOptionsTypeByCode(t.subAccountType)(language),
                amount: PulseOpsFormat.addThousandSeparator(String(t.amount)),
                allowEdit: calA.allowEdit,
                allowDelete: calA.allowDelete
              }
            })
          }
        }) ?? []

    const checkAdjusted = detail.priorProposal
      ? detail.proposalNumber === detail.rawData.proposalNumber
      : detail.policyNumber === detail.rawData.policyNumber
    const adjustPolicy =
      checkAdjusted || _.isUndefined(detail.priorProposal)
        ? null
        : detail.priorProposal
        ? detail.proposalNumber
        : detail.policyNumber // adject policy exist if only policy number old exist

    const wrapPayloadReceipt = (detail.receipts ?? []).filter(
      // chỉ lấy những receipt có trannsaction Code khác với receipt trong coreReceipt
      (r) => coreReceipt.findIndex((c) => c.transactionCode === r.transactionCode) === -1
    )

    const wrapPayloadJournal = (detail.journals ?? []).filter(
      (r) => coreJournals.findIndex((c) => c.transactionCode === r.transactionCode) === -1
    )

    const wrapPayloadNoncash = detail.coreNonCash?.receiptNo
      ? detail.coreNonCash
      : detail.noncash?.transactionCode
      ? detail.noncash
      : detail.coreNonCash
    // const wrapPayloadNoncash =
    //   detail.coreNonCash?.transactionCode === detail.noncash?.transactionCode
    //     ? undefined
    //     : detail.noncash?.transactionCode
    //     ? detail.noncash
    //     : undefined

    // const wrapPayloadNoncash =
    //   detail.coreNonCash?.transactionCode === detail.noncash?.transactionCode
    //     ? undefined
    //     : detail.noncash?.transactionCode
    //     ? detail.noncash
    //     : undefined

    const toFormData = (r: Receip) => {
      return {
        about: { label: r.action, value: r.action },
        readonly: false,
        pendingCode: !!r.newDescription ? { label: r.newDescription, value: r.newDescription } : null,
        transactionCode: r.transactionCode ?? null,
        receiptNo: r.receiptNo ?? null,
        info: r.transactions.map((t, index) => {
          const cal = calAmountAction({
            detail,
            sacCode: t.subAccountCode ?? '',
            sacType: t.subAccountType ?? '',
            action: (r.action ?? '') as PCReason.ActionEnum,
            index
          })
          return {
            sacsCode: SacsData.getSacsCodeByCode(t.subAccountCode)(language),
            sacsType: SacsData.getSacsTypeByCode(t.subAccountType)(language),
            sacsTypeSelection: SacsData.getOptionsTypeByCode(t.subAccountType)(language),
            amount: PulseOpsFormat.addThousandSeparator(String(t.amount)),
            allowEdit: !!r.receiptNo || !canAction ? false : cal.allowEdit,
            allowDetele: !!r.receiptNo || !canAction ? false : cal.allowDelete
          }
        })
      }
    }

    const toJournalForm = (r: Receip) => {
      return {
        about: { label: r.action, value: r.action },
        readonly: false,
        pendingCode: !!r.newDescription ? { label: r.newDescription, value: r.newDescription } : null,
        transactionCode: r.transactionCode ?? null,
        receiptNo: r.receiptNo ?? null,
        info: r.transactions.map((t) => {
          return {
            sacsCode: SacsData.getSacsCodeByCode(t.subAccountCode)(language),
            sacsType: SacsData.getSacsTypeByCode(t.subAccountType)(language),
            sacsTypeSelection: SacsData.getOptionsTypeByCode(t.subAccountType)(language),
            amount: PulseOpsFormat.addThousandSeparator(String(t.amount)),
            allowEdit: !!r.receiptNo || canAction,
            allowDetele: !!r.receiptNo || canAction
          }
        })
      }
    }

    const toNoncashForm = (r: Receip) => {
      return {
        about: { label: r.action, value: r.action },
        readonly: false,
        pendingCode: !!r.newDescription ? { label: r.newDescription, value: r.newDescription } : null,
        transactionCode: r.transactionCode ?? null,
        receiptNo: r.receiptNo ?? null,
        previousTotalAmount: detail.rawCoreData.coreNonCash
          .filter((c) => c.action !== 'MSP_R_RPL' && c.receiptNo)
          .reduce((prev, cur) => (cur.transactions ? (prev += _.sumBy(cur.transactions, 'amount')) : prev), 0),
        info: r.transactions.map((t) => {
          const calFrom = calNonCash({
            detail: detail,
            action: r.action as PCReason.ActionEnum,
            sacCode: t.subAccountCode ?? '',
            sacType: t.subAccountType ?? '',
            receiptLNLO: 0,
            receiptLNAO: 0,
            receiptLPS: 0,
            bankCode: detail.bankCode
          })
          return {
            sacsCode: SacsData.getSacsCodeByCode(t.subAccountCode)(language),
            sacsType: SacsData.getSacsTypeByCode(t.subAccountType)(language),
            sacsTypeSelection: SacsData.getOptionsTypeByCode(t.subAccountType)(language),
            amount: PulseOpsFormat.addThousandSeparator(String(t.amount)),
            allowEdit: !!r.receiptNo || !canAction ? false : calFrom.allowEdit,
            allowDetele: !!r.receiptNo || !canAction ? false : calFrom.allowDelete,
            action: r.action
          }
        })
      }
    }

    const concatReceipt =
      wrapPayloadReceipt.length > 0
        ? wrapPayloadReceipt.map(toFormData)
        : canAction && coreReceipt.filter((c) => !c.receiptNo).length === 0
        ? [defaultReceipt]
        : []

    const concatJournals =
      wrapPayloadJournal.length > 0
        ? wrapPayloadJournal.map(toJournalForm)
        : canAction && coreJournals.filter((c) => !c.receiptNo).length === 0
        ? [defaultJournal]
        : []

    form.reset({
      amount: detail.amount,
      //adjustPolicy: detail.policyNumberOld ? adjustPolicy : null,
      adjustPolicy,
      receips: [...coreReceipt, ...concatReceipt], // add default receipt from core, with inquiry and QC don't add default receipt
      nonCash: wrapPayloadNoncash
        ? toNoncashForm(wrapPayloadNoncash)
        : {
            ...defaultNonCash,
            previousTotalAmount: detail.rawCoreData.coreNonCash
              .filter((c) => c.action !== 'MSP_R_RPL')
              .reduce((prev, cur) => (cur.transactions ? (prev += _.sumBy(cur.transactions, 'amount')) : prev), 0)
          },
      // nonCash: defaultNonCash,
      journal: [...coreJournals, ...concatJournals]
    })
    pipe(
      // coreReceipt.length + (detail.receipts?.length ?? 0),
      wrapPayloadReceipt.length,
      ZIO.succeed,
      ZIO.flatMap((data) => {
        if (data > 0) {
          return TaskDetailState.action.setFormState(true)
        } else {
          return TaskDetailState.action.setFormState(false, t('message:MS990053'))
        }
      }),
      ZIO.unsafeRun({})
    )
  }, [detail])

  React.useEffect(() => {
    pathData()
  }, [detail])

  const receipts = useWatch({ control: form.control, name: 'receips' }) // observe receipts change

  React.useEffect(() => {
    if (!_.isEmpty(receipts)) {
      setTimeout(() => {
        pipe(
          TaskDetailState.formValid.get,
          ZIO.flatMap((valid) => {
            return pipe(
              receipts.some((x) => _.isEqual(x.about, defaultReceipt.about)),
              ZIO.succeed,
              ZIO.flatMap((data) => {
                if (!valid) {
                  if (data) {
                    return TaskDetailState.action.setFormState(false, t('message:MS990053'))
                  } else {
                    return TaskDetailState.action.setFormState(false, t('message:MS100006'))
                  }
                } else {
                  if (data) {
                    return TaskDetailState.action.setFormState(false, t('message:MS990053'))
                  } else {
                    return TaskDetailState.action.setFormState(true)
                  }
                }
              })
            )
          }),
          ZIO.unsafeRun({})
        )
      }, 500)
    }
  }, [receipts])
  /**
   * calulate remaining amount to update info UI
   */
  const remainingAmount = React.useMemo(() => {
    const totalReceiptsAmount = receipts
      ? (receipts ?? []).reduce<number>((pre, cur) => {
          return (
            pre +
            (cur.info ?? []).reduce<number>((p, c) => {
              return p + Number(PulseOpsFormat.thousandSepartorReverse(c.amount ?? '0'))
            }, 0)
          )
        }, 0)
      : 0

    return Math.max(detail.amount - totalReceiptsAmount, 0)
  }, [receipts])

  const saveData = React.useCallback(
    (validated: CashLessForm.Validated) => {
      return pipe(
        ZIO.effect(() => {
          const dataUpdate = mapReciepsUpdatePayload(validated, detail)
          const payloadUpdate = {
            ...detail?.rawData.payload,

            body: {
              ...detail?.rawData.payload.body,
              ...dataUpdate,
              amount: String(detail.rawData.payload.body.amount)
            }
          }
          return payloadUpdate
        }),
        ZIO.flatMap((payloadUpdate) => {
          return TaskDetailService.saveLog(props.taskId ?? '', payloadUpdate, props.processId ?? '', [])
        })
      )
    },
    [detail]
  )

  const confirmReload = React.useMemo(() => {
    return Alert.modalM({
      title: ca('info'),
      content: ca('MS100003'),
      hideCancle: true,
      size: {
        width: '30%'
      },
      onClose: () => {
        window.location.reload()
      },
      onOK: () => {
        window.location.reload()
      }
    })
  }, [])

  const confirmFail = React.useMemo(() => {
    return Alert.modalM({
      title: ca('info'),
      content: ca('MS050001'),
      size: {
        width: '30%'
      },
      hideCancle: true
    })
  }, [])

  const handleSaveLog = (_: CashLessForm.Validated) => {
    const save = (validated: CashLessForm.Validated) =>
      pipe(
        ZIO.effect(() => {
          const body = detail.rawData.payload.body
          //tìm số transcode dư đã save log trước đó nhưng được adjusted về policyNum cũ
          const getPolicyNumOfProposalInfo =
            detail.rawCoreData.proposalInfo && detail.rawCoreData.proposalInfo.policyNum

          const noncashReceiptDelete =
            detail.rawCoreData.coreNonCash.some(
              (c) =>
                c.policyNumber === body.policyNumber ||
                (body.proposalNumber && getPolicyNumOfProposalInfo && c.policyNumber === getPolicyNumOfProposalInfo)
            ) && xorBy(detail.rawCoreData.coreNonCash, body.nonCashReceipts, 'transactionCode')[0]

          // tìm số transcode có trong core receipt list mà ko có trong receipts form
          const allReceiptsCore = [
            ...(detail.coreRecipts ?? []),
            ...(detail.coreNonCash ? [detail.coreNonCash] : []),
            ...(detail.coreJournals ?? [])
          ]
          const allReceiptsForm = [
            ...(validated.receips ?? []),
            ...(validated.journal ?? []),
            ...(validated.nonCash ? [validated.nonCash] : [])
          ]
          const deletedTranscode = allReceiptsCore
            .filter((core) => allReceiptsForm.every((f) => f.transactionCode !== core.transactionCode))
            .map((f) => f.transactionCode)
            .filter((f): f is string => !!f)

          noncashReceiptDelete && deletedTranscode.push(noncashReceiptDelete?.transactionCode as string)
          return deletedTranscode
        }),
        ZIO.flatMap((transcode) => {
          if (transcode.length > 0) {
            return CashLessService.deleteReceipts(detail.businessKey, transcode)
          } else {
            return ZIO.succeed(true)
          }
        }),
        ZIO.flatMap((validDeleteTranscode) => {
          if (validDeleteTranscode) {
            return pipe(
              saveData(validated),
              ZIO.tap((_) => {
                return confirmReload
              }),
              ZIO.tapError(() => confirmFail)
            )
          } else {
            return confirmFail
          }
        })
      )

    return pipe(
      ZIO.effect(() => {
        return form.getValues() as CashLessForm.Validated
      }),
      ZIO.flatMap((validated) => {
        const validNonCash =
          (validated.nonCash.info ?? []).reduce<number>((pre, cur) => {
            return pre + Number(PulseOpsFormat.thousandSepartorReverse(cur.amount ?? '0'))
          }, 0) ?? 0

        const totalMoney = (validated.receips ?? []).reduce<number>(
          (pre, cur) =>
            (cur.info ?? []).reduce<number>(
              (p, c) => Number(PulseOpsFormat.thousandSepartorReverse(c.amount ?? '0')) + p,
              0
            ) + pre,
          0
        )

        //Check SacCode LN with AO,AI,LO,LI if policy has ao,ai,lo,li amount = 0, valid is true
        const validSacCodeLN = validated.receips.reduce<boolean>(
          (pre, cur) =>
            (pre =
              cur.info !== undefined
                ? cur.info.some((item) =>
                    item.sacsCode?.value === 'LN' && ['AO', 'AI', 'LO', 'LI'].includes(item.sacsType?.value || '')
                      ? detail[item.sacsType?.value.toLowerCase() as keyof typeof detail] === 0
                      : false
                  )
                : pre),
          false
        )

        const validActLG = validated.receips.reduce<boolean>(
          (pre, cur) =>
            ['L', 'G'].includes(cur.about?.value.charAt(cur.about.value.length - 1) || '') &&
            ['UR2', 'UR4', 'VL6', 'VL7', 'VL8', 'UR5', 'UR6', 'VL2', 'VL3', 'VL5', 'VR1', 'IP1'].includes(
              detail.productCode
            ),
          false
        )

        const validActJ = validated.receips.reduce<boolean>(
          (pre, cur) =>
            ['J'].includes(cur.about?.value.charAt(cur.about.value.length - 1) || '') &&
            ['UR5', 'UR6', 'VL2', 'VL3', 'VL5', 'IP1'].includes(detail.productCode),
          false
        )

        if (validNonCash <= 0 && validated.nonCash.info.length > 0) {
          return Alert.snackbarM(t('Cashless:noncashAPLError1'), '', 'error')
        } else if (validSacCodeLN || validActLG || validActJ) {
          return Alert.snackbarM(t('Cashless:receiptLNError'), '', 'error')
        } else if (totalMoney > validated.amount) {
          return Alert.snackbarM(t('Cashless:errorAmountGreater'), '', 'error')
        } else if (totalMoney === 0) {
          return Alert.snackbarM(t('Cashless:greater0'), '', 'error')
        } else if (totalMoney < validated.amount) {
          return Alert.modalM({
            title: t('Cashless:warning'),
            content: t('Cashless:warningAmountLess'),
            size: {
              width: '30%'
            },
            onOK: () => {
              return pipe(save(validated), bindLoader, ErrorHandling.run())
            }
          })
        } else {
          return save(validated)
        }
      }),
      bindLoader,
      ErrorHandling.run()
    )
  }

  return (
    <ScrollView>
      <View style={{ zIndex: 99999 }}>
        <Toast
          message={toast.message}
          visible={toast.open}
          type={toast.type}
          duration={toast.duration}
          canClose={toast.canClose}
        />
      </View>
      <View>
        {/* Policy info  */}
        <PolicyInfoView detail={detail} />
        {/* Request Info */}
        <TransactionInfoView
          detail={detail}
          form={form}
          remainingAmount={remainingAmount}
          processId={props.processId}
          taskId={props.taskId}
          canAction={canAction}
        />
        {/* Billing detail */}
        <Reciepts
          form={form}
          detail={detail}
          canAction={canAction}
          remainingAmount={remainingAmount}
          mapTablePendingCode={pendingSelectionMap}
        />
        {/* Non-cash */}
        <NonCash pendingCode={pendingCodeSelections} form={form} detail={detail} canAction={canAction} />
        {/* Journal */}
        <Journals pendingCode={pendingCodeSelections} form={form} detail={detail} canAction={canAction} />

        {/* ReceiptList have enter core */}
        <HistoryReceipt detail={props.detail} />

        <Text style={styles.helperText}>{_.get(errors, '.message')}</Text>
        {canAction && (
          <View style={{ flexDirection: 'row', alignSelf: 'center' }}>
            <TouchableOpacity style={[styles.Button, { backgroundColor: '#fff' }]} onPress={pathData}>
              <Text style={{ color: '#ED1B2E', fontWeight: '700', fontSize: 15 }}>{t('common:Cancel')}</Text>
            </TouchableOpacity>
            <TouchableOpacity style={[styles.Button]} onPress={handleSubmit(handleSaveLog)}>
              <Text style={{ color: '#fff', fontWeight: '700', fontSize: 15 }}>{t('common:Save')}</Text>
            </TouchableOpacity>
          </View>
        )}
      </View>
    </ScrollView>
  )
}
