import React from 'react'
import { VoiceRecordingController } from './controller'
import { VoiceRecordingReducer, initVoiceRecordingState } from './reducer'
import { VoiceRecordingModels } from '../VoiceRecordingModels'

const VoiceRecordingContext = React.createContext<Types.PropsContextState | undefined>(undefined)

const useVoiceRecording = () => {
  const context = React.useContext(VoiceRecordingContext)

  if (context === undefined) {
    throw new Error('Context must be within a provider')
  }

  return context
}
const VoiceRecordingProvider = React.forwardRef((props: Types.PropsProvider, ref) => {
  const [state, dispatch] = React.useReducer(VoiceRecordingReducer, initVoiceRecordingState())

  // State
  const value: Types.PropsContextState = {
    state,
    controller: new VoiceRecordingController(dispatch),
    dispatchReducer: dispatch
  }

  React.useImperativeHandle(ref, () => ({
    controller: value.controller
  }))

  return <VoiceRecordingContext.Provider value={value}>{props.children}</VoiceRecordingContext.Provider>
})

const MySelectorComponents = React.memo((props: Types.PropsSelector) => {
  return <>{props.children(props.context)}</>
})

const VoiceRecordConsumer = React.memo(({ children, shouldBuild }: Types.PropsConsumer) => {
  const context = useVoiceRecording()

  const [myState, setMyState] = React.useState<Types.PropsContextState>(context)

  React.useEffect(() => {
    if (shouldBuild) {
      setMyState(context)
    }
  }, [context])

  return <MySelectorComponents context={myState}>{children}</MySelectorComponents>
})

export { VoiceRecordConsumer, VoiceRecordingProvider, useVoiceRecording }

export declare module Types {
  // provider type
  interface PropsProvider {
    children: React.ReactNode
  }

  type RefType = React.ForwardedRef<{
    controller: VoiceRecordingController
  }>

  interface PropsSelector {
    children: (context: PropsContextState) => React.ReactNode
    context: PropsContextState
  }

  interface PropsContextState {
    state: VoiceRecordContextState
    dispatchReducer: DispatchReducer
    controller: VoiceRecordingController
  }

  interface PropsConsumer {
    children: (context: PropsContextState) => React.ReactNode | React.ReactElement
    shouldBuild?: (currentState: VoiceRecordContextState, nextState: VoiceRecordContextState) => boolean
  }
  type DispatchReducer = (action: ActionReducer) => void

  type ActionReducer =
    | {
        type: 'SET_TRACK_INDEX_STATE'
        payload: number
      }
    | {
        type: 'SET_CURRENT_TRACK_STATE'
        payload: AudioData
      }
    | {
        type: 'SET_DURATION_STATE'
        payload: number
      }
    | {
        type: 'SET_CONFIG_TRAINSCRIPT_STATE'
        payload: Array<VoiceRecordingModels.ScriptsConfig>
      }
    | {
        type: 'SET_VOICE_DETAIL_STATE'
        payload: VoiceRecordingModels.VoiceRecordResponse
      }
    | {
        type: 'SET_PLAYING_VOICE_STATE'
        payload: boolean
      }
    | {
        type: 'SET_DYNAMIC_KEYWORD_STATE'
        payload: Array<VoiceRecordingModels.DynamicKeywordC>
      }
    | {
        type: 'SET_APPLICATION_INFO_STATE'
        payload: VoiceRecordingModels.ApplicationInfoC
      }
    | {
        type: 'SET_DATA_GIRD_STATE'
        payload: {
          keywordGroupEvaluations: Array<VoiceRecordingModels.KeywordGroupEvaluations>
          dynamicKeyword: Array<VoiceRecordingModels.DynamicKeyword>
        }
      }
    | {
        type: 'SET_TRANSCRIPT_STATE'
        payload: Array<VoiceRecordingModels.Segments>
      }
    | {
        type: 'SET_LOADING_SRC_STATE'
        payload: boolean
      }
  interface AudioData {
    title: string
    src: any
    author: string
    thumbnail: string
  }

  interface VoiceRecordContextState {
    audioTrack: AudioData[]
    trackIndex: number
    currentTrack: AudioData | null
    timeProgress: number
    duration: number
    configTrainscript: Array<VoiceRecordingModels.ScriptsConfig> | null
    voiceDetail: VoiceRecordingModels.VoiceRecordResponse | null
    isPlaying: boolean
    dynamicKeyword: Array<VoiceRecordingModels.DynamicKeywordC> | null
    applicationInfo: VoiceRecordingModels.ApplicationInfoC | null
    dataGird: {
      keywordGroupEvaluations: Array<VoiceRecordingModels.KeywordGroupEvaluations>
      dynamicKeyword: Array<VoiceRecordingModels.DynamicKeyword>
    } | null
    transcripts: Array<VoiceRecordingModels.Segments> | null
    loadingSrc: boolean
  }
}
