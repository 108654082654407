import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import React from 'react'
import { Controller, useFieldArray, useFormState, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { View, Text, TextInput, Pressable, TouchableOpacity } from 'react-native'
import {
  Alert,
  assets,
  Input,
  PulseOpsFormat,
  Select,
  SelectOption,
  SelectTable,
  TaskDetail,
  SelectTableOption
} from '@pulseops/common'
import { PCReason } from '../mock'
import { CashLessForm, CashLessFormBase, defaultSelect } from './cash-less-form'
import {
  calNonCash,
  checkNonCashCondition,
  getNoncashCodeOptions,
  getNoncashTypesByActionCode,
  getNoncashTypesOptions
} from './helper'
import { ContainerView, styles, useStyles } from './styles'
import _ from 'lodash'

/** Noncash Receipts */
export const NonCash = (props: {
  pendingCode: SelectOption[]
  form: CashLessFormBase
  detail: TaskDetail.VeriCashLess
  canAction?: boolean
}) => {
  const { form, canAction, detail } = props
  const { control } = form
  const classes = useStyles()
  const {
    t: ca,
    i18n: { language }
  } = useTranslation('Cashless')

  const aboutSelect = React.useMemo(() => [defaultSelect, ...PCReason.getActionNonCash()], [])

  const { append, fields, update } = useFieldArray({
    control,
    name: 'nonCash.info'
  })

  const { errors } = useFormState({ control })

  const { about, receiptNo } = useWatch({ control: form.control, name: 'nonCash' }) ?? {}

  const showButton = React.useMemo(() => canAction && !receiptNo, [receiptNo])

  const currentReceipts = useWatch({ control: form.control, name: `receips` })

  const fromReceiptSum = React.useMemo(() => {
    const infos = (currentReceipts?.filter((i) => !i.receiptNo) ?? []).reduce<CashLessForm.RawTransaction[]>(
      (pre, cur) => {
        return [...pre, ...(cur.info ?? []).filter((i) => !!i.sacsType?.value && !!i.sacsCode?.value)]
      },
      []
    )

    const sumFilter = (infos: CashLessForm.RawTransaction[]) => {
      return _.sumBy(infos, (i) => PulseOpsFormat.thousandSepartorReverse(i.amount ?? '0'))
    }

    const lps = sumFilter(infos.filter((i) => i.sacsCode?.value === 'LP' && i.sacsType?.value === 'S'))
    const lnao = sumFilter(infos.filter((i) => i.sacsCode?.value === 'LN' && i.sacsType?.value === 'AO'))
    const lnlo = sumFilter(infos.filter((i) => i.sacsCode?.value === 'LN' && i.sacsType?.value === 'LO'))

    return {
      lps,
      lnao,
      lnlo
    }
  }, [currentReceipts])

  const infos = useWatch({ control: form.control, name: 'nonCash.info' })

  const checkNoncash = React.useMemo(() => {
    return checkNonCashCondition({
      detail,
      action: (about?.value ?? '') as PCReason.ActionEnum,
      receiptLPS: fromReceiptSum.lps
    })
  }, [detail, about, fromReceiptSum])

  const checkActionNoncash = React.useMemo(() => {
    return detail.rawCoreData.coreNonCash.some(
      (c) =>
        c.action === 'MSP_R_RPL' &&
        c.receiptNo !== null &&
        (detail.policyNumber === c.policyNumber || detail.proposalNumber === c.proposalNumber)
    )
  }, [detail])

  const sacCodeOptions = React.useMemo(() => {
    return getNoncashCodeOptions(language)
  }, [language])

  const sacTypeOptions = React.useMemo(() => {
    return getNoncashTypesOptions(language)
  }, [language])
  const amountNonCash = React.useMemo(() => {
    return (
      (infos ?? []).reduce<number>((pre, cur) => {
        return pre + Number(PulseOpsFormat.thousandSepartorReverse(cur.amount ?? '0'))
      }, 0) ?? 0
    )
  }, [infos])

  if (!!receiptNo || (!canAction && fields.length === 0)) {
    // hide this view if receiptNo exist in core
    return <View />
  } else {
    return (
      <View pointerEvents={!canAction ? 'none' : undefined}>
        <Text style={styles.sessonText}>NON-CASH</Text>
        <ContainerView>
          <View style={styles.row}>
            <View style={styles.selectContainer}>
              <Controller
                key={`noncash.${about?.value}`}
                control={control}
                name={`nonCash.about`}
                defaultValue={defaultSelect}
                render={({ field: { value, onChange } }) => {
                  return (
                    <Select
                      value={value}
                      onChange={(v) => {
                        const currentInfo = form.getValues(`nonCash`)
                        onChange(v)
                        form.setValue(`nonCash`, {
                          ...currentInfo,
                          about: v,
                          info: []
                        })
                      }}
                      options={checkActionNoncash ? aboutSelect.filter((s) => s.value === 'MSP_R_RPL') : aboutSelect}
                      label={ca('chooseAction')}
                    />
                  )
                }}
              />
            </View>
            <View style={styles.selectContainer}>
              <View style={{ borderBottomWidth: 1, borderBottomColor: '#D3DCE6' }}>
                <Input
                  title={ca('amount')}
                  value={PulseOpsFormat.thousandSepartor(amountNonCash) + ' VND'}
                  disabled={true}
                  inputStyle={{
                    fontWeight: amountNonCash === 0 ? 'normal' : 'bold'
                  }}
                />
              </View>
              {
                <Text style={styles.helperText}>
                  {!!checkNoncash.messageCode ? ca(checkNoncash.messageCode) : _.get(errors, 'nonCash.message')}
                </Text>
              }
            </View>
            <View style={styles.selectContainer} />
          </View>

          <TableContainer component={Paper}>
            <Table>
              <TableHead className={classes.tableHeader}>
                <TableCell className={classes.stickyLeft} width={'33%'}>
                  <Text style={styles.titleColumn}>{ca('sacCode')}</Text>
                </TableCell>
                <TableCell className={classes.stickyLeft} width={'33%'}>
                  <Text style={styles.titleColumn}>{ca('sacType')}</Text>
                </TableCell>
                <TableCell className={classes.stickyLeft} width={'33%'}>
                  <Text style={styles.titleColumn}>{ca('amount')}</Text>
                </TableCell>
              </TableHead>
              <TableBody>
                {(infos ?? []).map((v, idx) => {
                  return (
                    <Controller
                      key={`list_index${idx}`}
                      control={control}
                      name={`receips.${idx}.info`}
                      render={({ field: { value, onChange } }) => {
                        //const sacsCodeOption = getNonCashSascCodeByCode(about?.value as PCReason.ActionEnum)
                        return (
                          <TableRow>
                            <Controller
                              control={control}
                              name={`nonCash.info.${idx}.sacsCode`}
                              render={({ field: { value, onChange } }) => {
                                return (
                                  <TableCell className={classes.cell} width={'33%'}>
                                    <SelectTable
                                      // id={`_list_noncash${idx}`}
                                      // key={`list+n${idx}.${value?.value}`}
                                      id={`${idx}${value?.value}`}
                                      // key={`${idx}${value?.value}`}
                                      options={sacCodeOptions}
                                      value={value}
                                      onChange={(v) => {
                                        onChange(v)
                                        // const _allSacType = (form.getValues(`nonCash.info`) ?? []).reduce<string[]>(
                                        //   (pre, cur) => [...pre, cur?.sacsType?.value ?? ''],
                                        //   []
                                        // )
                                        const sacs = getNoncashTypesByActionCode(
                                          about?.value as PCReason.ActionEnum,
                                          v?.value ?? ''
                                        )

                                        const _currentInfo = form.getValues(`nonCash.info.${idx}`)

                                        if (sacs.defaultValue) {
                                          const cal = calNonCash({
                                            detail,
                                            action: (about?.value ?? '') as PCReason.ActionEnum,
                                            sacCode: v?.value ?? '',
                                            sacType: sacs.defaultValue.value,
                                            receiptLPS: fromReceiptSum.lps,
                                            receiptLNAO: fromReceiptSum.lnao,
                                            receiptLNLO: fromReceiptSum.lnlo,
                                            bankCode: detail.bankCode
                                          })

                                          form.setValue(`nonCash.info.${idx}`, {
                                            ..._currentInfo,
                                            sacsCode: v,
                                            //sacsTypeSelection: sacs.sacsType,
                                            sacsTypeSelection: [],
                                            sacsType: sacs.defaultValue,
                                            amount: PulseOpsFormat.addThousandSeparator(String(cal.amount)),
                                            allowDetele: cal.allowDelete,
                                            allowEdit: cal.allowEdit,
                                            action: about?.value ?? ''
                                          })
                                        } else {
                                          form.setValue(`nonCash.info.${idx}`, {
                                            ..._currentInfo,
                                            sacsCode: v,
                                            amount: '',
                                            // sacsTypeSelection: sacs.sacsType.filter(
                                            //   (s) => !_allSacType.includes(s.value)
                                            // ),
                                            sacsTypeSelection: [],
                                            allowEdit: true,
                                            allowDetele: true,
                                            action: about?.value ?? ''
                                          })
                                        }
                                      }}
                                    />
                                  </TableCell>
                                )
                              }}
                            />
                            <Controller
                              control={control}
                              name={`nonCash.info.${idx}.sacsType`}
                              render={({ field: { value, onChange } }) => {
                                // const typeSelection =
                                //   useWatch({ control: form.control, name: `nonCash.info.${idx}.sacsTypeSelection` }) ??
                                //   []
                                return (
                                  <TableCell className={classes.cell}>
                                    <SelectTable
                                      // id={`_list_noncash${idx}`}
                                      // key={`_list_noncash${idx}${value?.value}`}
                                      id={`${idx}${value?.value}`}
                                      // key={`${idx}${value?.value}`}
                                      options={sacTypeOptions}
                                      value={value}
                                      onChange={(v) => {
                                        onChange(v)
                                        const currentInfo = form.getValues(`nonCash.info.${idx}`)
                                        const cal = calNonCash({
                                          detail,
                                          action: about?.value as PCReason.ActionEnum,
                                          sacCode: currentInfo?.sacsCode?.value ?? '',
                                          sacType: v?.value ?? '',
                                          receiptLPS: fromReceiptSum.lps,
                                          receiptLNAO: fromReceiptSum.lnao,
                                          receiptLNLO: fromReceiptSum.lnlo,
                                          bankCode: detail.bankCode
                                        })

                                        form.setValue(`nonCash.info.${idx}`, {
                                          ...currentInfo,
                                          sacsType: v,
                                          amount: PulseOpsFormat.addThousandSeparator(String(cal.amount)),
                                          allowEdit: cal.allowEdit,
                                          allowDetele: cal.allowDelete
                                        })
                                      }}
                                    />
                                  </TableCell>
                                )
                              }}
                            />
                            <Controller
                              control={control}
                              name={`nonCash.info.${idx}.amount`}
                              render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                                const allowEdit = form.watch(`nonCash.info.${idx}.allowEdit`)
                                const allowDetele = form.watch(`nonCash.info.${idx}.allowDetele`)
                                return (
                                  <TableCell className={classes.cell}>
                                    <View style={styles.inputContainer}>
                                      <View style={{ flex: 1, marginEnd: 10 }}>
                                        <TextInput
                                          key={`key_state${idx}`}
                                          onChangeText={(value) => {
                                            form.setValue(
                                              `nonCash.info.${idx}.amount`,
                                              PulseOpsFormat.addThousandSeparator(value)
                                            )
                                          }}
                                          value={value ?? ''}
                                          style={styles.input}
                                          maxLength={15}
                                          editable={allowEdit ?? undefined}
                                        />
                                      </View>

                                      {
                                        // hide this button with inquiery and QC
                                        showButton && (
                                          <Pressable
                                            onPress={() => {
                                              Alert.modal({
                                                title: ca('warning'),
                                                content: ca('warningDeleteLine'),
                                                size: {
                                                  width: '30%'
                                                },
                                                onOK: () => {
                                                  const nonCashInfo = form.getValues(
                                                    `nonCash.info`
                                                  ) as CashLessForm.NonCashTransaction[]
                                                  form.setValue(`nonCash.info`, [
                                                    ...nonCashInfo.slice(0, idx),
                                                    ...nonCashInfo.slice(idx + 1)
                                                  ])
                                                }
                                              })
                                            }}
                                            disabled={!allowDetele}
                                          >
                                            <View style={{ opacity: allowDetele ? 1.0 : 0.4 }}>
                                              <assets.DeleteIcon />
                                            </View>
                                          </Pressable>
                                        )
                                      }
                                    </View>
                                  </TableCell>
                                )
                              }}
                            />
                          </TableRow>
                        )
                      }}
                    />
                  )
                })}
                {showButton && (
                  <TableRow>
                    <TableCell />
                    <TableCell />
                    <TableCell>
                      <View style={{ flex: 1, justifyContent: 'center' }}>
                        <TouchableOpacity
                          onPress={() =>
                            append({
                              sacsType: null,
                              sacsCode: null,
                              amount: null,
                              sacsTypeSelection: [],
                              allowDetele: true,
                              allowEdit: true,
                              action: about?.value ?? ''
                            })
                          }
                          style={[
                            styles.Button,
                            { alignSelf: 'flex-end', backgroundColor: '#fff', width: undefined, marginHorizontal: 0 }
                          ]}
                        >
                          <Text style={[styles.boldText, { color: '#ED1B2E', fontSize: 12 }]}>{ca('addRow')}</Text>
                        </TouchableOpacity>
                      </View>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </ContainerView>
      </View>
    )
  }
}
