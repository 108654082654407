import {
  AppContext,
  assets,
  CustomedDateTimePicker,
  ErrorHandling,
  form2,
  Input,
  InternalFeedbackService,
  MultilineInput,
  Select,
  SelectSearch,
  sharedStyle,
  TaskType,
  Title,
  useMobile
} from '@pulseops/common'
import { FieldText, SectionCol, SectionContent, SectionRow } from '@pulseops/submission/common'
import { pipe } from 'fp-ts/lib/function'
import React from 'react'
import { Controller, useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Text, View, StyleSheet, TouchableOpacity } from 'react-native'
import { InternalSuspendInformationConst } from './InternalSuspendInformation.const'
import { InternalSuspendInformationForm } from './InternalSuspendInformation.form'
import { Status } from '../../supplementary-info/SupplementaryInfoForm'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import moment from 'moment'
import { InternalContext, InternalUserInfo } from '../InternalSuspendScreen'
import { useIsFocused } from '@react-navigation/native'
import { TextField, makeStyles } from '@material-ui/core'

interface InternalSuspendInformationProps {
  category: string
  processInstanceId: string
  businessKey: string
  T31DepartmentList: InternalFeedbackService.T31DepartmentList
  userInfo: InternalUserInfo
  suspendTypeList: InternalFeedbackService.SuspendTypeArray
  workingTimeList: InternalFeedbackService.WorkingTimeInfo[]
}

export const InternalSuspendInformation: React.FC<InternalSuspendInformationProps> = ({
  category,
  processInstanceId,
  businessKey,
  T31DepartmentList,
  userInfo,
  suspendTypeList,
  workingTimeList
}) => {
  const { t, i18n } = useTranslation()
  const { isWide } = useMobile()
  const isFocused = useIsFocused()
  const {
    base: { control, setValue, watch, trigger }
  } = form2.useForm(InternalSuspendInformationForm.codec, {
    defaultValues: { suspendList: [] }
  })
  const { fields, append, remove } = useFieldArray<InternalSuspendInformationForm.raw>({
    control: control,
    name: 'suspendList'
  })
  const [isLoading, bindLoader] = useLoading(false)
  const { getFooter, showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const [departmentInfo, setDepartmentInfo] = React.useState<InternalFeedbackService.SuspendDepartmentInfo>()
  const { disabled } = React.useContext(InternalContext)

  React.useEffect(() => {
    if (isFocused) {
      addNewSuspendItem()
      if (!['PS', 'CS', 'CH'].includes(category)){
        setButtonForFooter()
      }
    }
    return () => {
      remove()
      getFooter(<></>)
    }
  }, [isFocused])

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  const setButtonForFooter = () => {
    getFooter(
      disabled ? (
        <></>
      ) : (
        <View style={[sharedStyle.footer, isWide ? { marginRight: 19 } : { width: '100%', justifyContent: 'center' }]}>
          <TouchableOpacity
            style={[sharedStyle.button, { marginRight: 15, borderColor: '#ED1B2E' }]}
            onPress={cancelButtonEvent}
          >
            <Text style={sharedStyle.textButton}>{t('common:Cancel')}</Text>
          </TouchableOpacity>
          <TouchableOpacity style={[sharedStyle.button, sharedStyle.btnRed]} onPress={AddInternalSuspend}>
            <Text style={sharedStyle.textButtonRed}>{t('common:Save')}</Text>
          </TouchableOpacity>
        </View>
      )
    )
  }

  const addNewSuspendItem = () => {
    const defaultLevelItem = convertArrayToOption(InternalSuspendInformationConst.levelList).find(
      (x) => x.value === 'LV01'
    )
    const defaultStatus = InternalSuspendInformationConst.statusList().find((x) => x.value === Status.ACTIVE)
    userInfo && setValue('userEmail', userInfo.email)
    const effectiveTime = moment(new Date()).add(15, 'minutes').toDate()
    append({
      suspendType: undefined,
      departmentCode: '',
      departmentName: '',
      level: defaultLevelItem,
      status: defaultStatus,
      SLA: '',
      effectiveTime: effectiveTime,
      dueDateText: '',
      dueDateVal: '',
      to: '',
      cc: '',
      content: ''
    })
  }

  const getSuspendGroupCode = () => {
    switch (category) {
      case TaskType.Complaint:
        return 'INT1'
      case TaskType.CustomerService:
        return 'INT2'
      case TaskType.Claim:
        return 'INT3'
      default:
        return ''
    }
  }

  const getMailGroupCode = (departmentShortName: string, index: number, emailType: string) => {
    let mailGroupCode = ''
    const suspendGroupCode = getSuspendGroupCode()
    const levelItem = watch(`suspendList.${index}.level`)
    const levelNameEn = InternalSuspendInformationConst.levelList.find((x) => x.code === levelItem?.value)?.description
    switch (suspendGroupCode) {
      case 'INT1':
        mailGroupCode = TaskType.Complaint + '-' + departmentShortName + '-' + levelNameEn + '-' + emailType
        break
      case 'INT2':
        mailGroupCode = TaskType.CustomerService + '-' + departmentShortName + '-' + levelNameEn + '-' + emailType
        break
      case 'INT3':
        mailGroupCode = TaskType.Claim + '-' + departmentShortName + '-' + levelNameEn + '-' + emailType
        break
      default:
        mailGroupCode = ''
    }
    return mailGroupCode
  }

  const getToEmailList = (departmentShortName: string, index: number, isToEmail: boolean) => {
    const emailType = isToEmail ? 'TO' : 'CC'
    const mailGroupCode = getMailGroupCode(departmentShortName, index, emailType)
    pipe(
      InternalFeedbackService.loadToEmailList(mailGroupCode),
      ZIO.map((emailList) => {
        if (!!emailList && emailList.length > 0) {
          const emailItem = emailList.find((x) => x?.mailGroup === mailGroupCode)?.email ?? ''
          isToEmail && setValue(`suspendList.${index}.to`, emailItem)
          !isToEmail && setValue(`suspendList.${index}.cc`, emailItem)
        } else {
          isToEmail && setValue(`suspendList.${index}.to`, '')
          !isToEmail && setValue(`suspendList.${index}.cc`, '')
        }
      }),
      ErrorHandling.run()
    )
  }

  const editSLAByLevel = (index: number, departmentItem: InternalFeedbackService.SuspendDepartmentInfo) => {
    let SLAValue = ''
    const levelItem = watch(`suspendList.${index}.level`)?.value
    switch (levelItem) {
      case 'LV01':
        SLAValue = departmentItem?.slaHourNormal ?? ''
        setValue(`suspendList.${index}.SLA`, SLAValue)
        break
      case 'LV02':
        SLAValue = departmentItem?.slaHourUrgent ?? ''
        setValue(`suspendList.${index}.SLA`, SLAValue)
        break
      case 'LV03':
        SLAValue = departmentItem?.slaHourCritical ?? ''
        setValue(`suspendList.${index}.SLA`, SLAValue)
        break
      default:
        break
    }
    return SLAValue
  }

  const setDueDate = (SLAValue: string, index: number, effectiveTime: Date | null) => {
    // calculate dueDate base on effectiveTime,SLA,working time list
    let nextDay = ''
    let estimatedTime: moment.Moment = moment()
    let originSLAMinutes = Number(SLAValue) * 60
    let originEffectiveTime = moment(effectiveTime)
    for (let i = 0; i < workingTimeList.length && i + 1 < workingTimeList.length; i++) {
      // calculate in working time
      if (!!workingTimeList[i].startTime && !!workingTimeList[i].endTime) {
        const startDate = workingTimeList[i].date + ' ' + workingTimeList[i].startTime
        const endDate = workingTimeList[i].date + ' ' + workingTimeList[i].endTime
        // if startDate > originEffectiveTime, We set value variable originEffectiveTime to equal with startdate
        if (moment(startDate).valueOf() >= originEffectiveTime.valueOf()) {
          const newEffectiveValue = moment(startDate).valueOf() - originEffectiveTime.valueOf()
          originEffectiveTime = moment(originEffectiveTime.valueOf() + newEffectiveValue)
        }
        if (moment(endDate).valueOf() > originEffectiveTime.valueOf()) {
          const SLAHour = Math.floor(originSLAMinutes / 60)
          const SLAMinutes = Math.round((originSLAMinutes / 60 - SLAHour) * 60)
          estimatedTime = moment(originEffectiveTime).add(SLAHour, 'hours').add(SLAMinutes, 'minutes')
          // if estimatedTime less than endDate, we get valid estimatedTime and  break loop
          if (moment(endDate).valueOf() >= estimatedTime.valueOf()) {
            break
          }
          // else we get distanceMinutes and set originEffectiveTime = nextDay
          const distanceMinutes = moment(endDate).diff(originEffectiveTime, 'minutes')
          nextDay = workingTimeList[i + 1].date + ' ' + workingTimeList[i + 1].startTime
          originEffectiveTime = moment(nextDay)
          originSLAMinutes = originSLAMinutes - distanceMinutes
        } else {
          // if originEffectiveTime > endDate, we will set originEffectiveTime = next working day
          if (workingTimeList[i + 1].startTime) {
            nextDay = workingTimeList[i + 1].date + ' ' + workingTimeList[i + 1].startTime
            originEffectiveTime =
              moment(nextDay).valueOf() > originEffectiveTime.valueOf() ? moment(nextDay) : originEffectiveTime
          } else if (workingTimeList[i + 1].endTime) {
            nextDay = workingTimeList[i + 1].date + ' ' + workingTimeList[i + 1].endTime
            originEffectiveTime =
              moment(nextDay).valueOf() > originEffectiveTime.valueOf() ? moment(nextDay) : originEffectiveTime
          }
        }
      }
    }
    const dueDate = !!effectiveTime ? estimatedTime.format('DD/MM/YYYY HH:mm') : ''
    setValue(`suspendList.${index}.dueDateText`, dueDate)
    setValue(`suspendList.${index}.dueDateVal`, estimatedTime.toISOString())
  }

  const deleteSuspendItem = (index: number) => {
    remove(index)
  }

  const convertArrayToOption = (dataList: InternalSuspendInformationConst.LevelInfoData[]) => {
    return dataList.map((level) => ({
      value: level.code,
      label: i18n.language === 'en' ? level.description : level.descriptionVi
    }))
  }

  const showOptionLanguage = () => {
    return i18n.language === 'en' ? 'Select' : 'Chọn'
  }

  const onChangeSuspendTypeEvent = (suspendTypeCode: string, index: number) => {
    pipe(
      InternalFeedbackService.getDeparmentInfo(suspendTypeCode),
      ZIO.map((responseData) => {
        if (!!responseData) {
          const departmentItem = T31DepartmentList.find(
            (x) => x.departmentShortName === responseData.departmentShortName
          )
          const departmentName = i18n.language === 'en' ? departmentItem?.departmentEN : departmentItem?.departmentVN
          const fullDepartmentName = (departmentItem?.departmentCode ?? '') + '-' + departmentName
          const SLAValue = editSLAByLevel(index, responseData)
          setDepartmentInfo(responseData)
          setValue(`suspendList.${index}.departmentShortName`, responseData.departmentShortName)
          setValue(`suspendList.${index}.departmentCode`, departmentItem?.departmentCode ?? '')
          setValue(`suspendList.${index}.departmentName`, fullDepartmentName)
          setDueDate(SLAValue, index, watch(`suspendList.${index}.effectiveTime`))
          getToEmailList(responseData.departmentShortName, index, true)
          getToEmailList(responseData.departmentShortName, index, false)
        }
      }),
      ErrorHandling.run()
    )
  }

  const onChangeLevelEvent = (index: number) => {
    const departmentShortName = watch(`suspendList.${index}.departmentShortName`)
    const SLAValue = editSLAByLevel(index, departmentInfo)
    getToEmailList(departmentShortName, index, true)
    getToEmailList(departmentShortName, index, false)
    setDueDate(SLAValue, index, watch(`suspendList.${index}.effectiveTime`))
  }

  const onChangeEffectiveTimeEvent = (timeValue: Date | null, index: number) => {
    if (moment(timeValue).isValid()) {
      const SLAValue = watch(`suspendList.${index}.SLA`)
      setDueDate(SLAValue, index, timeValue)
    }
  }

  const validateInternalSuspendDataForSave = () => {
    let isDuplicatedDepartment = false
    const internalSuspendList = watch('suspendList')
    for (let i = 0; i < internalSuspendList.length - 1; i++) {
      const historyItem = internalSuspendList[i]
      const duplicationFlag = internalSuspendList.some(
        (oldItem, index) =>
          i !== index &&
          historyItem.departmentCode === oldItem.departmentCode &&
          historyItem.status?.value === Status.ACTIVE &&
          oldItem.status?.value === Status.ACTIVE
      )
      if (duplicationFlag) {
        isDuplicatedDepartment = true
        break
      }
    }
    return isDuplicatedDepartment
  }

  const mappingNewInternalSuspendData = () => {
    const userEmail = watch('userEmail')
    const timeZone = Intl.DateTimeFormat('en-US', { timeZoneName: 'short' }).resolvedOptions().timeZone
    const internalSuspendList = watch('suspendList').map((suspendItem) => {
      const effectiveTime = suspendItem.effectiveTime?.toISOString()
      const newInternalSuspend: InternalFeedbackService.InsertedInternalSuspendData = {
        id: undefined,
        status: suspendItem.status?.value ?? '',
        suspendCode: suspendItem.suspendType?.value ?? '',
        user: userEmail,
        slaHour: suspendItem.SLA,
        effectiveDate: effectiveTime ?? '',
        dueDate: suspendItem.dueDateVal,
        department: suspendItem.departmentCode,
        level: suspendItem.level?.value ?? '',
        content: suspendItem.content ?? '',
        emailTo: suspendItem.to ?? '',
        emailCc: suspendItem.cc ?? '',
        timeZone: timeZone
      }
      return newInternalSuspend
    })
    const submitedInternalSuspendData: InternalFeedbackService.SavedInternalSuspendData = {
      data: internalSuspendList,
      processInstanceId: businessKey
    }
    return submitedInternalSuspendData
  }

  const AddInternalSuspend = async () => {
    const isValidForm = await trigger()
    const isDuplicatedDepartment = validateInternalSuspendDataForSave()
    if (isValidForm && !isDuplicatedDepartment) {
      const newInternalSuspendData = mappingNewInternalSuspendData()
      pipe(
        InternalFeedbackService.saveNewInternalSuspend(newInternalSuspendData),
        ZIO.map((responseData) => {
          if (!!responseData) {
            if (responseData.responseError.status === 'Success') {
              cancelButtonEvent()
              showToast(t('message:MS990017'), 'success')
            } else {
              showToast(t(`message:${responseData.responseError.code}`), 'error')
            }
          }
          return responseData
        }),
        bindLoader,
        ErrorHandling.run()
      )
    } else if (isDuplicatedDepartment) {
      showToast(t(`message:MS050270`), 'error')
    }
  }

  const cancelButtonEvent = () => {
    const defaultLevelItem = convertArrayToOption(InternalSuspendInformationConst.levelList).find(
      (x) => x.value === 'LV01'
    ) ?? { label: '', value: '' }
    const defaultStatus = InternalSuspendInformationConst.statusList().find((x) => x.value === Status.ACTIVE) ?? {
      label: '',
      value: ''
    }
    const suspendItem = {
      suspendType: null,
      departmentCode: '',
      departmentName: '',
      departmentShortName: '',
      level: defaultLevelItem,
      status: defaultStatus,
      SLA: '',
      effectiveTime: moment(new Date()).add(15, 'minutes').toDate(),
      dueDateText: '',
      dueDateVal: '',
      to: '',
      cc: '',
      content: ''
    }
    let i = watch('suspendList').length - 1
    while (i > 0) {
      remove(i)
      i--
    }
    setValue(`suspendList.${i}`, suspendItem)
  }

  return (
    <View style={internalInfoStyles.container}>
      <Title wrapperStyle={internalInfoStyles.noMarginBottom} title={t('InternalSuspend:AddInternalSuspend')}></Title>
      {fields &&
        fields.length > 0 &&
        fields.map((fieldItem, index) => (
          <SectionContent key={fieldItem.id}>
            <SectionRow>
              <SectionCol>
                <Controller
                  control={control}
                  name={`suspendList.${index}.suspendType`}
                  render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                    <SelectSearch
                      required={true}
                      label={t('InternalSuspend:SuspendType')}
                      options={suspendTypeList.map((item) => ({
                        value: item.code,
                        label: i18n.language === 'en' ? item.typeEn : item.typeVn
                      }))}
                      popupIcon={<assets.ArrowDownDropdownIcon />}
                      onChange={(val) => {
                        onChange(val)
                        onChangeSuspendTypeEvent(val?.value ?? '', index)
                      }}
                      placeholder={showOptionLanguage()}
                      disabled={disabled}
                      onBlur={onBlur}
                      value={value}
                      key={value?.value}
                      errorMessage={error?.message}
                    />
                  )}
                />
              </SectionCol>
              <SectionCol>
                <FieldText text={t('InternalSuspend:Department')}></FieldText>
                <FieldText
                  fieldStyles={internalInfoStyles.field_description}
                  text={watch(`suspendList.${index}.departmentName`)}
                ></FieldText>
              </SectionCol>
              <SectionCol>
                <Controller
                  control={control}
                  name={`suspendList.${index}.level`}
                  render={({ field: { onChange, value, onBlur } }) => (
                    <SelectSearch
                      label={t('InternalSuspend:Level')}
                      options={convertArrayToOption(InternalSuspendInformationConst.levelList)}
                      popupIcon={<assets.ArrowDownDropdownIcon />}
                      disabled={disabled}
                      onChange={(val) => {
                        onChange(val)
                        onChangeLevelEvent(index)
                      }}
                      onBlur={onBlur}
                      value={value}
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={internalInfoStyles.secondLine}>
              <SectionCol>
                <Controller
                  control={control}
                  name={`suspendList.${index}.status`}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <Select
                      label={t('common:Status')}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      disabled={disabled}
                      options={InternalSuspendInformationConst.statusList().filter((p) => p.value !== Status.COMPLETE)}
                      errorMessage={!!value?.value ? '' : error?.message}
                    />
                  )}
                />
              </SectionCol>
              <SectionCol>
                <FieldText text={t('InternalSuspend:ServiceLevelAgreement')}></FieldText>
                <FieldText
                  fieldStyles={internalInfoStyles.field_description}
                  text={watch(`suspendList.${index}.SLA`)}
                ></FieldText>
              </SectionCol>
              <SectionCol>
                <Controller
                  control={control}
                  name={`suspendList.${index}.effectiveTime`}
                  render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                    <CustomedDateTimePicker
                      label={t('InternalSuspend:EffectiveTime')}
                      alwaysShow={true}
                      disabled={disabled}
                      hideUnderline={true}
                      onChange={(val) => {
                        onChange(val)
                        onChangeEffectiveTimeEvent(val, index)
                      }}
                      onBlur={onBlur}
                      value={value}
                      minDate={new Date()}
                      errorMessage={error?.message}
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={internalInfoStyles.secondLine}>
              <SectionCol>
                <FieldText text={t('InternalSuspend:DueDate')}></FieldText>
                <FieldText
                  fieldStyles={internalInfoStyles.field_description}
                  text={watch(`suspendList.${index}.dueDateText`)}
                ></FieldText>
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={internalInfoStyles.secondLine}>
              <SectionCol>
                <Title title={t('InternalSuspend:EmailGroup')}></Title>
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={internalInfoStyles.secondLine}>
              <SectionCol sectionStyles={internalInfoStyles.col_12}>
                <Controller
                  control={control}
                  name={`suspendList.${index}.to`}
                  render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                    <MultilineInput
                      title={t('To')}
                      value={value ?? ''}
                      disabled={disabled}
                      onChange={onChange}
                      multiline={true}
                      onBlur={onBlur}
                      required={true}
                      errorMessage={!!value ? '' : error?.message}
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={internalInfoStyles.secondLine}>
              <SectionCol sectionStyles={internalInfoStyles.col_12}>
                <Controller
                  control={control}
                  name={`suspendList.${index}.cc`}
                  render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                    <MultilineInput
                      title={t('CC')}
                      value={value ?? ''}
                      disabled={disabled}
                      onChange={onChange}
                      multiline={true}
                      onBlur={onBlur}
                      required={true}
                      errorMessage={!!value ? '' : error?.message}
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={internalInfoStyles.secondLine}>
              <SectionCol sectionStyles={internalInfoStyles.col_12}>
                <Controller
                  control={control}
                  name={`suspendList.${index}.content`}
                  render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                    <Input
                      title={t('InternalSuspend:Content')}
                      required
                      maxLength={5000}
                      multiline
                      numberOfLines={5}
                      disabled={disabled}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value ?? ''}
                      errorMessage={!!value ? '' : error?.message}
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={internalInfoStyles.secondLine}>
              <SectionCol sectionStyles={internalInfoStyles.col_12}>
                {!disabled && (
                  <View style={internalInfoStyles.btnContent}>
                    <TouchableOpacity onPress={() => deleteSuspendItem(index)}>
                      <View style={internalInfoStyles.btnAdd}>
                        <Text style={internalInfoStyles.btn_text}>{t('common:Delete')}</Text>
                      </View>
                    </TouchableOpacity>
                  </View>
                )}
              </SectionCol>
            </SectionRow>
          </SectionContent>
        ))}
      <SectionRow sectionStyles={internalInfoStyles.secondLine}>
        <SectionCol sectionStyles={internalInfoStyles.col_12}>
          {!disabled && (
            <View style={internalInfoStyles.btnContent}>
              <TouchableOpacity onPress={() => addNewSuspendItem()}>
                <View style={internalInfoStyles.btnAdd}>
                  <Text style={internalInfoStyles.btn_text}>{t('InternalSuspend:AddSuspend')}</Text>
                </View>
              </TouchableOpacity>
            </View>
          )}
        </SectionCol>
      </SectionRow>
      <SectionRow sectionStyles={internalInfoStyles.secondLine}>
        <SectionCol sectionStyles={internalInfoStyles.col_12}>
          {['PS', 'CH', 'CS'].includes(category) && (
            disabled ? (
              <></>
            ) : (
              <View style={[internalInfoStyles.buttonContainer, !isWide && { width: '100%', justifyContent: 'center' }]}>
                <TouchableOpacity
                  style={[sharedStyle.button, { marginRight: 15, borderColor: '#ED1B2E' }]}
                  onPress={cancelButtonEvent}
                >
                  <Text style={sharedStyle.textButton}>{t('common:Cancel')}</Text>
                </TouchableOpacity>
                <TouchableOpacity style={[sharedStyle.button, sharedStyle.btnRed]} onPress={AddInternalSuspend}>
                  <Text style={sharedStyle.textButtonRed}>{t('common:Save')}</Text>
                </TouchableOpacity>
              </View>
            )
          )}
        </SectionCol>
      </SectionRow>
    </View>
  )
}
export const internalInfoStyles = StyleSheet.create({
  container: {
    marginBottom: 80
  },
  noMarginBottom: {
    marginBottom: 0
  },
  secondLine: {
    marginTop: 20
  },
  rightAlign: {
    textAlign: 'right'
  },
  col_12: {
    width: '100%',
    maxWidth: '100%',
    paddingHorizontal: 15
  },
  actionBtn: {
    backgroundColor: '#ED1B2E',
    borderRadius: 100,
    paddingHorizontal: 20,
    paddingVertical: 10
  },
  cancelBtn: {
    paddingHorizontal: 20,
    paddingVertical: 10
  },
  btnContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  btnAdd: {
    minHeight: 40,
    width: '100%',
    borderRadius: 100,
    paddingHorizontal: 25,
    paddingVertical: 10,
    borderColor: '#ED1B2E',
    borderWidth: 1
  },
  btnDelete: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  btn_text: {
    fontSize: 15,
    lineHeight: 20,
    color: '#ED1B2E',
    fontWeight: 'bold'
  },
  field_description: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 15,
    lineHeight: 22,
    color: '#000000'
  },
  buttonContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 70
  }
})
