import * as React from 'react'
import { useMobile, TaskCount, TaskType, TeamTaskPermissions, MyTaskPermissions, Permission } from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet, TouchableOpacity, Text, ScrollView, ViewStyle } from 'react-native'
import { CanView } from '../../../common/src'

export enum Type {
  TeamTask,
  MyTask
}

interface Props {
  type: Type
  permissions: String[]
  data: TaskCount[]
  containerStyle?: ViewStyle
  onTabChanged?: (taskGroup: TaskCount) => void
}

export const TaskGroupTabs = ({ type, permissions = [], data = [], containerStyle = {}, onTabChanged }: Props) => {
  const { isWide } = useMobile()
  const { t } = useTranslation('TaskManagement')

  const [currentItemIndex, setCurrentItemIndex] = React.useState(0)
  const [tabs, setTabs] = React.useState<TaskCount[]>([])

  // React.useEffect(() => {
  //   if (data.length > 0) {
  //     const tabsVisible: TaskCount[] = data.filter((item) => checkPerrmission(item.type))
  //     console.log(tabsVisible, 'tabsVisible')
  //     setTabs(tabsVisible)

  //     if (currentItemIndex >= 0 && currentItemIndex < tabsVisible.length) {
  //       onTabChanged && onTabChanged(tabsVisible[currentItemIndex])
  //     }
  //   }
  // }, [currentItemIndex, data])

  React.useEffect(() => {
    if (data.length > 0) {
      const tabsVisible: TaskCount[] = data.filter((item) => checkPerrmission(item.type))
      // console.log(tabsVisible, 'tabsVisible')
      setTabs(tabsVisible)
      if (currentItemIndex >= 0) {
        onTabChanged && onTabChanged(tabsVisible[currentItemIndex])
      }
    }
  }, [data])


  const checkPerrmission = (taskType: TaskType) => {
    let basketPermission: { [key: string]: Permission } = {}

    switch (taskType) {
      case TaskType.PolicyService:
        basketPermission = type === Type.TeamTask ? TeamTaskPermissions.PS : MyTaskPermissions.PS
        break
      case TaskType.DistributionService:
        basketPermission = type === Type.TeamTask ? TeamTaskPermissions.DS : MyTaskPermissions.DS
        break
      case TaskType.Complaint:
        basketPermission = type === Type.TeamTask ? TeamTaskPermissions.CH : MyTaskPermissions.CH
        break
      case TaskType.Claim:
        basketPermission = type === Type.TeamTask ? TeamTaskPermissions.CL : MyTaskPermissions.CL
        break
      case TaskType.CustomerService:
        basketPermission = type === Type.TeamTask ? TeamTaskPermissions.CS : MyTaskPermissions.CS
        break
      case TaskType.Underwriting:
        basketPermission = type === Type.TeamTask ? TeamTaskPermissions.UW : MyTaskPermissions.UW
        break
      case TaskType.PremiumCollection:
        basketPermission = type === Type.TeamTask ? TeamTaskPermissions.PC : MyTaskPermissions.PC
        break
      case TaskType.ErrorHandler:
        basketPermission = type === Type.TeamTask ? TeamTaskPermissions.EH : {}
        break
      case TaskType.IDC:
        basketPermission = type === Type.TeamTask ? TeamTaskPermissions.IDC : MyTaskPermissions.IDC
        break
    }

    const hasPermission =
      permissions.filter((p) => Object.values(basketPermission).includes(p as Permission)).length > 0

    if (taskType === TaskType.ErrorHandler) {
      return (
        hasPermission &&
        permissions.includes(
          type === Type.TeamTask
            ? TeamTaskPermissions.ViewErrorHandlerTeamTask
            : MyTaskPermissions.ViewErrorHandlerMyTask
        )
      )
    }
    // if (taskType === TaskType.DistributionService) {
    //   return true
    // }

    return hasPermission
  }

  return (
    <View style={containerStyle}>
      <ScrollView horizontal={true} style={{ paddingBottom: 6 }} showsHorizontalScrollIndicator={false}>
        {tabs.map(({ label, value, type }, index) => {
          const isActiveItem = index === currentItemIndex
          const isFinalItem = index === data.length - 1
          return (
            <TouchableOpacity
              key={index}
              activeOpacity={1}
              style={[styles.itemContainer, { marginEnd: isFinalItem ? 0 : 40 }]}
              // onPress={() => setCurrentItemIndex(index)}
              onPress={() => {
                setCurrentItemIndex(index)
                onTabChanged && onTabChanged(tabs[index])
              }}
            >
              <View style={styles.titleContainer}>
                <Text style={[styles.title, { fontWeight: isActiveItem ? 'bold' : 'normal' }]}>
                  {label && label(t)}
                </Text>
                <CanView condition={isActiveItem}>
                  <View style={styles.activeUnderline} />
                </CanView>
              </View>
              <CanView condition={isWide}>
                <View style={[styles.counterContainer, { paddingHorizontal: value > 99 ? 4 : 0 }]}>
                  <Text style={styles.count}>{value}</Text>
                </View>
              </CanView>
            </TouchableOpacity>
          )
        })}
      </ScrollView>
      <CanView condition={data.length > 0 && isWide}>
        <View style={styles.underline} />
      </CanView>
    </View>
  )
}

const styles = StyleSheet.create({
  underline: {
    height: 1,
    backgroundColor: '#C4C4C4',
    position: 'absolute',
    width: '100%',
    start: 0,
    bottom: 1,
    zIndex: -1
  },

  itemContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  },

  titleContainer: {
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },

  title: {
    color: '#4F4F4F',
    fontSize: 15,
    marginEnd: 4
  },

  activeUnderline: {
    backgroundColor: '#ED1B2E',
    height: 3,
    width: '100%',
    borderRadius: 3,
    position: 'absolute',
    start: 0,
    bottom: -6
  },

  counterContainer: {
    minWidth: 24,
    height: 24,
    borderRadius: 24,
    borderColor: '#ED1B2E',
    borderWidth: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },

  count: {
    color: '#ED1B2E',
    fontSize: 13,
    fontWeight: '600',
    lineHeight: 20
  }
})
