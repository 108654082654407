import React from 'react'

export type IDCDetailContextInfo = {
  caseID: string
  idcType: string
  transactionType: string
  processInstanceID: string
  OBSelectedCaseID: string
  proposalNumber: string
  category: string
  policyNumber: string
  setIsActiveTabIndex: (tabIndex: number) => void
  setOBSelectedCaseID: (caseID: string) => void
}

const defaultContextVal: IDCDetailContextInfo = {
  caseID: '',
  idcType: '',
  transactionType: '',
  processInstanceID: '',
  OBSelectedCaseID: '',
  proposalNumber: '',
  category: '',
  policyNumber: '',
  setIsActiveTabIndex: (_) => {},
  setOBSelectedCaseID: (_) => {}
}

export const IDCDetailContext = React.createContext<IDCDetailContextInfo>(defaultContextVal)