import React from 'react'
import { CallOutDetailContext, OBADDHOCSpecialInformation, OBCallProgramTypeCode, OBMaturitySpecialInformation, OBRMDBAUSpecialInformation, OBRMDSpecialInformation, OBSQCBSpecialInformation, OBWCCSpecialInformation } from '@pulseops/outbound'
import { OBAplAndLapsedSpecialInformation } from './ob-apl-and-lapsed-special-info/OB-APL-and-LAPSED-Special-Information'

export const OBSpecialInformation = () => {
  // const { t } = useTranslation()
  // const [dataSpecialInfo, setDataSpecialInfo] = React.useState<OBSpecialInfoData | null>(null)
  // const [loading, bindLoader] = useLoading(false)
  const { transactionType } = React.useContext(CallOutDetailContext)
  // const { showGlobalLoading } = React.useContext(AppContext.AppContextInstance)

  const showContentByTransactionType = () => {
    switch (transactionType) {
      case OBCallProgramTypeCode.RMD:
      case OBCallProgramTypeCode.CARE1:
      case OBCallProgramTypeCode.CARE2:
        return <OBRMDSpecialInformation></OBRMDSpecialInformation>
      case OBCallProgramTypeCode.ADHOCCCE:
      case OBCallProgramTypeCode.ADHOCCS:
        return <OBADDHOCSpecialInformation></OBADDHOCSpecialInformation>
      case OBCallProgramTypeCode.RMDBAU:
        return <OBRMDBAUSpecialInformation></OBRMDBAUSpecialInformation>
      case OBCallProgramTypeCode.WCC:
        return <OBWCCSpecialInformation></OBWCCSpecialInformation>
      case OBCallProgramTypeCode.MA1:
      case OBCallProgramTypeCode.MA2:
        return <OBMaturitySpecialInformation></OBMaturitySpecialInformation>
      case OBCallProgramTypeCode.APL:
        return <OBAplAndLapsedSpecialInformation isAPL></OBAplAndLapsedSpecialInformation>
      case OBCallProgramTypeCode.LAPSED:
        return <OBAplAndLapsedSpecialInformation isLAPSED></OBAplAndLapsedSpecialInformation>
      case OBCallProgramTypeCode.SQCB:
      default:
        return <OBSQCBSpecialInformation></OBSQCBSpecialInformation>
    }
  }

  return <>{showContentByTransactionType()}</>
}
