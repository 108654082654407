export * from './Policy'
export * from './loan'
export * from './Client'
export * from './Source'
export * from './SourceType'
export * from './TaskSource'
export * from './TaskType'
export * from './TransactionType'
export * from './Vip'
export * from './TransactionStatus'
export * from './QueryTask'
export * from './TaskOp'
export * from './HistoricTask'
export * from './WfDate'
export * from './SupplementaryInfo'
export * from './GeneralInfo'
export * from './SuspendTask'
export * from './ORG'
export * from './WFComment'
export * from './History'
export * from './QCComplaint'
export * from './RelatedDoc'
export * from './CashOutOption'
export * from './Payout'
export * from './RequestAuthEform'
export * from './general-info.model'
export * from './Locale'
export * from './CancelRider'
export * from './ReportType'

export * from './AddLackOfDocument'
export * from './Assignment'
export * from './BillingChange'
export * from './BillingChangeReversal'
export * from './Phone'
export * from './OptionField'
export * from './ChangeContactInfo'
export * from './ConfirmOfPremium'
export * from './ExcessPremium'
export * from './ChangeClientInfo'
export * from './MaturityAdvance'
export * from './CancelFromInception'
export * from './MaturityPayment'
export * from './PruCashPayment'
export * from './Bank'
export * from './Payout'
export * from './Topup'
export * from './EService'
export * from './Reinstatement'
export * from './LoanStatements'
export * from './PolicyCertificate'
export * from './ExcellentStudentAward'
export * from './InquiryComplaint'
export * from './LBU'
export * from './BonusSurrender'
export * from './PremiumRedirection'
export * from './FundSwitching'
export * from './SignatureRegistration'
export * from './FatcaDeclaration'
export * from './StopApl'
export * from './BeneficiaryDesignation'
export * from './Inheritance'
export * from './PruKid369'
export * from './PartialWithdrawal'
export * from './ConfirmLetter'
export * from './FullSurrenderReversal'
export * from './FullSurrender'
export * from './ProductCode'
export * from './GoAbroad'
export * from './ben.enum'
export * from './CashOut'
export * from './Fund'
export * from './Frequency'
export * from './Contract'
export * from './PremiumStatus'
export * from './QueryTaskResponse'
export * from './EFormBillingChange'
export * from './PaymentMethod'
export * from './LapseReins'
export * from './LoanEnqueryResponse'
export * from './SubTasksResponse'
export * from './GetCommentListResponse'
export * from './GetBanksResponse'
export * from './MoreData'
export * from './FundCode'
export * from './PolicyInquiry'
export * from './PaymentType'
export * from './DigitalPolicy'
export * from './PolicyDigital'
export * from './TopupOption'
export * from './mock/Wf'
export * from './CommonRequest'
export * from './mock/Occupation'
export * from './IheatData'
export * from './IheatDataDetail'
export * from './UploadDocument'
export * from './Category'
export * from './ServiceInquiryNote'
export * from './TransactionStatus'
export * from './mock/T0'
export * from './mock/Duration'
export * from './SpecialBonus'
export * from './OpEformDetail'
export * from './PaidUp'
export * from './SpecialBonus'
export * from './BonusSurrender'
export * from './InquiryComplaint'
export * from './AMLResult'
export * from './mock/Relationship'
export * from './ChangeClientInfo'
export * from './Redating'
export * from './mock/T18'
export * from './product-option-switching'
export * from './RiderReinstatement'
export * from './RiderAlteration'
export * from './Journal'
export * from './MajorCombined'
export * from './mock/Banks'
export * from './report/error-handler/ErrorActivity'
export * from './report/error-handler/HistoricProcessInstances'
export * from './AccountingCashout'
export * from './AdminPageData'

import * as TaskDetailApi from './task-detail-api'
import * as TaskDetail from './task-detail'

export { TaskDetail, TaskDetailApi }
