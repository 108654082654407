// React Lib
import React, { useCallback, useContext, useState } from 'react'
import { RouteProp, useRoute } from '@react-navigation/native'
import { isEmpty, isNil } from 'lodash'
import { useTranslation } from 'react-i18next'
import { pipe } from 'fp-ts/lib/function'
import { findIndex } from 'fp-ts/Array'
import { matchW } from 'fp-ts/Option'
import { ZIO } from '@mxt/zio'
import moment from 'moment'
import { useForm } from 'react-hook-form'

// Common
import { PSSubmissionStackParamList } from '../../../PSSubmissionStackParamList'
import {
  COLORS,
  FieldsType,
  groupsViewsConst,
  GroupsViewType,
  HiddenType,
  typeField,
  TypeFieldEnum,
  typeGroupName,
  typeNameField,
  valuesFormType
} from './change-dob-and-gender-const'
import { Controller, ControllerFieldState, ControllerRenderProps, UseFormStateReturn } from 'react-hook-form'
import {
  SelectSearch,
  assets,
  DatePicker,
  SelectOption,
  TransactionType,
  Checkbox,
  ErrorHandling,
  PulseOpsFormat,
  ChangeClientInfoService,
  ChangeClientInfo,
  TaskType,
  AppContext,
  ImgUploadMutiple,
  FileUploadData
} from '@pulseops/common'
import { CN, TextCustom } from './change-dob-and-gender-style'
import { RequestAuthenticateData } from '../../request-authen'
import { ChangeDOBAndGenderService } from './change-dob-and-gender-service'
import { UploadedFilesInfo } from '../policy-service-props'
import { ChangeDOBGenderForm } from './change-dob-and-gender-form'

const { Container, Row, Col_3, GROUP, TabText } = CN

interface Props {
  isConfirmed: boolean
  officeCode?: string
  initSubmission: ({ validate, clear }: { validate: any; clear: () => void }) => void
}

const defaultValues: ChangeDOBGenderForm = {
  selectClient: null,
  dob: null,
  gender: null,
  isCheckChangeDOBGroup: true,
  isCheckChangeGenderGroup: true
}

const ChangeDOBAndGenderScreen: React.FC<Props> = ({ initSubmission, isConfirmed }) => {
  const { t } = useTranslation('landingPage')
  const { t: translateDOBandGender, i18n } = useTranslation('CHANGE_DOB_GENDER')
  const [groupsViews, setGroupsViews] = useState<GroupsViewType<typeGroupName, typeNameField>[] | []>(
    () => groupsViewsConst
  )
  const [files, setFiles] = useState<FileUploadData[] | null>(null)
  const [lstCustomer, setLstCustomer] = React.useState<ChangeClientInfo.CustomerData[]>([])

  const { control, getValues, reset, trigger, setValue } = useForm({
    defaultValues,
    mode: 'all'
  })

  const { params } = useRoute<RouteProp<PSSubmissionStackParamList, 'PSSubmissionScreen'>>()
  const { showToast } = useContext(AppContext.AppContextInstance)

  const getRoles = useCallback(
    (role: string) => {
      return role === 'PolicyOwner'
        ? t('roles:PolicyOwner')
        : role === 'Beneficiary'
        ? t('roles:Beneficiary')
        : role === 'LifeAssured'
        ? t('roles:LifeAssured')
        : '-'
    },
    [t]
  )

  pipe(
    ChangeClientInfoService.getDetail(params.policyNum),
    ZIO.map((detail) => {
      if (isEmpty(detail)) return

      const groupsViewsClone = [...groupsViews]

      const clients = detail.customerData.map((dc) => ({
        value: dc.customerId,
        label: `${dc.surName ?? ''} ${dc.name} - ${getRoles(dc.role)}`
      }))

      const infoChangeClientGroup = groupsViewsClone.find((elm) => elm.groupName === 'info_change_client_group')
      const groupsViewWithNewData: FieldsType<typeNameField>[] = [
        {
          ...(infoChangeClientGroup?.fields.find((group) => group.formName === 'selectClient') ??
            ({} as FieldsType<typeNameField>)),
          options: clients
        }
      ]

      setLstCustomer(detail.customerData)
      setGroupsViews((prev: GroupsViewType<typeGroupName, typeNameField>[]) => {
        return prev.fill(
          {
            ...infoChangeClientGroup,
            fields: ChangeDOBAndGenderService.replaceTwoArray<FieldsType<typeNameField>>(
              infoChangeClientGroup?.fields ?? [],
              groupsViewWithNewData ?? [],
              'formName'
            )
          } as GroupsViewType<typeGroupName, typeNameField>,
          0,
          1
        )
      })
    }),
    ErrorHandling.runDidUpdate([i18n.language])
  )

  /**
   * FUNCTIONS
   */
  const setValuesToForm = (values: valuesFormType[]) => values.map(({ key, value }) => setValue(key, value))

  const handleDefaultOptions = useCallback(
    (fieldName: typeNameField, options: SelectOption[] = []) => {
      const optionsDefault = new Map<typeNameField | 'default', SelectOption[]>([
        [
          'gender',
          [
            { label: t('common:Male'), value: 'M' },
            { label: t('common:Female'), value: 'F' }
          ]
        ],
        ['default', options]
      ])

      return optionsDefault.get(fieldName) ?? optionsDefault.get('default')
    },
    [i18n.language]
  )

  const handleChangeFieldForm = (fieldName: typeNameField, payload: SelectOption | null) => {
    const isSelectClient = fieldName === 'selectClient'

    if (!isSelectClient) return

    if (isNil(payload)) {
      setValuesToForm([
        { key: 'dob', value: null },
        { key: 'gender', value: null }
      ])
      return
    }
    const listCustomerClone = [...lstCustomer]
    const clientLASInfo = listCustomerClone.find((elm) => elm.customerId === payload.value)

    return setValuesToForm([
      { key: 'dob', value: moment(clientLASInfo?.dob, 'YYYY/MM/DD').toDate() },
      {
        key: 'gender',
        value: {
          value: clientLASInfo?.gender,
          label: clientLASInfo?.gender === 'F' ? t('common:Female') : t('common:Male')
        } as SelectOption
      }
    ])
  }

  const handleMappingRules = (formName: typeNameField) => {
    const { isCheckChangeDOBGroup, isCheckChangeGenderGroup } = getValues()
    const required = (isRequired: boolean, formName: typeNameField) => {
      return {
        value: isRequired,
        message: t('message:MS020001', {
          field: t(`CHANGE_DOB_GENDER:GROUPS_VIEWS.FIELDS.${formName}`)
        })
      }
    }

    const detectIsRequired = (): boolean => {
      if (['dob'].includes(formName)) return isCheckChangeDOBGroup
      if (['gender'].includes(formName)) return isCheckChangeGenderGroup

      return true
    }

    return {
      required: required(detectIsRequired(), formName),
      ...(formName === 'dob' && {
        validate: (date: any) => {
          if (date instanceof Date) {
            if (date.toString() === 'Invalid Date') return t('form:error_invalid')
            const today = new Date(new Date().toDateString())
            const birthday = new Date(new Date(date).toDateString())
            if (birthday >= today) return t('message:MS050022')
          }
          return true
        }
      })
    }
  }

  /**
   * VIEWS
   */
  const renderTypeField = ({
    field,
    controller
  }: {
    field: FieldsType<typeNameField>
    controller: {
      field: ControllerRenderProps<ChangeDOBGenderForm, typeNameField>
      fieldState: ControllerFieldState
      formState: UseFormStateReturn<ChangeDOBGenderForm>
    }
  }) => {
    const { type, placeholder, required, options } = field
    const {
      field: { onChange, onBlur, value },
      fieldState: { error }
    } = controller

    const currentDate = new Date()
    const yesterday = currentDate.setDate(currentDate.getDate() - 1)

    const fieldTypeMap = new Map<typeField | 'DEFAULT', Function>([
      [
        TypeFieldEnum['DATE_PICKER'],
        () => (
          <DatePicker
            label={translateDOBandGender(`GROUPS_VIEWS.FIELDS.${field.formName}`)}
            onChange={onChange}
            onBlur={onBlur}
            value={value as Date}
            errorMessage={!isEmpty(error) ? error?.message : ''}
            maxDate={yesterday}
            alwaysShow={false}
            disabled={isConfirmed}
            required={required}
            maxDateMessage=""
            minDateMessage=""
            invalidDateMessage=""
          />
        )
      ],
      [
        TypeFieldEnum['DROPDOWN_LIST'],
        () => (
          <SelectSearch
            label={translateDOBandGender(`GROUPS_VIEWS.FIELDS.${field.formName}`)}
            options={handleDefaultOptions(field.formName, options)}
            placeholder={placeholder}
            onChange={(e) => {
              onChange(e)
              handleChangeFieldForm(field.formName, e)
            }}
            onBlur={onBlur}
            value={value as SelectOption}
            disabled={isConfirmed}
            required={required}
            popupIcon={<assets.ArrowDownDropdownIcon />}
            errorMessage={!isEmpty(error) ? error?.message : ''}
            maxLength={100}
          />
        )
      ],
      ['DEFAULT', () => <></>]
    ])

    return (fieldTypeMap.get(type) ?? fieldTypeMap.get('DEFAULT'))?.()
  }

  const renderFields = (fields: FieldsType<typeNameField>[]) => {
    return fields.map((_field, index) => {
      const { formName } = _field
      return (
        <Col_3 key={index}>
          <Controller
            name={formName}
            control={control}
            render={(controller) => <>{renderTypeField({ field: _field, controller })}</>}
            rules={handleMappingRules(formName)}
          />
        </Col_3>
      )
    })
  }

  const renderCheckBox = (typeHidden: HiddenType = 'NONE', groupName: typeGroupName) => {
    const setValuesCheckBoxToForm = (value: boolean, _groupName: typeGroupName) => {
      const valuesCheckBox = [
        ...(groupName === 'change_dob_group' ? [{ key: 'isCheckChangeDOBGroup', value }] : ([] as valuesFormType[])),
        ...(groupName === 'change_gender_group'
          ? [{ key: 'isCheckChangeGenderGroup', value }]
          : ([] as valuesFormType[]))
      ] as valuesFormType[]

      setValuesToForm(valuesCheckBox)
    }

    const setValuesCheckBoxToGroupViews = (value: boolean, _groupName: typeGroupName) => {
      const groupsViewsCLone = [...groupsViews]
      const result = pipe(
        groupsViewsCLone,
        findIndex((elm) => elm.groupName === _groupName),
        matchW(
          () => [],
          (index) => groupsViewsCLone.fill({ ...groupsViewsCLone[index], isVisible: value }, index, index + 1)
        )
      )
      setGroupsViews(result)
    }

    const handleChangeCheckBox = (value: boolean, _groupName: typeGroupName) => {
      setValuesCheckBoxToForm(value, _groupName)
      setValuesCheckBoxToGroupViews(value, _groupName)
    }

    const hiddenTypeMap = new Map<HiddenType, Function>([
      [
        'CHECK_BOX',
        () => (
          <Checkbox
            value={groupsViews.find((elm) => elm.groupName === groupName)?.isVisible ?? false}
            disabled={isConfirmed}
            onChange={(value) => handleChangeCheckBox(value, groupName)}
          />
        )
      ],
      ['NONE', () => <></>]
    ])

    return (hiddenTypeMap.get(typeHidden) ?? hiddenTypeMap.get('NONE'))?.()
  }

  /**
   * Root Views
   */
  const renderGroupsViews = () => {
    return groupsViews?.map(({ fields, isVisible, labelGroup, isBorder, groupName, typeHidden }, index) => {
      return (
        <GROUP key={`${labelGroup}_${index}`}>
          <TextCustom fontSize="15px" fontWeight="800" textColor="black">
            {renderCheckBox(typeHidden, groupName)} {translateDOBandGender(`GROUPS_VIEWS.LABEL_GROUP.${groupName}`)}
          </TextCustom>

          {isVisible ? (
            <Row
              style={[
                { marginTop: 12 },
                isBorder
                  ? {
                      borderRadius: 6,
                      paddingHorizontal: 12,
                      paddingVertical: 30,
                      borderStyle: 'solid',
                      borderColor: COLORS.HOWKES_BLUE,
                      borderWidth: 1
                    }
                  : {}
              ]}
            >
              {renderFields(fields)}
            </Row>
          ) : null}
        </GROUP>
      )
    })
  }

  const renderUploadFiles = () => {
    return (
      <>
        <TextCustom textColor={COLORS.ROLLING_STONE} fontWeight="bold" fontSize="15px">
          {t('IFQ:FileAttachment')} <TabText>*</TabText>
        </TextCustom>
        <ImgUploadMutiple
          value={files ?? []}
          onChange={(files) => setFiles([...files])}
          timeFormat={'DD/MM/YYYY HH:mm'}
          maxNumFile={100}
        />

        {files && isEmpty(files) && (
          <TextCustom textColor={COLORS.ALIZARIN} fontStyle="italic" fontSize="13px">
            {t('message:MS020001', { field: t('utilities:FileAttachment') })}
          </TextCustom>
        )}
      </>
    )
  }

  /**
   * Submission
   */
  const mappingBodySubmisson = () => {
    const { selectClient, dob, gender, isCheckChangeDOBGroup, isCheckChangeGenderGroup } = getValues()
    const getCustommerBySelectClient = () => [...lstCustomer]?.find((item) => item.customerId === selectClient?.value)
    return {
      clientId: selectClient?.value ?? '',
      name: selectClient?.label?.split(new RegExp('-'))[0] ?? '',
      dob: getCustommerBySelectClient()?.dob || '',
      sex: getCustommerBySelectClient()?.gender || '',
      firstName: getCustommerBySelectClient()?.name || '',
      surName: getCustommerBySelectClient()?.surName || '',
      // nationality: 'VN',
      ...(isCheckChangeGenderGroup && { salutation: gender?.value === 'M' ? 'Ông' : "Bà" }),
      attributesExt: {
        clientNum: selectClient?.value ?? '',
        isCheckChangeDOBGroup,
        isCheckChangeGenderGroup,
        ...(isCheckChangeGenderGroup && { gender: gender?.value }),
        ...(isCheckChangeDOBGroup && { dob: PulseOpsFormat.datetoFormat(dob ?? new Date(), 'yyyyMMDD') })
      }
    }
  }

  const getUploadedFilesInfo = () => {
    if (!files || isEmpty(files)) return
    let uploadedFileList: UploadedFilesInfo[] = []
    const uploadedItem: UploadedFilesInfo = {
      uploadFiles: [...files],
      transactionType: TransactionType.CHANGE_DOB_GENDER,
      docTypeCode: 'DPS10',
      category: TaskType.PolicyService,
      policyNumber: params.policyNum ?? '',
      officeCode: params.officeCode ?? ''
    }
    uploadedFileList.push(uploadedItem)
    return uploadedFileList
  }

  initSubmission({
    validate: async () => {
      const isValidForm = await trigger()

      if (!isValidForm) return false

      const { isCheckChangeDOBGroup, isCheckChangeGenderGroup } = getValues()

      if (!isCheckChangeDOBGroup && !isCheckChangeGenderGroup) {
        showToast(t('message:MS020051'), 'error')
        return false
      }

      if (isEmpty(files)) {
        showToast(t('message:MS020001', { field: t('utilities:FileAttachment') }), 'error')
        return false
      }

      const { selectClient } = getValues()

      return {
        url: () => `wf-api/customer/dob-and-gender/${selectClient?.value}`,
        body: mappingBodySubmisson(),
        transactionName: RequestAuthenticateData.TransactionLabelShort(TransactionType.CHANGE_DOB_GENDER) || '',
        collerationId: params.policyNum || '',
        transaction: TransactionType.CHANGE_DOB_GENDER,
        uploadedFilesInfo: getUploadedFilesInfo()
      }
    },
    clear: () => {
      reset({ ...defaultValues })
    }
  })

  return (
    <Container>
      {renderGroupsViews()} {renderUploadFiles()}
    </Container>
  )
}

export default ChangeDOBAndGenderScreen
