import {
  Title,
  AppContext,
  formatNumberWithComma,
  Select,
  RadioButton,
  TransactionType,
  JournalModel,
  Input,
  PulseOpsApi,
  T0Data
} from '@pulseops/common'
import { SectionContent } from '@pulseops/submission/common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { SafeAreaView, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { PolicyServiceProps } from '../policy-service-props'
import { useLoading } from '@mxt/zio-react'
import { Controller, useForm } from 'react-hook-form'
import { Link, makeStyles, TextField } from '@material-ui/core'
import { RequestAuthenticateData } from '../../request-authen'
import { JournalsForm } from './transfer-journal-form'
import { PayableJournalForm } from './payable-journal'
import { UnitJournalForm } from './unit-journal'
import { PolicyAccountForm } from './policy-account-journal'
import { BonusJournalForm } from './bonus-journal'
import { RemoveJournalForm } from './remove-journal'
import moment from 'moment'

export enum ActionOption {
  ACTION_A_CNB = 'CASH_NOT_BANKED',
  ACTION_A_UJ = 'UNIT_JOURNAL',
  ACTION_A_CJ = 'CREATE_JOURNAL',
  ACTION_J_APR = 'ADVANCE_PREMIUM_RECEIPT',
  ACTION_J_ET = 'EA_TRANSFER',
  ACTION_B_RJ = 'REMOVE_JOURNAL',
  ACTION_B_BJ = 'BONUS_JOURNAL',
  ACTION_C_JPA = 'JOURNAL_POLICY_ACCOUNT',
  ACTION_L_LRR = 'LOAN_REPAYMENT_RECEIPT'
}

const actionList = [
  {
    label: 'Action A - Cash Not Banked',
    value: ActionOption.ACTION_A_CNB
  },
  {
    label: 'Action J - Advance Premium Receipt',
    value: ActionOption.ACTION_J_APR
  },
  {
    label: 'Action A - Unit Journal',
    value: ActionOption.ACTION_A_UJ
  },
  {
    label: 'Action C - Journal Policy Account',
    value: ActionOption.ACTION_C_JPA
  },
  {
    label: 'Action A - Create Journal',
    value: ActionOption.ACTION_A_CJ
  },
  {
    label: 'Action J - EA Transfer (For Death Claim)',
    value: ActionOption.ACTION_J_ET
  },
  {
    label: 'Action B - Remove Journal',
    value: ActionOption.ACTION_B_RJ
  },
  {
    label: 'Action B - Bonus Journal',
    value: ActionOption.ACTION_B_BJ
  },
  {
    label: 'Action L - Loan Repayment Receipt',
    value: ActionOption.ACTION_L_LRR
  }
]
const useStylesTextField = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-root': {
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;'
    }
  }
}))

type TranfersJournalProps = PolicyServiceProps<JournalModel.JournalSubmitData> & { groupName: string }

export const TranfersJournalScreen: React.FC<TranfersJournalProps> = ({
  policyNumber,
  isConfirmed,
  initSubmission,
  officeCode,
  groupName
}) => {
  const [isLoading, bindLoader] = useLoading(false)
  const { showGlobalLoading, showToast } = React.useContext(AppContext.AppContextInstance)
  const classesTextField = useStylesTextField()

  const journalsForm = useForm<JournalsForm.JournalsData>({
    defaultValues: {
      category: groupName === 'payout' ? 'PS01' : 'PS02',
      transaction: undefined,
      caseId: '',
      remark: '',
      totalPaymentAmount: 0
    }
  })
  const { t, i18n } = useTranslation()

  const { control } = journalsForm

  const [journalsSelected, setJournalsSelected] = React.useState<JournalsForm.JournalsOptions | undefined>()
  const [actionSelected, setActionSelected] = React.useState<string>('')
  const [transactionName, setTransactionName] = React.useState<string>('')
  const [caseIdChecked, setCaseIdChecked] = React.useState<string>('')

  const dataJournal = [
    {
      label: t('Journal:Payable'),
      id: JournalsForm.JournalsOptions.PAYABLE,
      disabled:
        !actionSelected ||
        actionSelected === ActionOption.ACTION_A_UJ ||
        actionSelected === ActionOption.ACTION_C_JPA ||
        actionSelected === ActionOption.ACTION_B_BJ ||
        actionSelected === ActionOption.ACTION_B_RJ
    },
    {
      label: t('Journal:UnitJournal'),
      id: JournalsForm.JournalsOptions.UNIT_JOURNAL,
      disabled: !actionSelected || actionSelected !== ActionOption.ACTION_A_UJ
    },
    {
      label: t('Journal:PolicyAccountJournal'),
      id: JournalsForm.JournalsOptions.POLICY_ACCOUNT_JOUNAL,
      disabled: !actionSelected || actionSelected !== ActionOption.ACTION_C_JPA
    },
    {
      label: t('Journal:BonusJournal'),
      id: JournalsForm.JournalsOptions.BONUS_JOURNAL,
      disabled: !actionSelected || actionSelected !== ActionOption.ACTION_B_BJ
    },
    {
      label: t('Journal:RemoveJournal'),
      id: JournalsForm.JournalsOptions.REMOVE_JOURNAL,
      disabled: !actionSelected || actionSelected !== ActionOption.ACTION_B_RJ
    }
  ]

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  const getTransactionTypeLabel = (transactionType: string) => {
    const transactionItem = T0Data.getValue(transactionType)
    return transactionItem
      ? i18n.language === 'en'
        ? transactionItem.transactionType
        : transactionItem.transactionTypeVn
      : ''
  }

  const setTotalAmount = () => {
    if (journalsSelected === JournalsForm.JournalsOptions.PAYABLE) {
      const totalAmount = journalsForm.getValues('journalPayable.totalReceive')
      journalsForm.setValue('totalPaymentAmount', totalAmount)
    } else if (journalsSelected === JournalsForm.JournalsOptions.UNIT_JOURNAL) {
      let total = 0
      const totalAmountList = journalsForm.getValues('journalUnit')
      if (totalAmountList)
        totalAmountList.map((item) => {
          total += item.adjust.indexOf('-') !== -1 ? 0 - Number(item.adjust.replace('-', '')) : Number(item.adjust)
        })
      journalsForm.setValue('totalPaymentAmount', total)
    } else if (journalsSelected === JournalsForm.JournalsOptions.POLICY_ACCOUNT_JOUNAL) {
      let total = 0
      const totalAmountList = journalsForm.getValues('journalPolicyAccount')
      if (totalAmountList)
        totalAmountList.map((item) => {
          total += item.adjust.indexOf('-') !== -1 ? 0 - Number(item.adjust.replace('-', '')) : Number(item.adjust)
        })
      journalsForm.setValue('totalPaymentAmount', total)
    } else {
      journalsForm.setValue('totalPaymentAmount', 0)
    }
  }

  const getOtpRequest = (journalData: JournalsForm.JournalsData): JournalModel.OptionalRequest | undefined => {
    switch (journalsSelected) {
      case JournalsForm.JournalsOptions.PAYABLE:
        return journalData.journalPayable
          ? {
              type: JournalModel.JournalType.PAYABLE_ACCOUNT,
              policyTransfer: {
                releaseNumber: journalData.journalPayable.releaseNumber || '',
                type: journalData.journalPayable.type?.value || '',
                total: journalData.journalPayable.totalTransfer,
                policyDetails: journalData.journalPayable.journalsTransfer.map((item) => {
                  return {
                    code: item.SACCode,
                    type: item.SACType,
                    balance: 0 - Number(item.currentBalance.replace('-', '')),
                    amount: 0 - Number(item.tranferAmount.replace('-', ''))
                  }
                })
              },
              receivingPolicy: {
                policy: journalData.journalPayable.receivePolicy,
                total: journalData.journalPayable.totalReceive,
                policyDetails: journalData.journalPayable.journalsReceive.map((item) => {
                  return {
                    code: item.SACCode,
                    type: item.SACType,
                    amount: Number(item.amount)
                  }
                })
              }
            }
          : undefined
      case JournalsForm.JournalsOptions.UNIT_JOURNAL:
        return journalData.journalUnit
          ? {
              type: JournalModel.JournalType.UNIT_JOURNAL,
              policyTransfer: {
                policyDetails: journalData.journalUnit.map((item) => {
                  return {
                    code: item.fund,
                    type: item.type,
                    balance:
                      item.unitBalance.indexOf('-') !== -1
                        ? 0 - Number(item.unitBalance.replace('-', ''))
                        : Number(item.unitBalance),
                    amount:
                      item.adjust.indexOf('-') !== -1 ? 0 - Number(item.adjust.replace('-', '')) : Number(item.adjust)
                  }
                })
              }
            }
          : undefined
      case JournalsForm.JournalsOptions.POLICY_ACCOUNT_JOUNAL:
        return journalData.journalPolicyAccount
          ? {
              type: JournalModel.JournalType.POLICY_ACCOUNT_JOURNAL,
              policyTransfer: {
                policyDetails: journalData.journalPolicyAccount.map((item) => {
                  return {
                    code: item.fund,
                    type: item.unitType,
                    balance:
                      item.policyAccount.indexOf('-') !== -1
                        ? 0 - Number(item.policyAccount.replace('-', ''))
                        : Number(item.policyAccount),
                    amount:
                      item.adjust.indexOf('-') !== -1 ? 0 - Number(item.adjust.replace('-', '')) : Number(item.adjust)
                  }
                })
              }
            }
          : undefined
      case JournalsForm.JournalsOptions.BONUS_JOURNAL:
        return journalData.journalBonus
          ? {
              type: JournalModel.JournalType.BONUS_JOURNAL,
              policyTransfer: {
                effectiveDate: moment(journalData.journalBonus.effectiveDate).format('DD/MM/YYYY'),
                bonus: Number(journalData.journalBonus.accruedReversionaryBonus),
                amount:
                  journalData.journalBonus.arbAmount.indexOf('-') !== -1
                    ? 0 - Number(journalData.journalBonus.arbAmount.replace('-', ''))
                    : Number(journalData.journalBonus.arbAmount)
              }
            }
          : undefined
      case JournalsForm.JournalsOptions.REMOVE_JOURNAL:
        return journalData.journalRemove
          ? {
              type: JournalModel.JournalType.REMOVE_JOURNAL,
              policyTransfer: {
                journalNumber: journalData.journalRemove.journalNumber || ''
              }
            }
          : undefined
      default:
        return undefined
    }
  }

  const getSubmitedJournalData = () => {
    const journalData = journalsForm.getValues()
    let submitedData: JournalModel.JournalSubmitData
    const otpRequest = getOtpRequest(journalData)
    return otpRequest
      ? pipe(
          ZIO.effect(() => {
            submitedData = {
              policyNo: policyNumber ?? '',
              category: journalData.category,
              action: journalData.action.value,
              caseID: journalData.caseId,
              transaction: journalData.transaction,
              remark: journalData.remark,
              optionalRequest: otpRequest,
              attributesExt: {
                TOTAL_PAYOUT_AMOUNT: journalData.totalPaymentAmount
              }
            }
            return submitedData
          }),
          bindLoader,
          ZIO.unsafeRun({})
        )
      : false
  }

  const resetOptionRequest = () => {
    journalsForm.resetField('journalBonus')
    journalsForm.resetField('journalPayable')
    journalsForm.resetField('journalPolicyAccount')
    journalsForm.resetField('journalUnit')
    journalsForm.resetField('journalRemove')
  }

  const checkCaseId = () => {
    const id = journalsForm.getValues('caseId').replace('PS-', '')
    if (!id) {
      if (transactionName) {
        setTransactionName('')
        journalsForm.resetField('transaction')
      }
    } else if (journalsForm.getValues('caseId').indexOf('PS-') !== -1)
      pipe(
        PulseOpsApi.getHistoricDetail(id),
        ZIO.map((data) => {
          if (data.transactionType) {
            journalsForm.setValue('transaction', data.transactionType)
            setCaseIdChecked(journalsForm.getValues('caseId'))
            setTransactionName(getTransactionTypeLabel(data.transactionType))
          } else {
            journalsForm.resetField('transaction')
            setTransactionName('')
            showToast(t('message:MS020024'), 'error')
          }
        }),
        ZIO.catchAll((err) => {
          journalsForm.resetField('transaction')
          setTransactionName('')
          showToast(t('message:MS020024'), 'error')
          return ZIO.unit
        }),
        ZIO.unsafeRun({})
      )
    else {
      journalsForm.resetField('transaction')
      setTransactionName('')
      showToast(t('message:MS020024'), 'error')
    }
  }

  const validateNextTab = () => {
    switch (journalsSelected) {
      case JournalsForm.JournalsOptions.PAYABLE: {
        const actionJournal = journalsForm.watch('action.value')
        const releaseNumber = journalsForm.watch('journalPayable.releaseNumber')
        const payableTransfer = journalsForm.watch('journalPayable.journalsTransfer')
        if (actionJournal === ActionOption.ACTION_A_CJ && releaseNumber) {
          const filter =
            payableTransfer &&
            payableTransfer.filter(
              (i) => (i.SACCode === 'LP' && i.SACType === 'EO') || (i.SACCode === 'LN' && i.SACType === 'EI')
            )
          if (filter && filter.length === 0) journalsForm.setValue('journalPayable.releaseNumber', '')
        }
        const payableReceive = journalsForm.watch('journalPayable.journalsReceive')
        const totalTransfer = journalsForm.watch('journalPayable.totalTransfer')
        const totalReceive = journalsForm.watch('journalPayable.totalReceive')
        if (totalTransfer + totalReceive !== 0) {
          showToast(t('message:MS100016'), 'error')
          return false
        }
        if (payableTransfer?.length === 0 || payableReceive?.length === 0) {
          showToast(t('message:MS030031'), 'error')
          return false
        }
        return true
      }
      case JournalsForm.JournalsOptions.UNIT_JOURNAL: {
        const unit = journalsForm.watch('journalUnit')
        if (unit?.length === 0) {
          showToast(t('message:MS030031'), 'error')
          return false
        }
        return true
      }
      case JournalsForm.JournalsOptions.POLICY_ACCOUNT_JOUNAL: {
        const policyAccount = journalsForm.watch('journalPolicyAccount')
        if (policyAccount?.length === 0) {
          showToast(t('message:MS030031'), 'error')
          return false
        }
        return true
      }
      default:
        return true
    }
  }

  initSubmission({
    validate: async (isContinue) => {
      setTotalAmount()
      const id = journalsForm.getValues('caseId')
      const isValidForm = await journalsForm.trigger()
      if (id && !transactionName) {
        showToast(t('message:MS020025'), 'error')
        return false
      } else if (id && id !== caseIdChecked) {
        checkCaseId()
        return false
      } else if (!journalsSelected) {
        showToast(t('message:MS030030'), 'error')
        return false
      } else if (isValidForm && validateNextTab()) {
        console.log(isValidForm, 'isValidForm')
        console.log('isContinue: ' + isContinue)
        const submitedData = await getSubmitedJournalData()
        return submitedData
          ? {
              url: (policyNum: string) => `wf-api/policy/${policyNum}/journal`,
              body: submitedData,
              transactionName: RequestAuthenticateData.TransactionLabelShort(TransactionType.TRANSFER_JOURNAL),
              collerationId: policyNumber ?? '',
              transaction: TransactionType.TRANSFER_JOURNAL,
              documents: []
            }
          : false
      } else {
        return false
      }
    },
    clear: () => {
      journalsForm.reset()
      setActionSelected('')
      setJournalsSelected(undefined)
      setTransactionName('')
    }
  })

  return (
    <SafeAreaView style={style.container}>
      <Title wrapperStyle={style.noMarginBottom} title={t('Tab:RelatedInfo')}></Title>
      <SectionContent sectionStyles={style.sectionContent}>
        <View style={style.row}>
          <View style={style.col_4_first}>
            <Text style={style.field_title}>{t('ServiceInquiry:Category')}</Text>
            <Text style={[style.field_description]}>
              {groupName === 'payout' ? t('Journal:Payout') : t('Journal:Alteration')}
            </Text>
          </View>
          <View style={style.col_4}>
            {!isConfirmed ? (
              <Controller
                name="action"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t('message:MS020001', { field: t('Journal:Action') })
                  }
                }}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <Select
                    disabled={isConfirmed}
                    label={t('Journal:Action')}
                    required
                    options={actionList}
                    placeholder={t('common:Select')}
                    onChange={(e) => {
                      setActionSelected(e?.value || '')
                      setJournalsSelected(undefined)
                      resetOptionRequest()
                      onChange(e)
                    }}
                    onBlur={onBlur}
                    value={value ?? undefined}
                    errorMessage={error?.message}
                  />
                )}
              />
            ) : (
              <View>
                <Text style={style.field_title}>{t('Journal:Action')}</Text>
                <Text style={style.field_description}>{journalsForm.watch('action.label')}</Text>
              </View>
            )}
          </View>
          <View style={isConfirmed ? style.col_4 : { maxWidth: '26%', width: '100%', flexDirection: 'row' }}>
            <Controller
              name="caseId"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <Input
                  containerStyle={{ width: isConfirmed ? '100%' : '95%' }}
                  disabled={isConfirmed}
                  title={t('common:CaseId')}
                  value={value || ''}
                  onChange={(value) => {
                    onChange(value)
                    setTransactionName('')
                    journalsForm.resetField('transaction')
                  }}
                  //   placeholder={t('ServiceInquiry:EnterIDNumber')}
                  errorMessage={error?.message}
                />
              )}
            />
            {!isConfirmed && (
              <TouchableOpacity
                style={style.button}
                onPress={() => {
                  checkCaseId()
                }}
              >
                <Text style={style.textButton}>{t('Journal:Check')}</Text>
              </TouchableOpacity>
            )}
          </View>
        </View>
        <View style={[style.row, { marginBottom: 15, marginTop: 30 }]}>
          <View style={style.col_4_first}>
            <Text style={style.field_title}>{t('Journal:Transaction')}</Text>
            <Text style={[style.field_description]}>{transactionName || '-'}</Text>
          </View>
        </View>
      </SectionContent>
      <View style={[style.row, { marginTop: 30 }]}>
        <View style={{ flexDirection: 'column', width: '100%', paddingBottom: 20 }}>
          {dataJournal.map((r, i) => (
            <View style={{ paddingBottom: 20 }}>
              <RadioButton
                disable={r.disabled || isConfirmed}
                key={i}
                label={r.label}
                value={r.id}
                selected={r.id === journalsSelected}
                // style={{ paddingBottom: 20}}
                labelStyle={{ color: r.disabled ? '#70777E' : '#000000', fontWeight: r.disabled ? 'normal' : '600' }}
                onChange={r.disabled === true || r.id === journalsSelected ? () => {} : () => setJournalsSelected(r.id)}
              />
              {r.id === journalsSelected && journalsSelected === JournalsForm.JournalsOptions.PAYABLE && (
                <PayableJournalForm
                  form={journalsForm}
                  disabled={isConfirmed}
                  policyNum={policyNumber ?? ''}
                ></PayableJournalForm>
              )}
              {r.id === journalsSelected && journalsSelected === JournalsForm.JournalsOptions.UNIT_JOURNAL && (
                <UnitJournalForm
                  form={journalsForm}
                  disabled={isConfirmed}
                  policyNum={policyNumber ?? ''}
                ></UnitJournalForm>
              )}
              {r.id === journalsSelected && journalsSelected === JournalsForm.JournalsOptions.POLICY_ACCOUNT_JOUNAL && (
                <PolicyAccountForm
                  form={journalsForm}
                  disabled={isConfirmed}
                  policyNum={policyNumber ?? ''}
                ></PolicyAccountForm>
              )}
              {r.id === journalsSelected && journalsSelected === JournalsForm.JournalsOptions.BONUS_JOURNAL && (
                <BonusJournalForm
                  form={journalsForm}
                  disabled={isConfirmed}
                  policyNum={policyNumber ?? ''}
                ></BonusJournalForm>
              )}
              {r.id === journalsSelected && journalsSelected === JournalsForm.JournalsOptions.REMOVE_JOURNAL && (
                <RemoveJournalForm form={journalsForm} disabled={isConfirmed}></RemoveJournalForm>
              )}
            </View>
          ))}
        </View>
      </View>
      <View style={{ marginBottom: 16 }}>
        <Text style={{ color: '#70777E', fontWeight: 'bold', fontSize: 15, marginBottom: 10 }}>
          {t('Journal:Remark')}
        </Text>
        {!isConfirmed ? (
          <Controller
            key={`remark`}
            control={control}
            name={'remark'}
            rules={{
              required: {
                value: actionSelected === ActionOption.ACTION_L_LRR,
                message: t('message:MS020001', { field: t('Journal:Remark') })
              }
            }}
            render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
              <View>
                <TextField
                  classes={classesTextField}
                  // placeholder="Placeholder"
                  disabled={isConfirmed}
                  multiline
                  variant={'outlined'}
                  style={{ width: '100%' }}
                  value={value}
                  onBlur={onBlur}
                  onChange={(e) => {
                    onChange(e.target.value)
                  }}
                />
                {!!error?.message ? <Text style={{ color: '#ED1B2C', fontSize: 11.25 }}>{error.message}</Text> : null}
              </View>
            )}
          />
        ) : (
          <Text style={style.field_description}>{journalsForm.watch('remark') || '-'}</Text>
        )}
      </View>
    </SafeAreaView>
  )
}

const style = StyleSheet.create({
  container: {
    marginTop: 15
  },
  noMarginBottom: {
    marginBottom: 0
  },
  sectionContent: {
    paddingTop: 20,
    paddingBottom: 20,
    backgroundColor: '#fafafa',
    border: '1px solid #d3dce6',
    boxSizing: 'border-box',
    borderRadius: 8,
    marginTop: 15
  },
  sectionRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginRight: -15,
    marginLeft: -15
  },
  row: {
    flexDirection: 'row'
  },
  col_4: {
    width: '100%',
    maxWidth: '33.3333333333%',
    paddingRight: 20,
    paddingLeft: 15
  },
  col_4_first: {
    width: '100%',
    maxWidth: '33.3333333333%',
    paddingRight: 15
  },
  col_12: {
    width: '100%',
    maxWidth: '100%',
    paddingRight: 15,
    paddingLeft: 15
  },
  field_title: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  },
  field_description: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 15,
    lineHeight: 22,
    color: '#000000'
  },
  redLine: {
    color: '#ed1c2e',
    fontWeight: '700'
  },
  underlineLink: {
    textDecorationLine: 'underline'
  },
  button: {
    width: 100,
    marginLeft: 10,
    marginVertical: 20,
    justifyContent: 'center',
    backgroundColor: '#fff',
    borderRadius: 100,
    borderWidth: 1,
    borderColor: '#ED1B2E',
    height: 35,
    paddingHorizontal: 20
  },
  textButton: { textAlign: 'center', color: '#ED1B2E', fontWeight: 'bold' }
})
