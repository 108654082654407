import { ZIO, Task } from '@mxt/zio'
import { pipe } from 'fp-ts/function'
// import { PulseOpsAuth } from '@pulseops/auth'

// import { FatcaDeclaration } from '../model/FatcaDeclaration'
// import { RequestAuthFormDataSubmit } from '@pva/pulse-ops-core'

import * as t from 'io-ts'
import * as A from 'fp-ts/lib/Array'
import { GeneralService } from './GeneralService'
import { SUCommonRequestData } from './model/CommonRequest'
import { StorageBlob } from '../storage-blob'
import { SubmissionService } from './SubmissionService'
import { RequestAuthFormDataSubmit } from './model/RequestAuthEform'
import { TransactionType } from './model/TransactionType'
import { POApi } from '../POApi'
import { Duration } from '@mxt/zio/date'
// import { Logging } from '@mxt/zio/logging'

export namespace CommonRequestService {
  const hiddenTransactionList = [TransactionType.RIDER_ALTERATION, TransactionType.MAJOR_CHANGE_AFTER_FREE_LOOK, TransactionType.MAJOR_CHANGE_IN_FREE_LOOK,
  TransactionType.PARTIAL_WITHDRAWAL, TransactionType.OTHER_REQUEST, TransactionType.ADD_LACK_OF_DOCUMENT, TransactionType.CHANGE_PREMIUM, TransactionType.PRODUCT_OPTION_SWITCHING, TransactionType.REDATING,
  TransactionType.RIDER_REINSTATEMENT, TransactionType.FULL_SURRENDER_REVERSAL, TransactionType.PARTIAL_SURRENDER, TransactionType.FULL_SURRENDER]
  export const getDetail = (policyNum: string): Task<SUCommonRequestData.DetailData> =>
    pipe(
      ZIO.zipPar(getCommonRequestList(), getDocumentTypeList(), getTransactionWFTypeList()),
      ZIO.map(([requestList, documentTypeList, transactionWFTypeList]) => {
        const commonRequestList = requestList.filter(x => !hiddenTransactionList.includes(x.requestWF as TransactionType))
        return {
          commonRequestList: commonRequestList,
          documentTypeList: documentTypeList,
          transactionWFTypeList: transactionWFTypeList
        }
      })
    )

  export const submit =
    (primaryPolicyNo: string, data: SUCommonRequestData.SubmitedData, documents: StorageBlob.FileContentSubmit[]) =>
    (requestauth: RequestAuthFormDataSubmit) =>
      SubmissionService.submit(t.unknown)(
        `wf-api/policy/common-request-form`,
        { body: data },
        primaryPolicyNo,
        requestauth,
        documents
      )

  export const getCommonRequestList = (): Task<SUCommonRequestData.CommonRequest[]> =>
    pipe(
      POApi.get('wf-api/table/t29s1/get-all')(
        t.type({
          body: t.array(SUCommonRequestData.CommonRequest)
        })
      ),
      ZIO.map((requestList) => requestList.body)
    )
  
    export const getCommonRequestListByCachedZIO = (): Task<SUCommonRequestData.CommonRequest[]> =>
    pipe(
      POApi.get('wf-api/table/t29s1/get-all')(
        t.type({
          body: t.array(SUCommonRequestData.CommonRequest)
        })
      ),
      ZIO.map((requestList) => requestList.body),
      ZIO.cached(Duration.minutes(5))
    )

  export const getDocumentTypeList = (): Task<SUCommonRequestData.DocumentType[]> =>
    pipe(
      POApi.get('wf-api/table/t29s2/get-all')(
        t.type({
          body: t.array(SUCommonRequestData.DocumentType)
        })
      ),
      ZIO.map((requestList) => requestList.body)
    )

  export const getTransactionWFTypeList = (): Task<SUCommonRequestData.TransactionWFType[]> =>
    pipe(
      POApi.get(`wf-api/table/common-request-form-web-service/get-all`)(
        t.type({
          body: t.array(SUCommonRequestData.TransactionWFType)
        })
      ),
      ZIO.map((requestList) => {
        return requestList.body
      })
    )

  export const getMetaDataForApi = (
    policyNum: string,
    documentFiles: SUCommonRequestData.DocumentTypeFiles[],
    officeCode?: string
  ) => {
    return pipe(
      documentFiles,
      A.map((docFile) => {
        return pipe(
          GeneralService.getMetaData(docFile.transactionTypeName || '', docFile.docTypeCode, officeCode),
          ZIO.map((metaDataRes) => {
            const metaDataRaw = {
              type: metaDataRes.data.transactionType,
              doctype: metaDataRes.data.doctypeEn,
              class: metaDataRes.data.classFilenet,
              docid: metaDataRes.data.docID,
              maindoc: metaDataRes.data.mainDoc,
              subdoc: metaDataRes.data.subDoc,
              polnum: policyNum,
              batchno: metaDataRes.data.batchNo,
              docTypeCode: docFile.docTypeCode,
              transactionTypeName: docFile.transactionTypeName
            }
            // console.log(`metaDataRaw:${JSON.stringify(metaDataRaw)}`)
            return metaDataRaw
          })
        )
      }),
      ZIO.sequence
    )
  }
}
