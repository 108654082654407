import * as React from 'react'
import {
  FieldList,
  Panel,
  Title,
  Divider,
  ErrorHandling,
  formatNumberWithComma,
  form2,
  TransactionType,
  PayoutPopup,
  Alert,
  GeneralService,
  PrucashPayment as PrucashPaymentModel,
  PruCashPaymentService,
  StorageBlob,
  AppContext,
  TaskType
} from '@pulseops/common'
import { Checkbox, Column } from '@pulseops/submission/common'
import { pipe } from 'fp-ts/function'
import { DateOfDeath, DateOfDeathForm } from '../../date-of-death'
import { mapCashOutOption, PayoutMethod, PayoutMethodRef } from '../../payout-method'
import { PolicyServiceProps, UploadedFilesInfo } from '../policy-service-props'
import { PrucashPaymentForm } from './prucash-payment.form'
import { Controller } from 'react-hook-form'
import moment from 'moment'
import { RequestAuthenticateData } from '../../request-authen'
import { useTranslation } from 'react-i18next'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { useIsFocused } from '@react-navigation/native'

interface Props extends PolicyServiceProps<PrucashPaymentModel.SubmitData> {
  policyNumber: string
}

export const PrucashPayment = ({ policyNumber, initSubmission, isConfirmed, officeCode }: Props) => {
  const { t } = useTranslation()

  const isFocused = useIsFocused()
  const { showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const [isLoading, bindLoader] = useLoading(false)

  const payoutRef = React.useRef<PayoutMethodRef | null>(null)

  const [isPayoutEnable, setIsPayoutEnable] = React.useState<boolean>(false)

  const [payoutMethods, setPayoutMethods] = React.useState<PayoutPopup.PayoutMethods[]>([])

  const [defaultPayments, setDefaultPayments] = React.useState<PayoutPopup.Summary[]>([])

  const [translator, setTranslator] = React.useState<PayoutPopup.PayoutMethodTranslator[]>([])

  const [isCoverage, setIsCoverage] = React.useState<boolean>(false)

  const benefit = pipe(PruCashPaymentService.getDetail(policyNumber), ErrorHandling.runDidUpdate([policyNumber]))

  const prucashForm = form2.useForm(PrucashPaymentForm.codec, {
    defaultValues: {
      isPoPassAway: false,
      payout: []
    }
  })

  const { control, watch, setValue, trigger, setError } = prucashForm.base

  const { isPoPassAway, payout } = watch()

  const totalAmount = React.useMemo(() => payout.reduce((sum, item) => sum + item.amount, 0), [payout])

  React.useEffect(() => {
    if (totalAmount > (benefit?.totalCashBenefit || 0)) {
      prucashForm.base.setError('payout', { message: t('message:MS050237') })
    }
  }, [totalAmount, prucashForm.base.formState.isValidating])

  React.useEffect(() => {
    return () => {
      prucashForm.base.reset({
        isPoPassAway: false,
        payout: []
      })
    }
  }, [isFocused])

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  const getPayoutMethodTranslator = () => {
    return pipe(
      GeneralService.getPayoutMethods(),
      ZIO.tap((x) => {
        const translator = x.body.map((x) => ({
          typeEnum: x.typeEnum,
          nameEn: x.nameEn,
          nameVi: x.nameVi
        }))
        setTranslator(translator)
        return ZIO.unit
      })
    )
  }

  const getPayoutSetupInfo = (): {
    isEnablePayout: boolean
    methods: PayoutPopup.PayoutMethods[]
    defaultPayments: PayoutPopup.Summary[]
  } => {
    let methods: PayoutPopup.PayoutMethods[] = []
    let defaultPayments: PayoutPopup.Summary[] = []
    let isEnablePayout: boolean = true
    if (benefit) {
      const a = benefit.totalCashBenefit
      const b = benefit.notPaidPremium
      const c = benefit.loanExceedSurrenderValue
      const d = benefit.netTotalCashBenefit

      const showWarningMessage = () => {
        if (b > 0 && a >= b && c === 0) {
          Alert.alert(t('message:MS050238'))
        } else if (b === 0 && c > 0) {
          Alert.alert(t('message:MS050239'))
        } else if (b > 0 && a >= b && c > 0) {
          Alert.alert(t('message:MS050240'))
        }
      }

      a - b > 0 &&
        b > 0 &&
        defaultPayments.push({
          method: PayoutPopup.PayoutMethods.PAYPREMIUM,
          methodView: PayoutPopup.translateMethod(PayoutPopup.PayoutMethods.PAYPREMIUM, translator),
          detail: `${benefit.policyNum} - ${benefit.clientName}`,
          policyNum: benefit.policyNum,
          poName: benefit.clientName,
          amount: b,

          bankAccountName: null,
          bankAccountNum: null,
          bankBranchCode: null,
          bankBranchName: null,
          bankCode: null,
          bankName: null,
          nationalId: null,
          totalPremium: null,
          payee: null,
          officeCode: null,
          // issueDate: null,
          // issueBy: null
          officeBankCode: undefined,
          officeType: undefined
        })

      c > 0 &&
        defaultPayments.push({
          method: PayoutPopup.PayoutMethods.PAYLOAN,
          methodView: PayoutPopup.translateMethod(PayoutPopup.PayoutMethods.PAYLOAN, translator),
          detail: `${benefit.policyNum} - ${benefit.clientName}`,
          policyNum: benefit.policyNum,
          poName: benefit.clientName,
          amount: d < 0 ? c + d : c,

          bankAccountName: null,
          bankAccountNum: null,
          bankBranchCode: null,
          bankBranchName: null,
          bankCode: null,
          bankName: null,
          nationalId: null,
          totalPremium: null,
          payee: null,
          officeCode: null,
          // issueDate: null,
          // issueBy: null
          officeBankCode: undefined,
          officeType: undefined
        })

      if (d > 0) {
        methods.push(
          PayoutPopup.PayoutMethods.PAYPREMIUM,
          PayoutPopup.PayoutMethods.PAYLOAN,
          PayoutPopup.PayoutMethods.CASHLESS,
          PayoutPopup.PayoutMethods.OTHER,
          PayoutPopup.PayoutMethods.CASH_AT_COUNTER,
          PayoutPopup.PayoutMethods.PAID_AT_BANK,
          PayoutPopup.PayoutMethods.BANKTRANSFER
        )
      } else {
        isEnablePayout = false
        if (b > 0 && a >= b && c === 0) {
          methods.push(PayoutPopup.PayoutMethods.PAYPREMIUM)
        } else if (b === 0 && c > 0) {
          methods.push(PayoutPopup.PayoutMethods.PAYLOAN)
        } else if (b > 0 && a >= b && c > 0) {
          methods.push(PayoutPopup.PayoutMethods.PAYPREMIUM, PayoutPopup.PayoutMethods.PAYLOAN)
        }
      }
      showWarningMessage()
    }

    return { isEnablePayout, methods, defaultPayments }
  }

  pipe(getPayoutMethodTranslator(), ErrorHandling.runDidMount())

  React.useEffect(() => {
    if (benefit && translator.length > 0) {
      const { isEnablePayout, methods, defaultPayments } = getPayoutSetupInfo()
      setIsPayoutEnable(isEnablePayout)
      setPayoutMethods(methods)
      setDefaultPayments(defaultPayments)
      setValue('payout', defaultPayments)
      setIsCoverage(['EUS3', 'EUS4', 'EUS5'].includes(benefit.coverageCode))
    }
  }, [benefit, translator])

  // const ewalletMethod = React.useMemo(()=> {
  //    if(isPoPassAway && payoutMethods.includes(PayoutPopup.PayoutMethods.CASHLESS)){
  //     return PayoutPopup.PayoutMethods.MOMO
  //    }
  //    return ''
  // },[isPoPassAway])

  // const getMetaDataFromAttachmentFiles = () => {
  //   let files: StorageBlob.FileContent[] = []
  //   return pipe(
  //     GeneralService.getMetaData(TransactionType.PRUCASH_PAYMENT, 'DPS11', officeCode),
  //     ZIO.map((metaDataRes) => {
  //       const metaDataRaw: StorageBlob.MetaDataUpload = {
  //         type: metaDataRes.data.transactionType,
  //         doctype: metaDataRes.data.doctypeEn,
  //         class: metaDataRes.data.classFilenet,
  //         docid: metaDataRes.data.docID,
  //         maindoc: metaDataRes.data.mainDoc,
  //         subdoc: metaDataRes.data.subDoc,
  //         polnum: policyNumber,
  //         batchno: metaDataRes.data.batchNo
  //       }
  //       const documents = prucashForm.base.getValues('files') as DateOfDeathForm.FileList
  //       files = documents.map((x) => {
  //         return {
  //           file: x.file,
  //           metaData: metaDataRaw
  //         }
  //       })
  //       return files
  //     })
  //   )
  // }

  const getUploadedFilesInfo = () => {
    let uploadedFileList: UploadedFilesInfo[] = []
    const files = prucashForm.base.getValues('files') as DateOfDeathForm.FileList
    if (isPoPassAway) {
      uploadedFileList.push({
        uploadFiles: files,
        transactionType: TransactionType.PRUCASH_PAYMENT,
        docTypeCode: 'DPS11',
        category: TaskType.PolicyService,
        policyNumber: policyNumber ?? '',
        officeCode: officeCode ?? ''
      })
    }
    uploadedFileList.push({
      uploadFiles: GeneralService.getPayoutDocuments(prucashForm.base.getValues('payout')),
      transactionType: TransactionType.PRUCASH_PAYMENT,
      docTypeCode: 'DPS01',
      category: TaskType.PolicyService,
      policyNumber: policyNumber ?? '',
      officeCode: officeCode ?? ''
    })
    return uploadedFileList
  }

  initSubmission({
    validate: async (isContinue) => {
      const isFormValid = await trigger()

      if (!benefit) {
        return false
      }

      if (totalAmount === 0) {
        setError('payout', { message: t('message:MS050241') })
        return false
      }

      if (totalAmount > benefit.totalCashBenefit) {
        return false
      }

      if (!isFormValid) {
        return false
      }

      const value = prucashForm.base.getValues()
      let filesUploaded: StorageBlob.FileContentSubmit[] = []
      if (!isContinue) {
        // showGlobalLoading(true)
        // filesUploaded = await pipe(
        //   getMetaDataFromAttachmentFiles(),
        //   ZIO.flatMap((metaDataFiles) => StorageBlob.uploadToSubmit('PS', policyNumber)(metaDataFiles)),
        //   ErrorHandling.run([] as StorageBlob.FileContentSubmit[])
        // )
        // showGlobalLoading(false)
        // const files = prucashForm.base.getValues('files') as DateOfDeathForm.FileList
        // filesUploaded = await pipe(
        //   ZIO.effect(() => {
        //     return {
        //       pruCashDocuments: files,
        //       payoutDocuments: GeneralService.getPayoutDocuments(value.payout)
        //     }
        //   }),
        //   ZIO.flatMap((documentInfo) =>
        //     ZIO.zipPar(
        //       GeneralService.getMetaFilesByAttachedFiles(
        //         documentInfo.pruCashDocuments,
        //         TransactionType.PRUCASH_PAYMENT,
        //         'DPS11',
        //         policyNumber,
        //         officeCode ?? ''
        //       ),
        //       GeneralService.getMetaFilesByAttachedFiles(
        //         documentInfo.payoutDocuments,
        //         TransactionType.PRUCASH_PAYMENT,
        //         'DPS01',
        //         policyNumber,
        //         officeCode ?? ''
        //       )
        //     )
        //   ),
        //   ZIO.map(([submitedFiles, PayoutFiles]) => {
        //     return [...submitedFiles, ...PayoutFiles]
        //   }),
        //   ZIO.flatMap((metaDataFiles) =>
        //     metaDataFiles && metaDataFiles.length > 0
        //       ? StorageBlob.uploadToSubmit('PS', policyNumber)(metaDataFiles)
        //       : ZIO.succeed([])
        //   ),
        //   bindLoader,
        //   ZIO.unsafeRun({})
        // )
      }
      return {
        url: (policyNumber: string) => `wf-api/policy/${policyNumber}/prucash`,
        body: {
          policy: {
            owners: {
              clientId: benefit.clientId,
              dateOfDeath: value.isPoPassAway ? moment(value.deathDate).format('DD/MM/yyyy') : ''
            },
            attributesExt: {
              inheritanceFlag: value.isPoPassAway ? 'Y' : 'N',
              TOTAL_PAYOUT_AMOUNT: totalAmount
            }
          },
          cashOutOptions: mapCashOutOption(value.payout)
        },
        collerationId: policyNumber,
        transactionName: RequestAuthenticateData.TransactionLabelShort(TransactionType.PRUCASH_PAYMENT),
        transaction: TransactionType.PRUCASH_PAYMENT,
        documents: filesUploaded,
        uploadedFilesInfo: getUploadedFilesInfo()
      }
    },
    clear: () => {
      setValue('isPoPassAway', false)
      setValue('deathDate', null)
      setValue('files', [])
      setValue('payout', defaultPayments)
      payoutRef.current?.clearData()
    }
  })

  return (
    <Column flex={1}>
      <Title title={t('submission:PRUCASH_INFO')} wrapperStyle={{ marginTop: 30 }} />
      <Panel isExand={false} containerStyle={{ backgroundColor: '#FAFAFA' }} isLoading={false}>
        <FieldList
          dataSource={[
            {
              label: t('submission:TotalPrucashBenefit'),
              value: formatNumberWithComma(benefit?.totalCashBenefit || ''),
              suffix: 'VND'
            },
            {
              label: t('submission:NotPaidPremium'),
              value: formatNumberWithComma(benefit?.notPaidPremium || ''),
              suffix: 'VND'
            },
            {
              label: t('submission:LoanOverLimit'),
              value: formatNumberWithComma(benefit?.loanExceedSurrenderValue || ''),
              suffix: 'VND'
            },
            {
              label: t('submission:NetTotalCashBenefits'),
              value: formatNumberWithComma(benefit?.netTotalCashBenefit || ''),
              suffix: 'VND',
              isHighlight: true
            }
          ]}
        />
      </Panel>
      {(!isConfirmed || (isConfirmed && isPoPassAway)) && (
        <>
          <Divider height={30} />
          <Checkbox
            enable={!isConfirmed && isCoverage}
            title={t('submission:PODeath')}
            fontWeight="bold"
            value={isPoPassAway}
            onChange={(val) => {
              if (val) {
                setValue('deathDate', null)
                setValue('files', [])
              }
              setValue('isPoPassAway', val)
            }}
          />
        </>
      )}
      {isPoPassAway && (
        <DateOfDeath
          editable={!isConfirmed}
          deathDateControl={{ control: control, name: 'deathDate' }}
          fileControl={{ control: control, name: 'files' }}
        />
      )}
      {benefit && (
        <Controller
          control={control}
          name="payout"
          render={({ field, fieldState: { error } }) => (
            <PayoutMethod
              {...field}
              transactionType={TransactionType.PRUCASH_PAYMENT}
              inheritanceFlag={isPoPassAway}
              ref={payoutRef}
              editable={!isConfirmed}
              enable={isPayoutEnable}
              policyNum={policyNumber}
              maxAmount={benefit.totalCashBenefit}
              errorMessage={error?.message}
              methods={payoutMethods}
              onChange={(data) => field.onChange([...defaultPayments, ...data])}
              officeCode={officeCode}
            />
          )}
        />
      )}
    </Column>
  )
}
