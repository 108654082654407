import { Maybe } from '@mxt/zio/codec'
import * as t from 'io-ts'
import { TransactionType } from '../TransactionType'
import { adjudicationC, policyRider, verificationC } from './AdjudicationModel'
import { SourceType } from '../SourceType'

const Base = <C extends t.Mixed>(codec: C) =>
  t.intersection([
    t.type({
      transactionType: t.literal(TransactionType.NEW_CLAIM_REGISTER)
    }),
    codec
  ])
const DeathAndTPD = Maybe(
  t.type({
    claimEventDate: Maybe(t.string),
    occurrenceDate: Maybe(t.string),
    isRelatedToAccident: t.boolean,
    accidentName: Maybe(
      t.type({
        label: t.string,
        value: t.string
      })
    ),
    cause: t.union([
      t.string,
      t.array(
        t.type({
          label: t.string,
          value: t.string
        })
      ),
      t.undefined,
      t.null
    ]),
    tpdName: t.union([
      t.string,
      t.array(
        t.type({
          label: t.string,
          value: t.string
        })
      ),
      t.undefined,
      t.null
    ]),
    remark: Maybe(t.string)
  })
)

const CI = Maybe(
  t.type({
    claimEventDate: Maybe(t.string),
    occurrenceDate: Maybe(t.string),
    deathDate: Maybe(t.string),
    accidentName: Maybe(
      t.type({
        label: t.string,
        value: t.string
      })
    ),
    isRelatedToAccident: t.boolean,
    diagnosis: t.union([
      t.string,
      t.array(
        t.type({
          label: t.string,
          value: t.string
        })
      ),
      t.undefined,
      t.null
    ]),
    ciName: t.union([
      t.string,
      t.array(
        t.type({
          label: t.string,
          value: t.string
        })
      ),
      t.undefined,
      t.null
    ]),
    remark: Maybe(t.string)
  })
)
const payloadBanca = t.type({
  authenOption: t.string,
  body: t.type({
    claimAntsInfo: t.type({
      claimAntsAddress: t.string,
      claimAntsEmail: t.string,
      claimAntsId: t.string,
      claimAntsName: t.string,
      claimAntsPhone: t.string,
      isPO: t.boolean
    }),
    claimInfo: t.type({
      claimEventDate: t.string,
      claimNumber: t.string,
      claimType: t.string,
      dateOfDiagnosis: t.string,
      deathDate: t.string,
      fromDate: t.string,
      isAccident: t.boolean,
      isICU: t.boolean,
      isSurgery: t.boolean,
      laAddress: t.string,
      laLocationCode: t.string,
      laName: t.string,
      laNum: t.string,
      medicalEndDate: t.string,
      medicalHospitalCode: t.string,
      medicalStartDate: t.string,
      reason: t.string,
      surgeryCode: t.string,
      toDate: t.string
    }),
    paymentInfo: t.type({
      accountName: t.string,
      accountNum: t.string,
      bank: t.string,
      bankBranch: t.string,
      country: t.string,
      dob: t.string,
      nationality: t.string,
      office: t.string,
      paymentMethod: t.string
    })
  }),
  clientId: t.string,
  createdBy: t.string,
  createdDate: t.string,
  documents: t.array(
    t.type({
      name: t.string,
      url: t.string
    })
  ),
  exchangeId: t.string,
  idNumber: t.string,
  location: t.string,
  officeCode: t.string,
  primaryPolicyNo: t.string,
  source: t.string,
  transType: t.string
})

const DeathAndTPDPruonline = Maybe(
  t.type({
    claimEventDate: Maybe(t.string),
    occurrenceDate: Maybe(t.string),
    isRelatedToAccident: Maybe(t.boolean),
    accidentName: Maybe(
      t.type({
        label: t.string,
        value: t.string
      })
    ),
    cause: t.union([
      t.string,
      t.array(
        t.type({
          label: t.string,
          value: t.string
        })
      ),
      t.undefined,
      t.null
    ]),
    tpdName: t.union([
      t.string,
      t.array(
        t.type({
          label: t.string,
          value: t.string
        })
      ),
      t.undefined,
      t.null
    ]),
    remark: Maybe(t.string)
  })
)

const CIPruonline = Maybe(
  t.type({
    claimEventDate: Maybe(t.string),
    occurrenceDate: Maybe(t.string),
    deathDate: Maybe(t.string),
    accidentName: Maybe(
      t.type({
        label: t.string,
        value: t.string
      })
    ),
    isRelatedToAccident: Maybe(t.boolean),
    diagnosis: t.union([
      t.string,
      t.array(
        t.type({
          label: t.string,
          value: t.string
        })
      ),
      t.undefined,
      t.null
    ]),
    ciName: t.union([
      t.string,
      t.array(
        t.type({
          label: t.string,
          value: t.string
        })
      ),
      t.undefined,
      t.null
    ]),
    remark: Maybe(t.string)
  })
)

const payloadPruonline = t.type({
  adjudication: Maybe(adjudicationC),
  policyRider: Maybe(policyRider),
  verification: Maybe(verificationC),
  documents: Maybe(
    t.array(
      t.type({
        name: Maybe(t.string),
        url: Maybe(t.string)
      })
    )
  ),
  body: t.type({
    claimNo: Maybe(t.string),
    subClaimType: Maybe(t.string),
    lifeAssured: t.type({
      name: t.string,
      dob: Maybe(t.string),
      externalIds: Maybe(
        t.type({
          SOE_VALUE: Maybe(t.string)
        })
      )
    }),
    policy: t.type({
      sourceOfBusiness: t.string,
      owners: Maybe(
        t.type({
          clientId: t.string,
          name: Maybe(t.string),
          contactDetails: t.type({
            EMAIL: t.type({
              channel: t.string,
              value: t.string
            }),
            PHONE: t.type({
              channel: t.string,
              countryCode: t.string,
              value: t.string
            })
          })
        })
      )
    }),
    claimType: t.string,
    directBilling: Maybe(t.boolean),
    attributes: t.type({
      IS_CLAIM_ANT_NOT_PO: t.boolean,
      CLAIM_ANT_NAME: t.string,
      CLAIM_ANT_PHONE: t.string,
      CLAIM_ANT_ADDRESS: t.string,
      CLAIM_ANT_EMAIL: t.string,

      CRITICAL_ILLNESS: CIPruonline,
      DEATH: DeathAndTPDPruonline,
      DISABILITY: DeathAndTPDPruonline,
      PATIENT_IN: Maybe(
        t.type({
          hospital: t.string,
          admissionDate: Maybe(t.string),
          dischargeDate: Maybe(t.string),
          surgeryBenefit: Maybe(t.union([t.boolean, t.string])),
          isRelatedToAccident: Maybe(t.boolean),
          icuBenefit: Maybe(t.boolean),
          diagnosis: t.string,
          additionalDiagnosis: Maybe(
            t.array(
              t.type({
                label: t.string,
                value: t.string
              })
            )
          ),
          occurrenceDate: Maybe(t.string),
          accidentName: Maybe(
            t.type({
              label: t.string,
              value: t.string
            })
          )
        })
      ),
      PATIENT_OUT: Maybe(
        t.type({
          hospital: t.string,
          diagnosis: t.string,
          admissionDate: t.string,
          surgeryDate: t.string,
          fee: t.number,
          isRelatedToAccident: Maybe(t.boolean),
          icuBenefit: Maybe(t.boolean),
          occurrenceDate: Maybe(t.string),
          accidentName: Maybe(
            t.type({
              label: t.string,
              value: t.string
            })
          )
        })
      ),
      PATIENT_DENTAL: Maybe(
        t.type({
          hospital: t.string,
          diagnosis: t.string,
          admissionDate: t.string,
          fee: t.number,
          isRelatedToAccident: Maybe(t.boolean),
          icuBenefit: Maybe(t.boolean),
          occurrenceDate: Maybe(t.string),
          accidentName: Maybe(
            t.type({
              label: t.string,
              value: t.string
            })
          )
        })
      ),
      PATIENT_MATERNITY: Maybe(
        t.type({
          hospital: t.string,
          diagnosis: t.string,
          admissionDate: t.string,
          isRelatedToAccident: Maybe(t.boolean),
          icuBenefit: Maybe(t.boolean),
          occurrenceDate: Maybe(t.string),
          accidentName: Maybe(
            t.type({
              label: t.string,
              value: t.string
            })
          )
        })
      ),
      PAYMENT: t.type({
        paymentMethod: t.string,
        isPayeeNotPO: Maybe(t.boolean),
        relationshipWithPO: Maybe(t.string),
        dob: Maybe(t.string),
        gender: Maybe(t.string),
        occupation: Maybe(t.string),
        residenceCountry: Maybe(t.string),
        nationality: Maybe(t.string),
        nationality2: Maybe(t.string),
        address: Maybe(t.string),
        foreignAddress: Maybe(t.string),
        bankTransfer: Maybe(
          t.type({
            accountName: t.string,
            idNumber: t.string,
            accountNumber: t.union([t.string, t.number]),
            bank: Maybe(t.string),
            bankName: Maybe(t.string),
            branch: Maybe(t.string),
            branchName: Maybe(t.string)
          })
        ),
        payAtBank: Maybe(
          t.type({
            payeeName: t.string,
            idNumber: t.string,
            issuedDate: t.string,
            issuedBy: t.string,
            bank: Maybe(t.string),
            bankName: t.string,
            branch: Maybe(t.string),
            branchName: Maybe(t.string)
          })
        ),
        payAtCounter: Maybe(
          t.type({
            payeeName: Maybe(t.string),
            idNumber: Maybe(t.string),
            gaOffice: t.string
          })
        ),
        paymentMethodLabel: Maybe(t.string)
      })
    })
  }),
  subClaimType: Maybe(t.string),
  exchangeId: Maybe(t.string)
})

export const VerificationClaim = t.union([
  Base(
    t.type({
      source: t.literal(SourceType.BANCA_SEA),
      claimNumber: t.string,
      payload: payloadBanca
    })
  ),
  Base(
    t.type({
      source: t.literal(SourceType.PRUONLINE),
      claimNumber: t.string,
      payload: payloadPruonline,
      policyNumber: Maybe(t.string)
    })
  ),
  Base(
    t.type({
      source: t.string,
      claimNumber: t.string,
      payload: t.type({
        adjudication: Maybe(adjudicationC),
        policyRider: Maybe(policyRider),
        verification: Maybe(verificationC),
        documents: Maybe(
          t.array(
            t.type({
              name: Maybe(t.string),
              url: Maybe(t.string)
            })
          )
        ),
        body: t.type({
          claimNo: Maybe(t.string),
          subClaimType: Maybe(t.string),
          lifeAssured: t.type({
            name: t.string,
            dob: Maybe(t.string),
            externalIds: Maybe(
              t.type({
                SOE_VALUE: Maybe(t.string)
              })
            )
          }),
          policy: t.type({
            sourceOfBusiness: t.string,
            owners: Maybe(
              t.type({
                clientId: t.string,
                name: Maybe(t.string),
                contactDetails: t.type({
                  EMAIL: t.type({
                    channel: t.string,
                    value: t.string
                  }),
                  PHONE: t.type({
                    channel: t.string,
                    countryCode: t.string,
                    value: t.string
                  })
                })
              })
            )
          }),
          claimType: t.string,
          directBilling: Maybe(t.boolean),
          attributes: t.type({
            IS_CLAIM_ANT_NOT_PO: t.boolean,
            CLAIM_ANT_NAME: t.string,
            CLAIM_ANT_PHONE: t.string,
            CLAIM_ANT_ADDRESS: t.string,
            CLAIM_ANT_EMAIL: t.string,

            CRITICAL_ILLNESS: CI,
            DEATH: DeathAndTPD,
            DISABILITY: DeathAndTPD,
            PATIENT_IN: Maybe(
              t.type({
                hospital: t.string,
                admissionDate: Maybe(t.string),
                dischargeDate: Maybe(t.string),
                surgeryBenefit: Maybe(t.union([t.boolean, t.string])),
                isRelatedToAccident: Maybe(t.boolean),
                icuBenefit: Maybe(t.boolean),
                diagnosis: t.string,
                additionalDiagnosis: Maybe(
                  t.array(
                    t.type({
                      label: t.string,
                      value: t.string
                    })
                  )
                ),
                occurrenceDate: Maybe(t.string),
                accidentName: Maybe(
                  t.type({
                    label: t.string,
                    value: t.string
                  })
                )
              })
            ),
            PATIENT_OUT: Maybe(
              t.type({
                hospital: t.string,
                diagnosis: t.string,
                admissionDate: t.string,
                surgeryDate: t.string,
                fee: t.number,
                isRelatedToAccident: Maybe(t.boolean),
                icuBenefit: Maybe(t.boolean),
                occurrenceDate: Maybe(t.string),
                accidentName: Maybe(
                  t.type({
                    label: t.string,
                    value: t.string
                  })
                )
              })
            ),
            PATIENT_DENTAL: Maybe(
              t.type({
                hospital: t.string,
                diagnosis: t.string,
                admissionDate: t.string,
                fee: t.number,
                isRelatedToAccident: Maybe(t.boolean),
                icuBenefit: Maybe(t.boolean),
                occurrenceDate: Maybe(t.string),
                accidentName: Maybe(
                  t.type({
                    label: t.string,
                    value: t.string
                  })
                )
              })
            ),
            PATIENT_MATERNITY: Maybe(
              t.type({
                hospital: t.string,
                diagnosis: t.string,
                admissionDate: t.string,
                isRelatedToAccident: Maybe(t.boolean),
                icuBenefit: Maybe(t.boolean),
                occurrenceDate: Maybe(t.string),
                accidentName: Maybe(
                  t.type({
                    label: t.string,
                    value: t.string
                  })
                )
              })
            ),
            PAYMENT: t.type({
              paymentMethod: t.string,
              isPayeeNotPO: Maybe(t.boolean),
              relationshipWithPO: Maybe(t.string),
              dob: Maybe(t.string),
              gender: Maybe(t.string),
              occupation: Maybe(t.string),
              residenceCountry: Maybe(t.string),
              nationality: Maybe(t.string),
              nationality2: Maybe(t.string),
              address: Maybe(t.string),
              foreignAddress: Maybe(t.string),
              bankTransfer: Maybe(
                t.type({
                  accountName: t.string,
                  idNumber: t.string,
                  accountNumber: t.union([t.string, t.number]),
                  bank: Maybe(t.string),
                  bankName: Maybe(t.string),
                  branch: Maybe(t.string),
                  branchName: Maybe(t.string)
                })
              ),
              payAtBank: Maybe(
                t.type({
                  payeeName: t.string,
                  idNumber: t.string,
                  issuedDate: t.string,
                  issuedBy: t.string,
                  bank: Maybe(t.string),
                  bankName: t.string,
                  branch: Maybe(t.string),
                  branchName: Maybe(t.string)
                })
              ),
              payAtCounter: Maybe(
                t.type({
                  payeeName: Maybe(t.string),
                  idNumber: Maybe(t.string),
                  gaOffice: t.string
                })
              )
            })
          })
        }),
        subClaimType: Maybe(t.string),
        exchangeId: Maybe(t.string)
      }),
      policyNumber: Maybe(t.string)
    })
  )
])

export type VerificationClaim = t.TypeOf<typeof VerificationClaim>
