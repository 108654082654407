import { SelectOption } from "@pulseops/common"

export namespace ConfigT33OfficeConst {
  export const EFMConst = [
    { id: 'Y', label: 'common:Yes' },
    { id: 'N', label: 'common:No' }
  ]
}

export const GAInformationSexConst = [
  { id: 'Anh', label: 'Anh' },
  { id: 'Chị', label: 'Chị' }
]

export interface ConfigBankFormData {
  bankCode: string
  pulseBankCode: string
  bankName: string
  status: SelectOption | undefined
}

export interface ConfigBankBranchFormData {
  bank: SelectOption | undefined
  branchCode: string
  pulseBankCode: string
  pulseCode: string
  branchName: string
  branchNameEn: string
}

export interface ConfigT33OfficeFormData {
  id: string
  officeCode: string
  officeNameEn: string
  officeNameVi: string
  // type: SelectOption | undefined
  // GACode: SelectOption | undefined
  // bankCode: SelectOption | undefined
  type: string
  GACode: string
  bankCode: string
  isEFM: string
  status: SelectOption | undefined
}

export interface ConfigGAInformationFormData {
  id: string
  adRegion: string
  adZone: string
  address: string
  agentCode: string
  bankCode: string
  classification: string
  companyName: string
  csRegion: string
  dob: string
  extraPay: string
  gaCSAssociate: string
  gaCSAssociateEmail: string
  gaCSEmail: string
  gaCSSupportManager: string
  gaCode: string
  gaFlag: string
  gaMother: string
  gaOMEmail: string
  gaServiceAssociate: string
  gaTaxCode: string
  gadEmail: string
  gadName: string
  gamRHS: string
  mobile: string
  officeCode: string
  officeName: SelectOption | undefined
  openDate: string
  province: string
  sex: string
  telPhone: string
  transactionLiveZoom: string
  type: string
  couchbaseId: string
}