import { AppContext, AuthState, CanView, ErrorHandling, MyTaskPermissions, OBMyTaskPermissions, Permission, RBAC, TeamTaskPermissions } from '@pulseops/common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { SafeAreaView, StyleSheet, View, Text, TouchableOpacity } from 'react-native'
import { OBMyTask } from './OBMyTask'
import { OBTeamTask } from './OBTeamTask'
import { StackNavigationProp, StackScreenProps } from '@react-navigation/stack'
import { OutboundParamList } from '../OutboundParamList'
import { pipe } from 'fp-ts/lib/function'
import { CallProgramInfo, OBBasketType, OBTaskService } from '../ob-service'
import { useIsFocused } from '@react-navigation/native'
import { ZIO } from '@mxt/zio'
import { DrawerContentComponentProps, DrawerContentOptions } from '@react-navigation/drawer'
// import { useIsFocused } from '@react-navigation/native'

export type OBTaskCount = {
  value: OBBasketType,
  count: number
}

type TaskManagementContextInfo = {
  //  parentNavigation?: StackNavigationProp<OutboundParamList, "OBTaskManagementScreen">
  permissions: string[]
  managerList:  string[]
  staffList: string []
  callProgramList: CallProgramInfo[]
}

const defaultTaskContext: TaskManagementContextInfo = {
  permissions: [],
  managerList:  [],
  staffList:  [],
  callProgramList: []
}

export const TaskManagementContext = React.createContext<TaskManagementContextInfo>(defaultTaskContext)

export const OBTaskManagementScreen = (props: DrawerContentComponentProps<DrawerContentOptions>) => {
  const [activeTabIndex, setActiveTabIndex] = React.useState<number>(0)
  props.navigation
  const { t } = useTranslation()
  const authData = pipe(AuthState.auth.get)
  const permissions: string[] = pipe(RBAC.permissionWithNoCache, ErrorHandling.runDidUpdate([authData])) || []
  const isFocused = useIsFocused()
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)
  
  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            props.navigation.navigate('HomeScreen')
          }
        },
        {
          title: t('home:TaskManagement'),
          navigate: null
        }
      ])
    }
  }, [isFocused])

  const { managerList, staffList, callProgramList } = pipe(
    ZIO.zipPar(
      OBTaskService.getManagerNameArr(),
      OBTaskService.getStaffList(),
      OBTaskService.getCallProgramList()
    ),
    ZIO.map(([managerList, staffList, callProgramList]) => {
      return {
        managerList,
        staffList, 
        callProgramList
      }
    }),
    ErrorHandling.runDidMount()
  ) || {
    managerList: [],
    staffList: [],
    callProgramList: []
  }

  return (
    <TaskManagementContext.Provider value={{ permissions: permissions, managerList: managerList, staffList: staffList, callProgramList: callProgramList }}>
      <SafeAreaView style={taskStyles.container}>
        <View style={taskStyles.tabWrapper}>
          <TouchableOpacity onPress={() => setActiveTabIndex(0)}>
            <View style={[taskStyles.tabContent, activeTabIndex !== 0 && taskStyles.tabHidden]}>
              <Text style={[taskStyles.tabText, activeTabIndex === 0 && taskStyles.tabHightLightText]}>
                {t('TaskManagement:MyTasks')}
              </Text>
            </View>
          </TouchableOpacity>
          {permissions.includes(Permission['ViewOBTeamTask']) &&
            <TouchableOpacity onPress={() => setActiveTabIndex(1)}>
              <View style={[taskStyles.tabContent, activeTabIndex !== 1 && taskStyles.tabHidden]}>
                <Text style={[taskStyles.tabText, activeTabIndex === 1 && taskStyles.tabHightLightText]}>
                  {t('TaskManagement:TeamTasks')}
                </Text>
              </View>
            </TouchableOpacity>
          }
        </View>
        <OBMyTask isActive={activeTabIndex === 0} parentTabIndex={activeTabIndex} ></OBMyTask>
        <OBTeamTask isActive={activeTabIndex === 1} parentTabIndex={activeTabIndex}></OBTeamTask>
      </SafeAreaView>
    </TaskManagementContext.Provider>

  )
}
const taskStyles = StyleSheet.create({
  container: {
    flex: 1
  },
  tabWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 20,
    marginLeft: 20
  },
  tabContent: {
    width: 200,
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 20,
    paddingVertical: 2,
    backgroundColor: '#fff',
    borderTopLeftRadius: 14,
    borderTopRightRadius: 14
  },
  tabHidden: {
    backgroundColor: '#E7E7E7'
  },
  tabHightLightText: {
    color: '#ED1B2E',
    fontWeight: '700'
  },
  tabText: {
    textAlign: 'center',
    fontSize: 14,
    fontStyle: 'normal',
    color: '#828282'
  }
})
