import { PayoutPopup, LapseReins } from './model'
import { ZIO } from '@mxt/zio'
import { pipe } from 'fp-ts/function'
import * as t from 'io-ts'
import * as A from 'fp-ts/Array'
import moment from 'moment'
import * as O from 'fp-ts/Option'
import { SubmissionService } from './SubmissionService'
import { parseDate } from '../shared'
import { TopUpService } from './TopUpService'
import { POApi } from '../POApi'
import { Maybe } from '@mxt/zio/codec'

export namespace PayoutService {
  /**PAY PREMIUM */

  export const getPayPremiumMaturityAdvDetail = (policyNum: string) =>
    pipe(
      POApi.get(`wf-api/policy/${policyNum}`)(
        t.type({
          body: t.type({
            owners: t.type({
              id: t.string
            })
          })
        })
      ),
      ZIO.flatMap((policy) =>
        pipe(
          POApi.post(`wf-api/customer/policies`)(
            t.type({
              body: t.array(
                t.type({
                  clientNum: t.string,
                  policyNo: t.string,
                  role: t.string
                })
              )
            })
          )({
            body: {
              securityNo: `C${policy.body.owners.id}P` // format CxxxP
            }
          }),
          ZIO.flatMap((clientPolicies) =>
            pipe(
              SubmissionService.getPolicies(
                clientPolicies.body.map((e) => ({
                  policyNo: e.policyNo
                }))
              ),
              ZIO.flatMap((policyInfo) =>
                pipe(
                  SubmissionService.getClients(
                    policyInfo.map((e) => ({
                      clientId: e.ownerId
                    }))
                  ),
                  ZIO.map((clients): Array<PayoutPopup.PayPremiumData> => {
                    const k = 10000000
                    return policyInfo
                      .filter((x) => x.contractStatus === 'PS')
                      .map((policy) => ({
                        policyNum: policy.policyNo,
                        paidToDate: moment(policy.paidToDateBasic).format('DD/MM/yyyy'),
                        installmentPremium: policy.totalPremium,
                        poName: clients.body.find((client) => client.clientId === policy.ownerId)?.name ?? '',
                        totalPremium: policy.totalPremium,
                        billingFrequency: policy.billFreq,
                        isMaturityAdvValid: Number(policy.totalPremium) * Number(policy.billFreq) >= k
                      }))
                      .sort((p1, p2) => {
                        return p1.policyNum >= p2.policyNum ? 1 : -1
                      })
                  })
                )
              )
            )
          )
        )
      )
    )

  export const getPayPremiumDetail = (policyNum: string, isSeaBankPolicy?: boolean) =>
    pipe(
      POApi.get(`wf-api/policy/${policyNum}`)(
        t.type({
          body: t.type({
            owners: t.type({
              id: t.string
            })
          })
        })
      ),
      ZIO.flatMap((policy) =>
        pipe(
          isSeaBankPolicy ? getPoliciesForSeabank(policy.body.owners.id) :
            POApi.post(`wf-api/customer/policies`)(
              t.type({
                body: t.array(
                  t.type({
                    clientNum: t.string,
                    policyNo: t.string,
                    role: t.string
                  })
                )
              })
            )({
              body: {
                securityNo: `C${policy.body.owners.id}P` // format CxxxP
              }
            }),
          ZIO.flatMap((clientPolicies) =>
            pipe(
              SubmissionService.getPolicies(
                clientPolicies.body.map((e) => ({
                  policyNo: e.policyNo
                }))
              ),
              ZIO.flatMap((policyInfo) =>
                pipe(
                  SubmissionService.getClients(
                    policyInfo.map((e) => ({
                      clientId: e.ownerId
                    }))
                  ),
                  ZIO.map((clients): Array<PayoutPopup.PayPremiumData> => {
                    return policyInfo
                      .filter(
                        (x) =>
                          x.contractStatus === 'PS' ||
                          (x.contractStatus === 'IF' && (x.premiumStatus === 'PP' || x.premiumStatus === 'HA'))
                      )
                      .map((policy) => ({
                        policyNum: policy.policyNo,
                        paidToDate:
                          policy.paidToDateBasic !== '0' ? moment(policy.paidToDateBasic).format('DD/MM/YYYY') : '-',
                        installmentPremium: policy.totalPremium,
                        poName: clients.body.find((client) => client.clientId === policy.ownerId)?.name ?? '',
                        totalPremium: policy.totalPremium,
                        billingFrequency: policy.billFreq
                      }))
                  })
                )
              )
            )
          )
        )
      )
    )

  export const getPoliciesForSeabank = (client: string) => pipe(
    POApi.get(`wf-api/customer/policies-owner/${client}`)(t.type({
      body: t.array(
        t.type({
          clientNum: t.string,
          policyNo: t.string,
          role: t.string
        })
      )
    }))
  )

  export const searchPolicy = (policyNum: string) =>
    pipe(
      SubmissionService.getPolicy(policyNum),
      ZIO.flatMap((policy) =>
        pipe(
          SubmissionService.getClientIDandNum(policy.body.owners.id || '-'),
          ZIO.map((owner): PayoutPopup.PayPremiumOtherData => {
            const k = 10000000
            return {
              policyNum,
              poName: owner.clientName ?? '',
              paidToDate:
                policy.body.paidToDateBasic && policy.body.paidToDateBasic !== '0'
                  ? moment(policy.body.paidToDateBasic).format('DD/MM/YYYY')
                  : '-',
              installmentPremium: policy.body.totalPremium ?? 0,
              totalPremium: policy.body.totalPremium ?? 0,
              billingFrequency: policy.body.billFreq ?? '',
              isMaturityAdvValid:
                Number(policy.body.totalPremium) * Number(policy.body.billFreq) >= k && policy.body.status === 'PS',
              isPayPremiumValid:
                policy.body.status === 'PS' ||
                (policy.body.status === 'IF' &&
                  (policy.body.premiumStatus === 'PP' || policy.body.premiumStatus === 'HA'))
            }
          })
        )
      )
    )

  //   /** REPAY LOAN */

  export const getRepayLoadData = (policyNum: string) =>
    pipe(
      POApi.get(`wf-api/policy/${policyNum}`)(
        t.type({
          body: t.type({
            owners: t.type({
              id: t.string
            })
          })
        })
      ),
      ZIO.flatMap((policy) =>
        pipe(
          POApi.post(`wf-api/customer/policies`)(
            t.type({
              body: t.array(
                t.type({
                  clientNum: t.string,
                  policyNo: t.string,
                  role: t.string
                })
              )
            })
          )({
            body: {
              securityNo: `C${policy.body.owners.id}L` // format: CxxxL
            }
          }),
          ZIO.flatMap((clientPolicies) => {
            const loandEnq = pipe(
              clientPolicies.body,
              A.map((po) =>
                pipe(
                  ZIO.zipPar(
                    pipe(
                      SubmissionService.getLoanEnquiry(po.policyNo),
                      ZIO.catchAll(() => ZIO.succeed(null)) // catch and passing value when errors occur
                    ),
                    SubmissionService.getPolicy(po.policyNo)
                  ),
                  ZIO.flatMap(([loan, policyInfo]) =>
                    pipe(
                      SubmissionService.getCustomer(policyInfo.body.owners.id ?? ''),
                      ZIO.map((client): PayoutPopup.ReloanPayData => {
                        const opl = loan?.subfileOccurs
                          ? loan?.subfileOccurs
                            .filter((e) => e.loanType === 'P')
                            .reduce(
                              (total, next) =>
                                total +
                                (next.accuredInterest ?? 0) +
                                (next.pendingInterest ?? 0) +
                                (next.currentBalance ?? 0),
                              0
                            )
                          : 0 // type P, principal --> currentBalance
                        const apl = loan?.subfileOccurs
                          ? loan?.subfileOccurs
                            .filter((e) => e.loanType === 'A')
                            .reduce(
                              (total, next) =>
                                total +
                                (next.accuredInterest ?? 0) +
                                (next.pendingInterest ?? 0) +
                                (next.currentBalance ?? 0),
                              0
                            )
                          : 0 // type A, principal --> currentBalance

                        return {
                          policyNum: po.policyNo,
                          opl,
                          apl,
                          poName: client.body.name ?? '',
                          totalPremium: policyInfo.body.totalPremium ?? 0
                        }
                      })
                    )
                  )
                )
              ),
              ZIO.sequence
            )
            return loandEnq
          })
        )
      )
    )

  export const searchPolicyRepayLoan = (policyNum: string) =>
    pipe(
      SubmissionService.getPolicy(policyNum),
      ZIO.flatMap((policy) =>
        pipe(
          ZIO.zipPar(
            SubmissionService.getClientIDandNum(policy.body.owners.id || '-'),
            SubmissionService.getLoanEnquiry(policyNum)
          ),
          ZIO.map(([owner, loan]): PayoutPopup.RepayLoanOtherData => {
            const opl = loan.subfileOccurs
              ? loan.subfileOccurs
                .filter((e) => e.loanType === 'P')
                .reduce(
                  (total, next) =>
                    total + (next.accuredInterest ?? 0) + (next.pendingInterest ?? 0) + (next.currentBalance ?? 0),
                  0
                )
              : 0 // type P, principal --> currentBalance
            const apl = loan.subfileOccurs
              ? loan.subfileOccurs
                .filter((e) => e.loanType === 'A')
                .reduce(
                  (total, next) =>
                    total + (next.accuredInterest ?? 0) + (next.pendingInterest ?? 0) + (next.currentBalance ?? 0),
                  0
                )
              : 0 // type A, principal --> currentBalance
            return {
              policyNum,
              poName: owner.clientName ?? '',
              opl,
              apl,
              totalPremium: policy.body.totalPremium ?? 0,
              isNotFound: !loan.contractType,
              isRepayLoanvalid:
                !!policy.body.premiumStatus &&
                policy.body.status === 'IF' &&
                ['PP', 'FP', 'WV'].includes(policy.body.premiumStatus) &&
                (apl > 0 || opl > 0)
            }
          })
        )
      )
    )

  //   /** OTHER PAY PREMIUM */
  export const getOtherPremiumDetail = (policyNum: string) =>
    pipe(
      POApi.get(`wf-api/policy/${policyNum}`)(
        t.type({
          body: t.type({
            owners: t.type({
              id: t.string
            })
          })
        })
      ),
      ZIO.flatMap((policy) =>
        pipe(
          POApi.post(`wf-api/customer/policies`)(
            t.type({
              body: t.array(
                t.type({
                  clientNum: t.string,
                  policyNo: t.string,
                  role: t.string
                })
              )
            })
          )({
            body: {
              securityNo: `C${policy.body.owners.id}O` // format: CxxxO
            }
          }),
          ZIO.flatMap((clientPolicies) =>
            pipe(
              SubmissionService.getPolicies(
                clientPolicies.body.map((e) => ({
                  policyNo: e.policyNo
                }))
              ),
              ZIO.flatMap((policyInfo) =>
                pipe(
                  SubmissionService.getClients(
                    policyInfo.map((policy) => ({
                      clientId: policy.ownerId
                    }))
                  ),
                  ZIO.map((clients): Array<PayoutPopup.OtherPayPremiumData> => {
                    return policyInfo
                      .filter(
                        (x) =>
                          x.contractStatus === 'PS' ||
                          (x.contractStatus === 'IF' && (x.premiumStatus === 'PP' || x.premiumStatus === 'HA')) ||
                          x.contractStatus === 'LA' //rule calc at backend
                      )
                      .map((policy) => ({
                        policyNum: policy.policyNo,
                        contractStatus: policy.contractStatus,
                        poName: clients.body.find((client) => client.clientId === policy.ownerId)?.name ?? '',
                        totalPremium: policy.totalPremium
                      }))
                  })
                )
              )
            )
          )
        )
      )
    )

  //   /** MATURITY ADVANCE */
  export const getTopupMaturityAdvDetail = (policyNum: string) =>
    pipe(
      POApi.get(`wf-api/policy/${policyNum}`)(
        t.type({
          body: t.type({
            owners: t.type({
              id: t.string
            })
          })
        })
      ),
      ZIO.flatMap((policy) =>
        pipe(
          POApi.post(`wf-api/customer/policies`)(
            t.type({
              body: t.array(
                t.type({
                  clientNum: t.string,
                  policyNo: t.string,
                  role: t.string
                })
              )
            })
          )({
            body: {
              securityNo: `C${policy.body.owners.id}O` // format: CxxxO
            }
          }),
          ZIO.flatMap((clientPolicies) =>
            pipe(
              SubmissionService.getPolicies(
                clientPolicies.body.map((e) => ({
                  policyNo: e.policyNo
                }))
              ),
              ZIO.map((e) =>
                e.filter((x) => x.contractStatus === 'PS' && ['U', 'V'].includes(x.productCode.charAt(0)))
              ),
              ZIO.flatMap((policyInfo) =>
                pipe(
                  ZIO.zipPar(
                    SubmissionService.getClients(
                      policyInfo.map((policy) => ({
                        clientId: policy.ownerId
                      }))
                    ),
                    GetTopUpPolicyAmounts(policyInfo.map((policy) => policy.policyNo))
                  ),
                  ZIO.map(([clients, topUpPolicies]): Array<PayoutPopup.OtherPayPremiumData> => {
                    console.log(policyInfo)
                    const getTopupData = (policyNo: string) =>
                      topUpPolicies.find((e) => e.policyNum === policyNo) ?? {
                        maxTopUp: 0,
                        ogMaxTopup: 0,
                        totalPremium: 0
                      }
                    return policyInfo.map((policy) => {
                      const topupData = getTopupData(policy.policyNo)
                      return {
                        policyNum: policy.policyNo,
                        contractStatus: policy.contractStatus,
                        poName: clients.body.find((client) => client.clientId === policy.ownerId)?.name ?? '',
                        totalPremium: policy.totalPremium,
                        minTopUp: 10000000,
                        maxTopUp: ['UR2', 'UR4'].includes(policy.productCode)
                          ? topupData.maxTopUp
                          : topupData.ogMaxTopup * topupData.totalPremium
                      }
                    })
                  })
                )
              )
            )
          )
        )
      )
    )

  export const GetTopUpPolicyAmounts = (policies: Array<string>) =>
    pipe(
      policies,
      A.map((policy) =>
        pipe(
          TopUpService.getDetail(policy),
          ZIO.map((topup): PayoutPopup.TopUpPolicy => {
            console.log('topup', topup)
            return {
              policyNum: policy,
              minTopUp: topup.minTopUp,
              maxTopUp: topup.maxTopUp,
              ogMaxTopup: topup.OGmaxTopUp,
              totalPremium: topup.totalPremium
            }
          })
        )
      ),
      ZIO.sequence
    )

  export const searchPolicyOther = (policyNum: string) =>
    pipe(
      SubmissionService.getPolicy(policyNum),
      ZIO.flatMap((policy) =>
        pipe(
          ZIO.zipPar(
            SubmissionService.getClientIDandNum(policy.body.owners.id || '-'),
            policy.body.status === 'LA'
              ? pipe(
                POApi.post(`wf-api/policy/billing-change-apl/lapse-reins-enquiry`)(LapseReins.LapseReins)({
                  body: {
                    contractNumber: policyNum
                  }
                }),
                ZIO.catchAll(() => ZIO.succeed(null))
              )
              : ZIO.succeed(null)
          ),
          ZIO.map(([owner, lapse]): PayoutPopup.OtherPayPremiumSearchData => {
            const k = 10000000
            const lapsedDate = !!lapse && lapse.body.lapseDate ? parseDate(lapse.body.lapseDate) : null
            return {
              policyNum,
              poName: owner.clientName ?? '',
              contractStatus: policy.body.status ?? '',
              totalPremium: policy.body.totalPremium ?? 0,
              isMaturityAdvValid: Number(policy.body.totalPremium) * Number(policy.body.billFreq) >= k,
              isOtherPayPremiumValid:
                policy.body.status === 'PS' ||
                (policy.body.status === 'IF' && policy.body.premiumStatus === 'PP') ||
                (policy.body.status === 'IF' && policy.body.premiumStatus === 'HA') ||
                (!!lapsedDate && policy.body.status === 'LA' && moment().diff(moment(lapsedDate), 'months') < 24)
            }
          })
        )
      )
    )

  export const searchPolicyTopupMaturityAdv = (policyNum: string) =>
    pipe(
      ZIO.zipPar(SubmissionService.getPolicy(policyNum), GetTopUpPolicyAmounts([policyNum])),
      ZIO.flatMap(([policy, topUpPolicies]) =>
        pipe(
          SubmissionService.getClientIDandNum(policy.body.owners.id || '-'),
          ZIO.map((owner): PayoutPopup.OtherPayPremiumSearchData => {
            const minTopUp = 10000000
            const maxTopUp = ['UR2', 'UR4'].includes(policy.body.productCode ?? '')
              ? topUpPolicies[0].maxTopUp
              : topUpPolicies[0].ogMaxTopup * topUpPolicies[0].totalPremium
            return {
              policyNum,
              poName: owner.clientName ?? '',
              contractStatus: policy.body.status ?? '',
              totalPremium: policy.body.totalPremium ?? 0,
              minTopUp,
              maxTopUp,
              isMaturityAdvValid:
                policy.body.status === 'PS' &&
                !!policy.body.productCode &&
                ['U', 'V'].includes(policy.body.productCode.charAt(0))
            }
          })
        )
      )
    )

  //   /** BANK TRANSFER */
  const bankInfo =  t.type({
    code: t.string,
    name: t.string,
    codePulse: Maybe(t.string)
  })

  const bankRes = t.type({
    body: t.array(bankInfo)
  })

  export type bankInfo = t.TypeOf<typeof bankInfo>

  export const getBanks = (status: string) =>
    pipe(
      POApi.post(`wf-api/bank/search`)(bankRes)({
        body: { status: status }
      }),
      ZIO.map((banks) => banks.body.filter((bank) => bank.code !== 'NULL' && bank.code !== 'VRB'))
    )

  export const getDifferentBankList = () => pipe(
    POApi.post(`wf-api/bank/search-equal`)(bankRes)({
      body: {}
    }),
    ZIO.map((banks) => banks.body.filter((bank) => bank.code !== 'NULL' && bank.code !== 'VRB'))
  )

  const branchRes = t.type({
    body: t.array(
      t.type({
        code: t.string,
        name: t.string
      })
    )
  })

  export const bankBranch = (code: string, codePulse?: string) =>
    pipe(
      POApi.get(`wf-api/bank/${code}/branch/search?bankCodePulse=${codePulse}`)(branchRes),
      ZIO.map((branches) =>
        branches.body.map((x) => ({
          code: x.code,
          name: x.name
        }))
      )
    )

  export const bankBranchByCode = (code: string) => pipe(
    POApi.get(`wf-api/bank/${code}/branch/search`)(branchRes),
    ZIO.map((branches) => {
      const branchArr = !!branches && branches.body.length > 0 ? branches.body.filter(x => x.code === code).map((x) => ({
        code: x.code,
        name: x.name
      })) : []
      return branchArr
    })
  )

  export const getClients = (idNumber: string) =>
    pipe(
      POApi.get(`wf-api/customer/get-all/${idNumber}`)(
        t.type({
          body: t.array(
            t.type({
              address01: Maybe(t.string),
              address02: Maybe(t.string),
              address03: Maybe(t.string),
              address04: Maybe(t.string),
              countryCode: t.string,
              dob: t.string,
              dod: t.string,
              email: t.string,
              gender: t.string,
              giveName: t.string,
              idDate: Maybe(t.string),
              location: t.string,
              married: t.string,
              mobileNumber: t.string,
              nationality: t.string,
              nationality2: Maybe(t.string),
              occupationCode: t.string,
              salutation: t.string,
              securityNo: t.string,
              sureName: t.string,
              foreignStreet: Maybe(t.string),
              foreignCountry: Maybe(t.string)
            })
          )
        })
      ),
      ZIO.map((clients) => clients.body)
    )

  export const getOwnerInfo = (policyNum: string) =>
    pipe(
      SubmissionService.getPolicy(policyNum),
      ZIO.flatMap((policy) =>
        pipe(
          policy.body.owners.id,
          O.fromNullable,
          O.filter((id) => id.length > 0),
          O.fold(
            () => ZIO.succeed({ ownerName: '', nationalId: '', mobilePhone: '' }),
            (clientId) =>
              pipe(
                SubmissionService.getCustomer(clientId),
                ZIO.map((client): { ownerName: string; nationalId: string; mobilePhone: string } => ({
                  ownerName: client.body.name ?? '',
                  nationalId: client.body.externalIds.SOE_VALUE ?? '',
                  mobilePhone: client.body.mobilePhone ?? ''
                }))
              )
          )
        )
      )
    )
}
