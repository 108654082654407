import { ZIO } from '@mxt/zio'
import { Maybe } from '@mxt/zio/codec'
import { POApi } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'

export const T4S7Config = t.type({
  total: t.number,
  // start: t.number,
  // size: t.number,
  data: Maybe(
    t.array(
      t.type({
        id: Maybe(t.string),
        activityBasket: Maybe(t.string),
        transactionType: Maybe(t.string),
        maxCase: Maybe(t.number),
        version: Maybe(t.string)
      })
    )
  )
})
export type T4S7Config = t.TypeOf<typeof T4S7Config>

export type T4S7UpdateParams = {
  id: string
  maxCase: number
  version: string
}

export const T4S14Config = t.type({
  total: t.number,
  // start: t.number,
  // size: t.number,
  data: Maybe(
    t.array(
      t.type({
        id: Maybe(t.string),
        managerEmail: Maybe(t.string),
        teamNo: Maybe(t.number),
        teamName: Maybe(t.string)
      })
    )
  )
})
export type T4S14Config = t.TypeOf<typeof T4S14Config>

export type T4S14UpdateParams = {
  id: string
  managerEmail: string
  teamNo?: number
  teamName?: string
}

export const T4S18Config = t.type({
  total: t.number,
  // start: t.number,
  // size: t.number,
  data: Maybe(
    t.array(
      t.type({
        id: Maybe(t.string),
        staffEmail: Maybe(t.string),
        teamCode: Maybe(t.string),
        extensionCode: Maybe(t.string),
        status: Maybe(t.string)
      })
    )
  )
})
export type T4S18Config = t.TypeOf<typeof T4S18Config>

export type QueryT4S18 = {
  start: number
  size: number
  sort?: 'ASC' | 'DESC'
  orders?: string[]
  staffEmail?: string
}

export type T4S18UpdateParams = {
  id: string
  staffEmail: string
  extensionCode: string
  teamCode: string
  status: string
}

export const T4S15Config = t.type({
  total: t.number,
  // start: t.number,
  // size: t.number,
  data: Maybe(
    t.array(
      t.type({
        id: Maybe(t.string),
        staffEmail: Maybe(t.string),
        teamNo: Maybe(t.number),
        blackList: Maybe(t.string),
        extensionCode: Maybe(t.string),
        status: Maybe(t.string)
      })
    )
  )
})
export type T4S15Config = t.TypeOf<typeof T4S15Config>

export type T4S15UpdateParams = {
  id: string
  staffEmail: string
  teamNo?: number
  blackList: string
  extensionCode: string
  status: string
}

export const T4S17Config = t.type({
  total: t.number,
  // start: t.number,
  // size: t.number,
  data: Maybe(
    t.array(
      t.type({
        id: Maybe(t.string),
        email: Maybe(t.string),
        listUserGroupCode: Maybe(t.array(t.string)),
        status: Maybe(t.string)
      })
    )
  )
})
export type T4S17Config = t.TypeOf<typeof T4S17Config>

export type T4S17UpdateParams = {
  id: string
  email: string
  listUserGroupCode: string[]
  status: string
}

export const T4S19Config = t.type({
  data: Maybe(
    t.array(
      t.type({
        id: Maybe(t.string),
        managerEmail: Maybe(t.string),
        teamCode: Maybe(t.string),
        teamName: Maybe(t.string)
      })
    )
  )
})
export type T4S19Config = t.TypeOf<typeof T4S19Config>

export const ManagerList = t.type({
  data: Maybe(
    t.array(
      t.type({
        name: Maybe(t.string),
        email: Maybe(t.string),
        userGroupCode: Maybe(t.string)
      })
    )
  )
})
export type ManagerList = t.TypeOf<typeof ManagerList>

export const A2S3Config = t.type({
  data: Maybe(
    t.array(
      t.type({
        id: Maybe(t.string),
        answerCode: Maybe(t.string),
        answerEN: Maybe(t.string),
        answerVN: Maybe(t.string),
        createdDate: Maybe(t.string)
      })
    )
  )
})
export type A2S3Config = t.TypeOf<typeof A2S3Config>

export const A2S1ConfigList = t.type({
  data: t.array(
    t.type({
      id: Maybe(t.string),
      category: Maybe(t.string),
      transactionTypeWF: Maybe(t.string),
      questionCode: Maybe(t.string),
      questionDescEn: Maybe(t.string),
      questionDescVN: Maybe(t.string),
      questionOrder: Maybe(t.number),
      status: Maybe(t.boolean),
      order: Maybe(t.number),
      createdDate: Maybe(t.string)
    })
  )
})
export type A2S1ConfigList = t.TypeOf<typeof A2S1ConfigList>

export const A2S2ConfigList = t.type({
  data: t.array(
    t.type({
      id: Maybe(t.string),
      questionCode: Maybe(t.string),
      answerCode: Maybe(t.string),
      answerOrder: Maybe(t.number)
    })
  )
})
export type A2S2ConfigList = t.TypeOf<typeof A2S2ConfigList>

export const A2S3ConfigList = t.type({
  data: t.array(
    t.type({
      id: Maybe(t.string),
      answerCode: Maybe(t.string),
      answerVN: Maybe(t.string),
      answerEN: Maybe(t.string)
    })
  )
})
export type A2S3ConfigList = t.TypeOf<typeof A2S3ConfigList>

export namespace AdmintoolService {
  export const getT4S7Config = () => {
    return pipe(
      POApi.get(`administration-rest/t4s7config/all`)(T4S7Config),
      ZIO.map((res) => (res.data ? res.data.filter((i) => i.activityBasket === 'OB-VER') : []))
    )
  }
  export const updateT4S7Config = (data: T4S7UpdateParams) => {
    return pipe(
      POApi.put(`administration-rest/t4s7config`)(t.unknown)(data),
      ZIO.map((res) => res)
    )
  }
  export const getT4S14Config = () => {
    return pipe(
      POApi.get(`administration-rest/t4s14config/all`)(T4S14Config),
      ZIO.map((res) => res)
    )
  }
  export const updateT4S14Config = (data: T4S14UpdateParams) => {
    return pipe(
      POApi.put(`administration-rest/t4s14config`)(t.unknown)(data),
      ZIO.map((res) => res)
    )
  }
  export const getT4S15Config = () => {
    return pipe(
      POApi.get(`administration-rest/t4s15config/all`)(T4S15Config),
      ZIO.map((res) => res)
    )
  }
  export const updateT4S15Config = (data: T4S15UpdateParams) => {
    return pipe(
      POApi.put(`administration-rest/t4s15config`)(t.unknown)(data),
      ZIO.map((res) => res)
    )
  }
  export const addT4S15Config = (data: {
    staffEmail: string
    teamNo?: number
    blackList: string
    extensionCode: string
    status: string
  }) => {
    return pipe(
      POApi.post(`administration-rest/t4s15config`)(t.unknown)(data),
      ZIO.map((res) => res)
    )
  }
  export const getT4S17Config = () => {
    return pipe(
      POApi.get(`administration-rest/t4s17config/all`)(T4S17Config),
      ZIO.map((res) => res)
    )
  }
  export const updateT4S17Config = (data: T4S17UpdateParams) => {
    return pipe(
      POApi.put(`administration-rest/t4s17config`)(t.unknown)(data),
      ZIO.map((res) => res)
    )
  }
  export const addT4S17Config = (data: { email: string; listUserGroupCode: string[]; status: string }) => {
    return pipe(
      POApi.post(`administration-rest/t4s17config`)(t.unknown)(data),
      ZIO.map((res) => res)
    )
  }
  export const getT4S18Config = () => {
    return pipe(
      POApi.get(`administration-rest/t4s18config/all`)(T4S18Config),
      ZIO.map((res) => res)
    )
  }
  export const getT4S18ConfigQuery = (query: QueryT4S18) => {
    return pipe(
      POApi.post(`administration-rest/t4s18config/query`)(T4S18Config)(query),
      ZIO.map((res) => res)
    )
  }
  export const updateT4S18Config = (data: T4S18UpdateParams) => {
    return pipe(
      POApi.put(`administration-rest/t4s18config`)(t.unknown)(data),
      ZIO.foldM(
        (err) => ZIO.fail(err),
        (res) => ZIO.succeed(res)
      )
    )
  }
  export const addT4S18Config = (data: {
    staffEmail: string
    extensionCode: string
    teamCode: string
    status: string
  }) => {
    return pipe(
      POApi.post(`administration-rest/t4s18config`)(t.unknown)(data),
      ZIO.foldM(
        (err) => ZIO.fail(err),
        (res) => ZIO.succeed(res)
      )
    )
  }
  export const getT4S19Config = () => {
    return pipe(
      POApi.get(`administration-rest/t4s19config/all`)(T4S19Config),
      ZIO.map((res) => res)
    )
  }
  export const getListMailManager = () => {
    return pipe(
      POApi.get(`cs-api/outbound/config-data/list-user-by-group-ob-manager`)(ManagerList),
      ZIO.map((res) => res.data || [])
    )
  }

  export const getA2S3Config = () => {
    return pipe(
      POApi.get(`administration-rest/a2s3config/all`)(A2S3Config),
      ZIO.map((res) => res.data || [])
    )
  }

  export const getListA2S1Config = () => {
    return pipe(
      POApi.get(`administration-rest/a2s1config/all`)(A2S1ConfigList),
      ZIO.map((res) => res.data || [])
    )
  }

  export const createA2S3Config = (data: { answerEN: string; answerVN: string }) =>
    pipe(
      POApi.post(`administration-rest/a2s3config`)(
        t.type({
          id: t.string,
          answerCode: t.string,
          answerEN: t.string,
          answerVN: t.string
        })
      )(data),
      ZIO.map((res) => res)
    )

  export const updateOrderQuestionA2S1 = (data: {
    category: string
    transactionTypeWF: string
    a2S1QuestionOrderDTOs: { id: string; questionOrder: number | null; questionCode: string | null }[]
  }) =>
    pipe(
      POApi.post(`administration-rest/a2s1config/update-order-questions`)(t.unknown)(data),
      ZIO.map((res) => res)
    )

  export const createNewQuestionA2S1 = (data: {
    category: string
    transactionTypeWF: string
    questionDescEn: string
    questionDescVN: string
    questionOrder: number
    status: boolean
  }) =>
    pipe(
      POApi.post(`administration-rest/a2s1config`)(
        t.type({
          id: Maybe(t.string),
          category: Maybe(t.string),
          transactionTypeWF: Maybe(t.string),
          questionCode: Maybe(t.string),
          questionDescEn: Maybe(t.string),
          questionDescVN: Maybe(t.string),
          questionOrder: Maybe(t.number),
          status: Maybe(t.boolean)
        })
      )(data),
      ZIO.map((res) => res)
    )

  export const updateA2S3Config = (data: { id: string; answerCode: string; answerEN: string; answerVN: string }) => {
    return pipe(
      POApi.put(`administration-rest/a2s3config`)(
        t.type({
          id: Maybe(t.string),
          answerCode: Maybe(t.string),
          answerEN: Maybe(t.string),
          answerVN: Maybe(t.string)
        })
      )(data),
      ZIO.foldM(
        (err) => ZIO.fail(err),
        (res) => ZIO.succeed(res)
      )
    )
  }

  export const updateQuestionA2S1 = (data: {
    id: string
    category: string
    transactionTypeWF: string
    questionCode: string
    questionDescEn: string
    questionDescVN: string
    questionOrder: number
    status: boolean
  }) => {
    return pipe(
      POApi.put(`administration-rest/a2s1config`)(
        t.type({
          id: Maybe(t.string),
          category: Maybe(t.string),
          transactionTypeWF: Maybe(t.string),
          questionCode: Maybe(t.string),
          questionDescEn: Maybe(t.string),
          questionDescVN: Maybe(t.string),
          questionOrder: Maybe(t.number),
          status: Maybe(t.boolean)
        })
      )(data),
      ZIO.foldM(
        (err) => ZIO.fail(err),
        (res) => ZIO.succeed(res)
      )
    )
  }

  export const getListA2S2Config = () => {
    return pipe(
      POApi.get(`administration-rest/a2s2config/all`)(A2S2ConfigList),
      ZIO.map((res) => res.data || [])
    )
  }

  export const getListA2S3Config = () => {
    return pipe(
      POApi.get(`administration-rest/a2s3config/all`)(A2S3ConfigList),
      ZIO.map((res) => res.data || [])
    )
  }

  export const updateAnswersOfQuestionA2S2 = (data: {
    category: string
    transactionTypeWF: string
    questionCode: string
    a2S2AnswerOrderDTOs: {
      answerCode: string
      answerOrder: number
    }[]
  }) =>
    pipe(
      POApi.post(`administration-rest/a2s2config/save-answers-of-question`)(t.unknown)(data),
      ZIO.map((res) => res)
    )

  export const getAllConfigT0S3 = () =>
    pipe(
      POApi.get(`administration-rest/t0s3config/get-ob-transaction-for-question`)(
        t.type({ data: t.array(t.type({ transactionTypeWF: t.string })) })
      ),
      ZIO.map((res) => res.data)
    )
}
