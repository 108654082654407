import { enumC } from '@mxt/zio/codec'
import { pipe } from 'fp-ts/pipeable'
import * as t from 'io-ts'
import * as E from 'fp-ts/Either'
import { TransactionType } from './TransactionType'
import { TaskOp } from './TaskOp'
import { TransactionStatus } from './TransactionStatus'
import { TaskSource } from './TaskSource'

export enum TaskType {
  DistributionService = 'DS',
  Claim = 'CL',
  PolicyService = 'PS',
  CustomerService = 'CS',
  Complaint = 'CH',
  Underwriting = 'UW',
  ErrorHandler = 'EH',
  PremiumCollection = 'PC',
  Outbound = 'OB',
  GENERAL = 'GI',
  IDC = 'IDC'
}

export const TaskTypeC = enumC(TaskType)

export const TaskTypeList: TaskType[] = Object.entries(TaskType).map(([, v]) => v)

type StrConverter = (val: string) => string

export const TaskLabel =
  (taskType: TaskType) =>
    (converter: StrConverter): string => {
      switch (taskType) {
        case TaskType.DistributionService:
          return converter('DistributionService')
        case TaskType.Claim:
          return converter('Claims')
        case TaskType.PolicyService:
          return converter('PolicyServices')
        case TaskType.Complaint:
          return converter('Complaint')
        case TaskType.CustomerService:
          return converter('CustomerServices')
        case TaskType.ErrorHandler:
          return converter('ErrorHandler')
        case TaskType.Underwriting:
          return converter('Underwriting')
        case TaskType.PremiumCollection:
          return converter('PremiumCollection')
        case TaskType.Outbound:
          return converter('Outbound')
        case TaskType.IDC:
          return converter('IDC')
        default:
          return ''
      }
    }

export enum SubTaskType {
  QC = 'QC',
  Verification = 'VER',
  Suspend = 'SUSPENDED',
  ErrorHandling = 'ERRORHANDLING',
  Adjudication = 'ADJ',
  // Underwriting = 'Underwriting'
  Underwriting = 'UW',
  Payout = 'PV',
  WriteOff = 'WRITEOFF',
  QCUnderwriting = 'UW-QC',
  REQC = 'RE-QC',
  ErrorHandlingOB = 'ERROR-HANDLE'
}

export const SubTaskTypeC = enumC(SubTaskType)

export type SubTaskCode = {
  task: TaskType
  subTask: SubTaskType
}

const SubTaskCodeC = t.type({ task: TaskTypeC, subTask: SubTaskTypeC })

export const SubTaskCodeFromString = new t.Type<SubTaskCode, string, unknown>(
  'SubTaskCode',
  SubTaskCodeC.is,
  (u, c) =>
    pipe(
      t.string.validate(u, c),
      E.chain((s) =>
        pipe(s.split('-').length <= 2 ? s.split('-') : [s.slice(0, s.indexOf('-')), s.slice(s.indexOf('-') + 1)], ([task, subTask]) => {
          return SubTaskCodeC.validate(
            {
              task,
              subTask
            },
            c
          )
        })
      )
    ),
  ({ task, subTask }) => `${task}-${subTask}`
)

export const SubTaskLabel =
  (code: SubTaskCode) =>
    (converter: StrConverter): string => {
      const prefix: string = (() => {
        switch (code.subTask) {
          case SubTaskType.Verification:
            return converter('Verification')
          case SubTaskType.QC:
            return converter('QC')
          case SubTaskType.Suspend:
            return converter('Suspend')
          case SubTaskType.ErrorHandlingOB:
          case SubTaskType.ErrorHandling:
            return converter('ErrorHandler')
          case SubTaskType.Adjudication:
            return converter('Adjudication')
          case SubTaskType.Underwriting:
            return converter('Underwriting')
          case SubTaskType.Payout:
            return converter('Payout')
          case SubTaskType.WriteOff:
            return converter('WriteOff')
          case SubTaskType.QCUnderwriting:
            return converter('QCUnderwriting')
          case SubTaskType.REQC:
            return converter('REQC')
          default:
            return ''
        }
      })()

      // const postfix: string = TaskLabel(code.task)(converter)

      return `${prefix}`
    }

export const UWAllowedTransactionTypeList = [
  TransactionType.CHANGE_CLIENT_INFO,
  TransactionType.GO_ABROAD,
  TransactionType.REINSTATEMENT,
  TransactionType.CHANGE_SUM_ASSURED,
  TransactionType.RIDER_ALTERATION,
  TransactionType.HEALTH_DECLARATION,
  TransactionType.FULL_SURRENDER_REVERSAL,
  TransactionType.PRODUCT_OPTION_SWITCHING,
  TransactionType.CHANGE_PREMIUM,
  TransactionType.REDATING,
  TransactionType.RIDER_REINSTATEMENT,
  TransactionType.MAJOR_COMBINED_CHANGE
]