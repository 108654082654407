import React from 'react'
import { View, Text } from 'react-native'
import { RiderReinstatementForm, ChooseView } from './common'
import { Panel, AppContext, sharedStyle } from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import { useLoading } from '@mxt/zio-react'
import { SC, ValueShow, FilePresent } from '../../common'
import _ from 'lodash'

export const ConfirmTab: React.FC<{ form: RiderReinstatementForm.Raw }> = ({ form }) => {
  const { lifeAssuredList, attachmentFiles } = form.formRein
  const [loading, bindLoader] = useLoading(false)
  const { showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  React.useEffect(() => {
    showGlobalLoading(loading)
  }, [loading])
  const { t } = useTranslation()

  return (
    <SC.Container>
      <View style={{ marginVertical: 10 }}>
        <Text style={sharedStyle.sectionTitle}>{t('submission:StatementOfInsurability')}</Text>
      </View>
      {lifeAssuredList &&
        lifeAssuredList.map((value, index) => {
          return (
            <Panel
              key={`list_rein_clientss_${index}`}
              title={value.name || ''}
              containerStyle={{ backgroundColor: '#fafafa' }}
            >
              <ChooseView title={t('submission:AnyChangesInStateOfHealth')} selected={value.healthAnswer} disable />
              <ChooseView title={t('submission:AnyChangesInOccupation')} selected={value.occupationAnswer} disable />
              {value.occupationAnswer && (
                <SC.Row>
                  <ValueShow title={t('submission:CurrentOccupation')} value={value.curOccupation?.label || ''} />
                  <ValueShow title={t('submission:NewOccupation')} value={value.newOccupation?.label || ''} />
                </SC.Row>
              )}
              <ChooseView title={t('submission:AnyChangeInActivities')} selected={value.activitiesAnswer} disable />
              {value.activitiesAnswer && (
                <SC.Row>
                  <ValueShow title={t('submission:NewActivity')} value={value.newActivity} />
                </SC.Row>
              )}

              <ChooseView
                title={t('submission:AnyChangesInInsurancePolicy/Requesting')}
                selected={value.companyAnswer}
                disable
              />
              {value.companyAnswer && (
                <SC.Row>
                  <ValueShow title={t('submission:NewCompany')} value={value.newCompany} />
                </SC.Row>
              )}
            </Panel>
          )
        })}
      {attachmentFiles && attachmentFiles.length > 0 && (
        <SC.Padding>
          <SC.Padding vertical={10} horizontal={10}>
            <SC.TitleText>{t('submission:HealthDeclaration')}</SC.TitleText>
          </SC.Padding>
          <SC.Row>
            {attachmentFiles.map((value, index) => {
              const wrapValue = { ...value, uploadedDate: value.uploadedDate!, file: value.file! }
              return (
                <SC.InputContainer key={`file_health_declaration_${index}`}>
                  <SC.Padding horizontal={10}>
                    <FilePresent file={wrapValue} />
                  </SC.Padding>
                </SC.InputContainer>
              )
            })}
          </SC.Row>
        </SC.Padding>
      )}
    </SC.Container>
  )
}
