
export namespace AdminPageData {
  export type T33OfficeInfo = {
    no: number
    id: string
    code: string
    nameEn: string
    nameVi: string
    type: string
    gaCode: string
    status: string
    bankCode: string
    isEFM: string
  }

  export type GAInformationInfo = {
    no: number
    id: string
    adRegion: string
    adZone: string
    address: string
    agentCode: string
    bankCode: string
    classification: string
    companyName: string
    couchbaseId: string
    csRegion: string
    dob: string
    extraPay: string
    gaCSAssociate: string
    gaCSAssociateEmail: string
    gaCSEmail: string
    gaCSSupportManager: string
    gaCode: string
    gaFlag: string
    gaMother: string
    gaOMEmail: string
    gaServiceAssociate: string
    gaTaxCode: string
    gadEmail: string
    gadName: string
    gamRHS: string
    mobile: string
    officeCode: string
    officeName: string
    openDate: string
    province: string
    sex: string
    telPhone: string
    transactionLiveZoom: string
    type: string
    // Zone: string
  }
}