import { enumC } from "@mxt/zio/codec"

export enum OBTaskType {
  Outbound = 'OB',
  Inbound = 'IB',
}

export enum OBCallType {
  SQCB = 'SQCB',
  WCC = 'WCC'
}

export enum OBBasketType {
  Verification = 'VER',
  QC = 'QC',
  QA = 'QA',
  Suspend = 'SUSPENDED',
  ErrorHandling = 'ERROR-HANDLE',
  Adjudication = 'ADJ',
  Underwriting = 'Underwriting',
  WriteOff = 'WRITEOFF',
  Payout = 'PV',
  REQC = 'RE-QC'
}

export enum OBLastActivity {
  VERIFICATION = 'VERIFICATION',
  PENDING = 'PENDING',
  QC = 'QC',
  // QA = 'QA'
}

export const OBTaskTypeC = enumC(OBTaskType)

export const OBTaskTypeList: OBTaskType[] = Object.entries(OBTaskType).map(([, v]) => v)

export const OBMapLastActivity = new Map<OBLastActivity, string>([
  [OBLastActivity.VERIFICATION, '5. Verification'],
  [OBLastActivity.PENDING, '7. Suspend'],
  [OBLastActivity.QC, '9. Quality Control']
])

export const getLastActivityName = (lastActivity: string) => {
  return OBMapLastActivity.get(lastActivity as OBLastActivity) || ''
}

export type TaskServiceInquiryData = {
  businessKey: string | undefined | null
  policyNumber: string | undefined | null
  transactionType: string | undefined | null
  transactionCode: string | undefined | null
  poClientNumber: string | undefined | null
  poName: string | undefined | null
  vip: string | undefined | null
  outboundAgency: string | undefined | null
  outBoundCallBackAppointmentDate: string | undefined | null
  createdDate: string | undefined | null
  agingDays: number | undefined | null
  outBoundExecutionTime: number | undefined | null
  outBoundLatestHandleStaff: string | undefined | null
  outBoundLeaderOfStaff: string | undefined | null
  status: string | undefined | null
  activity: string | undefined | null
  processInstanceId: string | undefined | null
  categoryInsurance: string | undefined | null
  policyFirstIssuedDate: string | undefined | null
  policyLastIssuedDate: string | undefined | null
  caseSubmittedDate: string | undefined | null
  paidToDate: string | undefined | null
  callOutCode: string | undefined | null
  assignee: string | undefined | null
  agentCode: string | undefined | null
}

export type OBDataSource = {
  [key: string]: string | number | boolean | JSX.Element
}

export type OBUserInfo = {
  email: string;
  name: string
  officeCode: string | null
  isGaLogin: boolean
}