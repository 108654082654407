import { FileUploadData, SelectOption } from '@pulseops/common'

export type SelectOptions = {
  value: string
  label: string
}[]

export type SelectOptionsSuspendType = {
  value: string
  label: string
  timeout: string,
  description: string[],
  lableVN: string
}[]
export interface Form {
  group: SelectOption | null
  types: SelectOptions
  codes: string[]
  status: SelectOption | null
  pendingLetter: FileUploadData[]
  description: string
}

export interface PendingLetter {
  azureBlobName: string
  url: string
}

export interface History {
  id: string
  idHistory: string
  suspendGroupCode: string
  suspendGroup: string
  suspendType: string
  pendingLetters: PendingLetter[]
  status: SelectOption
  createdUser: string
  createdDate: string
  updatedUser: string
  updatedDate: string
  expiredDate: Date
  suspendCode: string
  addLackDocs: PendingLetter[]
  receivedDate: string
  errorExpiredDate: string
}

export interface GeneralInfo {
  suspendGroup: string
  suspendCodes: string[]
  suspendTypes: string[]
  azureBlobName: string
  url: string
  createdBy: string
  createdDate: string
  clientId: string
  businessKey: string
  processInstanceId: string
  status: string
  letterContents: string
  docTypeCode: string
  transactionType: string
  policyNumber: string
  policyOwnerName: string
  userID: string
  unsuspendDate: string
  isUploadedToAzure: boolean
  isSuspend: boolean
}

export enum GroupCode {
  DOC1 = 'DOC1',
  DOC2 = 'DOC2',
  DOC3 = 'DOC3',
  DOC4 = 'DOC4',
  DOC5 = 'DOC5',
  PRE1 = 'PRE1',
  AML2 = 'AML2',
  SYS1 = 'SYS1',
  PAY1 = 'PAY1',
  PAY3 = 'PAY3'
}

export enum Status {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  COMPLETE = 'Complete',
  INCOMPLETE = 'Incomplete'
}
