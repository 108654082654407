// React Lib
import { View, ScrollView, TouchableOpacity } from 'react-native'
import React, { useState } from 'react'
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TablePagination
} from '@material-ui/core'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'

// Common
import { assets } from '@pulseops/common'
import { CF } from '../ConfigTableT6Styles'
import { Actions, actionsType } from '../models'
import { titleHeaders } from '../ConfigTableT6Const'

const { Row } = CF

declare interface Pagination {
  pageNum: number
  pageSize: number
  total: number
}

export type DataSourceType = {
  no: string
  suspendBy: string
  suspendGroupEN: string
  suspendGroupCode: string
  suspendGroupVN: string
  suspendCode: string
  suspendTypeEN: string
  suspendTypeVN: string
  description: string
  docType: string
  doctypeCode: string
  department: string
  departmentShortName: string
  timeOut: string
  autoReject: string
  status: string
  sendComunication: string
}

export type onActionRowType = {
  type: actionsType
  payload: DataSourceType
}

interface Props {
  onAction: ({ type, payload }: onActionRowType) => void
}

const ListAreaComp: React.FC<Props> = ({ onAction }) => {
  const { t } = useTranslation()
  const [{ pageNum, pageSize, total }] = useState<Pagination>({
    pageNum: 0,
    pageSize: 10,
    total: 0
  })

  const mockData = ({
    no,
    suspendBy,
    suspendGroupEN,
    suspendGroupCode,
    suspendGroupVN,
    suspendCode,
    suspendTypeEN,
    suspendTypeVN,
    description,
    docType,
    doctypeCode,
    department,
    departmentShortName,
    timeOut,
    autoReject,
    status,
    sendComunication
  }: DataSourceType) => {
    return {
      no,
      suspendBy,
      suspendGroupEN,
      suspendGroupCode,
      suspendGroupVN,
      suspendCode,
      suspendTypeEN,
      suspendTypeVN,
      description,
      docType,
      doctypeCode,
      department,
      departmentShortName,
      timeOut,
      autoReject,
      status,
      sendComunication
    }
  }

  const [dataSource] = useState<Array<DataSourceType>>(
    Array.from({ length: 10 }, (_, i) =>
      mockData({
        no: `${i}`,
        suspendBy: `Suspend By ${i}`,
        suspendGroupEN: `Suspend By ${i}`,
        suspendGroupCode: `Suspend By ${i}`,
        suspendGroupVN: `Suspend By ${i}`,
        suspendCode: `Suspend By ${i}`,
        suspendTypeEN: `Suspend By ${i}`,
        suspendTypeVN: `Suspend By ${i}`,
        description: `Suspend By ${i}`,
        docType: `Suspend By ${i}`,
        doctypeCode: `Suspend By ${i}`,
        department: `Suspend By ${i}`,
        departmentShortName: `Suspend By ${i}`,
        timeOut: `Suspend By ${i}`,
        autoReject: `Suspend By ${i}`,
        status: `Suspend By ${i}`,
        sendComunication: `Suspend By ${i}`
      })
    )
  )

  const handleChangePage = (page: number) => {}

  const handleChangePerPage = (perPage: number) => {}

  const renderTableHeader = () => {
    return titleHeaders.map(({ title, style }, index) => {
      return (
        <TableCell align="center" style={style} key={index}>
          {title}
        </TableCell>
      )
    })
  }

  const renderTableBody = () => {
    if (isEmpty(dataSource)) return

    const handlePressAction = (row: DataSourceType, typeAction: actionsType) =>
      onAction({ type: typeAction, payload: row })

    return dataSource?.map((row, index) => {
      return (
        <TableRow key={`${row.no}-${index}`}>
          <TableCell align="center">{row.no}</TableCell>
          <TableCell align="center">{row.suspendBy}</TableCell>
          <TableCell align="center">{row.suspendGroupEN}</TableCell>
          <TableCell align="center">{row.suspendGroupCode}</TableCell>
          <TableCell align="center">{row.suspendGroupVN}</TableCell>
          <TableCell align="center">{row.suspendCode}</TableCell>
          <TableCell align="center">{row.suspendTypeEN}</TableCell>
          <TableCell align="center">{row.suspendTypeVN}</TableCell>
          <TableCell align="center">{row.description}</TableCell>
          <TableCell align="center">{row.docType}</TableCell>
          <TableCell align="center">{row.doctypeCode}</TableCell>
          <TableCell align="center">{row.department}</TableCell>
          <TableCell align="center">{row.departmentShortName}</TableCell>
          <TableCell align="center">{row.timeOut}</TableCell>
          <TableCell align="center">{row.autoReject}</TableCell>
          <TableCell align="center">{row.status}</TableCell>
          <TableCell align="center">{row.sendComunication}</TableCell>
          <TableCell align="center">
            <Row>
              <TouchableOpacity onPress={() => handlePressAction(row, Actions['EDIT_ROW'])}>
                <assets.ConfigTable.EDIT fontSize={10} style={{ marginRight: 32, cursor: 'pointer' }} />
              </TouchableOpacity>

              <TouchableOpacity onPress={() => handlePressAction(row, Actions['DELETE_ROW'])}>
                <assets.ConfigTable.DELETE fontSize={10} style={{ cursor: 'pointer' }} />
              </TouchableOpacity>
            </Row>
          </TableCell>
        </TableRow>
      )
    })
  }

  const renderList = () => {
    if (isEmpty(dataSource)) return
    return (
      <View style={{ marginTop: 20, marginBottom: 20 }}>
        <TableContainer component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>{renderTableHeader()}</TableRow>
            </TableHead>

            <TableBody>{renderTableBody()}</TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          page={pageNum}
          rowsPerPage={pageSize}
          count={total}
          onPageChange={(_, page) => handleChangePage(page)}
          onRowsPerPageChange={(e) => handleChangePerPage(+e.target.value)}
          labelRowsPerPage={t('common:PaginationSize')}
          labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
          component={View}
        />
      </View>
    )
  }

  return <ScrollView>{renderList()}</ScrollView>
}

export default ListAreaComp
