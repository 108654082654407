import { ReinstatementModel } from './Reinstatement'

export namespace SUFullSurrenderReversalData {
  export interface DetailData {
    policyLoans: number
    surrenderValue: number
    ptdateAdvance: string
    billFreq: string
    // basicPrem: number
    // riderPrem: number
    totalPaidCashBenefits: number
    reinData: ReinstatementModel.ReinData
    occupationList: FUSUREOccupation[]
    LXTFval: number
    LPSBalanceValue: number
    totalPrem: number
  }
  export interface FUSUREOccupation {
    id: string
    code: string
    name: string
  }

  export interface FullSurrenderReversalInfo {
    PAID_CASH_BENEFITS: string
    NOT_PAID_PREMIUM: string
    POLICY_LOANS: string
    REINSTATEMENT_FEE: string
    REASON: string
  }

  export interface FSR_LifeAssureds {
    attributesExt: {
      CLIENT_NO: string
      H_ANSWER: string
      O_ANSWER: string
      CURRENT_OCCUPATION: string
      NEW_OCCUPATION: string
      A_ANSWER: string
      NEW_ACTIVITY: string
      C_ANSWER: string
      COMPANY_NAME: string
      AGENT_CODE: string
    }
  }

  export interface SubmitData {
    surrenderValue: string
    attributesExt: FullSurrenderReversalInfo
    lifeAssureds: FSR_LifeAssureds[]
  }
}
