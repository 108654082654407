
export enum AdminTableList {
  ConfigT33OfficeScreen = 'ConfigT33OfficeScreen',
  ConfigBankBranchListScreen = 'ConfigBankBranchListScreen',
  ConfigBankListScreen= 'ConfigBankListScreen',
  ConfigGAInformationListScreen = 'ConfigGAInformationListScreen'
}

export type AdminPageStackParamList = {
 [key in AdminTableList]: undefined
} & AdminPageStackInfo

export type AdminPageStackInfo = {
  AdminPageScreen: undefined
}