import { ZIO } from '@mxt/zio'
import {
  ErrorHandling,
  GeneralData,
  GeneralService,
  Input,
  mapPaymentTitleArr,
  ModalComponent,
  PayoutPopup,
  PulseOpsFormat
} from '@pulseops/common'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet, FlatList } from 'react-native'
import { PayoutInfoConst } from './payout-info.const'
import { pipe } from 'fp-ts/function'

interface ItemData {
  title: string
  inputType?: 'text' | 'number' | 'money'
  value: string
  containerStyle?: Record<string, number | string>
  isFractionFormat?: boolean
}

interface IPayoutInfoPopup {
  visible?: boolean
  onClose?: () => void
  data?: PayoutPopup.Summary
  isFractionFormat?: boolean
}

export const PayoutInfoPopup: React.FC<IPayoutInfoPopup> = (props) => {
  const { visible, data, onClose, isFractionFormat } = props
  const { t, i18n } = useTranslation()

  const { iacRela, nationalityOptions, occupations, salutation, married, genders } = pipe(
    ZIO.zipPar(GeneralService.getCountries, GeneralService.getOccupations, GeneralService.getIACRelationships),
    ZIO.map(([countries, occupations, iacRela]) => {
      const nationalityOptions = countries
      const salutation = PayoutInfoConst.salutation.map((item) => ({
        value: item.value,
        label: t(item.label)
      }))
      const married = PayoutInfoConst.married.map((item) => ({
        value: item.value,
        label: t(item.label)
      }))
      const genders = PayoutInfoConst.genders.map((item) => ({
        value: item.value,
        label: t(item.label)
      }))
      return {
        iacRela: iacRela?.map((item) => {
          return {
            value: item.code,
            name: item.name,
            nameEn: item.nameEn
          }
        }),
        nationalityOptions: nationalityOptions.map((item: GeneralData) => {
          return {
            value: item.code,
            label: t(`submission:${item.name}`)
          }
        }),
        occupations: occupations.map((item: GeneralData) => {
          return {
            value: item.code,
            label: t(`submission:${item.name}`)
          }
        }),
        salutation,
        married,
        genders
      }
    }),
    ErrorHandling.runDidMount()
  ) || {
    iaRela: [],
    nationalityOptions: [],
    occupations: [],
    salutation: [],
    married: [],
    genders: []
  }

  const officeCode = pipe(
    data && !!data?.officeCode ? GeneralService.getOfficeCode(data?.officeCode) : ZIO.succeed({ body: [] }),
    ZIO.map((officeInfo) => {
      let officeName =
        officeInfo && officeInfo.body.length > 0
          ? i18n.language === 'en'
            ? officeInfo.body[0].nameEn
            : officeInfo.body[0].nameVi
          : ''
      if (!!officeName) {
        officeName = data?.officeCode + ' - ' + officeName
      }
      return officeName
    }),
    ErrorHandling.runDidUpdate([data?.officeCode])
  )

  const relationshipWithPO = useMemo(() => {
    if (data?.payee?.relationshipWithPO) {
      return iacRela?.find((item) => item.value === data.payee?.relationshipWithPO)?.[
        i18n.language === 'en' ? 'nameEn' : 'name'
      ]
    }

    return
  }, [data?.payee?.relationshipWithPO, iacRela])

  const nationality = useMemo(() => {
    if (data?.payee?.nationality) {
      return nationalityOptions?.find((item) => item.value === data.payee?.nationality)?.label
    }

    return
  }, [data?.payee?.nationality, nationalityOptions])

  const nationality2 = useMemo(() => {
    if (data?.payee?.nationality2) {
      return nationalityOptions?.find((item) => item.value === data.payee?.nationality2)?.label
    }

    return data?.payee?.nationality2
  }, [data?.payee?.nationality2, nationalityOptions])

  const residenceCountry = useMemo(() => {
    if (data?.payee?.residenceCountry) {
      return nationalityOptions?.find((item) => item.value === data.payee?.residenceCountry)?.label
    }

    return
  }, [data?.payee?.residenceCountry, nationalityOptions])

  const occupation = useMemo(() => {
    if (data?.payee?.occupation) {
      return occupations?.find((item) => item.value === data.payee?.occupation)?.label
    }

    return data?.payee?.occupation
  }, [data?.payee?.occupation, occupations])

  const foreignCountry = useMemo(() => {
    if (data?.payee?.foreignCountry) {
      return nationalityOptions?.find((item) => item.value === data.payee?.foreignCountry)?.label
    }

    return data?.payee?.foreignCountry
  }, [data?.payee?.foreignCountry, nationalityOptions])

  const itemData = [
    officeCode && {
      title: t('common:Office'),
      value: officeCode ?? ''
    },
    {
      title: t('submission:PayeeName'),
      value: data?.payee?.payeeName ?? ''
    },
    {
      title: t('submission:CCCD_IDNumber'),
      value: data?.payee?.idNumber ?? ''
    },
    data?.payee?.issuedDate && {
      title: t('claim:IssuedDate'),
      value: PulseOpsFormat.dateStr(data?.payee?.issuedDate)
    },
    data?.payee?.issuedBy && {
      title: t('claim:IssuedBy'),
      value: data.payee.issuedBy ?? ''
    },
    data?.bankName && {
      title: t('submission:Bank'),
      value: data.bankName
    },
    data?.bankBranchName && {
      title: t('submission:Branch'),
      value: data.bankBranchName
    },
    data?.bankAccountNum && {
      title: t('submission:AccountNumber'),
      value: data.bankAccountNum
    },
    {
      title: t('requestInfo:Amount'),
      inputType: 'money',
      value: (data?.amount ?? 0).toString(),
      isFractionFormat: isFractionFormat
    },
    data?.payee?.dob && {
      title: t('submission:DateOfBirth'),
      value: PulseOpsFormat.dateStr(data.payee.dob)
    },
    (data?.payee?.phone || typeof data?.payee?.phone === 'string') && {
      title: t('submission:PhoneNumber'),
      value: data.payee.phone || '-'
    },
    (data?.payee?.email || typeof data?.payee?.phone === 'string') && {
      title: t('submission:Email'),
      value: data.payee.email || '-'
    },
    relationshipWithPO && {
      title: t('submission:RelationshipWithPO'),
      value: relationshipWithPO
    },
    (occupation || typeof occupation === 'string') && {
      title: t('submission:Occupation'),
      value: occupation || '-'
    },
    data?.payee?.gender && {
      title: t('submission:Gender'),
      value: t(genders.find((gen) => gen.value === data.payee?.gender)?.label ?? '')
    },
    data?.payee?.salutation && {
      title: t('submission:Salutation'),
      value: t(salutation.find((sal) => sal.value === data.payee?.salutation)?.label ?? '')
    },
    data?.payee?.married && {
      title: t('submission:SubMarried'),
      value: t(married.find((mar) => mar.value === data.payee?.married)?.label ?? '')
    },
    nationality && {
      title: t('submission:Nationality'),
      value: nationality
    },
    residenceCountry && {
      title: t('submission:ResidenceCountry'),
      value: residenceCountry
    },
    data?.payee?.city && {
      title: t('submission:CityProvince'),
      value: data.payee.city
    },
    data?.payee?.district && {
      title: t('submission:District'),
      value: data.payee.district
    },
    data?.payee?.ward && {
      title: t('submission:Ward'),
      value: data.payee.ward
    },
    data?.payee?.street && {
      title: t('submission:HouseNumber'),
      value: data.payee.street
    },
    (nationality2 || typeof nationality2 === 'string') && {
      title: t('submission:Nationality2'),
      value: nationality2 || '-'
    },
    (foreignCountry || typeof foreignCountry === 'string') && {
      title: t('submission:ForeignCountry'),
      value: foreignCountry || '-'
    },
    (data?.payee?.foreignAddress || typeof data?.payee?.foreignAddress === 'string') && {
      title: t('submission:ForeignAddress'),
      value: data.payee.foreignAddress || '-'
    }
  ].filter(Boolean) as ItemData[]

  const Item = ({ item }: { item: ItemData }) => {
    const { inputType, ...props } = item
    return <Input containerStyle={styles.item} inputType={inputType ?? 'text'} disabled {...props} />
  }

  return (
    <ModalComponent
      titleStyle={styles.modalTitle}
      contentStyle={styles.modal}
      title={t(mapPaymentTitleArr.get(data?.method as PayoutPopup.PayoutMethods) ?? '')}
      visible={visible ?? false}
      modalWidth={900}
      onClose={() => onClose?.()}
      actions={[]}
    >
      <View style={styles.container}>
        <FlatList data={itemData} numColumns={2} renderItem={Item} keyExtractor={(item) => item.title} />
      </View>
    </ModalComponent>
  )
}

const styles = StyleSheet.create({
  modalTitle: {
    textAlign: 'center'
  },
  modal: {
    backgroundColor: '#eee'
  },
  container: {
    marginLeft: 30,
    marginRight: 30,
    marginTop: 30,
    paddingTop: 20,
    paddingLeft: 20,
    paddingBottom: 20,
    flex: 2,
    marginHorizontal: 'auto',
    backgroundColor: '#fafafa'
  },
  item: {
    flex: 1,
    maxWidth: '50%',
    paddingTop: 10,
    paddingBottom: 10,
    marginRight: 20
  }
})
