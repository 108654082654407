import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { BasicBenefitC } from '@pulseops/business/core'
import {
  AppContext,
  AuthService,
  ErrorHandling,
  formatNumberWithComma,
  GeneralService,
  LogFinalLetter,
  PulseOpsFormat,
  // PulseOpsFormat,
  PulseOpsService,
  SelectOption,
  StorageBlob,
  SubTasksResponse,
  SubTaskType,
  SupplementaryInfoService,
  Tab,
  TabList,
  TaskDetail,
  TaskDetailService,
  TaskType,
  TASK_DETAIL_ROLES,
  TransactionType,
  useMobile,
  SubClaimTypeBenefit,
  SubClaimType,
  HospitalType,
  VerClaimType,
  CriticalIllnessType,
  ICDType,
  HistoryLetter
} from '@pulseops/common'
import { TaskDetailStackParamList } from '@pulseops/task'
import {
  requestRefreshAllBasketInDashboard,
  requestRefreshAllInDashboard,
  requestRefreshDashboardAfterTranfer
} from '@pulseops/task/common'
import { StackNavigationProp } from '@react-navigation/stack'
import { pipe } from 'fp-ts/lib/function'
import * as _ from 'lodash'
import moment from 'moment'
import * as React from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SafeAreaView, View } from 'react-native'
import {
  checkRolesForButton,
  CompletePopup,
  EndPopup,
  onCompleteAction,
  onEndAction,
  onRejectAction,
  onSuspendAction,
  onUnsuspendAction,
  RejectPopup,
  SuspendPopup,
  TransferPopup
} from '../../actions'
import {
  AllocationForm,
  BenefitClaimDetail,
  BenefitDetailForm,
  CalculationForm,
  FinalDecisionForm,
  FinalLetterForm,
  AutoDecisions,
  FileUploadData
} from './AdjudicationForm'
import { Allocation } from './Allocation'
import { Calculation } from './Calculation'
import { FinalDecision } from './FinalDecision'
import { FinalLetter } from './FinalLetter'
import * as D from 'date-fns'
import { isEmpty } from 'lodash'
import { uploadRef } from './FinalLetter'
interface Props {
  navigation: StackNavigationProp<TaskDetailStackParamList, 'TaskDetailScreen'>
  route: {
    id: string
    basketName: string
    officeCode: string
  }
  detail: TaskDetail.Type
  processId: string
  subTask: SubTasksResponse
  subTaskCode: string
  setIsUnSuspendPopupOpen: (val: boolean) => void
  finalDecisionForm: UseFormReturn<FinalDecisionForm>
  calculationForm: UseFormReturn<CalculationForm>
  allocationForm: UseFormReturn<AllocationForm>
  finalLetterForm: UseFormReturn<FinalLetterForm>
  roles: string[]
  isSupActive: boolean
  disabled?: boolean
  isInquiry?: boolean
  onChangeTab: (val: number) => void
  claimData: {
    accidents: VerClaimType[] | null
    claimTypes?: SubClaimType[] | null
    subClaimTypes?: SubClaimTypeBenefit[] | null
    tpdTypes?: VerClaimType[] | null
    ciTypes?: CriticalIllnessType[] | null
    icdTypes: ICDType[] | null
    hospitals: HospitalType[] | null
  }
  isQC?: boolean
}

export type Evaluation = {
  evaluationCode: string
  evaluationType: string
  subject: string
  description: string
  createdDate: string
  createdBy: string
}

export const Adjudication = (props: Props) => {
  const { isWide } = useMobile()
  const { t, i18n } = useTranslation(['Adjudication', 'claim', 'TaskManagement'])
  const [subTabIndex, setSubTabIndex] = React.useState(0)
  const [isTransferModalVisible, setTransferModalVisible] = React.useState<boolean>(false)
  const [isRejectPopupOpen, setIsRejectPopupOpen] = React.useState<boolean>(false)
  const [isEndPopupOpen, setIsEndPopupOpen] = React.useState<boolean>(false)
  const [completeOpen, setCompleteOpen] = React.useState<boolean>(false)
  const [loading, bindLoading] = useLoading(false)
  const [isCanChangeTab, setIsCanChangeTab] = React.useState<boolean>(true)
  const detailPayload: TaskDetail.VerificationClaim = React.useMemo(
    () => props.detail?.payload as TaskDetail.VerificationClaim,
    [props.detail]
  )
  const finalDecision = detailPayload.detail.payload?.adjudication?.finalDecision
  const { showToast, showGlobalLoading, getFooterClaim } = React.useContext(AppContext.AppContextInstance)
  const [isSuspendPopupOpen, setIsSuspendPopupOpen] = React.useState<boolean>(false)
  const [planSuspendDate, setPlanSuspendDate] = React.useState<Date>()
  const [evaluations, setEvaluations] = React.useState<Evaluation[]>([])
  const [detailValue, setDetail] = React.useState<any>()
  const [autoDecisions, setAutoDecisions] = React.useState<AutoDecisions[]>([])
  const [finalLetter, setFinalLetter] = React.useState<LogFinalLetter>({
    createdDate: '',
    createdBy: '',
    status: '',
    policyNumber: '',
    documentDetails: []
  })
  const listHospital = pipe(GeneralService.getListHospital, ErrorHandling.runDidMount()) || []
  const calculateFaceAmount = (): number => {
    const components = detailValue?.payload?.detail?.payload?.policyRider?.components as BenefitDetailForm[]
    const riders = components && _.isArray(components) ? components : []
    let totalFaceAmount = 0
    if (riders.length > 0) {
      riders.forEach((rider) => {
        rider.benefitClaimCode
          .filter((item) => item.eligible)
          .forEach((benefitClaim) => {
            totalFaceAmount += benefitClaim.claimAmount ? parseInt(benefitClaim.claimAmount.replace(/\,/g, ''), 10) : 0
          })
      })
    }
    return totalFaceAmount
  }

  const { finalDecisionForm, calculationForm, allocationForm, finalLetterForm, roles, route, detail } = props
  const [isSeenLetter, setIsSeenLetter] = React.useState<boolean>(false)
  const [isGenerateLetter, setIsGenerateLetter] = React.useState<boolean>(false)
  const author = pipe(AuthService.userInfo, ErrorHandling.runDidMount())
  const claimType =
    props?.claimData?.claimTypes?.find((elm) => elm.claimTypeCode === detailValue?.payload?.claimType) ?? null
  const uploadRef = React.useRef<uploadRef | null>(null)
  const templateRef = React.useRef<uploadRef | null>(null)
  const [historyLetter, setHistoryLetter] = React.useState<HistoryLetter[]>([])
  /**Table T55 - Make Decision */
  const decisionList =
    pipe(
      ZIO.zipPar(
        GeneralService.getDecision('s1'),
        GeneralService.getDecision('s2'),
        GeneralService.getDecision('s3'),
        GeneralService.getDecision('s4')
      ),
      ZIO.map((decisions) => {
        const decisionList = decisions?.map((childDecision) =>
          childDecision.map((res) => ({
            value: res.code,
            label: i18n.language === 'en' ? res.nameEn : res.nameVi
          }))
        )
        return decisionList
      }),
      ErrorHandling.runDidMount()
    ) || []

  const declinesData =
    pipe(
      GeneralService.getDeclineReason(),
      ZIO.map((declineList) => {
        return declineList
      }),
      ErrorHandling.runDidMount()
    ) || []

  const declinesOptions = () => {
    let declineList: SelectOption[] = []
    if (declinesData && declinesData.length > 0) {
      if (!!detailValue?.payload.death) {
        declineList =
          declinesData
            .filter((item) => item.death === 'Y')
            .map((item) => ({
              value: item.declineCode,
              label: i18n.language === 'vi' ? item?.declineVN : item?.declineEN
            })) || []
      } else if (!!detailValue?.payload.criticalIllness) {
        declineList =
          declinesData
            .filter((item) => item.criticalIllness === 'Y')
            .map((item) => ({
              value: item.declineCode,
              label: i18n.language === 'vi' ? item?.declineVN : item?.declineEN
            })) || []
      } else if (!!detailValue?.payload.tdpInfo) {
        declineList =
          declinesData
            .filter((item) => item.totalPermanentDisability === 'Y')
            .map((item) => ({
              value: item.declineCode,
              label: i18n.language === 'vi' ? item?.declineVN : item?.declineEN
            })) || []
      } else if (!!detailValue?.payload.isMedical) {
        declineList =
          declinesData
            .filter((item) => item.hospitalCash === 'Y')
            .map((item) => ({
              value: item.declineCode,
              label: i18n.language === 'vi' ? item?.declineVN : item?.declineEN
            })) || []
      }
    }
    return declineList
  }

  const declinesOptionsVN = () => {
    let declineList: SelectOption[] = []
    if (declinesData && declinesData.length > 0) {
      if (!!detailValue?.payload.death) {
        declineList =
          declinesData
            .filter((item) => item.death === 'Y')
            .map((item) => ({
              value: item.declineCode,
              label: item?.declineVN
            })) || []
      } else if (!!detailValue?.payload.criticalIllness) {
        declineList =
          declinesData
            .filter((item) => item.criticalIllness === 'Y')
            .map((item) => ({
              value: item.declineCode,
              label: item?.declineVN
            })) || []
      } else if (!!detailValue?.payload.tdpInfo) {
        declineList =
          declinesData
            .filter((item) => item.totalPermanentDisability === 'Y')
            .map((item) => ({
              value: item.declineCode,
              label: item?.declineVN
            })) || []
      } else if (!!detailValue?.payload.isMedical) {
        declineList =
          declinesData
            .filter((item) => item.hospitalCash === 'Y')
            .map((item) => ({
              value: item.declineCode,
              label: item?.declineVN
            })) || []
      }
    }
    return declineList
  }

  const getPayableAmount = (adjType: string, benefitAmount: string, CMPExGAmount: string) => {
    switch (adjType) {
      case 'DS01':
      case 'DS03':
        return benefitAmount
      case 'DS02':
      case 'DS04':
        return '0'
      default:
        return CMPExGAmount
    }
  }

  const calculateTotalPayableAmount = (components: BenefitDetailForm[]) => {
    const riders = components && _.isArray(components) ? components : []
    let totalPayableAmount = 0
    if (riders.length > 0) {
      riders.forEach((rider) => {
        rider.benefitClaimCode
          .filter((item) => item.eligible)
          .forEach((benefitClaim) => {
            const paymentAmount = benefitClaim.adjudicatorDecision
              ? getPayableAmount(
                  benefitClaim.adjudicatorDecision.value,
                  benefitClaim.claimBenefitAmount || '0',
                  benefitClaim.CMPExGAmount || '0'
                )
              : '0'
            totalPayableAmount += parseInt(paymentAmount.replace(/\,/g, ''), 10)
          })
      })
    }
    return PulseOpsFormat.thousandSepartor(totalPayableAmount)
  }

  const calculateTotalPayableNetAmount = (tpa: string, de: string): string => {
    const totalPayableAmount = PulseOpsFormat.thousandSepartorReverse(tpa)
    const deductedExpenses = PulseOpsFormat.thousandSepartorReverse(de)
    let totalNetPayableAmount: string = '0'
    if (totalPayableAmount)
      if (totalPayableAmount >= deductedExpenses) {
        totalNetPayableAmount = PulseOpsFormat.thousandSepartor(totalPayableAmount - deductedExpenses)
      } else {
        totalNetPayableAmount = '0'
      }
    return totalNetPayableAmount
  }

  const claimDecision = () => {
    let claimOption = null
    if (decisionList) {
      let enableRiders: BenefitDetailForm[] = []
      const riders = detailValue?.payload?.detail?.payload?.policyRider?.components as BenefitDetailForm[]
      if (riders && riders.length > 0) {
        riders.forEach((rider) => {
          if (rider.benefitClaimCode.filter((item) => !!item.eligible).length > 0) {
            enableRiders.push({
              ...rider,
              benefitClaimCode: rider.benefitClaimCode.filter((item) => item.eligible)
            })
          }
        })
      }

      if (enableRiders.length > 0) {
        let benefits: string[] = []
        enableRiders.forEach((rider) => {
          rider.benefitClaimCode.forEach((benefit) => {
            if (benefit.adjudicatorDecision?.value) {
              benefits.push(benefit.adjudicatorDecision?.value)
            }
          })
        })
        const NOT_ACCEPT = ['DS04'] //1 Not applicable
        const ACCEPT1 = ['DS01'] // 3 accept
        const ACCEPT2 = ['DS01', 'DS04'] // 2 accept
        const DECLINE1 = ['DS02'] // 5 Decline
        const DECLINE2 = ['DS02', 'DS04'] // 4 Decline
        const EX_GRATIA1 = ['DS05'] // 7 Ex-gratia
        const EX_GRATIA2 = ['DS04', 'DS05'] // 8 Ex-gratia
        const EX_GRATIA3 = ['DS02', 'DS05'] // 9 Ex-gratia
        const EX_GRATIA4 = ['DS02', 'DS04', 'DS05'] // 6 Ex-gratia

        if (_.isEqual(_.sortedUniq(benefits).sort(), NOT_ACCEPT)) {
          claimOption = decisionList[0]?.find((item: { value: string }) => item.value === 'DS04')
        } else if (
          _.isEqual(_.sortedUniq(benefits).sort(), ACCEPT1) ||
          _.isEqual(_.sortedUniq(benefits).sort(), ACCEPT2)
        ) {
          claimOption = decisionList[0]?.find((item: { value: string }) => item.value === 'DS01')
        } else if (
          _.isEqual(_.sortedUniq(benefits).sort(), DECLINE1) ||
          _.isEqual(_.sortedUniq(benefits).sort(), DECLINE2)
        ) {
          claimOption = decisionList[0]?.find((item) => item.value === 'DS02')
        } else if (
          _.isEqual(_.sortedUniq(benefits).sort(), EX_GRATIA1) ||
          _.isEqual(_.sortedUniq(benefits).sort(), EX_GRATIA2) ||
          _.isEqual(_.sortedUniq(benefits).sort(), EX_GRATIA3) ||
          _.isEqual(_.sortedUniq(benefits).sort(), EX_GRATIA4)
        ) {
          claimOption = decisionList[0]?.find((item) => item.value === 'DS05')
        } else {
          claimOption = decisionList[0]?.find((item) => item.value === 'DS03')
        }
      }
    }

    return claimOption
  }

  React.useEffect(() => {
    if (!_.isEmpty(listHospital) && subTabIndex === 0) {
      showGlobalLoading(true)
      const task = detailValue.payload as TaskDetail.VerificationClaim
      const riders = (task.detail?.payload?.policyRider?.components as BenefitDetailForm[]) || []
      if (riders.length > 0) {
        let enableRiders: BenefitDetailForm[] = []
        riders.forEach((rider) => {
          if (rider.benefitClaimCode.filter((item) => !!item.eligible).length > 0) {
            enableRiders.push({
              ...rider,
              benefitClaimCode: rider.benefitClaimCode.filter((item) => item.eligible)
            })
          }
        })
        const payloadAutoDecisions = enableRiders.map((x) => {
          return x.benefitClaimCode.map((item) => ({
            caseId: x.id,
            productEN: x.productEN,
            productCode: x.productCode,
            componentEN: x.componentEN,
            componentCode: x.componentCode,
            planName: x.planName,
            benefitClaimEN: item.benefitClaimEN,
            benefitClaimCode: item.benefitClaimCode,
            los: task?.isMedical
              ? moment(task?.PATIENT_IN?.dischargeDate, 'DD/MM/YYYY')
                  .diff(moment(task?.PATIENT_IN?.admissionDate, 'DD/MM/YYYY'), 'days')
                  .toString()
              : '',
            rcd: moment(task.policyRider.effectiveDate).format('DD/MM/YYYY'),
            eventDate: task
              ? task.criticalIllness?.claimEventDate ||
                task.death?.claimEventDate ||
                task.tdpInfo?.claimEventDate ||
                task.PATIENT_IN?.admissionDate ||
                task.PATIENT_DENTAL?.admissionDate ||
                task.PATIENT_OUT?.admissionDate ||
                task.PATIENT_MATERNITY?.admissionDate ||
                '-'
              : '-',
            exclusion:
              listHospital?.find(
                (_) =>
                  _.code ===
                  (task.PATIENT_IN?.hospital ||
                    task.PATIENT_DENTAL?.hospital ||
                    task.PATIENT_OUT?.hospital ||
                    task.PATIENT_MATERNITY?.hospital)
              )?.exclusion || 'N',
            deathDate: !_.isNull(task?.criticalIllness?.deathDate) ? task?.criticalIllness?.deathDate || '' : '',
            claimEventDate: task
              ? task.criticalIllness?.claimEventDate ||
                task.death?.claimEventDate ||
                task.tdpInfo?.claimEventDate ||
                task.PATIENT_IN?.admissionDate ||
                task.PATIENT_DENTAL?.admissionDate ||
                task.PATIENT_OUT?.admissionDate ||
                task.PATIENT_MATERNITY?.admissionDate ||
                '-'
              : '-'
          }))
        })
        pipe(
          GeneralService.getAutoDecision(_.flatten(payloadAutoDecisions)),
          ZIO.map((data) => {
            setAutoDecisions(data)
            showGlobalLoading(false)
          }),
          ErrorHandling.run()
        )
      } else {
        showGlobalLoading(false)
      }
    }
  }, [detailValue])

  React.useEffect(() => {
    showGlobalLoading(true)
    if (subTabIndex === 0) {
      pipe(
        props.isInquiry
          ? PulseOpsService.getHistoryDetail(route.id)
          : PulseOpsService.getTaskDetail(route.id, route.basketName),
        ZIO.catchAll((e) => {
          showGlobalLoading(false)
          return ZIO.fail(null)
        }),
        ZIO.tap((value) => {
          setDetail(value)
          const task = value?.payload as TaskDetail.VerificationClaim
          const evaluations = task?.detail?.payload?.adjudication?.finalDecision?.evaluationInfo?.evaluations || []
          const finalInformation =
            task?.detail?.payload?.adjudication?.finalDecision?.evaluationInfo?.finalInformation || ''
          setEvaluationList(evaluations)
          finalDecisionForm.setValue('finalInformation', finalInformation)
          return ZIO.unit
        }),
        ZIO.unsafeRun({})
      )
    }
    if (subTabIndex === 1) {
      pipe(
        props.isInquiry
          ? PulseOpsService.getHistoryDetail(route.id)
          : PulseOpsService.getTaskDetail(route.id, route.basketName),
        ZIO.catchAll((e) => {
          showGlobalLoading(false)
          return ZIO.fail(null)
        }),
        ZIO.tap((value) => {
          setDetail(value)
          const task = value?.payload as TaskDetail.VerificationClaim
          const components = task?.detail?.payload?.policyRider?.components as BenefitDetailForm[]
          const calcSummaryDetail = task?.detail?.payload?.adjudication?.calculation?.summaryDetail || null

          const deductedExpenses = calcSummaryDetail?.deductedExpenses ? calcSummaryDetail?.deductedExpenses : '0'
          const summaryDetail = {
            totalPayableAmount: calculateTotalPayableAmount(components),
            deductedExpenses: deductedExpenses,
            totalNetPayableAmount: calculateTotalPayableNetAmount(
              calculateTotalPayableAmount(components),
              deductedExpenses
            )
          }
          calculationForm.setValue('summaryDetail', summaryDetail)
          showGlobalLoading(false)
          return ZIO.unit
        }),
        ZIO.unsafeRun({})
      )
    }
    if (subTabIndex === 2) {
      pipe(
        props.isInquiry
          ? PulseOpsService.getHistoryDetail(route.id)
          : PulseOpsService.getTaskDetail(route.id, route.basketName),
        ZIO.catchAll((e) => {
          showGlobalLoading(false)
          return ZIO.fail(null)
        }),
        ZIO.tap((value) => {
          setDetail(value)
          showGlobalLoading(false)
          return ZIO.unit
        }),
        ZIO.unsafeRun({})
      )
    }
    if (subTabIndex === 3) {
      showGlobalLoading(false)
    }
  }, [subTabIndex, detail.processId])

  React.useEffect(() => {
    const components = detailValue?.payload?.detail?.payload?.policyRider?.components as BenefitDetailForm[]
    if (components && components.length > 0 && decisionList) {
      // Final info
      const finalInfo = detailValue?.payload?.detail.payload?.adjudication?.finalDecision?.finalInfo || null
      const evaluationInfo = detailValue?.payload?.detail.payload?.adjudication?.finalDecision?.evaluationInfo
      finalDecisionForm.setValue('transferToReInsurance', finalInfo?.transferToReInsurance ?? false)
      finalDecisionForm.setValue('closeReInsurance', finalInfo?.closeReInsurance ?? false)
      finalDecisionForm.setValue('finalInformation', evaluationInfo?.finalInformation ?? '')

      const riders = detailValue?.payload?.detail.payload?.policyRider?.components as BenefitDetailForm[]
      if (riders.length > 0) {
        let enableRiders: BenefitDetailForm[] = []
        riders.forEach((rider) => {
          if (rider.benefitClaimCode.filter((item) => !!item.eligible).length > 0) {
            enableRiders.push({ ...rider, benefitClaimCode: rider.benefitClaimCode.filter((item) => item.eligible) })
          }
        })

        // Final Decision
        const finalDecisionDefault =
          enableRiders.length > 0
            ? enableRiders.map((res) => {
                let recission = null
                const recissionList = decisionList && decisionList[3] && decisionList[3][0] ? decisionList[3] : []
                if (!res.recission && recissionList) {
                  recission = recissionList[0]
                } else if (res.recission && decisionList && decisionList[3]) {
                  recission = recissionList.find((item) => item.value === res.recission?.value) ?? null
                }

                return {
                  ...res,
                  lifeAssuredNumber: '01',
                  effectiveDate: detailPayload?.policyRider?.productOptions[0].riskCessDate
                    ? detailPayload?.policyRider?.productOptions[0].riskCessDate
                    : '-',
                  productStatus: res.subStatus ?? '-',
                  policyStatusOnEventdate: '-',
                  recission: recission,
                  recissionDate: res.recissionDate ? res.recissionDate : null,
                  refundFrom: res.refundFrom ? res.refundFrom : null,
                  refundTo: res.refundTo ? res.refundTo : null,
                  WOP: res.WOP ? true : false,
                  WOPFrom: res.WOPFrom ? res.WOPFrom : null,
                  WOPTo: res.WOPTo ? res.WOPTo : null
                }
              })
            : []

        finalDecisionForm.setValue('decisionDetail', finalDecisionDefault)

        const benefitTable = enableRiders.map((res) => ({
          ...res,
          benefitClaimCode: res.benefitClaimCode.map((benefitClaim) => {
            const adjuDecisionList =
              decisionList && decisionList[0] && decisionList[0].length > 0 ? decisionList[0] : []
            const autoDecision = autoDecisions?.find((_) => _.caseId === res.id)
              ? autoDecisions?.find((_) => _.caseId === res.id)?.autoDecision || '-'
              : detailValue?.payload
              ? decisionList[0]?.find((item) => item.value === 'DS01')?.label || '-'
              : '-'
            const adjudicatorDecision = benefitClaim?.adjudicatorDecision
              ? adjuDecisionList.find((item) => item.value === benefitClaim?.adjudicatorDecision?.value)
              : autoDecisions?.find((_) => _.caseId === res.id)
              ? adjuDecisionList.find(
                  (item) => item.value === autoDecisions?.find((_) => _.caseId === res.id)?.decisionCode
                )
              : decisionList[0]?.find((item) => item.value === 'DS01')
            return {
              ...benefitClaim,
              benefitName: '',
              eventDate: detailValue?.payload
                ? detailValue?.payload?.criticalIllness?.claimEventDate ||
                  detailValue?.payload?.death?.claimEventDate ||
                  detailValue?.payload?.tdpInfo?.claimEventDate ||
                  detailValue?.payload?.PATIENT_IN?.admissionDate ||
                  detailValue?.payload?.PATIENT_DENTAL?.admissionDate ||
                  detailValue?.payload?.PATIENT_OUT?.admissionDate ||
                  detailValue?.payload?.PATIENT_MATERNITY?.admissionDate ||
                  '-'
                : '-',
              autoDecision,
              adjudicatorDecision,
              declineReason:
                benefitClaim?.declineReason || benefitClaim?.adjudicatorDecision?.value === 'DS05'
                  ? declinesOptions()?.find((item) => item.value === benefitClaim?.declineReason?.value)
                  : autoDecisions?.find((_) => _.caseId === res.id)
                  ? declinesOptions()?.find(
                      (item) => item.value === autoDecisions?.find((_) => _.caseId === res.id)?.declineReason
                    )
                  : null,
              claimBenefitAmount: adjudicatorDecision?.value === 'DS02' ? '0' : benefitClaim?.claimAmount ?? '',
              excludedAmount: benefitClaim.excludedAmount ?? '',
              CMPExGAmount: benefitClaim.CMPExGAmount ?? '',
              remarks: benefitClaim.remarks ?? ''
            }
          })
        }))
        finalDecisionForm.setValue('benefitDetail', benefitTable)

        // Calculate
        const calculationDetail = enableRiders.map((res) => ({
          ...res,
          WOPAmount: res?.WOPAmount ?? '',
          numberOfWaiver: res?.numberOfWaiver ?? '0',
          WOP: res.WOP ?? false,
          WOPFrom: res.WOPFrom ?? null,
          WOPTo: res.WOPTo ?? null,
          benefitClaimCode: res.benefitClaimCode.map((benefitClaim) => ({
            ...benefitClaim,
            adjudicatorDecision: benefitClaim.adjudicatorDecision ?? null,
            paymentAmount: benefitClaim.adjudicatorDecision
              ? getPayableAmount(
                  benefitClaim.adjudicatorDecision.value,
                  benefitClaim.claimBenefitAmount || '0',
                  [...(benefitClaim.CMPExGAmount as string)].filter((item) => item !== ',').join('') || '0'
                )
              : '-'
          }))
        }))
        calculationForm.setValue('calculateDetail', calculationDetail)

        const calcSummaryDetail = detailValue?.payload?.detail.payload?.adjudication?.calculation?.summaryDetail || null

        const deductedExpenses = calcSummaryDetail?.deductedExpenses ? calcSummaryDetail?.deductedExpenses : '0'
        const summaryDetail = {
          totalPayableAmount: calculateTotalPayableAmount(components),
          deductedExpenses: deductedExpenses,
          totalNetPayableAmount: calculateTotalPayableNetAmount(
            calculateTotalPayableAmount(components),
            deductedExpenses
          )
        }

        calculationForm.setValue('summaryDetail', summaryDetail)

        const scheduleOption = decisionList && decisionList[1] && decisionList[1][0] ? decisionList[1][0] : null
        // Allocation
        const allocationDetail = enableRiders.map((res) => ({
          ...res,
          benefitClaimCode: res.benefitClaimCode.map((benefitClaim) => ({
            ...benefitClaim,
            schedule: scheduleOption,
            lumpsumPayDateInstallmentDate: benefitClaim?.lumpsumPayDateInstallmentDate ?? null,
            paymentFrequency: null,
            totalInstallmentNumber: ''
          }))
        }))

        allocationForm.setValue('allocation', allocationDetail)
      }
    }
  }, [i18n.language, decisionList, detailValue, autoDecisions])

  const menus = [
    {
      id: 0,
      title: t('FinalDecision'),
      permission: TASK_DETAIL_ROLES.ViewAdjudicationFinalDecisionTaskDetail
    },
    {
      id: 1,
      title: t('Calculation'),
      permission: TASK_DETAIL_ROLES.ViewAdjudicationCalculationTaskDetail
    },
    {
      id: 2,
      title: t('Allocation'),
      permission: TASK_DETAIL_ROLES.ViewAdjudicationAllocationTaskDetail
    },
    {
      id: 3,
      title: t('FinalLetter'),
      permission: TASK_DETAIL_ROLES.ViewAdjudicationFinalLetterTaskDetail
    }
  ]

  const getTabs = (): Tab[] => {
    if (props.roles.length > 0) {
      const visibleTabs = menus.filter((item) => props.roles.includes(item.permission))
      if (visibleTabs.length > 0 && !hasTab(subTabIndex, visibleTabs)) {
        setSubTabIndex(visibleTabs[0].id)
      }
      return visibleTabs
    }
    return []
  }

  const hasTab = (tabId: number, tabs: Tab[]) => {
    return tabs.find((item) => item.id === tabId) !== undefined
  }

  const tabs = getTabs()

  const onChangeSubTab = (tabIdx: number) => {
    if (isCanChangeTab || !!props.isInquiry) {
      setSubTabIndex(tabIdx)
    } else {
      let fieldName = 'FinalDecision'
      if (subTabIndex === 0) {
        fieldName = 'FinalDecision'
      } else if (subTabIndex === 1) {
        fieldName = 'Calculation'
      } else if (subTabIndex === 2) {
        fieldName = 'Allocation'
      }
      showToast(t('message:MS070020', { field: t(`${fieldName}`) }), 'error')
    }
  }

  const onPressSuspend = (detail: TaskDetail.Type) => {
    showGlobalLoading(true)
    pipe(
      SupplementaryInfoService.checkExpireDate(detail.processId),
      ZIO.catchAll(() => {
        showGlobalLoading(false)
        return ZIO.fail(null)
      }),
      ZIO.tap(({ expiredDate }) => {
        showGlobalLoading(false)
        setPlanSuspendDate(moment(expiredDate, 'DD/MM/yyyy HH:mm:ss').toDate())
        onSuspendAction(showToast, t)()(setIsSuspendPopupOpen, () => {})()
        return ZIO.unit
      }),
      ZIO.unsafeRun({})
    )
  }

  let faceAmountValue = formatNumberWithComma(calculateFaceAmount())

  React.useEffect(() => {
    // console.log(loading)
    // showGlobalLoading(loading)
    const isSupsend = props.subTask.split('-')[1] === 'SUSPENDED'

    getFooterClaim(
      isSupsend
        ? [
            {
              name: t('claim:Unsuspend'),
              action: onUnsuspendAction(props.setIsUnSuspendPopupOpen)
            }
          ]
        : [
            {
              name: t('claim:Complete'),
              action: () => {
                const changeInFD =
                  !!finalDecisionForm.formState.dirtyFields.benefitDetail ||
                  !!finalDecisionForm.formState.dirtyFields.decisionDetail
                const changeInCal = !!calculationForm.formState.dirtyFields.calculateDetail
                const changeInAllo = !!allocationForm.formState.dirtyFields.allocation

                changeInFD
                  ? showToast(t('message:MS070020', { field: t('FinalDecision') }), 'error')
                  : changeInCal
                  ? showToast(t('message:MS070020', { field: t('Calculation') }), 'error')
                  : changeInAllo
                  ? showToast(t('message:MS070020', { field: t('Allocation') }), 'error')
                  : (() => {
                      pipe(
                        ZIO.zipPar(
                          PulseOpsService.getTaskDetail(props.route.id, props.route.basketName),
                          GeneralService.getHistoryLetter(detailPayload?.detail?.processInstanceId || '-')
                        ),
                        ZIO.map(([x, history]) => {
                          const checker = x.payload as TaskDetail.VerificationClaim
                          const riders = checker?.detail?.payload?.policyRider?.components as BenefitDetailForm[]
                          let enableRiders: BenefitDetailForm[] = []
                          if (!!riders && riders.length > 0) {
                            riders.forEach((rider) => {
                              if (rider.benefitClaimCode.filter((item) => !!item.eligible).length > 0) {
                                enableRiders.push({
                                  ...rider,
                                  benefitClaimCode: rider.benefitClaimCode.filter((item) => item.eligible)
                                })
                              }
                            })
                          }

                          return enableRiders.length > 0 && history && history.filter((item) => item.status).length > 0
                        }),
                        ZIO.tap((isValidAdju) => {
                          if (isValidAdju) {
                            onCompleteAction(showGlobalLoading, showToast, t)(
                              props.subTask,
                              props.subTaskCode,
                              props.detail
                            )(setCompleteOpen, () => {})()
                          } else {
                            showToast(t('message:PleaseUpdateFormBeforeComplete'), 'error')
                          }
                          return ZIO.unit
                        }),
                        bindLoading,
                        ErrorHandling.run()
                      )
                    })()
              },
              isVisible: checkRolesForButton('CL', props.roles, false)('Complete') && !props.isSupActive
            },
            {
              name: t('claim:Suspend'),
              action: props.detail ? () => onPressSuspend(props.detail) : () => {},
              isVisible: checkRolesForButton('CL', props.roles, false)('Suspend') && props.isSupActive
            },
            {
              name: t('claim:Reject'),
              action: props.detail
                ? onRejectAction(showToast, t)(undefined, false)(setIsRejectPopupOpen, () => {})
                : () => {},
              isVisible: checkRolesForButton('CL', props.roles, false)('Reject')
            },
            {
              name: t('claim:End'),
              action: props.detail ? onEndAction(showToast, t)()(setIsEndPopupOpen, () => {}) : () => {},
              isVisible: checkRolesForButton('CL', props.roles, false)('End')
            },
            {
              name: t('claim:Transfer'),
              action: () => {
                setTransferModalVisible(true)
              },
              isVisible: checkRolesForButton('CL', props.roles, false)('Transfer')
            },
            {
              name: t('claim:Save'),
              isLoading: loading,
              action: () => {
                const detailInfo = detailValue ? detailValue?.payload?.detail?.payload : detailPayload?.detail?.payload
                if (subTabIndex === 0) {
                  const finalDecision = {
                    finalInfo: {
                      faceAmount: faceAmountValue,
                      closeReInsurance: finalDecisionForm.getValues('closeReInsurance'),
                      transferToReInsurance: finalDecisionForm.getValues('transferToReInsurance')
                    },
                    evaluationInfo: {
                      evaluations: evaluations,
                      finalInformation: finalDecisionForm.getValues('finalInformation')
                    }
                  }

                  const decisionDetail = [...finalDecisionForm.getValues('decisionDetail')]
                  const benefitDetail = [...finalDecisionForm.getValues('benefitDetail')]

                  const components = detailInfo.policyRider?.components
                  const riders = components && _.isArray(components) ? components : []

                  const finalDecisionObject = decisionDetail.map((item, i) => {
                    let benefitClaimCode: BenefitClaimDetail = []
                    let filteredRider: BenefitDetailForm | null = null
                    filteredRider = riders.find((rider) => rider.id === item.id) || null
                    benefitDetail[i].benefitClaimCode.map((benDetail, index1) => {
                      filteredRider?.benefitClaimCode.map((benClaim, index2) => {
                        if (benClaim.id === benDetail.id) {
                          const benInValid = !!benefitClaimCode.find(
                            (benefitClaimItem) => benefitClaimItem.id === benDetail.id
                          )
                          if (benefitClaimCode.length === 0 || (benefitClaimCode.length > 0 && !benInValid)) {
                            benefitClaimCode.push(_.cloneDeep(benDetail))
                          }
                        } else {
                          const benInValid = !!benefitClaimCode.find(
                            (benefitClaimItem) => benefitClaimItem.id === benClaim.id
                          )
                          const benDetailExist = benefitDetail[i].benefitClaimCode.find(
                            (benDetailExist) => benDetailExist.id === benClaim.id
                          )
                          if (
                            benefitClaimCode.length === 0 ||
                            (benefitClaimCode.length > 0 && !benDetailExist && !benInValid)
                          ) {
                            benefitClaimCode.push(_.cloneDeep(benClaim))
                          } else if (benefitClaimCode.length > 0 && benDetailExist && !benInValid) {
                            benefitClaimCode.push(_.cloneDeep(benDetailExist))
                          }
                        }
                        return benClaim
                      })
                      return benDetail
                    })
                    return {
                      ...item,
                      recission: item.recission,
                      recissionDate: item.recissionDate,
                      refundFrom: item.refundFrom,
                      refundTo: item.refundTo,
                      WOP: item.WOP,
                      WOPFrom: item.WOPFrom,
                      WOPTo: item.WOPTo,
                      benefitClaimCode: benefitClaimCode
                    }
                  })

                  let policyRider: BenefitDetailForm[] = _.cloneDeep(riders)

                  policyRider.map((rider: BenefitDetailForm, index) => {
                    finalDecisionObject.map((item) => {
                      if (item.id === rider.id && item.componentCode === rider.componentCode) {
                        policyRider.splice(index, 1, item)
                      }
                    })
                  })

                  const durationICU: string | undefined =
                    detailInfo.verification?.subBenefit
                      ?.find((_) => _.value === 'SBC01')
                      ?.receiptItems.find((_) => _.receiptItemCode.value === 'RCI02')?.duration || '0'

                  const durationHospitalization: string | undefined = detailPayload?.PATIENT_IN
                    ? D.differenceInDays(
                        moment(detailValue.payload?.PATIENT_IN?.dischargeDate, 'DD/MM/YYYY').toDate(),
                        moment(detailValue.payload?.PATIENT_IN?.admissionDate, 'DD/MM/YYYY').toDate()
                      ).toString()
                    : '0'

                  finalDecisionForm.handleSubmit(
                    () => {
                      pipe(
                        PulseOpsService.getTaskDetail(props.route.id, props.route.basketName),
                        ZIO.flatMap((res) =>
                          TaskDetailService.saveLog(
                            props.detail.id,
                            {
                              ...detailInfo,
                              adjudication: {
                                ...(res.payload as TaskDetail.VerificationClaim).detail.payload.adjudication,
                                finalDecision: finalDecision,
                                planName: detailPayload.policyRider.productOptions[0].planName,
                                productName: detailPayload.policyRider.productOptions[0].productName
                              },
                              policyRider: {
                                components: _.cloneDeep(policyRider)
                              }
                            },
                            props.detail.processId,
                            []
                          )
                        ),
                        ZIO.tap((_) => {
                          let enableRiders: BenefitDetailForm[] = []
                          const { decisionDetail } = finalDecisionForm.getValues()
                          if (decisionDetail && decisionDetail.length > 0) {
                            finalDecisionForm.getValues().decisionDetail.forEach((rider) => {
                              if (rider.benefitClaimCode.filter((item) => item.eligible).length > 0) {
                                enableRiders.push({
                                  ...rider,
                                  benefitClaimCode: rider.benefitClaimCode.filter((item) => item.eligible)
                                })
                              }
                            })
                          }

                          const calcSummaryDetail =
                            detailValue?.payload?.detail.payload?.adjudication?.calculation?.summaryDetail || null

                          const deductedExpenses = calcSummaryDetail?.deductedExpenses
                            ? calcSummaryDetail?.deductedExpenses
                            : '0'

                          const summaryDetail = {
                            totalPayableAmount: calculateTotalPayableAmount(enableRiders),
                            deductedExpenses: deductedExpenses,
                            totalNetPayableAmount: calculateTotalPayableNetAmount(
                              calculateTotalPayableAmount(enableRiders),
                              deductedExpenses
                            )
                          }

                          finalDecisionForm.reset({
                            ...finalDecisionForm.getValues(),
                            decisionDetail: enableRiders
                          })

                          calculationForm.reset({
                            ...calculationForm.getValues(),
                            summaryDetail: summaryDetail
                          })

                          setSubTabIndex(1)
                          return ZIO.unit
                        }),
                        ZIO.flatMap(() =>
                          GeneralService.saveBenefitClaimDecision(
                            detailInfo.primaryPolicyNo,
                            benefitDetail.map((_) => ({
                              productCode: _.productCode || '',
                              planName: _.planName || '',
                              componentCode: _.componentCode || '',
                              benefitClaimCode: _.benefitClaimCode[0].benefitClaimCode || '',
                              sumAssured: _.benefitClaimCode[0].sumAssure || '0',
                              adjudicationDecision: _.benefitClaimCode[0].adjudicatorDecision?.value || '',
                              durationICU: durationICU || '0',
                              durationHospitalization: durationHospitalization || '0',
                              benefitClaimAmount: _.benefitClaimCode[0].claimAmount || ''
                            })),
                            detailInfo.source,
                            detailPayload?.detail?.processInstanceId || '',
                            detailPayload?.detail?.businessKey,
                            detailInfo.createdBy
                          )
                        ),
                        bindLoading,
                        ZIO.unsafeRun({})
                      )
                      showToast(t('message:MS990017'), 'success')
                      setIsCanChangeTab(true)
                    },
                    (error) => {
                      console.log('Error: ', error, calculationForm.formState)
                      showToast('Please check form again', 'error')
                    }
                  )()
                }

                if (subTabIndex === 1) {
                  const currentData = calculationForm.getValues()
                  const calculation = {
                    summaryDetail: {
                      deductedExpenses: currentData.summaryDetail.deductedExpenses,
                      totalPayableAmount: currentData.summaryDetail.totalPayableAmount,
                      totalNetPayableAmount: currentData.summaryDetail.totalNetPayableAmount
                    }
                  }

                  const components = detailInfo?.policyRider?.components
                  const riders = components && _.isArray(components) ? components : []

                  const calculations = currentData.calculateDetail.map((item, i) => {
                    let benefitClaimCode: BenefitClaimDetail = []
                    let filteredRider: BenefitDetailForm | null = null
                    filteredRider = riders.find((rider) => rider.id === item.id) || null
                    item.benefitClaimCode.map((benDetail) => {
                      filteredRider?.benefitClaimCode.map((benClaim, index2) => {
                        if (benClaim.id === benDetail.id) {
                          const benInValid = !!benefitClaimCode.find(
                            (benefitClaimItem) => benefitClaimItem.id === benDetail.id
                          )
                          if (benefitClaimCode.length === 0 || (benefitClaimCode.length > 0 && !benInValid)) {
                            benefitClaimCode.push(_.cloneDeep(benDetail))
                          }
                        } else {
                          const benInValid = !!benefitClaimCode.find(
                            (benefitClaimItem) => benefitClaimItem.id === benClaim.id
                          )
                          const benDetailExist = item.benefitClaimCode.find(
                            (benDetailExist) => benDetailExist.id === benClaim.id
                          )
                          if (
                            benefitClaimCode.length === 0 ||
                            (benefitClaimCode.length > 0 && !benDetailExist && !benInValid)
                          ) {
                            benefitClaimCode.push(_.cloneDeep(benClaim))
                          } else if (benefitClaimCode.length > 0 && benDetailExist && !benInValid) {
                            benefitClaimCode.push(_.cloneDeep(benDetailExist))
                          }
                        }
                        return benClaim
                      })
                      return benDetail
                    })
                    return {
                      ...item,
                      benefitClaimCode: benefitClaimCode
                    }
                  })

                  let policyRider: BenefitDetailForm[] = _.cloneDeep(riders)

                  policyRider.map((rider: BenefitDetailForm, index) => {
                    calculations.map((item) => {
                      if (item.id === rider.id && item.componentCode === rider.componentCode) {
                        policyRider.splice(index, 1, item)
                      }
                    })
                  })

                  calculationForm.handleSubmit(
                    () => {
                      pipe(
                        PulseOpsService.getTaskDetail(props.route.id, props.route.basketName),
                        ZIO.flatMap((res) =>
                          TaskDetailService.saveLog(
                            props.detail.id,
                            {
                              ...detailInfo,
                              adjudication: {
                                ...(res.payload as TaskDetail.VerificationClaim).detail.payload.adjudication,
                                calculation: calculation,
                                planName: detailPayload.policyRider.productOptions[0].planName,
                                productName: detailPayload.policyRider.productOptions[0].productName
                              },
                              policyRider: {
                                components: _.cloneDeep(policyRider)
                              }
                            },
                            props.detail.processId,
                            []
                          )
                        ),
                        ZIO.tap((_) => {
                          let enableRiders: BenefitDetailForm[] = []
                          const { calculateDetail } = calculationForm.getValues()
                          if (calculateDetail && calculateDetail.length > 0) {
                            calculateDetail.forEach((rider) => {
                              if (rider.benefitClaimCode.filter((item) => !!item.eligible).length > 0) {
                                enableRiders.push({
                                  ...rider,
                                  benefitClaimCode: rider.benefitClaimCode.filter((item) => item.eligible)
                                })
                              }
                            })
                          }

                          const calcSummaryDetail =
                            detailValue?.payload?.detail.payload?.adjudication?.calculation?.summaryDetail || null

                          const deductedExpenses = calcSummaryDetail?.deductedExpenses
                            ? calcSummaryDetail?.deductedExpenses
                            : '0'
                          const summaryDetail = {
                            totalPayableAmount: calculateTotalPayableAmount(enableRiders),
                            deductedExpenses: deductedExpenses,
                            totalNetPayableAmount: calculateTotalPayableNetAmount(
                              calculateTotalPayableAmount(enableRiders),
                              deductedExpenses
                            )
                          }

                          calculationForm.reset({
                            ...calculationForm.getValues(),
                            calculateDetail: enableRiders,
                            summaryDetail: summaryDetail
                          })
                          setSubTabIndex(2)
                          return ZIO.unit
                        }),
                        bindLoading,
                        ZIO.unsafeRun({})
                      )
                      showToast(t('message:MS990017'), 'success')
                      setIsCanChangeTab(true)
                    },
                    (error) => {
                      console.log('Error: ', error, calculationForm.formState.errors)
                      showToast('Please check form again', 'error')
                    }
                  )()
                }

                if (subTabIndex === 2) {
                  const { allocation } = allocationForm.getValues() || []

                  const components = detailInfo?.policyRider?.components
                  const riders = components && _.isArray(components) ? components : []

                  const allocations = allocation.map((item, i) => {
                    let benefitClaimCode: BenefitClaimDetail = []
                    let filteredRider: BenefitDetailForm | null = null
                    filteredRider = riders.find((rider) => rider.id === item.id) || null
                    item.benefitClaimCode.map((benDetail) => {
                      filteredRider?.benefitClaimCode.map((benClaim, index2) => {
                        if (benClaim.id === benDetail.id) {
                          const benInValid = !!benefitClaimCode.find(
                            (benefitClaimItem) => benefitClaimItem.id === benDetail.id
                          )
                          if (benefitClaimCode.length === 0 || (benefitClaimCode.length > 0 && !benInValid)) {
                            benefitClaimCode.push(_.cloneDeep(benDetail))
                          }
                        } else {
                          const benInValid = !!benefitClaimCode.find(
                            (benefitClaimItem) => benefitClaimItem.id === benClaim.id
                          )
                          const benDetailExist = item.benefitClaimCode.find(
                            (benDetailExist) => benDetailExist.id === benClaim.id
                          )
                          if (
                            benefitClaimCode.length === 0 ||
                            (benefitClaimCode.length > 0 && !benDetailExist && !benInValid)
                          ) {
                            benefitClaimCode.push(_.cloneDeep(benClaim))
                          } else if (benefitClaimCode.length > 0 && benDetailExist && !benInValid) {
                            benefitClaimCode.push(_.cloneDeep(benDetailExist))
                          }
                        }
                        return benClaim
                      })
                      return benDetail
                    })
                    return {
                      ...item,
                      benefitClaimCode: benefitClaimCode
                    }
                  })

                  let policyRider: BenefitDetailForm[] = _.cloneDeep(riders)

                  policyRider.map((rider: BenefitDetailForm, index) => {
                    allocations.map((item) => {
                      if (item.id === rider.id && item.componentCode === rider.componentCode) {
                        policyRider.splice(index, 1, item)
                      }
                    })
                  })

                  allocationForm.handleSubmit(
                    () => {
                      pipe(
                        PulseOpsService.getTaskDetail(props.route.id, props.route.basketName),
                        ZIO.flatMap((res) =>
                          TaskDetailService.saveLog(
                            props.detail.id,
                            {
                              ...detailInfo,
                              adjudication: {
                                ...(res.payload as TaskDetail.VerificationClaim).detail.payload.adjudication,
                                allocation: null,
                                planName: detailPayload.policyRider.productOptions[0].planName,
                                productName: detailPayload.policyRider.productOptions[0].productName,
                                claimDecision: claimDecision() ? claimDecision()?.value : decisionList[0][0].value
                              },
                              policyRider: {
                                components: _.cloneDeep(policyRider)
                              }
                            },
                            props.detail.processId,
                            []
                          )
                        ),
                        ZIO.tap((_) => {
                          let enableRiders: BenefitDetailForm[] = []
                          allocationForm.getValues().allocation.forEach((rider) => {
                            if (rider.benefitClaimCode.filter((item) => !!item.eligible).length > 0) {
                              enableRiders.push({
                                ...rider,
                                benefitClaimCode: rider.benefitClaimCode.filter((item) => item.eligible)
                              })
                            }
                          })
                          allocationForm.reset({
                            ...allocationForm.getValues(),
                            allocation: enableRiders
                          })
                          setSubTabIndex(3)
                          return ZIO.unit
                        }),
                        bindLoading,
                        ZIO.unsafeRun({})
                      )
                      showToast(t('message:MS990017'), 'success')
                      setIsCanChangeTab(true)
                    },
                    (error) => {
                      showToast('Please check form again', 'error')
                    }
                  )()
                }
                if (subTabIndex === 3) {
                  finalLetterForm.handleSubmit(
                    () => {
                      onSaveFinalLetter()
                      setIsCanChangeTab(true)
                    },
                    (erros) => {
                      showToast(t('message:MS050001'), 'error')
                    }
                  )()
                }
              }
            }
          ]
    )
  }, [subTabIndex, loading, evaluations, isSeenLetter, isGenerateLetter, historyLetter])

  const onSaveFinalLetter = () => {
    const currentData = finalLetterForm.getValues()

    const { fileAttached, fileTemplate } = currentData

    let isCheck = false
    if (historyLetter.length > 0 && isEmpty(fileAttached) && isEmpty(fileTemplate)) {
      isCheck = historyLetter.findIndex((item) => item.status === 'Active') !== -1 ? false : true
    }
    if (isCheck) {
      return showToast(t('message:MS070032'), 'success')
    }

    if (isEmpty(fileAttached) && isEmpty(fileTemplate)) {
      return showToast(t('message:MS070027'), 'error')
    }

    if (!isSeenLetter && !isEmpty(fileTemplate)) {
      return showToast(t('message:MS070026'), 'error')
    }

    let files = [...fileAttached, ...fileTemplate]
    const policyNumber = localStorage.getItem("FinalLetterPolicyNumber")
    console.log(`FinalLetterPolicyNumber ${policyNumber}`)
    pipe(
      // GeneralService.getMetaData(TransactionType.NEW_CLAIM_REGISTER || '', 'DCL11'),
      // ZIO.flatMap((metaDataRes) => {
      //   const metaDataRaw: StorageBlob.MetaDataUpload = {
      //     type: metaDataRes.data.transactionType,
      //     doctype: metaDataRes.data.doctypeEn,
      //     class: metaDataRes.data.classFilenet,
      //     docid: metaDataRes.data.docID,
      //     maindoc: metaDataRes.data.mainDoc,
      //     subdoc: metaDataRes.data.subDoc,
      //     polnum: props.detail.primaryPolicyNo,
      //     batchno: metaDataRes.data.batchNo
      //   }
      //   const files: StorageBlob.FileContent[] = fileAttached.map((x) => {
      //     return {
      //       file: x.file,
      //       metaData: metaDataRaw
      //     }
      //   })
      //   return pipe(StorageBlob.uploadToSubmit('CL', props.detail.primaryPolicyNo)(files))
      // }),
      !!files
        ? GeneralService.getAzureStorageFiles(
            files,
            TransactionType.NEW_CLAIM_REGISTER,
            'DCL11',
            'CL',
            policyNumber as string,
            route.officeCode
          )
        : ZIO.succeed([]),
      ZIO.tapError((_) => {
        showToast(t('message:MS050001'), 'error')
        return ZIO.unit
      }),
      ZIO.map((file) => {
        if (author) {
          pipe(
            GeneralService.saveLogFinal(
              props.detail.primaryPolicyNo,
              file,
              props.detail.processId || '',
              props.detail.id || '',
              author?.email || ''
            ),
            ZIO.map((res) => {
              res && setFinalLetter(res)
              onChangeRefUpload(false)
              finalLetterForm.reset({
                ...finalLetterForm.getValues(),
                fileAttached: [],
                fileTemplate: []
              })
              uploadRef.current?.removeFile()
              templateRef.current?.removeFile()
              return ZIO.unit
            }),
            ZIO.unsafeRun({})
          )
        }
        showToast(t('message:MS990017'), 'success')
      }),
      ZIO.unsafeRun({})
    )
  }
  const onChangeRefUpload = (val: boolean) => {
    uploadRef.current?.disableUpload(val)
  }

  const onGetTemplateFile = (files: FileList) => {
    templateRef.current?.getFileTemple(files)
  }

  React.useEffect(() => {
    setEvaluations(finalDecision?.evaluationInfo?.evaluations || [])
    localStorage.setItem("FinalLetterPolicyNumber",  props.detail.primaryPolicyNo)
    return () =>{
      localStorage.removeItem("FinalLetterPolicyNumber")
    }
  }, [])

  const onHandleActionSuccess = (mustRefreshTaskGroup = false, isTranfer = false) => {
    if (mustRefreshTaskGroup) {
      isTranfer ? requestRefreshDashboardAfterTranfer() : requestRefreshAllInDashboard()
    } else {
      requestRefreshAllBasketInDashboard()
    }

    if (props.navigation.canGoBack()) {
      props.navigation.goBack()
    } else {
      props.navigation.navigate('UserDrawer', {
        screen: 'TaskDashboardScreen'
      })
    }
  }

  const setEvaluationList = (evaluationList: Evaluation[]) => {
    setEvaluations(evaluationList)
  }

  return (
    <SafeAreaView style={{ position: 'relative', flex: 1 }}>
      <TabList
        gutterBottom
        isSubTab={true}
        useOriginalId={true}
        menus={tabs}
        tabIndex={subTabIndex}
        onChangeTab={onChangeSubTab}
      />
      <View>
        {hasTab(0, tabs) && subTabIndex === 0 && isWide && decisionList.length > 0 && !loading && (
          <FinalDecision
            props={{
              faceAmount: faceAmountValue,
              payload: detailValue?.payload || (detailPayload as TaskDetail.VerificationClaim),
              adjuDecisionOptions: decisionList[0],
              navigation: props.navigation,
              declinesOptions: declinesOptions()
            }}
            form={finalDecisionForm}
            disabled={props.disabled}
            setEvaluationList={setEvaluationList}
            evaluations={evaluations}
            setIsCanChangeTab={setIsCanChangeTab}
          />
        )}
        {hasTab(1, tabs) && subTabIndex === 1 && isWide && decisionList.length > 0 && !loading && (
          <Calculation
            finalForm={finalDecisionForm}
            props={{
              claimNumber: props.detail?.claimNumber as string,
              faceAmount: faceAmountValue,
              payload: detailValue?.payload || (detailPayload as TaskDetail.VerificationClaim),
              decision: decisionList[0]
            }}
            form={calculationForm}
            disabled={props.disabled}
            setIsCanChangeTab={setIsCanChangeTab}
          />
        )}
        {hasTab(2, tabs) && subTabIndex === 2 && isWide && decisionList.length > 0 && !loading && (
          <Allocation
            props={{
              claimNumber: props.detail?.claimNumber as string,
              faceAmount: faceAmountValue,
              payload: detailValue?.payload || (detailPayload as TaskDetail.VerificationClaim),
              decision: decisionList
            }}
            form={allocationForm}
            formCalculation={calculationForm}
            disabled={props.disabled}
            setIsCanChangeTab={setIsCanChangeTab}
            claimDecision={claimDecision()}
          />
        )}
        {hasTab(3, tabs) && subTabIndex === 3 && isWide && decisionList.length > 0 && !loading && (
          <FinalLetter
            props={{
              claimNumber: props.detail?.claimNumber as string,
              faceAmount: faceAmountValue,
              payload: detailValue?.payload || (detailPayload as TaskDetail.VerificationClaim),
              decision: decisionList,
              category: props.detail.category as TaskType,
              declinesOptions: declinesOptionsVN(),
              policyNum: props.detail.primaryPolicyNo
            }}
            form={finalLetterForm}
            formCalculation={calculationForm}
            disabled={props.disabled}
            setIsCanChangeTab={setIsCanChangeTab}
            finalLetter={finalLetter}
            roles={roles}
            onChangeParentTab={props.onChangeTab}
            onChangeSubTab={setSubTabIndex}
            onSeenLetter={setIsSeenLetter}
            onSave={onSaveFinalLetter}
            benefitDetailInfo={finalDecisionForm.getValues('benefitDetail')}
            claimTypeInfo={claimType}
            onGenerateLetter={setIsGenerateLetter}
            claimData={props.claimData}
            refUpload={uploadRef}
            handldeChangeRef={onChangeRefUpload}
            refTemplate={templateRef}
            handleGetTemplate={onGetTemplateFile}
            onHistoryLetter={setHistoryLetter}
            claimDecision={claimDecision()}
          />
        )}
      </View>
      {props.detail && (
        <CompletePopup
          open={completeOpen}
          onClose={() => setCompleteOpen(false)}
          onSuccess={() => onHandleActionSuccess(true)}
          selectedValue={{
            taskId: props.detail.id || '-',
            processId: props.detail.processId || '-',
            updateToCore: props.detail.updateToCore,
            policyNum: props.detail.primaryPolicyNo || '-',
            category: props.detail.category,
            caseId: props.detail.caseId
          }}
        />
      )}
      {props.detail && (
        <TransferPopup
          open={isTransferModalVisible}
          isClaim={true}
          onClose={() => setTransferModalVisible(false)}
          onSuccess={() => onHandleActionSuccess(true, true)}
          selectedValue={{
            category: props.detail.category as TaskType,
            basket: SubTaskType.Adjudication,
            taskIds: [props.detail.id],
            updateToCore: props.detail.updateToCore,
            transactionType: props.detail.payload.tag
          }}
        />
      )}
      {props.detail && planSuspendDate && (
        <SuspendPopup
          open={isSuspendPopupOpen}
          onClose={() => setIsSuspendPopupOpen(false)}
          onSuccess={() => onHandleActionSuccess(true)}
          selectedValue={{
            processId: props.detail.processId,
            taskId: props.detail.id,
            planSuspendDate: planSuspendDate,
            updateToCore: props.detail.updateToCore,
            category: props.detail.category,
            caseId: props.detail.caseId
          }}
          category={props.detail.category}
        />
      )}
      {props.detail && (
        <RejectPopup
          open={isRejectPopupOpen}
          onSuccess={() => onHandleActionSuccess(true)}
          selectedValue={{
            taskId: props.detail.id,
            processId: props.detail.processId,
            updateToCore: props.detail.updateToCore,
            category: props.detail.category as TaskType,
            caseId: props.detail.caseId
          }}
          onClose={() => setIsRejectPopupOpen(false)}
        />
      )}

      {props.detail && (
        <EndPopup
          open={isEndPopupOpen}
          onClose={() => setIsEndPopupOpen(false)}
          onSuccess={() => onHandleActionSuccess(true)}
          selectedValue={{
            taskId: props.detail.id,
            updateToCore: props.detail.updateToCore,
            category: props.detail.category,
            caseId: props.detail.caseId
          }}
        />
      )}
    </SafeAreaView>
  )
}
