import * as api from '../task-detail-api'
import { TransactionType } from '../TransactionType'
import { formatTextFromLasToVN } from '../../format'
import { SourceType } from '../SourceType'
import { Customer } from '../Client'
import { markupString } from './Util'

export interface P4OpsData {
  source: SourceType.PULSE4OPS | SourceType.BANCA_SEA | SourceType.PRUONLINE | SourceType.OUTBOUND | SourceType.INBOUND
  street: string
  country: string
  city: string
  district: string
  ward: string
  priorPolicies: { policyNumber: string; address: string }[]
}
export interface PulseData {
  source: SourceType.PULSE
  country: string
  city: string
  district: string
  ward: string
  street: string
  priorPolicies: { policyNumber: string; address: string }[]
}
export interface DefaultData {
  source: SourceType.BANCA | SourceType.EKIOSK | SourceType.PRUGREAT | SourceType.ZALO
  address: string
}
export interface ChangePrimaryAddressInfo {
  tag: TransactionType.CHANGE_CONTACT_INFO_ADDRESS | TransactionType.CHANGE_CONTACT_INFO_ADDRESS_DIGITAL
  clientName: string
  clientCode: string
  detail: P4OpsData | DefaultData | PulseData
  policyNo: string
}

export interface generalAddressInfo {
  country?: string
  city?: string
  district?: string
  ward?: string
}

export const ChangePrimaryAddressInfo = (
  detail: api.ChangePrimaryAddressInfo,
  client: Customer,
  generalAddress: generalAddressInfo
): ChangePrimaryAddressInfo => {
  switch (detail.source) {
    case SourceType.PRUONLINE:
    case SourceType.PULSE4OPS:
    case SourceType.BANCA_SEA: {
      const p4ops = detail.payload.body

      const street = markupString(p4ops.dispatchAddress?.line1) || client.body.addressDetails.PRIMARY?.line1 || '-'
      // const transactionType = SourceType.BANCA_SEA ? TransactionType.CHANGE_CONTACT_INFO_ADDRESS_DIGITAL :
      // TransactionType.CHANGE_CONTACT_INFO_ADDRESS
      return {
        tag:
          detail.transactionType === TransactionType.CHANGE_CONTACT_INFO_ADDRESS
            ? TransactionType.CHANGE_CONTACT_INFO_ADDRESS
            : TransactionType.CHANGE_CONTACT_INFO_ADDRESS_DIGITAL,
        clientName: client.body?.surName + ' ' + client.body?.firstName,
        clientCode: client.body?.clientId || '-',
        detail: {
          source: detail.source === SourceType.PULSE4OPS ? SourceType.PULSE4OPS : SourceType.BANCA_SEA,
          street: p4ops.dispatchAddress.line1,
          country: p4ops.dispatchAddress.countryCode ?? '',
          city: (p4ops.dispatchAddress && p4ops.dispatchAddress.city) || '',
          district: (p4ops.dispatchAddress && p4ops.dispatchAddress.district) || '',
          ward: (p4ops.dispatchAddress && p4ops.dispatchAddress.subDistrict) || '',
          priorPolicies:
            p4ops.priorPolicies?.map((e) => ({
              address: `${street}, ${generalAddress.ward}, ${generalAddress.district}, ${generalAddress.city}`,
              policyNumber: e.policyNo ?? ''
            })) ?? []
        },
        policyNo: p4ops.policyNo
      }
    }
    case SourceType.PULSE: {
      const p = detail.payload.body

      return {
        tag:
          detail.transactionType === TransactionType.CHANGE_CONTACT_INFO_ADDRESS
            ? TransactionType.CHANGE_CONTACT_INFO_ADDRESS
            : TransactionType.CHANGE_CONTACT_INFO_ADDRESS_DIGITAL,
        clientName: client.body?.surName + ' ' + client.body?.firstName,
        clientCode: client.body?.clientId || '-',
        detail: {
          source: SourceType.PULSE,
          country: p.countryCode || '',
          city: p.state || '',
          district: p.line3 || '',
          ward: p.line2 || '',
          street: p.line1 || '',
          priorPolicies:
            p.priorPolicies?.map((e) => ({
              address: `${p.line1}, ${generalAddress.ward}, ${generalAddress.district}, ${generalAddress.city}`,
              policyNumber: e.policyNo ?? ''
            })) ?? []
        },
        policyNo: detail.payload.primaryPolicyNo
      }
    }
    case SourceType.INBOUND:
    case SourceType.OUTBOUND: {
      const p4ops = detail.payload.body

      const street = markupString(p4ops.dispatchAddress?.line1) || client.body.addressDetails.PRIMARY?.line1 || '-'
      // const transactionType = SourceType.BANCA_SEA ? TransactionType.CHANGE_CONTACT_INFO_ADDRESS_DIGITAL :
      // TransactionType.CHANGE_CONTACT_INFO_ADDRESS
      return {
        tag:
          detail.transactionType === TransactionType.CHANGE_CONTACT_INFO_ADDRESS
            ? TransactionType.CHANGE_CONTACT_INFO_ADDRESS
            : TransactionType.CHANGE_CONTACT_INFO_ADDRESS_DIGITAL,
        clientName: client.body?.surName + ' ' + client.body?.firstName,
        clientCode: client.body?.clientId || '-',
        detail: {
          source: detail.source,
          street: p4ops.dispatchAddress.line1,
          country: p4ops.dispatchAddress.countryCode ?? '',
          city: (p4ops.dispatchAddress && p4ops.dispatchAddress.city) || '',
          district: (p4ops.dispatchAddress && p4ops.dispatchAddress.district) || '',
          ward: (p4ops.dispatchAddress && p4ops.dispatchAddress.subDistrict) || '',
          priorPolicies:
            p4ops.priorPolicies?.map((e) => ({
              address: `${street}, ${generalAddress.ward}, ${generalAddress.district}, ${generalAddress.city}`,
              policyNumber: e.policyNo ?? ''
            })) ?? []
        },
        policyNo: p4ops.policyNo
      }
    }
    default: {
      const p = detail.payload.body
      const country = p.country ? p.country : p.countryCode ? p.countryCode : ''
      return {
        tag: TransactionType.CHANGE_CONTACT_INFO_ADDRESS,

        // address: `${detail.payload.body.line1} ${country ? ', ' + country : ''}`,
        clientName: client.body?.surName + ' ' + client.body?.firstName,
        clientCode: client.body.clientId,
        detail: {
          source: detail.source,
          address: `${formatTextFromLasToVN(detail.payload.body.line1)} ${country ? ', ' + country : ''}`
        },
        policyNo: ''
      }
    }
  }
}
