import {
  QCTaskList,
  SubTasksResponse,
  SubTaskType,
  SupendedTaskList,
  SupplementaryInfo,
  TaskDetail,
  TaskType,
  TransactionType
} from '@pulseops/common'
import { VeriPayloadData } from 'libs/common/src/service/model/task-detail/InquiryComplaint'
import React from 'react'
import { UseFormReturn } from 'react-hook-form'
import { View } from 'react-native'
import { InquiryComplaintForm } from '../inquiry-complaint'
import { RequestInfoTransactions } from '../RequestInfoTransactions'
import { VoiceDataGridForm, VoiceRecordingScreen } from '../../container/voice-recording'

interface Props {
  detail: TaskDetail.Type | null
  subTask: SubTasksResponse
  isHistory: boolean | null
  isInquiry: boolean | null

  policyStatus: string
  positionPlayer: number
  setDynamicKeywordData?:(list: Array<VoiceDataGridForm.DynamicKeywordData>) => void
  setIsSavedDynamicKeyword?: (value: boolean) => void
}

export const VoiceRecordingRequestInfo: React.FC<Props> = (props) => {
  const { detail, subTask, isHistory, isInquiry, policyStatus, positionPlayer, setDynamicKeywordData, setIsSavedDynamicKeyword } = props

  const renderDetail = () => {
    switch (detail?.payload?.tag) {
      case TransactionType.VOICE_RECORDING:
        return (
          <VoiceRecordingScreen
            detail={detail?.payload}
            processId={detail.processId}
            isHistory={isHistory}
            isInquiry={isInquiry}
            isSuspend={SupendedTaskList.includes(subTask)}
            isQC={QCTaskList.includes(subTask)}
            policyStatus={policyStatus}
            playerPosition={positionPlayer}
            setDynamicKeywordData={setDynamicKeywordData}
            setIsSavedDynamicKeyword={setIsSavedDynamicKeyword}
          />
        )
      default:
        return null
    }
  }
  return (
    <View style={{ flex: 1 }}>
      <View style={{ flex: 1 }}>
        <View style={{ marginTop: 10 }}>{renderDetail()}</View>
      </View>
    </View>
  )
}
