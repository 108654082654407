import React from 'react'
import { SafeAreaView, ScrollView } from 'react-native'
import { useTranslation } from 'react-i18next'
import {
  AppContext,
  ErrorHandling,
  PanelProp,
  RBAC,
  SearchPanel,
  DistributionServices,
  HomePagePermissions
} from '@pulseops/common'
import { useIsFocused, useNavigation } from '@react-navigation/native'
// import { StackScreenProps } from '@react-navigation/stack'
// import { UtilitiesStackParamList } from '../utilites-stack-params-list'
import { pipe } from 'fp-ts/lib/function'

export const DistributionServiceScreen = () => {
  const { t } = useTranslation()
  const ht = useTranslation('menu').t
  const isFocused = useIsFocused()
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)

  const roles: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([]))
  const { navigate } = useNavigation()

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigate('HomeScreen')
          }
        },
        {
          title: ht('DistributionServices'),
          navigate: null
        }
      ])
    }
  }, [isFocused])

  const data: PanelProp = {
    itemsSearch: [
      {
        id: DistributionServices.ImportAvicadDistributionServices,
        name: t('utilities:ImportAVICAD'),
        navigator: () => {
          navigate('ImportAVICADScreen')
        }
      },
      {
        id: DistributionServices.ViewExportAvicadDistributionServices,
        name: t('utilities:ExportAVICAD'),
        navigator: () => {
          navigate('ExportAVICADScreen')
        }
      },
      {
        id: DistributionServices.ViewSalesStructureDistributionServices,
        name: t('Reports:SaleStructure'),
        navigator: () => {
          navigate('SaleStructureReportScreen')
        }
      },
      {
        id: DistributionServices.ViewCandidateInforDistributionServices,
        name: t('Reports:CandidateInfo'),
        navigator: () => {
          navigate('CandidateInformationScreen')
        }
      },
      {
        id: DistributionServices.ViewDSAdminDistributionServices,
        name: t('menu:DsAdmin'),
        navigator: () => {
          navigate('DsAdminScreen')
        }
      },
      {
        id: HomePagePermissions.ViewSubmissionHomepage,
        name: t('submission:AgentSubmissionForm'),
        navigator: () => {
          navigate('AgentSearchScreen')
        }
      },
      {
        id: DistributionServices.ViewAddLackDoc,
        name: t('DistributionService:AddLackDoc'),
        navigator: () => {
          navigate('DSScreen')
        }
      },
      {
        id: DistributionServices.ImportAvicadDistributionServices,
        name: t('DistributionService:ImportCert'),
        navigator: () => {
          navigate('ImportCertScreen')
        }
      },
      {
        id: DistributionServices.ViewExportAvicadDistributionServices,
        name: t('DistributionService:ExportCert'),
        navigator: () => {
          navigate('ExportCertScreen')
        }
      }
    ].filter((_) => roles.includes(_.id)),
    itemsGroup: [
      // {
      //   id: 'ReportsEform',
      //   name: t('Reports:ReportsEform'),
      //   icon: () => <assets.ReportEform />,
      //   children: [
      //     {
      //       id: ReportPermission.SubmissionReportReports,
      //       name: t('Reports:SubmissionReport'),
      //       navigator: () => {
      //         navigate('SubmissionReportScreen')
      //       }
      //     }
      //   ].filter((_) => roles.includes(_.id))
      // },
      // {
      //   id: 'ReportsBusinessPulse',
      //   name: t('Reports:ReportsBusinessPulse'),
      //   icon: () => <assets.ReportBusinessPulse />,
      //   children: [
      //     {
      //       id: Permission['BusinessPulseReports'],
      //       name: t('Reports:PolicyDataXML'),
      //       navigator: () => {
      //         navigate('PolicyDataXMLScreen')
      //       }
      //     },
      //     {
      //       id: Permission['BusinessPulseReports'],
      //       name: t('Reports:PerformanceReport'),
      //       navigator: () => {
      //         navigate('PerformanceReportScreen')
      //       }
      //     }
      //   ].filter((_) => roles.includes(_.id))
      // }
    ]
  }
  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView>{data && <SearchPanel data={data} />}</ScrollView>
    </SafeAreaView>
  )
}
