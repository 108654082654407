import {
  LinearProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core'
import TableContainer from '@material-ui/core/TableContainer'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import {
  AppContext,
  assets,
  DatePicker,
  ErrorHandling,
  form2,
  HistoricTask,
  Input,
  Permission,
  PulseOpsFormat,
  RBAC,
  Select,
  TaskOp,
  TaskPremiumOp,
  TaskService,
  TransactionLabel,
  TransactionStatusLabel,
  ServiceInquiryCategories,
  TransactionTypeSelectOptions,
  SelectSearch,
  TransactionType,
  Category,
  InquiryComplaintService,
  SelectOption,
  SourceList,
  CategoryToLabel,
  ModalComponent,
  ServiceInquiryNote,
  RadioButton,
  GeneralService,
  AuthService,
  TaskSource,
  TransactionStatus,
  CommonRequestService,
  SUCommonRequestData,
  SourceType,
  SubTaskType,
  PulseOpsService,
  TaskComment,
  TaskDSOp,
  sourceDAOptions,
  TableHeaderSort,
  useMobile,
  TaskType,
  SubmissionService,
  sourceIDCOptions,
  TaskIDCOp
} from '@pulseops/common'
import { DrawerContentComponentProps, DrawerContentOptions } from '@react-navigation/drawer'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { pipe } from 'fp-ts/function'
import { default as React, useEffect } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  Pressable,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  useWindowDimensions,
  View
} from 'react-native'
import {
  BasicSearchForm,
  TabSearch,
  PremiumCollectionForm,
  DistributionServiceInquiryForm,
  IndependenceInquiryForm
} from './basic-search-form'
import * as _ from 'lodash'
import { UploadDocumentDetail } from './upload-document-view'
import { RView } from './r-view'
import { PCReason, PartnerChannel } from '../task-detail/request-info/mock'
import { FormSearchClaim } from './form-search-claim'
import { Panel } from './comment/commentPanel'
import { DistributionServicesExportConst } from './exportDS/DistributionServicesExportConst'
import { ReportService } from '@pulseops/reports'
import moment from 'moment'

const cashLessTransactions = [
  'CASHLESS_O1',
  'CASHLESS_O2',
  'CASHLESS_O3',
  'CASHLESS_DK',
  'CASHLESS_C9',
  'CASHLESS_P2',
  'CASHLESS_V2',
  'CASHLESS_M2',
  'CASHLESS_A9',
  'CASHLESS_S6',
  'CASHLESS_L3',
  'CASHLESS_D7',
  'CASHLESS_M3',
  'CASHLESS_V4',
  'CASHLESS_A0',
  'CASHLESS_I4',
  'CASHLESS_P9',
  'CASHLESS_I2',
  'CASHLESS_I2',
  'CASHLESS_A7',
  'CASHLESS_S1',
  'CASHLESS_B3',
  'CASHLESS_P3',
  'CASHLESS_S2',
  'CASHLESS_S3',
  'CASHLESS_C3',
  'CASHLESS_U3',
  'CASHLESS_V3',
  'CASHLESS_I3',
  'CASHLESS_C7',
  'CASHLESS_X1',
  'CASHLESS_T3',
  'CASHLESS_M9',
  'CASHLESS_D6',
  'CASHLESS_D8',
  'CASHLESS_D9',
  'CASHLESS_V5'
]

const useStyles = makeStyles({
  tableHeader: {
    backgroundColor: '#E5EAEF'
  },
  tableCell: {
    fontWeight: 'bold',
    whiteSpace: 'nowrap'
  },
  cellNoWrap: {
    whiteSpace: 'nowrap'
  },
  commentCell: {
    position: 'sticky',
    right: -20,
    backgroundColor: '#fff',
    maxWidth: 120,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    height: 64,
    width: 94,
    boxShadow: '-1px 0px 4px 0px #e3dbdb',
    paddingLeft: 0
  },
  styleHeaderComment: {
    position: 'sticky',
    right: 0
  }
})

export const ServiceInquiryScreen = (props: DrawerContentComponentProps<DrawerContentOptions>) => {
  const { height } = useWindowDimensions()
  const { isWide } = useMobile()

  const classes = useStyles()

  const [tab, setTab] = React.useState<TabSearch | null>(null)
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [pageNum, setPageNum] = React.useState<number>(0)
  const [totalItem, setTotalItem] = React.useState<number>(0)
  const [isCollapse, setIsCollapse] = React.useState<boolean>(false)
  const [loading, bindLoading] = useLoading(false)
  const [infoVisible, setInfoVisible] = React.useState<boolean>(false)
  const [uploadDocVisible, setUploadDocVisible] = React.useState<boolean>(false)
  const [sortItem, setSortItem] = React.useState<{ field: string; order: 'asc' | 'desc' | undefined }>({
    field: '',
    order: 'asc'
  })
  const { t, i18n } = useTranslation()

  const [rows, setRows] = React.useState<TaskOp[]>([])
  const [messageResponse, setMessageResponse] = React.useState<string | null>(null)
  const [errorMessageForGA, setErrorMessageForGA] = React.useState<string | null>(null)
  const [officeCodes, setOfficeCodes] = React.useState<SelectOption[]>([])
  const [officeGA, setOfficeGA] = React.useState<SelectOption | null>(null)
  const [claimTypes, setClaimTypes] = React.useState<{ label: string; value: string; codes: any[] }[]>([])
  const [subServiceTypeList, setSubServiceTypeList] = React.useState<SelectOption[]>([])
  const [subTransactionTypeList, setSubTransactionTypeList] = React.useState<(SelectOption & { typeCode: string })[]>(
    []
  )
  const [noteDetail, setNoteDetail] = React.useState<ServiceInquiryNote>({
    customerComment: '',
    closedInfo: '',
    responseToCustomer: ''
  })

  const [submittedData, setSubmittedData] = React.useState<HistoricTask.Filter>({})
  const [uploadDocumentTaskId, setUploadDocumentTaskId] = React.useState<string>()
  const [commonRequests, setCommonRequests] = React.useState<SUCommonRequestData.CommonRequest[]>([])
  const [order, setOrder] = React.useState<'asc' | 'desc' | undefined>('desc')
  const [isUploadImaging, setIsUploadImaging] = React.useState<boolean>(false)

  const [showExportBtn, setShowExportBtn] = React.useState<boolean>(false)
  // const [premiumData, setPremiumData] = React.useState<TaskPremiumOp[]>([])
  const premiumRef = React.useRef<PremiumTableRef | null>(null)
  const DSRef = React.useRef<DSTableRef | null>(null)
  const [PSComment, setPSComment] = React.useState<boolean>(false)
  const [PSCommentData, setPSCommentData] = React.useState<TaskComment[]>([])
  const IDCRef = React.useRef<IDCTableRef | null>(null)
  const roles: string[] = pipe(
    RBAC.permissionWithNoCache,
    ZIO.map((permissions) => {
      setTab(getDefaultTab(permissions))
      return permissions
    }),
    ErrorHandling.runDidMount([])
  )
  const currentDate = new Date()
  const afterDate = new Date(new Date().setMonth(new Date().getMonth() - 60))

  const enum CancelPaymentStatus {
    Accept = 'ACCEPT_CANCEL',
    Reject = 'REJECT_CANCEL'
  }
  const cancelPaymentLabel = [
    {
      value: CancelPaymentStatus.Accept,
      label: 'Accept cancel'
    },
    {
      value: CancelPaymentStatus.Reject,
      label: 'Reject cancel'
    }
  ]
  const selectOptionOBAndIB = [
    { label: 'Outbound', value: 'OUTBOUND' },
    { label: 'Inbound', value: 'INBOUND' }
  ]

  const getServiceInquiry = (
    submittedData: HistoricTask.Filter,
    pageSize: number,
    pageNum: number,
    order: 'asc' | 'desc' | undefined = 'desc'
  ) => {
    pipe(
      tab === TabSearch.TraditionalClaimSearch
        ? TaskService.getHistoricTraditionalClaimTasks({
          pagination: {
            pageIndex: pageNum,
            pageSize: pageSize
          },
          order: 'desc',
          filter: submittedData
        })
        : TaskService.getHistoricTasks({
          pagination: {
            pageIndex: pageNum,
            pageSize: pageSize
          },
          order: order,
          filter: submittedData
        }),
      ZIO.tap((res) => {
        setTotalItem(res.total)
        setRows(
          res.items.map((item) => ({
            ...item,
            categoryName: convertCategoryToLabel(item.category),
            transactionTypeName: t(TransactionLabel(item.transactionType)),
            subServiceTypeName: subServiceTypeList.find((x) => x.value === item.subServiceType)?.label || '-',
            subTransactionTypeName:
              subTransactionTypeList.find((x) => x.value === item.subTransactionType)?.label || '-',
            lastUpdateByVerName: item.lastUpdateByAdj
              ? item.lastUpdateByAdj.split('@')[0]
              : item.lastUpdateByVer
                ? item.lastUpdateByVer.split('@')[0]
                : '-',
            caseId: ['PS', 'CH', 'CS'].includes(item.category) ? item.caseId.substring(0, 11) : item.caseId
          }))
        )
        setMessageResponse(res.items.length === 0 ? t('message:MS030029') : null)
        return ZIO.unit
      }),
      bindLoading,
      ErrorHandling.run()
    )
  }
  const createSortHandler = (event: any) => {
    setOrder(order === 'asc' ? 'desc' : 'asc')
    getServiceInquiry(submittedData, pageSize, pageNum, order === 'asc' ? 'desc' : 'asc')
  }
  const getServiceInquiryNote = (taskId: string) => {
    pipe(
      TaskService.getServiceInquiryNote(taskId),
      ZIO.tap((note) => {
        setNoteDetail({
          customerComment: note.customerComment,
          closedInfo: note.closedInfo,
          responseToCustomer: note.responseToCustomer
        })
        setInfoVisible(true)
        return ZIO.unit
      }),
      bindLoading,
      ErrorHandling.run()
    )
  }

  const showUploadDocument = (taskId: string, uploadImaging?: boolean) => {
    setUploadDocVisible(true)
    setUploadDocumentTaskId(taskId)
    setIsUploadImaging(uploadImaging ?? false)
  }

  const isFocused = useIsFocused()
  const { changeBreadcrumb, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)

  const defaultValues: BasicSearchForm.Raw = {
    policyNumber: '',
    policyNumberClaim: '',
    idNumber: '',
    clientNumber: '',
    phoneNumber: '',
    source: [],
    category: ServiceInquiryCategories[0],
    transactionType:
      tab === TabSearch.ClaimSearch
        ? [
          {
            label: t(`TransactionType:${TransactionType.NEW_CLAIM_REGISTER}`),
            value: TransactionType.NEW_CLAIM_REGISTER
          }
        ]
        : [],
    // fromDate: tab === TabSearch.ClaimSearch ? afterDate : currentDate,
    fromDate: currentDate,
    toDate: currentDate,
    subServiceType: [],
    subTransactionType: [],
    offices: officeGA ? [officeGA] : [],
    claimNumber: '',
    claimTypes: [],
    status: null
  }
  const { base, handleSubmit } = form2.useForm(BasicSearchForm.codec, {
    defaultValues: defaultValues
  })
  BasicSearchForm.setTab(tab)

  const checkErrorSearchForGA = () => {
    if (messageResponse) setMessageResponse(null)
    const policySearch = base.getValues('policyNumber')
    const conditionPolicy = policySearch && officeGA
    const conditionOthers = base.getValues('idNumber') || base.getValues('clientNumber')

    if ((conditionPolicy && conditionOthers) || tab !== TabSearch.BasicSearch) {
      setErrorMessageForGA(null)
      return true
    } else if (conditionPolicy) {
      return pipe(
        SubmissionService.getAgentInfo(policySearch),
        ZIO.mapError((err) => {
          // console.log(err, 'err')
          setErrorMessageForGA(t('message:MS990027'))
          return false
        }),
        ZIO.map((agentInfo) => {
          if (agentInfo && agentInfo.office === officeGA.value) {
            setErrorMessageForGA(null)
            return true
          } else {
            setErrorMessageForGA(t('message:MS990027'))
            return false
          }
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    } else if (officeGA) {
      setErrorMessageForGA(t('message:MS990029'))
      return false
    } else {
      setErrorMessageForGA(null)
      return true
    }
  }

  const search = handleSubmit((data) => {
    setPageNum(0)
    setMessageResponse(null)
    const getSubmitData = (): HistoricTask.Filter => {
      switch (tab) {
        case TabSearch.BasicSearch: {
          return {
            policyNum: data.policyNumber || undefined,
            idNumber: data.idNumber ? data.idNumber.trim() : undefined,
            clientNum: data.clientNumber || undefined,
            phoneNumber: data.phoneNumber || undefined,
            fromDate: PulseOpsFormat.serviceInquiry_StartOfDate(data.fromDate, true),
            toDate: PulseOpsFormat.serviceInquiry_endOfDate(data.toDate, true),
            source: data.source.length > 0 ? data.source.map((x) => x.value as TaskSource) : undefined,
            categories: data.category?.value
              ? [data.category.value]
              : [
                Category.COMPLAINT,
                Category.INQUIRY,
                Category.POLICY_SERVICE,
                Category.GENERAL,
                Category.PREMIUM_COLLECTION
              ],
            transactionType:
              data.transactionType.length > 0 ? data.transactionType.map((x) => x.value as TransactionType) : undefined,
            subServiceTypes: data.subServiceType.length > 0 ? data.subServiceType.map((x) => x.value) : undefined,
            subTransactionTypes:
              data.subTransactionType.length > 0 ? data.subTransactionType.map((x) => x.value) : undefined
          }
        }
        case TabSearch.AdvancedSearch: {
          return {
            fromDate: PulseOpsFormat.serviceInquiry_StartOfDate(data.fromDate, true),
            toDate: PulseOpsFormat.serviceInquiry_endOfDate(data.toDate, true),
            source: data.source.length > 0 ? data.source.map((x) => x.value as TaskSource) : undefined,
            offices: data.offices.length > 0 ? data.offices.map((x) => x.value) : undefined,
            categories: data.category?.value
              ? [data.category.value]
              : [
                Category.COMPLAINT,
                Category.INQUIRY,
                Category.POLICY_SERVICE,
                Category.GENERAL,
                Category.PREMIUM_COLLECTION
              ],
            transactionType:
              data.transactionType.length > 0 ? data.transactionType.map((x) => x.value as TransactionType) : undefined,
            subServiceTypes: data.subServiceType.length > 0 ? data.subServiceType.map((x) => x.value) : undefined,
            subTransactionTypes:
              data.subTransactionType.length > 0 ? data.subTransactionType.map((x) => x.value) : undefined
          }
        }
        case TabSearch.ClaimSearch: {
          return {
            policyNum: data.policyNumberClaim || undefined,
            claimNumber: data.claimNumber || undefined,
            clientNum: data.idNumber || undefined, // idNumber = clientNum
            fromDate: PulseOpsFormat.serviceInquiry_StartOfDate(data.fromDate, true),
            toDate: PulseOpsFormat.serviceInquiry_endOfDate(data.toDate, true),
            source: data.source.length > 0 ? data.source.map((x) => x.value as TaskSource) : undefined,
            claimTypes:
              data.claimTypes.length > 0
                ? _.flatten(data.claimTypes.map((x) => x.codes.map((_) => _.claimCode)))
                : undefined,
            categories: ['CL'],
            transactionType:
              data.transactionType.length > 0 ? data.transactionType.map((x) => x.value as TransactionType) : undefined
          }
        }
        case TabSearch.AdminClaimSearch: {
          return {
            policyNum: data.policyNumberClaim || undefined,
            claimNumber: data.claimNumber || undefined,
            clientNum: data.idNumber || undefined, // idNumber = clientNum
            fromDate: PulseOpsFormat.serviceInquiry_StartOfDate(data.fromDate, true),
            toDate: PulseOpsFormat.serviceInquiry_endOfDate(data.toDate, true),
            source: data.source.length > 0 ? data.source.map((x) => x.value as TaskSource) : undefined,
            claimTypes:
              data.claimTypes.length > 0
                ? _.flatten(data.claimTypes.map((x) => x.codes.map((_) => _.claimCode)))
                : undefined,
            categories: ['CL'],
            transactionType:
              data.transactionType.length > 0 ? data.transactionType.map((x) => x.value as TransactionType) : undefined
          }
        }
        case TabSearch.TraditionalClaimSearch: {
          return {
            policyNum: data.policyNumberClaim || undefined,
            claimNumber: data.claimNumber || undefined,
            clientNum: data.idNumber || undefined, // idNumber = clientNum
            fromDate: PulseOpsFormat.serviceInquiry_StartOfDate(data.fromDate, true),
            toDate: PulseOpsFormat.serviceInquiry_endOfDate(data.toDate, true),
            source: data.source.length > 0 ? data.source.map((x) => x.value as TaskSource) : undefined,
            claimTypes:
              data.claimTypes.length > 0
                ? _.flatten(data.claimTypes.map((x) => x.codes.map((_) => _.claimCode)))
                : undefined,
            categories: ['CL']
          }
        }
        default:
          return {}
      }
    }
    const submitData: HistoricTask.Filter = getSubmitData()
    setSubmittedData(submitData)
    getServiceInquiry(submitData, pageSize, 0)
  })

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            props.navigation.navigate('HomeScreen')
          }
        },
        {
          title: t('menu:ServiceInquiry'),
          navigate: null
        }
      ])

      // load Sub Service Type
      pipe(
        InquiryComplaintService.getTypes(),
        ZIO.map((typeList) => {
          if (typeList && typeList.length > 0) {
            setSubServiceTypeList(
              typeList.slice().map((x) => ({
                label: i18n.language === 'en' ? x.name : x.nameVi,
                value: x.code
              }))
            )
          } else {
            setSubServiceTypeList([])
          }
        }),
        ZIO.unsafeRun({})
      )
      // load sub transaction type
      pipe(
        InquiryComplaintService.getSubType(),
        ZIO.map((typeList) => {
          if (typeList && typeList.length > 0) {
            setSubTransactionTypeList(
              typeList.slice().map((x) => ({
                label: i18n.language === 'en' ? x.name : x.nameVi,
                value: x.code,
                typeCode: x.typeCode
              }))
            )
          } else {
            setSubTransactionTypeList([])
          }
        }),
        ZIO.unsafeRun({})
      )
      // load getOfficeCodes
      pipe(
        // GeneralService.getOfficeCodes('CSC'),
        GeneralService.getALLOfficeList(),
        ZIO.map((officeList) => {
          return officeList && officeList.length > 0
            ? officeList.filter((x) => ['CSC', 'PCM', 'GA'].includes(x.type))
            : []
        }),
        ZIO.zipWith(AuthService.userInfo),
        ZIO.flatMap(([codes, userInfo]) =>
          pipe(
            userInfo.officeCode ? GeneralService.getOfficeCode(userInfo.officeCode) : ZIO.succeed({ body: [] }),
            ZIO.map((officeGA) => ({ codes, userInfo, officeGA: officeGA.body }))
          )
        ),
        ZIO.map(({ codes, userInfo, officeGA }) => {
          setOfficeCodes(
            codes.map((code) => ({
              value: code.code,
              label: i18n.language === 'vi' ? code.nameVi : code.nameEn
            }))
          )
          if (userInfo.officeCode) {
            const office = officeCodes.find((code) => code.value === userInfo.officeCode)
            if (office) {
              setOfficeGA(office)
              base.setValue('offices', [office])
            } else {
              const office = {
                value: userInfo.officeCode,
                label: (i18n.language === 'en' ? officeGA[0]?.nameEn : officeGA[0]?.nameVi) || userInfo.officeCode
              }
              setOfficeCodes([office])
              setOfficeGA(office)
              base.setValue('offices', [office])
            }
          }
        }),
        ZIO.unsafeRun({})
      )
      // load getSubClaimType
      pipe(
        GeneralService.getClaims,
        ZIO.map((codes) => {
          setClaimTypes(
            _.map(_.groupBy(codes, 'claimTypeShortName'), (item, key) => ({
              value: key,
              label: i18n.language === 'en' ? item[0]?.claimType : item[0]?.claimTypeVN,
              codes: _.groupBy(codes, 'claimTypeShortName')[key]
            }))
          )
          // setClaimTypes(
          //   codes.map((code) => ({
          //     value: code.claimCode,
          //     label: i18n.language === 'en' ? code?.claimType : code?.claimTypeVN
          //   }))
          // )
        }),
        ZIO.unsafeRun({})
      )

      // load t29s1 data - Commonrequest
      pipe(
        CommonRequestService.getCommonRequestList(),
        ZIO.map((common) => {
          if (common && common.length > 0) {
            setCommonRequests(common)
          } else {
            setCommonRequests([])
          }
        }),
        ZIO.unsafeRun({})
      )
    }
    // setTab(getDefaultTab(roles)) no reset for changed page
    // setPageSize(10) no reset for changed page
    // setPageNum(0) no reset for changed page
    // setTotalItem(0) no reset for changed page
    // setIsCollapse(false) no reset for changed page
    // setInfoVisible(false) no reset for changed page
    // setRows([]) no reset for changed page
    // setMessageResponse(null) no reset for changed page
    // setClaimTypes([])
    // setSubServiceTypeList([])
    // setSubTransactionTypeList([])
    // setOfficeCodes([])
    // setOfficeCodeGA(null)
    // setNoteDetail()
    // setSubmittedData({}) no reset for changed page
    // base.reset(defaultValues) no reset for changed page
  }, [isFocused])

  React.useEffect(() => {
    setPageSize(10)
    setPageNum(0)
    setTotalItem(0)
    setIsCollapse(false)
    setInfoVisible(false)
    setRows([])
    setMessageResponse(null)
    setErrorMessageForGA(null)
    setSubmittedData({})
    setSortItem({ field: '', order: 'asc' })
    base.reset(defaultValues)
  }, [tab])

  const convertCategoryToLabel = (category: string): string => {
    const converted = CategoryToLabel(category)
    if (converted) {
      return t(`Reports:${converted}`)
    } else {
      return ''
    }
  }

  const mapRequestName = (requestCode: string) => {
    const request = commonRequests.find((c) => c.requestCode === requestCode)
    return request ? (i18n.language === 'vi' ? request.requestNameVn : request.requestNameEn) : '-'
  }

  function getDefaultTab(permissions: string[]): TabSearch | null {
    if (permissions.includes(Permission['ViewAdminSearchServiceInquiry'])) {
      return TabSearch.AdminClaimSearch
    } else if (permissions.includes(Permission['ViewTraditionalSearchServiceInquiry'])) {
      return TabSearch.TraditionalClaimSearch
    } else if (permissions.includes(Permission['ViewBasicSearchServiceInquiry'])) {
      return TabSearch.BasicSearch
    } else if (permissions.includes(Permission['ViewAdvancedSearchServiceInquiry'])) {
      return TabSearch.AdvancedSearch
    } else if (permissions.includes(Permission['ViewClaimSearchServiceInquiry'])) {
      return TabSearch.ClaimSearch
    } else if (permissions.includes(Permission['ViewPremiumCollectionSearch'])) {
      return TabSearch.PremiumCollection
    } else if (permissions.includes(Permission['ViewDistributionServicesServiceInquiry'])) {
      return TabSearch.DistributionServicesSearch
    } else if (permissions.includes(Permission['IDCFrontline']) ||
      permissions.includes(Permission['View-IndependenceCheck']) ||
      permissions.includes(Permission['ViewIndependenceCheckServiceInquiry'])) {
      return TabSearch.IndependenceCheck
    } else {
      return null
    }
  }

  const checkPressableCaseID = React.useCallback(
    (row: TaskOp) => {
      return (
        row.caseId &&
        roles.includes(Permission['ViewDetailServiceInquiry']) &&
        ((['PULSE4OPS', 'BANCA_SEA', 'LANDING_PAGE', 'PRUONLINE', 'ZALO', 'OUTBOUND', 'INBOUND'].includes(row.source) &&
          row.transactionType !== 'UPLOAD_DOCUMENT') ||
          (row.source === SourceType.PULSE &&
            ((row.policyNumber.length === 9 && row.transactionType === TransactionType.NEW_CLAIM_REGISTER) ||
              row.transactionType !== (TransactionType.UPLOAD_DOCUMENT && TransactionType.NEW_CLAIM_REGISTER))) ||
          (['EKIOSK', 'ZALO'].includes(row.source) &&
            [TransactionType.COMPLAINT, TransactionType.INQUIRY].includes(row.transactionType as TransactionType)) ||
          (['ONLINEPAYMENT', 'PRUONLINE'].includes(row.source) &&
            cashLessTransactions.includes(row.transactionType ?? '')))
      )
    },
    [roles]
  )

  const getCommentById = (processId: string) => {
    showGlobalLoading(true)
    return pipe(
      PulseOpsService.getCommentList(processId),
      ZIO.tap((comments) => {
        showGlobalLoading(false), setPSComment(true), setPSCommentData(comments ?? [])
        return ZIO.unit
      }),
      ZIO.mapError((e) => {
        showGlobalLoading(false)
        return ZIO.fail(e)
      }),
      ZIO.unsafeRun({})
    )
  }

  const showCancelPaymentText = (value: string) => {
    return cancelPaymentLabel.find((item) => item.value === value)?.label ?? '-'
  }

  return tab === null ? (
    <></>
  ) : (
    <SafeAreaView style={{ flex: 1, backgroundColor: '#FFF' }}>
      <ScrollView>
        <View style={{ flex: 1 }}>
          <View style={styles.searchContainer}>
            <View style={[styles.row, styles.titleContainer]}>
              {roles.includes(Permission['ViewAdminSearchServiceInquiry']) && (
                <View
                  style={[{ padding: 16 }, tab === TabSearch.AdminClaimSearch ? { backgroundColor: '#FAFAFA' } : {}]}
                >
                  <RadioButton
                    key="AdminClaimSearch"
                    label={t('ServiceInquiry:AdminClaimSearch')}
                    selected={tab === TabSearch.AdminClaimSearch}
                    onChange={() => setTab(TabSearch.AdminClaimSearch)}
                  />
                </View>
              )}
              {/* {roles.includes(Permission['ViewTraditionalSearchServiceInquiry']) && (
                <View
                  style={[
                    { padding: 16 },
                    tab === TabSearch.TraditionalClaimSearch ? { backgroundColor: '#FAFAFA' } : {}
                  ]}
                >
                  <RadioButton
                    key="TraditionalClaimSearch"
                    label={t('ServiceInquiry:TraditionalClaimSearch')}
                    selected={tab === TabSearch.TraditionalClaimSearch}
                    onChange={() => setTab(TabSearch.TraditionalClaimSearch)}
                  />
                </View>
              )} */}
              {roles.includes(Permission['ViewBasicSearchServiceInquiry']) && (
                <View style={[{ padding: 16 }, tab === TabSearch.BasicSearch ? { backgroundColor: '#FAFAFA' } : {}]}>
                  <RadioButton
                    key="BasicSearch"
                    label={t('ServiceInquiry:BasicSearch')}
                    selected={tab === TabSearch.BasicSearch}
                    onChange={() => setTab(TabSearch.BasicSearch)}
                  />
                </View>
              )}
              {roles.includes(Permission['ViewAdvancedSearchServiceInquiry']) && (
                <View style={[{ padding: 16 }, tab === TabSearch.AdvancedSearch ? { backgroundColor: '#FAFAFA' } : {}]}>
                  <RadioButton
                    key="AdvancedSearch"
                    label={t('ServiceInquiry:AdvancedSearch')}
                    selected={tab === TabSearch.AdvancedSearch}
                    onChange={() => setTab(TabSearch.AdvancedSearch)}
                  />
                </View>
              )}
              {roles.includes(Permission['ViewClaimSearchServiceInquiry']) && (
                <View style={[{ padding: 16 }, tab === TabSearch.ClaimSearch ? { backgroundColor: '#FAFAFA' } : {}]}>
                  <RadioButton
                    key="ClaimSearch"
                    label={t('ServiceInquiry:ClaimSearch')}
                    selected={tab === TabSearch.ClaimSearch}
                    onChange={() => setTab(TabSearch.ClaimSearch)}
                  />
                </View>
              )}
              {roles.includes(Permission['ViewPremiumCollectionSearch']) && (
                <View
                  style={{ padding: 16, backgroundColor: tab === TabSearch.PremiumCollection ? '#FAFAFA' : undefined }}
                >
                  <RadioButton
                    key={'ServiceInquiry'}
                    label={t('ServiceInquiry:PremiumCollectionSearch')}
                    selected={tab === TabSearch.PremiumCollection}
                    onChange={() => setTab(TabSearch.PremiumCollection)}
                  />
                </View>
              )}
              {roles.includes(Permission['ViewDistributionServicesServiceInquiry']) && (
                <View
                  style={{
                    padding: 16,
                    backgroundColor: tab === TabSearch.DistributionServicesSearch ? '#FAFAFA' : undefined
                  }}
                >
                  <RadioButton
                    key={'ServiceInquiry'}
                    label={t('ServiceInquiry:DistributionService')}
                    selected={tab === TabSearch.DistributionServicesSearch}
                    onChange={() => setTab(TabSearch.DistributionServicesSearch)}
                  />
                </View>
              )}
              {(roles.includes(Permission['View-IndependenceCheck']) ||
                roles.includes(Permission['IDC-Query']) ||
                roles.includes(Permission['ViewIndependenceCheckServiceInquiry'])) && (
                  <View
                    style={{
                      padding: 16,
                      backgroundColor: tab === TabSearch.IndependenceCheck ? '#FAFAFA' : undefined
                    }}
                  >
                    <RadioButton
                      key={'ServiceInquiry'}
                      label={t('ServiceInquiry:IndependenceCheck')}
                      selected={tab === TabSearch.IndependenceCheck}
                      onChange={() => setTab(TabSearch.IndependenceCheck)}
                    />
                  </View>
                )}
            </View>
            {tab === TabSearch.PremiumCollection ? (
              <PremiumCollectionSearch
                onSearch={(data) => {
                  const submitData: HistoricTask.Filter = {
                    policyNum: data.policyNumber ?? undefined,

                    clientNum: data.clientNumber ?? undefined, // idNumber = clientNum
                    fromDate: data.fromDate
                      ? PulseOpsFormat.serviceInquiry_StartOfDate(data.fromDate, true)
                      : undefined,
                    toDate: data.toDate ? PulseOpsFormat.serviceInquiry_endOfDate(data.toDate, true) : undefined,
                    amount: data.amount ?? undefined,
                    bankCode: data.bankCode?.value ?? undefined,
                    referenceNumber: data.referenceNumber ?? undefined,
                    proposalNumber: data.proposalNumber ?? undefined,
                    categories: ['PC']
                  }
                  // setSubmittedData(submitData)
                  // getPremiumCollectionTask(submitData, pageSize, 0)
                  premiumRef.current?.onSearch(submitData)
                }}
              />
            ) : tab === TabSearch.DistributionServicesSearch ? (
              <DistributionServiceInquirySearch
                onSearch={(data) => {
                  const submitData: HistoricTask.Filter = {
                    eref: data.eRef ?? undefined,
                    agentCode: data.agentCode ?? undefined,
                    clientNum: data.IDnumber ? data.IDnumber.trim() : undefined,
                    phoneNumber: data.phoneNumber || undefined,
                    fromDate: data.fromDate ?? undefined,
                    toDate: data.toDate ?? undefined,
                    categories: ['DS'],
                    source: !!data?.source ? data.source.map((x) => x.value as TaskSource) : undefined,
                    transactionType: !!data?.transactionType
                      ? data.transactionType.map((x) => x.value as TransactionType)
                      : undefined
                  }
                  DSRef.current?.onSearch?.(submitData)
                }}
                onExport={() => {
                  DSRef.current?.onExport?.()
                }}
                isShowBtnExport={showExportBtn}
              />
            ) : tab === TabSearch.IndependenceCheck ? (
              <>
                <IndependenceCheckInquirySearch
                  onSearch={(data) => {
                    const submitData: HistoricTask.Filter = {
                      proposalNumber: data.ProposalNumber ?? undefined,
                      // clientNum: data.IDnumber ? data.IDnumber.trim() : undefined,
                      fromDate: data.fromDate ?? undefined,
                      toDate: data.toDate ?? undefined,
                      // categories: ['IDC'],
                      source: !!data?.source ? data.source.map((x) => x.value as TaskSource) : undefined,
                      // transactionType: !!data?.transactionType
                      //   ? data.transactionType.map((x) => x.value as TransactionType)
                      //   : undefined,
                      transactionType: [data.transactionType?.value as TransactionType],
                      policyNum: data.PolicyNumber ?? undefined,
                      idNumber: data.IDnumber ? data.IDnumber.trim() : undefined,
                      category: 'IDC',
                      taxNumber: data.transactionType?.value === TransactionType.VOICE_RECORDING && data.taxNumber ? data.taxNumber : undefined,
                      agentCertificate: data.transactionType?.value === TransactionType.VOICE_RECORDING && data.agentLicense ? data.agentLicense : undefined,
                      signDateCompany: data.transactionType?.value === TransactionType.VOICE_RECORDING && data.signDateCompany ? data.signDateCompany : undefined
                    }
                    IDCRef.current?.onSearch?.(submitData)
                  }}
                  onExport={() => {
                    IDCRef.current?.onExport?.()
                  }}
                  isShowBtnExport={showExportBtn}
                />
              </>
            ) : (
              <>
                {/* Policy Number, Client Number */}
                {[TabSearch.BasicSearch, TabSearch.ClaimSearch].includes(tab) && (
                  <View style={styles.row}>
                    {tab === TabSearch.BasicSearch && (
                      <View style={styles.col}>
                        <Controller
                          name="policyNumber"
                          control={base.control}
                          render={({ field: { onChange, value } }) => (
                            <Input
                              title={t('ServiceInquiry:PolicyNumber')}
                              value={value ?? undefined}
                              maxLength={8}
                              inputType="number"
                              onChange={onChange}
                              placeholder={t('ServiceInquiry:EnterPolicy')}
                              errorMessage={base.formState.errors.policyNumber?.message}
                            />
                          )}
                        />
                      </View>
                    )}
                    {tab === TabSearch.ClaimSearch && (
                      <View style={styles.col}>
                        <Controller
                          name="policyNumberClaim"
                          control={base.control}
                          render={({ field: { onChange, value } }) => (
                            <Input
                              title={t('ServiceInquiry:PolicyNumber')}
                              value={value ?? undefined}
                              maxLength={9}
                              inputType="number"
                              onChange={onChange}
                              placeholder={t('ServiceInquiry:EnterPolicy')}
                              errorMessage={base.formState.errors.policyNumberClaim?.message}
                            />
                          )}
                        />
                      </View>
                    )}
                    {tab === TabSearch.BasicSearch && (
                      <View style={styles.col}>
                        <Controller
                          name="clientNumber"
                          control={base.control}
                          render={({ field: { onChange, value } }) => (
                            <Input
                              title={t('ServiceInquiry:ClientNumber')}
                              value={value ?? undefined}
                              maxLength={8}
                              inputType="number"
                              onChange={onChange}
                              placeholder={t('ServiceInquiry:EnterClientNumber')}
                              errorMessage={base.formState.errors.clientNumber?.message}
                            />
                          )}
                        />
                      </View>
                    )}
                    {tab === TabSearch.ClaimSearch && (
                      <View style={styles.col}>
                        <Controller
                          name="claimNumber"
                          control={base.control}
                          render={({ field: { onChange, value } }) => (
                            <Input
                              title={t('ServiceInquiry:ClaimNumber')}
                              value={value ?? undefined}
                              maxLength={11}
                              onChange={onChange}
                              placeholder={t('ServiceInquiry:EnterClaimNumber')}
                              errorMessage={base.formState.errors.claimNumber?.message}
                            />
                          )}
                        />
                      </View>
                    )}
                    <View style={styles.col}>
                      <Controller
                        name="idNumber"
                        control={base.control}
                        render={({ field: { onChange, value } }) => (
                          <Input
                            title={t('ServiceInquiry:IDNumber')}
                            value={value ?? undefined}
                            maxLength={24}
                            onChange={onChange}
                            placeholder={t('ServiceInquiry:EnterIDNumber')}
                            errorMessage={base.formState.errors.idNumber?.message}
                          />
                        )}
                      />
                    </View>
                  </View>
                )}
                {[TabSearch.AdminClaimSearch, TabSearch.TraditionalClaimSearch].includes(tab) && (
                  <FormSearchClaim form={base} />
                )}
                {/* From Date, To Date */}
                <View style={styles.row}>
                  {/* {tab === TabSearch.BasicSearch && (
                    <View style={styles.col}>
                      <Controller
                        name="phoneNumber"
                        control={base.control}
                        render={({ field: { onChange, value } }) => (
                          <Input
                            title={t('ServiceInquiry:PhoneNumber')}
                            value={value ?? undefined}
                            maxLength={10}
                            inputType="number"
                            onChange={onChange}
                            errorMessage={base.formState.errors.phoneNumber?.message}
                            alwayShowUnderline={true}
                          />
                        )}
                      />
                    </View>
                  )} */}
                  <View style={styles.col}>
                    <Controller
                      name="fromDate"
                      control={base.control}
                      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                        <DatePicker
                          required
                          label={t('ServiceInquiry:FromDate')}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          maxDate={currentDate}
                          maxDateMessage={t('message:MS990032')}
                          errorMessage={error?.message}
                        />
                      )}
                    />
                  </View>
                  <View style={styles.col}>
                    <Controller
                      name="toDate"
                      control={base.control}
                      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                        <DatePicker
                          required
                          label={t('ServiceInquiry:ToDate')}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          maxDate={currentDate}
                          maxDateMessage={t('message:MS990032')}
                          errorMessage={error?.message}
                        />
                      )}
                    />
                  </View>
                  {[TabSearch.AdminClaimSearch].includes(tab) && (
                    <View style={[styles.col]}>
                      <Controller
                        name="transactionType"
                        control={base.control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <SelectSearch
                            label={t('ServiceInquiry:TransactionType')}
                            options={[
                              {
                                label: t(`TransactionType:${TransactionType.NEW_CLAIM_REGISTER}`),
                                value: TransactionType.NEW_CLAIM_REGISTER
                              },
                              {
                                label: t(`TransactionType:${TransactionType.PAYOUT}`),
                                value: TransactionType.PAYOUT
                              }
                            ]}
                            placeholder={t('common:Select')}
                            onChange={(v) => {
                              onChange(_.uniqBy(v, (e) => e.value))
                            }}
                            onBlur={onBlur}
                            value={value}
                            multiple={true}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                          />
                        )}
                      />
                    </View>
                  )}
                  {tab === TabSearch.AdvancedSearch && (
                    <View style={styles.col}>
                      <Controller
                        name="offices"
                        control={base.control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <SelectSearch
                            label={t('ServiceInquiry:Office')}
                            options={officeCodes}
                            buildLabel={(select) => `${select.value} - ${select.label}`}
                            placeholder={officeGA ? '' : t('common:Select')}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            multiple={true}
                            disabled={officeGA !== null}
                            showPopupIcon={officeGA === null}
                          />
                        )}
                      />
                    </View>
                  )}
                  {tab === TabSearch.ClaimSearch && (
                    <View style={styles.col}>
                      <Controller
                        name="claimTypes"
                        control={base.control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <SelectSearch
                            label={t('ServiceInquiry:ClaimType')}
                            options={claimTypes}
                            placeholder={t('common:Select')}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            multiple={true}
                          />
                        )}
                      />
                    </View>
                  )}
                </View>
                {/* Category */}
                <View style={[styles.row, isCollapse && { display: 'none' }]}>
                  <View style={styles.col}>
                    <Controller
                      name="source"
                      control={base.control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <SelectSearch
                          label={t('ServiceInquiry:Source')}
                          options={SourceList.concat(selectOptionOBAndIB)}
                          placeholder={t('common:Select')}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          multiple={true}
                        />
                      )}
                    />
                  </View>
                  {[TabSearch.ClaimSearch].includes(tab) && (
                    <View style={[styles.col]}>
                      <Controller
                        name="transactionType"
                        control={base.control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <SelectSearch
                            label={t('ServiceInquiry:TransactionType')}
                            options={[
                              {
                                label: t(`TransactionType:${TransactionType.NEW_CLAIM_REGISTER}`),
                                value: TransactionType.NEW_CLAIM_REGISTER
                              },
                              {
                                label: t(`TransactionType:${TransactionType.PAYOUT}`),
                                value: TransactionType.PAYOUT
                              }
                            ]}
                            placeholder={t('common:Select')}
                            onChange={(v) => {
                              onChange(_.uniqBy(v, (e) => e.value))
                            }}
                            onBlur={onBlur}
                            value={value}
                            multiple={true}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                          />
                        )}
                      />
                    </View>
                  )}
                  {(tab === TabSearch.AdminClaimSearch || tab === TabSearch.TraditionalClaimSearch) && (
                    <View style={styles.col}>
                      <Controller
                        name="claimTypes"
                        control={base.control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <SelectSearch
                            label={t('ServiceInquiry:ClaimType')}
                            options={claimTypes}
                            placeholder={t('common:Select')}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            multiple={true}
                          />
                        )}
                      />
                    </View>
                  )}
                  {(tab === TabSearch.BasicSearch || tab === TabSearch.AdvancedSearch) && (
                    <View style={styles.col}>
                      <Controller
                        name="category"
                        control={base.control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Select
                            label={t('ServiceInquiry:Category')}
                            options={ServiceInquiryCategories.map((item) => ({
                              label: t(`Reports:${item.label}`),
                              value: item.value
                            }))}
                            placeholder={t('common:Select')}
                            onChange={(e) => {
                              onChange(e)
                              base.setValue('transactionType', [])
                              base.setValue('subServiceType', [])
                              base.setValue('subTransactionType', [])
                            }}
                            onBlur={onBlur}
                            value={value}
                          />
                        )}
                      />
                    </View>
                  )}
                  {tab !== TabSearch.ClaimSearch && (
                    <View style={[styles.col, !base.watch('category')?.value && { display: 'none' }]}>
                      <Controller
                        name="transactionType"
                        control={base.control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <SelectSearch
                            label={t('ServiceInquiry:TransactionType')}
                            options={TransactionTypeSelectOptions.filter(
                              (x) => x.category === base.watch('category')?.value
                            ).map((item) => ({
                              label: t(item.label),
                              value: item.value
                            }))}
                            placeholder={t('common:Select')}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            multiple={true}
                          />
                        )}
                      />
                    </View>
                  )}
                </View>
                {/* Sub Service, Sub Transaction */}
                {tab !== TabSearch.ClaimSearch && (
                  <View
                    style={[
                      styles.row,
                      (isCollapse ||
                        ![Category.INQUIRY, Category.COMPLAINT].includes(
                          base.watch('category')?.value as Category
                        )) && {
                        display: 'none'
                      }
                    ]}
                  >
                    <View style={styles.col}>
                      <Controller
                        name="subServiceType"
                        control={base.control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <SelectSearch
                            label={t('ServiceInquiry:SubServiceType')}
                            options={subServiceTypeList}
                            placeholder={t('common:Select')}
                            onChange={(e) => {
                              onChange(e)
                              base.setValue('subTransactionType', [])
                            }}
                            onBlur={onBlur}
                            value={value}
                            multiple={true}
                          />
                        )}
                      />
                    </View>
                    <View style={[styles.col, !base.watch('subServiceType').length && { display: 'none' }]}>
                      <Controller
                        name="subTransactionType"
                        control={base.control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <SelectSearch
                            label={t('ServiceInquiry:SubTransactionType')}
                            options={subTransactionTypeList.filter((x) =>
                              base
                                .watch('subServiceType')
                                .map((_) => _.value)
                                .includes(x.typeCode)
                            )}
                            placeholder={t('common:Select')}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            multiple={true}
                          />
                        )}
                      />
                    </View>
                  </View>
                )}
                <View style={[styles.row, { alignItems: 'center' }]}>
                  <TouchableOpacity
                    style={{ width: 118, height: 40 }}
                    onPress={async () => {
                      if (await checkErrorSearchForGA()) search()
                    }}
                  >
                    <View style={styles.btn}>
                      <Text style={{ textAlign: 'center', color: '#fff' }}>{t('common:Search')}</Text>
                    </View>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{ width: 118, height: 40, marginLeft: 30, flexDirection: 'row', alignItems: 'center' }}
                    onPress={() => setIsCollapse((prev) => !prev)}
                  >
                    <Text style={{ textAlign: 'center', lineHeight: 40, fontSize: 16 }}>
                      {t(isCollapse ? 'common:Expand' : 'common:Collapse')}
                    </Text>
                    <Text style={{ marginLeft: 3 }}>
                      {isCollapse ? <assets.ExpandIcon /> : <assets.CollapseIcon />}
                    </Text>
                  </TouchableOpacity>
                </View>
                <View style={[styles.row, { marginTop: 6 }]}>
                  <Text style={{ color: '#ED1B2E' }}>
                    {_.get(base.formState.errors, '.message') || errorMessageForGA}
                  </Text>
                </View>
              </>
            )}
          </View>
          {loading && <LinearProgress color="secondary" />}

          {tab === TabSearch.PremiumCollection ? (
            <PremiumTable ref={premiumRef} />
          ) : tab === TabSearch.DistributionServicesSearch ? (
            <DSTable ref={DSRef} onShowBtnExport={setShowExportBtn} />
          ) : tab === TabSearch.IndependenceCheck ? (
            <IDCTable ref={IDCRef} onShowBtnExport={setShowExportBtn} />
          ) : rows.length > 0 ? (
            <View style={[styles.section, { marginTop: 10 }]}>
              {(tab === TabSearch.ClaimSearch || tab === TabSearch.AdminClaimSearch) && (
                <TableContainer component={Paper} style={{ maxHeight: 500 }} elevation={1}>
                  <Table style={{ userSelect: 'text' }} stickyHeader>
                    <TableHead className={classes.tableHeader}>
                      <TableRow>
                        <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:CaseID')}</TableCell>

                        <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:ClaimNumber')}</TableCell>
                        <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:ClaimType')}</TableCell>

                        <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:Status')}</TableCell>
                        <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:ClaimJourney')}</TableCell>
                        <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:CurrentActivity')}</TableCell>
                        {tab === TabSearch.AdminClaimSearch && (
                          <>
                            <TableCell className={classes.tableCell}>
                              {t('ServiceInquiry:table:LastAssignee')}
                            </TableCell>
                          </>
                        )}
                        <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:PolicyNumber')}</TableCell>
                        <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:POClient')}</TableCell>
                        {tab === TabSearch.AdminClaimSearch && (
                          <>
                            <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:POID')}</TableCell>
                            <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:POName')}</TableCell>
                            <TableCell className={classes.tableCell}>
                              {t('ServiceInquiry:table:POPhoneNumber')}
                            </TableCell>
                            <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:Service')}</TableCell>
                            <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:Group')}</TableCell>
                            <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:SubService')}</TableCell>
                            <TableCell className={classes.tableCell}>
                              {t('ServiceInquiry:table:SubTransaction')}
                            </TableCell>
                          </>
                        )}
                        <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:LAName')}</TableCell>
                        <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:CreatedDate')}</TableCell>
                        <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:UserSubmission')}</TableCell>
                        <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:LastUpdatedDate')}</TableCell>
                        {tab === TabSearch.AdminClaimSearch && (
                          <>
                            <TableCell className={classes.tableCell}>
                              {t('ServiceInquiry:table:LastUpdatedBy')}
                            </TableCell>
                          </>
                        )}
                        <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:Aging')}</TableCell>
                        <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:Category')}</TableCell>
                        <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:Transaction')}</TableCell>
                        <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:Source')}</TableCell>
                        <TableCell className={classes.tableCell} style={{ textAlign: 'center' }}>
                          {t('ServiceInquiry:table:Note')}
                        </TableCell>

                        {/* <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:POID')}</TableCell> */}
                        {/* <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:Service')}</TableCell> */}
                        {/* <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:Group')}</TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row, i) => (
                        <TableRow key={`TableRow-${i}`}>
                          {/* CaseID */}
                          <TableCell>
                            {/* {row.caseId &&
                            roles.includes(Permission['ViewDetailServiceInquiry']) &&
                            ((['PULSE4OPS', 'BANCA_SEA'].includes(row.source) &&
                              row.transactionType !== 'UPLOAD_DOCUMENT') ||
                              (['EKIOSK', 'ZALO'].includes(row.source) && row.transactionType === 'COMPLAINT') ||
                              (row.source === 'LANDING_PAGE' && row.transactionType === 'SPECIAL_BONUS_CUSTOMER') ||
                              row.source === 'ONLINEPAYMENT')  */}
                            {row.caseId &&
                              roles.includes(Permission['ViewDetailServiceInquiry']) &&
                              ((['PULSE4OPS', 'BANCA_SEA', 'PRUONLINE'].includes(row.source) &&
                                row.transactionType !== 'UPLOAD_DOCUMENT') ||
                                (row.source === SourceType.PULSE &&
                                  ((row.policyNumber.length === 9 &&
                                    row.transactionType === TransactionType.NEW_CLAIM_REGISTER) ||
                                    row.transactionType !==
                                    (TransactionType.UPLOAD_DOCUMENT && TransactionType.NEW_CLAIM_REGISTER))) ||
                                (['EKIOSK', 'ZALO'].includes(row.source) && row.transactionType === 'COMPLAINT') ||
                                (row.source === 'LANDING_PAGE' && row.transactionType === 'SPECIAL_BONUS_CUSTOMER') ||
                                row.source === 'ONLINEPAYMENT') ? (
                              <Pressable
                                onPress={() =>
                                  props.navigation.navigate('TaskDetailStack', {
                                    screen: 'TaskDetailScreen',
                                    params: {
                                      id: row.id,
                                      caterogy: row.category,
                                      basket: '',
                                      basketName:
                                        row.transactionType === TransactionType.PAYOUT
                                          ? 'CL-PV'
                                          : row.status === TransactionStatus.IN_PROGRESS &&
                                            row.activity === '6. Verification'
                                            ? 'CL-VER'
                                            : 'CL-ADJ',
                                      isInquiry: '1',
                                      isBreadcrumb: '1',
                                      isHistory: 'true'
                                    }
                                  })
                                }
                              >
                                <Text style={[{ color: '#ed1b2e' }, styles.cellNoWrap]}>{row.caseId}</Text>
                              </Pressable>
                            ) : (
                              <Text style={styles.cellNoWrap}>{row.caseId || '-'}</Text>
                            )}
                          </TableCell>
                          {/* ClaimNumber */}
                          <TableCell>{row.claimNumber || '-'}</TableCell>
                          {/* ClaimType */}
                          <TableCell className={classes.cellNoWrap}>
                            {claimTypes.find((x) => x.codes.find((_) => _.claimCode === row.claimType))?.label || '-'}
                          </TableCell>
                          {/* Status */}
                          <TableCell
                            style={{ color: TransactionStatusLabel(row.status).color }}
                            className={classes.cellNoWrap}
                          >
                            {TransactionStatusLabel(row.status).label}
                          </TableCell>
                          <TableCell>
                            {row.transactionType === TransactionType.NEW_CLAIM_REGISTER ? (
                              <Pressable
                                onPress={() =>
                                  props.navigation.navigate('TaskDetailStack', {
                                    screen: 'TrackingStatusClaimScreen',
                                    params: {
                                      id: row.id,
                                      sourceOfBusiness: row.policyNumber.length === 9 ? 'D' : 'T',
                                      LAName: row.lifeAssuredName,
                                      claimNumber: row.claimNumber,
                                      policyNumber: row.policyNumber
                                    }
                                  })
                                }
                              >
                                <Text style={[{ color: '#1DA5FC' }, styles.cellNoWrap]}>
                                  {t('ServiceInquiry:table:Detail')}
                                </Text>
                              </Pressable>
                            ) : (
                              '-'
                            )}
                          </TableCell>
                          {/* CurrentActivity */}
                          <TableCell>{row.activity || '-'}</TableCell>
                          {tab === TabSearch.AdminClaimSearch && (
                            <>
                              {/* LastAssignee */}
                              <TableCell>
                                <Text>{row.assigneeName || '-'}</Text>
                                {row.assignee && row.assignee !== '-' && (
                                  <View>
                                    <Text>({row.assignee.split('@')[0]})</Text>
                                  </View>
                                )}
                              </TableCell>
                            </>
                          )}
                          {/* PolicyNumber */}
                          <TableCell>{row.policyNumber || '-'}</TableCell>
                          {/* POClient */}
                          <TableCell>{row.clientNumber || '-'}</TableCell>
                          {tab === TabSearch.AdminClaimSearch && (
                            <>
                              {/* POID */}
                              <TableCell>{row.poClientNumber || '-'}</TableCell>
                              {/* POName */}
                              <TableCell>{row.poName || '-'}</TableCell>
                              {/* POPhoneNumber */}
                              <TableCell>{row.poPhoneNumber || '-'}</TableCell>
                              {/* Service */}
                              <TableCell>{'-'}</TableCell>
                              {/* Group */}
                              <TableCell>{'-'}</TableCell>
                              {/* SubService */}
                              <TableCell>
                                {subServiceTypeList.find((x) => x.value === row.subServiceType)?.label || '-'}
                              </TableCell>
                              {/* SubTransaction */}
                              <TableCell>
                                {subTransactionTypeList.find((x) => x.value === row.subTransactionType)?.label || '-'}
                              </TableCell>
                            </>
                          )}
                          {/* LAName */}
                          <TableCell className={classes.cellNoWrap}>{row.lifeAssuredName || '-'}</TableCell>
                          {/* CreatedDate */}
                          <TableCell>{PulseOpsFormat.datetoFormat(row.createdDate, 'DD/MM/YYYY HH:mm:ss')}</TableCell>
                          {/* UserSubmission */}
                          <TableCell>{row.createdBy ? row.createdBy.split('@')[0] : '-'}</TableCell>
                          {/* LastUpdatedDate */}
                          <TableCell>
                            {row.lastUpdateDateAdj
                              ? PulseOpsFormat.datetoFormat(row.lastUpdateDateAdj, 'DD/MM/YYYY HH:mm:ss')
                              : row.lastUpdateDateVer
                                ? PulseOpsFormat.datetoFormat(row.lastUpdateDateVer, 'DD/MM/YYYY HH:mm:ss')
                                : '-'}
                          </TableCell>
                          {tab === TabSearch.AdminClaimSearch && (
                            <>
                              {/* LastUpdatedBy */}
                              <TableCell>
                                {row.lastUpdateByAdj
                                  ? row.lastUpdateByAdj.split('@')[0]
                                  : row.lastUpdateByVer
                                    ? row.lastUpdateByVer.split('@')[0]
                                    : '-'}
                              </TableCell>
                            </>
                          )}
                          {/* Aging */}
                          <TableCell>{row.aging}</TableCell>
                          {/* Category */}
                          <TableCell className={classes.cellNoWrap}>
                            {convertCategoryToLabel(row.category) || '-'}
                          </TableCell>
                          {/* TransactionType */}
                          <TableCell className={classes.cellNoWrap}>
                            {t(TransactionLabel(row.transactionType))}
                          </TableCell>
                          {/* Source */}
                          <TableCell>{row.source}</TableCell>
                          {/* Note */}
                          <TableCell>
                            {roles.includes(Permission['ViewNoteServiceInquiry']) &&
                              [Category.COMPLAINT, Category.INQUIRY].includes(row.category as Category) ? (
                              <TouchableOpacity onPress={() => getServiceInquiryNote(row.id)}>
                                <View style={styles.btnDetail}>
                                  <Text style={{ textAlign: 'center', color: '#0000ee' }}>{t('common:Detail')}</Text>
                                </View>
                              </TouchableOpacity>
                            ) : row.transactionType === TransactionType.UPLOAD_DOCUMENT ? (
                              <TouchableOpacity onPress={() => showUploadDocument(row.id)}>
                                <View style={styles.btnDetail}>
                                  <Text style={{ textAlign: 'center', color: '#0000ee' }}>{t('common:Detail')}</Text>
                                </View>
                              </TouchableOpacity>
                            ) : (
                              <Text> {'-'}</Text>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow></TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              {![TabSearch.ClaimSearch, TabSearch.AdminClaimSearch].includes(tab) && (
                <TableContainer component={Paper} style={{ maxHeight: 500 }} elevation={1}>
                  <Table style={{ userSelect: 'text' }} stickyHeader>
                    <TableHead className={classes.tableHeader}>
                      <TableHeaderSort
                        data={rows}
                        setData={setRows}
                        sortItem={sortItem}
                        setSortItem={setSortItem}
                        column={[
                          {
                            label: t('ServiceInquiry:table:CaseID'),
                            disabled: true,
                            field: 'caseId'
                          },
                          {
                            label: t('ServiceInquiry:table:Source'),
                            field: 'source'
                          },
                          {
                            label: t('ServiceInquiry:table:PolicyNumber'),
                            field: 'policyNumber'
                          },
                          {
                            label: t('ServiceInquiry:table:POClient'),
                            field: 'clientNumber'
                          },
                          {
                            label: t('ServiceInquiry:table:POID'),
                            field: 'poClientNumber'
                          },
                          {
                            label: t('ServiceInquiry:table:POName'),
                            field: 'poName'
                          },
                          {
                            label: t('ServiceInquiry:table:POPhoneNumber'),
                            field: 'poPhoneNumber'
                          },
                          {
                            label: t('ServiceInquiry:table:Category'),
                            field: 'categoryName'
                          },
                          {
                            label: t('ServiceInquiry:table:Service'),
                            field: 'service',
                            disabled: true
                          },
                          {
                            label: t('ServiceInquiry:table:Transaction'),
                            field: 'transactionTypeName'
                          },
                          {
                            label: t('ServiceInquiry:table:Group'),
                            field: 'group',
                            disabled: true
                          },
                          {
                            label: t('ServiceInquiry:table:SubService'),
                            field: 'subServiceTypeName'
                          },
                          {
                            label: t('ServiceInquiry:table:SubTransaction'),
                            field: 'subTransactionTypeName'
                          },
                          {
                            label: t('ServiceInquiry:table:UserSubmission'),
                            field: 'createdBy'
                          },
                          {
                            label: t('ServiceInquiry:table:CreatedDate'),
                            field: 'createdDate'
                          },
                          {
                            label: t('ServiceInquiry:table:LastAssignee'),
                            field: 'assigneeName'
                          },
                          {
                            label: t('ServiceInquiry:table:Aging'),
                            field: 'aging'
                          },
                          {
                            label: t('ServiceInquiry:table:LastUpdatedDate'),
                            field: 'lastUpdateDateVer'
                          },
                          {
                            label: t('ServiceInquiry:table:LastUpdatedBy'),
                            field: 'lastUpdateByVerName'
                          },
                          {
                            label: t('ServiceInquiry:table:CancelPaymentStatus'),
                            field: 'cancelPaymentStatus'
                          },
                          {
                            label: t('ServiceInquiry:table:Status'),
                            field: 'status'
                          },
                          {
                            label: t('ServiceInquiry:table:CurrentActivity'),
                            field: 'activity'
                          },
                          {
                            label: t('ServiceInquiry:table:BatchName'),
                            field: 'batchName'
                          },
                          {
                            label: t('ServiceInquiry:table:Note'),
                            field: 'note',
                            disabled: true
                          },
                          {
                            label: t('ServiceInquiry:table:CommentView'),
                            field: 'comment'
                            // visible: !roles.includes(Permission.ViewCommentServiceInquiry)
                          }
                        ]}
                      />
                      {/* <TableRow>
                        <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:CaseID')}</TableCell>
                        <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:Source')}</TableCell>
                        <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:PolicyNumber')}</TableCell>
                        <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:POClient')}</TableCell>
                        <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:POID')}</TableCell>
                        <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:POName')}</TableCell>
                        <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:POPhoneNumber')}</TableCell>
                        <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:Category')}</TableCell>
                        <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:Service')}</TableCell>
                        <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:Transaction')}</TableCell>
                        <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:Group')}</TableCell>
                        <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:SubService')}</TableCell>
                        <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:SubTransaction')}</TableCell>
                        <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:UserSubmission')}</TableCell>
                        <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:CreatedDate')}</TableCell>
                        <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:LastAssignee')}</TableCell>
                        <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:Aging')}</TableCell>
                        <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:LastUpdatedDate')}</TableCell>
                        <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:LastUpdatedBy')}</TableCell>
                        <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:Status')}</TableCell>
                        <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:CurrentActivity')}</TableCell>
                        <TableCell className={classes.tableCell} style={{ textAlign: 'center' }}>
                          {t('ServiceInquiry:table:Note')}
                        </TableCell>
                      </TableRow> */}
                    </TableHead>
                    <TableBody>
                      {rows.map((row, i) => (
                        <TableRow
                          key={`TableRow-${i}`}
                          style={{
                            backgroundColor:
                              row.status === TransactionStatus.PENDING && row.suspendReasonPC === 'WRITE_OFF'
                                ? '#ed1b2e26'
                                : row.status === TransactionStatus.PENDING &&
                                  row.suspendReasonPC === 'AML_FISERV_REDFLAG'
                                  ? '#b0e0ff'
                                  : '#fff'
                          }}
                        >
                          {/* CaseID */}
                          <TableCell>
                            {/* {row.caseId &&
                            roles.includes(Permission['ViewDetailServiceInquiry']) &&
                            ((['PULSE4OPS', 'PULSE', 'BANCA_SEA'].includes(row.source) &&
                              row.transactionType !== 'UPLOAD_DOCUMENT') ||
                              (['EKIOSK', 'ZALO'].includes(row.source) && row.transactionType === 'COMPLAINT') ||
                              (['ONLINEPAYMENT', 'PRUONLINE'].includes(row.source) &&
                                ['CASHLESS_O1', 'CASHLESS_O2', 'CASHLESS_O3'].includes(row.transactionType ?? '')))  */}
                            {row.caseId &&
                              roles.includes(Permission['ViewDetailServiceInquiry']) &&
                              ((['PULSE4OPS', 'PULSE', 'BANCA_SEA', 'LANDING_PAGE', 'PRUONLINE', 'ZALO', 'OUTBOUND', 'INBOUND'].includes(
                                row.source
                              ) &&
                                row.transactionType !== 'UPLOAD_DOCUMENT' &&
                                row.transactionType !== 'IMAGING_UPLOAD') ||
                                (row.source === SourceType.PULSE &&
                                  ((row.policyNumber.length === 9 &&
                                    row.transactionType === TransactionType.NEW_CLAIM_REGISTER) ||
                                    row.transactionType !==
                                    (TransactionType.UPLOAD_DOCUMENT && TransactionType.NEW_CLAIM_REGISTER))) ||
                                (['EKIOSK', 'ZALO'].includes(row.source) &&
                                  row?.transactionType &&
                                  [
                                    TransactionType.COMPLAINT,
                                    TransactionType.INQUIRY,
                                    TransactionType.CHANGE_NATIONAL_ID_CARD
                                  ].includes(row.transactionType)) ||
                                ([
                                  SourceType.PRUONLINE,
                                  SourceType.ONLINE_PAYMENT,
                                  SourceType.ShopeePay,
                                  SourceType.Ebanking_seabank,
                                  SourceType.MSB_BANK,
                                  SourceType.MOMO,
                                  SourceType.MOMO_FOR_AGENT,
                                  SourceType.MY_VIB,
                                  SourceType.Shopee,
                                  SourceType.PAYOO,
                                  SourceType.POS_SeABank,
                                  SourceType.VIB_AUTODEBIT,
                                  SourceType.Agribank_VBA,
                                  SourceType.Viettel,
                                  SourceType.VNPOST,
                                  SourceType.VIETTEL_TELCO,
                                  SourceType.MPOS_EXIMBANK,
                                  SourceType.Offline,
                                  SourceType.SACOMBANK,
                                  SourceType.CREDITLIFEPORTAL
                                ].includes(row.source as SourceType) &&
                                  cashLessTransactions.includes(row.transactionType ?? ''))) ? (
                              <Pressable
                                onPress={() =>
                                  props.navigation.navigate('TaskDetailStack', {
                                    screen: 'TaskDetailScreen',
                                    params: {
                                      id: row.id,
                                      caterogy: row.category,
                                      basket: '',
                                      basketName:
                                        tab === TabSearch.AdminClaimSearch
                                          ? row.transactionType === TransactionType.PAYOUT
                                            ? 'CL-PV'
                                            : row.status === TransactionStatus.IN_PROGRESS &&
                                              row.activity === '6. Verification'
                                              ? 'CL-VER'
                                              : 'CL-ADJ'
                                          : '',
                                      isInquiry: '1',
                                      isHistory: 'true',
                                      isBreadcrumb: '1',
                                      isPCWriteOff: row.suspendReasonPC === 'WRITE_OFF' ? 'true' : 'false'
                                    }
                                  })
                                }
                              >
                                <Text style={[{ color: '#ed1b2e' }, styles.cellNoWrap]}>{row.caseId}</Text>
                              </Pressable>
                            ) : (
                              <Text style={styles.cellNoWrap}>{row.caseId || '-'}</Text>
                            )}
                          </TableCell>
                          {/* Source */}
                          <TableCell>{row.source}</TableCell>
                          {/* PolicyNumber */}
                          <TableCell>{row.policyNumber || '-'}</TableCell>
                          {/* POClient */}
                          <TableCell>{row.clientNumber === 'null' ? '-' : row.clientNumber || '-'}</TableCell>
                          {/* POID */}
                          <TableCell>{row.poClientNumber || '-'}</TableCell>
                          {/* POName */}
                          <TableCell>{row.poName || '-'}</TableCell>
                          {/* POPhoneNumber */}
                          <TableCell>{row.poPhoneNumber || '-'}</TableCell>
                          {/* Category */}
                          <TableCell className={classes.cellNoWrap}>
                            {convertCategoryToLabel(row.category) || '-'}
                          </TableCell>
                          {/* Service */}
                          <TableCell>{'-'}</TableCell>
                          {/* Transaction */}
                          <TableCell className={classes.cellNoWrap}>
                            {t(TransactionLabel(row.transactionType))}
                          </TableCell>
                          {/* <TableCell className={classes.cellNoWrap}>
                            {row.transactionType === TransactionType.COMMON_REQUEST_FORM
                              ? mapRequestName(row.request)
                              : '-'}
                          </TableCell> */}
                          {/* Group */}
                          <TableCell>{'-'}</TableCell>
                          {/* SubService */}
                          <TableCell>
                            {subServiceTypeList.find((x) => x.value === row.subServiceType)?.label || '-'}
                          </TableCell>
                          {/* SubTransaction */}
                          <TableCell>
                            {subTransactionTypeList.find((x) => x.value === row.subTransactionType)?.label || '-'}
                          </TableCell>
                          {/* UserSubmission */}
                          <TableCell>{row.createdBy ? row.createdBy.split('@')[0] : '-'}</TableCell>
                          {/* CreatedDate */}
                          <TableCell>{PulseOpsFormat.datetoFormat(row.createdDate, 'DD/MM/YYYY HH:mm:ss')}</TableCell>
                          {/* LastAssignee */}
                          <TableCell>
                            <Text>{row.assigneeName || '-'}</Text>
                            {row.assignee && row.assignee !== '-' && (
                              <View>
                                <Text>({row.assignee.split('@')[0]})</Text>
                              </View>
                            )}
                          </TableCell>
                          {/* Aging */}
                          <TableCell>{row.aging}</TableCell>
                          {/* LastUpdatedDate */}
                          <TableCell>
                            {row.lastUpdateDateAdj
                              ? PulseOpsFormat.datetoFormat(row.lastUpdateDateAdj, 'DD/MM/YYYY HH:mm:ss')
                              : row.lastUpdateDateVer
                                ? PulseOpsFormat.datetoFormat(row.lastUpdateDateVer, 'DD/MM/YYYY HH:mm:ss')
                                : '-'}
                          </TableCell>
                          {/* LastUpdatedBy */}
                          <TableCell>
                            {row.lastUpdateByAdj
                              ? row.lastUpdateByAdj.split('@')[0]
                              : row.lastUpdateByVer
                                ? row.lastUpdateByVer.split('@')[0]
                                : '-'}
                          </TableCell>
                          {/* CancelPaymentStatus */}
                          <TableCell>
                            {row.cancelPaymentStatus ? showCancelPaymentText(row.cancelPaymentStatus) : '-'}
                          </TableCell>
                          {/* Status */}
                          <TableCell
                            style={{ color: TransactionStatusLabel(row.status).color }}
                            className={classes.cellNoWrap}
                          >
                            {TransactionStatusLabel(row.status).label}
                          </TableCell>
                          {/* CurrentActivity */}
                          <TableCell>{row.activity || '-'}</TableCell>
                          {/* BatchName */}
                          <TableCell>{row.batchName}</TableCell>
                          {/* Note */}
                          <TableCell>
                            {roles.includes(Permission['ViewNoteServiceInquiry']) &&
                              [Category.COMPLAINT, Category.INQUIRY].includes(row.category as Category) ? (
                              <TouchableOpacity onPress={() => getServiceInquiryNote(row.id)}>
                                <View style={styles.btnDetail}>
                                  <Text style={{ textAlign: 'center', color: '#0000ee' }}>{t('common:Detail')}</Text>
                                </View>
                              </TouchableOpacity>
                            ) : (row.transactionType === TransactionType.UPLOAD_DOCUMENT || row.transactionType === TransactionType.IMAGING_UPLOAD) ? (
                              <TouchableOpacity onPress={() => showUploadDocument(row.id, row.transactionType === TransactionType.IMAGING_UPLOAD)}>
                                <View style={styles.btnDetail}>
                                  <Text style={{ textAlign: 'center', color: '#0000ee' }}>{t('common:Detail')}</Text>
                                </View>
                              </TouchableOpacity>
                            ) : (
                              <Text> {'-'}</Text>
                            )}
                          </TableCell>
                          {/* {roles.includes(Permission.ViewCommentServiceInquiry) &&  */}
                          <TableCell className={classes.commentCell}>
                            <View
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-around',
                                width: isWide ? 100 : 80,
                                height: 53.02,
                                backgroundColor: '#fff',
                                marginLeft: 'auto',
                                marginRight: -6,
                                paddingVertical: 10,
                                paddingLeft: 16,
                                paddingRight: 0
                              }}
                            >
                              <TouchableOpacity
                                onPress={() => {
                                  getCommentById(row.id)
                                }}
                              >
                                <assets.IconComment />
                              </TouchableOpacity>
                            </View>
                          </TableCell>
                          {/* ClaimNumber */}
                          {/* <TableCell>{row.claimNumber || '-'}</TableCell> */}
                          {/* ClaimType */}
                        </TableRow>
                      ))}
                      <TableRow></TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              )}

              <TablePagination
                page={pageNum}
                rowsPerPage={pageSize}
                count={totalItem}
                onPageChange={(e, page) => {
                  setPageNum(page)
                  getServiceInquiry(submittedData, pageSize, page)
                }}
                onRowsPerPageChange={(e) => {
                  setPageSize(Number(e.target.value))
                  setPageNum(0)
                  getServiceInquiry(submittedData, Number(e.target.value), 0)
                }}
                labelRowsPerPage={t('common:PaginationSize')}
                labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
                component={View}
              />
            </View>
          ) : (
            <View style={{ marginHorizontal: 30 }}>
              <Text style={{ color: '#ED1B2E' }}>{messageResponse}</Text>
            </View>
          )}

          <ModalComponent
            title={t('ServiceInquiry:DetailNote')}
            visible={infoVisible}
            onClose={() => setInfoVisible(false)}
            actions={[
              {
                text: t('common:Close'),
                type: 'filled',
                disabled: false,
                loading,
                onlyWide: false,
                style: { height: 40 },
                action: () => setInfoVisible(false)
              }
            ]}
          >
            <View style={{ marginHorizontal: 20, marginTop: 20, maxHeight: height - 200 }}>
              <Text style={{ paddingVertical: 10, fontWeight: 'bold' }}>{t('ServiceInquiry:CustomerComment')}</Text>
              <View
                style={{
                  minHeight: 80,
                  maxHeight: 'calc((100vh - 300px)/2)',
                  borderRadius: 5,
                  borderWidth: 1,
                  borderColor: '#4f4f4f',
                  backgroundColor: '#fff',
                  padding: 9
                }}
              >
                <ScrollView>
                  <Text>{noteDetail.customerComment}</Text>
                </ScrollView>
              </View>
              {/* Response to Customer */}
              <Text style={{ paddingVertical: 10, fontWeight: 'bold' }}>{t('ServiceInquiry:ResponseToCustomer')}</Text>
              <View
                style={{
                  minHeight: 80,
                  maxHeight: 'calc((100vh - 300px)/2)',
                  borderRadius: 5,
                  borderWidth: 1,
                  borderColor: '#4f4f4f',
                  backgroundColor: '#fff',
                  padding: 9
                }}
              >
                <ScrollView>
                  <Text>{noteDetail.responseToCustomer}</Text>
                </ScrollView>
              </View>

              <Text style={{ paddingVertical: 10, fontWeight: 'bold' }}>{t('ServiceInquiry:ClosedInfo')}</Text>
              <View
                style={{
                  minHeight: 80,
                  maxHeight: 'calc((100vh - 300px)/2)',
                  borderRadius: 5,
                  borderWidth: 1,
                  borderColor: '#4f4f4f',
                  backgroundColor: '#fff',
                  padding: 9
                }}
              >
                <ScrollView>
                  <Text>{noteDetail.closedInfo}</Text>
                </ScrollView>
              </View>
            </View>
          </ModalComponent>

          <ModalComponent
            title={t('common:Detail')}
            visible={uploadDocVisible}
            onClose={() => {
              setUploadDocVisible(false)
              //setUploadDocumentTaskId(undefined)
            }}
            actions={[
              {
                text: t('common:Cancel'),
                type: 'filled',
                disabled: false,
                loading,
                onlyWide: false,
                style: { height: 40 },
                action: () => {
                  setUploadDocVisible(false)
                  //setUploadDocumentTaskId(undefined)
                }
              }
            ]}
          >
            {uploadDocumentTaskId && <UploadDocumentDetail taskId={uploadDocumentTaskId} isUploadImaging={isUploadImaging} />}
          </ModalComponent>

          {/*Modal comment of basic/advance search  */}
          <ModalComponent
            title={t('ServiceInquiry:CommentDetail')}
            visible={PSComment}
            onClose={() => {
              setPSComment(false), setPSCommentData([])
            }}
            actions={[
              {
                text: t('common:Cancel'),
                type: 'filled',
                disabled: false,
                loading,
                onlyWide: false,
                style: { height: 40 },
                action: () => {
                  setPSComment(false), setPSCommentData([])
                }
              }
            ]}
            modalWidth={864}
          >
            <ScrollView>
              {PSCommentData.length > 0 && (
                <View
                  style={{
                    marginHorizontal: 20,
                    marginTop: 20,
                    // maxHeight: height - 200,
                    borderWidth: 1,
                    borderRadius: 8,
                    borderColor: '#dcdcdc',
                    paddingTop: 16,
                    paddingHorizontal: 16
                  }}
                >
                  {PSCommentData.map((user, index) => (
                    <Panel
                      key={index}
                      title={user.username}
                      comment={PulseOpsFormat.datetoFormat(user.time, 'DD/MM/YYYY HH:mm:ss')}
                      isHiddenTitle={false}
                      isExand={true}
                      isHaveComment
                    >
                      <Text>{user.message}</Text>
                    </Panel>
                  ))}
                </View>
              )}
              {PSCommentData.length === 0 && (
                <View
                  style={{
                    marginHorizontal: 20,
                    marginTop: 20,
                    maxHeight: height - 200,
                    borderWidth: 1,
                    borderRadius: 8,
                    borderColor: '#dcdcdc',
                    paddingHorizontal: 16,
                    minHeight: 80
                  }}
                >
                  <Text style={{ marginVertical: 'auto' }}>{t('ServiceInquiry:CommentNotFound')}</Text>
                </View>
              )}
            </ScrollView>
          </ModalComponent>
        </View>
      </ScrollView>
    </SafeAreaView>
  )
}

const PremiumCollectionSearch = (props: { onSearch: (data: PremiumCollectionForm.Validated) => void }) => {
  const { onSearch } = props
  const [expanded, setExpanded] = React.useState<boolean>(true)
  const { base, handleSubmit } = form2.useForm(PremiumCollectionForm.codec, {
    defaultValues: {
      fromDate: new Date(),
      toDate: new Date()
    }
  })
  const formError = _.get(base.formState.errors, '.message')
  const { t } = useTranslation()

  const submit = handleSubmit((validated) => {
    onSearch({
      ...validated,
      amount: validated.amount ? String(PulseOpsFormat.thousandSepartorReverse(validated.amount)) : null
    })
  })
  const formatBankCode = () => {
    const listBankCode = PartnerChannel.RawParnerChannel
    return listBankCode.map((item) => ({ label: `${item.name} - ${item.bankCode}`, value: item.bankCode }))
  }
  return (
    <View style={{ paddingHorizontal: 20 }}>
      {expanded && (
        <>
          <View style={styles.wrap}>
            <Controller
              control={base.control}
              name={'clientNumber'}
              render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                return (
                  <RView width={'22%'} style={styles.inputStyle}>
                    <Input
                      maxLength={8}
                      title={t('ServiceInquiry:ClientNumber')}
                      value={value ?? ''}
                      onChange={(value) => onChange(PulseOpsFormat.positiveNumber(value ?? ''))}
                      errorMessage={error?.message}
                    />
                  </RView>
                )
              }}
            />
            <Controller
              control={base.control}
              name={'policyNumber'}
              render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                return (
                  <RView width={'22%'} style={styles.inputStyle}>
                    <Input
                      maxLength={8}
                      title={t('ServiceInquiry:PolicyNumber')}
                      onChange={(value) => {
                        onChange(value.replace(/[^0-9]/g, ''))
                      }}
                      value={value ?? ''}
                      errorMessage={error?.message}
                    />
                  </RView>
                )
              }}
            />
            <Controller
              control={base.control}
              name={'proposalNumber'}
              render={({ field: { value, onChange }, fieldState: { error } }) => {
                return (
                  <RView width={'22%'} style={styles.inputStyle}>
                    <Input
                      maxLength={9}
                      title={t('ServiceInquiry:proposalNumber')}
                      onChange={(value) => onChange(PulseOpsFormat.positiveNumber(value ?? ''))}
                      value={value ?? ''}
                      errorMessage={error?.message}
                    />
                  </RView>
                )
              }}
            />
            <Controller
              control={base.control}
              name={'amount'}
              render={({ field: { value, onChange } }) => {
                return (
                  <RView width={'22%'} style={styles.inputStyle}>
                    <Input
                      title={t('ServiceInquiry:amount')}
                      value={value ?? ''}
                      maxLength={15}
                      onChange={(value) => {
                        if (!!value) {
                          onChange(PulseOpsFormat.addThousandSeparator(value))
                        } else {
                          onChange('')
                        }
                      }}
                    />
                  </RView>
                )
              }}
            />
            <Controller
              control={base.control}
              name={'bankCode'}
              render={({ field: { value, onChange } }) => {
                return (
                  <RView width={'22%'} style={styles.inputStyle}>
                    {/* <Input title={t('ServiceInquiry:bankCode')} onChange={onChange} value={value ?? ''} /> */}
                    <SelectSearch
                      label={t('ServiceInquiry:bankCode')}
                      options={formatBankCode()}
                      placeholder={t('common:Select')}
                      onChange={(e) => {
                        onChange(e)
                      }}
                      value={value}
                    />
                  </RView>
                )
              }}
            />
            <Controller
              control={base.control}
              name={'referenceNumber'}
              render={({ field: { value, onChange } }) => {
                return (
                  <RView width={'22%'} style={styles.inputStyle}>
                    <Input title={t('ServiceInquiry:ReferenceNo')} onChange={onChange} value={value ?? ''} />
                  </RView>
                )
              }}
            />
          </View>

          <View style={styles.wrap}>
            <Controller
              control={base.control}
              name={'fromDate'}
              render={({ field: { value, onChange } }) => {
                return (
                  <RView width={'22%'} style={styles.inputStyle}>
                    <DatePicker
                      label={t('ServiceInquiry:FromDate')}
                      onChange={onChange}
                      value={value}
                      alwaysShow
                      maxDate={new Date()}
                    // minDate={toDate ?? undefined}
                    />
                  </RView>
                )
              }}
            />
            <Controller
              control={base.control}
              name={'toDate'}
              render={({ field: { value, onChange } }) => {
                return (
                  <RView width={'22%'} style={styles.inputStyle}>
                    <DatePicker
                      label={t('ServiceInquiry:ToDate')}
                      onChange={onChange}
                      value={value}
                      alwaysShow
                      maxDate={new Date()}
                    />
                  </RView>
                )
              }}
            />
          </View>
        </>
      )}

      <View style={[styles.row, { alignItems: 'center' }]}>
        <TouchableOpacity
          style={{ width: 118, height: 40 }}
          onPress={() => {
            //console.log(base.getValues())
            submit()
          }}
        >
          <View style={styles.btn}>
            <Text style={{ textAlign: 'center', color: '#fff' }}>{t('common:Search')}</Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity
          style={{ width: 118, height: 40, marginLeft: 30, flexDirection: 'row', alignItems: 'center' }}
          onPress={() => setExpanded(!expanded)}
        >
          <Text style={{ textAlign: 'center', lineHeight: 40, fontSize: 16 }}>
            {t(expanded ? 'common:Collapse' : 'common:Expand')}
          </Text>
          <Text style={{ marginLeft: 3 }}>{!expanded ? <assets.ExpandIcon /> : <assets.CollapseIcon />}</Text>
        </TouchableOpacity>
      </View>
      {formError && <Text style={{ color: '#ed1b2e', marginVertical: 12 }}>{formError}</Text>}
    </View>
  )
}

export interface PremiumTableRef {
  onSearch: (data: HistoricTask.Filter) => void
}

export const PremiumTable = React.forwardRef((props: {}, ref: React.Ref<PremiumTableRef>) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { navigate } = useNavigation()
  const [pageNum, setPageNum] = React.useState<number>(0)
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [totalItem, setTotalItem] = React.useState<number>(0)
  const [premiumData, setPremiumData] = React.useState<TaskPremiumOp[]>([])
  const [submittedData, setSubmittedData] = React.useState<HistoricTask.Filter>({})
  const [loading, bindLoading] = useLoading(false)
  const [sortItem, setSortItem] = React.useState<{ field: string; order: 'asc' | 'desc' | undefined }>({
    field: '',
    order: 'asc'
  })

  const [messageResponse, setMessageResponse] = React.useState<string | null>(null)

  React.useImperativeHandle(ref, () => ({
    onSearch: (data) => {
      setSubmittedData(data)
      getPremiumCollectionTask(data, pageSize, pageNum)
    }
  }))

  const getPremiumCollectionTask = (
    submittedData: HistoricTask.Filter,
    pageSize: number,
    pageNum: number,
    order: 'asc' | 'desc' | undefined = 'desc'
  ) => {
    pipe(
      TaskService.getHistoryTasks({
        pagination: {
          pageIndex: pageNum,
          pageSize: pageSize
        },
        order: order,
        filter: submittedData
      }),
      ZIO.tap((res) => {
        setTotalItem(res.total)
        setMessageResponse(res.items.length === 0 ? t('message:MS030029') : null)
        return ZIO.unit
      }),
      ZIO.tap((res) => {
        const wrapItem = res.items.map((item) => ({
          ...item,
          policyNumber:
            item.policyNumber && item.policyNumber !== 'null' ? item.policyNumber : item.proposalNumber ?? '-',
          paymentReasonName: PCReason.getDescriptionReasonByCode(item.paymentReason ?? '-'),
          lastUpdateByVerName: getLastUpdateName(item),
          statusName: TransactionStatusLabel(item.status).label
        }))
        setPremiumData(wrapItem)
        return ZIO.unit
      }),
      bindLoading,
      ErrorHandling.run()
    )
  }

  const getLastUpdateName = React.useCallback((task: TaskPremiumOp) => {
    return task.lastUpdateByAdj
      ? task.lastUpdateByAdj.split('@')[0]
      : task.lastUpdateByVer
        ? task.lastUpdateByVer.split('@')[0]
        : '-'
  }, [])

  if (loading) {
    return <LinearProgress color="secondary" />
  }
  return (
    <View>
      {messageResponse ? (
        <View style={{ marginHorizontal: 30 }}>
          <Text style={{ color: '#ED1B2E' }}>{messageResponse}</Text>
        </View>
      ) : premiumData.length > 0 ? (
        <View style={{ marginHorizontal: 30 }}>
          <TableContainer component={Paper} style={{ maxHeight: 500 }} elevation={1}>
            <Table style={{ userSelect: 'text' }} stickyHeader>
              <TableHead className={classes.tableHeader}>
                <TableHeaderSort
                  data={premiumData}
                  setData={setPremiumData}
                  setSortItem={setSortItem}
                  sortItem={sortItem}
                  column={[
                    {
                      label: t('ServiceInquiry:pc:transactionNo'),
                      disabled: true,
                      field: 'transactionNo'
                    },
                    {
                      label: t('ServiceInquiry:pc:pendingNum'),
                      field: 'pendingNum'
                    },
                    {
                      label: t('ServiceInquiry:pc:policyNo'),
                      field: 'policyNumber'
                    },
                    {
                      label: t('ServiceInquiry:pc:receiveAmount'),
                      field: 'amount'
                    },
                    {
                      label: t('ServiceInquiry:pc:receiptDate'),
                      field: 'receiptDate'
                    },
                    {
                      label: t('ServiceInquiry:pc:paymentContent'),
                      field: 'paymentReasonName'
                    },
                    {
                      label: t('ServiceInquiry:pc:refNo'),
                      field: 'referenceNumber'
                    },
                    {
                      label: t('ServiceInquiry:pc:bankCode'),
                      field: 'bankCode'
                    },
                    {
                      label: t('ServiceInquiry:pc:lastUserId'),
                      field: 'lastUpdateByVerName'
                    },
                    {
                      label: t('ServiceInquiry:pc:currentActivity'),
                      field: 'activity'
                    },
                    {
                      label: t('ServiceInquiry:pc:status'),
                      field: 'statusName'
                    }
                  ]}
                />
              </TableHead>
              <TableBody>
                {premiumData.map((row, index) => (
                  <TableRow
                    key={`___list_row${index}`}
                    style={{
                      backgroundColor:
                        row.status === TransactionStatus.PENDING && row.suspendReasonPC === 'WRITE_OFF'
                          ? '#ed1b2e26'
                          : row.status === TransactionStatus.PENDING && row.suspendReasonPC === 'AML_FISERV_REDFLAG'
                            ? '#b0e0ff'
                            : '#fff'
                    }}
                  >
                    <TableCell>
                      <Pressable
                        onPress={() => {
                          navigate('TaskDetailStack', {
                            screen: 'TaskDetailScreen',
                            params: {
                              id: row.id,
                              caterogy: row.category,
                              basket: '',
                              basketName: '',
                              isInquiry: '1',
                              isHistory: 'true',
                              isBreadcrumb: '1',
                              isPCWriteOff: row.suspendReasonPC === 'WRITE_OFF' ? 'true' : 'false'
                            }
                          })
                        }}
                      >
                        <Text style={[styles.cellNoWrap, { color: '#ED1B2E' }]}>{row.caseId}</Text>
                      </Pressable>
                    </TableCell>
                    <TableCell>{row.pendingNum ?? ''}</TableCell>
                    <TableCell>{row.policyNumber ?? row.proposalNumber ?? ''}</TableCell>
                    <TableCell className={classes.cellNoWrap}>
                      {PulseOpsFormat.thousandSepartor(parseInt(row.amount ?? '0')) + ' VND'}
                    </TableCell>
                    <TableCell>{row.receiptDate}</TableCell>
                    {/* <TableCell>-</TableCell> */}
                    <TableCell>
                      {row.hasVoucher && row.vouchers
                        ? `${PCReason.getDescriptionReasonByCode(row.paymentReason || '')} - ${row.vouchers[0].code
                        } - ${PulseOpsFormat.thousandSepartor(parseInt(row.vouchers[0].amount))}`
                        : row.paymentReason && row.remark
                          ? PCReason.getDescriptionReasonByCode(row.paymentReason ?? '') + ' - ' + row.remark
                          : row.remark && !row.paymentReason
                            ? row.remark
                            : !row.remark && row.paymentReason
                              ? PCReason.getDescriptionReasonByCode(row.paymentReason ?? '')
                              : '-'}
                    </TableCell>
                    <TableCell>{row.referenceNumber ?? '-'}</TableCell>
                    <TableCell>{row.bankCode ?? '-'}</TableCell>
                    <TableCell>{getLastUpdateName(row)}</TableCell>
                    <TableCell>{row.activity ?? '-'}</TableCell>
                    <TableCell
                      style={{ color: TransactionStatusLabel(row.status).color }}
                      className={classes.cellNoWrap}
                    >
                      {TransactionStatusLabel(row.status).label}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            page={pageNum}
            rowsPerPage={pageSize}
            count={totalItem}
            onPageChange={(e, page) => {
              setPageNum(page)
              getPremiumCollectionTask(submittedData, pageSize, page)
            }}
            onRowsPerPageChange={(e) => {
              setPageSize(Number(e.target.value))
              setPageNum(0)
              getPremiumCollectionTask(submittedData, Number(e.target.value), 0)
            }}
            labelRowsPerPage={t('common:PaginationSize')}
            labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
            component={View}
          />
        </View>
      ) : (
        <View />
      )}
    </View>
  )
})

const DistributionServiceInquirySearch = (props: {
  onSearch: (data: DistributionServiceInquiryForm.Validated) => void
  onExport: () => void
  isShowBtnExport: boolean
}) => {
  const { onSearch, onExport, isShowBtnExport = false } = props
  const [expanded, setExpanded] = React.useState<boolean>(true)
  const { base, handleSubmit } = form2.useForm(DistributionServiceInquiryForm.codec, {
    defaultValues: {
      fromDate: new Date(),
      toDate: new Date()
    }
  })
  const formError = _.get(base.formState.errors, '.message')
  const { t } = useTranslation()

  const submit = handleSubmit((validated) => {
    return onSearch({
      ...validated
    })
  })

  const exportDataDS = () => {
    return onExport?.()
  }

  return (
    <View style={{ paddingHorizontal: 20 }}>
      <>
        <View style={styles.wrap}>
          <Controller
            control={base.control}
            name={'eRef'}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <RView width={'30%'} style={styles.inputStyle}>
                  <Input
                    maxLength={11}
                    title={t('ServiceInquiry:eRef')}
                    value={value ?? ''}
                    onChange={onChange}
                    errorMessage={error?.message}
                  />
                </RView>
              )
            }}
          />
          <Controller
            control={base.control}
            name={'agentCode'}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <RView width={'30%'} style={styles.inputStyle}>
                  <Input
                    maxLength={8}
                    title={t('ServiceInquiry:AgentCode')}
                    onChange={onChange}
                    value={value ?? ''}
                    errorMessage={error?.message}
                  />
                </RView>
              )
            }}
          />
          <Controller
            control={base.control}
            name={'IDnumber'}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <RView width={'30%'} style={styles.inputStyle}>
                  <Input
                    maxLength={24}
                    title={t('ServiceInquiry:IDNumber')}
                    onChange={(value) => onChange(PulseOpsFormat.positiveNumber(value ?? ''))}
                    value={value ?? ''}
                    errorMessage={error?.message}
                  />
                </RView>
              )
            }}
          />
        </View>

        <View style={styles.wrap}>
          {/* <Controller
                control={base.control}
                name={'phoneNumber'}
                render={({ field: { value, onChange }, fieldState: { error }  }) => {
                  return (
                    <RView width={'30%'} style={[styles.inputStyle, { marginTop:6 }]}>
                      <Input
                        title={t('ServiceInquiry:PhoneNumber')}
                        value={value ?? ''}
                        maxLength={15}
                        onChange={onChange}
                        errorMessage={error?.message}
                      />
                    </RView>
                  )
                }}
            /> */}
          <Controller
            control={base.control}
            name={'fromDate'}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <RView width={'30%'} style={styles.inputStyle}>
                  <DatePicker
                    required
                    label={t('ServiceInquiry:FromDate')}
                    onChange={onChange}
                    value={value}
                    maxDate={new Date()}
                    // minDate={toDate ?? undefined}
                    errorMessage={error?.message}
                  />
                </RView>
              )
            }}
          />

          <Controller
            control={base.control}
            name={'toDate'}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <RView width={'30%'} style={styles.inputStyle}>
                  <DatePicker
                    required
                    label={t('ServiceInquiry:ToDate')}
                    onChange={onChange}
                    value={value}
                    maxDate={new Date()}
                    errorMessage={error?.message}
                  />
                </RView>
              )
            }}
          />

          <Controller
            name="category"
            control={base.control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
              return (
                <RView width={'30%'} style={styles.inputStyle}>
                  <Select
                    label={t('ServiceInquiry:Category')}
                    options={[
                      {
                        label: 'Distribution Services',
                        value: Category.DISTRIBUTION_SERVICES
                      }
                    ]}
                    placeholder={t('common:Select')}
                    onChange={(e) => {
                      onChange(e)
                      base.setValue('transactionType', [])
                    }}
                    onBlur={onBlur}
                    value={{
                      label: 'Distribution Services',
                      value: Category.DISTRIBUTION_SERVICES
                    }}
                    disabled={true}
                    errorMessage={error?.message}
                  />
                </RView>
              )
            }}
          />
        </View>
        {expanded && (
          <View style={styles.wrap}>
            <Controller
              name="transactionType"
              control={base.control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                return (
                  <RView width={'30%'} style={styles.inputStyle}>
                    <SelectSearch
                      label={t('ServiceInquiry:TransactionType')}
                      options={TransactionTypeSelectOptions.filter(
                        (x) => x.category === Category.DISTRIBUTION_SERVICES
                      ).map((item) => ({
                        label: t(item.label),
                        value: item.value
                      }))}
                      placeholder={t('common:Select')}
                      onChange={onChange}
                      value={value ?? undefined}
                      multiple={true}
                      errorMessage={error?.message}
                    />
                  </RView>
                )
              }}
            />
            <Controller
              name="source"
              control={base.control}
              render={({ field: { onChange, value }, fieldState: { error } }) => {
                return (
                  <RView width={'30%'} style={styles.inputStyle}>
                    <SelectSearch
                      label={t('ServiceInquiry:Source')}
                      options={sourceDAOptions}
                      placeholder={t('common:Select')}
                      onChange={onChange}
                      value={value ?? undefined}
                      multiple={true}
                      errorMessage={error?.message}
                    />
                  </RView>
                )
              }}
            />
          </View>
        )}
      </>

      <View style={[styles.row, { alignItems: 'center' }]}>
        <TouchableOpacity
          style={{ width: 118, height: 40 }}
          onPress={() => {
            //console.log(base.getValues())
            submit()
          }}
        >
          <View style={styles.btn}>
            <Text style={{ textAlign: 'center', color: '#fff' }}>{t('common:Search')}</Text>
          </View>
        </TouchableOpacity>
        {/* <TouchableOpacity
          style={{ width: 118, height: 40, marginLeft: 30 }}
          onPress={() => {
            exportDataDS()
          }}
          disabled={!isShowBtnExport}
        >
          <View style={!isShowBtnExport ? styles.btnDisabled : styles.btn}>
            <Text style={{ textAlign: 'center', color: '#fff' }}>{t('common:Export')}</Text>
          </View>
        </TouchableOpacity> */}

        <TouchableOpacity
          style={{ width: 118, height: 40, marginLeft: 30, flexDirection: 'row', alignItems: 'center' }}
          onPress={() => setExpanded(!expanded)}
        >
          <Text style={{ textAlign: 'center', lineHeight: 40, fontSize: 16 }}>
            {t(expanded ? 'common:Collapse' : 'common:Expand')}
          </Text>
          <Text style={{ marginLeft: 3 }}>{!expanded ? <assets.ExpandIcon /> : <assets.CollapseIcon />}</Text>
        </TouchableOpacity>
      </View>
      {formError && <Text style={{ color: '#ed1b2e', marginVertical: 12 }}>{formError}</Text>}
    </View>
  )
}
export interface DSTableRef {
  onSearch: (data: HistoricTask.Filter) => void
  onExport: () => void
}

export const DSTable = React.forwardRef(
  (props: { onShowBtnExport: (e: boolean) => void }, ref: React.Ref<DSTableRef>) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const { navigate } = useNavigation()
    const [pageNum, setPageNum] = React.useState<number>(0)
    const [pageSize, setPageSize] = React.useState<number>(10)
    const [totalItem, setTotalItem] = React.useState<number>(0)
    const [DSData, setDSData] = React.useState<TaskDSOp[]>([])
    const [submittedData, setSubmittedData] = React.useState<HistoricTask.Filter>({})
    const [commentVisible, setCommentVisible] = React.useState<boolean>(false)
    const [commentData, setCommentData] = React.useState<TaskComment[]>([])
    const [loading, bindLoading] = useLoading(false)
    const { height } = useWindowDimensions()
    const [messageResponse, setMessageResponse] = React.useState<string | null>(null)
    React.useImperativeHandle(ref, () => ({
      onSearch: (data) => {
        setSubmittedData(data)
        let dataSearch = {
          ...data
        }
        getPremiumCollectionTask(dataSearch, pageSize, pageNum)
      },
      onExport: () => {
        if (DSData.length > 0) {
          exportDSData()
        }
      }
    }))

    React.useEffect(() => {
      if (DSData.length > 0) {
        return props?.onShowBtnExport?.(true)
      }
      return props?.onShowBtnExport?.(false)
    }, [DSData])

    const getPremiumCollectionTask = (submittedData: HistoricTask.Filter, pageSize: number, pageNum: number) => {
      pipe(
        TaskService.getHistoryTasksDS({
          pagination: {
            pageIndex: pageNum,
            pageSize: pageSize
          },
          order: 'desc',
          filter: {
            ...submittedData,
            fromDate: submittedData.fromDate
              ? PulseOpsFormat.serviceInquiry_StartOfDate(submittedData.fromDate, true)
              : undefined,
            toDate: submittedData.toDate
              ? PulseOpsFormat.serviceInquiry_endOfDate(submittedData.toDate, true)
              : undefined
          }
        }),
        ZIO.tap((res) => {
          setTotalItem(res.total)
          setMessageResponse(res.items.length === 0 ? t('message:MS030029') : null)
          return ZIO.unit
        }),
        ZIO.tap((res) => {
          const wrapItem = res.items.map((item) => ({
            ...item
          }))
          setDSData(wrapItem)
          return ZIO.unit
        }),
        bindLoading,
        ErrorHandling.run()
      )
    }

    const exportDSData = () => {
      const customedFromDate = submittedData.fromDate as Date
      const customedToDate = submittedData.toDate as Date
      const selectedCategory = submittedData?.categories?.[0] ?? ''
      const nameCategory = ServiceInquiryCategories.find((x) => x.value === selectedCategory)

      const summary = {
        fromDate: customedFromDate,
        toDate: customedToDate,
        title: t('ServiceInquiry:DistributionService'),
        category: nameCategory?.label ?? ''
      }

      const columns = DistributionServicesExportConst.displayedColumns.map(({ name, widthPx, horizontal }) => ({
        name: t(name),
        horizontal,
        width: widthPx
      }))

      const exportDataSource = mappingDataSource(DSData)

      const rows = exportDataSource.map(
        ({
          no,
          caseID,
          eRef,
          source,
          agentCode,
          idNumber,
          name,
          phone,
          category,
          transactionType,
          createdDate,
          assignee,
          aging,
          lastUpdateBy,
          lastUpdatedDate,
          status,
          currentActivity
        }) => [
            no,
            caseID,
            eRef,
            source,
            agentCode,
            idNumber,
            name,
            phone,
            category,
            transactionType,
            createdDate,
            assignee,
            aging,
            lastUpdateBy,
            lastUpdatedDate,
            status,
            currentActivity
          ]
      )

      return pipe(
        ReportService.exportTemplateRP0001(
          { ref: 'B8', colRef: 2, rowRef: 8 },
          summary,
          columns,
          rows,
          DistributionServicesExportConst.Extension.EXCEL,
          t('ServiceInquiry:DistributionService')
        ),
        ZIO.unsafeRun({})
      )
    }
    const mappingDataSource = (data: TaskDSOp[], start: number = 0) => {
      const filteredDataSource = data.map((item, index) => ({
        no: (start + index + 1).toString(),
        caseID: item.caseId,
        eRef: item.eref ?? '-',
        source: item.source,
        agentCode: item.agentCode ?? '-',
        idNumber: item.clientNumber ?? '-',
        name: item.agentLastName + ' ' + item.agentFirstName,
        phone: item.agentPhoneNumber,
        category: convertCategoryToLabel(item.category),
        transactionType: item.transactionType?.replace(/_/g, ' '),
        createdDate: !!item.createdDate ? PulseOpsFormat.date(new Date(item.createdDate)) : '-',
        assignee: item.assigneeName,
        aging: item.aging,
        lastUpdateBy: item.lastUpdateByAdj
          ? item.lastUpdateByAdj.split('@')[0]
          : item.lastUpdateByVer
            ? item.lastUpdateByVer.split('@')[0]
            : '-',
        lastUpdatedDate: item.lastUpdateDateAdj
          ? PulseOpsFormat.datetoFormat(item.lastUpdateDateAdj, 'DD/MM/YYYY HH:mm:ss')
          : item.lastUpdateDateVer
            ? PulseOpsFormat.datetoFormat(item.lastUpdateDateVer, 'DD/MM/YYYY HH:mm:ss')
            : '-',
        status: item.status?.replace(/_/g, ' '),
        currentActivity: item.activity
      }))

      return filteredDataSource
    }

    const viewCommentList = (processId: string) => {
      pipe(
        PulseOpsService.getCommentList(processId),
        ZIO.tap((comments) => {
          setCommentData(comments)
          return ZIO.unit
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )

      setCommentVisible(true)
    }

    const convertCategoryToLabel = (category: string): string => {
      const converted = CategoryToLabel(category)
      if (converted) {
        return t(`Reports:${converted}`)
      } else {
        return ''
      }
    }

    if (loading) {
      return <LinearProgress color="secondary" />
    }

    return (
      <View>
        {messageResponse ? (
          <View style={{ marginHorizontal: 30 }}>
            <Text style={{ color: '#ED1B2E' }}>{messageResponse}</Text>
          </View>
        ) : DSData.length > 0 ? (
          <View style={{ marginHorizontal: 30 }}>
            <TableContainer component={Paper} style={{ maxHeight: 500 }} elevation={1}>
              <Table style={{ userSelect: 'text' }} stickyHeader>
                <TableHead className={classes.tableHeader}>
                  <TableRow>
                    <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:CaseID')}</TableCell>
                    <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:eRef')}</TableCell>
                    <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:Source')}</TableCell>
                    <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:AgentCode')}</TableCell>
                    <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:IDNumber')}</TableCell>
                    <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:Name')}</TableCell>
                    <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:Phone')}</TableCell>
                    <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:Category')}</TableCell>
                    <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:Transaction')}</TableCell>
                    <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:CreatedDate')}</TableCell>
                    <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:LastAssignee')}</TableCell>
                    <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:Aging')}</TableCell>
                    <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:LastUpdatedBy')}</TableCell>
                    <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:LastUpdatedDate')}</TableCell>
                    <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:Status')}</TableCell>
                    <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:Comment')}</TableCell>
                    <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:CurrentActivity')}</TableCell>
                    <TableCell className={classes.tableCell} style={{ textAlign: 'center' }}>
                      {t('ServiceInquiry:table:Note')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {DSData.map((row, i) => {
                    return (
                      <TableRow key={`TableRow-${i}`}>
                        {/* CaseID */}
                        <TableCell>
                          {row.caseId && (
                            <Pressable
                              onPress={() =>
                                navigate('TaskDetailStack', {
                                  screen: 'TaskDetailScreen',
                                  params: {
                                    id: row.id,
                                    caterogy: row?.category,
                                    basket: row.activity ? (row.activity as SubTaskType) : SubTaskType.Suspend,
                                    basketName: '',
                                    isInquiry: '1',
                                    isHistory: 'true',
                                    isPSDigital: (row?.transactionType
                                      ? row?.transactionType.includes('DIGITAL')
                                      : false
                                    ).toString(),
                                    isBreadcrumb: '1'
                                  }
                                })
                              }
                            >
                              <Text style={[{ color: '#1EA5FC' }, styles.cellNoWrap]}>{row.caseId}</Text>
                            </Pressable>
                          )}
                        </TableCell>
                        {/* Eref */}
                        <TableCell>{row.eref || '-'}</TableCell>
                        {/* Source */}
                        <TableCell>{row.source}</TableCell>
                        {/* AgentCode */}
                        <TableCell>{row.agentCode || '-'}</TableCell>
                        {/* IDNo */}
                        <TableCell>{row.clientNumber || '-'}</TableCell>
                        {/* Name */}
                        <TableCell>{row.agentLastName + ' ' + row.agentFirstName || '-'}</TableCell>
                        {/* poPhoneNumber */}
                        <TableCell>{row.agentPhoneNumber || '-'}</TableCell>
                        {/* category */}
                        <TableCell className={classes.cellNoWrap}>
                          {convertCategoryToLabel(row.category) || '-'}
                        </TableCell>
                        {/* transactionType */}
                        <TableCell>{row.transactionType?.replace(/_/g, ' ') || '-'}</TableCell>
                        {/* CreatedDate */}
                        <TableCell>{PulseOpsFormat.datetoFormat(row.createdDate, 'DD/MM/YYYY HH:mm:ss')}</TableCell>
                        {/* LastAssignee */}
                        <TableCell>
                          <Text>{row.assigneeName || '-'}</Text>
                          {row.assignee && row.assignee !== '-' && (
                            <View>
                              <Text>({row.assignee.split('@')[0]})</Text>
                            </View>
                          )}
                        </TableCell>
                        {/* Aging */}
                        <TableCell>{row.aging || '-'}</TableCell>
                        {/* LastUpdatedBy */}
                        <TableCell>
                          {row.lastUpdateByAdj
                            ? row.lastUpdateByAdj.split('@')[0]
                            : row.lastUpdateByVer
                              ? row.lastUpdateByVer.split('@')[0]
                              : '-'}
                        </TableCell>
                        {/* LastUpdatedDate */}
                        <TableCell>
                          {row.lastUpdateDateAdj
                            ? PulseOpsFormat.datetoFormat(row.lastUpdateDateAdj, 'DD/MM/YYYY HH:mm:ss')
                            : row.lastUpdateDateVer
                              ? PulseOpsFormat.datetoFormat(row.lastUpdateDateVer, 'DD/MM/YYYY HH:mm:ss')
                              : '-'}
                        </TableCell>
                        {/* Status */}
                        <TableCell
                          style={{ color: TransactionStatusLabel(row.status).color }}
                          className={classes.cellNoWrap}
                        >
                          {TransactionStatusLabel(row.status).label}
                        </TableCell>
                        {/* Comment */}
                        <TableCell className={classes.cellNoWrap}>
                          <TouchableOpacity onPress={() => viewCommentList(row.id)}>
                            <Text style={[{ color: '#1EA5FC', textDecorationLine: 'underline' }, styles.cellNoWrap]}>
                              {t('ServiceInquiry:View')}
                            </Text>
                          </TouchableOpacity>
                        </TableCell>
                        {/* Current Activity */}
                        <TableCell className={classes.cellNoWrap}>{row.activity}</TableCell>
                        {/* Note */}
                        <TableCell>
                          <Text> {'-'}</Text>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              page={pageNum}
              rowsPerPage={pageSize}
              count={totalItem}
              onPageChange={(e, page) => {
                setPageNum(page)
                getPremiumCollectionTask(submittedData, pageSize, page)
              }}
              onRowsPerPageChange={(e) => {
                setPageSize(Number(e.target.value))
                setPageNum(0)
                getPremiumCollectionTask(submittedData, Number(e.target.value), 0)
              }}
              labelRowsPerPage={t('common:PaginationSize')}
              labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
              component={View}
            />
            <ModalComponent
              title={t('ServiceInquiry:CommentDetail')}
              visible={commentVisible}
              onClose={() => {
                setCommentVisible(false), setCommentData([])
              }}
              actions={[
                {
                  text: t('common:Cancel'),
                  type: 'filled',
                  disabled: false,
                  loading,
                  onlyWide: false,
                  style: { height: 40 },
                  action: () => {
                    setCommentVisible(false), setCommentData([])
                  }
                }
              ]}
              modalWidth={864}
            >
              {commentData.length > 0 && (
                <View
                  style={{
                    marginHorizontal: 20,
                    marginTop: 20,
                    maxHeight: height - 200,
                    borderWidth: 1,
                    borderRadius: 8,
                    borderColor: '#dcdcdc',
                    paddingTop: 16,
                    paddingHorizontal: 16
                  }}
                >
                  {commentData.map((user, index) => (
                    <Panel
                      key={index}
                      title={user.username}
                      comment={PulseOpsFormat.datetoFormat(user.time, 'DD/MM/YYYY HH:mm:ss')}
                      isHiddenTitle={false}
                      isExand={true}
                      isHaveComment
                    >
                      {/*  */}
                      <Text>{user.message}</Text>
                    </Panel>
                  ))}
                </View>
              )}
            </ModalComponent>
          </View>
        ) : (
          <View />
        )}
      </View>
    )
  }
)
const IndependenceCheckInquirySearch = (props: {
  onSearch: (data: IndependenceInquiryForm.Validated) => void
  onExport: () => void
  isShowBtnExport: boolean
}) => {
  const { t } = useTranslation()
  const { onSearch, onExport, isShowBtnExport = false } = props
  const [expanded, setExpanded] = React.useState<boolean>(true)
  const { base, handleSubmit } = form2.useForm(IndependenceInquiryForm.codec, {
    defaultValues: {
      fromDate: new Date(),
      toDate: new Date(),
      transactionType: {
        label: t(TransactionLabel(TransactionType.INDEPENDENT_CHECK)),
        value: TransactionType.INDEPENDENT_CHECK
      }
    }
  })
  const formError = _.get(base.formState.errors, '.message')
  const submit = handleSubmit((validated) => {
    return onSearch({
      ...validated
    })
  })

  const exportDataDS = () => {
    return onExport?.()
  }

  const transactionTypeConst = [TransactionType.INDEPENDENT_CHECK, TransactionType.VOICE_RECORDING].map((item) => ({
    label: t(TransactionLabel(item)),
    value: item
  }))

  const onChangeTransactionTypeEvent = () => {
    base.setValue('taxNumber', null)
    base.setValue('agentLicense', null)
    base.setValue('signDateCompany', null)
  }

  return (
    <View style={{ paddingHorizontal: 20 }}>
      <>
        <View style={styles.wrap}>
          <Controller
            control={base.control}
            name={'PolicyNumber'}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <RView width={'30%'} style={styles.inputStyle}>
                  <Input
                    maxLength={11}
                    title={t('ServiceInquiry:PolicyNumber')}
                    value={value ?? ''}
                    onChange={onChange}
                    errorMessage={error?.message}
                  />
                </RView>
              )
            }}
          />
          <Controller
            control={base.control}
            name={'ProposalNumber'}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <RView width={'30%'} style={styles.inputStyle}>
                  <Input
                    maxLength={11}
                    title={t('ServiceInquiry:ProposalNumber')}
                    value={value ?? ''}
                    onChange={onChange}
                    errorMessage={error?.message}
                  />
                </RView>
              )
            }}
          />
          <Controller
            control={base.control}
            name={'IDnumber'}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <RView width={'30%'} style={styles.inputStyle}>
                  <Input
                    maxLength={24}
                    title={t('ServiceInquiry:IDNumber')}
                    onChange={(value) => onChange(PulseOpsFormat.positiveNumber(value ?? ''))}
                    value={value ?? ''}
                    errorMessage={error?.message}
                  />
                </RView>
              )
            }}
          />
        </View>

        <View style={styles.wrap}>
          <Controller
            control={base.control}
            name={'fromDate'}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <RView width={'30%'} style={styles.inputStyle}>
                  <DatePicker
                    required
                    label={t('ServiceInquiry:FromDate')}
                    onChange={onChange}
                    value={value}
                    maxDate={new Date()}
                    // minDate={toDate ?? undefined}
                    maxDateMessage={t('message:MS990032')}
                    errorMessage={error?.message}
                  />
                </RView>
              )
            }}
          />

          <Controller
            control={base.control}
            name={'toDate'}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <RView width={'30%'} style={styles.inputStyle}>
                  <DatePicker
                    required
                    label={t('ServiceInquiry:ToDate')}
                    onChange={onChange}
                    value={value}
                    maxDate={new Date()}
                    maxDateMessage={t('message:MS990032')}
                    errorMessage={error?.message}
                  />
                </RView>
              )
            }}
          />

          <Controller
            name="category"
            control={base.control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
              return (
                <RView width={'30%'} style={styles.inputStyle}>
                  <Select
                    label={t('ServiceInquiry:Category')}
                    options={[
                      {
                        label: 'IDC',
                        value: Category.INDEPENDENCE_CHECK
                      }
                    ]}
                    placeholder={t('common:Select')}
                    onChange={(e) => {
                      onChange(e)
                      // base.setValue('transactionType', [])
                    }}
                    onBlur={onBlur}
                    value={{
                      label: 'IDC',
                      value: Category.INDEPENDENCE_CHECK
                    }}
                    disabled={true}
                    errorMessage={error?.message}
                  />
                </RView>
              )
            }}
          />
        </View>
        {expanded && (
          <>
            <View style={styles.wrap}>
              <Controller
                name="transactionType"
                control={base.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                  return (
                    <RView width={'30%'} style={styles.inputStyle}>
                      <Select
                        label={t('ServiceInquiry:TransactionType')}
                        options={transactionTypeConst}
                        placeholder={t('common:Select')}
                        onChange={(val) => {
                          onChange(val)
                          onChangeTransactionTypeEvent()
                        }}
                        value={value}
                      // errorMessage={error?.message}
                      />
                    </RView>
                  )
                }}
              />
              <Controller
                name="source"
                control={base.control}
                render={({ field: { onChange, value }, fieldState: { error } }) => {
                  return (
                    <RView width={'30%'} style={styles.inputStyle}>
                      <SelectSearch
                        label={t('ServiceInquiry:Source')}
                        options={sourceIDCOptions}
                        placeholder={t('common:Select')}
                        onChange={onChange}
                        value={value ?? undefined}
                        multiple={true}
                        errorMessage={error?.message}
                      />
                    </RView>
                  )
                }}
              />
              {base.getValues("transactionType") && base.getValues("transactionType")?.value === TransactionType.VOICE_RECORDING && (
                <>
                  <Controller
                    control={base.control}
                    name={'taxNumber'}
                    render={({ field: { value, onChange }, fieldState: { error } }) => {
                      return (
                        <RView width={'30%'} style={styles.inputStyle}>
                          <Input
                            maxLength={15}
                            title={t('ServiceInquiry:TaxNumber')}
                            value={value ?? ''}
                            onChange={onChange}
                            errorMessage={error?.message}
                          />
                        </RView>
                      )
                    }}
                  />
                  <Controller
                    control={base.control}
                    name={'agentLicense'}
                    render={({ field: { value, onChange }, fieldState: { error } }) => {
                      return (
                        <RView width={'30%'} style={styles.inputStyle}>
                          <Input
                            maxLength={15}
                            title={t('ServiceInquiry:BusinessLicense')}
                            value={value ?? ''}
                            onChange={onChange}
                            errorMessage={error?.message}
                          />
                        </RView>
                      )
                    }}
                  />
                  <Controller
                    control={base.control}
                    name={'signDateCompany'}
                    render={({ field: { value, onChange }, fieldState: { error } }) => {
                      return (
                        <RView width={'30%'} style={styles.inputStyle}>
                          <DatePicker
                            alwaysShow
                            label={t('ServiceInquiry:SignDateCompany')}
                            onChange={onChange}
                            value={value}
                            // maxDate={new Date()}
                            // maxDateMessage={t('message:MS990032')}
                            errorMessage={error?.message}
                          />
                        </RView>
                      )
                    }}
                  />
                </>
              )}
            </View>
          </>
        )}
      </>

      <View style={[styles.row, { alignItems: 'center' }]}>
        <TouchableOpacity
          style={{ width: 118, height: 40 }}
          onPress={() => {
            //console.log(base.getValues())
            submit()
          }}
        >
          <View style={styles.btn}>
            <Text style={{ textAlign: 'center', color: '#fff' }}>{t('common:Search')}</Text>
          </View>
        </TouchableOpacity>
        {/* <TouchableOpacity
          style={{ width: 118, height: 40, marginLeft: 30 }}
          onPress={() => {
            exportDataDS()
          }}
          disabled={!isShowBtnExport}
        >
          <View style={!isShowBtnExport ? styles.btnDisabled : styles.btn}>
            <Text style={{ textAlign: 'center', color: '#fff' }}>{t('common:Export')}</Text>
          </View>
        </TouchableOpacity> */}

        <TouchableOpacity
          style={{ width: 118, height: 40, marginLeft: 30, flexDirection: 'row', alignItems: 'center' }}
          onPress={() => setExpanded(!expanded)}
        >
          <Text style={{ textAlign: 'center', lineHeight: 40, fontSize: 16 }}>
            {t(expanded ? 'common:Collapse' : 'common:Expand')}
          </Text>
          <Text style={{ marginLeft: 3 }}>{!expanded ? <assets.ExpandIcon /> : <assets.CollapseIcon />}</Text>
        </TouchableOpacity>
      </View>
      {formError && <Text style={{ color: '#ed1b2e', marginVertical: 12 }}>{formError}</Text>}
    </View>
  )
}
export interface IDCTableRef {
  onSearch: (data: HistoricTask.Filter) => void
  onExport: () => void
}

export const IDCTable = React.forwardRef(
  (props: { onShowBtnExport: (e: boolean) => void }, ref: React.Ref<DSTableRef>) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const { navigate } = useNavigation()
    const [pageNum, setPageNum] = React.useState<number>(0)
    const [pageSize, setPageSize] = React.useState<number>(10)
    const [totalItem, setTotalItem] = React.useState<number>(0)
    const [DSData, setDSData] = React.useState<TaskIDCOp[]>([])
    const [submittedData, setSubmittedData] = React.useState<HistoricTask.Filter>({})
    const [commentVisible, setCommentVisible] = React.useState<boolean>(false)
    const [commentData, setCommentData] = React.useState<TaskComment[]>([])
    const [loading, bindLoading] = useLoading(false)
    const { height } = useWindowDimensions()
    const [messageResponse, setMessageResponse] = React.useState<string | null>(null)
    const [sortItem, setSortItem] = React.useState<{ field: string; order: 'asc' | 'desc' | undefined }>({
      field: '',
      order: 'asc'
    })
    React.useImperativeHandle(ref, () => ({
      onSearch: (data) => {
        setSubmittedData(data)
        let dataSearch = {
          ...data
        }
        getPremiumCollectionTask(dataSearch, pageSize, pageNum)
      },
      onExport: () => {
        if (DSData.length > 0) {
          exportDSData()
        }
      }
    }))

    React.useEffect(() => {
      if (DSData.length > 0) {
        return props?.onShowBtnExport?.(true)
      }
      return props?.onShowBtnExport?.(false)
    }, [DSData])

    const getPremiumCollectionTask = (submittedData: HistoricTask.Filter, pageSize: number, pageNum: number) => {
      const signDateCompany = submittedData.signDateCompany ? PulseOpsFormat.serviceInquiry_StartOfDate(submittedData.signDateCompany, true) : undefined
      pipe(
        TaskService.getHistoryTasksIDC({
          pagination: {
            pageIndex: pageNum,
            pageSize: pageSize
          },
          order: 'desc',
          filter: {
            ...submittedData,
            fromDate: submittedData.fromDate
              ? PulseOpsFormat.serviceInquiry_StartOfDate(submittedData.fromDate, true)
              : undefined,
            toDate: submittedData.toDate
              ? PulseOpsFormat.serviceInquiry_endOfDate(submittedData.toDate, true)
              : undefined,
            transactionType: submittedData.transactionType,
            signDateCompany: signDateCompany
          }
        }),
        ZIO.tap((res) => {
          setTotalItem(res.total)
          setMessageResponse(res.items.length === 0 ? t('message:MS030029') : null)
          return ZIO.unit
        }),
        ZIO.tap((res) => {
          const wrapItem = res.items.map((item) => ({
            ...item
          }))
          setDSData(wrapItem)
          return ZIO.unit
        }),
        bindLoading,
        ErrorHandling.run()
      )
    }

    const exportDSData = () => {
      const customedFromDate = submittedData.fromDate as Date
      const customedToDate = submittedData.toDate as Date
      const selectedCategory = submittedData?.categories?.[0] ?? ''
      const nameCategory = ServiceInquiryCategories.find((x) => x.value === selectedCategory)

      const summary = {
        fromDate: customedFromDate,
        toDate: customedToDate,
        title: t('ServiceInquiry:DistributionService'),
        category: nameCategory?.label ?? ''
      }

      const columns = DistributionServicesExportConst.displayedColumns.map(({ name, widthPx, horizontal }) => ({
        name: t(name),
        horizontal,
        width: widthPx
      }))

      const exportDataSource = mappingDataSource(DSData)

      const rows = exportDataSource.map(
        ({
          no,
          caseID,
          eRef,
          source,
          agentCode,
          idNumber,
          name,
          phone,
          category,
          transactionType,
          createdDate,
          assignee,
          aging,
          lastUpdateBy,
          lastUpdatedDate,
          status,
          currentActivity
        }) => [
            no,
            caseID,
            eRef,
            source,
            agentCode,
            idNumber,
            name,
            phone,
            category,
            transactionType,
            createdDate,
            assignee,
            aging,
            lastUpdateBy,
            lastUpdatedDate,
            status,
            currentActivity
          ]
      )

      return pipe(
        ReportService.exportTemplateRP0001(
          { ref: 'B8', colRef: 2, rowRef: 8 },
          summary,
          columns,
          rows,
          DistributionServicesExportConst.Extension.EXCEL,
          t('ServiceInquiry:DistributionService')
        ),
        ZIO.unsafeRun({})
      )
    }
    const mappingDataSource = (data: TaskDSOp[], start: number = 0) => {
      const filteredDataSource = data.map((item, index) => ({
        no: (start + index + 1).toString(),
        caseID: item.caseId,
        eRef: item.eref ?? '-',
        source: item.source,
        agentCode: item.agentCode ?? '-',
        idNumber: item.clientNumber ?? '-',
        name: item.agentLastName + ' ' + item.agentFirstName,
        phone: item.agentPhoneNumber,
        category: convertCategoryToLabel(item.category),
        transactionType: item.transactionType?.replace(/_/g, ' '),
        createdDate: !!item.createdDate ? PulseOpsFormat.date(new Date(item.createdDate)) : '-',
        assignee: item.assigneeName,
        aging: item.aging,
        lastUpdateBy: item.lastUpdateByAdj
          ? item.lastUpdateByAdj.split('@')[0]
          : item.lastUpdateByVer
            ? item.lastUpdateByVer.split('@')[0]
            : '-',
        lastUpdatedDate: item.lastUpdateDateAdj
          ? PulseOpsFormat.datetoFormat(item.lastUpdateDateAdj, 'DD/MM/YYYY HH:mm:ss')
          : item.lastUpdateDateVer
            ? PulseOpsFormat.datetoFormat(item.lastUpdateDateVer, 'DD/MM/YYYY HH:mm:ss')
            : '-',
        status: item.status?.replace(/_/g, ' '),
        currentActivity: item.activity
      }))

      return filteredDataSource
    }

    const viewCommentList = (processId: string) => {
      pipe(
        PulseOpsService.getCommentList(processId),
        ZIO.tap((comments) => {
          setCommentData(comments)
          return ZIO.unit
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )

      setCommentVisible(true)
    }

    const convertCategoryToLabel = (category: string): string => {
      const converted = CategoryToLabel(category)
      if (converted) {
        return t(`Reports:${converted}`)
      } else {
        return ''
      }
    }

    const navigateHistoryDetailPage = (row: TaskIDCOp) => {
      switch (row.transactionType) {
        case TransactionType.INDEPENDENT_CHECK:
          navigate('IDCDetailStack', {
            screen: 'IDCDetailScreen',
            params: {
              caseID: row.caseId || '',
              channel: row.channel || '',
              policyNumber: row.policyNumber,
              clientID: row.clientNumber,
              policyOwnerName: row.poName,
              idcType: row.idcType,
              transactionType: row.transactionType,
              processInstanceID: row.processInstanceId,
              proposalNumber: row.proposalNumber || '',
              category: row.category || ''
            }
          })
          break
        case TransactionType.VOICE_RECORDING:
        default:
          navigate('TaskDetailStack', {
            screen: 'TaskDetailScreen',
            params: {
              id: row.id,
              caterogy: row.category,
              basket: '',
              basketName: '',
              isInquiry: '1',
              isHistory: 'true',
              isBreadcrumb: '1',
              isPCWriteOff: row.suspendReasonPC === 'WRITE_OFF' ? 'true' : 'false'
            }
          })
          break
      }
    }

    if (loading) {
      return <LinearProgress color="secondary" />
    }

    return (
      <View>
        {messageResponse ? (
          <View style={{ marginHorizontal: 30 }}>
            <Text style={{ color: '#ED1B2E' }}>{messageResponse}</Text>
          </View>
        ) : DSData.length > 0 ? (
          <View style={{ marginHorizontal: 30 }}>
            <TableContainer component={Paper} style={{ maxHeight: 500 }} elevation={1}>
              <Table style={{ userSelect: 'text' }} stickyHeader>
                {/* <TableHead className={classes.tableHeader}>
                  <TableRow>
                    <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:CaseID')}</TableCell>
                    <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:Source')}</TableCell>
                    <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:AgentCode')}</TableCell>
                    <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:POID')}</TableCell>
                    <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:PolicyNumber')}</TableCell>
                    <TableCell className={classes.tableCell}>{t('ServiceInquiry:proposalNumber')}</TableCell>
                    <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:POName')}</TableCell>
                    <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:POPhoneNumber')}</TableCell>
                    <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:Category')}</TableCell>
                    <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:CreatedDate')}</TableCell>
                    <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:Aging')}</TableCell>
                    <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:Status')}</TableCell>
                    <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:CurrentActivity')}</TableCell>
                    <TableCell className={classes.tableCell} style={{ textAlign: 'center' }}>
                      {t('ServiceInquiry:table:CallTimes')}
                    </TableCell>
                    <TableCell className={classes.tableCell} style={{ textAlign: 'center' }}>
                      {t('ServiceInquiry:table:SMSTimes')}
                    </TableCell>
                  </TableRow>
                </TableHead> */}
                <TableHead className={classes.tableHeader}>
                  <TableHeaderSort
                    data={DSData}
                    setData={setDSData}
                    sortItem={sortItem}
                    setSortItem={setSortItem}
                    column={[
                      {
                        label: t('ServiceInquiry:table:CaseID'),
                        disabled: true,
                        field: 'caseId'
                      },
                      {
                        label: t('ServiceInquiry:table:Source'),
                        disabled: true,
                        field: 'source'
                      },
                      {
                        label: t('ServiceInquiry:table:AgentCode'),
                        disabled: true,
                        field: 'agentCode'
                      },
                      {
                        label: t('ServiceInquiry:table:POID'),
                        disabled: true,
                        field: 'idNumber'
                      },
                      {
                        label: t('ServiceInquiry:table:PolicyNumber'),
                        disabled: true,
                        field: 'policyNumber'
                      },
                      {
                        label: t('ServiceInquiry:proposalNumber'),
                        disabled: true,
                        field: 'proposalNumber'
                      },
                      {
                        label: t('ServiceInquiry:table:POName'),
                        disabled: true,
                        field: 'poName'
                      },
                      // {
                      //   label: t('ServiceInquiry:table:POPhoneNumber'),
                      //   disabled: true,
                      //   field: 'poPhoneNumber'
                      // },
                      {
                        label: t('ServiceInquiry:table:Category'),
                        field: 'category'
                      },
                      {
                        label: t('ServiceInquiry:table:CreatedDate'),
                        field: [TransactionType.VOICE_RECORDING].includes(!!submittedData && !!submittedData.transactionType ?
                          submittedData?.transactionType[0] : '' as TransactionType) ?
                          'startTime' : 'createdDate',
                      },
                      {
                        label: t('ServiceInquiry:table:Aging'),
                        disabled: true,
                        field: 'aging'
                      },
                      {
                        label: t('ServiceInquiry:table:Status'),
                        field: 'status',
                        disabled: true
                      },
                      {
                        label: t('ServiceInquiry:table:CurrentActivity'),
                        disabled: true,
                        field: 'activity'
                      },
                      {
                        label: t('ServiceInquiry:table:CallTimes'),
                        disabled: true,
                        field: 'callTimes',
                        visible: !!submittedData && !!submittedData.transactionType ? submittedData?.transactionType[0] === TransactionType.INDEPENDENT_CHECK : false
                      },
                      {
                        label: t('ServiceInquiry:table:SMSTimes'),
                        disabled: true,
                        field: 'smsTimes',
                        visible: !!submittedData && !!submittedData.transactionType ? submittedData?.transactionType[0] === TransactionType.INDEPENDENT_CHECK : false
                      },
                    ]}
                  />
                </TableHead>
                <TableBody>
                  {DSData.map((row, i) => {
                    return (
                      <TableRow key={`TableRow-${i}`}>
                        {/* CaseID */}
                        <TableCell>
                          {row.caseId && (
                            <Pressable
                              onPress={() =>
                                navigateHistoryDetailPage(row)
                              }
                            >
                              <Text style={[{ color: '#1EA5FC' }, styles.cellNoWrap]}>{row.caseId}</Text>
                            </Pressable>
                          )}
                        </TableCell>
                        {/* Eref */}
                        {/* <TableCell>{row.eref || '-'}</TableCell> */}
                        {/* Source */}
                        <TableCell>{row.source}</TableCell>
                        {/* AgentCode */}
                        <TableCell>{row.agentCode || '-'}</TableCell>
                        {/* IDNo */}
                        <TableCell>{row.idNumber || '-'}</TableCell>
                        <TableCell>{row.policyNumber || '-'}</TableCell>
                        <TableCell>{row.proposalNumber || '-'}</TableCell>
                        {/* Name */}
                        <TableCell>{row.poName || '-'}</TableCell>
                        {/* poPhoneNumber */}
                        {/* <TableCell>{row.poPhoneNumber || '-'}</TableCell> */}
                        {/* category */}
                        <TableCell className={classes.cellNoWrap}>
                          {convertCategoryToLabel(row.category) || '-'}
                        </TableCell>
                        {/* transactionType */}
                        {/* <TableCell>{row.transactionType?.replace(/_/g, ' ') || '-'}</TableCell> */}
                        {/* CreatedDate */}
                        <TableCell>
                          {row.transactionType === TransactionType.VOICE_RECORDING ?
                            PulseOpsFormat.datetoFormat(new Date(row.startTime || ''), 'DD/MM/YYYY HH:mm:ss')
                            : PulseOpsFormat.datetoFormat(row.createdDate as unknown as Date, 'DD/MM/YYYY HH:mm:ss')}
                        </TableCell>
                        {/* Aging */}
                        <TableCell>{row.aging || '-'}</TableCell>
                        {/* Status */}
                        <TableCell
                          style={{ color: TransactionStatusLabel(row.status).color }}
                          className={classes.cellNoWrap}
                        >
                          {TransactionStatusLabel(row.status).label}
                        </TableCell>
                        {/* Current Activity */}
                        <TableCell className={classes.cellNoWrap}>{row.activity}</TableCell>
                        {[TransactionType.INDEPENDENT_CHECK].includes(row.transactionType as TransactionType) && (
                          <>
                            <TableCell>
                              <Text> {row.callTimes || '-'}</Text>
                            </TableCell>
                            <TableCell>
                              <Text> {row.smsTimes || '-'}</Text>
                            </TableCell>
                          </>
                        )}

                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              page={pageNum}
              rowsPerPage={pageSize}
              count={totalItem}
              onPageChange={(e, page) => {
                setPageNum(page)
                getPremiumCollectionTask(submittedData, pageSize, page)
              }}
              onRowsPerPageChange={(e) => {
                setPageSize(Number(e.target.value))
                setPageNum(0)
                getPremiumCollectionTask(submittedData, Number(e.target.value), 0)
              }}
              labelRowsPerPage={t('common:PaginationSize')}
              labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
              component={View}
            />
            <ModalComponent
              title={t('ServiceInquiry:CommentDetail')}
              visible={commentVisible}
              onClose={() => {
                setCommentVisible(false), setCommentData([])
              }}
              actions={[
                {
                  text: t('common:Cancel'),
                  type: 'filled',
                  disabled: false,
                  loading,
                  onlyWide: false,
                  style: { height: 40 },
                  action: () => {
                    setCommentVisible(false), setCommentData([])
                  }
                }
              ]}
              modalWidth={864}
            >
              {commentData.length > 0 && (
                <View
                  style={{
                    marginHorizontal: 20,
                    marginTop: 20,
                    maxHeight: height - 200,
                    borderWidth: 1,
                    borderRadius: 8,
                    borderColor: '#dcdcdc',
                    paddingTop: 16,
                    paddingHorizontal: 16
                  }}
                >
                  {commentData.map((user, index) => (
                    <Panel
                      key={index}
                      title={user.username}
                      comment={PulseOpsFormat.datetoFormat(user.time, 'DD/MM/YYYY HH:mm:ss')}
                      isHiddenTitle={false}
                      isExand={true}
                      isHaveComment
                    >
                      {/*  */}
                      <Text>{user.message}</Text>
                    </Panel>
                  ))}
                </View>
              )}
            </ModalComponent>
          </View>
        ) : (
          <View />
        )}
      </View>
    )
  }
)

const styles = StyleSheet.create({
  section: {
    marginLeft: 30,
    marginRight: 30
  },

  searchContainer: {
    backgroundColor: '#FAFAFA',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    marginHorizontal: 30,
    marginVertical: 30,
    paddingBottom: 16
  },

  titleContainer: {
    backgroundColor: '#EAEAEA',
    paddingHorizontal: 0,
    marginBottom: 16
  },

  row: {
    flexDirection: 'row',
    paddingHorizontal: 16
  },

  col: {
    width: '33.33%',
    marginBottom: 16,
    paddingHorizontal: 16
  },

  btn: {
    width: 118,
    height: 40,
    backgroundColor: '#ed1b2e',
    borderRadius: 100,
    padding: 10,
    boxShadow: 'none'
  },

  btnDetail: {
    width: 80,
    height: 40,
    backgroundColor: '#ebe9e9',
    borderRadius: 100,
    padding: 10
  },

  tableHeader: {
    backgroundColor: '#E5EAEF'
  },

  cellNoWrap: {
    whiteSpace: 'nowrap'
  } as TextStyle,

  wrap: {
    flexWrap: 'wrap',
    flexDirection: 'row'
  },
  inputStyle: {
    marginHorizontal: 15,
    marginBottom: 15
  },

  btnDisabled: {
    width: 118,
    height: 40,
    backgroundColor: '#ed1b2e',
    borderRadius: 100,
    padding: 10,
    boxShadow: 'none',
    opacity: 0.5
  }
} as const)
