import {
  AppContext,
  form2,
  Select,
  useMobile,
  ImgUploadMutiple,
  Input,
  DatePicker,
  assets,
  SelectSearch,
  ChangeClientInfoService,
  GeneralService,
  ErrorHandling,
  RadioButton,
  ChangeClientInfo as ChangeClientData,
  PulseOpsFormat,
  TransactionType,
  // StorageBlob,
  TaskType,
  SelectOption
} from '@pulseops/common'
import { useIsFocused, useNavigation } from '@react-navigation/core'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { PolicyServiceProps, UploadedFilesInfo } from '../policy-service-props'
import { SC, ConfirmView, CustomCheckBox, FilePresent, DuplicateTable } from '../../common'
import { ChangeClientInfoForm } from './change-client-info-form'
import { Controller } from 'react-hook-form'
import _ from 'lodash'
import { pipe } from 'fp-ts/lib/function'
import { Throwable, ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import * as O from 'fp-ts/lib/Option'
import { BenIDCard, BenIDCardOption, Gender } from './change-client-info-const'
import moment from 'moment'
import { RequestAuthenticateData } from '../../request-authen'

type Props = PolicyServiceProps<ChangeClientData.DataSubmit> & {
  officeCode?: string
}

const initForm = {
  clientDetail: {
    requireIssue: false,
    files: [],
    requireIssueDate: false
  },
  chooseOccupation: false
}

export const ChangeClientInfo: React.FC<Props> = ({ initSubmission, policyNumber, isConfirmed, officeCode }) => {
  const { t, i18n } = useTranslation()
  const { changeBreadcrumb, showGlobalLoading, showToast } = React.useContext(AppContext.AppContextInstance)
  const isFocused = useIsFocused()
  const { navigate } = useNavigation()
  const [loading, bindLoader] = useLoading()
  const [occupationItem, setOccupationItem] = useState<ChangeClientInfoService.OccupationItem>()
  const [occupationMessage, setOccupationMessage] = useState<string>('')
  const [districtList, setDistrictList] = React.useState<SelectOption[]>([])
  const [wardList, setWardList] = React.useState<SelectOption[]>([])
  const {
    base: {
      control,
      getValues,
      setValue,
      formState: { errors },
      watch,
      reset,
      trigger,
      clearErrors,
      setError
    }
  } = form2.useForm(ChangeClientInfoForm.codec, {
    defaultValues: initForm
  })
  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigate('HomeScreen')
          }
        },
        {
          title: t('Submission:EForm'),
          navigate: () => navigate('StaffSubmissionStack', { screen: 'StaffSubmissionScreen' })
        },
        {
          title: t('common:PolicyServices'),
          navigate: () => navigate('PSSubmissionStack', { screen: 'PSSubmissionListScreen' })
        },
        { title: t('TransactionType:CHANGE_CLIENT_INFO'), navigate: null }
      ])
    }
  }, [isFocused])

  React.useEffect(() => {
    showGlobalLoading(loading)
  }, [loading])

  const getUploadedFilesInfo = () => {
    let uploadedFileList: UploadedFilesInfo[] = []
    const currentFiles = getValues('clientDetail.files') as ChangeClientInfoForm.FileMeta[]
    if (currentFiles && currentFiles.length > 0) {
      uploadedFileList.push({
        uploadFiles: currentFiles,
        transactionType: TransactionType.CHANGE_CLIENT_INFO,
        docTypeCode: 'DPS10',
        category: TaskType.PolicyService,
        policyNumber: policyNumber ?? '',
        officeCode: officeCode ?? ''
      })
    }
    return uploadedFileList
  }

  const validateOccupation = () => {
    return !watch('chooseOccupation') || !occupationMessage
  }

  initSubmission({
    validate: async (isContinue) => {
      const isValidForm = await trigger()
      // console.log('errororor', errors)
      if (isValidForm && validateOccupation()) {
        if (detail) {
          // const currentFiles = getValues('clientDetail.files') ?? []
          // let fileMeta: StorageBlob.FileContentSubmit[] = []
          // if (!isContinue && currentFiles.length > 0) {
          //   fileMeta = await pipe(
          //     GeneralService.getMetaData(TransactionType.CHANGE_CLIENT_INFO, 'DPS10', officeCode),
          //     ZIO.flatMap(({ data }) => {
          //       const metaRaw: StorageBlob.MetaDataUpload = {
          //         type: data.transactionType,
          //         doctype: data.doctypeEn,
          //         class: data.classFilenet,
          //         docid: data.docID,
          //         maindoc: data.mainDoc,
          //         subdoc: data.subDoc,
          //         polnum: policyNumber!,
          //         batchno: data.batchNo
          //       }
          //       const files: StorageBlob.FileContent[] = currentFiles.map((f) => ({
          //         file: f.file!,
          //         metaData: metaRaw
          //       }))

          //       return pipe(StorageBlob.uploadToSubmit('PS', policyNumber!)(files))
          //     }),
          //     ZIO.tapError((_) => {
          //       // this.snackBarService.openError(PulseOpsMessage.MS050001)
          //       showToast(t('message:MS050001'), 'error')
          //       return ZIO.unit
          //     }),
          //     bindLoader,
          //     ErrorHandling.run({})
          //   )
          // }

          // if (!fileMeta) return false

          return {
            url: (_) => `wf-api/customer/${detail.customerId}`,
            collerationId: policyNumber ?? '',
            transactionName: RequestAuthenticateData.TransactionLabelShort(TransactionType.CHANGE_CLIENT_INFO),
            transaction: TransactionType.CHANGE_CLIENT_INFO,
            body: mapData(),
            // documents: fileMeta,
            uploadedFilesInfo: getUploadedFilesInfo()
          }
        }
        return false
      }
      return false
    },
    clear: () => {
      reset(initForm)
    }
  })

  const phoneDups = watch('clientDetail.dupPhone') ?? []
  const emailDups = watch('clientDetail.dupEmail') ?? []

  const mapData = (): ChangeClientData.DataSubmit => {
    const { clientDetail, chooseOccupation } = getValues()
    const occupation = getValues('occupation')
    return {
      clientId: detail.customerId,
      surName: clientDetail.surname ?? '',
      firstName: clientDetail.givenName ?? '',
      dob: PulseOpsFormat.datetoFormat(clientDetail.dob ?? new Date(), 'yyyyMMDD'),
      oldDob: PulseOpsFormat.datetoFormat(clientDetail.oldDob ?? new Date(), 'yyyyMMDD'),
      sex: clientDetail.gender?.value ?? '',
      nationality: clientDetail.nationality?.value ?? '',
      occupation: occupation?.occupation?.value ?? '',
      contactDetails: {
        phone: {
          countryCode: clientDetail.mobileCode?.value ?? '',
          value: clientDetail.mobileNumber ?? ''
        },
        email: {
          value: clientDetail.email ?? ''
        }
      },
      addressDetails: {
        ADDRESS: {
          line1: clientDetail.street ?? '',
          city: clientDetail.city?.label ?? '',
          district: clientDetail.district?.label ?? '',
          subDistrict: clientDetail.ward?.label ?? '',
          countryCode: clientDetail.country?.value ?? '',
          location: clientDetail.ward?.value ?? ''
        },
        FOREIGN_ADDRESS: {
          line1: clientDetail.foreignAddress ?? '',
          countryCode: clientDetail.countryOfForeignAddress?.value ?? ''
        }
      },
      attributesExt: {
        idType: clientDetail.idType?.value ?? '',
        idNumber: clientDetail.idNumber ?? '',
        issuedBy: clientDetail.issueBy?.value ?? '',
        usTaxDeclarationFrom: clientDetail.taxInUS ? 'Y' : 'N',
        occupationFlag: chooseOccupation ? 'Y' : 'N',
        specificJob: occupation?.jobDescription ?? '',
        title: occupation?.jobTitle ?? '',
        workPlace: occupation?.companyName ?? '',
        workAddress: occupation?.companyAddress ?? '',
        salary: occupation?.salary?.value ?? '',
        clientNum: clientDetail.client?.value ?? '',
        // issuedDate: PulseOpsFormat.datetoFormat(clientDetail.dob ?? new Date(), 'yyyyMMDD'),
        issuedDate: PulseOpsFormat.datetoFormat(clientDetail.issuedDate, 'YYYY-MM-DD'),
        duplicatePhones:
          clientDetail.dupPhone?.map((p) => ({
            clientName: p.clientName ?? '',
            clientNum: p.clientId ?? '',
            relationship: p.relationship?.value ?? 'AG'
          })) ?? [],
        duplicateEmails:
          clientDetail.dupEmail?.map((e) => ({
            clientName: e.clientName ?? '',
            clientNum: e.clientId ?? '',
            relationship: e.relationship?.value ?? 'AG'
          })) ?? [],
        NATIONALITY_2: clientDetail.nationality2?.value ?? '',
        nationality2Name: clientDetail.nationality2?.label ?? '',
        countryName: clientDetail.country?.label ?? '',
        districtName: clientDetail.district?.label ?? '',
        wardName: clientDetail.ward?.label ?? '',
        taxResidencyCountry: clientDetail.taxResidencyCountry?.value ?? ''
      }
    }
  }

  const getRole = (role: string) => {
    return role === 'PolicyOwner'
      ? t('roles:PolicyOwner')
      : role === 'Beneficiary'
        ? t('roles:Beneficiary')
        : role === 'LifeAssured'
          ? t('roles:LifeAssured')
          : '-'
  }

  const {
    detail,
    occupationsOptions,
    provincesOptions,
    countriesOptions,
    mobileCodeOptions,
    salaryOptions,
    namesOptions,
    relationshipOptions
  } = pipe(
    ZIO.zipPar(
      ChangeClientInfoService.getDetail(policyNumber!),
      GeneralService.getProvinces,
      GeneralService.getOccupations,
      GeneralService.getCountries,
      GeneralService.getMobileCodes,
      GeneralService.getSalaries,
      GeneralService.getRelationship()
    ),
    ZIO.map(([detail, provinces, occupations, countries, mobileCodes, salaries, relationships]) => {
      const namesOptions = detail.customerData.map((dc) => ({
        value: dc.customerId,
        label: `${dc.surName ?? ''} ${dc.name} - ${getRole(dc.role)}`
      }))

      const provincesOptions = provinces.map((p) => ({
        value: p.code,
        label: p.name
      }))

      const occupationsOptions = occupations.map((o) => ({
        value: o.code,
        label: o.name
      }))

      const countriesOptions = countries.map((c) => ({
        value: c.code,
        label: c.name
      }))

      const mobileCodeOptions = mobileCodes.map((m) => ({
        value: m.code,
        label: `+${m.code}`
      }))

      const salaryOptions = salaries.map((s) => ({
        value: s.code,
        label: i18n.language === 'vi' ? s.name : s.nameEn
      }))

      const relationshipOptions = relationships
        .filter((r) => r.changeClientInfo === 'Y')
        .map((r) => ({
          label: i18n.language === 'vi' ? r.nameVn : r.nameEn,
          value: r.code
        }))

      setValue('clientDetail.country', pipe(countriesOptions.find((p) => p.value === 'VN'), O.fromNullable, O.toNullable))

      return {
        detail,
        provincesOptions,
        occupationsOptions,
        countriesOptions,
        mobileCodeOptions,
        salaryOptions,
        namesOptions,
        relationshipOptions
      }
    }),
    bindLoader,
    ErrorHandling.runDidMount({})
  ) || {
      detail: null,
      provincesOptions: [],
      occupationsOptions: [],
      countriesOptions: [],
      mobileCodeOptions: [],
      salaryOptions: [],
      namesOptions: [],
      relationshipOptions: []
    }
  const idCardOptions = React.useMemo(() => {
    return BenIDCardOption((value) => t(`submission:${value}`))
  }, [i18n.language])

  const genderOptions = React.useMemo(() => {
    return Gender.map((g) => ({
      label: i18n.language === 'vi' ? g.nameVi : g.nameEn,
      value: g.value
    }))
  }, [i18n.language])

  const idTypeOps = (dob: Date) => {
    if (moment().subtract(16, 'years').isAfter(moment(dob))) {
      return idCardOptions.filter((i) => i.value !== BenIDCard.BIRTH_CERTIFICATE)
    }
    return idCardOptions
  }

  const isSameClient = (
    relationship: ChangeClientInfoForm.RelationShip,
    clientInfo?: ChangeClientData.CustomerData
  ): boolean => {
    if (clientInfo) {
      return (
        relationship.clientName === `${clientInfo.surName} ${clientInfo.name}` &&
        relationship.dob === clientInfo.dob &&
        relationship.gender === clientInfo.gender &&
        relationship.secuityNo === clientInfo.idNum
      )
    }
    return false
  }

  const getDupEmail = (email: string, clientId: string) =>
    pipe(
      ChangeClientInfoService.getDuplicateEmailInfo(email, clientId),
      ZIO.map((emails) => {
        // console.log('emialssss', emails)
        const clientInfo = detail?.customerData?.find((c) => c.customerId === clientId)
        const emailDup: ChangeClientInfoForm.RelationShip[] = emails.map((email) => ({
          clientId: email.clientId,
          clientName: email.clientName,
          relationship: null,
          role: email.role,
          secuityNo: email.secuityNo,
          dob: email.dob,
          gender: email.gender,
          poName: email.clientName
          //sameClient: isSameClient(email, clientInfo)
        }))
        setValue('clientDetail.dupEmail', emailDup?.filter((e) => !isSameClient(e, clientInfo)) ?? [])
        return ZIO.unit
      }),
      bindLoader,
      ErrorHandling.run({})
    )

  const getDupPhone = (phone: string, clientId: string) =>
    pipe(
      ChangeClientInfoService.getDuplicatePhoneInfo(phone, clientId),
      ZIO.map((phones) => {
        // console.log('phonesss', phones)
        const clientInfo = detail?.customerData?.find((c) => c.customerId === clientId)
        const phoneDup: ChangeClientInfoForm.RelationShip[] = phones.map((phone) => ({
          clientId: phone.clientId,
          clientName: phone.clientName,
          relationship: null,
          role: phone.role,
          secuityNo: phone.secuityNo,
          dob: phone.dob,
          gender: phone.gender,
          poName: phone.clientName
          //sameClient: isSameClient(phone, clientInfo)
        }))
        // console.log('phoneeeeDup', phoneDup)
        setValue('clientDetail.dupPhone', phoneDup?.filter((p) => !isSameClient(p, clientInfo)) ?? [])
        return ZIO.unit
      }),
      bindLoader,
      ErrorHandling.run({})
    )

  const getOccupationItemByCode = (code: string) =>
    pipe(
      ChangeClientInfoService.getOccupationItemByCode(code),
      ZIO.map((response) => {
        if (!!response && Object.keys(response).length > 0) {
          const occupationItem = response as ChangeClientInfoService.OccupationItem
          setOccupationItem(occupationItem)
          setValue('occupation.jobTitle', occupationItem.positionName)
          // setOccupationMessage('')
        } else {
          // setOccupationMessage(t('message:MS020040'))
        }
      }),
      bindLoader,
      ErrorHandling.run({})
    )

  const predicateAgent = (relationship: ChangeClientInfoForm.RelationShip) => relationship.role === 'AG'

  const anyAgentPhone = React.useMemo(() => phoneDups?.find(predicateAgent), [phoneDups])
  const anyAgentEmail = React.useMemo(() => emailDups?.find(predicateAgent), [emailDups])

  // const phoneDupsFilter = React.useMemo(() => phoneDups?.filter((p) => !p.sameClient), [phoneDups])
  // const emailDupsFilter = React.useMemo(() => emailDups?.filter((e) => !e.sameClient), [emailDups])

  const onChangeClient = (clientId: string) => {
    const client = detail?.customerData?.find((c) => c.customerId === clientId)
    //console.log('asdasdasdas', client)
    if (client) {
      pipe(
        ZIO.effect(() => {
          return {
            provinceCode: client.city,
            districtCode: client.district
          }
        }),
        ZIO.flatMap(({ provinceCode, districtCode }) => ZIO.zipPar(
          !!provinceCode ? GeneralService.getDistricts(provinceCode) : ZIO.succeed([]),
          !!provinceCode && !!districtCode ? GeneralService.getWards({ provinceCode, districtCode }) : ZIO.succeed([])
        )),
        ZIO.map(([districtArr, wardArr]) => {
          const districtItems = districtArr.map((item) => ({ label: item.name, value: item.code }))
          const wardItems = wardArr.map((item) => ({ label: item.name, value: item.code }))
          const dob = moment(client.dob).toDate()
          setValue('clientDetail.dob', dob)
          setValue('clientDetail.oldDob', dob)
          const idType = idTypeOps(dob).find((i) => client.idType === i.value)
          setValue('clientDetail.idType', idType ?? null)

          setValue('clientDetail.idNumber', client.idNum)
          const province = provincesOptions.find((p) => client.issuedAt === p.value)
          setValue('clientDetail.issueBy', province ?? null)

          setValue('clientDetail.surname', client.surName)
          setValue('clientDetail.givenName', client.name)

          setValue('clientDetail.taxInUS', client.fatca)

          // setValue('clientDetail.dob', moment(client.dob).toDate())
          const gender = genderOptions.find((g) => client.gender === g.value)
          setValue('clientDetail.gender', gender ?? null)

          const nationality = countriesOptions.find((c) => client.nationality === c.value)
          setValue('clientDetail.nationality', nationality ?? null)

          const mobileCode = mobileCodeOptions.find((m) => client.mobilePhoneCode === m.value)
          setValue('clientDetail.mobileCode', mobileCode ?? null)

          setValue('clientDetail.mobileNumber', client.mobilePhoneNumber)
          client.mobilePhoneNumber && getDupPhone(client.mobilePhoneNumber, clientId)

          setValue('clientDetail.email', client.email)
          client.email && getDupEmail(client.email, clientId)
          pathIssueDateRequire(idType?.value)
          //mapp new AML field
          setValue('clientDetail.country', pipe(countriesOptions.find((p) => p.value === client.countryCode), O.fromNullable, O.toNullable))
          setValue('clientDetail.city',
            pipe(provincesOptions.find((p) => p.value === client.city), O.fromNullable, O.toNullable)
          )
          setValue('clientDetail.district',
            pipe(districtItems.find((p) => p.value === client.district), O.fromNullable, O.toNullable)
          )
          setValue('clientDetail.ward',
            pipe(wardItems.find((p) => p.value === client.ward), O.fromNullable, O.toNullable)
          )
          setValue('clientDetail.street', client.address)
          setValue('clientDetail.foreignAddress', client.foreignAddress)
          setValue(`clientDetail.countryOfForeignAddress`, pipe(countriesOptions.find((p) => p.value === client.foreignCountry), O.fromNullable, O.toNullable))
          setValue('clientDetail.nationality2', pipe(countriesOptions.find((p) => p.value === client.nationality2), O.fromNullable, O.toNullable))
          trigger()
        }),
        bindLoader,
        ZIO.unsafeRun({})
      )
    }
  }

  const pathIssueDateRequire = (idTypeCode?: string) => {
    if (idTypeCode === 'BC') {
      setValue('clientDetail.requireIssue', true)
      // clearErrors('clientDetail.requireIssue')
      // clearErrors('clientDetail.issueBy')
      setError('clientDetail.issueBy', {
        message: t('message:MS020001', { field: t('submission:IssueBy').toLowerCase() })
      })
    } else {
      setValue('clientDetail.requireIssue', false)
      clearErrors('clientDetail.requireIssue')
      clearErrors('clientDetail.issueBy')
    }

    if (idTypeCode === 'ID') {
      setValue('clientDetail.requireIssueDate', true)
      // clearErrors('clientDetail.requireIssueDate')
      // clearErrors('clientDetail.issuedDate')
      setError('clientDetail.issuedDate', {
        message: t('message:MS020001', { field: t('submission:issuedDate').toLowerCase() })
      })
    } else {
      setValue('clientDetail.requireIssueDate', false)
      clearErrors('clientDetail.requireIssueDate')
      clearErrors('clientDetail.issuedDate')
    }
  }

  const onChangeOccupationEvent = (code: string) => {
    setValue('occupation.jobDescription', '')
    setValue('occupation.companyName', '')
    setValue('occupation.companyAddress', '')
    setValue('occupation.jobTitle', '')
    code && getOccupationItemByCode(code)
  }

  const getDistrictList = (provinceCode: string) => {
    pipe(
      ZIO.effect(() => {
        setDistrictList([])
        setValue(`clientDetail.district`, null)
      }),
      ZIO.flatMap((_) => !!provinceCode ? GeneralService.getDistricts(provinceCode) : ZIO.succeed([])),
      ZIO.map((districtList) => {
        const filterDistrict = districtList.map((item) => ({ label: item.name, value: item.code }))
        setDistrictList(filterDistrict)
        // !!oldDistrictCode && setValue(`clientDetail.district`, filterDistrict.find(x => x.value === oldDistrictCode) || null)
        return filterDistrict
      }),
      ZIO.mapError((_) => {
        return Throwable('call get districts api with error')
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }

  const getWardList = (provinceCode?: string, districtCode?: string) => {
    pipe(
      ZIO.effect(() => {
        setWardList([])
        setValue('clientDetail.ward', null)
      }),
      ZIO.flatMap((_) => !!provinceCode && !!districtCode ? GeneralService.getWards({ provinceCode, districtCode }) : ZIO.succeed([])),
      ZIO.map((wardList) => {
        const mapWards = wardList.map((_) => ({
          value: _.code,
          label: _.name
        }))
        setWardList(mapWards)
        // !!oldWardCode && setValue('clientDetail.ward', mapWards.find(x => x.value === oldWardCode) || null)
        return mapWards
      }),
      ZIO.mapError((_) => {
        return Throwable('call get wards api with error')
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }

  return (
    <SC.Container>
      <SC.Padding vertical={10}>
        <SC.SessionText>{t('submission:ChangeClientDetail').toUpperCase()}</SC.SessionText>
      </SC.Padding>
      <SC.PanelContainer>
        <RView width={'66%'}>
          <Controller
            control={control}
            name={'clientDetail.client'}
            render={({ field: { value, onChange } }) => {
              return (
                <ConfirmView title={t('requestInfo:SelectClient')} value={value?.label ?? ''} isConfirmed={isConfirmed}>
                  <Select
                    label={t('requestInfo:SelectClient')}
                    options={namesOptions ?? []}
                    value={value}
                    onChange={(v) => {
                      onChange(v)
                      if (v && v?.value) {
                        onChangeClient(v.value)
                      }
                      // onChange(v)
                      // const currentPhone =
                      //   getValues('clientDetail.mobileCode') && getValues('clientDetail.mobileNumber')
                      // const currentEmail = getValues('clientDetail.email')
                      // if (v && v?.value && currentPhone) {
                      //   getDupPhone(currentPhone, v.value)
                      // }

                      // if (v && v?.value && currentEmail) {
                      //   getDupEmail(currentEmail, v.value)
                      // }

                      // if (v && v?.value) onChangeClient(v.value)
                    }}
                    required
                    errorMessage={_.get(errors, 'clientDetail.client.message')}
                    placeholder={t('common:Select')}
                  />
                </ConfirmView>
              )
            }}
          />
        </RView>

        <SC.RowWrap>
          <RView>
            <Controller
              key={`idType${getValues('clientDetail.idType.value')}`}
              control={control}
              name={'clientDetail.idType'}
              render={({ field: { value, onChange } }) => {
                const dob = getValues('clientDetail.dob')
                return (
                  <ConfirmView title={t('submission:idType')} value={value?.label ?? ''} isConfirmed={isConfirmed}>
                    <SC.Padding top={2}>
                      <Select
                        label={t('submission:idType')}
                        options={dob ? idTypeOps(dob) : idCardOptions}
                        value={value}
                        onChange={(v) => {
                          onChange(v)
                          pathIssueDateRequire(v?.value)
                          // if (v?.value === 'BC') {
                          //   setValue('clientDetail.requireIssue', true)
                          //   clearErrors('clientDetail.requireIssue')
                          //   clearErrors('clientDetail.issueBy')
                          // } else {
                          //   setValue('clientDetail.requireIssue', false)
                          //   clearErrors('clientDetail.requireIssue')
                          //   clearErrors('clientDetail.issueBy')
                          // }

                          // if (v?.value === 'ID') {
                          //   setValue('clientDetail.requireIssueDate', true)
                          //   clearErrors('clientDetail.requireIssueDate')
                          //   clearErrors('clientDetail.issuedDate')
                          // } else {
                          //   setValue('clientDetail.requireIssueDate', false)
                          //   clearErrors('clientDetail.requireIssueDate')
                          //   clearErrors('clientDetail.issuedDate')
                          // }
                        }}
                        errorMessage={_.get(errors, 'clientDetail.idType.message')}
                        placeholder={t('common:Select')}
                        required
                      />
                    </SC.Padding>
                  </ConfirmView>
                )
              }}
            />
          </RView>
          <RView>
            <Controller
              control={control}
              name={'clientDetail.idNumber'}
              render={({ field: { value, onChange } }) => {
                return (
                  <ConfirmView title={t('submission:idNumber')} value={value ?? '-'} isConfirmed={isConfirmed}>
                    <Input
                      value={value ?? ''}
                      onChange={onChange}
                      required
                      title={t('submission:idNumber')}
                      errorMessage={_.get(errors, 'clientDetail.idNumber.message')}
                      maxLength={24}
                      placeholder={t('submission:IDNumber')}
                      containerStyle={{ paddingTop: 8 }}
                    />
                  </ConfirmView>
                )
              }}
            />
          </RView>
          <RView>
            <Controller
              key={`issueBykey${getValues('clientDetail.issueBy')}`}
              control={control}
              name={'clientDetail.issueBy'}
              render={({ field: { value, onChange } }) => {
                const requiredIssue = getValues('clientDetail.requireIssue') ?? false
                return (
                  <ConfirmView
                    title={t('submission:IssueBy')}
                    value={value?.label ?? ''}
                    isConfirmed={isConfirmed}
                    required={requiredIssue}
                  >
                    <SC.Padding top={2}>
                      <SelectSearch
                        label={t('submission:IssueBy')}
                        options={provincesOptions ?? []}
                        value={value}
                        onChange={onChange}
                        errorMessage={_.get(errors, 'clientDetail.issueBy.message')}
                        popupIcon={<assets.ArrowDownDropdownIcon />}
                        required={requiredIssue}
                        placeholder={t('common:Select')}
                      />
                    </SC.Padding>
                  </ConfirmView>
                )
              }}
            />
          </RView>
        </SC.RowWrap>
        <SC.RowWrap>
          <RView>
            <Controller
              key={`issueDateKey${getValues('clientDetail.requireIssueDate')}`}
              control={control}
              name={'clientDetail.issuedDate'}
              render={({ field: { value, onChange }, fieldState: { error } }) => {
                const required = getValues('clientDetail.requireIssueDate') ?? false
                return (
                  <ConfirmView
                    title={t('submission:issuedDate')}
                    value={value ? PulseOpsFormat.datetoFormat(value, 'DD/MM/yyyy') : '-'}
                    isConfirmed={isConfirmed}
                    required={required}
                  >
                    <SC.Padding top={0}>
                      <DatePicker
                        onChange={onChange}
                        value={value}
                        required={required}
                        label={t('submission:issuedDate')}
                        alwaysShow={!required}
                        maxDate={new Date(new Date().setHours(0, 0, 0, 0))}
                        invalidDateMessage={null}
                        minDateMessage={null}
                        maxDateMessage={null}
                        errorMessage={error?.message}
                      />
                    </SC.Padding>
                  </ConfirmView>
                )
              }}
            />
          </RView>
          <RView>
            <Controller
              control={control}
              name={'clientDetail.surname'}
              render={({ field: { value, onChange } }) => {
                return (
                  <ConfirmView title={t('submission:Surname')} value={value ?? '-'} isConfirmed={isConfirmed}>
                    <Input
                      onChange={onChange}
                      title={t('submission:Surname')}
                      value={value ?? ''}
                      required
                      placeholder={t('submission:Surname')}
                      errorMessage={_.get(errors, 'clientDetail.surname.message')}
                      maxLength={150}
                      containerStyle={{ paddingTop: 6 }}
                    />
                  </ConfirmView>
                )
              }}
            />
          </RView>
          <RView>
            <Controller
              control={control}
              name={'clientDetail.givenName'}
              render={({ field: { value, onChange } }) => {
                return (
                  <ConfirmView title={t('submission:GiveName')} value={value ?? '-'} isConfirmed={isConfirmed}>
                    <Input
                      onChange={onChange}
                      title={t('submission:GiveName')}
                      value={value ?? ''}
                      required
                      placeholder={t('submission:GiveName')}
                      errorMessage={_.get(errors, 'clientDetail.givenName.message')}
                      maxLength={150}
                      containerStyle={{ paddingTop: 6 }}
                    />
                  </ConfirmView>
                )
              }}
            />
          </RView>
        </SC.RowWrap>
        <SC.RowWrap>
          <RView>
            <Controller
              control={control}
              name={'clientDetail.taxInUS'}
              render={({ field: { value, onChange } }) => {
                return (
                  <ChooseFatca
                    selected={value ?? false}
                    onChange={onChange}
                    title={t('submission:CurrentlyFatca')}
                    disable={isConfirmed}
                  />
                )
              }}
            />
          </RView>
          <RView>
            <Controller
              control={control}
              name={'clientDetail.dob'}
              render={({ field: { value, onChange }, fieldState: { error } }) => {
                return (
                  <ConfirmView
                    title={t('submission:DateOfBirth')}
                    value={value ? PulseOpsFormat.datetoFormat(value, 'DD/MM/yyyy') : '-'}
                    isConfirmed={isConfirmed}
                  >
                    <SC.Padding top={5}>
                      <DatePicker
                        onChange={onChange}
                        value={value}
                        required
                        label={t('submission:DateOfBirth')}
                        maxDate={new Date(new Date().setHours(0, 0, 0, 0) - 86400000)}
                        invalidDateMessage={null}
                        minDateMessage={null}
                        maxDateMessage={null}
                        errorMessage={error?.message}
                      />
                    </SC.Padding>
                  </ConfirmView>
                )
              }}
            />
          </RView>
          <RView>
            <Controller
              control={control}
              name={'clientDetail.gender'}
              render={({ field: { value, onChange } }) => {
                return (
                  <ConfirmView title={t('submission:Gender')} value={value?.label ?? ''} isConfirmed={isConfirmed}>
                    <SC.Padding top={5}>
                      <Select
                        label={t('submission:Gender')}
                        options={genderOptions}
                        value={value}
                        onChange={onChange}
                        errorMessage={_.get(errors, 'clientDetail.gender.message')}
                        placeholder={t('common:Select')}
                        required
                      />
                    </SC.Padding>
                  </ConfirmView>
                )
              }}
            />
          </RView>
        </SC.RowWrap>
        <SC.RowWrap>
          <RView>
            <Controller
              key={`Nationalitykey${getValues('clientDetail.nationality')}`}
              control={control}
              name={'clientDetail.nationality'}
              render={({ field: { value, onChange } }) => {
                return (
                  <ConfirmView title={t('submission:Nationality')} value={value?.label ?? ''} isConfirmed={isConfirmed}>
                    <SC.Padding top={0}>
                      <SelectSearch
                        label={t('submission:Nationality')}
                        options={countriesOptions ?? []}
                        value={value}
                        onChange={onChange}
                        errorMessage={_.get(errors, 'clientDetail.nationality.message')}
                        placeholder={t('common:Select')}
                        popupIcon={<assets.ArrowDownDropdownIcon />}
                        required
                      />
                    </SC.Padding>
                  </ConfirmView>
                )
              }}
            />
          </RView>
          <RView>
            <ConfirmView
              title={t('submission:MobilePhone')}
              value={`${getValues('clientDetail.mobileCode.label') ?? ''} ${getValues('clientDetail.mobileNumber') ?? '-'
                }`}
              isConfirmed={isConfirmed}
            >
              <SC.Padding vertical={5}>
                <SC.TitleText>
                  {t('submission:MobilePhone')} <SC.ErrorText>*</SC.ErrorText>
                </SC.TitleText>
                <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
                  <Controller
                    key={`mobileCodekey${getValues('clientDetail.mobileCode')}`}
                    control={control}
                    name={'clientDetail.mobileCode'}
                    render={({ field: { value, onChange, onBlur } }) => {
                      //const wrapValue = { label: value?.label ?? '', value: value?.value ?? '' }
                      return (
                        <View style={{ width: 110 }}>
                          <SelectSearch
                            options={mobileCodeOptions ?? []}
                            onChange={onChange}
                            value={value}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                            hideLabel
                            placeholder={'+1'}
                          //disabled={isConfirmed}
                          />
                        </View>
                      )
                    }}
                  />
                  <SC.Divider />
                  <Controller
                    control={control}
                    name={'clientDetail.mobileNumber'}
                    render={({ field: { value, onChange, onBlur } }) => {
                      return (
                        <View style={{ flex: 1 }}>
                          <Input
                            onChange={(value) => {
                              onChange(value)
                            }}
                            value={value ?? ''}
                            maxLength={10}
                            placeholder={t('submission:InputPhone')}
                            onBlur={() => {
                              onBlur()
                              if (/\b\d{10}\b/.test(value)) {
                                //getDuplicatePhone(value)
                                const currentId = getValues('clientDetail.client.value')
                                //console.log('asdasdasdasdasdas ', currentId)
                                if (currentId) getDupPhone(value, currentId)
                              } else {
                                setValue('clientDetail.dupPhone', [])
                              }
                            }}
                          //disabled={isConfirmed}
                          />
                        </View>
                      )
                    }}
                  />
                </View>
                {(_.get(errors, `clientDetail.mobileCode`)?.message ||
                  _.get(errors, `clientDetail.mobileNumber`)?.message) && (
                    <View>
                      <SC.ErrorText fontSize={11.25}>
                        {_.get(errors, `clientDetail.mobileCode`)?.message ||
                          _.get(errors, `clientDetail.mobileNumber`)?.message}
                      </SC.ErrorText>
                    </View>
                  )}
              </SC.Padding>
            </ConfirmView>
          </RView>
          <RView>
            <Controller
              control={control}
              name={'clientDetail.email'}
              render={({ field: { value, onChange, onBlur } }) => {
                return (
                  <ConfirmView
                    title={t('submission:Email')}
                    value={value ?? '-'}
                    isConfirmed={isConfirmed}
                    required={false}
                  >
                    <Input
                      onChange={onChange}
                      onBlur={() => {
                        const currentId = getValues('clientDetail.client.value')
                        //console.log('asdasdasdasdasdas ', currentId)
                        if (value && currentId && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value))
                          getDupEmail(value ?? '', currentId)
                        else {
                          setValue('clientDetail.dupEmail', [])
                        }
                      }}
                      title={t('submission:Email')}
                      value={value ?? ''}
                      placeholder={t('submission:Email')}
                      errorMessage={_.get(errors, 'clientDetail.email.message')}
                      containerStyle={{ paddingTop: 5 }}
                    />
                  </ConfirmView>
                )
              }}
            />
          </RView>
        </SC.RowWrap>
        {anyAgentPhone ? (
          <SC.Padding>
            <SC.ErrorText>{t('message:MS050225', { agent: _.get(anyAgentPhone, 'clientName') })}</SC.ErrorText>
          </SC.Padding>
        ) : (
          phoneDups &&
          phoneDups.length > 0 && (
            <SC.Container>
              <SC.Padding vertical={10}>
                <SC.ErrorText>{t('submission:PhoneDuplicatedWithOther')}</SC.ErrorText>
              </SC.Padding>
              {_.get(errors, 'clientDetail.dupPhone') && (
                <SC.Padding vertical={5}>
                  <SC.ErrorText>{t('message:MS050228')}</SC.ErrorText>
                </SC.Padding>
              )}
              <Controller
                control={control}
                name={'clientDetail.dupPhone'}
                render={({ field: { value, onChange } }) => {
                  return (
                    <DuplicateTable
                      key={`dup_phone_table${isConfirmed}`}
                      value={value}
                      onChange={onChange}
                      disable={isConfirmed}
                      relationshipOptions={relationshipOptions ?? []}
                    />
                  )
                }}
              />
            </SC.Container>
          )
        )}

        {anyAgentEmail ? (
          <SC.Padding vertical={10}>
            <SC.ErrorText>{t('message:MS050227', { agent: _.get(anyAgentEmail, 'clientName') })}</SC.ErrorText>
          </SC.Padding>
        ) : (
          emailDups &&
          emailDups.length > 0 && (
            <SC.Container>
              <SC.Padding vertical={10}>
                <SC.ErrorText>{t('submission:EmailDuplicatedWithOther')}</SC.ErrorText>
              </SC.Padding>
              {_.get(errors, 'clientDetail.dupEmail') && (
                <SC.Padding vertical={5}>
                  <SC.ErrorText>{t('message:MS050228')}</SC.ErrorText>
                </SC.Padding>
              )}
              <Controller
                control={control}
                name={'clientDetail.dupEmail'}
                render={({ field: { value, onChange } }) => {
                  return (
                    <DuplicateTable
                      key={`dup_mail_table${isConfirmed}`}
                      value={value}
                      onChange={onChange}
                      disable={isConfirmed}
                      relationshipOptions={relationshipOptions ?? []}
                    />
                  )
                }}
              />
            </SC.Container>
          )
        )}
        <SC.RowWrap>
          <RView>
            <Controller
              control={control}
              name={'clientDetail.country'}
              render={({ field }) => (
                <ConfirmView title={t('submission:Country')} value={field.value?.label ?? ''} isConfirmed={true}></ConfirmView>
              )}
            />
          </RView>
          <RView>
            <Controller
              control={control}
              name={'clientDetail.city'}
              render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                return (
                  <ConfirmView title={t('submission:CityProvince')} value={value?.label ?? ''} isConfirmed={isConfirmed}>
                    <SelectSearch
                      label={t('submission:CityProvince')}
                      options={provincesOptions}
                      required
                      value={value}
                      onChange={(val) => {
                        onChange(val)
                        getDistrictList(val?.value ?? '')
                      }}
                      onBlur={onBlur}
                      popupIcon={<assets.ArrowDownDropdownIcon />}
                      errorMessage={error?.message}
                    />
                  </ConfirmView>
                )
              }}
            />
          </RView>
          <RView>
            <Controller
              control={control}
              name={'clientDetail.district'}
              render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                return (
                  <ConfirmView title={t('submission:District')} value={value?.label ?? ''} isConfirmed={isConfirmed}>
                    <SelectSearch
                      label={t('submission:District')}
                      options={districtList}
                      required
                      value={value}
                      onChange={(val) => {
                        onChange(val)
                        getWardList(watch('clientDetail.city')?.value ?? '', val?.value ?? '')
                      }}
                      onBlur={onBlur}
                      popupIcon={<assets.ArrowDownDropdownIcon />}
                      errorMessage={error?.message}
                    />
                  </ConfirmView>
                )
              }}
            />
          </RView>
        </SC.RowWrap>
        <SC.RowWrap>
          <RView>
            <Controller
              control={control}
              name={'clientDetail.ward'}
              render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                return (
                  <ConfirmView title={t('submission:WardSubDistrict')} value={value?.label ?? ''} isConfirmed={isConfirmed}>
                    <SelectSearch
                      label={t('submission:WardSubDistrict')}
                      options={wardList}
                      required
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      popupIcon={<assets.ArrowDownDropdownIcon />}
                      errorMessage={error?.message}
                    />
                  </ConfirmView>
                )
              }}
            />
          </RView>
          <RView width={'66.66%'}>
            <Controller
              control={control}
              name={'clientDetail.street'}
              render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                return (
                  <ConfirmView title={t('submission:Street')} value={value ?? ''} isConfirmed={isConfirmed}>
                    <Input
                      title={t('submission:Street')}
                      required
                      value={value ?? ''}
                      onChange={onChange}
                      onBlur={onBlur}
                      maxLength={50}
                      errorMessage={error?.message}
                    />
                  </ConfirmView>
                )
              }}
            />
          </RView>
        </SC.RowWrap>
        <SC.RowWrap>
          <RView>
            <Controller
              control={control}
              name={'clientDetail.taxResidencyCountry'}
              render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                return (
                  <ConfirmView title={t('submission:TaxResidencyCountry')} value={value?.label ?? ''} isConfirmed={isConfirmed}>
                    <SelectSearch
                      label={t('submission:TaxResidencyCountry')}
                      required
                      options={countriesOptions}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      popupIcon={<assets.ArrowDownDropdownIcon />}
                      errorMessage={error?.message}
                    />
                  </ConfirmView>
                )
              }}
            />
          </RView>
          <RView>
            <Controller
              control={control}
              name={'clientDetail.nationality2'}
              render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                return (
                  <ConfirmView title={t('submission:Nationality2')} value={value?.label ?? ''} isConfirmed={isConfirmed}>
                    <SelectSearch
                      label={t('submission:Nationality2')}
                      options={countriesOptions}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      popupIcon={<assets.ArrowDownDropdownIcon />}
                    />
                  </ConfirmView>
                )
              }}
            />
          </RView>
          <RView>
            <Controller
              control={control}
              name={'clientDetail.countryOfForeignAddress'}
              render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                return (
                  <ConfirmView title={t('submission:CountryOfForeignAddress')} value={value?.label ?? ''} isConfirmed={isConfirmed}>
                    <SelectSearch
                      label={t('submission:CountryOfForeignAddress')}
                      options={countriesOptions}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      popupIcon={<assets.ArrowDownDropdownIcon />}
                    />
                  </ConfirmView>
                )
              }}
            />
          </RView>
        </SC.RowWrap>
        <SC.RowWrap>
          <RView width={'66.66%'}>
            <Controller
              control={control}
              name={'clientDetail.foreignAddress'}
              render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                return (
                  <ConfirmView title={t('submission:ForeignAddress')} value={value ?? ''} isConfirmed={isConfirmed}>
                    <Input
                      title={t('submission:ForeignAddress')}
                      value={value ?? ''}
                      onChange={onChange}
                      onBlur={onBlur}
                      maxLength={50}
                      errorMessage={error?.message}
                    />
                  </ConfirmView>
                )
              }}
            />
          </RView>
        </SC.RowWrap>
        <Controller
          control={control}
          name={'clientDetail.files'}
          render={({ field: { value, onChange } }) => {
            const wrapValue = value?.map((f) => ({ ...f, file: f.file!, uploadedDate: f.uploadedDate! })) ?? []
            return (
              <ConfirmView
                title={''}
                value={''}
                isConfirmed={isConfirmed}
                confirmBuilder={() => {
                  return (
                    <View>
                      <SC.Padding top={16}>
                        <SC.BoldText>
                          {t('submission:RelativeDocument')} <SC.ErrorText>*</SC.ErrorText>
                        </SC.BoldText>
                      </SC.Padding>
                      <SC.RowWrap>
                        {wrapValue.map((v, index) => {
                          return (
                            <RView key={`_list_file_client_${index}`}>
                              <FilePresent file={v} />
                            </RView>
                          )
                        })}
                      </SC.RowWrap>
                    </View>
                  )
                }}
              >
                <SC.Container>
                  <SC.Padding top={16}>
                    <SC.BoldText>
                      {t('submission:RelativeDocument')} <SC.ErrorText>*</SC.ErrorText>
                    </SC.BoldText>
                  </SC.Padding>
                  <ImgUploadMutiple value={wrapValue} onChange={onChange} />
                  {_.get(errors, 'clientDetail.files.message') && (
                    <SC.ErrorText>{_.get(errors, 'clientDetail.files.message')}</SC.ErrorText>
                  )}
                </SC.Container>
              </ConfirmView>
            )
          }}
        />
      </SC.PanelContainer>

      {/* Change Occupation */}
      <SC.Padding>
        <Controller
          control={control}
          name={'chooseOccupation'}
          render={({ field: { value, onChange } }) => {
            return (
              <CustomCheckBox
                checked={value}
                title={t('submission:ChangeOccupation').toUpperCase()}
                onPress={() => onChange(!value)}
                disabled={isConfirmed}
                textStyle={{ fontWeight: 'bold' }}
              />
            )
          }}
        />
      </SC.Padding>
      {watch('chooseOccupation') && (
        <SC.Container>
          <SC.PanelContainer>
            <SC.RowWrap>
              <RView>
                <Controller
                  key={`occupationkey${getValues('occupation.occupation')}`}
                  control={control}
                  name={'occupation.occupation'}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <ConfirmView
                        title={t('submission:Occupation')}
                        value={value?.label ?? '-'}
                        isConfirmed={isConfirmed}
                      >
                        <SelectSearch
                          //key={`occupationkey`}
                          label={t('submission:Occupation')}
                          options={occupationsOptions ?? []}
                          value={value}
                          onChange={(item) => {
                            onChange(item)
                            onChangeOccupationEvent(item?.value ?? '')
                          }}
                          required
                          placeholder={t('common:Select')}
                          errorMessage={_.get(errors, 'occupation.occupation.message') || occupationMessage}
                          popupIcon={<assets.ArrowDownDropdownIcon />}
                        />
                      </ConfirmView>
                    )
                  }}
                />
              </RView>
              <RView>
                <Controller
                  control={control}
                  name={'occupation.jobDescription'}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <ConfirmView
                        title={t('submission:JobDescription')}
                        value={value ?? '-'}
                        isConfirmed={isConfirmed}
                        required={false}
                      >
                        <Input
                          onChange={onChange}
                          title={t('submission:JobDescription')}
                          value={value ?? undefined}
                          placeholder={t('submission:JobDescription')}
                          errorMessage={_.get(errors, 'occupation.jobDescription.message')}
                          maxLength={100}
                          containerStyle={{ paddingTop: 5 }}
                          disabled={
                            !!watch('occupation.occupation') && watch('occupation.occupation')?.value !== '3254'
                              ? true
                              : false
                          }
                        />
                      </ConfirmView>
                    )
                  }}
                />
              </RView>
              <RView>
                <Controller
                  control={control}
                  name={'occupation.jobTitle'}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <ConfirmView
                        title={t('submission:JobTitle')}
                        value={value ?? '-'}
                        isConfirmed={isConfirmed}
                        required={false}
                      >
                        <Input
                          onChange={onChange}
                          title={t('submission:JobTitle')}
                          value={value ?? undefined}
                          placeholder={t('submission:JobTitle')}
                          errorMessage={_.get(errors, 'occupation.jobTitle.message')}
                          maxLength={100}
                          containerStyle={{ paddingTop: 5 }}
                          disabled={true}
                        />
                      </ConfirmView>
                    )
                  }}
                />
              </RView>
            </SC.RowWrap>
            <SC.RowWrap>
              <RView>
                <Controller
                  control={control}
                  name={'occupation.companyName'}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <ConfirmView
                        title={t('submission:CompanyName')}
                        value={value ?? '-'}
                        isConfirmed={isConfirmed}
                        required={false}
                      >
                        <Input
                          onChange={onChange}
                          title={t('submission:CompanyName')}
                          value={value ?? undefined}
                          placeholder={t('submission:CompanyName')}
                          errorMessage={_.get(errors, 'occupation.companyName.message')}
                          maxLength={100}
                          containerStyle={{ paddingTop: 5 }}
                          disabled={occupationItem?.isEnableCompanyInfo === 'N'}
                        />
                      </ConfirmView>
                    )
                  }}
                />
              </RView>
              <RView>
                <Controller
                  control={control}
                  name={'occupation.companyAddress'}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <ConfirmView
                        title={t('submission:CompanyAddress')}
                        value={value ?? '-'}
                        isConfirmed={isConfirmed}
                        required={false}
                      >
                        <Input
                          onChange={onChange}
                          title={t('submission:CompanyAddress')}
                          value={value || undefined}
                          placeholder={t('submission:CompanyAddress')}
                          errorMessage={_.get(errors, 'occupation.companyAddress.message')}
                          maxLength={100}
                          containerStyle={{ paddingTop: 5 }}
                          disabled={occupationItem?.isEnableCompanyInfo === 'N'}
                        />
                      </ConfirmView>
                    )
                  }}
                />
              </RView>
              <RView>
                <Controller
                  control={control}
                  name={'occupation.salary'}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <ConfirmView
                        title={t('submission:Salary')}
                        value={value?.label ?? ''}
                        isConfirmed={isConfirmed}
                        required={false}
                      >
                        <Select
                          label={t('submission:Salary')}
                          options={salaryOptions ?? []}
                          value={value}
                          onChange={onChange}
                          placeholder={t('common:Select')}
                        />
                      </ConfirmView>
                    )
                  }}
                />
              </RView>
            </SC.RowWrap>
          </SC.PanelContainer>
        </SC.Container>
      )}
    </SC.Container>
  )
}

const RView: React.FC<{ mobileWidth?: string | number; width?: string | number; padding?: number }> = ({
  mobileWidth = '100%',
  width = '33%',
  padding = 5,
  children
}) => {
  const { isMobile } = useMobile()
  const composeWidth = isMobile ? mobileWidth : width
  const composePadding = isMobile ? padding : 5

  return <View style={{ width: composeWidth, padding: composePadding }}>{children}</View>
}

const ChooseFatca: React.FC<{
  selected: boolean
  title: string
  onChange?: (value: boolean) => void
  disable?: boolean
}> = ({ selected, title, onChange, disable = false }) => {
  const { t } = useTranslation()
  return (
    <SC.Padding vertical={8}>
      <SC.BoldText>
        {title} <SC.ErrorText>*</SC.ErrorText>
      </SC.BoldText>
      <SC.Divider />
      <SC.Row>
        <RadioButton
          label={t('common:Yes')}
          selected={selected}
          onChange={() => onChange?.call(null, true)}
          disable={disable}
        />
        <RadioButton
          label={t('common:No')}
          selected={!selected}
          onChange={() => onChange?.call(null, false)}
          disable={disable}
        />
      </SC.Row>
    </SC.Padding>
  )
}
