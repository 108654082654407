import React, { useState } from 'react'
import { pipe } from 'fp-ts/lib/function'
import { Throwable, ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import {
  Container,
  ErrorHandling,
  Panel,
  useTranslator,
  AppContext,
  ModalComponent,
  useMobile,
  formatNumberWithComma,
  CanView
} from '@pulseops/common'
import { CepTableField } from '../cep-components'
import { RouteProp, useRoute, useIsFocused, useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { InquiryStackParamList } from '../../InquiryStackParamList'
import { View, Text, StyleSheet, ActivityIndicator } from 'react-native'
import { TablePagination } from '@material-ui/core'
import i18next from 'i18next'
import { PremiumHistoryInfo, getPremiumHistory, getPremiumHistoryDetail } from '../../cep-api-services'
import { IPremiumDetail, PremiumInfoData, IPremiumHistory, TransCode } from '../CEPInterface'

export const CepPremiumScreen = (props: {
  id: string
  data: PremiumHistoryInfo | null
  setData: (val: PremiumHistoryInfo | null) => void
  clientNumber: string
  isGA: boolean
}) => {
  const { t } = useTranslator('TaskManagement')
  const cep = useTranslator('CustomerEngagement').t
  const [loading, bindLoading] = useLoading()

  const isFocused = useIsFocused()
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)
  const { navigate } = useNavigation()
  const { isWide } = useMobile()
  const navigation = useNavigation<StackNavigationProp<InquiryStackParamList, 'InquiryScreen'>>()
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const [premiumDetail, setPremiumDetail] = React.useState<IPremiumDetail[] | null>(null)
  const [loadingPremium, setLoadingPremium] = React.useState<boolean>(false)
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [pageNum, setPageNum] = React.useState<number>(0)
  const [totalItem, setTotalItem] = React.useState<number>(0)
  const [dataSource, setDataSource] = React.useState<PremiumInfoData[]>([])
  const route = useRoute<RouteProp<InquiryStackParamList, 'CEPPolicyDetailScreen'>>()
  const [dataPremium, setDataPremium] = React.useState<IPremiumHistory[]>([])

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => navigate('HomeScreen')
        },
        {
          title: route?.params?.isSubmission === 'true' ? t('Submission:EForm') : t('menu:360Inquiry'),
          navigate: () =>
            route?.params?.isSubmission === 'true'
              ? navigation.replace('UserDrawer', {
                  screen: 'StaffSubmissionStack'
                })
              : navigation.navigate('CEPSearchScreen')
        },
        {
          title: t('CustomerEngagement:Premium'),
          navigate: null
        }
      ])
    }
  }, [isFocused])

  const getData = (page: number): ZIO<unknown, Throwable, PremiumHistoryInfo> =>
    pipe(
      getPremiumHistory({
        start: page,
        size: 100,
        data: {
          policyNo: props.id,
          clientNo: props.clientNumber
        }
      }),
      ZIO.map((premiumData) => {
        return premiumData
      })
    )

  const mappingDataSource = (data: IPremiumHistory[]) => {
    return data.map((x) => ({
      transactionId: x.transactionId ?? '',
      paymentType: ( x.paymentType ?? '') +' '+ (x.paymentDesc ?? ''),
      bankCode: x.bankCode ?? '',
      transDate: x.transDate ?? '',
      amount: formatNumberWithComma(x.amount ?? '') ?? 0,
      rcpDate: x.rcpDate ?? '',
      orNo: (x.channel ?? '') + ' ' + (x.orNo ?? ''),
      agtCol: x.agtCol ?? '',
      user: x.user ?? '',
      channel: x.channel ?? '',
      sacsCode: x.sacsCode ?? '',
      sacsType: x.sacsType ?? '',
      gtAmount: formatNumberWithComma(x.gtAmount ?? '') ?? 0,
      content: x.isFromACMV ? x.content : x.glCode +' '+ x.content,
      transactionCode: x.transactionCode ?? ''
    }))
  }

  pipe(
    props.data !== null ? ZIO.succeed(props.data) : getData(0),
    ZIO.tap((x) => {
      let list: IPremiumHistory[] = []

      const data = Object.entries(x.data ?? {}).map(([k, v]) => ({
        transactionId: k,
        data: mappingDataSource(v as IPremiumHistory[])
      }))
      
      Object.entries(x.data ?? {}).map(([k, v]) => {
        let item = mappingDataSource(v as IPremiumHistory[])
        item.splice(1, item.length - 1)
        list = [...list, ...item]
        // console.log(item, 'item')
      })
      const dataSource = list.sort((a, b) => {
        const timeB = b?.transDate?.split('/').reverse().join('/') ?? ''
        const timeA = a?.transDate?.split('/').reverse().join('/') ?? ''

        const newB = new Date(timeB)

        const newA = new Date(timeA)
        return newB.valueOf() - newA.valueOf()
      })
      
      setDataPremium(dataSource)
      setDataSource(data ?? [])
      setTotalItem(x.total ?? 0)
      if (x !== null && props.data === null) props.setData(x)
      return ZIO.unit
    }),
    bindLoading,
    ErrorHandling.runDidMount()
  )

  // const getDataItem = (preItem: { transactionId: string; data: IPremiumHistory[] }) =>
  //   pipe(
  //     getPremiumHistoryDetail({
  //       data: {
  //         policyNo: props.id,
  //         transactionId: preItem.transactionId
  //       }
  //     }),
  //     ZIO.map((preDetail) => {
  //       const dataOld: IPremiumDetail[] =
  //         preItem && preItem.data.length > 0
  //           ? preItem.data.map((item) => ({
  //               sacsCode: item.sacsCode ?? '',
  //               sacsType: item.sacsType ?? '',
  //               gtAmount: item.gtAmount ?? 0,
  //               content: item.content ?? ''
  //             }))
  //           : []

  //       const preData = [...dataOld, ...preDetail.data]

  //       setPremiumDetail(preData)
  //       setLoadingPremium(false)
  //     }),
  //     ZIO.unsafeRun([])
  //   )

  const columnPremiumHistory = [
    {
      key: '1',
      title: cep('Transaction ID'),
      name: 'transactionId',
      color: () => '#1EA5FC',
      onPress: (index: number) => {
        setIsOpen(true)
        const tranId = dataPremium[index].transactionId;
        const dataDetail = dataSource.find((el) => el.transactionId === tranId)
        // setLoadingPremium(true)
        const data: IPremiumDetail[] = dataDetail ? dataDetail.data.map((item) => ({
          transactionId: item.transactionId ?? '',
          sacsCode: item.sacsCode +' '+ item.sacsType,
          sacsType: item.sacsType ?? '',
          gtAmount: item.gtAmount ?? 0,
          content: item.content ?? ''
        })) : []
		
        setPremiumDetail(data)
      },
      styleCss: {
        textDecorationLine: 'underline',
        fontWeight: 'bold'
      }
    },
    {
      key: '2',
      title: cep('Payment Type'),
      name: 'paymentType'
    },
    {
      key: '3',
      title: cep('Bank Code'),
      name: 'bankCode'
    },
    {
      key: '4',
      title: cep('Tran. Date'),
      name: 'transDate'
    },
    {
      key: '5',
      title: cep('Amount'),
      name: 'amount'
    },
    {
      key: '6',
      title: cep('RcptDte'),
      name: 'rcpDate'
    },
    {
      key: '7',
      title: cep('OR 1'),
      name: 'orNo'
    },
    {
      key: '8',
      title: cep('Agt/Col'),
      name: 'agtCol'
    },
    {
      key: '8',
      title: cep('User'),
      name: 'user'
    }
  ]

  const columnPremiumHistory2 = [
    {
      key: '1',
      title: cep('Transaction ID'),
      name: 'transactionId',
      color: () => '#1EA5FC',
      onPress: (index: number) => {
        setIsOpen(true)
        setLoadingPremium(true)
        // if (TransCode.includes(dataSource[index].data[0].transactionCode ?? '')) {
          const data: IPremiumDetail[] = dataSource[index].data.map((item) => ({
            sacsCode: item.sacsCode ?? '',
            sacsType: item.sacsType ?? '',
            gtAmount: item.gtAmount ?? 0,
            content: item.content ?? ''
          }))

        setPremiumDetail(data)
        // setLoadingPremium(false)
        // if (TransCode.includes(dataSource[index].data[0].transactionCode ?? '')) {
        //   const data: IPremiumDetail[] = dataSource[index].data.map((item) => ({
        //     sacsCode: item.sacsCode ?? '',
        //     sacsType: item.sacsType ?? '',
        //     gtAmount: item.gtAmount ?? 0,
        //     content: item.content ?? ''
        //   }))

        //   setPremiumDetail(data)
        //   setLoadingPremium(false)
        // } else {
        //   getDataItem(dataSource[index] ?? '')
        // }
          // }
      },
      styleCss: {
        textDecorationLine: 'underline',
        fontWeight: 'bold'
      }
    },
    {
      key: '2',
      title: cep('Payment Type'),
      name: 'paymentType'
    },
    {
      key: '3',
      title: cep('Bank Code'),
      name: 'bankCode'
    },
    {
      key: '4',
      title: cep('Tran. Date'),
      name: 'transDate'
    },
    {
      key: '5',
      title: cep('Amount'),
      name: 'amount'
    },
    {
      key: '6',
      title: cep('RcptDte'),
      name: 'rcpDate'
    },
    {
      key: '7',
      title: cep('OR 1'),
      name: 'orNo'
    },
    {
      key: '8',
      title: cep('Agt/Col'),
      name: 'agtCol'
    }
  ]

  const columnTransactionDetail = [
    {
      key: '0',
      title: cep('Transaction ID'),
      name: 'transactionId'
    },
    {
      key: '1',
      title: cep('Dissection A/C'),
      name: 'sacsCode'
    },
    {
      key: '2',
      title: cep('Content'),
      name: 'content'
    },
    {
      key: '3',
      title: cep('GL Amount'),
      name: 'gtAmount'
    }
  ]

  // const getPagingPremiumHistory = (page: number) =>
  //   pipe(
  //     getData(page),
  //     ZIO.map((res) => {
  //       const data = Object.entries(res.data ?? {}).map(([k, v]) => ({
  //         transactionId: k,
  //         data: mappingDataSource(v as IPremiumHistory[])
  //       }))
  //       setDataSource(data ?? [])
  //     }),
  //     ZIO.unsafeRun([])
  //   )

  return (
    <Container loading={loading}>
      <Panel title={cep('PremiumHistory')}>
        <CepTableField
          columns={columnPremiumHistory}
          dataSource={dataPremium} name="PremiumHistory" />
        {/* <TablePagination
            page={pageNum}
            rowsPerPageOptions={[5, 10, 25]}
            rowsPerPage={pageSize}
            count={totalItem}
            onPageChange={(e, page) => {
              setPageNum(page)
              getPagingPremiumHistory(page)
            }}
            onRowsPerPageChange={(e) => {
              const rowsPerPage = Number(e.target.value)
              setPageSize(rowsPerPage)
              if (pageNum * rowsPerPage >= totalItem) {
                getPagingPremiumHistory(pageNum)
              } else {
                getPagingPremiumHistory(pageNum)
              }
            }}
            labelRowsPerPage={t('common:PaginationSize')}
            labelDisplayedRows={({ from, to, count }) => i18next.t('common:Pagination', { from, to, count })}
            component={View}
          ></TablePagination> */}
      </Panel>

      <ModalComponent
        title={cep('PremiumInfo')}
        visible={isOpen}
        onClose={() => {
          setIsOpen(false)
        }}
        actions={[]}
        modalWidth={1082}
        titleStyle={{ color: '#000000' }}
      >
        <View
          style={{
            marginHorizontal: 20,
            marginTop: isWide ? 20 : 16
          }}
        >
          <View style={{ width: '100%' }}>
            <Text style={{ color: '#4F4F4F', fontWeight: '700' }}>{cep('TransactionDetail').toUpperCase()}</Text>
            {loadingPremium ? (
              <CanView condition={loadingPremium}>
                <View style={styles.loadingContainer}>
                  <ActivityIndicator size="large" color="red" />
                </View>
              </CanView>
            ) : (
              <CepTableField columns={columnTransactionDetail} dataSource={premiumDetail ?? []} name="PremiumHistory" />
            )}
          </View>
        </View>
      </ModalComponent>
    </Container>
  )
}
const styles = StyleSheet.create({
  sectionRow: {
    flex: 1,
    flexWrap: 'wrap',
    flexDirection: 'row'
  },
  col_45: {
    width: '100%',
    maxWidth: '48%'
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  }
})
