import { FileUploadData, SelectOption } from '@pulseops/common'

export const COLORS = {
  SNOW: '#fafafa',
  HOWKES_BLUE: '#d3dce6',
  ALIZARIN: '#ED1B2E',
  WHITE: '#ffff',
  ROLLING_STONE: '#70777e',
  BLACK: '#000000',
  MYSTIC: '#E5EAEF',
  BISCAY: '#1B365D'
}

export interface TextCustomI {
  textColor?: string
  alignText?: string
  fontSize?: string
  fontWeight?: string
  fontStyle?: 'italic' | 'normal' | 'oblique'
}

export enum TypeFieldEnum {
  TEXTBOX = 'TEXTBOX',
  DROPDOWN_LIST = 'DROPDOWN_LIST',
  DATE_PICKER = 'DATE_PICKER'
}

enum TypeGroupNameEnum {
  INFO_CHANGE_CLIENT = 'info_change_client',
  CHANGE_CITIZEN_NUMBER = 'change_citizen_number',
  FRONT_SIDE = 'front_side',
  BACK_SIDE = 'back_size'
}

export type typeField = `${TypeFieldEnum}`

export type typeGroupName = `${TypeGroupNameEnum}_group`
export type typeNameField = 'selectClient' | 'idCitizenNumber' | 'issuedBy' | 'issuedDate'
export type typeNameFieldFPT = 'id' | 'name' | 'dob' | 'sex' | 'doe'

type HiddenType = 'CHECK_BOX' | 'RADIO'

export type FieldsType<TN> = {
  type: typeField
  formName: TN
  required: boolean
  label: string
  options?: SelectOption[]
  placeholder: string
  popupIcon?: string
  disabled: boolean
  rules?: object
}

export type GroupsViewType<GN, TN> = {
  groupName: GN
  isVisible: boolean
  fields: FieldsType<TN>[]
  typeHidden?: HiddenType
  labelGroup?: string
  isBorder?: boolean
}

export enum FILE_ERROR {
  LARGE_FILE_SIZE = 'LARGE_FILE_SIZE',
  MISSING_FILE = 'MISSING_FILE'
}

export enum STATUS {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR'
}

export type FileErrorType = `${FILE_ERROR}`
export type StatusType = `${STATUS}`

export type CallBackValidateUploadFileType = {
  typeError?: FileErrorType
  message?: string
  status: StatusType
}

export const groupsViewsConst = (t: any): GroupsViewType<typeGroupName, typeNameField>[] => [
  {
    groupName: 'info_change_client_group',
    labelGroup: t('GROUPS_VIEWS.LABEL_GROUP.INFO_CHANGE_CLIENT'),
    fields: [
      {
        formName: 'selectClient',
        type: 'DROPDOWN_LIST',
        required: true,
        placeholder: '-',
        label: t('GROUPS_VIEWS.FIELDS.SELECT_CLIENT'),
        disabled: false,
        options: [],
        rules: {
          required: {
            value: true,
            message: `${t('message:MS020009', { field: t('GROUPS_VIEWS.FIELDS.selectClient') })}`
          }
        }
      }
    ],
    isVisible: true,
    typeHidden: 'CHECK_BOX'
  },
  {
    groupName: 'change_citizen_number_group',
    isVisible: true,
    typeHidden: 'CHECK_BOX',
    labelGroup: t('GROUPS_VIEWS.LABEL_GROUP.CHANGE_CITIZEN_NUMBER'),
    isBorder: true,
    fields: [
      {
        type: 'TEXTBOX',
        formName: 'idCitizenNumber',
        label: t('GROUPS_VIEWS.FIELDS.ID_CITIZEN_NUMBER'),
        required: true,
        placeholder: '-',
        disabled: false,
        rules: {
          required: {
            value: true,
            message: `${t('message:MS020009', { field: t('GROUPS_VIEWS.FIELDS.idCitizenNumber') })}`
          },
          validate: (value: string) => {
            const reg = /^-?\d*\.?\d*$/
            const isOnlyNumber = !reg.test(value)
            const isMatch12Character = value?.length !== 12

            if (isMatch12Character) return t('message:MS020048', { number: 12 })

            if (isOnlyNumber) return t('message:MS070018')

            return true
          }
        }
      },
      {
        type: 'DATE_PICKER',
        formName: 'issuedDate',
        label: t('GROUPS_VIEWS.FIELDS.ISSUED_DATE'),
        required: true,
        placeholder: 'dd/mm/yyyy',
        disabled: false,
        rules: {
          required: {
            value: true,
            message: `${t('message:MS020009', { field: t('GROUPS_VIEWS.FIELDS.issuedDate') })}`
          },
          validate: (date: Date) => {
            const today = new Date().getTime()
            const issueDate = new Date(date).getTime()

            if (date.toString() === 'Invalid Date') return t('form:error_invalid')
            if (issueDate > today) return t('message:MS050292')
            return true
          }
        }
      },
      {
        type: 'DROPDOWN_LIST',
        formName: 'issuedBy',
        label: t('GROUPS_VIEWS.FIELDS.ISSUED_BY'),
        required: true,
        placeholder: '-',
        disabled: false,
        options: [
          {
            label: 'Cục Trưởng Cục Cảnh Sát Quản lý Hành Chính về Trật tự Xã hội',
            value: 'Cục Trưởng Cục Cảnh Sát Quản lý Hành Chính về Trật tự Xã hội'
          },
          {
            label: 'Cục Trưởng Cục Cảnh Sát DKQL CƯ TRÚ VÀ DLQG VỀ DÂN CƯ',
            value: 'Cục Trưởng Cục Cảnh Sát DKQL CƯ TRÚ VÀ DLQG VỀ DÂN CƯ'
          },
          {
            label: 'BỘ CÔNG AN',
            value: 'BỘ CÔNG AN'
          }
        ],
        rules: {
          required: {
            value: true,
            message: `${t('message:MS020009', { field: t('GROUPS_VIEWS.FIELDS.issuedBy') })}`
          }
        }
      }
    ]
  }
]

export const groupViewsFPTConst = (t: any): GroupsViewType<typeGroupName, typeNameFieldFPT>[] => [
  {
    groupName: 'front_side_group',
    isVisible: true,
    fields: [
      {
        type: 'TEXTBOX',
        formName: 'id',
        label: t('MODAL_UPLOAD_CITIZEN.FIELDS.ID'),
        required: false,
        placeholder: '',
        disabled: true
      },
      {
        type: 'TEXTBOX',
        formName: 'name',
        label: t('MODAL_UPLOAD_CITIZEN.FIELDS.NAME'),
        required: false,
        placeholder: '',
        disabled: true
      },
      {
        type: 'TEXTBOX',
        formName: 'dob',
        label: t('MODAL_UPLOAD_CITIZEN.FIELDS.DOB'),
        required: false,
        placeholder: '',
        disabled: true
      }
    ]
  },
  {
    groupName: 'back_size_group',
    isVisible: true,
    fields: [
      {
        type: 'TEXTBOX',
        formName: 'doe',
        label: t('MODAL_UPLOAD_CITIZEN.FIELDS.DOE'),
        required: false,
        placeholder: '',
        disabled: true
      },
      {
        type: 'TEXTBOX',
        formName: 'sex',
        label: t('MODAL_UPLOAD_CITIZEN.FIELDS.SEX'),
        required: false,
        placeholder: '',
        disabled: true
      }
    ]
  }
]

export interface GroupCitizenI {
  group: number
  content: { title: string; action: string }
  url?: string
  isUpload?: boolean
  fileUpload?: FileUploadData
}

export interface FilesPDF {
  id: string | number
  createDate: string
  name: string
  images: {
    name: string
    url: string
    id?: string
  }[]
  fileUploads: FileUploadData[]
}
