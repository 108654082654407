import React, { useEffect } from 'react'
import { pipe } from 'fp-ts/lib/function'
import { useLoading } from '@mxt/zio-react'
import { Throwable, ZIO } from '@mxt/zio'
import {
  Container,
  ErrorHandling,
  FieldList,
  Panel,
  TableField,
  useMobile,
  useTranslator,
  AppContext,
  AuthService,
  GeneralService
} from '@pulseops/common'
import { CepTableField, CepFieldList } from '../cep-components'
import { useIsFocused, useNavigation, useRoute, RouteProp } from '@react-navigation/native'
import { isEmpty } from 'lodash'
import _ from 'lodash'
import { StackNavigationProp } from '@react-navigation/stack'
import { InquiryStackParamList } from '../../InquiryStackParamList'
import i18next from 'i18next'
import { getCustomerInfo, getDuplicationPhone, IDuplicationPhone, RolesMLA, StatusPO } from '@pulseops/inquiry'

export interface CustomerInfoData {
  contactInfoFields: FieldList.DataSources
  personalInfoSource: TableField.DataSources
  faoInfoSource: TableField.DataSources
}

export const CepCustomerScreen = (props: {
  id: string
  data: CustomerInfoData | null
  setData: (val: CustomerInfoData) => void
  clientNumber: string
  duplicationPhoneData: Array<IDuplicationPhone> | null
  setDuplicationPhone: (val: Array<IDuplicationPhone>) => void
}) => {
  const { isMobile } = useMobile()
  const [loading, bindLoading] = useLoading()
  const { t } = useTranslator('TaskManagement')
  const cct = useTranslator('classCode').t
  const ct = useTranslator('contact').t
  const rolesT = useTranslator('roles').t
  const { t: tCommon } = useTranslator('common')
  const requestInfoT = useTranslator('requestInfo').t
  const cep = useTranslator('CustomerEngagement').t
  const [isFAO, setIsFAO] = React.useState<boolean>(true)
  const isFocused = useIsFocused()
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)
  const { navigate } = useNavigation()

  const navigation = useNavigation<StackNavigationProp<InquiryStackParamList, 'InquiryScreen'>>()
  const route = useRoute<RouteProp<InquiryStackParamList, 'CEPPolicyDetailScreen'>>()
  const [PONumberPhone, setPONumberPhone] = React.useState<string>('')

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => navigate('HomeScreen')
        },
        {
          title: route?.params?.isSubmission === 'true' ? t('Submission:EForm') : t('menu:360Inquiry'),
          navigate: () =>
            route?.params?.isSubmission === 'true'
              ? navigation.replace('UserDrawer', {
                  screen: 'StaffSubmissionStack'
                })
              : navigation.navigate('CEPSearchScreen')
        },
        {
          title: t('Tab:CustomerInfo'),
          navigate: null
        }
      ])
    }
  }, [isFocused])

  function getClassCode(code: string) {
    switch (code) {
      case 'V':
        return cct('Gold')
      case 'P':
        return cct('Platinum')
      case 'D':
        return cct('Diamond')
      default:
        return cct('Basic')
    }
  }

  const getIndicatorPhoneAndEmail = (value: string, type: string = 'Email') => {
    switch (value) {
      case '1':
        return 'Pol Info'
      case '3':
        return 'Pol & Pru Info'
      case '4':
        return 'Refuse' + type
      default:
        return '-'
    }
  }

  const getIndicatorZalo = (value: string) => {
    switch (value) {
      case '3':
        return 'Accept Zalo'
      case '4':
        return 'Refuse Zalo'
      default:
        return '-'
    }
  }

  const getRolePolicyOwner = (role: string, lifeNo: string) => {
    if (role === 'LF' && lifeNo === '01') {
      return rolesT('LF1')
    }
    return rolesT(role)
  }

  const getData = (): ZIO<unknown, Throwable, CustomerInfoData> =>
    pipe(
      ZIO.zip(getCustomerInfo(props.id), AuthService.userInfo, GeneralService.getCountries),
      ZIO.flatMap(([_customerInfo, userData, countries]) =>
        ZIO.effect(() => {
          const { contact, policyOwners, fao } = _customerInfo.data ?? {}
          const phoneContact = _customerInfo.data?.contact?.contactChannels?.find((x) => x.channel === 'Phone')
          const emailContact = _customerInfo.data?.contact?.contactChannels?.find((x) => x.channel === 'Email')
          const zaloContact = _customerInfo.data?.contact?.contactChannels?.find((x) => x.channel === 'Zalo')
          setPONumberPhone(phoneContact?.contactValue ?? '')
          let dataContactInfoFields: any = [
            {
              label: ct('Country'),
              value: contact && contact.country ? countries.find((c) => c.code === contact.country)?.name : '-'
            },
            {
              label: ct('City'),
              value: contact && contact.city ? contact.city : '-'
            },
            {
              label: ct('District'),
              value: contact && contact.district ? contact.district : '-'
            },
            {
              label: ct('Ward'),
              value: contact && contact.ward ? contact.ward : '-'
            },
            {
              label: ct('Address'),
              value: contact && contact.address ? contact.address : '-'
            },
            {
              label: '',
              value: ''
            },
            {
              label: t('VIP'),
              value: getClassCode(contact && contact.vip ? contact.vip : '')
            },
            {
              label: '',
              value: ''
            },
            {
              label: '',
              value: ''
            },
            {
              label: ct('MobilePhone'),
              value: phoneContact && phoneContact.contactValue ? phoneContact.contactValue : ''
            },
            {
              label: t('SMSEmailIndicator'),
              value:
                phoneContact && phoneContact.indicator ? getIndicatorPhoneAndEmail(phoneContact.indicator, 'SMS') : '-'
            },
            {
              label: t('CompanyPhone'),
              value: contact && contact.companyPhone ? contact.companyPhone : ''
            },
            {
              label: ct('Email'),
              value: emailContact && emailContact.contactValue ? emailContact.contactValue : ''
            },
            {
              label: ct('EmailIndicator'),
              value:
                emailContact && emailContact.indicator
                  ? getIndicatorPhoneAndEmail(emailContact.indicator, 'Email')
                  : '-'
            },
            {
              label: cep('OTPConsent'),
              value: contact && contact.otpConsent ? contact.otpConsent : ''
            }
          ]

          if (!userData.isGaLogin) {
            dataContactInfoFields.push(
              {
                label: ct('ZaloID'),
                value: zaloContact?.contactValue
              },
              {
                label: ct('ZaloName'),
                value: zaloContact?.zaloName
              },
              {
                label: ct('ZaloIndicator'),
                value: zaloContact && zaloContact?.indicator ? getIndicatorZalo(zaloContact.indicator) : '-'
              }
            )
          }

          return {
            contactInfoFields: dataContactInfoFields,
            personalInfoSource: policyOwners
              ? policyOwners.map((x) => ({
                  gender: !isEmpty(x.gender)
                    ? x.gender === 'Male' || x.gender === 'M'
                      ? tCommon('Male')
                      : tCommon('Female')
                    : '',
                  role: !!x && x.role ? x.role : '-',
                  clientNumber: x.clientNumber ? x.clientNumber : '-',
                  clientName: !!x && x.clientName ? getName(x.clientName) : '-',
                  nationalID: !!x && x.nationalID ? x.nationalID : '-',
                  dateOfBirth: !!x && x.dateOfBirth ? x.dateOfBirth : '0',
                  ageLatBirth: !!x && x.ageLatBirth ? x.ageLatBirth : '-',
                  // mortalityClass: !!x && x.mortalityClass ? x.mortalityClass : '-',
                  occupationName: !!x && x.occupationName ? x.occupationName : '-',
                  currentAge: !!x && x.currentAge ? x.currentAge : '-',
                  lifeNo: !!x && x.lifeNo ? x.lifeNo : '',
                  priority: getPriority(x.role ?? '', x.lifeNo ?? '', x.usedToBe ?? ''),
                  usedToBe: !!x && x.usedToBe ? getStatusPO(x.usedToBe) : '-'
                }))
              : [],
            faoInfoSource:
              fao && fao.clientName !== ''
                ? [
                    {
                      clientNumber: fao && fao.clientNumber ? fao.clientNumber : '',
                      clientName: fao && fao.clientName ? fao.clientName : '',
                      title: fao && fao.title ? fao.title : '',
                      nationalId: fao && fao.nationalId ? fao.nationalId : '',
                      gender: fao && fao.gender ? fao.gender : '',
                      dateOfBirth: fao && fao.dateOfBirth ? fao.dateOfBirth : ''
                    }
                  ]
                : []
          }
        })
      )
    )
  const { contactInfoFields, personalInfoSource, faoInfoSource } = pipe(
    props.data ? ZIO.succeed(props.data) : getData(),
    ZIO.tap((x) => {
      if (x !== null && props.data === null) props.setData(x)

      if (x.faoInfoSource && isEmpty(x.faoInfoSource[0].clientName)) {
        setIsFAO(false)
      }

      return ZIO.unit
    }),
    ZIO.map((res) => {
      const POList = _.orderBy(res.personalInfoSource, ['priority', 'lifeNo'])
      return {
        contactInfoFields: res.contactInfoFields,
        faoInfoSource: res.faoInfoSource,
        personalInfoSource: POList
          ? POList.map((x) => ({
              ...x,
              role: !!x && x.role ? getRolePolicyOwner(x.role + '', x.lifeNo + '' ?? '') : '-'
            }))
          : []
      }
    }),
    bindLoading,
    ErrorHandling.runDidUpdate([props.data])
  ) || {
    contactInfoFields: [],
    personalInfoSource: [],
    faoInfoSource: []
  }

  const getPriority = (role: string, lifeNo: string, status: string) => {
    if (role === RolesMLA.OW) {
      return StatusPO.Current === status ? 1 : 1.5
    }

    if (role === RolesMLA.LF) {
      if (lifeNo === '01') {
        return StatusPO.Current === status ? 2 : 2.5
      } else {
        return StatusPO.Current === status ? 3 : 3.5
      }
    }

    if (role === RolesMLA.BN) {
      return StatusPO.Current === status ? 4 : 4.5
    }
    return 0
  }

  const getStatusPO = (status: string) => {
    if (status === 'U') {
      return i18next.t('CustomerEngagement:OldPO')
    }
    return i18next.t('CustomerEngagement:CurrentPO')
  }

  const columns = [
    {
      key: '11',
      title: cep('Status'),
      name: 'usedToBe'
    },
    {
      key: '1',
      title: ct('Role'),
      name: 'role'
    },
    {
      key: '2',
      title: ct('Fullname'),
      name: 'clientName'
    },
    {
      key: '3',
      title: requestInfoT('IDNumber'),
      name: 'nationalID'
    },
    // {
    //   key: '4',
    //   title: ct('MortalityClass'),
    //   name: 'mortalityClass'
    // },
    {
      key: '4',
      title: ct('Occupation'),
      name: 'occupationName'
    },
    {
      key: '5',
      title: ct('DOB'),
      name: 'dateOfBirth'
    },
    {
      key: '6',
      title: cep('ClientNumber'),
      name: 'clientNumber'
    },
    {
      key: '7',
      title: ct('AgeOfParticipation'),
      name: 'ageLatBirth'
    },
    {
      key: '8',
      title: ct('CurrentAge'),
      name: 'currentAge'
    },
    {
      key: '9',
      title: ct('Gender'),
      name: 'gender'
    }
  ]

  const columnsFAOINFO = [
    {
      key: '1',
      title: ct('Fullname'),
      name: 'clientName'
    },
    {
      key: '2',
      title: ct('Gender'),
      name: 'gender'
    },
    {
      key: '3',
      title: requestInfoT('IDNumber'),
      name: 'nationalId'
    },
    {
      key: '4',
      title: ct('Position'),
      name: 'title'
    },
    {
      key: '5',
      title: ct('DOB'),
      name: 'dateOfBirth'
    }
  ]

  const getName = (item: { firstName?: string; surName?: string; middleName?: string } | null) => {
    if (!!item) {
      return item.surName + ' ' + item.middleName + ' ' + item.firstName
    }

    return ''
  }

  const duplicationPhoneStatusColumns = [
    {
      key: '1',
      title: cep('DuplicationPhoneNumber'),
      name: 'phoneNumber'
    },
    {
      key: '2',
      title: cep('DuplicationClientNumber'),
      name: 'clientNumber'
    },
    {
      key: '3',
      title: cep('DuplicationCustomerName'),
      name: 'clientName'
    },
    {
      key: '4',
      title: cep('DuplicationAgentNumber'),
      name: 'agentCode'
    },
    {
      key: '5',
      title: cep('DuplicationAgentName'),
      name: 'agentName'
    }
  ]
  React.useEffect(() => {
    const getInfoDuplicationPhone = () =>
      pipe(
        getDuplicationPhone({
          start: 0,
          size: 30,
          data: {
            phoneNumber: PONumberPhone,
            clientNumber: props.clientNumber
          }
        }),
        ZIO.map((res) => {
          const duplicationPhoneData = res.data.map((item) => ({
            phoneNumber: item.phoneNumber ?? '-',
            clientNumber: item.clientNumber ?? '-',
            clientName:
              item.clientSurName || item.clientGivenName
                ? getName({ firstName: item.clientGivenName ?? '', middleName: '', surName: item.clientSurName ?? '' })
                : '-',
            agentCode: item.agentCode ?? '-',
            agentName:
              item.agentGivenName || item.agentSurname
                ? getName({ firstName: item.agentGivenName ?? '', middleName: '', surName: item.agentSurname ?? '' })
                : '-'
          }))

          props?.setDuplicationPhone?.(duplicationPhoneData)
        }),
        ZIO.unsafeRun({})
      )

    if (PONumberPhone !== '') {
      getInfoDuplicationPhone()
    }
  }, [PONumberPhone])

  return (
    <Container loading={loading}>
      {/* {isMobile && <PolicyInfoHeader menus={menus} />} */}
      {contactInfoFields?.length > 0 && (
        <Panel title={t('ContactInfo').toUpperCase()}>
          <CepFieldList dataSource={contactInfoFields} />
        </Panel>
      )}
      {personalInfoSource.length > 0 && (
        <Panel title={t('ClientInfo').toUpperCase()}>
          <CepTableField columns={columns} dataSource={personalInfoSource} />
        </Panel>
      )}
      {faoInfoSource?.length > 0 && isFAO && (
        <Panel title={t('CustomerEngagement:FAOINFO').toUpperCase()}>
          <CepTableField columns={columnsFAOINFO} dataSource={faoInfoSource} />
        </Panel>
      )}
      {(props.duplicationPhoneData ?? []).length > 0 ? (
        <Panel title={cep('DuplicatePhoneStatus').toUpperCase()}>
          <CepTableField columns={duplicationPhoneStatusColumns} dataSource={props.duplicationPhoneData ?? []} />
        </Panel>
      ) : null}
    </Container>
  )
}
