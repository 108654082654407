import React from 'react'
import { pipe } from 'fp-ts/lib/function'
import { Throwable, ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import {
  Container,
  ErrorHandling,
  FieldList,
  Panel,
  useTranslator,
  TableField,
  AppContext,
  ModalComponent,
  useMobile,
  Input,
  ContractMapping,
  PremiumStatusMapping
} from '@pulseops/common'
import { CepTableField, CepFieldList } from '../cep-components'
import { RouteProp, useIsFocused, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { InquiryStackParamList } from '../../InquiryStackParamList'
import { TouchableOpacity, View, Text, StyleSheet, TextStyle } from 'react-native'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { IReinSuranceInfo, IReqDescription, NbuwInfoData, RolesCode } from '../CEPInterface'
import { getNBUWRequestInfo, NbuwRequestInfos } from '@pulseops/inquiry'
import { isEmpty } from 'lodash'

export const CepNBUWScreen = (props: {
  id: string
  data: NbuwInfoData | null
  setData: (val: NbuwInfoData | null) => void
  proposalNo: string
  isGA: boolean
}) => {
  const { t } = useTranslator('TaskManagement')
  const cep = useTranslator('CustomerEngagement').t
  const [loading, bindLoading] = useLoading()
  const ct = useTranslator('contract').t
  const pst = useTranslator('premiumStatus').t
  const isFocused = useIsFocused()
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)
  const { navigate } = useNavigation()
  const { isWide } = useMobile()
  const navigation = useNavigation<StackNavigationProp<InquiryStackParamList, 'InquiryScreen'>>()
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const [isOpenME, setIsOpenME] = React.useState<boolean>(false)
  const route = useRoute<RouteProp<InquiryStackParamList, 'CEPPolicyDetailScreen'>>()
  const [NbRequestDetail, setNbRequestDetail] = React.useState<NbuwRequestInfos | null>({
    requestRole: '',
    roleName: '',
    remark: '',
    requestStatus: '',
    requestType: '',
    createdDate: '',
    createdBy: '',
    lastUpdatedDate: '',
    lastUpdatedBy: ''
  })
  const [reqDescription, setReqDescription] = React.useState<IReqDescription | null>(null)
  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => navigate('HomeScreen')
        },
        {
          title: route?.params?.isSubmission === 'true' ? t('Submission:EForm') : t('menu:360Inquiry'),
          navigate: () =>
            route?.params?.isSubmission === 'true'
              ? navigation.replace('UserDrawer', {
                  screen: 'StaffSubmissionStack'
                })
              : navigation.navigate('CEPSearchScreen')
        },
        {
          title: t('NBUW'),
          navigate: null
        }
      ])
    }
  }, [isFocused])

  const getData = (): ZIO<unknown, Throwable, NbuwInfoData> =>
    pipe(
      getNBUWRequestInfo({
        data: {
          proposalNo: props.proposalNo ?? ''
        }
      }),
      ZIO.map((nbuwData) => {
        const { proposalFinalUWDecision, remarkForCallCenter, reason } = nbuwData.data.uwRequestInfo ?? {
          proposalFinalUWDecision: '',
          remarkForCallCenter: '',
          reason: ''
        }

        const {
          provinceName,
          meServiceLevel,
          panelDoctor,
          suspendRemark,
          reinsuranceRequestDetails,
          conditionAcceptContent
        } = nbuwData.data.reinsuranceRequestInfo ?? {
          province: '',
          meServiceLevel: '',
          panelDoctor: '',
          suspendRemark: '',
          reinsuranceRequestDetails: [],
          conditionAcceptContent: ''
        }

        let conditionListData: { laNo: string; clientNo: string; clientName: string; decision: string; letterSummaryContent: string }[] = [];
        reinsuranceRequestDetails && reinsuranceRequestDetails.length > 0
        ? reinsuranceRequestDetails.map((item) => {
          if(item.uwDecisionBasic === "CA" && isEmpty(item.letterSummaryContent)) {
            return
          }
          const data =   {
            laNo: item.lifeNo ?? '-',
            clientNo: item.clientNumber ?? '-',
            clientName: item.clientName?.name ?? '-',
            decision: item.uwDecisionBasic ?? '-',
            letterSummaryContent: item.letterSummaryContent ?? ''
          }
          conditionListData.push(data)
        }) : [];
        return {
          contractInfo: [
            {
              label: cep('ProposalNo'),
              value: nbuwData.data.proposalNumber ?? '-'
            },
            {
              label: cep('PolicyNo'),
              value: nbuwData.data.policyNumber ?? '-'
            },
            {
              label: cep('PolicyStatus'),
              value: ContractMapping.get(ct)(nbuwData.data.policyStatus ?? '') ?? '-'
            },
            {
              label: cep('PremiumStatus'),
              value: PremiumStatusMapping.get(pst)(nbuwData.data.premiumStatus ?? '') ?? '-'
            }
          ],
          NbRequest: nbuwData.data.nbuwRequestInfos ?? [],
          UwRequest: [
            {
              label: cep('ProposalFinalUWDecision'),
              value: proposalFinalUWDecision ?? '-',
              colLength: '50%'
            },
            {
              label: cep('RemarksForCallCenter'),
              value: remarkForCallCenter ?? '-',
              colLength: '50%'
            },
            {
              label: cep('Reason'),
              value: reason ?? '-',
              colLength: '100%'
            }
          ],
          MeIsReinsuranceRequest: [
            {
              label: cep('Province'),
              value: provinceName ?? '-',
              colLength: '33%'
            },
            {
              label: cep('MEServiceLevel'),
              value: meServiceLevel ?? '-',
              colLength: '33%'
            },
            {
              label: cep('PanelDoctor'),
              value: panelDoctor ?? '-',
              colLength: '33%'
            },
            {
              label: cep('SuspendRemark'),
              value: suspendRemark ?? '-',
              colLength: '33%'
            }
          ],
          ConditionAccept:conditionListData.sort((a,b) => {
            return a.laNo.localeCompare(b.laNo, undefined, {
              numeric: true,
              sensitivity: 'base'
            })
          }),
          ConditionAcceptContent: conditionAcceptContent ?? '',
          reinsurancePOInfo: reinsuranceRequestDetails
            ? reinsuranceRequestDetails
                .filter((it) => it.isRole === RolesCode.PO || it.isRole === RolesCode.AG)
                .map((item) => ({
                  isRole: item.isRole ?? '',
                  clientNumber: item.clientNumber ?? '',
                  clientName: item?.clientName?.name ?? '-',
                  isDescription: item.isDescription ?? '',
                  meDescription: item.meDescription ?? '',
                  reinDescription: item.reinDescription ?? '',
                  lifeNo: item.lifeNo ?? '',
                  uwDecisionBasic: item.uwDecisionBasic ?? '',
                  letterSummaryContent: item.letterSummaryContent ?? '',
                  uwDecision: item.uwDecision ?? ''
                }))
            : [],
          reinsuranceLAInfo: reinsuranceRequestDetails
            ? reinsuranceRequestDetails
                .filter((it) => it.isRole === RolesCode.LA)
                .map((item) => ({
                  isRole: item.isRole ?? '',
                  clientNumber: item.clientNumber ?? '',
                  clientName: item?.clientName?.name ?? '-',
                  isDescription: item.isDescription ?? '',
                  meDescription: item.meDescription ?? '',
                  reinDescription: item.reinDescription ?? '',
                  lifeNo: item.lifeNo ?? '',
                  uwDecisionBasic: item.uwDecisionBasic ?? '',
                  letterSummaryContent: item.letterSummaryContent ?? '',
                  uwDecision: item.uwDecision ?? ''
                }))
            : []
        }
      })
    )

  const {
    contractInfo,
    NbRequest,
    UwRequest,
    MeIsReinsuranceRequest,
    ConditionAccept,
    ConditionAcceptContent,
    reinsurancePOInfo,
    reinsuranceLAInfo
  } = pipe(
    props.data !== null ? ZIO.succeed(props.data) : getData(),
    ZIO.tap((x) => {
      if (x !== null && props.data === null) props.setData(x)
      return ZIO.unit
    }),
    bindLoading,
    ErrorHandling.runDidMount()
  ) || {
    contractInfo: [],
    NbRequest: [],
    UwRequest: [],
    MeIsReinsuranceRequest: [],
    ConditionAccept: [],
    ConditionAcceptContent: '',
    reinsurancePOInfo: [],
    reinsuranceLAInfo: []
  }

  const columnConditionAccept = [
    {
      key: '1',
      title: cep('LANo'),
      name: 'laNo'
    },
    {
      key: '2',
      title: cep('ClientNo'),
      name: 'clientNo'
    },
    {
      key: '3',
      title: cep('LANameNB'),
      name: 'clientName'
    },
    {
      key: '4',
      title: cep('Decision'),
      name: 'decision'
    }
  ]

  const columnsNBRequest = [
    {
      key: '1',
      title: cep('Role'),
      name: 'businessKey'
    },
    {
      key: '2',
      title: cep('Role/Name'),
      name: 'source'
    },
    {
      key: '3',
      title: cep('RemarkNBUW'),
      name: 'policyNumber'
    },
    {
      key: '4',
      title: cep('RequestStatus'),
      name: 'clientNumber'
    },
    {
      key: '5',
      title: cep('RequestDescription'),
      name: 'poClientNumber'
    }
  ]

  const MEReqColumn = [
    {
      key: '1',
      title: cep('Role'),
      name: 'businessKey'
    },
    {
      key: '2',
      title: cep('ClientNo'),
      name: 'source'
    },
    {
      key: '3',
      title: cep('RoleName'),
      name: 'policyNumber'
    },
    {
      key: '4',
      title: cep('RequestForPO/AG'),
      name: 'clientNumber'
    }
  ]

  const ISReqColumn = [
    {
      key: '1',
      title: cep('LANo'),
      name: 'businessKey'
    },
    {
      key: '2',
      title: cep('ClientNo'),
      name: 'source'
    },
    {
      key: '3',
      title: cep('LANameNB'),
      name: 'policyNumber'
    },
    {
      key: '4',
      title: cep('RequestForLA'),
      name: 'clientNumber'
    }
  ]

  const getDetailNbRequest = (item: NbuwRequestInfos) => {
    setIsOpen(true)
    setNbRequestDetail(item)
  }

  const renderTableNoData = () => {
    return (
      <TableRow>
        <TableCell style={{ textAlign: 'center' }} colSpan={12}>
          {t('common:Nodata')}
        </TableCell>
      </TableRow>
    )
  }

  const getTitleDescription = () => {
    switch (reqDescription?.type) {
      case 'ME':
        return cep('MERequestDescription')
      case 'IS':
        return cep('ISRequestDescription')
      case 'LA':
        return cep('ConditionAccept')
      default:
        return cep('ReinsRequestDescription')
    }
  }

  const getTextBtnDescription = (item: IReinSuranceInfo) => {
    if (!isEmpty(item.isDescription)) {
      return cep('MEReq')
    } else if (!isEmpty(item.meDescription)) {
      return cep('ISReq')
    } else {
      return cep('ReInsReq')
    }
  }

  return (
    <Container loading={loading}>
      <Panel title={cep('contract_info')}>
        <CepFieldList dataSource={contractInfo} />
      </Panel>
      {!props.isGA && (
        <>
          <Panel title={cep('NBRequest')}>
            <View style={{ marginTop: 20, marginBottom: 20 }}>
              <TableContainer component={Paper}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow style={{ backgroundColor: '#F4F4F4' }}>
                      {columnsNBRequest.map((column, index) => (
                        <TableCell
                          key={column.key ? column.key : `table-header-${index}-${column.name}`}
                          align="left"
                          style={{ minWidth: 150, fontWeight: 'bold', fontSize: 15 }}
                        >
                          {column.title}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {NbRequest && NbRequest.length > 0
                      ? NbRequest.map((item) => {
                          if (
                            !isEmpty(item.requestRole) &&
                            !isEmpty(item.roleName) &&
                            !isEmpty(item.remark) &&
                            !isEmpty(item.requestStatus)
                          ) {
                            return (
                              <TableRow>
                                <TableCell>{item.requestRole}</TableCell>
                                <TableCell>{item.roleName}</TableCell>
                                <TableCell>{item.remark}</TableCell>
                                <TableCell>
                                  <View
                                    style={{
                                      borderWidth: 2,
                                      borderStyle: 'solid',
                                      borderColor: '#D3DCE6',
                                      borderRadius: 8,
                                      padding: 8
                                    }}
                                  >
                                    <Text style={{ color: '#000000', fontWeight: '400' }}>{item.requestStatus}</Text>
                                  </View>
                                </TableCell>
                                <TableCell>
                                  <TouchableOpacity onPress={() => getDetailNbRequest(item)}>
                                    <View
                                      style={{
                                        backgroundColor: '#ED1B2E',
                                        padding: 8,
                                        borderRadius: 20,
                                        flex: 1,
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                      }}
                                    >
                                      <Text style={{ color: '#FFFFFF' }}>{t('ServiceInquiry:View')}</Text>
                                    </View>
                                  </TouchableOpacity>
                                </TableCell>
                              </TableRow>
                            )
                          }
                        })
                      : renderTableNoData()}
                  </TableBody>
                </Table>
              </TableContainer>
            </View>
          </Panel>
          <Panel title={cep('UWRequest')}>
            <CepFieldList dataSource={UwRequest} />
          </Panel>
          <Panel title={cep('ME/IS/REINSURANCEREQUEST')}>
            <CepFieldList dataSource={MeIsReinsuranceRequest} />

            <View style={{ marginTop: 20, marginBottom: 20 }}>
              <TableContainer component={Paper}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow style={{ backgroundColor: '#F4F4F4' }}>
                      {MEReqColumn.map((column, index) => (
                        <TableCell
                          key={column.key ? column.key : `table-header-${index}-${column.name}`}
                          align="left"
                          style={{ minWidth: 150, fontWeight: 'bold', fontSize: 15 }}
                        >
                          {column.title}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reinsurancePOInfo && reinsurancePOInfo.length > 0
                      ? reinsurancePOInfo.map((it) => {
                          return (
                            <TableRow>
                              <TableCell>{it.isRole}</TableCell>
                              <TableCell>{it.clientNumber}</TableCell>
                              <TableCell>{it.clientName}</TableCell>
                              <TableCell>
                                <TouchableOpacity
                                  onPress={() => {
                                    setIsOpenME(true)
                                    if (!isEmpty(it.isDescription)) {
                                      setReqDescription({
                                        type: 'IS',
                                        reqDes: it.isDescription
                                      })
                                    } else if (!isEmpty(it.meDescription)) {
                                      setReqDescription({
                                        type: 'ME',
                                        reqDes: it.meDescription
                                      })
                                    } else {
                                      setReqDescription({
                                        type: 'REINS',
                                        reqDes: it.reinDescription
                                      })
                                    }
                                  }}
                                >
                                  <View
                                    style={{
                                      backgroundColor: '#ED1B2E',
                                      padding: 8,
                                      borderRadius: 20,
                                      flex: 1,
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      width: 120
                                    }}
                                  >
                                    <Text style={{ color: '#FFFFFF' }}>{getTextBtnDescription(it)}</Text>
                                  </View>
                                </TouchableOpacity>
                              </TableCell>
                            </TableRow>
                          )
                        })
                      : renderTableNoData()}
                  </TableBody>
                </Table>
              </TableContainer>
            </View>

            <View style={{ marginTop: 20, marginBottom: 20 }}>
              <TableContainer component={Paper}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow style={{ backgroundColor: '#F4F4F4' }}>
                      {ISReqColumn.map((column, index) => (
                        <TableCell
                          key={column.key ? column.key : `table-header-${index}-${column.name}`}
                          align="left"
                          style={{ minWidth: 150, fontWeight: 'bold', fontSize: 15 }}
                        >
                          {column.title}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reinsuranceLAInfo && reinsuranceLAInfo.length > 0 ? (
                      reinsuranceLAInfo.map((it) => {
                        return (
                          <TableRow>
                            <TableCell>{it.isRole}</TableCell>
                            <TableCell>{it.clientNumber}</TableCell>
                            <TableCell>{it.clientName}</TableCell>
                            <TableCell>
                              <TouchableOpacity
                                onPress={() => {
                                  setIsOpenME(true)
                                  if (!isEmpty(it.isDescription)) {
                                    setReqDescription({
                                      type: 'IS',
                                      reqDes: it.isDescription
                                    })
                                  } else if (!isEmpty(it.meDescription)) {
                                    setReqDescription({
                                      type: 'ME',
                                      reqDes: it.meDescription
                                    })
                                  } else {
                                    setReqDescription({
                                      type: 'REINS',
                                      reqDes: it.reinDescription
                                    })
                                  }
                                }}
                              >
                                <View
                                  style={{
                                    backgroundColor: '#ED1B2E',
                                    padding: 8,
                                    borderRadius: 20,
                                    flex: 1,
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: 120
                                  }}
                                >
                                  <Text style={{ color: '#FFFFFF' }}>{getTextBtnDescription(it)}</Text>
                                </View>
                              </TouchableOpacity>
                            </TableCell>
                          </TableRow>
                        )
                      })
                    ) : (
                      <TableRow>
                        <TableCell style={{ textAlign: 'center' }} colSpan={12}>
                          {t('common:Nodata')}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </View>
          </Panel>

          <Panel title={cep('ConditionAccept')}>
            {/* <CepTableField columns={columnConditionAccept} dataSource={ConditionAccept} name="ConditionAccept" /> */}
            <View style={{ marginTop: 20, marginBottom: 20 }}>
              <TableContainer component={Paper}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow style={{ backgroundColor: '#F4F4F4' }}>
                      {columnConditionAccept.map((column, index) => (
                        <TableCell
                          key={column.key ? column.key : `table-header-${index}-${column.name}`}
                          align="left"
                          style={{ minWidth: 150, fontWeight: 'bold', fontSize: 15 }}
                        >
                          {column.title}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ConditionAccept && ConditionAccept.length > 0
                      ? ConditionAccept.map((it) => {
                        if(!isEmpty(it.letterSummaryContent))
                          return (
                            <TableRow>
                              <TableCell>{it.laNo}</TableCell>
                              <TableCell>{it.clientNo}</TableCell>
                              <TableCell>{it.clientName}</TableCell>
                              <TableCell>
                                {it.decision === 'CA' ? (
                                  <TouchableOpacity
                                    onPress={() => {
                                      setIsOpenME(true)
                                      setReqDescription({
                                        type: 'LA',
                                        reqDes: it.letterSummaryContent ?? ''
                                      })
                                    }}
                                  >
                                    <View
                                      style={{
                                        backgroundColor: '#ED1B2E',
                                        padding: 8,
                                        borderRadius: 20,
                                        flex: 1,
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: 120
                                      }}
                                    >
                                      <Text style={{ color: '#FFFFFF' }}>{cep('Detail')}</Text>
                                    </View>
                                  </TouchableOpacity>
                                ) : null}
                              </TableCell>
                            </TableRow>
                          )
                        })
                      : renderTableNoData()}
                  </TableBody>
                </Table>
              </TableContainer>
            </View>
            {/* <CepFieldList
              dataSource={[
                {
                  label: cep('ConditionAccept'),
                  value: ConditionAcceptContent ?? '-',
                  colLength: '100%'
                }
              ]}
            /> */}
          </Panel>
        </>
      )}

      {/* Modal Request Description */}
      <ModalComponent
        title={cep('RequestDescriptionPopup')}
        visible={isOpen}
        onClose={() => {
          setIsOpen(false)
          setNbRequestDetail(null)
        }}
        actions={[]}
        modalWidth={440}
      >
        <View
          style={{
            marginHorizontal: 20,
            marginTop: isWide ? 20 : 16
          }}
        >
          <View style={{ width: '100%' }}>
            <Input
              title={cep('Role')}
              value={NbRequestDetail?.requestRole + ' - ' + NbRequestDetail?.roleName ?? ''}
              inputType="text"
              inputStyle={{ fontSize: 15 }}
              maxLength={12}
              alwayShowUnderline={false}
              disabled={true}
            />
          </View>
          <View style={{ width: '100%', marginTop: isWide ? 20 : 16 }}>
            <Input
              title={cep('RequestDescriptionPU')}
              value={NbRequestDetail?.requestType ?? ''}
              inputType="text"
              inputStyle={{ fontSize: 15 }}
              maxLength={12}
              alwayShowUnderline={false}
              disabled={true}
            />
          </View>
          <View style={{ width: '100%', marginTop: isWide ? 20 : 16 }}>
            <Input
              title={cep('RequestContent')}
              value={NbRequestDetail?.remark ?? ''}
              inputType="text"
              inputStyle={{ fontSize: 15 }}
              maxLength={12}
              alwayShowUnderline={false}
              disabled={true}
              multiline={true}
              numberOfLines={4}
            />
          </View>
          <View style={[styles.sectionRow, { justifyContent: 'space-between', marginTop: isWide ? 20 : 16 }]}>
            <View style={styles.col_45}>
              <Input
                title={cep('CreatedDateRewards')}
                value={NbRequestDetail?.createdDate ?? ''}
                inputType="text"
                inputStyle={{ fontSize: 15 }}
                maxLength={12}
                alwayShowUnderline={false}
                disabled={true}
              />
            </View>
            <View style={styles.col_45}>
              <Input
                title={t('requestInfo:CreatedBy')}
                value={NbRequestDetail?.createdBy ?? ''}
                inputType="text"
                inputStyle={{ fontSize: 15 }}
                maxLength={12}
                alwayShowUnderline={false}
                disabled={true}
              />
            </View>
          </View>
          <View style={[styles.sectionRow, { justifyContent: 'space-between', marginTop: isWide ? 20 : 16 }]}>
            <View style={styles.col_45}>
              <Input
                title={cep('LastUpdatedDate')}
                value={NbRequestDetail?.lastUpdatedDate ?? ''}
                inputType="text"
                inputStyle={{ fontSize: 15 }}
                maxLength={12}
                alwayShowUnderline={false}
                disabled={true}
              />
            </View>
            <View style={styles.col_45}>
              <Input
                title={cep('LastUpdatedBy')}
                value={NbRequestDetail?.lastUpdatedBy ?? ''}
                inputType="text"
                inputStyle={{ fontSize: 15 }}
                maxLength={12}
                alwayShowUnderline={false}
                disabled={true}
              />
            </View>
          </View>
        </View>
      </ModalComponent>

      <ModalComponent
        title={cep('RequestDescription')}
        visible={isOpenME}
        onClose={() => {
          setIsOpenME(false)
        }}
        actions={[]}
        modalWidth={440}
      >
        <View
          style={{
            marginHorizontal: 20,
            marginTop: isWide ? 20 : 16
          }}
        >
          <View style={{ width: '100%' }}>
            <Input
              title={getTitleDescription() ?? ''}
              value={reqDescription?.reqDes}
              inputType="text"
              inputStyle={{ fontSize: 15 }}
              alwayShowUnderline={false}
              disabled={true}
              multiline={true}
              numberOfLines={4}
            />
          </View>
        </View>
      </ModalComponent>
    </Container>
  )
}
const styles = StyleSheet.create({
  sectionRow: {
    flex: 1,
    flexWrap: 'wrap',
    flexDirection: 'row'
  },
  col_45: {
    width: '100%',
    maxWidth: '48%'
  }
})
