import * as api from '../task-detail-api'
import { PaymentMethod } from '../PaymentMethod'
import { TransactionType } from '../TransactionType'
import { ClientPolicy, Customer } from '../Client'
import { PaymentType } from '../PaymentType'
import { getCoreDetail, getPayOutDetail } from './common/payout'
// import { InternalPayments } from '../task-detail-api/PaymentData'
import { LoanModel } from '../loan'
import { SourceType } from '../SourceType'

export interface CashOutLoan {
  tag: TransactionType.LOAN

  clientName: string
  surrenderValue: number | null
  principal: number | null
  interest: number | null
  loanAmountAvailable: number | null
  totalAmount: number
  methods: PaymentMethod[]
  cashOutList?: api.PaymentData.CashOutList
  branchName?: string
}

export const CashOutLoan = (
  detail: api.CashOutLoan & api.TaskDetailBase,
  client: Customer,
  poName: string,
  loanDetail: LoanModel.Detail,
  clientPolicy: Array<ClientPolicy>,
  branchName?: string,
  banks?: Array<{ code: string; name: string }>
): CashOutLoan => {
  const p = detail.payload
  const coreStatus = getCoreDetail(detail.coreResponseDetail, p?.body?.cashOutOptions ?? [])
  const cashOutList =
    p && p.body
      ? p.body.cashOutOptions?.filter(
          (x) =>
            PaymentType.BANKTRANSFER === x.type ||
            PaymentType.PAID_AT_BANK === x.type ||
            PaymentType.CASH_AT_COUNTER === x.type
        )
      : []
  return {
    tag: TransactionType.LOAN,
    clientName: poName || client.body.name,
    surrenderValue: Number(loanDetail.contractSurrenderValue || 0),
    principal: Number(loanDetail.principalAplOpl || 0),
    interest: Number(loanDetail.interestAplOpl || 0),
    loanAmountAvailable: Number(loanDetail.loanAmountAvailable || 0),
    totalAmount: Number(
      p && p.body && p.body.cashOutOptions
        ? p.body.cashOutOptions.reduce((total, next) => total + Number(next.paymentOption.amount), 0)
        : 0
    ),
    cashOutList: cashOutList,
    branchName: branchName,
    methods:
      p && p.body
        ? p.body.cashOutOptions.map((v, i) => {
            let nameOfBank = null
            if (detail?.source === SourceType.PULSE) {
              nameOfBank = banks?.find(
                (bank) => v.type === PaymentType.BANKTRANSFER && bank.code === v?.bankAccount?.bankName
              )?.name
            }
            return {
              method: v.type || '-',
              // detail:
              //   v.type === PaymentType.BANKTRANSFER
              //     ? `${v.bankAccount?.bankName || ''} - ${
              //         v.bankAccount?.accountName ? v.bankAccount.accountName + ' -' : ''
              //       } ${v.bankAccount?.accountNo || ''}`
              //     : v.type === PaymentType.EWALLET
              //     ? `${v.bankAccount.branchCode}`
              //     : v.type && InternalPayments.includes(v.type)
              //     ? getDetail(v.policy?.policyNo, clientPolicy)
              //     : '-',
              detail: getPayOutDetail(
                {
                  type: v.type as PaymentType,
                  officeCode:
                    (v.type === PaymentType.CASH_AT_COUNTER && v.attributesExt?.PVA_CASH_OUT_OPTION_INFO.officeCode) ||
                    '',

                  bankAccount: {
                    accountName:
                      (v.type === PaymentType.BANKTRANSFER && v.bankAccount?.accountName) ||
                      (v.type === PaymentType.PAID_AT_BANK &&
                        (v.attributesExt?.PAYEE_INFO?.payeeName === ''
                          ? poName
                          : v.attributesExt?.PAYEE_INFO?.payeeName)) ||
                      (v.type === PaymentType.CASH_AT_COUNTER &&
                        (v.attributesExt?.PAYEE_INFO?.payeeName === ''
                          ? poName
                          : v.attributesExt?.PAYEE_INFO?.payeeName)) ||
                      ((v.type === PaymentType.MOMO && v.attributesExt?.PAYEE_INFO?.payeeName) ?? poName) ||
                      '-',
                    accountNo:
                      (v.type === PaymentType.BANKTRANSFER && v.bankAccount?.accountNo) ||
                      ((v.type === PaymentType.PAID_AT_BANK && v.attributesExt?.PAYEE_INFO?.idNumber) ??
                        detail.clientNumber) ||
                      ((v.type === PaymentType.CASH_AT_COUNTER && v.attributesExt?.PAYEE_INFO?.idNumber) ??
                        detail.clientNumber) ||
                      ((v.type === PaymentType.MOMO && v.attributesExt?.PAYEE_INFO?.idNumber) ?? detail.clientNumber) ||
                      '-',
                    bankName:
                      (v.type === PaymentType.BANKTRANSFER && (nameOfBank ?? v.bankAccount?.bankName)) ||
                      (v.type === PaymentType.PAID_AT_BANK && v.bankAccount?.bankName) ||
                      '-',
                    branchName:
                      (v.type === PaymentType.BANKTRANSFER && branchName) ||
                      (v.type === PaymentType.PAID_AT_BANK && branchName) ||
                      undefined,
                    bankCode: '-',
                    branchCode: (v.type === PaymentType.BANKTRANSFER && v.bankAccount?.branchCode) || '-'
                  },
                  phoneNumber: (v.type === PaymentType.MOMO && v.attributesExt?.PAYEE_INFO?.phone) || '',
                  issuedDate: (v.type === PaymentType.PAID_AT_BANK && v.attributesExt?.PAYEE_INFO?.issuedDate) || '',
                  issuedBy: (v.type === PaymentType.PAID_AT_BANK && v.attributesExt?.PAYEE_INFO?.issuedBy) || '',
                  policy: {
                    policyNo: v.policy?.policyNo || '-'
                  }
                },
                clientPolicy
              ),
              amount: v.paymentOption.amount || 0,
              status: coreStatus[i].status,
              note: coreStatus[i].errorMes,
              action: '-',
              bankCode: v.type === PaymentType.BANKTRANSFER ? v.bankAccount?.bankName || '-' : undefined
            }
          })
        : []
  }
}
