import { CircularProgress } from '@material-ui/core'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import {
  AppContext,
  useMobile,
  BonusSurrenderService,
  ErrorHandling,
  PulseOpsFormat,
  PayoutPopup,
  BonusSurrenderPayment,
  form2,
  TransactionType,
  GeneralService,
  StorageBlob,
  TaskType
} from '@pulseops/common'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { pipe } from 'fp-ts/lib/function'
import _ from 'lodash'
import React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { View, TextInput } from 'react-native'
import { SC } from '../../common'
import { PayoutMethod, PayoutMethodRef, mapCashOutOption } from '../../payout-method'
import { RequestAuthenticateData } from '../../request-authen'
import { PolicyServiceProps } from '../policy-service-props'
import { BonusSurrenderForm } from './bonus-surrender-form'

type Props = PolicyServiceProps<BonusSurrenderPayment.SubmiDataV2> & {}

export const BonusSurrender: React.FC<Props> = ({ initSubmission, policyNumber, isConfirmed, officeCode }) => {
  const { isMobile } = useMobile()
  const { t } = useTranslation()
  const { changeBreadcrumb, showGlobalLoading, showToast } = React.useContext(AppContext.AppContextInstance)
  const isFocused = useIsFocused()
  const { navigate } = useNavigation()
  const [loading, bindLoader] = useLoading(false)
  const [loaderInit, bindLoaderInit] = useLoading(false)
  const [payouts, setPayouts] = React.useState<PayoutPopup.Summary[]>([])
  const payoutMethodRef = React.useRef<PayoutMethodRef>(null)
  const [initValue, setInitValue] = React.useState<BonusSurrenderForm.Raw>({
    bonusValue: '0',
    principalAplOpl: '0',
    interestAplOpl: '0',
    bonusValueAmountToSurrender: '0',
    bonusReserveValue: '0',
    bonusReserveRemainValue: '0',
    cashValue: '0'
  })
  const {
    base: {
      control,
      getValues,
      reset,
      watch,
      trigger,
      formState: { isValid, errors }
    }
  } = form2.useForm(BonusSurrenderForm.codec, {
    defaultValues: {
      bonusValue: '0',
      principalAplOpl: '0',
      interestAplOpl: '0',
      bonusValueAmountToSurrender: '0',
      bonusReserveValue: '0',
      bonusReserveRemainValue: '0',
      cashValue: '0'
    }
  })

  const bonusReverve = watch('bonusReserveValue')

  React.useEffect(() => {
    showGlobalLoading(loading)
  }, [loading])

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigate('HomeScreen')
          }
        },
        {
          title: t('Submission:EForm'),
          navigate: () => navigate('StaffSubmissionStack', { screen: 'StaffSubmissionScreen' })
        },
        {
          title: t('common:PolicyServices'),
          navigate: () => navigate('PSSubmissionStack', { screen: 'PSSubmissionListScreen' })
        },
        { title: t('TransactionType:BONUS_SURRENDER'), navigate: null }
      ])
    }
    return () => {
      reset(initValue)
      payoutMethodRef.current?.clearData()
    }
  }, [isFocused])

  // const getPayoutDocuments = () => {
  //   let payoutDocuments: FileUploadData[] = []
  //   payouts.map((payoutItem) => {
  //     const relativeDocs = payoutItem.payee?.isPayeeNotPO ? payoutItem.payee.relativeDocument : []
  //     payoutDocuments = [...(relativeDocs as []), ...payoutDocuments]
  //   })
  //   return payoutDocuments
  // }

  initSubmission({
    validate: async (isContinue) => {
      await trigger()
      // console.log(errors)
      // console.log(formState)
      let uploadedFiles: StorageBlob.FileContentSubmit[] = []
      const bonusAmountToSurrender = getValues('bonusValueAmountToSurrender')
      if (isValid) {
        if (bonusAmountToSurrender && Number(bonusAmountToSurrender) === 0) {
          showToast(t('message:MS020001', { field: t('submission:BonusValueAmountToSur') }), 'error')
          return false
        } else if (totalBonus === 0) {
          showToast(t('message:MS050241'), 'error')
          return false
        } else if (!Number.isNaN(getValues('bonusReserveValue')) && Number(getValues('bonusReserveValue')) === 0) {
          showToast(
            t('message:MS050264', {
              bonusAmount: PulseOpsFormat.thousandSepartor(Number(getValues('cashValue') || 0)) + ' VND'
            }),
            'error'
          )
          return false
        } else if (totalBonus !== Number(getValues('bonusReserveValue'))) {
          showToast(
            t('message:MS050273', {
              bonusAmount: PulseOpsFormat.thousandSepartor(Number(getValues('bonusReserveValue') || 0)) + ' VND'
            }),
            'error'
          )
          return false
        }
        const { policy, cashOutOptions } = mapData()
        const requestData: BonusSurrenderPayment.SubmiDataV2 = {
          policy: {
            policyNo: policyNumber!
          },
          attributesExt: {
            bonusValue: Number(getValues('bonusValue') || 0),
            principalAplOpl: Number(getValues('principalAplOpl') || 0),
            interestAplOpl: Number(getValues('interestAplOpl') || 0),
            bonusValueAmountToSurrender: PulseOpsFormat.thousandSepartorReverse(
              getValues('bonusValueAmountToSurrender') || '0'
            ),
            bonusReserveValue: Number(getValues('bonusReserveValue') || 0),
            bonusReserveRemainValue: Number(getValues('bonusReserveRemainValue') || 0),
            TOTAL_PAYOUT_AMOUNT: totalBonus,
            BONUS_AMOUNT: +policy.attributesExt.bonusValueAmountToSurrender
          },
          cashOutOptions
        }
        if (!isContinue) {
          // const payoutDocuments = getPayoutDocuments()
          // uploadedFiles = await pipe(
          //   GeneralService.getMetaForPayoutDocuments(
          //     payoutDocuments,
          //     TransactionType.BONUS_SURRENDER,
          //     'DPS01',
          //     policyNumber ?? '',
          //     officeCode ?? ''
          //   ),
          //   ZIO.flatMap((metaDataFiles) =>
          //     metaDataFiles && metaDataFiles.length > 0
          //       ? StorageBlob.uploadToSubmit('PS', policyNumber ?? '')(metaDataFiles)
          //       : ZIO.succeed([])
          //   ),
          //   bindLoader,
          //   ZIO.unsafeRun({})
          // )
          uploadedFiles = await pipe(
            GeneralService.getAzureStorageFiles(
              GeneralService.getPayoutDocuments(payouts),
              TransactionType.BONUS_SURRENDER,
              'DPS01',
              TaskType.PolicyService,
              policyNumber ?? '',
              officeCode ?? ''
            ),
            bindLoader,
            ZIO.unsafeRun({})
          )
        }
        return {
          url: (_) => `wf-api/policy/${policyNumber!}/surrender-bonus`,
          body: requestData,
          collerationId: policyNumber ?? '',
          transactionName: RequestAuthenticateData.TransactionLabelShort(TransactionType.BONUS_SURRENDER),
          transaction: TransactionType.BONUS_SURRENDER,
          documents: uploadedFiles
        }
      } else {
        //console.log('form is invalid')
        return false
      }
    },
    clear: () => {
      reset(initValue)
      payoutMethodRef.current?.clearData()
    }
  })

  const mapData = (): BonusSurrenderPayment.SubmitData => {
    return {
      policy: {
        owners: {
          clientId: policyNumber!
        },
        attributesExt: {
          bonusValueAmountToSurrender: PulseOpsFormat.thousandSepartorReverse(
            getValues('bonusValueAmountToSurrender') || '0'
          )
        }
      },
      cashOutOptions: mapCashOutOption(payouts)
    }
  }

  const totalBonus = React.useMemo(() => {
    return payouts.reduce((pre, cur) => pre + cur.amount, 0)
  }, [payouts])

  pipe(
    ZIO.zipPar(
      BonusSurrenderService.getAccountBalance(policyNumber!),
      BonusSurrenderService.getPolicyLoanEnquiry(policyNumber!)
    ),
    ZIO.flatMap(([accountBalance, policyLoanEnquiry]) => {
      // console.log('acccccount blance', accountBalance)
      // console.log('account inquiry', policyLoanEnquiry)
      return ZIO.zipPar(
        ZIO.succeed(accountBalance),
        ZIO.succeed(policyLoanEnquiry),
        BonusSurrenderService.bonusSurrenderPolicyLoanEnquiry(
          {
            contractNumber: policyNumber!,
            bonusReserveValue: accountBalance.amountLERV
          },
          true
        )
      )
    }),
    ZIO.map(([accountBalance, policyLoanEnquiry, bonusReverse]) => {
      //console.log('_Bonus_reverse_tag', bonusReverse)
      const valueForm = {
        ...getValues(),
        bonusValue: accountBalance.amountLERV.toString(),
        principalAplOpl: policyLoanEnquiry.principalAplOpl.toString(),
        interestAplOpl: policyLoanEnquiry.interestAplOpl.toString(),
        //bonusReserveValue: bonusReverse.body.bonusReserveValue.toString(),
        bonusReserveRemainValue: accountBalance.amountLERV.toString(),
        cashValue: bonusReverse.body.cashValue.toString()
      }
      setInitValue(valueForm)
      reset(valueForm)
      return ZIO.unit
    }),
    ZIO.tapError((_) => ZIO.unit),
    bindLoaderInit,
    ErrorHandling.runDidUpdate([policyNumber])
  )

  const getBonusSurrenderLoanEnquiry = (value: number) => {
    payoutMethodRef.current?.clearData()
    const wrapValue = +value
    const bonusWrapValue = +(getValues('bonusValue') || 0)
    if (value && !Number.isNaN(wrapValue)) {
      if (wrapValue > bonusWrapValue) {
        return showToast(
          t('message:MS050264', { bonusAmount: PulseOpsFormat.thousandSepartor(bonusWrapValue) + ' VND' }),
          'error'
        )
      }

      return pipe(
        BonusSurrenderService.bonusSurrenderPolicyLoanEnquiry({
          contractNumber: policyNumber!,
          bonusReserveValue: wrapValue
        }),
        ZIO.map(({ body }) => {
          const bonusReserveRemainValue = Number(getValues('bonusValue') || 0) - wrapValue
          reset({
            ...getValues(),
            bonusReserveValue: body.bonusReserveValue.toString(),
            bonusReserveRemainValue: bonusReserveRemainValue.toString(),
            bonusValueAmountToSurrender: PulseOpsFormat.thousandSepartor(wrapValue)
          })
          return ZIO.unit
        }),
        bindLoader,
        ErrorHandling.run()
      )
    } else {
      reset(initValue)
    }
  }

  const composeWith = isMobile ? '100%' : '33%'
  const composePadding = isMobile ? 15 : 10

  return (
    <SC.Container>
      <SC.Padding vertical={10}>
        <SC.SessionText>{t('submission:BonusSuerrenderInfo')}</SC.SessionText>
      </SC.Padding>
      <SC.PanelContainer>
        {loaderInit ? (
          <SC.CenterSelf>
            <CircularProgress />
          </SC.CenterSelf>
        ) : (
          <>
            <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
              <View style={{ width: composeWith, paddingVertical: composePadding }}>
                <Controller
                  control={control}
                  name={'bonusValue'}
                  render={({ field: { value } }) => {
                    return (
                      <_Item
                        title={t('submission:BonusValue')}
                        value={`${PulseOpsFormat.thousandSepartor(Number(value || 0) || 0)} VND`}
                      />
                    )
                  }}
                />
              </View>
              <View style={{ width: composeWith, paddingVertical: composePadding }}>
                <Controller
                  control={control}
                  name={'principalAplOpl'}
                  render={({ field: { value } }) => {
                    return (
                      <_Item
                        title={t('submission:Principal_apl_opl')}
                        //value={`${policyLoanEnquiry?.principalAplOpl || 0} VND`}
                        value={`${PulseOpsFormat.thousandSepartor(Number(value || 0) || 0)} VND`}
                      />
                    )
                  }}
                />
              </View>
              <View style={{ width: composeWith, paddingVertical: composePadding }}>
                <Controller
                  control={control}
                  name={'interestAplOpl'}
                  render={({ field: { value } }) => {
                    return (
                      <_Item
                        title={t('submission:Interest_apl_opl')}
                        value={`${PulseOpsFormat.thousandSepartor(Number(value || 0) || 0)} VND`}
                      />
                    )
                  }}
                />
              </View>
            </View>
            <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
              <View style={{ width: composeWith, paddingVertical: composePadding }}>
                <SC.Padding right={30}>
                  <Controller
                    control={control}
                    name={'bonusValueAmountToSurrender'}
                    render={({ field: { value, onChange, onBlur } }) => {
                      return (
                        <SC.Padding>
                          <SC.TitleText>
                            {t('submission:BonusValueAmountToSur')}
                            <SC.ErrorText> * </SC.ErrorText>
                          </SC.TitleText>
                          <SC.Row>
                            {!isConfirmed ? (
                              <SC.ExpandView>
                                <TextInput
                                  value={value ?? ''}
                                  onChangeText={(valueChange) => {
                                    const _change = valueChange.split(',').join('')
                                    if (/^[0-9]*$/.test(_change)) {
                                      onChange(PulseOpsFormat.thousandSepartor(Number(_change)))
                                    } else if (valueChange === '') {
                                      onChange('')
                                    }
                                  }}
                                  keyboardType={'numeric'}
                                  onBlur={() => {
                                    onBlur()
                                    if (value)
                                      getBonusSurrenderLoanEnquiry(PulseOpsFormat.thousandSepartorReverse(value))
                                  }}
                                  maxLength={15}
                                  style={{ borderBottomWidth: isConfirmed ? 0 : 1, paddingVertical: 5 }}
                                  editable={!isConfirmed}
                                />
                              </SC.ExpandView>
                            ) : (
                              <SC.CenterSelf>
                                <SC.NormalText>{value}</SC.NormalText>
                              </SC.CenterSelf>
                            )}
                            <SC.CenterSelf>
                              <SC.NormalText> VND</SC.NormalText>
                            </SC.CenterSelf>
                          </SC.Row>
                          <SC.ErrorText>{_.get(errors, '')}</SC.ErrorText>
                        </SC.Padding>
                      )
                    }}
                  />
                </SC.Padding>
              </View>
              <View style={{ width: composeWith, paddingVertical: composePadding }}>
                <Controller
                  control={control}
                  name={'bonusReserveValue'}
                  render={({ field: { value } }) => {
                    return (
                      <_Item
                        title={t('submission:BonusReserveValue')}
                        //value={`${PulseOpsFormat.thousandSepartor(bonusReverse?.bonusReserveValue || 0)} VND`}
                        value={`${PulseOpsFormat.thousandSepartor(Number(value || 0) || 0)} VND`}
                        highlight
                      />
                    )
                  }}
                />
              </View>
              <View style={{ width: composeWith, paddingVertical: composePadding }}>
                <Controller
                  control={control}
                  name={'bonusReserveRemainValue'}
                  render={({ field: { value } }) => {
                    return (
                      <_Item
                        title={t('submission:BonusReserveRemainValue')}
                        value={`${PulseOpsFormat.thousandSepartor(Number(value || 0) || 0)} VND`}
                      />
                    )
                  }}
                />
              </View>
            </View>
          </>
        )}
      </SC.PanelContainer>
      <PayoutMethod
        ref={payoutMethodRef}
        maxAmount={Number(bonusReverve || 0)}
        onChange={setPayouts}
        value={payouts}
        policyNum={policyNumber!}
        editable={!isConfirmed}
        methods={[
          PayoutPopup.PayoutMethods.PAYPREMIUM,
          PayoutPopup.PayoutMethods.PAYLOAN,
          PayoutPopup.PayoutMethods.OTHER,
          PayoutPopup.PayoutMethods.CASHLESS,
          PayoutPopup.PayoutMethods.CASH_AT_COUNTER,
          PayoutPopup.PayoutMethods.PAID_AT_BANK,
          PayoutPopup.PayoutMethods.BANKTRANSFER,
          // PayoutPopup.PayoutMethods.MOMO
        ]}
        transactionType={TransactionType.BONUS_SURRENDER}
        officeCode={officeCode}
      />
    </SC.Container>
  )
}

const _Item: React.FC<{ title: string; value: string; highlight?: boolean }> = ({
  title,
  value,
  highlight = false
}) => {
  return (
    <View>
      <SC.Padding>
        <SC.TitleText>{title}</SC.TitleText>
        {highlight ? <SC.BoldText color={'red'}>{value}</SC.BoldText> : <SC.NormalText>{value}</SC.NormalText>}
      </SC.Padding>
    </View>
  )
}
