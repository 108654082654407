import * as React from 'react'
import {
  ModalComponent,
  HeaderTable,
  Title,
  PayoutPopup,
  formatNumberWithComma,
  FieldList,
  assets,
  form2,
  Input,
  Divider,
  PayoutService,
  ModalComponentRef,
  ModalAction,
  Columns,
  DataSource,
  getLanguage,
  InputTable,
  ErrorHandling,
  Alert
} from '@pulseops/common'
import {
  useWindowDimensions,
  View,
  ScrollView,
  TouchableOpacity,
  StyleProp,
  ViewStyle,
  ActivityIndicator,
  StyleSheet,
  Text,
  TextInput,
  TextStyle
} from 'react-native'
import { PolicyOfOrtherClientForm } from './policy-of-orther-client.form'
import { Controller, useFieldArray } from 'react-hook-form'
import { Label, Error, SectionRow, SectionCol, Checkbox } from '@pulseops/submission/common'
import { pipe } from 'fp-ts/function'
import { ZIO } from '@mxt/zio'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { Paper, Popover, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@material-ui/core'
import Icon from 'react-native-vector-icons/AntDesign'
interface IconButtonProps {
  title: string
  style?: StyleProp<ViewStyle>
  icon: () => JSX.Element
  onPress: () => void
}

const IconButton = ({ title, icon, style, onPress }: IconButtonProps) => {
  return (
    <TouchableOpacity
      activeOpacity={0.7}
      style={[
        {
          height: 32,
          alignItems: 'center',
          flexDirection: 'row',
          alignSelf: 'baseline'
        },
        style
      ]}
      onPress={onPress}
    >
      {icon()}
      <Divider width={12} />
      <Label title={title} fontWeight="600" color="#000000" />
    </TouchableOpacity>
  )
}

interface Props {
  primaryPolicy: string
  details: PayoutPopup.PayPremiumData[]
  visible: boolean
  onClose: () => void
  onConfirm: (payouts: PayoutPopup.PayoutData[]) => void
  isSeaBankPolicy?: boolean
}

export const PayPremiumPopup = ({ visible, details, primaryPolicy, onClose, onConfirm, isSeaBankPolicy }: Props) => {
  const { width, height } = useWindowDimensions()
  const { t } = useTranslation()

  const modalRef = React.useRef<ModalComponentRef | null>(null)

  const [rowsSelected, setRowsSelected] = React.useState<Map<number, PayoutPopup.PayPremiumData>>(new Map())
  const [loadingMap, setLoadingMap] = React.useState<Map<number, boolean>>(new Map())
  const [totalItem, setTotalItem] = React.useState<number>(0)
  const [pageNum, setPageNum] = React.useState<number>(0)
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [premiumPolicy, setPremiumPolicy] = React.useState<PayoutPopup.PayPremiumData[]>([])
  const [isOpenDeletedPopup, setIsOpenDeletedPopup] = React.useState<boolean>(false)
  const [deletedIndex, setDeletedIndex] = React.useState<number>(-1)
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  // const touchableRef = React.useRef<TableCell>(null)
  const IDPopover = isOpenDeletedPopup ? 'simple-popover' : undefined

  const POCForm = form2.useForm(PolicyOfOrtherClientForm.codec, { defaultValues: { list: [] } })
  const payPremiumPolicyForm = form2.useForm(PolicyOfOrtherClientForm.payPremiumPolicyDetail, {
    defaultValues: {
      selectedAllRow: false,
      payPreOptionalPolicyList: [],
      payPremiumChosenPolicyArr: []
    }
  })
  const POCFormList = useFieldArray<PolicyOfOrtherClientForm.Raw>({
    control: POCForm.base.control,
    name: 'list'
  })

  const payPreOptionalPolicyList = useFieldArray<PolicyOfOrtherClientForm.payPreOptionalPolicyListRaw>({
    control: payPremiumPolicyForm.base.control,
    name: 'payPreOptionalPolicyList'
  })

  const payPremiumChosenPolicyArr = useFieldArray<PolicyOfOrtherClientForm.payPreOptionalPolicyListRaw>({
    control: payPremiumPolicyForm.base.control,
    name: 'payPremiumChosenPolicyArr'
  })

  const { list } = POCForm.base.watch()

  React.useEffect(() => {
    visible && resetPagination(details)
    return () => {
      payPremiumChosenPolicyArr.remove()
    }
  }, [visible])

  React.useEffect(() => {
    if (details && details.length > 0) {
      for (let i = 0; i < details.length; i++) {
        const payPreItem = {
          policyNumber: details[i].policyNum,
          paidToDate: details[i].paidToDate,
          installmentPremium: details[i].installmentPremium.toString(),
          selectedRow: false,
          poName: details[i].poName,
          totalPremium: details[i].totalPremium
        }
        payPreOptionalPolicyList.append(payPreItem)
        payPremiumPolicyForm.base.setValue(`payPreOptionalPolicyList.${i}`, payPreItem)
      }
    }
    setPremiumPolicy(details.filter((item, index) => index < pageSize))
  }, [details])

  const showSearchLoadingAtIndex = (index: number, isShow: boolean) => {
    const map = new Map(loadingMap)
    map.set(index, isShow)
    setLoadingMap(map)
  }

  const searchPolicy = (policyNum: string, formIndex: number) => {
    if (policyNum === primaryPolicy) {
      modalRef?.current?.showToast(t('message:MS050110'), 'error')
      POCFormList.update(formIndex, { policyNumber: policyNum })
      return
    }

    showSearchLoadingAtIndex(formIndex, true)
    return pipe(
      PayoutService.searchPolicy(policyNum),
      ZIO.catchAll((error) => {
        showSearchLoadingAtIndex(formIndex, false)
        modalRef?.current?.showToast(error.source.message, 'error')
        return ZIO.fail(null)
      }),
      ZIO.tap((response) => {
        showSearchLoadingAtIndex(formIndex, false)

        const isNotFound =
          response.paidToDate === '-' && response.totalPremium === 0 && response.installmentPremium === 0

        if (isNotFound) {
          modalRef?.current?.showToast(t('message:MS030029'), 'error')
          POCFormList.update(formIndex, { policyNumber: policyNum })
        } else {
          if (response.isPayPremiumValid) {
            POCFormList.update(formIndex, {
              policyNumber: policyNum,
              poName: response.poName,
              paidToDate: response.paidToDate,
              finalPolicyNum: response.policyNum,
              totalPremium: `${response.totalPremium || ''}`,
              installmentPremium: `${response.installmentPremium || ''}`
            })
          } else {
            modalRef?.current?.showToast(t('message:MS050259'), 'error')
            POCFormList.update(formIndex, { policyNumber: policyNum })
          }
        }
        return ZIO.unit
      }),
      ZIO.unsafeRun({})
    )
  }

  const resetForm = () => {
    rowsSelected.clear()
    POCFormList.remove()
  }

  const onPressClose = () => {
    resetForm()
    onClose()
  }

  const onPressConfirm = POCForm.handleSubmit((data) => {
    // const payPremiumArr = [...rowsSelected.values()]
    const payPremiumArr = isSeaBankPolicy ? payPremiumPolicyForm.base.getValues('payPremiumChosenPolicyArr') : payPremiumPolicyForm.base.getValues('payPreOptionalPolicyList')
    const payPremiumData = payPremiumArr
      .filter((x) => x.selectedRow === true)
      .filter((e) => Number(e.installmentPremium) > 0)
      .map(
        (e): PayoutPopup.PayoutData => ({
          method: PayoutPopup.PayoutMethods.PAYPREMIUM,
          amount: Number(e.installmentPremium),
          policyNum: e.policyNumber ?? '',
          poName: e.poName,
          totalPremium: e.totalPremium,
          payee: undefined,
          officeBankCode: undefined,
          officeType: undefined
        })
      )

    const otherPolicyArr: PayoutPopup.PayoutData[] = list
      .filter((e) => Number(e.installmentPremium || 0) > 0)
      .map(
        (e): PayoutPopup.PayoutData => ({
          method: PayoutPopup.PayoutMethods.PAYPREMIUM,
          poName: e.poName || '-',
          policyNum: e.finalPolicyNum || '-',
          amount: Number(e.installmentPremium),
          totalPremium: Number(e.totalPremium || 0),
          payee: undefined,
          officeBankCode: undefined,
          officeType: undefined
        })
      )
    onConfirm([...payPremiumData, ...otherPolicyArr])
    resetForm()
    otherPolicyArr.length = 0
  })

  const isDisableRule = isSeaBankPolicy ? payPremiumPolicyForm.base.watch('payPremiumChosenPolicyArr').length <= 0 : payPremiumPolicyForm.base.getValues('payPreOptionalPolicyList').filter((x) => x.selectedRow).length <= 0

  const modalActions: ModalAction[] = [
    {
      text: t('common:Cancel'),
      type: 'outline',
      action: onPressClose,
      disabled: false,
      loading: false,
      onlyWide: false,
      style: styles.btnCancel
    },
    {
      text: t('submission:Confirm'),
      type: 'filled',
      action: onPressConfirm,
      // disabled: rowsSelected.size === 0 && POCFormList.fields.length === 0,
      disabled: isDisableRule,
      loading: false,
      onlyWide: false,
      style: styles.btnConfirm
    }
  ]

  const seabankPayPreColumns: Columns[] = [
    {
      key: 'PAY0',
      name: '',
      title: '',
      render: () => {
        return (
          !isSeaBankPolicy ?
            (
              <Controller
                control={payPremiumPolicyForm.base.control}
                name={`selectedAllRow`}
                render={({ field: { value, onChange, onBlur } }) => {
                  return (
                    <Checkbox
                      style={styles.checkboxItem}
                      value={value}
                      onChange={(val) => {
                        onChange(val)
                        onSelectAllRowEvent(val)
                      }}
                      onBlur={onBlur}
                      enable={true}
                    />
                  )
                }}
              />
            ) : <></>
        )
      }
    },
    {
      key: 'PAY1',
      name: 'PolicyNumber',
      title: t('submission:PolicyNumber'),
      styles: { fontWeight: 'bold' }
    },
    {
      key: 'PAY2',
      name: 'PaidToDate',
      title: t('TaskManagement:PaidToDate'),
      styles: { fontWeight: 'bold' }
    },
    {
      key: 'PAY3',
      name: 'InstallmentPremium',
      title: t('Payout:InstallmentPremium'),
      styles: { fontWeight: 'bold', textAlign: 'right', paddingRight: 19 }
    }
  ]

  const payPremiumTableColumns: Columns[] = [
    {
      key: '0',
      name: 'policyNum',
      title: t('submission:PolicyNumber'),
      styles: {
        textAlign: getLanguage() === 'en' ? 'center' : 'right'
      }
    },
    {
      key: '1',
      name: 'paidToDate',
      title: t('TaskManagement:PaidToDate'),
      flex: 1,
      styles: {
        textAlign: 'center'
      }
    },
    {
      key: '2',
      name: 'installmentPremium',
      title: t('Payout:InstallmentPremium'),
      styles: {
        textAlign: 'right'
      }
    }
  ]

  const onTableRowSelected = (index: number, _data: DataSource) => {
    const rows = new Map(rowsSelected)
    rows.set(index, details[index])
    setRowsSelected(rows)
  }

  const onTableRowUnSelected = (index: number, _data: DataSource) => {
    const rows = new Map(rowsSelected)
    rows.delete(index)
    setRowsSelected(rows)
  }

  const onAllTableRowSelected = () => {
    const rows = details.reduce((result, item, index) => {
      result.set(index, item)
      return result
    }, new Map<number, PayoutPopup.PayPremiumData>())
    setRowsSelected(rows)
  }

  const onAllTableRowUnSelected = () => {
    setRowsSelected(new Map())
  }

  const renderSearchIcon = (isEnable: boolean, isLoading: boolean) => {
    return isLoading ? (
      <ActivityIndicator color="red" size={24} />
    ) : isEnable ? (
      <assets.Search20Icon />
    ) : (
      <assets.SearchDisableIcon />
    )
  }

  const renderPOCPolicySearchInputController = (index: number) => {
    return (
      <Controller
        control={POCForm.base.control}
        name={`list.${index}.policyNumber`}
        render={({ field, fieldState: { error, invalid } }) => {
          const isValid = field.value && !invalid
          const isSearching = loadingMap.get(index) || false
          return (
            <View style={{ marginBottom: 22 }}>
              <View style={styles.searchPolicyContainer}>
                <Input
                  {...field}
                  title={t('submission:PolicyNumber')}
                  required={true}
                  maxLength={8}
                  value={field.value || ''}
                  disabled={isSearching}
                  alwayShowUnderline={true}
                  onKeyEnter={() => field.value && searchPolicy(field.value, index)}
                />
                <TouchableOpacity
                  disabled={!isValid || isSearching}
                  onPress={() => field.value && searchPolicy(field.value, index)}
                  style={styles.btnSearch}
                >
                  {renderSearchIcon(isValid, isSearching)}
                </TouchableOpacity>
              </View>
              {error?.message && <Error message={error.message} />}
            </View>
          )
        }}
      />
    )
  }

  const renderPOCFieldList = (index: number) => {
    const data = list[index]
    return (
      <FieldList
        dataSource={[
          {
            label: t('submission:POname'),
            value: data?.poName?.trim() || '-'
          },
          {
            label: t('TaskManagement:PaidToDate'),
            value: data?.paidToDate?.trim() || '-'
          },
          {
            label: t('Payout:InstallmentPremium'),
            value: `${formatNumberWithComma(data?.installmentPremium)} VND` || '-'
          }
        ]}
      />
    )
  }

  const renderPOCForm = (item: Record<'id', string>, index: number) => {
    return (
      <View style={styles.ortherPolicyContainer} key={item.id}>
        {renderPOCPolicySearchInputController(index)}
        {renderPOCFieldList(index)}
        <IconButton
          title={t('common:Delete')}
          icon={() => <assets.DeleteBin />}
          onPress={() => POCFormList.remove(index)}
        />
      </View>
    )
  }

  const resetPayPremPolicyArr = (dataArr: PayoutPopup.PayPremiumData[]) => {
    let filterList: PayoutPopup.PayPremiumData[] = []
    payPremiumPolicyForm.base.setValue(`payPreOptionalPolicyList.${0}.selectedRow`, false)
    payPreOptionalPolicyList.remove()
    payPremiumPolicyForm.base.setValue('selectedAllRow', false)
    payPremiumPolicyForm.base.setValue(`payPreOptionalPolicyList`, [])
    resetPagination(dataArr)
    for (let i = 0, k = 0; i < dataArr.length; i++, k++) {
      const payPreItem = {
        policyNumber: dataArr[i].policyNum,
        paidToDate: dataArr[i].paidToDate,
        installmentPremium: dataArr[i].installmentPremium.toString(),
        selectedRow: false,
        poName: dataArr[i].poName,
        totalPremium: dataArr[i].totalPremium
      }
      k < 10 && k < dataArr.length && filterList.push(dataArr[k])
      payPreOptionalPolicyList.append(payPreItem)
      payPremiumPolicyForm.base.setValue(`payPreOptionalPolicyList.${i}`, payPreItem)
    }
    setPremiumPolicy(filterList)
  }

  const resetPagination = (dataArr: PayoutPopup.PayPremiumData[]) => {
    setPageNum(0)
    setPageSize(10)
    setTotalItem(dataArr.length)
  }

  const onPressSearch = () => {
    const searchedPolicy = payPremiumPolicyForm.base.getValues('searchedPolicy')
    if (!!searchedPolicy && searchedPolicy.length === 8) {
      const filterList = details.filter((x) => x.policyNum === searchedPolicy)
      resetPayPremPolicyArr(filterList)
    } else if (!searchedPolicy) {
      resetPayPremPolicyArr(details)
    }
  }

  const onSelectItemRowEvent = (selected: boolean, index: number) => {
    const selectedRow = payPremiumPolicyForm.base.getValues(`payPreOptionalPolicyList.${index}`)
    if (selected) {
      payPremiumChosenPolicyArr.append(selectedRow)
    } else {
      const deletedIndex = payPremiumChosenPolicyArr.fields.findIndex(x => x.policyNumber === selectedRow.policyNumber)
      payPremiumChosenPolicyArr.remove(deletedIndex)

    }
  }

  const onSelectAllRowEvent = (selected: boolean) => {
    for (let i = 0; i < payPremiumPolicyForm.base.getValues('payPreOptionalPolicyList').length; i++) {
      payPreOptionalPolicyList.update(i, {
        ...payPreOptionalPolicyList.fields[i],
        selectedRow: selected
      })
      payPremiumPolicyForm.base.setValue(`payPreOptionalPolicyList.${i}.selectedRow`, selected)
    }
  }

  const getHandlingPage = (pageNu: number, pageSi: number) => {
    let filterList: PayoutPopup.PayPremiumData[] = []
    setPageNum(pageNu)
    setPageSize(pageSi)
    for (let i = 0 + (pageNu * pageSi); i < (pageNu + 1) * pageSi && i < details.length; i++) {
      filterList.push(details[i])
    }
    setPremiumPolicy(filterList)
  }

  const onDeletedItemEvent = (index: number, event: any) => {
    setDeletedIndex(index)
    setAnchorEl(event.currentTarget)
    setIsOpenDeletedPopup(true)
  }

  const onConfirmDeletedItem = (index: number) => {
    const deteledItem = payPremiumPolicyForm.base.getValues(`payPremiumChosenPolicyArr.${index}`)
    const deletedIndex = payPreOptionalPolicyList.fields.findIndex(x => x.policyNumber === deteledItem.policyNumber)
    payPreOptionalPolicyList.update(deletedIndex, {
      ...payPreOptionalPolicyList.fields[deletedIndex],
      selectedRow: false
    })
    payPremiumChosenPolicyArr.remove(index)
    onHandleClosedPopup()
  }

  const onHandleClosedPopup = () => {
    setDeletedIndex(-1)
    setIsOpenDeletedPopup(false)
    setAnchorEl(null)
  }

  return (
    <ModalComponent
      ref={modalRef}
      title={t('submission:PayPremium')}
      titleStyle={styles.modalTitle}
      contentStyle={styles.modalContent}
      modalWidth={Math.min(width * 0.9, 1024)}
      modalHeight={Math.min(height * 0.9, 768)}
      visible={visible}
      onClose={onPressClose}
      actions={modalActions}
    >
      <ScrollView style={styles.container} horizontal={false} showsVerticalScrollIndicator={true}>

        {isSeaBankPolicy ? (
          <View style={styles.seaContainer}>
            <Title title={t('submission:Searched policy')} wrapperStyle={styles.bigLabel} />
            <View style={styles.seaContent}>
              <Controller
                control={payPremiumPolicyForm.base.control}
                name="searchedPolicy"
                render={({ field: { value, onChange, onBlur }, fieldState }) => (
                  <InputTable
                    maxLength={8}
                    value={value ?? ''}
                    onChange={onChange}
                    onBlur={onBlur}
                    inputType={'number'}
                    placeholder={t('Policy')}
                  ></InputTable>
                )}
              />
              <TouchableOpacity onPress={() => onPressSearch()}>
                <View style={styles.inputContent}>
                  <Text style={{ textAlign: 'center', color: '#FFF', fontWeight: 'bold' }}>Search</Text>
                </View>
              </TouchableOpacity>
            </View>
            {payPremiumPolicyForm.base.formState.errors.searchedPolicy && (
              <Text style={styles.searchMessage}>{payPremiumPolicyForm.base.formState.errors.searchedPolicy?.message}</Text>
            )}
          </View>
        ) : (
          <Title title={t('submission:PayPremium')} wrapperStyle={styles.bigLabel} />
        )}

        {/* <HeaderTable
          selectable={true}
          rowsSelected={rowsSelected}
          unit={'VND'}
          headerContainerStyle={{ backgroundColor: '#FAFAFA' }}
          rowStyle={{ backgroundColor: '#FAFAFA' }}
          selectBoxContainerStyle={{ justifyContent: 'center', alignItems: 'center' }}
          columns={payPremiumTableColumns}
          dataSource={details.map((item) => ({
            policyNum: item.policyNum,
            paidToDate: item.paidToDate,
            installmentPremium: formatNumberWithComma(item.installmentPremium)
          }))}
          isSeaBankPolicy={isSeaBankPolicy}
          onAllRowSelected={onAllTableRowSelected}
          onAllRowUnSelected={onAllTableRowUnSelected}
          onRowSelected={onTableRowSelected}
          onRowUnSelected={onTableRowUnSelected}
        /> */}
        <View style={styles.policyListContainer}>
          <View style={styles.policyListHeader}>
            <Text style={styles.policyHeaderText}>{t('requestInfo:Currency')}: VND</Text>
          </View>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {/* <TableCell>
                    <Controller
                      control={payPremiumPolicyForm.base.control}
                      name={`selectedAllRow`}
                      render={({ field: { value, onChange, onBlur } }) => {
                        return (
                          <Checkbox
                            style={styles.checkboxItem}
                            value={value}
                            onChange={(val) => {
                              onChange(val)
                              onSelectAllRowEvent(val)
                            }}
                            onBlur={onBlur}
                            enable={true}
                          />
                        )
                      }}
                    />
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>{t('submission:PolicyNumber')}</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>{t('TaskManagement:PaidToDate')}</TableCell>
                  <TableCell style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 19 }}>
                    {t('Payout:InstallmentPremium')}
                  </TableCell> */}
                  {seabankPayPreColumns.length > 0 && seabankPayPreColumns.map((colItem, index) => {
                    return !!colItem.render ? <TableCell>{colItem.render({ index, isSelected: false })}</TableCell>
                      : <>
                        <TableCell style={colItem.styles as React.CSSProperties}>{colItem.title}</TableCell>
                      </>
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {premiumPolicy.map((fieldItem, index) => {
                  return (
                    <TableRow key={index + (pageNum * pageSize)}>
                      <TableCell>
                        <Controller
                          control={payPremiumPolicyForm.base.control}
                          name={`payPreOptionalPolicyList.${index + (pageNum * pageSize)}.selectedRow`}
                          render={({ field: { value, onChange, onBlur } }) => {
                            return (
                              <Checkbox
                                style={styles.checkboxItem}
                                value={value}
                                onChange={(val) => {
                                  onChange(val)
                                  onSelectItemRowEvent(val, index + (pageNum * pageSize))
                                }}
                                onBlur={onBlur}
                                enable={true}
                              />
                            )
                          }}
                        />
                      </TableCell>
                      <TableCell>{fieldItem.policyNum}</TableCell>
                      <TableCell>{fieldItem.paidToDate}</TableCell>
                      <TableCell style={{ textAlign: 'right', paddingRight: 19 }}>
                        {formatNumberWithComma(fieldItem.installmentPremium)}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10]}
            component={View}
            count={totalItem}
            rowsPerPage={pageSize}
            page={pageNum}
            onPageChange={(e, page) => {
              setPageNum(page)
              getHandlingPage(page, pageSize)
            }}
            onRowsPerPageChange={(e) => {
              const pageSiz = Number(e.target.value)
              setPageSize(pageSiz)
              if (totalItem <= pageSiz * pageNum) {
                setPageNum(0)
                getHandlingPage(0, pageSiz)
              } else {
                getHandlingPage(pageNum, pageSiz)
              }
            }}
            labelRowsPerPage={t('common:PaginationSize')}
            labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
          />
        </View>
        {isSeaBankPolicy && (
          <View>
            <Title title={t('submission:PayPremium')} wrapperStyle={styles.bigLabel} />
            <View style={[styles.policyListContainer, { marginTop: 10 }]}>
              <View style={styles.policyListHeader}>
                <Text style={styles.policyHeaderText}>{t('requestInfo:Currency')}: VND</Text>
              </View>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontWeight: 'bold' }}>{t('submission:PolicyNumber')}</TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>{t('TaskManagement:PaidToDate')}</TableCell>
                      <TableCell style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 19 }}>
                        {t('Payout:InstallmentPremium')}
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {payPremiumChosenPolicyArr.fields && payPremiumChosenPolicyArr.fields.length > 0 && payPremiumChosenPolicyArr.fields.map((fieldItem, index) => {
                      return (
                        <TableRow key={index + '_ChosenPolicy'}>
                          <TableCell>{fieldItem.policyNumber}</TableCell>
                          <TableCell>{fieldItem.paidToDate}</TableCell>
                          <TableCell style={{ textAlign: 'right', paddingRight: 19 }}>
                            {formatNumberWithComma(fieldItem.installmentPremium)}
                          </TableCell>
                          <TableCell style={{ cursor: 'pointer' }} onClick={(event) => onDeletedItemEvent(index, event)}>
                            <assets.DeleteBin />
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </View>
          </View>
        )}



        <Title title={t('submission:POLICY_OF_OTHER_CLIENT')} wrapperStyle={styles.bigLabel} />
        {POCFormList.fields.map(renderPOCForm)}
        <IconButton
          style={styles.btnAdd}
          title={t('common:Add')}
          icon={() => <assets.PlusCircleIcon />}
          onPress={() => POCFormList.append({ policyNumber: '' }, { shouldFocus: true })}
        />
      </ScrollView>
      <Popover
        id={IDPopover}
        open={isOpenDeletedPopup}
        anchorEl={anchorEl}
        onClose={onHandleClosedPopup}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left'
        }}
        style={{ zIndex: 10000 }}
      >
        <View
          style={{
            flex: 1,
            padding: 12,
            width: 250
          }}
        >
          <View style={{ flexDirection: 'column' }}>
            <View style={{ flexDirection: 'row', marginBottom: 10 }}>
              <View style={{ width: '20%', justifyContent: 'center', alignItems: 'center' }}>
                <Icon name="exclamationcircle" size={18} color="#FBAD13" />
              </View>
              <View style={{ width: '80%' }}>
                {/* <Text>This information will be filled in your current form.</Text>
                <Text>Do you confirm?</Text> */}
                <Text>{t('Do you want to delete this policy')}</Text>
              </View>
            </View>
            <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
              <TouchableOpacity
                onPress={onHandleClosedPopup}
                style={[styles.button, { backgroundColor: 'white', borderWidth: 1, borderColor: 'gray' }]}
              >
                <Text>{t('common:No')}</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => onConfirmDeletedItem(deletedIndex)} style={[styles.button, { backgroundColor: 'red' }]}>
                <Text style={{ color: 'white' }}>{t('common:Yes')}</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Popover>
    </ModalComponent>
  )
}

const styles = StyleSheet.create({
  modalTitle: {
    textAlign: 'center'
  },

  modalContent: {
    backgroundColor: '#EEEEEE'
  },

  btnCancel: {
    height: 39,
    marginEnd: 15
  },

  btnConfirm: {
    height: 39
  },

  container: {
    paddingHorizontal: 30
  },

  bigLabel: {
    textTransform: 'uppercase',
    marginTop: 20,
    marginBottom: 10
  },

  ortherPolicyContainer: {
    backgroundColor: '#FAFAFA',
    borderRadius: 8,
    overflow: 'hidden',
    marginVertical: 5,
    paddingHorizontal: 30,
    paddingVertical: 24
  },

  searchPolicyContainer: {
    width: 275,
    flexDirection: 'row',
    alignItems: 'flex-end'
  },

  btnSearch: {
    width: 32,
    height: 32,
    justifyContent: 'center',
    alignItems: 'center'
  },

  btnAdd: {
    marginStart: 20,
    marginTop: 16
  },

  searchContainer: {
    flexDirection: 'row',
    borderWidth: 1,
    borderRadius: 20,
    borderColor: '#D3DCE6',
    backgroundColor: '#F9F9F9'
  },

  inputContainer: {
    flex: 1,
    marginVertical: 4,
    marginStart: 15,
    marginEnd: 10
  },
  btnSearchContainer: {
    backgroundColor: '#ED1B2E',
    height: '100%'
  },
  input: {
    // flex: 1,
    minHeight: 30,
    fontWeight: '400',
    color: '#70777E'
  },
  policyListContainer: {
    borderWidth: 1,
    borderColor: '#D3DCE6',
    borderRadius: 8,
    backgroundColor: '#fff'
  },
  policyListHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingRight: 20,
    paddingTop: 20
  },
  policyHeaderText: {
    color: '#70777E',
    fontSize: 15,
    fontStyle: 'italic'
  },
  searchMessage: {
    color: '#ED1B2C',
    fontSize: 11.25
  },
  checkboxItem: {
    marginEnd: 16,
    marginLeft: 11
  },
  seaContainer: { marginBottom: 10 },
  seaContent: { display: 'flex', flexDirection: 'row' },
  inputContent: {
    minWidth: 121,
    height: 35,
    paddingHorizontal: 30,
    borderWidth: 1,
    borderRadius: 100,
    justifyContent: 'center',
    borderColor: '#ED1B2E',
    backgroundColor: '#ED1B2E',
    marginLeft: 10
  },
  button: {
    borderRadius: 10,
    paddingVertical: 2,
    paddingHorizontal: 15,
    margin: 5
  }
})
