import { SelectOption } from '@pulseops/common'

export const COLORS = {
  SNOW: '#fafafa',
  HOWKES_BLUE: '#d3dce6',
  ALIZARIN: '#ED1B2E',
  WHITE: '#ffff',
  ROLLING_STONE: '#70777e',
  BLACK: '#000000',
  MYSTIC: '#E5EAEF',
  BISCAY: '#1B365D'
}

export interface TextCustomI {
  textColor?: string
  alignText?: string
  fontSize?: string
  fontWeight?: string
  fontStyle?: 'italic' | 'normal' | 'oblique'
}

export enum TypeFieldEnum {
  TEXTBOX = 'TEXTBOX',
  DROPDOWN_LIST = 'DROPDOWN_LIST',
  DATE_PICKER = 'DATE_PICKER',
  RADIO = 'RADIO'
}

enum TypeGroupNameEnum {
  INFO_CHANGE_CLIENT = 'info_change_client',
  CHANGE_CARD = 'change_card',
  CHANGE_FULL_NAME = 'change_full_name',
  CHANGE_NATIONALITY = 'change_nationality'
}

export type typeField = `${TypeFieldEnum}`

export type typeGroupName = `${TypeGroupNameEnum}_group`
export type typeNameField =
  | 'selectClient'
  | 'idType'
  | 'idNumber'
  | 'issuedBy'
  | 'issuedDate'
  | 'surName'
  | 'givenName'
  | 'nationality'
  | 'usTaxDeclarationFrom'
  | 'isCheckChangeCardGroup'
  | 'isCheckChangeFullNameGroup'
  | 'isCheckChangeNationalityGroup'
  // | 'taxResidencyCountry'
  | 'foreignAddress'
  | 'countryOfForeignAddress'
  | 'nationality2'

export type HiddenType = 'CHECK_BOX' | 'RADIO' | 'NONE'

export type FieldsType<TN> = {
  type: typeField
  formName: TN
  required: boolean
  label: string
  options?: SelectOption[]
  placeholder: string
  popupIcon?: string
  editable: boolean
  rules?: object
  maxLength?: number
}

export type GroupsViewType<GN, TN> = {
  groupName: GN
  isVisible: boolean
  fields: FieldsType<TN>[]
  typeHidden?: HiddenType
  labelGroup?: string
  isBorder?: boolean
}

export enum FILE_ERROR {
  LARGE_FILE_SIZE = 'LARGE_FILE_SIZE',
  MISSING_FILE = 'MISSING_FILE'
}

export enum STATUS {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR'
}

export type FileErrorType = `${FILE_ERROR}`
export type StatusType = `${STATUS}`

export type CallBackValidateUploadFileType = {
  typeError?: FileErrorType
  message?: string
  status: StatusType
}

export enum HiddenEnum {
  CHECK_BOX = 'CHECK_BOX',
  RADIO = 'RADIO',
  NONE = 'NONE'
}
export type typeHiddenEnum = `${HiddenEnum}`

export const groupsViewsConst: GroupsViewType<typeGroupName, typeNameField>[] = [
  {
    groupName: 'info_change_client_group',
    labelGroup: '',
    isVisible: true,
    typeHidden: HiddenEnum['NONE'],
    fields: [
      {
        formName: 'selectClient',
        type: TypeFieldEnum['DROPDOWN_LIST'],
        placeholder: '-',
        label: '',
        required: true,
        editable: true,
        options: [],
        rules: {}
      }
    ]
  },
  {
    groupName: 'change_card_group',
    isVisible: true,
    typeHidden: HiddenEnum['CHECK_BOX'],
    labelGroup: '',
    isBorder: true,
    fields: [
      {
        type: TypeFieldEnum['DROPDOWN_LIST'],
        formName: 'idType',
        label: '',
        required: true,
        editable: true,
        placeholder: '-',
        rules: {},
        options: []
      },
      {
        type: TypeFieldEnum['TEXTBOX'],
        formName: 'idNumber',
        label: '',
        required: true,
        editable: true,
        placeholder: '-',
        rules: {}
      },
      {
        type: TypeFieldEnum['DATE_PICKER'],
        formName: 'issuedDate',
        label: '',
        required: true,
        editable: true,
        placeholder: '-',
        rules: {}
      },
      {
        type: TypeFieldEnum['DROPDOWN_LIST'],
        formName: 'issuedBy',
        label: '',
        required: true,
        editable: true,
        placeholder: '-',
        rules: {}
      }
    ]
  },
  {
    groupName: 'change_full_name_group',
    isVisible: true,
    typeHidden: HiddenEnum['CHECK_BOX'],
    labelGroup: '',
    isBorder: true,
    fields: [
      {
        type: TypeFieldEnum['TEXTBOX'],
        formName: 'surName',
        label: '',
        required: true,
        editable: true,
        placeholder: '-',
        options: [],
        rules: {}
      },
      {
        type: TypeFieldEnum['TEXTBOX'],
        formName: 'givenName',
        label: '',
        required: true,
        editable: true,
        placeholder: '-',
        options: [],
        rules: {}
      }
    ]
  },
  {
    groupName: 'change_nationality_group',
    isVisible: true,
    typeHidden: HiddenEnum['CHECK_BOX'],
    labelGroup: '',
    isBorder: true,
    fields: [
      {
        type: TypeFieldEnum['DROPDOWN_LIST'],
        formName: 'nationality',
        label: '',
        required: true,
        editable: true,
        placeholder: '-',
        options: [],
        rules: {},
        maxLength : 100
      },
      // {
      //   type: TypeFieldEnum['DROPDOWN_LIST'],
      //   formName: 'taxResidencyCountry',
      //   label: '',
      //   required: false,
      //   editable: true,
      //   placeholder: '-',
      //   options: [],
      //   rules: {},
      //   maxLength : 100
      // },
      {
        type: TypeFieldEnum['DROPDOWN_LIST'],
        formName: 'nationality2',
        label: '',
        required: false,
        editable: true,
        placeholder: '-',
        options: [],
        rules: {},
        maxLength : 100
      },
      {
        type: TypeFieldEnum['DROPDOWN_LIST'],
        formName: 'countryOfForeignAddress',
        label: '',
        required: false,
        editable: true,
        placeholder: '-',
        options: [],
        rules: {},
        maxLength : 100
      },
      {
        type: TypeFieldEnum['TEXTBOX'],
        formName: 'foreignAddress',
        label: '',
        required: false,
        editable: true,
        placeholder: '-',
        options: [],
        rules: {},
        maxLength : 30
      },
      {
        type: TypeFieldEnum['RADIO'],
        formName: 'usTaxDeclarationFrom',
        label: '',
        required: true,
        editable: true,
        placeholder: '-',
        options: [],
        rules: {}
      }
    ]
  }
]

export type valuesFormType = {
  key: typeNameField
  value: Date | null | SelectOption | string
}

export enum BenIDCard {
  NATIONAL_ID = 'ID',
  PASSPORT = 'PP',
  BIRTH_CERTIFICATE = 'BC'
}

export const BenIDCardOption = (mapper: (val: string) => string) => [
  {
    value: BenIDCard.NATIONAL_ID,
    label: mapper(BenIDCard.NATIONAL_ID)
  },
  {
    value: BenIDCard.PASSPORT,
    label: mapper(BenIDCard.PASSPORT)
  },
  {
    value: BenIDCard.BIRTH_CERTIFICATE,
    label: mapper(BenIDCard.BIRTH_CERTIFICATE)
  }
]

export const issuedByAdult = [
  {
    value: 'Cục Trưởng Cục Cảnh Sát Quản lý Hành Chính về Trật tự Xã hội ',
    label: 'Cục Trưởng Cục Cảnh Sát Quản lý Hành Chính về Trật tự Xã hội '
  },
  {
    value: 'Cục Trưởng Cục Cảnh Sát DKQL CƯ TRÚ VÀ DLQG VỀ DÂN CƯ',
    label: 'Cục Trưởng Cục Cảnh Sát DKQL CƯ TRÚ VÀ DLQG VỀ DÂN CƯ'
  },
  {
    value: 'Cục Quản Lý Xuất Nhập cảnh',
    label: 'Cục Quản Lý Xuất Nhập cảnh'
  },
  {
    label: 'BỘ CÔNG AN',
    value: 'BỘ CÔNG AN'
  }
]
