import { ZIO } from '@mxt/zio'
import {
  AppContext,
  assets,
  ConfirmationLetterService,
  ConfirmOfPremiumService,
  ErrorHandling,
  GeneralService,
  PanelProp,
  ProductEnum,
  PulseUtils,
  //PolicyInfoHeader,
  SearchPanel,
  TransactionType,
  ReinstatementService,
  BillingChangeReversalService,
  PruKid369Service,
  ChangeContactInfoService,
  CancelFromInceptionService,
  BillingChangeService,
  BonusSurrenderService,
  PolicyCertificateService,
  PremiumRedirectionService,
  SpecialBonusService,
  ChangeClientInfoService,
  CancelRiderService,
  SubmissionService,
  LoanService,
  MaturityAdvanceService,
  PulseOpsApi,
  LoanStatementsService,
  MaturityPaymentService,
  TopUpService,
  BeneficiaryDesignationService,
  ScheduledPaymentStatementService,
  T18,
  ExcellentStudentAwardService,
  PruCashPaymentService,
  ExcessPremiumService,
  ProductOptionSwitchingService,
  PartialWithdrawalService,
  RedatingService,
  PulseOpsFormat,
  FullSurrenderService,
  FullSurrenderReversalService,
  RiderReinstatementService,
  Permission,
  RBAC,
  PanelItem,
  PanelGroupProps,
  PolicyServiceGroup
} from '@pulseops/common'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import { pipe } from 'fp-ts/lib/function'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { PSSubmissionStackParamList } from './PSSubmissionStackParamList'
import { PolicyInfoHeader } from './transactionScreen/screens'

type Props = StackScreenProps<PSSubmissionStackParamList, 'PSSubmissionListScreen'>

const policyServices: {
  group: PolicyServiceGroup
  transaction: TransactionType
}[] = [
  /* Add Lack Of Documents */
  { group: PolicyServiceGroup.AddLackOfDocuments, transaction: TransactionType.ADD_LACK_OF_DOCUMENT },
  { group: PolicyServiceGroup.AddLackOfDocuments, transaction: TransactionType.COMMON_REQUEST_FORM },

  /* Cash advancement */
  { group: PolicyServiceGroup.CashAdvance, transaction: TransactionType.LOAN },
  { group: PolicyServiceGroup.CashAdvance, transaction: TransactionType.MATURITY_ADVANCE },

  /* Reinstatement */
  { group: PolicyServiceGroup.Reinstatement, transaction: TransactionType.REINSTATEMENT },
  { group: PolicyServiceGroup.Reinstatement, transaction: TransactionType.FULL_SURRENDER_REVERSAL },
  { group: PolicyServiceGroup.Reinstatement, transaction: TransactionType.REDATING },
  { group: PolicyServiceGroup.Reinstatement, transaction: TransactionType.RIDER_REINSTATEMENT },
  /* Payout */
  { group: PolicyServiceGroup.Payout, transaction: TransactionType.EXCESS_PREMIUM },
  { group: PolicyServiceGroup.Payout, transaction: TransactionType.CANCEL_FROM_INCEPTION },
  { group: PolicyServiceGroup.Payout, transaction: TransactionType.PRUCASH_PAYMENT },
  { group: PolicyServiceGroup.Payout, transaction: TransactionType.MATURITY },
  { group: PolicyServiceGroup.Payout, transaction: TransactionType.SPECIAL_BONUS },
  { group: PolicyServiceGroup.Payout, transaction: TransactionType.EXCELLENT_STUDENT_AWARD },
  { group: PolicyServiceGroup.Payout, transaction: TransactionType.PRUKID369 },
  { group: PolicyServiceGroup.Payout, transaction: TransactionType.BONUS_SURRENDER },
  { group: PolicyServiceGroup.Payout, transaction: TransactionType.FULL_SURRENDER },
  { group: PolicyServiceGroup.Payout, transaction: TransactionType.PARTIAL_SURRENDER },
  { group: PolicyServiceGroup.Payout, transaction: TransactionType.PARTIAL_WITHDRAWAL },

  /* Change Policy Info */
  { group: PolicyServiceGroup.ChangePolicyInfo, transaction: TransactionType.BENEFICIARY_DESIGNATION },
  { group: PolicyServiceGroup.ChangePolicyInfo, transaction: TransactionType.BILLING_CHANGE },
  { group: PolicyServiceGroup.ChangePolicyInfo, transaction: TransactionType.BILLING_FREQUENCY_REVERSAL },
  { group: PolicyServiceGroup.ChangePolicyInfo, transaction: TransactionType.TOP_UP },
  { group: PolicyServiceGroup.ChangePolicyInfo, transaction: TransactionType.GO_ABROAD },
  { group: PolicyServiceGroup.ChangePolicyInfo, transaction: TransactionType.CANCEL_RIDER },
  { group: PolicyServiceGroup.ChangePolicyInfo, transaction: TransactionType.FUND_SWITCHING },
  { group: PolicyServiceGroup.ChangePolicyInfo, transaction: TransactionType.PREMIUM_REDIRECTION },
  { group: PolicyServiceGroup.ChangePolicyInfo, transaction: TransactionType.STOP_APL },
  { group: PolicyServiceGroup.ChangePolicyInfo, transaction: TransactionType.ASSIGNMENT },
  { group: PolicyServiceGroup.ChangePolicyInfo, transaction: TransactionType.INHERITANCE },
  { group: PolicyServiceGroup.ChangePolicyInfo, transaction: TransactionType.HEALTH_DECLARATION },
  { group: PolicyServiceGroup.ChangePolicyInfo, transaction: TransactionType.PAID_UP },
  { group: PolicyServiceGroup.ChangePolicyInfo, transaction: TransactionType.PRODUCT_OPTION_SWITCHING },
  { group: PolicyServiceGroup.ChangePolicyInfo, transaction: TransactionType.CHANGE_PREMIUM },
  { group: PolicyServiceGroup.ChangePolicyInfo, transaction: TransactionType.CHANGE_SUM_ASSURED },
  { group: PolicyServiceGroup.ChangePolicyInfo, transaction: TransactionType.RIDER_ALTERATION },
  { group: PolicyServiceGroup.ChangePolicyInfo, transaction: TransactionType.MAJOR_COMBINED_CHANGE },

  /* Change General Info */
  { group: PolicyServiceGroup.ChangeGeneralInfo, transaction: TransactionType.CHANGE_CONTACT_INFO },
  // { group: PolicyServiceGroup.ChangeGeneralInfo, transaction: TransactionType.CHANGE_CLIENT_INFO },
  { group: PolicyServiceGroup.ChangeGeneralInfo, transaction: TransactionType.SIGNATURE_REGISTRATION },
  { group: PolicyServiceGroup.ChangeGeneralInfo, transaction: TransactionType.E_SERVICE_REGISTRATION },
  { group: PolicyServiceGroup.ChangeGeneralInfo, transaction: TransactionType.FATCA_DECLARATION },
  { group: PolicyServiceGroup.ChangeGeneralInfo, transaction: TransactionType.CHANGE_NATIONAL_ID_CARD },
  { group: PolicyServiceGroup.ChangeGeneralInfo, transaction: TransactionType.CHANGE_OCCUPATION_PERSONAL },
  { group: PolicyServiceGroup.ChangeGeneralInfo, transaction: TransactionType.CHANGE_DOB_GENDER },
  { group: PolicyServiceGroup.ChangeGeneralInfo, transaction: TransactionType.CHANGE_OTHER_INFORMATION_PERSONAL },

  /* Letter Request */
  { group: PolicyServiceGroup.LetterRequest, transaction: TransactionType.CONFIRMATION_OF_PREMIUM_RECEIPT },
  { group: PolicyServiceGroup.LetterRequest, transaction: TransactionType.LOAN_STATEMENTS },
  { group: PolicyServiceGroup.LetterRequest, transaction: TransactionType.POLICY_CERTIFICATE },
  {
    group: PolicyServiceGroup.LetterRequest,
    transaction: TransactionType.CONFIRMATION_LETTER_OF_POLICY_ACCOUNT_AND_SURRENDER_VALUE
  },
  { group: PolicyServiceGroup.LetterRequest, transaction: TransactionType.SCHEDULED_PAYMENT_STATEMENT },
  { group: PolicyServiceGroup.TransferJournal, transaction: TransactionType.JOURNAL_PAYOUT },
  { group: PolicyServiceGroup.TransferJournal, transaction: TransactionType.JOURNAL_ALTERATION }
]

export const PSSubmissionListScreen = (props: Props) => {
  const { t, i18n } = useTranslation()
  const { changeBreadcrumb, showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const { navigate } = useNavigation()
  const isFocused = useIsFocused()
  const forceUpdate = React.useState()[1]
  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigate('HomeScreen')
          }
        },
        {
          title: t('Submission:EForm'),
          navigate: () => navigate('StaffSubmissionStack', { screen: 'StaffSubmissionScreen' })
        },
        {
          title: t('common:PolicyServices'),
          navigate: () => navigate('PSSubmissionStack', { screen: 'PSSubmissionListScreen' })
        }
      ])
    }
    forceUpdate(undefined)
  }, [isFocused])

  const getPolicyOwnerHeaderName = () => {
    return !!props.route.params?.primaryId && !!props.route.params?.policyOwner
      ? props.route.params?.primaryId.trim() + ' - ' + props.route.params?.policyOwner.trim()
      : '-'
  }

  const checkRoles = (role: Permission, permissions: string[]): boolean => {
    // console.log(permissions, permissions?.includes(role), 'role')
    permissions.push(Permission.ViewFuncListAlterJournalFunctionListPS)
    return permissions.includes(role)
  }

  const headers = [
    {
      label: t('common:PolicyNumber'),
      value: props.route.params?.policyNum || '-'
      // icon: <assets.PolicyNumber />
    },
    {
      label: t('common:PolicyOwner'),
      // value: props.route.params.policyOwner || '-',
      value: getPolicyOwnerHeaderName()
      // icon: <assets.MainBoyAvatar />
    },
    {
      label: t('common:Office'),
      value: `${props.route.params?.officeCode ?? ''} - ${props.route.params?.officeName ?? ''}`
      // icon: <assets.Building />
    }
  ]

  const data: PanelProp | null = pipe(
    ZIO.zipPar(GeneralService.getTransactionList(), RBAC.permissions),
    ZIO.map(([transactions, permissions]) => {
      const filterTran = transactions.filter((trans) => trans.typeWf !== TransactionType.CHANGE_CLIENT_INFO)
      // const transatransactionList = transactions.filter((transaction) =>
      //   [
      //     TransactionType.MATURITY,
      //     TransactionType.E_SERVICE_REGISTRATION,
      //     TransactionType.POLICY_CERTIFICATE,
      //     TransactionType.LOAN_STATEMENTS,
      //     TransactionType.CONFIRMATION_OF_PREMIUM_RECEIPT,
      //     TransactionType.SIGNATURE_REGISTRATION,
      //     TransactionType.SCHEDULED_PAYMENT_STATEMENT,
      //     TransactionType.CONFIRMATION_LETTER_OF_POLICY_ACCOUNT_AND_SURRENDER_VALUE,
      //     TransactionType.CHANGE_CONTACT_INFO,
      //     TransactionType.EXCESS_PREMIUM,
      //     TransactionType.BENEFICIARY_DESIGNATION,
      //     TransactionType.BILLING_FREQUENCY_REVERSAL,
      //     TransactionType.FATCA_DECLARATION,
      //     TransactionType.HEALTH_DECLARATION,
      //     TransactionType.PRUCASH_PAYMENT,
      //     TransactionType.BILLING_CHANGE,
      //     TransactionType.CANCEL_RIDER,
      //     TransactionType.REINSTATEMENT,
      //     TransactionType.STOP_APL,
      //     TransactionType.LOAN,
      //     TransactionType.CHANGE_CLIENT_INFO,
      //     TransactionType.TOP_UP,
      //     TransactionType.GO_ABROAD,
      //     TransactionType.INHERITANCE,
      //     TransactionType.MATURITY_ADVANCE,
      //     TransactionType.PREMIUM_REDIRECTION,
      //     TransactionType.PRUKID369,
      //     TransactionType.SPECIAL_BONUS,
      //     TransactionType.ASSIGNMENT,
      //     TransactionType.FUND_SWITCHING,
      //     TransactionType.EXCELLENT_STUDENT_AWARD,
      //     TransactionType.COMMON_REQUEST_FORM,
      //     TransactionType.BONUS_SURRENDER,
      //     TransactionType.ADD_LACK_OF_DOCUMENT,
      //     TransactionType.PAID_UP,
      //     TransactionType.CANCEL_FROM_INCEPTION,
      //     TransactionType.PARTIAL_SURRENDER,
      //     TransactionType.REDATING,
      //     TransactionType.PARTIAL_WITHDRAWAL,
      //     TransactionType.FULL_SURRENDER,
      //     TransactionType.PRODUCT_OPTION_SWITCHING,
      //     TransactionType.RIDER_REINSTATEMENT,
      //     TransactionType.FULL_SURRENDER_REVERSAL,
      //     TransactionType.CHANGE_PREMIUM,
      //     TransactionType.JOURNAL_ALTERATION,
      //     TransactionType.JOURNAL_PAYOUT
      //   ].includes(transaction.typeWf as TransactionType)
      // )
      return { transactions: filterTran, permissions }
    }),
    ZIO.map((data) => {
      const transactions = data.transactions.map((transaction) => ({
        id: transaction.typeWf,
        name: i18n.language === 'en' ? transaction.nameEn : transaction.nameVi,
        navigator: async () => {
          const policyNum = props.route.params?.policyNum
          if (await validateTransactionTypeEntry(transaction.typeWf, policyNum)) {
            props.navigation.navigate('PSSubmissionScreen', {
              transactionName: transaction.typeWf,
              policyNum: policyNum || '-',
              officeName: props.route.params?.officeName || '-',
              officeCode: props.route.params?.officeCode || '-',
              policyOwner: props.route.params?.policyOwner || '-',
              POClientID: props.route.params?.primaryId || ''
            })
          }
        },
        group: policyServices.find((x) => x.transaction === transaction.typeWf)?.group
      }))

      return { transactions, permissions: data.permissions }
    }),
    ZIO.map((data): PanelProp => {
      let itemsSearchList: Array<PanelItem> = []
      let itemsGroupList: Array<PanelGroupProps> = []
      let checkAddedGroupJournal = false
      const groupPSTrans = [
        {
          id: PolicyServiceGroup.CashAdvance,
          name: t('TransactionGroup:CashAdvance'),
          icon: () => <assets.CashAdvance />,
          children: data.transactions.filter((x) => x.group === PolicyServiceGroup.CashAdvance)
        },
        {
          id: PolicyServiceGroup.AddLackOfDocuments,
          name: t('TransactionGroup:AddLackOfDocuments'),
          icon: () => <assets.AddLackOfDocuments />,
          children: data.transactions.filter((x) => x.group === PolicyServiceGroup.AddLackOfDocuments)
        },
        {
          id: PolicyServiceGroup.Reinstatement,
          name: t('TransactionGroup:Reinstatement'),
          icon: () => <assets.Reinstatement />,
          children: data.transactions.filter((x) => x.group === PolicyServiceGroup.Reinstatement)
        },
        {
          id: PolicyServiceGroup.Payout,
          name: t('TransactionGroup:Payout'),
          icon: () => <assets.Payout />,
          children: data.transactions.filter((x) => x.group === PolicyServiceGroup.Payout)
        },
        {
          id: PolicyServiceGroup.ChangePolicyInfo,
          name: t('TransactionGroup:ChangePolicyInfo'),
          icon: () => <assets.ChangePolicyInfo />,
          children: data.transactions.filter((x) => x.group === PolicyServiceGroup.ChangePolicyInfo)
        },
        {
          id: PolicyServiceGroup.ChangeGeneralInfo,
          name: t('TransactionGroup:ChangeGeneralInfo'),
          icon: () => <assets.ChangeGeneralInfo />,
          children: data.transactions.filter((x) => x.group === PolicyServiceGroup.ChangeGeneralInfo)
        },
        {
          id: PolicyServiceGroup.LetterRequest,
          name: t('TransactionGroup:LetterRequest'),
          icon: () => <assets.LetterRequest />,
          children: data.transactions.filter((x) => x.group === PolicyServiceGroup.LetterRequest)
        }
      ]

      const groupForJournal = {
        id: PolicyServiceGroup.TransferJournal,
        name: t('TransactionGroup:TransferJournal'),
        icon: () => <assets.CashAdvance />,
        children: data.transactions.filter((x) => x.group === PolicyServiceGroup.TransferJournal)
      }

      const groupForJournalAlteration = {
        id: PolicyServiceGroup.TransferJournal,
        name: t('TransactionGroup:TransferJournal'),
        icon: () => <assets.CashAdvance />,
        children: data.transactions.filter(
          (x) => x.group === PolicyServiceGroup.TransferJournal && x.id === TransactionType.JOURNAL_ALTERATION
        )
      }

      const groupForJournalPayout = {
        id: PolicyServiceGroup.TransferJournal,
        name: t('TransactionGroup:TransferJournal'),
        icon: () => <assets.CashAdvance />,
        children: data.transactions.filter(
          (x) => x.group === PolicyServiceGroup.TransferJournal && x.id === TransactionType.JOURNAL_PAYOUT
        )
      }

      if (checkRoles(Permission.ViewFunctionListPSSubmission, data.permissions)) {
        data.transactions.map((trans) => {
          if (trans.id !== TransactionType.JOURNAL_PAYOUT && trans.id !== TransactionType.JOURNAL_ALTERATION)
            itemsSearchList.push(trans)
        })
        groupPSTrans.map((group) => {
          itemsGroupList.push(group)
        })
      }

      if (checkRoles(Permission.ViewFuncListAlterJournalFunctionListPS, data.permissions)) {
        const filterTrans = data.transactions.find((trans) => trans.id === TransactionType.JOURNAL_ALTERATION)
        if (filterTrans) {
          itemsSearchList.push(filterTrans)
          itemsGroupList.push(groupForJournalAlteration)
          checkAddedGroupJournal = true
        }
      }

      if (checkRoles(Permission.ViewFuncListPayoutJournalFunctionListPS, data.permissions)) {
        const filterTrans = data.transactions.find((trans) => trans.id === TransactionType.JOURNAL_PAYOUT)
        if (filterTrans) {
          itemsSearchList.push(filterTrans)
        }

        if (checkAddedGroupJournal) {
          itemsGroupList = itemsGroupList.filter((x) => x.id !== PolicyServiceGroup.TransferJournal)
          itemsGroupList.push(groupForJournal)
        } else {
          itemsGroupList.push(groupForJournalPayout)
        }
      }

      return {
        itemsSearch: itemsSearchList,
        itemsGroup: itemsGroupList
      }
    }),
    ErrorHandling.runDidUpdate([isFocused, props.route.params?.policyNum])
  )
  //console.log(data)

  const validateTransactionTypeEntry = (transactionTypeWF: string, policyNum: string): Promise<boolean> => {
    const transactionTypeItem = transactionTypeWF as TransactionType
    switch (transactionTypeItem) {
      case TransactionType.CONFIRMATION_OF_PREMIUM_RECEIPT: {
        showGlobalLoading(true)
        return pipe(
          ConfirmOfPremiumService.getDataCheckAccess(policyNum),
          ZIO.map((accessDataCheck) => {
            if (
              (accessDataCheck.contractStatus !== ProductEnum.Status.IF &&
                accessDataCheck.contractStatus !== ProductEnum.Status.LA) ||
              (accessDataCheck.contractStatus === ProductEnum.Status.LA.toString() &&
                diffTwoDateAsMonth(new Date(), accessDataCheck.lapseDate) > 24)
            ) {
              // this.snackBarService.openError(MESS)
              showToast(t('message:MS050034'), 'error')
              return false
            } else {
              return true
            }
          }),
          ZIO.tap((_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.tapError((_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.unsafeRun({})
        )
      }

      case TransactionType.POLICY_CERTIFICATE: {
        showGlobalLoading(true)
        return pipe(
          PolicyCertificateService.getAccessData(policyNum),
          ZIO.map((resData) => {
            const requestDate = moment()
            const lapsedDate = resData.lapsedDate
            const distanceMonth =
              resData.contractStatus === ProductEnum.Status.LA
                ? PulseUtils.getDiffMonths(moment(lapsedDate), requestDate)
                : 0
            if (resData.contractStatus === ProductEnum.Status.IF || resData.contractStatus === ProductEnum.Status.DH) {
              return true
            } else if (resData.contractStatus === ProductEnum.Status.LA && distanceMonth <= 24) {
              return true
            } else {
              showToast(t('message:MS050034'), 'error')
              return false
            }
          }),
          ZIO.tap((_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.tapError((_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.unsafeRun({})
        )
      }

      case TransactionType.STOP_APL:
        showGlobalLoading(true)
        return pipe(
          SubmissionService.getPolicy(policyNum),
          ZIO.map((policyInfo) => {
            if (
              ['V', 'U'].some((code) => policyInfo.body?.basicCode?.startsWith(code)) ||
              policyInfo.body?.status !== ProductEnum.Status.IF ||
              policyInfo.body?.premiumStatus !== ProductEnum.PremiumStatus.PP
            ) {
              showToast(t('message:MS050034'), 'error')
              return false
            } else {
              return true
            }
          }),
          ZIO.tap((_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.tapError((_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.unsafeRun({})
        )

      case TransactionType.ASSIGNMENT:
        showGlobalLoading(true)
        return pipe(
          SubmissionService.getPolicy(policyNum),
          ZIO.map((policy) => {
            const { body } = policy
            const { status, proposal, productCode } = body
            const requestDate = moment()
            const customerRecdDate = moment(proposal?.proposalReceivedDate)
            const distanceDate = requestDate.diff(customerRecdDate, 'days')
            if (productCode === 'UR2') {
              showToast(t('message:MS050138'), 'error')
              return false
            } else if (status !== ProductEnum.Status.IF || distanceDate <= 21) {
              showToast(t('message:MS050034'), 'error')
              return false
            } else {
              showToast(t('message:MS050015'), 'warning')
              return true
            }
          }),
          ZIO.tap((_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.tapError((_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.unsafeRun({})
        )

      case TransactionType.INHERITANCE:
        showGlobalLoading(true)
        return pipe(
          SubmissionService.getPolicy(policyNum),
          ZIO.map((policy) => ({
            contractStatus: String(policy.body.status),
            lapseDate: policy.body.lapsedDate ? new Date(policy.body.lapsedDate) : null
          })),
          ZIO.map(({ contractStatus, lapseDate }) => {
            if (contractStatus !== ProductEnum.Status.IF && contractStatus !== ProductEnum.Status.LA) {
              showToast(t('message:MS050154'), 'error')
              return false
            } else if (
              contractStatus === ProductEnum.Status.LA &&
              diffTwoDateAsMonth(new Date(), lapseDate as Date) > 24
            ) {
              showToast(t('message:MS050154'), 'error')
              return false
            } else {
              return true
            }
          }),
          ZIO.tap((_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.tapError((_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.unsafeRun({})
        )

      case TransactionType.CHANGE_SUM_ASSURED:
        showGlobalLoading(true)
        return pipe(
          SubmissionService.getPolicy(policyNum),
          ZIO.map((policy) => {
            const { body } = policy
            const { status, basicCode } = body

            if (
              status !== ProductEnum.Status.IF ||
              !basicCode ||
              !policy.body.riskCommenceDate ||
              !policy.body.paidToDateAdvance ||
              !policy.body.paidToDateBasic
            ) {
              showToast(t('message:MS050034'), 'error')
              return false
            }

            const riskCommenceDate = new Date(policy.body.riskCommenceDate)
            const paidToDateAdvance = new Date(policy.body.paidToDateAdvance)
            const paidToDateBasic = new Date(policy.body.paidToDateBasic)
            const requestDate = new Date()

            if (basicCode.startsWith('U') || basicCode.startsWith('V')) {
              if (
                diffTwoDateAsMonth(paidToDateBasic, riskCommenceDate) < 24 ||
                moment(paidToDateBasic).format('DD/MM') !== moment(riskCommenceDate).format('DD/MM') ||
                !(['ULR1', 'ULR4', 'VLR1'].includes(basicCode)
                  ? requestDate.getTime() >= moment(paidToDateAdvance).subtract(30, 'days').toDate().getTime() &&
                    requestDate.getTime() <= paidToDateAdvance.getTime()
                  : requestDate.getTime() >= moment(paidToDateBasic).subtract(30, 'days').toDate().getTime() &&
                    requestDate.getTime() <= paidToDateBasic.getTime())
              ) {
                showToast(t('message:MS050034'), 'error')
                return false
              }
              // if (['VLR6', 'VLR7', 'VLR8'].includes(basicCode)) {
              //   showToast(t('message:MS050307'), 'error')
              //   return false
              // }
            } else {
              if (diffTwoDateAsDay(paidToDateBasic, riskCommenceDate) <= 21) {
                showToast(t('message:MS050034'), 'error')
                return false
              }
              if (diffTwoDateAsMonth(paidToDateBasic, riskCommenceDate) >= 24) {
                showToast(t('message:MS050306'), 'error')
                return false
              }
            }

            return true
          }),
          ZIO.tap((_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.tapError((_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.unsafeRun({})
        )

      case TransactionType.CONFIRMATION_LETTER_OF_POLICY_ACCOUNT_AND_SURRENDER_VALUE: {
        showGlobalLoading(true)
        return pipe(
          ConfirmationLetterService.getAccessData(policyNum),
          ZIO.map((response) => {
            const requestDate = moment()
            const lapsedDate = response.lapsedDate
            const distanceMonth =
              response.contractStatus === ProductEnum.Status.LA
                ? PulseUtils.getDiffMonths(moment(lapsedDate), requestDate)
                : 0
            if (response.contractStatus === ProductEnum.Status.IF) {
              return true
            } else if (response.contractStatus === ProductEnum.Status.LA && distanceMonth <= 24) {
              return true
            } else {
              showToast(t('message:MS050034'), 'error')
              return false
            }
          }),
          ZIO.tap((_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.tapError((_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.unsafeRun({})
        )
      }

      case TransactionType.REINSTATEMENT: {
        showGlobalLoading(true)
        return pipe(
          ReinstatementService.getDataAccess(policyNum),
          ZIO.map((response) => {
            const startDate = moment(response.reinData.attributesExt.LAPSED_DATE ?? new Date())
            const endDate = moment(new Date())
            if (response.policy.status !== 'LA' || !response.reinData || response.isPoDeath || response.isMainLADeath) {
              showToast(t('message:MS050136'), 'error')
              return false
            } else if (PulseUtils.getDiffMonths(startDate, endDate) > 24) {
              showToast(t('message:MS050132'), 'error')
              return false
            } else {
              return true
            }
          }),
          ZIO.tap((_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.tapError((_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.unsafeRun({})
        )
      }

      case TransactionType.RIDER_REINSTATEMENT: {
        showGlobalLoading(true)
        return pipe(
          RiderReinstatementService.getDataAccess(policyNum),
          ZIO.map((policyInfo) => {
            if (!policyInfo.coverageCode.startsWith('U') && !policyInfo.coverageCode.startsWith('V')) {
              showToast(t('message:MS050034'), 'error')
              return false
            } else if (policyInfo.contractStatus !== ProductEnum.Status.IF) {
              showToast(t('message:MS050128'), 'error')
              return false
            } else if (policyInfo.notExistAnyRider || !policyInfo.checkDate) {
              showToast(t('message:MS050136'), 'error')
              return false
            }
            return true
          }),
          ZIO.tap((_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.tapError((_) => {
            showToast(t('message:MS050136'), 'error')
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.unsafeRun({})
        )
      }

      case TransactionType.BILLING_FREQUENCY_REVERSAL:
        showGlobalLoading(true)
        return pipe(
          BillingChangeReversalService.getDataCheckAccess(policyNum),
          ZIO.map((accessDataCheck) => {
            if (
              accessDataCheck.curFreq === '00' ||
              (accessDataCheck.contractStatus !== ProductEnum.Status.IF &&
                accessDataCheck.contractStatus !== ProductEnum.Status.LA) ||
              !accessDataCheck.hasLeastOldTransaction ||
              accessDataCheck.exceed120days
            ) {
              showToast(t('message:MS050034'), 'error')
              return false
            } else {
              return true
            }
          }),
          ZIO.tap((_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.tapError((_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.unsafeRun({})
        )

      case TransactionType.PRUKID369: {
        showGlobalLoading(true)
        return pipe(
          PruKid369Service.getAccess(policyNum),
          ZIO.map((response) => {
            if (!response.checkerCode) {
              showToast(t('message:MS050034'), 'error')
              return false
            } else if (!response.checkerBalance) {
              showToast(t('message:MS050162'), 'error')
              return false
            } else {
              return true
            }
          }),
          ZIO.foldM(
            (error) =>
              ZIO.effect(() => {
                showGlobalLoading(false)
                return false
              }),
            (success) =>
              ZIO.effect(() => {
                showGlobalLoading(false)
                return success
              })
          ),
          ZIO.unsafeRun({})
        )
      }

      case TransactionType.CHANGE_CONTACT_INFO: {
        showGlobalLoading(true)
        return pipe(
          ChangeContactInfoService.getDataAccess(policyNum),
          ZIO.map((accessDataCheck) => {
            showGlobalLoading(false)
            if (
              !accessDataCheck.contractStatus ||
              !accessDataCheck.paidToDateAdvance ||
              (accessDataCheck.contractStatus !== ProductEnum.Status.IF &&
                accessDataCheck.contractStatus !== ProductEnum.Status.LA) ||
              (accessDataCheck.contractStatus === ProductEnum.Status.LA &&
                diffTwoDateAsMonth(new Date(), accessDataCheck.paidToDateAdvance) > 24)
              //PulseUtils.getDiffMonths(moment(new Date()), moment(accessDataCheck.paidToDateAdvance)) > 24)
            ) {
              showToast(t('message:MS050034'), 'error')
              return false
            } else {
              return true
            }
          }),
          // bindLoader,
          ZIO.foldM(
            (_) =>
              ZIO.effect(() => {
                showGlobalLoading(false)
                return false
              }),
            (success) =>
              ZIO.effect(() => {
                showGlobalLoading(false)
                return success
              })
          ),
          //ErrorHandling.()
          ZIO.unsafeRun({})
        )
      }

      case TransactionType.CANCEL_FROM_INCEPTION: {
        showGlobalLoading(true)
        return pipe(
          CancelFromInceptionService.getDataAccess(policyNum),
          ZIO.map((policyInfo) => {
            const requestDate = moment()
            const customerRecdDate = moment(policyInfo.proposalReceivedDate)
            const distanceDate = requestDate.diff(customerRecdDate, 'days')
            if (policyInfo.coverageCode === 'DH02' || policyInfo.coverageCode === 'CYR1') {
              showToast(t('message:MS050034'), 'error')
              return false
            } else if (policyInfo.contractStatus !== ProductEnum.Status.IF) {
              showToast(t('message:MS050034'), 'error')
              return false
            } else if (
              isNaN(Number(policyInfo.proposalReceivedDate)) &&
              moment(policyInfo.proposalReceivedDate).isValid() &&
              distanceDate > 21
            ) {
              showToast(t('message:MS050034'), 'error')
              return false
            } else if (
              !isNaN(Number(policyInfo.proposalReceivedDate)) &&
              requestDate.diff(moment(policyInfo.riskCommenceDate), 'days') > 180
            ) {
              showToast(t('message:MS050034'), 'error')
              return false
            }
            return true
          }),
          ZIO.foldM(
            (error) =>
              ZIO.effect(() => {
                showGlobalLoading(false)
                return false
              }),
            (success) =>
              ZIO.effect(() => {
                showGlobalLoading(false)
                return success
              })
          ),
          ZIO.unsafeRun({})
        )
      }

      case TransactionType.FUND_SWITCHING: {
        showGlobalLoading(true)
        return pipe(
          CancelFromInceptionService.getDataAccess(policyNum),
          ZIO.map((policyInfo) => {
            if (!policyInfo.coverageCode.startsWith('U') || policyInfo.contractStatus !== ProductEnum.Status.IF) {
              showToast(t('message:MS050034'), 'error')
              return false
            }
            return true
          }),
          ZIO.foldM(
            (error) =>
              ZIO.effect(() => {
                showGlobalLoading(false)
                return false
              }),
            (success) =>
              ZIO.effect(() => {
                showGlobalLoading(false)
                return success
              })
          ),
          ZIO.unsafeRun({})
        )
      }
      case TransactionType.BILLING_CHANGE_CORPORATE:
      case TransactionType.BILLING_CHANGE: {
        showGlobalLoading(true)
        return pipe(
          BillingChangeService.getDataCheckAccess(policyNum),
          ZIO.map((accessDataCheck) => {
            // TradProduct: bat dau khac U,V
            // InvestmentProduct: bat dau = U,V
            if (accessDataCheck.freequency === '00') {
              showToast(t('message:MS050034'), 'error')
              return false
            } else if (accessDataCheck.contractStatus && accessDataCheck.contractStatus !== ProductEnum.Status.IF) {
              showToast(t('message:MS050044'), 'error')
              return false
            } else if (
              (accessDataCheck.productCode &&
                isTraditionalProduct(accessDataCheck.productType, accessDataCheck.productCode) &&
                isRqDateAfterPtdAdv120(accessDataCheck.paidToDateAdvance)) ||
              (accessDataCheck.productCode &&
                !isTraditionalProduct(accessDataCheck.productType, accessDataCheck.productCode) &&
                isRqDateAfterPtdAdv60(accessDataCheck.paidToDateAdvance))
            ) {
              showToast(t('message:MS050046'), 'error')
              return false
            } else if (
              (accessDataCheck.productCode &&
                isTraditionalProduct(accessDataCheck.productType, accessDataCheck.productCode) &&
                !isSameDateWithRCDMonthAndDay(accessDataCheck.paidToDateAdvance, accessDataCheck.rcd)) ||
              (accessDataCheck.productCode &&
                !isTraditionalProduct(accessDataCheck.productType, accessDataCheck.productCode) &&
                !isSameDateWithRCDMonthAndDay(accessDataCheck.paidToDateBasic, accessDataCheck.rcd))
            ) {
              showToast(t('message:MS050045'), 'error')
              return false
            }
            return true
          }),
          ZIO.foldM(
            () =>
              ZIO.effect(() => {
                showGlobalLoading(false)
                return false
              }),
            (success) =>
              ZIO.effect(() => {
                showGlobalLoading(false)
                return success
              })
          ),
          ZIO.unsafeRun({})
        )
      }

      case TransactionType.SPECIAL_BONUS: {
        showGlobalLoading(true)
        return pipe(
          SpecialBonusService.specialBonusAccessedData(policyNum),
          ZIO.map((res) => {
            if (res.contractStatus === ProductEnum.Status.DH) {
              showToast(t('message:MS050034'), 'error')
              return false
            } else if (res.specialBonus <= 0) {
              showToast(t('message:MS050034'), 'error')
              return false
            } else {
              return true
            }
          }),
          ZIO.foldM(
            (error) =>
              ZIO.effect(() => {
                showGlobalLoading(false)
                return false
              }),
            (success) =>
              ZIO.effect(() => {
                showGlobalLoading(false)
                return success
              })
          ),
          ZIO.unsafeRun({})
        )
      }

      case TransactionType.PREMIUM_REDIRECTION: {
        showGlobalLoading(true)
        return pipe(
          PremiumRedirectionService.accessData(policyNum),
          ZIO.map((policyInfo) => {
            const requestDate = moment()
            const customerRecdDate = moment(policyInfo.proposalReceivedDate)
            const distanceDate = requestDate.diff(customerRecdDate, 'days')
            if (!policyInfo.coverageCode.startsWith('U')) {
              showToast(t('message:MS050138'), 'error')
              return false
            } else if (policyInfo.contractStatus !== ProductEnum.Status.IF) {
              showToast(t('message:MS050034'), 'error')
              return false
            } else if (distanceDate <= 21) {
              showToast(t('message:MS050034'), 'error')
              return false
            }
            return true
          }),
          ZIO.foldM(
            (error) =>
              ZIO.effect(() => {
                showGlobalLoading(false)
                return false
              }),
            (success) =>
              ZIO.effect(() => {
                showGlobalLoading(false)
                return success
              })
          ),
          ZIO.unsafeRun({})
        )
      }

      case TransactionType.CANCEL_RIDER: {
        showGlobalLoading(true)
        return pipe(
          CancelRiderService.getAccessData(policyNum),
          ZIO.map((response) => {
            const requestDate = moment()
            const customerRecdDate = moment(response.proposalReceivedDate)
            const distanceDate = requestDate.diff(customerRecdDate, 'days')
            if (response.contractStatus !== ProductEnum.Status.IF) {
              // this.snackBarService.openError(MESS)
              showToast(t('message:MS050034'), 'error')
              return false
            } else if (response.notExistAnyRider) {
              // this.snackBarService.openError(MESS)
              showToast(t('message:MS050034'), 'error')
              return false
            } else if (distanceDate <= 21) {
              // this.snackBarService.openError(MESS)
              showToast(t('message:MS050034'), 'error')
              return false
            } else {
              return true
            }
          }),
          ZIO.tap((_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.tapError((_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.unsafeRun({})
        )
      }

      case TransactionType.LOAN: {
        showGlobalLoading(true)
        return pipe(
          LoanService.getDataAccess(policyNum),
          ZIO.map((response) => {
            let isValid = true
            if (response.coverageCode.startsWith('VL') || response.coverageCode.startsWith('UR')) {
              showToast(t('message:MS050156'), 'error')
              isValid = false
            } else if (response.contractStatus !== ProductEnum.Status.IF) {
              showToast(t('message:MS050156'), 'error')
              isValid = false
            } else if (response.loanAmountAvailable < 500000) {
              showToast(t('message:MS050032'), 'error')
              isValid = false
            }
            return isValid
          }),
          ZIO.tap((_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.tapError((_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.unsafeRun({})
        )
      }

      case TransactionType.MATURITY_ADVANCE: {
        showGlobalLoading(true)
        return pipe(
          MaturityAdvanceService.getDataAccess(policyNum),
          ZIO.map((accessDataCheck) => {
            if (!accessDataCheck.maturityAdvValid) {
              showToast(t('message:MS050034'), 'error')
              return false
            } else if (accessDataCheck.contractStatus !== ProductEnum.Status.IF) {
              showToast(t('message:MS050165'), 'error')
              return false
            } else {
              return true
            }
          }),
          ZIO.tap((_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.tapError((_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.unsafeRun({})
        )
      }

      case TransactionType.LOAN_STATEMENTS: {
        showGlobalLoading(true)
        return pipe(
          LoanStatementsService.accessData(policyNum),
          ZIO.map((response) => {
            const requestDate = moment()
            const lapsedDate = response.lapsedDate
            const distanceMonth =
              response.contractStatus === ProductEnum.Status.LA
                ? PulseUtils.getDiffMonths(moment(lapsedDate), requestDate)
                : 0
            if (
              response.contractStatus === ProductEnum.Status.IF ||
              response.contractStatus === ProductEnum.Status.MA ||
              response.contractStatus === ProductEnum.Status.SU
            ) {
              return true
            } else if (response.contractStatus === ProductEnum.Status.LA && distanceMonth <= 24) {
              return true
            } else {
              showToast(t('message:MS050034'), 'error')
              return false
            }
          }),
          ZIO.tap((_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.tapError((_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.unsafeRun({})
        )
      }

      case TransactionType.E_SERVICE_REGISTRATION: {
        showGlobalLoading(true)
        return pipe(
          PulseOpsApi.getPolicy(policyNum),
          ZIO.flatMap((policy) =>
            pipe(
              PulseOpsApi.getCustomer(policy.body.owners.id),
              ZIO.map((customer) => {
                if (customer.body.otpConsentStatus === '1') {
                  showToast(t('message:MS050221'), 'error')
                  return false
                } else {
                  return true
                }
              })
            )
          ),
          ZIO.tap((_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.tapError((_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.unsafeRun({})
        )
      }

      case TransactionType.MATURITY: {
        showGlobalLoading(true)
        return pipe(
          MaturityPaymentService.getAccessData(policyNum),
          ZIO.map((response) => {
            if (response.contractStatus !== 'MA') {
              showToast(t('message:MS050163'), 'error')
              return false
            } else if (response.balanceAmount <= 0) {
              showToast(t('message:MS050163'), 'error')
              return false
            } else {
              return true
            }
          }),
          ZIO.tap((_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.tapError((_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.unsafeRun({})
        )
      }

      case TransactionType.TOP_UP: {
        showGlobalLoading(true)
        return pipe(
          TopUpService.getDataAccess(policyNum),
          ZIO.map((response) => {
            const requestDate = moment().valueOf()
            const customPaidToDateAdvance = moment(response.paidToDateAdvance).valueOf()
            const paidTo60Date = moment(response.paidToDateAdvance).add(60, 'day').valueOf()
            if (!['VLR1', 'VLS1'].includes(response.coverageCode) && !response.coverageCode.startsWith('U')) {
              showToast(t('message:MS050138'), 'error')
              return false
            } else if (response.contractStatus !== ProductEnum.Status.IF) {
              showToast(t('message:MS050140'), 'error')
              return false
            } else if (response.premiumStatus === ProductEnum.PremiumStatus.HA) {
              showToast(t('message:MS050140'), 'error')
              return false
            } else if (['UR5', 'UR6'].includes(response.contractType) && response.totalPaidBasicAmount < response.api) {
              showToast(t('message:MS050139'), 'error')
              return false
            } else if (
              ['UR2', 'UR4', 'VLR1'].includes(response.contractType) &&
              requestDate > customPaidToDateAdvance
            ) {
              showToast(t('message:MS050140'), 'error')
              return false
            } else if (customPaidToDateAdvance <= requestDate && requestDate < paidTo60Date) {
              showToast(t('message:MS050140'), 'error')
              return false
            } else {
              return true
            }
          }),
          ZIO.tap((_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.tapError((_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.unsafeRun({})
        )
      }

      case TransactionType.BONUS_SURRENDER: {
        showGlobalLoading(true)
        return pipe(
          ZIO.zipPar(BonusSurrenderService.getAccountBalance(policyNum), SubmissionService.getPolicy(policyNum)),
          ZIO.map(([accountBalance, policy]) => {
            // console.log('accccccountBalance', accountBalance)
            // console.log('accccccountPolicy', policy)
            const { body } = policy
            const { status, basicCode } = body
            const validCoverageCode =
              basicCode && ['V', 'U', 'IPD1', 'TLA3', 'ROP1'].some((code) => basicCode.startsWith(code))
            const paidToDateAdvance = new Date(body.paidToDateAdvance ?? new Date())
            const anniDate = new Date(body.anniDate ?? new Date())
            const riskCommenceDate = new Date(body.riskCommenceDate ?? new Date())
            showGlobalLoading(false)
            if (
              validCoverageCode ||
              status !== ProductEnum.Status.IF ||
              diffTwoDateAsMonth(anniDate, riskCommenceDate) < 24 ||
              diffTwoDateAsMonth(paidToDateAdvance, riskCommenceDate) < 24 ||
              !accountBalance.validPolicy
            ) {
              showToast(t('message:MS050145'), 'error')
              return false
            } else {
              return true
            }
          }),
          ZIO.foldM(
            (error) =>
              ZIO.effect(() => {
                showGlobalLoading(false)
                return false
              }),
            (success) =>
              ZIO.effect(() => {
                showGlobalLoading(false)
                return success
              })
          ),
          ZIO.unsafeRun({})
        )
      }

      case TransactionType.BENEFICIARY_DESIGNATION: {
        showGlobalLoading(true)
        return pipe(
          BeneficiaryDesignationService.getDataCheckAccess(policyNum),
          ZIO.map((accessDataCheck) => {
            if (accessDataCheck.contractStatus && accessDataCheck.contractStatus !== ProductEnum.Status.IF) {
              showToast(t('message:MS050034', 'error'))
              return false
            } else {
              return true
            }
          }),
          ZIO.foldM(
            (_) =>
              ZIO.effect(() => {
                showGlobalLoading(false)
                return false
              }),
            (success) =>
              ZIO.effect(() => {
                showGlobalLoading(false)
                return success
              })
          ),
          ZIO.unsafeRun({})
        )
      }

      case TransactionType.CHANGE_DOB_GENDER:
      case TransactionType.CHANGE_OCCUPATION_PERSONAL:
      case TransactionType.CHANGE_NATIONAL_ID_CARD:
      case TransactionType.CHANGE_OTHER_INFORMATION_PERSONAL:
      case TransactionType.CHANGE_CLIENT_INFO: {
        showGlobalLoading(true)
        return pipe(
          ChangeClientInfoService.getDataAccess(policyNum),
          ZIO.map((accessDataCheck) => {
            showGlobalLoading(false)
            if (!accessDataCheck) {
              showToast(t('message:MS050034'), 'error')
            }
            return accessDataCheck
          }),
          ZIO.foldM(
            (error) =>
              ZIO.effect(() => {
                showGlobalLoading(false)
                return false
              }),
            (success) =>
              ZIO.effect(() => {
                showGlobalLoading(false)
                return success
              })
          ),
          ZIO.unsafeRun({})
        )
      }

      case TransactionType.SCHEDULED_PAYMENT_STATEMENT: {
        showGlobalLoading(true)
        return pipe(
          ScheduledPaymentStatementService.checkAccess(policyNum),
          ZIO.map(({ basicCode }) => {
            showGlobalLoading(false)
            if (T18.ProductTransaction.cashBenefit.includes(basicCode)) {
              return true
            } else {
              showToast(t('message:MS050034'), 'error')
              return false
            }
          }),
          ZIO.foldM(
            (error) =>
              ZIO.effect(() => {
                showGlobalLoading(false)
                return false
              }),
            (success) =>
              ZIO.effect(() => {
                showGlobalLoading(false)
                return success
              })
          ),
          ZIO.unsafeRun({})
        )
      }

      case TransactionType.EXCELLENT_STUDENT_AWARD: {
        showGlobalLoading(true)
        return pipe(
          ExcellentStudentAwardService.getAccess(policyNum),
          ZIO.map((x) => {
            if (x) {
              return true
            }
            showToast(t('message:MS050034'), 'error')
            return false
          }),
          ZIO.foldM(
            (error) =>
              ZIO.effect(() => {
                showGlobalLoading(false)
                return false
              }),
            (success) =>
              ZIO.effect(() => {
                showGlobalLoading(false)
                return success
              })
          ),
          ZIO.unsafeRun({})
        )
      }

      case TransactionType.PRUCASH_PAYMENT: {
        showGlobalLoading(true)
        return pipe(
          PruCashPaymentService.getDataAccessCheck(policyNum),
          ZIO.map((accessData) => {
            const acceptBenefitCode = T18.ProductTransaction.cashBenefit
            const benGroup = ['EUS3', 'EUS4', 'EUS5']
            if (
              !acceptBenefitCode.includes(accessData.coverageCode) ||
              accessData.totalCashBenefit <= 0 ||
              (benGroup.includes(accessData.coverageCode) && !['IF', 'LA', 'DH'].includes(accessData.contractStatus)) ||
              (!benGroup.includes(accessData.coverageCode) && !['IF', 'LA'].includes(accessData.contractStatus))
            ) {
              showToast(t('message:MS050034'), 'error')
            } else {
              return true
            }
            return false
          }),
          ZIO.tap((_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.tapError((_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.unsafeRun({})
        )
      }

      case TransactionType.FULL_SURRENDER: {
        showGlobalLoading(true)
        return pipe(
          FullSurrenderService.getAccess(policyNum),
          ZIO.map((valid) => {
            if (!valid) {
              showToast(t('message:MS050152'), 'error')
              return false
            }

            return true
          }),
          ZIO.tap((_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.tapError((_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.unsafeRun({})
        )
      }

      case TransactionType.PARTIAL_SURRENDER: {
        showGlobalLoading(true)
        return pipe(
          SubmissionService.getPolicy(policyNum),
          ZIO.map((policy) => {
            const { body } = policy
            const { status, paidToDateBasic, riskCommenceDate, basicCode, premiumStatus } = body

            if (basicCode?.startsWith('VLR') || basicCode?.startsWith('ULR') || basicCode === 'ROP1') {
              showToast(t('message:MS050034'), 'error')
              return false
            }

            if (
              status !== ProductEnum.Status.IF ||
              premiumStatus === 'FP' ||
              !paidToDateBasic ||
              !riskCommenceDate ||
              diffTwoDateAsMonth(new Date(paidToDateBasic), new Date(riskCommenceDate)) < 24
            ) {
              showToast(t('message:MS050034'), 'error')
              return false
            }

            return true
          }),
          ZIO.tap((_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.tapError((_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.unsafeRun({})
        )
      }

      case TransactionType.CHANGE_PREMIUM: {
        showGlobalLoading(true)
        return pipe(
          SubmissionService.getPolicy(policyNum),
          ZIO.map((policy) => {
            const { status, basicCode, premiumStatus } = policy.body

            if (
              status !== ProductEnum.Status.IF ||
              premiumStatus !== ProductEnum.PremiumStatus.PP ||
              !basicCode ||
              !policy.body.riskCommenceDate ||
              !policy.body.paidToDateAdvance ||
              !policy.body.paidToDateBasic ||
              !policy.body.anniDate
            ) {
              showToast(t('message:MS050034'), 'error')
              return false
            }

            const riskCommenceDate = new Date(policy.body.riskCommenceDate)
            const paidToDateAdvance = new Date(policy.body.paidToDateAdvance)
            const paidToDateBasic = new Date(policy.body.paidToDateBasic)
            const anniversaryDate = new Date(policy.body.anniDate)
            const requestDate = new Date()

            switch (basicCode) {
              case 'ULR1':
              case 'ULR4':
              case 'VLR1':
                if (
                  diffTwoDateAsYear(anniversaryDate, riskCommenceDate) < 5 ||
                  diffTwoDateAsYear(paidToDateAdvance, riskCommenceDate) < 5 ||
                  diffTwoDateAsDay(moment(paidToDateAdvance).subtract(30, 'd').toDate(), requestDate) > 0 ||
                  diffTwoDateAsDay(requestDate, moment(paidToDateAdvance).add(60, 'd').toDate()) > 0
                ) {
                  showToast(t('message:MS050034'), 'error')
                  return false
                }
                return true
              case 'ULR5':
                if (
                  diffTwoDateAsYear(anniversaryDate, riskCommenceDate) < 5 ||
                  diffTwoDateAsYear(paidToDateBasic, riskCommenceDate) < 5 ||
                  diffTwoDateAsDay(moment(paidToDateBasic).subtract(30, 'd').toDate(), requestDate) > 0 ||
                  diffTwoDateAsDay(requestDate, moment(paidToDateBasic).add(60, 'd').toDate()) > 0
                ) {
                  showToast(t('message:MS050034'), 'error')
                  return false
                }
                return true
              case 'ULR6':
                //decrease premium
                if (
                  diffTwoDateAsYear(anniversaryDate, riskCommenceDate) < 5 ||
                  diffTwoDateAsYear(paidToDateBasic, riskCommenceDate) < 5 ||
                  diffTwoDateAsDay(moment(paidToDateBasic).subtract(30, 'd').toDate(), requestDate) > 0 ||
                  diffTwoDateAsDay(requestDate, moment(paidToDateBasic).add(60, 'd').toDate()) > 0
                ) {
                  // inscrease premium
                  if (
                    diffTwoDateAsMonth(anniversaryDate, riskCommenceDate) < 12 ||
                    diffTwoDateAsMonth(paidToDateBasic, riskCommenceDate) < 12 ||
                    diffTwoDateAsDay(moment(paidToDateBasic).subtract(30, 'd').toDate(), requestDate) > 0 ||
                    diffTwoDateAsDay(requestDate, moment(paidToDateBasic).add(60, 'd').toDate()) > 0
                  ) {
                    showToast(t('message:MS050034'), 'error')
                    return false
                  }
                }
                return true
              default:
                showToast(t('message:MS050034'), 'error')
                return false
            }
          }),
          ZIO.tap((_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.tapError((_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.unsafeRun({})
        )
      }

      case TransactionType.EXCESS_PREMIUM: {
        showGlobalLoading(true)
        return pipe(
          ExcessPremiumService.getDataCheckAccess(policyNum),
          ZIO.map((accessData) => {
            const aceptStatus = ['IF', 'CF', 'SU', 'MA', 'PU', 'LA', 'DC', 'PO', 'WD']
            if (aceptStatus.includes(accessData.contractStatus) && accessData.excessPremium > 0) {
              return true
            } else {
              showToast(t('message:MS050150'), 'error')
            }
            return false
          }),
          ZIO.tap((_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.tapError((_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.unsafeRun({})
        )
      }

      case TransactionType.PRODUCT_OPTION_SWITCHING: {
        showGlobalLoading(true)
        return pipe(
          ProductOptionSwitchingService.getDataAccessCheck(policyNum),
          ZIO.map((accessData) => {
            const converageCodes = ['VLR2', 'VLR3', 'ULR6', 'EUS3']
            if (
              !converageCodes.includes(accessData.converage || '') ||
              accessData.contractStatus !== ProductEnum.Status.IF ||
              (accessData.converage === 'ULR6' && accessData.ageOfMainLifeAssured < 70) ||
              (accessData.converage !== 'ULR6' && accessData.ageOfMainLifeAssured > 65) ||
              (accessData.converage === 'ULR6' && accessData.benefitOption === 'OPT1')
            ) {
              showToast(t('message:MS050034'), 'error')
              return false
            }

            return true
          }),
          ZIO.tap((_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.tapError((_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.unsafeRun({})
        )
      }
      case TransactionType.PARTIAL_WITHDRAWAL: {
        showGlobalLoading(true)
        return pipe(
          PartialWithdrawalService.checkAccessData(policyNum),
          ZIO.map(({ status, message }) => {
            if (!status) {
              showToast(t(`message:${message}`), 'error')
              return false
            }
            return true
          }),
          ZIO.tap((_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.tapError((_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.unsafeRun({})
        )
      }
      case TransactionType.REDATING: {
        showGlobalLoading(true)
        return pipe(
          RedatingService.accessData(policyNum),
          ZIO.map((responseData) => {
            // const requestDate = moment()
            const requestDate = new Date()
            // const lapseDateDistanceMonth = PulseUtils.getDiffMonths(moment(responseData.lapsedDate), requestDate)
            const lapseDateDistanceMonth = PulseOpsFormat.diffTwoDateAsMonth(
              requestDate,
              new Date(responseData.lapsedDate)
            )
            // const rcdDistanceMonth = PulseUtils.getDiffMonths(
            //   moment(responseData.riskCommenceDate),
            //   moment(responseData.paidToDateAdvance)
            // )
            const rcdDistanceMonth = PulseOpsFormat.diffTwoDateAsMonth(
              new Date(responseData.paidToDateAdvance),
              new Date(responseData.riskCommenceDate)
            )
            if (responseData.coverageCode.startsWith('U') || responseData.coverageCode.startsWith('V')) {
              showToast(t('message:MS050130'), 'error')
              return false
            } else if (responseData.contractStatus !== ProductEnum.Status.LA) {
              showToast(t('message:MS050136'), 'error')
              return false
            } else if (responseData.PODateOfDead !== '99999999') {
              showToast(t('message:MS050136'), 'error')
              return false
              // Main LA's dod ≠ Null , not exist transcode = "B673"
            } else if (responseData.errorCode === 'MS050136') {
              showToast(t('message:MS050136'), 'error')
              return false
            } else if (lapseDateDistanceMonth < 6) {
              showToast(t('message:MS050131'), 'error')
              return false
            } else if (lapseDateDistanceMonth >= 24) {
              showToast(t('message:MS050132'), 'error')
              return false
            } else if (rcdDistanceMonth >= 24) {
              showToast(t('message:MS050135'), 'error')
              return false
            } else if (responseData.errorCode === 'MS050133') {
              // exist transcode = "T6A5"
              showToast(t('message:MS050133'), 'error')
              return false
            } else if (responseData.errorCode === 'MS050134') {
              // exist any claims history
              showToast(t('message:MS050134'), 'error')
              return false
            }
            return true
          }),
          ZIO.tap((_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.tapError((_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.unsafeRun({})
        )
      }
      case TransactionType.RIDER_ALTERATION: {
        showGlobalLoading(true)
        return pipe(
          SubmissionService.getPolicy(policyNum),
          ZIO.map((policyData) => {
            const policyItem = policyData.body
            const requestDate = moment()
            const customerRecdDate = moment(policyItem.proposal?.proposalReceivedDate)
            const distanceDate = requestDate.diff(customerRecdDate, 'days')
            if (policyItem.status !== ProductEnum.Status.IF) {
              showToast(t('message:MS050034'), 'error')
              return false
            } else if (policyItem.premiumStatus !== ProductEnum.PremiumStatus.PP) {
              showToast(t('message:MS050034'), 'error')
              return false
            } else if (distanceDate <= 21) {
              showToast(t('message:MS050034'), 'error')
              return false
            }
            return true
          }),
          ZIO.tap((_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.tapError((_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.unsafeRun({})
        )
      }

      case TransactionType.MAJOR_COMBINED_CHANGE: {
        showGlobalLoading(true)
        return pipe(
          SubmissionService.getPolicy(policyNum),
          ZIO.map((policyData) => {
            const policyItem = policyData.body
            const requestDate = moment()
            const customerRecdDate = moment(policyItem.proposal?.proposalReceivedDate)
            const policyLastIssueDate = moment(policyItem?.lastIssueDate)
            const timestampToCompare =
              policyItem.proposal?.proposalReceivedDate &&
              moment(policyItem.proposal?.proposalReceivedDate, true).isValid()
                ? customerRecdDate
                : policyLastIssueDate
            const distanceDate = requestDate.diff(timestampToCompare, 'days')
            const traditionalProduct =
              policyItem?.basicCode && !policyItem.basicCode.startsWith('U') && !policyItem.basicCode.startsWith('V')
            const distanceMonth = PulseUtils.getDiffMonths(moment(policyItem.lapsedDate), requestDate)
            const checkInFreeLook =
              policyItem.status === ProductEnum.Status.IF &&
              (traditionalProduct || policyItem?.basicCode === 'VLR5') &&
              distanceDate <= 21
            const checkAfterFreeLook =
              (policyItem.status === ProductEnum.Status.IF ||
                (policyItem.status === ProductEnum.Status.LA && distanceMonth <= 24) ||
                (policyItem?.basicCode &&
                  (policyItem.basicCode.startsWith('U') || policyItem.basicCode.startsWith('V')))) &&
              distanceDate > 21
            if (!checkAfterFreeLook && !checkInFreeLook) {
              showToast(t('message:MS050034'), 'error')
              return false
            }
            return true
          }),
          ZIO.tap((_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.tapError((_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }),
          ZIO.unsafeRun({})
        )
      }

      case TransactionType.FULL_SURRENDER_REVERSAL: {
        showGlobalLoading(true)
        return pipe(
          FullSurrenderReversalService.getAccessData(policyNum),
          ZIO.map((responsedData) => {
            const surrenderDate = moment(responsedData.surrenderDate)
            const dayDistance = moment().diff(surrenderDate, 'days')
            const monthDistance = dayDistance / 30
            if (responsedData.contractStatus !== ProductEnum.Status.SU) {
              showToast(t('message:MS050034'), 'error')
              return false
            } else if (responsedData.accountBalance === 0 && monthDistance > 1) {
              showToast(t('message:MS050034'), 'error')
              return false
            } else if (responsedData.accountBalance < 0 && monthDistance > 6) {
              showToast(t('message:MS050034'), 'error')
              return false
            } else if (responsedData.accountBalance > 0) {
              showToast(t('message:MS050034'), 'error')
              return false
            } else {
              return true
            }
          }),
          ZIO.foldM(
            (_) => {
              showGlobalLoading(false)
              return ZIO.fail(false)
            },
            (success) => {
              showGlobalLoading(false)
              return ZIO.succeed(success)
            }
          ),
          ZIO.unsafeRun({})
        )
      }

      default:
        return Promise.resolve(true)
    }
  }

  const isRqDateAfterPtdAdv120 = (ptdAvance: Date | null): boolean => {
    if (ptdAvance) {
      return moment(new Date()).startOf('days').isAfter(moment(ptdAvance).startOf('days').add(120, 'days'))
    }
    return true
  }

  const isRqDateAfterPtdAdv60 = (ptdAvance: Date | null): boolean => {
    if (ptdAvance) {
      const reqDate = moment(new Date()).startOf('days')
      const ptd60 = moment(ptdAvance).startOf('days').add(60, 'days')
      console.log('isRqDateAfterPtdAdv60', reqDate.isAfter(ptd60))
      return reqDate.isAfter(ptd60)
    }
    return true
  }

  const isSameDateWithRCDMonthAndDay = (ptdAvance: Date | null, rcd: Date | null): boolean => {
    if (ptdAvance && rcd) {
      return moment(ptdAvance).format('DD/MM') === moment(rcd).format('DD/MM')
    }
    return true
  }

  const isTraditionalProduct = (productType: string, productCode: string): boolean => {
    const asTraditionalProducts = ['ur2', 'ur4', 'vr1']
    return productType === 'TRAD' || asTraditionalProducts.includes(productCode.toLocaleLowerCase())
  }

  const diffTwoDateAsYear = (d1: Date, d2: Date) => moment(d1).startOf('days').diff(moment(d2).startOf('days'), 'years')

  const diffTwoDateAsMonth = (d1: Date, d2: Date) =>
    moment(d1).startOf('days').diff(moment(d2).startOf('days'), 'months')

  const diffTwoDateAsDay = (d1: Date, d2: Date) => moment(d1).startOf('days').diff(moment(d2).startOf('days'), 'days')

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView>
        <PolicyInfoHeader menus={headers} />
        {data && <SearchPanel data={data} />}
      </ScrollView>
    </SafeAreaView>
  )
}
