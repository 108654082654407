import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import {
  AppContext,
  AuthService,
  CancelRiderService,
  ErrorHandling,
  form2,
  Input,
  // GeneralService,
  InquiryComplaintData,
  InquiryComplaintService,
  PulseOpsFormat,
  RoundedButton,
  // StorageBlob,
  TopUpService,
  TransactionType,
  useMobile
} from '@pulseops/common'
import { useIsFocused } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import { pipe } from 'fp-ts/lib/function'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, StyleSheet, View } from 'react-native'
// import { ClaimInfoHeader } from '../claim'
import { PolicyInfoHeader, UploadedFilesInfo } from '../ps-submission/transactionScreen/screens'
import { StaffSubmissionStackParamList } from '../StaffSubmissionStackParamList'
import { ComplainInquiryForm } from './complain-inquiry-form'
import { CommunicationInfo } from './comunication-info'
import { Conservation } from './conservation'
import { GAOperation } from './ga-operation'
import { GeneralInfomation } from './general-infomation'
import { MoneyMisappropriation } from './money-misappropriation'
import { SalesPractice } from './sales-practice'

type Props = StackScreenProps<StaffSubmissionStackParamList, 'ComplainInquiryScreen'>

export const ComplainInquiryScreen = ({ navigation, route }: Props) => {
  const { isMobile } = useMobile()
  const { t, i18n } = useTranslation()
  const isFocused = useIsFocused()
  const [loader, bindLoader] = useLoading()

  const { showToast, showGlobalLoading, changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)

  const [categoryCode, setCategoryCode] = useState<string>('')
  const [groupCode, setGroupCode] = useState<string>('')
  const [isShowConservation, setShowConservation] = useState<boolean>(false)
  const [isReset, setReset] = useState<boolean>(false)
  const [agentCodeError, setAgentCodeError] = useState<string>('')

  const [userInfo, setUserInfo] = React.useState<{ email: string; name: string }>({
    email: '',
    name: ''
  })

  const defaultValues: ComplainInquiryForm.Raw = {
    generalInfo: {
      group: null,
      type: null,
      subType: null,
      requestorRole: null,
      requestorFullName: '',
      requestorFullName1: null,
      relationshipWithPolicyOwner: null,
      requestorNationalID: '',
      method: null,
      source: null,
      firstContactResolution: false,
      hot: false,
      callId: '',
      isRequireCallId: false,
      callBackLaterHour: null,
      callbackAppointmentTime: ''
    },
    ga: {
      generalAgency: null,
      personToBeComplained: ''
    },
    salesPractice: {
      agentCode: '',
      agentName: '',
      POSignedInProposal: 'yes',
      customerReceivedPolicyKit: 'yes',
      customerReceivedDate: new Date(),
      financialNeedAnalysis: null
    },
    communicationInfo: {
      customerComments: '',
      responsesToCustomer: '',
      attachmentFiles: []
    },
    moneyMissappropriation: {
      list: [
        {
          receipt: 'no',
          receiptType: null,
          complaintAmount: '',
          receiptNumber: '',
          benefit_PremiumMisappropriatedDate: new Date()
        }
      ],
      totalComplaintAmount: '0'
    },
    conservationInfomation: {
      conservationAgentCode: '',
      conservationAgentName: '',
      conservationBonus: null,
      conservationGAStaff: false,
      conservationNote: '',
      conservationResult: null,
      conservationSolution: null,
      reasonFullSurrender: null,
      attachmentFiles: []
    },
    isSubmited: false
  }

  const { base } = form2.useForm(ComplainInquiryForm.codec, {
    defaultValues: defaultValues
  })

  const { detail, mainProductCode, riders } = pipe(
    route.params?.policyNum,
    ZIO.succeed,
    ZIO.flatMap((policyNum) =>
      pipe(
        ZIO.zipPar(
          InquiryComplaintService.getDetail(policyNum),
          TopUpService.GetPolicyExtraInfo([policyNum], ''),
          CancelRiderService.getRiderInfoList()
        ),
        ZIO.map(([detail, policyExtraInfo, riders]) => ({
          detail,
          mainProductCode: policyExtraInfo.policyExtraInfoDetail.find(
            (x) => x.lifeNo === '01' && x.coverageNo === '01' && x.riderNo === '00'
          )?.productCode,
          riders
        }))
      )
    ),
    ZIO.map(({ detail, mainProductCode, riders }) => {
      return {
        detail: detail,
        mainProductCode,
        riders
      }
    }),
    bindLoader,
    ErrorHandling.runDidUpdate([isFocused])
  ) || {
    detail: null,
    mainProductCode: undefined,
    riders: null
  }

  useEffect(() => {
    onReset()
    base.clearErrors()
  }, [i18n.language, isFocused])

  pipe(
    AuthService.userInfo,
    ZIO.map((user) => {
      setUserInfo(user)
    }),
    bindLoader,
    ErrorHandling.runDidUpdate([detail])
  )

  useEffect(() => {
    showGlobalLoading(loader)
  }, [loader])

  useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigation.replace('HomeScreen')
          }
        },
        {
          title: t('Submission:EForm'),
          navigate: () => navigation.navigate('StaffSubmissionScreen')
        },

        { title: t('common:InquiryComplaint'), navigate: null }
      ])
    }
  }, [isFocused])

  const getPolicyOwnerHeaderName = () => {
    return !!route.params?.primaryId && !!route.params?.policyOwner
      ? route.params?.primaryId + ' - ' + route.params?.policyOwner
      : ''
  }

  const headers = [
    {
      label: t('common:PolicyNumber'),
      value: route.params?.policyNum ?? '-'
    },
    {
      label: t('common:PolicyOwner'),
      value: getPolicyOwnerHeaderName()
    },
    {
      label: t('common:Office'),
      value: `${route.params?.officeCode ?? ''} - ${route.params?.officeName ?? ''}`
    }
  ]

  const getCategoryCode = (value: string) => {
    setCategoryCode(value)
  }

  const getGroupCode = (value: string) => {
    setGroupCode(value)
    setAgentCodeError('')
  }

  const onShowConservation = (value: boolean) => {
    setShowConservation(value)
    setAgentCodeError('')
  }

  const setAgentCodeErr = (value: string) => {
    setAgentCodeError(value)
  }

  const onReset = () => {
    base.reset({
      generalInfo: {
        group: null,
        type: null,
        subType: null,
        requestorRole: null,
        requestorFullName: '',
        requestorFullName1: null,
        relationshipWithPolicyOwner: null,
        requestorNationalID: '',
        method: null,
        source: null,
        firstContactResolution: false,
        hot: false,
        callId: '',
        isRequireCallId: false
      },
      ga: {
        generalAgency: null,
        personToBeComplained: ''
      },
      salesPractice: {
        agentCode: '',
        agentName: '',
        POSignedInProposal: 'yes',
        customerReceivedPolicyKit: 'yes',
        customerReceivedDate: new Date(),
        financialNeedAnalysis: null
      },
      communicationInfo: {
        customerComments: '',
        responsesToCustomer: '',
        attachmentFiles: []
      },
      moneyMissappropriation: {
        list: [
          {
            receipt: 'no',
            receiptType: null,
            complaintAmount: '',
            receiptNumber: '',
            benefit_PremiumMisappropriatedDate: new Date()
          }
        ],
        totalComplaintAmount: '0'
      },
      conservationInfomation: {
        conservationAgentCode: '',
        conservationAgentName: '',
        conservationBonus: null,
        conservationGAStaff: false,
        conservationNote: '',
        conservationResult: null,
        conservationSolution: null,
        reasonFullSurrender: null,
        attachmentFiles: []
      },
      isSubmited: false
    })
    setReset((prevState) => !prevState)
    setGroupCode('')
    setAgentCodeErr('')
    setShowConservation(false)
  }

  const mappingData = () => {
    const { generalInfo, communicationInfo, conservationInfomation, ga, moneyMissappropriation, salesPractice } =
      base.getValues()

    let COMPLAINTMISMONEYDETAILS: InquiryComplaintData.COMPLAINTMISMONEYDETAILS[] = []
    let COMPLAINTSALEDETAILS: InquiryComplaintData.COMPLAINTSALEDETAILS[] = []
    let COMPLAINTGADETAILS: InquiryComplaintData.COMPLAINTGADETAILS[] = []
    let CONSERVATIONINFO: InquiryComplaintData.CONSERVATIONINFO | null = null
    const hot = generalInfo.hot ? true : false
    const fcr = generalInfo.firstContactResolution ? true : false
    const totalComplaintAmount =
      groupCode === 'CHG2'
        ? moneyMissappropriation.totalComplaintAmount
          ? PulseOpsFormat.thousandSepartorReverse(moneyMissappropriation.totalComplaintAmount)
          : 0
        : 0
    const groupItem = detail?.groupList && detail.groupList.find((x) => x.code === generalInfo.group?.value)
    const categoryName =
      groupItem?.categoryName === 'Complaint' ? 'COMPLAINT' : groupItem?.categoryName === 'Inquiry' ? 'INQUIRY' : ''
    let touchPoint =
      detail?.sourceList && detail.sourceList.find((x) => x.code === generalInfo.source?.value)?.touchPoint
    touchPoint = !!touchPoint ? touchPoint : ''

    if (groupCode === 'CHG2') {
      COMPLAINTMISMONEYDETAILS = moneyMissappropriation.list.map((controlItem) => {
        const misPremiumDate = moment(controlItem.benefit_PremiumMisappropriatedDate).format('YYYYMMDD')
        const Moneyitem: InquiryComplaintData.COMPLAINTMISMONEYDETAILS = {
          hasReciept: controlItem.receipt,
          recieptType: _.get(controlItem, 'receiptType.value') || '',
          recieptNum: _.get(controlItem, 'receiptNumber') || '',
          complaintAmount: controlItem.complaintAmount
            ? PulseOpsFormat.thousandSepartorReverse(controlItem.complaintAmount)
            : 0,
          misPremiumDate: misPremiumDate
        }
        return Moneyitem
      })
    }

    if (groupCode === 'CHG3') {
      const isPoSignedInProposal = salesPractice.POSignedInProposal === 'yes' ? true : false
      const isCustomerReceiveKit = salesPractice.customerReceivedPolicyKit === 'yes' ? true : false
      const customerReceiveDate = moment(salesPractice.customerReceivedDate).format('YYYYMMDD')
      const saleItem: InquiryComplaintData.COMPLAINTSALEDETAILS = {
        agentCode: salesPractice.agentCode || '',
        agentName: salesPractice.agentName || '',
        financeNeedCode: salesPractice.financialNeedAnalysis?.value || '',
        isPoSignedInProposal: isPoSignedInProposal,
        isCustomerReceiveKit: isCustomerReceiveKit,
        customerReceiveDate: customerReceiveDate
      }
      COMPLAINTSALEDETAILS.push(saleItem)
    }

    if (groupCode === 'CHG4') {
      const gaItem: InquiryComplaintData.COMPLAINTGADETAILS = {
        generalAgency: ga.generalAgency?.value || '',
        personComplaint: ga.personToBeComplained
      }
      COMPLAINTGADETAILS.push(gaItem)
    }

    if (isShowConservation) {
      let mainProductName: string = ''

      if (detail && riders && mainProductCode) {
        const mainRider = riders.find((r) => r.code === mainProductCode)
        mainProductName = mainRider ? (i18n.language === 'en' ? mainRider.name : mainRider.nameVi) : ''
      }
      CONSERVATIONINFO = {
        product: mainProductName || '',
        servicingAgentCode: detail?.agentCode || '',
        servicingAgentName: detail?.agentName || '',
        conservationGAStaff: conservationInfomation.conservationGAStaff ? userInfo.name : '',
        conservationAgentCode: _.get(conservationInfomation, 'conservationAgentCode') || '',
        conservationAgentName: _.get(conservationInfomation, 'conservationAgentName') || '',
        reasonFullSurrender: conservationInfomation.reasonFullSurrender?.value || '',
        conservationResult: conservationInfomation.conservationResult?.value || '',
        conservationSolution: conservationInfomation.conservationSolution?.value || '',
        conservationBonus: _.get(conservationInfomation, 'conservationBonus.value') || '',
        conservationNote: conservationInfomation.conservationNote
      }
    }

    let requestorFullName = ''

    if (generalInfo.requestorRole?.value) {
      if (generalInfo.requestorRole.value === '1' || generalInfo.requestorRole.value === '4') {
        requestorFullName = _.get(generalInfo, 'requestorFullName')
      } else {
        requestorFullName = _.get(generalInfo, 'requestorFullName1.value') || ''
      }
    }

    const submitData: InquiryComplaintData.SubmitData = {
      CATEGORY_CODE: categoryName,
      GROUP_CODE: generalInfo.group?.value || '',
      SUB_SVC_TYPE_CODE: generalInfo.type?.value || '',
      SUB_TRANS_TYPE_CODE: generalInfo.subType?.value || '',
      REQUEST_WITH_ROLE: generalInfo.requestorRole?.value || '',
      REQUESTER_FULL_NAME: requestorFullName,
      REQUESTER_NATIONAL_ID: generalInfo.requestorNationalID,
      RLA_WITH_PO: _.get(generalInfo, 'relationshipWithPolicyOwner.value') || '',
      METHOD: generalInfo.method?.value || '',
      SOURCE: generalInfo.source?.value || '',
      TOUCH_POINT: touchPoint,
      HOT: hot,
      FCR: fcr,
      CUSTOMER_CMT: communicationInfo.customerComments || '',
      RES_TO_CUSTOMER: communicationInfo.responsesToCustomer || '',
      TOTAL_MIS_MONEY_AMT: totalComplaintAmount,
      COMPLAINT_MIS_MONEY_DETAILS: COMPLAINTMISMONEYDETAILS,
      COMPLAINT_SALE_DETAILS: COMPLAINTSALEDETAILS,
      COMPLAINT_GA_DETAILS: COMPLAINTGADETAILS,
      CONSERVATION_INFO: CONSERVATIONINFO ?? {},
    }
    return submitData
  }

  const getAttachmentFiles = () => {
    const { generalInfo, communicationInfo, conservationInfomation } = base.getValues()
    // let files: StorageBlob.FileContent[] = []
    const groupItem = detail?.groupList && detail.groupList.find((x) => x.code === generalInfo.group?.value)
    const categoryName =
      groupItem?.categoryName === 'Complaint'
        ? TransactionType.COMPLAINT
        : groupItem?.categoryName === 'Inquiry'
        ? TransactionType.INQUIRY
        : ''
    let docTypeCode = categoryName === TransactionType.COMPLAINT ? 'DCH02' : 'DCH05'

    let attachmentFilesControl = communicationInfo.attachmentFiles
    if (isShowConservation) {
      attachmentFilesControl = conservationInfomation.attachmentFiles
      docTypeCode = 'DCS01'
    }

    // if (!!attachmentFilesControl && attachmentFilesControl.length > 0) {
    //   return pipe(
    //     GeneralService.getMetaData(categoryName, docTypeCode, route?.params?.officeCode || ''),
    //     ZIO.map((metaDataRes) => {
    //       const metaDataRaw: StorageBlob.MetaDataUpload = {
    //         type: metaDataRes.data.transactionType,
    //         doctype: metaDataRes.data.doctypeEn,
    //         class: metaDataRes.data.classFilenet,
    //         docid: metaDataRes.data.docID,
    //         maindoc: metaDataRes.data.mainDoc,
    //         subdoc: metaDataRes.data.subDoc,
    //         polnum: route.params?.policyNum,
    //         batchno: metaDataRes.data.batchNo
    //       }
    //       attachmentFilesControl.forEach((x) => {
    //         if (x.file) {
    //           files.push({
    //             file: x.file,
    //             metaData: metaDataRaw
    //           })
    //         }
    //       })
    //       return files
    //     })
    //   )
    // } else {
    //   return pipe(files, ZIO.succeed)
    // }
    let uploadedFileItem: UploadedFilesInfo = {
      uploadFiles: attachmentFilesControl as ComplainInquiryForm.File[],
      transactionType: categoryName as TransactionType,
      docTypeCode: docTypeCode,
      category: categoryCode,
      policyNumber: route.params?.policyNum ?? '',
      officeCode: route.params?.officeCode ?? ''
    }
    return uploadedFileItem
  }

  const onSubmit = () => {
    if (isShowConservation) {
      if (
        base.watch('conservationInfomation.conservationResult')?.value === 'FAIL' &&
        !base.watch('conservationInfomation.conservationGAStaff')
      ) {
        base
          .trigger([
            'generalInfo',
            'conservationInfomation.conservationGAStaff',
            'conservationInfomation.conservationAgentCode',
            'conservationInfomation.conservationAgentName',
            'conservationInfomation.conservationResult',
            'conservationInfomation.conservationSolution',
            'conservationInfomation.reasonFullSurrender',
            'conservationInfomation.attachmentFiles'
          ])
          .then((isValid) => {
            if (isValid) {
              handleSubmited()
            }
          })
      } else {
        base.trigger(['generalInfo', 'conservationInfomation']).then((isValid) => {
          if (isValid && !agentCodeError) {
            handleSubmited()
          }
        })
      }
    } else {
      if (groupCode === 'CHG2') {
        base.trigger(['generalInfo', 'moneyMissappropriation', 'communicationInfo']).then((isValid) => {
          if (isValid) {
            handleSubmited()
          }
        })
      } else if (groupCode === 'CHG3') {
        base.trigger(['generalInfo', 'salesPractice', 'communicationInfo']).then((isValid) => {
          if (isValid && !agentCodeError) {
            handleSubmited()
          }
        })
        base.trigger('salesPractice')
      } else if (groupCode === 'CHG4') {
        base.trigger(['generalInfo', 'ga', 'communicationInfo']).then((isValid) => {
          if (isValid) {
            handleSubmited()
          }
        })
      } else {
        base.trigger(['generalInfo', 'communicationInfo']).then((isValid) => {
          if (isValid) {
            handleSubmited()
          }
        })
      }
    }
  }

  console.log('isSubmited : ' + base.watch('isSubmited'))

  const handleSubmited = () => {
    const inquiryData = mappingData()
    if (!base.watch('isSubmited')) {
      showGlobalLoading(true)
      base.setValue('isSubmited', true)
      pipe(
        // getAttachmentFiles(),
        // ZIO.flatMap((metaDataFiles) => {
        //   return pipe(StorageBlob.uploadToSubmit(categoryCode, route.params?.policyNum)(metaDataFiles))
        // }),
        // ZIO.tapError((_) => {
        //   showToast(t('message:MS050001'), 'error')
        //   return ZIO.unit
        // }),
        ZIO.effect(() => getAttachmentFiles()),
        ZIO.flatMap((attachmentFilesInfo) => {
          return pipe(
            InquiryComplaintService.submitData(
              route.params?.policyNum,
              inquiryData,
              [],
              route.params?.officeCode || undefined,
              attachmentFilesInfo
            ),
            ZIO.flatMap((response) => {
              showGlobalLoading(false)
              showToast(t('message:RequestSendSuccessfully'), 'success')
              base.setValue('isSubmited', false)
              return ZIO.succeed(navigation.navigate('StaffSubmissionScreen'))
            })
          )
        }),
        ZIO.catchAll((error) => {
          showGlobalLoading(false)
          showToast(t('message:MS050001'), 'error')
          base.setValue('isSubmited', false)
          return ZIO.fail(null)
        }),
        ZIO.unsafeRun({})
      )
    }
  }

  return (
    <View
      style={{
        flex: 1,
        flexDirection: 'column',
        backgroundColor: isMobile ? '#E5E5E5' : '#F9F9F9',
        paddingTop: 16
      }}
    >
      <ScrollView>
        <View style={{ flex: 1 }}>
          {/* <ClaimInfoHeader
            menus={{
              policyNum: route.params?.policyNum,
              policyOwner: route.params.policyOwner,
              officeName: `${route.params?.officeCode} - ${route.params?.officeName}` || '-'
            }}
          /> */}
          <PolicyInfoHeader menus={headers} />
          <View style={{ paddingHorizontal: 16 }}>
            <GeneralInfomation
              form={base}
              detail={detail}
              getCategoryCode={getCategoryCode}
              getGroupCode={getGroupCode}
              onShowConservation={onShowConservation}
              isReset={isReset}
              policyOwner={route?.params?.policyOwner || ''}
            />
            {groupCode === 'CHG2' && <MoneyMisappropriation form={base} detail={detail} />}
            {groupCode === 'CHG3' && (
              <SalesPractice
                form={base}
                detail={detail}
                setAgentCodeErr={setAgentCodeErr}
                agentCodeError={agentCodeError}
              />
            )}
            {groupCode === 'CHG4' && <GAOperation form={base} detail={detail} />}
            {isShowConservation && (
              <Conservation
                form={base}
                detail={detail}
                mainProductCode={mainProductCode}
                riders={riders}
                isReset={isReset}
                userInfo={userInfo}
                setAgentCodeErr={setAgentCodeErr}
                agentCodeError={agentCodeError}
              />
            )}
            {!isShowConservation && <CommunicationInfo form={base} detail={detail} />}
            <View style={[styles.row]}>
              <RoundedButton
                showBorder={true}
                filled={false}
                activeOpacity={0.7}
                text={t('submission:ClearAll')}
                textStyle={styles.btnTitle}
                style={{ height: 39, marginEnd: 15, paddingHorizontal: 20 }}
                textColorDisable="#B0B0B0"
                textColorEnable="#ED1B2E"
                bgColorDisable={'#B0B0B0'}
                bgColorEnable={'#ED1B2E'}
                borderColorDisable={'#B0B0B0'}
                borderColorEnable={'#ED1B2E'}
                onPress={() => onReset()}
              />
              <RoundedButton
                // isLoading={loader}
                showBorder={false}
                filled={true}
                activeOpacity={0.7}
                text={t('submission:Submit')}
                textStyle={styles.btnTitle}
                style={{ height: 39, paddingHorizontal: 20 }}
                textColorDisable="#FFFFFF"
                textColorEnable="#FFFFFF"
                bgColorDisable={'#B0B0B0'}
                bgColorEnable={'#ED1B2E'}
                borderColorDisable={'#B0B0B0'}
                borderColorEnable={'#ED1B2E'}
                onPress={onSubmit}
              />
            </View>
          </View>
        </View>
      </ScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    marginBottom: 30
  },
  btnTitle: {
    fontSize: 15,
    fontWeight: 'bold',
    marginVertical: 5,
    marginHorizontal: 29
  }
})
