import { ZIO } from '@mxt/zio'
import { Maybe } from '@mxt/zio/codec'
import { POApi } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'

export namespace OBActionHistoryService {
  export const OBActionHistoryInfo = t.type({
    processInstanceId: Maybe(t.string),
    policyNumber: Maybe(t.string),
    assignee: Maybe(t.string),
    teamLeader: Maybe(t.string),
    transactionType: Maybe(t.string),
    calledDate: Maybe(t.string),
    savedDate: Maybe(t.string),
    createdBy: Maybe(t.string),
    createdDate: Maybe(t.string),
    updatedBy: Maybe(t.string),
    updatedDate: Maybe(t.string),
    isChangeContactInfo: Maybe(t.boolean),
    callingInfoDTO: t.type({
      callId: Maybe(t.string),
      callReceiver: Maybe(t.string),
      callingMethod: Maybe(t.string),
      callbackTime: Maybe(t.string),
      doNotContacts: Maybe(t.array(t.string)),
      callIndicator: Maybe(t.string),
      callIndicatorStatus: Maybe(t.string),
      callingResult: Maybe(t.string),
      quickNote: Maybe(t.string),
      callScript: Maybe(t.string),
      hint: Maybe(t.string),
      phoneNumber: Maybe(t.string),
      callingDuration: Maybe(t.string),
      connectionStatus: Maybe(t.string)
      // callingTime: t.string
    }),
    sendingFileInfoDTO: t.type({
      sendingType: Maybe(t.string),
      guidelineDocument: Maybe(t.string),
      communicationHistory: Maybe(t.string)
    })
  })

  export type OBActionHistoryInfo = t.TypeOf<typeof OBActionHistoryInfo>

  export const getActionHistoryList = (processInstanceId: string, pageIndex: number, itemsPerPage: number) =>
    pipe(
      POApi.post(`pulseops/api/v1/outbound/call-out/history-call`)(
        t.type({
          records: t.array(OBActionHistoryInfo),
          paging: Maybe(
            t.type({
              pageIndex: Maybe(t.number),
              itemsPerPage: Maybe(t.number),
              total: Maybe(t.number),
              pages: Maybe(t.number)
            })
          )
        })
      )({
        processInstanceId,
        pageIndex,
        itemsPerPage
      }),
      ZIO.foldM(
        (error) => ZIO.fail(error),
        (success) => ZIO.succeed(success)
      )
    )
}
