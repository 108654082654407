import * as t from 'io-ts'
import { TransactionType } from '../TransactionType'
import { SourceType } from '../SourceType'
import { Maybe } from '@mxt/zio/codec'

const Base = <C extends t.Mixed>(codec: C) =>
  t.intersection([
    t.type({
      transactionType: t.literal(TransactionType.BENEFICIARY_DESIGNATION)
    }),
    codec
  ])

const Pulse = Base(
  t.type({
    source: t.literal(SourceType.PULSE),
    payload: t.type({
      body: t.array(
        t.type({
          percentage: t.number,
          relationshipType: t.string,
          customer: t.type({
            firstName: Maybe(t.string),
            surName: Maybe(t.string),
            clientId: Maybe(t.string),
            dob: Maybe(t.string),
            sex: Maybe(t.string),
            nationality: Maybe(t.string),
            externalIds: Maybe(
              t.type({
                SOE_VALUE: Maybe(t.string),
                SOE: Maybe(t.string),
                NATIONAL_ID: Maybe(t.string)
              })
            ),
            contactDetails: t.type({
              EMAIL: t.type({
                value: Maybe(t.string)
              }),
              PHONE: t.type({
                value: Maybe(t.string)
              })
            })
          })
        })
      )
    })
  })
)

const Pulse4Ops = Base(
  t.type({
    source: t.literal(SourceType.PULSE4OPS),
    payload: t.type({
      body: t.array(
        t.type({
          percentage: t.number,
          relationshipType: t.string,
          customer: t.type({
            firstName: Maybe(t.string),
            surName: Maybe(t.string),
            clientId: Maybe(t.string),
            dob: Maybe(t.string),
            sex: Maybe(t.string),
            nationality: Maybe(t.string),
            occupation: Maybe(t.string),
            externalIds: Maybe(
              t.type({
                SOE_VALUE: Maybe(t.string),
                SOE: Maybe(t.string),
                ISSUED_BY: Maybe(t.string)
              })
            ),
            contactDetails: t.type({
              EMAIL: t.type({
                value: t.string
              }),
              PHONE: t.type({
                countryCode: t.string,
                value: t.string
              })
            }),
            addressDetails: Maybe(
              t.type({
                ADDRESS: Maybe(
                  t.type({
                    line1: Maybe(t.string),
                    city: Maybe(t.string),
                    district: Maybe(t.string),
                    subDistrict: Maybe(t.string),
                    countryCode: Maybe(t.string)
                  })
                ),
                FOREIGN_ADDRESS: Maybe(
                  t.type({
                    line1: Maybe(t.string),
                    countryCode: Maybe(t.string)
                  })
                )
              })
            ),
            attributesExt: Maybe(
              t.type({
                NATIONALITY_2: Maybe(t.string),
                nationality2Name: Maybe(t.string),
                countryName: Maybe(t.string),
                districtName: Maybe(t.string),
                wardName: Maybe(t.string)
              })
            )
          })
        })
      )
    })
  })
)

const ZaloEkiosk = t.type({
  payload: t.type({
    body: t.array(
      t.type({
        percentage: Maybe(t.number),
        relationshipType: Maybe(t.string),
        customer: t.type({
          clientId: Maybe(t.string),
          dob: Maybe(t.string),
          name: Maybe(t.string),
          nationality: Maybe(t.string),
          sex: Maybe(t.string),
          externalIds: t.type({
            SOE_VALUE: Maybe(t.string),
            ISSUED_BY: Maybe(t.string)
          })
        })
      })
    )
  })
})

const Zalo = Base(
  t.type({
    source: t.literal(SourceType.ZALO),
    payload: t.type({
      body: t.array(
        t.type({
          percentage: t.number,
          relationshipType: t.string,
          customer: t.type({
            firstName: Maybe(t.string),
            surName: Maybe(t.string),
            clientId: Maybe(t.string),
            dob: Maybe(t.string),
            sex: Maybe(t.string),
            nationality: Maybe(t.string),
            occupation: Maybe(t.string),
            externalIds: Maybe(
              t.type({
                SOE_VALUE: Maybe(t.string),
                SOE: Maybe(t.string),
                ISSUED_BY: Maybe(t.string)
              })
            ),
            contactDetails: t.type({
              EMAIL: Maybe(
                t.type({
                  value: t.string
                })
              ),
              PHONE: Maybe(
                t.type({
                  countryCode: t.string,
                  value: t.string
                })
              )
            }),

            addressDetails: Maybe(
              t.type({
                ADDRESS: Maybe(
                  t.type({
                    line1: Maybe(t.string),
                    city: Maybe(t.string),
                    district: Maybe(t.string),
                    subDistrict: Maybe(t.string),
                    countryCode: Maybe(t.string)
                  })
                ),
                FOREIGN_ADDRESS: Maybe(
                  t.type({
                    line1: Maybe(t.string),
                    countryCode: Maybe(t.string)
                  })
                )
              })
            ),

            attributesExt: Maybe(
              t.type({
                NATIONALITY_2: Maybe(t.string),
                nationality2Name: Maybe(t.string),
                countryName: Maybe(t.string),
                districtName: Maybe(t.string),
                wardName: Maybe(t.string),
                existAttachments: Maybe(t.boolean)
              })
            )
          })
        })
      )
    })
  })
)

const Ekiosk = Base(
  t.intersection([
    t.type({
      source: t.literal(SourceType.EKIOSK)
    }),
    ZaloEkiosk
  ])
)

const Banca = Base(
  t.type({
    source: t.literal(SourceType.BANCA)
  })
)

const PruGreat = Base(
  t.type({
    source: t.literal(SourceType.PRUGREAT)
  })
)

const PruOnline = Base(
  t.type({
    source: t.literal(SourceType.PRUONLINE),
    payload: t.type({
      body: t.array(
        t.type({
          percentage: t.number,
          relationshipType: t.string,
          customer: t.type({
            firstName: Maybe(t.string),
            surName: Maybe(t.string),
            clientId: Maybe(t.string),
            dob: Maybe(t.string),
            sex: Maybe(t.string),
            nationality: Maybe(t.string),
            occupation: Maybe(t.string),
            externalIds: Maybe(
              t.type({
                SOE_VALUE: Maybe(t.string),
                SOE: Maybe(t.string),
                ISSUED_BY: Maybe(t.string)
              })
            ),
            contactDetails: t.type({
              EMAIL: t.type({
                value: t.string
              }),
              PHONE: t.type({
                countryCode: t.string,
                value: t.string
              })
            }),
            addressDetails: Maybe(
              t.type({
                ADDRESS: Maybe(
                  t.type({
                    line1: Maybe(t.string),
                    city: Maybe(t.string),
                    district: Maybe(t.string),
                    subDistrict: Maybe(t.string),
                    countryCode: Maybe(t.string)
                  })
                ),
                FOREIGN_ADDRESS: Maybe(
                  t.type({
                    line1: Maybe(t.string),
                    countryCode: Maybe(t.string)
                  })
                )
              })
            ),
            attributesExt: Maybe(
              t.type({
                NATIONALITY_2: Maybe(t.string),
                nationality2Name: Maybe(t.string),
                countryName: Maybe(t.string),
                districtName: Maybe(t.string),
                wardName: Maybe(t.string)
              })
            )
          })
        })
      )
    })
  })
)
export const ChangeBeneficialInfo = t.union([PruOnline, Pulse, Pulse4Ops, Zalo, Banca, Ekiosk, PruGreat])
export type ChangeBeneficialInfo = t.TypeOf<typeof ChangeBeneficialInfo>
