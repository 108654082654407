import React, { useState } from 'react'
import { FlatList, TouchableOpacity, View, Text, StyleSheet } from 'react-native'
import {
  AppContext,
  ErrorHandling,
  GeneralInfoService,
  Permission,
  TabList,
  useMobile,
  Tab,
  CepPermission
} from '@pulseops/common'
import { FontAwesome5 } from '@expo/vector-icons'
import { StackNavigationProp } from '@react-navigation/stack'
import { useTranslation } from 'react-i18next'
import { AgentInfoData, CepAgentScreen } from './CepAgentScreen'
import { CepPolicyInfoScreen } from './CepPolicyScreen'
import { CustomerInfoData, CepCustomerScreen } from './CepCustomerScreen'
import { InvestmentInfoData, CepInvestmentScreen } from './CepInvestmentScreen'
import { CepPolicyValueScreen } from './CepPolicyValueScreen'
import { ProductInfoData, CepProductScreen } from './CepProductScreen'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { IFQuotationData, IFQuotationScreen } from './CepIFQuotation'
import { InquiryStackParamList } from '../../InquiryStackParamList'
import { CepStateInfo } from '../../state'
import { CepNBUWScreen } from './CepNBUWScreen'
import _ from 'lodash'
import { CepPremiumScreen } from './CepPremiumScreen'
import { CepPaymentScreen } from './CepPaymentScreen'
import { CepPaymentModal } from './CepPaymentModal'
import { CepPruOnlinecreen, PruOnlineInfoData } from './CepPruOnlineScreen'
import { CepPruReWardscreen } from './CepPruReWardScreen'
import { useIsFocused } from '@react-navigation/native'
import {
  BonusHistoryItem,
  IDuplicationPhone,
  IProductDetail,
  NbuwInfoData,
  PolicyInfoData,
  PolicyValueInfoData,
  PremiumHistoryInfo,
  PruReWardInformation,
  TransactionHistoryRes
} from '@pulseops/inquiry'
import { PaymentItemList } from '../../cep-api-services/CepPaymentModels'

export const GeneralInfoScreen = (props: {
  navigation: StackNavigationProp<InquiryStackParamList, 'CEPPolicyDetailScreen'>
  policyNum: string
  roles: string[]
  isGaStaff?: boolean
  messageState: CepStateInfo.MessageType
  clientNumber: string
  proposalNo: string
}) => {
  const { isMobile, isWide } = useMobile()
  const { t } = useTranslation()

  const { showToast } = React.useContext(AppContext.AppContextInstance)

  const [subTabIndex, setSubTabIndex] = React.useState(0)
  const [policyInfo, setPolicyInfo] = useState<PolicyInfoData | null>(null)
  const [customerInfo, setCustomerInfo] = useState<CustomerInfoData | null>(null)
  const [productInfo, setProductInfo] = useState<ProductInfoData | null>(null)
  const [pvInfo, setPVInfo] = useState<PolicyValueInfoData | null>(null)
  const [investInfo, setInvestInfo] = useState<InvestmentInfoData | null>(null)
  const [agentInfo, setAgentInfo] = useState<AgentInfoData | null>(null)
  const [IFQuotationInfo, setIFQuotationInfo] = useState<IFQuotationData | null>(null)
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const [productDetail, setProductDetail] = React.useState<IProductDetail | null>(null)
  const [premiumInfo, setPremiumInfo] = React.useState<PremiumHistoryInfo | null>(null)
  const [paymentNo, setPaymentNo] = React.useState<number>(0)
  const [transInfoHistories, setTransInfoHistories] = React.useState<TransactionHistoryRes | null>(null)
  const [pruOnlineInfo, setPruOnlineInfo] = useState<PruOnlineInfoData | null>(null)
  const [pruReWardInfo, setPruReWardInfo] = useState<PruReWardInformation | null>(null)
  const [duplicationPhoneInfo, setDuplicationPhoneInfo] = useState<Array<IDuplicationPhone> | null>(null)
  const [nbuwData, setNbuwData] = useState<NbuwInfoData | null>(null)
  const [bonusHistory, setBonusHistory] = useState<Array<BonusHistoryItem> | null>(null)
  const [paymentInfo, setPaymentInfo] = useState<PaymentItemList | null>(null)

  const isFocused = useIsFocused()
  const generalInfos = [
    {
      id: 0,
      title: t('Tab:PolicyInfo'),
      permission: props.isGaStaff
        ? CepPermission.ViewPolicyInfoGAPolicyDetail
        : CepPermission.ViewPolicyInfoStaffPolicyDetail
    },
    {
      id: 1,
      title: t('Tab:CustomerInfo'),
      permission: props.isGaStaff
        ? CepPermission.ViewCustomerInfoGAPolicyDetail
        : CepPermission.ViewCustomerInfoStaffPolicyDetail
    },
    {
      id: 2,
      title: t('Tab:ProductInfo'),
      permission: props.isGaStaff
        ? CepPermission.ViewProductInfoGAPolicyDetail
        : CepPermission.ViewProductInfoStaffPolicyDetail
    },
    {
      id: 3,
      title: t('Tab:PolicyValueInfo'),
      permission: props.isGaStaff
        ? CepPermission.ViewPolicyValueInfoGAPolicyDetail
        : CepPermission.ViewPolicyValueInfoStaffPolicyDetail
    },
    {
      id: 4,
      title: t('Tab:InvestmentInfo'),
      permission: props.isGaStaff
        ? CepPermission.ViewInvestmentInfoGAPolicyDetail
        : CepPermission.ViewInvestmentInfoStaffPolicyDetail
    },
    {
      id: 5,
      title: t('CustomerEngagement:Premium'),
      permission: props.isGaStaff
        ? CepPermission.ViewPremiumInfoGAPolicyDetail
        : CepPermission.ViewPremiumInfoStaffPolicyDetail
    },
    {
      id: 6,
      title: t('CustomerEngagement:Payment'),
      permission: props.isGaStaff
        ? CepPermission.ViewPaymentInfoGAPolicyDetail
        : CepPermission.ViewPaymentInfoStaffPolicyDetail
    },
    {
      id: 7,
      title: t('Tab:AgentInfo'),
      permission: props.isGaStaff
        ? CepPermission.ViewAgentInfoGAPolicyDetail
        : CepPermission.ViewAgentInfoStaffPolicyDetail
    },
    {
      id: 8,
      title: t('PruOnline'),
      permission: props.isGaStaff
        ? CepPermission.ViewPruOnlineInfoGAPolicyDetail
        : CepPermission.ViewPruOnlineInfoStaffPolicyDetail
    },

    {
      id: 9,
      title: t('PruRewards'),
      permission: props.isGaStaff
      ? CepPermission.View360PruRewardsGACSCCS
        :  (
          props.roles.includes(Permission.InquiryStaff) ?
          CepPermission.ViewPruRewardsInfoPolicyDetail :
          CepPermission.View360PruRewardsGACSCCS
        )
    },

    {
      id: 10,
      title: t('NBUW'),
      permission: props.isGaStaff
        ? CepPermission.ViewNBUWInfoGAPolicyDetail
        : CepPermission.ViewNBUWInfoStaffPolicyDetail
    }
  ]

  React.useEffect(() => {
    if (isFocused) {
      setSubTabIndex(0)
      setPolicyInfo(null)
      setCustomerInfo(null)
      setProductInfo(null)
      setPVInfo(null)
      setInvestInfo(null)
      setPremiumInfo(null)
      setTransInfoHistories(null)
      setProductDetail(null)
      setNbuwData(null)
    }
  }, [isFocused])

  const getTabs = () => {
    if (props.roles.length > 0) {
      const visibleTabs: Tab[] = generalInfos.filter((item) => props.roles.includes(item.permission))

      if (!props.isGaStaff && props.roles.includes(Permission.ViewIFQuotationPolicyDetail)) {
        visibleTabs.push({
          id: 11,
          title: t('Tab:IFQuotation')
        })
      }

      const tabList = _.orderBy(visibleTabs, 'id', 'asc')

      if (tabList.length > 0 && tabList.find((item) => item.id === subTabIndex) === undefined) {
        setSubTabIndex(tabList[0].id)
      }

      return tabList
    }

    return []
  }


  const isNotIFQuotation = pipe(
    props.isGaStaff ? ZIO.succeed(true) : GeneralInfoService.checkIFQuotationRule(props.policyNum),
    ErrorHandling.runDidMount()
  )

  const accept = (roles: string) => {
    return (
      (roles === 'PLI' &&
        (props.roles.includes(Permission.ViewPolicyInfoStaffPolicyDetail) ||
          props.roles.includes(Permission.ViewPolicyInfoGAPolicyDetail))) ||
      (roles === 'CI' &&
        (props.roles.includes(Permission.ViewCustomerInfoStaffPolicyDetail) ||
          props.roles.includes(Permission.ViewCustomerInfoGAPolicyDetail))) ||
      (roles === 'PDI' &&
        (props.roles.includes(Permission.ViewProductInfoStaffPolicyDetail) ||
          props.roles.includes(Permission.ViewProductInfoGAPolicyDetail))) ||
      (roles === 'PVI' &&
        (props.roles.includes(Permission.ViewPolicyValueInfoStaffPolicyDetail) ||
          props.roles.includes(Permission.ViewPolicyValueInfoGAPolicyDetail))) ||
      (roles === 'II' &&
        (props.roles.includes(Permission.ViewInvestmentInfoStaffPolicyDetail) ||
          props.roles.includes(Permission.ViewInvestmentInfoGAPolicyDetail))) ||
      (roles === 'AI' &&
        (props.roles.includes(Permission.ViewAgentInfoStaffPolicyDetail) ||
          props.roles.includes(Permission.ViewAgentInfoGAPolicyDetail))) ||
      (roles === 'IFQ' && props.roles.includes(Permission.ViewIFQuotationPolicyDetail)) ||
      (roles === 'PRW' && (props.roles.includes(Permission.ViewPruRewardsInfoPolicyDetail) ||
          props.roles.includes(Permission.View360PruRewardsGACSCCS))) ||
      (roles === 'PRO' &&
        (props.roles.includes(Permission.ViewPruOnlineInfoStaffPolicyDetail) ||
          props.roles.includes(Permission.ViewPruOnlineInfoGAPolicyDetail))) ||
      (roles === 'PM' &&
        (props.roles.includes(Permission.ViewPremiumInfoStaffPolicyDetail) ||
          props.roles.includes(Permission.ViewPremiumInfoGAPolicyDetail))) ||
      (roles === 'NBUW' &&
        (props.roles.includes(Permission.ViewNBUWInfoStaffPolicyDetail) ||
          props.roles.includes(Permission.ViewNBUWInfoGAPolicyDetail))) ||
      (roles === 'PAY' &&
        (props.roles.includes(Permission.ViewPaymentInfoStaffPolicyDetail) ||
          props.roles.includes(Permission.ViewPaymentInfoGAPolicyDetail)))
    )
  }

  const GeneralInfoItem = ({
    tabId,
    title,
    onNavigation
  }: {
    tabId: number
    title: string
    onNavigation: (tabId: number) => void
  }) => {
    return (
      <TouchableOpacity onPress={() => onNavigation(tabId)}>
        <View style={styles.generalInfoItem}>
          <Text style={styles.generalInfoItemText}>{title}</Text>
          <FontAwesome5 name="angle-right" size={20} color="#B0B0B0" />
        </View>
      </TouchableOpacity>
    )
  }

  const onChangePage = (tabId: number) => {
    switch (tabId) {
      case 0:
        props.navigation.navigate('PolicyScreen')
        break
      case 1:
        props.navigation.navigate('CustomerScreen')
        break
      case 2:
        props.navigation.navigate('ProductScreen')
        break
      case 3:
        props.navigation.navigate('PolicyValueScreen')
        break
      default:
        break
    }
  }

  const onChangeSubTab = (tabIdx: number) => {
    // check tabIdx from component parent

    if (tabIdx === 11 && !isNotIFQuotation) {
      showToast(t('message:MS990022'), 'error')
    }
    setSubTabIndex(tabIdx)
  }

  return (
    <View style={styles.tabContainer}>
      {isMobile ? (
        <>
          <FlatList
            data={getTabs()}
            renderItem={({ item }) => (
              <GeneralInfoItem tabId={item.id} onNavigation={onChangePage} title={item.title} />
            )}
            keyExtractor={(item) => item.id.toString()}
          />
        </>
      ) : (
        <TabList
          gutterBottom
          isSubTab={true}
          useOriginalId={true}
          menus={getTabs()}
          tabIndex={subTabIndex}
          onChangeTab={onChangeSubTab}
        />
      )}
      <View style={{ flex: 1 }}>
        {accept('PLI') && subTabIndex === 0 && isWide && props.policyNum && (
          <CepPolicyInfoScreen
            id={props.policyNum}
            data={policyInfo}
            setData={setPolicyInfo}
            messageState={props.messageState ?? {}}
            transHistory={transInfoHistories}
            setTransHistory={setTransInfoHistories}
          />
        )}
        {accept('CI') && subTabIndex === 1 && isWide && props.policyNum && (
          <CepCustomerScreen
            id={props.policyNum}
            data={customerInfo}
            setData={setCustomerInfo}
            clientNumber={props.clientNumber}
            duplicationPhoneData={duplicationPhoneInfo}
            setDuplicationPhone={setDuplicationPhoneInfo}
          />
        )}
        {accept('PDI') && subTabIndex === 2 && isWide && props.policyNum && (
          <CepProductScreen id={props.policyNum} data={productInfo} setData={setProductInfo} />
        )}
        {accept('PVI') && subTabIndex === 3 && isWide && props.policyNum && (
          <CepPolicyValueScreen
            id={props.policyNum}
            data={pvInfo}
            setData={setPVInfo}
            productTypeInfo={productDetail}
            setProductTypeInfo={setProductDetail}
            bonusHistoryInfo={bonusHistory}
            setBonusHistoryInfo={setBonusHistory}
          />
        )}
        {accept('II') && subTabIndex === 4 && isWide && props.policyNum && (
          <CepInvestmentScreen
            id={props.policyNum}
            data={investInfo}
            setData={setInvestInfo}
            productTypeInfo={productDetail}
            setProductTypeInfo={setProductDetail}
          />
        )}
        {accept('PM') && subTabIndex === 5 && isWide && props.policyNum && (
          <CepPremiumScreen id={props.policyNum} data={premiumInfo} setData={setPremiumInfo} isGA={props?.isGaStaff ?? false} clientNumber={props.clientNumber}/>
        )}
        {accept('PAY') && subTabIndex === 6 && isWide && props.policyNum && (
          <CepPaymentScreen
            id={props.policyNum}
            data={paymentInfo}
            setData={setPaymentInfo}
            setOpenPayment={setIsOpen}
          />
        )}
        {accept('AI') && subTabIndex === 7 && isWide && props.policyNum && (
          <CepAgentScreen id={props.policyNum} data={agentInfo} setData={setAgentInfo} />
        )}
        {accept('PRO') && subTabIndex === 8 && isWide && props.policyNum && (
          <CepPruOnlinecreen id={props.policyNum} data={pruOnlineInfo} setData={setPruOnlineInfo} isGA={props?.isGaStaff} />
        )}
        {accept('PRW') && subTabIndex === 9 && isWide && props.policyNum && (
          <CepPruReWardscreen
            id={props.policyNum}
            data={pruReWardInfo}
            setData={setPruReWardInfo}
            customerData={customerInfo}
            setCustomerData={setCustomerInfo}
            clientNumber={props.clientNumber}
          />
        )}
        {accept('NBUW') && subTabIndex === 10 && isWide && props.policyNum && (
          <CepNBUWScreen id={props.policyNum} data={nbuwData} setData={setNbuwData} proposalNo={props.proposalNo} isGA={props?.isGaStaff ?? false}/>
        )}
        {accept('IFQ') && isNotIFQuotation && subTabIndex === 11 && isWide && props.policyNum && (
          <IFQuotationScreen
            id={props.policyNum}
            data={IFQuotationInfo}
            setData={setIFQuotationInfo}
            editPermissionFlag={props.roles.includes(Permission.Edit_IFQuotation_PD)}
          />
        )}
      </View>
      {/* <CepPaymentModal
        visible={isOpen}
        onPressClose={() => {
          setIsOpen(false)
        }}
      /> */}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    marginHorizontal: 'auto',
    justifyContent: 'center'
  },
  // Tab ++
  tabContainer: {
    marginHorizontal: 15,
    marginBottom: 15,
    flex: 1
  },
  // Tab --
  bold: {
    fontWeight: 'bold'
  },
  sectionTitle: {
    fontSize: 15,
    fontWeight: '800'
  },
  // General Info Item
  generalInfoItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 55,
    width: '100%',
    borderRadius: 8,
    backgroundColor: '#FFFFFF',
    paddingHorizontal: 22,
    marginBottom: 12
  },
  generalInfoItemText: {
    fontSize: 16,
    lineHeight: 28
  },
  relative: {
    position: 'relative'
  },
  absolute: {
    position: 'absolute'
  },
  sectionStatus: {
    flexDirection: 'row',
    width: '100%',
    marginBottom: 16
  },
  sectionStatusTag: {
    backgroundColor: '#1EA5FC',
    borderRadius: 4,
    paddingVertical: 1,
    paddingHorizontal: 4,
    marginRight: 16
  },
  sectionStatusTagText: {
    color: '#FFFFFF',
    fontSize: 13,
    lineHeight: 17,
    fontWeight: 'bold'
  },
  dangerText: {
    color: '#ED1B2E',
    fontSize: 13,
    lineHeight: 14,
    marginRight: 30
  },
  strongText: {
    fontSize: 15,
    lineHeight: 14,
    fontWeight: '600',
    marginLeft: 10
  },
  sectionInfoContentRow: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  sectionInfoContentCol: {
    width: '33.33333333333%',
    marginBottom: 32
  },
  sectionInfoContentLabel: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  },
  sectionInfoContentValue: {
    color: '#000000',
    fontSize: 15,
    lineHeight: 22
  },
  footer: {
    flex: 1,
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    flexDirection: 'row',
    backgroundColor: '#f9f9f9',
    height: 70,
    shadowOffset: {
      width: 0,
      height: -5
    },
    shadowColor: '#e6e6e6',
    shadowOpacity: 15,
    shadowRadius: 24,
    alignItems: 'center'
  },
  textButton: { textAlign: 'center', color: '#ED1B2E', fontWeight: 'bold' },
  button: {
    marginLeft: 20,
    marginVertical: 20,
    justifyContent: 'center',
    backgroundColor: '#fff',
    borderRadius: 100,
    borderWidth: 1,
    borderColor: '#ED1B2E',
    minWidth: 121,
    height: 35,
    paddingHorizontal: 30
  }
})
