import { useMobile, Checkbox } from '@pulseops/common'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { TouchableOpacity, View, Text, StyleSheet, ActivityIndicator, ViewStyle } from 'react-native'

interface Props {
  listAction: {
    name: string
    action: () => void
    isLoading?: boolean
    isVisible?: boolean
    isSubmissionDisabled?: boolean
    isHighLightBtn?: boolean
  }[]
  containerStyle?: ViewStyle
}
export const FooterAction = ({ listAction, containerStyle }: Props) => {
  const { isWide } = useMobile()
  const { t } = useTranslation()
  return (
    <>
      {isWide && (
        <View style={[styles.footer, containerStyle]}>
          {listAction?.map((res, i) =>
            res.isVisible === false ? null : (
              <View key={`action-${i}`}>
                <TouchableOpacity
                  style={[
                    styles.button,
                    res.isSubmissionDisabled && styles.btnDisabled,
                    res.isHighLightBtn && styles.highLightBtn
                  ]}
                  onPress={res.action}
                  disabled={res.isLoading || res.isSubmissionDisabled}
                >
                  <View style={styles.loadingWrapper}>
                    {res.isLoading && <ActivityIndicator style={{ marginEnd: 13 }} color="#ED1B2E" />}
                    <Text
                      style={[
                        styles.textButton,
                        res.isSubmissionDisabled && styles.btnTextDisabled,
                        res.isHighLightBtn && styles.highLightText
                      ]}
                    >
                      {res.name}
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
            )
          )}
        </View>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    position: 'relative',
    flex: 1
  },
  loadingWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  footer: {
    flexDirection: 'row',
    backgroundColor: '#f9f9f9',
    height: 60,
    width: '100%',
    shadowOffset: {
      width: 0,
      height: -5
    },
    shadowColor: '#e6e6e6',
    shadowOpacity: 15,
    shadowRadius: 24,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 0
  },
  textButton: { textAlign: 'center', color: '#ED1B2E', fontWeight: 'bold' },
  button: {
    marginLeft: 20,
    marginVertical: 20,
    justifyContent: 'center',
    backgroundColor: '#fff',
    borderRadius: 100,
    borderWidth: 1,
    borderColor: '#ED1B2E',
    minWidth: 121,
    height: 35,
    paddingHorizontal: 30
  },
  highLightBtn: {
    backgroundColor: '#ed1b2e',
    color: '#fff'
  },
  highLightText: {
    color: '#fff',
    fontWeight: 'normal'
  },
  btnTextDisabled: { textAlign: 'center', color: '#B0B0B0', fontWeight: 'bold' },
  btnDisabled: {
    marginLeft: 20,
    marginVertical: 20,
    justifyContent: 'center',
    backgroundColor: '#FFF',
    borderRadius: 100,
    borderWidth: 1,
    borderColor: '#B0B0B0',
    minWidth: 121,
    height: 35,
    paddingHorizontal: 30
  }
})
