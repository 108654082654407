import { SelectOption } from '@pulseops/common'

export interface TextCustomI {
  color?: string
  textColor?: string
  fontSize?: string
  fontWeight?: string
  fontStyle?: string
}

export interface FormGroupSearchT6I {
  type: 'suspendBy' | 'suspendCode' | 'status'
  required: boolean
  label: string
  options: SelectOption[]
  placeholder: string
  popupIcon: any
}

export interface T6SearchFormData {
  suspendBy: SelectOption[] | undefined
  suspendCode: SelectOption | undefined
  status: SelectOption | undefined
}

export type FormModalData = {
  suspendBy: SelectOption[] | undefined
  suspendGroupEN: string
  suspendGroupCode: string
  suspendGroupVN: string
  suspendCode: string
  suspendTypeEN: string
  suspendTypeVN: string
  description: string
  docType: string
  doctypeCode: string
  department: SelectOption | undefined
  departmentShortName: string
  timeOut: string
  autoReject: SelectOption | undefined
  status: SelectOption | undefined
  sendCommunication: string
}

const formsGroupModal = [
  'suspendBy',
  'suspendGroupEN',
  'suspendGroupCode',
  'suspendGroupVN',
  'suspendCode',
  'suspendTypeEN',
  'suspendTypeVN',
  'description',
  'docType',
  'doctypeCode',
  'department',
  'departmentShortName',
  'timeOut',
  'autoReject',
  'status',
  'sendCommunication'
] as const

export type FormsModalType = typeof formsGroupModal[number]

export enum Mode_View {
  ADD = 'ADD_VIEW',
  EDIT = 'EDIT_VIEW'
}

export enum Actions {
  SEARCH = 'SEARCH',
  ADD_ROW = 'ADD_ROW',
  EDIT_ROW = 'EDIT_ROW',
  DELETE_ROW = 'DELETE_ROW',
  ADD = 'ADD',
  SAVE = 'SAVE',
  REFRESH = 'REFRESH'
}

export enum Field {
  TEXT = 'TEXT',
  DROPDOWN_LIST = 'DROPDOWN_LIST',
  RADIO = 'RADIO'
}

export type GroupType = {
  typeField: typeField
  type: FormsModalType
  required: boolean
  editable: boolean
  placeholder: string
  label: string
  options?: SelectOption[]
}
export interface ModalFormsAddAndEdit {
  groupName: 'col_1' | 'col_2' | 'col_3'
  group: Array<GroupType>
  isVisible: boolean
}

export type modeViewType = `${Mode_View}`
export type actionsType = `${Actions}`
export type typeField = `${Field}`
