import { ErrorHandling, FieldList, GeneralService, Label, Panel, TaskDetail } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { DuplicateInfoItem, DuplicateInfoTable } from './common'

export const Assignment = ({ detail }: { detail: TaskDetail.AssignmentModel }) => {
  const { t, i18n } = useTranslation()

  const relationshipT16s1 = pipe(GeneralService.getRelationship(), ErrorHandling.runDidMount()) || []
  const nationalityOptions = pipe(GeneralService.getCountries, ErrorHandling.runDidMount()) || []

  const smsIndicators = [
    { label: t('submission:SMSIndicatorOptions.PolInfo'), value: '1' },
    { label: t('submission:SMSIndicatorOptions.Pol&PruInfo'), value: '3' },
    { label: t('submission:SMSIndicatorOptions.RefuseSMS'), value: '4' }
  ]

  const emailIndicators = [
    { label: t('submission:EmailIndicatorOptions.PolInfo'), value: '1' },
    { label: t('submission:EmailIndicatorOptions.Pol&PruInfo'), value: '3' },
    { label: t('submission:EmailIndicatorOptions.RefuseEmail'), value: '4' }
  ]

  const getRelationshipFromT16s1 = (code: string) => {
    const found = relationshipT16s1.find((item) => item.code === code)
    return {
      relationshipEN: found?.nameEn || '',
      relationshipVI: found?.nameVn || ''
    }
  }

  const translate = (en: string, vi: string) => (i18n.language === 'en' ? en : vi)

  const { body } = detail.detail.payload
  const { attributesExt, contactDetails, addressDetails } = body
  const { PHONE, EMAIL } = contactDetails
  const { ADDRESS, FOREIGN_ADDRESS } = addressDetails
  const newPolicyOwner = attributesExt.newPolicyOwner === 'Other' ? t('common:Other') : attributesExt.newPolicyOwner

  const relationshipWithMainLA = pipe(getRelationshipFromT16s1(attributesExt.RELATIONSHIP_1), (val) =>
    translate(val.relationshipEN, val.relationshipVI)
  )

  const Gender = body.sex === 'M' ? t('submission:Male') : t('submission:Female')

  const fields1: FieldList.DataSource[] = [
    { label: t('common:NewPolicyOwner'), value: newPolicyOwner },
    { label: t('submission:NationalID'), value: attributesExt.ID_NUMBER ?? '-' },
    { label: t('contact:MLA.Relationship'), value: relationshipWithMainLA },

    { label: t('contact:Surname'), value: body.surName ?? '-' },
    { label: t('contact:GivenName'), value: body.firstName ?? '-' },
    {
      label: t('contact:usTax'),
      value: attributesExt.IS_FATCA ?? '-',
      fieldType: 'radio',
      radioOptions: [
        { label: t('common:Yes'), id: 'Y' },
        { label: t('common:No'), id: 'N' }
      ]
    },
    {
      label: t('contact:DOB'),
      value: body.dob ? attributesExt.dobFormated : '-'
    },
    {
      label: t('contact:Gender'),
      value: Gender
    },
    { label: t('contact:Nationality'), value: attributesExt.nationality1Name },

    { label: t('requestInfo:MobilePhone'), value: `+${PHONE.countryCode} ${PHONE.value}` },
    { label: t('contact:Email'), value: EMAIL.value ?? '-' },
    { label: t('contact:Occupation'), value: attributesExt.occupationName },
    {
      label: t('submission:SMSIndicator'),
      value: smsIndicators.find((n) => n.value === body.smsIndicator)?.label ?? '_'
    },
    {
      label: t('submission:EmailIndicator'),
      value: emailIndicators.find((n) => n.value === body.emailIndicator)?.label ?? '_'
    }
  ]

  const fields2 = [
    { label: t('contact:Country'), value: attributesExt.countryName ?? '-' },
    { label: t('contact:CityProvince'), value: ADDRESS.city ?? '-' },
    { label: t('contact:District'), value: ADDRESS.district ?? '-' },

    { label: t('contact:WardSubDistrict'), value: ADDRESS.subDistrict ?? '-' },
    { label: t('submission:Street'), value: ADDRESS.line1 ?? '-', colLength: '66.6666666667%' },

    {
      label: t('contact:ForeignAddress'),
      value: FOREIGN_ADDRESS.line1 ?? '-',
      colLength: '66.6666666667%'
    },
    {
      label: t('submission:CountryOfForeignAddress'),
      value: nationalityOptions.find((n) => n.code === body?.foreignCountry)?.name ?? '-'
    },
    { label: t('submission:TaxResidencyCountry'), value: nationalityOptions.find((n) => n.code === attributesExt?.taxResidencyCountry)?.name ?? '-' },
    { label: t('contact:Nationality') + ' 2', value: attributesExt.nationality2Name },
    { label: '', value: '' }
  ]

  attributesExt.RELATIONSHIP_2 &&
    fields2.push({
      label: t('contact:RelationshipWithLAShort') + ` ${attributesExt.CLIENT_NAME_2}`,
      value: pipe(
        getRelationshipFromT16s1(attributesExt.RELATIONSHIP_2),
        (val) => translate(val.relationshipEN, val.relationshipVI) || '-'
      )
    })
  attributesExt.RELATIONSHIP_3 &&
    fields2.push({
      label: t('contact:RelationshipWithLAShort') + ` ${attributesExt.CLIENT_NAME_3}`,
      value: pipe(
        getRelationshipFromT16s1(attributesExt.RELATIONSHIP_3),
        (val) => translate(val.relationshipEN, val.relationshipVI) || '-'
      )
    })
  attributesExt.RELATIONSHIP_4 &&
    fields2.push({
      label: t('contact:RelationshipWithLAShort') + ` ${attributesExt.CLIENT_NAME_4}`,
      value: pipe(
        getRelationshipFromT16s1(attributesExt.RELATIONSHIP_4),
        (val) => translate(val.relationshipEN, val.relationshipVI) || '-'
      )
    })

  const dupPhone: DuplicateInfoItem[] =
    attributesExt.DUPLICATE_PHONES?.map(({ CLIENT_NUM, CLIENT_NAME, RELATIONSHIP }) => ({
      clientNumber: CLIENT_NUM,
      clientName: CLIENT_NAME,
      relationship: pipe(getRelationshipFromT16s1(RELATIONSHIP || ''), (val) => ({
        en: val.relationshipEN,
        vi: val.relationshipVI
      }))
    })) ?? []

  const dupEmail: DuplicateInfoItem[] =
    attributesExt.DUPLICATE_EMAILS?.map(({ CLIENT_NUM, CLIENT_NAME, RELATIONSHIP }) => ({
      clientNumber: CLIENT_NUM,
      clientName: CLIENT_NAME,
      relationship: pipe(getRelationshipFromT16s1(RELATIONSHIP || ''), (val) => ({
        en: val.relationshipEN,
        vi: val.relationshipVI
      }))
    })) ?? []

  return (
    <View>
      <Panel titleOutline={t('requestInfo:AssignmentInformation')} isExand={false} allowToggle={false}>
        <FieldList dataSource={fields1} />
        {dupPhone.length > 0 && (
          <View style={{ marginBottom: 20 }}>
            <Label title={t('requestInfo:dupPhone')} />
            <View style={{ marginTop: 6 }}>
              <DuplicateInfoTable data={dupPhone} />
            </View>
          </View>
        )}
        {dupEmail.length > 0 && (
          <View style={{ marginBottom: 20 }}>
            <Label title={t('requestInfo:dupEmail')} />
            <View style={{ marginTop: 6 }}>
              <DuplicateInfoTable data={dupEmail} />
            </View>
          </View>
        )}
        <FieldList dataSource={fields2} />
      </Panel>
    </View>
  )
}
