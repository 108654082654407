import { ZIO } from '@mxt/zio'
import moment from 'moment'
import {
  AppConfig,
  AppContext,
  changeLanguage,
  Checkbox,
  ErrorHandling,
  form2,
  PulseOpsFormat,
  useMobile,
  assets as assetsCommon,
  SelectOption
} from '@pulseops/common'
import { StackScreenProps } from '@react-navigation/stack'
import { pipe } from 'fp-ts/function'
import * as t from 'io-ts'
import * as React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import ReCAPTCHA from 'react-google-recaptcha'
import {
  SafeAreaView,
  ImageBackground,
  Pressable,
  Linking,
  ScrollView,
  View,
  StyleSheet,
  ActivityIndicator,
  Modal,
  Text
} from 'react-native'
import { assets, BorderFilledInput, Button, ButtonModal, SC, SC as SCore, Services } from './core'
import { LandingPageBankInfoStackParamList } from './LandingPageBankStackParamList'
import { UniversalLink } from './const'
import { Header } from './Header'
import { withMinStringLength, withMaxStringLength } from './validators'
import OtpInput from 'react-otp-input'
import styled from 'styled-components'
import { useLoading } from '@mxt/zio-react'
import _ from 'lodash'
import { DateFromISOString, NonEmptyString } from 'io-ts-types'
import { Maybe } from '@mxt/zio/codec'

const FormC = t.type({
  bankAccount: pipe(
    form2.string.requiredM(() => i18next.t('message:MS020001', { field: i18next.t('landingPage:BankAccount') }))
  ),
  // BankName: pipe(
  //   form2.string.requiredM(() => i18next.t('message:MS020001', { field: i18next.t('landingPage:BankName') })),
  //   withMaxStringLength(210, 'Vui lòng nhập tối đa 210 ký tự.')
  // ),
  phoneNumber: pipe(
    form2.string.requiredM(() => i18next.t('message:MS020001', { field: i18next.t('landingPage:phoneNumber') })),
    withMinStringLength(10, 'Vui lòng nhập số điện thoại 10 ký số.'),
    withMaxStringLength(10, 'Vui lòng nhập số số điện thoại 10 ký số.')
  ),
  policyNum: pipe(
    form2.string.requiredM(() => i18next.t('message:MS020001', { field: i18next.t('landingPage:policyNumber') })),
    withMinStringLength(9, 'Vui lòng nhập số khoản vay 9 ký số.'),
    withMaxStringLength(9, 'Vui lòng nhập số khoản vay 9 ký số.')
  ),
  fullName: pipe(
    form2.string.requiredM(() => i18next.t('message:MS020001', { field: i18next.t('landingPage:fullName') }))
  ),
  policies: Maybe(
    t.array(
      t.type({
        phoneNumber: t.string,
        policyNumber: t.string,
        createdDate: t.string,
        createdBy: t.string,
        updatedBy: t.string,
        updatedDate: t.string,
        status: t.string,
        checked: t.boolean
      })
    )
  ),
  BankName: form2.selectOption.requiredM(() =>
    i18next.t('message:MS020001', { field: i18next.t('landingPage:BankName') })
  ),
  captchaValue: form2.string.optional
})

interface Policy {
  phoneNumber: string
  policyNumber: string
  createdDate: string
  createdBy: string
  updatedBy: string
  updatedDate: string
  status: string
  checked: boolean
}
type Props = StackScreenProps<LandingPageBankInfoStackParamList, 'HomeScreen'>

const YESTERDAY = PulseOpsFormat.getYesterday()
const regex =
  /^[a-zA-ZÀÁÂÃÈÉÊẾÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêếìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵýỷỹ]+$/
const TIMEOUT = 180
const RESEND_TIME = 2
const TIME_BLOCK_OTP = '30 phút'
const TIME_BLOCK_VERIFY = '8h'
export const HomeScreen = (props: Props) => {
  const { t } = useTranslation('landingPage')
  const { t: m } = useTranslation('message')

  const { isMobile, isWide, width } = useMobile()

  const [loading, setLoading] = React.useState(false)
  const [otp, setOtp] = React.useState<string>('')
  const [time, setTime] = React.useState<number>(TIMEOUT)
  const [errorOtp, setErrorOtp] = React.useState<string | null>(null)
  const [disableOTP, setDisableOtp] = React.useState<boolean>(false)
  const [submitting, bindSubmitting] = useLoading(false)
  const { showGlobalLoading, showToast } = React.useContext(AppContext.AppContextInstance)
  const [otpTime, setOtpTime] = React.useState<number>(0)
  const [visible, setIsVisible] = React.useState<boolean>(false)
  const [policyNum, setPolicyNum] = React.useState<string>('')
  const [validateStep, setValidate] = React.useState<boolean>(false)
  const [open, setOpen] = React.useState<boolean>(false)
  const [showPolicies, setShowPolicies] = React.useState<boolean>(false)
  const [step2, setStep2] = React.useState<boolean>(false)
  const [bank, setBank] = React.useState<Array<SelectOption> | null>(null)
  const reCaptchaKey = pipe(
    AppConfig.get,
    ZIO.map((_) => _.reCaptchaKey),
    ErrorHandling.runDidMount()
  )

  const {
    base: {
      control,
      formState: { errors },
      setValue,
      watch,
      reset
    },
    handleSubmit
  } = form2.useForm(FormC)

  const onSumit = handleSubmit((validated) => {
    if (otpTime > RESEND_TIME) {
      setIsVisible(false)
      setOtp('')
      return showToast(
        t('message:MS030015', { t: TIME_BLOCK_OTP }),
        'error',
        {
          horizontal: 'right',
          vertical: 'top'
        },
        15000
      )
    }
    setOtp('')
    setTime(TIMEOUT)
    setOtpTime(otpTime + 1)
    setDisableOtp(true)
    const policies = watch('policies')
    const list = policies?.filter((item) => item.checked).map((item) => item.policyNumber)
    return pipe(
      Services.otpRequest({
        body: {
          phoneNumber: watch('phoneNumber'),
          bankName: watch('BankName')?.value ?? '',
          bankNumber: watch('bankAccount') ?? '',
          policyNumber:watch('policyNum') ?? '',
          fullName: watch('fullName'),
          //@ts-ignore
          policies: list
        }
      }),
      ZIO.tap((x) => {
        setDisableOtp(false)
        setIsVisible(true)
        return ZIO.unit
      }),
      ZIO.tapError(() => {
        setDisableOtp(false)
        return ZIO.unit
      }),
      ZIO.catchAll((error) => {
        console.log(error, 'error')
        let message = ''
        //@ts-ignore
        if (error?.source?.response?.status === 500 && error?.source?.response?.data?.responseStatus.code === 0) {
          //@ts-ignore
          // message = error?.source?.response?.data?.responseStatus.message
          message = t('message:MS030015', { t: TIME_BLOCK_OTP })
          //@ts-ignore
        } else if (error?.source?.response?.data?.responseStatus.errors?.[0].code === '005') {
          message =
            'Quý Khách đã thực hiện xác thực mã OTP thất bại 2 lần, vui lòng liên hệ tổng đài Số 18001247 để cập nhật thông tin tài khoản ngân hàng'
        }
        setDisableOtp(false)
        return ZIO.effect(() => {
          setLoading(false)
          showToast(
            message,
            'error',
            {
              horizontal: 'right',
              vertical: 'top'
            },
            15000
          )
        })
      }),
      ErrorHandling.run()
    )
  })
  const checkValidate = async () => {
    if (!watch('phoneNumber') && !watch('policyNum')) {
      return showToast(
        'Quý khách vui lòng kiểm tra lại thông tin số Hợp đồng và Số điện thoại như trong tin nhắn thông báo trước đó',
        'error',
        {
          horizontal: 'right',
          vertical: 'top'
        },
        15000
      )
    }
    setLoading(true)
    return pipe(
      Services.login(watch('phoneNumber'), watch('policyNum')),
      ZIO.tap(() => {
        return ZIO.effect(() => {
          setLoading(false)
          getPolicies(watch('phoneNumber'))
          getBank()
          setShowPolicies(true)
          setOpen(false)
          setStep2(true)
          setValidate(true)
        })
      }),
      ZIO.tapError(() => {
        return ZIO.unit
      }),
      ZIO.catchAll((error) => {
        console.log(error, 'error')
        let message = ''
        // @ts-ignore
        if (error?.source?.response?.data?.error_code === '004') {
          message =
            'Thông tin không hợp lệ, Quý Khách vui lòng kiểm tra lại thông tin số khoản vay và số điện thoại như trong tin nhắn thông báo trước đó'
          // @ts-ignore
        } else if (
          //@ts-ignore
          error?.source?.response?.status === 400 &&
          //@ts-ignore
          error?.source?.response?.data?.error_code === '005'
        ) {
          message = m(
            'Quý Khách đã thực hiện cập nhật thông tin tài khoản ngân hàng cho số khoản vay này, nếu cần thay đổi, vui lòng liên hệ tổng đài Số 18001247  để được hỗ trợ'
          )
          //@ts-ignore
        } else if (error?.source?.response?.data?.error_code === '006') {
          message = m(
            'Quý Khách đã thực hiện xác thực mã OTP thất bại 2 lần, vui lòng liên hệ tổng đài Số 18001247 để cập nhật thông tin tài khoản ngân hàng'
          )
        }
        setDisableOtp(false)
        return ZIO.effect(() => {
          setLoading(false)
          showToast(
            message,
            'error',
            {
              horizontal: 'right',
              vertical: 'top'
            },
            15000
          )
        })
      }),
      ZIO.unsafeRun({})
    )
  }

  React.useEffect(() => {
    changeLanguage('vi')
    localStorage.setItem('locale', 'vi-VN')
    // setOpen(true)
  }, [])

  const onChange = (value: string | null) => {
    setValue('captchaValue', value)
  }

  const convertMinute = (seconds: number): string => {
    const format = (val: number) => `0${Math.floor(val)}`.slice(-2)
    //const hours = seconds / 3600
    const minutes = (seconds % 3600) / 60

    return [minutes, seconds % 60].map(format).join(':')
  }

  const resendHandle = () => {
    setErrorOtp(null)
    if (time === 0) {
      sendOtp()
    }
  }

  const sendOtp = () => {
    // if (otpTime > RESEND_TIME) {
    //   setIsVisible(false)
    //   setOtp('')
    //   return showToast(
    //     t('message:MS030015', { t: TIME_BLOCK_OTP }),
    //     'error',
    //     {
    //       horizontal: 'right',
    //       vertical: 'top'
    //     },
    //     150000
    //   )
    // }
    setOtp('')
    setTime(TIMEOUT)
    setOtpTime(otpTime + 1)
    setDisableOtp(true)
    const policies = watch('policies')
    const list = policies?.filter((item) => item.checked).map((item) => item.policyNumber)
    return pipe(
      Services.otpRequest({
        body: {
          phoneNumber: watch('phoneNumber'),
          bankName: watch('BankName')?.value ?? '',
          bankNumber: watch('bankAccount') ?? '',
          policyNumber: watch('policyNum') ?? '',
          fullName: watch('fullName'),
          //@ts-ignore
          policies: list
        }
      }),
      ZIO.tap((x) => {
        setDisableOtp(false)
        setIsVisible(true)
        return ZIO.unit
      }),
      ZIO.tapError(() => {
        setDisableOtp(false)
        return ZIO.unit
      }),
      ZIO.catchAll((error) => {
        setIsVisible(false)
        setLoading(false)
        let message = ''
        //@ts-ignore
        if (
          //@ts-ignore
          error?.source?.response?.status === 400 &&
          //@ts-ignore
          error?.source?.response?.data?.responseStatus?.errors[0].code === 'MS030015'
        ) {
          //@ts-ignore
          // message = error?.source?.response?.data?.responseStatus.message
          message = t('message:MS030015', { t: TIME_BLOCK_OTP })
          //@ts-ignore
        } else if (error?.source?.response?.data?.responseStatus.errors?.[0].code === '005') {
          message =
            'Quý Khách đã thực hiện xác thực mã OTP thất bại 2 lần, vui lòng liên hệ tổng đài Số 18001247 để cập nhật thông tin tài khoản ngân hàng'
        }
        setDisableOtp(false)
        return ZIO.effect(() => {
          setLoading(false)
          showToast(
            message,
            'error',
            {
              horizontal: 'right',
              vertical: 'top'
            },
            15000
          )
        })
      }),
      ErrorHandling.run()
    )
  }
  React.useEffect(() => {
    let timer: number | false
    if (visible) {
      //@ts-ignore
      timer = time > 0 && setInterval(() => setTime(time - 1), 1000)
    } else {
      setTime(TIMEOUT)
    }

    return () => {
      if (timer) {
        clearInterval(timer)
      }
    }
  }, [time, visible])

  const verifyOTP = () => {
    setLoading(true)
    return pipe(
      Services.verifyOTP({
        body: {
          phoneNumber: watch('phoneNumber'),
          policyNumber: watch('policyNum') ?? '',
          bankAccount: watch('bankAccount') ?? '',
          bankName: watch('BankName')?.value ?? ''
        },
        otpTransaction: {
          receiverPhone: watch('phoneNumber'),
          otp: otp
        }
      }),
      ZIO.tap(() => {
        setDisableOtp(false)
        return ZIO.unit
      }),
      ZIO.map(() => {
        savePayload()
      }),
      ZIO.tapError(() => {
        return ZIO.unit
      }),
      ZIO.catchAll((error) => {
        let message = ''
        setLoading(false)
        if (
          //@ts-ignore
          error?.source?.response?.status === 400 &&
          //@ts-ignore
          error?.source?.response?.data?.responseStatus.errors?.[0].code === 'MS030016'
        ) {
          message = m('MS030016')
          //@ts-ignore
        } else if (error?.source?.response?.data?.responseStatus.errors?.[0].code === 'MS030017') {
          message = m('MS030017', { t: TIME_BLOCK_VERIFY })
          //@ts-ignore
        } else if (error?.source?.response?.data?.responseStatus.errors?.[0].code === 'MS030018') {
          message = m('MS030018')
          //@ts-ignore
        } else if (error?.source?.response?.data?.responseStatus.errors?.[0].code === '009') {
          setDisableOtp(true)
          message = 'Quý Khách đã thực hiện xác thực mã OTP thất bại 2 lần, vui lòng liên hệ tổng đài Số 18001247 để cập nhật thông tin tài khoản ngân hàng'
          //@ts-ignore
        } else if (error?.source?.response?.data?.responseStatus.errors?.[0].code === '010') {
          setDisableOtp(true)
          message = 'Mã OTP không đúng. Quý Khách vui lòng lựa chọn Gửi lại OTP để tiếp tục thực hiện.'
        }
        return ZIO.effect(() => {
          setLoading(false)
          setErrorOtp(message)
        })
      }),
      ErrorHandling.run()
    )
  }

  const savePayload = () => {
    const policies = watch('policies')
    const list = policies?.filter((item) => item.checked).map((item) => item.policyNumber)
    const data = [...(list as Array<string>), maskPolicy(watch('policyNum'))]
    return pipe(
      Services.savePayload({
        body: {
          phoneNumber: watch('phoneNumber'),
          bankNumber: watch('bankAccount') ?? '',
          bankName: watch('BankName')?.value ?? '',
          //@ts-ignore
          fullName: watch('fullName'),
          //@ts-ignore
          policies: data,
          policyNumber: watch('policyNum'),
          otpTransaction: {
            receiverPhone: watch('phoneNumber'),
            otp: otp
          }
        }
      }),
      ZIO.tap(() => {
        setOpen(true)
        setIsVisible(false)
        setLoading(false)
        setOtpTime(0)
        return ZIO.unit
      }),
      ZIO.tapError(() => {
        setLoading(false)
        return ZIO.unit
      }),
      ZIO.catchAll((e) => {
        return ZIO.unit
      }),
      ErrorHandling.run()
    )
  }
  // React.useEffect(() => {
  //   if (otp.length === 6) {
  //     verifyOTP()
  //   }
  // }, [otp])

  const getPolicies = (searchPhone: string) => {
    if (searchPhone === '') return
    return pipe(
      Services.searchPolicies(searchPhone),
      ZIO.tap((res) => {
        // res.body?.length ?? 0 > 0 ? setOpen(true) : ''
        const policies = (res.body ?? [])?.map((item: any) => ({
          phoneNumber: item?.phoneNumber ?? '',
          policyNumber: item?.policyNumber ?? '',
          createdDate: item?.createdDate ?? '',
          createdBy: item?.createdBy ?? '',
          updatedBy: item?.updatedBy ?? '',
          updatedDate: item?.updatedDate ?? '',
          status: item?.status ?? '',
          checked: false
        }))
        console.log(policies, 'policies')
        setValue('policies', policies)

        return ZIO.unit
      }),

      ZIO.tapError((e) => {
        console.log(e)
        return ZIO.unit
      }),
      ZIO.catchAll((error) => {
        return ZIO.unit
      }),
      ErrorHandling.run()
    )
  }

  const getBank = () => {
    return pipe(
      Services.searchBank(),
      ZIO.map((res) => {
        const data = (res?.body ?? []).map((item) => ({
          label: item?.name ?? '',
          value: item?.code ?? ''
        }))
        setBank(data)
      }),
      ZIO.tapError((e) => {
        return ZIO.unit
      }),
      ZIO.catchAll((error) => {
        return ZIO.unit
      }),
      ErrorHandling.run()
    )
  }

  const maskPolicy = (policy: string) => {
    const string = 'xxxx' + policy.slice(4)

    return string
  }

  return (
    <SafeAreaView style={{ flex: 1, height: '100%', backgroundColor: '#e5e5e5' }}>
      <Header isWide={isWide} t={t} />
      <ImageBackground style={{ flex: 1 }} source={isMobile ? assets.HomeBackground : assets.HomeBackgroundWide}>
        <ScrollView>
          <SC.Container width={width} maxWidth={width > 1367 ? "780" : "610"} isWide={isWide}>
            <View style={styles.form}>
              <SC.Form.Group marginBottom="0">
                {/* <SC.Form.Row marginBottom="18" isMobile={isMobile}>
                  <SC.MessageTitle isWide={isWide}>
                    {'Vui lòng nhập thông tin Số khoản vay và Số điện thoại:'}
                  </SC.MessageTitle>
                </SC.Form.Row> */}
                {/* <SC.Form.Row marginBottom="22" isMobile={isMobile}>
                  <Controller
                    control={control}
                    name="policyNum"
                    render={({ field }) => (
                      <BorderFilledInput
                        isDigit
                        controlStyle={{ width: '100%' }}
                        label={`${t('landingPage:policyNumber')}*`}
                        value={field.value || ''}
                        onChange={(val) => {
                          field.onChange(val)
                        }}
                        onBlur={field.onBlur}
                        maxLength={9}
                        errorMessage={errors?.policyNum?.message}
                        disabled={validateStep}
                      />
                    )}
                  />
                </SC.Form.Row> */}
                {/* <SC.Form.Row marginBottom="22" isMobile={isMobile}>
                  <Controller
                    control={control}
                    name="phoneNumber"
                    render={({ field }) => (
                      <BorderFilledInput
                        isDigit
                        controlStyle={{ width: '100%' }}
                        label={`${t('phoneNumber')}*`}
                        value={field.value || ''}
                        onChange={(val) => {
                          field.onChange(val)
                        }}
                        onBlur={field.onBlur}
                        maxLength={10}
                        errorMessage={errors?.phoneNumber?.message}
                        disabled={validateStep}
                      />
                    )}
                  />
                </SC.Form.Row> */}

                {/* {showPolicies ? (
                  <SC.Form.Row>
                    <Text>
                      Bên cạnh khoản vay vừa nhập, Quý Khách còn (những) khoản vay khác tại Shinhan Finance cũng có Hợp
                      Đồng Bảo Hiểm được hoàn phí dư, vui lòng chọn khoản vay cần cập nhật thông tin tài khoản ngân hàng
                      trong lượt cập nhật này. Đối với khoản vay không được chọn trong lượt này, QK vui lòng thực hiện
                      cập nhật lại thông tin từ đầu
                    </Text>
                  </SC.Form.Row>
                ) : (
                  ''
                )} */}
                {/* <View style={[styles.sectionRow, isMobile ? { marginHorizontal: 20 } : {}]}>
                  {showPolicies &&
                    watch('policies')?.map((item, i) => {
                      return (
                        <View style={styles.col_45}>
                          <Controller
                            control={control}
                            name={`policies.${i}.checked`}
                            render={({ field }) => (
                              <Checkbox
                                title={item.policyNumber}
                                value={field.value}
                                onChange={(val) => {
                                  field.onChange(val)
                                }}
                                onBlur={field.onBlur}
                              />
                            )}
                          />
                        </View>
                      )
                    })}
                </View> */}
                {step2 ? (
                  <>
                    {/* <SC.Form.Row marginBottom="18" isMobile={isMobile}>
                      <SC.MessageTitle isWide={isWide}>
                        {'Nhập thông tin Họ tên và Số tài khoản và Tên ngân hàng:'}
                      </SC.MessageTitle>
                    </SC.Form.Row> */}
                    {/* <SC.Form.Row marginBottom="22" isMobile={isMobile}>
                      <Controller
                        control={control}
                        name="fullName"
                        render={({ field }) => (
                          <BorderFilledInput
                            controlStyle={{ width: '100%' }}
                            label={`${t('fullName')}*`}
                            value={field.value || ''}
                            onChange={(val) => {
                              field.onChange(
                                val == null ? val : val.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')
                              )
                            }}
                            // type='number'
                            onBlur={field.onBlur}
                            maxLength={24}
                            errorMessage={errors?.fullName?.message}
                          />
                        )}
                      />
                    </SC.Form.Row> */}
                    {/* <SC.Form.Row marginBottom="22" isMobile={isMobile}>
                      <Controller
                        control={control}
                        name="bankAccount"
                        render={({ field }) => (
                          <BorderFilledInput
                            controlStyle={{ width: '100%' }}
                            label={`${t('BankAccount')}*`}
                            value={field.value || ''}
                            onChange={(val) => {
                              field.onChange(
                                val == null ? val : val.replace(/[`~ !@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')
                              )
                            }}
                            // type='number'
                            onBlur={field.onBlur}
                            maxLength={20}
                            errorMessage={errors?.bankAccount?.message}
                          />
                        )}
                      />
                    </SC.Form.Row> */}
                    <SC.Form.Row marginBottom="22" isMobile={isMobile}>
                      <Controller
                        control={control}
                        name="BankName"
                        render={({ field: { value, onChange, onBlur } }) => (
                          <View style={{ width: '100%' }}>
                            <SC.SelectContainer>
                              <SC.SelectSearch
                                options={bank ?? []}
                                label={t('BankName')}
                                value={value ?? null}
                                onChange={(value) => {
                                  onChange(value)
                                }}
                                required
                                onBlur={onBlur}
                                placeholder={t('common:Select')}
                                popupIcon={<assetsCommon.ArrowDownDropdownIcon />}
                              />
                            </SC.SelectContainer>
                            {/* @ts-ignore */}
                            {errors?.BankName?.message ? (
                              <Text style={{ color: 'red', fontSize: 12, marginTop: 5, fontFamily: 'Noto Sans' }}>
                                {/* @ts-ignore */}
                                {errors?.BankName?.message}
                              </Text>
                            ) : (
                              ''
                            )}
                          </View>
                        )}
                      />
                    </SC.Form.Row>
                  </>
                ) : (
                  ''
                )}
                {validateStep ? (
                  <>
                    {reCaptchaKey && (
                      <SC.Form.Row style={{ justifyContent: 'center' }} marginBottom="38" isMobile={isMobile}>
                        <ReCAPTCHA sitekey={reCaptchaKey} hl="vi" onChange={onChange} />
                      </SC.Form.Row>
                    )}
                    <SC.Form.Row marginBottom="28" isMobile={isMobile}>
                      <Button
                        title={t('confirm')}
                        onPress={onSumit}
                        loading={loading}
                        disabled={!watch('captchaValue')}
                      />
                    </SC.Form.Row>
                  </>
                ) : (
                  // <SC.Form.Row marginBottom="28" isMobile={isMobile}>
                  //   <Button
                  //     title={t('countinue')}
                  //     onPress={checkValidate}
                  //     loading={loading}
                  //     // disabled={!watch('phoneNumber') && !watch('policyNum')}
                  //   />
                  // </SC.Form.Row>
                  <SC.Form.Row marginBottom="28" isMobile={isMobile}>
                    <Text style={{textAlign: 'center', fontWeight: '700', color: 'black', fontSize: 16}}>
                      Đã quá thời hạn cập nhật thông tin, Quý Khách vui lòng liên hệ Tổng đài miễn cước 1800 1247 của Prudential để được hỗ trợ.
                    </Text>
                  </SC.Form.Row>
                )}
              </SC.Form.Group>
              {/* <SC.Universal isWide={isWide}>
                <SC.LinkingRow>
                  <Pressable onPress={() => Linking.openURL(UniversalLink.PRIVACY_LINK)}>
                    <SC.LinkText>{t('owner_notice')}</SC.LinkText>
                  </Pressable>
                  <Pressable onPress={() => Linking.openURL(UniversalLink.TERM_LINK)}>
                    <SC.LinkText>{t('term')}</SC.LinkText>
                  </Pressable>
                </SC.LinkingRow>
              </SC.Universal> */}
            </View>
          </SC.Container>
        </ScrollView>
      </ImageBackground>
      <View style={styles.footer}>
        <SC.FooterText isWide={isWide}>{t('copy_right')}</SC.FooterText>
      </View>
      <Modal visible={visible} transparent={true} animationType="fade">
        <SCore.Modal.Container>
          <SCore.Modal.View>
            <SCore.Modal.Title>{t('otpTitle')}</SCore.Modal.Title>
            <Text style={{ marginBottom: 5 }}>Nhập mã OTP được gửi tới {watch('phoneNumber')}</Text>
            <Text style={{ marginBottom: 5, fontSize: 12 }}>
              Chú ý: Nhập sai 3 lần OTP, Quý khách vui lòng đăng nhập lại để điền thông tin
            </Text>
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              separator={<View style={{ width: 15 }} />}
              isDisabled={submitting}
              inputStyle={{
                width: 44,
                height: 44,
                fontSize: 15,
                borderRadius: 8,
                background: '#E5EAEF',
                borderWidth: 0,
                color: '#70777E'
              }}
              containerStyle={{
                alignSelf: 'center'
              }}
              placeholder="------"
            />
            {errorOtp ? <Text style={styles.errorText}>{errorOtp}</Text> : ''}
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: 5
              }}
            >
              <Pressable onPress={resendHandle} disabled={time !== 0 || otpTime >= RESEND_TIME}>
                {/* disabled={time !== 0 || otpTime > RESEND_TIME} */}
                <Text style={{ color: time !== 0 || otpTime >= RESEND_TIME ? 'lightgray' : 'red', fontWeight: '700' }}>
                  Gửi lại OTP{' '}
                </Text>
              </Pressable>
              <Text>{time !== 0 ? `sau ${convertMinute(time)}` : null}</Text>
            </View>
            {submitting && <ActivityIndicator color={'primary'} />}
            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 20 }}>
              <ButtonModal
                title={t('Xác nhận')}
                onPress={() => {
                  verifyOTP()
                }}
                loading={loading}
                disabled={disableOTP}
              />
            </View>
          </SCore.Modal.View>
        </SCore.Modal.Container>
      </Modal>
      <Modal visible={open} transparent={true} animationType="fade">
        <SCore.Modal.Container>
          <SCore.Modal.View>
            <SCore.Modal.Title>{'Thông Báo'}</SCore.Modal.Title>
            <Text>Quý Khách đã cập nhật thông tin tài khoản ngân hàng thành công</Text>
            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 20 }}>
              <ButtonModal
                title={t('Đồng ý')}
                onPress={() => {
                  setOpen(false)
                  setValidate(false)
                  setStep2(false)
                  setShowPolicies(false)
                  reset()
                }}
              />
            </View>
          </SCore.Modal.View>
        </SCore.Modal.Container>
      </Modal>
    </SafeAreaView>
  )
}
const Core = {
  Container: styled(View)`
    flex: 1;
    background-color: #f9f9f9;
    align-self: center;
  `,
  ScrollContainer: styled(ScrollView)`
    flex: 1;
    background: #f7f7f7;
  `,
  Card: styled(View)`
    padding-right: 24px;
    padding-left: 24px;
    padding-bottom: 10px;
    padding-top: 10px;
    background-color: #fff;
    border-radius: 8px;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-right: 10px;
    margin-left: 10px;
  `,
  HeaderItem: styled(View)`
    margin-bottom: 4px;
    flex-direction: row;
  `,
  NormalText: styled(Text)<{ isWide?: boolean }>`
    font-family: 'Noto Sans';
    font-weight: 600;
    font-size: ${(p) => (p.isWide ? '20' : '18')}px;
  `,
  HighLightText: styled(Text)<{ isWide?: boolean }>`
    font-family: 'Noto Sans';
    font-weight: 700;
    font-size: ${(p) => (p.isWide ? '24' : '20')}px;
    color: #ed1b2e;
  `,
  CardBorder: styled(View)<{ isWide?: boolean }>`
    border: 1px solid #d3dce6;
    background: #fff;
    padding: ${(p) => (p.isWide ? '30px 40px' : '20px')};
    margin-top: ${(p) => (p.isWide ? '30' : '0')}px;
    border-radius: 8px;
    box-shadow: 0px 26px 30px rgba(0, 0, 0, 0.05);
  `,
  SessionText: styled(Text)<{ isWide?: boolean }>`
    margin-bottom: 16px;
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: ${(p) => (p.isWide ? '32' : '24')}px;
  `,
  TitleText: styled(Text)<{ isWide?: boolean }>`
    color: #000000;
    font-family: 'Noto Sans';
    font-weight: 400;
    font-size: ${(p) => (p.isWide ? '18' : '16')}px;
    margin-bottom: 5px;
  `,
  ItemInfoContainer: styled(View)<{ marginBottom?: string }>`
    margin-bottom: ${(p) => (p.marginBottom ? p.marginBottom : '40')}px;
    display: flex;
    flex-direction: row;
  `,
  SendText: styled(Text)<{ disabled?: boolean }>`
    text-align: center;
    color: red;
    opacity: ${(p) => (p.disabled ? 0.6 : 1)};
    font-size: 15;
    font-weight: 600;
    margin-top: 40px;
  `,
  HelperText: styled(Text)`
    text-align: flex-start;
    color: #e53935;
    font-size: 13px;
    margin-top: 2px;
  `,
  Icon: styled(View)`
    margin-right: 31px;
  `
}
const styles = StyleSheet.create({
  form: {
    backgroundColor: '#F7F7F7',
    paddingTop: 24,
    borderRadius: 8,
    boxShadow: '-6px 20px 30px rgba(0, 0, 0, 0.12)'
  },
  footer: {
    backgroundColor: '#000000'
  },
  errorText: {
    textAlign: 'left',
    color: '#e53935',
    fontSize: 13,
    marginTop: 2
  },
  sendText: {
    textAlign: 'center',
    color: '#ed1b2e',
    fontSize: 15,
    fontWeight: 600,
    marginTop: 40
  },
  sectionRow: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    marginHorizontal: 30
  },
  col_45: {
    width: '100%',
    maxWidth: '48%'
  }
})
