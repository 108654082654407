import React from 'react'
import { StyleProp, StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native'
import { makeStyles, TextField } from '@material-ui/core'
import { ControlProps } from '../FormProps'
type MultilineInputProps = ControlProps<string> & {
  //   placeholder?: string
  title?: string
  labelStyle?: TextStyle
  disabled?: boolean
  required?: boolean
  errorMessage?: string
  multiline?: boolean
  //   textAlign?: 'left' | 'right' | 'center' | 'auto' | 'justify'
  //   suffix?: string | JSX.Element
  //   inputType?: 'text' | 'number' | 'money'
  containerStyle?: StyleProp<ViewStyle>
}

const inputClasses = makeStyles({
  root: {
    '&.MuiInput-underline:before': {
      borderBottomWidth: 1,
      borderBottomColor: '#D3DCE6'
    },
    '&.MuiInput-underline:after': {
      borderBottomWidth: 1,
      borderBottomColor: '#D3DCE6'
    }
  }
})
export const MultilineInput = (props: MultilineInputProps) => {
  const { value, onChange, onBlur, errorMessage, multiline, disabled, title, containerStyle, required } = props
  return (
    <View style={containerStyle}>
      {title && (
        <View style={{ flex: 1, flexDirection: 'row' }}>
          <Text style={[multilineStyle.textTitle]}>{title}</Text>
          {required && <Text style={multilineStyle.requiredSymbol}>*</Text>}
        </View>
      )}
      <TextField
        value={value ?? ''}
        onChange={(val) => {
          onChange && onChange(val.target.value)
        }}
        onBlur={onBlur}
        variant="standard"
        fullWidth
        disabled={disabled}
        multiline={multiline}
        InputProps={{
          className: inputClasses().root
        }}
      />
      {errorMessage ? <Text style={multilineStyle.errorMsg}>{errorMessage}</Text> : null}
    </View>
  )
}
const multilineStyle = StyleSheet.create({
  textTitle: {
    fontWeight: 'bold',
    color: '#70777E',
    fontSize: 15,
    marginBottom: 2
  },
  requiredSymbol: {
    color: '#ed1b2c',
    marginLeft: 5,
    fontSize: 15,
    fontWeight: 'bold'
  },
  errorMsg: {
    color: '#ED1B2C',
    fontSize: 11.25
  }
})
