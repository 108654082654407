import { PulseOpsFormat } from '@pulseops/common'
import { InquiryComplaintData } from '../InquiryComplaint'
import * as api from '../task-detail-api'
import { TopUp } from '../Topup'
import { TransactionType } from '../TransactionType'
import { VeriCanRiderClientInfo } from './CancelRider'
import { RiderAlterationData } from '../../model'

export interface VERIRiderAlteration {
  tag: TransactionType.RIDER_ALTERATION
  clientName: string
  lifeAssureds: VeriLARiderInfo[]
  riderNameList: InquiryComplaintData.IACSources[]
  requiredPayInAmount: number
  lifeAssuredAdditions: Array<RiderAlterationData.LifeAssuredAdditions>
  waiverList: string[]
  isRiderAlteration: boolean
  isAddLA: boolean
  request?: string
}

export interface VERiderAlterationInfo {
  productCode: string
  riskCommDate: string
  riskCessDate: string
  sumAssured: number
  installmentPremium: number
  alteredSumAssured: string
  lifeNo: string
  coverageNo: string
  riderNo: string
  riderStatus: string
}

export interface VeriLARiderInfo {
  clientName: string
  lifeAssured: string
  coreRiders: VERiderAlterationInfo[]
  // submissionRiders: VeriCancelRiderInfo[]
}

export const RiderAlteration = (
  detail: api.RiderAlteration & api.TaskDetailBase,
  riderClientInfo: VeriCanRiderClientInfo[],
  poName: string,
  extraPolicyInfo: TopUp.PolicyExtraInfo,
  riderNameList: InquiryComplaintData.IACSources[],
  waiverList: string[]
): VERIRiderAlteration => {
  const riderPayload = detail as api.NewRiderAlterationPayload
  const payload = riderPayload.payload.body
  // const coreRiderList = extraPolicyInfo.policyExtraInfoDetail.filter(
  //   (x) => x.lifeNo !== '01' || x.coverageNo !== '01' || x.riderNo !== '00'
  // )
  const laRiderList: VeriLARiderInfo[] =
    payload.lifeAssuredList?.map((item) => {
      return {
        lifeAssured: item.clientNo,
        clientName: riderClientInfo.find((x) => x.clientNumberOfLA === item.clientNo)?.clientName || '',
        coreRiders: item.riderList.map((x) => {
          return {
            productCode: x.coverageCode,
            lifeNo: x.lifeNo,
            coverageNo: x.coverageNo,
            riderNo: x.riderNo,
            riderStatus: x.riderStatus,
            riskCommDate: x.riskCommDate,
            riskCessDate: x.riskCessDate,
            sumAssured: x.sumAssured,
            installmentPremium: x.installmentPremium,
            alteredSumAssured: String(x.alteredSumAssured ?? '')
          }
        }) as VERiderAlterationInfo[]
      }
    }) || []
  return {
    tag: detail.transactionType,
    clientName: poName,
    lifeAssureds: laRiderList,
    riderNameList,
    requiredPayInAmount: payload.requiredPayInAmount ?? 0,
    lifeAssuredAdditions: payload.lifeAssuredAdditions.map((item) => {
      return {
        lifeAssured: {
          ...item.lifeAssured,
          isNewLA: item.lifeAssured.isNewLA,
          isUSTax: item.lifeAssured.isUSTax,
          issuePlace: item.lifeAssured.issuePlace ?? '',
          lifeNo: item.lifeAssured.lifeNo ?? '',
          clientNumber: item.lifeAssured.clientNumber ?? '',
          addressDetails: {
            ADDRESS: {
              line1: item.lifeAssured.addressDetails?.ADDRESS?.line1 ?? '',
              city: item.lifeAssured.addressDetails?.ADDRESS?.city ?? '',
              district: item.lifeAssured.addressDetails?.ADDRESS?.district ?? '',
              subDistrict: item.lifeAssured.addressDetails?.ADDRESS?.subDistrict ?? '',
              countryCode: item.lifeAssured.addressDetails?.ADDRESS?.countryCode ?? ''
            },
            FOREIGN_ADDRESS: {
              line1: item.lifeAssured.addressDetails?.FOREIGN_ADDRESS?.line1 ?? '',
              countryCode: item.lifeAssured.addressDetails?.FOREIGN_ADDRESS?.countryCode ?? ''
            }
          },
          attributesExt: {
            NATIONALITY_2: item.lifeAssured.attributesExt?.NATIONALITY_2 ?? '',
            nationality2Name: item.lifeAssured.attributesExt?.nationality2Name ?? '',
            countryName: item.lifeAssured.attributesExt?.countryName ?? '',
            districtName: item.lifeAssured.attributesExt?.districtName ?? '',
            wardName: item.lifeAssured.attributesExt?.wardName ?? '',
            cityCode: item.lifeAssured.attributesExt?.cityCode ?? '',
            districtCode: item.lifeAssured.attributesExt?.districtCode ?? '',
            subDistrictCode: item.lifeAssured.attributesExt?.subDistrictCode ?? ''
          }
        }
      }
    }),
    waiverList,
    isRiderAlteration: payload.isRiderAlteration as boolean,
    isAddLA: payload.isAddLA as boolean
  }
}
