import { createStackNavigator, StackScreenProps } from '@react-navigation/stack'
import React from 'react'
import { UtilitiesStackParamList } from './utilites-stack-params-list'
import { UtilitiesScreen } from './utilities-screen'
// import { TaskDetailScreen } from './TaskDetailScreen'
// import { TaskDetailStackParamList } from './TaskDetailStackParamList'
import { InquiryIHeatScreen } from './inquiry-iheat-screen'
import { UploadDocumentScreen } from './upload-document'
import { UploadDocumentReportScreen } from './upload-document-report'
import { ImportAVICADScreen } from './import-avi-cad-screen'
import { DoNotCallScreen } from './do-not-call-screen'
import { DistributionServiceScreen } from './distribution-services-screen'
import { SaleStructureReportScreen } from './SaleStructureReport/SaleStructureReportScreen'
import { CandidateInformationScreen } from './CandidateInformationReport/CandidateInformationScreen'
import { ExportAVICADScreen } from './export-avi-cad-screen/export-avi-cad-sreen'
import { DsAdminScreen } from '../../../task/src/ds-admin'
import { UnitFundFundPriceScreen } from './unit-link-fund-price-history'
import {AddLackScreen, DSScreen} from './distribution-service-DA'
import {AgentWrapScreen} from './agent-DA'
import {AgentSearchScreen} from './agent-DA/agent-search'
import { UpdateDocumentGuideScreen, ViewDocumentGuideScreen } from './SupportingDocs'
import { ImportCertScreen } from './import-cert-screen'
import { ExportCertScreen } from './export-cert-screen'
import { ImagingUploadScreen } from './imaging-upload'

const Stack = createStackNavigator<UtilitiesStackParamList>()

type Props = StackScreenProps<UtilitiesStackParamList, 'Utilities'>

export const UtilitiesStack = ({ route }: Props) => {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }} initialRouteName="Utilities">
      <Stack.Screen name="Utilities" component={UtilitiesScreen} />
      <Stack.Screen name="InquiryIheatScreen" component={InquiryIHeatScreen} />
      <Stack.Screen name="UploadDocumentScreen" component={UploadDocumentScreen} />
      <Stack.Screen name="UploadDocumentReportScreen" component={UploadDocumentReportScreen} />
      <Stack.Screen name="ImportAVICADScreen" component={ImportAVICADScreen} />
      <Stack.Screen name="DoNotCallScreen" component={DoNotCallScreen} />
      <Stack.Screen name="DistributionServicesScreen" component={DistributionServiceScreen} />
      <Stack.Screen name="SaleStructureReportScreen" component={SaleStructureReportScreen}></Stack.Screen>
      <Stack.Screen name="CandidateInformationScreen" component={CandidateInformationScreen}></Stack.Screen>
      <Stack.Screen name="ExportAVICADScreen" component={ExportAVICADScreen}></Stack.Screen>
      <Stack.Screen name="DsAdminScreen" component={DsAdminScreen}></Stack.Screen>
      <Stack.Screen name="AgentWrapScreen" component={AgentWrapScreen}></Stack.Screen>
      <Stack.Screen name="AgentSearchScreen" component={AgentSearchScreen}></Stack.Screen>
      <Stack.Screen name="UnitFundFundPriceScreen" component={UnitFundFundPriceScreen}></Stack.Screen>
      <Stack.Screen name="AddLackScreen" component={AddLackScreen}></Stack.Screen>
      <Stack.Screen name="DSScreen" component={DSScreen}></Stack.Screen>
      <Stack.Screen name="UpdateDocumentGuideScreen" component={UpdateDocumentGuideScreen}></Stack.Screen>
      <Stack.Screen name="ViewDocumentGuideScreen" component={ViewDocumentGuideScreen}></Stack.Screen>
      <Stack.Screen name="ImportCertScreen" component={ImportCertScreen} />
      <Stack.Screen name="ExportCertScreen" component={ExportCertScreen}></Stack.Screen>
      <Stack.Screen name="ImagingUploadScreen" component={ImagingUploadScreen} />
    </Stack.Navigator>
  )
}
