import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { DatePicker, useMobile, GeneralService, ErrorHandling } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import moment from 'moment'
import * as React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View, Platform, ActivityIndicator } from 'react-native'
import { Button } from 'react-native-elements'

export interface TranData {
  batchCode?: string | null | undefined
  transactionNo?: string | null | undefined
  transactionDate?: string | null | undefined
  effectiveDate?: string | null | undefined
  description?: string | null | undefined
}

export interface TranForm {
  fromDate: Date | null
  toDate: Date | null
}

const useStyles = makeStyles({
  tableHeader: {
    backgroundColor: '#E5EAEF'
  }
})

const formatDate = (date: Date | null) => {
  return date && moment(date).format('YYYY-MM-DD').replace(/-/g, '/')
}
const currentDate = new Date()

const FromToDateRangeDay = (duration: number) => {
  return (value: { fromDate: Date | null; toDate: Date | null }) => {
    const time_diff = value.toDate && value.fromDate ? value.toDate.getTime() - value.fromDate.getTime() : duration + 1
    console.log('space time', time_diff / (1000 * 3600 * 24))
    return duration < time_diff / (1000 * 3600 * 24) ? { isInvalidRange: true } : null
  }
}

export const TransactionHistoryScreen = (props: {
  id: string
  data: TranData[] | null
  setData: (val: TranData[] | null) => void
}) => {
  const classes = useStyles()
  const { isWide } = useMobile()
  const { t } = useTranslation()
  const [list, setList] = React.useState<TranData[] | null | undefined>([])
  const [size, setSize] = React.useState<number>(10)
  const [page, setPage] = React.useState<number>(0)
  const [total, setTotal] = React.useState<number>(0)
  const [isLoading, bindLoading] = useLoading()
  const [errMess, setErrMess] = React.useState<string>('')

  const form = useForm<TranForm>({
    defaultValues: {
      fromDate: null,
      toDate: null
    }
  })

  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors },
    watch
  } = form

  const fromDate = watch('fromDate') ? formatDate(watch('fromDate')) : null
  const toDate = watch('toDate') ? formatDate(watch('toDate')) : null

  const onSearch = handleSubmit((values: TranForm) => {
    const fDate = watch('fromDate')
    const tDate = watch('toDate')

    if (FromToDateRangeDay(92)({ fromDate: fDate, toDate: tDate })) {
      setErrMess(t('message:MS030054', { k: '92 ' }))
    } else {
      setErrMess('')
      update(page, size)
    }
  })

  const loadData = (page: number, size: number) => {
    const policyNum: string = props.id
    return pipe(
      GeneralService.getTranHistory(policyNum, page, size, fromDate, toDate),
      ZIO.map((response) => {
        if (response.responseStatus.code === 1) {
          setTotal(Number(response.body?.total ?? 0))
          return response.body ? response.body.data : []
        } else {
          setTotal(0)
          return []
        }
      }),
      ZIO.tap((res) => {
        setList(res)
        return ZIO.unit
      }),
      bindLoading
    )
  }

  pipe(loadData(page, size), ErrorHandling.runDidMount())

  const update = (page: number, size: number) => {
    pipe(loadData(page, size), ErrorHandling.run())
  }

  return (
    <View>
      <Text style={styles.title}>{t('Tab:TransactionHistory')}</Text>
      <View style={styles.sectionControlGroup}>
        <View style={styles.sectionControl}>
          <Controller
            control={control}
            name="fromDate"
            rules={{
              required: {
                value: true,
                message: t('message:MS020009', { field: t('ServiceInquiry:FromDate') })
              }
            }}
            render={({ field: { value, onChange, onBlur } }) => (
              <DatePicker
                label={t('ServiceInquiry:FromDate')}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                maxDate={getValues('toDate') || currentDate}
                maxDateMessage={t('message:MS030042')}
                errorMessage={errors?.fromDate?.message}
                alwaysShow={true}
              />
            )}
          />
        </View>
        <View style={styles.sectionControl}>
          <Controller
            control={control}
            name="toDate"
            rules={{
              required: {
                value: true,
                message: t('message:MS020009', { field: t('ServiceInquiry:ToDate') })
              }
            }}
            render={({ field: { value, onChange, onBlur } }) => (
              <DatePicker
                label={t('ServiceInquiry:ToDate')}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                minDate={getValues('fromDate') || undefined}
                maxDate={currentDate}
                errorMessage={errors?.toDate?.message}
                minDateMessage={t('message:MS030044')}
                alwaysShow={true}
              />
            )}
          />
        </View>
        <View>
          <Button
            onPress={onSearch}
            title={t('common:Search')}
            buttonStyle={styles.btnSearch}
            titleStyle={styles.btnSearchText}
          />
        </View>
      </View>
      <View>
        {!!errMess && <Text style={[{ color: '#ED1B2C', marginBottom: 10, marginHorizontal: 30 }]}>{errMess}</Text>}
      </View>
      {isWide && Platform.OS === 'web' && (
        <View>
          <TableContainer component={Paper}>
            <Table>
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  <TableCell>{t('ServiceInquiry:pc:transactionNumber')}</TableCell>
                  <TableCell>{t('common:Description')}</TableCell>
                  <TableCell>{t('ServiceInquiry:pc:transactionDate')}</TableCell>
                  <TableCell>{t('claim:EffectiveDate')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell style={{ textAlign: 'center' }} colSpan={12}>
                      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                        <ActivityIndicator size="large" color="red" />
                      </View>
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {list &&
                      list.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            {row.transactionNo}
                          </TableCell>
                          <TableCell>
                            {row.batchCode} - {row.description}
                          </TableCell>
                          <TableCell>{moment(row.transactionDate).format('DD/MM/YYYY') || '-'}</TableCell>
                          <TableCell>{moment(row.effectiveDate).format('DD/MM/YYYY') || '-'}</TableCell>
                        </TableRow>
                      ))}
                    {/* <TableRow></TableRow> */}
                  </>
                )}
                {list?.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={12} style={{ textAlign: 'center' }}>
                      {t('common:noData')}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            page={page}
            rowsPerPage={size}
            count={total}
            onPageChange={(e, page) => {
              setPage(page)
              update(page, size)
            }}
            onRowsPerPageChange={(e) => {
              const newPageSize = Number(e.target.value)
              setSize(newPageSize)
              update(page, newPageSize)
            }}
            labelRowsPerPage={t('common:PaginationSize')}
            component={View}
          />
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  title: {
    color: '#000000',
    fontSize: 16,
    lineHeight: 22,
    fontWeight: '800',
    textTransform: 'uppercase',
    marginBottom: 10
  },
  sectionControlGroup: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    paddingVertical: 12,
    paddingHorizontal: 30,
    marginBottom: 20,
    borderRadius: 8,
    backgroundColor: '#FAFAFA',
    border: '1px solid #d3dce6'
  },
  sectionControl: {
    flex: 1 / 3,
    width: '100%',
    paddingVertical: 6,
    paddingHorizontal: 12,
    marginRight: 25
  },
  btnSearch: {
    borderRadius: 100,
    backgroundColor: '#ED1B2E',
    width: 'auto',
    paddingVertical: 5.5,
    paddingHorizontal: 29
  },
  btnSearchText: {
    fontSize: 15,
    lineHeight: 28
  }
})
