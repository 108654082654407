import * as React from 'react'
import {
  FieldList,
  Panel,
  Title,
  ErrorHandling,
  form2,
  PayoutPopup,
  formatNumberWithComma,
  MaturityAdvanceService,
  MaturityAdvanceGroup,
  TransactionType
} from '@pulseops/common'
import { Column } from '@pulseops/submission/common'
import { pipe } from 'fp-ts/function'
import { useLoading } from '@mxt/zio-react'
import {
  PayoutMethodMaturityAdvance,
  PayoutMethodMaturityAdvanceRef,
  mapCashOutOption
} from '../../payout-method-maturity-advance'
import { PolicyServiceProps } from '../policy-service-props'
import { MaturyAdvanceForm } from './maturity-advance.form'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { RequestAuthenticateData } from '../../request-authen'

interface Props extends PolicyServiceProps<MaturityAdvanceGroup.DataSubmit> {
  policyNumber: string
}

export const MaturityAdvance = ({ policyNumber, initSubmission, isConfirmed }: Props) => {
  const { t } = useTranslation()

  const [isLoadingBenefit, bindLoadingBenefit] = useLoading(false)

  const payoutRef = React.useRef<PayoutMethodMaturityAdvanceRef | null>(null)

  const detail = pipe(
    MaturityAdvanceService.getDetail(policyNumber),
    bindLoadingBenefit,
    ErrorHandling.runDidUpdate([policyNumber])
  )

  const maturityAdvForm = form2.useForm(MaturyAdvanceForm.codec, {
    defaultValues: {
      payout: []
    }
  })

  const { control, watch, setValue } = maturityAdvForm.base

  const { payout } = watch()

  const totalAmount = React.useMemo(() => payout.reduce((sum, item) => sum + item.amount, 0), [payout])

  initSubmission({
    validate: async () => {
      if (totalAmount === 0) {
        return false
      }

      const isFormValid = await maturityAdvForm.base.trigger()
      if (!isFormValid) {
        return false
      }

      return {
        url: (policyNum: string) => `wf-api/policy/${policyNum}/maturity-advance`,
        body: {
          attributesExt: {
            TOTAL_MATURITY_AMOUNT: detail?.totalMaturityAmount || 0,
            TOTAL_PAYOUT_AMOUNT: detail?.totalMaturityAmount
          },
          cashOutOptions: mapCashOutOption(payout)
        },
        collerationId: policyNumber,
        transactionName: RequestAuthenticateData.TransactionLabelShort(TransactionType.MATURITY_ADVANCE),
        transaction: TransactionType.MATURITY_ADVANCE
      }
    },
    clear: () => {
      setValue('payout', [])
      payoutRef.current?.clearData()
    }
  })

  return (
    <Column flex={1}>
      <Title title={t('submission:MATURITY_ADVANCE_INFO')} wrapperStyle={{ marginTop: 30 }} />
      <Panel isExand={false} containerStyle={{ backgroundColor: '#FAFAFA' }} isLoading={isLoadingBenefit}>
        <FieldList
          dataSource={[
            {
              label: t('submission:EstimatedTotalMaturityMenefit'),
              value: formatNumberWithComma(detail?.estimatedTotalMaturity || ''),
              suffix: 'VND'
            },
            {
              label: t('submission:PaidMaturityAdvance'),
              value: formatNumberWithComma(detail?.preMaturityAdvance || ''),
              suffix: 'VND'
            },
            {
              label: t('submission:NetAmountAvailable'),
              value: formatNumberWithComma(detail?.totalMaturityAmount || ''),
              suffix: 'VND',
              isHighlight: true,
              bold: true
            }
          ]}
        />
      </Panel>

      {detail && (
        <Controller
          control={control}
          name="payout"
          render={({ field, fieldState: { error } }) => (
            <PayoutMethodMaturityAdvance
              {...field}
              ref={payoutRef}
              editable={!isConfirmed}
              policyNum={policyNumber}
              errorMessage={
                error?.message ||
                (detail.totalMaturityAmount && totalAmount > detail.totalMaturityAmount ? t('message:MS050237') : '')
              }
              methods={[PayoutPopup.PayoutMethods.PAYPREMIUM, PayoutPopup.PayoutMethods.OTHER]}
            />
          )}
        />
      )}
    </Column>
  )
}
