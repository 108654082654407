import React, { useState } from 'react'
import { ScrollView, StyleSheet } from 'react-native'
import { useTranslation } from 'react-i18next'
import { OBFieldList } from './OBFieldList'
import { Paper, Table, TableCell, TableContainer, TableHead, TableRow, makeStyles } from '@material-ui/core'
import { OBBeneficiaryData, OBCallProgramTypeCode, OBFundData, OBPolicyData } from '../../ob-service/ob-model'
import { pipe } from 'fp-ts/lib/function'
import { OBContainer, OBGeneralService, OBTaskService } from '@pulseops/outbound'
import { ZIO } from '@mxt/zio'
import { CancelRiderService, Container, ContractMapping, ErrorHandling, Format, FrequencyMapping, FundMapping, GeneralService, PulseOpsFormat, SelectOption, useTranslator } from '@pulseops/common'
import { useLoading } from '@mxt/zio-react'
import { CallOutDetailContext } from '../ob-common'
import * as O from 'fp-ts/lib/Option'

export const OBPolicyInfo = () => {
  const { t, i18n } = useTranslation()
  const [dataPolicy, setDataPolicy] = useState<OBPolicyData | null>(null)
  const [docLink, setDocLink] = useState<string>('')
  const ct = useTranslator('contract').t
  const ft = useTranslator('frequency').t
  const [loading, bindLoader] = useLoading(false)
  const { policyNumber, caseId, transactionType } = React.useContext(CallOutDetailContext)

  const useStyles = makeStyles({
    tableHeader: {
      backgroundColor: '#F4F4F4'
    },
    tableCell: {
      minWidth: 120,
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
      color: '#70777E',
      backgroundColor: '#F4F4F4'
    },
    cellNoWrap: {
      whiteSpace: 'nowrap'
    }
  })

  React.useEffect(() => {
    pipe(
      OBGeneralService.getPolicyInfo(policyNumber, caseId),
      ZIO.map((data) => {
        setDataPolicy(data)
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )

    pipe(
      OBTaskService.getDocuments(policyNumber),
      ZIO.map((res) => {
        // const findDoc = res.data && res.data.find((item) => item.metaData.docid === '10307151')
        const findDoc = pipe(
          res.data,
          O.fromNullable,
          O.fold(
            () => undefined,
            (metaData) => pipe(
              metaData.find((item) => item.metaData.docid === '10307151'),
              O.fromNullable,
              O.fold(
                () => pipe(
                  metaData.find((item) => item.metaData.docid === '103071519'),
                  O.fromNullable,
                  O.fold(
                    () => undefined,
                    (item) => item
                  )
                ),
                (metaData) => metaData
              )
            )
          ),
        )
        if (findDoc) setDocLink(findDoc.url)
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )

  }, [policyNumber])

  const relationships =
    pipe(
      GeneralService.getRelationship(),
      ZIO.map((r) => {
        const relaList: SelectOption[] =
          r.map((item) => ({
            value: item.code,
            label: i18n.language === 'vi' ? item.relationshipVN : item.relationshipEN
          })) || []
        return relaList
      }),
      ErrorHandling.runDidUpdate([i18n.language])
    ) || []

  const riderNameList =
    pipe(
      // CancelRiderService.getRiderInfoList(),
      OBGeneralService.getProductList(),
      ZIO.map((r) => {
        return r
      }),
      ErrorHandling.runDidMount()
    )

  const getRiderName = (code: string) => {
    const contractName = riderNameList &&
      pipe(riderNameList.find((_) => _.coverageCode === code),
        O.fromNullable,
        O.map((riderItem) => i18n.language === 'en' ? riderItem.productNameEN : riderItem.productNameVN),
        O.getOrElse(() => '-'))
    return contractName
  }

  const countriesList =
    pipe(
      GeneralService.getCountries,
      ZIO.map((r) => r),
      ErrorHandling.runDidMount()
    )

  const getCountryName = (countryCode: string) => {
    if (countriesList && countriesList.length > 0)
      return countriesList.find((item) => item.code === countryCode)?.name ?? ''
    return ''
  }

  const checkHighlight = (firstIssueDate: string | null | undefined, issueDate: string | null | undefined) => {
    return !!issueDate && firstIssueDate !== issueDate
  }

  const dataField = [
    {
      title: t('Outbound:OBPolicyInfo:ContractName'),
      value: dataPolicy?.basicCode ? (dataPolicy?.basicCode + " - " + getRiderName(dataPolicy.basicCode)) : '-'
    },
    {
      title: t('Outbound:OBPolicyInfo:PolicyStatus'),
      value: dataPolicy?.statusCode ? ContractMapping.get(ct)(dataPolicy.statusCode) : '-'
    },
    {
      title: t('Outbound:OBPolicyInfo:MainLifeAssured'),
      value: dataPolicy?.mainLifeAssuredFullName || '-'
    },
    {
      title: t('Outbound:OBPolicyInfo:SumAssured'),
      value: (dataPolicy?.sumAssuredAmt ? PulseOpsFormat.thousandSepartor(dataPolicy?.sumAssuredAmt) : 0) + ' VNĐ'
    },
    {
      title: t('Outbound:OBPolicyInfo:PremiumStatus'),
      value: dataPolicy?.premiumStatusCode ? ContractMapping.get(ct)(dataPolicy.premiumStatusCode) : '-'
    },
    {
      title: t('Outbound:OBPolicyInfo:PTDAdvanced'),
      value: dataPolicy?.paidToDateAdvance ? PulseOpsFormat.dateStringtoFormat(dataPolicy.paidToDateAdvance, 'DD/MM/YYYY') : '-'
    },
    {
      title: t('Outbound:OBPolicyInfo:PTDAdvancedAtTriggerTime'),
      value: !!dataPolicy?.ptDateAdvanceDLTrigger ? PulseOpsFormat.dateStringtoFormat(dataPolicy.ptDateAdvanceDLTrigger, 'DD/MM/YYYY') : '-'
    },
    {
      title: t('Outbound:OBPolicyInfo:RiskCessationDate'),
      value: dataPolicy?.riskCessationDate ? PulseOpsFormat.dateStringtoFormat(dataPolicy.riskCessationDate, 'DD/MM/YYYY') : '-'
    },
    {
      title: t('Outbound:OBPolicyInfo:Premium'),
      value: (dataPolicy?.installmentPremiumAmt ? PulseOpsFormat.thousandSepartor(dataPolicy?.installmentPremiumAmt) : 0) + ' VNĐ'
    },
    {
      title: t('Outbound:OBPolicyInfo:PaidToDateBasic'),
      value: dataPolicy?.paidToDateBasic ? PulseOpsFormat.dateStringtoFormat(dataPolicy.paidToDateBasic, 'DD/MM/YYYY') : '-'
    },
    {
      title: t('Outbound:OBPolicyInfo:RiskCommencementDate'),
      value: dataPolicy?.riskCommenceDate ? PulseOpsFormat.dateStringtoFormat(dataPolicy.riskCommenceDate, 'DD/MM/YYYY') : '-'
    },
    {
      title: t('Outbound:OBPolicyInfo:Frequency'),
      value: dataPolicy?.frequencyCode ? FrequencyMapping.get(ft)(dataPolicy.frequencyCode) : '-'
    },
    {
      title: t('Outbound:OBPolicyInfo:PolicyIssueDate'),
      value: dataPolicy?.issueDate ? PulseOpsFormat.dateStringtoFormat(dataPolicy.issueDate, 'DD/MM/YYYY') : '-',
      textStyle: checkHighlight(dataPolicy?.firstIssueDate, dataPolicy?.issueDate) ? { color: 'red' } : {}
    },
    {
      title: t('Outbound:OBPolicyInfo:DispatchAddress'),
      value: (dataPolicy?.dispatchAddress && dataPolicy?.daCountryCode) ?
        (dataPolicy?.dispatchAddress + ' ' + (dataPolicy?.daCountryCode ? getCountryName(dataPolicy?.daCountryCode) : '')) :
        dataPolicy?.dispatchAddress || '-'
    },
    // blank column
    {
      title: '',
      value: ''
    },
    {
      title: t('Outbound:OBPolicyInfo:FirstIssueDate'),
      value: dataPolicy?.firstIssueDate ? PulseOpsFormat.dateStringtoFormat(dataPolicy.firstIssueDate, 'DD/MM/YYYY') : '-'
    }
  ]

  const checkboxList = [
    {
      title: t('Outbound:OBPolicyInfo:Letter'),
      value: dataPolicy?.consent.isEAL || false,
      urlLink: docLink,
    },
    {
      title: t('Outbound:OBPolicyInfo:OTPConsent'),
      value: dataPolicy?.consent.isOTP || false
    },
    {
      title: t('Outbound:OBPolicyInfo:ReceiveZalo'),
      value: dataPolicy?.consent.isZalo || false
    },
    {
      title: t('SMS'),
      value: dataPolicy?.consent.isSms || false
    },
    {
      title: t('Email'),
      value: dataPolicy?.consent.isEmail || false
    }
  ]

  const columnsFund = [
    t('Outbound:OBPolicyInfo:FundName'),
    t('Outbound:OBPolicyInfo:UnitBalance'),
    t('Outbound:OBPolicyInfo:EstimateFund'),
    t('Outbound:OBPolicyInfo:OfferPrice'),
    t('Outbound:OBPolicyInfo:DateLastUpdated')
  ]

  const columnsBeneficiary = [
    t('Outbound:OBPolicyInfo:BeneficiaryName'),
    t('Outbound:OBPolicyInfo:DateOfBirth'),
    t('Outbound:OBPolicyInfo:Sex'),
    t('Outbound:OBPolicyInfo:Percentage'),
    t('Outbound:OBPolicyInfo:Relationship')
  ]

  const rowFund: OBFundData[] = dataPolicy?.funds || []

  const rowBeneficiary: OBBeneficiaryData[] = dataPolicy?.bens || []

  const classes = useStyles()

  const getGenderName = (gender?: string | null): string => {
    switch (gender) {
      case 'M':
        return t('common:Male')
      case 'F':
        return t('common:Female')
      default:
        return '-'
    }
  }

  const getRelationShipFromCode = (codeRelationShip: string) => {
    return relationships.find((i) => i.value === codeRelationShip)?.label || '-'
  }

  const convertNumber = (value: number) => {
    const getLengthAfterDot = value.toString().split('.')[1]?.length ?? 0
    return PulseOpsFormat.currencyGetFull(value, getLengthAfterDot)
  }

  const TableTitleComlumn = (columns: string[]) => {
    return (
      <TableRow>
        {columns.map((item) =>
          <TableCell className={classes.tableCell}>{item}</TableCell>
        )}
      </TableRow>
    )
  }

  const TableRowFundData = (row: OBFundData[]) => {
    return row.map((item) =>
      <TableRow>
        <TableCell className={classes.cellNoWrap}>{item.fundCode ? t(FundMapping.getFunds(item.fundCode)) : '-'}</TableCell>
        <TableCell className={classes.cellNoWrap}>{item.unitBalanceAmt ? convertNumber(item.unitBalanceAmt) : '-'}</TableCell>
        <TableCell className={classes.cellNoWrap}>{item.estFundAmt ? convertNumber(item.estFundAmt) : '-'}</TableCell>
        <TableCell className={classes.cellNoWrap}>{item.offerPriceAmt ? convertNumber(item.offerPriceAmt) : '-'}</TableCell>
        <TableCell className={classes.cellNoWrap}>{item.latestUpdateDate ? PulseOpsFormat.dateStringtoFormat(item.latestUpdateDate, 'DD/MM/YYYY') : '-'}</TableCell>
      </TableRow>
    )
  }

  const TableRowBenefiData = (row: OBBeneficiaryData[]) => {
    return row.map((item) =>
      <TableRow>
        <TableCell className={classes.cellNoWrap}>{item.fullName || '-'}</TableCell>
        <TableCell className={classes.cellNoWrap}>{item.dob ? PulseOpsFormat.dateStringtoFormat(item.dob, 'DD/MM/YYYY') : '-'}</TableCell>
        <TableCell className={classes.cellNoWrap}>{getGenderName(item.genderCode)}</TableCell>
        <TableCell className={classes.cellNoWrap}>{item.percentage ? item.percentage + '%' : '-'}</TableCell>
        <TableCell className={classes.cellNoWrap}>{item.relationshipCode ? getRelationShipFromCode(item.relationshipCode) : '-'}</TableCell>
      </TableRow>
    )
  }

  const PolicyInfo = () => {
    return (
      <OBContainer containerTitle={t('Outbound:OBPolicyInfo:Policy')}>
        <OBFieldList dataSource={dataField} checkboxList={checkboxList}></OBFieldList>
      </OBContainer>
    )
  }

  const FundValue = () => {
    return (
      <OBContainer containerTitle={t('Outbound:OBPolicyInfo:FundValue')}>
        <TableContainer component={Paper} style={{ maxHeight: 500, marginLeft: 20, width: 'auto' }} elevation={1}>
          <Table stickyHeader>
            <TableHead className={classes.tableHeader}>
              {TableTitleComlumn(columnsFund)}
            </TableHead>
            {TableRowFundData(rowFund)}
          </Table>
        </TableContainer>
      </OBContainer>
    )
  }

  const BeneficiaryInfo = () => {
    return (
      <OBContainer containerTitle={t('Outbound:OBPolicyInfo:BeneficiaryInfo')}>
        <TableContainer component={Paper} style={{ maxHeight: 500, marginLeft: 20, width: 'auto' }} elevation={1}>
          <Table stickyHeader>
            <TableHead className={classes.tableHeader}>
              {TableTitleComlumn(columnsBeneficiary)}
            </TableHead>
            {TableRowBenefiData(rowBeneficiary)}
          </Table>
        </TableContainer>
      </OBContainer>
    )
  }

  return (
    <Container loading={loading}>
      <ScrollView style={policyStyles.scrollView}>
        {PolicyInfo()}
        {transactionType !== OBCallProgramTypeCode.IDC && FundValue()}
        {BeneficiaryInfo()}
      </ScrollView>
    </Container>
  )
}

const policyStyles = StyleSheet.create({
  scrollView: {
    width: '100%',
    paddingTop: 24
  }
})