import {
  Select,
  useMobile,
  Panel,
  TaskType,
  TaskDetail,
  assets,
  PulseOpsFormat,
  GeneralService,
  ErrorHandling,
  HistoryLetter,
  LogFinalLetter,
  ControlProps,
  TASK_DETAIL_ROLES,
  StorageBlobApi,
  replaceSpecialCharactersInFileName,
  AuthService,
  Input,
  ModalComponent,
  CanView,
  SelectOption,
  FinalLetterService,
  SubClaimType,
  ClaimTypeInfo,
  SubClaimTypeBenefit,
  HospitalType,
  VerClaimType,
  CriticalIllnessType,
  ICDType,
  AppContext
} from '@pulseops/common'
import { Error } from '@pulseops/submission/common'
import * as React from 'react'
import {
  SafeAreaView,
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  TouchableHighlight,
  Switch,
  ActivityIndicator
} from 'react-native'
import { useTranslation } from 'react-i18next'
import { Controller, UseFormReturn } from 'react-hook-form'
import { FinalLetterForm, CalculationForm, FileUploadData, BenefitDetailForm } from './AdjudicationForm'
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination
} from '@material-ui/core'
import { FontAwesome5 } from '@expo/vector-icons'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import {
  LetterTemplate,
  LetterTypeMinor,
  TemaplateType,
  LetterTypeMajor,
  BenifitClaimCode,
  ClaimCodeType,
  PartialPaymentStatus,
  ReasonCode
} from './FinalLetterTempConst'
import { TaskDetailTab } from '../../data'
import { isEmpty } from 'lodash'
import moment from 'moment'
import * as D from 'date-fns'
import i18next from 'i18next'
import { useLoading } from '@mxt/zio-react'
interface Props {
  props: {
    faceAmount: string
    payload: TaskDetail.VerificationClaim
    claimNumber: string
    decision: {
      value: string
      label: string
    }[][]
    category: TaskType
    declinesOptions: {
      value: string
      label: string
    }[]
    policyNum: string
  }
  form: UseFormReturn<FinalLetterForm>
  formCalculation: UseFormReturn<CalculationForm>
  disabled?: boolean
  setIsCanChangeTab: (isCanChangeTab: boolean) => void
  finalLetter: LogFinalLetter
  roles: string[]
  onChangeParentTab: (val: number) => void
  onChangeSubTab: (val: number) => void
  onSeenLetter: (val: boolean) => void
  onGenerateLetter: (val: boolean) => void
  onSave: (val?: boolean) => void
  benefitDetailInfo: BenefitDetailForm[]
  claimTypeInfo: SubClaimType | null
  claimData: {
    accidents: VerClaimType[] | null
    claimTypes?: SubClaimType[] | null
    subClaimTypes?: SubClaimTypeBenefit[] | null
    tpdTypes?: VerClaimType[] | null
    ciTypes?: CriticalIllnessType[] | null
    icdTypes: ICDType[] | null
    hospitals: HospitalType[] | null
  }
  refUpload: React.Ref<uploadRef>
  handldeChangeRef: (val: boolean) => void
  refTemplate: React.Ref<uploadRef>
  handleGetTemplate: (file: FileList) => void
  onHistoryLetter: (val: HistoryLetter[]) => void
  claimDecision: {
    value: string
    label: string
  } | null
}

type ImageUploadProps = ControlProps<FileUploadData[]> & {
  transactionType?: string
  maxNumFile?: number
  timeFormat?: string
  roles: string[]
}

const MB = 1048576

const useStyles = makeStyles({
  tableHeader: {
    backgroundColor: '#D3DCE6'
  },
  tableBody: {
    backgroundColor: '#FAFAFA'
  }
})
const TemplateName = 'generateletter'

const TemplateFileType = '.pdf'

export const FinalLetter: React.FC<Props> = ({
  props,
  form,
  formCalculation,
  disabled,
  setIsCanChangeTab,
  finalLetter,
  roles,
  onChangeParentTab,
  onChangeSubTab,
  onSeenLetter,
  onGenerateLetter,
  onSave,
  benefitDetailInfo,
  claimTypeInfo,
  claimData,
  refUpload,
  handldeChangeRef,
  refTemplate,
  handleGetTemplate,
  onHistoryLetter,
  claimDecision
}) => {
  const { t } = useTranslation(['Adjudication', 'claim'])
  const { control } = form
  const [historyLetter, setHistory] = React.useState<HistoryLetter[]>([])
  const { isWide } = useMobile()
  const { payload, category, claimNumber } = props
  const classes = useStyles()
  const [pageNum, setPageNum] = React.useState<number>(0)
  const [pageSize, setPageSize] = React.useState<number>(10)

  const validExtensions = ['PNG', 'JPG', 'JPEG', 'PDF', 'TIF', 'TIFF']
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const [isOpenModalReject, setIsOpenModalReject] = React.useState<boolean>(false)
  const [loadingReview, setLoadingReview] = React.useState<boolean>(false)
  const listClaim: ClaimTypeInfo[] = pipe(GeneralService.getClaims, ErrorHandling.runDidMount([])) || []
  const { showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  // const addMoreRef = React.useRef<uploadRef | null>(null)
  // const uploadRef = React.useRef<uploadRef | null>(null)

  const [loading, bindLoading] = useLoading()

  React.useEffect(() => {
    showGlobalLoading(loading)
  }, [loading])

  pipe(
    GeneralService.getHistoryLetter(payload?.detail?.processInstanceId || '-'),
    ZIO.map((res) => {
      if (res) {
        let response = res.sort((a, b) => Number(new Date(a.createdDate)) - Number(new Date(b.createdDate)))
        setHistory(response.reverse())
        onHistoryLetter?.(response)
      }
    }),
    bindLoading,
    ErrorHandling.runDidMount()
  )

  React.useEffect(() => {
    if (finalLetter?.createdDate) {
      pipe(
        GeneralService.getHistoryLetter(payload?.detail?.processInstanceId || '-'),
        ZIO.map((res) => {
          if (res && res.length) {
            const response = res.sort(
              (a: HistoryLetter, b: HistoryLetter) => Number(new Date(a.createdDate)) - Number(new Date(b.createdDate))
            )
            setHistory(response.reverse())
            onHistoryLetter(response)
          }
        }),
        ZIO.unsafeRun({})
      )
    }
  }, [finalLetter])
  React.useEffect(() => {
    form.setValue('fileAttached', [])
    form.setValue('fileTemplate', [])

    if (claimDecision?.value && claimDecision?.value !== 'DS02') {
      form.setValue('letterContent', t('claim:FinalLetterContentDefault'))
    } else {
      form.setValue('letterContent', '')
    }
    onSeenLetter(false)
    form.setValue('skipReview', false)
  }, [])

  const start = pageNum * pageSize
  const end =
    (pageNum + 1) * pageSize > historyLetter.length
      ? historyLetter.length + 1
      : Math.min((pageNum + 1) * pageSize, historyLetter.length)

  const viewUploadFile = (uploadedUrl: string) => {
    !!uploadedUrl &&
      pipe(
        ZIO.zipPar(
          ZIO.succeed(uploadedUrl),
          StorageBlobApi.getRemoteConfig(category, claimNumber),
          AuthService.token,
          AuthService.getLoginType
        ),
        ZIO.flatMap(([url, storageConfig, token, loginType]) => {
          return pipe(
            ZIO.fromPromise(() => {
              const apiUrl = url.split('?')
              const storageUrl = apiUrl[0]
              const paramInfo = apiUrl[1]
              const paramList = paramInfo.split('&')
              const containerName = !!paramList[0] ? paramList[0].split('=')[1] : ''
              const blobName = !!paramList[1] ? paramList[1].split('=')[1] : ''
              return fetch(storageUrl, {
                method: 'GET',
                headers: {
                  'Cache-Control': 'no-cache',
                  Pragma: 'no-cache',
                  Container: containerName,
                  Token: storageConfig.sas,
                  Blob: blobName,
                  Authorization: `Bearer ${token}`,
                  'X-Authen-Vendor': loginType
                }
              })
            })
          )
        }),
        ZIO.flatMap((res) =>
          ZIO.zipPar(
            ZIO.succeed(res),
            ZIO.fromPromise(() => res.blob())
          )
        ),
        ZIO.map(([response, blob]) => {
          if (blob) {
            const imageFile = window.URL.createObjectURL(blob)
            window.open(imageFile, '_blank')
          }
        }),
        ErrorHandling.run()
      )
  }

  const getLetterTemplate = (): SelectOption[] => {
    if (
      props.payload.detail.payload.body?.subClaimType === LetterTypeMinor.subClaimType &&
      props.payload.detail.payload.body.claimType === LetterTypeMinor.claimType
    ) {
      return LetterTemplate[LetterTypeMinor.templeteType as TemaplateType].map((item) => ({
        label: t(`${item.label}`),
        value: item.value
      }))
    }
    return LetterTemplate[LetterTypeMajor.templeteType as TemaplateType].map((item) => ({
      label: t(`${item.label}`),
      value: item.value
    }))
  }

  const OptionLetterTemplate = React.useMemo(() => getLetterTemplate(), [props.payload.detail.payload.body.claimType])

  const handleAcceptAction = () => {
    if (!handleCheckExistFile()) {
      return
    }

    onSave()
    // uploadRef.current?.disableUpload(false)
    handldeChangeRef(false)
    setIsOpen(false)
  }

  const handleGenerateLetter = () => {
    if (!form.watch('skipReview')) {
      setIsOpen(true)
    } else {
      openViewTemple()
    }
  }

  const claimBenifit = pipe(
    GeneralService.getAllSubBenefit(),
    ZIO.map((subBenefitList) => {
      return subBenefitList
        ?.filter((subBenefit) => subBenefit.benefit === 'BNF01')
        .map((item) => ({
          value: item.subBenefitCode,
          label: item.subBenefitVN
        }))
    }),
    ErrorHandling.runDidUpdate([])
  )

  const getDiagnosis = pipe(
    GeneralService.getICDName(),
    ZIO.map((subBenefitList) => {
      return subBenefitList.map((item) => ({
        value: item.icdcode,
        label: item.diseaseNameVn
      }))
    }),
    ErrorHandling.runDidUpdate([])
  )
  // 'Ex-gratia'
  // console.log(claimBenifit, 'claimBenifit')
  // console.log(getDiagnosis, 'getDiagnosis')
  // console.log(payload, 'payload')
  // console.log(benefitDetailInfo, 'benefitDetailInfo')
  const openViewTemple = () => {
    if (!handleCheckExistFile()) {
      return
    }
    const HS_ICU = benefitDetailInfo.filter((item) =>
      item.benefitClaimCode.find(
        (it) =>
          it.benefitClaimCode === BenifitClaimCode.ICU && it.adjudicatorDecision?.value === ReasonCode.acceptPartial
      )
    )
    const HS_QL = benefitDetailInfo.filter((item) =>
      item.benefitClaimCode.find(
        (it) =>
          it.benefitClaimCode === BenifitClaimCode.HS && it.adjudicatorDecision?.value === ReasonCode.acceptPartial
      )
    )
    const today = new Date()
    const durationDaysICU: string | undefined =
      payload?.detail?.payload?.verification?.subBenefit
        ?.find((_) => _.value === 'SBC01')
        ?.receiptItems.find((_) => _.receiptItemCode.value === 'RCI02')?.duration || '0'
    const expiredDate = new Date(payload.policyRider.effectiveDate).setFullYear(
      new Date(payload.policyRider.effectiveDate).getFullYear() + 1
    )
    const expiredDateMinsOne = new Date(expiredDate).setUTCDate(new Date(expiredDate).getDate() - 1)

    const durationHospitalization: string | undefined = payload?.PATIENT_IN
      ? D.differenceInDays(
          moment(payload?.PATIENT_IN?.dischargeDate, 'DD/MM/YYYY').toDate(),
          moment(payload?.PATIENT_IN?.admissionDate, 'DD/MM/YYYY').toDate()
        ).toString()
      : '0'

    let causeList: string[] = []
    let diagnosisList: string[] = []
    let claimEventDate = ''
    switch (claimTypeInfo?.claimTypeCode) {
      case ClaimCodeType.CI:
        if (typeof payload.detail.payload.body.attributes.CRITICAL_ILLNESS?.ciName === 'object') {
          const ids = payload.detail.payload.body.attributes.CRITICAL_ILLNESS?.ciName?.map((item) => item.value) ?? []
          causeList = ids.map((id) => claimData?.ciTypes?.find((it) => it.cilCode === id)?.nameVn ?? '')
        }
        if (typeof payload.detail.payload.body.attributes.CRITICAL_ILLNESS?.diagnosis === 'object') {
          const ids =
            payload.detail.payload.body.attributes.CRITICAL_ILLNESS?.diagnosis?.map((item) => item.value) ?? []
          diagnosisList = ids.map((id) => getDiagnosis?.find((it) => it.value === id)?.label ?? '')
        }
        claimEventDate = payload.detail.payload.body.attributes.CRITICAL_ILLNESS?.claimEventDate ?? ''
        break
      case ClaimCodeType.Death:
        // "Death"
        causeList = ['Tử vong']
        claimEventDate = payload.detail.payload.body.attributes.DEATH?.claimEventDate ?? ''
        if (typeof payload.detail.payload.body.attributes.DEATH?.cause === 'object') {
          const ids = payload.detail.payload.body.attributes.DEATH?.cause?.map((item) => item.value) ?? []
          diagnosisList = ids.map((id) => getDiagnosis?.find((it) => it.value === id)?.label ?? '')
        }
        break
      case ClaimCodeType.TPD:
        if (typeof payload.detail.payload.body.attributes.DISABILITY?.tpdName === 'object') {
          const ids = payload.detail.payload.body.attributes.DISABILITY?.tpdName?.map((item) => item.value) ?? []
          causeList = ids.map((id) => claimData?.tpdTypes?.find((it) => it.code === id)?.nameVi ?? '')
        }
        if (typeof payload.detail.payload.body.attributes.DISABILITY?.cause === 'object') {
          const ids = payload.detail.payload.body.attributes.DISABILITY?.cause?.map((item) => item.value) ?? []
          diagnosisList = ids.map((id) => getDiagnosis?.find((it) => it.value === id)?.label ?? '')
        }
        claimEventDate = payload.detail.payload.body.attributes.DISABILITY?.claimEventDate ?? ''
        // "Total Permanent Disability"
        break
      default:
        break
    }

    const idsBenifitClaim = payload.detail.payload?.verification?.subBenefit?.map((item) => item.value)

    let benifitClaim: string[] = []
    // console.log(idsBenifitClaim, 'idsBenifitClaim')
    if (!isEmpty(idsBenifitClaim)) {
      benifitClaim = idsBenifitClaim?.map((id) => claimBenifit?.find((item) => item.value == id)?.label ?? '') ?? []
    }
    // console.log(diagnosisList, 'diagnosisList')
    const admissionDate =
      payload?.PATIENT_IN?.admissionDate ||
      payload?.PATIENT_OUT?.admissionDate ||
      payload?.PATIENT_MATERNITY?.admissionDate ||
      payload?.PATIENT_DENTAL?.admissionDate
        ? (payload?.PATIENT_IN?.admissionDate ||
            payload?.PATIENT_OUT?.admissionDate ||
            payload?.PATIENT_MATERNITY?.admissionDate ||
            payload?.PATIENT_DENTAL?.admissionDate) ??
          ''
        : ''

    const data = {
      body: {
        partialPaymentQLHS: !isEmpty(HS_QL) ? PartialPaymentStatus.Accept : PartialPaymentStatus.NoAccept,
        partialPaymentQLICU: !isEmpty(HS_ICU) ? PartialPaymentStatus.Accept : PartialPaymentStatus.NoAccept,
        customerName: payload.detail.payload.body.attributes.CLAIM_ANT_NAME ?? '',
        addresses: [payload.detail.payload.body.attributes.CLAIM_ANT_ADDRESS],
        phoneNumber: payload.detail.payload.body.attributes.CLAIM_ANT_PHONE,
        dayTime: today.getUTCDate(),
        monthTime: today.getUTCMonth() + 1,
        yearTime: today.getFullYear(),
        claimNo: payload.claimNumber,
        policyNo: payload.policyRider.policyNo,
        POName: payload.policy.owners.name ?? '',
        LAName: payload.detail.payload.body.lifeAssured.name ?? '',
        dateOfAdmission: admissionDate,
        dischargeDate: payload?.PATIENT_IN?.dischargeDate ? payload?.PATIENT_IN?.dischargeDate : '',
        assuranceType: listClaim?.find((item) => item?.claimCode === claimTypeInfo?.claimTypeCode)?.claimTypeVN || '',
        productName: payload.detail.payload.adjudication?.productName ?? '',
        durationHSDays: durationHospitalization ?? '0',
        durationICUDays: durationDaysICU ?? '0',
        contractValidityDate: moment(payload.policyRider.effectiveDate).format('DD/MM/yyyy'),
        contractExpiredDate: moment(expiredDateMinsOne).format('DD/MM/yyyy'),
        acceptPaymentOfInsuranceBenefits: benefitDetailInfo
          .filter((item) =>
            item.benefitClaimCode.find(
              (it) =>
                it.adjudicatorDecision?.value === ReasonCode.accept ||
                it.adjudicatorDecision?.value === ReasonCode.acceptPartial
            )
          )
          .map((acc) => ({
            processInstanceId: payload.detail.processInstanceId ?? '',
            policyNumber: payload.detail.policyNumber ?? '',
            productCode: acc.productCode ?? '',
            planName: acc.planName,
            componentCode: acc.benefitClaimCode[0].componentCode ?? '',
            benefitClaimCode: acc.benefitClaimCode[0].benefitClaimCode ?? '',
            nameBenefitAssurance: acc.benefitClaimCode[0].benefitClaimVN,
            durationICU: durationDaysICU ?? '0',
            durationHospitalization: durationHospitalization ?? '0',
            sumAssured: acc.benefitClaimCode[0].sumAssure ?? '0',
            benefitClaimAmount: acc.benefitClaimCode[0].claimBenefitAmount ?? '',
            claimDecisionCode: acc.benefitClaimCode[0].adjudicatorDecision?.value ?? '',
            insuredAmount: acc.benefitClaimCode[0].sumAssure ?? '0',
            timesToBePaid: '',
            insuranceBenefitsCovered: acc.benefitClaimCode[0].claimBenefitAmount ?? '0'
          })),
        amountInLetters: '',
        declines: benefitDetailInfo
          .filter((item) => item.benefitClaimCode.find((it) => it.adjudicatorDecision?.value === ReasonCode.decline))
          .map((acc) => ({
            nameAssuranceBenefit: acc.benefitClaimCode[0].benefitClaimVN ?? '',
            declineCode: acc.benefitClaimCode[0].declineReason?.value ?? '',
            anotherDeclineCode:
              props.declinesOptions.find(
                (item) => item.value === acc.benefitClaimCode?.[0]?.anotherDeclineReason?.value
              )?.value ?? '',
            freeText:
              acc.benefitClaimCode[0].declineReason?.value === 'EX99' ||
              acc.benefitClaimCode?.[0]?.anotherDeclineReason?.value === 'EX99'
                ? acc.benefitClaimCode[0].remarks
                : acc.benefitClaimCode[0].noteDeclineReason ?? '',
            hospitalName: claimTypeInfo?.claimTypeCode === ClaimCodeType.MED ? payload?.PATIENT_IN?.hospitalLabel : '',
            diagnosis:
              claimTypeInfo?.claimTypeCode === ClaimCodeType.MED
                ? payload?.PATIENT_IN?.diagnosisLabel ?? ''
                : diagnosisList.join(', '),
            subBenefitNames: benifitClaim,
            productCode: acc.productCode,
            benefitClaimCode: acc.benefitClaimCode[0].benefitClaimCode ?? ''
          })),
        humanitarianPayments: benefitDetailInfo
          .filter((item) => item.benefitClaimCode.find((it) => it.adjudicatorDecision?.value === ReasonCode.Exgratia))
          .map((acc) => ({
            nameBenefitAssurance: acc.benefitClaimCode[0].benefitClaimVN,
            insuredAmount: acc.benefitClaimCode[0].sumAssure ?? '0',
            insuranceBenefitsCovered: acc.benefitClaimCode[0].CMPExGAmount
              ? acc.benefitClaimCode[0].CMPExGAmount?.replace(/,/g, '')
              : '0'
          })),
        letterContent: form.getValues('letterContent').replace(/\n/g, '</br>'),
        deadline: 3,
        eventDate: claimTypeInfo?.claimTypeCode === ClaimCodeType.MED ? admissionDate : claimEventDate ?? '',
        cause: causeList.join(', '),
        processInstanceId: payload.detail.processInstanceId ?? ''
      }
    }

    pipe(
      props.payload.detail.payload.body?.subClaimType === LetterTypeMinor.subClaimType &&
        props.payload.detail.payload.body.claimType === LetterTypeMinor.claimType
        ? FinalLetterService.generateMinorLetterTemplate(data)
        : FinalLetterService.generateMajorLetterTemplate(data),
      ZIO.flatMap((res) => {
        return ZIO.zipPar(
          ZIO.succeed(res),
          ZIO.fromPromise(() => res.blob())
        )
      }),
      ZIO.tap(([res, blob]) => {
        const FileName = '_' + TemplateName + TemplateFileType
        handleGetTemplate([new File([blob], FileName, { type: blob.type })] as unknown as FileList)
        const fileString = window.URL.createObjectURL(blob)
        window.open(fileString, '_blank')
        onSeenLetter(true)
        onGenerateLetter(true)
        handldeChangeRef(true)
        return ZIO.unit
      }),
      ZIO.unsafeRun({})
    )
  }

  const generateLetterType = (item: {
    documentDetails:
      | {
          name: string
          url: string
          id: string | undefined | null
        }[]
      | undefined
      | null
  }) => {
    let listUrl = item?.documentDetails?.filter((item) => !item.name.includes(TemplateName))

    let url = listUrl?.[0]?.url

    return !!url ? t('claim:ManualLetter') : t('claim:DigitalLetter')
  }

  const handleCheckExistFile = () => {
    if (!isEmpty(form.watch('fileAttached'))) {
      form.setValue('fileTemplate', [])
      showToast(t('message:MS070028'), 'error')

      return false
    }
    return true
  }
  console.log(historyLetter, 'historyLetter')
  return (
    <SafeAreaView>
      <View>
        <Text style={[style.sectionTitle, { paddingBottom: 10 }]}>{t('FinalLetter')}</Text>
        <Panel title={''} isExand={false} allowToggle={disabled}>
          <View style={style.sectionRow}>
            <View style={style.col_3}>
              <Controller
                name="policyNumber"
                control={control}
                defaultValue={props.policyNum}
                render={({ field: { onChange, onBlur }, fieldState: { error } }) => (
                  <Select
                    options={[
                      {
                        label: props.policyNum,
                        value: props.policyNum
                      }
                    ]}
                    label={t('common:PolicyNumber')}
                    value={{
                      label: props.policyNum,
                      value: props.policyNum
                    }}
                    onChange={onChange}
                    onBlur={onBlur}
                    required
                    errorMessage={error?.message}
                  />
                )}
              />
            </View>
            <View style={style.col_3}>
              <Controller
                name="letterTemplate"
                control={control}
                defaultValue={OptionLetterTemplate?.[0]}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <Select
                    options={OptionLetterTemplate}
                    label={t('claim:letterTemplate')}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    required
                    errorMessage={error?.message}
                  />
                )}
              />
            </View>
          </View>
          <View style={{ flexDirection: 'column', paddingTop: 20 }}>
            <Text style={{ fontWeight: 'bold', color: 'rgb(112, 119, 126)', paddingBottom: 10 }}>
              {t('AttachLetter')}
              {/* <Text style={{ color: 'red', marginLeft: 5 }}>*</Text> */}
            </Text>
            <Text style={{ fontSize: 15, opacity: 0.6, fontStyle: 'italic' }}>
              {t('common:ImageFormatOneFile', { fileExtension: validExtensions.join(', ') })}
            </Text>
            <View style={[style.sectionContainer]}>
              <View style={[style.contentText]}>
                <Text style={style.titleSectionText}>{t('ClaimFinalLetter')}</Text>
              </View>
              <View style={{ flexDirection: 'column', marginBottom: 20 }}>
                {isWide && (
                  <>
                    <Controller
                      control={control}
                      name="fileAttached"
                      render={({ field, fieldState: { error } }) => (
                        <View>
                          <AddMore
                            {...field}
                            roles={roles}
                            value={field.value as any[]}
                            errorMessage={error?.message || ''}
                            ref={refUpload}
                          />
                          {error?.message && (
                            <Error message={`${t('message:MS020009', { field: t('AttachLetter') })}`} />
                          )}
                        </View>
                      )}
                    ></Controller>
                  </>
                )}
              </View>
              <View style={{ display: 'none' }}>
                {isWide && (
                  <>
                    <Controller
                      control={control}
                      name="fileTemplate"
                      render={({ field, fieldState: { error } }) => (
                        <View>
                          <AddMore
                            {...field}
                            roles={roles}
                            value={field.value as any[]}
                            errorMessage={error?.message || ''}
                            ref={refTemplate}
                          />
                          {error?.message && (
                            <Error message={`${t('message:MS020009', { field: t('AttachLetter') })}`} />
                          )}
                        </View>
                      )}
                    ></Controller>
                  </>
                )}
              </View>
            </View>
          </View>
        </Panel>
      </View>
      <View>
        <View
          style={{
            flex: 1,
            justifyContent: 'space-between',
            flexDirection: 'row',
            alignItems: 'center',
            alignContent: 'center'
          }}
        >
          <Text style={[style.sectionTitle, { paddingBottom: 10, textTransform: 'capitalize' }]}>
            {t('claim:LetterContent')}
          </Text>
          <View style={[style.sectionRow, { flexDirection: 'row', alignItems: 'center', alignContent: 'center' }]}>
            <Text style={{ color: '#000000', fontWeight: '400', fontSize: 15, paddingRight: 15 }}>Skip Review:</Text>
            <Controller
              name="skipReview"
              control={control}
              defaultValue={false}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <Switch
                  trackColor={{ false: '#BDBDBD', true: '#ED1B2E' }}
                  thumbColor={'#FFFFFF'}
                  // it works for web
                  activeThumbColor="#FFFFFF"
                  ios_backgroundColor="#3e3e3e"
                  style={{ backgroundColor: value ? '#ED1B2E' : '#BDBDBD', borderRadius: 17 }}
                  onValueChange={onChange}
                  value={value}
                />
              )}
            />
            <View>
              <TouchableOpacity style={[style.buttonStyle, { marginLeft: 15 }]} onPress={() => handleGenerateLetter()}>
                {t('claim:GenerateLetter')}
              </TouchableOpacity>
            </View>
          </View>
        </View>
        <Controller
          control={control}
          name="letterContent"
          render={({ field }) => (
            <Input
              required
              multiline
              maxLength={1000}
              numberOfLines={6}
              inputStyle={{ paddingLeft: 10 }}
              onChange={(val) => field.onChange(val)}
              isAllowSpecialChar={false}
              regexParten={/[<]/}
              value={field.value}
            />
            // /[`~,.<>;':"/[\]|{}()=_+-]/
          )}
        />
      </View>
      <View>
        <Text style={[style.sectionTitle, { paddingBottom: 10 }]}>{t('FinalLetterHistory')}</Text>
        {historyLetter.length > 0 && (
          <View style={{ marginTop: 30 }}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead className={classes.tableHeader}>
                  <TableRow>
                    <TableCell style={{ fontWeight: 'bold' }}>{t('CreateUser')}</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>{t('CreateDate')}</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>{t('Status')}</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>{t('claim:LetterType')}</TableCell>
                    <TableCell style={{ fontWeight: 'bold', textAlign: 'center' }}>{t('claim:ViewLetter')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={classes.tableBody}>
                  {historyLetter
                    // .slice(pageNum * pageSize, Math.min(((pageNum + 1) * pageSize), historyLetter.length))
                    .slice(start, end)
                    .map((item, key) => {
                      return (
                        <TableRow key={key}>
                          <TableCell component="th" scope="row">
                            {item.createdBy}
                          </TableCell>
                          <TableCell>
                            {item?.createdDate
                              ? PulseOpsFormat.datetoFormat(new Date(item?.createdDate), 'DD-MM-YYYY')
                              : '-'}
                          </TableCell>
                          <TableCell>{item.status === 'Deactivate' ? 'Inactive' : 'Active'}</TableCell>
                          <TableCell>{generateLetterType(item)}</TableCell>
                          {/* Letter */}
                          <TableCell>
                            <TouchableOpacity
                              onPress={() => {
                                let url = item.documentDetails?.[0]?.url
                                viewUploadFile(url ?? '')
                              }}
                            >
                              <Text style={{ textAlign: 'center' }}>
                                <FontAwesome5 name="eye" color="#ED1B2E" />
                              </Text>
                            </TouchableOpacity>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              page={pageNum}
              rowsPerPage={pageSize}
              count={historyLetter.length}
              onPageChange={(e, page) => {
                setPageNum(page)
              }}
              component={View}
              onRowsPerPageChange={(e) => {
                setPageSize(Number(e.target.value))
                setPageNum(0)
              }}
              labelRowsPerPage={t('common:PaginationSize')}
              labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
            />
          </View>
        )}
      </View>
      <ModalComponent
        title={t('claim:ReviewLetter')}
        visible={isOpen}
        onClose={() => {
          setIsOpen(false)
        }}
        modalWidth={442}
        titleStyle={{ color: '#000000' }}
        actions={[
          {
            text: t('claim:Accept'),
            type: 'filled',
            disabled: false,
            loading: false,
            onlyWide: false,
            style: { height: 39, marginEnd: 15 },
            action: () => {
              handleAcceptAction()
            }
          },
          {
            text: t('claim:Reject'),
            type: 'outline',
            disabled: false,
            loading: false,
            onlyWide: false,
            style: { height: 39 },
            action: () => {
              setIsOpen(false)
              setIsOpenModalReject(true)
            }
          }
        ]}
      >
        <View
          style={{
            marginHorizontal: 20,
            marginTop: isWide ? 20 : 16
          }}
        >
          <View style={{ width: '100%' }}>
            {loadingReview ? (
              <CanView condition={loadingReview}>
                <View style={style.loadingContainer}>
                  <ActivityIndicator size="large" color="red" />
                </View>
              </CanView>
            ) : (
              <View>
                <View>
                  <Text onPress={() => openViewTemple()} style={{ textDecorationLine: 'underline', color: '#1EA5FC' }}>
                    {' '}
                    {props.payload.detail.payload.body?.subClaimType === LetterTypeMinor.subClaimType &&
                    props.payload.detail.payload.body.claimType === LetterTypeMinor.claimType
                      ? t('Adjudication:TemplalteMinor')
                      : t('Adjudication:TemplalteMajor')}{' '}
                  </Text>
                </View>
              </View>
            )}
          </View>
        </View>
      </ModalComponent>
      <ModalComponent
        title={''}
        visible={isOpenModalReject}
        onClose={() => {
          setIsOpenModalReject(false)
        }}
        modalWidth={i18next.language === 'en' ? 442 : 550}
        titleStyle={{ color: '#000000' }}
        actions={[
          {
            text: t('claim:ClaimTypeTitle'),
            type: 'filled',
            disabled: false,
            loading: false,
            onlyWide: false,
            style: { height: 39, marginEnd: 15 },
            action: () => {
              setIsOpenModalReject(false)
              onChangeParentTab(TaskDetailTab.CLAIM_INFO_TAB)
            }
          },
          {
            text: t('Adjudication:FinalDecision'),
            type: 'outline',
            disabled: false,
            loading: false,
            onlyWide: false,
            style: { height: 39 },
            action: () => {
              setIsOpenModalReject(false)
              onChangeSubTab(0)
            }
          }
        ]}
      >
        <View
          style={{
            marginHorizontal: 20,
            marginTop: isWide ? 20 : 16
          }}
        >
          <View style={{ width: '100%' }}>
            {loadingReview ? (
              <CanView condition={loadingReview}>
                <View style={style.loadingContainer}>
                  <ActivityIndicator size="large" color="red" />
                </View>
              </CanView>
            ) : (
              <View>
                <View>
                  <Text style={{ color: '#000000E5', fontSize: 15 }}> {t('message:MSRejectDecisionFinal')} </Text>
                </View>
              </View>
            )}
          </View>
        </View>
      </ModalComponent>
    </SafeAreaView>
  )
}
export interface uploadRef {
  getFileTemple: (files: FileList) => void
  click: () => void
  removeFile: () => void
  disableUpload: (val: boolean) => void
}
export const AddMore = React.forwardRef((props: ImageUploadProps, ref: React.Ref<uploadRef>) => {
  const { isMobile, isWide } = useMobile()
  const { t } = useTranslation()
  const { onChange, value, roles } = props

  const maxSize = 10 * MB
  const validExtensions = ['PNG', 'JPG', 'JPEG', 'PDF', 'TIF', 'TIFF']
  const all = useTranslation().t
  const [errorMess, setErrorMess] = React.useState<string>('')
  const [list, setList] = React.useState<FileUploadData[]>([])
  const inputRef = React.createRef<HTMLInputElement>()
  const { showToast } = React.useContext(AppContext.AppContextInstance)
  const [disbaleUL, setDisableUL] = React.useState<boolean>(false)

  React.useImperativeHandle(ref, () => ({
    getFileTemple: (files) => {
      return handleUpload(files)
    },
    click: () => {
      return inputRef.current?.click()
    },
    removeFile: () => {
      return deleteAll()
    },
    disableUpload: (val) => {
      setDisableUL(val)
    }
  }))

  const onUploaded = (files: FileUploadData[]) => {
    const file = files[0]
    if (!validExtensions.includes(file.fileExtension.toLocaleUpperCase())) {
      setErrorMess(all('message:MS040003', { files: validExtensions.join('/') }))
    } else if (file.size >= maxSize) {
      setErrorMess(all('message:MS040004'))
    } else if (list.length >= 1) {
      setErrorMess(all('message:MS990034'))
    } else {
      const cloneList = [...list, file]
      setList(cloneList)
      setErrorMess('')
      onChange && onChange(value ? [...value, file] : [file])
    }
  }

  const deleteAttachment = (attachmentIdx: number) => {
    const currSelectedFile = [...list]
    currSelectedFile.splice(attachmentIdx, 1)
    setList(currSelectedFile)
    onChange && onChange(currSelectedFile)
    setErrorMess('')
  }

  const deleteAll = () => {
    setList([])
    onChange && onChange([])
    setErrorMess('')
  }

  const upload = () => {
    if (disbaleUL) {
      return showToast(t('message:MS070028'), 'error')
    }

    inputRef.current?.click()
  }

  const handleUpload = (files: FileList) => {
    const fileList: FileUploadData[] = []
    const fileLength = files.length
    for (let i = 0; i < files.length; i++) {
      const fileItem = files[i]
      const reader = new FileReader()
      const oldExtension = fileItem.name ? fileItem.name.slice(fileItem.name.lastIndexOf('.')) : ''
      const fileName = fileItem.name ? fileItem.name.slice(0, fileItem.name.lastIndexOf('.')) : ''
      //filter file name and replace special characters
      const filterNewName = replaceSpecialCharactersInFileName(fileName)
      const fullFilterName = filterNewName + oldExtension
      const newFile = new File([fileItem], fullFilterName, { type: fileItem.type })
      const fileExt = newFile.name.split('.').pop() ?? ''
      reader.readAsDataURL(newFile)
      reader.onloadend = () => {
        fileList.push({
          fileName: newFile.name.split('.')[0],
          fileExtension: fileExt,
          size: newFile.size,
          base64: reader.result as string,
          uploadedDate: new Date(),
          file: newFile
        })
        i === fileLength - 1 && fileList.length > 0 && onUploaded(fileList)
      }
    }
  }

  return (
    <>
      <View
        style={{
          width: '100%',
          marginTop: 30,
          flexDirection: isMobile ? 'column' : 'row',
          display: 'flex'
        }}
      >
        <View>
          <TouchableOpacity
            style={
              isMobile
                ? {
                    flexDirection: 'row'
                  }
                : {
                    backgroundColor: '#FFFFFF',
                    borderRadius: 8,
                    marginTop: 15,
                    padding: 15,
                    borderWidth: 2,
                    borderColor: '#D3DCE6',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                    minWidth: 300
                  }
            }
            disabled={!roles.includes(TASK_DETAIL_ROLES.EditAdjudicationFinalLetterTaskDetail)}
            onPress={upload}
          >
            <assets.UploadIcon />
            <Text style={{ fontWeight: 'bold', marginLeft: 10, color: '#ED1B2E' }}>{t('claim:AMF')}</Text>
            <input
              ref={inputRef}
              hidden
              type="file"
              multiple={true}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (event.target.files) {
                  handleUpload(event.target.files)
                  event.target.files = null
                  event.target.value = ''
                }
              }}
            />
          </TouchableOpacity>
        </View>
        <ScrollView>
          <View
            style={{
              flex: 1,
              flexWrap: 'wrap',
              marginTop: 8,
              flexDirection: isMobile ? 'column' : 'row'
            }}
          >
            {list
              ? list.map((file: FileUploadData, attachmentIdx) => (
                  <View
                    key={`File: ${file.fileName}-${attachmentIdx}`}
                    style={{
                      width: isWide ? '50%' : '100%',
                      paddingLeft: isWide ? 15 : 0,
                      paddingBottom: 10,
                      flexDirection: 'row'
                    }}
                  >
                    <View style={{ flex: 1 }}>
                      <img src={file.base64} width={38} height={41} />
                    </View>
                    <View style={{ flexDirection: 'column', flex: 3 }}>
                      <Text>{file.fileName}</Text>
                      <Text>{PulseOpsFormat.datetoFormat(file.uploadedDate, 'DD-MM-YYYY hh:mm')}</Text>
                    </View>
                    <TouchableHighlight style={{ flex: 1 }} onPress={() => deleteAttachment(attachmentIdx)}>
                      <assets.RemoveIcon />
                    </TouchableHighlight>
                  </View>
                ))
              : null}
          </View>
        </ScrollView>
      </View>
      <Text style={{ color: '#ED1B2C' }}>{errorMess}</Text>
    </>
  )
})
const style = StyleSheet.create({
  sectionTitle: {
    fontSize: 15,
    fontWeight: '800',
    paddingTop: 14,
    textTransform: 'uppercase'
  },
  sectionContainer: {
    marginTop: 14,
    padding: 18,
    // backgroundColor: "#FAFAFA",
    backgroundColor: '#FFFFFF',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    minHeight: 20,
    overflow: 'hidden'
  },
  sectionInfo: {
    width: '100%',
    padding: 20,
    backgroundColor: '#FFF',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#D3DCE6',
    borderTopStartRadius: 8,
    borderTopEndRadius: 8
  },
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  col: {
    flex: 1 / 3,
    marginBottom: 32
  },
  label: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  },
  value: {
    color: '#000000',
    fontSize: 15,
    lineHeight: 18
  },
  titleSectionText: {
    fontSize: 13,
    lineHeight: 22,
    fontWeight: '600',
    paddingStart: 10,
    paddingEnd: 10,
    color: '#4F4F4F'
  },
  contentText: {
    padding: 5,
    borderRadius: 20,
    width: 'fit-content',
    backgroundColor: '#D3DCE6'
  },
  sectionRow: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row'
  },
  col_3: {
    width: '100%',
    maxWidth: '33%',
    paddingRight: 15
  },
  buttonStyle: {
    borderColor: '#ED1B2E',
    borderWidth: 1,
    borderStyle: 'solid',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    width: 130,
    height: 32,
    borderRadius: 8,
    paddingHorizontal: 10,
    paddingVertical: 8,
    boxShadow: 'none',
    color: '#ED1B2E',
    fontWeight: 600,
    fontSize: 12,
    alignItems: 'center',
    fontFamily: 'NotoSans_700Bold'
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  btnTitle: {
    fontSize: 15,
    fontWeight: 'bold',
    marginVertical: 5,
    marginHorizontal: 29
  }
})
