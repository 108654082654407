import React from 'react'
import _ from 'lodash'
import {
  form2,
  ChangeContactInfoService,
  ErrorHandling,
  ChangeContactInfo,
  TransactionType,
  AppContext,
  SelectSearch,
  assets,
  SelectOption,
  GeneralService,
  Select
} from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import { PolicyServiceProps } from '../policy-service-props'
// import { useLoading } from '@mxt/zio-react'
import { SC } from './common'
import { RequestAuthenticateData } from '../../request-authen'
import { LinearProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useIsFocused } from '@react-navigation/native'
import { Controller, useWatch } from 'react-hook-form'
import { View } from 'react-native'
import { CustomCheckBox, Input, ConfirmView, DuplicateTable } from '../../common'
import * as O from 'fp-ts/Option'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { ChangeContactInfoForm } from './change-contact-info-form'
import { EmailIndicator, SMSIndicator } from './change-contact-info-const'

type Props = PolicyServiceProps<ChangeContactInfo.DataSubmit> & {
  //policyNum: string
  isCCE: boolean,
  requestAuthenData: RequestAuthenticateData.EformRequestAuthenticateData
}

export const ChangeContactInfoScreen: React.FC<Props> = ({ isConfirmed, initSubmission, policyNumber, isCCE, clientNumber, requestAuthenData }) => {
  const { t, i18n } = useTranslation()
  const isFocused = useIsFocused()
  const { showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const [countryOptions, setCountryOptions] = React.useState<SelectOption[]>([])
  const [provinceOptions, setProvinceOptions] = React.useState<SelectOption[]>([])
  const [districOptions, setDistrictOptions] = React.useState<SelectOption[]>([])
  const [wardOptions, setWardOptions] = React.useState<SelectOption[]>([])
  const [relationOptions, setRelationOptions] = React.useState<SelectOption[]>([])
  const [loading, bindLoader] = useLoading(false)
  const [detailLoader, bindLoaderDetail] = useLoading(false)

  const {
    base: {
      control,
      reset,
      getValues,
      trigger,
      formState: { errors, isValid },
      setValue,
      watch,
      clearErrors
    }
  } = form2.useForm(ChangeContactInfoForm.codec, {
    defaultValues: {
      chooseAddress: false,
      chooseEmail: false,
      choosePhone: false
    }
  })

  const detail = pipe(ChangeContactInfoService.getDetail(policyNumber!), bindLoaderDetail, ErrorHandling.runDidUpdate([policyNumber]))

  React.useEffect(() => {
    showGlobalLoading(loading)
  }, [loading])

  const mapDataSubmit = (currentForm: ChangeContactInfoForm.Raw,  mainPhoneNumber: boolean) => {
    const address = _.get(currentForm, 'address') as ChangeContactInfoForm.AddressForm
    const phone = _.get(currentForm, 'phone') as ChangeContactInfoForm.PhoneForm
    const email = _.get(currentForm, 'email') as ChangeContactInfoForm.EmailForm
    const chooseAddress = currentForm.chooseAddress
    const choosePhone = currentForm.choosePhone
    const chooseEmail = currentForm.chooseEmail

    let contactDetails = {}

    if (chooseEmail) {
      contactDetails = {
        ...contactDetails,
        EMAIL: {
          value: email?.email?.toString() || ''
        },

        EMAIL_IND: {
          value: email.receiveInfoVia?.value
        }
      }
    }

    if (choosePhone) {
      contactDetails = {
        ...contactDetails,
        PHONE: {
          value: phone.mobileNum,
          countryCode: phone.mobileCode.value
        },
        OFFICE_PHONE: {
          value: _.get(phone, 'officePhoneNum') || '',
          countryCode: _.get(phone, 'officeMobileCode.value') || ''
        },
        SMS_IND: {
          value: phone.receiveInfoVia?.value
        }
      }
    }

    const data: ChangeContactInfo.DataSubmit = {
      policyNo: policyNumber!,
      owners: {
        clientId: detail?.customerId || ''
      },
      priorPolicies:
        chooseAddress && address.applyPolicies
          ? [
            { policyNo: policyNumber! },
            ...address.applyPolicies
              .filter((e) => e.selected && !!e.policyNum)
              .map((policy) => ({ policyNo: policy.policyNum.toString() }))
          ]
          : [{ policyNo: policyNumber! }],
      attributesExt: {
        PHONES_DUP:
          choosePhone && phone.relationships
            ? phone.relationships
              ?.filter((v) => v.relationship !== null)
              .map((e) => ({ clientId: e.clientId || '', relationshipType: e.relationship?.value || '' }))
            : [],
        EMAILS_DUP:
          chooseEmail && email.relationships
            ? email.relationships
              ?.filter((v) => v.relationship !== null)
              .map((e) => ({ clientId: e.clientId || '', relationshipType: e.relationship?.value || '' }))
            : [],
        PO_OWNER_ID: clientNumber ?? '',
        mainPhoneNumber: mainPhoneNumber
      }
    }

    const requestdata = {
      ...data,
      dispatchAddress: chooseAddress
        ? {
          line1: address.street,
          zipcode: address.ward.value,
          countryCode: address.country.value,
          city: address.province.value,
          district: address.district.value,
          subDistrict: address.ward.value
        }
        : undefined,
      contactDetails,
      isCCE: false
    }
    //console.log('request data', requestdata)
    return requestdata
  }

  const getTransactionName = (currentForm: ChangeContactInfoForm.Raw) => {
    const isAll = currentForm.chooseAddress && (currentForm.chooseEmail || currentForm.choosePhone)
    // const isAddress = requestData.changeAddressCheckbox && !requestData.changeEmailCheckbox && !requestData.changePhoneCheckbox
    const isAddress = currentForm.chooseAddress && !currentForm.chooseEmail && !currentForm.choosePhone
    // const isPhone = !requestData.changeAddressCheckbox && !requestData.changeEmailCheckbox && requestData.changePhoneCheckbox
    const isPhone = !currentForm.chooseAddress && !currentForm.chooseEmail && currentForm.choosePhone
    // const isEmail = !requestData.changeAddressCheckbox && requestData.changeEmailCheckbox && !requestData.changePhoneCheckbox
    const isEmail = !currentForm.chooseAddress && currentForm.chooseEmail && !currentForm.choosePhone
    // const isPhoneAndEmail = !requestData.changeAddressCheckbox && requestData.changeEmailCheckbox && requestData.changePhoneCheckbox
    const isPhoneAndEmail = !currentForm.chooseAddress && currentForm.chooseEmail && currentForm.choosePhone
    return isCCE
      ? getTransactionShortNameForCCE(isPhone, isEmail, isAddress, isPhoneAndEmail, currentForm)
      : isAll
        ? RequestAuthenticateData.TransactionLabelShort(TransactionType.CHANGE_CONTACT_INFO)
        : isPhoneAndEmail
          ? RequestAuthenticateData.TransactionLabelShort(TransactionType.CHANGE_CONTACT_INFO_PHONE_AND_EMAIL)
          : isAddress
            ? RequestAuthenticateData.TransactionLabelShort(TransactionType.CHANGE_CONTACT_INFO_ADDRESS)
            : isEmail
              ? RequestAuthenticateData.TransactionLabelShort(TransactionType.CHANGE_CONTACT_INFO_EMAIL)
              : isPhone
                ? RequestAuthenticateData.TransactionLabelShort(TransactionType.CHANGE_CONTACT_INFO_PHONE)
                : '-'
  }

  const getTransactionShortNameForCCE = (
    isPhone: boolean,
    isEmail: boolean,
    isAddress: boolean,
    isPhoneAndEmail: boolean,
    currentForm: ChangeContactInfoForm.Raw
  ) => {
    let smsShortMessage = ''
    const phone = _.get(currentForm, 'phone') as ChangeContactInfoForm.PhoneForm
    const email = _.get(currentForm, 'email') as ChangeContactInfoForm.EmailForm
    // const isPhoneAndAddress = changeContactData.changeAddressCheckbox && !changeContactData.changeEmailCheckbox && changeContactData.changePhoneCheckbox
    const isPhoneAndAddress = currentForm.chooseAddress && !currentForm.chooseEmail && currentForm.choosePhone
    // const isEmailAndAddress = changeContactData.changeAddressCheckbox && changeContactData.changeEmailCheckbox && !changeContactData.changePhoneCheckbox
    const isEmailAndAddress = currentForm.chooseAddress && currentForm.chooseEmail && !currentForm.choosePhone
    // const isALL = changeContactData.changeAddressCheckbox && changeContactData.changeEmailCheckbox && changeContactData.changePhoneCheckbox
    const isALL = currentForm.chooseAddress && currentForm.chooseEmail && currentForm.choosePhone
    if (isPhone || isPhoneAndEmail || isPhoneAndAddress || isALL) {
      smsShortMessage =
        RequestAuthenticateData.TransactionLabelShort(TransactionType.CHANGE_CONTACT_INFO_PHONE) + ' ' + phone.mobileNum
    } else if (isEmail || isEmailAndAddress) {
      smsShortMessage = 'Cap nhat dia chi thu dien tu ' + email?.email ?? ''
    } else if (isAddress) {
      smsShortMessage = RequestAuthenticateData.TransactionLabelShort(TransactionType.CHANGE_CONTACT_INFO_ADDRESS)
    }
    return smsShortMessage
  }

  initSubmission({
    validate: async () => {
      await trigger()
      const isCheckLeast = getValues('chooseAddress') || getValues('choosePhone') || getValues('chooseEmail')
      console.log(errors)
      if (isValid && isCheckLeast) {
        const changeContactFormValue = getValues()
        const data = mapDataSubmit(changeContactFormValue, requestAuthenData.mainPhoneNumber)
        const transactionName = getTransactionName(changeContactFormValue)
        return {
          url: (_) => `wf-api/customer/${detail?.customerId}/contact/address`,
          body: data,
          transactionName: transactionName,
          collerationId: policyNumber ?? '',
          transaction: TransactionType.CHANGE_CONTACT_INFO
        }
      } else {
        return false
      }
    },
    clear: () => {
      reset({
        chooseAddress: false,
        chooseEmail: false,
        choosePhone: false
      })
      clearErrors()
    }
  })

  const applyPolicyAddress = useWatch({
    control,
    name: 'address.applyPolicies'
  })

  const phoneRelationShip = useWatch({
    control,
    name: 'phone.relationships'
  })

  const emailRelationShip = useWatch({
    control,
    name: 'email.relationships'
  })

  const provinceCode = watch('address.province.value')

  const pathAddress = () => {
    if (
      provinceOptions.length > 0 &&
      districOptions.length > 0 &&
      wardOptions.length > 0 &&
      getValues('address.country') &&
      getValues('address.street') &&
      getValues('address.province') &&
      getValues('address.district') &&
      getValues('address.ward')
    ) {
      return
    }
    return pipe(
      detail,
      O.fromNullable,
      O.fold(
        () => showToast('Error', 'error'),
        (detail) => {
          //showGlobalLoading(true)
          return pipe(
            ZIO.zipPar(ZIO.succeed(detail), GeneralService.getCountries, GeneralService.getProvinces),
            ZIO.flatMap(([detail, countries, provinces]) => {
              // setDetail(detail)
              const countriesOptions = countries.map((country) => {
                return {
                  label: country.name,
                  value: country.code
                }
              })

              const provinceOptions = provinces.map((province) => {
                return {
                  label: province.name,
                  value: province.code
                }
              })

              setCountryOptions(countriesOptions)
              setProvinceOptions(provinceOptions)

              const chooseCountry = countriesOptions.find((value) => value.value === detail.nationality)
              const chooseProvince = provinceOptions.find(
                (value) => value.value === getValues('address.province.value') || value.value === detail.province
              )

              return pipe(
                GeneralService.getDistricts(getValues('address.province.value') || detail.province),
                ZIO.flatMap((districts) => {
                  const districtOptions = districts.map((district) => {
                    return {
                      label: district.name,
                      value: district.code
                    }
                  })

                  const chooseDistrict = districtOptions.find(
                    (value) => value.value === getValues('address.district.value') || value.value === detail.district
                  )
                  setDistrictOptions(districtOptions)

                  return pipe(
                    GeneralService.getWards({
                      provinceCode: getValues('address.province.value') || detail.province,
                      districtCode: getValues('address.district.value') || detail.district
                    }),
                    ZIO.map((wards) => {
                      const wardOptions = wards.map((ward) => {
                        return {
                          label: ward.name,
                          value: ward.code
                        }
                      })
                      const chooseWards = wardOptions.find(
                        (value) => value.value === getValues('address.ward.value') || value.value === detail.ward
                      )

                      setWardOptions(wardOptions)

                      setValue('address.street', getValues('address.street') || detail.street)
                      setValue(
                        'address.country',
                        getValues('address.country') || chooseCountry || { label: '', value: '' }
                      )
                      setValue(
                        'address.province',
                        getValues('address.province') || chooseProvince || { label: '', value: '' }
                      )
                      setValue(
                        'address.district',
                        getValues('address.district') || chooseDistrict || { label: '', value: '' }
                      )
                      setValue('address.ward', getValues('address.ward') || chooseWards || { label: '', value: '' })
                      //console.log('dasdasdas asdasd asd', detail.clientPolicyList)
                      const clientPolicy =
                        getValues('address.applyPolicies') ||
                        detail.clientPolicyList
                          .filter((c) => !!c.policyNum && !!c.address)
                          .map((policy) => {
                            return { selected: false, policyNum: policy.policyNum, address: policy.address }
                          })
                      setValue('address.applyPolicies', clientPolicy)
                      //setValue('address.applyPolicies', [{selected: false, policyNum: '7000001', address: '2B nguyen thi huynh'}, {selected: false, policyNum: '7000001', address: '2B nguyen thi huynh'}, {selected: false, policyNum: '7000001', address: '2B nguyen thi huynh'}, {selected: false, policyNum: '7000001', address: '2B nguyen thi huynh'}, {selected: false, policyNum: '7000001', address: '2B nguyen thi huynh'}])
                      return ZIO.unit
                    })
                  )
                })
              )
            }),
            ZIO.tapError((_) => {
              //showGlobalLoading(false)
              return ZIO.unit
            }),
            bindLoader,
            ErrorHandling.run({})
          )
        }
      )
    )
  }

  const getDistricts = (provinceCode: string) =>
    pipe(
      GeneralService.getDistricts(provinceCode),
      ZIO.map((districts) => {
        const districtOptions = districts.map((district) => {
          return {
            value: district.code,
            label: i18n.language === 'vi' ? district.name : district.name
          }
        })
        setDistrictOptions(districtOptions)
        return ZIO.unit
      }),
      ErrorHandling.run()
    )

  const getWards = (provinceCode: string, districtCode: string) =>
    pipe(
      GeneralService.getWards({ districtCode, provinceCode }),
      ZIO.map((wards) => {
        const wardOptions = wards.map((ward) => {
          return {
            value: ward.code,
            label: i18n.language === 'vi' ? ward.name : ward.name
          }
        })
        setWardOptions(wardOptions)
        return ZIO.unit
      }),
      ErrorHandling.run()
    )

  const phoneCodeOptions: SelectOption[] = pipe(
    GeneralService.getMobileCodes,
    ZIO.map((codes) => {
      return codes.map((code) => ({
        label: `+${code.code}`,
        value: code.code
      }))
    }),
    ErrorHandling.runDidMount({})
  )

  const SMSOptions = React.useMemo(() => {
    return SMSIndicator.map((sms) => {
      return {
        value: sms.value,
        label: i18n.language === 'vi' ? sms.labelVI : sms.labelEN
      }
    })
  }, [i18n.language])

  const EmailOptions = React.useMemo(() => {
    return EmailIndicator.map((email) => {
      return {
        value: email.value,
        label: i18n.language === 'vi' ? email.labelVI : email.labelEN
      }
    })
  }, [i18n.language])

  pipe(
    GeneralService.getRelationship(),
    ZIO.tap((relations) => {
      //console.log(relations)
      const relationOptions = relations
        .filter((r) => r.changeContactInfo === 'Y')
        .map((relation) => {
          return {
            label: i18n.language === 'vi' ? relation.nameVn : relation.nameEn,
            value: relation.code
          }
        })
      setRelationOptions(relationOptions)
      return ZIO.unit
    }),
    ErrorHandling.runDidUpdate([isFocused])
  )

  const pathEmail = () => {
    return pipe(
      detail,
      O.fromNullable,
      O.fold(
        () => console.log('Error'),
        (detail) => {
          setValue('email.email', detail.email)
          const mapEmail = EmailIndicator.find((email) => email.value === detail.emailIndicator)
          if (mapEmail) {
            setValue('email.receiveInfoVia', {
              value: mapEmail.value,
              label: i18n.language === 'vi' ? mapEmail.labelVI : mapEmail.labelEN
            })
          } else {
            const receiveDefaultValue = EmailOptions.find((x) => x.value === '3') ?? { value: '', label: '' }
            setValue('email.receiveInfoVia', receiveDefaultValue)
          }

          getDuplicateEmail(detail.email)
        }
      )
    )
  }

  const pathPhone = () => {
    return pipe(
      detail,
      O.fromNullable,
      O.fold(
        () => console.log('Error'),
        (detail) => {
          setValue('phone.mobileCode', { value: detail.mobilePhoneCode, label: `+${detail.mobilePhoneCode}` })
          setValue('phone.mobileNum', detail.mobilePhoneNumber)
          setValue('phone.officeMobileCode', { value: detail.officePhoneCode, label: `+${detail.officePhoneCode}` })
          setValue('phone.officePhoneNum', detail.officePhoneNumber)
          const mapRelationShip = EmailIndicator.find((phone) => phone.value === detail.smsIndicator)
          if (mapRelationShip) {
            setValue('phone.receiveInfoVia', {
              value: mapRelationShip.value,
              label: i18n.language === 'vi' ? mapRelationShip.labelVI : mapRelationShip.labelEN
            })
          } else {
            const receiveDefaultValue = SMSOptions.find((x) => x.value === '3') ?? { value: '', label: '' }
            setValue('phone.receiveInfoVia', receiveDefaultValue)
          }
          getDuplicatePhone(detail.mobilePhoneNumber || '')
        }
      )
    )
  }

  const isSameClient = (relationship: ChangeContactInfo.DuplicateInfo): boolean => {
    // console.log('Check is Same Client')
    // console.log(relationship)
    // console.log(detail)
    return (
      relationship.clientName === detail?.poName &&
      relationship.dob === detail?.dob &&
      relationship.gender === detail?.gender &&
      relationship.secuityNo === detail?.secuityNo
    )
  }

  const getDuplicatePhone = (phoneNumber: string) => {
    pipe(
      detail,
      O.fromNullable,
      O.fold(
        () => showToast(t('common:noData'), 'error'),
        (detail) => {
          //showGlobalLoading(true)
          return pipe(
            ChangeContactInfoService.getDuplicatePhoneInfo(phoneNumber, detail.customerId),
            ZIO.tap((info) => {
              const phones = info.map((e) => {
                return {
                  clientId: e.clientId,
                  clientName: e.clientName,
                  poName: detail.poName,
                  role: e.role,
                  //role: 'AG',
                  relationship: null,
                  dob: e.dob,
                  secuityNo: e.secuityNo,
                  gender: e.gender
                  //sameClient: isSameClient(e)
                }
              })
              setValue(
                'phone.relationships',
                phones.filter((p) => !isSameClient(p))
              )
              return ZIO.unit
            }),
            ZIO.tapError((_) => {
              //showGlobalLoading(false)
              return ZIO.unit
            }),
            bindLoader,
            ErrorHandling.run()
          )
        }
      )
    )
  }

  const getDuplicateEmail = (email: string) => {
    pipe(
      detail,
      O.fromNullable,
      O.fold(
        () => showToast('Khong co du lieu', 'error'),
        (detail) => {
          //showGlobalLoading(true)
          return pipe(
            ChangeContactInfoService.getDuplicateEmailInfo(email, detail.customerId),
            ZIO.tap((info) => {
              const email = info.map((e) => {
                // const validationFlag = (e?.role !== 'AG' || e?.role === 'AG') && e?.clientName !== detail.poName
                return {
                  clientId: e.clientId,
                  clientName: e.clientName,
                  poName: detail.poName,
                  role: e.role,
                  relationship: null,
                  dob: e.dob,
                  secuityNo: e.secuityNo,
                  gender: e.gender
                  //sameClient: isSameClient(e)
                }
              })
              //console.log('duplication email', email)
              setValue(
                'email.relationships',
                email.filter((e) => !isSameClient(e))
              )
              //showGlobalLoading(false)
              return ZIO.unit
            }),
            ZIO.tapError((_) => {
              //showGlobalLoading(false)
              return ZIO.unit
            }),
            bindLoader,
            ErrorHandling.run()
          )
        }
      )
    )
  }

  /// debounce email input to get duplicate email
  const handlerEmail = React.useCallback(
    _.debounce((email) => {
      if (ChangeContactInfoForm.emailValidate(email)) {
        getDuplicateEmail(email)
      } else {
        setValue('email.relationships', [])
      }
    }, 300),
    [detail]
  )

  const applyAllPolicy = (value: boolean) => {
    const newApply = applyPolicyAddress && applyPolicyAddress.map((a) => ({ ...a, selected: value }))
    setValue('address.applyPolicies', newApply)
  }

  const predicateAgent = (relationship: ChangeContactInfoForm.RelationShip) => relationship.role === 'AG'

  const anyAgentPhone = React.useMemo(() => phoneRelationShip?.find(predicateAgent), [phoneRelationShip])
  const anyAgentEmail = React.useMemo(() => emailRelationShip?.find(predicateAgent), [emailRelationShip])

  return (
    <SC.Container>
      {detailLoader && <LinearProgress />}
      {/* Change Address */}
      <Controller
        control={control}
        name={'chooseAddress'}
        defaultValue={getValues('chooseAddress')}
        render={({ field: { value, onChange, onBlur } }) => {
          return (
            <View>
              <CustomCheckBox
                title={t('submission:ChangeAdress').toUpperCase()}
                checked={value}
                onPress={() => {
                  onChange(!value)
                  if (!value) pathAddress()
                }}
                onBlur={onBlur}
                textStyle={{ fontWeight: 'bold' }}
                disabled={detailLoader || isConfirmed}
              />
              {value && (
                <SC.PanelContainer backgroundColor={'#fff'}>
                  <SC.Row>
                    <View style={{ flex: 1, minWidth: 250 }}>
                      <Controller
                        control={control}
                        name={'address.country'}
                        render={({ field: { value, onChange, onBlur } }) => {
                          return (
                            <ConfirmView
                              title={t('submission:Country')}
                              value={value?.label ?? ''}
                              isConfirmed={isConfirmed}
                            >
                              <SelectSearch
                                label={t('submission:Country')}
                                options={countryOptions || []}
                                popupIcon={<assets.ArrowDownDropdownIcon />}
                                value={value}
                                placeholder={t('common:Select')}
                                onChange={onChange}
                                errorMessage={_.get(errors, 'address.country')?.message}
                                disabled
                                required
                              />
                            </ConfirmView>
                          )
                        }}
                      />
                    </View>
                    <SC.Divider />
                    <View style={{ flex: 1, minWidth: 250 }}>
                      <Controller
                        control={control}
                        name={'address.province'}
                        render={({ field: { value, onChange, onBlur } }) => {
                          return (
                            <ConfirmView
                              title={t('submission:CityProvince')}
                              value={value?.label ?? ''}
                              isConfirmed={isConfirmed}
                            >
                              <SelectSearch
                                label={t('submission:CityProvince')}
                                value={value}
                                options={provinceOptions || []}
                                popupIcon={<assets.ArrowDownDropdownIcon />}
                                placeholder={t('common:Select')}
                                onChange={(value) => {
                                  if (value) {
                                    onChange(value)
                                    setValue('address.district', { label: '', value: '' })
                                    setValue('address.ward', { label: '', value: '' })
                                    getDistricts(value.value)
                                  }
                                }}
                                errorMessage={_.get(errors, 'address.province')?.message}
                                required
                              />
                            </ConfirmView>
                          )
                        }}
                      />
                    </View>
                    <SC.Divider />
                    <View style={{ flex: 1, minWidth: 250 }}>
                      <Controller
                        control={control}
                        name={'address.district'}
                        render={({ field: { value, onChange, onBlur } }) => {
                          return (
                            <ConfirmView
                              title={t('submission:District')}
                              value={value?.label ?? ''}
                              isConfirmed={isConfirmed}
                            >
                              <SelectSearch
                                label={t('submission:District')}
                                value={value}
                                options={districOptions || []}
                                popupIcon={<assets.ArrowDownDropdownIcon />}
                                placeholder={t('common:Select')}
                                onChange={(value) => {
                                  if (value) {
                                    onChange(value)
                                    setValue('address.ward', { label: '', value: '' })
                                    getWards(provinceCode, value.value)
                                  }
                                }}
                                errorMessage={_.get(errors, 'address.district')?.message}
                                required
                              />
                            </ConfirmView>
                          )
                        }}
                      />
                    </View>
                  </SC.Row>
                  <SC.Divider height={15} />
                  <SC.Row>
                    <View style={{ flex: 1, minWidth: 250 }}>
                      <Controller
                        control={control}
                        name={'address.ward'}
                        render={({ field: { value, onChange, onBlur } }) => {
                          return (
                            <ConfirmView
                              title={t('submission:Ward')}
                              value={value?.label ?? ''}
                              isConfirmed={isConfirmed}
                            >
                              <SelectSearch
                                label={t('submission:Ward')}
                                value={value}
                                placeholder={t('common:Select')}
                                options={wardOptions || []}
                                popupIcon={<assets.ArrowDownDropdownIcon />}
                                onChange={onChange}
                                errorMessage={_.get(errors, 'address.ward')?.message}
                                required
                              />
                            </ConfirmView>
                          )
                        }}
                      />
                    </View>
                    <SC.Divider />
                    <View style={{ flex: 2, minWidth: 250, paddingTop: 5 }}>
                      <Controller
                        control={control}
                        name={'address.street'}
                        render={({ field: { value, onChange, onBlur } }) => {
                          return (
                            <ConfirmView title={t('submission:Address')} value={value ?? ''} isConfirmed={isConfirmed}>
                              <Input
                                title={t('submission:Address')}
                                required
                                onChange={(text) => {
                                  onChange(text)
                                }}
                                onBlur={() => {
                                  onBlur()
                                  onChange(value?.trim())
                                }}
                                value={value}
                                maxLength={50}
                                errorMessage={errors.address?.street?.message}
                              />
                            </ConfirmView>
                          )
                        }}
                      />
                    </View>
                  </SC.Row>
                  {applyPolicyAddress && applyPolicyAddress.length > 0 && (
                    <SC.Padding top={20}>
                      <SC.TitleText>{t('submission:AppliedTo')}</SC.TitleText>
                    </SC.Padding>
                  )}
                  {applyPolicyAddress && applyPolicyAddress.length > 0 && (
                    <CustomCheckBox
                      checked={!applyPolicyAddress.some((a) => !a.selected)}
                      title={t('submission:AllPolicies')}
                      onPress={(_) => {
                        applyAllPolicy(applyPolicyAddress.some((a) => !a.selected))
                      }}
                      disabled={isConfirmed}
                    />
                  )}
                  {applyPolicyAddress &&
                    applyPolicyAddress.map((value, index) => {
                      return (
                        <Controller
                          key={`list_address${value?.policyNum}${value?.selected}`}
                          control={control}
                          name={`address.applyPolicies.${index}`}
                          render={({ field: { value, onChange, onBlur } }) => {
                            return (
                              <CustomCheckBox
                                checked={value.selected}
                                onPress={(_) => onChange({ ...value, selected: !value.selected })}
                                title={`${value.policyNum || ''} ${value.address || ''}`}
                                disabled={isConfirmed}
                              />
                            )
                          }}
                        />
                      )
                    })}
                </SC.PanelContainer>
              )}
            </View>
          )
        }}
      />

      {/* Change Phone number */}
      <Controller
        control={control}
        name={'choosePhone'}
        defaultValue={getValues('choosePhone')}
        render={({ field: { value, onChange, onBlur } }) => {
          return (
            <View>
              <CustomCheckBox
                title={t('submission:ChangePhone').toUpperCase()}
                checked={value}
                onPress={() => {
                  if (!value) pathPhone()
                  onChange(!value)
                }}
                onBlur={onBlur}
                textStyle={{ fontWeight: 'bold' }}
                disabled={detailLoader || isConfirmed}
              />
              {value && (
                <SC.Padding top={5} bottom={5} horizontal={0}>
                  <SC.ErrorText>{t('message:MS050224')}</SC.ErrorText>
                </SC.Padding>
              )}
              {value && (
                <SC.PanelContainer backgroundColor={'#fff'}>
                  <SC.Row>
                    <View style={{ flex: 1, paddingTop: 4 }}>
                      <ConfirmView
                        title={t('submission:MobilePhone')}
                        value={`${getValues('phone.mobileCode.label') ?? ''} ${getValues('phone.mobileNum') ?? ''}`}
                        isConfirmed={isConfirmed}
                      >
                        <View>
                          <SC.TitleText>
                            {t('submission:MobilePhone')} <SC.RequiredMark>*</SC.RequiredMark>
                          </SC.TitleText>
                          <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
                            <Controller
                              control={control}
                              name={'phone.mobileCode'}
                              render={({ field: { value, onChange, onBlur } }) => {
                                return (
                                  <View style={{ width: 110 }}>
                                    <SelectSearch
                                      options={phoneCodeOptions}
                                      onChange={onChange}
                                      value={value}
                                      popupIcon={<assets.ArrowDownDropdownIcon />}
                                      hideLabel
                                    />
                                  </View>
                                )
                              }}
                            />
                            <SC.Divider />
                            <Controller
                              control={control}
                              name={'phone.mobileNum'}
                              render={({ field: { value, onChange, onBlur } }) => {
                                return (
                                  <View style={{ flex: 1 }}>
                                    <Input
                                      onChange={(value) => {
                                        onChange(value)
                                        if (/\b\d{10}\b/.test(value)) {
                                          getDuplicatePhone(value)
                                        } else {
                                          setValue('phone.relationships', [])
                                        }
                                      }}
                                      value={value || undefined}
                                      maxLength={10}
                                    />
                                  </View>
                                )
                              }}
                            />
                          </View>
                          {(_.get(errors, 'phone.mobileCode')?.message ||
                            _.get(errors, 'phone.mobileNum')?.message) && (
                              <View>
                                <SC.ErrorText>
                                  {_.get(errors, 'phone.mobileCode')?.message ||
                                    _.get(errors, 'phone.mobileNum')?.message}
                                </SC.ErrorText>
                              </View>
                            )}
                        </View>
                      </ConfirmView>
                    </View>
                    <SC.Divider />
                    <View style={{ flex: 1, paddingTop: 4 }}>
                      <ConfirmView
                        title={t('submission:SecondaryPhone')}
                        value={`${getValues('phone.officeMobileCode.label') ?? ''} ${getValues('phone.officePhoneNum') ?? ''
                          }`}
                        isConfirmed={isConfirmed}
                        required={false}
                      >
                        <View>
                          <SC.TitleText>{t('submission:SecondaryPhone')}</SC.TitleText>
                          <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
                            <Controller
                              control={control}
                              name={'phone.officeMobileCode'}
                              render={({ field: { value, onChange, onBlur } }) => {
                                return (
                                  <View style={{ width: 110 }}>
                                    <SelectSearch
                                      options={phoneCodeOptions}
                                      onChange={onChange}
                                      value={value}
                                      popupIcon={<assets.ArrowDownDropdownIcon />}
                                      hideLabel
                                    />
                                  </View>
                                )
                              }}
                            />
                            <SC.Divider />
                            <Controller
                              control={control}
                              name={'phone.officePhoneNum'}
                              render={({ field: { value, onChange, onBlur } }) => {
                                return (
                                  <View style={{ flex: 1 }}>
                                    <Input
                                      onChange={(value) => {
                                        onChange(value)
                                        trigger()
                                      }}
                                      onBlur={onBlur}
                                      value={value}
                                      maxLength={10}
                                    />
                                  </View>
                                )
                              }}
                            />
                          </View>
                          {(_.get(errors, 'phone.officeMobileCode')?.message ||
                            _.get(errors, 'phone.officePhoneNum')?.message) && (
                              <View>
                                <SC.ErrorText>
                                  {_.get(errors, 'phone.officeMobileCode')?.message ||
                                    _.get(errors, 'phone.officePhoneNum')?.message}
                                </SC.ErrorText>
                              </View>
                            )}
                        </View>
                      </ConfirmView>
                    </View>
                    <SC.Divider />
                    <View style={{ flex: 1 }}>
                      <Controller
                        control={control}
                        name={'phone.receiveInfoVia'}
                        render={({ field: { value, onChange, onBlur } }) => {
                          return (
                            <ConfirmView
                              title={t('submission:ReceiveInfoViaSms')}
                              value={value?.label ?? ''}
                              required={false}
                              isConfirmed={isConfirmed}
                            >
                              <Select
                                options={SMSOptions}
                                // popupIcon={<View />}
                                onChange={onChange}
                                value={value}
                                label={t('submission:ReceiveInfoViaSms')}
                              />
                            </ConfirmView>
                          )
                        }}
                      />
                    </View>
                  </SC.Row>

                  {anyAgentPhone ? (
                    <SC.Padding vertical={15}>
                      <SC.ErrorText>
                        {t('message:MS050225', { agent: _.get(anyAgentPhone, 'clientName') })}
                      </SC.ErrorText>
                    </SC.Padding>
                  ) : (
                    phoneRelationShip &&
                    phoneRelationShip.length > 0 && (
                      <SC.Container>
                        {!isConfirmed && (
                          <SC.Padding vertical={10}>
                            <SC.ErrorText>{t('submission:PhoneDuplicatedWithOther')}</SC.ErrorText>
                          </SC.Padding>
                        )}
                        {_.get(errors, 'phone.relationships') && (
                          <SC.Padding vertical={5}>
                            <SC.ErrorText>{t('message:MS050228')}</SC.ErrorText>
                          </SC.Padding>
                        )}
                        <Controller
                          control={control}
                          name={'phone.relationships'}
                          render={({ field: { value, onChange } }) => {
                            return (
                              <DuplicateTable
                                key={`phone_table_${isConfirmed}`}
                                value={value ?? []}
                                onChange={onChange}
                                disable={isConfirmed}
                                relationshipOptions={relationOptions ?? []}
                              />
                            )
                          }}
                        />
                      </SC.Container>
                    )
                  )}
                </SC.PanelContainer>
              )}
            </View>
          )
        }}
      />

      {/* Change Email */}
      <Controller
        control={control}
        name={'chooseEmail'}
        defaultValue={getValues('chooseEmail')}
        render={({ field: { value, onChange, onBlur } }) => {
          return (
            <View>
              <CustomCheckBox
                title={t('submission:ChangeEmail').toUpperCase()}
                checked={value}
                onPress={() => {
                  if (!value) pathEmail()
                  onChange(!value)
                }}
                onBlur={onBlur}
                textStyle={{ fontWeight: 'bold' }}
                // checkedColor={'red'}
                disabled={detailLoader || isConfirmed}
              />
              {value && (
                <SC.Padding top={5} bottom={5} horizontal={0}>
                  <SC.ErrorText>{t('message:MS050226')}</SC.ErrorText>
                </SC.Padding>
              )}
              {value && (
                <SC.PanelContainer backgroundColor={'#fff'}>
                  <SC.Row>
                    <View style={{ width: '33%', paddingTop: 5 }}>
                      <Controller
                        control={control}
                        name={'email.email'}
                        render={({ field: { value, onChange, onBlur } }) => {
                          return (
                            <ConfirmView title={t('submission:Email')} value={value ?? ''} isConfirmed={isConfirmed}>
                              <Input
                                value={value}
                                onChange={(value) => {
                                  onChange(value)
                                  handlerEmail(value)
                                }}
                                onBlur={onBlur}
                                required
                                title={t('submission:Email')}
                                errorMessage={errors.email?.email?.message}
                              //onSubmit={() => getDuplicateEmail(value)}
                              />
                            </ConfirmView>
                          )
                        }}
                      />
                    </View>
                    <SC.Divider />
                    <View style={{ width: '30%' }}>
                      <Controller
                        control={control}
                        name={'email.receiveInfoVia'}
                        render={({ field: { value, onChange, onBlur } }) => {
                          return (
                            <ConfirmView
                              title={t('submission:ReceiveInfoViaEmail')}
                              value={value?.label ?? ''}
                              isConfirmed={isConfirmed}
                            >
                              <Select
                                options={EmailOptions}
                                onChange={onChange}
                                value={value}
                                label={t('submission:ReceiveInfoViaEmail')}
                              />
                            </ConfirmView>
                          )
                        }}
                      />
                    </View>
                  </SC.Row>

                  <SC.Divider />

                  {anyAgentEmail ? (
                    <SC.Padding vertical={10}>
                      <SC.ErrorText>
                        {t('message:MS050227', { agent: _.get(anyAgentEmail, 'clientName') })}
                      </SC.ErrorText>
                    </SC.Padding>
                  ) : (
                    emailRelationShip &&
                    emailRelationShip.length > 0 && (
                      <SC.Container>
                        {!isConfirmed && (
                          <SC.Padding vertical={10}>
                            <SC.ErrorText>{t('submission:EmailDuplicatedWithOther')}</SC.ErrorText>
                          </SC.Padding>
                        )}
                        {_.get(errors, 'email.relationships') && (
                          <SC.Padding vertical={5}>
                            <SC.ErrorText>{t('message:MS050228')}</SC.ErrorText>
                          </SC.Padding>
                        )}
                        <Controller
                          control={control}
                          name={'email.relationships'}
                          render={({ field: { value, onChange } }) => {
                            return (
                              <DuplicateTable
                                key={`email_table_${isConfirmed}`}
                                value={value ?? []}
                                onChange={onChange}
                                disable={isConfirmed}
                                relationshipOptions={relationOptions ?? []}
                              />
                            )
                          }}
                        />
                      </SC.Container>
                    )
                  )}
                </SC.PanelContainer>
              )}
            </View>
          )
        }}
      />
    </SC.Container>
  )
}
