import * as React from 'react'
import { View } from 'react-native'
import { formatNumberWithComma, Panel, TaskDetail } from '@pulseops/common'
import { Title, InfoViewList, DataSource } from '../common'
import { useTranslation } from 'react-i18next'

interface Props {
  detail: TaskDetail.ProductOptionSwitching
}

export const ProductOptionSwitching = ({ detail }: Props) => {
  const { t } = useTranslation()

  const getFieldData = (converageCode: string): DataSource[] => {
    const isVLR = converageCode.startsWith('V') || converageCode.startsWith('U')
    const fields: DataSource[] = [
      {
        label: isVLR ? t('submission:CurrentInsuranceBenefits') : t('submission:CurrentEducationalBenefits'),
        value: t(`${detail.converageCode}:${detail.oldBenefit}`) || '-'
      },
      {
        label: t('submission:CurrentSumAssured'),
        value: formatNumberWithComma(detail.currentSumAssured),
        suffix: 'VND'
      },
      {
        label: t('submission:InstallmentPremium'),
        value: formatNumberWithComma(detail.curRenewalPremium),
        suffix: 'VND'
      },
      {
        label: isVLR ? t('submission:NewInsuranceBenefits') : t('submission:NewEducationalBenefits'),
        value: t(`${detail.converageCode}:${detail.newBenefitOption}`) || '-'
      },
      {
        label: t('submission:NewSumAssured'),
        value: formatNumberWithComma(detail.newSumAssured),
        suffix: 'VND'
      },
      {
        label: t('submission:NewInstallmentPremium'),
        value: formatNumberWithComma(detail.curRenewalPremium),
        suffix: 'VND'
      }
    ]

    return fields
  }

  return (
    <View style={{ flex: 1 }}>
      <Title title={t('requestInfo:PRODUCT_OPTION_SWITCHING_INFORMATION')} />
      <Panel>
        <InfoViewList dataSource={getFieldData(detail.converageCode)} itemStyle={{ paddingHorizontal: 15 }} />
      </Panel>
    </View>
  )
}
