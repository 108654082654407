import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text, ActivityIndicator } from 'react-native'
import { IBStyles } from '../../../common'
import { useLoading } from '@mxt/zio-react'
import { IBService, PayModeData } from '../../../ib-service'
import { isNull } from 'lodash'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'

interface Props {
  paymodeData: PayModeData | null
  setPaymodeData: (data: PayModeData) => void
  claimNumber: string
}

export const IBPayMode = ({ paymodeData, setPaymodeData, claimNumber }: Props) => {
  const { t } = useTranslation('Inbound')
  const [loading, bindLoading] = useLoading(false)

  React.useEffect(() => {
    if (claimNumber && isNull(paymodeData)) {
      pipe(
        IBService.getPayMode(claimNumber),
        ZIO.map((res) => {
          res && setPaymodeData(res)
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    }
  }, [claimNumber])

  const dataPayMode = (data: PayModeData) => [
    {
      title: t('Bank'),
      value: (data?.bankCode && data?.bankName) ? (data?.bankCode ?? '') + ' ' + (data?.bankName ?? '') : '',
      listPayModeShow: ['1', '6', '8', '9']
    },
    {
      title: t('Branch'),
      value: data?.branch,
      listPayModeShow: ['1', '6', '8', '9']
    },
    {
      title: t('BankAddress'),
      value: data?.bankAddress,
      listPayModeShow: ['1', '6', '8', '9']
    },
    {
      title: t('RecipientName'),
      value: data?.recipientName,
      listPayModeShow: ['1', '6', '8', '9', 'M']
    },
    {
      title: t('BankAccount'),
      value: data?.bankAccount,
      listPayModeShow: ['6', '8', '9']
    },
    {
      title: t('IDNumberClaim'),
      value: data?.idNo,
      listPayModeShow: ['1', 'M']
    },
    {
      title: t('IssuingDate'),
      value: data?.issuingDate,
      listPayModeShow: ['1']
    },
    {
      title: t('AuthoriesID'),
      value: data?.authoriseOfId,
      listPayModeShow: ['1']
    },
    {
      title: t('PolicyNumberVIAPruOnline'),
      value: data?.plcnum,
      listPayModeShow: ['2']
    },
    {
      title: t('MobilePhone'),
      value: data?.mobilePhone,
      listPayModeShow: ['M']
    }
  ]

  const getPayModeLabel = (paymentMode: string | undefined | null) => {
    switch (paymentMode) {
      case '0': //CASH
        return t('Cash')
      case '1': //CASH_BANK
        return t('CashBank')
      case 'M':
        return t('MomoPay')
      case '6': //BANKTRANSFER_FORAGENT
      case '8': //BANKTRANSFER
      case '9': //BANKTRANSFER_FORAGENT_OFGA
        return t('BankTransfer')
      case '2': //LOANPREMIUM
        return t('LoanRepay')
      // case '4': //INTERNALFUNDING
      // case '7': //PAYMENTBYBANK
      // case 'G': //GAREFUNDPAYABLE
      // case 'R': //REVERSAL
      // case '3': //VIETTEL
      //   return t('OtherPaymode')
      default:
        return ''
    }
  }

  return (
    <View>
      {loading ? (
        <View>
          <ActivityIndicator size="large" animating={true} hidesWhenStopped={true} color="red" />
        </View>
      ) : (
        <View>
          {paymodeData && getPayModeLabel(paymodeData.paymentMode) ?
            <View>
              <Text style={{ fontSize: 15, fontWeight: 'bold', paddingBottom: 24, paddingTop: 10 }}>{getPayModeLabel(paymodeData.paymentMode)}</Text>
              <View style={[IBStyles.row, { paddingBottom: 15 }]}>
                {dataPayMode(paymodeData).map((field) => (
                  paymodeData.paymentMode && field.listPayModeShow.includes(paymodeData.paymentMode) &&
                    <View style={[IBStyles.sectionCol4, { paddingBottom: 24, paddingRight: 8 }]}>
                      <View style={{ flexDirection: 'row', marginTop: 5, marginBottom: 4 }}>
                        <Text style={{ fontWeight: '700', color: '#58647A', fontSize: 15 }}>{field.title}</Text>
                      </View>
                      <Text style={{ fontSize: 15, marginTop: 4 }}>{field.value ?? '-'}</Text>
                    </View>
                ))}
              </View>
            </View> :
            <Text style={{ textAlign: 'center', fontSize: 15, alignItems: 'center' }}>{t('common:Nodata')}</Text>
          }
        </View>
      )}
    </View>
  )
}