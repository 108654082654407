import { ZIO, Task } from '@mxt/zio'
import { Maybe } from '@mxt/zio/codec'
import { pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'
import { AuthService } from '../auth'
import { POApi } from '../POApi'
import { StorageBlob } from '../storage-blob'

export namespace FinalLetterService {
  const AcceptPaymentOfInsuranceBenefits = t.type({
    processInstanceId: t.string,
    policyNumber: t.string,
    productCode: t.string,
    planName: t.string,
    componentCode: t.string,
    benefitClaimCode: t.string,
    nameBenefitAssurance: t.string,
    durationICU: t.string,
    durationHospitalization: t.string,
    sumAssured: t.string,
    benefitClaimAmount: t.string,
    claimDecisionCode: t.string,
    insuredAmount: t.string,
    timesToBePaid: t.string,
    insuranceBenefitsCovered: t.string
  })

  const HumanitarianPayments = t.type({
    nameBenefitAssurance: t.string,
    insuredAmount: t.string,
    insuranceBenefitsCovered: t.string
  })

  // const DeclineDescriptionDtoList = t.type({
  //   description: t.string
  // })

  const Declines = t.type({
    nameAssuranceBenefit: t.string,
    declineCode: t.string,
    anotherDeclineCode: t.string,
    freeText: Maybe(t.string)
    // declineDescriptionDtoList: Maybe(t.array(DeclineDescriptionDtoList))
  })

  const Body = t.type({
    partialPaymentQLHS: t.string,
    partialPaymentQLICU: t.string,
    customerName: t.string,
    addresses: t.array(t.string),
    phoneNumber: t.string,
    dayTime: t.number,
    monthTime: t.number,
    yearTime: t.number,
    claimNo: t.string,
    policyNo: t.string,
    POName: t.string,
    LAName: t.string,
    dateOfAdmission: t.string,
    dischargeDate: t.string,
    assuranceType: t.string,
    productName: t.string,
    durationHSDays: t.string,
    durationICUDays: t.string,
    contractValidityDate: t.string,
    contractExpiredDate: t.string,
    acceptPaymentOfInsuranceBenefits: t.array(AcceptPaymentOfInsuranceBenefits),
    amountInLetters: t.string,
    declines: Maybe(t.array(Declines)),
    humanitarianPayments: t.array(HumanitarianPayments),
    letterContent: t.string,
    deadline: t.number,
    eventDate: t.string,
    cause: t.string
  })
  export const GenerateLetterRequestC = t.type({
    body: Body
  })

  export type GenerateLetterRequestC = t.TypeOf<typeof GenerateLetterRequestC>

  export const generateMajorLetterTemplate = (data: GenerateLetterRequestC) =>
    pipe(
      ZIO.zipPar(
        AuthService.token,
        AuthService.getLoginType,
        POApi.buildUrl(`document-generation/api/v1/generate-service/major-final-letter-generate`)
      ),
      ZIO.flatMap(([token, loginType, url]) =>
        ZIO.fromPromise(() => {
          return fetch(url, {
            method: 'POST',
            headers: {
              'Cache-Control': 'no-cache',
              Pragma: 'no-cache',
              'Content-Type': 'application/json; charset=utf-8',
              Authorization: `Bearer ${token}`,
              'X-Authen-Vendor': loginType
            },
            body: JSON.stringify(data)
          })
        })
      )
    )

  export const generateMinorLetterTemplate = (data: GenerateLetterRequestC) =>
    pipe(
      ZIO.zipPar(
        AuthService.token,
        AuthService.getLoginType,
        POApi.buildUrl(`document-generation/api/v1/generate-service/minor-final-letter-generate`)
      ),
      ZIO.flatMap(([token, loginType, url]) =>
        ZIO.fromPromise(() => {
          return fetch(url, {
            method: 'POST',
            headers: {
              'Cache-Control': 'no-cache',
              Pragma: 'no-cache',
              'Content-Type': 'application/json; charset=utf-8',
              Authorization: `Bearer ${token}`,
              'X-Authen-Vendor': loginType
            },
            body: JSON.stringify(data)
          })
        })
      )
    )
}
