import React from 'react'
import { createStackNavigator } from "@react-navigation/stack"
import { AdminPageStackParamList, AdminTableList } from "./AdminPageStackParamList"
import { AdminPageScreen } from "./AdminPageScreen"
import { ConfigT33OfficeScreen } from './ConfigT33OfficeScreen'
import { ConfigBankListScreen } from './ConfigBankListScreen'
import { ConfigBankBranchListScreen } from './ConfigBankBranchListScreen'
import { ConfigGAInformationListScreen } from './ConfigGAInformationListScreen'

const Stack = createStackNavigator<AdminPageStackParamList>()

const stackNavigationItemList: Array<{ component: any, name: AdminTableList | 'AdminPageScreen' }> = [
  { component: AdminPageScreen, name: 'AdminPageScreen' },
  { component: ConfigT33OfficeScreen, name: AdminTableList.ConfigT33OfficeScreen },
  { component: ConfigBankListScreen, name: AdminTableList.ConfigBankListScreen},
  { component: ConfigBankBranchListScreen, name: AdminTableList.ConfigBankBranchListScreen},
  { component: ConfigGAInformationListScreen, name: AdminTableList.ConfigGAInformationListScreen}
]
export const AdminPageStack = () => {

  const getStackScreens = () => {
    return stackNavigationItemList.map((stackItem, index) => {
      return <Stack.Screen key={index} name={stackItem.name} component={stackItem.component} />
    })
  }

  return (
    <Stack.Navigator initialRouteName="AdminPageScreen" screenOptions={{ headerShown: false }}>
      {/* <Stack.Screen name="AdminPageScreen" component={AdminPageScreen} /> */}
      {getStackScreens()}
    </Stack.Navigator>
  )
}