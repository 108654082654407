import { Task, Throwable, ZIO } from '@mxt/zio'
import * as D from 'date-fns'
import { pipe } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import { Linking } from 'react-native'
import { POLogging } from '../POLogging'
import { AuthGA } from './AuthGA'
import { AuthStaff } from './AuthStaff'
import { AuthState } from './AuthState'
import * as expoLinking from 'expo-linking'
import { AppConfig } from '../config'
import { Subject } from 'rxjs'
import { ZStream } from '@mxt/zio/stream'
import * as Rx from 'rxjs/operators'

export namespace AuthService {
  const logger = POLogging.getLogger('AuthService')

  export const init = pipe(
    ZIO.zip(
      ZIO.fromPromiseTotal(() => Linking.getInitialURL()),
      AuthState.auth.get
    ),
    ZIO.tap((res) => logger.info('getInitialURL, authData', res)),
    ZIO.flatMap(([url, authData]) => {
      if (!url) {
        return ZIO.fail(Throwable('initial url is empty'))
      }
      if (url.includes('code=')) {
        // AAD & keycloak redirect urls with login state
        return pipe(
          logger.info('url with auth code, handle auth callback'),
          ZIO.flatMap(() => AuthStaff.handleRedirectPromise),
          ZIO.flatMap((res) =>
            res.account?.username
              ? AuthState.setStaffLogin(res.account?.username)
              : logger.info('cannot set staff login username')
          )
        )
      }
      switch (authData.tag) {
        case AuthState.Status.Unauthenticated: {
          const redirectUrl = url.replace(expoLinking.createURL(''), '')
          // console.log('url: ' + url)
          // console.log('expoLinking: ' + expoLinking.createURL(''))
          // console.log('redirectUrl: ' + redirectUrl)
          return AuthState.redirectUrl.set(redirectUrl)
        }
        case AuthState.Status.GA:
          return pipe(D.isAfter(new Date(), authData.token.expires_at), (isExpired) =>
            // isExpired ? AuthState.setUnauthenticated : ZIO.unit
            {
              if (isExpired) {
                const redirectUrl = url.replace(expoLinking.createURL(''), '')
                // console.log('url: ' + url)
                // console.log('expoLinking: ' + expoLinking.createURL(''))
                // console.log('redirectUrl: ' + redirectUrl)
                return AuthState.setUnauthenticatedInitial(redirectUrl)
              } else {
                return ZIO.unit
              }
            }
          )
        case AuthState.Status.Staff:
          return pipe(
            AuthStaff.acquireTokenSilent(authData.username),
            // ZIO.flatMap((token) => (O.isNone(token) ? AuthState.setUnauthenticated : ZIO.unit))
            ZIO.flatMap((token) => {
              if (O.isNone(token)) {
                const redirectUrl = url.replace(expoLinking.createURL(''), '')
                // console.log('url: ' + url)
                // console.log('expoLinking: ' + expoLinking.createURL(''))
                // console.log('redirectUrl: ' + redirectUrl)
                return AuthState.setUnauthenticatedInitial(redirectUrl)
              } else {
                return ZIO.unit
              }
            })
          )
      }
    })
  )

  export const token: Task<string> = pipe(
    AuthState.auth.get,
    ZIO.flatMap((auth) => {
      switch (auth.tag) {
        case AuthState.Status.Unauthenticated:
          return ZIO.fail(Throwable('Unauthenticated'))
        case AuthState.Status.GA:
          return pipe(
            ZIO.effect(() => {
              sessionCheck.next({ access_token: auth.token.access_token, authState: AuthState.Status.GA })
            }),
            ZIO.map(() => auth.token.access_token)
          )
        case AuthState.Status.Staff:
          return pipe(
            AuthStaff.acquireTokenSilent(auth.username),
            ZIO.mapFoldOptionM(
              () => ZIO.fail(Throwable('acquireTokenSilent failed')),
              (res) => ZIO.succeed(res.accessToken)
            )
          )
      }
    })
  )

  export const loginGA = (body: { username: string; password: string }) =>
    pipe(
      AuthGA.login(body),
      ZIO.flatMap((success) =>
        AuthState.setGALogin({
          access_token: success.access_token,
          expires_at: D.addSeconds(new Date(), success.expires_in)
        })
      )
    )

  export const loginStaff = AuthStaff.loginRedirect

  export const logout: Task<void> = pipe(AuthState.setUnauthenticated)

  export const userInfo: Task<{ email: string; name: string; officeCode: string | null; isGaLogin: boolean }> = pipe(
    ZIO.zipPar(token, AuthState.auth.get),
    ZIO.map(([token, auth]) => {
      const data = parseJwt(token)
      const isGaLogin = auth.tag === AuthState.Status.GA
      const email = isGaLogin ? data.email : data.preferred_username
      const name = isGaLogin ? data.preferred_username : data.name
      const officeCode = isGaLogin ? data.preferred_username.substr(2, 3).toUpperCase() : null
      return {
        email,
        name,
        officeCode,
        isGaLogin
      }
    })
  )

  export const redirectInitialLink = (linkTo: (path: string) => void) =>
    pipe(
      AuthState.redirectUrl.get,
      ZIO.mapFoldOptionM(
        () => ZIO.unit,
        (url) =>
          pipe(
            AuthState.redirectUrl.remove,
            ZIO.flatMap(() =>
              pipe(
                AppConfig.get,
                ZIO.map((cf) => {
                  const currVersion = cf.version.split('.').join('-') || '1-0-0'
                  // console.log('inital Url : ' + url)
                  !!url && url.includes(`${currVersion}/rn`) && linkTo(url)
                })
              )
            )
          )
      )
    )

  export const getLoginType = pipe(
    AuthState.auth.get,
    ZIO.map((auth) => {
      switch (auth.tag) {
        case AuthState.Status.Unauthenticated:
          return ''
        case AuthState.Status.GA:
          return 'GA'
        case AuthState.Status.Staff:
          return 'STAFF'
      }
    })
  )

  export const sessionCheck = new Subject<{ access_token: string, authState: AuthState.Status }>()

  export const singleSession = pipe(
    sessionCheck.asObservable(),
    Rx.throttleTime(20000),
    ZStream.fromObservable,
    ZStream.chainEffect((authenInfo) => {
      const sessionID = parseJwt(authenInfo.access_token)?.session_state
      console.log('sessionID:' + sessionID)
      return AuthGA.checkSessionAPI(sessionID, authenInfo.authState)
    })
  )
}

export const parseJwt = (token: string) => {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )
  return JSON.parse(jsonPayload)
}
