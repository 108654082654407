import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import {
  TaskDetail,
  PulseOpsFormat,
  FrequencyMapping,
  formatNumberWithComma,
  Panel,
  CancelRiderService,
  ErrorHandling,
  TableField,
  GeneralService,
  RiderReinstatementService,
  useTranslator,
  RadioButton,
  convertToAmount,
  formatTextFromLasToVN,
  notEmpty,
  MajorCombined,
  RiderAlterationService,
  RiderAlterationFormData,
  SelectOption,
  Checkbox,
  AddNewLAModal,
  LifeAssuredTable,
  AlterRiderTable,
  CancelRider
} from '@pulseops/common'
import { SectionContent } from '@pulseops/submission/common'
import { pipe } from 'fp-ts/lib/function'
import { TFunctionResult } from 'i18next'
import React from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ActivityIndicator, SafeAreaView, StyleSheet, View } from 'react-native'
import { Text } from 'react-native-elements'
import * as A from 'fp-ts/lib/Array'

import { Title, InfoViewList, DataSource } from '../common'

const MajorTypes = {
  MJ_IN_FREE_LOOK: 'MAJOR_CHANGE_IN_FREE_LOOK',
  MJ_AFTER_FREE_LOOK: 'MAJOR_CHANGE_AFTER_FREE_LOOK'
}

type Output = {
  label: string
  value: string | number | JSX.Element | TFunctionResult
  suffix?: string
  isHighlight?: boolean
}

type VeriRiderInfo = {
  coverageCode: string
  riskCommDate: string
  lapsedDate: string
  sumAssured: string
  riderPremium: string
}

type StatementOfInsurability = {
  laName: string
  newHeathState: boolean
  newOccupationState: boolean
  curOccupation: string
  newOccupation: string
  newActivityState: boolean
  newActivity: string
  newCompanyState: boolean
  newCompany: string
  riderList: VeriRiderInfo[]
}

type StatementOfInsurabilityReins = {
  laName: string
  newHeathState: boolean
  newOccupationState: boolean
  curOccupation: string
  newOccupation: string
  newActivityState: boolean
  newActivity: string
  newCompanyState: boolean
  newCompany: string
}

type DataSourceCancelRider = {
  riderName: string
  riskCommDate: string
  riskCessDate: string
  sumAssured: string
  installmentPremium: string
}

export const MajorCombinedChange = ({
  detail,
  policyNum
}: {
  detail?: TaskDetail.VERIMajorCombined
  policyNum: string
}) => {
  const detailPayload = detail?.submissionData
  const riderNameList = detail?.riderNameList
  const [dataStatementReins, setDataStatementReins] = React.useState<StatementOfInsurabilityReins[]>([])
  const [dataReinstatement, setDataReinstatement] = React.useState<Output[]>([])
  const [dataStatementRiderReins, setDataStatementRiderReins] = React.useState<StatementOfInsurability[]>([])

  const [isShowPopup, setIsShowPopup] = React.useState<boolean>(false)
  const [newLAInfoIndex, setNewLAInfoIndex] = React.useState<number>(-1)

  const riderAlterationForm = useForm<RiderAlterationFormData.RiderAlteration>({
    defaultValues: {
      LARiderAlterationList: [],
      isAddRider: false,
      isRiderAlteration: false
    }
  })

  const LAFieldList = useFieldArray({
    control: riderAlterationForm.control,
    name: 'LARiderAlterationList'
  })

  const NewRiderLAList = useFieldArray({
    control: riderAlterationForm.control,
    name: 'newRiderLAList'
  })
  const [reinsFee, setReinsFee] = React.useState<number>(0)
  const [loading, bindLoader] = useLoading(false)
  const { t, i18n } = useTranslation()
  const st = useTranslator('submission').t
  const columnsRiderReins = [
    {
      key: '1',
      title: st('Rider'),
      name: 'productName'
    },
    {
      key: '2',
      title: st('RiskCommDate'),
      name: 'riskCommDate'
    },
    {
      key: '3',
      title: st('LapsedDate'),
      name: 'lapsedDate'
    },
    {
      key: '4',
      title: st('SumAssured'),
      name: 'sumAssured'
    },
    {
      key: '5',
      title: st('InstallmentPremium'),
      name: 'riderPremium'
    }
  ]

  const columnsCancelRider = [
    {
      key: '1',
      title: t('requestInfo:RiderName'),
      name: 'riderName'
    },
    {
      key: '2',
      title: t('requestInfo:RiskCommencementDate'),
      name: 'riskCommDate'
    },
    {
      key: '3',
      title: t('requestInfo:RiskCessationDate'),
      name: 'riskCessDate'
    },
    {
      key: '4',
      title: t('requestInfo:SumAssured'),
      name: 'sumAssured'
    },
    {
      key: '5',
      title: t('requestInfo:InstallmentPremium'),
      name: 'installmentPremium'
    }
  ]

  const getRiderName = (riderCode: string) => {
    const item = riderNameList ? riderNameList.find((x) => x.code === riderCode) : undefined
    return item ? riderCode + ' - ' + (i18n.language === 'en' ? item.name : item.nameVi) : ''
  }

  const getCoreRiders = (coreRiders: CancelRider.SubmitedRiderInfo[]): DataSourceCancelRider[] => {
    return coreRiders.map((coreRider) => ({
      riderName: getRiderName(coreRider.productCode),
      riskCommDate: coreRider.riskCommDate,
      riskCessDate: coreRider.riskCessDate,
      sumAssured: formatNumberWithComma(coreRider.sumAssured),
      installmentPremium: formatNumberWithComma(coreRider.installmentPremium)
    }))
  }

  React.useEffect(() => {
    console.log(detail, 'detailData')

    if (detailPayload?.riderReinstatement)
      pipe(
        ZIO.zipPar(
          GeneralService.getOccupations,
          CancelRiderService.getRiderInfoList(),
          RiderReinstatementService.getReinStateFee(policyNum!)
        ),
        ZIO.map(([occupations, riderInfoList, reinsFeeRider]) => {
          setReinsFee(reinsFeeRider ?? 0)
          const dataStatementOfInsurability = detailPayload?.riderReinstatement?.lifeAssuredList.map((l) => ({
            ...l,
            curOccupation: occupations.find((o) => o.code === l.curOccupation)?.name ?? l.curOccupation ?? '-',
            newOccupation: occupations.find((o) => o.code === l.newOccupation)?.name ?? l.newOccupation
          }))

          const newDataStatementOfInsurability = dataStatementOfInsurability?.map((item) => {
            const riderListShow: VeriRiderInfo[] = item.riderList.map((itemRider) => {
              const riderFind = riderInfoList.filter((riderInfo) => itemRider.coverageCode === riderInfo.code)
              let riderName = ''
              if (riderFind[0]) riderName = i18n.language === 'vi' ? riderFind[0].nameVi : riderFind[0].name
              return {
                ...itemRider,

                riskCommDate: PulseOpsFormat.dateStringtoFormat(itemRider.riskCommDate, 'DD/MM/YYYY'),
                lapsedDate: PulseOpsFormat.dateStringtoFormat(itemRider.lapsedDate, 'DD/MM/YYYY'),
                productName: riderName,
                riderPremium: formatNumberWithComma(itemRider?.riderPremium ?? 0),
                sumAssured: formatNumberWithComma(itemRider?.sumAssured ?? 0)
              }
            })
            return {
              laName: item.clientName,
              newHeathState: item.healthStateChange,
              newOccupationState: item.occupationChange,
              curOccupation: item.curOccupation,
              newOccupation: item.newOccupation || '',
              newActivityState: item.activityChange,
              newActivity: item.newActivity || '',
              newCompanyState: item.companyChange,
              newCompany: item.newCompany || '',
              riderList: riderListShow || []
            }
          })

          setDataStatementRiderReins(newDataStatementOfInsurability || [])
          return ZIO.unit
        }),
        bindLoader,
        ZIO.unsafeRun({})
      )

    if (detailPayload?.reinstatement)
      pipe(
        GeneralService.getOccupations,
        ZIO.tap((occupations) => {
          const billingFrequency: string | TFunctionResult =
            (detailPayload?.reinstatement?.paymentFrequency &&
              FrequencyMapping.mapFrequency.get(detailPayload?.reinstatement?.paymentFrequency)) ||
            '-'

          setDataReinstatement([
            {
              label: t('requestInfo:RiskCommencementDate'),
              value: detailPayload?.reinstatement?.riskCommencementDate || '-'
            },
            { label: t('requestInfo:LapsedDate'), value: detailPayload?.reinstatement?.lapsedDate },
            {
              label: t('requestInfo:BillingFrequency'),
              value: t(billingFrequency)
            },
            {
              label: t('requestInfo:InstallmentPremium'),
              value:
                detailPayload?.reinstatement?.installmentPremium &&
                formatNumberWithComma(detailPayload?.reinstatement?.installmentPremium),
              suffix: 'VND'
            },
            {
              label: t('requestInfo:AutomaticPolicyLoan'),
              value: detailPayload?.reinstatement?.apl && formatNumberWithComma(detailPayload?.reinstatement?.apl),
              suffix: 'VND'
            },
            {
              label: t('requestInfo:OriginalLoan'),
              value: detailPayload?.reinstatement?.opl && formatNumberWithComma(detailPayload?.reinstatement?.opl),
              suffix: 'VND'
            },
            {
              label: t('requestInfo:ReinstatementFee'),
              value: detailPayload?.reinstatement?.reinstatementFee
                ? formatNumberWithComma(detailPayload?.reinstatement?.reinstatementFee)
                : '0',
              suffix: 'VND'
            },
            {
              label: t('requestInfo:TotalReinstatementAmount'),
              value: detailPayload?.reinstatement?.totalReinsAmount
                ? formatNumberWithComma(detailPayload?.reinstatement?.totalReinsAmount)
                : '0',
              suffix: 'VND',
              isHighlight: true
            }
          ])
          const dataStatementOfInsurability = detailPayload?.reinstatement?.lifeAssured.map((l) => ({
            ...l,
            curOccupation: occupations.find((o) => o.code === l.curOccupation)?.name ?? l.curOccupation ?? '-',
            newOccupation: occupations.find((o) => o.code === l.newOccupation)?.name ?? l.newOccupation
          }))

          const newDataStatementOfInsurability = dataStatementOfInsurability?.map((item) => ({
            laName: item.laName,
            newHeathState: item.newHeathState,
            newOccupationState: notEmpty(item?.newOccupation || ''),
            curOccupation: item.curOccupation,
            newOccupation: item.newOccupation || '',
            newActivityState: notEmpty(item?.newActivity || ''),
            newActivity: item.newActivity || '',
            newCompanyState: notEmpty(item?.newCompany || ''),
            newCompany: item.newCompany || ''
          }))

          setDataStatementReins(newDataStatementOfInsurability || [])

          return ZIO.unit
        }),
        bindLoader,
        ZIO.unsafeRun({})
      )

    if (detailPayload?.riderAlteration) {
      const lifeAssuredAdditions = detailPayload.riderAlteration.lifeAssuredAdditions
      pipe(
        lifeAssuredAdditions,
        A.map((lifeAssuredItem) =>
          RiderAlterationService.getRiderList(policyNum, lifeAssuredItem.lifeAssured.lifeNo ?? '')
        ),
        ZIO.sequence,
        ZIO.map((riderNameArr) => {
          const lifeAssuredArr = lifeAssuredAdditions.map((item) => {
            const alteredRiderList = riderNameArr.find((x) => x.lifeNo === item.lifeAssured.lifeNo)?.riderArr ?? []
            return {
              lifeAssured: {
                ...item.lifeAssured,
                alteredRiderList: alteredRiderList
              }
            }
          })
          return lifeAssuredArr
        }),
        ZIO.map((lifeAssuredList) => {
          let riderFormArray: Array<RiderAlterationFormData.CurrentRiderForm> = []
          const LARiderItemList = detailPayload?.riderAlteration?.lifeAssuredList || []
          riderAlterationForm.setValue('isRiderAlteration', !!detailPayload?.riderAlteration?.isRiderAlteration)
          riderAlterationForm.setValue('isAddRider', !!detailPayload?.riderAlteration?.isAddLA)
          LARiderItemList.map((LAItem) => {
            LAItem.coreRiders.map((rider) => {
              const productName = getRiderName(rider.productCode)
              const waiverList = detailPayload?.riderAlteration?.waiverList || []
              const isWaiverProduct =
                waiverList.length > 0 ? waiverList.some((waiverItem) => waiverItem === rider.productCode) : false
              riderFormArray.push({
                riderCode: rider.productCode,
                riderName: productName,
                isWaiverProduct: isWaiverProduct,
                isClassNumber: false,
                classNumberList: [],
                // SelectRowForRider: false,
                riskCommDate: rider.riskCommDate,
                riskCessDate: rider.riskCessDate,
                sumAssured: rider.sumAssured,
                alteredSumAssured: rider.alteredSumAssured,
                installmentPrem: rider.installmentPremium,
                newInstallmentPremium: 0,
                lifeNo: rider.lifeNo,
                coverageNo: rider.coverageNo,
                riderNo: rider.riderNo,
                riderStatus: rider.riderStatus
              })
            })

            LAFieldList.append({
              // selectAllForLA: false,
              LAName: LAItem.clientName,
              lifeAssured: LAItem.lifeAssured,
              currentRiders: riderFormArray
            })

            riderFormArray = []
          })
          lifeAssuredList.map((lifeItem, index) => {
            // addNewRiderLAItem(index)
            const riderList = lifeItem.lifeAssured.riderListAdditional.map((riderItem) => {
              const riderInfo = getAlterRiderOptions(lifeItem.lifeAssured.alteredRiderList).find(
                (item) => item.value === riderItem.coverageCode
              ) ?? {
                value: '',
                label: ''
              }
              return {
                riderName: riderInfo,
                sumAssured: riderItem.sumAssured.toString(),
                policyPeriod: riderItem.policyPeriod.toString(),
                isWaiverProduct: false,
                isClassNumber: false,
                classNumberList: []
              }
            })
            NewRiderLAList.insert(index, {
              LAName: lifeItem.lifeAssured.fullName,
              lifeAssured: '',
              isNewLA: lifeItem.lifeAssured.isNewLA ? true : false,
              newRiderList: riderList,
              detailData: lifeItem.lifeAssured,
              alteredRiderList: lifeItem.lifeAssured.alteredRiderList
            })
            riderAlterationForm.setValue(`newRiderLAList.${index}.LAName`, lifeItem.lifeAssured.fullName)
            riderAlterationForm.setValue(`newRiderLAList.${index}.lifeAssured`, '')
            riderAlterationForm.setValue(`newRiderLAList.${index}.isNewLA`, lifeItem.lifeAssured.isNewLA ? true : false)
            riderAlterationForm.setValue(`newRiderLAList.${index}.newRiderList`, riderList)
            riderAlterationForm.setValue(`newRiderLAList.${index}.detailData`, lifeItem.lifeAssured)
            riderAlterationForm.setValue(
              `newRiderLAList.${index}.alteredRiderList`,
              lifeItem.lifeAssured.alteredRiderList
            )
          })
        }),
        ZIO.unsafeRun({})
      )
    }
  }, [detail && i18n])

  const getAlterRiderOptions = (riderArr: RiderAlterationService.RiderNameInfo[]) => {
    const riderList = riderArr.map((item) => ({
      label: item.code + ' - ' + (i18n.language === 'en' ? item.name : item.nameVi),
      value: item.code
    }))
    return riderList
  }

  const closeAddLAModal = () => {
    setIsShowPopup(false)
  }

  const viewLADetail = (index: number) => {
    setNewLAInfoIndex(index)
    setIsShowPopup(true)
  }
  const openViewDetailPopUp = () => {
    const veriDetailData = riderAlterationForm.watch(`newRiderLAList.${newLAInfoIndex}.detailData`)
    console.log('viewItem', veriDetailData)
    return isShowPopup ? (
      <AddNewLAModal
        title={t('RiderAlteration:NewLAInformation')}
        isVisible={isShowPopup}
        isConfirmed={true}
        onClose={closeAddLAModal}
        isDetailView={true}
        detailIndex={newLAInfoIndex}
        veriDetailData={veriDetailData}
        riderAlterationForm={riderAlterationForm}
        // NewRiderLAList={NewRiderLAList}
        onSuccess={() => {}}
      />
    ) : (
      <></>
    )
  }

  const formatText = (text: string): string => {
    return formatTextFromLasToVN(text)
  }

  const getFieldDataProductOption = (coverageCode: string): DataSource[] => {
    const isVLR = coverageCode.startsWith('V') || coverageCode.startsWith('U')
    const fields: DataSource[] = [
      {
        label: isVLR ? t('submission:CurrentInsuranceBenefits') : t('submission:CurrentEducationalBenefits'),
        value: t(`${coverageCode}:${detailPayload?.productOptionSwitching?.oldBenefit}`) || '-'
      },
      // {
      //   label: isVLR ? t('submission:NewInsuranceBenefits') : t('submission:NewEducationalBenefits'),
      //   value: detail.newBenefitOption || '-'
      // },
      {
        label: t('submission:CurrentSumAssured'),
        value: formatNumberWithComma(detailPayload?.productOptionSwitching?.currentSumAssured || ''),
        suffix: 'VND'
      },
      {
        label: t('submission:InstallmentPremium'),
        value: formatNumberWithComma(detailPayload?.productOptionSwitching?.curRenewalPremium || ''),
        suffix: 'VND'
      },
      {
        label: isVLR ? t('submission:NewInsuranceBenefits') : t('submission:NewEducationalBenefits'),
        value: t(`${coverageCode}:${detailPayload?.productOptionSwitching?.benefits}`) || '-'
      },
      {
        label: t('submission:NewSumAssured'),
        value: formatNumberWithComma(detailPayload?.productOptionSwitching?.newSumAssured || ''),
        suffix: 'VND',
        isHighlight: true
      },
      {
        label: t('submission:NewInstallmentPremium'),
        value: formatNumberWithComma(detailPayload?.productOptionSwitching?.curRenewalPremium || ''),
        suffix: 'VND',
        isHighlight: true
      }
    ]

    return fields
  }

  const getFieldDataBillingChange = (): Output[] => {
    let data: Output[] = []

    if (detailPayload?.billingChange) {
      const currentFrequency: string | TFunctionResult =
        FrequencyMapping.mapFrequency.get(detailPayload?.billingChange.curBillingFrequency) || '-'
      const newFrequency: string | TFunctionResult =
        FrequencyMapping.mapFrequency.get(detailPayload?.billingChange.newBillingFrequency) || '-'
      data = [
        {
          label: t('requestInfo:CurrentBillingFrequency'),
          value: t(currentFrequency)
        },
        {
          label: t('requestInfo:CurentInstallmentPremium'),
          value: detailPayload?.billingChange.curInstallmentPremium
            ? formatNumberWithComma(detailPayload?.billingChange.curInstallmentPremium)
            : '0',
          suffix: 'VND'
        },
        {
          label: t('requestInfo:SuspenseAmount'),
          value: detailPayload?.billingChange.suspendAmount
            ? formatNumberWithComma(detailPayload?.billingChange.suspendAmount)
            : '0',
          suffix: 'VND'
        },
        {
          label: t('requestInfo:NewBillingFrequency'),
          value: t(newFrequency),
          isHighlight: true
        },
        {
          label: t('requestInfo:NewInstallmentPremium'),
          value: detailPayload?.billingChange.newInstallmentPremium
            ? formatNumberWithComma(detailPayload?.billingChange.newInstallmentPremium)
            : '0',
          suffix: 'VND'
        },
        {
          label: t('AdvancePremium'),
          value: detailPayload?.billingChange.advancePremium
            ? formatNumberWithComma(detailPayload?.billingChange.advancePremium)
            : '0',
          suffix: 'VND'
        },
        {
          label: t('requestInfo:EffectiveDateOfNewFrequency'),
          value: detailPayload?.billingChange.effectiveDateNewFrequency
            ? detailPayload?.billingChange.effectiveDateNewFrequency
            : ''
        },
        {
          label: t('requestInfo:RequirePayinAmount'),
          value:
            detailPayload?.billingChange.requirePayinAmount &&
            Number(detailPayload?.billingChange.requirePayinAmount) > 0
              ? formatNumberWithComma(detailPayload?.billingChange.requirePayinAmount)
              : '0',
          suffix: 'VND',
          isHighlight: true
        }
      ]
    }
    return data
  }

  const changeContractTypeArray = [
    {
      fields: [
        {
          title: t('MajorCombined:BasicProduct'),
          value: detailPayload?.changeContract?.basicProductCode ?? '',
          isHighlight: false
        },
        {
          title: t('requestInfo:RiskCommencementDate'),
          value: detailPayload?.changeContract?.riskCommDate
            ? PulseOpsFormat.dateStringtoFormat(detailPayload.changeContract.riskCommDate, 'DD/MM/YYYY')
            : '',
          isHighlight: false
        },
        {
          title: t('MajorCombined:Age'),
          value: detailPayload?.changeContract?.age ?? 0,
          isHighlight: false
        }
      ]
    },
    {
      fields: [
        {
          title: t('MajorCombined:NewSumAssured'),
          value: formatNumberWithComma(detailPayload?.changeContract?.sumAssured ?? 0),
          isHighlight: true
        },
        {
          title: t('MajorCombined:NewPolicyYear'),
          value: detailPayload?.changeContract?.policyYear ?? '',
          isHighlight: true
        },
        {
          title: t('MajorCombined:NewPremiumYear'),
          value: detailPayload?.changeContract?.premiumYear ?? '',
          isHighlight: true
        }
      ]
    },
    {
      fields: [
        {
          title: t('requestInfo:RiskCessationDate'),
          value: detailPayload?.changeContract?.riskCessDate
            ? PulseOpsFormat.dateStringtoFormat(detailPayload.changeContract.riskCessDate, 'DD/MM/YYYY')
            : '',
          isHighlight: false
        },
        {
          title: t('MajorCombined:InstallmentPremium'),
          value: formatNumberWithComma(detailPayload?.changeContract?.installmentPremium ?? 0),
          isHighlight: true
        }
      ]
    }
  ]

  const renderLoading = () => {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size="large" color="red" />
      </View>
    )
  }

  if (loading) {
    renderLoading()
  }

  return (
    <SafeAreaView style={styles.containerMarginBottom}>
      <Title wrapperStyle={styles.marginTop} title={t('requestInfo:RequestDetail')}></Title>
      {detailPayload?.majorType === MajorTypes.MJ_AFTER_FREE_LOOK ? (
        <Title wrapperStyle={styles.marginTop} title={t('MajorCombined:AfterFreeLook')}></Title>
      ) : (
        <Title wrapperStyle={styles.noMarginBottom} title={t('MajorCombined:InFreeLook')}></Title>
      )}

      {detailPayload?.changeContract && (
        <View style={styles.containerMarginBottom}>
          <Title title={t('TransactionType:CHANGE_CONTRACT_TYPE')} />

          <View style={styles.sectionContent}>
            {changeContractTypeArray.map((item, index) => (
              <View style={index === 0 ? styles.sectionRow : [styles.sectionRow, styles.secondLine]}>
                {item.fields.map((element) => (
                  <View style={styles.col_4}>
                    <Text style={styles.field_title}>{element.title}</Text>
                    <Text style={element.isHighlight ? [styles.value, styles.highlight] : styles.field_description}>
                      {element.value}
                    </Text>
                  </View>
                ))}
              </View>
            ))}
          </View>
        </View>
      )}

      {detailPayload?.billingChange && (
        <View style={styles.containerMarginBottom}>
          <Title title={t('TransactionType:BILLING_CHANGE')} />
          <Panel title="" isExand={false}>
            <InfoViewList dataSource={getFieldDataBillingChange()} />
          </Panel>
        </View>
      )}

      {detailPayload?.productOptionSwitching && (
        <View style={styles.containerMarginBottom}>
          <Title title={t('requestInfo:PRODUCT_OPTION_SWITCHING_INFORMATION')} />
          <Panel>
            <InfoViewList
              dataSource={getFieldDataProductOption(detailPayload.productOptionSwitching.coverageCode || '')}
            />
          </Panel>
        </View>
      )}

      {detailPayload?.riderCancellation && (
        <View style={styles.containerMarginBottom}>
          <Title title={t('TransactionType:CANCEL_RIDER')} />
          {detailPayload?.riderCancellation.lifeAssureds.length > 0 &&
            detailPayload?.riderCancellation?.lifeAssureds.map(
              (lifeAssureds: MajorCombined.SubmitLARider, index: number) => (
                <Panel title={lifeAssureds.clientName?.toUpperCase() || ''} key={index}>
                  <TableField
                    columns={columnsCancelRider}
                    dataSource={getCoreRiders(lifeAssureds.riders)}
                    currency="VND"
                  />
                </Panel>
              )
            )}
          <View>
            <Text style={styles.text}>{t('requestInfo:EstimatedNewInstallmentPremium')}</Text>
            <Text style={styles.isHightLight}>{detailPayload?.riderCancellation?.totalPremium} VND</Text>
          </View>
        </View>
      )}

      {detailPayload?.riderAlteration && (
        <View style={detailPayload?.riderAlteration ? styles.containerMarginBottom : styles.container}>
          <Title title={t('TransactionType:RIDER_ALTERATION').toUpperCase()} />
          <Title title={t('requestInfo:RIDERALTERATIONINFORMATION')} />
          <Controller
            control={riderAlterationForm.control}
            name={'isRiderAlteration'}
            render={({ field: { value, onChange, onBlur } }) => (
              <Checkbox
                title={t('RiderAlteration:RiderAlteration')}
                onChange={(checked) => {
                  onChange(checked)
                  // changeEventForLA(checked, index, fieldItem)
                }}
                onBlur={onBlur}
                value={value}
                disabled={true}
                checkBoxStyle={styles.tableCheckBox}
              />
            )}
          />
          {riderAlterationForm.watch('isRiderAlteration') && (
            <SectionContent sectionStyles={{ backgroundColor: '#fff' }}>
              <LifeAssuredTable
                riderAlterationForm={riderAlterationForm}
                LAFieldList={LAFieldList}
                isConfirmed={true}
                isVerificationScreen={true}
              />
            </SectionContent>
          )}
          <View style={styles.secondLine}>
            <Controller
              control={riderAlterationForm.control}
              name={'isAddRider'}
              render={({ field: { value, onChange, onBlur } }) => (
                <Checkbox
                  title={t('RiderAlteration:AddRider')}
                  onChange={(checked) => {
                    onChange(checked)
                    // changeEventForLA(checked, index, fieldItem)
                  }}
                  onBlur={onBlur}
                  value={value}
                  disabled={true}
                  checkBoxStyle={styles.tableCheckBox}
                />
              )}
            />
          </View>
          {riderAlterationForm.watch('isAddRider') && (
            <SectionContent sectionStyles={{ backgroundColor: '#fff' }}>
              <AlterRiderTable
                // newRiderList={newRiderList}
                riderAlterationForm={riderAlterationForm}
                NewRiderLAList={NewRiderLAList}
                disabled={true}
                // addNewRider={addNewRiderForExistedLA}
                // removeRider={removeRiderForExistedLA}
                // removeNewLA={removeNewLA}
                viewLADetail={viewLADetail}
                isVerificationScreen={true}
              />
            </SectionContent>
          )}
          {openViewDetailPopUp()}
        </View>
      )}

      {detailPayload?.majorType === MajorTypes.MJ_AFTER_FREE_LOOK && detailPayload.reinstatement && (
        <View style={styles.containerMarginBottom}>
          <Title title={t('TransactionType:REINSTATEMENT').toUpperCase()} />
          <Title title={t('requestInfo:ReinstatementInformation')} />
          <Panel title="" isExand={false}>
            <InfoViewList dataSource={dataReinstatement} />
          </Panel>
          {dataStatementReins.length > 0 && (
            <>
              <Title title={t('requestInfo:StatementOfInsurability')} />
              {dataStatementReins.map((item: StatementOfInsurabilityReins, index: number) => (
                <Panel title={item.laName} key={index}>
                  <View style={styles.col}>
                    <Text style={styles.field_title}>{t('requestInfo:AnyChangesInStateOfHealth')}</Text>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                      <RadioButton label={t('requestInfo:Change')} selected={item.newHeathState} />
                      <RadioButton label={t('submission:NoChange')} selected={!item.newHeathState} />
                    </View>
                  </View>
                  <View style={styles.col}>
                    <Text style={styles.field_title}>{t('requestInfo:AnyChangesInOccupation')}</Text>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                      <RadioButton label={t('requestInfo:Change')} selected={item.newOccupationState} />
                      <RadioButton label={t('submission:NoChange')} selected={!item.newOccupationState} />
                    </View>
                  </View>
                  {item.newOccupationState && (
                    <View style={styles.col}>
                      <View style={{ flexDirection: 'row', alignItems: 'flex-start' }}>
                        <View style={{ marginRight: 30 }}>
                          <Text style={styles.field_title}>{t('requestInfo:CurrentOccupation')}</Text>
                          <Text style={styles.value}>{item.curOccupation}</Text>
                        </View>
                        <View>
                          <Text style={styles.field_title}>{t('requestInfo:NewOccupation')}</Text>
                          <Text style={styles.value}>{item.newOccupation}</Text>
                        </View>
                      </View>
                    </View>
                  )}
                  <View style={styles.col}>
                    <Text style={styles.field_title}>{t('requestInfo:AnyChangeInActivities')}</Text>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                      <RadioButton label={t('requestInfo:Change')} selected={item.newActivityState} />
                      <RadioButton label={t('submission:NoChange')} selected={!item.newActivityState} />
                    </View>
                  </View>
                  {item.newActivityState && (
                    <View style={styles.col}>
                      <Text style={styles.field_title}>{t('requestInfo:NewActivity')}</Text>
                      <Text style={styles.value}>{item.newActivity}</Text>
                    </View>
                  )}
                  <View style={styles.col}>
                    <Text style={styles.field_title}>{t('requestInfo:AnyChangesInInsurancePolicy/Requesting')}</Text>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                      <RadioButton label={t('requestInfo:Change')} selected={item.newCompanyState} />
                      <RadioButton label={t('submission:NoChange')} selected={!item.newCompanyState} />
                    </View>
                  </View>
                  {item.newCompanyState && (
                    <View style={styles.col}>
                      <Text style={styles.field_title}>{t('requestInfo:CompanyName')}</Text>
                      <Text style={styles.value}>{item.newCompany}</Text>
                    </View>
                  )}
                </Panel>
              ))}
            </>
          )}

          <Title title={t('requestInfo:ReinstatementAgent')} />
          <Panel title="" isExand={false}>
            <View style={styles.col}>
              <Text style={styles.field_title}>{t('requestInfo:ReinstatementAgent')}</Text>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <Text style={(styles.value, { marginRight: 30 })}>
                  {detailPayload?.reinstatement?.agentNumber || '-'}
                </Text>
                <Text style={styles.value}>{formatText(detailPayload?.reinstatement?.servicingAgent || '-')}</Text>
              </View>
            </View>
          </Panel>
        </View>
      )}

      {detailPayload?.majorType === MajorTypes.MJ_AFTER_FREE_LOOK && detailPayload?.riderReinstatement && (
        <View style={styles.containerMarginBottom}>
          <Title title={t('TransactionType:RIDER_REINSTATEMENT').toUpperCase()} />

          <Title title={t('submission:RidersList').toUpperCase()} />
          <View>
            {dataStatementRiderReins.map((item, index) => {
              return (
                <Panel isExand key={index} title={<Text style={{ marginTop: 16 }}>{item.laName}</Text>}>
                  <View>
                    <TableField columns={columnsRiderReins} dataSource={item.riderList} currency="VND" />
                  </View>
                </Panel>
              )
            })}
          </View>
          <View style={{ marginVertical: 15, marginLeft: 10, marginBottom: 30 }}>
            <Text style={{ color: '#70777e', fontWeight: 'bold', fontSize: 15 }}>
              {t('submission:EstimatedReinstateFee')}
            </Text>
            <Text style={{ color: '#EE1A2D', fontWeight: 'bold', fontSize: 15 }}>
              {convertToAmount(reinsFee) + ' VND'}
            </Text>
          </View>
          {dataStatementRiderReins.length > 0 && (
            <>
              <Title title={t('requestInfo:StatementOfInsurability')} />
              {dataStatementRiderReins.map((item: StatementOfInsurability, index: number) => (
                <Panel title={item.laName} key={index}>
                  <View style={styles.col}>
                    <Text style={styles.field_title}>{t('requestInfo:AnyChangesInStateOfHealth')}</Text>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                      <RadioButton label={t('requestInfo:Change')} selected={item.newHeathState} />
                      <RadioButton label={t('submission:NoChange')} selected={!item.newHeathState} />
                    </View>
                  </View>
                  <View style={styles.col}>
                    <Text style={styles.field_title}>{t('submission:AnyChangesInOccupation')}</Text>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                      <RadioButton label={t('requestInfo:Change')} selected={item.newOccupationState} />
                      <RadioButton label={t('submission:NoChange')} selected={!item.newOccupationState} />
                    </View>
                  </View>
                  {item.newOccupationState && (
                    <View style={styles.col}>
                      <View style={{ flexDirection: 'row', alignItems: 'flex-start' }}>
                        <View style={{ marginRight: 30 }}>
                          <Text style={styles.field_title}>{t('submission:CurrentOccupation')}</Text>
                          <Text style={styles.value}>{item.curOccupation}</Text>
                        </View>
                        <View>
                          <Text style={styles.field_title}>{t('submission:NewOccupation')}</Text>
                          <Text style={styles.value}>{item.newOccupation}</Text>
                        </View>
                      </View>
                    </View>
                  )}
                  <View style={styles.col}>
                    <Text style={styles.field_title}>{t('submission:AnyChangeInActivities')}</Text>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                      <RadioButton label={t('requestInfo:Change')} selected={item.newActivityState} />
                      <RadioButton label={t('submission:NoChange')} selected={!item.newActivityState} />
                    </View>
                  </View>
                  {item.newActivityState && (
                    <View style={styles.col}>
                      <Text style={styles.field_title}>{t('submission:NewActivity')}</Text>
                      <Text style={styles.value}>{item.newActivity}</Text>
                    </View>
                  )}
                  <View style={styles.col}>
                    <Text style={styles.field_title}>{t('submission:AnyChangesInInsurancePolicy/Requesting')}</Text>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                      <RadioButton label={t('requestInfo:Change')} selected={item.newCompanyState} />
                      <RadioButton label={t('submission:NoChange')} selected={!item.newCompanyState} />
                    </View>
                  </View>
                  {item.newCompanyState && (
                    <View style={styles.col}>
                      <Text style={styles.field_title}>{t('requestInfo:CompanyName')}</Text>
                      <Text style={styles.value}>{item.newCompany}</Text>
                    </View>
                  )}
                </Panel>
              ))}
            </>
          )}
        </View>
      )}

      <View style={[styles.containerMarginBottom, { width: '33%' }]}>
        <Title wrapperStyle={styles.secondLine} title={t('submission:TEMPORARYPAYININFO')}></Title>
        <Text style={styles.field_title}>{t('MajorCombined:RequiredPayinAmount')}</Text>
        <Text style={styles.isHightLight}>
          {detailPayload?.requirePayinAmount ? formatNumberWithComma(detailPayload.requirePayinAmount) : '-'}
        </Text>
      </View>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    marginTop: 15
  },
  containerMarginBottom: {
    marginTop: 15,
    marginBottom: 10
  },
  noMarginBottom: {
    marginBottom: 0
  },
  marginTop: {
    marginTop: 15,
    marginBottom: 10
  },
  marginCheckBoxSelected: {
    marginBottom: 10
  },
  sectionContent: {
    padding: 20,
    backgroundColor: '#fafafa',
    border: '1px solid #d3dce6',
    boxSizing: 'border-box',
    borderRadius: 8,
    marginTop: 15
  },
  sectionRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginRight: -15,
    marginLeft: -15
  },
  col_4: {
    width: '100%',
    maxWidth: '33.3333333333%',
    paddingRight: 15,
    paddingLeft: 15
  },
  col_12: {
    width: '100%',
    maxWidth: '100%',
    paddingRight: 15,
    paddingLeft: 15
  },
  secondLine: {
    marginTop: 20
  },
  secondSmallLine: {
    marginTop: 10
  },
  field_title: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  },
  field_description: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 15,
    lineHeight: 22,
    color: '#000000'
  },
  redLine: {
    color: '#ed1c2e',
    fontWeight: '700'
  },
  underlineLink: {
    textDecorationLine: 'underline'
  },
  value: {
    color: '#000000',
    fontSize: 15,
    lineHeight: 22
  },
  text: {
    fontSize: 16,
    marginBottom: 10
  },
  isHightLight: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#eD1B2E'
  },
  col: {
    width: '100%',
    marginBottom: 15
  },
  tableCheckBox: {
    padding: 0,
    marginTop: 0,
    paddingLeft: 10
  },
  highlight: {
    color: '#FF0000'
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center'
  }
})
