import { FontAwesome5 } from '@expo/vector-icons'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import {
  assets,
  ErrorHandling,
  GeneralService,
  Input,
  PulseOpsFormat,
  SelectOption,
  SelectSearch
} from '@pulseops/common'
import * as D from 'date-fns'
import { pipe } from 'fp-ts/lib/function'
import _, { differenceWith, isEqual } from 'lodash'
import * as React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ActivityIndicator, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { Panel } from '../../claim-common'
import { ClaimInfoProps } from '../ClaimInfoProps'

export const ReceiptInformation = (props: ClaimInfoProps) => {
  const { t, i18n } = useTranslation(['claim', 'message'])
  const isExand = true
  const { form, isSurery, subBenefit, detail, setIsCanChangeTab } = props
  const { control, watch } = form
  const [isLoading, bindLoading] = useLoading(false)
  const translator = (en: string, vi: string) => (i18n.language === 'en' ? en : vi)
  const [receiptType, setReceiptType] = React.useState<SelectOption | null>(null)
  const [inpatientActualLOS, setInpatientActualLOS] = React.useState<string>('0')

  // let inpatientActualLOS = '-'
  React.useEffect(() => {
    const admissionDate = form.watch('hospital.admissionDate')
    const dischargeDate = form.watch('hospital.dischargeDate')
    if (admissionDate && dischargeDate) {
      setInpatientActualLOS(`${D.differenceInDays(dischargeDate, admissionDate)}`)
    }
    form.clearErrors('subBenefit')
  }, [form.watch('hospital.dischargeDate'), form.watch('hospital.admissionDate')])

  const receiptData = pipe(
    ZIO.zipPar(
      GeneralService.getReceiptData(),
      pipe(
        GeneralService.getSurgeryData(),
        ZIO.map((x) => {
          return x.map((y) => ({
            value: y.surgeryCode,
            label: i18n.language === 'en' ? y.nameEn : y.nameVn
          }))
        })
      )
    ),
    ZIO.tap(([item, surgeryData]) => {
      if (item?.length > 0) {
        const receiptDefault = {
          receiptItemCode: null,
          dayNumber: '1',
          expenseAmt: '0',
          claimAmt: '0',
          duration: '0',
          thirdPartyAmt: '0',
          excludedAmt: '0',
          surgeryCode: null,
          siAmt: '0',
          payableAmt: '0',
          isToggle: true
        }
        if (detail?.PATIENT_IN) {
          let data: any = []
          const receipt = [
            {
              ...receiptDefault,
              duration: inpatientActualLOS || '',
              receiptItemCode: {
                value: item[0].receiptItemCode,
                label: `${i18n.language === 'en' ? item[0].receiptItemEN : item[0].receiptItemVN}`
              }
            }
          ]
          form.setValue('receiptInformation', receipt)
          if (isSurery) {
            data = [
              {
                ...receiptDefault,
                receiptItemCode: {
                  value: item[2].receiptItemCode,
                  label: `${i18n.language === 'en' ? item[2].receiptItemEN : item[2].receiptItemVN}`
                }
              }
            ]
            form.setValue('receiptInformation', [...receipt, ...data])
          }
          if (detail?.PATIENT_IN?.icuBenefit) {
            const index = item?.findIndex((item) => item.receiptItemCode === 'RCI02')
            const receiptData = [
              {
                ...receiptDefault,
                receiptItemCode: {
                  value: item[index].receiptItemCode,
                  label: `${i18n.language === 'en' ? item[index].receiptItemEN : item[index].receiptItemVN}`
                }
              }
            ]
            form.setValue('receiptInformation', [...receipt, ...data, ...receiptData])
          }
        }
      }
      return ZIO.succeed(item)
    }),
    ZIO.map(([item, _]) => item),
    bindLoading,
    ErrorHandling.runDidUpdate([isSurery, inpatientActualLOS])
  )

  const addNewReceipt = (receiptData: SelectOption, index: number) => {
    const data = form.getValues().subBenefit[index].receiptItems || []
    const recipient = [
      {
        receiptItemCode: receiptData,
        dayNumber: '1',
        expenseAmt: '0',
        claimAmt: '0',
        duration: '0',
        thirdPartyAmt: '0',
        excludedAmt: '0',
        surgeryCode: props.isSurery
          ? [
              {
                value: 'DCL030',
                label: 'Surgical Fees'
              }
            ]
          : null,
        siAmt: '0',
        payableAmt: '0',
        isToggle: true
      }
    ]
    form.setValue(`subBenefit.${index}.receiptItems`, [...data, ...recipient])
    setReceiptType(null)
  }

  React.useEffect(() => {
    if (subBenefit?.value) {
      const receiptInformation = _.cloneDeep(watch('receiptInformation'))
      const index = watch('subBenefit').length - 1
      if (receiptInformation && receiptInformation.length) {
        receiptInformation.length && form.setValue(`subBenefit.${index}.receiptItems`, receiptInformation)
      }
    }
  }, [watch('subBenefit'), subBenefit])

  React.useEffect(() => {
    const benefits = detail?.detail.payload.verification?.subBenefit
    // benefit && form.setValue('subBenefit', benefits)
    if (benefits) {
      const resultBenefits = benefits.map((benefit, indexSub) => ({
        ...benefit,
        receiptItems: benefit.receiptItems.map((recepit, index) => {
          let surgeryCode: SelectOption[] = []
          if (recepit.surgeryCode && typeof recepit.surgeryCode === 'object' && !Array.isArray(recepit.surgeryCode)) {
            surgeryCode.push(recepit.surgeryCode)
          } else if (
            recepit.surgeryCode &&
            typeof recepit.surgeryCode === 'object' &&
            Array.isArray(recepit.surgeryCode)
          ) {
            surgeryCode = [...recepit.surgeryCode]
          }
          return {
            ...recepit,
            surgeryCode: recepit.receiptItemCode?.value === 'RCI03' ? surgeryCode : recepit.surgeryCode
          }
        })
      }))
      form.setValue('subBenefit', resultBenefits)
    }
  }, [detail?.detail.payload.verification?.subBenefit])

  const validatePatternNumber = /^[0-9,]*$/

  const checkDisableDelete = (value: string): boolean => {
    if (
      (detail?.PATIENT_IN && value === 'RCI01') ||
      (isSurery && value === 'RCI03') ||
      (detail?.PATIENT_IN?.icuBenefit && value === 'RCI02')
    ) {
      return true
    }
    return false
  }

  const hdDeleteReceiptItem = React.useCallback(
    (indexSub, indexReceipt) => {
      const sub = form.getValues().subBenefit
      sub[indexSub].receiptItems.splice(indexReceipt, 1)
      form.setValue(`subBenefit.${indexSub}.receiptItems`, sub[indexSub].receiptItems)
      const indexHRB = sub[indexSub].receiptItems.findIndex((item) => item.receiptItemCode?.value === 'RCI01')
      if (indexHRB > -1) {
        form.setValue(`subBenefit.${indexSub}.receiptItems.${indexHRB}.duration`, inpatientActualLOS || '')
      }
      setIsCanChangeTab(false)
    },
    [inpatientActualLOS]
  )

  const hdDeleteSubBenefit = React.useCallback((index) => {
    const sub = form.getValues().subBenefit
    sub.splice(index, 1)
    form.setValue('subBenefit', sub)
    setIsCanChangeTab(false)
  }, [])

  const hdDurationIntensive = (index: number, value: string) => {
    const sub = form.getValues().subBenefit[index]
    const indexItem = sub.receiptItems.findIndex((item) => item.receiptItemCode?.value === 'RCI01')
    setIsCanChangeTab(false)
    if (indexItem !== -1) {
      const data = Number(inpatientActualLOS) - Number(value)
      form.setValue(`subBenefit.${index}.receiptItems.${indexItem}.duration`, data.toString())
    }
  }

  return isLoading ? (
    <ActivityIndicator />
  ) : (
    <>
      {_.map(watch('subBenefit'), (item, indexSub) => {
        const sub1 = receiptData
          ? receiptData
              .filter((data) => data.subBenefit.includes(item?.value))
              ?.map((option) => ({
                value: option.receiptItemCode,
                label: `${i18n.language === 'en' ? option.receiptItemEN : option.receiptItemVN}`
              }))
          : []
        const sub2 = item?.receiptItems?.map((item) => item.receiptItemCode) || []

        return (
          <Panel
            title=""
            breadcrumb={
              <View style={[styles.row, { alignItems: 'center' }]}>
                <View style={[styles.contentText, styles.row]}>
                  <Text style={styles.titleSectionText}>{t('MedicalClaim')}</Text>
                  <FontAwesome5 name="angle-right" size={20} color="#B0B0B0" />
                  <Text style={styles.titleSectionText}>{t('HospitalCashSection')}</Text>
                  <FontAwesome5 name="angle-right" size={20} color="#B0B0B0" />
                  <Text style={styles.titleSectionText}>{t('claim:InPatientSection')}</Text>
                  <FontAwesome5 name="angle-right" size={20} color="#B0B0B0" />
                  <Text style={styles.titleSectionText}>{t(`${item?.label}`)}</Text>
                </View>
                <View style={[styles.subClass]}>
                  <TouchableOpacity
                    style={styles.button}
                    onPress={() => {
                      hdDeleteSubBenefit(indexSub)
                    }}
                  >
                    <Text style={styles.textButton}>{t('Delete')}</Text>
                  </TouchableOpacity>
                </View>
              </View>
            }
            isExand={true}
            isHiddenTitle={true}
            styling={styles.sectionInfo}
          >
            <View style={[styles.row, { alignItems: 'center' }]}>
              <View style={[styles.col]}>
                <SelectSearch
                  popupIcon={<assets.ArrowDownDropdownIcon />}
                  onChange={(data) => {
                    setReceiptType(data)
                    setIsCanChangeTab(false)
                  }}
                  label={`${t('ReceiptItem')}`}
                  placeholder={t('common:Select')}
                  options={differenceWith(sub1, sub2, isEqual) || []}
                  value={receiptType}
                />
              </View>
              <View>
                <TouchableOpacity
                  style={receiptType?.value ? styles.button : styles.btnDisabled}
                  disabled={!receiptType?.value}
                  onPress={() => {
                    if (receiptType) {
                      addNewReceipt(receiptType, indexSub)
                    }
                  }}
                >
                  <Text style={receiptType?.value ? styles.textButton : styles.btnTextDisabled}>{t('AddNew')}</Text>
                </TouchableOpacity>
              </View>
            </View>
            <View style={[styles.row, { justifyContent: 'space-between', marginBottom: 16 }]}>
              <View>
                <Text style={styles.label}>{t('ReceiptInfomation')}</Text>
              </View>
            </View>
            {item?.receiptItems &&
              item?.receiptItems?.map((field, index) => {
                const isSurgeryRequired =
                  watch(`subBenefit.${indexSub}.receiptItems.${index}.receiptItemCode`)?.value === 'RCI03'

                const isDurationRequired =
                  watch(`subBenefit.${indexSub}.receiptItems.${index}.receiptItemCode`)?.value === 'RCI02'
                return (
                  <View key={field.id} style={styles.receitBlock}>
                    <View
                      style={
                        watch(`subBenefit.${indexSub}.receiptItems.${index}.isToggle`)
                          ? styles.sectionInfoHeader
                          : styles.sectionInfoCollapse
                      }
                    >
                      {watch(`subBenefit.${indexSub}.receiptItems.${index}.isToggle`) ? (
                        <View style={[styles.col, { paddingRight: 44 }]}>
                          <Text style={styles.label}>{`${t('ReceiptItem')}`}</Text>
                          <Text>{watch(`subBenefit.${indexSub}.receiptItems.${index}.receiptItemCode`)?.label}</Text>
                        </View>
                      ) : (
                        <View>
                          <Text style={styles.labelCollapse}>
                            {watch(`subBenefit.${indexSub}.receiptItems.${index}.receiptItemCode`)?.label}
                          </Text>
                        </View>
                      )}
                      {isExand ? (
                        <View style={{ flexDirection: 'row', alignItems: 'flex-start' }}>
                          <TouchableOpacity
                            style={
                              !checkDisableDelete(String(field?.receiptItemCode?.value))
                                ? styles.button
                                : styles.btnDisabled
                            }
                            disabled={checkDisableDelete(String(field?.receiptItemCode?.value))}
                            onPress={() => {
                              hdDeleteReceiptItem(indexSub, index)
                            }}
                          >
                            <Text
                              style={
                                !checkDisableDelete(String(field?.receiptItemCode?.value))
                                  ? styles.textButton
                                  : styles.btnTextDisabled
                              }
                            >
                              {t('Delete')}
                            </Text>
                          </TouchableOpacity>

                          <Controller
                            name={`subBenefit.${indexSub}.receiptItems.${index}.isToggle`}
                            control={control}
                            render={({ field: { onChange, onBlur, value } }) => (
                              <TouchableOpacity
                                disabled={props.disabled}
                                onPress={() =>
                                  form.setValue(`subBenefit.${indexSub}.receiptItems.${index}.isToggle`, !value)
                                }
                              >
                                <assets.ArrowDownDropdownIcon />
                              </TouchableOpacity>
                            )}
                          />
                        </View>
                      ) : (
                        <></>
                      )}
                    </View>
                    {watch(`subBenefit.${indexSub}.receiptItems.${index}.isToggle`) && (
                      <View style={{ marginTop: 16 }}>
                        <View style={styles.row}>
                          <View style={[styles.col, { paddingRight: 44 }]}>
                            <Text style={styles.label}>{t('DayNumber')}</Text>
                            <Controller
                              name={`subBenefit.${indexSub}.receiptItems.${index}.dayNumber`}
                              control={control}
                              rules={{ required: true }}
                              render={({ field: { onChange, onBlur, value } }) => (
                                <Input
                                  onBlur={onBlur}
                                  onChange={(e) => {
                                    onChange(e)
                                    setIsCanChangeTab(false)
                                  }}
                                  value={value as string}
                                  disabled
                                />
                              )}
                            />
                          </View>
                          <View style={[styles.col, { paddingRight: 44 }]}>
                            <Text style={styles.label}>{t('ExpenseAmount')}</Text>
                            <Controller
                              name={`subBenefit.${indexSub}.receiptItems.${index}.expenseAmt`}
                              control={control}
                              rules={{
                                // required: {
                                //   value: true,
                                //   message: `${t('message:MS020001', { field: t('ExpenseAmount') })}`
                                // },
                                pattern: {
                                  value: validatePatternNumber,
                                  message: `${t('message:MS070018')}`
                                },
                                maxLength: {
                                  value: 15,
                                  message: `${t('message:MS070016', { field: 13 })}`
                                }
                              }}
                              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                <Input
                                  // required
                                  inputType="money"
                                  disabled={props.disabled}
                                  onBlur={onBlur}
                                  onChange={(e) => {
                                    onChange(e)
                                    setIsCanChangeTab(false)
                                  }}
                                  value={value as string}
                                  errorMessage={error?.message}
                                />
                              )}
                            />
                          </View>
                          <View style={[styles.col, { paddingRight: 44 }]}>
                            <Text style={styles.label}>{t('ClaimAmountInput')}</Text>
                            <Controller
                              name={`subBenefit.${indexSub}.receiptItems.${index}.claimAmt`}
                              // name={`receiptInformation.${index}.claimAmt` as 'receiptInformation.0.claimAmt'}
                              control={control}
                              rules={{
                                // required: {
                                //   value: true,
                                //   message: `${t('message:MS020001', { field: t('ClaimAmountInput') })}`
                                // },
                                pattern: {
                                  value: validatePatternNumber,
                                  message: `${t('message:MS070018')}`
                                },
                                maxLength: {
                                  value: 15,
                                  message: `${t('message:MS070016', { field: 13 })}`
                                }
                              }}
                              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                <Input
                                  // required
                                  inputType="money"
                                  disabled={props.disabled}
                                  onBlur={onBlur}
                                  onChange={(e) => {
                                    onChange(e)
                                    setIsCanChangeTab(false)
                                  }}
                                  value={value as string}
                                  errorMessage={error?.message}
                                />
                              )}
                            />
                          </View>
                        </View>
                        <View style={styles.row}>
                          <View style={[styles.col, { paddingRight: 44 }]}>
                            <Text style={styles.label}>
                              {t('Duration')}
                              {field?.receiptItemCode?.value === 'RCI02' && <Text style={{ color: '#ed1b2c' }}>*</Text>}
                            </Text>
                            <Controller
                              name={`subBenefit.${indexSub}.receiptItems.${index}.duration`}
                              control={control}
                              rules={{
                                required: isDurationRequired && `${t('message:MS020001', { field: t('Duration') })}`,
                                pattern: {
                                  value: validatePatternNumber,
                                  message: `${t('message:MS070018')}`
                                },
                                validate: (value) => {
                                  if (
                                    form.watch(`subBenefit.${indexSub}.receiptItems.${index}.receiptItemCode`)
                                      ?.value === 'RCI02' &&
                                    inpatientActualLOS
                                  ) {
                                    if (Number(value) <= Number(inpatientActualLOS)) {
                                      form.clearErrors(`subBenefit.${indexSub}.receiptItems.${index}.duration`)
                                    }
                                    return Number(value) <= Number(inpatientActualLOS) || `${t('message:MS990033')}`
                                  }
                                  return true
                                },
                                maxLength: {
                                  value: 3,
                                  message: `${t('message:MS070016', { field: 4 })}`
                                }
                              }}
                              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                <Input
                                  required={isDurationRequired}
                                  onBlur={onBlur}
                                  onChange={(item) => {
                                    onChange(item)
                                    form.trigger(`subBenefit.${indexSub}.receiptItems.${index}.duration`)
                                    isDurationRequired && hdDurationIntensive(indexSub, item)
                                  }}
                                  value={value as string}
                                  errorMessage={error?.message}
                                  disabled={field?.receiptItemCode?.value === 'RCI01'}
                                />
                              )}
                            />
                          </View>
                          <View style={[styles.col, { paddingRight: 44 }]}>
                            <Text style={styles.label}>{t('3rdPartyAmount')}</Text>
                            <Controller
                              name={`subBenefit.${indexSub}.receiptItems.${index}.thirdPartyAmt`}
                              control={control}
                              rules={{
                                // required: {
                                //   value: true,
                                //   message: `${t('message:MS020001', { field: t('3rdPartyAmount') })}`
                                // },
                                pattern: {
                                  value: validatePatternNumber,
                                  message: `${t('message:MS070018')}`
                                },
                                maxLength: {
                                  value: 15,
                                  message: `${t('message:MS070016', { field: 13 })}`
                                }
                              }}
                              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                <Input
                                  // required
                                  inputType="money"
                                  onBlur={onBlur}
                                  onChange={(e) => {
                                    onChange(e)
                                    setIsCanChangeTab(false)
                                  }}
                                  value={value as string}
                                  errorMessage={error?.message}
                                  // disabled={props.disabled}
                                />
                              )}
                            />
                          </View>
                          <View style={[styles.col, { paddingRight: 44 }]}>
                            <Text style={styles.label}>{t('ExcludedAmount')}</Text>
                            <Controller
                              name={`subBenefit.${indexSub}.receiptItems.${index}.excludedAmt`}
                              control={control}
                              rules={{
                                // required: {
                                //   value: true,
                                //   message: `${t('message:MS020001', { field: t('ExcludedAmount') })}`
                                // },
                                pattern: {
                                  value: validatePatternNumber,
                                  message: `${t('message:MS070018')}`
                                },
                                maxLength: {
                                  value: 15,
                                  message: `${t('message:MS070016', { field: 13 })}`
                                }
                              }}
                              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                <Input
                                  // required
                                  inputType="money"
                                  onBlur={onBlur}
                                  onChange={(e) => {
                                    onChange(e)
                                    setIsCanChangeTab(false)
                                  }}
                                  value={value as string}
                                  errorMessage={error?.message}
                                  // disabled={props.disabled}
                                />
                              )}
                            />
                          </View>
                        </View>
                        <View style={styles.row}>
                          <View style={[styles.col, { paddingRight: 44 }]}>
                            {isSurgeryRequired ? (
                              <Controller
                                name={`subBenefit.${indexSub}.receiptItems.${index}.surgeryCode`}
                                control={control}
                                rules={{
                                  required: isSurgeryRequired && `${t('message:MS020001', { field: t('Surgery') })}`
                                }}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <SelectSearch
                                    popupIcon={<assets.ArrowDownDropdownIcon />}
                                    required={isSurgeryRequired}
                                    value={(value as SelectOption[]) || []}
                                    onChange={(e) => {
                                      const checkDouble = _.uniqBy(e, (data: SelectOption) => {
                                        return data.value
                                      })
                                      onChange(checkDouble)
                                      setIsCanChangeTab(false)
                                    }}
                                    multiple
                                    label={t('Surgery')}
                                    errorMessage={error?.message}
                                    searchOptions={GeneralService.getSurgeryDataByName(translator)}
                                    // disabled={props.disabled}
                                  />
                                )}
                              />
                            ) : (
                              <Controller
                                name={`subBenefit.${indexSub}.receiptItems.${index}.surgeryCode`}
                                control={control}
                                rules={{
                                  required: isSurgeryRequired && `${t('message:MS020001', { field: t('Surgery') })}`
                                }}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <SelectSearch
                                    required={isSurgeryRequired}
                                    value={value as SelectOption}
                                    onChange={(e) => {
                                      onChange(e)
                                      setIsCanChangeTab(false)
                                    }}
                                    label={t('Surgery')}
                                    errorMessage={error?.message}
                                    searchOptions={GeneralService.getSurgeryDataByName(translator)}
                                    // disabled={props.disabled}
                                  />
                                )}
                              />
                            )}
                          </View>
                          <View style={[styles.col, { paddingRight: 44 }]}>
                            <Text style={styles.label}>{t('SIAmount')}</Text>
                            <Controller
                              name={`subBenefit.${indexSub}.receiptItems.${index}.siAmt`}
                              control={control}
                              rules={{
                                // required: {
                                //   value: true,
                                //   message: `${t('message:MS020001', { field: t('SIAmount') })}`
                                // },
                                pattern: {
                                  value: validatePatternNumber,
                                  message: `${t('message:MS070018')}`
                                },
                                maxLength: {
                                  value: 15,
                                  message: `${t('message:MS070016', { field: 13 })}`
                                }
                              }}
                              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                <Input
                                  // required
                                  inputType="money"
                                  onBlur={onBlur}
                                  onChange={(e) => {
                                    onChange(e)
                                    PulseOpsFormat.InputNUM(onChange)
                                    setIsCanChangeTab(false)
                                  }}
                                  value={value as string}
                                  errorMessage={error?.message}
                                  // disabled={props.disabled}
                                />
                              )}
                            />
                          </View>
                          <View style={[styles.col, { paddingRight: 44 }]}>
                            <Text style={styles.label}>{t('PayableAmount')}</Text>
                            <Controller
                              name={`subBenefit.${indexSub}.receiptItems.${index}.payableAmt`}
                              control={control}
                              rules={{
                                // required: {
                                //   value: true,
                                //   message: `${t('message:MS020001', { field: t('PayableAmount') })}`
                                // },
                                pattern: {
                                  value: validatePatternNumber,
                                  message: `${t('message:MS070018')}`
                                },
                                maxLength: {
                                  value: 15,
                                  message: `${t('message:MS070016', { field: 13 })}`
                                }
                              }}
                              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                <Input
                                  // required
                                  inputType="money"
                                  onBlur={onBlur}
                                  onChange={(e) => {
                                    onChange(e)
                                    setIsCanChangeTab(false)
                                  }}
                                  value={value as string}
                                  errorMessage={error?.message}
                                  // disabled={props.disabled}
                                />
                              )}
                            />
                          </View>
                        </View>
                      </View>
                    )}
                  </View>
                )
              })}
          </Panel>
        )
      })}
    </>
  )
}

const styles = StyleSheet.create({
  sectionInfo: {
    width: '100%',
    padding: 25,
    backgroundColor: '#FAFAFA',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#D3DCE6',
    borderBottomColor: '#E5EAEF',
    borderBottomWidth: 3
  },
  sectionInfoHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'flex-start'
  },
  sectionInfoCollapse: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  sectionInfoHeaderTitle: {
    fontSize: 15,
    lineHeight: 22,
    fontWeight: '800',
    textTransform: 'uppercase'
  },
  receitBlock: {
    backgroundColor: '#FFFF',
    borderColor: '#D3DCE6',
    borderWidth: 1,
    borderRadius: 8,
    padding: 14,
    marginBottom: 15
  },
  textInput: {
    borderBottomWidth: 2,
    borderColor: '#D3DCE6',
    paddingVertical: 4
  },
  textButton: { textAlign: 'center', color: '#ED1B2E', fontWeight: 'bold' },
  button: {
    marginLeft: 20,
    marginEnd: 20,
    justifyContent: 'center',
    backgroundColor: '#FFF',
    borderRadius: 100,
    borderWidth: 1,
    borderColor: '#ED1B2E',
    minWidth: 100,
    height: 30,
    paddingHorizontal: 16
  },
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  col: {
    width: '33.33333333333%',
    marginBottom: 32
  },
  label: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  },
  labelCollapse: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20
  },
  value: {
    color: '#000000',
    fontSize: 15,
    lineHeight: 22
  },
  btnTextDisabled: { textAlign: 'center', color: '#B0B0B0', fontWeight: 'bold' },
  btnDisabled: {
    marginLeft: 20,
    marginEnd: 30,
    justifyContent: 'center',
    backgroundColor: '#FFF',
    borderRadius: 100,
    borderWidth: 1,
    borderColor: '#B0B0B0',
    minWidth: 100,
    height: 30,
    paddingHorizontal: 16
  },
  required: {
    color: '#ED1B2E'
  },
  contentText: {
    padding: 5,
    borderRadius: 20,
    width: 'fit-content',
    backgroundColor: 'white',
    display: 'flex'
  },
  subClass: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: 'fit-content'
  },
  titleSectionText: {
    fontSize: 13,
    lineHeight: 22,
    fontWeight: '600',
    paddingStart: 10,
    paddingEnd: 10,
    color: '#4F4F4F'
  }
})
