import { BreadCrumb, sharedStyle, AdminPageData, ModalComponent, Input, DatePicker, RadioButtonGroup, SelectSearch, assets, GeneralService, ErrorHandling, AdminPageService, AppContext, Alert } from '@pulseops/common'
import { StackScreenProps } from '@react-navigation/stack'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, StyleSheet, Text, TouchableOpacity, useWindowDimensions, View } from 'react-native'
import { AdminPageStackParamList } from './AdminPageStackParamList'
import { useIsFocused } from '@react-navigation/native'
import { IBGeneralTable } from '@pulseops/inbound'
import { SectionCol, SectionRow } from '@pulseops/submission/common'
import { Controller, useForm } from 'react-hook-form'
import { ConfigGAInformationFormData, ConfigT33OfficeConst, GAInformationSexConst } from './ConfigTableForm'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import * as O from 'fp-ts/lib/Option'

export const ConfigGAInformationListScreen = (props: StackScreenProps<AdminPageStackParamList>) => {
  const { t, i18n } = useTranslation()
  const isFocused = useIsFocused()
  const { width, height } = useWindowDimensions()
  const [headerWidth, setHeaderWidth] = React.useState(0)
  const [GAInformationList, setGAInformationList] = React.useState<Array<AdminPageData.GAInformationInfo>>([])
  const [isOpenAddPopup, setIsOpenAddPopup] = React.useState<boolean>(false)
  const [isUpdatedAction, setIsUpdatedAction] = React.useState(false)
  const [modalTitle, setModalTitle] = React.useState('')
  const { showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const [isLoading, bindLoader] = useLoading(false)
  const configGAInformationForm = useForm<ConfigGAInformationFormData>()
  const getBreadCrumbList = React.useMemo(() => {
    const subItems = isFocused ? [
      { title: t('Admin page'), navigate: () => props.navigation.navigate("AdminPageScreen") },
      { title: t('GA Information List'), navigate: null }
    ] : []
    return subItems
  }, [isFocused])

  const displayColumns = [
    { label: 'No', field: 'no', disabled: true },
    { label: 'AdRegion', field: 'adRegion' },
    { label: 'AdZone', field: 'adZone' },
    { label: 'Address', field: 'address' },
    { label: 'Agent Code', field: 'agentCode' },
    { label: 'Bank Code', field: 'bankCode' },
    { label: 'Classification', field: 'classification' },
    { label: 'Company Name', field: 'companyName' },
    { label: 'CsRegion', field: 'csRegion' },
    { label: 'Dob', field: 'dob' },
    { label: 'ExtraPay', field: 'extraPay' },
    { label: 'GaCSAssociate', field: 'gaCSAssociate' },
    { label: 'GaCSAssociateEmail', field: 'gaCSAssociateEmail' },
    { label: 'GaCSEmail', field: 'gaCSEmail' },
    { label: 'GaCSSupportManager', field: 'gaCSSupportManager' },
    { label: 'GaCode', field: 'gaCode' },
    { label: 'GaFlag', field: 'gaFlag' },
    { label: 'GaMother', field: 'gaMother' },
    { label: 'GaOMEmail', field: 'gaOMEmail' },
    { label: 'GaServiceAssociate', field: 'gaServiceAssociate' },
    { label: 'GaTaxCode', field: 'gaTaxCode' },
    { label: 'GadEmail', field: 'gadEmail' },
    { label: 'GadName', field: 'gadName' },
    { label: 'GamRHS', field: 'gamRHS' },
    { label: 'Mobile', field: 'mobile' },
    { label: 'OfficeCode', field: 'officeCode' },
    { label: 'OfficeName', field: 'officeName' },
    { label: 'OpenDate', field: 'openDate' },
    { label: 'Province', field: 'province' },
    { label: 'Sex', field: 'sex' },
    { label: 'TelPhone', field: 'telPhone' },
    { label: 'TransactionLiveZoom', field: 'transactionLiveZoom' },
    { label: 'Type', field: 'type' },
    {
      label: '',
      field: 'id',
      disabled: true,
      render: (GAID: string) => {
        return <TouchableOpacity onPress={() => onOpenEditGAInformModalPopup(GAID)}>
          <assets.OBPencilGray />
        </TouchableOpacity>
      }
    },
    {
      label: '',
      field: 'id',
      disabled: true,
      render: (id: string) => {
        return <TouchableOpacity onPress={() => onOpenDeleteModalPopup(id)}>
          <assets.DeleteBin />
        </TouchableOpacity>
      }
    },
  ]

  const filterColumn = {
    adRegion: '',
    adZone: '',
    // address: '',
    agentCode: '',
    bankCode: '',
    classification: '',
    companyName: '',
    csRegion: '',
    dob: '',
    extraPay: '',
    gaCSAssociate: '',
    gaCSAssociateEmail: '',
    gaCSEmail: '',
    gaCSSupportManager: '',
    gaCode: '',
    gaFlag: '',
    gaMother: '',
    gaOMEmail: '',
    gaServiceAssociate: '',
    gaTaxCode: '',
  }

  const { provinceOptions, officeOptions } = pipe(
    ZIO.zipPar(
      GeneralService.getALLOfficeList(),
      GeneralService.getProvinces,
    ),
    ZIO.map(([officeResponse, provinceResponse]) => {
      const provinceOptions = provinceResponse.map((item) => ({ label: item.name, value: item.code }))
      const officeOptions = officeResponse.map((item) => ({ code: item.code, nameEn: item.nameEn, nameVi: item.nameVi }))
      return {
        provinceOptions: provinceOptions,
        officeOptions: officeOptions
      }
    }),
    ErrorHandling.runDidMount({})
  ) || {
    provinceOptions: [],
    officeOptions: []
  }

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  React.useEffect(() => {
    if (isFocused) {
      getInitialGAInformationList()
    }
  }, [isFocused])

  const getInitialGAInformationList = () => {
    pipe(
      AdminPageService.getGAInformationList(),
      ZIO.map((responseData) => {
        const customedGAList = responseData.data.map((GAItem, index) => {
          return {
            ...GAItem,
            no: index + 1,
            id: GAItem.id || '',
            adRegion: GAItem.adRegion || '',
            adZone: GAItem.adZone || '',
            address: GAItem.address || '',
            agentCode: GAItem.agentCode || '',
            bankCode: GAItem.bankCode || '',
            classification: GAItem.classification || '',
            companyName: GAItem.companyName || '',
            couchbaseId: GAItem.couchbaseId || '',
            csRegion: GAItem.csRegion || '',
            dob: GAItem.dob || '',
            extraPay: GAItem.extraPay || '',
            gaCSAssociate: GAItem.gaCSAssociate || '',
            gaCSAssociateEmail: GAItem.gaCSAssociateEmail || '',
            gaCSEmail: GAItem.gaCSEmail || '',
            gaCSSupportManager: GAItem.gaCSSupportManager || '',
            gaCode: GAItem.gaCode || '',
            gaFlag: GAItem.gaFlag || '',
            gaMother: GAItem.gaMother || '',
            gaOMEmail: GAItem.gaOMEmail || '',
            gaServiceAssociate: GAItem.gaServiceAssociate || '',
            gaTaxCode: GAItem.gaTaxCode || '',
            gadEmail: GAItem.gadEmail || '',
            gadName: GAItem.gadName || '',
            gamRHS: GAItem.gamRHS || '',
            mobile: GAItem.mobile || '',
            officeCode: GAItem.officeCode || '',
            officeName: GAItem.officeName || '',
            openDate: GAItem.openDate || '',
            province: GAItem.province || '',
            sex: GAItem.sex || '',
            telPhone: GAItem.telPhone || '',
            transactionLiveZoom: GAItem.transactionLiveZoom || '',
            type: GAItem.type || '',
          }
        })
        setGAInformationList(customedGAList)
        return responseData
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }

  const getOfficeOptions = () => {
    return officeOptions.map((item) => ({ label: item.nameVi, value: item.code }))
  }

  const onChangeOfficeNameEvent = (officeCode: string) => {
    configGAInformationForm.setValue('officeCode', officeCode)
  }

  const onOpenCreateNewGAInformationPopup = () => {
    setModalTitle('Create New GA Information')
    setIsUpdatedAction(false)
    configGAInformationForm.reset({
      adRegion: '',
      adZone: '',
      address: '',
      agentCode: '',
      bankCode: '',
      classification: '',
      companyName: '',
      csRegion: '',
      dob: '',
      extraPay: '',
      gaCSAssociate: '',
      gaCSAssociateEmail: '',
      gaCSEmail: '',
      gaCSSupportManager: '',
      gaCode: '',
      gaFlag: '',
      gaMother: '',
      gaOMEmail: '',
      gaServiceAssociate: '',
      gaTaxCode: '',
      gadEmail: '',
      gadName: '',
      gamRHS: '',
      mobile: '',
      officeCode: '',
      officeName: undefined,
      openDate: '',
      province: undefined,
      sex: '',
      telPhone: '',
      transactionLiveZoom: '',
      type: '',
      couchbaseId: ''
    })
    setIsOpenAddPopup(true)
  }

  const validateAddedNewGAInformation = (formData: ConfigGAInformationFormData) => {
    let isValidData = true
    if (GAInformationList.some((x) => x.gaCode === formData.gaCode)) {
      showToast('Duplicate GaCode, Please check again', 'error')
      isValidData = false
    } else if (GAInformationList.some((x) => x.companyName === formData.companyName)) {
      showToast('Duplicate Company Name, Please check again', 'error')
      isValidData = false
    } else if (GAInformationList.some((x) => x.gaTaxCode === formData.gaTaxCode)) {
      showToast('Duplicate GaTaxCode, Please check again', 'error')
      isValidData = false
    }
    return isValidData
  }
  const validateUpdateGAInformation = (formData: ConfigGAInformationFormData) => {
    let isValidData = true
    if (GAInformationList.some((x) => x.gaCode === formData.gaCode && x.id !== formData.id)) {
      showToast('Duplicate GaCode, Please check again', 'error')
      isValidData = false
    } else if (GAInformationList.some((x) => x.companyName === formData.companyName && x.id !== formData.id)) {
      showToast('Duplicate Company Name, Please check again', 'error')
      isValidData = false
    } else if (GAInformationList.some((x) => x.gaTaxCode === formData.gaTaxCode && x.id !== formData.id)) {
      showToast('Duplicate GaTaxCode, Please check again', 'error')
      isValidData = false
    }
    return isValidData
  }

  const onAddNewGAInformationEvent = async () => {
    const isValidForm = await configGAInformationForm.trigger()
    const formData = configGAInformationForm.getValues()
    if (isValidForm && validateAddedNewGAInformation(formData)) {
      const submitedData: AdminPageService.NewGAInformationBodyInput = {
        adRegion: formData.adRegion,
        adZone: formData.adZone,
        address: formData.address,
        agentCode: formData.agentCode,
        bankCode: formData.bankCode,
        classification: formData.classification,
        companyName: formData.companyName,
        couchbaseId: '',
        csRegion: formData.csRegion,
        dob: formData.dob,
        extraPay: formData.extraPay,
        gaCSAssociate: formData.gaCSAssociate,
        gaCSAssociateEmail: formData.gaCSAssociateEmail,
        gaCSEmail: formData.gaCSEmail,
        gaCSSupportManager: formData.gaCSSupportManager,
        gaCode: formData.gaCode,
        gaFlag: formData.gaFlag,
        gaMother: formData.gaMother,
        gaOMEmail: formData.gaOMEmail,
        gaServiceAssociate: formData.gaServiceAssociate,
        gaTaxCode: formData.gaTaxCode,
        gadEmail: formData.gadEmail,
        gadName: formData.gadName,
        gamRHS: formData.gamRHS,
        mobile: formData.mobile,
        officeCode: formData.officeCode,
        officeName: formData.officeName?.label || '',
        openDate: formData.openDate,
        province: formData.province || '',
        sex: formData.sex,
        telPhone: formData.telPhone,
        transactionLiveZoom: formData.transactionLiveZoom,
        type: formData.type,
      }
      pipe(AdminPageService.addNewGAInformation(submitedData),
        ZIO.map((_) => {
          showToast(t('message:OB0040'), 'success')
          getInitialGAInformationList()
          setIsOpenAddPopup(false)
        }),
        ZIO.catchAll((errorInfo) => {
          showToast(`${t('message:OB0042')}. ${errorInfo.source.message}`, 'error')
          // setIsOpenAddPopup(false)
          return ZIO.succeed('')
        }),
        bindLoader,
        ZIO.unsafeRun({})
      )
    }
  }

  const onOpenEditGAInformModalPopup = (GAID: string) => {
    setModalTitle('Update GA Information')
    setIsUpdatedAction(true)
    pipe(
      GAInformationList.find((p) => p.id === GAID),
      O.fromNullable,
      O.map((GAItem) => {
        const officeItem = pipe(
          officeOptions.find((x) => x.code === GAItem.officeCode),
          O.fromNullable,
          O.map((item) => ({ label: item.nameVi, value: item.code })),
          O.getOrElse(() => ({ label: '', value: '' }))
        )
        configGAInformationForm.setValue("id", GAID)
        configGAInformationForm.setValue("adRegion", GAItem.adRegion)
        configGAInformationForm.setValue("adZone", GAItem.adZone)
        configGAInformationForm.setValue("address", GAItem.address)
        configGAInformationForm.setValue("agentCode", GAItem.agentCode)
        configGAInformationForm.setValue("bankCode", GAItem.bankCode)
        configGAInformationForm.setValue("classification", GAItem.classification)
        configGAInformationForm.setValue("companyName", GAItem.companyName)
        configGAInformationForm.setValue("csRegion", GAItem.csRegion)
        configGAInformationForm.setValue("dob", GAItem.dob)
        configGAInformationForm.setValue("extraPay", GAItem.extraPay)
        configGAInformationForm.setValue("gaCSAssociate", GAItem.gaCSAssociate)
        configGAInformationForm.setValue("gaCSAssociateEmail", GAItem.gaCSAssociateEmail)
        configGAInformationForm.setValue("gaCSEmail", GAItem.gaCSEmail)
        configGAInformationForm.setValue("gaCSSupportManager", GAItem.gaCSSupportManager)
        configGAInformationForm.setValue("gaCode", GAItem.gaCode)
        configGAInformationForm.setValue("gaFlag", GAItem.gaFlag)
        configGAInformationForm.setValue("gaMother", GAItem.gaMother)
        configGAInformationForm.setValue("gaOMEmail", GAItem.gaOMEmail)

        configGAInformationForm.setValue("gaServiceAssociate", GAItem.gaServiceAssociate)
        configGAInformationForm.setValue("gaTaxCode", GAItem.gaTaxCode)
        configGAInformationForm.setValue("gadEmail", GAItem.gadEmail)
        configGAInformationForm.setValue("gadName", GAItem.gadName)
        configGAInformationForm.setValue("gamRHS", GAItem.gamRHS)
        configGAInformationForm.setValue("mobile", GAItem.mobile)
        configGAInformationForm.setValue("officeCode", GAItem.officeCode)
        configGAInformationForm.setValue("officeName", officeItem)
        configGAInformationForm.setValue("openDate", GAItem.openDate)
        configGAInformationForm.setValue("province", GAItem.province)
        configGAInformationForm.setValue("sex", GAItem.sex)
        configGAInformationForm.setValue("telPhone", GAItem.telPhone)
        configGAInformationForm.setValue("transactionLiveZoom", GAItem.transactionLiveZoom)
        configGAInformationForm.setValue("type", GAItem.type)
        configGAInformationForm.setValue("couchbaseId", GAItem.couchbaseId)
        return setIsOpenAddPopup(true)
      }),
      O.getOrElse(() => { })
    )
  }

  const onUpdateGAInformationEvent = async () => {
    const isValidForm = await configGAInformationForm.trigger()
    const formData = configGAInformationForm.getValues()
    if (isValidForm && validateUpdateGAInformation(formData)) {
      const submitedData: AdminPageService.NewGAInformationBodyInput = {
        id: formData.id,
        adRegion: formData.adRegion,
        adZone: formData.adZone,
        address: formData.address,
        agentCode: formData.agentCode,
        bankCode: formData.bankCode,
        classification: formData.classification,
        companyName: formData.companyName,
        couchbaseId: formData.couchbaseId,
        csRegion: formData.csRegion,
        dob: formData.dob,
        extraPay: formData.extraPay,
        gaCSAssociate: formData.gaCSAssociate,
        gaCSAssociateEmail: formData.gaCSAssociateEmail,
        gaCSEmail: formData.gaCSEmail,
        gaCSSupportManager: formData.gaCSSupportManager,
        gaCode: formData.gaCode,
        gaFlag: formData.gaFlag,
        gaMother: formData.gaMother,
        gaOMEmail: formData.gaOMEmail,
        gaServiceAssociate: formData.gaServiceAssociate,
        gaTaxCode: formData.gaTaxCode,
        gadEmail: formData.gadEmail,
        gadName: formData.gadName,
        gamRHS: formData.gamRHS,
        mobile: formData.mobile,
        officeCode: formData.officeCode,
        officeName: formData.officeName?.label || '',
        openDate: formData.openDate,
        province: formData.province || '',
        sex: formData.sex,
        telPhone: formData.telPhone,
        transactionLiveZoom: formData.transactionLiveZoom,
        type: formData.type,
      }
      pipe(AdminPageService.updateGAInformation(submitedData),
        ZIO.map((_) => {
          showToast(t('message:OB0040'), 'success')
          getInitialGAInformationList()
          setIsOpenAddPopup(false)
        }),
        ZIO.catchAll((errorInfo) => {
          showToast(`${t('message:OB0042')}. ${errorInfo.source.message}`, 'error')
          // setIsOpenAddPopup(false)
          return ZIO.succeed('')
        }),
        bindLoader,
        ZIO.unsafeRun({})
      )
    }

  }

  const onOpenDeleteModalPopup = (GAID: string) => {
    const GAInformation = pipe(
      GAInformationList.find((p) => p.id === GAID),
      O.fromNullable,
      O.fold(
        () => '',
        (item) => `${item.gaCode} - ${item.gaTaxCode}`
      )
    )
    Alert.modal({
      title: 'Delete GA Information',
      content: t(`Do you want to delete this ${GAInformation} GA Information ?`),
      size: {
        width: '30%'
      },
      onOK: () => {
        pipe(
          AdminPageService.deleteGAInformation(GAID),
          ZIO.map((response) => {
            showToast(t('Delete Successfully'), 'success')
            getInitialGAInformationList()
          }),
          ZIO.catchAll((error) => {
            showToast(`Delete Failed. ${error.source.message}`, 'error')
            return ZIO.succeed('')
          }),
          bindLoader,
          ZIO.unsafeRun({})
        )
      },
      onClose: () => {
      }
    })
  }


  const getAddGAInformationModalPopup = () => {
    return (
      <ModalComponent
        children={
          <ScrollView style={configStyles.modalBodyContainer}>
            <SectionRow>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={configGAInformationForm.control}
                  name="adRegion"
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                    return (
                      <Input
                        title={t('AdRegion')}
                        placeholder={''}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        disabled={false}
                        // required
                        maxLength={5}
                        errorMessage={!!value ? "" : error?.message}
                      />
                    )
                  }}
                />

              </SectionCol>
              <SectionCol sectionStyles={sharedStyle.col_6}>
                <Controller
                  control={configGAInformationForm.control}
                  name="adZone"
                  // rules={{
                  //   required: {
                  //     value: true,
                  //     message: t(`message:MS020001`, { field: t('AdZone') })
                  //   },
                  //   validate: (value) => {
                  //     if (!value || !value.trim()) {
                  //       return `${t(`message:MS020001`, { field: t('AdZone') })}`
                  //     } else {
                  //       return true
                  //     }
                  //   }
                  // }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                    return (
                      <Input
                        title={t('adZone')}
                        placeholder={''}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        disabled={false}
                        // required
                        maxLength={150}
                        errorMessage={!!value ? "" : error?.message}
                      />
                    )
                  }}
                />
              </SectionCol>
              <SectionCol sectionStyles={[sharedStyle.col_6, configStyles.modalSecondLine]}>
                <Controller
                  control={configGAInformationForm.control}
                  name="address"
                  rules={{
                    required: {
                      value: true,
                      message: t(`message:MS020001`, { field: t('Address') })
                    },
                    validate: (value) => {
                      if (!value || !value.trim()) {
                        return `${t(`message:MS020001`, { field: t('Address') })}`
                      } else {
                        return true
                      }
                    }
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                    return (
                      <Input
                        title={t('Address')}
                        placeholder={''}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        disabled={false}
                        required
                        maxLength={150}
                        errorMessage={!!value ? "" : error?.message}
                      />
                    )
                  }}
                />

              </SectionCol>
              <SectionCol sectionStyles={[sharedStyle.col_6, configStyles.modalSecondLine]}>
                <Controller
                  control={configGAInformationForm.control}
                  name="agentCode"
                  rules={{
                    required: {
                      value: true,
                      message: t(`message:MS020001`, { field: t('Agent Code') })
                    },
                    validate: (value) => {
                      if (!value || !value.trim()) {
                        return `${t(`message:MS020001`, { field: t('Agent Code') })}`
                      } else {
                        return true
                      }
                    }
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                    return (
                      <Input
                        title={t('Agent Code')}
                        placeholder={''}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        disabled={false}
                        required
                        maxLength={10}
                        errorMessage={!!value ? "" : error?.message}
                      />
                    )
                  }}
                />
              </SectionCol>
              <SectionCol sectionStyles={[sharedStyle.col_6, configStyles.modalSecondLine]}>
                <Controller
                  control={configGAInformationForm.control}
                  name="bankCode"
                  rules={{
                    required: {
                      value: true,
                      message: t(`message:MS020001`, { field: t('Bank Code') })
                    },
                    validate: (value) => {
                      if (!value || !value.trim()) {
                        return `${t(`message:MS020001`, { field: t('Bank Code') })}`
                      } else {
                        return true
                      }
                    }
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                    return (
                      <Input
                        title={t('Bank Code')}
                        placeholder={''}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        disabled={false}
                        required
                        maxLength={150}
                        errorMessage={!!value ? "" : error?.message}
                      />
                    )
                  }}
                />


              </SectionCol>
              <SectionCol sectionStyles={[sharedStyle.col_6, configStyles.modalSecondLine]}>
                <Controller
                  control={configGAInformationForm.control}
                  name="classification"
                  // rules={{
                  //   required: {
                  //     value: true,
                  //     message: t(`message:MS020001`, { field: t('Classification') })
                  //   },
                  //   validate: (value) => {
                  //     if (!value || !value.trim()) {
                  //       return `${t(`message:MS020001`, { field: t('Classification') })}`
                  //     } else {
                  //       return true
                  //     }
                  //   }
                  // }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                    return (
                      <Input
                        title={t('Classification')}
                        placeholder={''}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        disabled={false}
                        // required
                        maxLength={150}
                        errorMessage={!!value ? "" : error?.message}
                      />
                    )
                  }}
                />

              </SectionCol>
              <SectionCol sectionStyles={[sharedStyle.col_6, configStyles.modalSecondLine]}>
                <Controller
                  control={configGAInformationForm.control}
                  name="companyName"
                  rules={{
                    required: {
                      value: true,
                      message: t(`message:MS020001`, { field: t('Company Name') })
                    },
                    validate: (value) => {
                      if (!value || !value.trim()) {
                        return `${t(`message:MS020001`, { field: t('Company Name') })}`
                      } else {
                        return true
                      }
                    }
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                    return (
                      <Input
                        title={t('Company Name')}
                        placeholder={''}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        disabled={false}
                        required
                        maxLength={150}
                        errorMessage={!!value ? "" : error?.message}
                      />
                    )
                  }}
                />


              </SectionCol>
              <SectionCol sectionStyles={[sharedStyle.col_6, configStyles.modalSecondLine]}>
                <Controller
                  control={configGAInformationForm.control}
                  name="csRegion"
                  // rules={{
                  //   required: {
                  //     value: true,
                  //     message: t(`message:MS020001`, { field: t('CsRegion') })
                  //   },
                  //   validate: (value) => {
                  //     if (!value || !value.trim()) {
                  //       return `${t(`message:MS020001`, { field: t('CsRegion') })}`
                  //     } else {
                  //       return true
                  //     }
                  //   }
                  // }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                    return (
                      <Input
                        title={t('CsRegion')}
                        placeholder={''}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        disabled={false}
                        // required
                        maxLength={150}
                        errorMessage={!!value ? "" : error?.message}
                      />
                    )
                  }}
                />

              </SectionCol>
              <SectionCol sectionStyles={[sharedStyle.col_6, configStyles.modalSecondLine]}>
                <Controller
                  control={configGAInformationForm.control}
                  name="extraPay"
                  // rules={{
                  //   required: {
                  //     value: true,
                  //     message: t(`message:MS020009`, { field: t('ExtraPay') })
                  //   }
                  // }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                    return (
                      <RadioButtonGroup
                        title={t('ExtraPay')}
                        // required
                        colLength={'100%'}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        options={ConfigT33OfficeConst.EFMConst.map((item) => ({
                          id: item.id,
                          label: t(item.label)
                        }))}
                        disabled={false}
                        errorMessage={!value || !value.trim() ? error?.message : ''}
                      />
                    )
                  }}
                />

              </SectionCol>
              <SectionCol sectionStyles={[sharedStyle.col_6, configStyles.modalSecondLine]}>
                <Controller
                  control={configGAInformationForm.control}
                  name="gaCSAssociate"
                  // rules={{
                  //   required: {
                  //     value: true,
                  //     message: t(`message:MS020001`, { field: t('Dob') })
                  //   },
                  //   validate: (value) => {
                  //     if (!value || !value.trim()) {
                  //       return `${t(`message:MS020001`, { field: t('Dob') })}`
                  //     } else {
                  //       return true
                  //     }
                  //   }
                  // }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                    return (
                      <Input
                        title={t('GaCSAssociate')}
                        placeholder={''}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        disabled={false}
                        // required
                        maxLength={150}
                        errorMessage={!!value ? "" : error?.message}
                      />
                    )
                  }}
                />


              </SectionCol>
              <SectionCol sectionStyles={[sharedStyle.col_6, configStyles.modalSecondLine]}>
                <Controller
                  control={configGAInformationForm.control}
                  name="gaCSAssociateEmail"
                  // rules={{
                  //   required: {
                  //     value: true,
                  //     message: t(`message:MS020001`, { field: t('CsRegion') })
                  //   },
                  //   validate: (value) => {
                  //     if (!value || !value.trim()) {
                  //       return `${t(`message:MS020001`, { field: t('CsRegion') })}`
                  //     } else {
                  //       return true
                  //     }
                  //   }
                  // }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                    return (
                      <Input
                        title={t('GaCSAssociateEmail')}
                        placeholder={''}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        disabled={false}
                        // required
                        maxLength={150}
                        errorMessage={!!value ? "" : error?.message}
                      />
                    )
                  }}
                />

              </SectionCol>
              <SectionCol sectionStyles={[sharedStyle.col_6, configStyles.modalSecondLine]}>
                <Controller
                  control={configGAInformationForm.control}
                  name="gaCSEmail"
                  // rules={{
                  //   required: {
                  //     value: true,
                  //     message: t(`message:MS020001`, { field: t('Dob') })
                  //   },
                  //   validate: (value) => {
                  //     if (!value || !value.trim()) {
                  //       return `${t(`message:MS020001`, { field: t('Dob') })}`
                  //     } else {
                  //       return true
                  //     }
                  //   }
                  // }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                    return (
                      <Input
                        title={t('GaCSEmail')}
                        placeholder={''}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        disabled={false}
                        // required
                        maxLength={150}
                        errorMessage={!!value ? "" : error?.message}
                      />
                    )
                  }}
                />


              </SectionCol>
              <SectionCol sectionStyles={[sharedStyle.col_6, configStyles.modalSecondLine]}>
                <Controller
                  control={configGAInformationForm.control}
                  name="gaCSSupportManager"
                  // rules={{
                  //   required: {
                  //     value: true,
                  //     message: t(`message:MS020001`, { field: t('CsRegion') })
                  //   },
                  //   validate: (value) => {
                  //     if (!value || !value.trim()) {
                  //       return `${t(`message:MS020001`, { field: t('CsRegion') })}`
                  //     } else {
                  //       return true
                  //     }
                  //   }
                  // }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                    return (
                      <Input
                        title={t('GaCSSupportManager')}
                        placeholder={''}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        disabled={false}
                        // required
                        maxLength={150}
                        errorMessage={!!value ? "" : error?.message}
                      />
                    )
                  }}
                />

              </SectionCol>
              <SectionCol sectionStyles={[sharedStyle.col_6, configStyles.modalSecondLine]}>
                <Controller
                  control={configGAInformationForm.control}
                  name="gaCode"
                  rules={{
                    required: {
                      value: true,
                      message: t(`message:MS020001`, { field: t('GaCode') })
                    },
                    validate: (value) => {
                      if (!value || !value.trim()) {
                        return `${t(`message:MS020001`, { field: t('GaCode') })}`
                      } else {
                        return true
                      }
                    }
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                    return (
                      <Input
                        title={t('GaCode')}
                        placeholder={''}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        disabled={false}
                        required
                        maxLength={15}
                        errorMessage={!!value ? "" : error?.message}
                      />
                    )
                  }}
                />


              </SectionCol>
              <SectionCol sectionStyles={[sharedStyle.col_6, configStyles.modalSecondLine]}>
                <Controller
                  control={configGAInformationForm.control}
                  name="gaFlag"
                  // rules={{
                  //   required: {
                  //     value: true,
                  //     message: t(`message:MS020001`, { field: t('CsRegion') })
                  //   },
                  //   validate: (value) => {
                  //     if (!value || !value.trim()) {
                  //       return `${t(`message:MS020001`, { field: t('CsRegion') })}`
                  //     } else {
                  //       return true
                  //     }
                  //   }
                  // }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                    return (
                      <Input
                        title={t('GaFlag')}
                        placeholder={''}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        disabled={false}
                        // required
                        maxLength={150}
                        errorMessage={!!value ? "" : error?.message}
                      />
                    )
                  }}
                />

              </SectionCol>
              <SectionCol sectionStyles={[sharedStyle.col_6, configStyles.modalSecondLine]}>
                <Controller
                  control={configGAInformationForm.control}
                  name="gaMother"
                  // rules={{
                  //   required: {
                  //     value: true,
                  //     message: t(`message:MS020001`, { field: t('Dob') })
                  //   },
                  //   validate: (value) => {
                  //     if (!value || !value.trim()) {
                  //       return `${t(`message:MS020001`, { field: t('Dob') })}`
                  //     } else {
                  //       return true
                  //     }
                  //   }
                  // }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                    return (
                      <Input
                        title={t('GaMother')}
                        placeholder={''}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        disabled={false}
                        // required
                        maxLength={15}
                        errorMessage={!!value ? "" : error?.message}
                      />
                    )
                  }}
                />

              </SectionCol>
              <SectionCol sectionStyles={[sharedStyle.col_6, configStyles.modalSecondLine]}>
                <Controller
                  control={configGAInformationForm.control}
                  name="gaOMEmail"
                  // rules={{
                  //   required: {
                  //     value: true,
                  //     message: t(`message:MS020001`, { field: t('CsRegion') })
                  //   },
                  //   validate: (value) => {
                  //     if (!value || !value.trim()) {
                  //       return `${t(`message:MS020001`, { field: t('CsRegion') })}`
                  //     } else {
                  //       return true
                  //     }
                  //   }
                  // }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                    return (
                      <Input
                        title={t('GaOMEmail')}
                        placeholder={''}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        disabled={false}
                        // required
                        maxLength={150}
                        errorMessage={!!value ? "" : error?.message}
                      />
                    )
                  }}
                />
              </SectionCol>
              <SectionCol sectionStyles={[sharedStyle.col_6, configStyles.modalSecondLine]}>
                <Controller
                  control={configGAInformationForm.control}
                  name="gaServiceAssociate"
                  // rules={{
                  //   required: {
                  //     value: true,
                  //     message: t(`message:MS020001`, { field: t('Dob') })
                  //   },
                  //   validate: (value) => {
                  //     if (!value || !value.trim()) {
                  //       return `${t(`message:MS020001`, { field: t('Dob') })}`
                  //     } else {
                  //       return true
                  //     }
                  //   }
                  // }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                    return (
                      <Input
                        title={t('GaServiceAssociate')}
                        placeholder={''}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        disabled={false}
                        // required
                        maxLength={150}
                        errorMessage={!!value ? "" : error?.message}
                      />
                    )
                  }}
                />
              </SectionCol>
              <SectionCol sectionStyles={[sharedStyle.col_6, configStyles.modalSecondLine]}>
                <Controller
                  control={configGAInformationForm.control}
                  name="gaTaxCode"
                  rules={{
                    required: {
                      value: true,
                      message: t(`message:MS020001`, { field: t('GaTaxCode') })
                    },
                    validate: (value) => {
                      if (!value || !value.trim()) {
                        return `${t(`message:MS020001`, { field: t('GaTaxCode') })}`
                      } else {
                        return true
                      }
                    }
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                    return (
                      <Input
                        title={t('GaTaxCode')}
                        placeholder={''}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        disabled={false}
                        required
                        maxLength={15}
                        errorMessage={!!value ? "" : error?.message}
                      />
                    )
                  }}
                />
              </SectionCol>
              <SectionCol sectionStyles={[sharedStyle.col_6, configStyles.modalSecondLine]}>
                <Controller
                  control={configGAInformationForm.control}
                  name="gadEmail"
                  // rules={{
                  //   required: {
                  //     value: true,
                  //     message: t(`message:MS020001`, { field: t('Dob') })
                  //   },
                  //   validate: (value) => {
                  //     if (!value || !value.trim()) {
                  //       return `${t(`message:MS020001`, { field: t('Dob') })}`
                  //     } else {
                  //       return true
                  //     }
                  //   }
                  // }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                    return (
                      <Input
                        title={t('GadEmail')}
                        placeholder={''}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        disabled={false}
                        // required
                        maxLength={150}
                        errorMessage={!!value ? "" : error?.message}
                      />
                    )
                  }}
                />
              </SectionCol>
              <SectionCol sectionStyles={[sharedStyle.col_6, configStyles.modalSecondLine]}>
                <Controller
                  control={configGAInformationForm.control}
                  name="gadName"
                  // rules={{
                  //   required: {
                  //     value: true,
                  //     message: t(`message:MS020001`, { field: t('Dob') })
                  //   },
                  //   validate: (value) => {
                  //     if (!value || !value.trim()) {
                  //       return `${t(`message:MS020001`, { field: t('Dob') })}`
                  //     } else {
                  //       return true
                  //     }
                  //   }
                  // }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                    return (
                      <Input
                        title={t('GadName')}
                        placeholder={''}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        disabled={false}
                        // required
                        maxLength={150}
                        errorMessage={!!value ? "" : error?.message}
                      />
                    )
                  }}
                />
              </SectionCol>
              <SectionCol sectionStyles={[sharedStyle.col_6, configStyles.modalSecondLine]}>
                <Controller
                  control={configGAInformationForm.control}
                  name="gamRHS"
                  // rules={{
                  //   required: {
                  //     value: true,
                  //     message: t(`message:MS020001`, { field: t('CsRegion') })
                  //   },
                  //   validate: (value) => {
                  //     if (!value || !value.trim()) {
                  //       return `${t(`message:MS020001`, { field: t('CsRegion') })}`
                  //     } else {
                  //       return true
                  //     }
                  //   }
                  // }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                    return (
                      <Input
                        title={t('GamRHS')}
                        placeholder={''}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        disabled={false}
                        // required
                        maxLength={150}
                        errorMessage={!!value ? "" : error?.message}
                      />
                    )
                  }}
                />

              </SectionCol>
              <SectionCol sectionStyles={[sharedStyle.col_6, configStyles.modalSecondLine]}>
                <Controller
                  control={configGAInformationForm.control}
                  name="mobile"
                  rules={{
                    required: {
                      value: true,
                      message: t(`message:MS020001`, { field: t('Mobile') })
                    },
                    validate: (value) => {
                      if (!value || !value.trim()) {
                        return `${t(`message:MS020001`, { field: t('Mobile') })}`
                      } else {
                        return true
                      }
                    }
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                    return (
                      <Input
                        title={t('Mobile')}
                        placeholder={''}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        disabled={false}
                        required
                        maxLength={150}
                        errorMessage={!!value ? "" : error?.message}
                      />
                    )
                  }}
                />
              </SectionCol>
              <SectionCol sectionStyles={[sharedStyle.col_6, configStyles.modalSecondLine]}>
                <Controller
                  control={configGAInformationForm.control}
                  name="officeName"
                  rules={{
                    required: {
                      value: true,
                      message: t(`message:MS020009`, { field: t('OfficeName') })
                    },
                    validate: (item) => {
                      if (!item || !item.value) {
                        return `${t(`message:MS020009`, { field: t('OfficeName') })}`
                      } else {
                        return true
                      }
                    }
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                    return (
                      <SelectSearch
                        label={t('OfficeName')}
                        options={getOfficeOptions()}
                        value={value}
                        onChange={(val) => {
                          onChange(val)
                          onChangeOfficeNameEvent(val?.value || '')
                        }}
                        onBlur={onBlur}
                        disabled={false}
                        popupIcon={<assets.ArrowDownDropdownIcon />}
                        required={true}
                        placeholder={t('common:Select')}
                        errorMessage={!value || !value.value ? error?.message : ''}
                      />
                    )
                  }}
                />

              </SectionCol>
              <SectionCol sectionStyles={[sharedStyle.col_6, configStyles.modalSecondLine]}>
                <Controller
                  control={configGAInformationForm.control}
                  name="officeCode"
                  // rules={{
                  //   required: {
                  //     value: true,
                  //     message: t(`message:MS020001`, { field: t('Dob') })
                  //   },
                  //   validate: (value) => {
                  //     if (!value || !value.trim()) {
                  //       return `${t(`message:MS020001`, { field: t('Dob') })}`
                  //     } else {
                  //       return true
                  //     }
                  //   }
                  // }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                    return (
                      <Input
                        title={t('OfficeCode')}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        disabled={true}
                        // required
                        maxLength={15}
                        errorMessage={!!value ? "" : error?.message}
                      />
                    )
                  }}
                />
              </SectionCol>
              <SectionCol sectionStyles={[sharedStyle.col_6, configStyles.modalSecondLine]}>
                <Controller
                  control={configGAInformationForm.control}
                  name="province"
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                    return (
                      <Input
                        title={t('Province')}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        disabled={false}
                        // required
                        maxLength={50}
                      />
                    )
                  }}
                />
              </SectionCol>
              <SectionCol sectionStyles={[sharedStyle.col_6, configStyles.modalSecondLine]}>
                <Controller
                  control={configGAInformationForm.control}
                  name="sex"
                  // rules={{
                  //   required: {
                  //     value: true,
                  //     message: t(`message:MS020001`, { field: t('CsRegion') })
                  //   },
                  //   validate: (value) => {
                  //     if (!value || !value.trim()) {
                  //       return `${t(`message:MS020001`, { field: t('CsRegion') })}`
                  //     } else {
                  //       return true
                  //     }
                  //   }
                  // }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                    return (
                      <RadioButtonGroup
                        title={t('Sex')}
                        // required
                        colLength={'100%'}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        options={GAInformationSexConst.map((item) => ({
                          id: item.id,
                          label: item.label
                        }))}
                        disabled={false}
                      />
                    )
                  }}
                />

              </SectionCol>
              <SectionCol sectionStyles={[sharedStyle.col_6, configStyles.modalSecondLine]}>
                <Controller
                  control={configGAInformationForm.control}
                  name="telPhone"
                  rules={{
                    required: {
                      value: true,
                      message: t(`message:MS020001`, { field: t('TelPhone') })
                    },
                    validate: (value) => {
                      if (!value || !value.trim()) {
                        return `${t(`message:MS020001`, { field: t('TelPhone') })}`
                      } else {
                        return true
                      }
                    }
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                    return (
                      <Input
                        title={t('TelPhone')}
                        placeholder={''}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        disabled={false}
                        required
                        maxLength={15}
                        errorMessage={!!value ? "" : error?.message}
                      />
                    )
                  }}
                />
              </SectionCol>
              <SectionCol sectionStyles={[sharedStyle.col_6, configStyles.modalSecondLine]}>
                <Controller
                  control={configGAInformationForm.control}
                  name="transactionLiveZoom"
                  // rules={{
                  //   required: {
                  //     value: true,
                  //     message: t(`message:MS020001`, { field: t('CsRegion') })
                  //   },
                  //   validate: (value) => {
                  //     if (!value || !value.trim()) {
                  //       return `${t(`message:MS020001`, { field: t('CsRegion') })}`
                  //     } else {
                  //       return true
                  //     }
                  //   }
                  // }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                    return (
                      <Input
                        title={t('TransactionLiveZoom')}
                        placeholder={''}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        disabled={false}
                        // required
                        maxLength={150}
                        errorMessage={!!value ? "" : error?.message}
                      />
                    )
                  }}
                />

              </SectionCol>
              <SectionCol sectionStyles={[sharedStyle.col_6, configStyles.modalSecondLine]}>
                <Controller
                  control={configGAInformationForm.control}
                  name="type"
                  // rules={{
                  //   required: {
                  //     value: true,
                  //     message: t(`message:MS020001`, { field: t('Dob') })
                  //   },
                  //   validate: (value) => {
                  //     if (!value || !value.trim()) {
                  //       return `${t(`message:MS020001`, { field: t('Dob') })}`
                  //     } else {
                  //       return true
                  //     }
                  //   }
                  // }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                    return (
                      <Input
                        title={t('Type')}
                        placeholder={''}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        disabled={false}
                        // required
                        maxLength={150}
                        errorMessage={!!value ? "" : error?.message}
                      />
                    )
                  }}
                />
              </SectionCol>
            </SectionRow>
          </ScrollView>
        }
        visible={isOpenAddPopup}
        onClose={() => setIsOpenAddPopup(false)}
        title={modalTitle}
        actions={[
          {
            text: t('Cancel'),
            type: 'outline',
            disabled: false,
            loading: false,
            onlyWide: false,
            style: { marginRight: 10 },
            action: () => setIsOpenAddPopup(false)
          },
          isUpdatedAction ?
            {
              text: t('Update'),
              type: 'filled',
              disabled: false,
              loading: false,
              onlyWide: false,
              style: {},
              action: () => onUpdateGAInformationEvent()
            } :
            {
              text: t('Add'),
              type: 'filled',
              disabled: false,
              loading: false,
              onlyWide: false,
              style: {},
              action: () => onAddNewGAInformationEvent()
            }
        ]}
        centerTitle
        modalWidth={850}
      >
      </ModalComponent>
    )
  }
  return (
    <View style={configStyles.container}>
      <View style={configStyles.headerContainer}>
        <BreadCrumb navigation={getBreadCrumbList} />
      </View>
      <View style={[configStyles.bodyContainer]}>
          <View style={configStyles.buttonContainer}>
            <TouchableOpacity onPress={() => onOpenCreateNewGAInformationPopup()}>
              <View style={[sharedStyle.button, sharedStyle.btnRed, configStyles.buttonContent]}>
                <Text style={[sharedStyle.textButtonRed]}>{t('Add')}</Text>
              </View>
            </TouchableOpacity>
          </View>
          <IBGeneralTable
            dataTable={displayColumns}
            data={GAInformationList}
            autoPaging
            maxWidthContainer
            maxHeight={height - height * 0.25}
            maxWidth={width - width * 0.025}
            filterColumn={filterColumn}
          >
          </IBGeneralTable>
      </View>
      {getAddGAInformationModalPopup()}
    </View>
  )
}

const configStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff'
  },
  headerContainer: {
    flexDirection: 'row',
    backgroundColor: '#FFFFFF',
    alignItems: 'center',
    paddingHorizontal: 16,
    shadowColor: 'rgba(0, 0, 0, 0.04)',
    shadowOffset: {
      width: 0,
      height: 4
    },
    shadowOpacity: 0.3,
    shadowRadius: 4.65,
    elevation: 8
  },
  bodyContainer: {
    width: '100%',
    paddingHorizontal: 20,
    // paddingTop: 10
  },
  bodyContent: {
    paddingHorizontal: 20,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  buttonContent: {
    // paddingHorizontal: 10
    marginVertical: 10
  },
  bodyTitle: {
    color: '#000000',
    fontSize: 24,
    fontWeight: 'bold'
  },
  modalBodyContainer: {
    paddingHorizontal: 15,
    paddingVertical: 20
  },
  modalSecondLine: {
    marginTop: 20
  },
})