import { CashOut, PayoutPopup } from '@pulseops/common'

const getPayeeInfo = (payoutData: any) => {
  return {
    paymentMethod: payoutData.payee?.paymentMethod,
    isNewClient: payoutData.payee?.isNewClient,
    isPayeeNotPO: payoutData.payee?.isPayeeNotPO,
    dob: payoutData.payee?.dob,
    gender: payoutData.payee?.gender,
    phone: payoutData.payee?.phone,
    email: payoutData.payee?.email,
    payeeName: payoutData.payee?.payeeName,
    issuedDate: payoutData.payee?.issuedDate,
    issuedBy: payoutData.payee?.issuedBy,
    issueCode: payoutData.payee?.issueCode,
    salutation: payoutData.payee?.salutation,
    married: payoutData.payee?.married,
    occupation: payoutData.payee?.occupation,
    relationshipWithPO: payoutData.payee?.relationshipWithPO,
    nationality: payoutData.payee?.nationality,
    address: payoutData.payee?.address,
    nationality2: payoutData.payee?.nationality2,
    foreignAddress: payoutData.payee?.foreignAddress,
    foreignCountry: payoutData.payee?.foreignCountry,
    residenceCountry: payoutData.payee?.residenceCountry,
    name: payoutData.payee?.name,
    idNumber: payoutData.payee?.idNumber,
    dod: payoutData.payee?.dod,
    surName: payoutData.payee?.surName,
    giveName: payoutData.payee?.giveName,
    city: payoutData.payee?.city,
    district: payoutData.payee?.district,
    ward: payoutData.payee?.ward,
    street: payoutData.payee?.street
  }
}

export const mapCashOutOption = (value: PayoutPopup.Summary[]): CashOut.Option[] =>
  value.map((payout) => {
    if (payout.method === PayoutPopup.PayoutMethods.BANKTRANSFER) {
      return {
        type: payout.method,
        policy: {
          policyNo: payout.policyNum ?? '',
          totalPremium: payout.totalPremium ?? 0,
          status: 'IF'
        },
        paymentOption: {
          currency: 'VND',
          amount: Number(payout.amount)
        },
        bankAccount: {
          accountName: payout.bankAccountName ?? '',
          accountNo: payout.bankAccountNum ?? '',
          bankName: payout.bankName ?? '',
          branchCode: payout.bankBranchCode ?? '',
          bankKey: payout.bankBranchCode ?? '',
          nationalId: ''
        },
        attributesExt: {
          // PAYEE_INFO: payout.payee
          PAYEE_INFO: getPayeeInfo(payout)
        }
      }
    } else if (payout.method === PayoutPopup.PayoutMethods.CASH_AT_COUNTER) {
      return {
        type: payout.method,
        policy: {
          policyNo: payout.policyNum ?? '',
          totalPremium: payout.totalPremium ?? 0,
          status: 'IF'
        },
        // cashAtCounter: {
        //   gaOffice: payout.officeCode ?? '',
        //   payeeName: payout.poName ?? '',
        //   idNumber: payout.nationalId ?? '',
        //   bankCode: payout.payee?.bankCode ?? '',
        //   officeType: payout.payee?.officeType ?? ''
        // },
        bankAccount: {
          bankCode: payout?.officeBankCode ?? ''
        },
        paymentOption: {
          currency: 'VND',
          amount: Number(payout.amount)
        },
        // clientInfo: payout.payee
        attributesExt: {
          PVA_CASH_OUT_OPTION_INFO: {
            officeCode: payout.officeCode ?? '',
            officeType: payout?.officeType ?? ''
          },
          // PAYEE_INFO: payout.payee,
          PAYEE_INFO: getPayeeInfo(payout)
        }
      }
    } else if (payout.method === PayoutPopup.PayoutMethods.PAID_AT_BANK) {
      return {
        type: payout.method,
        policy: {
          policyNo: payout.policyNum ?? '',
          totalPremium: payout.totalPremium ?? 0,
          status: 'IF'
        },
        // payAtBank: {
        //   payeeName: payout.poName ?? '',
        //   idNumber: payout.nationalId ?? '',
        //   issueDate: payout.issueDate ?? '',
        //   issueBy: payout.issueBy ?? '',
        //   bank: payout.bankName ?? '',
        //   bankName: payout.bankName ?? '',
        //   branch: payout.bankBranchCode ?? '',
        //   branchName: payout.bankBranchName
        // },
        bankAccount: {
          bankCode: payout.bankCode ?? '',
          bankName: payout.bankName ?? '',
          branchCode: payout.bankBranchCode ?? ''
          // branchName: payout.bankBranchName
        },
        paymentOption: {
          currency: 'VND',
          amount: Number(payout.amount)
        },
        // clientInfo: payout.payee
        attributesExt: {
          // PAYEE_INFO: payout.payee,
          PAYEE_INFO: getPayeeInfo(payout)
        }
      }
    } else if (payout.method === PayoutPopup.PayoutMethods.MOMO) {
      return {
        type: payout.method,
        policy: {
          policyNo: payout.policyNum ?? '',
          totalPremium: payout.totalPremium ?? 0,
          status: 'IF'
        },
        paymentOption: {
          currency: 'VND',
          amount: Number(payout.amount)
        },
        attributesExt: {
          PAYEE_INFO: getPayeeInfo(payout)
        }
      }
    } else {
      return {
        type: payout.method,
        paymentOption: {
          currency: 'VND',
          amount: Number(payout.amount)
        },
        policy: {
          policyNo: payout.policyNum ?? '',
          totalPremium: payout.totalPremium ?? 0, // *
          status: 'IF'
        }
      }
    }
  })
