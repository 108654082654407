import * as React from 'react'
import { View, Text } from 'react-native'
import {
  FieldList,
  form2,
  formatNumberWithComma,
  addCommasToCurrencyString,
  getNumberFromString,
  getLanguage,
  HeaderTable,
  InputTable,
  Label,
  Panel,
  PartialWithdrawal,
  PartialWithdrawalService,
  Title,
  TopUpConst,
  TransactionType,
  formatStringToDecimalNumber,
  AppContext
} from '@pulseops/common'
import { mapCashOutOption } from '../../utils'
import { useTranslation } from 'react-i18next'
import { CepILPForm } from '../CEPIlpPartialWithdrawForm'
import { Controller, useFieldArray } from 'react-hook-form'
import { Column, Error } from '@pulseops/submission/common'
import { RequestAuthenticateData, PolicyServiceProps } from '@pulseops/submission'

import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { calcPWAmountILP } from '../../cep-api-services'

enum FundType {
  TargetPremium = '1',
  TopUp = '2'
}

interface Props {
  policyNumber: string
  detail: any
  basicCode: string
}

export const CepILPPartialWithdraw = ({ detail, policyNumber, basicCode }: Props) => {
  const { t } = useTranslation()

  const [enableWATagetPremium, setEnableWATagetPremium] = React.useState<boolean>(false)

  const isTargetPremiumFundType = (fundCode: string) => {
    return fundCode.endsWith(FundType.TargetPremium)
  }

  const isTopupFundType = (fundCode: string) => {
    return fundCode.endsWith(FundType.TopUp)
  }

  const getTotalAmountByFundType = (fundList: PartialWithdrawal.FundData[], fundType: FundType) => {
    return (
      fundList
        .filter((item) => item.fundCode.endsWith(fundType))
        // .reduce((total, item) => total + item.fundValue, 0)
        .reduce((total, item) => Number((total + item.fundValue).toFixed(2)), 0)
    )
  }

  const { totalAmountAllTargetPremiumFund, totalAmountAllTopUpFund } = React.useMemo(() => {
    const fundList = detail?.fundList || []
    const totalAmountAllTopUpFund = getTotalAmountByFundType(fundList, FundType.TopUp)
    const totalAmountAllTargetPremiumFund = getTotalAmountByFundType(fundList, FundType.TargetPremium)
    return {
      totalAmountAllTopUpFund,
      totalAmountAllTargetPremiumFund
    }
  }, [detail])

  const { showToast } = React.useContext(AppContext.AppContextInstance)
  const { base } = form2.useForm(CepILPForm.codec, {
    defaultValues: { fundList: [] }
  })

  const fundListForm = useFieldArray({
    control: base.control,
    name: 'fundList'
  })

  const initFundListForm = (fundList: PartialWithdrawal.FundData[]) => {
    const subForm = fundList.map((item) => ({
      isRequired: isTopupFundType(item.fundCode),
      withdrawAmount: '0'
    }))
    fundListForm.remove()
    fundListForm.append(subForm)
  }

  React.useEffect(() => {
    const fundList = detail?.fundList || []
    initFundListForm(fundList)
    base.setValue('withdrawFee', detail?.withDrawFee)
    return () => {
      base.reset({
        fundList: [],
        withdrawFee: undefined
      })
    }
  }, [detail])

  const convertFundListToDataSource = (fundList: PartialWithdrawal.FundData[]) => {
    return fundList
      .filter((item) => item.fundValue > 0)
      .map((item) => {
        const fundName = TopUpConst.FundList.find((fund) => fund.fundCode === item.fundCode)
        return {
          ...item,
          fundName: `${item.fundCode} - ${getLanguage() === 'en' ? fundName?.desEn : fundName?.desVi}`,
          minPWRemain: formatNumberWithComma(item.minPWRemain),
          // fundValue: formatNumberWithComma(item.fundValue)
          fundValue: formatStringToDecimalNumber(item.fundValue.toFixed(2))
        }
      }) as any[]
  }

  const totalWithdrawAmount = React.useMemo(() => {
    const result = fundListForm.fields.reduce((total, item) => {
      const numberString = getNumberFromString(item.withdrawAmount ?? '')
      const amount = Number(numberString)
      const totalSum = Number((total + amount).toFixed(2))
      return totalSum
    }, 0)
    return result
  }, [base.watch('fundList')])

  const isFillAllWithdrawalAmountTopUp = () => {
    const fundList = detail?.fundList || []
    const isFillAll =
      fundListForm.fields
        .filter((_, index) => isTopupFundType(fundList[index].fundCode))
        .find((item) => !item.withdrawAmount) === undefined
    return isFillAll
  }

  React.useEffect(() => {
    if (totalAmountAllTopUpFund === 0 && totalAmountAllTargetPremiumFund > 0) {
      setEnableWATagetPremium(true)
    } else {
      setEnableWATagetPremium(false)
    }
  }, [totalAmountAllTopUpFund, totalAmountAllTargetPremiumFund])

  React.useEffect(() => {
    validateTotalWithdrawAmount()
  }, [base.formState.isValidating])

  React.useEffect(() => {
    validateTotalWithdrawAmount(true)
  }, [totalWithdrawAmount])

  React.useEffect(() => {
    const fundList = detail?.fundList || []
    fundList.forEach((item: any, index: number) => {
      if (isTargetPremiumFundType(item.fundCode)) {
        base.setValue(
          `fundList.${index}`,
          { isRequired: enableWATagetPremium, withdrawAmount: '0' },
          { shouldValidate: true }
        )
      }
    })
  }, [enableWATagetPremium])

  const validateValueFor_ULR1 = (index: number, E: number, D: number, minPWRemain: number) => {
    let flag = true
    if (E > D) {
      base.setError(`fundList.${index}.withdrawAmount`, {
        message: t('message:MS050159')
      })
      flag = false
    } else if (D - E < minPWRemain) {
      base.setError(`fundList.${index}.withdrawAmount`, {
        message: t('message:MS050247', { min: minPWRemain })
      })
      flag = false
    }
    return flag
  }

  const validateValueFor_ULR4_URL5 = (index: number, E: number, D: number) => {
    let flag = true
    if (E > D) {
      base.setError(`fundList.${index}.withdrawAmount`, {
        message: t('message:MS050248')
      })
      flag = false
    }
    return flag
  }

  const validateValueFor_ULR6 = (index: number, E: number, D: number, minPWRemain: number) => {
    let flag = true
    if (E > D) {
      base.setError(`fundList.${index}.withdrawAmount`, {
        message: t('message:MS050159')
      })
      flag = false
    } else {
      const fundList = detail?.fundList || []
      const totalWATopup = base
        .watch('fundList')
        .filter((_, idx) => isTopupFundType(fundList[idx].fundCode))
        .reduce((total, item) => {
          // total + Number(item.withdrawAmount)
          const numberString = getNumberFromString(item.withdrawAmount ?? '')
          const amount = Number(numberString)
          const totalSum = Number((total + amount).toFixed(2))
          return totalSum
        }, 0)
      const isFillAllTopupWithDrawal = isFillAllWithdrawalAmountTopUp()

      if (
        (isFillAllTopupWithDrawal && totalWATopup === totalAmountAllTopUpFund) ||
        (totalAmountAllTopUpFund === 0 && totalAmountAllTargetPremiumFund > 0)
      ) {
        setEnableWATagetPremium(true)
        const remaining = D - E
        const isTargetPremium = isTargetPremiumFundType(fundList[index].fundCode)
        if (remaining < minPWRemain && isTargetPremium) {
          base.setError(`fundList.${index}.withdrawAmount`, {
            message: t('message:MS050247', { min: minPWRemain })
          })
          flag = false
        }
      } else {
        setEnableWATagetPremium(false)
      }
    }
    return flag
  }

  const fundListDataSource = React.useMemo(() => convertFundListToDataSource(detail?.fundList || []), [detail])

  const renderFundName = (index: number) => {
    const fund = detail?.fundList[index]
    const withdrawAmount = base.watch('fundList')[index]?.withdrawAmount
    const isShowWarning =
      isTopupFundType(fund?.fundCode || '') && withdrawAmount && Number(withdrawAmount) !== fund?.fundValue

    return (
      <View>
        <Text>{fundListDataSource[index].fundName}</Text>
        {isShowWarning && <Error message={t('message:MS050249')} style={{ paddingEnd: 16 }} />}
      </View>
    )
  }

  const getTotalFee = () => {
    const formValue = base.getValues()
    let fundDetailList =
      detail && detail?.fundList
        ? detail?.fundList.map((fundItem: any, index: number) => {
            const withdrawAmount = getNumberFromString(formValue.fundList[index].withdrawAmount ?? '')
            return {
              coverage: '01',
              fundCode: fundItem.fundCode,
              amount: Number(withdrawAmount)
            }
          })
        : []
    fundDetailList = fundDetailList.filter((x: any) => Number(x.amount) > 0)
    const inputData = {
      data: {
        details: fundDetailList
      }
    }
    // console.log(`inputData :${JSON.stringify(inputData)}`)
    pipe(
      calcPWAmountILP(policyNumber, inputData),
      ZIO.foldM(
        (error) =>
          ZIO.effect(() => {
            if (error) {
              const errorSour = error.source as Error
              const messageList = errorSour.message.split('-')
              if (messageList && messageList.length > 1) {
                const errorMsg = t('message:MS050034') + messageList[1]
                base.setValue('isLASMessage', true)
                base.setValue('LASMessage', errorMsg)
                showToast(errorMsg, 'error')
              }
              base.setValue('withdrawFee', 0)
            }
          }),
        (data) =>
          ZIO.effect(() => {
            if (data?.error?.code === 0) {
              const message = data.error.message
              if (message) {
                const errorMsg = t('message:MS050034') + message
                base.setValue('isLASMessage', true)
                base.setValue('LASMessage', errorMsg)
                showToast(errorMsg, 'error')
              }
              base.setValue('withdrawFee', 0)
            }
            if (data?.data) {
              const partialWithdrawFee = Math.floor(data.data.withDrawFee ?? 0)
              base.setValue('withdrawFee', partialWithdrawFee)
            }
          })
      ),
      ZIO.unsafeRun({})
    )
  }

  const validateTotalWithdrawAmount = (isTotalFee?: boolean) => {
    const P = totalWithdrawAmount
    const B = detail?.minimumPartialWithdrawal || 0
    const C = detail?.maximumPartialWithdrawal || 0
    const coverageCode = basicCode || ''

    let isFormValid = false
    if (['ULR1', 'ULR4', 'ULR5', 'ULR6'].includes(coverageCode)) {
      if (P < B) {
        base.setError('fundListError', { message: t('message:MS050242', { Min: `${formatNumberWithComma(B)} VND` }) })
      } else if (P > C) {
        base.setError('fundListError', { message: t('message:MS050243', { Max: `${formatNumberWithComma(C)} VND` }) })
      } else if (coverageCode === 'ULR6' && P === C) {
        base.setError('fundListError', { message: t('message:MS050245') })
      } else {
        isFormValid = true
        base.clearErrors('fundListError')
      }
    } else {
      isFormValid = true
      base.clearErrors('fundListError')
    }
    console.log(isFormValid, 'isFormValid')
    isFormValid && isTotalFee === true && getTotalFee()
    return isFormValid
  }

  return (
    <Column flex={1}>
      {/* <Title title={t('requestInfo:PARTIAL_WITHDRAWAL_INFO')} wrapperStyle={{ marginTop: 30 }} /> */}
      <Panel title={t('requestInfo:PARTIAL_WITHDRAWAL_INFO')} containerStyle={{ backgroundColor: '#FAFAFA' }}>
        <FieldList
          dataSource={[
            {
              label: t('requestInfo:EstimatedValue'),
              value: formatNumberWithComma(detail?.estimatedValue ?? 0),
              suffix: 'VND'
            },
            {
              label: t('requestInfo:Minimumpartialwithdrawal'),
              value: formatNumberWithComma(detail?.minimumPartialWithdrawal ?? 0),
              suffix: 'VND'
            },
            {
              label: t('requestInfo:Maximumpartialwithdrawal'),
              value: formatNumberWithComma(detail?.maximumPartialWithdrawal ?? 0),
              suffix: 'VND'
            },
            {
              label: t('requestInfo:Withdrawfee'),
              value: formatNumberWithComma(detail?.withdrawFee ?? 0),
              suffix: 'VND'
            }
          ]}
        />

        <Label title={t('requestInfo:Fundlist')} />
        <Text style={{ fontSize: 15, fontWeight: '400', color: '#000000', marginTop: 14, marginBottom: 33 }}>
          {`${t('requestInfo:TotalWithdrawAmount')}:`}
          <Text
            style={{ fontSize: 18, fontWeight: 'bold', color: '#ED1B2E', marginStart: 4 }}
          >{`${addCommasToCurrencyString(String(totalWithdrawAmount))} VND`}</Text>
        </Text>

        <Text style={{ color: '#70777E', fontStyle: 'italic', fontSize: 15, textAlign: 'right', marginBottom: 12 }}>
          {`${t('Payout:Currency')}: VND`}
        </Text>
        <HeaderTable
          columns={[
            {
              key: 0,
              title: t('requestInfo:Fund'),
              name: 'fundName',
              render: ({ index }) => renderFundName(index)
            },
            {
              key: 1,
              title: t('requestInfo:MinP/Wremain'),
              name: 'minPWRemain'
            },
            {
              key: 2,
              title: t('requestInfo:Fundvalue'),
              name: 'fundValue'
            },
            {
              key: 3,
              title: t('requestInfo:WithdrawAmount'),
              name: '',
              require: true,
              styles: {
                textAlign: 'right'
              },
              render: ({ index }) => {
                const fundForm = fundListForm.fields[index]
                if (fundForm) {
                  return (
                    <Controller
                      control={base.control}
                      name={`fundList.${index}.withdrawAmount`}
                      render={({ field, fieldState: { error } }) => {
                        const fundList = detail?.fundList || []
                        const isTargePremium = isTargetPremiumFundType(fundList[index].fundCode)
                        const isDisable = isTargePremium && !enableWATagetPremium

                        return (
                          <InputTable
                            disabled={isDisable}
                            value={field.value || ''}
                            inputType="money"
                            isFractionFormat={true}
                            textAlign="right"
                            inputStyle={{
                              backgroundColor: isDisable ? '#FAFAFA' : '#FFFFFF',
                              borderColor: '#D3DCE6'
                            }}
                            errorMessage={error?.message}
                            onChange={(value) => {
                              fundListForm.update(index, { isRequired: !isDisable, withdrawAmount: value })
                              if (value) {
                                // const withdrawAmount = Number(value || 0)
                                const withdrawAmount = Number(getNumberFromString(value ?? ''))
                                const fundValue = fundList[index]?.fundValue || 0
                                const minPWRemain = Number(fundList[index]?.minPWRemain || 0)
                                if (basicCode === 'ULR1') {
                                  validateValueFor_ULR1(index, withdrawAmount, fundValue, minPWRemain)
                                } else if (basicCode === 'ULR4' || basicCode === 'ULR5') {
                                  validateValueFor_ULR4_URL5(index, withdrawAmount, fundValue)
                                } else if (basicCode === 'ULR6') {
                                  validateValueFor_ULR6(index, withdrawAmount, fundValue, minPWRemain)
                                }
                              }
                            }}
                          />
                        )
                      }}
                    />
                  )
                }
                return <View />
              }
            }
          ]}
          dataSource={fundListDataSource}
          rowStyle={{ backgroundColor: '#FAFAFA' }}
        />
        <Error message={base.formState?.errors?.fundListError?.message || ''} style={{ marginTop: 10 }} />
      </Panel>
    </Column>
  )
}
