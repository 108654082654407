/* eslint-disable no-extra-boolean-cast */
import * as React from 'react'
import { View, Text, StyleSheet, TouchableOpacity, ScrollView } from 'react-native'
import {
  ControlProps,
  PulseOpsFormat,
  SourceType,
  TransactionType,
  assets,
  downloadURI,
  replaceFileNameSEABillingChange,
  useMobile
} from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { AuthService, ErrorHandling } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import _ from 'lodash'
import { AppContext } from '@pulseops/common'
import { GenerateUUID } from './SEAuuid'
import { SEABillingChangeService } from '../billing-change/SEABillingChangeService'
export interface FileUploadDataXLS {
  fileName: string
  fileExtension: string
  size: number
  base64: string
  uploadedDate: Date
  file: File
}

type ImageMultiProps = ControlProps<FileUploadDataXLS[]> & {
  transactionType?: string
  maxNumFile?: number
  timeFormat?: string
  disabled?: boolean
  type?: number
}
export enum ImportType {
  ImportBillingChange = 1,
  ImportPartialWithdramw = 2
}
export interface UploadImgRef {
  clearError: () => void
}

export const ImgUploadMutipleXLS = React.forwardRef((props: ImageMultiProps, ref: React.Ref<UploadImgRef>) => {
  const { showToast } = React.useContext(AppContext.AppContextInstance)
  const { value, onChange, disabled } = props
  const { t } = useTranslation()
  const inputRef = React.createRef<HTMLInputElement>()
  const MB = 1048576
  const maxSize = 10 * MB
  const maxTotal = 50 * MB
  const userInfo = pipe(AuthService.userInfo, ErrorHandling.runDidMount())
  const maxFile = props.maxNumFile
  const validExtensions = ['XLSX', 'XLS']
  const validExtensionsName = ['*.xlsx', '*.xls']
  const [errorMess, setErrorMess] = React.useState<string>('')
  const [currSize, setCurrSize] = React.useState<number>(0)
  const { isMobile } = useMobile()
  function isDateValid(dateString: string | number | Date) {
    const date = moment(dateString, 'DDMMYYYY')
    // The date string is valid.
    return date.isValid()
  }

  function checkString(string: string) {
    return /^[0-9]*$/.test(string)
  }
  const validateName = (data: string) => {
    const arr = data.split('_')
    if (arr.length < 2) return false
    if (data.length < 19) return false
    if (arr[0] !== 'Seabank') return false
    if (arr[1].length !== 8) return false
    if (checkString(arr[2]) === false || checkString(arr[1]) === false) return false
    const time: string = arr[1]
    const timeArr = arr[1].split('')
    timeArr.splice(2, 0, '-')
    timeArr.splice(-4, 0, '-')
    return isDateValid(time)
  }

  const checkUpload = (files: FileUploadDataXLS[]) => {
    const file = files[0]
    const namePDF = file.fileName
    const namePDF1 = namePDF.substring(8, 16)
    const date = moment(namePDF1, 'DDMMYYYY').toDate()
    const datePase = moment(date)
    const nowDate = moment(new Date())
    if (!validExtensions.includes(file.fileExtension.toLocaleUpperCase())) {
      return setErrorMess(t('message:MS990095', { files: validExtensions.join('/') }))
    } else if (value && value?.length === 1) {
      return setErrorMess(t('message:MS990104'))
    } else if ([19, 20, 21, 22, 23, 24].includes(file.fileName.length) && moment(datePase).isAfter(moment(nowDate))) {
      return setErrorMess(t('message:MS990102', { date: moment(new Date()).format('DD/MM/YYYY') }))
    } else if (!validateName(file.fileName)) {
      return setErrorMess(t('message:MS990094'))
    } else if (file.size >= maxSize) {
      return setErrorMess(t('message:MS990096'))
    } else if (currSize + file.size >= maxTotal) {
      return setErrorMess(t('message:MS990096'))
    } else if (maxFile && value && value.length >= maxFile) {
      return setErrorMess(t('message:MS050279', { size: maxFile }))
    } else {
      onChange && onChange(value ? [...value, file] : [file])
      setCurrSize(currSize + file.size)
      setErrorMess('')
    }
  }

  const deleteItem = (i: number) => {
    setErrorMess('')
    const temp = value || []
    temp.splice(i, 1)
    onChange && onChange(temp)
  }

  const handleUpload = (files: FileList) => {
    const fileList: FileUploadDataXLS[] = []
    const fileLength = files.length

    for (let i = 0; i < files.length; i++) {
      const fileItem = files[i]
      const reader = new FileReader()
      const oldExtension = fileItem.name ? fileItem.name.slice(fileItem.name.lastIndexOf('.')) : ''
      const fileName = fileItem.name ? fileItem.name.slice(0, fileItem.name.lastIndexOf('.')) : ''
      //filter file name and replace special characters
      const filterNewName = replaceFileNameSEABillingChange(fileName)
      const fullFilterName = filterNewName + oldExtension.toLowerCase()
      const newFile = new File([fileItem], fullFilterName, { type: fileItem.type })
      const fileExt = newFile.name.split('.').pop() ?? ''
      reader.readAsDataURL(newFile)
      reader.onloadend = () => {
        fileList.push({
          fileName: newFile.name.split('.')[0],
          fileExtension: fileExt.toLowerCase(),
          size: newFile.size,
          base64: reader.result as string,
          uploadedDate: new Date(),
          file: newFile
        })
        i === fileLength - 1 && fileList.length > 0 && checkUpload(fileList)
      }
    }
  }

  React.useImperativeHandle(ref, () => ({
    clearError: () => {
      setErrorMess('')
    }
  }))
  const ProxyView = isMobile ? View : ScrollView
  const composeProxyView = '100%'
  const group = '<Group Name_ddmmyyyy_xx>'

  const exportExcel = () => {
    const data = {
      source: SourceType.BANCA_SEA,
      exchangeId: GenerateUUID.uuid(),
      createdBy: userInfo?.email,
      action: 'MSP_CCG',
      body: {
        templateFileName:
          props.type === ImportType.ImportPartialWithdramw
            ? 'template/autopartialwithdraw-sea-template.xlsx'
            : 'template/billingChange-sea-template.xlsx',
        transactionType:
        props.type === ImportType.ImportPartialWithdramw
            ? TransactionType.AUTO_DEBIT_TOPUP_ACCOUNT_REQUEST
            : TransactionType.BILLING_CHANGE_CORPORATE
      },
      createdDate: new Date().toISOString()
    }
    pipe(
      ZIO.zipPar(
        AuthService.token,
        AuthService.getLoginType,
        props.type === ImportType.ImportPartialWithdramw
          ? SEABillingChangeService.exportTemplateExcelWithdraw(data)
          : SEABillingChangeService.exportTemplateExcel(data)
      ),
      ZIO.flatMap(([token, loginType, res]) => {
        return !!res && !!res.body
          ? pipe(
              ZIO.fromPromise(() =>
                fetch('data:application/vnd.openxmlformats-officedocument.speadsheetml.sheet;base64,' + res.body, {
                  method: 'GET',
                  headers: {
                    Authorization: `Bearer ${token}`,
                    'X-Authen-Vendor': loginType
                  }
                })
              ),
              ZIO.flatMap((res) =>
                ZIO.zipPar(
                  ZIO.succeed(res),
                  ZIO.fromPromise(() => res.blob())
                )
              ),
              ZIO.map(([res, blob]) => {
                return downloadURI(window.URL.createObjectURL(blob), `Group Name_ddmmyyyy_xx.xlsx`)
              })
            )
          : ZIO.succeed(showToast(t(`message:MS030029`), 'error'))
      }),
      ZIO.unsafeRun({})
    )
  }
  const handleRenderIcon = (fileExtension: string) => {
    switch (fileExtension.toLowerCase()) {
      case 'xls':
        return <assets.XLSXIcon />
      case 'xlsx':
        return <assets.XLSXIcon />
      default:
        return <assets.DocumentIcon />
    }
  }
  return (
    <View>
      <View style={{ flexDirection: isMobile ? 'column' : 'row', marginTop: 10 }}>
        <View style={{ flexDirection: 'row', marginBottom: 20 }}>
          <TouchableOpacity style={[styles.uploadContainer, { marginRight: 10, width: 230 }]}>
            <assets.UploadCloud />
            <Text style={{ fontSize: 12 }}>{t('common:DropFileHere')}</Text>
            <Text style={{ fontSize: 12 }}>{t('common:or')}</Text>
            <Text style={{ fontSize: 12, color: '#ED1B1C' }}>{t('common:SelectFromComputer')}</Text>
            <input
              ref={inputRef}
              //hidden
              style={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                fontSize: 20,
                cursor: 'pointer',
                opacity: 0,
                borderWidth: 1
              }}
              type="file"
              multiple={false}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (event.target.files) {
                  handleUpload(event.target.files)
                  event.target.files = null
                  event.target.value = ''
                }
              }}
              onDrop={(event: React.DragEvent<HTMLInputElement>) => {
                event.preventDefault()
                if (event.dataTransfer.files) {
                  handleUpload(event.dataTransfer.files)
                }
              }}
            />
          </TouchableOpacity>
        </View>
        <ProxyView
          style={{ width: composeProxyView, paddingVertical: isMobile ? 16 : 0, maxHeight: isMobile ? undefined : 250 }}
        >
          <View style={{ width: '100%', flexDirection: 'row', flexWrap: 'wrap' }}>
            {value &&
              value.map((file, i) => (
                <View style={[styles.resultItem, isMobile ? { flex: 1 } : {}]} key={`resultItem-${i}`}>
                  <View style={{ justifyContent: 'center' }}>{handleRenderIcon(file.fileExtension)}</View>
                  <View style={{ flexDirection: 'column', marginLeft: 10 }}>
                    <Text style={{ fontSize: 15 }}>
                      <Text> {file?.fileName && file.file.name} </Text>
                    </Text>
                    <Text style={{ fontSize: 15, fontStyle: 'italic', marginTop: 20 }}>
                      {PulseOpsFormat.datetoFormat(file.uploadedDate, props.timeFormat || 'DD/MM/YYYY HH:mm')}
                    </Text>
                  </View>
                  <View style={{ position: 'absolute', right: 0, paddingTop: 20, flexDirection: 'row' }}>
                    {!disabled && (
                      <TouchableOpacity
                        hitSlop={{ right: 20, left: 20, top: 20, bottom: 20 }}
                        onPress={() => deleteItem(i)}
                        style={{ padding: 5 }}
                      >
                        <assets.DeleteIcon />
                      </TouchableOpacity>
                    )}
                  </View>
                </View>
              ))}
          </View>
        </ProxyView>
      </View>
      <Text
        onPress={() => {
          exportExcel()
        }}
      >
        <Text style={{ fontSize: 15, opacity: 0.6, fontStyle: 'italic' }}>{t('BillingChange:templateName')}</Text>{' '}
        <Text style={{ color: '#4fa8fb', fontStyle: 'italic' }}> {group} </Text>
      </Text>
      <Text style={{ fontSize: 15, opacity: 0.6, fontStyle: 'italic' }}>
        {t('BillingChange:FormatFile', { fileExtension: validExtensionsName.join(', ') })}
      </Text>
      <Text style={{ fontSize: 15, opacity: 0.6, fontStyle: 'italic' }}>
        {t('BillingChange:MaximumFile', { fileExtension: '1MB' })}
      </Text>
      <Text style={{ color: '#ED1B2C', fontSize: 15 }}>{errorMess}</Text>
    </View>
  )
})

const styles = StyleSheet.create({
  common: {
    height: 31,
    fontSize: 15,
    borderBottomWidth: 0
  },
  dashline: {
    borderBottomColor: '#D3DCE6',
    borderBottomWidth: 1
  },
  uploadContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    // font-size: 12px;
    // border: 1px dashed #D3DCE6,
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: '#D3DCE6',
    borderRadius: 8,
    height: 156
  },
  resultItem: {
    height: 70,
    width: 275,
    flexDirection: 'row',
    borderBottomColor: '#92B0B3',
    borderBottomWidth: 2,
    borderStyle: 'dashed',
    marginBottom: 10,
    marginHorizontal: 10
  }
})
