export namespace ChangeClientInfo {
  export interface ClientPolicy {
    policyNum: string
    address: string
  }

  export interface CustomerData {
    customerId: string
    idType: string
    idNum: string
    issuedAt: string
    name: string
    surName: string
    fatca: boolean
    dob: string
    dod: string
    gender: string
    nationality: string
    mobilePhoneCode: string
    mobilePhoneNumber: string
    email: string
    role: string
    countryCode: string
    city: string
    district: string
    ward: string
    address: string
    foreignAddress: string
    foreignCountry: string
    nationality2: string
  }
  export interface Detail {
    customerId: string
    customerData: CustomerData[]
    occupation: {
      job: string
      jobSpec: string
      title: string
      companyName: string
      companyAddress: string
      salary: string
    }
  }

  export interface DataSubmit {
    clientId: string
    surName: string
    firstName: string
    dob: string
    oldDob: string
    sex: string
    nationality: string
    occupation: string
    contactDetails: {
      phone: {
        countryCode: string
        value: string
      }
      email: {
        value: string
      }
    }
    addressDetails: {
      ADDRESS: {
        line1: string
        city: string
        district: string
        subDistrict: string
        countryCode: string
        location?: string
      },
      FOREIGN_ADDRESS: {
        line1: string
        countryCode: string
      }
    }
    attributesExt: {
      // clientType: string,
      idType: string
      idNumber: string
      issuedBy: string
      usTaxDeclarationFrom: string
      occupationFlag: string
      specificJob: string
      issuedDate: string
      title: string
      workPlace: string
      workAddress: string
      salary: string
      clientNum: string
      duplicatePhones: {
        clientNum: string
        relationship: string
        clientName: string
      }[]
      duplicateEmails: {
        clientNum: string
        relationship: string
        clientName: string
      }[]
      NATIONALITY_2: string
      nationality2Name: string
      countryName: string
      districtName: string
      wardName: string
      taxResidencyCountry: string
    }
  }

  export interface AccessDataCheck {
    contractStatus: string
    paidToDateAdvance: Date | null
  }

  export interface DuplicateInfo {
    clientId: string
    clientName: string
    role: string
    secuityNo: string
    dob: string
    gender: string
  }
}
