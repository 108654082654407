import React from 'react'
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { SafeAreaView, StyleSheet, TouchableOpacity, View, Text } from 'react-native'
import {
  assets,
  Input,
  Select,
  Title,
  JournalService,
  AppContext,
  formatNumberWithComma,
  PayoutService
} from '@pulseops/common'
import { SelectBox } from './component'
import { JournalsForm } from './transfer-journal-form'
import { Controller, useFieldArray, UseFormReturn } from 'react-hook-form'
import { useIsFocused } from '@react-navigation/native'
import { SectionContent } from '@pulseops/submission/common'
import { ActionOption } from './transfer-journal-screen'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'

const defaultValueTranfer = {
  SACCode: '',
  SACType: '',
  currentBalance: '',
  tranferAmount: ''
}

const defaultValueReceive = {
  SACCode: '',
  SACType: '',
  amount: ''
}

const typeList = [
  {
    label: 'T209 - Direct refund',
    value: 'T209'
  },
  {
    label: 'TV9L- Loan repayment',
    value: 'TV9L'
  },
  {
    label: 'TMAD - Maturity Advance',
    value: 'TMAD'
  },
  {
    label: 'B522 - Premium collection',
    value: 'B522'
  },
  {
    label: 'T2A6 - Transfer journal for ULP 2019',
    value: 'T2A6'
  }
]

const listSACCode = ['LN', 'LX', 'LP', 'LI', 'LF']
const useStylesTextField = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-root': {
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;'
    }
  }
}))
interface Props {
  disabled: boolean
  form: UseFormReturn<JournalsForm.JournalsData, object>
  policyNum: string
}

export const PayableJournalForm: React.FC<Props> = ({ disabled, form, policyNum }) => {
  const { t } = useTranslation()
  const isFocused = useIsFocused()
  const [isLoading, bindLoader] = useLoading(false)
  const { showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const classesTextField = useStylesTextField()
  const fieldTranfer = useFieldArray({
    control: form.control,
    name: 'journalPayable.journalsTransfer'
  })
  const fieldReceive = useFieldArray({
    control: form.control,
    name: 'journalPayable.journalsReceive'
  })
  const actionJournal = form.watch('action.value')
  const [disableAddRowTranfer, setDisableAddRowTranfer] = React.useState<boolean>(false)
  const [disableAddRowReceive, setDisableAddRowReceive] = React.useState<boolean>(false)
  const [changeToInput, setChangeToInput] = React.useState<boolean>(false)
  const [policyOwner, setPolicyOwner] = React.useState<string>('')

  const setDefaultValue = () => {
    fieldTranfer.append(defaultValueTranfer)
    fieldReceive.append(defaultValueReceive)
    form.setValue('journalPayable.totalTransfer', 0)
    form.setValue('journalPayable.totalReceive', 0)
  }

  const listSACCodeFilter = (receiPolicy: boolean) => {
    switch(actionJournal){
      case ActionOption.ACTION_J_ET:
        return ['LP']
      case ActionOption.ACTION_A_CNB:
      case ActionOption.ACTION_J_APR:
        return ['LP', 'LN']
      case ActionOption.ACTION_L_LRR: {
        if (receiPolicy) return ['LN']
        return ['LP']
      }
      case ActionOption.ACTION_A_CJ: {
        if (receiPolicy) return ['LP']
        return listSACCode
      }
      default:
        return []
    }
  }

  const listSACTypeFilter = (index: number) => {
    const sacCode = form.watch(`journalPayable.journalsTransfer.${index}.SACCode`)
    if (sacCode === 'LP')
      switch (actionJournal) {
        case ActionOption.ACTION_A_CNB:
          return ['U', 'S', 'VP', 'PS']
        case ActionOption.ACTION_J_ET:
          return ['EO', 'S', 'EI']
        case ActionOption.ACTION_J_APR:
        case ActionOption.ACTION_L_LRR:
          return ['S']
        case ActionOption.ACTION_A_CJ:
          return ['S', 'MP', 'PS', 'EO', 'PL', 'SB', 'U', 'MI']
        default:
          return []
      }
    else if (sacCode === 'LN')
      switch (actionJournal) {
        case ActionOption.ACTION_A_CNB:
        case ActionOption.ACTION_J_APR:
          return ['VP']
        case ActionOption.ACTION_A_CJ:
          return ['EI']
        default:
          return []
      }
    else if (sacCode && actionJournal === ActionOption.ACTION_A_CJ) {
      switch (sacCode) {
        case 'LX':
          return ['DM', 'EX', 'TF']
        case 'LI':
          return ['MO']
        case 'LF':
          return ['WF']
        default:
          return []
      }
    }
    return []
  }

  const listSACTypeReceiFilter = (index: number) => {
    const sacCode = form.watch(`journalPayable.journalsReceive.${index}.SACCode`)
    if (sacCode === 'LP')
      switch (actionJournal) {
        case ActionOption.ACTION_A_CNB:
          return ['U', 'S', 'PS']
        case ActionOption.ACTION_J_ET:
          return ['EO', 'S', 'EI']
        case ActionOption.ACTION_J_APR:
          return ['S', 'PT']
        case ActionOption.ACTION_A_CJ:
          return ['S']
        default:
          return []
      }
    else if (sacCode === 'LN')
      switch (actionJournal) {
        case ActionOption.ACTION_A_CNB:
        case ActionOption.ACTION_J_APR:
          return ['VP']
        case ActionOption.ACTION_L_LRR:
          return ['AO', 'AI', 'LO', 'LI']
        default:
          return []
      }
    return []
  }

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  React.useEffect(() => {
    if (
      isFocused &&
      form.watch('journalPayable.journalsTransfer').length === 0 &&
      form.watch('journalPayable.journalsReceive').length === 0
    ) {
      setDefaultValue()
    } else if (isFocused) {
      form.resetField('journalPayable')
      setDefaultValue()
    }
    checkActionSetPolicyNum()
    setChangeToInput(false)
  }, [isFocused])

  const checkShowReleaseNumber = () => {
    const journalTranferList = form.getValues('journalPayable.journalsTransfer')
    const filter =
      journalTranferList &&
      journalTranferList.filter(
        (i) => (i.SACCode === 'LP' && i.SACType === 'EO') || (i.SACCode === 'LN' && i.SACType === 'EI')
      )
    if (actionJournal === ActionOption.ACTION_A_CJ && filter && filter.length > 0) return true
    if (actionJournal === ActionOption.ACTION_J_ET) return true
    return false
  }

  const checkShowType = () => {
    if (actionJournal === ActionOption.ACTION_A_CJ) return true
    return false
  }

  const getCurrentBalance = (index: number, sacCode: string, sacType: string) => {
    pipe(
      JournalService.getCurrentBalanceData(policyNum, sacCode, sacType),
      ZIO.map((data) => {
        form.setValue(`journalPayable.journalsTransfer.${index}.currentBalance`, data.toString())
        form.setError(`journalPayable.journalsTransfer.${index}.currentBalance`, { message: '' })
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }

  const getCurrentBalanceReleaseNumber = (index: number, sacCode: string, sacType: string, releaseNumber: string) => {
    pipe(
      JournalService.getCurrentBalanceData(policyNum, sacCode, sacType, releaseNumber),
      ZIO.map((data) => {
        form.setValue(`journalPayable.journalsTransfer.${index}.currentBalance`, data.toString())
        form.setError(`journalPayable.journalsTransfer.${index}.currentBalance`, { message: '' })
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }

  const replaceAll = (str: string, from: string, to: string) => {
    return str.replace(new RegExp(from, 'g'), to)
  }

  const changeToAmount = (value: string) => {
    const checkLastValueNegative = value.indexOf('-')
    const valueRep = value !== '-' ? '-' + formatNumberWithComma(value.replace('-', '')) : value
    const lastValue = checkLastValueNegative !== -1 ? valueRep : formatNumberWithComma(value)
    return lastValue
  }

  const checkActionSetPolicyNum = () => {
    if (
      actionJournal === ActionOption.ACTION_J_APR ||
      actionJournal === ActionOption.ACTION_A_CNB ||
      actionJournal === ActionOption.ACTION_L_LRR ||
      actionJournal === ActionOption.ACTION_J_ET
    ) {
      form.setValue('journalPayable.receivePolicy', policyNum)
    } else {
      form.setValue('journalPayable.receivePolicy', '')
    }
  }

  const checkPolicyOwner = () => {
    const policyNumReceiver = form.getValues('journalPayable.receivePolicy')
    if (policyNumReceiver)
      pipe(
        PayoutService.getOwnerInfo(policyNumReceiver),
        ZIO.map((data) => {
          setPolicyOwner(data.ownerName)
        }),
        bindLoader,
        ZIO.unsafeRun({})
      )
  }

  return (
    <SafeAreaView style={style.container}>
      <Title wrapperStyle={style.noMarginBottom} title={t('Journal:PolicyTransfer')}></Title>
      <SectionContent sectionStyles={style.sectionContent}>
        <View style={style.row}>
          {checkShowReleaseNumber() && (
            <View style={style.col_3_first}>
              <Controller
                name="journalPayable.releaseNumber"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: t('message:MS020001', { field: t('Journal:ReleaseNumber') })
                  }
                }}
                render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                  <Input
                    disabled={disabled}
                    required
                    inputType="number"
                    title={t('Journal:ReleaseNumber')}
                    value={value || ''}
                    onBlur={() => {
                      onBlur()
                      const journalTranferList = form.getValues('journalPayable.journalsTransfer')
                      if (value && journalTranferList && actionJournal === ActionOption.ACTION_A_CJ) {
                        journalTranferList.map((item, index) => {
                          if (
                            (item.SACCode === 'LP' && item.SACType === 'EO') ||
                            (item.SACCode === 'LN' && item.SACType === 'EI')
                          ) {
                            getCurrentBalanceReleaseNumber(index, item.SACCode, item.SACType, value)
                          }
                        })
                      }
                    }}
                    onChange={onChange}
                    errorMessage={error?.message}
                  />
                )}
              />
            </View>
          )}
          {checkShowType() && (
            <View style={style.col_3}>
              <Controller
                name="journalPayable.type"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: t('message:MS020001', { field: t('Journal:JournalType') })
                  }
                }}
                render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                  <Select
                    disabled={disabled}
                    required
                    label={t('Journal:JournalType')}
                    options={typeList}
                    placeholder={t('common:Select')}
                    onChange={(e) => {
                      onChange(e)
                    }}
                    onBlur={onBlur}
                    value={value ?? undefined}
                    errorMessage={error?.message}
                  />
                )}
              />
            </View>
          )}
        </View>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow style={styleView.backGround}>
                <TableCell style={{ width: '23%' }}>{t('Journal:SacCode')}</TableCell>
                <TableCell style={{ width: '23%' }}>{t('Journal:SacType')}</TableCell>
                <TableCell style={{ width: '24%' }}>{t('Journal:CurrentBalance')}</TableCell>
                <TableCell style={{ width: '24%' }}>{t('Journal:TransferAmount')}</TableCell>
                <TableCell style={{ width: '6%' }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fieldTranfer.fields.map((fieldItem, index) => {
                return (
                  <TableRow key={`journalsTransfer_${fieldItem.id}`}>
                    <TableCell>
                      <Controller
                        key={`SACCode_${fieldItem.id}`}
                        control={form.control}
                        name={`journalPayable.journalsTransfer.${index}.SACCode`}
                        rules={{
                          required: {
                            value: true,
                            message: t('message:MS020001', { field: t('Journal:SacCode') })
                          }
                        }}
                        render={({ field: { value, onChange, onBlur }, fieldState: { error } }) =>
                          disabled ? (
                            <Text style={style.fontSize}>{value}</Text>
                          ) : (
                            <SelectBox
                              onChange={(value) => {
                                onChange(value)
                                const releaseNumber = form.watch(`journalPayable.releaseNumber`)
                                const sacType = form.watch(`journalPayable.journalsTransfer.${index}.SACType`)
                                if (actionJournal === ActionOption.ACTION_A_CJ) {
                                  if ((value === 'LP' && sacType === 'EO') || (value === 'LN' && sacType === 'EI')) {
                                    if (releaseNumber)
                                      getCurrentBalanceReleaseNumber(index, value, sacType, releaseNumber)
                                    else form.setValue(`journalPayable.journalsTransfer.${index}.currentBalance`, '')
                                  } else if (sacType && value) {
                                    getCurrentBalance(index, value, sacType)
                                    setChangeToInput(false)
                                  }
                                } else if (
                                  actionJournal === ActionOption.ACTION_J_APR &&
                                  value === 'LP' &&
                                  sacType === 'PT'
                                ) {
                                  form.setValue(`journalPayable.journalsTransfer.${index}.currentBalance`, '0')
                                  setChangeToInput(true)
                                } else if (sacType && value) {
                                  getCurrentBalance(index, value, sacType)
                                  setChangeToInput(false)
                                }
                              }}
                              onBlur={onBlur}
                              options={listSACCodeFilter(false)}
                              value={value}
                              errorMessage={error?.message}
                            />
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <Controller
                        key={`SACType_${fieldItem.id}`}
                        control={form.control}
                        name={`journalPayable.journalsTransfer.${index}.SACType`}
                        rules={{
                          required: {
                            value: true,
                            message: t('message:MS020001', { field: t('Journal:SacType') })
                          }
                        }}
                        render={({ field: { value, onChange, onBlur }, fieldState: { error } }) =>
                          disabled ? (
                            <Text style={style.fontSize}>{value}</Text>
                          ) : (
                            <SelectBox
                              onChange={(value) => {
                                onChange(value)
                                const sacCode = form.watch(`journalPayable.journalsTransfer.${index}.SACCode`)
                                const releaseNumber = form.watch(`journalPayable.releaseNumber`)
                                if (actionJournal === ActionOption.ACTION_A_CJ) {
                                  if ((sacCode === 'LP' && value === 'EO') || (sacCode === 'LN' && value === 'EI')) {
                                    if (releaseNumber)
                                      getCurrentBalanceReleaseNumber(index, sacCode, value, releaseNumber)
                                    else form.setValue(`journalPayable.journalsTransfer.${index}.currentBalance`, '')
                                  } else if (sacCode && value) {
                                    getCurrentBalance(index, sacCode, value)
                                    setChangeToInput(false)
                                  }
                                } else if (
                                  actionJournal === ActionOption.ACTION_J_APR &&
                                  sacCode === 'LP' &&
                                  value === 'PT'
                                ) {
                                  form.setValue(`journalPayable.journalsTransfer.${index}.currentBalance`, '0')
                                  setChangeToInput(true)
                                } else if (sacCode && value) {
                                  getCurrentBalance(index, sacCode, value)
                                  setChangeToInput(false)
                                }
                              }}
                              onBlur={onBlur}
                              options={listSACTypeFilter(index)}
                              value={value}
                              errorMessage={error?.message}
                            />
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <Controller
                        key={`currentBalance_${fieldItem.id}`}
                        control={form.control}
                        name={`journalPayable.journalsTransfer.${index}.currentBalance`}
                        rules={{
                          validate: (value) => {
                            const sacCode = form.getValues(`journalPayable.journalsTransfer.${index}.SACCode`)
                            const sacType = form.getValues(`journalPayable.journalsTransfer.${index}.SACType`)
                            const checkPositive = value.indexOf('-') === -1
                            if (checkPositive && value)
                              return `${t('message:MS050311', { saccode: sacCode, sactype: sacType })}`
                            return true
                          }
                        }}
                        render={({ field: { value, onChange, onBlur }, fieldState: { error } }) =>
                          !changeToInput || disabled ? (
                            <View>
                              <Text style={{ fontSize: 16, paddingBottom: 2 }}>
                                {value ? formatNumberWithComma(value) : ''}
                              </Text>
                              {!!error?.message ? (
                                <Text style={{ color: '#ED1B2C', fontSize: 11.25, paddingTop: 10 }}>
                                  {error.message}
                                </Text>
                              ) : null}
                            </View>
                          ) : (
                            <View>
                              <TextField
                                classes={classesTextField}
                                size={'small'}
                                variant={'outlined'}
                                style={styleView.tableCellAmount}
                                value={value ? changeToAmount(value) : ''}
                                onBlur={onBlur}
                                inputProps={{
                                  maxLength: 20
                                }}
                                onChange={(e) => {
                                  const amountChange = replaceAll(e.target.value, ',', '')
                                  if (amountChange.match(/^-?\d*$/) || !e.target.value) {
                                    onChange(amountChange)
                                  }
                                }}
                              ></TextField>
                              {!!error?.message ? (
                                <Text style={{ color: '#ED1B2C', fontSize: 11.25, paddingTop: 10 }}>
                                  {error.message}
                                </Text>
                              ) : null}
                            </View>
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <Controller
                        key={`tranferAmount_${fieldItem.id}`}
                        control={form.control}
                        name={`journalPayable.journalsTransfer.${index}.tranferAmount`}
                        rules={{
                          required: {
                            value: true,
                            message: t('message:MS100018')
                          },
                          validate: (value) => {
                            const currentBalance = form.getValues(
                              `journalPayable.journalsTransfer.${index}.currentBalance`
                            )
                            const valueRep = replaceAll(value, ',', '')
                            const checkPositive = value.indexOf('-') === -1
                            const valueAmount = value ? Number(valueRep.replace('-', '')) : 0
                            const valueBalance = currentBalance ? Number(currentBalance.replace('-', '')) : 0
                            if (checkPositive || valueAmount === 0 || valueAmount > valueBalance)
                              return `${t('message:MS100018')}`
                            return true
                          }
                        }}
                        render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                          <View>
                            <View style={{ flexDirection: 'row' }}>
                              {disabled ? (
                                <Text style={style.labelConfirm}>{changeToAmount(value)}</Text>
                              ) : (
                                <TextField
                                  classes={classesTextField}
                                  size={'small'}
                                  variant={'outlined'}
                                  style={styleView.tableCellAmount}
                                  value={value ? changeToAmount(value) : ''}
                                  onBlur={onBlur}
                                  inputProps={{
                                    maxLength: 20
                                  }}
                                  onChange={(e) => {
                                    const amount = replaceAll(value, ',', '')
                                    const amountChange = replaceAll(e.target.value, ',', '')
                                    if (amountChange.match(/^-?\d*$/) || !e.target.value) {
                                      const totalCurrent = form.watch('journalPayable.totalTransfer')
                                      const checkLastValueNegative = amount.indexOf('-')
                                      const lastValue =
                                        checkLastValueNegative !== -1
                                          ? 0 - Number(amount.replace('-', ''))
                                          : Number(amount)
                                      const checkNegative = amountChange.indexOf('-')
                                      const valueInput =
                                        checkNegative !== -1
                                          ? 0 - Number(amountChange.replace('-', ''))
                                          : Number(amountChange)
                                      form.setValue(
                                        'journalPayable.totalTransfer',
                                        totalCurrent - lastValue + valueInput
                                      )
                                      onChange(amountChange)
                                    }
                                  }}
                                ></TextField>
                              )}
                              <Text style={disabled ? style.fontSize : style.labelVND}>{'VND'}</Text>
                            </View>
                            {!!error?.message ? (
                              <Text style={{ color: '#ED1B2C', fontSize: 11.25 }}>{error.message}</Text>
                            ) : null}
                          </View>
                        )}
                      />
                    </TableCell>
                    <TableCell align="center">
                      {!disabled && (
                        <TouchableOpacity
                          style={{ alignItems: 'center' }}
                          onPress={() => {
                            const valueAmount = form.watch(`journalPayable.journalsTransfer.${index}.tranferAmount`)
                            const checkLastValueNegative = valueAmount.indexOf('-')
                            const lastValue =
                              checkLastValueNegative !== -1
                                ? 0 - Number(valueAmount.replace('-', ''))
                                : Number(valueAmount)
                            const totalCurrent = form.watch('journalPayable.totalTransfer')
                            form.setValue('journalPayable.totalTransfer', totalCurrent - lastValue)
                            fieldTranfer.remove(index)
                            setDisableAddRowTranfer(false)
                          }}
                        >
                          <assets.DeleteIcon />
                        </TouchableOpacity>
                      )}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
            <TableRow>
              <TableCell style={styleView.backGround}></TableCell>
              <TableCell align="center" style={styleView.backGround}>
                <Text style={{ fontWeight: 'bold' }}>{t('Journal:Total')}</Text>
              </TableCell>
              <TableCell style={styleView.backGround}></TableCell>
              <TableCell>
                <Text style={{ fontSize: 16 }}>
                  {formatNumberWithComma(form.watch('journalPayable.totalTransfer'))}
                </Text>
              </TableCell>
            </TableRow>
          </Table>
        </TableContainer>
        {!disabled && (
          <View style={{ alignItems: 'flex-end' }}>
            <TouchableOpacity
              style={disableAddRowTranfer ? style.btnDisabled : style.button}
              disabled={disableAddRowTranfer}
              onPress={() => {
                const rowNumbers = form.getValues('journalPayable.journalsTransfer').length
                if (rowNumbers >= 1) setDisableAddRowTranfer(true)
                fieldTranfer.append(defaultValueTranfer)
              }}
            >
              <Text style={disableAddRowTranfer ? style.btnTextDisabled : style.textButton}>{t('Journal:AddNew')}</Text>
            </TouchableOpacity>
          </View>
        )}
      </SectionContent>

      <Title wrapperStyle={style.noMarginBottom} title={t('Journal:ReceivingPolicy')}></Title>
      <SectionContent sectionStyles={style.sectionContent}>
        <View style={style.row}>
          <View style={style.col_3_first}>
            <Controller
              name="journalPayable.receivePolicy"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: t('message:MS020001', { field: t('Journal:Policy') })
                },
                validate: (value) => {
                  const actionField = form.getValues('action.value')
                  const checkActionField = actionField !== ActionOption.ACTION_A_CJ
                  if (checkActionField && value !== policyNum) return `${t('message:MS100015')}`
                  return true
                }
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input
                  required
                  disabled={disabled}
                  title={t('Journal:Policy')}
                  value={value || ''}
                  onChange={onChange}
                  onBlur={() => {
                    if(value.length === 8 && actionJournal === ActionOption.ACTION_A_CJ)
                      checkPolicyOwner()
                    else {
                      setPolicyOwner('')
                    }
                  }}
                  inputType={'number'}
                  maxLength={8}
                  //   placeholder={t('ServiceInquiry:EnterIDNumber')}
                  errorMessage={error?.message}
                />
              )}
            />
          </View>
          {policyOwner && (
            <View style={style.col_3}>
              <Text style={style.label}>{t('common:PolicyOwner')}</Text>
              <Text>{policyOwner}</Text>
            </View>
          )}
        </View>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow style={{ backgroundColor: '#E8E8E8' }}>
                <TableCell style={{ width: '23%' }}>{t('Journal:SacCode')}</TableCell>
                <TableCell style={{ width: '47%' }}>{t('Journal:SacType')}</TableCell>
                <TableCell style={{ width: '24%' }}>{t('Journal:Amount')}</TableCell>
                <TableCell style={{ width: '6%' }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fieldReceive.fields.map((fieldItem, index) => {
                return (
                  <TableRow key={`journalsReceive_${fieldItem.id}`}>
                    <TableCell>
                      <Controller
                        key={`SACCode_${fieldItem.id}`}
                        control={form.control}
                        name={`journalPayable.journalsReceive.${index}.SACCode`}
                        rules={{
                          required: {
                            value: true,
                            message: t('message:MS020001', { field: t('Journal:SacCode') })
                          }
                        }}
                        render={({ field: { value, onChange, onBlur }, fieldState: { error } }) =>
                          disabled ? (
                            <Text style={style.fontSize}>{value}</Text>
                          ) : (
                            <SelectBox
                              onChange={onChange}
                              onBlur={onBlur}
                              options={listSACCodeFilter(true)}
                              value={value}
                              errorMessage={error?.message}
                            />
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <Controller
                        key={`SACType_${fieldItem.id}`}
                        control={form.control}
                        name={`journalPayable.journalsReceive.${index}.SACType`}
                        rules={{
                          required: {
                            value: true,
                            message: t('message:MS020001', { field: t('Journal:SacType') })
                          }
                        }}
                        render={({ field: { value, onChange, onBlur }, fieldState: { error } }) =>
                          disabled ? (
                            <Text style={style.fontSize}>{value}</Text>
                          ) : (
                            <SelectBox
                              onChange={onChange}
                              onBlur={onBlur}
                              options={listSACTypeReceiFilter(index)}
                              value={value}
                              errorMessage={error?.message}
                            />
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <Controller
                        key={`amount_${fieldItem.id}`}
                        control={form.control}
                        name={`journalPayable.journalsReceive.${index}.amount`}
                        rules={{
                          required: {
                            value: true,
                            message: t('message:MS100004', { num: '0' })
                          },
                          validate: (value) => {
                            const valueRep = replaceAll(value, ',', '')
                            const valueAmount = value ? Number(valueRep.replace('-', '')) : 0
                            if (valueAmount === 0) return `${t('message:MS100004', { num: '0' })}`
                            return true
                          }
                        }}
                        render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                          <View>
                            <View style={{ flexDirection: 'row' }}>
                              {disabled ? (
                                <Text style={style.labelConfirm}>{formatNumberWithComma(value)}</Text>
                              ) : (
                                <TextField
                                  classes={classesTextField}
                                  size={'small'}
                                  variant={'outlined'}
                                  style={styleView.tableCellInput}
                                  value={value ? formatNumberWithComma(value) : ''}
                                  onBlur={onBlur}
                                  inputProps={{
                                    maxLength: 19
                                  }}
                                  onChange={(e) => {
                                    const amount = replaceAll(value, ',', '')
                                    const amountChange = replaceAll(e.target.value, ',', '')
                                    if (amountChange.match(/^\d*$/) || !e.target.value) {
                                      form.watch(`journalPayable.journalsReceive.${index}.amount`)
                                      const totalCurrent = form.watch('journalPayable.totalReceive')
                                      const lastValue = Number(amount)
                                      const valueInput = Number(amountChange)
                                      form.setValue(
                                        'journalPayable.totalReceive',
                                        totalCurrent - lastValue + valueInput
                                      )
                                      onChange(amountChange)
                                    }
                                  }}
                                ></TextField>
                              )}
                              <Text style={disabled ? style.fontSize : style.labelVND}>{'VND'}</Text>
                            </View>
                            {!!error?.message ? (
                              <Text style={{ color: '#ED1B2C', fontSize: 11.25 }}>{error.message}</Text>
                            ) : null}
                          </View>
                        )}
                      />
                    </TableCell>
                    <TableCell align="center">
                      {!disabled && (
                        <TouchableOpacity
                          style={{ alignItems: 'center' }}
                          onPress={() => {
                            const valueAmount = form.watch(`journalPayable.journalsReceive.${index}.amount`)
                            const totalCurrent = form.watch('journalPayable.totalReceive')
                            form.setValue('journalPayable.totalReceive', totalCurrent - Number(valueAmount))
                            fieldReceive.remove(index)
                            setDisableAddRowReceive(false)
                          }}
                        >
                          <assets.DeleteIcon />
                        </TouchableOpacity>
                      )}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
            <TableRow style={{ marginTop: 20 }}>
              <TableCell style={styleView.backGround}></TableCell>
              <TableCell align="center" style={{ paddingRight: '25%', backgroundColor: '#E8E8E8' }}>
                <Text style={{ fontWeight: 'bold' }}>{t('Journal:TotalReceive')}</Text>
              </TableCell>
              <TableCell>
                <Text style={{ fontSize: 16 }}>{formatNumberWithComma(form.watch('journalPayable.totalReceive'))}</Text>
              </TableCell>
              {/* <TableCell ></TableCell> */}
            </TableRow>
          </Table>
        </TableContainer>
        {!disabled && (
          <View style={{ alignItems: 'flex-end' }}>
            <TouchableOpacity
              style={disableAddRowReceive ? style.btnDisabled : style.button}
              disabled={disableAddRowReceive}
              onPress={() => {
                const rowNumbers = form.getValues('journalPayable.journalsReceive').length
                if (rowNumbers >= 1) setDisableAddRowReceive(true)
                fieldReceive.append(defaultValueReceive)
              }}
            >
              <Text style={disableAddRowReceive ? style.btnTextDisabled : style.textButton}>{t('Journal:AddNew')}</Text>
            </TouchableOpacity>
          </View>
        )}
      </SectionContent>
    </SafeAreaView>
  )
}

const style = StyleSheet.create({
  container: {
    marginTop: 15
  },
  noMarginBottom: {
    marginBottom: 0
  },
  sectionContent: {
    paddingTop: 20,
    paddingBottom: 20,
    marginBottom: 50,
    backgroundColor: '#fafafa',
    border: '1px solid #d3dce6',
    boxSizing: 'border-box',
    borderRadius: 8,
    marginTop: 15
  },
  sectionRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginRight: -15,
    marginLeft: -15
  },
  row: {
    flexDirection: 'row',
    paddingBottom: 30
  },
  col_3: {
    width: '100%',
    maxWidth: '33.3333333333%',
    paddingRight: 15,
    paddingLeft: 15
  },
  col_3_first: {
    width: '100%',
    maxWidth: '33.3333333333%',
    paddingRight: 15
  },
  button: {
    width: 130,
    marginLeft: 20,
    marginVertical: 20,
    justifyContent: 'center',
    backgroundColor: '#fff',
    borderRadius: 100,
    borderWidth: 1,
    borderColor: '#ED1B2E',
    minWidth: 121,
    height: 35,
    paddingHorizontal: 30
  },
  textButton: { textAlign: 'center', color: '#ED1B2E', fontWeight: 'bold' },
  btnTextDisabled: { textAlign: 'center', color: '#B0B0B0', fontWeight: 'bold' },
  btnDisabled: {
    marginLeft: 20,
    marginVertical: 20,
    justifyContent: 'center',
    backgroundColor: '#FFF',
    borderRadius: 100,
    borderWidth: 1,
    borderColor: '#B0B0B0',
    minWidth: 121,
    height: 35,
    paddingHorizontal: 30
  },
  labelVND: {
    paddingVertical: 10,
    paddingLeft: 20,
    fontSize: 16
  },
  labelConfirm: {
    fontSize: 16,
    width: '60%'
  },
  fontSize: {
    fontSize: 16
  },
  label: {
    fontSize: 15,
    lineHeight: 22,
    color: '#70777E',
    fontWeight: 'bold',
    paddingBottom: 10
  }
})

const styleView = {
  tableCellSelect: {
    width: '100%',
    borderRadius: '6px',
    paddingRight: 20
  },
  tableCellInput: {
    width: '100%'
  },
  tableCellAmount: {
    width: '80%'
  },
  backGround: {
    backgroundColor: '#E8E8E8'
  }
}
