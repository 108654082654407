import { pipe } from 'fp-ts/function'
import { ZIO } from '@mxt/zio'
import { POApi } from '../POApi'
import * as t from 'io-ts'
import { AuthService } from '../auth'
import { Maybe } from '@mxt/zio/codec'
export namespace CashOutService {
  export interface CashoutStatusInfo {
    caseId: string
    notes: string
    status: string
    createdBy: string
    updatedBy: string
  }

  export interface CashoutRemoveInfo {
    processInstanceId: string
    paymentNumber: string
    policyNumber: string
    cancelBy: string
    cancelDate: string
    comment: string
  }

  export const FileCashout = t.type({
    uuid: t.string,
    params: t.type({
      gaName: t.string,
      gaAddress: t.string,
      gaTaxCode: t.string,
      createdDate: t.string,
      payeeName: t.string,
      payeeID: t.string,
      paymentAmount: t.string,
      paymentAmountDescription: t.string,
      paymentService: t.string,
      policyNumber: t.string,
      policyOwnerName: t.string,
      authorizeDate: t.string,
      documents: t.array(t.string),
      bankCode: t.string,
      gaUser: t.string,
      paymentNumber: t.string,
      authorizeLetter: t.string
    }),
    pushCode: t.string,
    outputFileName: t.string,
    docTypeCode: t.string,
    transactionTypeWF: t.string,
    businessKey: t.string
  })

  export const GaInfoTypeC = t.type({
    officeCode: Maybe(t.string),
    gaCode: Maybe(t.string),
    officeDesc: Maybe(t.string),
    officeAddress: Maybe(t.string),
    agentNumber: Maybe(t.string),
    taxCode: Maybe(t.string)
  })

  export const CheckPrintInfo = t.type({
    createdBy: Maybe(t.string),
    createdDate: Maybe(t.string),
    clickNumber: Maybe(t.number),
    businessKey: Maybe(t.string),
    isLocked: Maybe(t.boolean),
    url: Maybe(t.string),
    name: Maybe(t.string)
  })

  export const CashoutTypeC = t.type({
    body: t.type({
      files: t.array(FileCashout)
    }),
    createdBy: t.string,
    transType: t.string,
    officeCode: t.string
  })

  export type FileCashout = t.TypeOf<typeof FileCashout>
  export type CashoutType = t.TypeOf<typeof CashoutTypeC>

  export const getGaInfo = (gaCode: string) =>
    pipe(
      POApi.get(`wf-api/agent/ga-info/${gaCode}`)(
        t.type({
          body: GaInfoTypeC,
          responseStatus: Maybe(
            t.type({
              code: t.number,
              message: t.string
            })
          )
        })
      ),
      ZIO.map((gaInfo) => {
        return gaInfo.body
      })
    )

  export const printCashout = (data: CashoutType) =>
    pipe(
      ZIO.zipPar(
        AuthService.token,
        AuthService.getLoginType,
        POApi.buildUrl(`document-generation/print-pv/create-file`)
      ),
      ZIO.flatMap(([token, loginType, url]) =>
        ZIO.fromPromise(() => {
          return fetch(url, {
            method: 'POST',
            headers: {
              'Cache-Control': 'no-cache',
              Pragma: 'no-cache',
              'Content-Type': 'application/json; charset=utf-8',
              Authorization: `Bearer ${token}`,
              'X-Authen-Vendor': loginType
            },
            body: JSON.stringify(data)
          })
        })
      )
    )

  export const checkPrintPv = (id: string) =>
    pipe(
      POApi.get(`document-generation/print-pv/get-info/${id}`)(
        t.type({
          body: CheckPrintInfo,
          responseStatus: Maybe(
            t.type({
              code: t.number,
              message: t.string
            })
          )
        })
      ),
      ZIO.map((checkInfo) => {
        return checkInfo.body
      })
    )

  export const getCashoutStatus = (caseId: string) =>
    pipe(
      POApi.get(`pulseops/api/v1/cashout-note/get`, { params: { caseId } })(
        t.type({
          body: Maybe(
            t.type({
              caseId: t.string,
              notes: Maybe(t.string),
              status: t.string,
              createdBy: t.string,
              updatedBy: Maybe(t.string),
              updatedDate: Maybe(t.string)
            })
          )
        })
      ),
      ZIO.map((cashoutInfo) => {
        return cashoutInfo.body
      })
    )

  export const updateCashoutStatus = (cashoutData: CashoutStatusInfo) =>
    pipe(POApi.post(`pulseops/api/v1/cashout-note/save`)(t.unknown)(cashoutData))

  export const updateCashoutAccountingStatus = (cashoutData: CashoutStatusInfo[]) =>
    pipe(POApi.post(`pulseops/api/v1/cashout-note/save-notes`)(t.unknown)({ notes: cashoutData }))
    
  export const removeCashoutCase = (cashoutData: CashoutRemoveInfo) =>
    pipe(POApi.post(`pulseops/api/v1/cash-out/unsuspend`)(t.unknown)(cashoutData))
}
