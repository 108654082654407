import React from 'react'
import { OBModalAction, OBModalPopup } from '../OBModalPopup'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { OBSharedStyles } from '../../ob-shared'
import { OBSectionCol, OBSectionRow } from '../OBSectionContent'
import { GeneralService, RadioButtonGroup, SelectOption, SelectSearch, TextInput, Toast, assets, useMobile } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import * as O from 'fp-ts/lib/Option'
import { OBCallProgramTypeCode, OBTaskQueryData, OBTaskService, OBTaskType, mapCallProgram } from '../../../ob-service'
import { Controller, useForm } from 'react-hook-form'
import { Color } from '@material-ui/lab'

export type OBUnsuspendPopupFormData = {
  option: string
  group: SelectOption | null
  user: SelectOption | null
  comment: string
}

export type OBUnsuspendPopupData = {
  option: string
  group: string
  user: string
  comment: string
  transactionCode: string
}

type OBTeamTaskUnsuspendPopupProps = {
  isVisible: boolean
  onCloseEvent?: () => void
  onHandleUnsuspendEvent?: (data: OBUnsuspendPopupData) => void
  selectedTaskList?: Map<string, OBTaskQueryData>
  setToastInternal?: (infoToast: { open: boolean, message: string, type?: Color }) => void
  toastInternal?: { open: boolean, message: string, type?: Color }
}

export const OBTeamTaskUnsuspendPopup = (props: OBTeamTaskUnsuspendPopupProps) => {
  const { t } = useTranslation()
  const { isWide } = useMobile()
  const [assignmentGroups, setAssignmentGroups] = React.useState<SelectOption[]>([])
  const [users, setUsers] = React.useState<SelectOption[]>([])
  const unSuspendPopupForm = useForm<OBUnsuspendPopupFormData>({
    defaultValues: {
      option: '1',
      group: null,
      user: null,
      comment: ''
    }
  })

  React.useEffect(() => {
    if (props.isVisible) {
      pipe(
        GeneralService.getUsersGroupsByTransaction(OBTaskType.Outbound),
        ZIO.tap((res) => {
          const filterGroupByCallProgram = res.filter((x) => {
            const filterGroupList = x.userGroup.split('-')
            return filterGroupList && !x.groupName.includes('Admin') && filterGroupList.length >= 3 ? filterGroupList[1] === transactionCode : false
          })
          filterGroupByCallProgram.length > 0 && setAssignmentGroups(
            filterGroupByCallProgram.map((x) => ({
              value: x.userGroup,
              label: x.groupName
            }))
          )
          return ZIO.unit
        }),
        // bindLoader,
        ZIO.unsafeRun({})
      )
    }
    return () => {
      unSuspendPopupForm.reset({
        option: '1',
        group: null,
        user: null,
        comment: ''
      })
      setAssignmentGroups([])
      setUsers([])
    }
  }, [props.isVisible])

  const { transactionCode, currAssignee } = React.useMemo(() => {
    let currAssignee, transactionCode = ''
    if (props.selectedTaskList && props.selectedTaskList.size > 0) {
      const selectedTask = pipe(props.selectedTaskList.get(props.selectedTaskList.keys().next().value),
        O.fromNullable,
        O.fold(
          () => ({} as OBTaskQueryData),
          (selectedItem) => selectedItem
        ))
      transactionCode = pipe(mapCallProgram.get(selectedTask.transactionType as OBCallProgramTypeCode), O.fromNullable, O.map((item) => item), O.getOrElse(() => ''))
      currAssignee = selectedTask.lastVerUpdate || ''
    }
    return {
      transactionCode,
      currAssignee: currAssignee
    }
  }, [props.selectedTaskList])

  const onChangeOptionEvent = (optionCode: string) => {
    setUsers([])
    unSuspendPopupForm.setValue(`group`, null)
    unSuspendPopupForm.setValue(`user`, null)
  }

  const onChangeGroupEvent = (groupItem: SelectOption | null) => {
    setUsers([])
    unSuspendPopupForm.setValue(`user`, null)
    if (groupItem) {
      pipe(
        OBTaskService.getUsersByGroups(
          [groupItem.value.split('OB-')?.[1] ?? ''],
          currAssignee || '',
          'OB',
          'SUSPENDED'
        ),
        ZIO.catchAll((_error) => ZIO.fail(null)),
        ZIO.tap((users) => {
          setUsers(users.map((item) => ({ label: item.name, value: item.email })))
          return ZIO.unit
        }),
        ZIO.unsafeRun({})
      )
    }
  }

  const isDisableModalButtons = () => {
    return unSuspendPopupForm.watch('option') === '1' && (!unSuspendPopupForm.watch('group') || !unSuspendPopupForm.watch('user'))
  }

  const onHandleUnsuspendEvent = () => {
    const formData =  unSuspendPopupForm.getValues()
    const unsuspendData: OBUnsuspendPopupData = {
      option: formData.option,
      group: formData.group?.value || '',
      user: formData.user?.value || '',
      comment: formData.comment || '',
      transactionCode: transactionCode
    }
    props.onHandleUnsuspendEvent && props.onHandleUnsuspendEvent(unsuspendData)
  }

  const modalButtons: OBModalAction[] = [
    {
      text: t('claim:Cancel'),
      onClickEvent: () => props.onCloseEvent && props.onCloseEvent(),
      actionStyle: [OBSharedStyles.actionContent, acceptStyles.actionLine],
      textStyle: OBSharedStyles.actionContentText
    },
    {
      text: t('claim:Unsuspend'),
      onClickEvent: () => onHandleUnsuspendEvent(),
      actionStyle: [OBSharedStyles.actionContent, isDisableModalButtons() ? OBSharedStyles.actionDisable : OBSharedStyles.actionHightLight],
      textStyle: isDisableModalButtons() ? OBSharedStyles.actionDisableText : OBSharedStyles.actionHightLightText,
      disabled: isDisableModalButtons()
    }
  ]
  const getModalBody = () => {
    return (
      <View style={{ flex: 1 }}>
        <OBSectionRow>
          <OBSectionCol style={OBSharedStyles.sectionCol12}>
            <Controller
              control={unSuspendPopupForm.control}
              name="option"
              render={(({ field: { value, onChange, onBlur } }) => (
                <RadioButtonGroup
                  required
                  disabled={false}
                  title={t(`Outbound:OBTaskManagement:Option`)}
                  style={{ flexDirection: 'column', width: '100%', padding: 0 }}
                  titleStyle={{ display: 'flex', marginRight: 24 }}
                  options={[
                    { id: '1', label: t(`Outbound:OBTaskManagement:SpecificUser`) },
                    { id: '2', label: t(`Outbound:OBTaskManagement:OPLastAssignee`) }
                  ]}
                  value={value}
                  // errorMessage={!isEmpty(error) ? error?.message : ''}
                  onChange={(val) => {
                    onChange(val)
                    onChangeOptionEvent(val)
                  }}
                  onBlur={onBlur}
                />
              ))}
            />
          </OBSectionCol>
        </OBSectionRow>
        {unSuspendPopupForm.watch('option') === '1' && (
          <OBSectionRow>
            <OBSectionCol style={OBSharedStyles.sectionCol6}>
              <Controller
                control={unSuspendPopupForm.control}
                name="group"
                render={(({ field: { value, onChange, onBlur } }) => (
                  <SelectSearch
                    value={value}
                    label={t('claim:Group')}
                    showPopupIcon={true}
                    required={true}
                    onBlur={onBlur}
                    popupIcon={<assets.ArrowDownDropdownIcon />}
                    options={assignmentGroups}
                    onChange={(val) => {
                      onChange(val)
                      onChangeGroupEvent(val)
                    }}
                  />
                ))}
              />

            </OBSectionCol>
            <OBSectionCol style={OBSharedStyles.sectionCol6}>
              <Controller
                control={unSuspendPopupForm.control}
                name="user"
                render={(({ field: { value, onChange, onBlur } }) => (
                  <SelectSearch
                    value={value}
                    label={t('claim:User')}
                    showPopupIcon={true}
                    required={true}
                    onBlur={onBlur}
                    popupIcon={<assets.ArrowDownDropdownIcon />}
                    options={users}
                    onChange={onChange}
                  />
                ))}
              />
            </OBSectionCol>
          </OBSectionRow>
        )}

        <OBSectionRow style={acceptStyles.secondLine}>
          <OBSectionCol style={OBSharedStyles.sectionCol12}>
            <Controller
              control={unSuspendPopupForm.control}
              name="comment"
              render={(({ field: { value, onChange, onBlur } }) => (
                <TextInput
                  title={t('claim:Comment')}
                  value={value}
                  onChange={onChange}
                  placeholder={'...'}
                  textInputProps={{
                    maxLength: 500,
                    multiline: true,
                    style: [
                      {
                        borderWidth: 1,
                        borderColor: '#D3DCE6',
                        borderRadius: 8,
                        padding: 16,
                        fontSize: 15,
                        lineHeight: 22,
                        color: '#000000'
                      },
                      { marginTop: 10, height: isWide ? 140 : 60 }
                    ],
                    placeholderTextColor: '#B0B0B0'
                  }}
                />
              ))}
            />
          </OBSectionCol>
        </OBSectionRow>
      </View>
    )
  }
  return (
    <OBModalPopup
      isVisible={props.isVisible}
      modalTitle={t('claim:Unsuspend')}
      modalBody={getModalBody()}
      onCloseEvent={() => props.onCloseEvent && props.onCloseEvent()}
      modalActions={modalButtons}
      modalContainerStyle={acceptStyles.modalContainerStyle}
      modalContentStyle={acceptStyles.modalContentStyle}
      toastComponent={
        <Toast
          message={props.toastInternal?.message ?? ''}
          visible={props.toastInternal?.open ?? false}
          type={props.toastInternal?.type}
          onClose={() => props.setToastInternal && props.setToastInternal({ open: false, message: '', type: undefined })}
        />
      }
    >
    </OBModalPopup>
  )
}

const acceptStyles = StyleSheet.create({
  actionLine: {
    marginRight: 15
  },
  modalContainerStyle: {
    width: 550
  },
  modalContentStyle: {
    marginVertical: 25,
    justifyContent: 'flex-start'
  },
  secondLine: {
    marginTop: 20
  }
})