import { pipe } from 'fp-ts/function'
import * as t from 'io-ts'
import { Maybe, Nullable } from '@mxt/zio/codec'
import { Task, ZIO } from '@mxt/zio'
import { QueryTask } from './model'
import * as O from 'fp-ts/Option'
import { AuthService } from '../auth'

import {
  Customer,
  LoanEnquery,
  LoanModel,
  PolicyHeader,
  PolicyS,
  RequestAuthFormDataSubmit,
  SearchPolicy,
  SourceType,
  PolicyInquiry,
  PolicyDigital,
  NewOwnerInfo,
  NewOwnerInfoResponseData
} from './model'
import { POApi } from '../POApi'
import moment from 'moment'
import _ from 'lodash'
import { StorageBlob } from '../storage-blob'

export namespace SubmissionService {
  export const PolicyTypeC = t.type({
    anniDate: Maybe(t.string),
    salaryIncome: Maybe(t.number),
    basicCode: Maybe(t.string), // Coverage Code
    lastIssueDate: Maybe(t.string),
    paidToDateAdvance: Maybe(t.string),
    paidToDateBasic: Maybe(t.string),
    paidToDateRider: Maybe(t.string),
    billToDate: Maybe(t.string),
    billFreq: Maybe(t.string),
    mainLifeAssuredNumber: Maybe(t.string), // Client Id
    productCode: Maybe(t.string), // Coverage Type
    productType: Maybe(t.string),
    clientDespatchAddress: Maybe(t.string),
    policyNo: Maybe(t.string),
    totalPremium: Maybe(t.number), // Installment Premium
    contractDate: Maybe(t.string), // Risk Command Date
    dispatchMethod: Maybe(t.string),
    firstIssueDate: Maybe(t.string),
    status: Maybe(t.string), // Contract Status
    premiumStatus: Maybe(t.string),
    riskCommenceDate: Maybe(t.string),
    lapsedDate: Maybe(t.string),
    surrenderDate: Maybe(t.string),
    proposal: Maybe(
      t.type({
        proposalNo: Maybe(t.string),
        proposalReceivedDate: Maybe(t.string)
      })
    ),
    owners: t.type({
      id: Nullable(t.string) // Owner Id
    }),
    servingAgents: Maybe(
      t.type({
        agent: Maybe(
          t.type({
            code: Maybe(t.string)
          })
        )
      })
    ),
    attributesExt: Maybe(
      t.type({
        basicPremium: Maybe(t.number),
        riderPremium: Maybe(t.number)
      })
    )
  })

  export type PolicyType = t.TypeOf<typeof PolicyTypeC>

  export const ClientC = t.type({
    classCode: Maybe(t.string),
    clientId: Maybe(t.string),
    email: Maybe(t.string),
    mobilePhone: Maybe(t.string),
    countryCode: Maybe(t.string),
    location: Maybe(t.string),
    otpConsentStatus: Maybe(t.string),
    customerSmsInd: Maybe(t.string),
    customerEmailInd: Maybe(t.string),
    agentSmsInd: Maybe(t.string),
    updateNewPruInfo: Maybe(t.string),
    name: Maybe(t.string),
    firstName: t.string,
    surName: Maybe(t.string),
    dob: Maybe(t.string),
    dod: Maybe(t.string),
    sex: Maybe(t.string),
    nationality: Maybe(t.string),
    occupation: Maybe(t.string),
    externalIds: Maybe(
      t.type({
        SOE: Maybe(t.string),
        SOE_VALUE: Maybe(t.string)
      })
    ),
    addressDetails: Maybe(
      t.type({
        PRIMARY: t.type({
          line1: Maybe(t.string), // street
          line2: Maybe(t.string), // ward
          line3: Maybe(t.string), // district
          line4: Maybe(t.string) // city
        })
      })
    ),
    attributesExt: t.type({
      MOBILE_CODE: t.string,
      FATCA_TAX_DECLARE: t.string,
      COMPANY_CITY: Maybe(t.string),
      COMPANY_DISTRICT: Maybe(t.string),
      COMPANY_WARD: Maybe(t.string),
      FOREIGN_CITY: Maybe(t.string),
      FOREIGN_COUNTRY: Maybe(t.string),
      FOREIGN_DISTRICT: Maybe(t.string),
      FOREIGN_STREET: Maybe(t.string),
      FOREIGN_WARD: Maybe(t.string),
      NATIONALITY_2: Maybe(t.string)
    })
  })
  export type ClientQuery = t.TypeOf<typeof ClientC>

  export const ClientExtInfo = t.intersection([
    t.type({
      relationshipCode: t.string
    }),
    ClientC
  ])
  export type ClientExtInfo = t.TypeOf<typeof ClientExtInfo>

  export const ClientInfo = t.type({
    clientCode: t.string,
    clientName: Maybe(t.string),
    idNum: Maybe(t.string),
    email: Maybe(t.string),
    birthDate: Maybe(t.string),
    gender: Maybe(t.string),
    clientPhoneNumber: Maybe(t.string),
    dod: Maybe(t.string),
    addressStreet: Maybe(t.string),
    addressWard: Maybe(t.string),
    addressDistrict: Maybe(t.string),
    addressCity: Maybe(t.string),
    addressProvince: Maybe(t.string),
    nationality: Maybe(t.string),
    companyPhoneNumber: Maybe(t.string)
  })

  export type ClientInfo = t.TypeOf<typeof ClientInfo>

  export const lAClient = t.type({
    clientNumberOfLA: t.string,
    life: t.string
  })

  export type lAClient = t.TypeOf<typeof lAClient>

  export const benClient = t.type({
    clientNumberOfBen: t.string,
    percent: t.number,
    relationShipCode: t.string
  })

  export type benClient = t.TypeOf<typeof benClient>

  export const AgentTeamC = t.type({
    name: t.string,
    clientId: Maybe(t.string),
    type: t.union([
      t.literal('servicing'),
      t.literal('sharing'),
      t.literal('unit manager'),
      t.literal('branch manager'),
      t.literal('business development manager'),
      t.literal('old')
    ]),
    contactDetails: Maybe(
      t.type({
        mobile: t.type({
          value: t.string,
          countryCode: t.string
        })
      })
    ),
    code: Maybe(t.string),
    appointmentDate: Maybe(t.string),
    terminationDate: Maybe(t.string)
  })

  export type AgentTeam = t.TypeOf<typeof AgentTeamC>

  export const AssigneeType = t.type({
    prefix: Maybe(t.string),
    id: Maybe(t.string),
    status: t.boolean,
    email: Maybe(t.string),
    createdDate: Maybe(t.number),
    createdBy: Maybe(t.string)
  })

  export type AssigneeType = t.TypeOf<typeof AssigneeType>

  export const AgentInfoC = t.type({
    attributes: t.type({
      officeName: t.string,
      saleUnitCode: t.string,
      gAName: t.string,
      gACode: t.string,
      regionCode: t.string
    }),
    office: t.string,
    teams: t.array(AgentTeamC)
  })

  export type AgentInfoC = t.TypeOf<typeof AgentInfoC>

  export const InputAgent = t.type({
    source: t.string,
    primaryPolicyNo: t.string,
    body: t.type({
      transactionType: t.string,
      optionFlag: t.boolean,
      agent: t.type({
        agentCode: t.string
      }),
      createdBy: Maybe(t.string),
      createdDate: Maybe(t.string)
    })
  })
  export type InputAgent = t.TypeOf<typeof InputAgent>

  export const searchAgent = (data: InputAgent) =>
    pipe(
      POApi.post(`distribution-agents-rest/distribution-service/registerFromPulseOp`)(QueryTask.Response)(data),
      ZIO.map((res) => {
        return res?.data
      })
    )

  export const searchPolicy = (policyNum: string): Task<SearchPolicy[]> =>
    pipe(
      POApi.get(`wf-api/policy/${policyNum}`)(
        t.type({
          body: t.type({
            owners: t.type({
              id: Nullable(t.string)
            }),
            proposal: t.type({
              proposalNo: Nullable(t.string)
            })
          })
        })
      ),
      ZIO.flatMap((res) =>
        pipe(
          res.body.owners.id,
          O.fromNullable,
          O.filter((id) => id.length > 0),
          O.fold(
            () => ZIO.succeed([]),
            (clientId) =>
              pipe(
                getClientsByPolicy(policyNum),
                ZIO.flatMap((cl) =>
                  cl.laList.length
                    ? getClients([{ clientId }].concat(cl.laList.map((l) => ({ clientId: l.clientNumberOfLA }))))
                    : getClients([{ clientId }])
                ),
                ZIO.map((client): SearchPolicy[] => [
                  {
                    policyNum,
                    idNum: client.body[0]?.externalIds?.SOE_VALUE ?? '-',
                    clientName: client.body[0]?.name ?? '-',
                    laName: client.body.length < 2 ? '-' : client.body[1].name ?? '-',
                    primaryId: clientId,
                    proposalNumber: res.body.proposal.proposalNo || ''
                  }
                ])
              )
          )
        )
      )
    )

  export const searchPolicyDA = (policyNum: string): Task<SearchPolicy[]> =>
    pipe(
      POApi.get(`wf-api/policy/${policyNum}`)(
        t.type({
          body: t.type({
            owners: t.type({
              id: Nullable(t.string)
            }),
            status: Maybe(t.string)
          })
        })
      ),
      ZIO.flatMap((res1) =>
        pipe(
          res1.body.owners.id,
          O.fromNullable,
          O.filter((id) => id.length > 0),
          O.fold(
            () => ZIO.succeed([]),
            (clientId) =>
              pipe(
                getClientsByPolicy(policyNum),
                ZIO.flatMap((cl) =>
                  cl.laList.length
                    ? getClients([{ clientId }].concat(cl.laList.map((l) => ({ clientId: l.clientNumberOfLA }))))
                    : getClients([{ clientId }])
                ),
                ZIO.map((res): SearchPolicy[] => [
                  {
                    policyNum,
                    idNum: res.body[0]?.externalIds?.SOE_VALUE ?? '-',
                    clientName: res.body[0]?.name ?? '-',
                    laName: res.body.length < 2 ? '-' : res.body[1].name ?? '-',
                    primaryId: clientId,
                    status: res1.body.status ?? ''
                  }
                ])
              )
          )
        )
      )
    )

  export const searchPolicyInquiry = (policyNum: string): Task<SearchPolicy[]> =>
    pipe(
      POApi.get(`wf-api/policy/digital/${policyNum}`)(
        t.type({
          body: PolicyInquiry
        })
      ),
      ZIO.map((res) => {
        if (Object.keys(res.body).length === 0 || Object.values(res.body).every((item) => item === undefined)) {
          return []
        }
        const owner = res?.body?.customerRoles?.find((x) => x.role === 'Owner')
        const la = res.body?.lifeAssured && res.body?.lifeAssured[0]
        return [
          {
            policyNum,
            idNum: owner?.customer.externalIds.NATIONAL_ID || '-',
            clientName: owner ? `${owner.customer.surName} ${owner.customer.firstName}` : '-',
            laName: res.body?.lifeAssured && res.body?.lifeAssured[0] ? `${la?.surName} ${la?.firstName}` : '-',
            sourceOfBusiness: res.body.sourceOfBusiness,
            primaryId: ''
          }
        ]
      })
    )

  export const searchIDNumber = (idNumber: string): Task<SearchPolicy[]> =>
    pipe(
      POApi.post(`wf-api/policy/search`)(
        t.type({
          body: t.array(PolicyTypeC)
        })
      )({
        body: {
          idNumber: idNumber
        }
      }),
      ZIO.flatMap((res) => {
        const policyList = res.body
        if (policyList.length === 0) {
          return ZIO.succeed([])
        }
        return pipe(
          ZIO.succeed(policyList),
          ZIO.map((policyList) => {
            const clientIDSet = new Set()
            policyList.forEach((item) => {
              clientIDSet.add(item.owners.id)
              clientIDSet.add(item.mainLifeAssuredNumber)
            })
            return clientIDSet
          }),
          ZIO.flatMap((clientIDSet) => {
            const clientIDArray: { clientId: any }[] = []
            clientIDSet.forEach((item) => {
              clientIDArray.push({ clientId: item })
            })
            return getClients([...clientIDArray])
          }),
          ZIO.map((resGetClients) => {
            const searchPolicyList: SearchPolicy[] = policyList.map((item) => {
              let clientArray = resGetClients.body
              let clientName: string = '_'
              let laName: string = '-'
              let searchingClientIDPolicyIndex = clientArray.findIndex((client) => client.clientId === item.owners.id)
              if (searchingClientIDPolicyIndex !== -1) {
                clientName = clientArray[searchingClientIDPolicyIndex]?.name ?? '-'
              }

              if (item.owners.id === item.mainLifeAssuredNumber && searchingClientIDPolicyIndex !== -1) {
                laName = clientArray[searchingClientIDPolicyIndex]?.name ?? '-'
              } else {
                let searchingMainLifeAssurePolicyIndex = clientArray.findIndex(
                  (client) => client.clientId === item.mainLifeAssuredNumber
                )
                if (searchingMainLifeAssurePolicyIndex !== -1) {
                  laName = clientArray[searchingMainLifeAssurePolicyIndex]?.name ?? '-'
                }
              }

              return {
                policyNum: item.policyNo ?? '',
                idNum: idNumber,
                clientName,
                laName,
                primaryId: item.owners.id ?? ''
              }
            })

            return searchPolicyList
          })
        )
      })
    )

  export const getPolicyInquiry = (policyNum: string) =>
    pipe(
      POApi.get(`wf-api/policy/digital/${policyNum}`)(
        t.type({
          body: PolicyInquiry
        })
      ),
      ZIO.map((p) => p.body)
    )

  export const getPolicyDigitalPVA = (policyNum: string) =>
    pipe(
      POApi.get(`wf-api/digital-inquiry/digital/policy/${policyNum}`)(
        t.type({
          body: PolicyDigital
        })
      ),
      ZIO.map((p) => p.body)
    )

  export const PolicyHeaderC = t.type({
    body: t.type({
      clientId: t.string,
      dob: Maybe(t.string),
      firstName: Maybe(t.string),
      id: Maybe(t.string),
      nationality: Maybe(t.string),
      sex: Maybe(t.string),
      surName: Maybe(t.string)
    })
  })

  export type PolicyHeaderData = t.TypeOf<typeof PolicyHeaderC>

  export const WarningMessage = t.type({
    id: Maybe(t.string),
    policyNum: t.string,
    warningDate: t.string,
    userId: t.string,
    shortDesc: t.string,
    detailDesc: t.string
  })
  export type WarningMessage = t.TypeOf<typeof WarningMessage>

  export const getPolicyHeader = (policyNum: string): Task<PolicyHeader> =>
    pipe(
      getOwnerInfo(policyNum),
      ZIO.map(
        (customer): PolicyHeader => ({
          policyNum: policyNum,
          ownerName: customer?.body?.name || '-',
          officeAddress: customer?.body?.location,
          clientId: customer?.body?.clientId || '-'
        })
      )
    )

  export const getPolicy = (policyNum: string) =>
    pipe(
      POApi.get(`wf-api/policy/${policyNum}`)(
        t.type({
          body: PolicyTypeC
        })
      ),
      ZIO.map((policy) => policy)
    )

  export const getPolicies = (
    policyNo: Array<{
      policyNo: string
      function?: string
    }>
  ): Task<Array<PolicyS>> =>
    pipe(
      POApi.post(`wf-api/policy/policies`)(
        t.type({
          body: t.array(PolicyTypeC)
        })
      )({
        body: policyNo
      }),
      ZIO.map((policies): Array<PolicyS> => {
        return policies.body.map((policy) => ({
          salaryIncome: policy.salaryIncome ?? 0,
          lastIssueDate: policy.lastIssueDate ?? '',
          paidToDateAdvance: policy.paidToDateAdvance ?? '',
          paidToDateBasic: policy.paidToDateBasic ?? '',
          contractStatus: policy.status ?? '',
          contractDate: policy.contractDate ?? '',
          productCode: policy.productCode ?? '',
          status: policy.status ?? '',
          productType: policy.productType ?? '',
          clientDespatchAddress: policy.clientDespatchAddress ?? '',
          policyNo: policy.policyNo ?? '',
          totalPremium: policy.totalPremium ?? 0,
          ownerId: policy.owners.id ?? '',
          mainLifeAssuredNumber: policy.mainLifeAssuredNumber ?? '',
          billFreq: policy.billFreq ?? '',
          premiumStatus: policy.premiumStatus ?? '',
          proposalReceivedDate: policy.proposal?.proposalReceivedDate || '',
          attributesExt: policy.attributesExt
            ? {
                basicPremium: policy.attributesExt.basicPremium,
                riderPremium: policy.attributesExt.riderPremium
              }
            : undefined
        }))
      })
    )

  export const getClients = (clientIds: Array<{ clientId: string }>) =>
    pipe(
      POApi.post(`wf-api/customer/customers`)(
        t.type({
          body: t.array(ClientC)
        })
      )({
        body: {
          clients: clientIds
        }
      }),
      ZIO.map((clients) => clients)
    )

  export const getAgents = (clientIds: string[]): Task<ClientQuery[]> =>
    pipe(
      POApi.post(`wf-api/customer/customers`)(
        t.type({
          body: t.array(ClientC)
        })
      )({
        body: {
          clients: clientIds.map((code) => ({
            agent: {
              code
            }
          }))
        }
      }),
      ZIO.map((clients) => clients.body)
    )

  export const getOwnerInfo = (policyNum: string, isClaim?: boolean | null): Task<Customer> => {
    return !isClaim
      ? pipe(
          getPolicy(policyNum),
          ZIO.flatMap((policy) =>
            pipe(
              policy?.body?.owners.id,
              O.fromNullable,
              O.filter((id) => id.length > 0),
              O.fold(
                () => ZIO.succeed(<Customer>{}),
                (clientId) => getCustomer(clientId)
              )
            )
          )
        )
      : pipe(
          getPolicyInquiry(policyNum),
          ZIO.flatMap((policy) =>
            ZIO.succeed(<Customer>{ body: { clientId: policy?.customerRoles?.[0].customer.externalIds.NATIONAL_ID } })
          )
        )
  }

  export const getOwnerInfoVoice = (policyNum: string) => {
    return pipe(
      getPolicy(policyNum),
      ZIO.flatMap((policy) =>
        pipe(
          policy?.body?.owners.id,
          O.fromNullable,
          O.filter((id) => id.length > 0),
          O.fold(
            () => ZIO.succeed(<Customer>{}),
            (clientId) => getCustomer(clientId)
          ),
          ZIO.map((customerInfo)=> {
            return {
              ...customerInfo,
              status: policy.body.status
            }
          })
        )
      )
    )
  }
  export const DAgetOwnerInfo = (policyNum: string): Task<Customer> => {
    return pipe(
      getDetail(policyNum),
      ZIO.flatMap((policy) =>
        pipe(
          policy?.clientNum,
          O.fromNullable,
          O.filter((id) => id.length > 0),
          O.fold(
            () => ZIO.succeed(<Customer>{}),
            (clientId) => getCustomer(clientId)
          )
        )
      )
    )
  }

  export const getOwnerInfoTransferDA = (policyNum: string, transferAgent?: string): Task<Customer> => {
    return pipe(
      getDetailTransfer(policyNum, transferAgent),
      ZIO.flatMap((policy) =>
        pipe(
          policy?.transferAgent?.clientNum,
          O.fromNullable,
          O.filter((id) => id.length > 0),
          O.fold(
            () => ZIO.succeed(<Customer>{}),
            (clientId) => getCustomer(clientId)
          )
        )
      )
    )
  }

  export const getOwnerInfoDigital = (
    policyNum: string
  ): Task<{
    fullname: string
    nationalID: string
    dob?: string
  }> =>
    pipe(
      POApi.get(`wf-api/digital-inquiry/policy/${policyNum}`)(
        t.type({
          customerInfo: t.type({
            fullName: t.string,
            nationalID: t.string,
            dob: t.string
          })
        })
      ),
      ZIO.map((res) => ({
        fullname: res.customerInfo.fullName,
        nationalID: res.customerInfo.nationalID,
        dob: res.customerInfo.dob || ''
      }))
    )

  export const getOwnerInfoExt = (policyNum: string): Task<Customer> =>
    pipe(
      getPolicy(policyNum),
      ZIO.flatMap((policy) =>
        pipe(
          policy?.body?.owners.id,
          O.fromNullable,
          O.filter((id) => id.length > 0),
          O.fold(
            () => ZIO.succeed(<Customer>{}),
            (clientId) => getCustomer(clientId)
          )
        )
      )
    )

  export const getCustomer = (clientId: string): Task<Customer> => POApi.get(`wf-api/customer/${clientId}`)(Customer)

  export const getNewOwnerInfo = (policyNum: string): Task<NewOwnerInfo> =>
    pipe(
      POApi.get(`wf-api/policy/${policyNum}/owner`)(NewOwnerInfoResponseData),
      ZIO.map((responseData) => {
        return responseData.body
      })
    )

  export const getLoanEnquiry = (policyNum: string): Task<LoanEnquery> =>
    pipe(
      POApi.post(`wf-api/policy/billing-change-apl/loan-enquiry`)(
        t.type({
          body: t.type({
            action: Maybe(t.string),
            contractType: Maybe(t.string),
            subfileOccurs: Maybe(
              t.array(
                t.type({
                  accuredInterest: Maybe(t.number),
                  pendingInterest: Maybe(t.number),
                  currentBalance: Maybe(t.number),
                  loanNumber: Maybe(t.number),
                  loanStartDate: Maybe(t.string),
                  loanType: Maybe(t.string),
                  principalAmount: Maybe(t.number),
                  policyLoanTotal: Maybe(t.number)
                })
              )
            )
          })
        })
      )({
        body: {
          contractNumber: policyNum,
          effectiveDate: moment(new Date()).format('yyyyMMDD')
        }
      }),
      ZIO.catchAll(() => ZIO.succeed(null)),
      ZIO.map(
        (loan): LoanEnquery => ({
          action: loan?.body.action ?? '',
          contractType: loan?.body.contractType ?? '',
          subfileOccurs:
            loan?.body.subfileOccurs?.map((e) => ({
              accuredInterest: e.accuredInterest ?? 0,
              pendingInterest: e.pendingInterest ?? 0,
              currentBalance: e.currentBalance ?? 0,
              loanNumber: e.loanNumber ?? 0,
              loanStartDate: e.loanStartDate ?? '',
              loanType: e.loanType ?? '',
              principalAmount: e.principalAmount ?? 0,
              policyLoanTotal: e.policyLoanTotal ?? 0
            })) ?? []
        })
      )
    )

  // export const getClient = (clientId: string) => pipe(
  //   POApi.get(`wf-api/client/${clientId}`, ClientInfo),
  // )

  export const getClientIDandNum = (
    clientId: string
  ): Task<{
    clientName: string
    idNum: string
  }> =>
    pipe(
      getCustomer(clientId),
      ZIO.map((x) => ({
        clientName: x.body.name,
        idNum: x.body.clientId
      }))
    )

  export const checkDuplicatePhone = (phoneNumber: string) =>
    pipe(
      POApi.post(`wf-api/customer/policies`)(
        t.type({
          body: t.array(
            t.type({
              clientNum: t.string,
              policyNo: t.string,
              role: t.string
            })
          )
        })
      )({
        body: {
          securityNo: `CHECK PHONE`,
          surName: phoneNumber
        }
      }),
      ZIO.catchAll(() => ZIO.succeed(null)),
      ZIO.map((res) => res)
    )

  export const checkDuplicateEmail = (email: string) =>
    pipe(
      POApi.post(`wf-api/customer/policies`)(
        t.type({
          body: t.array(
            t.type({
              clientNum: t.string,
              policyNo: t.string,
              role: t.string
            })
          )
        })
      )({
        body: {
          securityNo: `CHECK EMAIL`,
          surName: email
        }
      }),
      ZIO.catchAll(() => ZIO.succeed(null)),
      ZIO.map((res) => res)
    )

  const getInterestAPL = (policyNum: string) =>
    pipe(
      POApi.post(`wf-api/policy/account-balance`)(
        t.type({
          body: Maybe(
            t.type({
              accountGroups: Maybe(
                t.array(
                  t.type({
                    amount: Maybe(t.number),
                    sacsCode: Maybe(t.string),
                    sacsType: Maybe(t.string)
                  })
                )
              )
            })
          )
        })
      )({
        body: {
          policyNum,
          function: 'T542',
          accountGroups: [
            {
              sacsCode: 'LN',
              sacsType: 'AI'
            }
          ]
        }
      })
    )
  export const getAccountBalance = (
    policyNum: string,
    functionType: string,
    accountGroups: { sacsCode: string; sacsType: string }[]
  ) =>
    pipe(
      POApi.post(`wf-api/policy/account-balance`)(
        t.type({
          body: t.type({
            accountGroups: Maybe(
              t.array(
                t.type({
                  amount: t.number,
                  sacsCode: t.string,
                  sacsType: t.string
                })
              )
            )
          })
        })
      )({
        body: {
          policyNum,
          function: functionType,
          accountGroups
        }
      })
    )

  type GetTraditionalPartSurrender =
    | {
        hasError: true
        errorCode: string
      }
    | {
        hasError: false
        policyLoan: number
        sumAssured: number
        surrenderAmount: number
      }

  export const getTraditionalPartSurrender = (
    policyNum: string,
    newASumAssured: number,
    adjustmentAmount: number,
    feature: 'enquiry' | 'INITIALIZE'
  ): Task<GetTraditionalPartSurrender> =>
    pipe(
      POApi.post(`wf-api/bo/traditional-part-surrender`)(
        t.type({
          body: t.type({
            policyLoan: t.number,
            sumAssured: t.number,
            surrenderAmount: t.number
          })
        })
      )({
        body: {
          contractNumber: policyNum,
          function: feature,
          newASumAssured: newASumAssured,
          AdjustmentAmount: adjustmentAmount
        }
      }),
      ZIO.map((_) => ({
        hasError: false as const,
        ..._.body
      })),
      ZIO.catchAll((e): Task<GetTraditionalPartSurrender> => {
        if (feature !== 'INITIALIZE') {
          const errorCode = _.get(e, 'source.input.body.oErrorResult.errorCode')

          if (typeof errorCode === 'string') {
            return ZIO.succeed({
              hasError: true,
              errorCode: errorCode
            })
          }
        }

        return ZIO.succeed({
          hasError: false,
          policyLoan: 0,
          sumAssured: 0,
          surrenderAmount: 0
        })
      })
    )

  const getInterestOPL = (policyNum: string) =>
    pipe(
      POApi.post(`wf-api/policy/account-balance`)(
        t.type({
          body: Maybe(
            t.type({
              accountGroups: Maybe(
                t.array(
                  t.type({
                    amount: Maybe(t.number),
                    sacsCode: Maybe(t.string),
                    sacsType: Maybe(t.string)
                  })
                )
              )
            })
          )
        })
      )({
        body: {
          policyNum,
          function: 'T542',
          accountGroups: [
            {
              sacsCode: 'LN',
              sacsType: 'LI'
            }
          ]
        }
      })
    )

  const getBalanceAPL = (policyNum: string) =>
    pipe(
      POApi.post(`wf-api/policy/account-balance`)(
        t.type({
          body: Maybe(
            t.type({
              accountGroups: Maybe(
                t.array(
                  t.type({
                    amount: Maybe(t.number),
                    sacsCode: Maybe(t.string),
                    sacsType: Maybe(t.string)
                  })
                )
              )
            })
          )
        })
      )({
        body: {
          policyNum,
          function: 'T542',
          accountGroups: [
            {
              sacsCode: 'LN',
              sacsType: 'AO'
            }
          ]
        }
      })
    )

  const getBalanceOPL = (policyNum: string) =>
    pipe(
      POApi.post(`wf-api/policy/account-balance`)(
        t.type({
          body: Maybe(
            t.type({
              accountGroups: Maybe(
                t.array(
                  t.type({
                    amount: Maybe(t.number),
                    sacsCode: Maybe(t.string),
                    sacsType: Maybe(t.string)
                  })
                )
              )
            })
          )
        })
      )({
        body: {
          policyNum,
          function: 'T542',
          accountGroups: [
            {
              sacsCode: 'LN',
              sacsType: 'LO'
            }
          ]
        }
      })
    )

  export const getPolicyLoanInfoMaturity = (policyNum: string) =>
    pipe(
      ZIO.zipPar(
        getInterestAPL(policyNum),
        getInterestOPL(policyNum),
        getBalanceAPL(policyNum),
        getBalanceOPL(policyNum)
      ),
      ZIO.map(([interestAPLData, interestOPLData, balanceAPLData, balanceOPLData]): LoanModel.LoanInfo => {
        const interestAPL =
          (interestAPLData.body &&
            interestAPLData.body.accountGroups?.reduce((total, e) => total + (e.amount ?? 0), 0)) ||
          0
        const interestOPL =
          (interestOPLData.body &&
            interestOPLData.body.accountGroups?.reduce((total, e) => total + (e.amount ?? 0), 0)) ||
          0
        const balanceAPL =
          (balanceAPLData.body &&
            balanceAPLData.body.accountGroups?.reduce((total, e) => total + (e.amount ?? 0), 0)) ||
          0
        const balanceOPL =
          (balanceOPLData.body &&
            balanceOPLData.body.accountGroups?.reduce((total, e) => total + (e.amount ?? 0), 0)) ||
          0
        return {
          interestAPL,
          interestOPL,
          balanceAPL,
          balanceOPL
        }
      })
    )

  export const getClientsByPolicy = (policyNum: string) =>
    pipe(
      POApi.get(`wf-api/bo/${policyNum}/clients`)(
        t.type({
          body: t.type({
            laList: t.array(lAClient),
            benList: t.array(benClient)
          })
        })
      ),
      ZIO.map((res) => res.body)
    )

  export const getListClientsDetail = (
    policyNum: string
  ): Task<{ laClient: ClientQuery[]; benClient: ClientExtInfo[] }> =>
    pipe(
      SubmissionService.getClientsByPolicy(policyNum),
      ZIO.flatMap((clients) =>
        pipe(
          ZIO.zipPar(
            SubmissionService.getClients(clients.laList.map((l) => ({ clientId: l.clientNumberOfLA }))),
            SubmissionService.getClients(clients.benList.map((l) => ({ clientId: l.clientNumberOfBen })))
          ),
          ZIO.map(([laClient, benClient]) => ({
            laClient: laClient.body,
            benClient: benClient.body.map((b, i) => ({ ...b, relationshipCode: clients.benList[i].relationShipCode }))
          }))
        )
      )
    )

  export const getFileNetAccess = (policyNum: string): Task<boolean> =>
    pipe(
      POApi.post(`wf-api/other/client-policy-verify`)(
        t.type({
          body: t.type({
            policyRecOuts: t.array(
              t.type({
                errorCode: Maybe(t.string)
              })
            )
          })
        })
      )({
        body: {
          function: 'CHECK_RECEIPT',
          clientRecs: [{ clientNumber: '', securityNo: '' }],
          policyRecs: [{ policyNum }]
        }
      }),
      ZIO.map((verify) => verify.body.policyRecOuts.findIndex((x) => !!x.errorCode) > -1)
    )

  export const otpRequest = (
    transactionName: string,
    collerationId: string,
    phoneNumber: string,
    policyNum: string,
    isCCE: boolean = false
  ) =>
    pipe(
      POApi.postError()(`wf-api/transaction/otp/request`)(t.unknown, POApi.headerError)({
        isCCE: isCCE,
        body: {
          attributes: {
            PAYLOAD: '',
            TRANSACTION_NAME: transactionName,
            TRANSACTION_CODE: collerationId,
            POLICY_NUM: policyNum
          },
          channels: [
            {
              name: 'SMS',
              value: phoneNumber
            }
          ]
        }
      })
    )

  export const otpRequestAgent = (
    transactionName: string,
    collerationId: string,
    phoneNumber: string,
    agentNum: string,
    isCCE: boolean = false
  ) =>
    pipe(
      POApi.postError()(`wf-api/transaction/otp/request`)(t.unknown, POApi.headerError)({
        isCCE: isCCE,
        body: {
          attributes: {
            MESSAGE_TYPE: 'DS_SUBMISSION',
            TRANSACTION_NAME: transactionName,
            TRANSACTION_CODE: collerationId,
            AGENT_NUM: agentNum
          },
          channels: [
            {
              name: 'SMS',
              value: phoneNumber
            }
          ]
        }
      })
    )

  export const getAgentInfo = (policyNumber: string): Task<AgentInfoC> =>
    pipe(
      POApi.post(`wf-api/policy/${policyNumber}/agent-info`)(
        t.type({
          body: AgentInfoC
        })
      )({
        body: {
          policyNumber
        }
      }),
      ZIO.map((a) => a.body)
    )

  export const submit =
    <C extends t.Mixed>(resp: C, method?: 'POST' | 'PUT') =>
    (
      url: string,
      body: any,
      primaryPolicyNo: string,
      requestAuth: RequestAuthFormDataSubmit | null,
      documents: StorageBlob.FileContentSubmit[],
      clientNum?: string,
      isCCE?: boolean,
      uploadOfficeCode?: string,
      isClaim?: boolean,
      exchangeId?: string,
      isCashoutSubmit?: boolean,
      otherSource?: SourceType
    ) =>
      pipe(
        ZIO.zipPar(
          AuthService.userInfo,
          clientNum
            ? ZIO.succeed(clientNum)
            : pipe(
                getOwnerInfo(primaryPolicyNo, isClaim),
                ZIO.map((x) => x.body.clientId)
              )
        ),
        ZIO.flatMap(([currUser, clientId]) => {
          const otpTransaction =
            requestAuth && (requestAuth.type === 'OTP' || requestAuth.type === 'OTP_AND_ATTACH_REQUEST_FORM')
              ? requestAuth.data || null
              : null
          documents =
            requestAuth &&
            (requestAuth.type === 'ATTACH_REQUEST_FORM' ||
              requestAuth.type === 'OTP_AND_ATTACH_REQUEST_FORM' ||
              requestAuth.type === 'OWNER_SUBSCRIPTION_DOCUMENT_AND_ATTACH_REQUEST_FORM')
              ? [...documents, ...requestAuth.data.attachments]
              : documents
          const authenRequestForm =
            requestAuth &&
            (requestAuth.type === 'ATTACH_REQUEST_FORM' ||
              requestAuth.type === 'OTP_AND_ATTACH_REQUEST_FORM' ||
              requestAuth.type === 'OWNER_SUBSCRIPTION_DOCUMENT_AND_ATTACH_REQUEST_FORM')
              ? {
                  authFlag: requestAuth.data.authFlag,
                  signatureDocuments: requestAuth.data.attachments
                }
              : null
          return method !== 'PUT'
            ? POApi.postError()(url)(resp)({
                ...body,
                clientId,
                primaryPolicyNo,
                otpTransaction,
                authenRequestForm,
                documents,
                officeCode: uploadOfficeCode || '-',
                createdDate: new Date().toISOString(),
                createdBy: isCashoutSubmit && currUser.isGaLogin ? currUser.name : currUser.email,
                authenOption: requestAuth?.type,
                source: otherSource ? otherSource : SourceType.PULSE4OPS,
                isCCE,
                exchangeId
              })
            : POApi.putError()(url)(resp)({
                ...body,
                clientId,
                primaryPolicyNo,
                otpTransaction,
                authenRequestForm,
                documents,
                officeCode: uploadOfficeCode || '-',
                createdDate: new Date().toISOString(),
                createdBy: currUser.email,
                authenOption: requestAuth?.type,
                source: SourceType.PULSE4OPS,
                isCCE
              })
        })
      )

  export const submitPut =
    <C extends t.Mixed>(resp: C) =>
    (
      url: string,
      body: any,
      primaryPolicyNo: string,
      requestAuth: RequestAuthFormDataSubmit | null,
      documents: StorageBlob.FileContentSubmit[],
      clientNum?: string,
      authenOption: string = 'OTP'
    ) =>
      pipe(
        ZIO.zipPar(
          AuthService.userInfo,
          clientNum
            ? ZIO.succeed(clientNum)
            : pipe(
                getOwnerInfo(primaryPolicyNo),
                ZIO.map((x) => x.body.clientId)
              )
        ),
        ZIO.flatMap(([currUser, clientId]) => {
          const otpTransaction = requestAuth && requestAuth.type === 'OTP' ? requestAuth.data || null : null
          documents =
            requestAuth && requestAuth.type === 'ATTACH_REQUEST_FORM'
              ? [...documents, ...requestAuth.data.attachments]
              : documents
          const authenRequestForm =
            requestAuth && requestAuth.type === 'ATTACH_REQUEST_FORM'
              ? {
                  authFlag: requestAuth.data.authFlag,
                  signatureDocuments: requestAuth.data.attachments
                }
              : null
          return POApi.putError()(url)(resp)({
            ...body,
            clientId,
            primaryPolicyNo,
            otpTransaction,
            authenRequestForm,
            documents,
            officeCode: '-',
            createdDate: new Date().toISOString(),
            createdBy: currUser.email,
            authenOption: requestAuth?.type,
            source: SourceType.PULSE4OPS
          })
        })
      )

  export const getPolicyWarning = (policyNum: string): Task<Array<WarningMessage>> =>
    pipe(
      POApi.get(`wf-api/policy/${policyNum}/get-warnings`)(
        t.type({
          body: t.array(
            t.type({
              id: Maybe(t.string),
              policyNum: t.string,
              warningDate: t.string,
              userId: t.string,
              shortDesc: t.string,
              detailDesc: t.string
            })
          )
        })
      ),
      ZIO.catchAll((e) => ZIO.succeed(null)),
      ZIO.map((l) => l?.body ?? [])
    )

  export const getPrucashDetail = (policyNum: string) =>
    pipe(
      POApi.post(`wf-api/bo/prucash-detail`)(
        t.type({
          body: t.type({
            subfileOccurs: t.array(
              t.type({
                accuredInterest: t.number,
                currency: t.string,
                currentBalance: t.number,
                loanNumber: t.number,
                loanStartDate: t.string,
                loanType: t.string,
                numCon: t.number,
                pendingInterest: t.number,
                policyLoanTotal: t.number,
                principalAmount: t.number,
                receivedAmount: Maybe(t.number),
                receivedDate: Maybe(t.string)
              })
            ),
            totalAmount: t.number
          })
        })
      )({
        body: {
          contractNumber: policyNum,
          effectiveDate: moment(new Date()).format('yyyyMMDD')
        }
      }),
      ZIO.catchAll((e) => ZIO.succeed(null)),
      ZIO.map((policy) => policy?.body ?? { subfileOccurs: [], totalAmount: 0 })
    )
  export const changeAssigneeState = (status: boolean) =>
    pipe(
      AuthService.userInfo,
      ZIO.flatMap((currentUser) =>
        POApi.post(`pulseops/api/v1/change-assignee-state`)(AssigneeType)({
          email: currentUser.email,
          status,
          createdDate: new Date().getTime(),
          createdBy: currentUser.email
        })
      )
    )

  export const getStateAssignee = () =>
    pipe(
      AuthService.userInfo,
      ZIO.flatMap((currentUser) =>
        POApi.get(`pulseops/api/v1/get-latest-assignee-state?email=${currentUser.email}`)(
          t.type({
            data: Maybe(AssigneeType)
          })
        )
      )
    )

  export const TerminationDetail = Maybe(
    t.type({
      agentCode: t.string,
      agentSurName: Maybe(t.string),
      agentName: Maybe(t.string),
      agentGivName: Maybe(t.string),
      appointedDate: Maybe(t.string),
      terminateDate: Nullable(t.string),
      agentType: Maybe(t.string),
      clientNum: Maybe(t.string),
      title: Maybe(t.string),
      unitCode: Maybe(t.string),
      unitDescription: Maybe(t.string),
      branchCode: Maybe(t.string),
      branchDescription: Maybe(t.string),
      officeCode: Maybe(t.string),
      officeDescription: Maybe(t.string),
      regionCode: Maybe(t.string),
      regionDescription: Maybe(t.string),
      subzoneDescription: Maybe(t.string),
      zoneCode: Maybe(t.string),
      zoneDescription: Maybe(t.string),
      debt: Maybe(t.string)
    })
  )

  export type TerminationDetail = t.TypeOf<typeof TerminationDetail>
  export const getDetail = (agentNum: string) =>
    pipe(
      POApi.getConfig({ params: { agentNum } })('distribution-agents-service/agent/get-agent-info')(TerminationDetail),
      ZIO.map((res) => res)
    )

  export const transferDetail = Maybe(
    t.type({
      policyNum: Maybe(t.string),
      cownNum: Maybe(t.string),
      cownSurName: Maybe(t.string),
      cownGivName: Maybe(t.string),
      cownName: Maybe(t.string),
      cnttype: Maybe(t.string),
      statcode: Maybe(t.string),
      issueDate: Maybe(t.string),
      ptDate: Maybe(t.string),
      occDate: Maybe(t.string),
      policyYear: Maybe(t.number),
      serviceAgents: t.array(
        t.type({
          agentCode: Maybe(t.string),
          clientNum: Maybe(t.string),
          agentSurName: Maybe(t.string),
          agentGivName: Maybe(t.string),
          agentName: Maybe(t.string),
          appointedDate: Maybe(t.string),
          terminateDate: Nullable(t.string),
          agentType: Nullable(t.string),
          title: Nullable(t.string),
          unitCode: Nullable(t.string),
          unitDescription: Nullable(t.string),
          branchCode: Nullable(t.string),
          branchDescription: Nullable(t.string),
          officeCode: Nullable(t.string),
          officeDescription: Nullable(t.string),
          regionCode: Maybe(t.string),
          regionDescription: Maybe(t.string),
          subzoneCode: Nullable(t.string),
          subzoneDescription: Nullable(t.string),
          zoneCode: Nullable(t.string),
          zoneDescription: Nullable(t.string),
          debt: Nullable(t.string),
          workTime: Maybe(t.string),
          salezone: Maybe(t.string),
          rateInforce: Maybe(t.string),
          qualifiedProduct: Nullable(t.string)
        })
      ),
      transferAgent: Maybe(
        t.type({
          agentCode: Maybe(t.string),
          clientNum: Maybe(t.string),
          agentSurName: Maybe(t.string),
          agentGivName: Maybe(t.string),
          agentName: Maybe(t.string),
          appointedDate: Maybe(t.string),
          terminateDate: Maybe(t.string),
          agentType: Nullable(t.string),
          title: Nullable(t.string),
          unitCode: Nullable(t.string),
          unitDescription: Nullable(t.string),
          branchCode: Nullable(t.string),
          branchDescription: Nullable(t.string),
          officeCode: Nullable(t.string),
          officeDescription: Nullable(t.string),
          regionCode: Maybe(t.string),
          regionDescription: Maybe(t.string),
          subzoneCode: Nullable(t.string),
          subzoneDescription: Nullable(t.string),
          zoneCode: Nullable(t.string),
          zoneDescription: Nullable(t.string),
          debt: Nullable(t.string),
          workTime: Maybe(t.number),
          salezone: Maybe(t.string),
          rateInforce: Maybe(t.number),
          qualifiedProduct: Nullable(t.string)
        })
      ),
      transferReason: Maybe(t.string)
    })
  )

  export type transferDetail = t.TypeOf<typeof transferDetail>

  export const getDetailTransfer = (policyNum: string, transferAgent?: string) =>
    pipe(
      POApi.getConfig({ params: { policyNum, transferAgent } })(
        'distribution-agents-service/policy/get-policy-transfer-info'
      )(transferDetail),
      ZIO.map((res) => res)
    )

  export const getDataPendingLetterPS = (policyNum: string, isClaim?: boolean | null) =>
    pipe(
      getPolicy(policyNum),
      ZIO.flatMap((policy) =>
        ZIO.zipPar(
          policy.body?.clientDespatchAddress ? getCustomer(policy.body?.clientDespatchAddress) : ZIO.succeed(null),
          policy.body?.owners.id ? getCustomer(policy.body?.owners.id) : ZIO.succeed(null)
        )
      ),
      ZIO.map(([clientDpInfo, clientInfo]) => {
        return {
          name: clientInfo?.body.name,
          clientId: clientInfo?.body.clientId,
          street: clientDpInfo?.body.addressDetails?.PRIMARY?.line1,
          ward: clientDpInfo?.body.addressDetails?.PRIMARY?.line2,
          district: clientDpInfo?.body.addressDetails?.PRIMARY?.line3,
          city: clientDpInfo?.body.addressDetails?.PRIMARY?.line4,
          phoneNumber: clientDpInfo?.body.mobilePhone,
          locationCode: clientDpInfo?.body.location
        }
      })
    )

  export const getPolicyByGA = (data: string, policyNum: string) =>
    pipe(
      POApi.post(`wf-api/policy/search-by-params`)(
        t.type({
          body: t.array(
            t.type({
              owners: t.type({
                id: Nullable(t.string)
              }),
              proposal: t.type({
                proposalNo: Nullable(t.string)
              }),
              status: Maybe(t.string)
            })
          )
        })
      )({
        body: {
          params: data
        }
      }),
      ZIO.flatMap((res) => {
        return pipe(
          res.body?.[0]?.owners?.id,
          O.fromNullable,
          O.filter((id) => id.length > 0),
          O.fold(
            () => ZIO.succeed([]),
            (clientId) =>
              pipe(
                getClientsByPolicy(policyNum),
                ZIO.flatMap((cl) => {
                  return cl.laList.length
                    ? getClients([{ clientId }].concat(cl.laList.map((l) => ({ clientId: l.clientNumberOfLA }))))
                    : getClients([{ clientId }])
                }),
                ZIO.map((client): SearchPolicy[] => [
                  {
                    policyNum,
                    idNum: client.body[0]?.externalIds?.SOE_VALUE ?? '-',
                    clientName: client.body[0]?.name ?? '-',
                    laName: client.body.length < 2 ? '-' : client.body[1].name ?? '-',
                    primaryId: clientId,
                    proposalNumber: res.body?.[0].proposal.proposalNo || ''
                  }
                ])
              )
          )
        )
      })
    )

  export const checkLoanNumber99And00ForPolicy = (policyNum: string) => {
    return pipe(
      POApi.post(`wf-api/policy/billing-change-apl/loan-enquiry`)(
        t.type({
          body: t.type({
            action: Maybe(t.string),
            contractType: Maybe(t.string),
            subfileOccurs: Maybe(
              t.array(
                t.type({
                  accuredInterest: Maybe(t.number),
                  pendingInterest: Maybe(t.number),
                  currentBalance: Maybe(t.number),
                  loanNumber: Maybe(t.number),
                  loanStartDate: Maybe(t.string),
                  loanType: Maybe(t.string),
                  principalAmount: Maybe(t.number),
                  policyLoanTotal: Maybe(t.number),
                  numCon: Maybe(t.number)
                })
              )
            )
          })
        })
      )({
        body: {
          contractNumber: policyNum,
          effectiveDate: moment(new Date()).format('yyyyMMDD')
        }
      }),
      ZIO.map((reponse) => {
        const subfileOccurs = reponse?.body.subfileOccurs ?? []
        const loanEnquiryList = subfileOccurs.map((item) => ({
          loanNumber: item.loanNumber ?? 0,
          loanType: item.loanType ?? ''
        }))
        // filter to get item with oldest loanStartDate
        const customedLoanEqueryList = subfileOccurs.slice().sort(function (item1, item2) {
          return moment(item1.loanStartDate).valueOf() - moment(item2.loanStartDate).valueOf()
        })
        const loanStartDate =
          customedLoanEqueryList && customedLoanEqueryList.length > 0 ? customedLoanEqueryList[0].loanStartDate : ''
        return {
          loanStartDate: loanStartDate ?? '',
          loanEnquiry: loanEnquiryList
        }
      })
    )
  }
}
