import { ZIO } from '@mxt/zio'
import { assets, Form, Input, SelectOption, SelectSearch, useMobile, DatePicker, PayoutService } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import * as React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { ClaimService } from '../../ClaimService'
import { generalStyles as styles, PaymentGeneralInfo } from '../../common'
import { PaymentMethodProps } from '../PaymentMethodProps'
import moment from 'moment'
import * as O from 'fp-ts/lib/Option'

export const BankTransfer = (props: PaymentMethodProps) => {
  const { isMobile } = useMobile()
  const { t } = useTranslation('claim')
  const ct = useTranslation().t
  const { form, banks, editableForm } = props
  const register = Form.register(form)

  const watchPO = form.watch('payeeIsNotPolicyOwner')
  const errors = form.formState.errors

  const [bankBranch, setBankBranch] = React.useState<SelectOption[]>([])

  React.useEffect(() => {
    if (form.watch('bank')?.value) {
      // form.setValue('bankBranch', { label: '', value: '' }, { shouldValidate: true })
      pipe(
        // ClaimService.getBankBranch(form.watch('bank')?.value || ''),
        ZIO.effect(() => {
          const codePulse = pipe(
            banks?.find((x) => x.code === form.watch('bank')?.value),
            O.fromNullable,
            O.map((item) => item.codePulse ?? ''),
            O.getOrElse(() => '')
          )
          return {
            code: form.watch('bank')?.value ?? '',
            codePulse: codePulse
          }
        }),
        ZIO.flatMap((bankCodeInfo) => PayoutService.bankBranch(bankCodeInfo.code, bankCodeInfo.codePulse)),
        ZIO.tap((bankBranch) => {
          setBankBranch(bankBranch.map((branch) => ({ value: branch.code, label: branch.name })))
          return ZIO.unit
        }),
        ZIO.unsafeRun({})
      )
    }
  }, [form.watch('bank')])

  const onChangeBank = (e: SelectOption | null) => {
    if (e) {
      setBankBranch([])
      form.setValue('bankBranch', null)
    }
  }

  return (
    <View style={{ width: '100%' }}>
      <View style={[{ flexDirection: isMobile ? 'column' : 'row' }]}>
        <View style={isMobile ? styles.mobileField : styles.tabletField}>
          <Input
            {...register('bankAccountName', {
              required: `${ct('message:MS020001', { field: t('AccountName') })}`,
              maxLength: { value: 120, message: 'Max 120' },
              pattern: {
                value: /^[^<>'\"/;`%@*!(){}\[\],.:#+-=$^&\\|?~_-]*$/,
                message: `${t('message:MS050013', { field: t('AccountName') })}`
              }
            })}
            errorMessage={errors.bankAccountName?.message || ''}
            maxLength={120}
            title={t('AccountName')}
            required={watchPO}
            disabled={!watchPO || editableForm}
          />
        </View>
        <View style={isMobile ? styles.mobileField : styles.tabletField}>
          <Input
            {...register('bankIdNumber', {
              required: `${ct('message:MS020001', { field: t('IDNumber') })}`,
              maxLength: { value: 24, message: 'Max 24' },
              pattern: {
                value: /^((?!501|504)[0-9]*)$/,
                message: `${t('message:MS050013', { field: t('IDNumber') })}`
              }
            })}
            maxLength={24}
            errorMessage={errors.bankIdNumber?.message || ''}
            title={t('IDNumber')}
            required={watchPO}
            disabled={!watchPO || editableForm}
          />
        </View>
        {!watchPO && (
          <View style={isMobile ? styles.mobileField : styles.tabletField}>
            <Controller
              name="dob"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: `${t('message:MS020009', { field: t('contact:DOB') })}`
                },
                validate: (date) =>
                  (date && moment(date).toDate().getTime() < new Date().getTime()) ||
                  `${t('message:MS050013', { field: t('contact:DOB') })}`
              }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <DatePicker
                  required
                  label={t('contact:DOB')}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={moment(value)}
                  errorMessage={error?.message}
                  disabled
                />
              )}
            />
          </View>
        )}
      </View>
      <View style={[{ flexDirection: isMobile ? 'column' : 'row' }]}>
        <View style={isMobile ? styles.mobileField : styles.tabletField}>
          <Controller
            name="bank"
            control={form.control}
            rules={{
              required: {
                value: true,
                message: `${ct('message:MS020001', { field: t('Bank') })}`
              }
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <SelectSearch
                required
                popupIcon={<assets.ArrowDownDropdownIcon />}
                label={t('Bank')}
                options={banks?.map((v) => ({ value: v.code, label: v.name })) || []}
                value={value}
                onBlur={onBlur}
                onChange={(e) => {
                  onChange(e)
                  onChangeBank(e)
                }}
                placeholder={t('common:Select')}
                errorMessage={error?.message}
                key={value?.value}
                disabled={editableForm}
              />
            )}
          />
        </View>
        <View style={isMobile ? styles.mobileField : styles.tabletField}>
          <Controller
            name="bankBranch"
            control={form.control}
            rules={{
              required: {
                value: true,
                message: `${ct('message:MS020001', { field: t('Branch') })}`
              }
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <SelectSearch
                required
                popupIcon={<assets.ArrowDownDropdownIcon />}
                label={t('Branch')}
                options={bankBranch || []}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                placeholder={t('common:Select')}
                errorMessage={error?.message}
                key={value?.value}
                disabled={editableForm}
              />
            )}
          />
        </View>
        <View style={isMobile ? styles.mobileField : styles.tabletField}>
          <Input
            {...register('bankAccountNumber', {
              required: `${ct('message:MS020001', { field: t('AccountNumber') })}`,
              maxLength: 20,
              pattern: {
                value: /^[^*&$#\\<>|~`!@%^()/';.,:/[/\]\"+=_-]*$/g,
                message: t('message:MS050223')
              }
            })}
            maxLength={20}
            errorMessage={errors.bankAccountNumber?.message || ''}
            title={t('AccountNumber')}
            required={true}
            disabled={editableForm}
          />
        </View>
      </View>
      {watchPO && <PaymentGeneralInfo form={form} editableForm={editableForm} />}
    </View>
  )
}
