import { TFunction } from "react-i18next"
import { TabSearchOB } from "./ob-search-form";

export namespace OBTableServiceInquiry {
    export const columnCSServiceInquiry: (t: TFunction<"translation">) => any[] = (t) => [
        {
            label: t('Outbound:OBServiceInquiry:PolicyNumber'),
            field: 'policyNumber',
        },
        {
            label: t('Outbound:OBServiceInquiry:CallType'),
            field: 'callProgram'
        },
        {
            label: t('Outbound:OBServiceInquiry:ClientNumber'),
            field: 'clientNumber'
        },
        {
            label: t('Outbound:OBServiceInquiry:ClientName'),
            field: 'clientName'
        },
        {
            label: t('Outbound:OBServiceInquiry:VIPCustomer'),
            field: 'vipStatus'
        },
        {
            label: t('Outbound:OBServiceInquiry:Agency'),
            field: 'agency'
        },
        {
            label: t('Outbound:OBServiceInquiry:AppointmentDate'),
            field: 'appointmentDate'
        },
        {
            label: t('Outbound:OBServiceInquiry:CreatedDate'),
            field: 'createdDate'
        },
        {
            label: t('Outbound:OBServiceInquiry:TotalAging'),
            field: 'agingDays'
        },
        {
            label: t('Outbound:OBServiceInquiry:ExecutionTimes'),
            field: 'executionTime'
        },
        {
            label: t('Outbound:OBServiceInquiry:LatestAssignee'),
            field: 'latestHandleStaff'
        },
        {
            label: t('Outbound:OBServiceInquiry:Leader'),
            field: 'leaderOfStaff',
        },
        {
            label: t('Outbound:OBServiceInquiry:CaseStatus'),
            field: 'status'
        },
        {
            label: t('Outbound:OBServiceInquiry:CurrentActivity'),
            field: 'currentActivity'
        },
        {
            label: t('Outbound:OBServiceInquiry:CaseID'),
            disabled: true,
            field: 'caseId',
            visible: true
        }
    ]
    export const columnCSAdvanceServiceInquiry: (t: TFunction<"translation">, tab: TabSearchOB) => any[] = (t, tab) => {
        let columnList: Array<{ label: string; disabled?: boolean; field?: string; visible?: boolean }> = []
        columnList.push({
            label: t('Outbound:OBServiceInquiry:PolicyNumber'),
            field: 'policyNumber',
        })
        columnList.push({
            label: t('Outbound:OBServiceInquiry:CallType'),
            field: 'transactionType'
        })
        tab === TabSearchOB.AdvancedSearch && columnList.push({
            label: t('Outbound:OBTaskManagement:FirstIssueDate'),
            field: 'policyFirstIssuedDate'
        })
        tab === TabSearchOB.AdvancedSearch && columnList.push({
            label: t('Outbound:OBTaskManagement:LastIssueDate'),
            field: 'policyLastIssuedDate'
        })
        tab === TabSearchOB.AdvancedSearch && columnList.push({
            label: t('Outbound:OBServiceInquiry:PaidToDateAdvance'),
            field: 'paidToDate'
        })
        tab === TabSearchOB.AdvancedSearch && columnList.push({
            label: t('Outbound:OBServiceInquiry:SubmitDate'),
            field: 'caseSubmittedDate'
        })
        tab === TabSearchOB.AdvancedSearch && columnList.push({
            label: t('Outbound:OBServiceInquiry:LastCallingResult'),
            field: 'callOutCode',
            disabled: true
        })

        columnList.push({
            label: t('Outbound:OBServiceInquiry:ClientNumber'),
            field: 'poClientNumber'
        })
        columnList.push({
            label: t('Outbound:OBServiceInquiry:ClientName'),
            field: 'poName'
        })
        columnList.push({
            label: t('Outbound:OBServiceInquiry:VIPCustomer'),
            field: 'vip'
        })
        columnList.push({
            label: t('Outbound:OBServiceInquiry:Agency'),
            field: 'outboundAgency'
        })
        tab === TabSearchOB.AdvancedSearch && columnList.push({
            label: t('Outbound:OBServiceInquiry:AgentCode'),
            field: 'agentCode'
        })
        columnList.push({
            label: t('Outbound:OBServiceInquiry:AppointmentDate'),
            field: 'outBoundCallBackAppointmentDate'
        })
        columnList.push({
            label: t('Outbound:OBServiceInquiry:CreatedDate'),
            field: 'createdDate'
        })
        columnList.push({
            label: t('Outbound:OBServiceInquiry:TotalAging'),
            field: 'agingDays'
        })
        columnList.push({
            label: t('Outbound:OBServiceInquiry:ExecutionTimes'),
            field: 'outBoundExecutionTime'
        })
        columnList.push({
            label: t('Outbound:OBServiceInquiry:LatestAssignee'),
            field: 'assignee'
        })
        columnList.push({
            label: t('Outbound:OBServiceInquiry:Leader'),
            field: 'outBoundLeaderOfStaff',
        })
        columnList.push({
            label: t('Outbound:OBServiceInquiry:CaseStatus'),
            field: 'status'
        })
        columnList.push({
            label: t('Outbound:OBServiceInquiry:CurrentActivity'),
            field: 'activity'
        })
        columnList.push({
            label: t('Outbound:OBServiceInquiry:CaseID'),
            disabled: true,
            field: 'businessKey',
            visible: true
        })
        return columnList
    }
}