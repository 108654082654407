import { SubTasksResponse, TabWithPermission, TaskType } from '@pulseops/common'
import { TASK_DETAIL_ROLES } from '../../../common/src/rbac/Permission'

export interface Mistake {
  errorId: string
  qcCheck: boolean
  minusScore: number
  desc_en: string
  desc_vi: string
}

export interface MistakeAdju {
  desc_en: string
  desc_vi: string
  error_id: string
  score: number
  qcCheck: boolean
  topic: string
  minusScore: number
}

export interface QCTabData {
  lastUser: string
  totalScore: string
  remark: string
  mistakes: Mistake[]
}

export interface QCTabAdju {
  lastUser: string
  totalScore: string
  remark: string
  maxMinusScore?: number
  mistakes: MistakeAdju[]
}

export enum CheckBasketName {
  PS = 1,
  CLAIM = 2,
  ADJUDICATION = 3,
  CLAIM_PV = 4
}

export enum TaskDetailTab {
  GENERAL_INFO_TAB = 0,
  RELATED_INFO_TAB = 1,
  SUPPLEMENT_INFO_TAB = 2,
  REQUEST_INFO_TAB = 3,
  ERROR_LOG_TAB = 4,
  QC_CHECK_TAB = 5,
  QC_UW_CHECK_TAB = 14,

  CLAIM_GENERAL_INFO_TAB = 6,
  CLAIM_INFO_TAB = 7,
  CLAIM_ADJU_TAB = 8,
  INTERNAL_FEEBACK_TAB = 9,
  QC_CHECK_TAB_CLAIM = 10,
  UNDERWRITTING_TAB = 11,
  PAYOUT_VER_TAB = 12,
  SUSPEND_WRITE_OFF = 13
}

export const notGeneralButton = [
  SubTasksResponse.PS_SUSPENDED,
  SubTasksResponse.PS_QC,
  SubTasksResponse.CH_SUSPENDED,
  SubTasksResponse.CH_QC,
  SubTasksResponse.CS_SUSPENDED,
  SubTasksResponse.CS_QC,
  SubTasksResponse.PC_QC,
  SubTasksResponse.PC_SUSPENDED,
  SubTasksResponse.DS_SUSPENDED,
  SubTasksResponse.PC_WRITEOFF,
  SubTasksResponse.DS_QC,
  SubTasksResponse.UW_QC,
  SubTasksResponse.IDC_SUSPENDED,
  SubTasksResponse.IDC_QC
]

export const getTaskGroupName = (taskType: TaskType) => {
  switch (taskType) {
    case TaskType.DistributionService:
      return 'DS'
    case TaskType.PolicyService:
      return 'PS'
    case TaskType.Claim:
      return 'CL'
    case TaskType.Complaint:
      return 'CH'
    case TaskType.CustomerService:
      return 'CS'
    case TaskType.ErrorHandler:
      return 'EH'
    case TaskType.Underwriting:
      return 'UW'
    case TaskType.PremiumCollection:
      return 'PC'
    case TaskType.GENERAL:
      return 'GI'
    case TaskType.IDC:
      return 'IDC'
  }
}

interface DetailTabPermission {
  DS?: string
  CL?: string
  PS?: string
  CS?: string
  CH?: string
  UW?: string
  EH?: string
  PC?: string
  GI?: string
  IDC?: string
}

export type DetailTabItem = TabWithPermission<DetailTabPermission>

export const claimItem: DetailTabItem[] = [
  {
    id: TaskDetailTab.CLAIM_GENERAL_INFO_TAB,
    title: 'Tab:GeneralInfo',
    permission: {},
    subTabPermissions: [
      TASK_DETAIL_ROLES.ViewGeneralInfoAgentTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewGeneralInfoCoreTransTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewGeneralInfoCustomerTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewGeneralInfoInvestmentTaskDetail /*+ 'TEST_REMOVE'*/,

      TASK_DETAIL_ROLES.ViewGeneralInfoPolicyTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewGeneralInfoPolicyValueTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewGeneralInfoProductTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewGeneralInfoSpecialNotesTaskDetail /*+ 'TEST_REMOVE'*/
    ]
  },
  {
    id: TaskDetailTab.CLAIM_INFO_TAB,
    title: 'claim:ClaimInfo',
    permission: {
      CL: TASK_DETAIL_ROLES.VIEW_CLAIM_INFO /*+ 'TEST_REMOVE'*/,
      PS: '',
      UW: '',
      CH: '',
      CS: '',
      EH: ''
    },
    subTabPermissions: [
      TASK_DETAIL_ROLES.ViewAdminPaymentModeTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewAdminPolicyRiderTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewAdminClaimTypeTaskDetail /*+ 'TEST_REMOVE'*/
    ]
  },
  {
    id: TaskDetailTab.RELATED_INFO_TAB,
    title: 'Tab:RelatedInfo',
    permission: {
      CL: TASK_DETAIL_ROLES.VIEW_RELATED_INFO /*+ 'TEST_REMOVE'*/,
      PS: '',
      UW: '',
      CH: '',
      CS: '',
      EH: ''
    },
    subTabPermissions: [
      TASK_DETAIL_ROLES.ViewRelatedInfoCFMLetterTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewRelatedInfoOnGoingRequestTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewRelatedInfoDocumentTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewRelatedInfoHistoryTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewRelatedInfoCommentTaskDetail /*+ 'TEST_REMOVE'*/
    ]
  }
]

export const adjudicationItem: DetailTabItem[] = [
  {
    id: TaskDetailTab.CLAIM_GENERAL_INFO_TAB,
    title: 'Tab:GeneralInfo',
    permission: {},
    subTabPermissions: [
      TASK_DETAIL_ROLES.ViewGeneralInfoAgentTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewGeneralInfoCoreTransTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewGeneralInfoCustomerTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewGeneralInfoInvestmentTaskDetail /*+ 'TEST_REMOVE'*/,

      TASK_DETAIL_ROLES.ViewGeneralInfoPolicyTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewGeneralInfoPolicyValueTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewGeneralInfoProductTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewGeneralInfoSpecialNotesTaskDetail /*+ 'TEST_REMOVE'*/
    ]
  },
  {
    id: TaskDetailTab.CLAIM_INFO_TAB,
    title: 'claim:ClaimInfo',
    permission: {
      CL: TASK_DETAIL_ROLES.VIEW_CLAIM_INFO /*+ 'TEST_REMOVE'*/,
      PS: '',
      UW: '',
      CH: '',
      CS: '',
      EH: ''
    },
    subTabPermissions: [
      TASK_DETAIL_ROLES.ViewAdminPaymentModeTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewAdminPolicyRiderTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewAdminClaimTypeTaskDetail /*+ 'TEST_REMOVE'*/
    ]
  },
  {
    id: TaskDetailTab.CLAIM_ADJU_TAB,
    title: 'TaskManagement:Adjudication',
    permission: {},
    subTabPermissions: [
      TASK_DETAIL_ROLES.ViewAdjudicationAllocationTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewAdjudicationCalculationTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewAdjudicationFinalDecisionTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewAdjudicationPaymentModeTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewAdjudicationPolicyRiderTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewAdjudicationClaimTypeTaskDetail /*+ 'TEST_REMOVE'*/
    ]
  },
  {
    id: TaskDetailTab.SUPPLEMENT_INFO_TAB,
    title: 'Tab:SupplementaryInfo',
    permission: {
      CL: TASK_DETAIL_ROLES.VIEW_SUPPLEMENTARY_INFO /*+ 'TEST_REMOVE'*/,
      PS: '',
      UW: '',
      CH: '',
      CS: '',
      EH: ''
    },
    subTabPermissions: []
  },
  {
    id: TaskDetailTab.RELATED_INFO_TAB,
    title: 'Tab:RelatedInfo',
    permission: {
      CL: TASK_DETAIL_ROLES.VIEW_RELATED_INFO /*+ 'TEST_REMOVE'*/,
      PS: '',
      UW: '',
      CH: '',
      CS: '',
      EH: ''
    },
    subTabPermissions: [
      TASK_DETAIL_ROLES.ViewRelatedInfoCFMLetterTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewRelatedInfoOnGoingRequestTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewRelatedInfoDocumentTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewRelatedInfoHistoryTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewRelatedInfoCommentTaskDetail /*+ 'TEST_REMOVE'*/
    ]
  },
  {
    id: TaskDetailTab.QC_CHECK_TAB_CLAIM,
    title: 'Tab:ScoreVerificationInfo',
    permission: {
      CL: TASK_DETAIL_ROLES.VIEW_QC_ADMIN_CL /*+ 'TEST_REMOVE'*/,
      PS: '',
      UW: '',
      CH: '',
      CS: '',
      EH: ''
    },
    subTabPermissions: []
  },
  {
    id: TaskDetailTab.QC_CHECK_TAB,
    title: 'Tab:QC',
    permission: {
      PS: TASK_DETAIL_ROLES.VIEW_QC_PS /*+ 'TEST_REMOVE'*/,
      UW: TASK_DETAIL_ROLES.VIEW_QC_UW /*+ 'TEST_REMOVE'*/,
      CH: TASK_DETAIL_ROLES.VIEW_QC_CH /*+ 'TEST_REMOVE'*/,
      CL: TASK_DETAIL_ROLES.VIEW_QC_CL /*+ 'TEST_REMOVE'*/,
      CS: TASK_DETAIL_ROLES.VIEW_QC_CS /*+ 'TEST_REMOVE'*/,
      EH: ''
    },
    subTabPermissions: []
  },
  {
    id: TaskDetailTab.ERROR_LOG_TAB,
    title: 'Tab:ErrLog',
    permission: {
      PS: TASK_DETAIL_ROLES.VIEW_WORK_FLOW_COMMENT /*+ 'TEST_REMOVE'*/,
      UW: TASK_DETAIL_ROLES.VIEW_WORK_FLOW_COMMENT /*+ 'TEST_REMOVE'*/,
      CH: TASK_DETAIL_ROLES.VIEW_WORK_FLOW_COMMENT /*+ 'TEST_REMOVE'*/,
      CL: TASK_DETAIL_ROLES.VIEW_WORK_FLOW_COMMENT /*+ 'TEST_REMOVE'*/,
      CS: TASK_DETAIL_ROLES.VIEW_WORK_FLOW_COMMENT /*+ 'TEST_REMOVE'*/,
      EH: TASK_DETAIL_ROLES.VIEW_WORK_FLOW_COMMENT /*+ 'TEST_REMOVE'*/
    },
    subTabPermissions: []
  }
]

export const QCTabsClaim: DetailTabItem[] = [
  {
    id: TaskDetailTab.CLAIM_GENERAL_INFO_TAB,
    title: 'Tab:GeneralInfo',
    permission: {},
    subTabPermissions: [
      TASK_DETAIL_ROLES.ViewGeneralInfoAgentTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewGeneralInfoCoreTransTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewGeneralInfoCustomerTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewGeneralInfoInvestmentTaskDetail /*+ 'TEST_REMOVE'*/,

      TASK_DETAIL_ROLES.ViewGeneralInfoPolicyTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewGeneralInfoPolicyValueTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewGeneralInfoProductTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewGeneralInfoSpecialNotesTaskDetail /*+ 'TEST_REMOVE'*/
    ]
  },
  {
    id: TaskDetailTab.CLAIM_INFO_TAB,
    title: 'claim:ClaimInfo',
    permission: {
      CL: TASK_DETAIL_ROLES.VIEW_CLAIM_INFO /*+ 'TEST_REMOVE'*/,
      PS: '',
      UW: '',
      CH: '',
      CS: '',
      EH: ''
    },
    subTabPermissions: [
      TASK_DETAIL_ROLES.ViewAdminPaymentModeTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewAdminPolicyRiderTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewAdminClaimTypeTaskDetail /*+ 'TEST_REMOVE'*/
    ]
  },
  {
    id: TaskDetailTab.CLAIM_ADJU_TAB,
    title: 'TaskManagement:Adjudication',
    permission: {},
    subTabPermissions: [
      TASK_DETAIL_ROLES.ViewAdjudicationAllocationTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewAdjudicationCalculationTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewAdjudicationFinalDecisionTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewAdjudicationPaymentModeTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewAdjudicationPolicyRiderTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewAdjudicationClaimTypeTaskDetail /*+ 'TEST_REMOVE'*/
    ]
  },
  {
    id: TaskDetailTab.SUPPLEMENT_INFO_TAB,
    title: 'Tab:SupplementaryInfo',
    permission: {
      CL: TASK_DETAIL_ROLES.VIEW_SUPPLEMENTARY_INFO /*+ 'TEST_REMOVE'*/,
      PS: '',
      UW: '',
      CH: '',
      CS: '',
      EH: ''
    },
    subTabPermissions: []
  },
  {
    id: TaskDetailTab.RELATED_INFO_TAB,
    title: 'Tab:RelatedInfo',
    permission: {
      CL: TASK_DETAIL_ROLES.VIEW_RELATED_INFO /*+ 'TEST_REMOVE'*/,
      PS: '',
      UW: '',
      CH: '',
      CS: '',
      EH: ''
    },
    subTabPermissions: [
      TASK_DETAIL_ROLES.ViewRelatedInfoCFMLetterTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewRelatedInfoOnGoingRequestTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewRelatedInfoDocumentTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewRelatedInfoHistoryTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewRelatedInfoCommentTaskDetail /*+ 'TEST_REMOVE'*/
    ]
  },
  // {
  //   id: TaskDetailTab.QC_CHECK_TAB_CLAIM,
  //   title: 'Tab:ScoreVerificationInfo',
  //   permission: {
  //     CL: TASK_DETAIL_ROLES.VIEW_QC_ADMIN_CL /*+ 'TEST_REMOVE'*/,
  //     PS: '',
  //     UW: '',
  //     CH: '',
  //     CS: '',
  //     EH: ''
  //   },
  //   subTabPermissions: []
  // },
  {
    id: TaskDetailTab.QC_CHECK_TAB,
    title: 'Tab:QC',
    permission: {
      PS: TASK_DETAIL_ROLES.VIEW_QC_PS /*+ 'TEST_REMOVE'*/,
      UW: TASK_DETAIL_ROLES.VIEW_QC_UW /*+ 'TEST_REMOVE'*/,
      CH: TASK_DETAIL_ROLES.VIEW_QC_CH /*+ 'TEST_REMOVE'*/,
      CL: TASK_DETAIL_ROLES.VIEW_QC_CL /*+ 'TEST_REMOVE'*/,
      CS: TASK_DETAIL_ROLES.VIEW_QC_CS /*+ 'TEST_REMOVE'*/,
      EH: ''
    },
    subTabPermissions: []
  }
]

export const claimPVItem: DetailTabItem[] = [
  {
    id: TaskDetailTab.CLAIM_GENERAL_INFO_TAB,
    title: 'Tab:GeneralInfo',
    permission: {},
    subTabPermissions: [
      TASK_DETAIL_ROLES.ViewGeneralInfoAgentTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewGeneralInfoCoreTransTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewGeneralInfoCustomerTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewGeneralInfoInvestmentTaskDetail /*+ 'TEST_REMOVE'*/,

      TASK_DETAIL_ROLES.ViewGeneralInfoPolicyTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewGeneralInfoPolicyValueTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewGeneralInfoProductTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewGeneralInfoSpecialNotesTaskDetail /*+ 'TEST_REMOVE'*/
    ]
  },
  {
    id: TaskDetailTab.PAYOUT_VER_TAB,
    title: 'Tab:PayoutVerification',
    permission: {
      CL: TASK_DETAIL_ROLES.ViewPayoutCLTaskDetail /*+ 'TEST_REMOVE'*/,
      PS: '',
      UW: '',
      CH: '',
      CS: '',
      EH: ''
    },
    subTabPermissions: []
  },
  {
    id: TaskDetailTab.RELATED_INFO_TAB,
    title: 'Tab:RelatedInfo',
    permission: {
      CL: TASK_DETAIL_ROLES.VIEW_RELATED_INFO /*+ 'TEST_REMOVE'*/,
      PS: '',
      UW: '',
      CH: '',
      CS: '',
      EH: ''
    },
    subTabPermissions: [
      TASK_DETAIL_ROLES.ViewRelatedInfoCFMLetterTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewRelatedInfoOnGoingRequestTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewRelatedInfoDocumentTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewRelatedInfoHistoryTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewRelatedInfoCommentTaskDetail /*+ 'TEST_REMOVE'*/
    ]
  }
]
export const QCTabItems: DetailTabItem[] = [
  {
    id: TaskDetailTab.GENERAL_INFO_TAB,
    title: 'Tab:GeneralInfo',
    permission: {},
    subTabPermissions: [
      TASK_DETAIL_ROLES.ViewGeneralInfoAgentTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewGeneralInfoCoreTransTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewGeneralInfoCustomerTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewGeneralInfoInvestmentTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewGeneralInfoPolicyTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewGeneralInfoPolicyValueTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewGeneralInfoProductTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewGeneralInfoSpecialNotesTaskDetail /*+ 'TEST_REMOVE'*/
    ]
  },
  {
    id: TaskDetailTab.REQUEST_INFO_TAB,
    title: 'Tab:RequestInfo',
    permission: {
      PS: TASK_DETAIL_ROLES.VIEW_REQUESTINFO_PS /*+ 'TEST_REMOVE'*/,
      CH: TASK_DETAIL_ROLES.VIEW_REQUESTINFO_CH /*+ 'TEST_REMOVE'*/,
      CS: TASK_DETAIL_ROLES.VIEW_REQUESTINFO_CS /*+ 'TEST_REMOVE'*/,
      CL: '',
      EH: '',
      UW: '',
      PC: TASK_DETAIL_ROLES.VIEW_REQUESTINFO_PC /*+ 'TEST_REMOVE'*/
    },
    subTabPermissions: []
  },
  {
    id: TaskDetailTab.UNDERWRITTING_TAB,
    title: 'Tab:Underwritting',
    permission: {
      PS: TASK_DETAIL_ROLES.VIEW_REQUESTINFO_PS,
      CH: '',
      CS: '',
      CL: '',
      EH: '',
      UW: TASK_DETAIL_ROLES.VIEW_REQUESTINFO_PS
    },
    subTabPermissions: []
  },
  {
    id: TaskDetailTab.SUPPLEMENT_INFO_TAB,
    title: 'Tab:SupplementaryInfo',
    permission: {
      PS: TASK_DETAIL_ROLES.VIEW_SUPPLEMENTARY_INFO /*+ 'TEST_REMOVE'*/,
      UW: TASK_DETAIL_ROLES.VIEW_SUPPLEMENTARY_INFO /*+ 'TEST_REMOVE'*/,
      CH: TASK_DETAIL_ROLES.VIEW_SUPPLEMENTARY_INFO /*+ 'TEST_REMOVE'*/,
      CL: TASK_DETAIL_ROLES.VIEW_SUPPLEMENTARY_INFO /*+ 'TEST_REMOVE'*/,
      CS: TASK_DETAIL_ROLES.VIEW_SUPPLEMENTARY_INFO /*+ 'TEST_REMOVE'*/,
      EH: TASK_DETAIL_ROLES.VIEW_SUPPLEMENTARY_INFO /*+ 'TEST_REMOVE'*/,
      PC: TASK_DETAIL_ROLES.VIEW_SUPPLEMENTARY_INFO_PC /*+ 'TEST_REMOVE'*/
    },
    subTabPermissions: []
  },
  {
    id: TaskDetailTab.INTERNAL_FEEBACK_TAB,
    title: 'Tab:InternalSuspend',
    permission: {
      PS: TASK_DETAIL_ROLES.VIEW_INTERNAL_SUSPEND /*+ 'TEST_REMOVE'*/,
      CH: TASK_DETAIL_ROLES.VIEW_INTERNAL_SUSPEND /*+ 'TEST_REMOVE'*/,
      CS: TASK_DETAIL_ROLES.VIEW_INTERNAL_SUSPEND /*+ 'TEST_REMOVE'*/,
      CL: '',
      EH: '',
      UW: ''
    },
    subTabPermissions: []
  },
  {
    id: TaskDetailTab.RELATED_INFO_TAB,
    title: 'Tab:RelatedInfo',
    permission: {
      PS: TASK_DETAIL_ROLES.VIEW_RELATED_INFO /*+ 'TEST_REMOVE'*/,
      UW: TASK_DETAIL_ROLES.VIEW_RELATED_INFO /*+ 'TEST_REMOVE'*/,
      CH: TASK_DETAIL_ROLES.VIEW_RELATED_INFO /*+ 'TEST_REMOVE'*/,
      CL: TASK_DETAIL_ROLES.VIEW_RELATED_INFO /*+ 'TEST_REMOVE'*/,
      CS: TASK_DETAIL_ROLES.VIEW_RELATED_INFO /*+ 'TEST_REMOVE'*/,
      EH: TASK_DETAIL_ROLES.VIEW_RELATED_INFO /*+ 'TEST_REMOVE'*/,
      PC: TASK_DETAIL_ROLES.VIEW_RELATED_INFO_PC /*+ 'TEST_REMOVE'*/
    },
    subTabPermissions: [
      TASK_DETAIL_ROLES.ViewRelatedInfoCFMLetterTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewRelatedInfoOnGoingRequestTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewRelatedInfoDocumentTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewRelatedInfoHistoryTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewRelatedInfoCommentTaskDetail /*+ 'TEST_REMOVE'*/
    ]
  },
  {
    id: TaskDetailTab.QC_CHECK_TAB,
    title: 'Tab:QC',
    permission: {
      PS: TASK_DETAIL_ROLES.VIEW_QC_PS /*+ 'TEST_REMOVE'*/,
      UW: TASK_DETAIL_ROLES.VIEW_QC_UW /*+ 'TEST_REMOVE'*/,
      CH: TASK_DETAIL_ROLES.VIEW_QC_CH /*+ 'TEST_REMOVE'*/,
      CL: TASK_DETAIL_ROLES.VIEW_QC_CL /*+ 'TEST_REMOVE'*/,
      CS: TASK_DETAIL_ROLES.VIEW_QC_CS /*+ 'TEST_REMOVE'*/,
      EH: '',
      PC: TASK_DETAIL_ROLES.VIEW_QC_PC /*+ 'TEST_REMOVE'*/
    },
    subTabPermissions: []
  },
  {
    id: TaskDetailTab.ERROR_LOG_TAB,
    title: 'Tab:ErrLog',
    permission: {
      PS: TASK_DETAIL_ROLES.VIEW_WORK_FLOW_COMMENT /*+ 'TEST_REMOVE'*/,
      UW: TASK_DETAIL_ROLES.VIEW_WORK_FLOW_COMMENT /*+ 'TEST_REMOVE'*/,
      CH: TASK_DETAIL_ROLES.VIEW_WORK_FLOW_COMMENT /*+ 'TEST_REMOVE'*/,
      CL: TASK_DETAIL_ROLES.VIEW_WORK_FLOW_COMMENT /*+ 'TEST_REMOVE'*/,
      CS: TASK_DETAIL_ROLES.VIEW_WORK_FLOW_COMMENT /*+ 'TEST_REMOVE'*/,
      EH: TASK_DETAIL_ROLES.VIEW_WORK_FLOW_COMMENT /*+ 'TEST_REMOVE'*/
    },
    subTabPermissions: []
  }
]

export const QCUWTabItems: DetailTabItem[] = [
  {
    id: TaskDetailTab.GENERAL_INFO_TAB,
    title: 'Tab:GeneralInfo',
    permission: {},
    subTabPermissions: [
      TASK_DETAIL_ROLES.ViewGeneralInfoAgentTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewGeneralInfoCoreTransTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewGeneralInfoCustomerTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewGeneralInfoInvestmentTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewGeneralInfoPolicyTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewGeneralInfoPolicyValueTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewGeneralInfoProductTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewGeneralInfoSpecialNotesTaskDetail /*+ 'TEST_REMOVE'*/
    ]
  },
  {
    id: TaskDetailTab.REQUEST_INFO_TAB,
    title: 'Tab:RequestInfo',
    permission: {
      PS: TASK_DETAIL_ROLES.VIEW_REQUESTINFO_PS /*+ 'TEST_REMOVE'*/,
      CH: TASK_DETAIL_ROLES.VIEW_REQUESTINFO_CH /*+ 'TEST_REMOVE'*/,
      CS: TASK_DETAIL_ROLES.VIEW_REQUESTINFO_CS /*+ 'TEST_REMOVE'*/,
      CL: '',
      EH: '',
      UW: '',
      PC: TASK_DETAIL_ROLES.VIEW_REQUESTINFO_PC /*+ 'TEST_REMOVE'*/
    },
    subTabPermissions: []
  },
  {
    id: TaskDetailTab.UNDERWRITTING_TAB,
    title: 'Tab:Underwritting',
    permission: {
      PS: TASK_DETAIL_ROLES.VIEW_REQUESTINFO_PS,
      CH: '',
      CS: '',
      CL: '',
      EH: '',
      UW: TASK_DETAIL_ROLES.VIEW_REQUESTINFO_PS
    },
    subTabPermissions: []
  },
  {
    id: TaskDetailTab.SUPPLEMENT_INFO_TAB,
    title: 'Tab:SupplementaryInfo',
    permission: {
      PS: TASK_DETAIL_ROLES.VIEW_SUPPLEMENTARY_INFO /*+ 'TEST_REMOVE'*/,
      UW: TASK_DETAIL_ROLES.VIEW_SUPPLEMENTARY_INFO /*+ 'TEST_REMOVE'*/,
      CH: TASK_DETAIL_ROLES.VIEW_SUPPLEMENTARY_INFO /*+ 'TEST_REMOVE'*/,
      CL: TASK_DETAIL_ROLES.VIEW_SUPPLEMENTARY_INFO /*+ 'TEST_REMOVE'*/,
      CS: TASK_DETAIL_ROLES.VIEW_SUPPLEMENTARY_INFO /*+ 'TEST_REMOVE'*/,
      EH: TASK_DETAIL_ROLES.VIEW_SUPPLEMENTARY_INFO /*+ 'TEST_REMOVE'*/,
      PC: TASK_DETAIL_ROLES.VIEW_SUPPLEMENTARY_INFO_PC /*+ 'TEST_REMOVE'*/
    },
    subTabPermissions: []
  },
  {
    id: TaskDetailTab.INTERNAL_FEEBACK_TAB,
    title: 'Tab:InternalSuspend',
    permission: {
      PS: TASK_DETAIL_ROLES.VIEW_INTERNAL_SUSPEND /*+ 'TEST_REMOVE'*/,
      CH: TASK_DETAIL_ROLES.VIEW_INTERNAL_SUSPEND /*+ 'TEST_REMOVE'*/,
      CS: TASK_DETAIL_ROLES.VIEW_INTERNAL_SUSPEND /*+ 'TEST_REMOVE'*/,
      CL: '',
      EH: '',
      UW: ''
    },
    subTabPermissions: []
  },
  {
    id: TaskDetailTab.RELATED_INFO_TAB,
    title: 'Tab:RelatedInfo',
    permission: {
      PS: TASK_DETAIL_ROLES.VIEW_RELATED_INFO /*+ 'TEST_REMOVE'*/,
      UW: TASK_DETAIL_ROLES.VIEW_RELATED_INFO /*+ 'TEST_REMOVE'*/,
      CH: TASK_DETAIL_ROLES.VIEW_RELATED_INFO /*+ 'TEST_REMOVE'*/,
      CL: TASK_DETAIL_ROLES.VIEW_RELATED_INFO /*+ 'TEST_REMOVE'*/,
      CS: TASK_DETAIL_ROLES.VIEW_RELATED_INFO /*+ 'TEST_REMOVE'*/,
      EH: TASK_DETAIL_ROLES.VIEW_RELATED_INFO /*+ 'TEST_REMOVE'*/,
      PC: TASK_DETAIL_ROLES.VIEW_RELATED_INFO_PC /*+ 'TEST_REMOVE'*/
    },
    subTabPermissions: [
      TASK_DETAIL_ROLES.ViewRelatedInfoCFMLetterTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewRelatedInfoOnGoingRequestTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewRelatedInfoDocumentTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewRelatedInfoHistoryTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewRelatedInfoCommentTaskDetail /*+ 'TEST_REMOVE'*/
    ]
  },
  {
    id: TaskDetailTab.QC_UW_CHECK_TAB,
    title: 'Tab:QCUW',
    permission: {
      PS: TASK_DETAIL_ROLES.VIEW_QC_UW_PS /*+ 'TEST_REMOVE'*/,
      UW: '' /*+ 'TEST_REMOVE'*/,
      CH: '' /*+ 'TEST_REMOVE'*/,
      CL: '' /*+ 'TEST_REMOVE'*/,
      CS: '' /*+ 'TEST_REMOVE'*/,
      EH: '',
      PC: '' /*+ 'TEST_REMOVE'*/
    },
    subTabPermissions: []
  },
  {
    id: TaskDetailTab.ERROR_LOG_TAB,
    title: 'Tab:ErrLog',
    permission: {
      PS: TASK_DETAIL_ROLES.VIEW_WORK_FLOW_COMMENT /*+ 'TEST_REMOVE'*/,
      UW: TASK_DETAIL_ROLES.VIEW_WORK_FLOW_COMMENT /*+ 'TEST_REMOVE'*/,
      CH: TASK_DETAIL_ROLES.VIEW_WORK_FLOW_COMMENT /*+ 'TEST_REMOVE'*/,
      CL: TASK_DETAIL_ROLES.VIEW_WORK_FLOW_COMMENT /*+ 'TEST_REMOVE'*/,
      CS: TASK_DETAIL_ROLES.VIEW_WORK_FLOW_COMMENT /*+ 'TEST_REMOVE'*/,
      EH: TASK_DETAIL_ROLES.VIEW_WORK_FLOW_COMMENT /*+ 'TEST_REMOVE'*/
    },
    subTabPermissions: []
  }
]

export const tabItems: DetailTabItem[] = [
  {
    id: TaskDetailTab.GENERAL_INFO_TAB,
    title: 'Tab:GeneralInfo',
    permission: {},
    subTabPermissions: [
      TASK_DETAIL_ROLES.ViewGeneralInfoAgentTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewGeneralInfoCoreTransTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewGeneralInfoCustomerTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewGeneralInfoInvestmentTaskDetail /*+ 'TEST_REMOVE'*/,

      TASK_DETAIL_ROLES.ViewGeneralInfoPolicyTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewGeneralInfoPolicyValueTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewGeneralInfoProductTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewGeneralInfoSpecialNotesTaskDetail /*+ 'TEST_REMOVE'*/
    ]
  },
  {
    id: TaskDetailTab.REQUEST_INFO_TAB,
    title: 'Tab:RequestInfo',
    permission: {
      PS: TASK_DETAIL_ROLES.VIEW_REQUESTINFO_PS /*+ 'TEST_REMOVE'*/,
      CH: TASK_DETAIL_ROLES.VIEW_REQUESTINFO_CH /*+ 'TEST_REMOVE'*/,
      CS: TASK_DETAIL_ROLES.VIEW_REQUESTINFO_CS /*+ 'TEST_REMOVE'*/,
      CL: '',
      EH: '',
      UW: '',
      PC: TASK_DETAIL_ROLES.VIEW_REQUESTINFO_PC
    },
    subTabPermissions: []
  },
  {
    id: TaskDetailTab.UNDERWRITTING_TAB,
    title: 'Tab:Underwritting',
    permission: {
      PS: TASK_DETAIL_ROLES.VIEW_REQUESTINFO_PS,
      CH: '',
      CS: '',
      CL: '',
      EH: '',
      UW: TASK_DETAIL_ROLES.VIEW_REQUESTINFO_PS
    },
    subTabPermissions: []
  },
  {
    id: TaskDetailTab.SUPPLEMENT_INFO_TAB,
    title: 'Tab:SupplementaryInfo',
    permission: {
      PS: TASK_DETAIL_ROLES.VIEW_SUPPLEMENTARY_INFO /*+ 'TEST_REMOVE'*/,
      UW: TASK_DETAIL_ROLES.VIEW_SUPPLEMENTARY_INFO /*+ 'TEST_REMOVE'*/,
      CH: TASK_DETAIL_ROLES.VIEW_SUPPLEMENTARY_INFO /*+ 'TEST_REMOVE'*/,
      CL: TASK_DETAIL_ROLES.VIEW_SUPPLEMENTARY_INFO /*+ 'TEST_REMOVE'*/,
      CS: TASK_DETAIL_ROLES.VIEW_SUPPLEMENTARY_INFO /*+ 'TEST_REMOVE'*/,
      EH: TASK_DETAIL_ROLES.VIEW_SUPPLEMENTARY_INFO /*+ 'TEST_REMOVE'*/,
      PC: TASK_DETAIL_ROLES.VIEW_SUPPLEMENTARY_INFO_PC /*+ 'TEST_REMOVE'*/
    },
    subTabPermissions: []
  },
  {
    id: TaskDetailTab.SUSPEND_WRITE_OFF,
    title: 'Tab:SuspendWriteOff',
    permission: {
      PS: '',
      UW: '',
      CH: '',
      CL: '',
      CS: '',
      EH: '',
      PC: TASK_DETAIL_ROLES.VIEW_SUSPEND_WRITEOFF_PC /*+ 'TEST_REMOVE'*/
    },
    subTabPermissions: []
  },
  {
    id: TaskDetailTab.INTERNAL_FEEBACK_TAB,
    title: 'Tab:InternalSuspend',
    permission: {
      PS: TASK_DETAIL_ROLES.VIEW_INTERNAL_SUSPEND /*+ 'TEST_REMOVE'*/,
      CH: TASK_DETAIL_ROLES.VIEW_INTERNAL_SUSPEND /*+ 'TEST_REMOVE'*/,
      CS: TASK_DETAIL_ROLES.VIEW_INTERNAL_SUSPEND /*+ 'TEST_REMOVE'*/,
      CL: '',
      EH: '',
      UW: ''
    },
    subTabPermissions: []
  },
  {
    id: TaskDetailTab.RELATED_INFO_TAB,
    title: 'Tab:RelatedInfo',
    permission: {
      PS: TASK_DETAIL_ROLES.VIEW_RELATED_INFO /*+ 'TEST_REMOVE'*/,
      UW: TASK_DETAIL_ROLES.VIEW_RELATED_INFO /*+ 'TEST_REMOVE'*/,
      CH: TASK_DETAIL_ROLES.VIEW_RELATED_INFO /*+ 'TEST_REMOVE'*/,
      CL: TASK_DETAIL_ROLES.VIEW_RELATED_INFO /*+ 'TEST_REMOVE'*/,
      CS: TASK_DETAIL_ROLES.VIEW_RELATED_INFO /*+ 'TEST_REMOVE'*/,
      EH: TASK_DETAIL_ROLES.VIEW_RELATED_INFO /*+ 'TEST_REMOVE'*/,
      PC: TASK_DETAIL_ROLES.VIEW_RELATED_INFO_PC /*+ 'TEST_REMOVE'*/
    },
    subTabPermissions: [
      TASK_DETAIL_ROLES.ViewRelatedInfoCFMLetterTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewRelatedInfoOnGoingRequestTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewRelatedInfoDocumentTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewRelatedInfoHistoryTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewRelatedInfoCommentTaskDetail /*+ 'TEST_REMOVE'*/
    ]
  },
  {
    id: TaskDetailTab.ERROR_LOG_TAB,
    title: 'Tab:ErrLog',
    permission: {
      PS: TASK_DETAIL_ROLES.VIEW_WORK_FLOW_COMMENT /*+ 'TEST_REMOVE'*/,
      UW: TASK_DETAIL_ROLES.VIEW_WORK_FLOW_COMMENT /*+ 'TEST_REMOVE'*/,
      CH: TASK_DETAIL_ROLES.VIEW_WORK_FLOW_COMMENT /*+ 'TEST_REMOVE'*/,
      CL: TASK_DETAIL_ROLES.VIEW_WORK_FLOW_COMMENT /*+ 'TEST_REMOVE'*/,
      CS: TASK_DETAIL_ROLES.VIEW_WORK_FLOW_COMMENT /*+ 'TEST_REMOVE'*/,
      EH: TASK_DETAIL_ROLES.VIEW_WORK_FLOW_COMMENT /*+ 'TEST_REMOVE'*/,
      PC: TASK_DETAIL_ROLES.VIEW_WORK_FLOW_COMMENT_PC
    },
    subTabPermissions: []
  }
]

export const tabPSDigitalItems: DetailTabItem[] = [
  {
    id: TaskDetailTab.CLAIM_GENERAL_INFO_TAB,
    title: 'Tab:GeneralInfo',
    permission: {},
    subTabPermissions: [
      TASK_DETAIL_ROLES.ViewGeneralInfoAgentTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewGeneralInfoCoreTransTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewGeneralInfoCustomerTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewGeneralInfoInvestmentTaskDetail /*+ 'TEST_REMOVE'*/,

      TASK_DETAIL_ROLES.ViewGeneralInfoPolicyTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewGeneralInfoPolicyValueTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewGeneralInfoProductTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewGeneralInfoSpecialNotesTaskDetail /*+ 'TEST_REMOVE'*/
    ]
  },
  {
    id: TaskDetailTab.REQUEST_INFO_TAB,
    title: 'Tab:RequestInfo',
    permission: {
      PS: TASK_DETAIL_ROLES.VIEW_REQUESTINFO_PS /*+ 'TEST_REMOVE'*/,
      CH: TASK_DETAIL_ROLES.VIEW_REQUESTINFO_CH /*+ 'TEST_REMOVE'*/,
      CS: TASK_DETAIL_ROLES.VIEW_REQUESTINFO_CS /*+ 'TEST_REMOVE'*/,
      CL: '',
      EH: '',
      UW: ''
    },
    subTabPermissions: []
  },
  {
    id: TaskDetailTab.SUPPLEMENT_INFO_TAB,
    title: 'Tab:SupplementaryInfo',
    permission: {
      PS: TASK_DETAIL_ROLES.VIEW_SUPPLEMENTARY_INFO /*+ 'TEST_REMOVE'*/,
      UW: TASK_DETAIL_ROLES.VIEW_SUPPLEMENTARY_INFO /*+ 'TEST_REMOVE'*/,
      CH: TASK_DETAIL_ROLES.VIEW_SUPPLEMENTARY_INFO /*+ 'TEST_REMOVE'*/,
      CL: TASK_DETAIL_ROLES.VIEW_SUPPLEMENTARY_INFO /*+ 'TEST_REMOVE'*/,
      CS: TASK_DETAIL_ROLES.VIEW_SUPPLEMENTARY_INFO /*+ 'TEST_REMOVE'*/,
      EH: TASK_DETAIL_ROLES.VIEW_SUPPLEMENTARY_INFO /*+ 'TEST_REMOVE'*/,
      PC: TASK_DETAIL_ROLES.VIEW_SUPPLEMENTARY_INFO /*+ 'TEST_REMOVE'*/
    },
    subTabPermissions: []
  },
  {
    id: TaskDetailTab.RELATED_INFO_TAB,
    title: 'Tab:RelatedInfo',
    permission: {
      PS: TASK_DETAIL_ROLES.VIEW_RELATED_INFO /*+ 'TEST_REMOVE'*/,
      UW: TASK_DETAIL_ROLES.VIEW_RELATED_INFO /*+ 'TEST_REMOVE'*/,
      CH: TASK_DETAIL_ROLES.VIEW_RELATED_INFO /*+ 'TEST_REMOVE'*/,
      CL: TASK_DETAIL_ROLES.VIEW_RELATED_INFO /*+ 'TEST_REMOVE'*/,
      CS: TASK_DETAIL_ROLES.VIEW_RELATED_INFO /*+ 'TEST_REMOVE'*/,
      EH: TASK_DETAIL_ROLES.VIEW_RELATED_INFO /*+ 'TEST_REMOVE'*/
    },
    subTabPermissions: [
      TASK_DETAIL_ROLES.ViewRelatedInfoCFMLetterTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewRelatedInfoOnGoingRequestTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewRelatedInfoDocumentTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewRelatedInfoHistoryTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewRelatedInfoCommentTaskDetail /*+ 'TEST_REMOVE'*/
    ]
  },
  {
    id: TaskDetailTab.ERROR_LOG_TAB,
    title: 'Tab:ErrLog',
    permission: {
      PS: TASK_DETAIL_ROLES.VIEW_WORK_FLOW_COMMENT /*+ 'TEST_REMOVE'*/,
      UW: TASK_DETAIL_ROLES.VIEW_WORK_FLOW_COMMENT /*+ 'TEST_REMOVE'*/,
      CH: TASK_DETAIL_ROLES.VIEW_WORK_FLOW_COMMENT /*+ 'TEST_REMOVE'*/,
      CL: TASK_DETAIL_ROLES.VIEW_WORK_FLOW_COMMENT /*+ 'TEST_REMOVE'*/,
      CS: TASK_DETAIL_ROLES.VIEW_WORK_FLOW_COMMENT /*+ 'TEST_REMOVE'*/,
      EH: TASK_DETAIL_ROLES.VIEW_WORK_FLOW_COMMENT /*+ 'TEST_REMOVE'*/
    },
    subTabPermissions: []
  }
]
export const tabDStabItems: DetailTabItem[] = [
  {
    id: TaskDetailTab.REQUEST_INFO_TAB,
    title: 'Tab:RequestInfo',
    permission: {
      DS: TASK_DETAIL_ROLES.VIEW_REQUESTINFO_DS
    },
    subTabPermissions: []
  },
  {
    id: TaskDetailTab.SUPPLEMENT_INFO_TAB,
    title: 'Tab:SupplementaryInfo',
    permission: {
      DS: TASK_DETAIL_ROLES.ViewSupplementaryInfoDS /*+ 'TEST_REMOVE'*/
    },
    subTabPermissions: []
  },
  {
    id: TaskDetailTab.RELATED_INFO_TAB,
    title: 'Tab:RelatedInfo',
    permission: {
      DS: TASK_DETAIL_ROLES.VIEW_RELATED_INFO_DS
    },
    subTabPermissions: []
  },
  {
    id: TaskDetailTab.ERROR_LOG_TAB,
    title: 'Tab:ErrLog',
    permission: {
      DS: TASK_DETAIL_ROLES.ViewErrorlogDS
    },
    subTabPermissions: []
  }
]
export const tabDSQCtabItems: DetailTabItem[] = [
  {
    id: TaskDetailTab.REQUEST_INFO_TAB,
    title: 'Tab:RequestInfo',
    permission: {
      DS: TASK_DETAIL_ROLES.VIEW_REQUESTINFO_DS
    },
    subTabPermissions: []
  },
  {
    id: TaskDetailTab.SUPPLEMENT_INFO_TAB,
    title: 'Tab:SupplementaryInfo',
    permission: {
      DS: TASK_DETAIL_ROLES.ViewSupplementaryInfoDS /*+ 'TEST_REMOVE'*/
    },
    subTabPermissions: []
  },
  {
    id: TaskDetailTab.RELATED_INFO_TAB,
    title: 'Tab:RelatedInfo',
    permission: {
      DS: TASK_DETAIL_ROLES.VIEW_RELATED_INFO_DS
    },
    subTabPermissions: []
  },
  {
    id: TaskDetailTab.ERROR_LOG_TAB,
    title: 'Tab:ErrLog',
    permission: {
      DS: TASK_DETAIL_ROLES.ViewErrorlogDS
    },
    subTabPermissions: []
  },
  {
    id: TaskDetailTab.QC_CHECK_TAB,
    title: 'Tab:QC',
    permission: {
      DS: TASK_DETAIL_ROLES.VIEW_QC_DS
    },
    subTabPermissions: []
  }
]
export const tabDSAgent: DetailTabItem[] = [
  {
    id: TaskDetailTab.REQUEST_INFO_TAB,
    title: 'Tab:RequestInfo',
    permission: {
      DS: TASK_DETAIL_ROLES.VIEW_REQUESTINFO_DS
    },
    subTabPermissions: []
  }
]
export const IDCTabItems: DetailTabItem[] = [
  {
    id: TaskDetailTab.GENERAL_INFO_TAB,
    title: 'Tab:GeneralInfo',
    permission: {},
    subTabPermissions: [
      TASK_DETAIL_ROLES.ViewGeneralInfoAgentIDCTaskDetail,
      TASK_DETAIL_ROLES.ViewGeneralInfoCoreTransTaskDetail,
      TASK_DETAIL_ROLES.ViewGeneralInfoCustomerIDCTaskDetail,
      TASK_DETAIL_ROLES.ViewGeneralInfoInvestmentIDCTaskDetail,
      TASK_DETAIL_ROLES.ViewGeneralInfoPolicyIDCTaskDetail,
      TASK_DETAIL_ROLES.ViewGeneralInfoPolicyValueIDCTaskDetail,
      TASK_DETAIL_ROLES.ViewGeneralInfoProductIDCTaskDetail,
      TASK_DETAIL_ROLES.ViewGeneralInfoSpecialNotesTaskDetail
    ]
  },
  {
    id: TaskDetailTab.REQUEST_INFO_TAB,
    title: 'Tab:VoiceRecording',
    permission: {
      IDC: TASK_DETAIL_ROLES.VIEW_REQUESTINFO_IDC
    },
    subTabPermissions: []
  },
  {
    id: TaskDetailTab.SUPPLEMENT_INFO_TAB,
    title: 'Tab:SupplementaryInfo',
    permission: {
      IDC: TASK_DETAIL_ROLES.VIEW_SUPPLEMENTARY_INFO_IDC
    },
    subTabPermissions: []
  },
  {
    id: TaskDetailTab.RELATED_INFO_TAB,
    title: 'Tab:RelatedInfo',
    permission: {
      IDC: TASK_DETAIL_ROLES.VIEW_RELATED_INFO_IDC
    },
    subTabPermissions: [
      TASK_DETAIL_ROLES.ViewRelatedInfoOnGoingRequestTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewRelatedInfoHistoryTaskDetail /*+ 'TEST_REMOVE'*/
    ]
  },
  {
    id: TaskDetailTab.ERROR_LOG_TAB,
    title: 'Tab:ErrLog',
    permission: {
      IDC: TASK_DETAIL_ROLES.VIEW_ERROR_LOG_IDC
    },
    subTabPermissions: []
  }
]

export const IDCQCTabItems: DetailTabItem[] = [
  {
    id: TaskDetailTab.GENERAL_INFO_TAB,
    title: 'Tab:GeneralInfo',
    permission: {},
    subTabPermissions: [
      TASK_DETAIL_ROLES.ViewGeneralInfoAgentIDCTaskDetail,
      TASK_DETAIL_ROLES.ViewGeneralInfoCoreTransTaskDetail,
      TASK_DETAIL_ROLES.ViewGeneralInfoCustomerIDCTaskDetail,
      TASK_DETAIL_ROLES.ViewGeneralInfoInvestmentIDCTaskDetail,
      TASK_DETAIL_ROLES.ViewGeneralInfoPolicyIDCTaskDetail,
      TASK_DETAIL_ROLES.ViewGeneralInfoPolicyValueIDCTaskDetail,
      TASK_DETAIL_ROLES.ViewGeneralInfoProductIDCTaskDetail,
      TASK_DETAIL_ROLES.ViewGeneralInfoSpecialNotesTaskDetail
    ]
  },
  {
    id: TaskDetailTab.REQUEST_INFO_TAB,
    title: 'Tab:VoiceRecording',
    permission: {
      IDC: TASK_DETAIL_ROLES.VIEW_REQUESTINFO_IDC
    },
    subTabPermissions: []
  },
  {
    id: TaskDetailTab.SUPPLEMENT_INFO_TAB,
    title: 'Tab:SupplementaryInfo',
    permission: {
      IDC: TASK_DETAIL_ROLES.VIEW_SUPPLEMENTARY_INFO_IDC
    },
    subTabPermissions: []
  },
  {
    id: TaskDetailTab.RELATED_INFO_TAB,
    title: 'Tab:RelatedInfo',
    permission: {
      IDC: TASK_DETAIL_ROLES.VIEW_RELATED_INFO_IDC
    },
    subTabPermissions: [
      TASK_DETAIL_ROLES.ViewRelatedInfoOnGoingRequestTaskDetail /*+ 'TEST_REMOVE'*/,
      TASK_DETAIL_ROLES.ViewRelatedInfoHistoryTaskDetail /*+ 'TEST_REMOVE'*/
    ]
  },
  {
    id: TaskDetailTab.QC_CHECK_TAB,
    title: 'Tab:QC',
    permission: {
      IDC: TASK_DETAIL_ROLES.VIEW_QC_IDC
    },
    subTabPermissions: []
  },
  {
    id: TaskDetailTab.ERROR_LOG_TAB,
    title: 'Tab:ErrLog',
    permission: {
      IDC: TASK_DETAIL_ROLES.VIEW_ERROR_LOG_IDC
    },
    subTabPermissions: []
  }
]
