import { FileUploadData } from '@pulseops/common'
export namespace UploadForm {
  export const DefaultData = [
    [
      {
        fileName: 'string',
        fileExtension: 'string',
        size: 1,
        base64: 'string',
        uploadedDate: new Date(),
        file: null
      },
      {
        fileName: 'strin22g',
        fileExtension: 'str2ing',
        size: 2,
        base64: 'stri2ng',
        uploadedDate: new Date(),
        file: null
      }
    ]
  ]
  export interface AddLackDataForm {
    DocumentList: AddLackItem[]
  }
  export interface AddLackItem {
    category?: string
    docTypeCode: string
    docId: string
    transactionType: string
    documentNameEn: string
    documentNameVn: string
    fileList: FileUploadData[]
  }
}
