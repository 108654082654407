import { Maybe } from '@mxt/zio/codec'
import * as t from 'io-ts'
export const WFcommentContentC = t.type({
  correlationId: t.string,
  createDate: t.string,
  errorCode: Maybe(t.string),
  description: Maybe(t.string),
  processInstanceId: t.string,
  systemName: t.string,
  isAllowRetry: Maybe(t.boolean),
  isRetried: Maybe(t.boolean)
})

export type WFcommentContent = t.TypeOf<typeof WFcommentContentC>

export const WFcommentC = t.type({
  total: t.number,
  data: t.array(WFcommentContentC)
})

export type WFResponse = t.TypeOf<typeof WFcommentC>

export const WFcommentDataC = t.type({
  transactionDate: t.string,
  system: t.string,
  errorCode: t.string,
  desp: t.string
})

export type WFCommentData = t.TypeOf<typeof WFcommentDataC>
