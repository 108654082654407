import { ZIO } from '@mxt/zio'
import { HttpClient } from '@mxt/zio-http-client'
import { Maybe } from '@mxt/zio/codec'
import { pipe } from 'fp-ts/function'
import * as t from 'io-ts'
import { POApi } from '../POApi'
import { AuthState } from './AuthState'

export namespace AuthGA {
  export const login = (body: { username: string; password: string }) =>
    pipe(
      POApi.buildUrl('authentication/api/v1/homepage/login'),
      ZIO.flatMap((url) =>
        pipe(
          HttpClient.post()(url)(
            t.type({
              access_token: t.string,
              expires_in: t.number
            })
          )(body)
        )
      )
    )

  export const checkSessionAPI = (userSessionID: string, authenStatus: string) => pipe(
    POApi.get(`authentication/api/v1/token/get-session`)(t.type({
      sessionId: Maybe(t.string),
      isValidSession: Maybe(t.boolean)
    })),
    ZIO.map((response) => {
      const checkedResult = !!response && !!response.sessionId && authenStatus === AuthState.Status.GA ? userSessionID === response.sessionId : true
      console.log('checkedResult: ' + checkedResult)
      return checkedResult
    })
  )
}
