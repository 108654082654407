import * as api from '../task-detail-api'
import { TransactionType } from '../TransactionType'
import { formatNumberWithComma } from '../../format'
import { TopUpConst } from '../OpEformDetail/Topup'
import { SourceType } from '../SourceType'
import { TopupFund } from '../TopupFund'
import { TopupOption } from '../TopupOption'
import { TopUp } from '../Topup'

export interface PreviewTopUpInfo {
  totalTopupAmount: string
  suspendAmount: string
  requirePayinAmount: string
}

export interface Topup {
  tag: TransactionType.TOP_UP
  clientName: string
  totalTopupAmount: number | null
  suspendAmount: number | null
  requirePayinAmount: number | null
  topupOption: TopupOption | null
  totalFundAmount: number | null
  funds: TopupFund[]
  productCode: string
}

export const getFundLabel = (fundCode: TopUpConst.FundCode) => {
  return (lang: string = 'vi') => {
    const fund = TopUpConst.FundList.find((fund) => fund.fundCode === fundCode)
    // return fund ? `${fund.fundCode} - ${lang === 'vi' ? fund.desVi : fund.desEn}` : ''
    return fund ? fund.fundCode + ' - ' + `${lang === 'vi' ? fund.desVi : fund.desEn}` : ''
  }
}

export const getULPFundName = (fundCode: string) => {
  return (language: string = 'vi') => {
    const fundItem = TopUpConst.FT25List.find((fund) => fund.fundCode === fundCode)
    return fundItem ? fundItem.fundCode + ' - ' + `${language === 'vi' ? fundItem.desVi : fundItem.desEn}` : ''
  }
}

export const Topup = (
  detail: api.Topup,
  poName: string,
  accountBalance: TopUp.TopUpAccountBalance[],
  productCode: string
): Topup => {
  // amount: phi dau tu them
  // zalo --> percent

  const suspenseAmount = accountBalance.reduce(function (total, next) {
    return ['S', 'U'].includes(String(next.sacsType)) ? total + Number(next.amount) : total
  }, 0)
  switch (detail.source) {
    case SourceType.BANCA:
    case SourceType.EKIOSK:
    case SourceType.PRUGREAT:
      return {
        tag: TransactionType.TOP_UP,
        clientName: poName,
        totalTopupAmount: detail.payload.body.amount ? Number(detail.payload.body.amount) : null,
        suspendAmount: Number(suspenseAmount),
        requirePayinAmount:
          Number(detail.payload.body.amount ?? 0) - Number(suspenseAmount) > 0
            ? Number(detail.payload.body.amount ?? 0) - Number(suspenseAmount)
            : 0,
        topupOption: TopupOption.Ratio,
        totalFundAmount: null,
        funds: [],
        productCode: ''
      }
    case SourceType.ZALO:
    case SourceType.PRUONLINE:
    case SourceType.PULSE:
    case SourceType.BANCA_SEA:
    case SourceType.PULSE4OPS:
      const topupInfo = detail.payload.body.attributesExt.TOP_UP

      const ILPFlag = productCode.startsWith('U')
      const topupOption = topupInfo.option === 'P' ? TopupOption.Ratio : TopupOption.Amount
      const totalTopupAmount = Number(topupInfo.totalTopupAmount)
      const customSuspenseAmount = suspenseAmount ?? 0
      return {
        tag: TransactionType.TOP_UP,
        clientName: poName,
        totalTopupAmount,
        suspendAmount: Number(customSuspenseAmount),
        requirePayinAmount:
          Number(topupInfo?.totalTopupAmount) - Number(customSuspenseAmount) > 0
            ? Number(topupInfo?.totalTopupAmount) - Number(customSuspenseAmount)
            : 0,
        topupOption,
        totalFundAmount: totalTopupAmount,
        funds:
          topupInfo.topupFunds
            ?.filter(
              (x) =>
                (topupInfo.option === 'A' && !!x.topupAmount && Number(x.topupAmount) > 0) ||
                (topupInfo.option === 'P' && !!x.ratio && x.ratio !== 'null' && Number(x.ratio) > 0)
            )
            .map((x) => {
              const filterAmount =
                topupOption === TopupOption.Ratio
                  ? formatNumberWithComma((Number(x.ratio) / 100) * totalTopupAmount)
                  : formatNumberWithComma(Number(x.topupAmount))
              // const fundCode = ILPFlag ? getFundLabel(x.fundCode as TopUpConst.FundCode) : () => x.fundCode
              const fundCode = ILPFlag ? getFundLabel(x.fundCode as TopUpConst.FundCode) : getULPFundName(x.fundCode ?? '')
              const ratio = ILPFlag ? Number(topupInfo.option === 'P' ? Number(x.ratio) : 0) : 100
              return {
                fund: fundCode,
                amount: filterAmount,
                ratio
              }
            }) || [],
        productCode
      }
  }
}
