import { useMobile } from '@pulseops/common'
import React from 'react'
import { StyleSheet, Text, View } from 'react-native'

interface Props {
  menus: {
    label: string
    // icon: JSX.Element
    value: string
  }[]
  type?: 'normal' | 'special'
}

export const InfoHeader: React.FC<Props> = ({ menus, type = 'normal' }) => {
  const { isMobile, isWide } = useMobile()

  return (
    <View style={[!isMobile && styles.header, isWide ? styles.headerWide : styles.headerWideMobile]}>
      <View
        style={[
          isMobile ? styles.container : styles.containerWide,
          { flexWrap: 'wrap', flexDirection: isMobile ? 'column' : 'row' },
          { justifyContent: type === 'special' ? 'space-evenly' : 'flex-start' }
        ]}
      >
        {menus.map((m, i) => (
          <View
            key={i}
            style={
              isMobile
                ? styles.infoWrapper
                : [
                    styles.infoWrapper,
                    styles.infoWrapperWide,
                    i === menus.length - 1 && { flex: 1 / 3 },
                    type === 'normal' ? {} : styles.infoWrapperWideSpec
                  ]
            }
          >
            <Text style={[styles.label, isMobile ? { flex: 1 } : {}]}>{m.label}</Text>
            <Text style={[styles.bold, styles.label, styles.labelActive, isMobile ? { flex: 2 } : { marginTop: 8 }]}>
              {m.value}
            </Text>
          </View>
        ))}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  header: {
    minHeight: 68,
    marginBottom: 16,
    marginTop: 16
  },
  headerWide: {
    flexDirection: 'row',
    paddingVertical: 8,
    // backgroundColor: '#E5EAEF',
    backgroundColor: '#FFF',
    borderRadius: 8
  },
  headerWideMobile: {
    flexDirection: 'row',
    // marginHorizontal: 15,
    marginTop: 16,
    // backgroundColor: '#E5EAEF',
    backgroundColor: '#FFF',
    borderRadius: 8
  },
  image: {
    width: '100%',
    height: '100%'
  },
  icon: {
    height: 24,
    width: 24,
    marginRight: 10
  },
  label: {
    fontSize: 15,
    lineHeight: 22,
    color: '#70777E',
    fontWeight: 'bold'
  },
  labelActive: {
    color: '#ED1B2E',
    fontWeight: 'bold'
  },
  // Tab --
  container: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    paddingVertical: 4
  },
  containerWide: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    paddingVertical: 4,
    rowGap: 24
  },
  infoWrapper: {
    // flexDirection: 'row',
    // alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    paddingLeft: 10
  },
  infoWrapperWide: {
    // flex: 1 / 3,
    width: '30%',
    paddingLeft: 16
  },
  infoWrapperWideSpec: {
    // flex: 1 / 3,
    width: 'auto',
    paddingLeft: 16
  },
  bold: {
    fontWeight: 'bold'
  }
})
