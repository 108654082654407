import React, { useState } from 'react'
import { pipe } from 'fp-ts/lib/function'
import { Throwable, ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import {
  Container,
  ErrorHandling,
  Panel,
  useTranslator,
  AppContext,
  useMobile,
  assets,
  RBAC,
  Permission,
  CanView,
  SelectOption,
  SelectSearch,
  formatNumberWithComma
} from '@pulseops/common'
import { CepPolicyDetailServices } from '../CEPPolicyDetailServices'
import { CepTableField, CepPanel } from '../cep-components'
import { useIsFocused, useNavigation, useRoute, RouteProp } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { InquiryStackParamList } from '../../InquiryStackParamList'
import { View, Text, StyleSheet, TextStyle, ActivityIndicator } from 'react-native'
import { CustomerInfoData } from './CepCustomerScreen'
import {
  CustomerNotesC,
  getCustomerInfo,
  getDataPreRewardGiftRed,
  getDataPreRewardPoint,
  GiftRedemptionC,
  ICustomerInfo,
  RewardPointSearchC,
  RewardPointC,
  TierHistory,
  GiftRedemptionSearchC,
  CodeRedemptionC,
  CodeRedemptionSearchC,
  getDataPreRewardCodeRed,
  PruReWardInformation,
  getDataPruRewardInit,
  getDataPreRewardAgent
} from '@pulseops/inquiry'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import { CepFormConst } from '../CEPFormConst'
import moment from 'moment'

export const CepPruReWardscreen = (props: {
  id: string
  data: PruReWardInformation | null
  setData: (val: PruReWardInformation | null) => void
  customerData: CustomerInfoData | null
  setCustomerData: (val: CustomerInfoData) => void
  clientNumber: string
}) => {
  const { t } = useTranslator('TaskManagement')
  const { i18n } = useTranslation()
  const cep = useTranslator('CustomerEngagement').t
  const [loading, bindLoading] = useLoading()
  const isFocused = useIsFocused()
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)
  const { navigate } = useNavigation()
  const { isWide } = useMobile()
  const navigation = useNavigation<StackNavigationProp<InquiryStackParamList, 'InquiryScreen'>>()

  const [customerNotes, setCustomerNotes] = useState<CustomerNotesC[]>([])
  const [tierHistories, setTierHistories] = useState<TierHistory[]>([])
  const [giftRedemptions, setGiftRedemptions] = useState<GiftRedemptionC[]>([])
  const [CodeRedeems, setCodeRedeems] = useState<CodeRedemptionC[]>([])
  const [rewardPoints, setRewardPoints] = useState<RewardPointC[]>([])
  const [pageSizePoint, setPageSizePoint] = React.useState<number>(10)
  const [pageNumPoint, setPageNumPoint] = React.useState<number>(0)
  const [totalPoint, setTotalPoint] = React.useState<number>(0)
  const [dataSearchPoint, setDataSearchPoint] = React.useState<RewardPointSearchC>({
    size: 10,
    start: 0,
    data: { pointType: null, status: null }
  })
  const [pageSizeGift, setPageSizeGift] = React.useState<number>(10)
  const [pageNumGift, setPageNumGift] = React.useState<number>(0)
  const [totalGift, setTotalGift] = React.useState<number>(0)
  const [dataSearchGift, setDataSearchGift] = React.useState<GiftRedemptionSearchC>({
    size: 10,
    start: 0,
    data: { deliveryStatus: null }
  })
  const [pageSizeRedeem, setPageSizeRedeem] = React.useState<number>(10)
  const [pageNumRedeem, setPageNumRedeem] = React.useState<number>(0)
  const [totalRedeem, setTotalRedeem] = React.useState<number>(0)
  const [dataSearchRedeem, setDataSearchRedeem] = React.useState<CodeRedemptionSearchC>({
    size: 10,
    start: 0,
    data: { codeUsingStatus: null }
  })

  interface NameModel {
    firstName?: string | null
    middleName?: string | null
    name?: string | null
    surName?: string | null
  }

  const route = useRoute<RouteProp<InquiryStackParamList, 'CEPPolicyDetailScreen'>>()
  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => navigate('HomeScreen')
        },
        {
          title: route?.params?.isSubmission === 'true' ? t('Submission:EForm') : t('menu:360Inquiry'),
          navigate: () =>
            route?.params?.isSubmission === 'true'
              ? navigation.replace('UserDrawer', {
                  screen: 'StaffSubmissionStack'
                })
              : navigation.navigate('CEPSearchScreen')
        },
        {
          title: t('PruRewards'),
          navigate: null
        }
      ])
    }
  }, [isFocused])

  const onSearchRewardPoints = (data?: RewardPointSearchC) =>
    pipe(
      getDataPreRewardPoint(props.clientNumber, data ? data : dataSearchPoint),
      ZIO.map((pruReWardInfo) => {
        if (pruReWardInfo) {
          setRewardPoints(pruReWardInfo?.data ?? [])
          setTotalPoint(pruReWardInfo.total ?? 0)
          return pruReWardInfo.data ?? []
        }
        return []
      }),
      ZIO.unsafeRun({})
    )

  const onSearchGiftRedemptions = (data?: GiftRedemptionSearchC) =>
    pipe(
      getDataPreRewardGiftRed(props.clientNumber, data ? data : dataSearchGift),
      ZIO.map((pruReWardInfo) => {
        if (pruReWardInfo && pruReWardInfo?.data) {
          const { data } = pruReWardInfo
          let GDdata: { clientId: string | null | undefined; transactionId: string | null | undefined; promotionId: string | null | undefined; promotionName: string | null | undefined; providerName: string | null | undefined; promotionCreateDate: string | null | undefined; promotionReceivedDate: string | null | undefined; deliveryStatus: string | null | undefined; deliveryDate: string | null | undefined; deliveryNote: string | null | undefined }[] = []
          data.map((item) => {
            if(item.deliveryStatus) {
              GDdata.push(item)
            }
          })
          setGiftRedemptions(GDdata ?? [])
          setTotalGift(GDdata.length ?? 0)
          return data
        }
        return []
      }),
      ZIO.unsafeRun({})
    )

  const onSearchCodeRedemptions = (data?: CodeRedemptionSearchC) =>
    pipe(
      getDataPreRewardCodeRed(props.clientNumber, data ? data : dataSearchRedeem),
      ZIO.map((pruReWardInfo) => {
        if (pruReWardInfo && pruReWardInfo.data) {
          setCodeRedeems(pruReWardInfo?.data ?? [])
          setTotalRedeem(pruReWardInfo?.total ?? 0)
          return pruReWardInfo?.data
        }
        return []
      }),
      ZIO.unsafeRun({})
    )

  const onGetDataInit = () =>
    pipe(
      getDataPruRewardInit(props.clientNumber, dataSearchPoint, dataSearchGift, dataSearchRedeem),
      ZIO.map((pruReWardInfo) => {
        const { rewardPoints, customerTierHistories, codeRedeems, giftRedemptions, customerNotes } = pruReWardInfo
        props.setData({
          rewardPoints: rewardPoints?.data ?? [],
          customerTierHistories: customerTierHistories?.data?.tierHistory ?? [],
          codeRedeems: codeRedeems?.data ?? [],
          giftRedemptions: giftRedemptions?.data ?? [],
          customerNotes: customerNotes ?? []
        })
        setCustomerNotes(customerNotes)
        setRewardPoints(rewardPoints?.data ?? [])
        setTotalPoint(rewardPoints?.total ?? 0)

        let GDdata: { clientId: string | null | undefined; transactionId: string | null | undefined; promotionId: string | null | undefined; promotionName: string | null | undefined; providerName: string | null | undefined; promotionCreateDate: string | null | undefined; promotionReceivedDate: string | null | undefined; deliveryStatus: string | null | undefined; deliveryDate: string | null | undefined; deliveryNote: string | null | undefined }[] = []
        giftRedemptions?.data.map((item) => {
          if(item.deliveryStatus) {
            GDdata.push(item)
          }
        })
        GDdata = GDdata.sort((item1,item2) => moment(item1.promotionReceivedDate).valueOf() - moment(item2.promotionReceivedDate).valueOf())
        setGiftRedemptions(GDdata ?? [])
        setTotalGift(GDdata.length ?? 0)
        setCodeRedeems(codeRedeems?.data ?? [])
        setTotalRedeem(codeRedeems?.total ?? 0)
        if (
          customerTierHistories?.data &&
          customerTierHistories?.data?.tierHistory &&
          customerTierHistories?.data?.tierHistory.length > 0
        ) {
          const newOb: TierHistory[] = customerTierHistories?.data?.tierHistory.map((item) => {
            return {
              ...item,
              activePoint: item?.activePoint ?? 0,
              newAPE: item?.newAPE ?? 0,
              oldAPE: item?.oldAPE ?? 0,
              customerName: customerTierHistories?.data?.clientName ? customerTierHistories?.data?.clientName.name : ''
            }
          })
          setTierHistories(newOb)
        }
      }),
      ZIO.unsafeRun({})
    )

  React.useEffect(() => {
    if (props.data !== null) {
      props.setData(props.data)
      setCustomerNotes(props.data.customerNotes)
      setTierHistories(props.data.customerTierHistories)
      setTotalPoint(props.data?.rewardPoints.length ?? 0)
      setRewardPoints(props.data.rewardPoints)
      setCodeRedeems(props.data.codeRedeems)
      setTotalRedeem(props.data.codeRedeems.length ?? 0)
      setGiftRedemptions(props.data.giftRedemptions)
      setTotalGift(props.data.giftRedemptions.length ?? 0)
    } else {
      onGetDataInit()
    }
  }, [])

  const getName = (item: NameModel) => {
    if (!!item) {
      return item.surName + ' ' + item.middleName + ' ' + item.firstName
    }

    return ''
  }

  const getDataCustomer = (): ZIO<unknown, Throwable, ICustomerInfo | null | undefined> =>
    pipe(
      getCustomerInfo(props.id),
      ZIO.map((res) => {
        const { contact } = res.data ?? {}
        return contact
      })
    )
  const { contact } = pipe(
    getDataCustomer(),
    ZIO.map((res) => {
      return {
        contact: res
      }
    }),
    bindLoading,
    ErrorHandling.runDidUpdate([])
  ) || {
    contact: {
      ape: '',
      point: 0,
      expiredPoint: 0,
      usedPoint: 0,
      expiredPointInNextMonth: 0,
      expiredPointIn03Month: 0,
      expiredPointIn06Month: 0,
      enrolledDate: '',
      vip: ''
    }
  }

  const { agentInfo } = pipe(
    getDataPreRewardAgent(props.clientNumber),
    ZIO.map((res) => {
      return {
        agentInfo: res?.data
      }
    }),
    bindLoading,
    ErrorHandling.runDidUpdate([])
  ) || {
    agentInfo: {
      agentCode: '',
      agentName: {
        surName: '',
        middleName: '',
        firstName: '',
        name: ''
      },
      agentPhone: '',
      agentStatus: ''
    }
  }

  const roles: string[] = pipe(
    RBAC.permissions,
    ZIO.map((permissions) => {
      return permissions
    }),
    ErrorHandling.runDidMount([])
  )

  const tierHistory = [
    {
      key: '1',
      title: cep('CustomerName'),
      name: 'customerName'
    },
    {
      key: '2',
      title: cep('Point'),
      name: 'activePoint'
    },
    {
      key: '3',
      title: cep('OldAPE'),
      name: 'oldAPE'
    },
    {
      key: '4',
      title: cep('NewAPE'),
      name: 'newAPE'
    },
    {
      key: '5',
      title: cep('OldVIPLevel'),
      name: 'oldTier'
    },
    {
      key: '6',
      title: cep('NewVIPLevel'),
      name: 'newTier'
    },
    {
      key: '7',
      title: cep('CreatedDateRewards'),
      name: 'createdDate'
    }
  ]

  const customerNoteColumn = [
    {
      key: '1',
      title: cep('NoteCode'),
      name: 'noteId'
    },
    {
      key: '2',
      title: cep('CreatedBy'),
      name: 'noteCreatedBy'
    },
    {
      key: '3',
      title: cep('CreatedDateNbuw'),
      name: 'noteCreatedDate'
    },
    {
      key: '4',
      title: cep('Content'),
      name: 'noteContent'
    }
  ]

  const statusPoint = React.useMemo(() => {
    const ob: SelectOption[] = CepFormConst.StatusPointList.map((item) => {
      return {
        label: i18n.language === 'vi' ? item.nameEn : item.nameEn,
        value: item.code
      }
    })
    return ob
  }, [i18n.language])

  const pointTypeList = React.useMemo(() => {
    const ob: SelectOption[] = CepFormConst.PointTypeList.map((item) => {
      return {
        label: i18n.language === 'vi' ? item.nameVn : item.nameEn,
        value: item.code
      }
    })
    return ob
  }, [i18n.language])

  const statusGifts = React.useMemo(() => {
    return CepFormConst.StatusGiftList.map((item) => {
      return {
        label: i18n.language === 'vi' ? item.nameEn : item.nameEn,
        value: item.code
      }
    })
  }, [i18n.language])

  const statusRedeem = React.useMemo(() => {
    return CepFormConst.StatusRedeemList.map((item) => {
      return {
        label: i18n.language === 'vi' ? item.nameEn : item.nameEn,
        value: item.code
      }
    })
  }, [i18n.language])

  return (
    <Container loading={loading}>
      <View
        style={{
          backgroundColor: '#F4F4F4',
          borderRadius: 8,
          borderWidth: 1,
          borderColor: '#D3DCE6',
          borderStyle: 'solid',
          padding: 24
        }}
      >
        <View
          style={{
            backgroundColor: '#FFFFFF',
            borderRadius: 8,
            borderWidth: 1,
            borderColor: '#D3DCE6',
            borderStyle: 'solid',
            padding: 20
          }}
        >
          <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
            <Text>
              <assets.UserIcon />
            </Text>
            <Text style={{ color: '#4F4F4F', fontSize: 16, fontWeight: '600', paddingLeft: 10 }}>
              {cep('CustomerInfo').toUpperCase()}
            </Text>
          </View>
          <View style={{ marginTop: 16 }}>
            <View style={styles.sectionRow}>
              <View style={[styles.col_20]}>
                <Text style={{ color: '#4F4F4F', fontWeight: '400' }}>{cep('VipTier')}</Text>
              </View>
              <View style={[styles.col_3]}>
                <View style={styles.inputStyle}>
                  <Text style={{ color: '#4F4F4F', fontWeight: '500' }}>
                    {contact && contact.vip ? contact.vip : ''}
                  </Text>
                </View>
              </View>
            </View>
            <View style={[styles.sectionRow, { marginTop: 10 }]}>
              <View style={[styles.col_20]}>
                <Text style={{ color: '#4F4F4F', fontWeight: '400' }}>{cep('CurrentPoint')}</Text>
              </View>
              <View style={[styles.col_3]}>
                <View style={styles.inputStyle}>
                  <Text style={{ color: '#4F4F4F', fontWeight: '500' }}>
                    {contact && contact.point ? formatNumberWithComma(contact.point) : 0}
                  </Text>
                </View>
              </View>
            </View>
            <View style={[styles.sectionRow, { marginTop: 10 }]}>
              <View style={[styles.col_20]}>
                <Text style={{ color: '#4F4F4F', fontWeight: '400' }}>{cep('ExpiredPoint')}</Text>
              </View>
              <View style={[styles.col_3]}>
                <View style={styles.inputStyle}>
                  <Text style={{ color: '#4F4F4F', fontWeight: '500' }}>
                    {contact && contact.expiredPoint ? formatNumberWithComma(contact.expiredPoint) : 0}
                  </Text>
                </View>
              </View>
            </View>
            <View style={[styles.sectionRow, { marginTop: 10 }]}>
              <View style={[styles.col_20]}>
                <Text style={{ color: '#4F4F4F', fontWeight: '400' }}>{cep('UsedPoint')}</Text>
              </View>
              <View style={[styles.col_3]}>
                <View style={styles.inputStyle}>
                  <Text style={{ color: '#4F4F4F', fontWeight: '500' }}>
                    {contact && contact.usedPoint ? formatNumberWithComma(contact.usedPoint) : 0}
                  </Text>
                </View>
              </View>
            </View>
            <View style={[styles.sectionRow, { marginTop: 10 }]}>
              <View style={[styles.col_20]}>
                <Text style={{ color: '#4F4F4F', fontWeight: '400' }}>{cep('TotalAPE')}</Text>
              </View>
              <View style={[styles.col_3]}>
                <View style={styles.inputStyle}>
                  <Text style={{ color: '#4F4F4F', fontWeight: '500' }}>
                    {contact && contact.ape ? formatNumberWithComma(contact.ape) : 0}
                  </Text>
                </View>
              </View>
            </View>
            <View style={[styles.sectionRow, { marginTop: 10 }]}>
              <View style={[styles.col_20]}>
                <Text style={{ color: '#4F4F4F', fontWeight: '400' }}>{cep('VIPDate')}</Text>
              </View>
              <View style={[styles.col_3]}>
                <View style={styles.inputStyle}>
                  <Text style={{ color: '#4F4F4F', fontWeight: '500' }}>
                    {contact && contact.enrolledDate ? contact.enrolledDate : ''}
                  </Text>
                </View>
              </View>
            </View>
            <View style={[styles.sectionRow, { marginTop: 10 }]}>
              <View style={[styles.col_20]}>
                <Text style={{ color: '#4F4F4F', fontWeight: '400' }}>{cep('ExpiredPointInUpcomingMonth')}</Text>
              </View>
              <View style={[styles.col_3]}>
                <View style={styles.inputStyle}>
                  <Text style={{ color: '#4F4F4F', fontWeight: '500' }}>
                    {contact && contact.expiredPointInNextMonth ? formatNumberWithComma(contact.expiredPointInNextMonth) : 0}
                  </Text>
                </View>
              </View>
            </View>
            <View style={[styles.sectionRow, { marginTop: 10 }]}>
              <View style={[styles.col_20]}>
                <Text style={{ color: '#4F4F4F', fontWeight: '400' }}>{cep('ExpiredPointInNextThreeMonth')}</Text>
              </View>
              <View style={[styles.col_3]}>
                <View style={styles.inputStyle}>
                  <Text style={{ color: '#4F4F4F', fontWeight: '500' }}>
                    {contact && contact.expiredPointIn03Month ? formatNumberWithComma(contact.expiredPointIn03Month) : 0}
                  </Text>
                </View>
              </View>
            </View>
            <View style={[styles.sectionRow, { marginTop: 10 }]}>
              <View style={[styles.col_20]}>
                <Text style={{ color: '#4F4F4F', fontWeight: '400' }}>{cep('ExpiredPointInNextSixMonth')}</Text>
              </View>
              <View style={[styles.col_3]}>
                <View style={styles.inputStyle}>
                  <Text style={{ color: '#4F4F4F', fontWeight: '500' }}>
                    {contact && contact.expiredPointIn06Month ? formatNumberWithComma(contact.expiredPointIn06Month) : 0}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        {/* Agent information */}
        <View
          style={{
            backgroundColor: '#FFFFFF',
            borderRadius: 8,
            borderWidth: 1,
            borderColor: '#D3DCE6',
            borderStyle: 'solid',
            padding: 20,
            marginTop: 30
          }}
        >
          <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
            <Text>
              <assets.IconStar />
            </Text>
            <Text style={{ color: '#4F4F4F', fontSize: 16, fontWeight: '600', paddingLeft: 10 }}>
              {cep('AgentInfomation').toUpperCase()}
            </Text>
          </View>
          <View style={{ marginTop: 16 }}>
            <View style={styles.sectionRow}>
              <View style={[styles.col_20]}>
                <Text style={{ color: '#4F4F4F', fontWeight: '400' }}>{cep('ServiceAgentCode')}</Text>
              </View>
              <View style={[styles.col_3]}>
                <View style={styles.inputStyle}>
                  <Text style={{ color: '#4F4F4F', fontWeight: '500' }}>
                    {agentInfo && agentInfo.agentCode ? agentInfo.agentCode : ''}
                  </Text>
                </View>
              </View>
            </View>
            <View style={[styles.sectionRow, { marginTop: 10 }]}>
              <View style={[styles.col_20]}>
                <Text style={{ color: '#4F4F4F', fontWeight: '400' }}>{cep('ServiceAgentName')}</Text>
              </View>
              <View style={[styles.col_3]}>
                <View style={styles.inputStyle}>
                  <Text style={{ color: '#4F4F4F', fontWeight: '500' }}>
                    {agentInfo && agentInfo.agentName ? getName(agentInfo.agentName) : ''}
                  </Text>
                </View>
              </View>
            </View>
            <View style={[styles.sectionRow, { marginTop: 10 }]}>
              <View style={[styles.col_20]}>
                <Text style={{ color: '#4F4F4F', fontWeight: '400' }}>{cep('ServiceAgentPhone')}</Text>
              </View>
              <View style={[styles.col_3]}>
                <View style={styles.inputStyle}>
                  <Text style={{ color: '#4F4F4F', fontWeight: '500' }}>
                    {agentInfo && agentInfo.agentPhone ? agentInfo.agentPhone : ''}
                  </Text>
                </View>
              </View>
            </View>
            <View style={[styles.sectionRow, { marginTop: 10 }]}>
              <View style={[styles.col_20]}>
                <Text style={{ color: '#4F4F4F', fontWeight: '400' }}>{cep('ServiceAgentStatus')}</Text>
              </View>
              <View style={[styles.col_3]}>
                <View style={styles.inputStyle}>
                  <Text style={{ color: '#4F4F4F', fontWeight: '500' }}>
                    {agentInfo && agentInfo.agentStatus ? agentInfo.agentStatus : ''}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
      {roles.includes(Permission['ViewPruRewardsInfoPolicyDetail']) && (
        <>
          {/* POINT TABLE */}
          <CepPanel title={cep('PointHistory')} isShowIconTitle={true} containerStyle={{ marginTop: 30 }}>
            <View style={{ marginTop: 20, marginBottom: 4 }}>
              <TableContainer component={Paper}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow style={{ backgroundColor: '#F4F4F4' }}>
                      <TableCell align="left" style={{ minWidth: 150, fontWeight: 'bold', fontSize: 15 }}>
                        <Text style={styles.wrapText}>{cep('Issuer')}</Text>
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 150, fontWeight: 'bold', fontSize: 15 }}>
                        <View>
                          <Text style={{ fontWeight: 'bold', fontSize: 15 }}>{cep('Status')}</Text>
                          <SelectSearch
                            inputStyle={styles.selectStyle}
                            disableUnderline={true}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                            placeholder={t('common:Select')}
                            value={statusPoint.find((x) => x.value === dataSearchPoint.data?.status)}
                            onChange={(val) => {
                              const dataFilter: RewardPointSearchC = {
                                ...dataSearchPoint,
                                data: { pointType: dataSearchPoint.data?.pointType, status: val?.value }
                              }
                              onSearchRewardPoints(dataFilter)
                              setDataSearchPoint(dataFilter)
                            }}
                            options={statusPoint}
                          />
                        </View>
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 150, fontWeight: 'bold', fontSize: 15 }}>
                        <View>
                          <Text style={{ fontWeight: 'bold' }}>{cep('PointType')}</Text>
                          <SelectSearch
                            inputStyle={styles.selectStyle}
                            disableUnderline={true}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                            placeholder={t('common:Select')}
                            value={pointTypeList.find((x) => x.value === dataSearchPoint.data?.pointType)}
                            onChange={(val) => {
                              const dataFilter: RewardPointSearchC = {
                                ...dataSearchPoint,
                                data: { status: dataSearchPoint.data?.status, pointType: val?.value }
                              }
                              onSearchRewardPoints(dataFilter)
                              setDataSearchPoint(dataFilter)
                            }}
                            options={pointTypeList}
                          />
                        </View>
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 150, fontWeight: 'bold', fontSize: 15 }}>
                        <Text style={styles.wrapText}>{cep('PointAmount')}</Text>
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 150, fontWeight: 'bold', fontSize: 15 }}>
                        <Text style={styles.wrapText}>{cep('Note')}</Text>
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 150, fontWeight: 'bold', fontSize: 15 }}>
                        <Text style={styles.wrapText}>{cep('SystemNote')}</Text>
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 150, fontWeight: 'bold', fontSize: 15 }}>
                        <Text style={styles.wrapText}>{cep('CreatedDateRewards')}</Text>
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 150, fontWeight: 'bold', fontSize: 15 }}>
                        <Text style={styles.wrapText}>{cep('ExpirationDate')}</Text>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rewardPoints && rewardPoints.length === 0 && (
                      <TableRow>
                        <TableCell style={{ textAlign: 'center' }} colSpan={22}>
                          <CanView condition={loading}>
                            <View style={styles.loadingContainer}>
                              <ActivityIndicator size="large" color="red" />
                            </View>
                          </CanView>
                        </TableCell>
                      </TableRow>
                    )}
                    {rewardPoints && rewardPoints.length > 0 ? (
                      rewardPoints.map((data, i) => (
                        <TableRow key={i}>
                          <TableCell>{data.issuer}</TableCell>
                          <TableCell>{data.status}</TableCell>
                          <TableCell>{data.pointType}</TableCell>
                          <TableCell>{data.pointValue ? formatNumberWithComma(data?.pointValue ?? null): 0}</TableCell>
                          <TableCell>{data.pointComment}</TableCell>
                          <TableCell>{data.pointRemark}</TableCell>
                          <TableCell>{data.pointTransferCreatedAt}</TableCell>
                          <TableCell>{data.pointTransferExpiredAt}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <>
                        {!loading && (
                          <TableRow>
                            <TableCell style={{ textAlign: 'center' }} colSpan={22}>
                              {t('common:Nodata')}
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                page={pageNumPoint}
                rowsPerPageOptions={[10, 20, 50, 100]}
                rowsPerPage={pageSizePoint}
                count={totalPoint}
                onPageChange={(e, page) => {
                  setPageNumPoint(page)
                  setRewardPoints([])
                  bindLoading
                  const data = {
                    ...dataSearchPoint,
                    size: pageSizePoint,
                    start: page
                  }
                  onSearchRewardPoints(data)
                }}
                onRowsPerPageChange={(e) => {
                  const rowsPerPage = Number(e.target.value)
                  setPageSizePoint(rowsPerPage)
                  setRewardPoints([])
                  bindLoading
                  const data = {
                    ...dataSearchPoint,
                    size: rowsPerPage,
                    start: pageNumPoint
                  }
                  if (pageNumPoint * rowsPerPage >= totalPoint) {
                    setPageNumPoint(0)
                    onSearchRewardPoints(data)
                  } else {
                    onSearchRewardPoints(data)
                  }
                }}
                labelRowsPerPage={t('common:PaginationSize')}
                labelDisplayedRows={({ from, to, count }) => i18next.t('common:Pagination', { from, to, count })}
                component={View}
              ></TablePagination>
            </View>
            {/* {loading && <LinearProgress color="secondary" />} */}
          </CepPanel>

          {/* TIER HISTORY */}
          <Panel title={cep('TierHistory')} containerStyle={{ marginTop: 14 }}>
            <CepTableField columns={tierHistory} dataSource={tierHistories.map(item => {
              return{
                ...item,
                activePoint: formatNumberWithComma(item?.activePoint ?? 0),
                newAPE: formatNumberWithComma(item?.newAPE ?? 0),
                oldAPE: formatNumberWithComma(item?.oldAPE ?? 0)
              }
            }) ?? []} name="TierHistory" />
          </Panel>

          {/* CODE REDEEM TABLE */}
          <CepPanel title={cep('CodeRedeemHistory')} isShowIconTitle={true} containerStyle={{ marginTop: 14 }}>
            <View style={{ marginTop: 20, marginBottom: 20 }}>
              <TableContainer component={Paper}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow style={{ backgroundColor: '#F4F4F4' }}>
                      <TableCell align="left" style={{ minWidth: 150, fontWeight: 'bold', fontSize: 15 }}>
                        <Text style={styles.wrapText}>{cep('CodeNumber')}</Text>
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 150, fontWeight: 'bold', fontSize: 15 }}>
                        <Text style={styles.wrapText}>{cep('Provider')}</Text>
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 150, fontWeight: 'bold', fontSize: 15 }}>
                        <Text style={styles.wrapText}>{cep('Deal')}</Text>
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 150, fontWeight: 'bold', fontSize: 15 }}>
                        <View>
                          <Text style={{ fontWeight: 'bold', fontSize: 15 }}>{cep('UsageStatus')}</Text>
                          <SelectSearch
                            inputStyle={styles.selectStyle}
                            disableUnderline={true}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                            placeholder={t('common:Select')}
                            value={statusRedeem.find((x) => x.value === dataSearchRedeem.data?.codeUsingStatus)}
                            onChange={(val) => {
                              const dataFilter: CodeRedemptionSearchC = {
                                ...dataSearchRedeem,
                                data: { codeUsingStatus: val?.value }
                              }
                              onSearchCodeRedemptions(dataFilter)
                              setDataSearchRedeem(dataFilter)
                            }}
                            options={statusRedeem}
                          />
                        </View>
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 150, fontWeight: 'bold', fontSize: 15 }}>
                        <Text style={styles.wrapText}>{cep('CodeRedeemDate')}</Text>
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 150, fontWeight: 'bold', fontSize: 15 }}>
                        <Text style={styles.wrapText}>{cep('CodeExpiredDate')}</Text>
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 150, fontWeight: 'bold', fontSize: 15 }}>
                        <Text style={styles.wrapText}>{cep('Channel')}</Text>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {CodeRedeems && CodeRedeems.length === 0 && (
                      <TableRow>
                        <TableCell style={{ textAlign: 'center' }} colSpan={22}>
                          <CanView condition={loading}>
                            <View style={styles.loadingContainer}>
                              <ActivityIndicator size="large" color="red" />
                            </View>
                          </CanView>
                        </TableCell>
                      </TableRow>
                    )}
                    {CodeRedeems && CodeRedeems.length > 0 ? (
                      CodeRedeems.map((data, i) => (
                        <TableRow key={i}>
                          <TableCell>{data.code}</TableCell>
                          <TableCell>{data.providerName}</TableCell>
                          <TableCell>{data.dealName}</TableCell>
                          <TableCell>{data.codeUsingStatus}</TableCell>
                          <TableCell>{data.redeemCodeDate}</TableCell>
                          <TableCell>{data.expiredDate}</TableCell>
                          <TableCell>{data.channel}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <>
                        {!loading && (
                          <TableRow>
                            <TableCell style={{ textAlign: 'center' }} colSpan={22}>
                              {t('common:Nodata')}
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                page={pageNumRedeem}
                rowsPerPageOptions={[10, 20, 50, 100]}
                rowsPerPage={pageSizeRedeem}
                count={totalRedeem}
                onPageChange={(e, page) => {
                  setPageNumRedeem(page)
                  setCodeRedeems([])
                  bindLoading
                  const data = {
                    ...dataSearchRedeem,
                    size: pageSizeRedeem,
                    start: page
                  }
                  onSearchCodeRedemptions(data)
                }}
                onRowsPerPageChange={(e) => {
                  const rowsPerPage = Number(e.target.value)
                  setPageSizeRedeem(rowsPerPage)
                  setGiftRedemptions([])
                  bindLoading
                  const data = {
                    ...dataSearchRedeem,
                    size: rowsPerPage,
                    start: pageNumRedeem
                  }
                  if (pageNumPoint * rowsPerPage >= totalPoint) {
                    setPageNumGift(0)
                    onSearchCodeRedemptions(data)
                  } else {
                    onSearchCodeRedemptions(data)
                  }
                }}
                labelRowsPerPage={t('common:PaginationSize')}
                labelDisplayedRows={({ from, to, count }) => i18next.t('common:Pagination', { from, to, count })}
                component={View}
              ></TablePagination>
            </View>
            {/* {loading && <LinearProgress color="secondary" />} */}
          </CepPanel>

          {/* GIFT TABLE */}
          <CepPanel title={cep('GiftRedeemHistory')} isShowIconTitle={true} containerStyle={{ marginTop: 14 }}>
            <View style={{ marginTop: 20, marginBottom: 20 }}>
              <TableContainer component={Paper}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow style={{ backgroundColor: '#F4F4F4' }}>
                      <TableCell align="left" style={{ minWidth: 150, fontWeight: 'bold', fontSize: 15 }}>
                        <Text style={styles.wrapText}>{cep('TransactionID')}</Text>
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 150, fontWeight: 'bold', fontSize: 15 }}>
                        <Text style={styles.wrapText}>{cep('PromotionID')}</Text>
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 150, fontWeight: 'bold', fontSize: 15 }}>
                        <Text style={styles.wrapText}>{cep('DealName')}</Text>
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 150, fontWeight: 'bold', fontSize: 15 }}>
                        <Text style={styles.wrapText}>{cep('Provider')}</Text>
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 150, fontWeight: 'bold', fontSize: 15 }}>
                        <Text style={styles.wrapText}>{cep('RedeemDate')}</Text>
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 150, fontWeight: 'bold', fontSize: 15 }}>
                        <Text style={styles.wrapText}>{cep('DeliveryDate/ReturnedDate')}</Text>
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 150, fontWeight: 'bold', fontSize: 15 }}>
                        <View>
                          <Text style={{ fontWeight: 'bold', fontSize: 15 }}>{cep('GiftStatus')}</Text>
                          <SelectSearch
                            inputStyle={styles.selectStyle}
                            disableUnderline={true}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                            placeholder={t('common:Select')}
                            value={statusGifts.find((x) => x.value === dataSearchGift.data?.deliveryStatus)}
                            onChange={(val) => {
                              const dataFilter: GiftRedemptionSearchC = {
                                ...dataSearchGift,
                                data: { deliveryStatus: val?.value }
                              }
                              onSearchGiftRedemptions(dataFilter)
                              setDataSearchGift(dataFilter)
                            }}
                            options={statusGifts}
                          />
                        </View>
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 150, fontWeight: 'bold', fontSize: 15 }}>
                        <Text style={styles.wrapText}>{cep('AgentDeliveyDate')}</Text>
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 150, fontWeight: 'bold', fontSize: 15 }}>
                        <Text style={styles.wrapText}>{cep('Note')}</Text>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {giftRedemptions && giftRedemptions.length === 0 && (
                      <TableRow>
                        <TableCell style={{ textAlign: 'center' }} colSpan={22}>
                          <CanView condition={loading}>
                            <View style={styles.loadingContainer}>
                              <ActivityIndicator size="large" color="red" />
                            </View>
                          </CanView>
                        </TableCell>
                      </TableRow>
                    )}
                    {giftRedemptions && giftRedemptions.length > 0 ? (
                      giftRedemptions.map((data, i) => (
                        <TableRow key={i}>
                          <TableCell>{data.transactionId}</TableCell>
                          <TableCell>{data.promotionId}</TableCell>
                          <TableCell>{data.promotionName}</TableCell>
                          <TableCell>{data.providerName}</TableCell>
                          <TableCell>{data.promotionCreateDate}</TableCell>
                          <TableCell>{data.promotionReceivedDate}</TableCell>
                          <TableCell>{data.deliveryStatus}</TableCell>
                          <TableCell>{data.deliveryDate}</TableCell>
                          <TableCell>{data.deliveryNote}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <>
                        {!loading && (
                          <TableRow>
                            <TableCell style={{ textAlign: 'center' }} colSpan={22}>
                              {t('common:Nodata')}
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                page={pageNumGift}
                rowsPerPageOptions={[10, 20, 50, 100]}
                rowsPerPage={pageSizeGift}
                count={totalGift}
                onPageChange={(e, page) => {
                  setPageNumGift(page)
                  setGiftRedemptions([])
                  bindLoading
                  const data = {
                    ...dataSearchGift,
                    size: pageSizeGift,
                    start: page
                  }
                  onSearchGiftRedemptions(data)
                }}
                onRowsPerPageChange={(e) => {
                  const rowsPerPage = Number(e.target.value)
                  setPageSizeGift(rowsPerPage)
                  setGiftRedemptions([])
                  bindLoading
                  const data = {
                    ...dataSearchGift,
                    size: rowsPerPage,
                    start: pageNumGift
                  }
                  if (pageNumGift * rowsPerPage >= totalGift) {
                    setPageNumGift(0)
                    onSearchGiftRedemptions(data)
                  } else {
                    onSearchGiftRedemptions(data)
                  }
                }}
                labelRowsPerPage={t('common:PaginationSize')}
                labelDisplayedRows={({ from, to, count }) => i18next.t('common:Pagination', { from, to, count })}
                component={View}
              ></TablePagination>
            </View>
            {/* {loading && <LinearProgress color="secondary" />} */}
          </CepPanel>

          {/* NOTE */}
          <Panel title={cep('Note')} containerStyle={{ marginTop: 14 }}>
            <CepTableField columns={customerNoteColumn} dataSource={customerNotes ?? []} name="Note" />
          </Panel>
        </>
      )}
    </Container>
  )
}
const styles = StyleSheet.create({
  sectionRow: {
    flex: 1,
    flexWrap: 'wrap',
    flexDirection: 'row'
  },
  col_45: {
    width: '100%',
    maxWidth: '48%'
  },
  col_2: {
    width: '100%',
    maxWidth: '50%'
  },
  col_3: {
    width: '100%',
    maxWidth: '35%'
  },
  col_20: {
    width: '100%',
    maxWidth: '20%'
  },
  inputStyle: {
    backgroundColor: '#FCFCFC',
    borderWidth: 1,
    borderColor: '#D3DCE6',
    borderStyle: 'solid',
    borderRadius: 8,
    paddingHorizontal: 16,
    paddingVertical: 5,
    height: '100%'
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 10
  },
  cellNoWrap: {
    whiteSpace: 'nowrap'
  } as TextStyle,
  wrapText: {
    position: 'absolute',
    top: 16,
    fontWeight: 'bold'
  },
  selectStyle: {
    borderRadius: 6,
    borderColor: '#D3DCE6',
    borderWidth: 1,
    backgroundColor: '#fff',
    marginTop: 10,
    paddingHorizontal: 8,
    paddingVertical: 4
  }
})
