import { assets, BreadCrumb, useMobile } from '@pulseops/common'
import { useIsFocused } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native'
import { AdminPageStackParamList, AdminTableList } from './AdminPageStackParamList'

export const AdminPageScreen = (props: StackScreenProps<AdminPageStackParamList>) => {
  const { isWide } = useMobile()
  const { t } = useTranslation()
  const isFocused = useIsFocused()
  const getBreadCrumbList = React.useMemo(() => {
    const subItems = isFocused ? [{ title: t('Admin page'), navigate: null }] : []
    return subItems
  }, [isFocused])
  const getConfigTableList = () => {
    const configTableList = [
      { tableName: t('T33 - Get office list'), screenName: AdminTableList.ConfigT33OfficeScreen, role: '' },
      { tableName: t('Bank list'), screenName: AdminTableList.ConfigBankListScreen, role: '' },
      { tableName: t('Bank Branch list'), screenName: AdminTableList.ConfigBankBranchListScreen, role: '' },
      { tableName: t('GA Information List'), screenName: AdminTableList.ConfigGAInformationListScreen, role: '' },
    ]
    return configTableList.map((tableItem, index) => {
      return (
        <TouchableOpacity key={index} onPress={() => props.navigation.navigate(tableItem.screenName)}>
          <View style={adminStyles.blockContainer}>
            <Text>{tableItem.tableName}</Text>
            <assets.OBArrowRight />
          </View>
        </TouchableOpacity>
      )
    })
  }
  return (
    <View style={adminStyles.container}>
      <View style={adminStyles.headerContainer}>
        {isWide && <BreadCrumb navigation={getBreadCrumbList} />}
      </View>
      <View style={adminStyles.bodyContainer}>
        <View style={{}}>
          <Text style={adminStyles.bodyTitle}>{t('Admin page')}</Text>
        </View>
        {getConfigTableList()}
      </View>
    </View>
  )
}
const adminStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff'
  },
  headerContainer: {
    flexDirection: 'row',
    backgroundColor: '#FFFFFF',
    alignItems: 'center',
    paddingHorizontal: 16,
    shadowColor: 'rgba(0, 0, 0, 0.04)',
    shadowOffset: {
      width: 0,
      height: 4
    },
    shadowOpacity: 0.3,
    shadowRadius: 4.65,
    elevation: 8
  },
  bodyContainer: {
    // width: '100%',
    borderWidth: 1,
    borderColor: '#D3DCE6',
    backgroundColor: '#FAFAFA',
    borderRadius: 8,
    marginHorizontal: 15,
    padding: 30
  },
  bodyHeader: {
  },
  bodyTitle: {
    color: '#000000',
    fontSize: 24,
    fontWeight: 'bold'
  },
  blockContainer: {
    // width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    borderRadius: 8,
    marginTop: 30
  }
})